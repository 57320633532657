import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { creategoogleAuthLink, handleGoogleRedirection } from "../../../redux/actions/google-calander";
// import { setTokens } from "./tokens";


const CalanderConnect = (redirectUrl) => {

  const googleCottectedData = useSelector((state) => {
    return state.generateAuthLink?.data?.connected;
  });
  console.log("googleCottectedData", googleCottectedData)
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');


  useEffect(() => {
    handleTokenFromQueryParams();

  }, [googleCottectedData, code]);

  const dispatch = useDispatch();

  const [connected, setConnected] = useState(false);



  const createGoogleAuthLink = async (redirectUrl) => {
    dispatch(creategoogleAuthLink({ redirectUrl }))
  };

  const handleTokenFromQueryParams = () => {
    if (googleCottectedData && !connected) {
      setConnected(true)
    }
    if (code) {
      dispatch(handleGoogleRedirection({ code, redirectUrl }))
    }
    const query = new URLSearchParams(window.location.search);
    const accessToken = query.get("accessToken");
    const refreshToken = query.get("refreshToken");
    const expirationDate = newExpirationDate();
    if (accessToken && refreshToken) {
      storeTokenData(accessToken, refreshToken, expirationDate);
      setConnected(true);
    }
  };

  const newExpirationDate = () => {
    var expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    return expiration;
  };

  const storeTokenData = async (token, refreshToken, expirationDate) => {
    sessionStorage.setItem("accessToken", token);
    sessionStorage.setItem("refreshToken", refreshToken);
    sessionStorage.setItem("expirationDate", expirationDate);
  };

  const disconnect = () => {
    setConnected(false);
  };

  return (
    <div className="calander-connect-container">
      {/* <h1>Google</h1> */}
      {!connected ? (

        <button
          border="grey"
          type="button"
          className="sc-csuSiG biEAXd text-truncate undefined btn btn-default"
          onClick={(e) => {
            e.preventDefault()
            createGoogleAuthLink(redirectUrl)
          }}
        >
          <span>Connect</span><svg
            fill="none"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M17.5303 1.46967C17.8232 1.76256 17.8232 2.23744 17.5303 2.53033L14.5607 5.5L18.5 9.43934L21.4697 6.46967C21.7626 6.17678 22.2374 6.17678 22.5303 6.46967C22.8232 6.76256 22.8232 7.23744 22.5303 7.53033L19.5607 10.5L19.6161 10.5555C20.6901 11.6294 20.6901 13.3706 19.6161 14.4445L17.5303 16.5303C14.9313 19.1294 10.8215 19.297 8.02762 17.033L2.53033 22.5303C2.23744 22.8232 1.76256 22.8232 1.46967 22.5303C1.17678 22.2374 1.17678 21.7626 1.46967 21.4697L6.96696 15.9724C4.70304 13.1785 4.87061 9.06873 7.46967 6.46967L9.55546 4.38388C10.6294 3.30994 12.3706 3.30994 13.4445 4.38388L13.5 4.43934L16.4697 1.46967C16.7626 1.17678 17.2374 1.17678 17.5303 1.46967ZM12.3839 5.44454C11.8957 4.95639 11.1043 4.95639 10.6161 5.44454L8.53033 7.53033C6.33794 9.72272 6.33794 13.2773 8.53033 15.4697C10.7227 17.6621 14.2773 17.6621 16.4697 15.4697L18.5555 13.3839C19.0436 12.8957 19.0436 12.1043 18.5555 11.6161L12.3839 5.44454Z"
              fill="#293644"
            ></path>
          </svg>
        </button>
      ) : (
        <button
            border="grey"
          type="button"
          className="sc-csuSiG biEAXd text-truncate undefined btn btn-default"
          onClick={(e) => {
            e.preventDefault()
            disconnect()
          }}
        >
          <span>Disconnect</span><svg
            fill="none"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M17.5303 1.46967C17.8232 1.76256 17.8232 2.23744 17.5303 2.53033L14.5607 5.5L18.5 9.43934L21.4697 6.46967C21.7626 6.17678 22.2374 6.17678 22.5303 6.46967C22.8232 6.76256 22.8232 7.23744 22.5303 7.53033L19.5607 10.5L19.6161 10.5555C20.6901 11.6294 20.6901 13.3706 19.6161 14.4445L17.5303 16.5303C14.9313 19.1294 10.8215 19.297 8.02762 17.033L2.53033 22.5303C2.23744 22.8232 1.76256 22.8232 1.46967 22.5303C1.17678 22.2374 1.17678 21.7626 1.46967 21.4697L6.96696 15.9724C4.70304 13.1785 4.87061 9.06873 7.46967 6.46967L9.55546 4.38388C10.6294 3.30994 12.3706 3.30994 13.4445 4.38388L13.5 4.43934L16.4697 1.46967C16.7626 1.17678 17.2374 1.17678 17.5303 1.46967ZM12.3839 5.44454C11.8957 4.95639 11.1043 4.95639 10.6161 5.44454L8.53033 7.53033C6.33794 9.72272 6.33794 13.2773 8.53033 15.4697C10.7227 17.6621 14.2773 17.6621 16.4697 15.4697L18.5555 13.3839C19.0436 12.8957 19.0436 12.1043 18.5555 11.6161L12.3839 5.44454Z"
              fill="#293644"
            ></path>
          </svg>
        </button>
      )}
    </div>
  );
}

export default CalanderConnect;