import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MenteeRoutes, MentorRoutes, PublicRoutes } from "../../routes";
import { TailSpin } from "react-loader-spinner";
import "../../assets/css/find-mentor.css";
import { getUserDetails } from "../../redux/actions/login";

export const Auth = ({ LoggedIn }) => {
  const [userType, setUserType] = useState('')
  const [isRouteLoading, setIsRouteLoading] = useState(true)
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });

  const isAuth = useSelector((state) => {
    const data = (state.userDetailsReducer?.data && state.userDetailsReducer?.data[0]?.user_id) || state.passwordLoginReducer?.data?.user_id;
    return data ? true : false;
  });


  const handleRouting = (data) => {
    if (data?.user_type === "mentor") {
      setUserType("mentor")
      setIsRouteLoading(false)

      if (data?.status === "Signup") {
        navigate("/apply-as-mentor");
      }
      // else{
      //   navigate("/dashboard/home")
      // }
    }
    if (data?.user_type === "mentee") {
      setUserType("mentee")
      setIsRouteLoading(false)
      if (data?.status === "Signup") {
        navigate("/apply-as-mentee");
      }else{
        navigate('')
      }
    }
  }

  useEffect(() => {
    if (LoggedIn || isAuth) {
      if (!userDetails) {
        dispatch(getUserDetails(localStorage.getItem("REACT_USER_ID")));
      }
      else {
        handleRouting(userDetails)
      }
    }
  }, [userType, LoggedIn, isAuth])

  return <>
    {
      (LoggedIn || isAuth) && isRouteLoading &&
      <div className="loader">
        <TailSpin ariaLabel="Loading..." color="#00BFFF" />
      </div>
    }
    {(LoggedIn || isAuth) && userType == "mentor" && <MentorRoutes />}
    {(LoggedIn || isAuth) && userType == "mentee" && <MenteeRoutes />}
    {!(LoggedIn || isAuth) && <PublicRoutes />}
  </>;
};


