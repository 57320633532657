import React, { useEffect } from "react";
import "./menteeGoal.css";
import { useState } from "react";
import FindMentorNoResult from "../findMentor-noResult";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { viewMentorProfileAction } from "../../redux/actions/mentor";

import { crossIcon } from "../../assets/images/export-images";
import { TailSpin } from "react-loader-spinner";
import style from "../Pricing/p_ricing.module.css";

import femaleAvatar from "../../../src/assets/images/female.png";
import maleAvatar from "../../../src/assets/images/male.png";
import GaolQuestion from "./TopGoal";
import MessageMentor from "./MessageMentor";
import ReleventMentor from "./ReleventMentor";
import { REACT_USER_ID } from "../auth/tokenProvider";
import { getGoalAction, menteeGoalAction } from "../../redux/actions/common";
import { Modal, ModalBody } from "react-bootstrap";
import { goalSimilarMentor } from "../../redux/actions/mentee";
import arrowDownImg from "../../components/dashboard/images/arrow-down.png";

const MenteeGoal = ({ goalModal, setGoalModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mentee_id = REACT_USER_ID;

  let date = new Date();
  let datestring = date.toTimeString();
  let countryname =
    datestring?.includes("india") | datestring?.includes("India")
      ? "India"
      : "";
  useEffect(() => {
    if (REACT_USER_ID) {
      console.log("working");
      dispatch(goalSimilarMentor(REACT_USER_ID));
      dispatch(getGoalAction(REACT_USER_ID));
    }
  }, [REACT_USER_ID]);

  const similarMentorsList = useSelector((state) => {
    const data = state.goalSimilarMentorReducer?.data || [];
    return data.slice(0, 2);
  });

  const isLoading = useSelector(
    (state) => state.goalSimilarMentorReducer?.isLoading
  );
  const GoalLoading = useSelector((state) => state.getGoalReducer?.loading);

  const myGoalData = useSelector((state) => state.getGoalReducer?.data);

  const viewProfile = (mentor) => {
    let user = mentor.name.split(" ").join("") + "_" + mentor.user_id;
    dispatch(viewMentorProfileAction(mentor.user_id));
    navigate("/mentor-details/" + user);
  };

  const [activeTab, setActiveTab] = useState(0);
  const [activeMatch, setActiveMatch] = useState(true);

  const [menteeGoalData, setmenteeGoalData] = useState({
    mentee_id: mentee_id,
    goals: myGoalData?.goals || "",
    specific_tools: myGoalData?.specific_tools || [],
    specific_skills: myGoalData?.specific_skills || [],
    county_like_mentor: myGoalData?.county_like_mentor || [],
    experties: myGoalData?.experties || [],
    message_for_mentor: myGoalData?.message_for_mentor || "",
  });


  const handleTabClick = (index) => {
    setActiveTab(index);
    setActiveMatch(false);
  };
  const handleContinue = () => {
    setActiveTab(activeTab + 1);
  };

  const handleBack = () => {
    setActiveTab(activeTab - 1);
  };

  const handleSubmitGoal = () => {
    setActiveMatch(true);
    setActiveTab(0);
    console.log("menteeGoalData", menteeGoalData);
    dispatch(menteeGoalAction(menteeGoalData));
  };
  const id = 1;
  return (
    <>
      {GoalLoading ? (
        <div className="justify-content-center d-flex mt-5">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (null)}
      <section //section replace Modal for popup module
        size="xl"
        centered
      >
        <ModalBody>
          <div className="MenteeGoal__Modal">
            <div className="container">
              <div className=" Set___Goal text_wrapper">
                <div className="activation__header">
                  <div className="menteegoal_title text-center">
                    <h3>Find the perfect mentor with our help.</h3>
                  </div>
                  <div
                    className="menteeGoal__Subtitle"
                    style={{ textAlign: "start" }}
                  >
                    <h2
                      className="text-center"
                      style={{ whiteSpace: "normal" }}
                    >
                      Find the Best Mentors for You: Simple Steps , Guaranteed
                      Relevance and Customized Matches
                    </h2>
                  </div>
                </div>
                <div className="activation__content">
                  <div className="activation_main_content">
                    <div
                      style={{
                        transition:
                          "opacity 400ms ease 0s, transform 400ms ease 0s",
                        transform: "none",
                        opacity: 1,
                      }}
                      className={activeTab === 1 ? "d-none " : "d-block "}
                      onClick={() => handleTabClick(1)}
                    >
                      <a className="activation__content__accordion">
                        <h3 className=" SetGoal_Question text-truncate mr-3">
                          What's your top goal right now?
                        </h3>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-caret-right-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  {activeTab === 1 && (
                    <GaolQuestion
                      handleContinue={handleContinue}
                      setmenteeGoalData={setmenteeGoalData}
                      menteeGoalData={menteeGoalData}
                      handleBack={handleBack}
                    />
                  )}

                  <div className="activation_main_content">
                    <div
                      style={{
                        transition:
                          "opacity 400ms ease 0s, transform 400ms ease 0s",
                        transform: "none",
                        opacity: 1,
                      }}
                      className={
                        activeTab === 2 || activeTab === 1
                          ? "d-none"
                          : "d-block"
                      }
                      onClick={() => handleTabClick(2)}
                    >
                      <a className="activation__content__accordion">
                        <h3 className=" SetGoal_Question text-truncate mr-3">
                          Who is a relevant mentor for you?
                        </h3>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-caret-right-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  {activeTab === 2 && (
                    <ReleventMentor
                      handleContinue={handleContinue}
                      handleBack={handleBack}
                      setmenteeGoalData={setmenteeGoalData}
                      menteeGoalData={menteeGoalData}
                    />
                  )}

                  <div className="activation_main_content">
                    <div
                      style={{
                        transition:
                          "opacity 400ms ease 0s, transform 400ms ease 0s",
                        transform: "none",
                        opacity: 1,
                      }}
                      className={
                        activeTab === 3 || activeTab === 1 || activeTab === 2
                          ? "d-none"
                          : "d-block"
                      }
                      onClick={() => handleTabClick(3)}
                    >
                      <a className="activation__content__accordion">
                        <h3 className="SetGoal_Question text-truncate mr-3">
                          Message for your mentors
                        </h3>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-caret-right-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  {activeTab === 3 && (
                    <MessageMentor
                      handleContinue={handleContinue}
                      handleBack={handleBack}
                      handleSubmitGoal={handleSubmitGoal}
                      setmenteeGoalData={setmenteeGoalData}
                      menteeGoalData={menteeGoalData}
                    />
                  )}
                  {/* {activeMatch && (
                    <div>
                      <div className="Mentee___matches">
                        <h3 className="SetGoal_Question mb-3 mb-lg-24">
                          Here are the best matches for you.
                        </h3>
                        <div className=" SetGoal_match d-flex align align-items-center justify-content-between flex-wrap flex-md-nowrap">
                          <div className="header__info">
                            <p>
                              Based on your preferences, here are the top
                              mentors that matches you. We recommend
                              <span className="teal-text">
                                scheduling a mentoring session with one of them
                              </span>
                              .
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="container Session__list_main">
                        {isLoading ? (
                          <div className="justify-content-center d-flex mt-5">
                            <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                          </div>
                        ) : similarMentorsList.length > 0 ? (
                          similarMentorsList.slice(0, 3).map((mentor) => (
                            <div
                              className=" mentor___list pt-3"
                              onClick={() => viewProfile(mentor)}
                            >
                              <div className=" mentr_list__box cursor-pointer">
                                <div className="mentor-avatar overflow-hidden">
                                  <div className="image-dimmer" />
                                  {mentor?.profile_photo &&
                                  mentor?.profile_photo != "" ? (
                                    <img
                                      src={`${process.env.REACT_APP_IMAGES_BASE_URL}${mentor?.profile_photo}`}
                                      alt=""
                                    />
                                  ) : mentor?.gender == "female" ? (
                                    <img
                                      alt="icon"
                                      src={femaleAvatar}
                                      style={{ width: "17rem" }}
                                    />
                                  ) : (
                                    <img alt="icon" src={maleAvatar} />
                                  )}
                                </div>
                                <div className=" name___box " width={1920}>
                                  <p className="preview__content__name d-flex align-items-center text-truncate">
                                    <span className="black-text font-weight-600 text-truncate">
                                      {mentor.name}
                                    </span>
                                  </p>
                                  <div className="preview__content__job grey-1-text">
                                    <div className="item-list">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-briefcase"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                      </svg>
                                      <p className=" mentor___feature ml-2">
                                        <span>{mentor.title}</span>
                                        <span className="grey-2-text">at</span>
                                        <span>{mentor.company_or_school}</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <p
                                      className=" mentor___feature grey-1-text"
                                      style={{ marginLeft: "11px" }}
                                    >
                                      <span className="grey-2-text">
                                        {mentor.country}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="preview__content__metadata grey-4-bg expertise-p3 mt-auto d-flex justify-content-between">
                                  <div>
                                    <p className="sc-jsMahE dZPGPc grey-2-text">
                                      Experience
                                    </p>
                                    <p className=" mentor___feature grey-1-text font-weight-600">
                                      {mentor.experience}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="sc-jsMahE dZPGPc grey-2-text">
                                      Price Per Session
                                    </p>
                                    <p className=" mentor___feature grey-1-text font-weight-600">
                                      {countryname === "India"
                                        ? "₹" + mentor?.inr_price
                                        : "$" + mentor?.price_per_session}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div class=" Add_____notes mb-3 p-4 text-center inner-title">
                            <h2>No similar mentors</h2>
                          </div>
                        )}
                      </div>
                    </div>
                  )} */}
                </div>
                <div>
                  {/* <div className="menteegoal_title">
                    <h3>Your Goal </h3>
                  </div>
                  {GoalLoading ? (
                    <div className="justify-content-center d-flex mt-5">
                      <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                    </div>
                  ) : myGoalData && Object.keys(myGoalData).length > 0 ? (
                    <>
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              {myGoalData?.goal || "Your Goal"}
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <div>
                                <p>
                                  Countery like mentor:
                                  {myGoalData?.county_like_mentor?.map(
                                    (Countery) => Countery
                                  )}
                                </p>
                                <p>
                                  Expertise:
                                  {myGoalData?.experties?.map(
                                    (expertise) => expertise
                                  )}
                                </p>
                                <p>
                                  Specific skills:
                                  {myGoalData?.specific_skills?.map(
                                    (skills) => skills
                                  )}
                                </p>
                                <p>
                                  Specific tools:
                                  {myGoalData?.specific_tools?.map(
                                    (skills) => skills
                                  )}
                                </p>
                                <p style={{ whiteSpace: "normal" }}>
                                  Message: {myGoalData?.message_for_mentor}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div class=" Add_____notes mb-3 p-4 text-center inner-title">
                      <h2>No Goal Data</h2>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </section>
    </>
  );
};

export default MenteeGoal;
