import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_ID } from "../auth/tokenProvider";
import { getUserDetails } from "../../redux/actions/login";
import { TailSpin } from "react-loader-spinner";
import MenteeDashBoard from "./DashBoard";

const MenteeAdminDashboard = () => {
  const dispatch = useDispatch();
  const user_id = REACT_USER_ID;
  useEffect(() => {
    dispatch(getUserDetails(user_id));
  }, []);

  const isLoading = useSelector((state) => state.userDetailsReducer?.loading);

  const userDetailsReducerData = useSelector(
    (state) => state?.userDetailsReducer?.data
  );
  const userDetails = userDetailsReducerData[0];
  console.log("userDetails", userDetails);
  return (
    <>
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div className="Search___main_container">
          <MenteeDashBoard userDetails={userDetails} />
        </div>
      )}
    </>
  );
};

export default MenteeAdminDashboard;
