import React, { useState, useEffect, useRef, Fragment } from "react";
import Footer from "../../footer";
import Header from "../../header";
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";
import { TermAndCondtionSeo } from "../../Seo/data";
import SeoHelmet from "../../Seo/Helmet/SeoHelmet";

const Terms = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_USER_ID") ? true : false
  );

  return (
    <>
      <SeoHelmet metaData={TermAndCondtionSeo} />

      {loggedIn ? (
        <div style={{ marginTop: "0" }}>
          <ProtectedHeader extraClasses="inner-header" />
        </div>
      ) : (
        <Header extraClasses="inner-header" />
      )}

      <section className="Terms">
        <div className="container">
          <div className="terms_content">
            <div className="Terms_heading">
              <h1>Terms and Conditions</h1>
              <div className="Terms__paragraph">
                <p>
                  <span className="welcome">
                    Welcome! to the Mentorpal community{" "}
                  </span>{" "}
                  dear users, mentees, mentors, and visitors, thanks for being
                  here, if you are reading the terms and conditions section of
                  our content it means you are already a Mentorpal subscriber or
                  somewhat interested in Mentorpal, our purpose is to provide
                  you excellent mentoring services.
                </p>
                <p>
                  The ensuing terms and conditions have the charge for all use
                  of the Mentorpal.ai website and all services, products, and
                  services available at or through the website. The Website is
                  owned and operated by Mentorpal.ai. The Website is offered
                  subject to your acceptance without change of all of the terms
                  and conditions contained herein and all other functional
                  rules, policies, and procedures that may be published from
                  time to time on this website by Mentorpal.ai.
                </p>

                <p>
                  Please read this compliance carefully before using
                  Mentorpal.ai's Website. By using any part of the website, you
                  agree to become circumscribed by the terms and conditions of
                  this compliance. If you do not agree to all the terms and
                  conditions of this compliance, then you may not use the
                  Website or use any of its services. If these terms and
                  conditions are considered an offer by Mentorpal.ai, acceptance
                  is expressly limited to these terms. The Mentorpal.ai Website
                  is available only to individuals who are at least 18 years
                  old. You are requested, If you do not agree with the terms and
                  conditions, please don’t use the Mentorpal.ai website and its
                  services. once you access the content of Mentorpal.ai’s
                  website, it is understood that you have accepted the terms and
                  conditions of the Mentopal.ai website.
                </p>
                <div className="heading_item">
                  <h3>1. Delivery of Service</h3>
                  <div className="terms_item_paragraph"></div>
                  <p>
                    Our website is a platform where mentees are able to connect
                    directly with expert mentors. Users are able to visit our
                    website but if you wish to use our services you must
                    register with us.
                  </p>
                </div>
                <div className="heading_item">
                  <h3> 2. Disputes and Compensation</h3>
                  <div className="terms_item_paragraph">
                    <p>
                      You should set up that if someone were to give out to your
                      website or its products/services, it would get handled by
                      the proper courts or arbitration.Abstract that you have a
                      right to and will use the appropriate legal right.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="terms_content">
            <div className="Terms_heading">
              <h2>
                {" "}
                You should read the following statement before using the
                Mentorpal.ai services and products:
              </h2>
              <div className="terms_content">
                <div className="heading_item">
                  {" "}
                  <h3>1. Your Mentorpal.ai Account.</h3>
                </div>
                <div className="terms_item_paragraph">
                  {" "}
                  <p>
                    If you create a mentoring account on the Mentorpal.ai
                    Website or use any kind of service you are responsible for
                    maintaining the security of your account and blog, and
                    services.You must not describe or assign any keywords to
                    your account in a misleading or unlawful manner, including
                    in a manner intended to trade on the name or reputation of
                    Mentropal.ai, and may change or remove any description or
                    keyword that it considers improper or unlawful. You must
                    immediately notify Mentorpal.ai of any unauthorized uses of
                    your blog, product, or service. Mentorpal will not be liable
                    for any acts You, including breach of any damages of any
                    kind as a result of such acts or breach.
                  </p>
                </div>
              </div>
              <div className="terms_content">
                <div className="heading_item">
                  {" "}
                  <h3>2. Other websites and contributors.</h3>
                </div>
                <div className="terms_item_paragraph">
                  {" "}
                  <p>
                    No one can use content, blogs, product, and copy of any
                    services for any other website without the prior permission
                    of Mentorpal.ai otherwise it will be a legal matter.
                  </p>
                </div>
              </div>
              <div className="terms_content">
                <div className="heading_item">
                  {" "}
                  <h3> 3. Responsibility of website visitors and users.</h3>
                </div>
                <div className="terms_item_paragraph">
                  {" "}
                  <p>
                    You would be responsible while using the Mentorpal.ai
                    website or downloading visitors' content on the website for
                    any kind of viruses, Trozen horses, worms, and harmful
                    content. you are responsible for taking precautions as
                    necessary to protect yourself and your computer.
                  </p>
                </div>
              </div>
              <div className="terms_content">
                <div className="heading_item">
                  {" "}
                  <h3> 4. Copyright Infringement Policy.</h3>
                </div>
                <div className="terms_item_paragraph">
                  {" "}
                  <p>
                    As Mentorpal requests others to respect its intellectual
                    property rights, it respects the intellectual property
                    rights of others. If you believe that material published on
                    or linked to by Mentorpal.ai contravene your copyright, you
                    are encouraged to notify Mentorpal.ai in accordance with
                    Mentorpal’s Policy. Mentorpal will respond to all such
                    information, including as appropriate by removing the
                    infringing material or disabling all links to the infringing
                    material. Mentorpal will terminate a visitor’s access to and
                    use of the Website if, under appropriate circumstances, the
                    visitor is determined to be a repeat infringer of the
                    copyrights or other intellectual property rights of
                    Mentorpal. In the case of such termination, Mentorpal will
                    have no duty to provide a refund of any amounts previously
                    paid to Mentorpal.
                  </p>
                </div>
              </div>
              <div className="terms_content">
                <div className="heading_item">
                  {" "}
                  <h3> 5. Intellectual Property.</h3>
                </div>
                <div className="terms_item_paragraph">
                  <p>
                    By purchasing a product or service from Mentorpal you should
                    pay to agree to a monthly, annual, or one-time subscription
                    fee. fees will not be refunded. Renewal of any kind of
                    service or product would be your responsibility. You should
                    pay fees for any product and service timely otherwise
                    service would be blocked.
                  </p>
                </div>
              </div>
              <div className="terms_content">
                <div className="heading_item">
                  {" "}
                  <h3>6. Payment and Renewal.</h3>
                </div>
                <div className="terms_item_paragraph">
                  {" "}
                  <p>
                    {" "}
                    At the time of creating the Mentorpal.ai website, its
                    content or product we were doing our best to provide the
                    right information and create it different from other content
                    and websites. If found similar or the same it would be a
                    coincidence.
                  </p>
                </div>
              </div>
              <div className="terms_content">
                <div className="heading_item">
                  {" "}
                  <h3>7. Misllenious.</h3>
                </div>
                <div className="terms_item_paragraph">
                  <p>
                    {" "}
                    These terms and conditions comprise the agreement between
                    Mentorpal and you and only authorized administrators of
                    Mentorpal may make amendments or revise the agreement.
                    Termination of entry.Mentorpal may terminate your entry to
                    all or any part of the Website at any time, with or without
                    prior information, or notice, effective immediately. If you
                    desire to terminate this Agreement or your Mentorpal.ai
                    account, you may simply cease using the Website. If you have
                    a paid service account your account may terminate if you
                    breach the terms and condition agreement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </body> */}
      <Footer />
    </>
  );
};
export default Terms;
