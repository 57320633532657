import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { BsDatabaseAdd } from "react-icons/bs";
import { CgSoftwareDownload } from "react-icons/cg";
import {
  MdContentPaste,
  MdOutlineProductionQuantityLimits,
  MdProductionQuantityLimits,
} from "react-icons/md";
import { SiDesignernews, SiMarketo } from "react-icons/si";
import { ColorRing } from "react-loader-spinner";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; // Import default styles
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { innerBg } from "../../../assets/images/export-images";
import maleAvatar from "../../../assets/images/male.png";
import {
  findMentorAction,
  viewMentorProfileAction,
} from "../../../redux/actions/mentor";
import BreadCrumb from "../../Breadcrumb";
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";
import SeoHelmet from "../../Seo/Helmet/SeoHelmet";
import {
  FindAMentorSeo,
  FindAMentorSeoContentWriting,
  FindAMentorSeoDataScience,
  FindAMentorSeoDesign,
  FindAMentorSeoMarketing,
  FindAMentorSeoProductManagement,
  FindAMentorSeoProductResearch,
  FindAMentorSeoSoftwareDevelopment,
  describeSkill,
} from "../../Seo/data";
import FindMentorNoResult from "../../findMentor-noResult";
import Footer from "../../footer/index";
import Header from "../../header";
import femaleAvatar from "./FemaileDefualtNew.jpg";
import FilterModal from "./FilterModal";
import "./findMentor.css";

const LiveSessionList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { urlExprtise } = useParams();

  let content = describeSkill[urlExprtise] || {};

  console.log(urlExprtise);

  Modal.setAppElement(document.getElementById("root"));
  // these styles are for react modals
  const [filterCount, setfilterCount] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const loggedIn = localStorage.getItem("REACT_TOKEN_AUTH");
  let date = new Date();
  let datestring = date.toTimeString();
  let countryname =
    datestring?.includes("india") | datestring?.includes("India")
      ? "India"
      : "";
  const [filterModal, setFilterModal] = useState({
    pro_bono: "No Filter",
    expertise: "No Filter",
    countries: "No Filter",
    experience: "No Filter",
    languages: "No Filter",
  });

  const [search, setSearch] = useState("content-writing");
  const [SeoDataName, setSeoDataName] = useState(FindAMentorSeo);
  const [mentors, setmentors] = useState([]);
  const [expertise, setExpertise] = useState("content-writing");
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [requestInfo, setRequestInfo] = useState({
    search: true, // if the search bar is pressed
    Pro_Bono: false,
    expertise: false,
    form: false,
  });
  const [Mentors, setMentors] = useState([]);
  let totalMentors = 0;
  const MENTOR_PAGE_SIZE = 12;

  // this function checks whether the two arrays are equal or not
  function CheckEqual(arr1, arr2) {
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false; // Elements don't match, arrays are not equal
      }
    }

    return true; // All elements match, arrays are equal
  }
  const setRequest = (val) => {
    let newRequestInfo = {
      Pro_Bono: false,
      search: false,
      expertise: false,
      form: false,
    };
    newRequestInfo = { ...newRequestInfo, [val]: true };

    setRequestInfo(newRequestInfo);
  };
  const FilterOptions = [
    {
      icon: <AiOutlineHeart className="carousel_item_icon" />,
      value: "Pro Bono",
      active: false,
    },
    {
      icon: <MdContentPaste className="carousel_item_icon" />,
      value: "Content Writing",
      path: "/find-mentor/content-writing",
      active: false,
    },
    {
      icon: <BsDatabaseAdd className="carousel_item_icon" />,
      value: "Data Science",
      path: "/find-mentor/data-science",
      active: false,
    },

    {
      icon: <SiDesignernews className="carousel_item_icon" />,
      value: "Design",
      path: "/find-mentor/design",
      active: false,
    },
    {
      icon: <SiMarketo className="carousel_item_icon" />,
      value: "Marketing",
      path: "/find-mentor/marketing",
      active: false,
    },
    {
      icon: (
        <MdOutlineProductionQuantityLimits className="carousel_item_icon" />
      ),
      value: "Product Management",
      path: "/find-mentor/product-management",
      active: false,
    },
    {
      icon: <MdProductionQuantityLimits className="carousel_item_icon" />,
      value: "Product Research",
      path: "/find-mentor/product-research",
      active: false,
    },
    {
      icon: <CgSoftwareDownload className="carousel_item_icon" />,
      value: "Software Development",
      path: "/find-mentor/software-development",
      active: false,
    },
  ];
  const [filters, setFilters] = useState(FilterOptions);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 992 },
      items: 8,
    },

    ipad: {
      breakpoint: { max: 992, min: 768 },
      items: 7,
    },

    tablet1: {
      breakpoint: { max: 768, min: 465 },
      items: 6,
    },

    mobile: {
      breakpoint: { max: 465, min: 0 },
      items: 4,
    },
  };

  // this will disable all the filter'ss selected css
  const disableAllFilters = () => {
    const newFilters = [...filters];
    newFilters.forEach((filter) => (filter.active = false));
    setFilters(() => newFilters);
  };

  const handleClick = (idx, path) => {
    const newFilters = [...filters];
    if (newFilters[idx].active) {
      newFilters[idx].active = false;
      findMentors(search, 1);
      setMentors([]);
    } else {
      newFilters.forEach((filter) => (filter.active = false));
      newFilters[idx].active = true;
      if (newFilters[idx].value !== "Pro Bono") {
        setRequest("expertise");

        findMentorsByExpertise(newFilters[idx].value, 1);
      } else {
        setRequest("Pro_Bono");
        findProBonoMentors(1);
      }
      setMentors([]);
    }
    navigate(path);
    setFilters(() => newFilters);
  };

  useEffect(() => {
    if (requestInfo.search) {
      disableAllFilters();
    }
  }, [requestInfo]);

  useEffect(() => {
    const expertise = location.state?.expertise;
    FilterOptions.forEach((filter, idx) => {
      if (filter.value === expertise) {
        const newFilters = [...FilterOptions];
        newFilters[idx].active = true;
        setFilters(() => newFilters);
      }
    });

    if (expertise) {
      findMentorsByExpertise(expertise, 1);
      setRequest("expertise");
      setMentors([]);
    } else setRequest("search");
  }, [location]);

  useEffect(() => {
    totalMentors = 0;
    setmentors([]);
    setExpertise("");
    setPageNumbers([]);
    setIsFilterApplied(false);
  }, [isLoading]);

  const findMentors = (expertise, pageNumber) => {
    const data = {
      pageSize: MENTOR_PAGE_SIZE,
      pageNo: pageNumber,
      experties: expertise,
    };
    dispatch(findMentorAction(data));
    setfilterCount(0);
  };

  const findMentorsByExpertise = (expertise, pageNumber) => {
    const data = {
      pageSize: MENTOR_PAGE_SIZE,
      pageNo: pageNumber,
      experties: expertise,
    };
    dispatch(findMentorAction(data));
    setfilterCount(0);
  };

  const findProBonoMentors = (pageNumber) => {
    const data = {
      pageSize: MENTOR_PAGE_SIZE,
      pageNo: pageNumber,
      is_pro_bono: "yes",
    };
    dispatch(findMentorAction(data));
    setfilterCount(0);
  };

  const findFilterModalMentors = (pageNumber) => {
    const { pro_bono, languages, countries, experience, expertise } = {
      ...filterModal,
    };

    let data = {
      pageSize: MENTOR_PAGE_SIZE,
      pageNo: pageNumber,
    };
    if (pro_bono !== "No Filter") data = { ...data, is_pro_bono: pro_bono };

    if (expertise !== "No Filter") data = { ...data, experties: expertise };

    if (countries !== "No Filter") data = { ...data, country: countries };

    if (experience !== "No Filter") data = { ...data, experience: experience };

    if (languages !== "No Filter") data = { ...data, skills: languages };

    dispatch(findMentorAction(data));
  };
  const resetFilterModal = () => {
    setFilterModal({
      countries: "No Filter",
      experience: "No Filter",
      pro_bono: "No Filter",
      languages: "No Filter",
      expertise: "No Filter",
    });
    setfilterCount(0);
  };

  const handleFilterModalSubmit = () => {
    const { pro_bono, languages, countries, experience, expertise } = {
      ...filterModal,
    };

    setfilterCount(0);
    if (pro_bono !== "No Filter") setfilterCount((prev) => prev + 1);
    if (languages !== "No Filter") setfilterCount((prev) => prev + 1);
    if (countries !== "No Filter") setfilterCount((prev) => prev + 1);
    if (experience !== "No Filter") setfilterCount((prev) => prev + 1);
    if (expertise !== "No Filter") setfilterCount((prev) => prev + 1);
    if (
      pro_bono === "No Filter" &&
      expertise === "No Filter" &&
      countries === "No Filter" &&
      experience === "No Filter" &&
      languages === "No Filter"
    ) {
      if (requestInfo.form) {
        setRequest("search");
        findMentors(search, 1);
        setMentors([]);
      }

      closeModal();
      return;
    }
    disableAllFilters();
    setRequest("form");
    findFilterModalMentors(1);
    setMentors([]);
    closeModal();
  };

  useSelector((state) => {
    const request = state.findMentorReducer?.request;
    const data = state.findMentorReducer?.data;
    if (request === undefined) {
      if (urlExprtise) {
        if (urlExprtise) {
          const expertiseMap = {
            "content-writing": {
              name: "Content+Writing",
              value: FindAMentorSeoContentWriting,
            },
            "data-science": {
              name: "Data+Science",
              value: FindAMentorSeoDataScience,
            },
            design: { name: "Design", value: FindAMentorSeoDesign },
            marketing: { name: "Marketing", value: FindAMentorSeoMarketing },
            "product-management": {
              name: "Product+Management",
              value: FindAMentorSeoProductManagement,
            },
            "product-research": {
              name: "Product+Research",
              value: FindAMentorSeoProductResearch,
            },
            "software-development": {
              name: "Software+Development",
              value: FindAMentorSeoSoftwareDevelopment,
            },
          };

          const expertiseInfo = expertiseMap[urlExprtise] || {
            name: "top",
            value: FindAMentorSeo,
          };
          const { name, value } = expertiseInfo;

          findMentors(name, 1);
          setSeoDataName(value);
        }
      } else {
        findMentors("top", 1);
        console.log("by default working");
      }

      return;
    }

    if (
      (request?.skills !== "" ||
        request?.experties !== "" ||
        request?.is_pro_bono !== "" ||
        request?.country !== "" ||
        request?.language !== "" ||
        request?.experience !== "") &&
      expertise === ""
    ) {
      if (requestInfo.form) setExpertise("Custom  Filters");
      else if (request?.skills !== undefined && request?.skills !== "")
        setExpertise(request?.skills);
      else if (request?.experties !== undefined && request?.experties !== "") {
        setExpertise(request?.experties);
        setIsFilterApplied(true);
      } else if (
        request?.is_pro_bono !== undefined &&
        request?.is_pro_bono !== ""
      ) {
        setExpertise("Pro Bono");
        setIsFilterApplied(true);
      }

      setCurrentPage(request?.pageNo);
    }

    if (state.findMentorReducer?.loading && !isLoading) setIsLoading(true);
    if (!state.findMentorReducer?.loading) {
      if (
        data !== undefined &&
        data.length !== 0 &&
        data[0].pagination !== undefined &&
        data[0].pagination.length !== 0
      )
        totalMentors = data[0].pagination[0].totalCount;

      const pages =
        Math.floor(totalMentors / request?.pageSize) +
        (totalMentors % request?.pageSize ? 1 : 0);

      if (isLoading) setIsLoading(false);

      if (totalMentors !== 0 && mentors.length === 0) {
        if (data !== undefined && data.length !== 0) {
          setmentors(() => data[0].data);
          if (Mentors.length < data[0].data.length)
            setMentors((prev) => [...prev, ...data[0].data]);
          else if (
            CheckEqual(
              Mentors.slice(Mentors.length - data[0].data.length),
              data[0].data
            ) === false
          )
            setMentors((prev) => [...prev, ...data[0].data]);
        }
        for (let i = 1; i <= pages; i++) {
          setPageNumbers((pageNumbers) => [...pageNumbers, i]);
        }
      }
    }
  });

  const pageClick = (page) => {
    if (page === 0 || page > pageNumbers.length) return;
    if (requestInfo.search) findMentors(expertise, page);
    else if (requestInfo.Pro_Bono) findProBonoMentors(page);
    else if (requestInfo.expertise) {
      let expert = "";
      filters.forEach((filter) => {
        if (filter.active) expert = filter.value;
      });
      findMentorsByExpertise(expert, page);
    } else {
      findFilterModalMentors(page);
    }
  };

  // To convert all names with proper casing
  function convertString(name) {
    const arr = name.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  }

  const viewProfile = (mentor) => {
    let user = mentor.name.split(" ").join("") + "_" + mentor.user_id;
    dispatch(viewMentorProfileAction(mentor.user_id));
    navigate("/mentor-details/" + user);
  };
  return (
    <>
      <SeoHelmet metaData={SeoDataName} />

      {loggedIn ? <ProtectedHeader /> : <Header />}
      {urlExprtise ? (
        <>
          <section
            className="banner-sec inner-banner pb-0"
            style={{
              backgroundImage: `url(${innerBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></section>
          <BreadCrumb />
          <section
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.06)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="container">
              <div className="mentroship-sec" style={{ minWidth: "100%" }}>
                <div className="describe-category">
                  <h1>{content?.title || "find mentors"}</h1>
                  <div style={{ marginTop: "30px" }}>
                    {content?.description?.map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </div>
                </div>
                <div className="wrapper-content">
                  <div>
                    <span>Find your mentor</span>
                    <img src={require("../../../assets/images/searhBar.png")} />
                  </div>
                  <div>
                    <span>Apply for Mentorship</span>
                    <img src={require("../../../assets/images/person.png")} />
                  </div>
                  <div>
                    <span>Levelup your skills</span>
                    <img
                      src={require("../../../assets/images/personlist.png")}
                    />
                  </div>
                </div>
              </div>

              {/* FILTER OPTION STARTS */}

              {/* FILTER OPTION ENDS */}
            </div>
          </section>
          <div style={{ height: "55px", position: "relative", top: "50px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{ width: "100%", maxWidth: "650px" }}
                className="form-group input-group find-mentor-search"
              >
                <input
                  type="text"
                  name=""
                  style={{
                    width: "100%",
                    borderColor: "rgba(212, 41, 201, 1)",
                  }}
                  className="form-control"
                  placeholder={
                    (isFilterApplied ? "Expertise: " : "Skills: ") + expertise
                  }
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      setRequest("search");
                      findMentors(search, 1);
                      setMentors([]);
                    }
                  }}
                  onKeyUp={(e) => {
                    const a = debounce(function () {
                      setRequest("search");
                      if (e.target.value !== "") findMentors(e.target.value, 1);
                      else findMentors("top", 1);
                      setMentors([]);
                    }, 500);
                    a();
                  }}
                />
              </div>
              <div>
                <button
                  style={{ marginLeft: "-25px", marginTop: "0px" }}
                  className="form-control filter-btn notification"
                  data-attr={filterCount === 0 ? "" : `(${filterCount})`}
                  value="Filters"
                  onClick={openModal}
                >
                  Filters
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <section
            className="banner-sec inner-banner pb-0"
            style={{
              backgroundImage: `url(${innerBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container">
              <div className="mentroship-sec">
                <h1
                  style={{ fontSize: "40px" }}
                  className="font-size-28 font-weight-600 mb-4 text-white"
                >
                  Mentors available for {expertise}
                </h1>
                <div
                  className="form-group  input-group find-mentor-search"
                  style={{ height: "20px", position: "relative" }}
                >
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder={
                      (isFilterApplied ? "Expertise: " : "Skills: ") + expertise
                    }
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setRequest("search");
                        findMentors(search, 1);
                        setMentors([]);
                      }
                    }}
                    onKeyUp={(e) => {
                      const a = debounce(function () {
                        setRequest("search");
                        if (e.target.value !== "")
                          findMentors(e.target.value, 1);
                        else findMentors("top", 1);
                        setMentors([]);
                      }, 500);
                      a();
                    }}
                  />

                  <button
                    className="form-control submit-btn notification"
                    data-attr={filterCount === 0 ? "" : `(${filterCount})`}
                    value="Filters"
                    onClick={openModal}
                  >
                    Filters
                  </button>
                </div>
              </div>

              {/* FILTER OPTION STARTS */}

              <Carousel
                className=" pt-5 pb-2 mb-0 parent_carousel "
                responsive={responsive}
                renderButtonGroupOutside={true}
              >
                {filters.map((filter, idx) => (
                  <div
                    className="carousel-filter-item"
                    onClick={() => handleClick(idx, filter.path)}
                  >
                    {filter.icon}
                    {filter.active ? (
                      <div
                        className="carousel_text fw-bold"
                        style={{ color: "black" }}
                      >
                        {filter.value}
                      </div>
                    ) : (
                      <div>{filter.value}</div>
                    )}
                  </div>
                ))}
              </Carousel>

              {/* FILTER OPTION ENDS */}
            </div>
          </section>
          <BreadCrumb background="none" />
        </>
      )}

      {isLoading === true || totalMentors !== 0 ? (
        <section
          className="top_mentor_list"
          style={{ position: "relative", top: "100px" }}
        >
          <div className="container Session__list_main">
            {
              Mentors.map((mentor, id) => (
                <div
                  key={id}
                  className=" mentor___list pt-3"
                  onClick={() => viewProfile(mentor)}
                  style={{ zIndex: 0 }}
                >
                  <div className=" mentr_list__box cursor-pointer">
                    <div className="mentor-avatar overflow-hidden">
                      <div className="image-dimmer" />
                      {mentor?.profile_photo && mentor?.profile_photo != "" ? (
                        <img
                          src={`${process.env.REACT_APP_IMAGES_BASE_URL}${mentor?.profile_photo}`}
                          alt={mentor?.name}
                        />
                      ) : mentor?.gender === "female" ? (
                        <img
                          alt="femaleAvatar"
                          src={femaleAvatar}
                          style={{ width: "17rem" }}
                        />
                      ) : (
                        <img alt="maleAvatar" src={maleAvatar} />
                      )}
                    </div>
                    <div className=" name___box " width={1920}>
                      <p className="preview__content__name d-flex align-items-center text-truncate">
                        <span className="black-text font-weight-600 text-truncate">
                          {convertString(mentor.name)}
                        </span>
                      </p>
                      <div className="preview__content__job grey-1-text">
                        <div className="item-list">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-briefcase"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                          </svg>
                          <p className=" mentor___feature ml-2">
                            <span>{mentor.title}</span>{" "}
                            <span className="grey-2-text">at</span>{" "}
                            <span>{mentor.company_or_school}</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p
                          className=" mentor___feature grey-1-text"
                          style={{ marginLeft: "11px" }}
                        >
                          <span className="grey-2-text">{mentor.country}</span>
                        </p>
                      </div>
                    </div>
                    <div className="preview__content__metadata grey-4-bg expertise-p3 mt-auto d-flex justify-content-between">
                      <div>
                        <p className="sc-jsMahE dZPGPc grey-2-text">
                          Experience
                        </p>
                        <p className=" mentor___feature grey-1-text font-weight-600">
                          {mentor.experience}
                        </p>
                      </div>
                      <div>
                        <p className="sc-jsMahE dZPGPc grey-2-text">
                          Price Per Session
                        </p>
                        <p className=" mentor___feature grey-1-text font-weight-600">
                          {mentor?.is_pro_bono === "yes"
                            ? "Pro Bono"
                            : countryname === "India"
                            ? "₹" + mentor?.inr_price
                            : "$" + mentor?.price_per_session}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
              // )
            }
          </div>
          <div
            className={currentPage === pageNumbers.length ? "disable" : ""}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading === false ? (
              <div className="get-started-cls mt-2 mb-3">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLoading(true);
                    pageClick(currentPage + 1);
                  }}
                  type="button"
                  className="btn btn-primary"
                >
                  Load More ...
                </button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              </div>
            )}
          </div>
        </section>
      ) : (
        <FindMentorNoResult />
      )}

      <Footer />

      <FilterModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        closeModal={closeModal}
        filterModal={filterModal}
        setFilterModal={setFilterModal}
        resetFilterModal={resetFilterModal}
        handleFilterModalSubmit={handleFilterModalSubmit}
        FilterOptions={FilterOptions}
        setfilterCount={setfilterCount}
      />
    </>
  );
};

export default LiveSessionList;
