import React, { useState } from 'react';
import { Button, Drawer, Dropdown, Menu, Space, Typography } from 'antd';
import { convertTimeRangeToLocal, convertUTCtoDate } from '../../../Data/Data';
import { Edit, EditOutlined } from '@mui/icons-material';
import RescheduleBooking from './RescheduleBooking';
import CanccelBooking from './CancelBooking';

const BookingDetail = ({ DetailModal, setDetailModal, ModalData }) => {


    const onClose = () => {
        setDetailModal(false);
    };
    const isMobile = () => {
        // Check if the userAgent contains any keywords indicating a mobile device
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    const [ReschedulModal, setReschedulModal] = useState(false)
    const [CancelModal, setCancelModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState('3');
    const handleOpenChange = (open) => {
        console.log('Dropdown visibility changed:', open);
    };

    const handleItemClick = ({ key, data }) => {
        console.log('Selected key:', key);
        console.log('Selected data:', data);

        setSelectedItem(key);
        if (key === '1') {
            setReschedulModal(true)
        }
        else {
            setCancelModal(true)
        }
    };

    return (
        <>
            <Drawer
                title={null}
                placement={isMobile ? "right" : "bottom"}
                open={DetailModal}
                onClose={setDetailModal}
                width={600}
                closable={false}
                height={isMobile ? "" : "75%"}
                destroyOnClose
                className="call-details-drawer"
                styles={{ body: { padding: 0 } }}
            >
                <div className="ant-drawer-body" style={{ padding: 0 }}>
                    <div className="ant-row details-header ">
                        <div className="ant-col ant-col-xs-21 ant-col-md-21 ">
                            <span className="ant-typography details-title ">
                                Call with {ModalData?.consumer_name}
                            </span>
                        </div>
                        <div
                            className="ant-col ant-col-xs-3 ant-col-md-2 "
                            style={{ textAlign: "end" }}
                        >

                            <svg
                                onClick={() => {
                                    setDetailModal(false)
                                }}
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 512 512"
                                className="drawer-close-icon"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                            </svg>
                        </div>
                    </div>
                    <div className="ant-typography details-body ">
                        <div className="ant-row details-subhead ">
                            <div className="ant-col ant-col-xs-13 ant-col-md-16 ">
                                <div className="ant-typography service-name ">
                                    <span className="ant-typography ">For,</span>
                                    <span className="ant-typography service-name-main ">
                                        {ModalData?.service?.title}
                                    </span>
                                </div>
                            </div>
                            <div className="ant-col ">
                                <div className="ant-typography ">
                                    <div
                                        className="ant-typography "
                                        style={{
                                            backgroundColor: "rgb(236, 247, 241)",
                                            borderRadius: 28,
                                            textAlign: "center",
                                            display: "inline-flex"
                                        }}
                                    >
                                        <div
                                            className="ant-typography status-tag-text "
                                            style={{ color: "rgb(10, 68, 44)" }}
                                        >
                                            {ModalData?.service?.status}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ant-row ">
                            <div className="ant-col call-date ant-col-xs-9 ant-col-md-12 ">
                                <div className="ant-space  ant-space-horizontal ant-space-align-start ant-space-gap-row-small ant-space-gap-col-small">
                                    <div className="ant-space-item">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            height={20}
                                            width={20}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect x={3} y={4} width={18} height={18} rx={2} ry={2} />
                                            <line x1={16} y1={2} x2={16} y2={6} />
                                            <line x1={8} y1={2} x2={8} y2={6} />
                                            <line x1={3} y1={10} x2={21} y2={10} />
                                        </svg>
                                    </div>
                                    <div className="ant-space-item"> </div>
                                    <div className="ant-space-item">{convertUTCtoDate(ModalData?.time)} </div>
                                </div>
                            </div>
                            <div
                                className="ant-col call-date ant-col-xs-15 ant-col-md-12 "
                                style={{ borderLeft: 0 }}
                            >
                                <div className="ant-space  ant-space-horizontal ant-space-align-start ant-space-gap-row-small ant-space-gap-col-small">
                                    <div className="ant-space-item">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            height={20}
                                            width={20}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx={12} cy={12} r={10} />
                                            <polyline points="12 6 12 12 16 14" />
                                        </svg>
                                    </div>
                                    <div className="ant-space-item">{convertTimeRangeToLocal(ModalData?.time)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="ant-row action-btns ">
                            <div className="ant-col ant-col-14 ">
                                <button
                                    type="button"
                                    className="ant-btn  ant-btn-default ant-btn-block btn-dark"
                                    disabled=""
                                >
                                    <span>Join call</span>
                                </button>
                            </div>
                            <div className="ant-col ant-col-9 ant-col-offset-1 ">
                                <Dropdown
                                    className="ant-btn  ant-btn-default ant-btn-block ant-dropdown-trigger btn-edit"

                                    onOpenChange={handleOpenChange}
                                    overlay={
                                        <Menu
                                            items={[
                                                {
                                                    key: '1',
                                                    label: 'Reschedule',
                                                },
                                                {
                                                    key: '2',
                                                    label: 'Cancel',
                                                },
                                            ]}
                                            selectable
                                            defaultSelectedKeys={[selectedItem]}
                                            onClick={({ key }) => handleItemClick({ key })} />}
                                >
                                    <Typography.Link>
                                        <Space>
                                            <EditOutlined />
                                            Edit Booking
                                        </Space>
                                    </Typography.Link>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="ant-typography booking-review ">
                            <div className="ant-space  ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small ask-review-desc">
                                <div className="ant-space-item">
                                    <img
                                        alt="review"
                                        loading="lazy"
                                        width={56}
                                        height={20}
                                        decoding="async"
                                        data-nimg={1}
                                        className=""
                                        srcSet="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fholding-phone.b951db4f.svg&w=64&q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fholding-phone.b951db4f.svg&w=128&q=75 2x"
                                        src="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fholding-phone.b951db4f.svg&w=128&q=75"
                                        style={{ color: "transparent", height: "auto" }}
                                    />
                                </div>
                                <div className="ant-space-item">
                                    We gather feedback after the meeting. Encourage attendees to share
                                    their testimonials and ratings.
                                </div>
                            </div>
                        </div>
                        <div className="ant-typography details-attendee-title ">
                            Attendee details
                        </div>
                        <div className="ant-row ">
                            <div className="ant-col call-date ant-col-xs-24 ant-col-md-12 ">
                                <div className="ant-space  ant-space-horizontal ant-space-align-start ant-space-gap-row-small ant-space-gap-col-small">
                                    <div className="ant-space-item">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            height={20}
                                            width={20}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                            <polyline points="22,6 12,13 2,6" />
                                        </svg>
                                    </div>
                                    <div className="ant-space-item">codewithankit047@gmail.com</div>
                                </div>
                            </div>
                            <div className="ant-col call-date call-phone-details ant-col-xs-24 ant-col-md-12 ">
                                <div className="ant-space  ant-space-horizontal ant-space-align-start ant-space-gap-row-small ant-space-gap-col-small">
                                    <div className="ant-space-item">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            rotate={180}
                                            height={20}
                                            width={20}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                                        </svg>
                                    </div>
                                    <div className="ant-space-item">+918930395398</div>
                                </div>
                            </div>
                        </div>
                        <div className="ant-space  ant-space-vertical ant-space-gap-row-small ant-space-gap-col-small call-qna">
                            <div className="ant-space-item">
                                <div className="ant-space  ant-space-vertical ant-space-gap-row-small ant-space-gap-col-small">
                                    <div className="ant-space-item">
                                        <div className="ant-typography call-question ">
                                            What is the call about?
                                        </div>
                                    </div>
                                    <div className="ant-space-item">
                                        <div className="ant-typography call-answer ">
                                            Mentorship
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ant-space-item" />
                            <div className="ant-space-item" />
                        </div>
                    </div>
                </div>

            </Drawer>
            {
                ReschedulModal && (
                    <RescheduleBooking ReschedulModal={ReschedulModal} setReschedulModal={setReschedulModal} ModalData={ModalData} />
                )
            }
            {
                CancelModal && (
                    <CanccelBooking CancelModal={CancelModal} setCancelModal={setCancelModal} ModalData={ModalData} />
                )
            }
        </>
    );
}

export default BookingDetail;
