import React from 'react';
import { useState } from "react";

const CertificateBlackList = () => {
    const certificatedata = [
            {
                date : '9-09-2001',
                name : 'ankush',
                email : 'ankush@',
                serial : '44',
            },
            {
                date : '9-09-2002',
                name : 'ankush',
                email : 'ankush@',
                serial : '44',
            }
    ];

    const [certdata,setData] = useState(certificatedata);
    console.log(certdata);
        return (
            <div>
                <h2 style={{color:'black'}}>Design</h2>
                {certificatedata.length > 0 ? (
                <div className="card-body table-inner db-table">
                    <div className="table-responsive">
                    <table style={{ width: "100%" }}>
                        <thead>
                        <tr>
                            <th>DATE ISSUED</th>
                            <th>NAME</th>
                            <th>EMAIL</th>
                            <th>SERIAL NO.</th>
                            <th>DOWNLOAD</th>
                            <th>ACTIONS</th>
                        </tr>
                        </thead>
                        <tbody>
                        {certdata
                            .map((data) => (
                            <tr>
                                <td>{data.date}</td>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>{data.serial}</td>
                                <td><button type="button" className="btn btn-success">Download</button></td>
                                <td><button type="button" className="btn btn-success">Actions</button></td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                ) : (
                <div>
                    <div
                    style={{
                        "text-align": "center",
                        "padding-bottom": "1rem",
                    }}
                    >
                    <h2>NO DATA AVAILABE IN TABLE</h2>
                    </div>
                </div>
                )}
            </div>
        );
    }

export default CertificateBlackList;
