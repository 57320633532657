import React, { useState } from "react";
import { Drawer, Form, Input, Button, Upload, message, DatePicker, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { mpqTestDataAction } from './../../../redux/actions/common';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const ResultModal = ({ resultModal, setResultModal, user_id, answeredQuestions }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);


    const onFinish = (values) => {
        setLoading(true);
        // Simulating API call or other asynchronous operations
        setTimeout(() => {
            console.log("Form Data:", values);


            dispatch(mpqTestDataAction({ values, answeredQuestions, user_id }))
            setLoading(false);
            message.success("Form submitted successfully!");

            form.resetFields();
            setResultModal(false);
        }, 1000);
        navigate('result')
    };



    const onCloseDrawer = () => {
        form.resetFields();
        setResultModal(false);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // const uploadProps = {
    //     name: "file",
    //     action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    //     headers: {
    //         authorization: "authorization-text",
    //     },
    //     onChange(info) {
    //         if (info.file.status !== "uploading") {
    //             console.log(info.file, info.fileList);
    //         }
    //         if (info.file.status === "done") {
    //             message.success(`${info.file.name} file uploaded successfully`);
    //         } else if (info.file.status === "error") {
    //             message.error(`${info.file.name} file upload failed.`);
    //         }
    //     },
    // };

    return (
        <Drawer
            title="User Information"
            width={600}
            onClose={onCloseDrawer}
            visible={resultModal}
            placement="right"
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        { required: true, message: "Please enter your name" },
                        { max: 50, message: "Name must be at most 50 characters" },
                    ]}
                >
                    <Input style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: "Please enter your email" },
                        { type: "email", message: "Please enter a valid email address" },
                    ]}
                >
                    <Input style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item name="gender" label="Gender" rules={[{ required: true, message: "Please select your gender" }]}>
                    <Select style={{ width: "100%" }}>
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="dob"
                    label="Date of Birth"
                    rules={[{ required: true, message: "Please select your date of birth" }]}
                >
                    <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Phone Number"
                    rules={[
                        { required: true, message: "Please enter your phone number" },
                        { pattern: /^[0-9]*$/, message: "Please enter a valid phone number" },
                    ]}
                >
                    <Input style={{ width: "100%" }} />
                </Form.Item>

                {/* <Form.Item
                    name="profilePicture"
                    label="Profile Picture"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                </Form.Item> */}

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ResultModal;
