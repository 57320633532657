import React, { useEffect, useState } from 'react';

export const StepM2 = () => {
    const [expertiseselectedItem, setexpertiseSelectedItem] = useState(null);
    const expertiseData = [
        'Cybersecurity',
        'Law',
        'Content & Branding',
        'Others',
        'HR',
        'Software',
        'Product',
        'Study Abroad',
        'Finance',
        'Design',
        'Data',
        'Astrology',
        'Mental Health & Wellbeing',
        'Marketing'
    ];

    const handleItemClick = (item) => {
        setexpertiseSelectedItem(item === expertiseselectedItem ? null : item);
    };

    useEffect(() => {
        console.log('expertise Selected Item:', expertiseselectedItem);
        // You can perform additional actions with the selected item here
    }, [expertiseselectedItem]);

    const [selectedServices, setselectedServices] = useState(null);

    const servicesData = [
        'Quick chat',
        '1:1 Consultation',
        'Linkedin branding',
        'Contenct Creation',
        '1:1 Mentorship',
        'Doubt Session',
        'Discovery Call'
    ];

    const handleSelectOffer = (offer) => {
        setselectedServices(offer === selectedServices ? null : offer);
    };

    return (
        <>
            {/* <section className="container">
                <div className="step-hello">
                    <div className="h1">
                        <h5 className="onboarding-header">Let‘s add some services</h5>
                        <p className="onboarding-subheader">
                            We‘ll help you get set up based on your expertise
                        </p>
                    </div>
                </div>

                <div className="mt-4">
                    <label htmlFor="socialAccount" className="form-label onboardlabel">
                        Select your expertise
                    </label>
                    <div className="d-flex flex-wrap">
                        {expertiseData.map((expertise) => (
                            <div
                                key={expertise}
                                className={`step_use_item ${expertiseselectedItem === expertise ? 'selected' : ''
                                    }`}
                                onClick={() => handleItemClick(expertise)}
                            >
                                {expertise}
                            </div>
                        ))}
                    </div>
                    <div className="ant-col ant-form-item-control ">
                        <div className="ant-form-item-control-input">
                            <div className="ant-form-item-control-input-content">
                                <div
                                    aria-required="true"
                                    className="ant-radio-group ant-radio-group-outline radio-checkbox onboarding-checkbox "
                                    id="expertise"
                                >
                                    {expertiseData.map((expertise, index) => (
                                        <label
                                            key={index}
                                            className={`ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item  ${expertiseselectedItem === expertise ? 'ant-radio-button-wrapper-checked' : ''
                                                }`}
                                        >
                                            <span className={`ant-radio-button ${expertiseselectedItem === expertise ? 'ant-radio-button-checked' : ''}`}>
                                                <input
                                                    className="ant-radio-button-input"
                                                    type="radio"
                                                    value={expertise}
                                                    checked={expertiseselectedItem === expertise}
                                                    onChange={() => handleItemClick(expertise)}
                                                />
                                                <span className="ant-radio-button-inner" />
                                            </span>
                                            <span>{expertise}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="mt-4">
                    <label htmlFor="socialAccount" className="form-label onboardlabel">
                        What all do you plan to offer?
                    </label>
                    <div className="d-flex flex-wrap">
                        {servicesData.map((services) => (
                            <div
                                key={services}
                                className={`step_use_item ${selectedServices === services ? 'selected' : ''
                                    }`}
                                onClick={() => handleSelectOffer(services)}
                            >
                                {services}
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            
                                    <div
                                        className="ant-typography "
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <h5 className="ant-typography onboarding-header ">
                                            Let‘s add some services
                                        </h5>
                                    </div>
                                    <div className="ant-typography onboarding-subheader ">
                                        We‘ll help you get set up based on your expertise
                                    </div>
                                    <form
                                        className="ant-form ant-form-vertical ant-form-hide-required-mark "
                                        data-gtm-form-interact-id={1}
                                    >
                                        <div className="ant-form-item  ant-form-item-has-success">
                                            <div className="ant-row ant-form-item-row ">
                                                <div className="ant-col ant-form-item-label ">
                                                    <label
                                                        htmlFor="expertise"
                                                        className="ant-form-item-required"
                                                        title="Select your expertise"
                                                    >
                                                        Select your expertise
                                                    </label>
                                                </div>
                                                <div className="ant-col ant-form-item-control ">
                                                    <div className="ant-form-item-control-input">
                                                        <div className="ant-form-item-control-input-content">
                                                            <div
                                                                aria-required="true"
                                                                className="ant-radio-group ant-radio-group-outline radio-checkbox onboarding-checkbox "
                                                                id="expertise"
                                                            >
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={29}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Cybersecurity</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={30}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Law</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-checked ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button ant-radio-button-checked">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={15}
                                                                            data-gtm-form-interact-field-id={3}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Content &amp; Branding</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={18}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Others</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={27}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>HR</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={11}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Software</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={13}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Product</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={12}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Study Abroad</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={14}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Finance</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={19}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Design</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={22}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Data</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={23}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Astrology</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={25}
                                                                            data-gtm-form-interact-field-id={2}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Mental Health &amp; Wellbeing</span>
                                                                </label>
                                                                <label className="ant-radio-button-wrapper ant-radio-button-wrapper-in-form-item radio-checkbox-item ">
                                                                    <span className="ant-radio-button">
                                                                        <input
                                                                            className="ant-radio-button-input"
                                                                            type="radio"
                                                                            defaultValue={6}
                                                                        />
                                                                        <span className="ant-radio-button-inner" />
                                                                    </span>
                                                                    <span>Marketing</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div
                                        className="ant-row ant-row-center ant-row-middle "
                                        style={{ marginBottom: 30 }}
                                    >
                                        <div style={{ opacity: 1 }}>
                                            <div className="ant-typography ">
                                                <div
                                                    className="ant-row "
                                                    style={{
                                                        marginLeft: "-6px",
                                                        marginRight: "-6px",
                                                        marginBottom: 30
                                                    }}
                                                >
                                                    <div
                                                        className="ant-col service-type ant-col-xs-24 ant-col-md-24 "
                                                        style={{ paddingLeft: 6, paddingRight: 6 }}
                                                    >
                                                        Popular <b>1:1 services</b> in your expertise
                                                    </div>
                                                    <div
                                                        id={222}
                                                        className="ant-col ant-col-xs-24 ant-col-md-12 "
                                                        style={{ paddingLeft: 6, paddingRight: 6 }}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="ant-btn  ant-btn-text ant-btn-block onboarding-card"
                                                        >
                                                            <span>Quick chat</span>
                                                        </button>
                                                    </div>
                                                    <div
                                                        id={224}
                                                        className="ant-col ant-col-xs-24 ant-col-md-12 "
                                                        style={{ paddingLeft: 6, paddingRight: 6 }}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="ant-btn  ant-btn-text ant-btn-block onboarding-card"
                                                        >
                                                            <span>1:1 Consultation</span>
                                                        </button>
                                                    </div>
                                                    <div
                                                        id={226}
                                                        className="ant-col ant-col-xs-24 ant-col-md-12 "
                                                        style={{ paddingLeft: 6, paddingRight: 6 }}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="ant-btn  ant-btn-text ant-btn-block onboarding-card"
                                                        >
                                                            <span>Linkedin branding</span>
                                                        </button>
                                                    </div>
                                                    <div
                                                        id={228}
                                                        className="ant-col ant-col-xs-24 ant-col-md-12 "
                                                        style={{ paddingLeft: 6, paddingRight: 6 }}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="ant-btn  ant-btn-text ant-btn-block onboarding-card"
                                                        >
                                                            <span>Content creation</span>
                                                        </button>
                                                    </div>
                                                    <div
                                                        id={230}
                                                        className="ant-col ant-col-xs-24 ant-col-md-12 "
                                                        style={{ paddingLeft: 6, paddingRight: 6 }}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="ant-btn  ant-btn-text ant-btn-block onboarding-card"
                                                        >
                                                            <span>1:1 Mentorship</span>
                                                        </button>
                                                    </div>
                                                    <div
                                                        id={232}
                                                        className="ant-col ant-col-xs-24 ant-col-md-12 "
                                                        style={{ paddingLeft: 6, paddingRight: 6 }}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="ant-btn  ant-btn-text ant-btn-block onboarding-card"
                                                        >
                                                            <span>Doubt session</span>
                                                        </button>
                                                    </div>
                                                    <div
                                                        id={442}
                                                        className="ant-col ant-col-xs-24 ant-col-md-12 "
                                                        style={{ paddingLeft: 6, paddingRight: 6 }}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="ant-btn  ant-btn-text ant-btn-block onboarding-card"
                                                        >
                                                            <span>Discovery Call</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-row "
                                            style={{ marginLeft: "-6px", marginRight: "-6px", marginBottom: 30 }}
                                        >
                                            <div
                                                className="ant-col service-type ant-col-xs-24 ant-col-md-24 "
                                                style={{ paddingLeft: 6, paddingRight: 6 }}
                                            >
                                                Popular <b>Priority DM</b> services in your expertise
                                            </div>
                                            <div
                                                id={402}
                                                className="ant-col ant-col-xs-24 ant-col-md-12 "
                                                style={{ paddingLeft: 6, paddingRight: 6 }}
                                            >
                                                <button
                                                    type="button"
                                                    className="ant-btn  ant-btn-text ant-btn-block onboarding-card"
                                                >
                                                    <span>Priority DM</span>
                                                </button>
                                            </div>
                                            <div
                                                id={400}
                                                className="ant-col ant-col-xs-24 ant-col-md-12 "
                                                style={{ paddingLeft: 6, paddingRight: 6 }}
                                            >
                                                <button
                                                    type="button"
                                                    className="ant-btn  ant-btn-text ant-btn-block onboarding-card"
                                                >
                                                    <span>Ask me anything</span>
                                                </button>
                                            </div>
                                            <div
                                                id={398}
                                                className="ant-col ant-col-xs-24 ant-col-md-12 "
                                                style={{ paddingLeft: 6, paddingRight: 6 }}
                                            >
                                                <button
                                                    type="button"
                                                    className="ant-btn  ant-btn-text ant-btn-block onboarding-card"
                                                >
                                                    <span>Have a question?</span>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                               
        </>
    );
};
