import React from "react";
import MentorStats from "./MentorStats";
import "../menorDashboard.css";

function index() {
  return (
    <>
      <div className="mentor-stats-container">
   <div className="stats_heading"> <h5>My Stats</h5></div>  
        <MentorStats />
      </div>
    </>
  );
}

export default index;
