import React from 'react';

const ReferralComponet = () => {
  return (
   <>
          <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
              <div className="ant-row ant-row-center container-title-section analytics-nav referral-nav ">
                  <div
                      className="ant-col header-section ant-col-xs-22 ant-col-xs-offset-0 ant-col-md-11 ant-col-md-offset-1 "
                      style={{ justifyContent: "flex-start" }}
                  >
                      <h4 className="ant-typography header ">Referral</h4>
                  </div>
                  <div className="ant-col ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-12 ant-col-md-offset-0 ">
                      <div className="ant-row ant-row-center analytics-row ">
                          <div className="ant-col title-analytics-section ant-col-xs-12 ant-col-md-12 ">
                              <div className="ant-typography ">
                                  <img
                                      alt="Total Earnings"
                                      loading="lazy"
                                      width={30}
                                      height={20}
                                      decoding="async"
                                      data-nimg={1}
                                      className="icon"
                                      srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Fanalytics%2FProfile-view.svg&w=32&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Fanalytics%2FProfile-view.svg&w=64&q=75 2x"
                                      src="/_next/image?url=%2Fimages%2Fdashboard%2Fanalytics%2FProfile-view.svg&w=64&q=75"
                                      style={{ color: "transparent", height: "auto" }}
                                  />
                              </div>
                              <div className="ant-typography ">
                                  <div className="ant-typography title ">
                                      Successful referral
                                  </div>
                                  <div className="ant-typography value ">0</div>
                              </div>
                          </div>
                          <div className="ant-col title-analytics-section ant-col-xs-12 ant-col-md-12 ">
                              <div className="ant-typography ">
                                  <img
                                      alt="Total Earnings"
                                      loading="lazy"
                                      width={30}
                                      height={40}
                                      decoding="async"
                                      data-nimg={1}
                                      className="icon"
                                      srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Fanalytics%2FBooking.svg&w=32&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Fanalytics%2FBooking.svg&w=64&q=75 2x"
                                      src="/_next/image?url=%2Fimages%2Fdashboard%2Fanalytics%2FBooking.svg&w=64&q=75"
                                      style={{ color: "transparent", height: "auto" }}
                                  />
                              </div>
                              <div className="ant-typography ">
                                  <div className="ant-typography title ">Earnings</div>
                                  <div className="ant-typography value ">₹ 0</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="ant-row container-children ">
                  <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
                      <div className="container-fluid referral">
                          <div
                              className="ant-row "
                              style={{ marginLeft: "-10px", marginRight: "-10px" }}
                          >
                              <div
                                  className="ant-col ant-col-xs-24 ant-col-md-10 ant-col-lg-10 "
                                  style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                  <div className="ant-typography referral-title ">
                                      Invite &amp; Get <br />
                                      <span>100% of what we earn</span>
                                  </div>
                                  <div className="ant-typography referral-label ">
                                      Share your link
                                  </div>
                                  <div
                                      className="ant-space  ant-space-vertical"
                                      style={{ gap: 12, width: "100%" }}
                                  >
                                      <div className="ant-space-item">
                                          <div className="ant-typography ">
                                              <span className="ant-input-affix-wrapper referral-input ">
                                                  <input
                                                      className="ant-input "
                                                      type="text"
                                                      defaultValue="mentorpal.ai/join/ankit18"
                                                  />
                                                  <span className="ant-input-suffix">
                                                      <button
                                                          type="button"
                                                          className="ant-btn  ant-btn-text ant-btn-sm copy-button"
                                                      >
                                                          <span>Copy</span>
                                                      </button>
                                                  </span>
                                              </span>
                                          </div>
                                      </div>
                                      <div className="ant-space-item">
                                          <div className="ant-typography booking-mobile-footer ">
                                              <button
                                                  type="button"
                                                  className="ant-btn  ant-btn-default ant-btn-block btn-dark"
                                              >
                                                  <span>Send Invite</span>
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div
                                  className="ant-col ant-col-xs-12 ant-col-md-7 ant-col-lg-7 "
                                  style={{ paddingLeft: 10, paddingRight: 10, display: "flex" }}
                              >
                                  <div className="ant-typography referral-block ">
                                      <div className="ant-typography img-1 ">
                                          <img
                                              alt="referral"
                                              loading="lazy"
                                              width={135}
                                              height={98}
                                              decoding="async"
                                              data-nimg={1}
                                              className=""
                                              srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Freferral%2Fimg-referral-1.svg&w=256&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Freferral%2Fimg-referral-1.svg&w=384&q=75 2x"
                                              src="/_next/image?url=%2Fimages%2Fdashboard%2Freferral%2Fimg-referral-1.svg&w=384&q=75"
                                              style={{ color: "transparent", height: "auto" }}
                                          />
                                      </div>
                                      <div className="ant-typography rb-title ">
                                          Your friends get <br className="mobile-hide" />
                                          <span className="ant-typography ">
                                              <strong>VIP launch </strong>
                                          </span>
                                          on our socials and website
                                      </div>
                                  </div>
                              </div>
                              <div
                                  className="ant-col ant-col-xs-12 ant-col-md-7 ant-col-lg-7 "
                                  style={{ paddingLeft: 10, paddingRight: 10, display: "flex" }}
                              >
                                  <div className="ant-typography referral-block ">
                                      <div className="ant-typography img-2 ">
                                          <img
                                              alt="referral"
                                              loading="lazy"
                                              width={93}
                                              height={98}
                                              decoding="async"
                                              data-nimg={1}
                                              className=""
                                              srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Freferral%2Fimg-referral-2.svg&w=96&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Freferral%2Fimg-referral-2.svg&w=256&q=75 2x"
                                              src="/_next/image?url=%2Fimages%2Fdashboard%2Freferral%2Fimg-referral-2.svg&w=256&q=75"
                                              style={{ color: "transparent", height: "auto" }}
                                          />
                                      </div>
                                      <div className="ant-typography rb-title ">
                                          You get{" "}
                                          <span className="ant-typography ">
                                              <strong>100% of</strong>
                                          </span>
                                          <br className="mobile-hide" />
                                          whatever we earn from
                                          <br className="mobile-hide" />
                                          your friends for 3 months
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="ant-row ">
                              <div className="ant-col ant-col-24 referral-empty-state ">
                                  <div className="ant-space  ant-space-vertical ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small">
                                      <div className="ant-space-item">
                                          <img
                                              alt="referral"
                                              loading="lazy"
                                              width={130}
                                              height={128}
                                              decoding="async"
                                              data-nimg={1}
                                              className=""
                                              srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Freferral%2Fimg-referral-empty-state.svg&w=256&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Freferral%2Fimg-referral-empty-state.svg&w=384&q=75 2x"
                                              src="/_next/image?url=%2Fimages%2Fdashboard%2Freferral%2Fimg-referral-empty-state.svg&w=384&q=75"
                                              style={{ color: "transparent", height: "auto" }}
                                          />
                                      </div>
                                      <div className="ant-space-item">
                                          <div className="ant-typography es-title ">
                                              No referrals yet
                                          </div>
                                      </div>
                                      <div className="ant-space-item">
                                          <div className="ant-typography es-subtitle ">
                                              The perfect time to get a friend on topmate
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="ant-row referral-footer ">
                  <div className="ant-col ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
                      <div
                          className="ant-space  ant-space-horizontal ant-space-align-center"
                          style={{ gap: 36 }}
                      >
                          <div className="ant-space-item">
                              <a
                                  className="rf-links"
                                  target="_blank"
                                  href="https://topmate.click/referral-india"
                              >
                                  Terms &amp; Conditions
                                  <svg
                                      stroke="currentColor"
                                      fill="none"
                                      strokeWidth={2}
                                      viewBox="0 0 24 24"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      fontSize={20}
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                                      <polyline points="15 3 21 3 21 9" />
                                      <line x1={10} y1={14} x2={21} y2={3} />
                                  </svg>
                              </a>
                          </div>
                          <div className="ant-space-item">
                              <a
                                  className="rf-links"
                                  target="_blank"
                                  href="https://topmate.click/referral-query"
                              >
                                  Contact us
                                  <svg
                                      stroke="currentColor"
                                      fill="none"
                                      strokeWidth={2}
                                      viewBox="0 0 24 24"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      fontSize={20}
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                                      <polyline points="15 3 21 3 21 9" />
                                      <line x1={10} y1={14} x2={21} y2={3} />
                                  </svg>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

   </>
  );
}

export default ReferralComponet;
