import React, { useState, useEffect, useRef } from "react";
import style from "./BecomeMentor.module.css";


import mentorHelpingMentee from "../../assets/images/mentorHelpingMentee.jpg";
import PoliticianSittingAtRoundTableInBoardroom from "../../assets/images/PoliticianSittingAtRoundTableInBoardroom.jpg";
import scholarWithDegree from "../../assets/images/scholarWithDegree.jpg";
import teachingConcept from "../../assets/images/teachingConcept.jpg";
import howToBecomeMentorSteps from "../../assets/images/howTobecomMentorSteps.jpeg";
import Header from "../header";
import Footer from "../footer";
import VideoBanner from "./VideoBanner";
import { BecomeAMentorSeo } from "../Seo/data";
import SeoHelmet from "../Seo/Helmet/SeoHelmet";

export default function BecomeMentor() {
  const [headerModal, setHeaderModal] = useState({});

  return (
    <>
      <SeoHelmet metaData={BecomeAMentorSeo} />

      <div
        className="container-fluid"
        style={{ paddingLeft: "0", paddingRight: "0" }}
        >
        <div className={style.transparenthead}>
          <Header isVdoBannerPage={true} />
        </div>
        <div className="videoBanner-container">
          <VideoBanner />
        </div>
      </div>

      {/* second section */}
      <section className={style.intro_section}>
        <div className={`${style.intro_container} container `}>
          <div className={`${style.intro_row} row gx-5`}>
            <div
              className={`${style.introimg_col} col-lg-6 `}
              style={{ backgroundColor: "#ffff" }}
            >
              <img
                className={`${style.intro_image} img-fluid `}
                src={PoliticianSittingAtRoundTableInBoardroom}
                alt="IntroImage"
              />
            </div>
            <div className={`${style.intro} col-lg-6  `}>
              <h1
                className={style.intro_heading}
                style={{ textAlign: "center" }}
              >
                Introduction
              </h1>
              Transform lives and advance careers by joining Mentorpal.ai's
              dynamic community. Share your expertise, build leadership skills,
              and make a meaningful difference in someone's life. Join us now
              and help shape the next generation of leaders.{" "}
            </div>
          </div>
        </div>
      </section>

      {/* third and userfriendly section */}

      <section className={style.intro_section}>
        <div className="container-fluid" style={{ backgroundColor: "#F1F0F4" }}>
          <div className="container">
            <div className="row">
              <div className={`${style.sub_text} col-lg-6 `}>
                <h2 className={style.intro_heading}>
                  {" "}
                  Mentorpal.ai - what makes it so effective?
                </h2>
                Mentorpal.ai offers a user-friendly platform to connect you with
                diverse mentees from around the world. With features like
                scheduling and progress tracking, managing your mentorship
                relationships has never been easier. Join us today and find
                mentees that align with your areas of expertise.
              </div>
              <div className={`${style.userfriendlyimg_col} col-lg-6 `}>
                <img
                  className={`${style.userfriendly_image} img-fluid`}
                  src={mentorHelpingMentee}
                  alt="User Friendly"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* qualifications section */}

      <section className={style.intro_section}>
        <div className={`${style.intro_container} container `}>
          <div className={`${style.intro_row} row`}>
            <div
              className={`${style.qualiImg_col} col-lg-6 mb-4`}
              style={{ backgroundColor: "#ffff" }}
            >
              <img
                className={`${style.qualification_image} img-fluid`}
                src={teachingConcept}
                alt="qualifications Image"
              />
            </div>
            <div className={`${style.intro} col-lg-6`}>
              <h2 className={style.intro_heading}>
                Ideal mentors' qualities include:
              </h2>
              <ul>
                <li>
                  Minimum 3 years of professional experience in your field.
                </li>
                <li>Excellent communication and interpersonal skills</li>
                <li>A desire to help others achieve their goals</li>
                <li>Being available regularly to meet with your mentee</li>
                <li>Being professional and respectful at all times</li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </section>

      {/* process section */}
      <section className={style.intro_section}>
        <div className="container-fluid" style={{ backgroundColor: "#F1F0F4" }}>
          <div className="container">
            <div className="row">
              <div className={`${style.sub_text} col-lg-6 `}>
                <h2 className={style.intro_heading}>
                  Becoming a Mentorpal.ai mentor is easy:
                </h2>
                <ul>
                  <li>
                    Complete the mentor application form on our website,
                    providing details about your professional experience and
                    expertise.
                  </li>
                  <li>
                    Selected applicants will complete a screening interview to
                    assess their mentorship fit.
                  </li>
                  <li>
                    After the screening interview, complete any necessary
                    training to prepare for mentorship.
                  </li>
                </ul>
              </div>
              <div className={`${style.process_col} col-lg-6 `}>
                <img
                  className={`${style.process_img} img-fluid `}
                  src={howToBecomeMentorSteps}
                  alt="process img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* responsibilities section */}
      <section className={style.intro_section}>
        <div className={`${style.intro_container} container`}>
          <div className={`${style.intro_row} row`}>
            <div
              className={`${style.responsibilities_col} col-lg-6 `}
              style={{ backgroundColor: "#ffff" }}
            >
              <img
                className={`${style.responsibilities_img} img-fluid`}
                src={scholarWithDegree}
                alt="Responsibilities Image"
              />
            </div>
            <div className={`${style.intro} col-lg-6 `}>
              <h2 className={style.intro_heading}>
                A mentor's responsibilities include:
              </h2>
              <ul>
                <li>Mentoring a mentee in their field of choice.</li>
                <li>
                  Having regular meetings with your mentee (frequency can be
                  determined together).
                </li>
                <li>
                  Monitoring your mentee's progress and providing feedback.
                </li>
                <li>
                  Maintaining a professional and respectful relationship with
                  your mentee.
                </li>
              </ul>{" "}
              
            </div>
          </div>
          <div className="row">
<div
                className="get-started-cls align-items-center text-center"
                id="get-started-cls"
              >
                <button
                

                  type="button"
                  className="btn btn-primary"
                >
                  <a href="/apply-as-mentor" style={{ color: "gray" }}>
                    Apply as a Mentor
                  </a>
                </button>
              </div>
          </div>
        </div>
      </section>
      <hr></hr>
      {/* FAQs section */}

      <section className={style.faq_section}>
        <div className="container">
          <div className="frequently_asked">
            <div className="row">
              <div className="col-md-6">
                <div className="mentoring-cont">
                  <div className={style.faq__pricing}>
                    <h3 className={`${style.faq_heading}`}>
                      Frequently asked Questions
                    </h3>
                    <div className="inner-title">
                      <h2>
                        Can't find the answer you <span> are looking for?</span>
                      </h2>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                        </svg>
                      </span>
                      <a href="mailto:help@mentorpal.ai">
                        {" "}
                        <span className="cont-cls">Reach out to us</span>
                      </a>
                    </li>
                    <li>
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                        </svg>
                      </span>
                      <a href="https://www.mentorpal.ai/feedback">
                        <span className="cont-cls">
                          Help & grow with MentroPal
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed faqfont"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        What is MentorPal?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        MentorPal is a tool that enables you to connect with
                        your audience through 1:1 sessions - to share your
                        knowledge and monetise your time better. Just create
                        your personal link, add your services, availability and
                        charges; and share your link with your followers. Your
                        followers can now book a call to connect with you
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Can I conduct paid sessions?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Absolutely, we enable you to conduct both free and paid
                        sessions with your followers
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        How much can I charge for my sessions?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        It really depends on how much value you think you're
                        providing and how much are your followers willing to
                        pay. For idea, our mentors charge anywhere from $10 to
                        $200 for a session
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        How do the payments work?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        You can collect payments from anywhere in the world in
                        your preferred currency. Payouts are Instant with the
                        help of our integration with Stripe and Paypal
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        Wait, is MentorPal really free?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Yes! We're free to use and charge a very tiny commission
                        on your earnings (7%). So that we grow only when you
                        grow
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingSix">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix"
                        aria-expanded="false"
                        aria-controls="flush-collapseSix"
                      >
                        What are the transaction charges?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSix"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingSix"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Payment gateways like Stripe and PayPal typically charge
                        around 3% of the transaction amount. This is beyond our
                        control
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {headerModal?.modal && (
        <headerModal.modal
          headerModal={headerModal}
          setHeaderModal={setHeaderModal}
        />
      )}
      <Footer />
    </>
  );
}
