import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllCourseAction } from '../../redux/actions/course';
import MasterHeader from '../MasterCourses/MasterHeader/MasterHeader';
// import {
//     pro1,
//     pro2, bannerImg
// } from '../../assets/images/export-images';
// import MasterHeader from './MasterHeader/MasterHeader';
// import './Master.css'
// import MasterSlider from './MasterCrousel';
// import Testimonial from '../Testimonial/testimonial';
// import MasterFooter from './MasterFooter';
// import { useNavigate } from 'react-router';
// import { faqData, featuresData } from './data';
// import GetMore from './GetMore';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAllCourseAction } from '../../redux/actions/course';
// import { TailSpin } from 'react-loader-spinner';
// import CourseSubscribe from './CourseSubscribe';
import Header from './../header/index';
import { bannerImg, pro1, pro2 } from '../../assets/images/export-images';
import MasterSlider from '../MasterCourses/MasterCrousel';
import { TailSpin } from 'react-loader-spinner';
import { faqData, featuresData } from '../MasterCourses/data';
import Testimonial from '../Testimonial/testimonial';
import GetMore from '../MasterCourses/GetMore';
import CourseSubscribe from '../MasterCourses/CourseSubscribe';
import MasterFooter from '../MasterCourses/MasterFooter';



const ExploreWebinar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader, setloader] = useState();
    const [data, setdata] = useState();
    const [error, setError] = useState();

    useEffect(() => {


        dispatch(getAllCourseAction({ document_type: "webinar" }));
    }, [getAllCourseAction, dispatch]);

    const getAllCourse = useSelector((state) => state.getAllCourseReducer);
    useEffect(() => {
        setloader(getAllCourse?.loading);
        setdata(getAllCourse?.data);
        setError(getAllCourse?.error);
    }, [getAllCourse, loader, data]);


    const courseDetails = (course) => {
        navigate(`/webinar/${course?.slug}/${course?.product_id}`);
    }
    const getLocalTime = (utcTime) => {
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };

        const localTime = new Date(utcTime).toLocaleString("en-US", options);
        return localTime;
    };

    let date = new Date();
    let datestring = date.toTimeString();
    let countryname =
        datestring?.includes("india") | datestring?.includes("India")
            ? "India"
            : "";
    const currentDate = new Date();
    const [upcomingCoursesdata, setupcomingCoursesdata] = useState();
    const [pastCoursesdata, setpastCoursesdata] = useState();


    const upcomingCourses = data ? data.filter((course) => {
        const courseStartDate = new Date(course.course_start_date);
        return courseStartDate > currentDate;
    }) : [];

    const pastCourses = data ? data.filter((course) => {
        const courseStartDate = new Date(course.course_start_date);
        return courseStartDate < currentDate;
    }) : [];


    useEffect(() => {
        if (loader === false && upcomingCourses && pastCourses)
            setupcomingCoursesdata(upcomingCourses)
        setpastCoursesdata(pastCourses)

    }, [getAllCourse, loader, data]);
    return (
        <>
            <Header />
            <section
                className="banner-sec"
                style={{ backgroundImage: `url(${bannerImg})` }}
            >
                <div className="container">
                    <div className="mentroship-sec">
                        <h1>Explore the Expertise of World-Class Authorities</h1>
                        <p>
                            Elevate Your Abilities through MentorPal's Mentor Webinar. Experience Live Learning from Accomplished Experts, Collaborate with Fellow Peers, and Generate Meaningful Real-World Change.
                        </p>

                    </div>
                    <div className="get-started-cls">
                        {/* <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => { navigate('/explore-courses') }}
                        >
                            Explore All Webinar
                        </button> */}
                    </div>

                </div>

            </section>
            <section className="savetime-sec why_choose_ment">
                <div className="container">
                    <div className="save-time-sec">
                        <div className="inner-title">
                            <h3>Save time & Money</h3>
                            <h2>
                                Save your time & money by
                                <span className="d-block">
                                    choosing our professional Courses
                                </span>
                            </h2>
                        </div>
                        <div className="progress-sec">
                            <div className="satisfaction">
                                <span className="satisfaction-ic">
                                    <img alt="icon" src={pro1} />
                                </span>
                                <span className="satisfaction-cont">Mentee’s Satisfaction</span>
                            </div>
                            <div className="new-join">
                                <span className="satisfaction-ic">
                                    <img alt="icon" src={pro2} />
                                </span>
                                <span className="satisfaction-cont">New Joinee</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <MasterSlider />


            <section className="section background-color-grey">
                <div className="padding-global">
                    <div className="container-large">
                        <div className="padding-section-medium">
                            <div className="section-content-holder">
                                <div className="margin-bottom margin-medium">
                                    <div className="text-align-center">
                                        <div className="max-width-medium">
                                            <h2 className="heading-style-h3" style={{ color: '#b7308e' }}>Upcoming  Webinar</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-dyn-list">
                                    {
                                        loader
                                            ? (
                                                <div className="loader open_sessions_loader">
                                                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                                                </div>
                                            ) : (


                                                <>
                                                    {
                                                        upcomingCoursesdata && upcomingCoursesdata.length > 0 ? (
                                                            <>
                                                                <div role="list" className="course-grid w-dyn-items">
                                                                    {upcomingCoursesdata?.map((course, index) => (

                                                                        <div key={index} role="listitem" className="w-dyn-item course_card__Wrapper" onClick={() => { courseDetails(course) }}>
                                                                            <a className="course-card w-inline-block">
                                                                                <div className="c-wrap">
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}${course?.course_banner}`} alt={course?.course_topic}
                                                                                        loading="lazy"

                                                                                        sizes="100vw"
                                                                                        className="c-thumbnail"
                                                                                    />
                                                                                </div>
                                                                                <div className="course-details">
                                                                                    <div className="meta-group margin-bottom margin-xsmall">
                                                                                        <div className="small-headline paid">{course?.proBono === "true"
                                                                                            ? null
                                                                                            : countryname === "India"
                                                                                                ? "₹" + (Math.round(course?.discount_price * 83.12))
                                                                                                : "$" + (Math.round(course?.discount_price))

                                                                                        }</div>
                                                                                        <div className="small-headline">·</div>
                                                                                        <div className="small-headline">{course?.course_time_period} week</div>
                                                                                        <div className="small-headline">·</div>
                                                                                        <div className="small-headline">{getLocalTime(course?.course_start_date)}</div>
                                                                                    </div>
                                                                                    <h2 className="heading-style-h4 bold">{course?.course_topic}</h2>
                                                                                    <p>{course?.course_description?.split(' ').slice(0, 15).join(' ')} ...</p>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <section>
                                                                    <div className="container avilabilty___col">
                                                                        <div className="d-flex align-items-center justify-content-center ">
                                                                            <div className="text-center">
                                                                                <h2 className="display-1 fw-bold text-center">Comming Soon</h2>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </>
                                                        )


                                                    }

                                                </>

                                            )}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section background-color-grey">
                <div className="padding-global">
                    <div className="container-large">
                        <div className="padding-section-medium">
                            <div className="section-content-holder">
                                <div className="margin-bottom margin-medium">
                                    <div className="text-align-center">
                                        <div className="max-width-medium">
                                            <h2 className="heading-style-h3" style={{ color: '#b7308e' }}>Past  Webinar</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-dyn-list">
                                    {
                                        loader
                                            ? (
                                                <div className="loader open_sessions_loader">
                                                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                                                </div>
                                            ) : (


                                                <>
                                                    {
                                                        pastCoursesdata && pastCoursesdata.length > 0 ? (
                                                            <>
                                                                <div role="list" className="course-grid w-dyn-items">
                                                                    {pastCoursesdata?.map((course, index) => (
                                                                        <div key={index} role="listitem" className="w-dyn-item course_card__Wrapper" onClick={() => { courseDetails(course) }}>
                                                                            <a className="course-card w-inline-block">
                                                                                <div className="c-wrap">
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}${course?.course_banner}`} alt={course?.course_topic}
                                                                                        loading="lazy"

                                                                                        sizes="100vw"
                                                                                        className="c-thumbnail"
                                                                                    />
                                                                                </div>
                                                                                <div className="course-details">
                                                                                    <div className="meta-group margin-bottom margin-xsmall">
                                                                                        <div className="small-headline paid">{course?.proBono === "true"
                                                                                            ? null
                                                                                            : countryname === "India"
                                                                                                ? "₹" + (Math.round(course?.discount_price * 83.12))
                                                                                                : "$" + (Math.round(course?.discount_price))

                                                                                        }</div>
                                                                                        <div className="small-headline">·</div>
                                                                                        <div className="small-headline">{course?.course_time_period} week</div>
                                                                                        <div className="small-headline">·</div>
                                                                                        <div className="small-headline">{getLocalTime(course?.course_start_date)}</div>
                                                                                    </div>
                                                                                    <h2 className="heading-style-h4 bold">{course?.course_topic}</h2>
                                                                                    <p>{course?.course_description?.split(' ').slice(0, 15).join(' ')} ...</p>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <section>
                                                                    <div className="container avilabilty___col">
                                                                        <div className="d-flex align-items-center justify-content-center ">
                                                                            <div className="text-center">
                                                                                <h2 className="display-1 fw-bold text-center">Comming Soon</h2>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </>
                                                        )


                                                    }

                                                </>

                                            )}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-choose why_choose_ment">
                <div className="container">
                    <div className="inner-title">
                        <h3>Only MentorPal! Why?</h3>
                        <h2>
                            Because, these features are our strength
                            <span className="d-block">choose MentorPal</span>
                        </h2>
                    </div>
                    <div className="mentorpal-sec">
                        <div className="row">
                            {featuresData.map((feature, index) => (
                                <div className="col-sm-6 col-md-4" key={index}>
                                    <div className="mentorpal-box">
                                        <div className="icon">
                                            <img alt="icon" src={feature.icon} />
                                        </div>
                                        <div className="serve-cont">
                                            <h4>{feature.title}</h4>
                                            <p>{feature.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <section className=" why_choose_ment">
                <div className="container">
                    {/*  testimonial section */}
                    <div className="container">
                        <center>
                            <h2 className=" Our-inspiring-mentorss my-4" style={{ color: '#b7308e' }}>
                                Our Inspiring Mentors & Empowered Mentees
                            </h2>
                        </center>
                        <Testimonial />
                    </div>
                </div>
            </section>
            <GetMore data={{ document_type: "webinar" }} />
            <CourseSubscribe />

            <section className="section background-color-grey pt-4 " style={{ paddingBottom: "4rem" }}>
                <div className="container">
                    <div className="frequently_asked ">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mentoring-cont">
                                    <div className="faq__pricing">
                                        <h3>Frequently asked Questions</h3>
                                        <div className="inner-title">
                                            <h2>
                                                Can't find the answer you <span> are looking for?</span>
                                            </h2>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>
                                            <span className="tick">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    width="24px"
                                                    fill="#000000"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                                                </svg>
                                            </span>
                                            <a href="mailto:help@mentorpal.ai">

                                                <span className="cont-cls">Reach out to us</span>
                                            </a>
                                        </li>
                                        <li>
                                            <span className="tick">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    width="24px"
                                                    fill="#000000"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                                                </svg>
                                            </span>
                                            <a >
                                                <span className="cont-cls">
                                                    Help & grow with MentroPal
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <span className="tick">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    width="24px"
                                                    fill="#000000"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                                                </svg>
                                            </span>
                                            <span className="cont-cls">
                                                take your knowledge to global level
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="get-started-cls" id="get-started-cls">
                                        <a
                                            href="mailto:help@mentorpal.ai"
                                            type="button"
                                            className="btn btn-primary"
                                        >
                                            Reach out to us
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                    {faqData.map((faq, index) => (
                                        <div class="accordion-item" style={{ marginBottom: "2px" }} key={index}>
                                            <h2 class="accordion-header" id={`flush-heading-${index}`}>
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#flush-collapse-${index}`}
                                                    aria-expanded="false"
                                                    aria-controls={`flush-collapse-${index}`}
                                                >
                                                    {faq.question}
                                                </button>
                                            </h2>
                                            <div
                                                id={`flush-collapse-${index}`}
                                                class="accordion-collapse collapse"
                                                aria-labelledby={`flush-heading-${index}`}
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div class="accordion-body">{faq.answer}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <MasterFooter />
        </>);
}

export default ExploreWebinar;
