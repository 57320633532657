import React from 'react'
import TrainingHeader from './TrainingHeader/TrainingHeader'
import TrainingHeroSection from './TrainingHeroSection/TrainingHeroSection'
import TrainingInfoSection from './TrainingInfoSection/TrainingInfoSection'
import TrainingServices from './TrainingServices/TrainingServices'
import TrainingDemo from './TrainingDemo/TrainingDemo'
import Footer from './../footer'
import Trainingimg from './../../assets/images/training.png'

const Training = () => {
    return (
        <>
            <div className='Training' >
                <TrainingHeader />
                <TrainingHeroSection
                    heading={'Elevate your Expertise'}
                    SubHeading={'Master Your Craft: Elevate Your Skills with Expert Training'}
                    btn={' Get Started'}
                    BgImg={Trainingimg}
                />
                <TrainingInfoSection />
                <TrainingServices />
                <TrainingDemo />
                <Footer />
            </div>
        </>
    )
}

export default Training