import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import { getUserDetails, updateProfile } from "../../../redux/actions/login";
import UpdateName from "../../adminDashboard/profileUpdate/UpdateName";
import UpdateCountry from "../../adminDashboard/profileUpdate/UpdateCountry";
import UpdateAbout from "../../adminDashboard/profileUpdate/UpdateAbout";
import UpdateLanguage from "../../adminDashboard/profileUpdate/UpdateLanguage";
import UpdateExpertise from "../../adminDashboard/profileUpdate/UpdateExpertise";
import UpdateCompany from "../../adminDashboard/profileUpdate/UpdateCompany";
import UpdateRole from "../../adminDashboard/profileUpdate/UpdateRole";
import UpdateLinkedin from "../../adminDashboard/profileUpdate/UpdateLinkedin";
import { Link } from "react-router-dom";
import UpdateGoal from "../../adminDashboard/profile/Goal";
import UpdateYear from "../../adminDashboard/profile/UpdateYear";
import UpdateCourse from "../../adminDashboard/profile/UpdateCourse";
import UpdatePhone from "../../adminDashboard/profile/UpdatePhone";
import UpdateExperience from "../../adminDashboard/profile/UpdateExperience";
import { message } from "antd";



const Profile = () => {
  useEffect(() => {
    import("../../adminDashboard/css/custom.css");
    import('../../adminDashboard/profile/CustomProfile.css')
  }, []);

  const dispatch = useDispatch();

  const userDetailsData = useSelector(
    (state) => state?.userDetailsReducer?.data[0]
  );
  const updateProfileReducerData = useSelector(
    (state) => state?.updateProfileReducer
  );
  const editStateObject = {
    name: false,
    phone: false,
    country: false,
    language: false,
    role: false,
    expertise: false,

  };

  if (userDetailsData?.title === 'Students') {
    editStateObject.course = false;
    editStateObject.year = false;
    editStateObject.about = false;
    editStateObject.linkedin_profile = false;
    editStateObject.goals = false;
  } else {
    editStateObject.company = false;
    editStateObject.experience = false;
    editStateObject.about = false;
    editStateObject.linkedin_profile = false;
    editStateObject.goals = false;
  }

  const NO_VALUE = "N/A";
  const user_id = REACT_USER_ID;
  const getStringValueFromList = (lst) => {
    if (lst) return lst.join(", ");
    else return "N/A";
  };


  // const getUpdatedValues = (data) => {
  //   let tempData = { ...data, user_id: user_id };
  //   // setLoader(true);
  //   console.log(tempData);
  //   dispatch(updateProfile(tempData)).then(setTimeout(() => {
  //     dispatch(getUserDetails(user_id));

  //   }, !updateProfileReducerData));

  // };
  const getUpdatedValues = (data) => {
    // Check if data is empty
    if (!Object.values(data).some(value => value !== null && value !== undefined && value !== '')) {
      // Display a message and return without updating
      message.error('Please provide valid data before updating.');
      return;
    }

    let tempData = { ...data, user_id: user_id };
    // setLoader(true);
    console.log(tempData);

    dispatch(updateProfile(tempData))
    setTimeout(() => {
      if (!updateProfileReducerData?.loading || updateProfileReducerData?.status === true) {
        dispatch(getUserDetails(user_id));
      }
    }, !updateProfileReducerData?.loading);
  };
  const fieldComponentMapping = {
    name: {
      lable: "Name",
      value: userDetailsData?.name || NO_VALUE,
      component: (
        <UpdateName
          nameData={userDetailsData?.name}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    country: {
      lable: "Country",
      value: userDetailsData?.country || NO_VALUE,
      component: (
        <UpdateCountry
          countryData={userDetailsData?.country}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    year: {
      lable: "Graduation Year",
      value: userDetailsData?.year || NO_VALUE,
      component: (
        <UpdateYear
          YearData={userDetailsData?.year}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    course: {
      lable: "Qualification",
      value: userDetailsData?.course || NO_VALUE,
      component: (
        <UpdateCourse
          courseData={userDetailsData?.course}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    // timezone: {
    //   lable: "Timezone",
    //   value: "Timezone value",
    //   component: <div>Timezone Component</div>,
    // },
    about: {
      lable: "About",
      value: userDetailsData?.story || NO_VALUE,
      component: (
        <UpdateAbout
          aboutData={userDetailsData?.story}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    goals: {
      lable: "Goals",
      value: userDetailsData?.goals || NO_VALUE,
      component: (
        <UpdateGoal
          aboutData={userDetailsData?.goals}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    language: {
      lable: "Language",
      value: getStringValueFromList(userDetailsData?.language) || NO_VALUE,
      component: (
        <UpdateLanguage
          languages={userDetailsData?.language}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },

    expertise: {
      lable: "Expertise",
      value: getStringValueFromList(userDetailsData?.experties) || NO_VALUE,
      component: (
        <UpdateExpertise
          expertise={userDetailsData?.experties}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },

    company: {
      lable: "Company",
      value: userDetailsData?.company_or_school || NO_VALUE,
      component: (
        <UpdateCompany
          companyData={userDetailsData?.company_or_school}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    role: {
      lable: "Role",
      value: userDetailsData?.title || NO_VALUE,
      component: (
        <UpdateRole
          roleData={userDetailsData?.title}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    linkedin_profile: {
      lable: "Linkedin profile",
      value: userDetailsData?.linkedin_url || NO_VALUE,
      component: (
        <UpdateLinkedin
          linkedinData={userDetailsData?.linkedin_url}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    phone: {
      lable: "Phone",
      value: userDetailsData?.phone || NO_VALUE,
      component: (
        <UpdatePhone
          PhoneData={userDetailsData?.phone}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    experience: {
      lable: "How many year of experience do you have?",
      value: userDetailsData?.experience || NO_VALUE,
      component: (
        <UpdateExperience
          ExperienceData={userDetailsData?.phone}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
  };

  const fieldList = Object.keys(editStateObject);

  const [editState, setEditState] = useState(editStateObject);
  const changeEditStateMethod = (stateName) => {
    let newValue = !editState[stateName];
    setEditState({ ...editState, [stateName]: newValue });
  };
  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });
  return (
    <>
      <div className="update-field-container">
        {fieldList?.map((field, index) => (
          <div key={index} className="update-field-row">
            <div className="details-edit-btn-header">
              <span>{fieldComponentMapping[field]?.lable}</span>
              <Link to="" onClick={() => changeEditStateMethod(field)}>
                {editState[field] === false ? "Edit" : "Cancel"}
              </Link>
            </div>
            {editState[field] ? (
              fieldComponentMapping[field]?.component
            ) : (
              <div className="spanPadd">
                {fieldComponentMapping[field]?.value}
              </div>
            )}
            <hr />
          </div>
        ))}
      </div>
    </>
  );
};

export default Profile;
