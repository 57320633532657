import { Button, Col, Form, Result, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddServiceForm from "./AddServiceForm";
import ServiceAdvanced from "./ServiceAdvanced";
import { useDispatch, useSelector } from "react-redux";
import { getCourseDetailsAction } from "../../../../redux/actions/course";
import { TailSpin } from "react-loader-spinner";
import { CardPlaceholder, Container } from "../MainServiceListing";

const ServicesEdit = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { id } = useParams()
    const [loader, setloader] = useState(false);
    const [data, setData] = useState(null);
    useEffect(() => {
        dispatch(getCourseDetailsAction(id))
    }, [id, dispatch]);

    const getProductDetailsReducer = useSelector(
        (state) => state.getCourseDetailsReducer
    )
    useEffect(() => {
        setloader(getProductDetailsReducer?.loading)
        setData(getProductDetailsReducer?.data)
    }, [getProductDetailsReducer]);


    const [ServiceEditForm] = Form.useForm();
    function isValidDate(dateString) {
        // Check if the dateString is a valid date
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
        return regex.test(dateString) && !isNaN(new Date(dateString));
    }
    useEffect(() => {
        // Set initial values dynamically if needed
        if (data) {
            ServiceEditForm.setFieldsValue({
                ...data,
                date: data.specific_date
                    ? isValidDate(data.specific_date)
                        ? new Date(data.specific_date)
                        : undefined
                    : undefined,
                time: data.specific_date
                    ? isValidDate(data.specific_date)
                        ? new Date(data.specific_date)
                        : undefined
                    : undefined,
            });
        }
    }, [ServiceEditForm, data]);


    const [activeComponent, setActiveComponent] = useState("basic");

    const handleButtonClick = (component) => {
        setActiveComponent(component);
    };

    const OnHandleChanges = () => {
        setloader(true);
        setTimeout(() => {
            setloader(false);
        }, 3000);
        ServiceEditForm.submit();
    }

    const renderComponent = () => {
        switch (activeComponent) {
            case "basic":
                return < AddServiceForm form={ServiceEditForm} data={data} />;
            // case "advanced":
            //     return < ServiceAdvanced form={ServiceEditForm} data={serviceData} />;
            // case "share":
            //     return <Share form={ServiceEditForm} data={serviceData} />;

            default:
                return null;
        }
    };

    const goBack = () => {
        window.history.back();
    };
    return (
        <>
            {/* {
                loader ? (<div className="loader open_sessions_loader">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>) : (null)
            } */}
            <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
                <div className="ant-row container-title-section sticky ">
                    <div className="ant-col header-section ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-14 ant-col-md-offset-1 ant-col-xl-12 ant-col-xl-offset-1 ant-col-xxl-12 ant-col-xxl-offset-1 ">
                        <h4 className="ant-typography layout-header ">
                            <svg
                                onClick={() => {
                                    goBack()
                                }}
                                style={{marginLeft:"0"}}
                                stroke="currentColor"
                                fill="none"
                                strokeWidth={2}
                                viewBox="0 0 24 24"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="back-btn"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <line x1={19} y1={12} x2={5} y2={12} />
                                <polyline points="12 19 5 12 12 5" />
                            </svg>
                            <div className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line ">
                                Edit Service
                            </div>
                        </h4>
                    </div>
                    <div className="ant-col tab-container ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xl-22 ant-col-xl-offset-1 ant-col-xxl-22 ant-col-xxl-offset-1 ">
                        <span
                            className="ant-typography flex-center "
                            style={{ overflowY: "auto" }}
                        >
                            <a

                                className={`tab-link tab-link-common ${activeComponent === "basic" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClick("basic")}
                            >
                                <span className="ant-typography tab-title ">
                                    Basic details
                                </span>
                            </a>
                            {/* <a
                                className={`tab-link tab-link-common ${activeComponent === "advanced" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClick("advanced")}
                            >
                                <span className="ant-typography tab-title ">Advanced</span>
                            </a>
                            <a
                                className={`tab-link tab-link-common ${activeComponent === "share" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClick("share")}
                            >
                                <span className="ant-typography tab-title ">Share</span>
                            </a> */}
                        </span>
                        <div className="ant-flex " style={{ gap: 12 }}>
                            <button
                                onClick={OnHandleChanges}
                                type="button"
                                className="ant-btn  ant-btn-default tab-cta-link tab-link-common btn-dark"
                            >
                                <span>Save changes</span>
                            </button>
                        </div>
                    </div>
                </div>


                <Row style={{ padding: '0 % 5 % 5 %' }}>
                    <Col xs={24} lg={12}>


                        <Container type="fluid" className="dashboard-services">
                            {loader ? (
                                <>
                                    {[1, 2, 3, 4, 5, 6].map((index) => (
                                        <Row gutter={{ xs: 40, md: 0 }} key={index}>
                                            <Col xs={{ span: 24 }} md={{ span: 13 }} lg={11}>
                                                <CardPlaceholder />
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            ) : getProductDetailsReducer?.error !== undefined ? (
                                <>
                                    <h1>Something went wrong</h1>
                                </>
                            ) : (
                                <>
                                    {renderComponent()}
                                </>
                            )}


                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ServicesEdit;
