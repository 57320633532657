import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Overlay from "./Overlay";
import { updateProfile } from "../../redux/actions/login";
import { homeIcon, arNext } from "../../assets/images/export-images";
import Footer from "../footer";

import Header from "./BecomeMentorHeader";
import One from "./RegistrationProcess/One";
import Two from "./RegistrationProcess/Two";
import Three from "./RegistrationProcess/Three";
import Four from "./RegistrationProcess/Four";
import { TimeZoneAndPrice } from "./RegistrationProcess/Five";
import Six from "./RegistrationProcess/Six";
import Seven from "./RegistrationProcess/Seven";
import AvailableHours from "../adminDashboard/Availability/AvailableHours";
import backgroundImage from "../../assets/images/backgroundImageForRegistraionflow.jpg";
import backgroundImage2 from "../../assets/images/backgroundImageForRegistraionflowAnother.jpg";

const ApplyAsMentor = () => {
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const dispatch = useDispatch();
  useEffect(() => {
    import("../../assets/css/bootstrap.min.css");
    import("../../assets/css/font-awesome.min.css");
    import("../../assets/css/owl.carousel.min.css");
    import("../../assets/css/owl.theme.default.min.css");
    import("./custom.css");
    import("../../assets/css/header.css");
    import("../../assets/css/signup-popup.css");
    import("../../assets/css/apply-mentor.css");
    import("../../assets/css/footer.css");
    import("./media.css");
    import("./../applyAsMentor/RegistrationProcess/registration.css");

    if (code) {
      changestate(6);
    }
  }, [code]);

  const [data, setData] = useState({});
  const [state, setState] = useState(1);
  const [currState, setCurrState] = useState(1);
  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });
  const USER_ID = localStorage.getItem("REACT_USER_ID"); 
  const [registrationProcessData, setRegistrationProcessData] = useState({
    country: userDetails?.country ? userDetails.country : "",
    language: userDetails?.language ? userDetails.language : [],
    experties: userDetails?.experties ? userDetails.experties : [],
    experience: userDetails?.experience ? userDetails.experience : "",
    company_or_school: userDetails?.company_or_school
      ? userDetails.company_or_school
      : "",
    title: userDetails?.title ? userDetails.title : "",
    linkedin_url: userDetails?.linkedin_url ? userDetails.linkedin_url : "",
    mobile: userDetails?.mobile ? userDetails.mobile : "",
    leadership: userDetails?.leadership ? userDetails.leadership : [],
    mentorship: userDetails?.mentorship ? userDetails.mentorship : [],
    job_search: userDetails?.job_search ? userDetails.job_search : [],
    career_advice: userDetails?.career_advice ? userDetails.career_advice : [],
    skills: userDetails?.skills ? userDetails.skills : [],
    profile_photo: userDetails?.profile_photo ? userDetails?.profile_photo : "",
    story: userDetails?.story ? userDetails.story : "",
    timezone: userDetails?.timezone ? userDetails.timezone : "",
    user_id: USER_ID,
    price_per_session: userDetails?.price_per_session
      ? userDetails.price_per_session
      : "",
  });

  useEffect(() => {
    if (state === 7 && registrationProcessData.status === "registered")
      handleSubmit();
  }, [registrationProcessData]);

  useEffect(() => {
    if (state === 7)
      setRegistrationProcessData((data) => ({ ...data, status: "registered" }));
  }, [state]);

  const onValidate = (value, name) => {
    setCardOneErrors((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };
  const onValidateTwo = (value, name) => {
    setCardTwoErrors((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };
  const onValidateThree = (value, name) => {
    setCardThreeErrors((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };
  const onValidateFour = (value, name) => {
    setCardFourErrors((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };
  const onValidateFive = (value, name) => {
    setCardFiveErrors((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };
  const onValidateSix = (value, name) => {
    setCardSixErrors((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };

  const [cardOneErrors, setCardOneErrors] = useState({
    country: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
    language: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
    experties: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
    experience: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
  });
  const [cardTwoErrors, setCardTwoErrors] = useState({
    title: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateTwo,
    },
    company_or_school: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateTwo,
    },
  });
  const [cardThreeErrors, setCardThreeErrors] = useState({
    leadership: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateThree,
    },
    mentorship: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateThree,
    },
    job_search: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateThree,
    },
    career_advice: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateThree,
    },
    skills: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateThree,
    },
  });
  const [cardFourErrors, setCardFourErrors] = useState({
    profile_photo: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateFour,
    },
  });
  const [cardFiveErrors, setCardFiveErrors] = useState({
    price_per_session: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateFive,
    },
  });
  const [cardSixErrors, setCardSixErrors] = useState({
    story: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateSix,
    },
  });

  const validateFormOne = () => {
    let isInvalid = false;
    Object.keys(cardOneErrors).forEach((x) => {
      const errObj = cardOneErrors[x];
      if (errObj.errorMsg) {
        isInvalid = true;
      } else {
        let type = typeof registrationProcessData[x];
        if (type === "string") {
          if (errObj.isReq && !registrationProcessData[x]) {
            isInvalid = true;
            onValidate(true, x);
          }
        } else {
          if (errObj.isReq && !registrationProcessData[x]?.length) {
            isInvalid = true;
            onValidate(true, x);
          }
        }
      }
    });
    return !isInvalid;
  };

  const validateFormTwo = () => {
    let isInvalid = false;
    Object.keys(cardTwoErrors).forEach((x) => {
      const errObj = cardTwoErrors[x];
      if (errObj.errorMsg) {
        isInvalid = true;
      } else {
        let type = typeof registrationProcessData[x];
        if (type === "string") {
          if (errObj.isReq && !registrationProcessData[x]) {
            isInvalid = true;
            onValidate(true, x);
          }
        } else {
          if (errObj.isReq && !registrationProcessData[x]?.length) {
            isInvalid = true;
            onValidate(true, x);
          }
        }
      }
    });
    return !isInvalid;
  };

  const validateFormThree = () => {
    let isInvalid = false;
    Object.keys(cardThreeErrors).forEach((x) => {
      const errObj = cardThreeErrors[x];
      if (errObj.errorMsg) {
        isInvalid = true;
      } else {
        if (errObj.isReq && !registrationProcessData[x]?.length) {
          isInvalid = true;
          onValidateThree(true, x);
        }
      }
    });
    return !isInvalid;
  };
  const validateFormFour = () => {
    let isInvalid = false;
    Object.keys(cardFourErrors).forEach((x) => {
      const errObj = cardFourErrors[x];
      if (errObj.errorMsg) {
        isInvalid = true;
      } else {
        if (errObj.isReq && !registrationProcessData[x]) {
          isInvalid = true;
          onValidateFour(true, x);
        }
      }
    });
    return !isInvalid;
  };
  const validateFormFive = () => {
    let isInvalid = false;
    Object.keys(cardFiveErrors).forEach((x) => {
      const errObj = cardFiveErrors[x];
      if (errObj.errorMsg) {
        isInvalid = true;
      } else {
        if (errObj.isReq && !registrationProcessData[x]) {
          isInvalid = true;
          onValidateFive(true, x);
        }
      }
    });
    return !isInvalid;
  };
  const validateFormSix = () => {
    let isInvalid = false;
    Object.keys(cardSixErrors).forEach((x) => {
      const errObj = cardSixErrors[x];
      if (errObj.errorMsg) {
        isInvalid = true;
      } else {
        if (errObj.isReq && !registrationProcessData[x]) {
          isInvalid = true;
          onValidateSix(true, x);
        }
      }
    });
    return !isInvalid;
  };

  const handlerRequiredSubmit = () => {
    if (state > 0 && state < 8) {
      switch (state) {
        case 1:
          const isValidOne = validateFormOne();
          if (!isValidOne) {
            changestate(state);
            return false;
          } else {
            if (isValidOne) {
              changestate(state + 1);
              return true;
            }
          }
        case 2:
          const isValidTwo = validateFormTwo();
          if (!isValidTwo) {
            changestate(state);
            return false;
          } else {
            if (isValidTwo) {
              changestate(state + 1);
              return true;
            }
          }
        case 3:
          const isValidThree = validateFormThree();
          if (!isValidThree) {
            changestate(state);
            return false;
          } else {
            if (isValidThree) {
              changestate(state + 1);
              return true;
            }
          }
        case 4:
          const isValidFour = validateFormFour();
          if (!isValidFour) {
            changestate(state);
            return false;
          } else {
            if (isValidFour) {
              changestate(state + 1);
              return true;
            }
          }
        case 5:
          const isValidFive = validateFormFive();
          if (!isValidFive) {
            changestate(state);
            return false;
          } else {
            if (isValidFive) {
              changestate(state + 1);
              return true;
            }
          }
        case 6:
          const isValidSix = validateFormSix();
          if (!isValidSix) {
            changestate(state);
            return false;
          } else {
            if (isValidSix) {
              changestate(state + 1);
              return true;
            }
          }
        default:
          return;
      }
    }
  };

  const [card, setCard] = useState(
    <One
      registrationProcessData={registrationProcessData}
      errors={cardOneErrors}
      setRegistrationProcessData={setRegistrationProcessData}
    />
  );

  const changestate = (index) => {
    if (index > 0 && index < 8) {
      if (state < index) {
        handleSubmit();
      }

      setCurrState(state);
      setState(index);
      // setTimeout(() => {
      // setCurrState(state);
      switch (index) {
        case 1:
          return setCard(
            <One
              registrationProcessData={registrationProcessData}
              errors={cardOneErrors}
              setRegistrationProcessData={setRegistrationProcessData}
              state={state}
              setState={setState}
            />
          );
        case 2:
          return setCard(
            <Two
              registrationProcessData={registrationProcessData}
              errors={cardTwoErrors}
              setRegistrationProcessData={setRegistrationProcessData}
            />
          );
        case 3:
          return setCard(
            <Three
              registrationProcessData={registrationProcessData}
              errors={cardThreeErrors}
              setRegistrationProcessData={setRegistrationProcessData}
            />
          );
        case 4:
          return setCard(
            <Four
              registrationProcessData={registrationProcessData}
              errors={cardFourErrors}
              setRegistrationProcessData={setRegistrationProcessData}
            />
          );
        case 5:
          return setCard(
            <TimeZoneAndPrice
              errors={cardFiveErrors}
              registrationProcessData={registrationProcessData}
              setRegistrationProcessData={setRegistrationProcessData}
            />
          );
        case 6:
          return setCard(
            <Six
              errors={cardSixErrors}
              registrationProcessData={registrationProcessData}
              setRegistrationProcessData={setRegistrationProcessData}
            />
          );
        case 7:
          return setCard(
            <Seven
              registrationProcessData={registrationProcessData}
              setRegistrationProcessData={setRegistrationProcessData}
            />
          );
        case 8:
          return setCard(<AvailableHours />);
        default:
          return setCard(
            <Overlay overlayData={data} setOverlayData={setData} />
          );
      }
      // }, 2000);
    }
  };
  const profileData = useSelector((state) => {
    return state.updateProfileReducer;
  });

  const handleSubmit = () => {
    dispatch(updateProfile(registrationProcessData));
  };

  // useEffect(() => {
  //   if (!profileData.loading && profileData.status) {
  //     setState(7);
  //     changestate(7);
  //   }
  // }, [profileData]);

  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardOneErrors.country.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardOneErrors.language.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardOneErrors.experties.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardOneErrors.experience.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardTwoErrors.title.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardTwoErrors.company_or_school.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardThreeErrors.leadership.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardThreeErrors.mentorship.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardThreeErrors.job_search.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardThreeErrors.skills.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardThreeErrors.career_advice.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardFourErrors.profile_photo.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardFiveErrors.price_per_session.errorMsg]);
  useEffect(() => {
    changestate(state);
    return () => { };
  }, [cardSixErrors.story.errorMsg]);

  return (
    <>



      <div
        className="container-fluid"
        style={{
          paddingLeft: "0",
          paddingRight: "0",
        }}
      >
        <div className="transparenthead">
          <Header />
        </div>
      </div>


      {/* <!-- Breadcrum --> */}
      <div>

        <section
          className="aapply-mentor-sec"
          style={{ background: "transparent" }}
        >
         
          <div className="container">
            {state <= 6 ? (
              <div>
                <div className="steps-count">
                  <p className=" mb-1 font-size-14 steps-position">
                    <span style={{ color: "black" }}>STEP {state} of 6</span>
                  </p>
                </div>
              </div>
            ) : null}
            {/* ---------------------------registration data ------------------------------------------ */}
            {state === 7 ? (
              <div className="mt-5 info-container">{card}</div>
            ) : (
              <form className=" mx-auto registraion-Process-main-page">
                <div className="mt-2 info-container">
                  <div style={{ textAlign: "center", fontSize: "1.1rem" }}>
                    Mentor Basic information
                  </div>
                  <FontAwesomeIcon
                    className="icons-back"
                    icon={faArrowLeft}
                    size="2x"
                    onClick={() => {
                      setCurrState(state);
                      changestate(state - 1);
                    }}
                  />
                  <div className="mt-5 regstration-card-container">
                    {card}
                  </div>
                  <div className="row ">
                    <div className="col back-btn  ">
                      <a
                        className="Back_button "
                        onClick={() => changestate(state - 1)}
                      >
                        Back
                      </a>
                    </div>
                    <div className="col col-md-auto">
                      {state == 8 ? (
                        <button
                          type="submit"
                          className="btn-lg"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      ) : state == 7 ? (
                        <a href="/mentor-dashboard">
                          <button type="button" className="btn-lg">
                            Go to Home
                          </button>
                        </a>
                      ) : state == 8 ? (
                        <a href="/mentor-dashboard">
                          <button
                            type="button"
                            className="btn-lg"
                            onClick={() => {
                              setCurrState(state);
                              changestate(state + 1);
                            }}
                          >
                            Continue
                          </button>
                        </a>
                      ) : (
                        <button
                          type="button"
                          className="btn-lg "
                          onClick={(e) => {
                            e.preventDefault();
                            handlerRequiredSubmit();
                          }}
                        >
                          {state == 6 ? "Submit" : "Continue"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </section>
      </div>
      {/* <!-- Footer --> */}
      {/* <Footer /> */}
    </>
  );
};

export default ApplyAsMentor;
