import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import crossIcon from "../../../assets/images/cross-icon.png";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { appendFormData } from '../../../redux/actions/course';

const AddTextModal = ({ OpenModal, setOpenModal }) => {
    console.log("chapter itme", OpenModal)
    const loader = false;
    const dispatch = useDispatch();
    const [message, setMessage] = useState(null);

    const [formData, setheadingData] = useState({
        title: "",
        type: "ADD_TEXT"
    });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData?.title) {
        setMessage("Please enter valid details")
        setTimeout(() => {
            setMessage(null)
        }, 2000);
    } else {
        let actionType;

        switch (OpenModal?.add_type) {
            case 'ADD_CHAPTER':
                actionType = "ADD_CHAPTER_TO_UNIT_BY_INDEX";
                break;
            case 'ADD_UNIT':
                actionType = "ADD_UNIT";
                break;
            default:
                actionType = "APPEND_FORM_DATA";
                break;
        }

        dispatch(appendFormData(actionType, OpenModal?.chapterIndex, OpenModal?.unitIndex, formData));
        handleCancel()
    }
}
    const handleCancel = () => {

        setOpenModal((prevState) => ({ ...prevState, show: false }))
        setheadingData((pre) => ({
            ...pre,
            title: ''
        }))
    }
    return (
        <>
            {loader && (
                <div className="loader" style={{ zIndex: 9999 }}>
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            )}
            <Modal
                size="xl"
                centered
                show={OpenModal.show}
                onHide={() =>
                    setOpenModal((prevState) => ({ ...prevState, show: false }))
                }
            >

                <ModalBody>

                    <button
                        type="button"
                        className="cloose"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                            setOpenModal((prevState) => ({ ...prevState, show: false }))
                        }
                    >
                        <div className="closebuttons">
                            <span aria-hidden="true">
                                <img src={crossIcon} />
                            </span>
                        </div>
                    </button>

                    <div className="create_course_modal" style={{ background: "white" }}>
                        <div className="modal-body">
                            <div className="modal-container">
                                <div className="modal-header">
                                    <div className="modal-title h5">
                                        New Text
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <form >
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label className="session__label">
                                                        Text: *
                                                    </label>
                                                    <input
                                                        type="Text"
                                                        name="heading"
                                                        id="heading"
                                                        placeholder="Enter Text"
                                                        required=""
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            setheadingData((pre) => ({
                                                                ...pre,
                                                                title: e.target.value,
                                                            }))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="get-started-cls why_choose_ment">
                                                <a href="#">
                                                    <button type="button" onClick={handleCancel} className="find-btn btn btn-primary">
                                                        Cancel
                                                    </button>
                                                </a>
                                                <button
                                                    onClick={handleSubmit}
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                            {
                                                message && <p> {message}</p>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </ModalBody>

            </Modal>


        </>
    );
}

export default AddTextModal;
