import React, { useState } from "react";

const MessageForm = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (message.trim() !== "") {
      onSendMessage(message);
      setMessage("");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={message}
        onChange={handleMessageChange}
        placeholder="Type your message..."
      />
      <button type="submit">Send</button>
    </form>
  );
};

const Message = ({ content, sender }) => {
  return (
    <div>
      <strong>{sender}: </strong>
      <span>{content}</span>
    </div>
  );
};

const MessageList = ({ messages }) => {
  return (
    <div>
      {messages.map((message, index) => (
        <Message
          key={index}
          content={message.content}
          sender={message.sender}
        />
      ))}
    </div>
  );
};

const MentorMenteeMessaging = () => {
  const [messages, setMessages] = useState([]);

  const sendMessage = (message) => {
    const newMessage = {
      content: message,
      sender: "Mentor", // or 'Mentee'
    };
    setMessages([...messages, newMessage]);
  };

  return (
    <div>
      <h1>Mentor-Mentee Messaging</h1>
      <MessageList messages={messages} />
      <MessageForm onSendMessage={sendMessage} />
    </div>
  );
};

export default MentorMenteeMessaging;
