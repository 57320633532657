import React from 'react';

const Rewards = () => {
  return (
  <>
          <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
              <div className="ant-row container-title-section rewards-nav ">
                  <div className="ant-col header-section ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-9 ant-col-md-offset-1 ant-col-lg-8 ant-col-lg-offset-1 ant-col-xl-6 ant-col-xl-offset-1 ant-col-xxl-5 ant-col-xxl-offset-1 ">
                      <h4 className="ant-typography layout-header ">
                          Weekly rewards
                      </h4>
                  </div>
                  <div className="ant-col ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-14 ant-col-md-offset-0 ant-col-lg-14 ant-col-lg-offset-0 ant-col-xl-12 ant-col-xl-offset-0 ant-col-xxl-10 ant-col-xxl-offset-0 ">
                      <div className="ant-row ant-row-center ">
                          <div className="ant-col title-rewards-section ant-col-xs-8 ">
                              <div className="ant-typography ">
                                  <div className="ant-typography title ">
                                      Total earnings
                                  </div>
                                  <div className="ant-typography value ">
                                      <svg
                                          stroke="currentColor"
                                          fill="none"
                                          strokeWidth={2}
                                          viewBox="0 0 24 24"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M18 5h-11h3a4 4 0 0 1 0 8h-3l6 6" />
                                          <path d="M7 9l11 0" />
                                      </svg>
                                      0
                                  </div>
                              </div>
                              <div className="ant-typography ">
                                  <img
                                      alt="Total Earnings"
                                      loading="lazy"
                                      width={30}
                                      height={20}
                                      decoding="async"
                                      data-nimg={1}
                                      className="icon"
                                      srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Ficon-total-earning-rewards.svg&w=32&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Ficon-total-earning-rewards.svg&w=64&q=75 2x"
                                      src="/_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Ficon-total-earning-rewards.svg&w=64&q=75"
                                      style={{ color: "transparent", height: "auto" }}
                                  />
                              </div>
                          </div>
                          <div className="ant-col title-rewards-section ant-col-xs-8 ">
                              <div className="ant-typography ">
                                  <div className="ant-typography title ">
                                      Available{" "}
                                      <span className="ant-typography ">rewards</span>
                                  </div>
                                  <div className="ant-typography value ">0</div>
                              </div>
                              <div className="ant-typography ">
                                  <img
                                      alt="Total Earnings"
                                      loading="lazy"
                                      width={30}
                                      height={20}
                                      decoding="async"
                                      data-nimg={1}
                                      className="icon"
                                      srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Ficon-total-earning-available.svg&w=32&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Ficon-total-earning-available.svg&w=64&q=75 2x"
                                      src="/_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Ficon-total-earning-available.svg&w=64&q=75"
                                      style={{ color: "transparent", height: "auto" }}
                                  />
                              </div>
                          </div>
                          <div className="ant-col title-rewards-section ant-col-xs-8 ">
                              <div className="ant-typography ">
                                  <div className="ant-typography title ">
                                      Claimed{" "}
                                      <span className="ant-typography ">rewards</span>
                                  </div>
                                  <div className="ant-typography value ">0</div>
                              </div>
                              <div className="ant-typography ">
                                  <img
                                      alt="Total Earnings"
                                      loading="lazy"
                                      width={30}
                                      height={20}
                                      decoding="async"
                                      data-nimg={1}
                                      className="icon"
                                      srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Ficon-total-earning-claimed.svg&w=32&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Ficon-total-earning-claimed.svg&w=64&q=75 2x"
                                      src="/_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Ficon-total-earning-claimed.svg&w=64&q=75"
                                      style={{ color: "transparent", height: "auto" }}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="ant-row container-children ">
                  <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
                      <div className="container ">
                          <div className="ant-row ant-row-center ">
                              <div className="ant-col ant-col-xs-22 ant-col-md-12 ">
                                  <div className="ant-result ant-result-error ">
                                      <div className="ant-result-icon">
                                          <img
                                              alt="No Rewards"
                                              loading="lazy"
                                              width={220}
                                              height={20}
                                              decoding="async"
                                              data-nimg={1}
                                              className=""
                                              srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Fempty-rewards.svg&w=256&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Fempty-rewards.svg&w=640&q=75 2x"
                                              src="/_next/image?url=%2Fimages%2Fdashboard%2Frewards%2Fempty-rewards.svg&w=640&q=75"
                                              style={{ color: "transparent", height: "auto" }}
                                          />
                                      </div>
                                      <div className="ant-result-title">
                                          <div
                                              className="ant-typography "
                                              style={{ fontSize: 20 }}
                                          >
                                              No rewards available at the moment, <br />
                                              come back soon
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

  </>
  );
}

export default Rewards;
