import { Button, Col, Progress, Row, Typography, message } from "antd";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { TbCircleDotted } from "react-icons/tb";


const { Paragraph } = Typography;

let CHECKLIST_VIEWED = false;

const DashboardChecklist = (
    props
) => {
    const [personType, setPersonType] = useState("");
    const [activeItem, setActiveItem] = useState(0);
    const { checkList, category = "beginner", type = "banner", user } = props;
    const checkTerms = checkList?.steps;


    const updatePersonType = () => {
        if (personType === "") {
            const personTypeLocal = null;
            setPersonType(personTypeLocal !== null ? personTypeLocal : "");
        }
    };

    const itemClicked = async (checkitem) => {

        if (checkitem?.notify_action && !checkitem?.done) {

        } else {
        }
    };

    useEffect(() => {
        if (checkList && checkTerms) {
            if (checkList?.completed_steps < checkList?.total_steps) {
                const result = checkTerms?.find((val) => !val?.done);
                setActiveItem(result?.id);
            } else {
                setActiveItem(checkTerms[0]?.id);
            }
        }
    }, [checkList, checkTerms]);

    useEffect(() => {
        if (!CHECKLIST_VIEWED && checkTerms && personType) {
            CHECKLIST_VIEWED = true;
        }
    }, [checkTerms, personType]);

    useEffect(() => {
        updatePersonType();
    }, []);

    return (
        <Paragraph
            className={`checklist-card ${type === "Floating Onboarding" ? "floating-checklist" : ""
                }`}
        >
            <Row justify="space-between" className="header" align="middle">
                <Col span={18}>
                    <Paragraph className="heading">
                        {category === "beginner"
                            ? dayjs(user?.date_joined).add(7, "d") > dayjs()
                                ? `Launch your page by ${dayjs(user?.date_joined)
                                    .add(7, "d")
                                    .format("Do MMM")}`
                                : "Get started with Topmate"
                            : "Advance checklist"}
                    </Paragraph>

                    <Paragraph className="sub-head">
                        {dayjs(user?.date_joined).add(7, "d") > dayjs()
                            ? personType === "A"
                                ? "Get a chance to be featured among the hottest debuts at Times Square 🗽"
                                : "Get featured on our exclusive new launches page ✨"
                            : "Your personalised guide to launch your page"}
                        {/* Use this personalized guide to get your page up and running */}
                    </Paragraph>
                </Col>
                <Col>
                    <Progress
                        type="circle"
                        percent={
                            (checkList?.completed_steps / checkList?.total_steps) * 100
                        }
                        format={(percent) =>
                            `${checkList?.completed_steps}/${checkList?.total_steps}`
                        }
                        size={72}
                    />
                </Col>
            </Row>
            {checkTerms &&
                checkTerms?.map((item, idx) => {
                    return (
                        <Col key={item?.id}>
                            {activeItem === item?.id ? (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <Col
                                        className="active-checklist-item"
                                        style={
                                            idx + 1 === checkList?.total_steps
                                                ? { borderRadius: "0px 0px 24px 24px" }
                                                : {}
                                        }
                                    >
                                        <Row justify="space-between">
                                            {/* <Row> */}
                                            <Col xs={1} md={1}>
                                                {item?.done ? (
                                                    <BsFillCheckCircleFill color="#008060" size={16} />
                                                ) : (
                                                    <TbCircleDotted size={16} />
                                                )}
                                            </Col>
                                            <Col xs={22} md={22} className="service-title">
                                                {item?.title}
                                                <Paragraph className="sub-head">{item?.desc}</Paragraph>
                                                {/* <Link href={item?.link}> */}
                                                <Button
                                                    className="btn-dark"
                                                    style={{ marginTop: "16px" }}
                                                    onClick={() => itemClicked(item)}
                                                >
                                                    {item?.cta}
                                                </Button>
                                                {/* </Link> */}
                                            </Col>
                                            <Col xs={1} md={1}>
                                                <FaChevronUp
                                                    onClick={() => setActiveItem(0)}
                                                    className="cursor-hover"
                                                />
                                            </Col>
                                            {/* </Row> */}
                                        </Row>
                                    </Col>
                                </motion.div>
                            ) : (
                                <Row
                                    align="middle"
                                    justify="space-between"
                                    className="checklist-item cursor-hover"
                                    style={
                                        idx + 1 === checkList?.total_steps
                                            ? { borderRadius: "0px 0px 24px 24px" }
                                            : {}
                                    }
                                    onClick={() => {
                                        setActiveItem(item?.id);
                                    }}
                                >
                                    <Col xs={1} md={1}>
                                        {item?.done ? (
                                            <BsFillCheckCircleFill
                                                color="#008060"
                                                fill="#008060"
                                                size={16}
                                            />
                                        ) : (
                                            <TbCircleDotted size={16} />
                                        )}
                                    </Col>
                                    <Col xs={22} md={22} className="service-title">
                                        {item?.title}
                                    </Col>
                                    <Col xs={1} md={1}>
                                        <FaChevronDown />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    );
                })}
        </Paragraph>
    );
};

export default DashboardChecklist;
