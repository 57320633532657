import React, { useEffect, useState } from 'react';
import Feature_req from '../Image/Feature_req.svg'
import Guide from '../Image/Guide.svg'
import profilepic from '../Image/profilepic.jpg'
import videocallsvg from '../Image/videocallsvg.svg'
import digitalproductSvg from '../Image/digitalproductSvg.svg'
import videocallemhear from '../Image/videocallemhear.svg'
import webinarSvg from '../Image/webinarSvg.svg'
import priorityDm from '../Image/priorityDm.svg'

import emptyStateFY from '../Image/empty_state_FY.svg'
import no_notifications from '../Image/no_notifications.svg'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../../redux/actions/login';
import { REACT_USER_ID } from '../../auth/tokenProvider';
import { Avatar, Card, Col, Divider, Image, Row, Tag, Typography, message } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import Help from './Modal/Help';
import { getAllMentorSessionRequest } from '../../../redux/actions/mentor';
import { UserOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const user_id = REACT_USER_ID;
    const [data, setdata] = useState()
    const [bookingData, setBookingData] = useState(null)
    const [isLoading, setisLoading] = useState(false)
    const [error, seterror] = useState()

    const [showSuppportModal, setSupportModalVisibility] =
        useState(false);


    useEffect(() => {
        dispatch(getUserDetails(user_id));
        dispatch(getAllMentorSessionRequest(user_id));

    }, [dispatch, user_id]);

    const userDetails = useSelector(
        (state) => state?.userDetailsReducer
    );

    useEffect(() => {
        const {data,loading,status,error}= userDetails
        
        setdata(data[0])
        setisLoading(loading)
        seterror(error)
    }, [userDetails])

    const url_profile = `mentorpal.ai/mentor-details/${data?.name}_${data?.user_id}`;
    
    const [messageApi, contextHolder] = message.useMessage();


    const handleCopy_profile = (id) => {
        navigator.clipboard.writeText(url_profile);
        messageApi.open({
            type: 'success',
            content: " copied to clipboard!",
        });
    };

    const newBooking = [
        {
            "type": "priority_dm",
            "name": "Priority DM",
            "price": "₹Free"
        },
        {
            "type": "group_booking",
            "name": "sfhgdfhgf",
            "price": "Free"
        },
        {
            "type": "digital_product",
            "name": "Digital Product",
            "price": "Free"
        },
        {
            "type": "one_to_one",
            "name": "sdfgyjk",
            "price": "Free"
        },
        {
            "type": "priority_dm",
            "name": "Priority DM",
            "price": "₹Free"
        }
    ]




    const mentorSessionReducerData = useSelector(
        (state) => state?.getAllMentorSessionReducer
    );

    useEffect(() => {
        const { loading, status, data, error } = mentorSessionReducerData
        setisLoading(loading)
        setBookingData(data)

    }, [mentorSessionReducerData]);

    const getBookingImage = (type) => {
        switch (type) {
            case 'priority_dm':
                return priorityDm;
            case 'one_to_one':
                return webinarSvg;
            case 'group_booking':
                return videocallemhear;
            case 'digital_product':
                return digitalproductSvg;
            default:
                return videocallsvg;
        }
    };

    return (
        <>
            {contextHolder}
            {isLoading ? (
                <div className="loader open_sessions_loader">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            ) : (

                <>

                    <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
                        <div className="ant-typography container-title-section dashboard-nav ">
                            <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-0 ">
                                <Row gutter={[16, 16]} align="middle" justify="space-between" className="mobile-home-header ">
                                    <Col span={16} >
                                        <Title level={10} className="header-name ">Hi, {data?.name}</Title>

                                    </Col>
                                    <Row
                                        gutter={6}
                                    >
                                        <Col

                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <a
                                                className="copy-profile"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={url_profile}
                                            >
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    strokeWidth={0}
                                                    viewBox="0 0 24 24"
                                                    color="#8C5300"
                                                    height={24}
                                                    width={24}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{ color: "rgb(140, 83, 0)" }}
                                                >
                                                    <path d="M18.25 15.5a.75.75 0 0 1-.75-.75V7.56L7.28 17.78a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734L16.44 6.5H9.25a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-.75.75Z" />
                                                </svg>
                                            </a>
                                        </Col>
                                        <Col
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <Text className=" copy-profile ">
                                                <svg
                                                    onClick={() => handleCopy_profile()}

                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeWidth={0}
                                                    viewBox="0 0 15 15"
                                                    color="#8C5300"
                                                    height={24}
                                                    width={24}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{ color: "rgb(140, 83, 0)" }}
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </Text>
                                        </Col>
                                    </Row>
                                </Row>
                            </div>
                            <Col className="ant-col ant-col-xs-0 ant-col-md-22 ant-col-md-offset-1 ">
                                <div className="ant-row ant-row-space-between ant-row-middle ">
                                    <Col
                                        justifyContent="flex-start"
                                        gutter={26}
                                        className=" header-section "

                                    >
                                        <Title style={{ fontSize: "2rem" }} className=" header ">Hi, {data?.name}</Title>
                                    </Col>
                                    <Row
                                        gutter={6}
                                        className="ant-row mt-3"
                                    // style={{ marginLeft: "-6px", marginRight: "-6px" }}
                                    
                                    >
                                        <Col
                                            gutter={6}

                                        >
                                            <div
                                                className="ant-typography ant-typography-ellipsis ant-typography-ellipsis-multiple-line link-container "
                                                style={{ WebkitLineClamp: 2 }}
                                            >
                                                <a target="_blank" rel="noopener noreferrer" href={url_profile}>
                                                    <div className="ant-space  ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small">
                                                        <div className="ant-space-item">

                                                            <Avatar

                                                                size={'large'}
                                                                src={data?.profile_photo === "" ? <UserOutlined /> : `${process.env.REACT_APP_IMAGES_BASE_URL}${data?.profile_photo}`}
                                                            />

                                                        </div>
                                                        <div className="ant-space-item">
                                                            <span className="ant-typography ">
                                                                <strong> {data?.name}_{data?.user_id}</strong>
                                                            </span>
                                                        </div>
                                                        <div className="ant-space-item">
                                                            <svg
                                                                stroke="currentColor"
                                                                fill="currentColor"
                                                                strokeWidth={0}
                                                                viewBox="0 0 24 24"
                                                                color="#8C5300"
                                                                height={24}
                                                                width={24}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                style={{ color: "rgb(140, 83, 0)" }}
                                                            >
                                                                <path d="M18.25 15.5a.75.75 0 0 1-.75-.75V7.56L7.28 17.78a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734L16.44 6.5H9.25a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-.75.75Z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                        <div
                                            className="ant-col copy-profile "
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <svg
                                                onClick={() => handleCopy_profile()}

                                                stroke="currentColor"
                                                fill="none"
                                                strokeWidth={0}
                                                viewBox="0 0 15 15"
                                                color="#8C5300"
                                                height={24}
                                                width={24}
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{ color: "rgb(140, 83, 0)" }}
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </div>

                        <Row className="container-children ">

                            <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
                                <div className="container-fluid home-page-design">
                                    <Row className=" second-row-section ">
                                        <Col span={24} offset={0} xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 14, offset: 0 }}  className="ant-col banner-container">
                                            {/* Do you Know */}
                                            <div className="ant-typography slider-container">
                                                <div className="swiper swiper-initialized swiper-horizontal mySwiper swiper-backface-hidden">
                                                    <div className="swiper-wrapper">
                                                        <div className="swiper-slide swiper-slide-active swiper-slide-next" style={{ marginRight: 20 }}>
                                                            <Row justify="space-between" align="middle" className="foryou-banner-card cursor-hover">
                                                                <Col xs={0} md={8}>
                                                                    <Image preview={false} alt="We curate templates for your social media, check them out" width={108} height={200} src={emptyStateFY} className="banner-image" />
                                                                </Col>
                                                                <Col xs={24} md={0}>
                                                                    <Row justify="space-between" align="middle">
                                                                        <Image preview={false} alt="We curate templates for your social media, check them out" width={88} height={20} src={emptyStateFY} />
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={24} md={14} style={{ textAlign: "left" }}>
                                                                    <Title level={4} className="banner-title">Did you know?</Title>
                                                                    <Text className="banner-desc">We curate templates for your social media, check them out</Text>
                                                                    <Tag className="new-tag" color="rgb(0, 128, 96)">
                                                                        NEW
                                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 16 16" color="#fff" height={24} width={24} xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}>
                                                                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                                                        </svg>
                                                                    </Tag>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-lock">
                                                        <span className="swiper-pagination-bullet swiper-pagination-bullet-active" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        {/* Booking Notification */}
                                        <Col xs={24}  className="ant-col ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-lg-10 ant-col-md-offset-0">
                                            <div className="ant-col ant-col-24 notification-container" style={{ borderBottom: "1px solid rgb(250, 250, 250)" }}>
                                                <Title level={4} className="ant-typography header">
                                                    New Bookings
                                                </Title>
                                                    {bookingData?.length === 0 ? (
                                                    <Row justify="center" align="middle" style={{ marginTop: 24 }}>
                                                        <Image alt="notifications" width={200} height={200} src={no_notifications} />
                                                    </Row>
                                                ) : (
                                                    <div className="ant-typography notification">
                                                        <div className="ant-typography cursor-hover notification-content">
                                                                    {bookingData?.map((item, index) => (
                                                                <div key={index} className="ant-row ant-row-space-between ant-row-middle" onClick={()=>navigate('/dashboard/calls/video')}>
                                                                    <div className="ant-col">
                                                                        <div className="ant-space ant-space-horizontal ant-space-align-center" style={{ gap: 12 }}>
                                                                            <div className="ant-space-item">
                                                                                <Image preview={false} alt="booking" width={40} height={40} src={getBookingImage(item?.type)} style={{ color: "transparent", height: "auto" }} />
                                                                            </div>
                                                                            <div className="ant-space-item">
                                                                                <div className="ant-col">
                                                                                    <Title level={5} className="ant-typography title">{"Call"}</Title>
                                                                                    <Text className="ant-typography desc">{item.mentee_details.name} · {item.product_details?.price === "" ? "Probono" : item?.product_details?.price}</Text>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ant-col ant-col-9" style={{ textAlign: "end" }}>
                                                                        <div className="ant-space ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small">
                                                                            <div className="ant-space-item" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* Need Some Help */}
                                    <div style={{ opacity: 1 }}>
                                        <div className="container-fluid home-help-section">
                                            <div>
                                            <Title level={1} className="ant-typography feed-section-content-header help-heading">
                                                Need some help?
                                            </Title>
                                            <Divider className="content-divider" />
                                            <div className="ant-typography guide-container">
                                                <Row gutter={[16, 16]} justify="start">
                                                    {[
                                                        { type: 'FEEDBACK', image: Feature_req, heading: 'Request feature', text: 'Share your feature request or feedback, we’re all ears', path: '/dashboard/feedback' },
                                                        { type: 'GUIDE', image: Guide, heading: 'Need help?', text: 'Reach out to community, support, or your personal assistant' },
                                                    ].map((item, index) => (
                                                        <Col key={index} xs={24} md={8}>
                                                            <Card
                                                                className="guide-container-card common-container-card cursor-hover"
                                                                style={{ padding: 0 }}
                                                                onClick={() => item.path ? navigate(item.path) : setSupportModalVisibility(true)}
                                                            >
                                                                <div className="ant-card-body">
                                                                    <img
                                                                        alt={item.type}
                                                                        loading="lazy"
                                                                        width={350}
                                                                        height={135}
                                                                        decoding="async"
                                                                        data-nimg={1}
                                                                        className="guide-container-image"
                                                                        src={item.image}
                                                                        style={{ color: "transparent", height: "auto" }}
                                                                    />
                                                                    <div className="ant-typography guide-container-type">{item.type}</div>
                                                                    <div className="ant-typography guide-container-heading text-underline common-text" onClick={() => item.path ? navigate(item.path) : setSupportModalVisibility(true)}>
                                                                        {item.heading}
                                                                    </div>
                                                                    <div className="ant-typography guide-container-card-normal-text">{item.text}</div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </div>
                                            </div>
                                            
                                        </div>

                                    </div>
                                </div>
                                <div> </div>
                            </div>
                        </Row>
                    </div>
                </>

            )}
            {
                showSuppportModal && (
                    <Help showSuppportModal={showSuppportModal} setSupportModalVisibility={setSupportModalVisibility} />
                )
            }
        </>
    );
}

export default Home;
