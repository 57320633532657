import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { getAllMentorSessionRequest } from "../../../../redux/actions/mentor";
import { REACT_USER_ID } from "../../../auth/tokenProvider";
import OpenSession from "./OpenSession";

const AllActiveSession = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_id = REACT_USER_ID;
  useEffect(() => {
    dispatch(getAllMentorSessionRequest(user_id));
  }, []);
  return (
    <div className="w-100">
      {/* <!-- Page Heading --> */}
      <div className="d-sm-flex align-items-center justify-content-between">
        {/* <h1 className="h3 mb-0 text-gray-800 main-title">Go Back</h1> */}
      </div>

      {/* <!-- Content Row --> */}
      <div className="row">
        <div className="col-xl-12 col-md-12">
          <div className="card border-0 mx-3">
            <div className="card-header header-table tab-header">
              <ul
                className="nav nav-tabs"
                role="tablist"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#tabs-1"
                    role="tab"
                    onClick={() => navigate("upcoming")}
                  >
                    Upcoming Sessions
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#tabs-2"
                    role="tab"
                    onClick={() => navigate("history")}
                  >
                    History Session
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body table-inner">
              <div className="tab-content">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllActiveSession;
