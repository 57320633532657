import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { triggerOpenNav } from "../../../../redux/actions/course";

const Learner = () => {
  const learnersdata = [
    {
      enroll: "9-09-2001",
      name: "Mohit",
      email: "mohit@gmail.com",
      assigned: "",
      status: "Active",
      actions: "View",
      expiry: "9-09-2005",
    },
    {
      enroll: "9-09-2001",
      name: "Mohit",
      email: "mohit@gmail.com",
      assigned: "",
      status: "Active",
      actions: "View",
      expiry: "9-09-2005",
    },
  ];

  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(learnersdata.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = learnersdata.slice(startIndex, endIndex);
  const dispatch = useDispatch();

  const handleTriggerOpenNavFromB = () => {
    dispatch(triggerOpenNav()); // Dispatch the action to trigger openNav
  };
  return (
    <>
      <section id="Header">
        <div className="header ">
          <span
            className='mobile_viewCourse'
            style={{ fontSize: '30px', cursor: 'pointer' }}
            onClick={handleTriggerOpenNavFromB}
          >
            &#9776;
          </span>
          <div className="ctitle" style={{ maxWidth: 1872 }}>
            Learner(0)
          </div>
        </div>
        <br></br>

        <div className="items-per-page">
          <label>
            Show
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
            entries
          </label>
        </div>
      </section>

      <div className="table-inner db-table">
        <div className="table-responsive">
          <table classNmae="table" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ width: "200px" }}>ENROLL DATE</th>
                <th>NAME</th>
                <th style={{ width: "500px" }}>EMAIL</th>
                <th>
                  ASSIGNED <br></br>THROUGH
                </th>
                <th>STATUS</th>
                <th>EXPIRY</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {learnersdata.map((item, index) => (
                <tr className="table-row">
                  <td className="py-3">{item.enroll}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.assigned}</td>
                  <td>{item.status}</td>
                  <td>{item.expiry}</td>
                  <td>{item.actions}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <br></br>
      <div
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
          marginTop: 0,
        }}
      >
        <button
          className="btn btn-primary"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous Page
        </button>
        <button
          className="btn btn-primary"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next Page
        </button>
      </div>
    </>
  );
};

export default Learner;
