import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const QueriesTab = () => {
    const navigate = useNavigate();

    const tabs = [
        { label: 'Pending', key: 'pending' },
        { label: 'Answered', key: 'answered' },
    ];

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleTabClick = (index, tabKey) => {
        setSelectedTabIndex(index);
        navigate(`/dashboard/queries/${tabKey}`);
    };

    return (
        <>
            <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
                <div className="ant-row container-title-section ">
                    <div className="ant-col header-section ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-14 ant-col-md-offset-1 ant-col-xl-12 ant-col-xl-offset-1 ant-col-xxl-12 ant-col-xxl-offset-1 ">
                        <h4 className="ant-typography layout-header ">
                            <div className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line ">
                                Priority DM
                            </div>
                        </h4>
                    </div>
                    <div className="ant-col tab-container ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xl-22 ant-col-xl-offset-1 ant-col-xxl-22 ant-col-xxl-offset-1 ">
                        <span className="ant-typography flex-center " style={{ overflowY: "auto" }}>
                            {tabs.map((tab, index) => (
                                <a
                                    key={index}
                                    className={`tab-link tab-link-common ${index === selectedTabIndex ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index, tab.key)}
                                >
                                    <span className="ant-typography tab-title ">
                                        <div className="ant-typography " style={{ display: "flex", alignItems: "center" }}>
                                            <span className="ant-typography ">{tab.label}</span>
                                        </div>
                                    </span>
                                </a>
                            ))}
                            <span className="ant-typography ">
                                <a
                                    className="tab-cta-link tab-link-common btn-dark"
                                    href="/dashboard/services/text"
                                >
                                    <span
                                        role="img"
                                        aria-label="sliders"
                                        className="anticon anticon-sliders"
                                        style={{ color: "rgb(255, 255, 255)" }}
                                    >
                                        <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="sliders"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z" />
                                        </svg>
                                    </span>
                                    <span className="ant-typography tab-title ">
                                        Configure
                                    </span>
                                </a>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="ant-row container-children ">
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export default QueriesTab;
