import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { crossIcon } from "../../../assets/images/export-images";
import { OpenToData, hireTalentFilter } from "./constant";
import "../cutsom.css";

const MobileFilter = ({ filterModal, setFilterModal }) => {
  return (
    <>
      <Modal
        size="xl"
        centered
        show={filterModal.show}
        onHide={() =>
          setFilterModal((prevState) => ({ ...prevState, show: false }))
        }
      >
        <ModalBody>
          <div
            type="button"
            className="cloose"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              setFilterModal((prevState) => ({ ...prevState, show: false }))
            }
          >
            <div className="closebuttons">
              <span aria-hidden="true">
                <img src={crossIcon} />
              </span>
            </div>
          </div>
          {/* <form> */}
          <div className="signup-row filter______row">
            <div className="filter__col signup-leftcol">
              <header className=" border-bottom">
                <p className="  hire-subpara">Talent filters</p>
              </header>

              <div className="Filters__OpenToFilters border-bottom ">
                <p className=" hire-subtext  font-weight-400  grey-2-text">
                  Open to
                </p>
                {OpenToData.map((item, index) => (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        color="#25D209"
                        radius={4}
                        width="32px"
                        height="32px"
                        className=" open__icon"
                      >
                        {item.icon}
                      </div>
                      <div className="ml-3">
                        <p className="hire-subtext font-weight-700">
                          {item.title}
                        </p>
                      </div>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id={`open-to-toggle-${index}`}
                        className="custom-control-input"
                      />
                      <label
                        title=""
                        htmlFor={`open-to-toggle-${index}`}
                        className="custom-control-label"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="filter__col signup-leftcol">
              <div className=" Talent___box">
                {hireTalentFilter?.map((dropdown) => (
                  <div className="" key={dropdown.title}>
                    <p className="hire-subtext font-weight-400 mb-8 grey-2-text">
                      {dropdown.title}
                    </p>
                    <div className="form-group">
                      <select
                        className="text-left form-control"
                        style={{
                          height: "50px",
                          width: "100%",
                          borderRadius: "13px",
                        }}
                        required
                      >
                        <option value=""> {`Eg: ${dropdown.title}`} </option>
                        {dropdown.options.map((option) => (
                          <option value={option.value} key={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ))}
              </div>

              <button
                height={50}
                type="button"
                className=" request_to_chat text-truncate w-100 btn--default btn btn-default"
              >
                Create Filter
              </button>
            </div>
          </div>
          {/* </form> */}
        </ModalBody>
      </Modal>
    </>
  );
};

export default MobileFilter;
