import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Button,
  Col,
  Pagination,
  PaginationProps,
  Row,
  Skeleton,
  Space,
  Typography,
  message,
} from "antd";
import ServiceCard from './PropsComponent/ServiceCard';
import { DigitalProductSericeData, oneOnOneserviceData, packageServiceData, priorityDmServiceData, subscriptionServiceData, webinarServiceData } from '../Data/Data';
import ServiceTemplates from './PropsComponent/serviceTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { getMentorCourseAction } from '../../../redux/actions/course';
import { REACT_USER_ID } from '../../auth/tokenProvider';


const serviceCardLayoutVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 0.1,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const serviceCardVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const MainServiceListing = () => {
  const dispatch = useDispatch()

  const [services, setServices] = useState(null); // Initialize with null or an empty array/object based on your use case
  const [serviceError, setServiceError] = useState(false);
  const [serviceLoading, setServiceLoading] = useState(false);

  const { service_type } = useParams();
  useEffect(() => {
    dispatch(getMentorCourseAction({ mentor_id: REACT_USER_ID, document_type: service_type }))

  }, [dispatch, service_type])
  const getServiceData = useSelector(
    (state) => state.getMentorCourseReducer
  );

  useEffect(() => {
    console.log("service", service_type)
    const { data, error, loading, status } = getServiceData
    setServiceLoading(loading);
    if (data && error === undefined && !loading) {
      setServices(data)
    }
    else if (!data && !loading && error !== undefined) {
      setServiceError(true)
      message.error("something went wrong")
    }
    // Simulating a fetch operation with setTimeout

  }, [service_type, getServiceData]);

  return (
    <>
      <Container type="fluid" className="dashboard-services">
        {serviceLoading ? (
          <>
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <Row gutter={{ xs: 40, md: 0 }} key={index}>
                <Col xs={{ span: 24 }} md={{ span: 13 }} lg={11}>
                  <CardPlaceholder />
                </Col>
              </Row>
            ))}
          </>
        ) : serviceError ? (
          <>
            <h1>Something went wrong</h1>
          </>
        ) : services?.length <= 0 ? (
          <>
            <h1>EmptyView tab</h1>
          </>
        ) : (
          <DataView services={services} />
        )}


      </Container>
    </>
  );
}
const DataView = ({ services }) => (
  <Row gutter={{ xs: 0, md: 20 }} className="services">
    <motion.div
      variants={serviceCardLayoutVariants}
      initial="hidden"
      animate="visible"
      style={{ width: "100%" }}
    >
      {services?.map((service, idx) => (
        <motion.div variants={serviceCardVariants} key={service?.id}>
          <ServiceCard service={service} idx={idx} />
        </motion.div>
      ))}

    </motion.div>
  </Row>
);
export const Container = (props) => {
  const { children, className, type, id, style } = props;
  const getClassNames = () => {
    let classes = "";
    if (type === "fluid") {
      classes = classes + "container-fluid ";
    } else {
      classes = classes + "container ";
    }
    if (className) {
      classes += className;
    }
    return classes;
  };
  return (
    <div className={getClassNames()} id={id} style={style}>
      {children}
    </div>
  );
};
export const CardPlaceholder = () => {
  return <Skeleton active paragraph={{ rows: 4 }} />;
};

export default MainServiceListing;
