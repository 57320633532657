import React from "react";
import DashboardHeader from "../DashboardHeader";
const GroupSession = () => {
    return (
      <>
        {/* <DashboardHeader /> */}
        <div className="heading">
          <h1>Hi,Neha!Let's get started mentoring in groups.</h1>
        </div>
        <h4 className="sub-heading">
          Where would you like this session to be happened?
        </h4>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <button type="button" className="btn btn-secondary btn-square-md">
                Video Link
                <h6>Google meet/Zoom/MS Team</h6>
              </button>
            </div>
          </div>
        </div>

        <div className="singgup-upp">
          <button className="btn " style={{ width: "124px" }}>
            Create Session
          </button>
        </div>
      </>
    );
};

export default GroupSession;
