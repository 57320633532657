import React from 'react'
import TrainingHeader from './TrainingHeader/TrainingHeader'
import TrainingHeroSection from './TrainingHeroSection/TrainingHeroSection'
import EnterpriseImg from './../../assets/images/enterprise.png'

const Enterprise = () => {
    return (
        <>
            <TrainingHeader />
            <TrainingHeroSection
                heading={'Empower Your Whole Workforce'}
                SubHeading={'Ensure that your team remains actively involved and support their continuous development'}
                btn={'Get a Demo'}
                BgImg={EnterpriseImg}
                text={'ENTERPRISE-WIDE TRAINING'}
            />
        </>
    )
}

export default Enterprise