
import { Link, useLocation, useNavigate } from "react-router-dom";
import { arNext, homeIcon } from "../../assets/images/export-images";

// Breadcrumbs.js
const Breadcrumbs = ({mentorDetails,background="#e9bae7"}) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const navigate = useNavigate();
  

  return (
   
    <div className="breadcrumb-sec" >
        <div className="container">
          <div className="breadcrumb-inner">
          <Link to='/'>
            <span>
              <img src={homeIcon} alt="home icon" />
            </span></Link>
            <span className="breadcrumb-space">
              <img src={arNext} alt="Next" />
            </span>
            {
              mentorDetails? (<span >
              <Link to="/">find-mentor</Link>  
              <span className="breadcrumb-space">
                <img src={arNext} alt="Next" />
              </span>
            </span>):""
            }
           
           {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;

        return isLast ? (
          <span key={name}>{name}</span>
        ) : (
          <span key={name}>
           {mentorDetails?<Link onClick={()=>navigate(-1)}>{name}</Link>:<Link to={routeTo}>{name}</Link>  }
            <span className="breadcrumb-space">
              <img src={arNext} alt="Next" />
            </span>
          </span>
        );
      })}
          </div>
        </div>
      </div>
    
)};

export default Breadcrumbs;