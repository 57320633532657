import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ServiceForm from "./ServiceForm";
import { useDispatch, useSelector } from "react-redux";
import { updateMentorCourseAction } from "../../../../redux/actions/course";
import { getServiceType } from "../../Data/Data";
import { TailSpin } from "react-loader-spinner";




export const SERVICE_TYPE_ENUM = {
    1: "video",
    2: "text",
    3: "webinar",
    4: "package",
    5: "document",
    6: "Subscription",
};

export const SERVICE_TYPE_DISPLAY = {
    1: "Video Call",
    2: "Text Query",
    3: "Webinar",
    4: "Package",
    5: "Document",
    6: "Subscription",
};

const AddServiceForm = (props) => {
    const { data, form } = props;
    const [activeId, setActiveId] = useState(data ? data?.schedule : null);
    const navigate = useNavigate();
    const duplicate = false
    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState("");
    const [questionHeader, setQuestionHeader] = useState("");
    const [AddQuestion, setAddQuestion] = useState(data?.questions?.length > 0 ? data?.questions : []);
    const [buttonText, setButtonText] = useState("");
    const [questionType, setQuestionType] = useState(1);
    const [questionduration, setQuestionduration] = useState(
        data?.promised_response_time?.toString() || "48"
    );
    const serviceType = data?.type;
    const [questionModal, setQuestionModal] = useState(false);
    const [id] = useState(data?.product_id || undefined);
    const [formEdit, setFormEdit] = useState([]);
    const [packageService, setPackageService] = useState([]);

    const [fileUrl, setFileUrl] = useState(data?.document_url || "");
    const [thumbnailUrl, setThumbnailUrl] = useState(
        data?.document_thumbnail_url || ""
    );

    const [uploadList, setUploadList] = useState(
        data?.images?.length > 0
            ? data?.images?.map((item, idx) => {
                return {
                    url: item,
                    name: `cover-image(${idx + 1})`,
                    status: "done",
                    uid: idx + 1,
                };
            })
            : []
    );

    const [thumbnailList, setThumbnailList] = useState(
        data?.document_thumbnail_url
            ? [
                {
                    uid: "-1",
                    name: "thumbnail",
                    status: "done",
                    url: data?.document_thumbnail_url,
                },
            ]
            : []
    );



    const isHidden = () => {
        if (data === undefined || data === null) {
            return false;
        } else {
            return !data?.is_visible;
        }
    };

    const isPriceForDonation = () => {
        if (data === undefined || data === null) return false;
        else return !data?.is_donation;
    };
    function isValidDate(dateString) {
        // Check if the dateString is a valid date
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
        return regex.test(dateString) && !isNaN(new Date(dateString));
    }


    const [initialValues, setInitialValues] = useState({
        type: data?.type ? data?.type : " ",
        title: data?.title ? data?.title : "",
        description: data?.description ? data?.description : "",
        duration: data?.duration ? data?.duration?.toString() : "15",
        promised_response_time:
            data?.promised_response_time ? data?.promised_response_time?.toString() : questionduration || undefined,
        ppp_enabled: data?.ppp_enabled ? data?.ppp_enabled : false,
        charge: data?.charge ? data?.charge : "0",
        has_flexible_charge: data?.has_flexible_charge ? data?.has_flexible_charge : false,
        schedule: activeId ? activeId : 1,
        is_hidden: isHidden(),
        is_donation: isPriceForDonation(),
        record_session: data?.record_session === true ? true : false,
        bookings_limit: Number(data?.bookings_limit) || 100,
        limit_participants: data?.bookings_limit === null ? false : true,
        date: data?.date
            ? isValidDate(data.date)
                ? new Date(data.date)
                : undefined
            : undefined,
        time: data?.time
            ? isValidDate(data.time)
                ? new Date(data.time)
                : undefined
            : undefined,

        break_after_call: data?.break_after_call ? data?.break_after_call : 0,
        number_of_pages: data?.number_of_pages ? data?.number_of_pages : 1,
        document_url: data?.document_url ? data?.document_url : '',
        document_thumbnail_url: data?.document_thumbnail_url ? data?.document_thumbnail_url : "",
        bundle_price: data?.charge ? data?.charge : 0,
        package_validity_in_months: data?.package_validity_in_months || 0,
        country: data?.country ? data?.country : '',
        show_original_charge: data?.original_charge > 0 ? true : false,
        original_charge: data?.original_charge ? data?.original_charge : '',
        suggested_price: data?.suggested_price ? data?.suggested_price : "",
        short_custom_description: data?.short_custom_description ? data?.short_custom_description : '',
        group_call_url: data?.group_call_url ? data?.group_call_url : null,
        show_sale_count: data?.show_sale_count ? data?.show_sale_count : false,
        show_seats_left: data?.show_seats_left ? data?.show_seats_left : false,
        cohort_no_of_sessions: data?.cohort_no_of_sessions ? data?.cohort_no_of_sessions : 1,
        cohort_weekdays: data?.cohort_weekdays,
        cohort_noun: data?.cohort_noun,
        images: data?.images?.length > 0 ? data?.images : [],
    });




    const editQuestion = (question_data) => {
        setActiveQuestion(question_data);
        setQuestionModal(true);
        setButtonText("Edit Question");
        setQuestionType(2);
        setQuestionHeader("Edit Question");
    };

    const onShowModal = (e) => {
        setActiveQuestion({});
        setQuestionHeader("New Question");

        setButtonText("Add Question");
        setQuestionType(1);
        e.preventDefault();
        setQuestionModal(true);
    };

    const dispatch = useDispatch();

    const edit = async (payload, values) => {
        try {
            dispatch(updateMentorCourseAction({ ...payload, ...values }));
        } catch (error) {
            console.error('Error updating:', error);
        }
    };
    const updateMentorCourseReducer = useSelector((state) => state.updateMentorCourseReducer)

    useEffect(() => {
        const { loading, error, data } = updateMentorCourseReducer
        setLoading(loading)
        if (error && !loading) {
            message.error(error || 'Something went wrong!');
        }

        if (!error && !loading && data) {
            message.success('Service Updated successfully!');
        }

    }, [updateMentorCourseReducer]);




    const handleSubmit = (values) => {
        if (data?.type === 5 && data?.links?.length == 0) {
            message.error("Upload documents");
        } else {
            const groupCallDateTime =
                values?.date && values?.time
                    ? values?.date?.format("YYYY-MM-DD") +
                    "T" +
                    values?.time?.format("HH:mm:ss")
                    : "";
            const payload = {
                "language_mode": ["hindi", "english"],
                course_topic: values?.title,
                document_type: getServiceType(serviceType),
                product_id: data?.product_id,
                questions: AddQuestion,
                title: values?.title,
                description: values?.description,
                duration: values?.duration,
                charge: [4, 6].includes(serviceType)
                    ? values?.bundle_price
                    : values?.charge,
                schedule: values?.schedule === 1 ? "" : values?.schedule,
                is_visible:
                    "is_hidden" in values ? !values?.is_hidden : data?.is_visible,
                promised_response_time: questionduration,
                bookings_limit: values?.limit_participants
                    ? Number(values?.bookings_limit)
                    : "",
                record_session: values?.record_session,
                break_after_call: values.break_after_call,
                document_url: fileUrl || data?.document_url || "",
                document_thumbnail_url: thumbnailUrl || "",
                number_of_pages: values?.number_of_pages || 0,
                package_validity_in_months: values?.package_validity_in_months || 0,
                has_flexible_charge: values?.has_flexible_charge,
                show_discount_code: values?.has_flexible_charge
                    ? false
                    : data?.show_discount_code,
                original_charge: values?.show_original_charge
                    ? values?.original_charge
                    : 0,
                type: serviceType === 6 ? 4 : serviceType,
                ppp_enabled:
                    (serviceType == 4 && values?.bundle_price == 0) ||
                        (serviceType !== 4 && values?.charge == 0)
                        ? false
                        : values?.ppp_enabled,
                suggested_price: values?.suggested_price,
                short_custom_description: values?.short_custom_description || "",
                group_call_url: values?.group_call_url || "",
                show_sale_count: values?.show_sale_count || false,
                show_seats_left: values?.show_seats_left || false,
                cohort_no_of_sessions:
                    form?.getFieldValue("cohort_no_of_sessions") || 1,
                cohort_weekdays: form?.getFieldValue("cohort_weekdays"),
                cohort_noun: form?.getFieldValue("cohort_noun") || "Webinar",
                images:
                    uploadList?.length > 0 ? uploadList?.map((item) => item?.url) : [],
            };
            if (packageService?.length > 0) {
                payload["package_services"] = packageService;
            }
            if (groupCallDateTime) {
                payload["specific_date"] = groupCallDateTime;
            }


            edit(payload, values);

        }
    };









    return (
        <>
            {
                loading ? (<div className="loader open_sessions_loader">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>) : (null)
            }

            <ServiceForm
                type={serviceType}
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                data={data}
                ServiceEditForm={form}
                buttonText={buttonText}
                questionduration={questionduration}
                questionHeader={questionHeader}
                addQuestion={AddQuestion}
                setaddQuestion={setAddQuestion}
                setQuestionduration={setQuestionduration}
                activeQuestion={activeQuestion}
                editQuestion={editQuestion}
                questionType={questionType}
                questionModal={questionModal}
                setQuestionModal={setQuestionModal}
                onShowModal={onShowModal}
                schedules={schedules}
                setFormEdit={setFormEdit}
                formEdit={formEdit}
                setFileUrl={setFileUrl}
                setThumbnailUrl={setThumbnailUrl}
                uploadList={uploadList}
                thumbnailList={thumbnailList}
                setThumbnailList={setThumbnailList}
                setUplaodList={setUploadList}
                setPackageService={setPackageService}
            />
        </>
    );
};

export default AddServiceForm;

