import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { crossIcon } from "../../../../assets/images/export-images";
import { useState } from "react";
import { REACT_USER_ID } from "../../../auth/tokenProvider";
import { sendChatMessageAction } from "../../../../redux/actions/common";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import LoginModal from "../../../modals/loginModal";

const SendRequestModal = ({
  opensendRequestModal,
  setOpensendRequestModal,
  mentor,
}) => {
  const dispatch = useDispatch();
  const [headerModal, setHeaderModal] = useState({});

  const loggedInUserStatus = localStorage.getItem("REACT_USER_ID")
    ? true
    : false;

  const sender_id = REACT_USER_ID;
  const [sendingData, setSendingData] = useState({
    sender_id: sender_id,
    receiver_id: mentor?.user_id,
    message: "",
    // message: `Dear ${mentor?.name},I greatly admire your expertise and achievements. Can you please mentor me?, Thanks`,
  });
  const SubmitRequest = () => {
    if (loggedInUserStatus) {
      console.log("SendRequestModal", sendingData);
      dispatch(sendChatMessageAction(sendingData));
      setSendingData((prevState) => ({
        ...prevState,
        message: "", // Reset the message input field
      }));
    } else {
      setHeaderModal({ modal: LoginModal, show: true });
    }
  };

  const isLoading = useSelector(
    (state) => state.sendChatMessageReducer?.loading
  );
  const isSuccess = useSelector(
    (state) => state.sendChatMessageReducer?.isSuccess
  );

  const MessageExample = [
    {
      id: 1,
      content: `Dear ${mentor?.name},I have immense respect for your expertise and accomplishments. Could you please consider mentoring me? Your guidance would be greatly appreciated. Thank you.`,
    },
    {
      id: 2,
      content: `Dear ${mentor?.name},Your remarkable expertise and achievements have truly inspired me. I would be honored if you could mentor me. Thank you for considering my request.`,
    },
    {
      id: 3,
      content: `Dear ${mentor?.name},I hold great admiration for your expertise and the milestones you have achieved. Would you kindly consider being my mentor? Your guidance would mean the world to me. Thank you.`,
    },
  ];
  const handleCopy = (id) => {
    const paragraph = MessageExample.find((p) => p.id === id).content;
    navigator.clipboard.writeText(paragraph);
    alert("Paragraph copied to clipboard!");
  };
  return (
    <>
      <Modal
        size="xl"
        centered
        show={opensendRequestModal.show}
        onHide={() =>
          setOpensendRequestModal((prevState) => ({
            ...prevState,
            show: false,
          }))
        }
      >
        <ModalBody>
          <button
            type="button"
            className="cloose"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              setOpensendRequestModal((prevState) => ({
                ...prevState,
                show: false,
              }))
            }
          >
            <div className="closebuttons">
              <span aria-hidden="true">
                <img src={crossIcon} />
              </span>
            </div>
          </button>
          <div className="MenteeGoal__Modal">
            <div className=" Set___Goal">
              <div className="activation__header">
                <div className="menteegoal_title text-center">
                  <h3>Send Message </h3>
                </div>
                <form action="">
                  <label className="label_feedback" htmlFor="">
                    Enter Your Message
                  </label>
                  <div className="form-group">
                    <div className="form-group">
                      <textarea
                        className="text-left form-control"
                        rows={40} // Set the number of visible rows
                        cols={40} // Set the number of visible columns
                        value={sendingData.message}
                        style={{ height: "10rem" }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const words = inputValue.split(" ");
                          const maxWordLimit = 100; // Define your maximum word limit here

                          if (words.length <= maxWordLimit) {
                            setSendingData((pre) => ({
                              ...pre,
                              message: inputValue,
                            }));
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="get-started-cls" id="get-started-cls">
                    <button
                      onClick={SubmitRequest}
                      type="button"
                      className="btn btn-primary"
                    >
                      Send Message
                    </button>
                    {isSuccess && <p>Request Sent Successfully!</p>}
                    {!isSuccess && <p>Request not sent</p>}
                  </div>
                </form>
                {isLoading ? (
                  <div className="loader open_sessions_loader">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                  </div>
                ) : null}
              </div>
              <div>
                <div className=" Sample__msg__item">
                  {MessageExample.map((p) => (
                    <div
                      className=" Sample__msg__Content  Sample___Size"
                      width={1366}
                      key={p.id}
                    >
                      <p className=" Sample___message grey-2-text text-justify  ">
                        {p.content}
                      </p>
                      <svg
                        onClick={() => handleCopy(p.id)}
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-clipboard"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                      </svg>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {headerModal?.modal && (
              <headerModal.modal
                headerModal={headerModal}
                setHeaderModal={setHeaderModal}
              />
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SendRequestModal;
