import React, { useState, useEffect, useRef } from "react";
import "./videoBanner.css";
import MentorVideo from "../../../src/videos/New video.mp4";
const VideoBackground = () => {
  const [headerModal, setHeaderModal] = useState({});

  return (
    <>
      <div id="video-background" className="video-background">
        <video autoPlay loop muted>
          <source src={MentorVideo} type="video/mp4" />
        </video>

        <div className=" text-overlay ">
          <h1 className="blackShadow-background">
            Unlock Minds, Transform Lives and Harness Your Expertise : Join our
            Family as a Mentor
          </h1>
          <div
            className="get-started-cls align-items-center text-center become_mentor_apply_btn"
            id="get-started-cls"
          >
            <button
              type="button"
              className="btn btn-primary "
            >
              <a href="/apply-as-mentor" className="apply-as-mentor-link">
                Apply as a Mentor
              </a>
            </button>
          </div>
        </div>
      </div>
      {headerModal?.modal && (
        <headerModal.modal
          headerModal={headerModal}
          setHeaderModal={setHeaderModal}
        />
      )}
    </>
  );
};

export default VideoBackground;
