import React from "react";
import DashboardHeader from "../DashboardHeader";
import Bannner from '../../../assets/images/upload.png'

const AboutSession = () => {
    return (
      <>
        {/* <DashboardHeader /> */}
        <div className="heading">
          <h1>Let's publish this session to the community.</h1>
        </div>
        <h4 className="sub-heading">What is this group session about?</h4>

        <div className="background">
          <img alt="background" src={Bannner} />
        </div>
        <div className="file_input">
          <input
            type="file"
            name="Browse.."
            id="file-upload"
            className="form-btn"
          />
          <span
            htmlFor="fileToUpload"
            style={{
              "margin-top": "-50px",
              "margin-left": "59px",
              width: "16%",
            }}
          >
            Upload Session Banner
          </span>
        </div>

        <div className="signup-row">
          <div className="signup-leftcol">
            <div className="sign-form-cls">
              <div className="form-group">
                <label className="control-llabel">Session Name or title </label>
                <input
                  type="text"
                  name=""
                  placeholder="Maximum 50 words"
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group">
                <label className="control-label">
                  Event Description or agenda
                </label>
                <textarea
                  type="text"
                  name=""
                  rows="8"
                  cols="100"
                  placeholder=""
                  className="form-control"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="singgup-upp">
          <button
            className="btn "
            style={{
              width: "124px",
            }}
          >
            Continue
          </button>
        </div>
        <div className="get-started-cls">
          <button
            style={{
              width: "124px",
              "margin-right": "-280px",
              "margin-top": "-147px",
            }}
            type="button"
            className="btn btn-primary"
          >
            Back
          </button>
        </div>
      </>
    );
};

export default AboutSession;
