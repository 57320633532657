import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Typography, message } from "antd"; import { IoClose } from "react-icons/io5";


const CancelWebinar = ({ serviceId, setCancelScreen }) => {
    const { Paragraph, Text } = Typography;


    const [sendLoading, setSendLoading] = useState(false);
    const [CancelWebinarForm] = Form.useForm();



    const onCancelWebinar = async (values) => {
        setSendLoading(true)
        console.log("cancel webinar")
            .then((res) => {
                setSendLoading(false);
                setCancelScreen(false);
                message.success("Webinar cancelled successfully");
            })
            .catch((error) => {
                setSendLoading(false);
                message.error("Error in webinar cancellation");
            });
    };
    return (


        <>

            <Paragraph className="send-email-container">
                <Row className="heading" justify="space-between" align="middle">
                    <Row align="middle">
                        <Text className="email-heading" style={{ marginLeft: "0px" }}>
                            Are you sure?
                        </Text>
                    </Row>

                    <IoClose
                        className="drawer-close-icon"
                        onClick={() => {
                            setCancelScreen(false);
                        }}
                    />
                </Row>
                <Col className="drawer-table">
                    <Text className="content-title">Reason for cancellation</Text>
                    <Paragraph style={{ paddingTop: "8px" }}>
                        <Form onFinish={onCancelWebinar} form={CancelWebinarForm}>
                            <Form.Item
                                name="reason"
                                rules={[
                                    {
                                        required: true,
                                        message: "Reason is required!",
                                    },
                                    {
                                        whitespace: true,
                                        message: "Reason cannot be empty!",
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                            <Button
                                className="cancel-btn"
                                block
                                style={{ marginTop: "24px", backgroundColor: "#DF5546" }}
                                htmlType="submit"
                                loading={sendLoading}
                            >
                                Cancel Webinar
                            </Button>
                        </Form>
                    </Paragraph>
                </Col>
            </Paragraph>
        </>
    );
}

export default CancelWebinar;
