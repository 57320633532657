import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { REACT_USER_ID } from '../auth/tokenProvider';
import { useNavigate } from 'react-router-dom';
import { FeedbackForm } from '../Feedback';
import arrowDownImg from "../../components/dashboard/images/arrow-down.png";
import priceImg from "../../components/dashboard/images/price.png";
import noMentorImg from "../../components/dashboard/images/no-mentor.png";
import preSess1Img from "../../components/dashboard/images/pre-sess-1.png";
import preSess2Img from "../../components/dashboard/images/pre-sess-2.png";
import preSess3Img from "../../components/dashboard/images/pre-sess-3.png";
import feedbackimg from '../../assets/images/join-img-2.png'

import { calendarImg, languageImg, mentorEvent } from "../../assets/images/export-images";
import { TailSpin } from 'react-loader-spinner';
import { getAllMentorSessionRequest } from '../../redux/actions/mentor';
import styles from "../MenteeAdminDashboard/MenteeAdminDashboard.module.css";


const MentorHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [feedbackModal, setFeedbackModal] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    let date = new Date();
    let datestring = date.toTimeString();
    const user_id = REACT_USER_ID;

    useEffect(() => {
        dispatch(getAllMentorSessionRequest(user_id));
    }, []);


    let countryname =
        datestring?.includes("india") | datestring?.includes("India")
            ? "India"
            : "";
    let currencyName = countryname === "India" ? "INR" : "USD";
    const userId =
        useSelector((state) => {
            return (
                state.loginReducer?.headers?.["user-id"] ||
                state.passwordLoginReducer?.headers?.["user-id"] ||
                state.socialLoginReducer?.headers?.["user-id"]
            );
        }) || REACT_USER_ID;
    useEffect(() => {
        import("../../assets/css/bootstrap.min.css");
        import("../../assets/css/font-awesome.min.css");
        import("../../assets/css/owl.carousel.min.css");
        import("../../assets/css/owl.theme.default.min.css");
        import("../../components/dashboard/css/dashboard-mentor-subcription.css");
        import("../../assets/css/custom.css");
        import("../../assets/css/header.css");
        import("../../assets/css/dashboard.css");
        import("../../assets/css/footer.css");
        import("../../assets/css/media.css");
    }, []);







    const [upcomingSessions, setUpcomingSessions] = useState([]);
    const [HistorySession, setHistorySession] = useState([]);






    const mentorSessionReducerData = useSelector(
        (state) => state?.getAllMentorSessionReducer
    );
    const mentorSessionData = mentorSessionReducerData?.data;
    useEffect(() => {
        setIsLoading(mentorSessionReducerData?.loading)
    }, [])


    const isMeetingTimeInFuture = (meetingTime) => {
        const currentDateTime = new Date();
        const meetingDateTime = new Date(meetingTime);
        return meetingDateTime > currentDateTime;
    };
    const isMeetingTimeInPast = (meetingTime) => {
        const currentDateTime = new Date();
        const meetingDateTime = new Date(meetingTime);
        return meetingDateTime < currentDateTime;
    };
    useEffect(() => {
        // Set loading to true when the effect starts
        setIsLoading(true);
        if (mentorSessionData?.length > 0) {
            const filteredSessions = mentorSessionData.filter(
                (menteeRequest) =>
                    menteeRequest.mentee_details.meeting_time.includes("T")
                        ? isMeetingTimeInFuture(menteeRequest.mentee_details.meeting_time)
                        : isMeetingTimeInFuture(
                            `${menteeRequest.mentee_details.meeting_date}T${menteeRequest.mentee_details.meeting_time}:00.000Z`
                        )
            );

            setUpcomingSessions(filteredSessions);
        }
        if (mentorSessionData?.length > 0) {
            const filteredSessions = mentorSessionData.filter(
                (menteeRequest) =>
                    menteeRequest.mentee_details.meeting_time.includes("T")
                        ? isMeetingTimeInPast(menteeRequest.mentee_details.meeting_time)
                        : isMeetingTimeInPast(
                            `${menteeRequest.mentee_details.meeting_date}T${menteeRequest.mentee_details.meeting_time}:00.000Z`
                        )
            );

            setHistorySession(filteredSessions);
        }

        setIsLoading(false);
    }, [mentorSessionData]);




    console.log("upcomingSessions", upcomingSessions)

    return (
        <>

            <section className="p-1">
                <div className="">
                    <div className="subscribed-mentor-sec">
                        <div
                            className={`subscribe-mentor subscribe_box ${styles.home_tab_mentors}`}
                        >
                            <h4>Upcoming Sessions</h4>
                            {isLoading ? (
                                <div className="justify-content-center d-flex mt-5">
                                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                                </div>
                            ) : upcomingSessions?.length > 0 ? (
                                upcomingSessions?.map((subcription, id) => {
                                    let date = new Date(
                                        subcription.mentee_details.meeting_time.includes('T') ? (
                                            (subcription.mentee_details.meeting_time)
                                        ) : (
                                            (`${subcription.mentee_details.meeting_date}T${subcription.mentee_details.meeting_time}:00.000Z`)
                                        )
                                    );
                                    let meeting_time = new Date(

                                        subcription.mentee_details.meeting_time.includes('T') ? (
                                            (subcription.mentee_details.meeting_time)
                                        ) : (
                                            (`${subcription.mentee_details.meeting_date}T${subcription.mentee_details.meeting_time}:00.000Z`)
                                        )

                                    );
                                    return (
                                        <div key={subcription?.subscription_id} className="card my-2">
                                            <div className="card-header">
                                                <a
                                                    className="collapsed card-link"
                                                    data-toggle="collapse"
                                                    href={`#collapse${subcription?.subscription_id}`}
                                                >
                                                    <span className="title-main-cls">
                                                        {subcription?.product_details?.title}{" "}
                                                        <span className="dot"></span>Meeting on :{" "}
                                                        {meeting_time?.toDateString()} &nbsp;
                                                        {meeting_time?.toLocaleTimeString()}
                                                    </span>
                                                    {/* <button
                            type="button"
                            className={
                              subcription?.status == "PENDING"
                                ? "btn pending"
                                : subcription?.status == "APPROVED"
                                  ? "btn approved"
                                  : "btn rejected"
                            }
                          >
                            {subcription?.status}
                          </button> */}
                                                    <span className="arrow-ic }" style={{ marginLeft: 'auto' }} >
                                                        <img alt='icon' src={arrowDownImg} />
                                                    </span>
                                                </a>
                                            </div>
                                            <div
                                                id={`collapse${subcription?.subscription_id}`}
                                                className="collapse"
                                                data-parent="#accordion"
                                            >
                                                <div className="card-body">
                                                    <div className="event-cont mw-100">
                                                        <div className="fav-event">
                                                            <div className="event-user">
                                                                {subcription?.mentee_details?.profile_photo ? (
                                                                    <img
                                                                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}${subcription?.mentee_details?.profile_photo}`}
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <img alt='icon' src={mentorEvent} />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="event-user-dtl">
                                                            <h5>{subcription?.mentee_details?.name}</h5>
                                                            <span className="language">
                                                                <span className="lang-ic">
                                                                    <img alt='icon' src={languageImg} />
                                                                </span>
                                                                {Array.isArray(subcription?.mentee_details?.language) ?
                                                                    subcription?.mentee_details?.language?.map(
                                                                        (item, id) => <span key={id}> {item}</span>
                                                                    )
                                                                    : (null)
                                                                }
                                                            </span>
                                                            <div className="date-payment-sec">
                                                                <a
                                                                    target="_blank"
                                                                    href={
                                                                        subcription?.mentee_details?.meeting_link
                                                                    }
                                                                >
                                                                    <span className="date-wrap">
                                                                        <span className="date-ic">
                                                                            <img alt='icon' src={calendarImg} />
                                                                        </span>
                                                                        <span className="date-cont">
                                                                            {meeting_time?.toDateString()} &nbsp;
                                                                            {meeting_time?.toLocaleTimeString()}
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                                <span className="payment-wrap">
                                                                    <span className="date-ic">
                                                                        <img alt='icon' src={priceImg} />
                                                                    </span>
                                                                    <span className="date-cont">
                                                                        {subcription?.mentee_details?.price_per_session === "0" ? "Free Session" : "Paid Session"}
                                                                    </span>
                                                                    <span className="">
                                                                        <a
                                                                            style={{
                                                                                borderRadius: '15px'
                                                                            }}
                                                                            type="button"
                                                                            className="btn btn-success certificate_savebtn ml-3"
                                                                            target="_blank"
                                                                            href={
                                                                                subcription?.mentee_details?.meeting_link
                                                                            }
                                                                        >
                                                                            Join Session
                                                                        </a>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pre-session">
                                                        <span>Pre-Session Study Material</span>
                                                        <ul>
                                                            <li>
                                                                <img alt='icon' src={preSess1Img} />
                                                            </li>
                                                            <li>
                                                                <img alt='icon' src={preSess2Img} />
                                                            </li>
                                                            <li>
                                                                <img alt='icon' src={preSess3Img} />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="skills-sec">
                                                        <ul>
                                                            {subcription?.mentor_details?.skills?.map(
                                                                (skill, id) => (
                                                                    <li key={id}>
                                                                        <a href="#">{skill}</a>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-active-mentor-sec">
                                    <div className="no-active-cont">
                                        <h3>No Upcoming Sessions</h3>
                                        {/* <h3>No Active Mentorship</h3> */}
                                        <p>Mentee haven't applied for any mentorship yet</p>

                                    </div>
                                    <div className="no-active-img">
                                        <img alt='icon' src={noMentorImg} />
                                    </div>
                                </div>
                            )}
                        </div>


                    </div>
                </div>
            </section>
            <section className="p-1">
                <div className="">
                    <div className="subscribed-mentor-sec">
                        <div
                            className={`subscribe-mentor subscribe_box ${styles.home_tab_mentors}`}
                        >
                            <h4>Past Sessions</h4>
                            {/* <h4>Subscribed Mentors</h4> */}
                            {isLoading ? (
                                <div className="justify-content-center d-flex mt-5">
                                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                                </div>
                            ) : HistorySession?.length > 0 ? (
                                HistorySession?.reverse().map((subcription, id) => {
                                    let date = new Date(
                                        subcription.mentee_details.meeting_time.includes('T') ? (
                                            (subcription.mentee_details.meeting_time)
                                        ) : (
                                            (`${subcription.mentee_details.meeting_date}T${subcription.mentee_details.meeting_time}:00.000Z`)
                                        )
                                    );
                                    let meeting_time = new Date(

                                        subcription.mentee_details.meeting_time.includes('T') ? (
                                            (subcription.mentee_details.meeting_time)
                                        ) : (
                                            (`${subcription.mentee_details.meeting_date}T${subcription.mentee_details.meeting_time}:00.000Z`)
                                        )

                                    );
                                    return (
                                        <div key={subcription?.subscription_id} className="card my-2">
                                            <div className="card-header">
                                                <a
                                                    className="collapsed card-link"
                                                    data-toggle="collapse"
                                                    href={`#collapse${subcription?.subscription_id}`}
                                                >
                                                    <span className="title-main-cls">
                                                        {subcription?.product_details?.title}{" "}
                                                        <span className="dot"></span>Meeting on :{" "}
                                                        {meeting_time?.toDateString()} &nbsp;
                                                        {meeting_time?.toLocaleTimeString()}
                                                    </span>
                                                    {/* <button
                            type="button"
                            className={
                              subcription?.status == "PENDING"
                                ? "btn pending"
                                : subcription?.status == "APPROVED"
                                  ? "btn approved"
                                  : "btn rejected"
                            }
                          >
                            {subcription?.status}
                          </button> */}
                                                    <span className="arrow-ic " style={{ marginLeft: 'auto' }}>
                                                        <img alt='icon' src={arrowDownImg} />
                                                    </span>
                                                </a>
                                            </div>
                                            <div
                                                id={`collapse${subcription?.subscription_id}`}
                                                className="collapse"
                                                data-parent="#accordion"
                                            >
                                                <div className="card-body">
                                                    <div className="event-cont mw-100">
                                                        <div className="fav-event">
                                                            <div className="event-user">
                                                                {subcription?.mentee_details?.profile_photo ? (
                                                                    <img
                                                                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}${subcription?.mentee_details?.profile_photo}`}
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <img alt='icon' src={mentorEvent} />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="event-user-dtl">
                                                            <h5>{subcription?.mentee_details?.name}</h5>
                                                            <span className="language">
                                                                <span className="lang-ic">
                                                                    <img alt='icon' src={languageImg} />
                                                                </span>
                                                                {Array.isArray(subcription?.mentee_details?.language) ?
                                                                    subcription?.mentee_details?.language?.map(
                                                                        (item, id) => <span key={id}> {item}</span>
                                                                    )
                                                                    : (null)
                                                                }
                                                            </span>
                                                            <div className="date-payment-sec">
                                                                <a
                                                                    target="_blank"
                                                                    href={
                                                                        subcription?.mentee_details?.meeting_link
                                                                    }
                                                                >
                                                                    <span className="date-wrap">
                                                                        <span className="date-ic">
                                                                            <img alt='icon' src={calendarImg} />
                                                                        </span>
                                                                        <span className="date-cont">
                                                                            {meeting_time?.toDateString()} &nbsp;
                                                                            {meeting_time?.toLocaleTimeString()}
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                                <span className="payment-wrap">
                                                                    <span className="date-ic">
                                                                        <img alt='icon' src={priceImg} />
                                                                    </span>
                                                                    <span className="date-cont">
                                                                        {subcription?.mentee_details?.price_per_session === "0" ? "Free Session" : "Paid Session"}
                                                                    </span>
                                                                    <span className="">
                                                                        <a
                                                                            style={{
                                                                                borderRadius: '15px'
                                                                            }}
                                                                            type="button"
                                                                            className="btn btn-success certificate_savebtn ml-3"
                                                                            target="_blank"
                                                                            href={
                                                                                subcription?.mentee_details?.meeting_link
                                                                            }
                                                                        >
                                                                            Join Session
                                                                        </a>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pre-session">
                                                        <span>Pre-Session Study Material</span>
                                                        <ul>
                                                            <li>
                                                                <img alt='icon' src={preSess1Img} />
                                                            </li>
                                                            <li>
                                                                <img alt='icon' src={preSess2Img} />
                                                            </li>
                                                            <li>
                                                                <img alt='icon' src={preSess3Img} />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="skills-sec">
                                                        <ul>
                                                            {subcription?.mentor_details?.skills?.map(
                                                                (skill, id) => (
                                                                    <li key={id}>
                                                                        <a href="#">{skill}</a>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-active-mentor-sec">
                                    <div className="no-active-cont">
                                        <h3>No  Mentorship</h3>
                                        <p>Mentee  haven't applied for any mentorship yet</p>

                                    </div>
                                    <div className="no-active-img">
                                        <img alt='icon' src={noMentorImg} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="help-sec mt-4">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-md-6">
                          <div className="help-img">
                              <img alt='icon' src={helpImg} />
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className={`help-cont ${styles.mentee_admin_subhead}`}>
                              <h4>Ready to Book a Session with a Mentor?</h4>
                              <h3>Take the Next Step!</h3>
                              <p>
                                  Whether you have specific goals or need guidance, our mentors are here for you. Share your requirements, and we'll connect you with the right mentor to help you succeed.
                              </p>
                              <div className={`get-started-cls ${styles.mentee_home_btn}`}>
                                  <button
                                      type="button"
                                      className="btn btn-primary mt-3"
                                      onClick={() => navigate("/free-membership")}
                                      style={{
                                          padding: "0rem",
                                      }}
                                  >
                                      Book a Free Session Now
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section> */}


            <section className="help-sec">
                <div className="">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="help-img">
                                <img alt='icon' src={feedbackimg} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={`help-cont ${styles.mentee_admin_subhead}`}>
                                <h4>Having trouble finding the right path?</h4>
                                <h3>Let Us Help You!</h3>
                                <p>
                                    Please share your custom requirements with us, and we will
                                    find the right mentor for you.
                                </p>
                                <div className={`get-started-cls ${styles.mentee_home_btn}`}>
                                    <button
                                        data-toggle="modal"
                                        data-target="#loginForm"
                                        onClick={() =>
                                            setFeedbackModal({
                                                modal: FeedbackForm,
                                                show: true,
                                            })
                                        }
                                        type="button"
                                        className={`btn btn-primary mt-3 `}
                                    >
                                        Tell us now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {feedbackModal?.modal && (
                <feedbackModal.modal
                    feedbackModal={feedbackModal}
                    setFeedbackModal={setFeedbackModal}
                />
            )}
        </>
    )
}

export default MentorHome