import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Modal, ModalBody } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";

import crossIcon from "../../../assets/images/cross-icon.png";
import mentorLogo from "../../../assets/images/mentor-logo.png";
import mentorWlogo from "../../../assets/images/mentor-wlogo.png";
import SignUpModal from "../../modals/signUpModal";
import loginPasswordModal from "../../modals/loginwithPassword";
import { generateOtp, login, verifyEmail, socialLogin } from "../../../redux/actions/login";
import LoginPasswordModal from "../../modals/loginwithPassword";
import EmailOtpModel from "../../modals/emailotp";
import { data } from "jquery";
import SocialLogin from "../../socialLogin";
import LoginModal from "../../modals/loginModal";
// import { Loader } from "../../loader";


const LoginMentor = ({ headerModal, setHeaderModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpscreen, setOtpScreen] = useState(false);
  const [disable, setDisable] = useState(true);
  const [errorotp, setErrorOTP] = useState(false);
  const [error, setError] = useState("");
  const [isShowManualSignup, setIsShowManualSignup] = useState(true);
  const [userType, setUserType] = useState("mentor");
  const [loader, setLoader] = useState(false);

  const otpReducerState = useSelector((state) => state.otpReducer);
  const status = otpReducerState?.status;
  const requestId = otpReducerState?.data?.client_transaction_id;

  const deviceToken = useSelector(
    (state) => state.emailVerifyReducer?.headers?.["x-device-token"]
  );
  const userId = useSelector(
    (state) => state.emailVerifyReducer?.headers?.["x-user-id"]
  );
  const errorMsg = useSelector(
    (state) =>
      state.emailVerifyReducer?.headers?.["x-error-msg"] ||
      state?.loginReducer?.headers?.["x-error-msg"]
  );

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setEmail(event.target.value);
      setError(null);
    }
  };

  const verify = () => {
    setLoader(true)
    const data = {
      requestId: requestId,
      otp,
      email: email,
      purpose: "LOGIN",
      userType: userType
    };
    dispatch(verifyEmail(data));
  };

  const sendOtp = () => {
    const data = {
      email,
      userType:userType,
      requestId: uuidv4(),
      purpose: "LOGIN",
    };
    setLoader(true);
    dispatch(generateOtp(data));
  };

  const handleUserType = (e) => {
    setUserType(e.target.value);
  };

  useEffect(() => {
    if (status === 200 && loader) {
      setLoader(false);
      setOtpScreen(false);
      setHeaderModal({ modal: EmailOtpModel, show: true, email: email, userType: userType })
    }
  }, [status]);

  const refreshToken = useSelector(
    (state) => state.loginReducer?.headers?.["x-refresh-token"]
  );

  useEffect(() => {
    if (refreshToken) {
      if (headerModal?.userType === "mentor") {
        navigate("/mentor-dashboard");
      } else {
        navigate("/dashboard");
      }
    }
    if (errorMsg) {
      setErrorOTP(true);
    }
  }, [refreshToken, errorMsg]);

  useEffect(() => {
    if (deviceToken) {
      forlogin(deviceToken, userId)
    }
  }, [deviceToken]);

  const forlogin = (deviceToken, userId) => {
    const data = {
      userType: userType,
    };
    dispatch(login({ data, deviceToken, userId }));
  };
  const handleChangeOtp = (e) => {
    if (e.target.value.length === 6) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    setOtp(e.target.value);
  };

  useEffect(() => {
    setErrorOTP(false)
  }, [])

  return (
    <>
      {loader && (
        <div className="loader" style={{ zIndex: 9999 }}>
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      )}
      <Modal
        size="xl"
        centered
        show={headerModal.show}
        onHide={() =>
          setHeaderModal((prevState) => ({ ...prevState, show: false }))
        }
      >
        {!otpscreen ? (
          <ModalBody>
          
          <button
              type="button"
              className="cloose"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() =>
                setHeaderModal((prevState) => ({ ...prevState, show: false }))
              }
            >
              <div className="closebuttons">
                <span aria-hidden="true">
                  <img src={crossIcon} />
                </span>
              </div>
            </button>

            <div className="signup-row">
              <div className="signup-leftcol">
                <div className="sign-form-cls">
                  <div className="logo-sec">
                    <h4>Welcome to</h4>
                    <img src={mentorLogo} alt="Mentorpal" />
                  </div>
                  <div className="singup-thanku">

                  {/* <label htmlFor="radio1" className="as-mentor"> */}
                    {/* <input
                      type="radio"
                      id="radio1"
                      name="radios"
                      value="mentee"
                      defaultChecked
                      onChange={handleUserType}
                    />                   
                    As Mentee</label> */}

                    <label for="radio2" className="as-mentor">
                    <input
                      type="radio"
                      id="radio2"
                      name="radios"
                      value="mentor"
                      onChange={handleUserType}
                      checked
                    />
                   As Mentor</label>
                  </div>
                  {/* <SocialLogin modalprops={headerModal} modalType={userType} /> */}
                  <div className="signup-or">
                    {/* <p>
                      <span>OR</span>
                    </p> */}
                  </div>
                  <div className="singup-otp mt-3">
                    <p>
                      <a
                        href="#"
                        onClick={() => setIsShowManualSignup(!isShowManualSignup)}
                      >
                        Login with email
                      </a>
                    </p>
                  </div>
                  {
                    isShowManualSignup ?
                      <div className="mt-3">
                        <div className="form-group">
                          <input
                            type="text"
                            name=""
                            placeholder="Email"
                            className="form-control"
                            onChange={(e) => handleChange(e)}
                          />
                          {/* {error && <h2 style={{color: 'red'}}>{error}</h2>} */}
                        </div>
                        <div className="singup-upp">
                          <button
                            className="btn "
                            onClick={() =>
                              setHeaderModal({
                                modal: LoginPasswordModal,
                                show: true,
                                email,
                                userType,
                                as:"mentor"
                              })
                            }
                            disabled={error === null ? false : true}
                          >
                            Login with password
                          </button>
                        </div>
                        <div className="get-started-cls">
                          <button
                            style={{ width: '223px', margin: '0 auto' }}
                            type="button"
                            className="btn btn-primary"
                            onClick={sendOtp}
                            disabled={error === null ? false : true}
                          >
                            Login with otp
                          </button>
                        </div>
                      </div>
                      : null
                  }
                </div>
              </div>
              <div className="signup-rightcol">
                <div className="signup-cont">
                  <div className="signup-logo d-none d-md-block">
                    <img src={mentorWlogo} alt="" />
                  </div>
                  <div className="signup-inner">
                    <p>
                      Mentorship from the global expert. join one-on-one Live
                      session and group session from the verified mentors at
                      your fingertips
                    </p>
                    <button
                      type="button"
                      onClick={() =>
                        setHeaderModal({ modal: SignUpModal, show: false, as: "mentor" })
                      }
                    >
                      New to mentorpal? Sign up now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        ) : (
          <ModalBody>
            <div
              type="button"
              className="cloose"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() =>
                setHeaderModal((prevState) => ({ ...prevState, show: false }))
              }
            >
              <div className="closebuttons">
                <span aria-hidden="true">
                  <img src={crossIcon} />
                </span>
              </div>
            </div>
            {/* <form> */}
            <div className="signup-row">
              <div className="signup-leftcol">
                <div className="sign-form-cls">
                  <div className="logo-sec">
                    <h4>Welcome to</h4>
                    <img src={mentorLogo} alt="Mentorpal" />
                  </div>
                  <div className="wel-space-cls">
                    <div className="form-group change-pw">
                      <p>
                        Please Enter 6 Digit OTP sent{" "}
                        {/* <span>on +91 9876543210</span> */}
                      </p>
                      {/* <a href="#">Change Number</a> */}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name=""
                        placeholder="Enter OTP"
                        className="form-control"
                        onChange={(e) => handleChangeOtp(e)}
                      />
                      {errorotp && (
                        <p
                          style={{
                            color: "#800020",
                            marginTop: " 10px",
                            fontWeight: 500,
                          }}
                        >
                          {errorMsg}
                        </p>
                      )}
                    </div>
                    <div className="singup-upp">
                      <button
                        className="btn"
                        onClick={verify}
                        disabled={disable}
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="singup-otp">
                      <p>
                        Didn't receive the OTP yet? <a href="#" onClick={sendOtp}>Resend OTP</a>
                      </p>
                      <p>
                        <a
                          href="#"
                          onClick={() => {
                            setOtpScreen(false)
                            setHeaderModal({
                              modal: loginPasswordModal,
                              show: true,
                              email,
                              userType,
                              as:"mentor"
                            })
                          }
                          }
                        >
                          Login with Password
                        </a>
                      </p>
                      <p>
                        <a
                          href="#"
                          onClick={() =>
                            setHeaderModal({ modal: LoginMentor, show: true })
                          }
                        >
                          Back to login
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="signup-rightcol">
                <div className="signup-cont">
                    <div className="signup-logo d-none d-md-block">
                      <img src={mentorWlogo} alt="" />
                    </div>
                  <div className="signup-inner">
                    <p>
                      Mentorship from the global expert. join one-on-one Live
                      session and group session from the verified mentors at
                      your fingertips
                    </p>
                    <button
                      onClick={() =>
                        setHeaderModal({ modal: SignUpModal, show: false, as: "mentee" })
                      }
                    >
                      New to mentorpal? Sign up now
                    </button>
                  </div>
                </div>
              </div>

            </div>
            {/* </form> */}
          </ModalBody>
        )}
      </Modal>
    </>
  );
};

export default LoginMentor;
