
export const TermAndCondtionSeo = {
    title: 'Terms and Conditions of Mentorpal.ai',
    canonical: "https://www.mentorpal.ai/terms",
    metaTags: [
        { name: 'Description', content: 'Terms and conditions section of our content it means you are already a Mentorpal subscriber or somewhat interested in Mentorpal.' },
        { name: 'keywords', content: 'Find Mentorship Price Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/terms' },
        { property: 'og:title', content: 'Terms and Conditions of Mentorpal.ai' },
        {
            property: 'og:description',
            content: 'Terms and conditions section of our content it means you are already a Mentorpal subscriber or somewhat interested in Mentorpal.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Terms and Conditions of Mentorpal.ai' },
        {
            name: 'twitter:description',
            content: 'Terms and conditions section of our content it means you are already a Mentorpal subscriber or somewhat interested in Mentorpal.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
    ],
};
export const AboutMentorpalSeo = {
    title: 'About Mentorpal – Your trusted Mentorships and Coaching Source',
    canonical: 'https://www.mentorpal.ai/about-us',
    metaTags: [
        {
            name: 'Description',
            content:
                'Read more about Mentorpal: At Mentorpal.ai, we value open communication and are always here to help with any questions or concerns you may have.',
        },
        { name: 'keywords', content: 'Find Mentorship Price Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/about-us' },
        {
            property: 'og:title',
            content: 'About Mentorpal – Your trusted Mentorships and Coaching Source',
        },
        {
            property: 'og:description',
            content:
                'Read more about Mentorpal: At Mentorpal.ai, we value open communication and are always here to help with any questions or concerns you may have.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        {
            name: 'twitter:title',
            content: 'About Mentorpal – Your trusted Mentorships and Coaching Source',
        },
        {
            name: 'twitter:description',
            content:
                'Read more about Mentorpal: At Mentorpal.ai, we value open communication and are always here to help with any questions or concerns you may have.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
    ],
};
export const PricingPageSeo = {
    title: 'Find Mentorship Online Price for Students - Mentorpal',
    canonical: 'https://www.mentorpal.ai/pricing',
    metaTags: [
        {
            name: 'Description',
            content: 'Discover top online mentoring platforms to boost your career and personal growth. Find the mentor price and unlock your potential.',
        },
        { name: 'keywords', content: 'Find Mentorship Price Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/pricing' },
        {
            property: 'og:title',
            content: 'Find Mentorship Online Price for Students - Mentorpal',
        },
        {
            property: 'og:description',
            content: 'Discover top online mentoring platforms to boost your career and personal growth. Find the mentor price and unlock your potential.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/join-img.a0a84f5e491cf65aa3ff.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Find Mentorship Online Price for Students - Mentorpal' },
        {
            name: 'twitter:description',
            content: 'Discover top online mentoring platforms to boost your career and personal growth. Find the mentor price and unlock your potential.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/join-img.a0a84f5e491cf65aa3ff.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
    ],
};

export const PrivacyPolicySeo = {
    title: 'Privacy Policy of Mentorpal.ai',
    canonical: 'https://www.mentorpal.ai/privacy-policy',
    metaTags: [
        {
            name: 'Description',
            content: 'Privacy Policy covers all personally identifiable information collected by Mentorpal when you use the Mentorpal.ai website and its services.',
        },
        { name: 'keywords', content: 'Find Mentorship Price Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/privacy-policy' },
        { property: 'og:title', content: 'Privacy Policy of Mentorpal.ai' },
        {
            property: 'og:description',
            content: 'Privacy Policy covers all personally identifiable information collected by Mentorpal when you use the Mentorpal.ai website and its services.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Privacy Policy of Mentorpal.ai' },
        {
            name: 'twitter:description',
            content: 'Privacy Policy covers all personally identifiable information collected by Mentorpal when you use the Mentorpal.ai website and its services.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
    ],
};
export const FindAMentorSeo = {
    title: 'Find a mentor | Mentors Available for Top- Mentorpal',
    canonical: 'https://www.mentorpal.ai/find-mentor',
    metaTags: [
        {
            name: 'Description',
            content: 'Find a mentor Online. We offer various expert mentors in skills like Marketing, software engineering, product management, Data Science.',
        },
        { name: 'keywords', content: 'Find a mentor Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/find-mentor' },
        { property: 'og:title', content: 'Find a mentor | Mentors Available for Top- Mentorpal' },
        {
            property: 'og:description',
            content: 'Find a mentor Online. We offer various expert mentors in skills like Marketing, software engineering, product management, Data Science.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Find a mentor | Mentors Available for Top- Mentorpal' },
        {
            name: 'twitter:description',
            content: 'Find a mentor Online. We offer various expert mentors in skills like Marketing, software engineering, product management, Data Science.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
        { name: 'Top Online Mentors for Content Writing - Mentorpal.Ai', content: 'Discover expert content writing mentors on Mentorpal.Ai, guiding you to mastery. Boost your craft with personalized online mentorship.' },
        { name: 'Find A Data Science Mentor Online - Mentorpal.Ai', content: 'Unlock your data science potential with Mentorpal.Ai. Find the perfect online mentor to guide your journey to mastery.' },
        { name: 'Find Online Mentors for Web Designs - Mentorpal.Ai', content: 'Level up your web design skills with Mentorpal.Ai. Discover top online mentors and transform your creative vision into stunning web experiences.' },
        { name: 'Top Marketing Mentors online - Mentorpal.Ai', content: 'mentorUpgrade your marketing game with Mentorpal.Ai. Find leading online mentors to boost your strategies and grow your career.pal' },
        { name: 'Find online mentors for product Management - Mentorpl.Ai', content: 'Master product management with Mentorpl.Ai. Discover online mentors and accelerate your skills to lead successful product strategies.' },
        { name: 'Top online Mentors for product research - Mentorpal.Ai', content: 'Perfect your product research with top mentors on Mentorpal.Ai. Boost your insights and strategies for successful product development.' },
        { name: 'Best Software development Mentors online - Mentorpal.Ai', content: 'Excel in software development with Mentorpal.Ai. Connect with the best online mentors to hone your coding skills and build exceptional software' },

    ],
};
export const MentorpalBlogSeo = {
    title: 'Mentorpal Blog - Career, Coaching, Mentorship News, Development',
    canonical: 'https://blog.mentorpal.ai/',
    metaTags: [
        {
            name: 'Description',
            content: 'Read more about Career, Coaching, Mentorship News, articles and blog on Mentorpal by Learn strategies to mentorship.',
        },
        { name: 'keywords', content: 'Find Mentorship Price Online' },
        { property: 'og:url', content: 'https://blog.mentorpal.ai/' },
        { property: 'og:title', content: 'Mentorpal Blog - Career, Coaching, Mentorship News, Development' },
        {
            property: 'og:description',
            content: 'Read more about Career, Coaching, Mentorship News, articles and blog on Mentorpal by Learn strategies to mentorship.',
        },
        { property: 'og:image', content: 'https://blog.mentorpal.ai/wp-content/uploads/2023/02/Mentorpal-.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Mentorpal Blog - Career, Coaching, Mentorship News, Development' },
        {
            name: 'twitter:description',
            content: 'Read more about Career, Coaching, Mentorship News, articles and blog on Mentorpal by Learn strategies to mentorship.',
        },
        { name: 'twitter:image:src', content: 'https://blog.mentorpal.ai/wp-content/uploads/2023/02/Mentorpal-.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
    ],
};
export const BecomeAMentorSeo = {
    title: 'Become Mentor Online Fully Certified - Mentorpal',
    canonical: 'https://www.mentorpal.ai/become-mentor',
    metaTags: [
        {
            name: 'Description',
            content: 'Our online mentorship programme would helping students to Become a certified mentor. Join us to become a mentor.',
        },
        { name: 'keywords', content: 'Find Mentorship Price Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/become-mentor' },
        { property: 'og:title', content: 'Become Mentor Online Fully Certified - Mentorpal' },
        {
            property: 'og:description',
            content: 'Our online mentorship programme would helping students to Become a certified mentor. Join us to become a mentor.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/teachingConcept.09fc4367a3e211a8b877.jpg' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Become Mentor Online Fully Certified - Mentorpal' },
        {
            name: 'twitter:description',
            content: 'Our online mentorship programme would helping students to Become a certified mentor. Join us to become a mentor.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/teachingConcept.09fc4367a3e211a8b877.jpg' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
    ],
};
export const MasteringFullStackWebDevSeo = {
    title: 'Mastering Full Stack Web Development - Mentorpal',
    canonical: 'https://www.mentorpal.ai/mastering-full-stack-web-development',
    metaTags: [
        {
            name: 'Description',
            content: 'Mastering Full Stack Web Development Course. Learn to build dynamic and responsive web applications from scratch using MongoDB, Express.js, React, and Node.js.',
        },
        { name: 'keywords', content: 'Find Full Stack Web Developer Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/mastering-full-stack-web-development' },
        { property: 'og:title', content: 'Mastering Full Stack Web Development - Mentorpal' },
        {
            property: 'og:description',
            content: 'Mastering Full Stack Web Development Course. Learn to build dynamic and responsive web applications from scratch using MongoDB, Express.js, React, and Node.js.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/fullStackBanner.ac28de25ba0838df7f32.jpg' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Mastering Full Stack Web Development - Mentorpal' },
        {
            name: 'twitter:description',
            content: 'Mastering Full Stack Web Development Course. Learn to build dynamic and responsive web applications from scratch using MongoDB, Express.js, React, and Node.js.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/fullStackBanner.ac28de25ba0838df7f32.jpg' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
    ],
};
export const FindAMentorSeoContentWriting = {
    title: 'Top Online Mentors for Content Writing - Mentorpal.Ai',
    canonical: 'https://www.mentorpal.ai/find-mentor/content-writing',
    metaTags: [
        {
            name: 'Description',
            content: 'Discover expert content writing mentors on Mentorpal.Ai, guiding you to mastery. Boost your craft with personalized online mentorship.',
        },
        { name: 'keywords', content: 'Find a mentor Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/find-mentor/content-writing' },
        { property: 'og:title', content: 'Top Online Mentors for Content Writing - Mentorpal.Ai' },
        {
            property: 'og:description',
            content: 'Discover expert content writing mentors on Mentorpal.Ai, guiding you to mastery. Boost your craft with personalized online mentorship.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Top Online Mentors for Content Writing - Mentorpal.Ai' },
        {
            name: 'twitter:description',
            content: 'Discover expert content writing mentors on Mentorpal.Ai, guiding you to mastery. Boost your craft with personalized online mentorship.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
        { name: 'Top Online Mentors for Content Writing - Mentorpal.Ai', content: 'Discover expert content writing mentors on Mentorpal.Ai, guiding you to mastery. Boost your craft with personalized online mentorship.' },
    ],
};
export const FindAMentorSeoDataScience = {
    title: 'Find A Data Science Mentor Online - Mentorpal.Ai',
    canonical: 'https://www.mentorpal.ai/find-mentor/data-science',
    metaTags: [
        {
            name: 'Description',
            content: 'Unlock your data science potential with Mentorpal.Ai. Find the perfect online mentor to guide your journey to mastery.',
        },
        { name: 'keywords', content: 'Find a mentor Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/find-mentor/data-science' },
        { property: 'og:title', content: 'Find A Data Science Mentor Online - Mentorpal.Ai' },
        {
            property: 'og:description',
            content: 'Unlock your data science potential with Mentorpal.Ai. Find the perfect online mentor to guide your journey to mastery.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Find a mentor | Mentors Available for Top- Mentorpal' },
        {
            name: 'twitter:description',
            content: 'Unlock your data science potential with Mentorpal.Ai. Find the perfect online mentor to guide your journey to mastery.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
        { name: 'Find A Data Science Mentor Online - Mentorpal.Ai', content: 'Unlock your data science potential with Mentorpal.Ai. Find the perfect online mentor to guide your journey to mastery.' },
    ],
};
export const FindAMentorSeoDesign = {
    title: 'Find Online Mentors for Web Designs - Mentorpal.Ai',
    canonical: 'https://www.mentorpal.ai/find-mentor/design',
    metaTags: [
        {
            name: 'Description',
            content: 'Level up your web design skills with Mentorpal.Ai. Discover top online mentors and transform your creative vision into stunning web experiences.',
        },
        { name: 'keywords', content: 'Find a mentor Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/find-mentor/design' },
        { property: 'og:title', content: 'Find Online Mentors for Web Designs - Mentorpal.Ai' },
        {
            property: 'og:description',
            content: 'Level up your web design skills with Mentorpal.Ai. Discover top online mentors and transform your creative vision into stunning web experiences.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Find Online Mentors for Web Designs - Mentorpal.Ai' },
        {
            name: 'twitter:description',
            content: 'Level up your web design skills with Mentorpal.Ai. Discover top online mentors and transform your creative vision into stunning web experiences.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
        { name: 'Find Online Mentors for Web Designs - Mentorpal.Ai', content: 'Level up your web design skills with Mentorpal.Ai. Discover top online mentors and transform your creative vision into stunning web experiences.' },
    ],
};
export const FindAMentorSeoMarketing = {
    title: 'Top Marketing Mentors online - Mentorpal.Ai',
    canonical: 'https://www.mentorpal.ai/find-mentor/marketing',
    metaTags: [
        {
            name: 'Description',
            content: 'Upgrade your marketing game with Mentorpal.Ai. Find leading online mentors to boost your strategies and grow your career.',
        },
        { name: 'keywords', content: 'Find a mentor Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/find-mentor/marketing' },
        { property: 'og:title', content: 'Top Marketing Mentors online - Mentorpal.Ai' },
        {
            property: 'og:description',
            content: 'Upgrade your marketing game with Mentorpal.Ai. Find leading online mentors to boost your strategies and grow your career.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Top Marketing Mentors online - Mentorpal.Ai' },
        {
            name: 'twitter:description',
            content: 'Upgrade your marketing game with Mentorpal.Ai. Find leading online mentors to boost your strategies and grow your career.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
        { name: 'Top Marketing Mentors online - Mentorpal.Ai', content: 'Upgrade your marketing game with Mentorpal.Ai. Find leading online mentors to boost your strategies and grow your career.' },
    ],
};
export const FindAMentorSeoProductManagement = {
    title: 'Find online mentors for product Management - Mentorpl.Ai',
    canonical: 'https://www.mentorpal.ai/find-mentor/product-management',
    metaTags: [
        {
            name: 'Description',
            content: 'Master product management with Mentorpl.Ai. Discover online mentors and accelerate your skills to lead successful product strategies.',
        },
        { name: 'keywords', content: 'Find a mentor Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/find-mentor/product-management' },
        { property: 'og:title', content: 'Find online mentors for product Management - Mentorpl.Ai' },
        {
            property: 'og:description',
            content: 'Master product management with Mentorpl.Ai. Discover online mentors and accelerate your skills to lead successful product strategies.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Find online mentors for product Management - Mentorpl.Ai' },
        {
            name: 'twitter:description',
            content: 'Master product management with Mentorpl.Ai. Discover online mentors and accelerate your skills to lead successful product strategies.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
        { name: 'Find online mentors for product Management - Mentorpl.Ai', content: 'Master product management with Mentorpl.Ai. Discover online mentors and accelerate your skills to lead successful product strategies.' },
    ],
};
export const FindAMentorSeoProductResearch = {
    title: 'Top online Mentors for product research - Mentorpal.Ai',
    canonical: 'https://www.mentorpal.ai/find-mentor/product-research',
    metaTags: [
        {
            name: 'Description',
            content: 'Perfect your product research with top mentors on Mentorpal.Ai. Boost your insights and strategies for successful product development.',
        },
        { name: 'keywords', content: 'Find a mentor Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/find-mentor/product-management' },
        { property: 'og:title', content: 'Top online Mentors for product research - Mentorpal.Ai' },
        {
            property: 'og:description',
            content: 'Perfect your product research with top mentors on Mentorpal.Ai. Boost your insights and strategies for successful product development.',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Top online Mentors for product research - Mentorpal.Ai' },
        {
            name: 'twitter:description',
            content: 'Perfect your product research with top mentors on Mentorpal.Ai. Boost your insights and strategies for successful product development.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
        { name: 'Top online Mentors for product research - Mentorpal.Ai', content: 'Perfect your product research with top mentors on Mentorpal.Ai. Boost your insights and strategies for successful product development.' },
    ],
};
export const FindAMentorSeoSoftwareDevelopment = {
    title: 'Best Software development Mentors online - Mentorpal.Ai',
    canonical: 'https://www.mentorpal.ai/find-mentor/software-development',
    metaTags: [
        {
            name: 'Description',
            content: 'Excel in software development with Mentorpal.Ai. Connect with the best online mentors to hone your coding skills and build exceptional software',
        },
        { name: 'keywords', content: 'Find a mentor Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/find-mentor/software-development' },
        { property: 'og:title', content: 'Best Software development Mentors online - Mentorpal.Ai' },
        {
            property: 'og:description',
            content: 'Excel in software development with Mentorpal.Ai. Connect with the best online mentors to hone your coding skills and build exceptional software',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Best Software development Mentors online - Mentorpal.Ai' },
        {
            name: 'twitter:description',
            content: 'Excel in software development with Mentorpal.Ai. Connect with the best online mentors to hone your coding skills and build exceptional software',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/services.8b604a7e1d3eff97c8b2.png' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
        { name: 'Best Software development Mentors online - Mentorpal.Ai', content: 'Excel in software development with Mentorpal.Ai. Connect with the best online mentors to hone your coding skills and build exceptional software' },
    ],
};


export const describeSkill = {
    "content-writing":{
        "title":"Unleash your creativity with MentorPal.ai, your gateway to excellence in content writing.",
        "description": [
            "MentorPal.ai revolutionizes the realm of content writing mentorship, connecting aspiring writers with industry luminaries from renowned global companies. Our platform ensures unparalleled guidance, equipping individuals with the skills and insights needed to excel in content creation. ",
            "Backed by the expertise of top leaders, MentorPal.ai not only cultivates talent but guarantees job opportunities, shaping the next generation of content creators and securing a pathway to success in the dynamic world of writing.",
          ]
    },
    "data-science":{
        "title":"Ready to Excel in Data Science? Unlock Your Potential with Our Expert Mentorship!",
        "description": [
            "MentorPal.ai, your gateway to excellence in data science, connects aspiring professionals with the industry's brightest minds. Dive into the world of data with personalized mentorship from top-tier data scientists hailing from renowned global companies.",
            " Unlock your full potential and chart a successful career path with MentorPal.ai – where expertise meets ambition, and mentorship fuels success in the dynamic realm of data science.",
          ]
    },
    "design":{
        "title":"Empowering Futures, Unleashing Insights: Your Design Journey Starts Here!",
        "description": [
            "Elevate your design journey with personalized mentorship, where creativity meets guidance. Cultivate your unique style, refine your skills, and navigate the world of design under the tutelage of experienced mentors.",
            " Unlock your full artistic potential and thrive in the ever-evolving realm of design with our dedicated mentorship program.",
          ]
    },
    "software-development":{
        "title":"Code You   r Success Story: Ready to Level Up Your Skills with Expert Software Development Mentorship?",
        "description": [
            "Embark on your coding odyssey with MentorPal.ai – your gateway to software development excellence. Elevate your skills under the mentorship of industry trailblazers from renowned global companies and secure your future with guaranteed job opportunities.",
            "At MentorPal.ai, we're not just shaping developers; we're crafting success stories in code. Join us in the journey where mentorship meets job assurance, forging your path to success in the dynamic world of software development."
          ]
    },
    "product-research":{
        "title":"Shaping Tomorrow's Hits: Curate Success with Expert Mentorship in Product Research!",
        "description": [
            "Unlock the realm of innovation with MentorPal.ai – your compass to excellence in product research. Immerse yourself in personalized mentorship curated by industry leaders from renowned global companies, and secure your future with guaranteed job opportunities. MentorPal.ai not only hones your skills but propels your career forward.",
             " Join us where mentorship meets job certainty, shaping you into a standout force in the dynamic field of product research."
        ]
    },
    "marketing":{
        "title":"Fuel Your Success in Marketing: Are You Ready to Elevate Your Strategies with Expert Mentorship?",
        "description": [
            "Dive into the world of marketing mastery with MentorPal.ai, where mentorship meets opportunity. Unleash your potential under the guidance of top leaders from renowned global companies, and let us not just shape your skills, but guarantee your journey to a promising career.",
            "MentorPal.ai: Your passport to marketing excellence, personalized mentorship, and a guaranteed pathway to coveted job opportunities in the dynamic landscape of global marketing."
          ]
    },
    "product-management":{
        "title":"Struggling with Product Management? Ready to Master it with Our Guiding Expertise?",
        "description": [
            "Elevate your product management journey with MentorPal.ai, your passport to success in the corporate landscape. Immerse yourself in personalized mentorship curated by the industry's trailblazing leaders from renowned global companies.",
            " At MentorPal.ai, we transform ambition into achievement, pairing you with top minds in product management to navigate the path to innovation and success. Join us, where excellence meets mentorship, and carve out your legacy in the world of product management.",
          ]
    }
}
export const HireTalentSeo = {
    title: 'Fast track your tech hiring with prescreened candidates- Mentorpal.ai',
    canonical: 'https://www.mentorpal.ai/hire-talent',
    metaTags: [
        {
            name: 'Description',
            content: 'We provide potential hires post deep vetting tech interviews,custom tailored to your company growth.',
        },
        { name: 'keywords', content: 'Find Mentorship Price Online' },
        { property: 'og:url', content: 'https://www.mentorpal.ai/hire-talent' },
        { property: 'og:title', content: 'Fast track your tech hiring with prescreened candidates- Mentorpal.ai' },
        {
            property: 'og:description',
            content: 'We provide potential hires post deep vetting tech interviews,custom tailored to your company growth..',
        },
        { property: 'og:image', content: 'https://www.mentorpal.ai/static/media/teachingConcept.09fc4367a3e211a8b877.jpg' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:site_name', content: 'mentorpal' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'EN' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Fast track your tech hiring with prescreened candidates- Mentorpal.ai' },
        {
            name: 'twitter:description',
            content: 'We provide potential hires post deep vetting tech interviews,custom tailored to your company growth.',
        },
        { name: 'twitter:image:src', content: 'https://www.mentorpal.ai/static/media/teachingConcept.09fc4367a3e211a8b877.jpg' },
        { name: 'twitter:domain', content: 'mentorpal' },
        { name: 'copyright', content: 'mentorpal' },
        { name: 'author', content: 'mentorpal' },
        { name: 'email', content: 'help@mentorpal.ai' },
        { name: 'googlebot', content: 'index, follow' },
        { name: 'yahooSeeker', content: 'index, follow' },
        { name: 'msnbot', content: 'index, follow' },
        { name: 'Rating', content: 'General' },
        { name: 'Robots', content: 'INDEX,FOLLOW' },
        { name: 'Revisit-after', content: '7 Days' },
    ],
};