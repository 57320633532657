import { Drawer } from 'antd';
import React from 'react';

const EmailDrawer = ({emailModal,setEmailModal}) => {
    const isMobile = () => {
        // Check if the userAgent contains any keywords indicating a mobile device
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  return (
   <>
          <Drawer
              title={null}
              placement={isMobile ? "right" : "bottom"}
              open={emailModal}
              onClose={setEmailModal}
              width={600}
              closable={false}
              height={isMobile ? "" : "75%"}
              destroyOnClose
              className="call-details-drawer"
              styles={{ body: { padding: 0 } }}
          >
              <div className="ant-drawer-body" style={{ padding: 0 }}>
                  <div className="ant-row details-header ">
                      <div className="ant-col ant-col-xs-21 ant-col-md-21 ">
                          <span className="ant-typography details-title ">
                              Send Email
                          </span>
                      </div>
                      <div
                          className="ant-col ant-col-xs-3 ant-col-md-2 "
                          style={{ textAlign: "end" }}
                      >

                          <svg
                              onClick={() => {
                                  setEmailModal(false)
                              }}
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth={0}
                              viewBox="0 0 512 512"
                              className="drawer-close-icon"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                              <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                          </svg>
                      </div>
                  </div>
                  <div className="ant-typography details-body ">
                      <div className="ant-col drawer-table ">
                          <span className="ant-typography content-title ">Message</span>
                          <div className="ant-typography " style={{ paddingTop: 8 }}>
                              <div className="quill custom-quill-editor ">
                                  <div className="ql-toolbar ql-snow">
                                      <span className="ql-formats">
                                          <button type="button" className="ql-bold">
                                              <svg viewBox="0 0 18 18">
                                                  {" "}
                                                  <path
                                                      className="ql-stroke"
                                                      d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
                                                  />{" "}
                                                  <path
                                                      className="ql-stroke"
                                                      d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
                                                  />{" "}
                                              </svg>
                                          </button>
                                          <button type="button" className="ql-italic">
                                              <svg viewBox="0 0 18 18">
                                                  {" "}
                                                  <line className="ql-stroke" x1={7} x2={13} y1={4} y2={4} />{" "}
                                                  <line className="ql-stroke" x1={5} x2={11} y1={14} y2={14} />{" "}
                                                  <line className="ql-stroke" x1={8} x2={10} y1={14} y2={4} />{" "}
                                              </svg>
                                          </button>
                                          <button type="button" className="ql-underline">
                                              <svg viewBox="0 0 18 18">
                                                  {" "}
                                                  <path
                                                      className="ql-stroke"
                                                      d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"
                                                  />{" "}
                                                  <rect
                                                      className="ql-fill"
                                                      height={1}
                                                      rx="0.5"
                                                      ry="0.5"
                                                      width={12}
                                                      x={3}
                                                      y={15}
                                                  />{" "}
                                              </svg>
                                          </button>
                                          <button type="button" className="ql-list" value="bullet">
                                              <svg viewBox="0 0 18 18">
                                                  {" "}
                                                  <line className="ql-stroke" x1={6} x2={15} y1={4} y2={4} />{" "}
                                                  <line className="ql-stroke" x1={6} x2={15} y1={9} y2={9} />{" "}
                                                  <line className="ql-stroke" x1={6} x2={15} y1={14} y2={14} />{" "}
                                                  <line className="ql-stroke" x1={3} x2={3} y1={4} y2={4} />{" "}
                                                  <line className="ql-stroke" x1={3} x2={3} y1={9} y2={9} />{" "}
                                                  <line className="ql-stroke" x1={3} x2={3} y1={14} y2={14} />{" "}
                                              </svg>
                                          </button>
                                          <button type="button" className="ql-list" value="ordered">
                                              <svg viewBox="0 0 18 18">
                                                  {" "}
                                                  <line className="ql-stroke" x1={7} x2={15} y1={4} y2={4} />{" "}
                                                  <line className="ql-stroke" x1={7} x2={15} y1={9} y2={9} />{" "}
                                                  <line className="ql-stroke" x1={7} x2={15} y1={14} y2={14} />{" "}
                                                  <line
                                                      className="ql-stroke ql-thin"
                                                      x1="2.5"
                                                      x2="4.5"
                                                      y1="5.5"
                                                      y2="5.5"
                                                  />{" "}
                                                  <path
                                                      className="ql-fill"
                                                      d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"
                                                  />{" "}
                                                  <path
                                                      className="ql-stroke ql-thin"
                                                      d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"
                                                  />{" "}
                                                  <path
                                                      className="ql-stroke ql-thin"
                                                      d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"
                                                  />{" "}
                                              </svg>
                                          </button>
                                          <button type="button" className="ql-image">
                                              <svg viewBox="0 0 18 18">
                                                  {" "}
                                                  <rect
                                                      className="ql-stroke"
                                                      height={10}
                                                      width={12}
                                                      x={3}
                                                      y={4}
                                                  />{" "}
                                                  <circle className="ql-fill" cx={6} cy={7} r={1} />{" "}
                                                  <polyline
                                                      className="ql-even ql-fill"
                                                      points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"
                                                  />{" "}
                                              </svg>
                                          </button>
                                          <span className="ql-header ql-picker">
                                              <span
                                                  className="ql-picker-label"
                                                  tabIndex={0}
                                                  role="button"
                                                  aria-expanded="false"
                                                  aria-controls="ql-picker-options-0"
                                              >
                                                  <svg viewBox="0 0 18 18">
                                                      {" "}
                                                      <polygon
                                                          className="ql-stroke"
                                                          points="7 11 9 13 11 11 7 11"
                                                      />{" "}
                                                      <polygon className="ql-stroke" points="7 7 9 5 11 7 7 7" />{" "}
                                                  </svg>
                                              </span>
                                              <span
                                                  className="ql-picker-options"
                                                  aria-hidden="true"
                                                  tabIndex={-1}
                                                  id="ql-picker-options-0"
                                              >
                                                  <span
                                                      tabIndex={0}
                                                      role="button"
                                                      className="ql-picker-item"
                                                      data-value={1}
                                                  />
                                                  <span
                                                      tabIndex={0}
                                                      role="button"
                                                      className="ql-picker-item"
                                                      data-value={2}
                                                  />
                                                  <span
                                                      tabIndex={0}
                                                      role="button"
                                                      className="ql-picker-item"
                                                      data-value={3}
                                                  />
                                                  <span
                                                      tabIndex={0}
                                                      role="button"
                                                      className="ql-picker-item"
                                                      data-value={4}
                                                  />
                                                  <span
                                                      tabIndex={0}
                                                      role="button"
                                                      className="ql-picker-item"
                                                      data-value={5}
                                                  />
                                                  <span
                                                      tabIndex={0}
                                                      role="button"
                                                      className="ql-picker-item ql-selected"
                                                  />
                                              </span>
                                          </span>
                                          <select className="ql-header" style={{ display: "none" }}>
                                              <option value={1} />
                                              <option value={2} />
                                              <option value={3} />
                                              <option value={4} />
                                              <option value={5} />
                                              <option selected="selected" />
                                          </select>
                                      </span>
                                  </div>
                                  <div className="ql-container ql-snow" style={{ position: "relative" }}>
                                      <div
                                          className="ql-editor ql-blank"
                                          data-gramm="false"
                                          contentEditable="true"
                                      >
                                          <p>
                                              <br />
                                          </p>
                                      </div>
                                      <div className="ql-clipboard" contentEditable="true" tabIndex={-1} />
                                      <div className="ql-tooltip ql-hidden">
                                          <a
                                              className="ql-preview"
                                              rel="noopener noreferrer"
                                              target="_blank"
                                              href="about:blank"
                                          />
                                          <input
                                              type="text"
                                              data-formula="e=mc^2"
                                              data-link="https://quilljs.com"
                                              data-video="Embed URL"
                                          />
                                          <a className="ql-action" />
                                          <a className="ql-remove" />
                                      </div>
                                      <ul
                                          className="emoji_completions"
                                          style={{ position: "absolute", display: "none" }}
                                      />
                                  </div>
                              </div>
                              <div className="ant-row " style={{ marginTop: 24 }}>
                                  <div className="ant-col ant-col-8 ">
                                      <button
                                          type="button"
                                          className="ant-btn  ant-btn-default ant-btn-block btn-dark"
                                      >
                                          <span>Notify</span>
                                      </button>
                                  </div>
                                  <div className="ant-col ant-col-1 ">
                                      <div
                                          className="ant-divider  ant-divider-vertical"
                                          role="separator"
                                          style={{ height: "100%" }}
                                      />
                                  </div>
                                  <div className="ant-col ant-col-15 ">
                                      <span className="ant-typography ">
                                          An email with your message and package booking details will be sent to
                                          afdsfas
                                      </span>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>

          </Drawer>
   </>
  );
}

export default EmailDrawer;
