import React, { useEffect, useState } from 'react';
import '../../../css/calls.css'
import { Button, Drawer, Space, Table, Typography } from 'antd';
import { convertTimeRangeToLocal, convertUTCtoDate, isMobile } from '../../../Data/Data';
import CancelWebinar from './CancelWebinar';
import MessageParticipants from './MessageParticipants';

const WebinarRegModal = ({ RegModal, setRegModal, ModalData }) => {
    const [cancelScreen, setCancelScreen] = useState(false);
    const [MessageModal, setMessage] = useState(false);
    const [consumerList, setConsumerList] = useState([]);
    const [loadingList, setLoadingList] = useState(false);
    const [showTestimonials, setShowTestimonials] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setConsumerList(ModalData?.results)
    }, [])


    const { Paragraph, Text } = Typography;

    const handleTableChange = (pagination) => {
        setPage(pagination?.current);

    };

    const columns = isMobile
        ? [
            {
                key: 1,
                title: "Name",
                dataIndex: "consumer_name",
                width: "35%",
            },
            {
                key: 2,
                title: "Amount",
                width: "35%",
                render: (data) => {
                    return {
                        props: {},
                        children: ` ${data?.currency?.display_text} ${data?.price}`,
                    };
                },
            },
        ]
        : [
            {
                key: 1,
                title: "Name",
                dataIndex: "consumer_name",
                width: "35%",
            },
            {
                key: 2,
                title: "Amount",
                width: "35%",
                render: (data) => {
                    return {
                        props: {},
                        children: ` ${data?.currency?.display_text} ${data?.price}`,
                    };
                },
            },
            {
                key: 3,
                title: "Rating",
                dataIndex: "booking_review",
                width: "35%",
                render: (data) => {
                    return {
                        props: {},
                        children: data?.rating ? `${data?.rating}/5` : "-",
                    };
                },
            },
        ];
    return (
        <>
            <Drawer
                title={null}
                placement={isMobile ? "right" : "bottom"}
                open={RegModal}
                onClose={setRegModal}
                width={600}
                closable={false}
                height={isMobile ? "" : "75%"}
                destroyOnClose
                className="call-details-drawer"
                styles={{ body: { padding: 0 } }}
            >
                <div className="ant-drawer-body" style={{ padding: 0 }}>
                    {
                        cancelScreen ? (
                            <>
                                <CancelWebinar setCancelScreen={setCancelScreen} />
                            </>
                        ) :
                        MessageModal ? (

                            <>
                                    <MessageParticipants
                                      setMessage={setMessage}/>
                                                                </>
                        ):
                            showTestimonials ?
                                (
                                    <>
                                        Testimonial

                                    </>
                                ) : (
                                    <>

                                        <div className="ant-row details-header ">
                                            <div className="ant-col ant-col-xs-21 ant-col-md-21 ">
                                                <span className="ant-typography details-title ">
                                                    {ModalData?.service?.title}
                                                </span>
                                            </div>
                                            <div
                                                className="ant-col ant-col-xs-3 ant-col-md-2 "
                                                style={{ textAlign: "end" }}
                                            >

                                                <svg
                                                    onClick={() => {
                                                        setRegModal(false)
                                                    }}
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    strokeWidth={0}
                                                    viewBox="0 0 512 512"
                                                    className="drawer-close-icon"
                                                    height="1em"
                                                    width="1em"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ant-typography details-body ">
                                            <div className="ant-row ">
                                                <div
                                                    className="ant-col call-date ant-col-xs-8 ant-col-md-8 "
                                                    style={{ borderTop: 0 }}
                                                >
                                                    <div className="ant-space  ant-space-horizontal ant-space-align-start ant-space-gap-row-small ant-space-gap-col-small">
                                                        <div className="ant-space-item">
                                                            <svg
                                                                stroke="currentColor"
                                                                fill="none"
                                                                strokeWidth={2}
                                                                viewBox="0 0 24 24"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                height={20}
                                                                width={20}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <rect x={3} y={4} width={18} height={18} rx={2} ry={2} />
                                                                <line x1={16} y1={2} x2={16} y2={6} />
                                                                <line x1={8} y1={2} x2={8} y2={6} />
                                                                <line x1={3} y1={10} x2={21} y2={10} />
                                                            </svg>
                                                        </div>
                                                        <div className="ant-space-item"> </div>
                                                        <div className="ant-space-item">{convertUTCtoDate(ModalData?.time)} </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="ant-col call-date ant-col-xs-16 ant-col-md-16 "
                                                    style={{ borderLeft: 0, borderRight: 0, borderTop: 0 }}
                                                >
                                                    <div className="ant-space  ant-space-horizontal ant-space-align-start ant-space-gap-row-small ant-space-gap-col-small">
                                                        <div className="ant-space-item">
                                                            <svg
                                                                stroke="currentColor"
                                                                fill="none"
                                                                strokeWidth={2}
                                                                viewBox="0 0 24 24"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                height={20}
                                                                width={20}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <circle cx={12} cy={12} r={10} />
                                                                <polyline points="12 6 12 12 16 14" />
                                                            </svg>
                                                        </div>
                                                        <div className="ant-space-item">                                                                                        {convertTimeRangeToLocal(ModalData?.time, ModalData?.duration)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="ant-row ant-row-start action-btns "
                                                style={{ marginLeft: "-6px", marginRight: "-6px" }}
                                            >
                                                <div
                                                    className="ant-col ant-col-xs-12 ant-col-md-8 "
                                                    style={{ paddingLeft: 6, paddingRight: 6 }}
                                                >
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="ant-btn  ant-btn-default ant-btn-block btn-dark"
                                                            disabled=""
                                                        >
                                                            <span>Start Webinar</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    className="ant-col ant-col-xs-12 ant-col-md-6 "
                                                    style={{ paddingLeft: 6, paddingRight: 6 }}
                                                >
                                                    <div>
                                                        <button
                                                            onClick={() => setCancelScreen(true)}
                                                            type="button"
                                                            className="ant-btn  ant-btn-default ant-btn-block btn-edit"
                                                        >
                                                            <span>Cancel</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ant-row ant-row-space-between ant-row-middle action-btns ">
                                                <div className="ant-typography webinar-reg-title ">
                                                    Registrations({ModalData?.group_call_count})
                                                </div>
                                                <div className="ant-space  ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small">
                                                    <div className="ant-space-item">
                                                        <button
                                                                onClick={() => setMessage(true)}

                                                            type="button"
                                                            className="ant-btn  ant-btn-default ant-btn-block btn-edit"
                                                        >
                                                            <span className="ant-btn-icon">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 16 16"
                                                                    className="download-icon"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                                </svg>
                                                            </span>
                                                            <span>Message all</span>
                                                        </button>
                                                    </div>
                                                    <div className="ant-space-item">
                                                        <button
                                                            type="button"
                                                            className="ant-btn  ant-btn-default ant-btn-icon-only ant-btn-block btn-edit"
                                                        >
                                                            <span className="ant-btn-icon">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 16 16"
                                                                    className="download-icon"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Table
                                                columns={columns}
                                                dataSource={consumerList}
                                                loading={loadingList}
                                                onChange={handleTableChange}
                                                rowKey={(record) => record?.id}
                                                expandable={{
                                                    expandedRowRender: (record) => (
                                                        <Paragraph style={{ textAlign: "center" }}>
                                                            {isMobile ? (
                                                                <Paragraph style={{ margin: 0 }}>
                                                                    {record?.consumer_email}{" "}
                                                                    {record?.consumer_phone
                                                                        ? `| ${record?.consumer_phone}`
                                                                        : ""}
                                                                </Paragraph>
                                                            ) : (
                                                                <Space
                                                                    align="center"
                                                                    style={{
                                                                        gap: 52,
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <Paragraph style={{ margin: 0 }}>
                                                                        Email: {record?.consumer_email}{" "}
                                                                    </Paragraph>
                                                                    {record?.consumer_phone && (
                                                                        <Paragraph style={{ margin: 0 }}>
                                                                            Mobile: {record?.consumer_phone}
                                                                        </Paragraph>
                                                                    )}
                                                                </Space>
                                                            )}
                                                        </Paragraph>
                                                    ),
                                                    rowExpandable: (record) => record.name !== "Not Expandable",
                                                }}
                                                pagination={{
                                                    total: 10,
                                                    pageSize: 20,
                                                    responsive: true,
                                                    showSizeChanger: false,
                                                    hideOnSinglePage: true,
                                                    current: Number(page),
                                                }}
                                                scroll={isMobile ? { y: 240, x: 600 } : { y: 240 }}
                                                bordered
                                                className="drawer-table"
                                            />
                                        </div>
                                    </>
                                )
                    }

                </div>

            </Drawer>
        </>
    );
}

export default WebinarRegModal;
