import React, { useEffect, useState } from 'react';
import ProfileBasicDetails from './BasicDetails';
import Setting from './Setting';
import AdvancedProfile from './AdvacnedProfile';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_USER_ID } from '../../auth/tokenProvider';
import { getUserDetails } from '../../../redux/actions/login';
import { Form } from 'antd';
import CineIntro from './CineIntro';

const ProfileComponent = () => {
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('profile'); // Default active tab
    const user_id = REACT_USER_ID;
    const [data, setdata] = useState()
    const [isLoading, setisLoading] = useState(false)
    const [error, seterror] = useState()

    useEffect(() => {
        dispatch(getUserDetails(user_id));
    }, [dispatch, user_id]);

    const userDetails = useSelector(
        (state) => state?.userDetailsReducer
    );

    useEffect(() => {
        setdata(userDetails?.data[0])
        setisLoading(userDetails?.loading)
        seterror(userDetails?.error)
    }, [userDetails])

    const [ProfileDetailsForm] = Form.useForm();

    useEffect(() => {
        ProfileDetailsForm?.setFieldsValue({
            ...data,
            socials: data?.social_links || []
        })
    }, [ProfileDetailsForm, data])
    const OnHandleChanges = () => {

        ProfileDetailsForm.submit();
    }
    const tabs = [
        { key: 'profile', label: 'Profile', href: '/dashboard/profile/details' },
        { key: 'settings', label: 'Settings', href: '/dashboard/profile/settings' },
        { key: 'account', label: 'Account', href: '/dashboard/profile/account' },
        { key: 'cineIntro', label: 'CineIntro', href: '/dashboard/profile/cineIntro' },
    ];

    const handleTabClick = (tabKey) => {
        setActiveTab(tabKey);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'profile':
                return <ProfileBasicDetails form={ProfileDetailsForm}
                    profile={data} loading={isLoading} setLoading={setisLoading} />;
            case 'settings':
                return <Setting form={ProfileDetailsForm}
                    profile={data} loading={isLoading} setLoading={setisLoading} />;
            case 'account':
                return <AdvancedProfile form={ProfileDetailsForm}
                    profile={data} loading={isLoading} setLoading={setisLoading} />;
            case 'cineIntro':
                return <CineIntro form={ProfileDetailsForm}
                    profile={data} loading={isLoading} setLoading={setisLoading} />;
            default:
                return <ProfileBasicDetails form={ProfileDetailsForm}
                    profile={data} loading={isLoading} setLoading={setisLoading} />;
        }
    };
    const updateProfileReducer = useSelector((state) => {
        return state.updateProfileReducer;
    });
    useEffect(() => {
        const { data, loading, error, status } = updateProfileReducer
        setisLoading(loading)
        if (!loading && status === true) {
            dispatch(getUserDetails(user_id));

        }
    }, [dispatch, updateProfileReducer, user_id])

    return (
        <>


            <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
                <div className="ant-row container-title-section sticky ">
                    <div className="ant-col header-section ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-14 ant-col-md-offset-1 ant-col-xl-12 ant-col-xl-offset-1 ant-col-xxl-12 ant-col-xxl-offset-1 ">
                        <h4 className="ant-typography layout-header ">
                            <div className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line ">
                                Profile
                            </div>
                        </h4>
                    </div>
                    <div className="ant-col tab-container ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xl-22 ant-col-xl-offset-1 ant-col-xxl-22 ant-col-xxl-offset-1 ">
                        <span className="ant-typography flex-center " style={{ overflowY: 'auto' }}>
                            {tabs.map((tab) => (
                                <a
                                    key={tab.key}
                                    className={`tab-link tab-link-common ${activeTab === tab.key ? 'active' : ''}`}
                                    onClick={() => handleTabClick(tab.key)}
                                >
                                    <span className="ant-typography tab-title ">{tab.label}</span>
                                </a>
                            ))}
                        </span>
                        <div className="ant-flex " style={{ gap: 12 }}>
                            <button
                                onClick={OnHandleChanges}
                                type="button"
                                className="ant-btn  ant-btn-default tab-cta-link tab-link-common btn-dark"
                            >
                                <span>Save changes</span>
                            </button>
                        </div>

                    </div>
                </div>
                <div className='mx-5 mx-md-3'>
                    {renderContent()}
                </div>
            </div>
        </>
    );
};

export default ProfileComponent;
