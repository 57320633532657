import React, { useState } from "react";
import { Button, Col, Form, Input, Radio, Row, Typography, message } from "antd";
import { WebsiteRegexPattern } from './../../MentorDashboardNew/Services/AddNewService/WebinarMeetingLink';
import { TailSpin } from "react-loader-spinner";
import { REACT_USER_ID } from "../../auth/tokenProvider";

const { Title, Paragraph } = Typography;

const OnboardingAboutYou = (props) => {
    const { form, profile, loading, setLoading } = props;
    const [formEdit, setFormEdit] = useState([]);

    const [selectedTags, setSelectedTags] = useState([]);

    const serviceTypes = [
        { id: 1, title: "1:1 sessions" },
        { id: 2, title: "Priority DMs" },
        { id: 3, title: "Webinars" },
        { id: 4, title: "Packages" },
        { id: 5, title: "Digital products" },
    ];

    const reasons = [
        { id: 1, title: "I want to offer my expertise to my followers", value: "I want to offer my expertise to my followers" },
        { id: 2, title: "I want to monetise my audience", value: "I want to monetise my audience" },
        { id: 3, title: "I’m just exploring", value: "I’m just exploring" },
    ];

    const initialValues = {
        user_id: REACT_USER_ID,
        name: profile?.name,
        language: profile?.language,
        price_per_session: profile?.price_per_session,
        career_advice: profile?.career_advice,
        company_or_school: profile?.company_or_school,
        country: profile?.country,
        experience: profile?.experience,
        experties: profile?.experties,
        job_search: profile?.job_search,
        leadership: profile?.leadership,
        mentorship: profile?.mentorship,
        skills: profile?.skills,
        story: profile?.story,
        title: profile?.title,
        is_pro_bono: profile?.is_pro_bono,
        inr_price: profile?.inr_price,
        socials:
            profile?.social_urls?.filter(
                (social) => String(social?.name).toLowerCase() !== "website"
            ) || [],
    };
    const onFinish = async (values) => {
        try {
            setLoading(true);
            console.log("Form Data on Finish:", values);
            // ... perform other actions, e.g., API call
        } catch (error) {
            console.error("Form validation error:", error);
        } finally {
            setLoading(false);
        }
    };

    const skipClicked = async () => {

    };


    const tagClicked = (tag) => {
        const updatedTags = selectedTags.includes(tag)
            ? selectedTags.filter(item => item !== tag)
            : [...selectedTags, tag];

        form.setFieldsValue({ intended_service_type: updatedTags });
        setSelectedTags(updatedTags);
    };
    return (
        <>
            {loading ? (
                <div className="loader open_sessions_loader ">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            ) : (null)}
            <Row justify="center" className="onboarding-main">
                <Col xs={22} md={24} className="onboarding-content">
                    <Title level={5} className="onboarding-header">Hello there!</Title>
                    <Paragraph className="onboarding-subheader">
                        In a few moments you will be ready to share your expertise & time
                    </Paragraph>
                    <Row justify="center">
                        <Col xs={24} md={24} className="general-info" style={{ marginBottom: "80px" }}>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={initialValues}
                                onFinish={onFinish}
                                scrollToFirstError={{
                                    behavior: actions =>
                                        actions.forEach(({ el, top }) => {
                                            el.scrollTop = top + 120;
                                        }),
                                    scrollMode: "always",
                                }}
                                requiredMark={false}

                            >
                                <Form.Item
                                    label="Connect your social account"
                                    name="url"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <Paragraph className="social-error">
                                                    <Paragraph className="social-error-text">
                                                        <Paragraph>
                                                            We&apos;d love to know you better, Add socials for the
                                                            best experience.
                                                        </Paragraph>
                                                        <Paragraph
                                                            underline
                                                            className="error-skip-btn"
                                                            onClick={skipClicked}
                                                        >
                                                            Skip for now
                                                        </Paragraph>
                                                    </Paragraph>
                                                </Paragraph>
                                            ),
                                        },
                                        {
                                            pattern: WebsiteRegexPattern,
                                            message: "Invalid URL entered, please check URL",
                                        },
                                    ]}
                                >
                                    <Input placeholder="LinkedIn, Twitter, Instagram" addonBefore="https://" />
                                </Form.Item>

                                <Form.Item
                                    label="How do you plan to use topmate"
                                    name="registration_intent"
                                    className="reason-field"
                                    required
                                    rules={[{ required: true, message: "Select an option" }]}
                                >
                                    <Radio.Group className="radio-checkbox">
                                        {reasons.map(reason => (
                                            <Radio.Button key={reason.id} className="radio-checkbox-item" value={reason.value}>
                                                {reason.title}
                                            </Radio.Button>
                                        ))}
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    label="What all do you plan to offer?"
                                    name="intended_service_type"
                                    required
                                    rules={[{ required: selectedTags.length === 0, message: "Select a service type" }]}
                                >
                                    <Paragraph id="intended_service_type">
                                        <Row justify="start" align="middle" gutter={6}>
                                            {serviceTypes.map(service => (
                                                <Col key={service.id}>
                                                    <Paragraph
                                                        className={`option-tag cursor-hover option-tag-container ${selectedTags.includes(service.id) ? "option-tag-selected" : ""
                                                            }`}
                                                        onClick={() => tagClicked(service.id)}
                                                    >
                                                        {service.title}
                                                    </Paragraph>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Paragraph>
                                </Form.Item>

                                <Row>
                                    <Col xs={{ span: 24 }} md={{ span: 0 }}>
                                        <Form.Item>
                                            <Button
                                                htmlType="submit"
                                                className="btn-dark"
                                                loading={loading}
                                                block
                                                disabled={loading}
                                                style={{ marginTop: 20 }}

                                            >
                                                Save Changes
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default OnboardingAboutYou;
