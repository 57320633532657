import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import crossImg from '../img/cross.png';

 
import { useDispatch, useSelector } from "react-redux";
import {
  mentorChooseSessionServiceType,
  mentorSessionServiceAvailability,
} from "../../../redux/actions/mentor";
 
import("../css/custom.css");

const CreateSession = () => {
  const navigate = useNavigate();
  const selectedSessionTypeSavedData = useSelector((state) => {
    const data = state.mentorServiceSessionTypeReducer?.data?.type || "";
    return data;
  });
  const selectedSessionAvailabilitySavedData = useSelector((state) => {
    const data = state.mentorSessionServiceAvailabilityReducer?.data || "";
    return data;
  });
  const [availabilityDays, setAvailabilityDays] = useState(
    selectedSessionAvailabilitySavedData?.availability?.days[0].startDate
  );
  const [availabilityHours, setAvailabilityHours] = useState(
    selectedSessionAvailabilitySavedData?.availability?.timings[0]?.startTime
  );
  const dispatch = useDispatch();
  const availabilityDataModel = {
    availability: {
      timings: [
        {
          startTime: "",
        },
      ],
      frequency: "",
      days: [
        {
          startDate: "",
        },
      ],
    },
  };

  const availabilityDaysChoice = [
    {
      text: "Select Days",
      value: "",
    },
    {
      text: "Every Day",
      value: "Every Day",
    },
    {
      text: "Weekdays",
      value: "Weekdays",
    },
    {
      text: "Weekend",
      value: "Weekend",
    },
  ];
  const availabilityHoursChoice = [
    {
      text: "Select Hours",
      value: "",
    },
    {
      text: "7-8 PM",
      value: "7-8 PM",
    },
    {
      text: "1 Hour Every Day",
      value: "1 Hour Every Day",
    },
    {
      text: "2 or more Hours on weekend",
      value: "2 or more Hours on weekend",
    },
  ];
  const setAvailabilityDetails = () => {
    availabilityDataModel.availability.days[0].startDate = availabilityDays;
    availabilityDataModel.availability.timings[0].startTime = availabilityHours;
    availabilityDataModel.availability.frequency = selectedSessionTypeSavedData;
  };
  useEffect(() => {
     
    import("../css/custom.css");
  }, []);
  useEffect(() => {
    setAvailabilityDetails();
    dispatch(mentorSessionServiceAvailability(availabilityDataModel));
  }, [availabilityDays, availabilityHours]);

  return (
    <>
      <div id="wrapper">
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div className="step-cont">
          <div className="step-inner-cls">
            <form action="#" method="post" id="raq_questions">
              <div className="question-container  active_panel" id="question-3">
                <div className="sign-form-cls">
                  <h3>How much you are available for the session?</h3>
                  <div className="custom-radio-cls">
                    <label className="label">Your Availability?</label>
                    <div className="custom-radio-bg">
                      <label className="custom-radio">
                        <input
                          type="radio"
                          checked="checked"
                          name="radio"
                          disabled
                        />
                        <span className="checkmark"></span>
                        <span className="cont">
                          {selectedSessionTypeSavedData}
                        </span>
                      </label>
                    </div>
                    <div className="form-group">
                      <select
                        name=""
                        id=""
                        className="form-control"
                        value={availabilityDays}
                        onChange={(e) => setAvailabilityDays(e.target.value)}
                      >
                        {availabilityDaysChoice.map((choice) => (
                          <option value={choice.value}>{choice.text}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <select
                        name=""
                        id=""
                        className="form-control"
                        value={availabilityHours}
                        onChange={(e) => setAvailabilityHours(e.target.value)}
                      >
                        {availabilityHoursChoice.map((choice) => (
                          <option value={choice.value}>{choice.text}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSession;
