import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Input, message, Row, Typography, Upload } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage, uploadImageFile } from '../../../redux/actions/common';
import { updateProfile } from '../../../redux/actions/login';
import { REACT_USER_ID } from '../../auth/tokenProvider';

const { Title } = Typography;

const CineIntro = (props) => {
    const { form, profile, loading, setLoading } = props;
    const dispatch = useDispatch();

    const [videoState, setVideoState] = useState({
        isPlaying: false,
        mainFile: null,
        imageLoading: false,
    });

    const { isPlaying, mainFile, imageLoading } = videoState;


    const togglePlay = () => {
        setVideoState({ ...videoState, isPlaying: !isPlaying });
    };
    const beforeUpload = (file) => {
        const fileName = "_profile-intro";
        const imagePath = "profileIntro/";
        const videoName = imagePath + REACT_USER_ID + fileName;
        const extension = file?.name?.split('.').pop().toLowerCase();

        setVideoState({ ...videoState, mainFile: file });
        dispatch(uploadImage({ documentName: videoName, format: extension }));

        const videoNameEx = imagePath + REACT_USER_ID + fileName + '.' + extension;

        dispatch(updateProfile({
            user_id: REACT_USER_ID,
            mentor_intro_video: videoNameEx,
        }));

        return false; // Prevent automatic upload
    };

    const videoURLReqData = useSelector((state) => state.uploadImageReducer);

    useEffect(() => {
        const { data, loading, error } = videoURLReqData;

        setVideoState({ ...videoState, imageLoading: loading });

        if (data?.url && !loading && !error) {
            const reqData = { url: data.url, fileData: mainFile };
            dispatch(uploadImageFile(reqData));

        } else if (error) {
            console.log("error", error)
        }
    }, [dispatch, videoURLReqData]);

    const uploadImageFileReducer = useSelector((state) => state.uploadImageFileReducer);

    useEffect(() => {
        const { data, loading, error, status, uploaded } = uploadImageFileReducer;
        setVideoState({ ...videoState, imageLoading: loading });
        if (!loading && uploaded) {

            message.success("Profile video uploaded");
            dispatch(uploadImage());

        } else if (error) {
            console.log("An error occurred while uploading the profile video.");
        }
    }, [dispatch, uploadImageFileReducer]);

    return (
        <>
            {imageLoading && (
                <div className="loader open_sessions_loader ">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            )}
            <Row xs={24} lg={{ span: 18, offset: 3 }}>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 0 }} xl={{ span: 14, offset: 0 }}>
                    <Title level={5} style={{ fontWeight: 700, marginBottom: 0, marginTop: 15, fontSize: '20px' }}>
                        Share Your Story with Us Through Video
                    </Title>

                    <div className="container-fluid">
                        <Row className=" calls-main mt-3">
                            <Col span={24}>
                                <div className="ant-typography">
                                    <Row  style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                        <Col span={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <Col  className=" calls-card-container ant-col-xs-24" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                {profile?.mentor_intro_video === '' ? null : (
                                                    <div className="ant-typography calls-card" style={{ borderRadius: 10, overflow: 'hidden', width: '100%', height: '0', paddingTop: '56.25%', position: 'relative' }}>
                                                        <video
                                                            loading="lazy"
                                                            width="100%"
                                                            height="100%"
                                                            controls
                                                            onClick={togglePlay}
                                                            style={{ borderRadius: 10, position: 'absolute', top: '0', left: '0' }}
                                                        >
                                                            <source src={`${process.env.REACT_APP_IMAGES_BASE_URL}${profile?.mentor_intro_video}`} type="video/mp4" />
                                                        </video>
                                                    </div>

                                                )}
                                            </Col>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Divider />
                    <Row className="configurations">
                        <Col xs={24} md={24}>
                            <Upload
                                accept="video/*"
                                name="file"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                            >

                                <Button
                                    loading={loading}
                                    className="ant-btn  ant-btn-default tab-cta-link tab-link-common btn-dark"
                                    icon={<UploadOutlined />}> {profile?.mentor_intro_video !== '' ? "ReUpload" : "Upload"}</Button>
                            </Upload>
                        </Col>


                    </Row>
                    <div className=" joIaGT d-flex">
                        <p
                            className=" hAXIJe ml-2 grey-1-text"
                            style={{ whiteSpace: "normal" }}
                        >
                            Your privacy is important to us, and we will never use your
                            introduction video without your permission. By uploading your
                            video, you give us permission to share it on our app/website and
                            social media channels. You can revoke this permission at any
                            time by contacting us directly.
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default CineIntro;
