import { Row, Col, Button, Upload, Image, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { shareOnLinkedInAction } from "../../../redux/actions/mentor";
import { useEffect, useState } from "react";
import { Card, Typography, Tag, Space, Divider } from 'antd';
import { CalendarOutlined, ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import { webinarInfo } from "./webinarInfo";
import { TailSpin } from 'react-loader-spinner';
const { Title, Text } = Typography;

const SocialTemplets = () => {
  const dispatch = useDispatch();
  const [content, setContent] = useState(null);
  const provider = localStorage.getItem("PROVIDER")
  const handleChangeContent = (e) => {
    setContent(e.target.value);
  };
console.log(content)
const [loading,setLoading] = useState(false)
    // Displaying preview before uploading
  const handleShare = async () => {
   if(provider==="linkedin"){
    const data = {
      lifecycleState: "DRAFT",
      specificContent: {
        "com.linkedin.ugc.ShareContent": {
          shareCommentary: {
            text: content,
          },
          shareMediaCategory: "NONE",
        },
      },
      visibility: {
        "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC",
      },
    };
    //shareOnLinkedInReducer
   dispatch(shareOnLinkedInAction(data));
  }
  
  };

  const shareOnLinkedInReducer = useSelector(
    (state) => state?.shareOnLinkedInReducer
);
useEffect(() => {
    const { loading, isSuccess,data, error } = shareOnLinkedInReducer
    console.log("shareonlinkein",shareOnLinkedInReducer)
    setLoading(loading)
    if (!loading && isSuccess) {

        message.success("uploaded!")

    }
    if (!isSuccess && !loading) {
        message.error("Something went wrong ! Please try again later.")
    }


}, [shareOnLinkedInReducer]);
  console.log(content)
  console.log(webinarInfo)
  return (
    <>
     {loading ? (
                            <div className="loader" style={{ zIndex: 9999 }}>
                                <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                            </div>
                        ) : null}
       <Row
       style={{width:"100%",justifyContent:"space-around"}}
>
      {
        webinarInfo.map((value,index)=>(
          <>
           <Col span={6}  style={{
        border: '1px solid #dddddd',
        borderRadius: '10px',
        padding: '15px',
        marginTop: '20px',
        width:'200px'
      }}>
        <div className="form-group border-0">
          <input type="text"   
          className="form-control border-0"
            id="exampleFormControlTextarea1"
            onChange={handleChangeContent}
            placeholder=""/>

          {/* <textarea
            className="form-control border-0"
            id="exampleFormControlTextarea1"
            rows="3"
            value={value.info}
            onChange={handleChangeContent}
            placeholder="write your Share Content"
          ></textarea> */}
          <img src={value.poster} alt="demo"/>
        </div>
        <div className="d-flex justify-content-center">
        <button
          type="button"
          className="ant-btn ant-btn-primary ant-btn btn-dark"
          
          onClick={handleShare}
        >
          <span>Share on LinkedIn</span>
        </button>
        </div>
        
      </Col>
     </> 
        ))
      }
    </Row>
    </>
  );
};

export default SocialTemplets;
