import React, { useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import crossIcon from "../../../assets/images/cross-icon.png";
import { useDispatch } from 'react-redux';
import { appendFormData } from '../../../redux/actions/course';

const AddAssignmentModal = ({ OpenModal, setOpenModal }) => {
    const dispatch = useDispatch();

    const [message, setMessage] = useState(null);
    const [title, setTitle] = useState("");
    const loader = false;

    const handleSubmitAssignment = (e) => {
        e.preventDefault();
        const assignmentData = {
            title: title,
            type: "ADD_ASSIGNMENT"
        };
        if (!title) {
            setMessage("Please enter a title")
            setTimeout(() => {
                setMessage(null)
            }, 2000);
        }
        else {

            let actionType;

            switch (OpenModal?.add_type) {
                case 'ADD_CHAPTER':
                    actionType = "ADD_CHAPTER_TO_UNIT_BY_INDEX";
                    break;
                case 'ADD_UNIT':
                    actionType = "ADD_UNIT";
                    break;
                default:
                    actionType = "APPEND_FORM_DATA";
                    break;
            }

            dispatch(appendFormData(actionType, OpenModal?.chapterIndex, OpenModal?.unitIndex, assignmentData));
            handleCancel()

        }
    };
    const handleCancel = () => {
        setOpenModal((prevState) => ({ ...prevState, show: false }))

    }

    return (
        <>
            {loader && (
                <div className="loader" style={{ zIndex: 9999 }}>
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            )}
            <Modal
                size="xl"
                centered
                show={OpenModal.show}
                onHide={() =>
                    setOpenModal((prevState) => ({ ...prevState, show: false }))
                }
            >

                <ModalBody>

                    <button
                        type="button"
                        className="cloose"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                            setOpenModal((prevState) => ({ ...prevState, show: false }))
                        }
                    >
                        <div className="closebuttons">
                            <span aria-hidden="true">
                                <img src={crossIcon} alt="Close Icon" />
                            </span>
                        </div>
                    </button>

                    <div className="create_course_modal" style={{ background: "white" }}>
                        <div className="modal-body">
                            <div className="modal-container">
                                <div className="modal-header">
                                    <div className="modal-title h5">
                                        New Assignment
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <form>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label className="session__label">
                                                        Title: *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        placeholder="Enter Title"
                                                        required=""
                                                        className="form-control"
                                                        value={title}
                                                        onChange={(e) => setTitle(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="get-started-cls why_choose_ment">
                                                <a href="#">
                                                    <button type="button" className="find-btn btn btn-primary">
                                                        Cancel
                                                    </button>
                                                </a>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleSubmitAssignment}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                            {
                                                message && <p> {message}</p>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default AddAssignmentModal;
