import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Skeleton,
    Space,
    Spin,
    Switch,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { FiCalendar, FiLink2 } from "react-icons/fi";
import { HiOutlineCalendar, HiOutlineLocationMarker } from "react-icons/hi";
import { IoIosClose } from "react-icons/io";
import { WebsiteRegexPattern } from './../Services/AddNewService/WebinarMeetingLink';
import { isMobile } from "../Data/Data";
import DisplayEmail from './Modal/DisplayEmail';
import ReschedulePolicyModal from "./Modal/ReschedulePolicyModal";
import { Timezones_List } from "../../../assets/data/data";
import { localTimezone } from "../Data/exportConst";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, updateProfile } from "../../../redux/actions/login";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import { TailSpin } from "react-loader-spinner";
import CalanderConnect from "../../common/google-calander";



const { Paragraph } = Typography;



const Availability = () => {
    const dispatch = useDispatch()
    const [profile, setprofile] = useState()
    const [loading, setLoading] = useState(false)
    const [BookingPeriod, setBookingPeriod] = useState(profile?.booking_period || 0)
    useEffect(() => {
        dispatch(getUserDetails(REACT_USER_ID));
    }, [dispatch]);
    const userDetailsReducer = useSelector(
        (state) => state?.userDetailsReducer
    );
    const [typeNoticePeriod, setTypeNoticePeriod] = useState(profile?.type_notice_period || 'Hours');
    const [noticePeriodData, setNoticePeriodData] = useState(profile?.notice_period || 0);
    const [toggleLoading, setToggleLoading] = useState(false);
    const [showPolicyModal, setShowPolicyModal] = useState(false);
    const [customLink, setCustomLink] = useState(
        profile?.personal_meeting_url || null
    );
    const [meetingForm] = Form.useForm();
    const [isInProgress, setInProgress] = useState(false);
    const [meetingLinkVisible, setMeetingLinkVisible] = useState(
        profile?.personal_meeting_url ? true : false
    );
    const [meetingLinkSave, setMeetingLinkSave] = useState(true);
    const [useGoogleMeet, setuseGoogleMeet] = useState(profile?.use_google_meet ? true : false);
    const [selectedTimezone, setSelectedTimezone] = useState(
        profile?.timezone ? profile?.timezone : localTimezone
    );


    const updateProfileReducer = useSelector(
        (state) => state?.updateProfileReducer
    );



    useEffect(() => {
        const { data, loading, error, status } = userDetailsReducer
        setLoading(userDetailsReducer?.loading)
        if (data && !loading && error === undefined) {

            const user = userDetailsReducer?.data[0];
            setprofile(user);

            // Set default values for state variables based on user profile
            setBookingPeriod(user?.booking_period || 0);
            setTypeNoticePeriod(user?.type_notice_period || 'Minutes');
            setNoticePeriodData(user?.notice_period || 0);
            setSelectedTimezone(user?.timezone || localTimezone);
            setCustomLink(user?.personal_meeting_url || null);
            setMeetingLinkVisible(user?.personal_meeting_url ? true : false);
            setMeetingLinkSave(true);  // Assuming you want to reset this to true initially
            setuseGoogleMeet(user?.use_google_meet ? true : false);
        }
        if (!data && !loading && error) {
            setprofile([])
            console.warn("something went wrong in User Details API")
        }
    }, [userDetailsReducer])

    const typeOfNoticePeriod = [
        {
            key: "1",
            text: "Minutes",
            value: "Minutes",
        },
        {
            key: "2",
            text: "Hours",
            value: "Hours",
        },
        {
            key: "3",
            text: "Days",
            value: "Days",
        },
        {
            key: "4",
            text: "Weeks",
            value: "Weeks",
        },
    ];

    const bookingOptions = [
        {
            label: "1 Week",
            value: "7",
        },
        {
            label: "2 Weeks",
            value: "14",
        },
        {
            label: "3 Weeks",
            value: "21",
        },
        {
            label: "4 Weeks",
            value: "28",
        },
        {
            label: "2 Months",
            value: "60",
        },
        {
            label: "3 Months",
            value: "90",
        },
    ];


    const selectBookingPeriod = async (value) => {

        try {
            await dispatch(updateProfile({ user_id: REACT_USER_ID, booking_period: value }));

            // Handle the response here if needed
            const { data, error, loading, status } = updateProfileReducer;
            console.log("updateProfileResponse", updateProfileReducer);

            if (status === true && !loading && error === undefined) {
                message.success("Booking period updated successfully!", 4);
            } else {
                console.warn("Failed to update Booking period. Please try again!");
            }
        } catch (error) {
            console.warn(error);
            console.warn("Something went wrong!");
        }
    };

    const selectTimezone = async (value) => {
        console.log("selectTimezone", value);
        try {
            await dispatch(updateProfile({ user_id: REACT_USER_ID, timezone: value }));

            // Handle the response here if needed
            const { data, error, loading, status } = updateProfileReducer;
            console.log("updateProfileResponse", updateProfileReducer);

            if (status === true && !loading && error === undefined) {
                message.success("Timezone updated successfully!", 4);
            } else {
                console.warn("Failed to update timezone. Please try again!");
            }
        } catch (error) {
            console.warn(error);
            console.warn("Something went wrong!");
        }
    };
    /*custom meetinglink*/
    const saveCustomMettingLink = async (value) => {
        const link = value
            ? value?.indexOf("://") === -1
                ? "https://" + String(value)
                : String(value)
            : null;
        setInProgress(true);
        try {
            await dispatch(updateProfile({ user_id: REACT_USER_ID, personal_meeting_url: link }));

            // Handle the response here if needed
            const { data, error, loading, status } = updateProfileReducer;
            console.log("updateProfileResponse", updateProfileReducer);

            if (status === true && !loading && error === undefined) {
                setMeetingLinkSave(false);
                message.success("Meeting link updated successfully!", 4);
            } else {
                setMeetingLinkSave(true);
                console.warn("Failed to update Meeting link. Please try again!");
            }
        } catch (error) {
            console.warn(error);
            setMeetingLinkSave(true);
            console.warn("Something went wrong!");
        }
    };

    // notice peroid time
    const onNoticePeriodTimeChange = async (value) => {
        setNoticePeriodData(value)
        try {
            await dispatch(updateProfile({ user_id: REACT_USER_ID, notice_period: value }));

            // Handle the response here if needed
            const { data, error, loading, status } = updateProfileReducer;
            console.log("updateProfileResponse", updateProfileReducer);

            if (status === true && !loading && error === undefined) {

                message.success("type_notice_period  updated successfully!", 4);
            } else {

                console.warn("Failed to update type_notice_period. Please try again!");
            }
        } catch (error) {
            console.warn(error);

            console.warn("Something went wrong!");
        }
    }
    // notice period type
    const onNoticePeriodTypeChange = async (value) => {
        setTypeNoticePeriod(value);
        console.log(noticePeriodData, { type_notice_period: value })
        try {
            await dispatch(updateProfile({ user_id: REACT_USER_ID, type_notice_period: value }));

            // Handle the response here if needed
            const { data, error, loading, status } = updateProfileReducer;
            console.log("updateProfileResponse", updateProfileReducer);

            if (status === true && !loading && error === undefined) {

                message.success("type_notice_period  updated successfully!", 4);
            } else {

                console.warn("Failed to update type_notice_period. Please try again!");
            }
        } catch (error) {
            console.warn(error);

            console.warn("Something went wrong!");
        }
    };

    const [googleCalendarModalVisible, setGoogleCalendarModalVisible] =
        useState(false);

    const submitMeetingLink = (values) => {
        saveCustomMettingLink(values?.meetingLink);
    };

    const updateGoogleMeet = (val) => {
        if (val && meetingLinkVisible) {
            console.warn("Please remove personal meeting link to use Google Meet");
        } else {

            setGoogleCalendarModalVisible(true);

        }
    };

    return (
        <>
            {loading ? (
                <div className="loader open_sessions_loader ">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            ) : (null)}
            <Row className="configurations">
                <Col xs={24} md={24}>
                    {googleCalendarModalVisible && (
                        <Modal
                            width={350}
                            closable={true}
                            centered
                            open={googleCalendarModalVisible}
                            onCancel={() => setGoogleCalendarModalVisible(false)}
                            footer={false}
                        >
                            <Paragraph
                                style={{
                                    textAlign: "left",
                                    fontSize: 22,
                                    marginBottom: 20,
                                    fontWeight: "700",
                                }}
                            >
                                Connect your Google Calendar
                            </Paragraph>
                            <Paragraph style={{ textAlign: "left", fontSize: 12 }}>
                                To start using google meet you need to sync your calendar account
                            </Paragraph>
                            <Divider />
                            <Col
                                style={{
                                    textAlign: "center",
                                    color: "green",
                                }}
                            >
                                <Spin spinning={loading}>
                                    <CalanderConnect redirectUrl="/calendar-redirection" />
                                </Spin>
                            </Col>
                        </Modal>
                    )}

                    <Row className="config-row">
                        {/* Timezone */}
                        {/* Configuration Type */}
                        <Col xs={24} md={9} className="config-type">
                            <HiOutlineLocationMarker className="config-icon" />
                            <Paragraph>
                                <Paragraph className="config-title">Timezone</Paragraph>
                                <Paragraph className="config-description">
                                    Required for timely communications
                                </Paragraph>
                            </Paragraph>
                        </Col>

                        {/* Configuration Values */}
                        <Col xs={{ span: 19, offset: 2 }} md={{ span: 6, offset: 0 }}>
                            <Select
                                value={selectedTimezone}
                                className="timezone-select"
                                classNames={{
                                    control: (state) =>
                                        state.isFocused
                                            ? "timezone-select focused"
                                            : "timezone-select",
                                }}
                                onChange={(value) => {
                                    setSelectedTimezone(value);
                                    selectTimezone(value);

                                }}
                                name="timezone"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 6,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#f5f6f8",
                                        primary: "#212121",
                                    },
                                })}
                            // options={Timezones_List}
                            >
                                {Timezones_List.map((option) => (
                                    <Select.Option key={option.timezone} value={option.timezone}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    {/* Reschedule policy */}
                    <Row className="config-row">
                        {/* Configuration Type */}
                        <Col xs={24} md={9} className="config-type">
                            <HiOutlineCalendar className="config-icon" />
                            <Paragraph>
                                <Paragraph className="config-title">Reschedule policy</Paragraph>
                                <Paragraph className="config-description">
                                    How can customers reschedule calls
                                </Paragraph>
                            </Paragraph>
                        </Col>

                        {/* Configuration Values */}
                        <Col
                            xs={{ span: 21, offset: 2 }}
                            md={{ span: 6, offset: 0 }}
                            className="timezone-select-slots"
                        >
                            <Button
                                onClick={() => {

                                    setShowPolicyModal(true);
                                }}
                            >
                                Update Policy
                            </Button>
                        </Col>
                    </Row>

                    {/* Booking Period */}
                    <Row className="config-row">
                        {/* Configuration Type */}
                        <Col xs={24} md={9} className="config-type">
                            <HiOutlineCalendar className="config-icon" />
                            <Paragraph>
                                <Paragraph className="config-title">Booking Period</Paragraph>
                                <Paragraph className="config-description">
                                    How far in the future can attendees book
                                </Paragraph>
                            </Paragraph>
                        </Col>

                        {/* Configuration Values */}
                        <Col
                            xs={{ span: 21, offset: 2 }}
                            md={{ span: 6, offset: 0 }}
                            className="timezone-select-slots"
                        >
                            <Select
                                placeholder="Select Duration"
                                options={bookingOptions}
                                value={
                                    BookingPeriod < 29
                                        ? BookingPeriod === 7
                                            ? BookingPeriod / 7 + " Week"
                                            : BookingPeriod / 7 + " Weeks"
                                        : BookingPeriod / 30 + " Months"
                                }
                                onChange={(value) => {
                                    setBookingPeriod(value)
                                    selectBookingPeriod(value);
                                }}
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </Row>
                    {/* Notice Period */}
                    <Row className="config-row">
                        {/* Configuration Type */}
                        <Col xs={24} md={9} className="config-type">
                            <HiOutlineCalendar className="config-icon" />
                            <Paragraph>
                                <Paragraph className="config-title">Notice Period</Paragraph>
                                <Paragraph className="config-description">
                                    Set the minimum amount of notice that is required
                                </Paragraph>
                            </Paragraph>
                        </Col>

                        {/* Configuration Values */}
                        <Col
                            xs={{ span: 21, offset: 2 }}
                            md={{ span: 6, offset: 0 }}
                            className="timezone-select-slots"
                        >
                            <InputNumber
                                name="buffer"
                                placeholder="Enter Value in Mins"
                                min={0}
                                defaultValue={noticePeriodData}
                                value={noticePeriodData}
                                style={{ width: "100%" }}
                                onChange={(value) =>
                                    onNoticePeriodTimeChange(value)
                                }
                                addonAfter={
                                    <Select
                                        defaultValue={typeNoticePeriod}
                                        style={{ width: 100 }}
                                        options={typeOfNoticePeriod}
                                        onChange={(value) => {
                                            onNoticePeriodTypeChange(value);
                                        }}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    {/* <Paragraph className="config-heading"> Meeting location</Paragraph> */}
                    {/* Personalized Meeting URL */}
                    <Row className="config-row" style={{ borderBottom: "0px" }}>
                        {/* Configuration Type */}
                        {/* <Col xs={24} md={9} className="config-type">
                            <FiLink2 className="config-icon" />
                            <Paragraph>
                                <Paragraph className="config-title">
                                    Personal meeting link
                                </Paragraph>
                                <Paragraph className="config-description">
                                    All your 1:1 meetings will be redirected to this URL
                                </Paragraph>
                            </Paragraph>
                        </Col> */}

                   
                        {/* {meetingLinkVisible ? (
                            <>
                                <Col
                                    xs={{ span: 19, offset: 2 }}
                                    md={{ span: 6, offset: 0 }}
                                    className="meeting-link-slot"
                                >
                                    <Form
                                        initialValues={{
                                            meetingLink: customLink,
                                        }}
                                        onFinish={submitMeetingLink}
                                        form={meetingForm}
                                    >
                                        <Form.Item
                                            name="meetingLink"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter meeting url",
                                                },
                                                {
                                                    pattern: WebsiteRegexPattern,
                                                    message: "Please enter valid Link",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Form>
                                </Col>
                                {meetingLinkSave && !customLink ? (
                                    <Col xs={{ span: 10, offset: 2 }} md={6}>
                                        <Space>
                                            <Button
                                                className="btn-dark save-btn"
                                                htmlType="submit"
                                                onClick={() => {
                                                    meetingForm.submit();
                                                }}
                                                loading={isInProgress}
                                                disabled={isInProgress}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                className="cancel-btn"
                                                onClick={() => {
                                                    meetingForm.resetFields();
                                                    setMeetingLinkVisible(!meetingLinkVisible);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Space>
                                    </Col>
                                ) : (
                                    <Col
                                        xs={{ span: 1, offset: 0 }}
                                        md={{ span: 6, offset: 0 }}
                                        className="meeting-link-slot"
                                    >
                                        <IoIosClose
                                            className="close-link-btn"
                                            size="10px"
                                            onClick={() => {
                                                saveCustomMettingLink(null);
                                                meetingForm.resetFields();
                                                setCustomLink("");
                                                setMeetingLinkVisible(false);
                                                setMeetingLinkSave(true);
                                            }}
                                        />
                                    </Col>
                                )}
                            </>
                        ) : (
                            <Col
                                xs={{ span: 21, offset: 2 }}
                                md={{ span: 6, offset: 0 }}
                                className="meeting-link-slot"
                            >
                                <Button
                                    type="dashed"
                                    color="blue"
                                    onClick={() => {
                                        if (useGoogleMeet) {
                                            console.warn(
                                                "Please turn off Google Meet to use personal meeting link"
                                            );
                                        } else {
                                            meetingForm.setFieldsValue({ meetingLink: null });
                                            setMeetingLinkVisible(true);
                                        }
                                    }}
                                    className="meetingLinkRef"
                                >
                                    + Add meeting link
                                </Button>
                            </Col>
                        )} */}
                    </Row>
                    <Divider
                        orientation={isMobile ? "center" : "left"}
                        className="meeting-divider"
                    >
                        OR
                    </Divider>
                    <Row className="config-row">
                        {/* Configuration Type */}
                        <Col xs={20} md={9} className="config-type">
                            <FiCalendar className="config-icon" />
                            <Paragraph>
                                <Paragraph className="config-title">Google meet </Paragraph>
                                <Paragraph className="config-description">
                                    Use google meet for your 1:1 calls
                                </Paragraph>
                            </Paragraph>
                        </Col>

                        {/* Configuration Values */}
                        <Col xs={{ span: 2, offset: 0 }} md={{ span: 8, offset: 0 }}>
                            {toggleLoading ? (
                                <Spin />
                            ) : (
                                <Switch
                                    checked={useGoogleMeet}
                                    onChange={updateGoogleMeet}
                                    className="googleMeetRef"
                                    size="default"
                                />
                            )}
                        </Col>
                    </Row>
                    {showPolicyModal && (
                        <ReschedulePolicyModal
                            showModal={showPolicyModal}
                            setShowModal={setShowPolicyModal}
                            profile={profile}
                        />
                    )}
                </Col>
            </Row>

        </>

    );
};

export default Availability;
