import React from "react";

const RequirementsForm = ({
    registrationProcessData,
    setRegistrationProcessData,
    errors,
}) => {
    return (
        <>
            <div className="registrationCard">
                <div className="leftSide">
                    <h3 className="mb-32 fw-bold">
                        What is your expectation from mentorship ?.
                    </h3>
                    <p className="related-context-for-bio">
                        "Please share your expectations from the mentorship. This could include specific skills you want to develop, career guidance, project assistance, or any other areas where you seek guidance and support. Your response will help us tailor the mentorship experience to meet your needs effectively."
                    </p>
                </div>

                <div className="row rightSide" style={{ flex: "1" }}>
                    <div className="container">
                        <div className="form-group">
                            <label className="form-label" htmlFor="country">
                                What is your expectation.*
                            </label>
                            <textarea
                                style={{ height: '200px' }}
                                onChange={(e) => {
                                    e.preventDefault();
                                    let msg = null;
                                    if (!e.target.value && errors?.goals?.isReq) {
                                        msg = `Please type your goals.`;
                                    }
                                    if (errors?.goals?.onValidateFunc) {
                                        errors.goals.onValidateFunc(msg, "goals");
                                    }
                                    setRegistrationProcessData((prev) => ({
                                        ...prev,
                                        goals: e.target.value,
                                    }));
                                }}
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="15"
                                defaultValue={registrationProcessData.goals}
                            ></textarea>
                            {errors?.goals?.errorMsg && (
                                <span className="text-danger">
                                    {errors?.goals?.errorMsg === true
                                        ? `Please type your goals.`
                                        : errors?.goals?.errorMsg}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RequirementsForm;
