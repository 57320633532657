import React from "react";
import {useEffect} from "react";

export const Mentorfilter = () => {
    useEffect(() => {
    import("./filter.css")
    }, [])
  return (
    <>
      <div className="Module_filter intro-search">
        <div className="intro-search__container">
          <div className="Search_bar input_container d-flex align-items-center white-bg form-group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
            <input
              placeholder="Search by company, language or role"
              type="search"
              className="Search_input px-0 h-100 text-truncate form-control"
            />
          </div>
        </div>
        <div className="intro-search__expertise pr-md-3">
          <div className="option_container">
            <span
              aria-live="polite"
              aria-atomic="false"
              aria-relevant="additions text"
              className="filter_span"
            />
            <div className="option_control">
              <div className="text_box">
                <div className="module_placeholder">Expertise</div>
                <div className="module_filter">
                  <div className="" style={{ display: "inline-block" }}>
                    <input
                      autoCapitalize="none"
                      autoComplete="off"
                      autoCorrect="off"
                      id="react-select-2-input"
                      spellCheck="false"
                      tabIndex={0}
                      type="text"
                      aria-autocomplete="list"
                      defaultValue=""
                      style={{
                        boxSizing: "content-box",
                        width: 2,
                        background: "0px center",
                        border: 0,
                        fontSize: "inherit",
                        opacity: 1,
                        outline: 0,
                        padding: 0,
                        color: "inherit",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        visibility: "hidden",
                        height: 0,
                        overflow: "scroll",
                        whiteSpace: "pre",
                        fontSize: 16,
                        fontFamily: "Circular",
                        fontWeight: 400,
                        fontStyle: "normal",
                        letterSpacing: "normal",
                        textTransform: "none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="intro-search__country pr-md-3">
          <div className="option_container">
            <span
              aria-live="polite"
              aria-atomic="false"
              aria-relevant="additions text"
              className="filter_span"
            />
            <div className="option_control">
              <div className="text_box">
                <div className="module_placeholder">Country</div>
                <div className="module_filter">
                  <div className="" style={{ display: "inline-block" }}>
                    <input
                      autoCapitalize="none"
                      autoComplete="off"
                      autoCorrect="off"
                      id="react-select-3-input"
                      spellCheck="false"
                      tabIndex={0}
                      type="text"
                      aria-autocomplete="list"
                      defaultValue=""
                      style={{
                        boxSizing: "content-box",
                        width: 2,
                        background: "0px center",
                        border: 0,
                        fontSize: "inherit",
                        opacity: 1,
                        outline: 0,
                        padding: 0,
                        color: "inherit",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        visibility: "hidden",
                        height: 0,
                        overflow: "scroll",
                        whiteSpace: "pre",
                        fontSize: 16,
                        fontFamily: "Circular",
                        fontWeight: 400,
                        fontStyle: "normal",
                        letterSpacing: "normal",
                        textTransform: "none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="Go_button text-truncate px-32 border-0 white-text btn btn-default"
          style={{ background: "#c727bb" }}
        >
          Search
        </button>
      </div>
    </>
  );
};
