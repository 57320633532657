import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { REACT_USER_ID } from "../../../auth/tokenProvider";
import "../../../dashboard/css/dashboard.css";
import { Link, NavLink } from "react-router-dom";
import UpdateExpertise from "../../profileUpdate/UpdateExpertise";
import UpdateSkill from "../../profileUpdate/UpdateSkills";
import UpdateCompany from "../../profileUpdate/UpdateCompany";
import UpdateRole from "../../profileUpdate/UpdateRole";
import UpdateLinkedin from "../../profileUpdate/UpdateLinkedin";
import { getUserDetails, updateProfile } from "../../../../redux/actions/login";

const Professional = () => {
  useEffect(() => {
      ;
    import("../../css/custom.css");
    import("../CustomProfile.css");
  }, []);
  const dispatch = useDispatch();
  const userDetailsData = useSelector(
    (state) => state?.userDetailsReducer?.data[0]
  );
  const NO_VALUE = "N/A";
  const getStringValueFromList = (lst) => {
    if (lst) return lst.join(", ");
    else return "N/A";
  };
  const user_id = REACT_USER_ID;
  const getUpdatedValues = (data) => {
    let tempData = { ...data, user_id: user_id };
    // setLoader(true);
    dispatch(updateProfile(tempData)).then(setTimeout(()=>{
      window.location.reload();
    },500));
    dispatch(getUserDetails(user_id));
    
  };
  const editStateObject = {
    expertise: false,
    skill: false,
    company: false,
    role: false,
    linkedin_profile: false,
    // twitter_profile: false,
    // website_link: false,
  };
  const fieldComponentMapping = {
    expertise: {
      lable: "Expertise",
      value: getStringValueFromList(userDetailsData?.experties) || NO_VALUE,
      component: (
        <UpdateExpertise
          expertise={userDetailsData?.experties}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    skill: {
      lable: "Skills",
      value: getStringValueFromList(userDetailsData?.skills) || NO_VALUE,
      component: (
        <UpdateSkill
          skill={userDetailsData?.skills}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    company: {
      lable: "Company",
      value: userDetailsData?.company_or_school || NO_VALUE,
      component: (
        <UpdateCompany
          companyData={userDetailsData?.company_or_school}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    role: {
      lable: "Role",
      value: userDetailsData?.title || NO_VALUE,
      component: (
        <UpdateRole
          roleData={userDetailsData?.title}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    linkedin_profile: {
      lable: "Linkedin profile",
      value: userDetailsData?.linkedin_url || NO_VALUE,
      component: (
        <UpdateLinkedin
          linkedinData={userDetailsData?.linkedin_url}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    // twitter_profile: {
    //   lable: "Twitter profile",
    //   value: "Twitter profile value",
    //   component: <div>twitter_profile Component</div>,
    // },
    // website_link: {
    //   lable: "Website Link",
    //   value: "Website Link value",
    //   component: <div>Website Link Component</div>,
    // },
  };

  const fieldList = Object.keys(editStateObject);

  const [editState, setEditState] = useState(editStateObject);
  const changeEditStateMethod = (stateName) => {
    let newValue = !editState[stateName];
    setEditState({ ...editState, [stateName]: newValue });
  };
  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });
  return (
    <div className="update-field-container">
      {fieldList?.map((field, index) => (
        <div key={index} className="update-field-row">
          <div className="details-edit-btn-header">
            <span className="spanPadd">{fieldComponentMapping[field]?.lable}</span>
            <Link to="" onClick={() => changeEditStateMethod(field)}>
              {editState[field] === false ? "Edit" : "Cancel"}
            </Link>
          </div>
          {editState[field] ? (
            fieldComponentMapping[field]?.component
          ) : (
            <div>{fieldComponentMapping[field]?.value}</div>
          )}
          <hr />
        </div>
      ))}
    </div>
  );
};

export default Professional;
