import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import SelectMultiInputs from '../../applyAsMentor/selectMultiInputs';
import { UploadImage } from '../../UploadImage';
import { useDispatch, useSelector } from 'react-redux';
import { appendFormData } from '../../../redux/actions/course';

const AddYoutubeLive = ({ unitIndex, chapterIndex }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        title: '',
        notify: false,
        Tags: [],
        availabilityType: 'AlwaysAvailable',
        availableFrom: '',
        availableTill: '',
        preMessage: '',
        postMessage: '',
        thumbnailImage: '',
        link: '',
    });
    const [validationMessages, setValidationMessages] = useState({
        title: '',
        Tags: '',
        availableFrom: '',
        availableTill: '',
        preMessage: '',
        postMessage: '',
        thumbnailImage: '',
        link: '',
    });
    const [successMessage, setSuccessMessage] = useState('');

    const syllabusIndexData = useSelector(
        (state) => state.SyllabusIndexDataRedcuer?.data[0]
    );

    useEffect(() => {
        if (syllabusIndexData) {
            const selectedUnit = syllabusIndexData.course_syllabus?.[unitIndex];
            const selectedChapter = selectedUnit?.chapters?.[chapterIndex];
            const data = selectedChapter?.data[0];

            if (selectedChapter) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    title: data?.title || '',
                    notify: data?.notify || false,
                    link: data?.link || '',
                    availabilityType: data?.availabilityType || '',
                    availableFrom: data?.availableFrom || '',
                    availableTill: data?.availableTill || '',
                    preMessage: data?.preMessage || '',
                    postMessage: data?.postMessage || '',
                    thumbnailImage: data?.thumbnailImage || '',
                }));
            }
        }
    }, [syllabusIndexData, chapterIndex, unitIndex]);

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleTagsChange = (item, addFunc) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            Tags: [...prevFormData.Tags, item],
        }));
    };

    const handleRemoveTag = (item) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            Tags: prevFormData.Tags.filter((tag) => tag.value !== item.value),
        }));
    };

    const uploadBanner = (val, pre) => {
        setFormData((prevData) => ({
            ...prevData,
            thumbnailImage: val,
        }));
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const newValidationMessages = {};

        // Validate presence of required fields
        if (!formData.title) {
            newValidationMessages.title = 'Title is required';
        }
        if (formData.Tags.length < 5) {
            newValidationMessages.Tags = 'At least 5 Tags are required';
        }
        if (!formData.availableFrom) {
            newValidationMessages.availableFrom = 'Available From is required';
        }
        if (!formData.availableTill) {
            newValidationMessages.availableTill = 'Available Till is required';
        }
        if (!formData.preMessage) {
            newValidationMessages.preMessage = 'Pre Class Message is required';
        }
        if (!formData.postMessage) {
            newValidationMessages.postMessage = 'Post Class Message is required';
        }
        if (!formData.thumbnailImage) {
            newValidationMessages.thumbnailImage = 'Thumbnail Image is required';
        }
        if (!formData.link) {
            newValidationMessages.link = 'Youtube Live Link is required';
        }

        // Set validation messages
        setValidationMessages(newValidationMessages);

        // Check if any validation message exists
        const isValid = Object.values(newValidationMessages).every(
            (message) => !message
        );

        if (isValid) {
            // If all fields are valid, proceed with form submission
            console.log('Form Data:', formData);
            dispatch(
                appendFormData(
                    'ADD_CHAPTER_DATA',
                    chapterIndex,
                    unitIndex,
                    formData
                )
            );
            setSuccessMessage('Chapter data has been successfully saved.');
        }
    };

    return (
        <>
            <div className='bg-white p-5'>
                <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label className="course__label">
                            Title *
                        </label>
                        <input
                            type="text"
                            name="title"
                            placeholder="Enter Title"
                            className="form-control"
                            value={formData?.title}
                            onChange={handleInputChange}
                        />
                        {validationMessages.title && (
                            <p className="text-danger">{validationMessages.title}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">
                            Tags  *
                        </label>
                        <div className="multi-inputs-container">
                            <div className="multi-inputs">
                                {formData.Tags.map((Tag) => (
                                    <div className="multi-input-design" key={Tag.value}>
                                        <div>{Tag.label}</div>
                                        <div onClick={() => handleRemoveTag(Tag)}>
                                            <FontAwesomeIcon
                                                className="multi-select-icon"
                                                icon={faXmark}
                                                style={{ color: 'grey' }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <SelectMultiInputs
                            isMulti={true}
                            title="language"
                            onChangeFunc={(e) => handleTagsChange(e, setFormData)}
                            value=""
                            name="Tags"
                            isCreatable={true}
                            placeholder="Add at least 5-6 Tags"
                            className="basic-multi-select text-left"
                            classNamePrefix="select"
                            inputData={formData.Tags}
                            closeMenuOnSelect={true}
                            style={{ borderRadius: '12px', height: '3rem' }}
                        />
                        {validationMessages.Tags && (
                            <p className="text-danger">{validationMessages.Tags}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="" className='course__label'>Available From: *</label>
                        <input
                            type="datetime-local"
                            name="availableFrom"
                            value={formData?.availableFrom}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                        {validationMessages.availableFrom && (
                            <p className="text-danger">{validationMessages.availableFrom}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="" className='course__label'>Available Till: *</label>
                        <input
                            type="datetime-local"
                            name="availableTill"
                            value={formData?.availableTill}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                        {validationMessages.availableTill && (
                            <p className="text-danger">{validationMessages.availableTill}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">
                            Youtube Live Link *
                        </label>
                        <input
                            type="url"
                            name="link"
                            placeholder="Enter Youtube Live Link"
                            className="form-control"
                            value={formData?.link}
                            onChange={handleInputChange}
                        />
                        {validationMessages.link && (
                            <p className="text-danger">{validationMessages.link}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">
                            Pre Class Message (shown to learners before live session) *
                        </label>
                        <input
                            type="text"
                            name="preMessage"
                            placeholder="Enter preMessage"
                            className="form-control"
                            value={formData.preMessage}
                            onChange={handleInputChange}
                        />
                        {validationMessages.preMessage && (
                            <p className="text-danger">{validationMessages.preMessage}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">
                            Post Class Message (shown to learners after live session) *
                        </label>
                        <input
                            type="text"
                            name="postMessage"
                            placeholder="Enter postMessage"
                            className="form-control"
                            value={formData?.postMessage}
                            onChange={handleInputChange}
                        />
                        {validationMessages.postMessage && (
                            <p className="text-danger">{validationMessages.postMessage}</p>
                        )}
                    </div>

                    <div className="form-group">
                        <div className="form-check form-switch d-flex">
                            <input
                                className="form-check-input"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                type="checkbox"
                                checked={formData?.notify}
                                onChange={handleInputChange}
                                name="notify"
                            />
                            <label className="course__label" htmlFor="flexSwitchCheckDefault">
                                Notify Users on Class Start
                            </label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="course__label">
                            Thumbnail Recommended Size ( 600px * 400px )
                        </label>
                        <UploadImage
                            callback={uploadBanner}
                            fileName="_course-thumbnail"
                            imagePath={"courseImage/courseThumbnail/"}
                            filetype={["image"]}
                        />
                        {validationMessages.thumbnailImage && (
                            <p className="text-danger">{validationMessages.thumbnailImage}</p>
                        )}
                    </div>

                    {/* Success message */}
                    {successMessage && (
                        <div className="alert alert-success">{successMessage}</div>
                    )}

                    <div className='pt-4'>
                        <label className="course__label">
                            Preview Youtube Video
                        </label>

                        {formData?.link ? (
                            <video
                                style={{ width: "100%", height: "100%" }}
                                src={formData?.link}
                                controls
                            />
                        ) : (
                            <p className="text-center">No video is Available to preview</p>
                        )}
                    </div>

                    <div className="get-started-cls why_choose_ment">
                        <a>
                            <button type="submit" className="find-btn btn btn-primary">
                                Save
                            </button>
                        </a>
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddYoutubeLive;
