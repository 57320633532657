import React, { useState } from 'react';
import { Col, Row, Space, Typography } from "antd";
import BalanceTabs from './BalanceTab';
import PaymentsOnboarding from './Modal/PaymentsOnboarding';
import WithdrawSuccess from './Modal/WithdrawSuccess';
import BalanceTable from './BalanceTabel';
import { currencyName } from '../Data/Data';
import TransactionsTable from './TransactionTabel';
export const accountingDetails = {
    "balance": 0,
    "total_earnings": 0,
    "total_earnings_last_3_months": [],
    "pending_earnings": 0,
    "service_wise_data": [
        {
            "id": 731116,
            "title": "Digital Product",
            "total_bookings": 2,
            "completed_bookings": 2,
            "earnings": 0
        },
        {
            "id": 727072,
            "title": "sdfgyjk",
            "total_bookings": 2,
            "completed_bookings": 2,
            "earnings": 0
        },
        {
            "id": 725312,
            "title": "call",
            "total_bookings": 2,
            "completed_bookings": 2,
            "earnings": 0
        },
        {
            "id": 725256,
            "title": "Priority DM",
            "total_bookings": 1,
            "completed_bookings": 1,
            "earnings": 0,
            "is_query": true
        },
        {
            "id": 731153,
            "title": "fds",
            "total_bookings": 1,
            "completed_bookings": 0,
            "earnings": 0
        }
    ],
    "incentive_wise_data": []
}
const { Paragraph } = Typography;
const PaymentsMentor = () => {
    const [activeTab, setActiveTab] = useState('summary'); // Default active tab
    const [successModal, setSuccessModal] = useState(false);


    const [onboardingModalOpen, setOnboardingModalOpen] =
        useState(false);
    const [onboardingStep, setOnboardingStep] = useState(0);

    const connectPayment = (page = 0) => {
        if (page) {
            setOnboardingStep(page);
        }
        setOnboardingModalOpen(true);
    };
    const successClose = () => {
        setSuccessModal(false);
    };




    return (
        <>
            <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24  ">
                <div className="ant-col ant-col-24 container-title-section payments ">
                    <div className="ant-row ant-row-space-between ant-row-middle ">
                        <div className="ant-col  ant-col-md-12 ant-col-md-offset-1 ant-col-xl-12 ant-col-xl-offset-1 ant-col-xxl-12 ant-col-xxl-offset-1 ">
                            <div className="ant-typography payment-title ">Payments</div>
                        </div>
                        <div className="ant-col bpo-payment-gateway undefined not_added ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-7 ant-col-xl-7 ">
                            <div className="ant-row ant-row-middle balance-payment-options ">
                                <div className="ant-col  ant-col-md-0 ">
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 16 16"
                                        height={28}
                                        width={28}
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                                    </svg>
                                </div>
                                <div className="ant-col  ant-col-md-0 ">
                                    <div className="ant-typography bpo-title ">
                                        Add a payout method
                                    </div>
                                    <div className="ant-typography bpo-subtitle ">
                                        Stripe, Paypal or Bank transfer
                                    </div>
                                </div>
                                <div className="ant-col ant-col-xs-23 ant-col-md-23 ">
                                    <button
                                        onClick={connectPayment}
                                        type="button"
                                        className="ant-btn  ant-btn-default ant-btn-block btn-dark"
                                    >
                                        <span>Connect payout</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ant-row container-children '>
                    <div className='ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 '>
                        <Col className="payments-page">
                            <BalanceTabs />
                            <Row gutter={12} className="tabs-container">
                                <Col
                                >
                                    <Paragraph
                                        onClick={() => setActiveTab('summary')}
                                        className={`tab ${activeTab === "summary" ? "active-tab" : "detail-tab"}`}
                                    >
                                        Summary
                                    </Paragraph>
                                </Col>
                                <Col

                                >
                                    <Paragraph
                                        onClick={() => setActiveTab('transaction')}
                                        className={`tab ${activeTab === "summary" ? "detail-tab" : "active-tab"}`}
                                    >
                                        Transactions
                                    </Paragraph>
                                </Col>
                            </Row>

                            {activeTab === "summary" ? (
                                <BalanceTable
                                    tableData={accountingDetails?.service_wise_data}
                                    currency={currencyName}
                                />
                            ) : (
                                <TransactionsTable
                                    tableData={accountingDetails?.service_wise_data}
                                    currency={currencyName}
                                />

                            )}
                        </Col>
                    </div>

                </div>

            </div>
            {onboardingModalOpen && (
                <PaymentsOnboarding
                    open={onboardingModalOpen}
                    page={onboardingStep}
                    onClose={() => setOnboardingModalOpen(false)}
                />
            )}
            {successModal && (
                <WithdrawSuccess show={successModal} onClose={successClose} />
            )}
        </>
    );
}

export default PaymentsMentor;
