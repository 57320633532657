import {
  commonConstants,
  feedbackConstants,
  updateCalendarSettings,
  personalityTest,
  testResponseConstants,
  fullStackConstants,
  liveSessionConstants,
  sessionDetailsConstants,
  enrollSessionConstants,
  createSessionConstants,
  menteeGoalActionConstants,
  sendChatMessageConstants,
  getChatMessageConstants,
  getMenteeInboxListConstants,
  getConnectionRequestConstants,
  updateConnectionRequestConstants,
  getAcceptConnectionRequestConstants,
  getConnectionListConstants,
  getGoalConstants,
  getNotificationConstants,
  updateNotificationStatusConstants,
  contactConstants,
  SubscribeConstants,
  addBankAccountConstants,
  getBankDetailsConstants,
} from "../../constants";

export const uploadImageReducer = (state = {}, action) => {
  switch (action.type) {
    case commonConstants.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case commonConstants.UPLOAD_IMAGE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case commonConstants.UPLOAD_IMAGE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const uploadImageFileReducer = (state = {}, action) => {
  switch (action.type) {
    case commonConstants.UPLOAD_IMAGE_FILE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case commonConstants.UPLOAD_IMAGE_FILE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        uploaded: true,
        error: action.error,
      };
    case commonConstants.UPLOAD_IMAGE_FILE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const feedbackReducer = (state = {}, action) => {
  switch (action.type) {
    case feedbackConstants.FEEDBACK_SUBMIT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case feedbackConstants.FEEDBACK_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case feedbackConstants.FEEDBACK_SUBMIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const updateCalendarSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case updateCalendarSettings.UPDATE_CALENDAR_SETTINGS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case updateCalendarSettings.UPDATE_CALENDAR_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case updateCalendarSettings.UPDATE_CALENDAR_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const personalityTestReducer = (state = {}, action) => {
  switch (action.type) {
    case personalityTest.TEST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case personalityTest.TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case personalityTest.TEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const testResponseReducer = (state = {}, action) => {
  switch (action.type) {
    case testResponseConstants.TEST_SUBMIT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case testResponseConstants.TEST_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case testResponseConstants.TEST_SUBMIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const fullStackReducer = (state = {}, action) => {
  switch (action.type) {
    case fullStackConstants.FULL_SUBMIT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case fullStackConstants.FULL_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case fullStackConstants.FULL_SUBMIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const liveSessionReducer = (state = {}, action) => {
  switch (action.type) {
    case liveSessionConstants.LIVESESSION_SUBMIT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case liveSessionConstants.LIVESESSION_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case liveSessionConstants.LIVESESSION_SUBMIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const sessionDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case sessionDetailsConstants.SESSION_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case sessionDetailsConstants.SESSION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case sessionDetailsConstants.SESSION_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const enrollSessionReducer = (state = {}, action) => {
  switch (action.type) {
    case enrollSessionConstants.ENROLL_SESSION_REQUEST:
      return {
        ...state,
        request: action.data,
        isSuccess: false,
        loading: true,
      };
    case enrollSessionConstants.ENROLL_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSuccess: true,
        status: action.status,
        error: action.error,
      };
    case enrollSessionConstants.ENROLL_SESSION_FAILURE:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const createSessionReducer = (state = {}, action) => {
  switch (action.type) {
    case createSessionConstants.CREATE_SESSION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case createSessionConstants.CREATE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case createSessionConstants.CREATE_SESSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const menteeGoalReducer = (state = {}, action) => {
  switch (action.type) {
    case menteeGoalActionConstants.MENTEE_GOAL_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case menteeGoalActionConstants.MENTEE_GOAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case menteeGoalActionConstants.MENTEE_GOAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
// Chat System
export const sendChatMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case sendChatMessageConstants.SEND_CHAT_MESSAGE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case sendChatMessageConstants.SEND_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
        isSuccess: true,
      };
    case sendChatMessageConstants.SEND_CHAT_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    default:
      return state;
  }
};
export const getChatMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case getChatMessageConstants.GET_CHAT_MESSAGE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getChatMessageConstants.GET_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case getChatMessageConstants.GET_CHAT_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getConnectionRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case getConnectionRequestConstants.GET_CONNECTION_REQUEST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getConnectionRequestConstants.GET_CONNECTION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case getConnectionRequestConstants.GET_CONNECTION_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const updateConnectionRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case updateConnectionRequestConstants.UPDATE_CONNECTION_REQUEST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case updateConnectionRequestConstants.UPDATE_CONNECTION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case updateConnectionRequestConstants.UPDATE_CONNECTION_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getConnectionListReducer = (state = {}, action) => {
  switch (action.type) {
    case getConnectionListConstants.GET_CONNECTION_LIST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getConnectionListConstants.GET_CONNECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case getConnectionListConstants.GET_CONNECTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getGoalReducer = (state = {}, action) => {
  switch (action.type) {
    case getGoalConstants.GET_GOAL_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getGoalConstants.GET_GOAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case getGoalConstants.GET_GOAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case getNotificationConstants.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getNotificationConstants.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case getNotificationConstants.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const contactReducer = (state = {}, action) => {
  switch (action.type) {
    case contactConstants.CONTACT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case contactConstants.CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case contactConstants.CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const updateNotificationStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case updateNotificationStatusConstants.UPDATE_NOTIFICATION_STATUS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case updateNotificationStatusConstants.UPDATE_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case updateNotificationStatusConstants.UPDATE_NOTIFICATION_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const subscribeReducer = (state = {}, action) => {
  switch (action.type) {
    case SubscribeConstants.SUBSCRIBE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case SubscribeConstants.SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case SubscribeConstants.SUBSCRIBE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const mpqTestDataReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
         ...state,
        data: action?.data,
      };
    default:
      return state;
  }
};
export const addBankAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case addBankAccountConstants.ADD_ACCOUNT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case addBankAccountConstants.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case addBankAccountConstants.ADD_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getBankDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case getBankDetailsConstants.GET_ACCOUNT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getBankDetailsConstants.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case getBankDetailsConstants.GET_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};