import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Modal, Typography } from "antd";
import { useEffect, useState } from "react";

const { Text, Paragraph } = Typography;

const AddQuestion = (props) => {
    const {
        showModal,
        setShowModal,
        questionHeader,
        buttonText,
        id,
        activeQuestion,
        questionType,
        setaddQuestion,
        addQuestion
    } = props;
    const [questionRequired, setQuestionRequired] = useState(
        activeQuestion?.question ? activeQuestion?.compulsory : false
    );
    console.log("testing", addQuestion)
    const [menuSelected, setMenuSelected] = useState("");
    const [showText, setShowText] = useState("");
    const [question, setQuestion] = useState(activeQuestion?.question || "");
    const [loading, setLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);

    // ...

    const postQuestion = async () => {
        if (question?.length === 0) {
            setShowText("*Required");
        } else {
            setLoading(true);
            try {
                setaddQuestion((prevQuestions) => {
                    if (!Array.isArray(prevQuestions)) {
                        console.error("Previous questions is not an array:", prevQuestions);
                        return [];
                    }

                    const newQuestion = {
                        id: Math.random().toString(36).substr(2, 9), // Generate a random id
                        question: question,
                        service: id,
                        type: menuSelected,
                        compulsory: questionRequired,
                    };

                    return [...prevQuestions, newQuestion];
                });

                handleCancel();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setShowText("*Something went wrong");
            }
        }
    };

    // ...

    const editQuestion = async () => {
        if (question?.length === 0) {
            setShowText("*Required");
        } else {
            setLoading(true);
            try {
                setaddQuestion((prevQuestions) => {
                    if (!Array.isArray(prevQuestions)) {
                        console.error("Previous questions is not an array:", prevQuestions);
                        return [];
                    }

                    const updatedQuestions = prevQuestions.map((q) =>
                        q.id === activeQuestion.id
                            ? {
                                ...q,
                                question: question,
                                service: id,
                                type: menuSelected,
                                compulsory: questionRequired,
                            }
                            : q
                    );

                    return updatedQuestions;
                });

                handleCancel();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setShowText("*Something went wrong");
            }
        }
    };

    // ...

    const deleteQuestion = async () => {
        setEditLoading(true);
        try {
            setaddQuestion((prevQuestions) => {
                if (!Array.isArray(prevQuestions)) {
                    console.error("Previous questions is not an array:", prevQuestions);
                    return [];
                }

                const updatedQuestions = prevQuestions.filter((q) => q.id !== activeQuestion.id);

                handleCancel();
                setEditLoading(false);

                return updatedQuestions;
            });
        } catch (error) {
            setEditLoading(false);
            setShowText("*Something went wrong");
        }
    };

    // ...

 
    const handleCancel = () => {
        setShowText("");
        setQuestion("");
        setShowModal(false);
        setQuestionRequired(false);
    };



    const handleChange = (e) => {
        setQuestion(e.target.value);
    };

    const OnTypeSelected = (value) => {
        setMenuSelected(value);
    };

    useEffect(() => {
        if (questionRequired == true && question?.length !== 0) {
            setShowText("");
        }
        setQuestion(activeQuestion?.question || "");
        setMenuSelected(activeQuestion?.type == "upload" ? "upload" : "text");

        setQuestionRequired(
            activeQuestion?.question ? activeQuestion?.compulsory : false
        );
    }, [activeQuestion]);

    const submitQuestion = () => {
        questionType === 1 ? postQuestion() : editQuestion();
    };
    return (
        <>
            <Modal
                footer={null}
                title={questionHeader}
                open={showModal}
                centered={true}
                onCancel={handleCancel}
                width={368}
                className="cancel-header-text border-rad add-questions"
            >
                <>
                    <Text>Question</Text>
                    <Input
                        style={{ marginTop: "8px" }}
                        type="text"
                        name="questions"
                        autoComplete="off"
                        value={question}
                        onChange={(e) => handleChange(e)}
                    />
                    <Checkbox
                        checked={questionRequired}
                        onClick={() => {
                            setQuestionRequired(!questionRequired);
                        }}
                        style={{
                            marginTop: "13px",
                            height: "16px",
                            width: "16px",
                            // borderRadius: "3px",
                        }}
                    >
                        Required
                    </Checkbox>
                </>
                <Paragraph style={{ marginTop: "32px" }}>
                    <Text>Answer type</Text>

                    <Paragraph className="analysis-flex" style={{ marginTop: "8px" }}>
                        <Button
                            className={
                                menuSelected == "text" ? "typeSelected" : "question-button-type"
                            }
                            onClick={() => {
                                OnTypeSelected("text");
                            }}
                        >
                            <Text className="question-button-text ">Text</Text>
                        </Button>
                        <Button
                            className={
                                menuSelected == "upload"
                                    ? "typeSelected"
                                    : "question-button-type"
                            }
                            onClick={() => {
                                OnTypeSelected("upload");
                            }}
                            style={{ padding: "0px" }}
                        >
                            <Text className="question-button-text ">Upload (jpg, pdf)</Text>
                        </Button>
                    </Paragraph>
                </Paragraph>
                <Paragraph className="action-buttons">
                    {questionType === 2 && (
                        <Button
                            danger
                            type="text"
                            icon={<DeleteOutlined />}
                            loading={editLoading}
                            onClick={() => deleteQuestion()}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        block
                        className="add-question-style question-button-text question-button-select btn-dark"
                        loading={loading}
                        onClick={() => {
                            submitQuestion();
                        }}
                    >
                        <Text className="question-button-text question-button-submit">
                            {buttonText}
                        </Text>
                    </Button>
                    <Text className="add-question-error">{showText}</Text>
                </Paragraph>
            </Modal>
        </>
    );
};
export default AddQuestion;
