import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fullStack, fullStackAction } from "../../../redux/actions/common";
import Footer from "../../footer";
import Header from "../../header";
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";
import styles from "./Fullstack.module.css";

const FullStack = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
  );
 

  const [email, setEmail] = useState("");
  const [graduation_year, setGraduation_Year] = useState("");
  const [job_title, setJob_title] = useState("");
  const [mobile, setmobile] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [stackForm, setStackForm] = useState(false);
  const [stackRequest, setStackRequest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);

  const handleSubmit = async (event) => {
    const data = { email, graduation_year, job_title, mobile };
    event.preventDefault();
    setStackForm(true);
    dispatch(fullStackAction(data));
  };
  const RequestSubmit = async (event) => {
    const data = { name, email, mobile, message };
    event.preventDefault();
    setStackRequest(true);
    dispatch(fullStackAction(data));
  };
  const fullStackState = useSelector((state) => state.fullStackReducer);
  const status = fullStackState?.status;
  useEffect(() => {
    if (status === 200 && stackForm) {
      setStackForm(false);
      setSuccess(true);
    }
    if (status === 200 && stackRequest) {
      setStackRequest(false);
      setSuccessRequest(true);
    }
  }, [status]);

  return (
    <>
      {loggedIn ? (
        <div style={{ marginTop: "0" }}>
          <ProtectedHeader extraClasses="inner-header" />
        </div>
      ) : (
        <Header extraClasses="inner-header" />
      )}
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className={`${styles.firstsection} row`}>
          <div className={`${styles.amtcolumn} col-lg-6`}>
            <h1 className={styles.mainheading}>
              {" "}
              Full Stack Web Development Program
            </h1>
            <p className={styles.certification}>Certification Program</p>
            <h5 className={styles.rank}>
              Ranked #1 Best Bootcamp By Mentorpal.
            </h5>
            <p className={styles.firstpara}>
            Mentorpal's Full Stack Web Development program is an intensive course that covers both front-end and back-end development. Students learn to build dynamic, responsive, and scalable web applications using the latest tools and technologies. Upon completion, graduates are prepared for in-demand careers in the tech industry.
            </p>
            <center>
              {" "}
              <button className={`${styles.applynow} btn`}>
                <h5>Apply Now</h5>
              </button>
            </center>
          </div>
          {/* formcolumn starts here */}
          <div className={`${styles.formcolumn} col-lg-6`}>
            <div className={styles.forform}>
              <form
                action=""
                className={`${styles.fullFormOne} form-control-lg form-control-sm`}
                onSubmit={handleSubmit}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  height: "750px",
                  // width: "100%",
                }}
              >
                <h5 className={styles.formheading}>
                  Request a callback to start your journey with Mentorpal!
                </h5>
                {/* Email Input */}
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                  />
                </div>
                {/* Graduation Year Selector      */}
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Graduation Year
                </label>
                <select
                  className="form-select"
                  aria-label=""
                  value={graduation_year}
                  onChange={(event) => setGraduation_Year(event.target.value)}
                  required
                >
                  Graduation Year
                  <option selected="">Select Your Graduation Year</option>
                  <option value={1}>2011</option>
                  <option value={2}>2012</option>
                  <option value={3}>2013</option>
                  <option value={4}>2014</option>
                  <option value={5}>2015</option>
                  <option value={6}>2016</option>
                  <option value={7}>2017</option>
                  <option value={8}>2018</option>
                  <option value={9}>2019</option>
                  <option value={10}>2020</option>
                  <option value={11}>2021</option>
                  <option value={12}>2022</option>
                  <option value={13}>2023</option>
                  <option value={14}>2024</option>
                  <option value={15}>2025</option>
                </select>
                {/* Job Title input      */}
                <div className="mb-3 mt-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Enter Your Present Job Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Job Title"
                    value={job_title}
                    onChange={(event) => setJob_title(event.target.value)}
                    required
                  />
                </div>
                <div className="mb-1 mt-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Enter Your Mobile Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Mobile Number"
                    value={mobile}
                    onChange={(event) => setmobile(event.target.value)}
                    required
                  />
                  <span
                    id="Mobile Number:HelpBlock"
                    className={`${
                      styles.otp + "" + styles.text + "-" + styles.muted
                    } mt-0 mb-0  form-text `}
                  >
                    You'll receive an otp on this number for verification.
                  </span>
                </div>
                <div className="mt-1 me-auto">
                  <center>
                    <button
                      name="submit"
                      type="submit"
                      className={`${styles.continuebtn} btn`}
                    >
                      Continue
                    </button>
                    {success ? <p>succes !!</p> : null}
                  </center>
                </div>
                <center>
                  <span
                    id="Mobile Number:HelpBlock"
                    className={`${styles.text + "-" + styles.muted} form-text`}
                  >
                    By creating an account I have read and agree to terms and
                    conditions of Mentorpal.
                  </span>
                </center>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Section 2 */}
      <div className={`${styles.second} container`}>
        <div className="row">
        <center>
                <h3>Full Stack Web Developer Program Overview</h3>
                <br />
                <p className={styles.fortext}>
                Our Full Stack Web Developer Course in India is a comprehensive program that covers everything from front-end to back-end web development. With a focus on practical application, students will learn how to build robust and scalable web applications using popular tools and frameworks such as React, Node.js, and Express.js. By the end of the course, you'll be proficient in building and deploying end-to-end applications, managing data using MongoDB, and much more.
                </p>
              </center>
          <div className="col-lg-6">
            <div className="row">
             
            </div>
            <div className={`${styles.features} row`}>
              <center>
                <h4 className="">Key Fetures</h4>
              </center>
              <br />
              <br />
              <div className={`${styles.fortext} col-md-6`}>
                <i className="fa fa-check-circle-o" aria-hidden="true" /> Learn 30+ cutting edge tools like ReactJS, NodeJS, etc.
                <br />
                <br />
                <i className="fa fa-check-circle-o" aria-hidden="true" /> Build real-world applications like Amazon, Facebook etc.
                <br />
                <br />
                <i className="fa fa-check-circle-o" aria-hidden="true" /> Premium Job placements from top Tech and internet companies
              </div>
              <div className={`${styles.fortext} col-md-6`}>
                <i className="fa fa-check-circle-o" aria-hidden="true" />Start building your product from Day 1 with 100% hands-on training
                <br /> <br />
                <i className="fa fa-check-circle-o" aria-hidden="true" /> Career mentoring through live sessions with industry experts
                <br />
                <br />
                <i className="fa fa-check-circle-o" aria-hidden="true" /> Land a job within 6 months of graduation
              </div>
            </div>
          </div>
          {/* smallcard starts here */}
          <div className="col-lg-6">
            <center>
              <div className={`${styles.card} card`}>
                <center>
                  <h4>Program Fees</h4>
                  <br />
                  <h5>₹ 9,999/-</h5>
                  <h6>(Incl. All Taxes)</h6>
                  <button className={`${styles.cardbtn} btn`}>Apply Now</button>
                  <br />
                  <br />
                  <p className={styles.conditiontext}>
                    *You are enrolling for batch starting on 15 Apr. <br />
                    See  Details, <a href="/">Here.</a>{" "}
                  </p>
                </center>
                <div className={styles.downloaderbox}>
                  <center>
                    <br />
                    <h5>PROGRAM SYLLABUS</h5>
                    <br />
                    <button className={`${styles.download} fa-download fa btn`}>
                      DOWNLOAD NOW
                    </button>
                    <br />
                    <br />
                  </center>
                </div>
              </div>
            </center>
          </div>
        </div>
      </div>
      {/* section3 */}
      <div
        className={`${styles.fourthsection} container`}
        style={{ marginBottom: "1.5rem" }}
      >
        <div className="row">
          <div className="col-lg-8">
            <h3 className={styles.stack}>
              {" "}
              Full Stack Development Training Details
            </h3>
            <p className={styles.para}>
              {" "}
              Our MERN full stack developer course is designed to equip you with the skills needed to become a proficient full stack MERN developer. Through our innovative Blended Learning approach, we offer a comprehensive curriculum that prepares you for a successful career in this field upon program completion.
            </p>
            <h4 className={styles.learn}> LEARNING PATH</h4>
            <section className={`${styles.path} path`}>
              <div className="row">
                <div className="col-md-9">
                  <h4 className={styles.heading} style={{ paddingTop: 20 }}>
                    {" "}
                    Introduction for Full Stack Web Developer- MERN Stack{" "}
                  </h4>
                  <p className={styles.para}>
                    {" "}
                    Embark on your journey as a full stack web developer with our MERN Stack Program. Discover all aspects of this comprehensive program and dive into the exciting world of web development.{" "}
                  </p>
                </div>
                <div className={`${styles.col + "-" + styles.md} col-md-3`} />
                <div className="col-md-9 ">
                  <h4 className={styles.heading}>
                    {" "}
                    Phase 1: Front-end Development{" "}
                  </h4>
                  <p className={styles.para}>
                    {" "}
                    In this phase, you will learn the fundamentals of front-end web development using HTML, CSS, and JavaScript. You will also learn how to use popular front-end frameworks like React to build interactive and responsive user interfaces.{" "}
                  </p>
                </div>
                <div
                  className={`${styles.col + "-" + styles.md} col-md-3`}
                  id="center"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={25}
                      height={25}
                      fill="blue"
                      className="bi bi-play-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                    </svg>
                    <p className="preview">Preview</p>
                  </span>
                </div>
                <div className="col-md-9">
                  <h4 className={styles.heading}>
                    {" "}
                    Phase 2: Back-end Development{" "}
                  </h4>
                  <p className={styles.para}>
                  In this phase, you will delve into server-side development using Node.js and Express. You will learn how to create REST APIs and interact with databases using MongoDB.
                  </p>
                </div>
                <div
                  className={`${styles.col + "-" + styles.md} col-md-3`}
                  id="center"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={25}
                      height={25}
                      fill="blue"
                      className="bi bi-play-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                    </svg>
                    <p className="preview">Preview</p>
                  </span>
                </div>
                <div className="col-md-9">
                  <h4 className={styles.heading}>
                    {" "}
                    Phase 3: Connecting Front-end and Back-end
                  </h4>
                  <p className={styles.para}>
                    {" "}
                    In this phase, you will bring together your front-end and back-end knowledge to build full-stack applications. You will also learn how to implement advanced features using the MERN stack, such as user authentication and real-time updates.
                  </p>
                </div>
                <div
                  className={`${styles.col + "-" + styles.md} col-md-3`}
                  id="center"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={25}
                      height={25}
                      fill="blue"
                      className="bi bi-play-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                    </svg>
                    <p className="preview">Preview</p>
                  </span>
                </div>
                <div className="col-md-9">
                  <h4 className={styles.heading}>
                    {" "}
                    Phase 4: Deployment and Testing
                  </h4>
                  <p className={styles.para}>
                    {" "}
                    In this phase, you will learn how to deploy your MERN stack applications to the cloud and test them using automated testing tools. You will also learn how to optimize your applications for performance and scalability.
                  </p>
                </div>
                <div
                  className={`${styles.col + "-" + styles.md} col-md-3`}
                  id="center"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={25}
                      height={25}
                      fill="blue"
                      className="bi bi-play-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                    </svg>
                    <p className="preview">Preview</p>
                  </span>
                </div>
                <div className="col-md-9">
                  <h4 className={styles.heading}>
                    {" "}
                    Full Stack MERN Developer Capstone Project
                  </h4>
                  <p className={styles.para}>
                    {" "}
                    Our MERN Stack Capstone project is designed to give you hands-on experience in building a complete application from the ground up, deploying it in a simulated production environment, and thoroughly testing it to ensure its seamless functioning.




                  </p>
                </div>
                {/* <div class="col-md-3"></div> */}
              </div>
              <center>
                <div style={{ padding: "20px 0px" }}>
                  <button className={styles.button}>
                    {" "}
                    <svg
                      style={{ marginRight: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      width={25}
                      height={25}
                      fill="white"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                    PROGRAM SYLLABUS{" "}
                  </button>
                </div>
              </center>
            </section>
          </div>
          <div className="col-lg-4" id="center-2">
            <form className={styles.fullForm} onSubmit={RequestSubmit}>
              <div className="path-2">
                <div className="request_box">
                  <h4 style={{ paddingTop: 20, textAlign: "center" }}>
                    {" "}
                    REQUEST MORE INFORMATION
                  </h4>
                </div>
                <div className={styles.rquest_question}>
                  <input
                    className={styles.full_input}
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required
                  />
                  <br />
                  <br />
                </div>
                <div className={styles.rquest_question}>
                  <input
                    className={styles.full_input}
                    type="text"
                    id="Email"
                    name="Email"
                    placeholder="Email"
                    required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <br />
                  <br />
                </div>
                <div className={styles.rquest_question}>
                  <input
                    className={styles.full_input}
                    type="text"
                    id="In"
                    name="In"
                    placeholder="+91 Phone Number"
                    required
                    value={mobile}
                    onChange={(event) => setmobile(event.target.value)}
                  />
                  <br />
                  <br />
                </div>
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  inquiry
                  <span className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      defaultChecked=""
                      style={{
                        marginTop: "0.3rem",
                      }}
                      required
                    />
                    <label
                      className={styles.checkQueston}
                      htmlFor="flexRadioDefault2"
                    >
                      My self
                    </label>
                  </span>
                  <span className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      style={{
                        marginTop: "0.3rem",
                      }}
                      required
                    />
                    <label
                      className={styles.checkQueston}
                      htmlFor="flexRadioDefault1"
                    >
                      My company
                    </label>
                  </span>
                </span>
                <br />
                <textarea
                  className={styles.message}
                  id="message"
                  name="message"
                  placeholder="write an optional message"
                  required
                  defaultValue={""}
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                />
                <br />
                <div className="form-check">
                  <input
                    className={`${styles.checkfull} form-check-input`}
                    type="checkbox"
                    defaultValue=""
                    id="flexCheckChecked"
                    defaultChecked=""
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    By providing contact details, you agree to our{" "}
                    <span style={{ color: "blue" }}> privacy policy</span>
                  </label>
                </div>
                <br />
                <center>
                  <button className={`${styles.submit} submit`}> SUBMIT</button>
                  { successRequest ? <p>Successfull!</p> : null}
                </center>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* section4 */}
      <div className={`${styles.thirdsection} container-fluid`}>
        <div className="row">
          <h2 className={`${styles.bootcampheading} mb-3 mt-1`}>
            Why Online Bootcamp
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div
              className={`${styles.video} embed-responsive embed-responsive-16by9`}
              style={{ width: "70% !important" }}
            >
              <iframe
                className="embed-responsive-item "
                src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                allowFullScreen=""
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row pt-4">
              <div className={`${styles.fortext} col-lg-6`}>
                <h5>Develop skills for real career growth</h5>
                <p>
                Industry and academia collaborated to create up-to-date job-ready curriculum.
</p>
              </div>
              <div className={`${styles.fortext} col-lg-6`}>
                <h5>
                  Learn from experts active in their field, not out-of-touch
                  trainers
                </h5>
                <p>
                Expert practitioners present relevant case studies and best practices in flexible sessions that accommodate work schedules.
                </p>
              </div>
            </div>
            <div className="row">
              <div className={`${styles.fortext} col-lg-6`}>
                <h5>Learn by working on real-world problems</h5>
                <p>
                Virtual labs and real-world data sets in capstone projects for immersive, hands-on learning experiences.
                </p>
              </div>
              <div className={`${styles.fortext} col-lg-6`}>
                <h5>Structured guidance ensuring learning never stops</h5>
                <p>
                Mentor and peer support available 24x7 to clarify concepts and doubts in a collaborative learning community.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.cohort} container`}>
        <div className="row ">
          <h3 className={`${styles.cohorthead}`}>Program Cohorts</h3>
          <h5 className={`${styles.cohorthead}`}>Next Cohort</h5>
        </div>
        <div className={`${styles.shadowbox} row`}>
          <div className={`${styles.boxheading} row  ms-auto me-auto`}>
            MS MEAN JUNE 2023 COHORT 1
          </div>
          <div className={`${styles.forhide} row  mb-3`}>
            <div className="col-sm-3 mt-2 mb-1" />
            <div className="col-sm-2">
              <i className="fa fa-calendar-o" aria-hidden="true" /> Date
            </div>
            <div className="col-sm-2">
              <i className="fa fa-clock-o" aria-hidden="true" /> Time
            </div>
            <div className="col-sm-2">
              <i className="fa fa-tasks" aria-hidden="true" /> Batch Type
            </div>
            <div className="col-sm-3" />
            <hr />
          </div>
          <div className={`${styles.programcat} row mb-3`}>
            <div className="col-sm-3">
              <span className={styles.number}>1</span>{" "}
              <i className="fa-sharp fa-solid fa-circle-1" /> Program Induction
            </div>
            <div className={`${styles.datetime} col-sm-2`}>3Apr2023</div>
            <div className={`${styles.datetime} col-sm-2`}>19.30IST</div>
            <div className="col-sm-2" />
            <div className="col-sm-3" />
          </div>
          <div className={`${styles.programcat} row mb-3`}>
            <div className="col-sm-3">
              <span className={styles.number}>2</span> Regular Classes
            </div>
            <div className={`${styles.datetime} col-sm-2`}>
              3Jun2023-28Oct2023
            </div>
            <div className={`${styles.datetime} col-sm-2`}>19-23IST</div>
            <div className={`${styles.datetime} col-sm-2`}>
              Weekend(Sat-Sun)
            </div>
            <div className="col-sm-3">
              <button
                className={`${styles.enrollbtn} btn  mb-4`}
                style={{ borderRadius: 0, width: "8rem" }}
              >
                Enroll Now
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", fontSize: 12 }} className="mt-4">
          <div className=" ms-auto me-auto">
            Got question regarding upcoming batch dates
          </div>
        </div>
        <div style={{ display: "flex" }} className="mt-1">
          <button
            className={`${styles.consultbtn} btn btn-primary mb-4 ms-auto me-auto `}
          >
            TALK TO A LEARNING CONSULTANT
          </button>
        </div>
      </div>
      <div className={styles.fullBlank}></div>

      <Footer />
    </>
  );
};

export default FullStack;
