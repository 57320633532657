import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import maleAvatar from "../../../assets/images/male.png";
import femaleAvatar from "../../../assets/images/female.png";
import "../css/dashboard.css";
import dotImg from "../images/dot.png";
import cameraImg from "../images/camera.png";
import personalInfoImg from "../images/personal-info.png";
import professionImg from "../images/profession.png";
import { getUserDetails, updateProfile } from "../../../redux/actions/login";
import { languages } from "../../../assets/data/data";
import ReactSelect from "react-select";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import {
  favoriteMentor,
  removeFavoriteMentor,
} from "../../../redux/actions/mentee";
import { useNavigate } from "react-router-dom";

import { v4 } from "uuid";
import { uploadImage, uploadImageFile } from "../../../redux/actions/common";

const DashBoard = () => {
  const dispatch = useDispatch();

  const userId =
    useSelector((state) => {
      return (
        state.loginReducer?.headers?.["user-id"] ||
        state.passwordLoginReducer?.headers?.["user-id"] ||
        state.socialLoginReducer?.headers?.["user-id"]
      );
    }) || REACT_USER_ID;
  useEffect(() => {
    import("../css/bootstrap.min.css");
    import("../../../assets/css/font-awesome.min.css");
    import("../css/owl.carousel.min.css");
    import("../css/owl.theme.default.min.css");
    import("../css/custom.css");
    import("../css/header.css");
    import("../css/dashboard.css");
    import("../css/footer.css");
    import("../css/media.css");
    import("./myProfile.css");
    import("../../adminDashboard/profile/CustomProfile.css");
    dispatch(getUserDetails(userId));
    dispatch(favoriteMentor(REACT_USER_ID));
  }, []);
  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });
  const UpdateReducerState = useSelector((state) => state.updateProfileReducer);
  const status = UpdateReducerState?.status;

  const favoriteMentorsList = useSelector((state) => {
    const data = state.favoriteMentorReducer?.data || [];
    return data.slice(0, 2);
  });
  const removeFromFavoriteMentorsFunction = (id) => {
    dispatch(removeFavoriteMentor(favoriteMentorsList, id));
  };
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState(userDetails?.email);
  const [name, setName] = useState(userDetails?.name);
  const [number, setNumber] = useState("1234567890");
  const [about, setAbout] = useState(
    userDetails?.about ? userDetails?.about : ""
  );
  const [profilePhoto, setProfilePhoto] = useState(
    userDetails?.profile_photo ? userDetails?.profile_photo : ""
  );
  const [mobile, setMobile] = useState(
    userDetails?.mobile ? userDetails?.mobile : "1234567890"
  );
  const [linkedinTxt, setLinkedinTxt] = useState(
    userDetails?.linkedinTxt? userDetails?.linkedinTxt : "NA"
  );
  const [country, setCountry] = useState(
    userDetails?.country? userDetails?.country : "NA"
  );
  const [goal, setGoal] = useState(
    userDetails?.goals ? userDetails?.goals : ""
  );
  const [profession, setProfession] = useState(
    userDetails?.profession ? userDetails?.profession : ""
  );
  const [dob, setDob] = useState(userDetails?.dob ? userDetails?.dob : "");
  const [gender, setGender] = useState(
    userDetails?.gender ? userDetails?.gender : ""
  );
  const [personal, setPersonal] = useState("nav-link  ");
  const [professional, setProfessional] = useState("nav-link active ");
  const [personalblock, setPersonalblock] = useState("tab-pane active ");
  const [professionalblock, setProfessionalblock] = useState("tab-pane  ");
  const [headerModal, setHeaderModal] = useState({});
  const [file, setFile] = useState();

  const personalview = () => {
    setPersonal("nav-link ");
    setPersonalblock("tab-pane active");
    setProfessionalblock("tab-pane");
    setProfessional("nav-link active");
  };
  const professionalview = () => {
    setPersonal("nav-link active");
    setProfessional("nav-link ");
    setPersonalblock("tab-pane");
    setProfessionalblock("tab-pane active");
  };
  const updateAccount = () => {
    const data = {
      email: email,
      name: name,
      gender: gender,
      user_type: "mentee",
      language: ["english"],
      mobile: mobile,
      address: "jaipur",
      user_id: REACT_USER_ID,
      about: about,
      goals: goal,
      profession: profession,
      dob: dob,
      profile_photo: profilePhoto,
      linkedin_url : linkedinTxt,
      country: country,
    };
    setLoader(true);
    dispatch(updateProfile(data));
    dispatch(getUserDetails(userId));
    // window.location.reload()
  };
  useEffect(() => {
    if (status === 200) {
      setLoader(false);
    }
  }, [status]);

  const saveImageData = async () => {
    const data = {
      email: email,
      name: name,
      gender: gender,
      language: ["english"],
      user_type: "mentee",
      mobile: mobile,
      address: "jaipur",
      user_id: REACT_USER_ID,
      about: about,
      goals: goal,
      profession: profession,
      dob: dob,
      profile_photo: profilePhoto,
    };
    setLoader(true);
    await dispatch(updateProfile(data));
    // window.location.reload()
  };

  const handleImage = (val) => {
    if (val) {
      setProfilePhoto(val);
    }
  };
  const [fileData, setFileData] = useState(undefined);

  const imageURLReqData = useSelector(
    (state) => state.uploadImageReducer?.data?.url
  );

  useEffect(() => {
    if (imageURLReqData) {
      const reqData = { url: imageURLReqData, fileData };
      dispatch(uploadImageFile(reqData));
    }
  }, [imageURLReqData]);

 

  const dragNdrop = (event) => {
    console.log("dragnDorp cfucntion called");
    setFileData(event.target.files[0]);
    const fileName = "_profile-photo";
    const imagePath = "profilePhoto/";

    const type = event.target.files[0].type.split("/");
    if (!type[0] || type[0] !== "image") {
      console.error("You can only upload image files.");
      return;
    }
    let imageName = imagePath + v4() + fileName;

    let imageNameExtension = imageName + "." + type[1];

    dispatch(uploadImage({ documentName: imageName, format: type[1] }));

    const updateProfileImageName = {
      user_id: REACT_USER_ID,
      profile_photo: imageNameExtension,
    };

    dispatch(updateProfile(updateProfileImageName));
    console.log(URL.createObjectURL(event.target.files[0]));
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <>
      <main>
        <section className="" style={{ background: "white" }}>
          <div>
            <div className="welcome-inner row">
              <div className="welcome-left-sec col-sm-9">
                <span className="profile_heading" style={{ color: "#b7308e" }}>
                  My Profile
                </span>
                <p className="profile_text">
                  Manage your personal and professional details
                </p>
              </div>

              <div className="welcome-right-sec dropdown"></div>
            </div>

            <div className="container img_name_id_sec">
              <div className="row">
                <div className="col-md-8">
                  <div className="row img_name_id">
                    <div className="col-md-6 profile_img_mentee">
                      <div className="profile-left-side ">
                        <div className="profile--sec">
                          <div className="profile____box">
                            <div className="profile-pic">
                              <label className="-label m-0" htmlFor="file">
                                <span className="glyphicon glyphicon-camera" />
                                <span className="camera___icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-camera"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                  </svg>
                                </span>
                              </label>
                              <input
                                id="file"
                                type="file"
                                accept="image/*"
                                onChange={dragNdrop}
                              />
                              {
                                <img
                                  id="output"
                                  width={200}
                                  src={
                                    file?file:userDetails?.profile_photo
                                      ? `${process.env.REACT_APP_IMAGES_BASE_URL}${userDetails?.profile_photo}`
                                      : userDetails?.gender == "female"
                                      ? `${femaleAvatar}`
                                      : `${maleAvatar}`
                                  }
                                  alt=""
                                />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mentee_name_id">
                      <h3 className="mentee__name">{userDetails?.name}</h3>
                      <h6 className="mentee_email">
                        {userDetails?.email?.email}
                      </h6>
                      <div>
                        <span className="profile--sec w-100 ">
                          <span className="profile-dot">
                            <span className="dropdown">
                              <a
                                href="#"
                                className="dropdown-togle"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                <img src={dotImg} alt="" />
                              </a>
                              <div
                                className="pprofile-upload dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                                x-placement="bottom-start"
                                style={{
                                  position: "absolute",
                                  willChange: "transform",
                                  top: 0,
                                  left: 0,
                                  transform: "translate3d(20px, 29px, 0px)",
                                }}
                              >
                                <ul>
                                  <li>
                                    <div className="file_input">
                                      <input
                                        type="file"
                                        name="Choose a file"
                                        id="file-upload"
                                        className="form-btn"
                                      />
                                      <span htmlFor="fileToUpload">
                                        <span className="link-ic">
                                          <img src={cameraImg} />
                                        </span>
                                        Update Profile Photo
                                        <input
                                          type="file"
                                          accept="image/*"
                                          onChange={dragNdrop}
                                          id="uploadFile"
                                        />
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-md-8 form_box">
                  <div>
                    <div>
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item ">
                          <a
                            className={professional}
                            onClick={() => personalview()}
                          >
                            <span className="tab-img">
                              <img
                                className="p-1"
                                src={personalInfoImg}
                                alt="...."
                              />
                            </span>
                            Personal
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={personal}
                            onClick={() => professionalview()}
                          >
                            <span className="tab-img">
                              <img
                                className="p-1"
                                src={professionImg}
                                alt="...."
                              />
                            </span>
                            Professional
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className={personalblock} id="tabs-1" role="tabpanel">
                      {/* <form> */}
                      <div className="form-group row">
                        <label
                          for="inputEmail3"
                          className="col-sm-2 colum-form-label forleft"
                        >
                          Name:
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control-mentee"
                            id="inputEmail3"
                            placeholder={userDetails?.name || "Name"}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          for="inputEmail3"
                          className="col-sm-2 colum-form-label forleft"
                        >
                          Email:
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="email"
                            className="form-control-mentee"
                            id="inputEmail3"
                            placeholder={userDetails?.email?.email || "Email"}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          for="inputEmail3"
                          className="col-sm-2 colum-form-label forleft"
                        >
                          Country:
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="email"
                            className="form-control-mentee"
                            id="inputEmail3"
                            placeholder={userDetails?.country?.country || "Country"}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          for="inputPassword3"
                          className="col-sm-2 colum-form-label forleft"
                        >
                          Mobile:
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="tel"
                            className="form-control-mentee"
                            id="inputPassword3"
                            placeholder={userDetails?.mobile || ""}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          for="inputEmail3"
                          className="col-sm-2 colum-form-label forleft"
                        >
                          DOB:
                        </label>
                        <div className="col-sm-10">
                          <input
                            defaultValue={userDetails?.dob}
                            type="date"
                            className="form-control-mentee"
                            id="inputEmail3"
                            placeholder={userDetails?.dob || "Date of birth"}
                            onChange={(e) => setDob(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* radio buttons for gender */}

                      <div className="form-group row gender_roww">
                        <span className="col-sm-2">
                          <label
                            for="inputEmail3"
                            className="col-sm-2 colum-form-label genderspan"
                          >
                            Gender:
                          </label>
                        </span>
                        <span className="radio col-sm-2 radiospan">
                          <label className="form-controll">
                            <input
                              type="radio"
                              name="radio"
                              onChange={(e) => setGender("male")}
                              defaultChecked={userDetails?.gender == "male"}
                            />
                            Male
                          </label>
                        </span>
                        <span className="col-sm-2 radiospan">
                          <label className="form-controll">
                            <input
                              type="radio"
                              name="radio"
                              defaultChecked={userDetails?.gender == "female"}
                              onChange={(e) => setGender("female")}
                            />
                            Female
                          </label>
                        </span>
                        <span className="col-sm-2 radiospan">
                          <label className="form-controll">
                            <input
                              type="radio"
                              name="radio"
                              defaultChecked={userDetails?.gender == "Other"}
                              onChange={(e) => setGender("Other")}
                            />
                            Other
                          </label>
                        </span>
                      </div>
                      <div className="radio"></div>
                      <div className="radio"></div>
                    </div>

                    <div
                      className={professionalblock}
                      id="tabs-2"
                      role="tabpanel"
                    >
                      <div className="profile-information">
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-2 colum-form-label"
                          >
                            Profession:
                          </label>
                          <div className="col-sm-10">
                            <select
                              name=""
                              id=""
                              className="form-control-mentee"
                            >
                              <option value="Computer Application">
                                Student
                              </option>
                              <option value="Computer Application">
                                teacher
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="form-group row">
                        <label
                          for="inputEmail3"
                          className="col-sm-2 colum-form-label forleft"
                        >
                          LinkedIn ID:
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="email"
                            className="form-control-mentee"
                            id="inputEmail3"
                            placeholder={userDetails?.linkedinTxt?.linkedinTxt || "LinkedIn"}
                            onChange={(e) => setLinkedinTxt(e.target.value)}
                          />
                        </div>
                      </div>

                        <div className="row">
                          <div className="col-sm-2">
                            <label
                              for="inputEmail3"
                              className="col-sm-2 colum-form-label"
                            >
                              About:
                            </label>
                          </div>
                          <div className="col-sm-10">
                            <textarea
                              type="text"
                              className="form-control-text text_arias"
                              rows="4"
                              id="inputtext"
                              placeholder={userDetails?.about}
                              onChange={(e) => setAbout(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-2">
                            <label
                              for="inputEmail3"
                              className="col-sm-2 colum-form-label"
                            >
                              Goal:
                            </label>
                          </div>
                          <div className="col-sm-10">
                            <textarea
                              type="text"
                              className="form-control-text text_arias"
                              rows="4"
                              id="inputtext"
                              placeholder={userDetails?.goals}
                              onChange={(e) => setGoal(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="update_button_col">
                    <button className="btn update_btn" onClick={updateAccount}>
                      Update Info
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {headerModal?.modal && (
        <headerModal.modal
          headerModal={headerModal}
          setHeaderModal={setHeaderModal}
        />
      )}
    </>
  );
};

export default DashBoard;
