import React from "react";

const Activity = (props) => {
    const { userDetails, menteeListingData } = props;
    console.log("activity",userDetails)

  return (
    <>
      {/* <div className="filter__bar d-flex">
        <div className="filter__sub_item">
          <div id="Filter______Col">
            <select name="" id="" className="filter_____Admin">
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
            </select>
          </div>
          <div className="all_user_select">
            <select name="" id="" className="filter_____Admin mx-3 px-4">
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
            </select>
          </div>
        </div>
      </div> */}
      
      <section className="User___Activity">
        <div className="title__activity">
          <h6 className="activity_heading">Upcoming Activity </h6>
        </div>
        <div className="accordion pb-3" id="accordionExample">
          <div className="accordion-item Item____Radius px-2">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed Item____Radius "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <span className="w-auto d-flex">
                  <span className="me-3 icon_circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="blue"
                      className="bi bi-journal-text"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                    </svg>
                  </span>
                  <span className="task_created">
                    Task Created <br></br> <b> Esther Howard</b>
                  </span>
                </span>

                <span className="user__Task__list">
                  <span className="mx-1 due"> Due:{"  "}</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="#6F7E8E"
                      className="bi bi-calendar"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                    </svg>
                  </span>
                  <span className="day_time"> Today, 12:00PM</span>
                </span>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse "
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="activity_item">
                  <div className="row">
                    <div className="col">
                      <p className="internal_heading mt-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right-circle me-2 mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillrule="evenodd"
                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                          />
                        </svg>
                        Prepare quote for Jerome Bello
                      </p>
                      <p className="internal_para">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Veniam est labore perspiciatis repellendus
                        sapiente doloremque consequuntur cumque, veritatis
                        distinctio necessitatibus, eius perferendis dolorem
                        blanditiis a atque obcaecati recusandae eligendi autem.
                      </p>
                    </div>
                  </div>
                  <div className="row Activity__item__box_input">
                    <div className="col  Activity__item__gridVIew">
                      <label htmlFor="" className="html_for">
                        Reminder
                      </label>
                      <select className="select_options" name="" id="">
                        <option value="1">No reminder</option>
                        <option value="3">1</option>
                        <option value="2">2</option>
                        <option value="4">3</option>
                        <option value="5">4</option>
                        <option value="6">5</option>
                      </select>
                    </div>
                    <div className="col  Activity__item__gridVIew">
                      <label htmlFor="" className="html_for">
                        Task Priority
                      </label>
                      <select className="select_options" name="" id="">
                        <option value="1">High</option>
                        <option value="3">Low</option>
                        <option value="2">Medium</option>
                        <option value="4">Urgent</option>
                      </select>
                    </div>
                    <div className="col d-grid">
                      <label htmlFor="" className="html_for">
                        Assigned to
                      </label>
                      <select className="select_options" name="" id="">
                        <option value="1">Esther Howard</option>
                        <option value="3">aaaa</option>
                        <option value="2">ssss</option>
                        <option value="4">dddd</option>
                        <option value="5">ffff</option>
                        <option value="6">gggg</option>
                      </select>
                    </div>
                    <hr className="task_hr" />
                    <div>
                      <span>Notes</span>
                      <p className="internal_para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Necessitatibus placeat dolor aut nisi magnam, ducimus
                        omnis labore commodi, autem perferendis, eveniet non
                        quas sit voluptate accusamus officia repellendus quod
                        minus!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="title__activity">
          <h6 className="activity_heading mb-0"> Activity History</h6>
          <p className="history_daytime"> 12 Dec 2020</p>
        </div>
        <div className="accordion pb-3" id="accordionExample">
          <div className="accordion-item Item____Radius px-2">
            <h2 className="accordion-header" id="headingtwo">
              <button
                className="accordion-button collapsed Item____Radius"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                <span className="w-auto d-flex">
                  <span className="me-3 icon_circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="blue"
                      className="bi bi-journal-text"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                    </svg>
                  </span>
                  <span className="task_created">
                    Task Created <br></br> <b> Esther Howard</b>
                  </span>
                </span>

                <span className="user__Task__list">
                  <span className="mx-1 due"> Due:{"  "}</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="#6F7E8E"
                      className="bi bi-calendar"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                    </svg>
                  </span>
                  <span className="day_time"> Today, 12:00PM</span>
                </span>
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse "
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="activity_item">
                  <div className="row">
                    <div className="col">
                      <p className="internal_heading mt-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right-circle me-2 mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                          />
                        </svg>
                        Prepare quote for Jerome Bello
                      </p>
                      <p className="internal_para">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Veniam est labore perspiciatis repellendus
                        sapiente doloremque consequuntur cumque, veritatis
                        distinctio necessitatibus, eius perferendis dolorem
                        blanditiis a atque obcaecati recusandae eligendi autem.
                      </p>
                    </div>
                  </div>
                  <div className="row Activity__item__box_input">
                    <div className="col  Activity__item__gridVIew">
                      <label htmlFor="" className="html_for">
                        Reminder
                      </label>
                      <select className="select_options" name="" id="">
                        <option value="1">No reminder</option>
                        <option value="3">1</option>
                        <option value="2">2</option>
                        <option value="4">3</option>
                        <option value="5">4</option>
                        <option value="6">5</option>
                      </select>
                    </div>
                    <div className="col  Activity__item__gridVIew">
                      <label htmlFor="" className="html_for">
                        Task Priority
                      </label>
                      <select className="select_options" name="" id="">
                        <option value="1">High</option>
                        <option value="3">Low</option>
                        <option value="2">Medium</option>
                        <option value="4">Urgent</option>
                      </select>
                    </div>
                    <div className="col d-grid">
                      <label htmlFor="" className="html_for">
                        Assigned to
                      </label>
                      <select className="select_options" name="" id="">
                        <option value="1">Esther Howard</option>
                        <option value="3">aaaa</option>
                        <option value="2">ssss</option>
                        <option value="4">dddd</option>
                        <option value="5">ffff</option>
                        <option value="6">gggg</option>
                      </select>
                    </div>
                    <hr className="task_hr" />
                    <div>
                      <span>Notes</span>
                      <p className="internal_para">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Necessitatibus placeat dolor aut nisi magnam, ducimus
                        omnis labore commodi, autem perferendis, eveniet non
                        quas sit voluptate accusamus officia repellendus quod
                        minus!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Activity;
