import React, { useEffect, useState } from 'react'
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectionListAction, getConnectionRequestAction, updateConnectionRequestAction } from '../../../redux/actions/common';
import { Avatar, Button, Result, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
const YourConnection = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [tabKey, setTabKey] = useState('request');

    const tabs = [
        { label: 'Request', key: 'request' },
        { label: 'Connection', key: 'connection' },
    ];
    const handleTabClick = (index, tabKey) => {
        setSelectedTabIndex(index);
        setTabKey(tabKey)
    };
    useEffect(() => {
        if (tabKey === 'request') {
            dispatch(getConnectionRequestAction());
        } else {
            dispatch(getConnectionListAction());
        }
    }, [dispatch, tabKey]);
console.log("loading:",loading)
    return (
        <>
           
            <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
                <div className="ant-tabs ant-tabs-top booking-tabs ">
                    <div role="tablist" className="ant-tabs-nav">
                        <div className="ant-tabs-nav-wrap">
                            <div className="ant-tabs-nav-list" style={{ transform: "translate(0px, 0px)" }}>
                                {tabs.map((tab, index) => (
                                    <div key={index} data-node-key={index + 1} className={`ant-tabs-tab ${index === selectedTabIndex ? 'ant-tabs-tab-active' : ''}`}>
                                        <div
                                            role="tab"
                                            aria-selected={index === selectedTabIndex}
                                            className="ant-tabs-tab-btn"
                                            tabIndex={0}
                                            id={`rc-tabs-14-tab-${index + 1}`}
                                            aria-controls={`rc-tabs-14-panel-${index + 1}`}
                                            onClick={() => handleTabClick(index, tab.key)}
                                        >
                                            {tab.label}
                                        </div>
                                    </div>
                                ))}
                                <div
                                    className="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                                    style={{
                                        left: `${37.5938 + selectedTabIndex * 150}px`,
                                        transform: "translateX(-50%)",
                                        width: "75.1875px"
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                    
                    <div className="ant-tabs-content-holder">
                        <div className="ant-tabs-content ant-tabs-content-top">
                            <div
                                role="tabpanel"
                                tabIndex={0}
                                aria-hidden="false"
                                className="ant-tabs-tabpane ant-tabs-tabpane-active tab-color"
                                id="rc-tabs-14-panel-1"
                                aria-labelledby="rc-tabs-14-tab-1"
                            >
                                <div className="container-fluid ">
                                    <div className="ant-row calls-main ">
                                        <div className="ant-col ant-col-24 ">
                                            <div className="ant-typography ">
                                                <div
                                                    className="ant-row "
                                                    style={{ marginLeft: "-10px", marginRight: "-10px" }}
                                                >
                                                    <div
                                                        className="ant-col ant-col-24 "
                                                        style={{ paddingLeft: 10, paddingRight: 10 }}
                                                    >
                                                        <div
                                                            className="ant-col calls-card-container ant-col-xs-24 "
                                                            style={{ paddingLeft: 10, paddingRight: 10 }}
                                                        >
                                                            {
                                                                tabKey === 'request' ? (
                                                                    <RequestListing setLoading={setLoading} loading={loading} />
                                                                ) : (
                                                                    <ConnectionListing setLoading={setLoading} loading={loading} />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default YourConnection
const RequestListing = ({ setLoading, loading }) => {
    const dispatch = useDispatch();

    const [index, setIndex] = useState(null);
    const [loadings, setLoadings] = useState([]);
    const [data, setData] = useState(null);

    const getConnectionRequestReducer = useSelector(
        (state) => state.getConnectionRequestReducer
    );

    useEffect(() => {
        const { data, loading, error, status } = getConnectionRequestReducer;
        setLoading(loading)
        if (!loading && data && !error) {
            setData(data)
        }
        if (!loading && data === undefined && error) {
            console.warn("error in getConnectionRequestReducer API", error)
        }

    }, [getConnectionRequestReducer])

    const ResponseRequest = (connection_id, response, index) => {
        setIndex(index)

        const data = {
            connection_id: connection_id,
            status: response
        }
        dispatch(updateConnectionRequestAction(data));

    }
    const updateConnectionRequestReducer = useSelector(
        (state) => state.updateConnectionRequestReducer
    );

    useEffect(() => {
        const { data, loading, error, status } = updateConnectionRequestReducer;
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = loading;
            return newLoadings;
        });
        if (!loading && data && status === 200) {
            message.success("Request Accepted !")
            dispatch(getConnectionRequestAction());

        }
        if (!loading && status !== 200) {
            console.warn("error in getConnectionRequestReducer API", error)
        }

    }, [dispatch, updateConnectionRequestReducer, index])
    return (
        <>
        {loading && (
            <div className="loader open_sessions_loader">
                <TailSpin ariaLabel="Loading..." color="#00BFFF" />
            </div>
        ) }
        {
            !loading && (
                
                    data && data?.length > 0 ? (
                          data?.map((item, index) => (
                              <div className="ant-typography calls-card " key={index}>
      
                                  <div className="ant-typography calls-details cursor-hover ">
                                      <div className="ant-typography calls-name ">
                                          <span className="ant-typography ">
                                              <strong>{item?.sender?.name}</strong>
                                          </span>
                                      </div>
      
                                  </div>
                                  <div className="ant-typography calls-footer ">
                                      <div
                                          className="ant-row ant-row-space-between ant-row-middle "
                                          style={{ marginLeft: "-2.5px", marginRight: "-2.5px" }}
                                      >
                                          <div
                                              className="ant-col ant-col-xs-10 ant-col-md-9 "
                                              style={{ paddingLeft: "2.5px", paddingRight: "2.5px" }}
                                          >
                                              <div className="ant-typography ">
                                                  <div
                                                      className="ant-typography "
                                                      style={{
                                                          backgroundColor: "rgb(236, 247, 241)",
                                                          borderRadius: 28,
                                                          textAlign: "center",
                                                          display: "inline-flex"
                                                      }}
                                                  >
                                                      <div
                                                          className="ant-typography status-tag-text "
                                                          style={{ color: "rgb(10, 68, 44)" }}
                                                      >
                                                          {item?.status}
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div
                                              className="ant-col ant-col-xs-14 ant-col-md-15 "
                                              style={{ paddingLeft: "2.5px", paddingRight: "2.5px" }}
                                          >
                                              <div
                                                  className="ant-row ant-row-end ant-row-middle "
                                                  style={{ marginLeft: "-4px", marginRight: "-4px" }}
                                              >
      
                                                  <div
                                                      className="ant-col ant-col-xs-15 ant-col-md-10 "
                                                      style={{ paddingLeft: 4, paddingRight: 4 }}
                                                  >
                                                      <Button
                                                          loading={loadings[index]}
                                                          onClick={() => ResponseRequest(item?.connection_id, "accepted", index)}
      
      
                                                          type="button"
                                                          className="ant-btn  ant-btn-primary ant-btn-block btn-dark"
      
                                                          rel="noreferrer"
                                                      >
                                                          <span>Accept</span>
                                                      </Button>
                                                  </div>
                                                  <div
                                                      className="ant-col ant-col-xs-15 ant-col-md-10 "
                                                      style={{ paddingLeft: 4, paddingRight: 4 }}
                                                  >
                                                      <Button
                                                          loading={loadings[index + 1]}
                                                          onClick={() => ResponseRequest(item?.connection_id, "rejected", index + 1)}
      
      
                                                          type="button"
                                                          className="ant-btn  ant-btn-primary ant-btn-block btn-dark"
      
                                                          rel="noreferrer"
                                                      >
                                                          <span>Decline</span>
                                                      </Button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          ))
                      ) : (
                          <Result
      
                              title="You don't have any request at the moment. Please try again later."
      
                          />
                      )
                  
            )
        }
            

        </>
    )
}

const ConnectionListing = ({ loading, setLoading }) => {
    const navigate = useNavigate()
    const [data, setData] = useState(null);


    const getConnectionListReducer = useSelector((state) => state.getConnectionListReducer);

    useEffect(() => {
        const { data, loading, error } = getConnectionListReducer;
        setLoading(loading);

        if (data && !loading && !error) {
            setData(data);
        } else if (!loading && error) {
            console.error('Error fetching connection list:', error);
        }
    }, [getConnectionListReducer]);

    return (
        <>
        {loading && (
            <div className="loader open_sessions_loader">
                <TailSpin ariaLabel="Loading..." color="#00BFFF" />
            </div>
        ) }
            {
                 !loading && (
                    data && data?.length > 0 ? (
                        data?.map((item, index) => (
                            <div className="ant-typography calls-card " key={index}>
    
                                <div className="ant-typography calls-details cursor-hover ">
                                    <div className="ant-typography calls-name ">
                                        <span className="ant-typography ">
                                            <Avatar
                                                size={64}
                                                icon={item.connection_details.profile_photo ? null : <UserOutlined />}
                                                src={item.connection_details.profile_photo ?
                                                    `${process.env.REACT_APP_IMAGES_BASE_URL}${item.connection_details.profile_photo}` :
                                                    null
                                                }
                                            />
                                            {"   "}
                                            <strong>{item?.connection_details?.name}</strong>
                                        </span>
                                    </div>
    
                                </div>
                                <div className="ant-typography calls-footer ">
                                    <div
                                        className="ant-row ant-row-space-between ant-row-middle "
                                        style={{ marginLeft: "-2.5px", marginRight: "-2.5px" }}
                                    >
                                        <div
                                            className="ant-col ant-col-xs-10 ant-col-md-9 "
                                            style={{ paddingLeft: "2.5px", paddingRight: "2.5px" }}
                                        >
                                            <div className="ant-typography ">
                                                <div
                                                    className="ant-typography "
                                                    style={{
                                                        backgroundColor: "rgb(236, 247, 241)",
                                                        borderRadius: 28,
                                                        textAlign: "center",
                                                        display: "inline-flex"
                                                    }}
                                                >
                                                    <div
                                                        className="ant-typography status-tag-text "
                                                        style={{ color: "rgb(10, 68, 44)" }}
                                                    >
                                                        {item?.status}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <Result
    
                            title="You don't have any connections at the moment. Please check your request page."
                            extra={
                                <Button type="primary" onClick={() => navigate('/dashboard/communications/connections')}>
                                    Check Request
                                </Button>
                            }
                        />
                    )
                 )
                
            }
        </>
    )
}
