import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REACT_USER_ID, logged } from "../auth/tokenProvider";
import {
  favoriteMentor,
  goalSimilarMentor,
  removeFavoriteMentor,
  subscribedMentor,
} from "../../redux/actions/mentee";
import { getUserDetails } from "../../redux/actions/login";
import { viewMentorProfileAction } from "../../redux/actions/mentor";
import { TailSpin } from "react-loader-spinner";
import {
  calendarImg,
  languageImg,
  mentorEvent,
  popularIc,
} from "../../assets/images/export-images";
import styles from "./MenteeAdminDashboard.module.css";
import arrowDownImg from "../dashboard/images/arrow-down.png";

import priceImg from "../dashboard/images/price.png";
import preSess1Img from "../dashboard/images/pre-sess-1.png";
import preSess2Img from "../dashboard/images/pre-sess-2.png";
import preSess3Img from "../dashboard/images/pre-sess-3.png";
import noMentorImg from "../dashboard/images/no-mentor.png";
import helpImg from "../dashboard/images/help.png";
import feedbackimg from "../../assets/images/join-img-2.png";

import maleAvatar from "../../assets/images/male.png";
import femaleAvatar from "../../assets/images/female.png";
import { FeedbackForm } from "../Feedback/index";
import freeSessionImage from './to_eister-removebg-preview.png'

const MenteeHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [feedbackModal, setFeedbackModal] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  let date = new Date();
  let datestring = date.toTimeString();
  const mentee_id = REACT_USER_ID;
  let countryname =
    datestring?.includes("india") | datestring?.includes("India")
      ? "India"
      : "";
  let currencyName = countryname === "India" ? "INR" : "USD";
  const userId =
    useSelector((state) => {
      return (
        state.loginReducer?.headers?.["user-id"] ||
        state.passwordLoginReducer?.headers?.["user-id"] ||
        state.socialLoginReducer?.headers?.["user-id"]
      );
    }) || REACT_USER_ID;
  useEffect(() => {
    import("../../assets/css/bootstrap.min.css");
    import("../../assets/css/font-awesome.min.css");
    import("../../assets/css/owl.carousel.min.css");
    import("../../assets/css/owl.theme.default.min.css");
    import("../../components/dashboard/css/dashboard-mentor-subcription.css");
    import("../../assets/css/custom.css");
    import("../../assets/css/header.css");
    import("../../assets/css/dashboard.css");
    import("../../assets/css/footer.css");
    import("../../assets/css/media.css");
    dispatch(subscribedMentor(REACT_USER_ID));
    // dispatch(getUserDetails(userId));
    dispatch(favoriteMentor(REACT_USER_ID));
  }, []);




  const favoriteMentorsList = useSelector((state) => {
    const data = state.favoriteMentorReducer?.data || [];
    return data.slice(0, 2);
  });

  const removeFromFavoriteMentorsFunction = (id) => {
    dispatch(removeFavoriteMentor(favoriteMentorsList, id));
  };
  const subscribedMentorLoading = useSelector(
    (state) => state.subscribedMentorReducer?.isLoading
  );
  useEffect(() => {

    setIsLoading(subscribedMentorLoading)
  }, [subscribedMentorLoading])

  const subscribedMentorsList =
    useSelector((state) => state.subscribedMentorReducer?.data) || [];

  useEffect(() => {
    if (mentee_id) {
      console.log("working");
      dispatch(goalSimilarMentor(mentee_id));
    }
  }, [mentee_id]);

  const similarMentorsList = useSelector((state) => {
    const data = state.goalSimilarMentorReducer?.data || [];
    return data.slice(0, 3);
  });

  const similarMenotrLoading = useSelector(
    (state) => state.goalSimilarMentorReducer?.isLoading
  );

  const viewProfile = (mentor) => {
    let user = mentor.name.split(" ").join("") + "_" + mentor.user_id;
    dispatch(viewMentorProfileAction(mentor.user_id));
    navigate("/mentor-details/" + user);
  };


  // subsrbie divider

  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [HistorySession, setHistorySession] = useState([]);
  const isMeetingTimeInFuture = (meetingTime) => {
    const currentDateTime = new Date();
    const meetingDateTime = new Date(meetingTime);
    return meetingDateTime > currentDateTime; // Adjusted condition
  };
  const isMeetingTimeInPast = (meetingTime) => {
    const currentDateTime = new Date();
    const meetingDateTime = new Date(meetingTime);
    return meetingDateTime < currentDateTime; // Adjusted condition
  };

  useEffect(() => {
    if (subscribedMentorsList?.length > 0) {
      const filteredSessions = subscribedMentorsList.filter((menteeRequest) => {
        const meetingDateTime =
          menteeRequest.mentee_details.meeting_time.includes("T")
            ? new Date(menteeRequest.mentee_details.meeting_time)
            : new Date(
              `${menteeRequest.mentee_details.meeting_date}T${menteeRequest.mentee_details.meeting_time}:00.000Z`
            );

        return isMeetingTimeInFuture(meetingDateTime);
      });

      setUpcomingSessions(filteredSessions);
    }
    if (subscribedMentorsList?.length > 0) {
      const filteredSessions = subscribedMentorsList.filter((menteeRequest) => {
        const meetingDateTime =
          menteeRequest.mentee_details.meeting_time.includes("T")
            ? new Date(menteeRequest.mentee_details.meeting_time)
            : new Date(
              `${menteeRequest.mentee_details.meeting_date}T${menteeRequest.mentee_details.meeting_time}:00.000Z`
            );

        return isMeetingTimeInPast(meetingDateTime);
      });

      setHistorySession(filteredSessions);
    }

    setIsLoading(false);
  }, [subscribedMentorsList]);
  console.log("upcomingSessions", upcomingSessions)

  return (
    <>
      <section className="p-1">
        <div className="container">
          <div className="subscribed-mentor-sec">
            <div
              className={`subscribe-mentor subscribe_box ${styles.home_tab_mentors}`}
            >
              <h4>Upcoming Sessions</h4>
              {isLoading ? (
                <div className="justify-content-center d-flex mt-5">
                  <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
              ) : upcomingSessions?.length ? (
                upcomingSessions?.reverse().map((subcription, id) => {
                  let date = new Date(
                    subcription.mentee_details.meeting_time.includes('T') ? (
                      (subcription.mentee_details.meeting_time)
                    ) : (
                      (`${subcription.mentee_details.meeting_date}T${subcription.mentee_details.meeting_time}:00.000Z`)
                    )
                  );
                  let meeting_time = new Date(

                    subcription.mentee_details.meeting_time.includes('T') ? (
                      (subcription.mentee_details.meeting_time)
                    ) : (
                      (`${subcription.mentee_details.meeting_date}T${subcription.mentee_details.meeting_time}:00.000Z`)
                    )

                  );
                  return (
                    <div key={subcription?.subscription_id} className="card my-2">
                      <div className="card-header">
                        <a
                          className="collapsed card-link"
                          data-toggle="collapse"
                          href={`#collapse${subcription?.subscription_id}`}
                        >
                          <span className="title-main-cls">
                            {subcription?.product_details?.title}{" "}
                            <span className="dot"></span>Meeting on :{" "}
                            {meeting_time?.toDateString()} &nbsp;
                            {meeting_time?.toLocaleTimeString()}
                          </span>
                          {/* <button
                            type="button"
                            className={
                              subcription?.status == "PENDING"
                                ? "btn pending"
                                : subcription?.status == "APPROVED"
                                  ? "btn approved"
                                  : "btn rejected"
                            }
                          >
                            {subcription?.status}
                          </button> */}
                          <span className="arrow-ic }" style={{ marginLeft: 'auto' }} >
                            <img alt='icon' src={arrowDownImg} />
                          </span>
                        </a>
                      </div>
                      <div
                        id={`collapse${subcription?.subscription_id}`}
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="event-cont mw-100">
                            <div className="fav-event">
                              <div className="event-user">
                                {subcription?.mentor_details?.profile_photo ? (
                                  <img
                                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}${subcription?.mentor_details?.profile_photo}`}
                                    alt=""
                                  />
                                ) : (
                                  <img alt='icon' src={mentorEvent} />
                                )}
                              </div>
                            </div>
                            <div className="event-user-dtl">
                              <h5>{subcription?.mentor_details?.name}</h5>
                              <span className="language">
                                <span className="lang-ic">
                                  <img alt='icon' src={languageImg} />
                                </span>
                                {Array.isArray(subcription?.mentor_details?.language) ?
                                  subcription?.mentor_details?.language?.map(
                                    (item, id) => <span key={id}> {item}</span>
                                  )
                                  : (null)
                                }
                              </span>
                              <div className="date-payment-sec">
                                <a
                                  target="_blank"
                                  href={
                                    subcription?.mentee_details?.meeting_link
                                  }
                                >
                                  <span className="date-wrap">
                                    <span className="date-ic">
                                      <img alt='icon' src={calendarImg} />
                                    </span>
                                    <span className="date-cont">
                                      {meeting_time?.toDateString()} &nbsp;
                                      {meeting_time?.toLocaleTimeString()}
                                    </span>
                                  </span>
                                </a>
                                <span className="payment-wrap">
                                  <span className="date-ic">
                                    <img alt='icon' src={priceImg} />
                                  </span>
                                  <span className="date-cont">
                                    {subcription?.mentee_details?.price_per_session === "0" ? "Free Session" : "Paid Session"}
                                  </span>
                                  <span className="">
                                    <a
                                      style={{
                                        borderRadius: '15px'
                                      }}
                                      type="button"
                                      className="btn btn-success certificate_savebtn ml-3"
                                      target="_blank"
                                      href={
                                        subcription?.mentee_details?.meeting_link
                                      }
                                    >
                                      Join Session
                                    </a>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="pre-session">
                            <span>Pre-Session Study Material</span>
                            <ul>
                              <li>
                                <img alt='icon' src={preSess1Img} />
                              </li>
                              <li>
                                <img alt='icon' src={preSess2Img} />
                              </li>
                              <li>
                                <img alt='icon' src={preSess3Img} />
                              </li>
                            </ul>
                          </div>
                          <div className="skills-sec">
                            <ul>
                              {subcription?.mentor_details?.skills?.map(
                                (skill, id) => (
                                  <li key={id}>
                                    <a href="#">{skill}</a>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-active-mentor-sec">
                  <div className="no-active-cont">
                    <h3>No Upcoming Sessions</h3>
                    {/* <h3>No Active Mentorship</h3> */}
                    <p>You haven't applied for any mentorship yet</p>
                    <div className="get-started-cls">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => navigate("/free-membership")}
                      >
                        Book a Free Session Now
                      </button>
                    </div>
                  </div>
                  <div className="no-active-img">
                    <img alt='icon' src={noMentorImg} />
                  </div>
                </div>
              )}
            </div>




            {/* fav-mentor section */}
            <div className="fav-mentor ">
              <h4>Book a Free Session</h4>
              <div className="fav-mentor-inner  d-flex justify-content-center align-items-center vh-30">

                <div className="view-mentor">
                  <div className="no-active-mentor-sec">
                    <div className="no-active-cont">

                      <div className={`get-started-cls ${styles.mentee_home_btn}`}>
                        <button
                          type="button"
                          className="btn btn-primary mt-3"
                          onClick={() => navigate("/free-membership")}
                          style={{
                            padding: "0rem",
                          }}
                        >
                          Book a Free Session Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            {/* <div className="fav-mentor">
              <h4>Your Fav. Mentors</h4>
              <div className="fav-mentor-inner">
                {favoriteMentorsList.length > 0 ? (
                  <>
                    {favoriteMentorsList.map((mentor, id) => (
                      <div key={id} className="event-cont">
                        <div className="fav-event">
                          <div className="event-user">
                            <img alt='icon' src={mentorEvent} />
                          </div>
                          <button
                            type="button"
                            className="btn remove-btn"
                            onClick={() => {
                              removeFromFavoriteMentorsFunction(
                                mentor?.favorite_id
                              );
                            }}
                          >
                            Remove
                          </button>
                        </div>
                        <div className="event-user-dtl">
                          <h5>{mentor?.mentor_details?.name}</h5>
                          <div className="rating-inner-cls">
                            <span className="rating-checked">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </span>
                            <span className="rating-checked">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </span>
                            <span className="rating-checked">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </span>
                            <span className="rating">
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span className="rating">
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <span className="language">
                            <span className="lang-ic">
                              <i
                                className="fa fa-language"
                                aria-hidden="true"
                              ></i>
                            </span>
                            {mentor?.mentor_details?.language}
                          </span>
                          <p>{mentor?.mentor_details?.professional}</p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="view-mentor">
                    <div className="no-active-mentor-sec">
                      <div className="no-active-cont">
                        <h3>No Mentor</h3>
                        <p>
                          You haven't shortlist any mentor in favourite list
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="view-mentor">
                <button type="button" className="btn view-btn">
                  View All
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="p-1">
        <div className="container">
          <div className="subscribed-mentor-sec">
            <div
              className={`subscribe-mentor subscribe_box ${styles.home_tab_mentors}`}
            >
              <h4>Past Sessions</h4>
              {/* <h4>Subscribed Mentors</h4> */}
              {isLoading ? (
                <div className="justify-content-center d-flex mt-5">
                  <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
              ) : HistorySession?.length ? (
                HistorySession?.reverse().map((subcription, id) => {
                  let date = new Date(
                    subcription.mentee_details.meeting_time.includes('T') ? (
                      (subcription.mentee_details.meeting_time)
                    ) : (
                      (`${subcription.mentee_details.meeting_date}T${subcription.mentee_details.meeting_time}:00.000Z`)
                    )
                  );
                  let meeting_time = new Date(

                    subcription.mentee_details.meeting_time.includes('T') ? (
                      (subcription.mentee_details.meeting_time)
                    ) : (
                      (`${subcription.mentee_details.meeting_date}T${subcription.mentee_details.meeting_time}:00.000Z`)
                    )

                  );
                  return (
                    <div key={subcription?.subscription_id} className="card my-2">
                      <div className="card-header">
                        <a
                          className="collapsed card-link"
                          data-toggle="collapse"
                          href={`#collapse${subcription?.subscription_id}`}
                        >
                          <span className="title-main-cls">
                            {subcription?.product_details?.title}{" "}
                            <span className="dot"></span>Meeting on :{" "}
                            {meeting_time?.toDateString()} &nbsp;
                            {meeting_time?.toLocaleTimeString()}
                          </span>
                          {/* <button
                            type="button"
                            className={
                              subcription?.status == "PENDING"
                                ? "btn pending"
                                : subcription?.status == "APPROVED"
                                  ? "btn approved"
                                  : "btn rejected"
                            }
                          >
                            {subcription?.status}
                          </button> */}
                          <span className="arrow-ic " style={{ marginLeft: 'auto' }}>
                            <img alt='icon' src={arrowDownImg} />
                          </span>
                        </a>
                      </div>
                      <div
                        id={`collapse${subcription?.subscription_id}`}
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="event-cont mw-100">
                            <div className="fav-event">
                              <div className="event-user">
                                {subcription?.mentor_details?.profile_photo ? (
                                  <img
                                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}${subcription?.mentor_details?.profile_photo}`}
                                    alt=""
                                  />
                                ) : (
                                  <img alt='icon' src={mentorEvent} />
                                )}
                              </div>
                            </div>
                            <div className="event-user-dtl">
                              <h5>{subcription?.mentor_details?.name}</h5>
                              <span className="language">
                                <span className="lang-ic">
                                  <img alt='icon' src={languageImg} />
                                </span>
                                {Array.isArray(subcription?.mentor_details?.language) ?
                                  subcription?.mentor_details?.language?.map(
                                    (item, id) => <span key={id}> {item}</span>
                                  )
                                  : (null)
                                }
                              </span>
                              <div className="date-payment-sec">
                                <a
                                  target="_blank"
                                  href={
                                    subcription?.mentee_details?.meeting_link
                                  }
                                >
                                  <span className="date-wrap">
                                    <span className="date-ic">
                                      <img alt='icon' src={calendarImg} />
                                    </span>
                                    <span className="date-cont">
                                      {meeting_time?.toDateString()} &nbsp;
                                      {meeting_time?.toLocaleTimeString()}
                                    </span>
                                  </span>
                                </a>
                                <span className="payment-wrap">
                                  <span className="date-ic">
                                    <img alt='icon' src={priceImg} />
                                  </span>
                                  <span className="date-cont">
                                    {subcription?.mentee_details?.price_per_session === "0" ? "Free Session" : "Paid Session"}
                                  </span>
                                  <span className="">
                                    <a
                                      style={{
                                        borderRadius: '15px'
                                      }}
                                      type="button"
                                      className="btn btn-success certificate_savebtn ml-3"
                                      target="_blank"
                                      href={
                                        subcription?.mentee_details?.meeting_link
                                      }
                                    >
                                      Join Session
                                    </a>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="pre-session">
                            <span>Pre-Session Study Material</span>
                            <ul>
                              <li>
                                <img alt='icon' src={preSess1Img} />
                              </li>
                              <li>
                                <img alt='icon' src={preSess2Img} />
                              </li>
                              <li>
                                <img alt='icon' src={preSess3Img} />
                              </li>
                            </ul>
                          </div>
                          <div className="skills-sec">
                            <ul>
                              {subcription?.mentor_details?.skills?.map(
                                (skill, id) => (
                                  <li key={id}>
                                    <a href="#">{skill}</a>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-active-mentor-sec">
                  <div className="no-active-cont">
                    <h3>No  Mentorship</h3>
                    <p>You haven't applied for any mentorship yet</p>
                    <div className="get-started-cls">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => navigate("/free-membership")}
                      >
                        Book a Free Session Now
                      </button>
                    </div>
                  </div>
                  <div className="no-active-img">
                    <img alt='icon' src={noMentorImg} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="help-sec mt-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="help-img">
                <img alt='icon' src={helpImg} />
              </div>
            </div>
            <div className="col-md-6">
              <div className={`help-cont ${styles.mentee_admin_subhead}`}>
                <h4>Ready to Book a Session with a Mentor?</h4>
                <h3>Take the Next Step!</h3>
                <p>
                  Whether you have specific goals or need guidance, our mentors are here for you. Share your requirements, and we'll connect you with the right mentor to help you succeed.
                </p>
                <div className={`get-started-cls ${styles.mentee_home_btn}`}>
                  <button
                    type="button"
                    className="btn btn-primary mt-3"
                    onClick={() => navigate("/free-membership")}
                    style={{
                      padding: "0rem",
                    }}
                  >
                    Book a Free Session Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="checkout-mentor">
        <div className="reading-book-sec">
          <div className="container">
            <div className={`inner-title ${styles.inner_title_heading}`}>
              <h3>Explore Similar Mentors</h3>
              <h2>Check out other mentors with the same skills</h2>
            </div>
            <div className="  Session__list_main">
              {similarMenotrLoading ? (
                <div className="justify-content-center d-flex mt-5">
                  <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
              ) : similarMentorsList.length > 0 ? (
                similarMentorsList.map((mentor, id) => (
                  <div
                    key={id}
                    className=" mentor___list"
                    onClick={() => viewProfile(mentor)}
                    style={{ padding: "1px" }}
                  >
                    <div className=" mentr_list__box cursor-pointer">
                      <div className="mentor-avatar overflow-hidden">
                        <div className="image-dimmer" />
                        {mentor?.profile_photo &&
                          mentor?.profile_photo != "" ? (
                          <img
                            src={`${process.env.REACT_APP_IMAGES_BASE_URL}${mentor?.profile_photo}`}
                            alt=""
                          />
                        ) : mentor?.gender == "female" ? (
                          <img
                            alt="icon"
                            src={femaleAvatar}
                            style={{ width: "17rem" }}
                          />
                        ) : (
                          <img alt="icon" src={maleAvatar} />
                        )}
                      </div>
                      <div className=" name___box " width={1920}>
                        <p className="preview__content__name d-flex align-items-center text-truncate">
                          <span className="black-text font-weight-600 text-truncate">
                            {mentor.name}
                          </span>
                        </p>
                        <div className="preview__content__job grey-1-text">
                          <div className="item-list">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-briefcase"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                            </svg>
                            <p className=" mentor___feature ml-2">
                              <span>{mentor.title}</span>{" "}
                              <span className="grey-2-text">at</span>{" "}
                              <span>{mentor.company_or_school}</span>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p
                            className=" mentor___feature grey-1-text"
                            style={{ marginLeft: "11px" }}
                          >
                            <span className="grey-2-text">
                              {mentor.country}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="preview__content__metadata grey-4-bg expertise-p3 mt-auto d-flex justify-content-between">
                        <div>
                          <p className=" dZPGPc grey-2-text">Experience</p>
                          <p className=" mentor___feature grey-1-text font-weight-600">
                            {mentor.experience}
                          </p>
                        </div>
                        <div>
                          <p className=" dZPGPc grey-2-text">
                            Price Per Session
                          </p>
                          <p className=" mentor___feature grey-1-text font-weight-600">
                            {mentor?.inr_price === "0" &&
                              mentor?.price_per_session === "0"
                              ? "Pro Bono"
                              : countryname === "India"
                                ? "₹" + mentor?.inr_price
                                : "$" + mentor?.price_per_session}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div class=" Add_____notes mb-3 p-4 text-center inner-title">
                  <h2>No similar mentors</h2>
                </div>
              )}
            </div>
            <div className="get-started-cls">
              <button
                type="button"
                className="find-btn btn btn-primary"
                onClick={() => navigate("/find-mentor")}
              >
                Load More
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="help-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="help-img">
                <img alt='icon' src={feedbackimg} />
              </div>
            </div>
            <div className="col-md-6">
              <div className={`help-cont ${styles.mentee_admin_subhead}`}>
                <h4>Having trouble finding the right mentor?</h4>
                <h3>Let Us Help You!</h3>
                <p>
                  Please share your custom requirements with us, and we will
                  find the right mentor for you.
                </p>
                <div className={`get-started-cls ${styles.mentee_home_btn}`}>
                  <button
                    data-toggle="modal"
                    data-target="#loginForm"
                    onClick={() =>
                      setFeedbackModal({
                        modal: FeedbackForm,
                        show: true,
                      })
                    }
                    type="button"
                    className={`btn btn-primary mt-3 `}
                  >
                    Tell us now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {feedbackModal?.modal && (
        <feedbackModal.modal
          feedbackModal={feedbackModal}
          setFeedbackModal={setFeedbackModal}
        />
      )}
    </>
  );
};

export default MenteeHome;
