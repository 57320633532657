export const webinarInfo = [
    {
        info:"This is free webinar held on 22 december 2024",
        poster:require("./4667062.jpg")
    },
    {
        info:"This is free webinar held on 22 december 2024",
        poster:require("./4669929.jpg")
    },
    {
        info:"This is free webinar held on 22 december 2024",
        poster:require("./5267292.jpg")
    }
   
]
    
