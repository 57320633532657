import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const CalenderTime = () => {
    const navigate = useNavigate();

    const tabs = [
        { label: 'Calendar', href: '/dashboard/availability/configurations' },
        { label: 'Schedule', href: '/dashboard/availability/schedule' },
    ];

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleTabClick = (index, tab) => {
        setSelectedTabIndex(index);
        navigate(tab.href);
    };

    return (
        <>
            <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
                <div className="ant-row container-title-section ">
                    <div className="ant-col header-section ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-14 ant-col-md-offset-1 ant-col-xl-12 ant-col-xl-offset-1 ant-col-xxl-12 ant-col-xxl-offset-1 ">
                        <h4 className="ant-typography layout-header ">
                            <div className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line ">
                                Availability
                            </div>
                        </h4>
                    </div>
                    <div className="ant-col tab-container ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xl-22 ant-col-xl-offset-1 ant-col-xxl-22 ant-col-xxl-offset-1 ">
                        <span className="ant-typography flex-center " style={{ overflowY: "auto" }}>
                            {tabs.map((tab, index) => (
                                <a
                                    key={index}
                                    className={`tab-link tab-link-common ${index === selectedTabIndex ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index, tab)}
                                >
                                    <span className="ant-typography tab-title ">{tab.label}</span>
                                </a>
                            ))}
                        </span>
                    </div>
                </div>
                <div className="container-children mx-5 ">
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default CalenderTime;
