import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import crossIcon from "../../../assets/images/cross-icon.png";
import mentorLogo from "../../../assets/images/mentor-logo.png";
import mentorWlogo from "../../../assets/images/mentor-wlogo.png";
import LoginModal from "../loginModal";

const SignUpWithMobileNoModal = ({ headerModal, setHeaderModal }) => {
  return (
    <Modal
      size="xl"
      centered
      show={headerModal.show}
      onHide={() =>
        setHeaderModal((prevState) => ({ ...prevState, show: false }))
      }
    >
      <ModalBody>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() =>
            setHeaderModal((prevState) => ({ ...prevState, show: false }))
          }
        >
          <span aria-hidden="true">
            <img src={crossIcon} />
          </span>
        </button>
        <form>
          <div className="signup-row">
            <div className="signup-leftcol">
              <div className="sign-form-cls">
                <div className="logo-sec">
                  <h4>Welcome to</h4>
                  <img src={mentorLogo} alt="Mentorpal" />
                </div>
                <div className="wel-space-cls">
                  <div className="form-group">
                    <h5>Please Enter Your Mobile Number</h5>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name=""
                      placeholder="Mobile Number"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="button"
                      className="submit-btn form-control"
                      data-toggle="modal"
                      data-target="#EnterOTP"
                    >
                      Continue
                    </button>
                  </div>
                  <div className="singup-up">
                    <a href="">Sign up with Email</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="signup-rightcol">
              <div className="signup-cont">
                <div className="signup-logo d-none d-md-block">
                  <img src={mentorWlogo} alt="" />
                </div>
                <div className="signup-inner">
                  <p>
                    Mentorship from the global expert. join one-on-one Live
                    session and group session from the verified mentors at your
                    fingertips
                  </p>
                  <button
                    type="button"
                    onClick={() =>
                      setHeaderModal({ modal: LoginModal, show: true })
                    }
                  >
                    Already Member?Login here
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default SignUpWithMobileNoModal;
