import React, { useState } from 'react';

import { expertise } from '../../../../assets/data/data';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { updateMentorCourseAction } from '../../../../redux/actions/course';
import { UploadImage } from '../../../UploadImage';
import maleAvatar from '../../../../assets/images/male.png'

const Details = () => {
	const dispatch = useDispatch()

	const [loader, setloader] = useState(false);
	const [data, setData] = useState({
		language_mode: [],
		faq_data: [],
		highlights: [],
		course_hosted_by: [],
		what_you_will_learn: [],
		course_start_date: '',
		course_banner: '',
		course_description: '',
		course_time_period: '',
		course_type: '',
		course_topic: '',
		document_type: '',
		course_intro_video: '',
		course_intro: '',
		course_seat: '',
		product_id: '',
	});
	const [status, setStatus] = useState('');
	const [error, setError] = useState('');

	const getCourseDetailsReducer = useSelector(
		(state) => state.getCourseDetailsReducer
	)
	useEffect(() => {
		setloader(getCourseDetailsReducer?.loading);
		setStatus(getCourseDetailsReducer?.status);
		setError(getCourseDetailsReducer?.error);

		if (!data && getCourseDetailsReducer?.data) {
			setData({
				...data,
				document_type: getCourseDetailsReducer.data.document_type || '',
				course_topic: getCourseDetailsReducer.data.course_topic || '',
				course_type: getCourseDetailsReducer.data.course_type || '',
				course_time_period: getCourseDetailsReducer.data.course_time_period || '',
				course_start_date: getCourseDetailsReducer.data.course_start_date || '',
				course_description: getCourseDetailsReducer.data.course_description || '',
				course_banner: getCourseDetailsReducer.data.course_banner || '',
				course_intro_video: getCourseDetailsReducer.data.course_intro_video || '',
				course_intro: getCourseDetailsReducer.data.course_intro || '',
				course_seat: getCourseDetailsReducer.data.course_seat || '',
				faq_data: getCourseDetailsReducer.data.faq_data || '',
				course_hosted_by: getCourseDetailsReducer.data.course_hosted_by || '',
				highlights: getCourseDetailsReducer.data.highlights || '',
				what_you_will_learn: getCourseDetailsReducer.data.what_you_will_learn || '',
				product_id: getCourseDetailsReducer.data.product_id || '',
				language_mode: getCourseDetailsReducer.data.language_mode || '',
			});
		}
	}, [getCourseDetailsReducer, data]);

	const [detailModal, setDetailModal] = useState({});

	const handleAddNew = () => {
		const newHighlight = {
			label: '',
			value: '',
		};

		setData((prevData) => ({
			...prevData,
			highlights: [...prevData.highlights, newHighlight], // Add the new input to the highlights array
		}));
	};
	const convertLocalTimeToUTC = (localTime) => {
		const localDate = new Date(localTime);
		const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
		return utcDate.toISOString();
	}


	const handleInputChange = (index, newValue) => {
		setData((prevData) => ({
			...prevData,
			highlights: prevData.highlights.map((highlight, i) =>
				i === index ? { ...highlight, label: newValue, value: newValue } : highlight
			), // Update the title of the input at the specified index
		}));
	};
	// Modal Faq
	const [modalOpen, setModalOpen] = useState(false);
	const [newFaq, setNewFaq] = useState({
		question: '',
		answer: '',
	});

	const [isEditModal, setIsEditModal] = useState(false);
	const [editFaqIndex, setEditFaqIndex] = useState(-1);

	const handleAddNewFaq = () => {
		setModalOpen(true);
		setIsEditModal(false);
		setNewFaq({
			question: '',
			answer: '',
		});
	};

	const handleEditFaq = (index) => {
		setModalOpen(true);
		setIsEditModal(true);
		setEditFaqIndex(index);
		setNewFaq({
			question: data?.faq_data[index]?.question || '',
			answer: data?.faq_data[index]?.answer || '',
		});
	};

	const handleDeleteFaq = (index) => {
		setData((prevData) => {
			const newData = { ...prevData };
			newData.faq_data.splice(index, 1);
			return newData;
		});
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const handleInputChangeFaq = (field, value) => {
		setNewFaq((prevFaq) => ({
			...prevFaq,
			[field]: value,
		}));
	};

	const handleModalSave = () => {
		if (isEditModal) {
			setData((prevData) => {
				const newData = { ...prevData };
				newData.faq_data[editFaqIndex] = {
					question: newFaq.question,
					answer: newFaq.answer,
				};
				return newData;
			});
		} else {
			setData((prevData) => {
				const newData = { ...prevData };
				newData.faq_data.push({
					question: newFaq.question,
					answer: newFaq.answer,
				});
				return newData;
			});
		}

		setModalOpen(false);
	};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newItem, setNewItem] = useState({
		label: '',
		value: '',
	});

	const [editItemIndex, setEditItemIndex] = useState(-1);
	const openWhyULearnModal = (index) => {
		setIsEditModal(true);
		setEditItemIndex(index);

		if (data && Array.isArray(data.what_you_will_learn) && index >= 0 && index < data.what_you_will_learn.length) {
			setNewItem({
				label: data.what_you_will_learn[index].label || '',
				value: data.what_you_will_learn[index].value || '',
			});
		} else {
			setNewItem({
				label: '',
				value: '',
			});
		}

		setIsModalOpen(true);
	};


	const closeWhyULearnModal = () => {
		setIsModalOpen(false);
		setNewItem({ label: '', value: '' });
	};

	const addOrUpdateWhyULearnItem = () => {
		if (isEditModal && editItemIndex !== -1) {
			setData((prevData) => {
				const newData = { ...prevData };
				newData.what_you_will_learn[editItemIndex] = {
					label: newItem.label,
					value: newItem.value,
				};
				return newData;
			});
		} else {
			setData((prevData) => {
				const newData = { ...prevData };
				const newItemToAdd = {
					label: newItem.label,
					value: newItem.value,
					id: prevData.what_you_will_learn.length + 1, // Generate a unique ID
				};
				newData.what_you_will_learn.push(newItemToAdd);
				return newData;
			});
		}

		closeWhyULearnModal();
	};



	const deleteWhyULearnItem = (index) => {
		setData((prevData) => {
			const newData = { ...prevData };
			newData.what_you_will_learn.splice(index, 1);
			return newData;
		});
	};
	const UpdateCourseDetails = () => {
		dispatch(updateMentorCourseAction(data))
	}
	const updateMentorCourseReducer = useSelector(
		(state) => state.updateMentorCourseReducer
	)
	useEffect(() => {
		if (updateMentorCourseReducer) {
			setloader(updateMentorCourseReducer?.loading)
			setData(updateMentorCourseReducer?.data)
			setStatus(updateMentorCourseReducer?.status)
			setError(updateMentorCourseReducer?.error)
		}
	}, [updateMentorCourseReducer]);
	const convertUTCtoLocalTime = (utcTime) => {
		const utcDate = new Date(utcTime);
		const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);

		const year = localDate.getUTCFullYear();
		const month = String(localDate.getUTCMonth() + 1).padStart(2, '0');
		const day = String(localDate.getUTCDate()).padStart(2, '0');
		const hours = String(localDate.getUTCHours()).padStart(2, '0');
		const minutes = String(localDate.getUTCMinutes()).padStart(2, '0');

		return `${year}-${month}-${day}T${hours}:${minutes}`;
	}

	// hosted by
	const [hostedByModalOpen, setHostedByModalOpen] = useState(false);
	const [newHostedBy, setNewHostedBy] = useState({
		name: '',
		imageUrl: '',
		jobTitle: '',
		linked_in: '',
		description: '',
	});

	const [isEditHostedBy, setIsEditHostedBy] = useState(false);
	const [editHostedByIndex, setEditHostedByIndex] = useState(-1);

	const handleAddNewHostedBy = () => {
		setHostedByModalOpen(true);
		setIsEditHostedBy(false);
		setNewHostedBy({
			name: '',
			image_url: '',
			title: '',
			linked_in: '',
			description: '',
		});
	};

	const handleEditHostedBy = (index) => {
		setHostedByModalOpen(true);
		setIsEditHostedBy(true);
		setEditHostedByIndex(index);
		setNewHostedBy(data?.course_hosted_by[index] || {});
	};

	const handleDeleteHostedBy = (index) => {
		setData((prevData) => {
			const newData = { ...prevData };
			newData.course_hosted_by.splice(index, 1);
			return newData;
		});
	};

	const handleInputChangeHostedBy = (field, value) => {
		setNewHostedBy((prevHostedBy) => ({
			...prevHostedBy,
			[field]: value,
		}));
	};

	const handleHostedByModalSave = () => {
		if (isEditHostedBy) {
			setData((prevData) => {
				const newData = { ...prevData };
				newData.course_hosted_by[editHostedByIndex] = newHostedBy;
				return newData;
			});
		} else {
			setData((prevData) => {
				const newData = { ...prevData };
				newData.course_hosted_by.push(newHostedBy);
				return newData;
			});
		}

		setHostedByModalOpen(false);
	};
	const uploadHostedByProfile = (val, pre) => {
		console.log("uploadbanner called", val);

		setNewHostedBy((pre) => ({
			...pre,
			image_url: val,
		}));
	};
	const uploadBanner = (val, pre) => {
		console.log("uploadbanner called", val);

		setData((pre) => ({
			...pre,
			course_banner: val,
		}));
	};

	return (
		<>
			{
				loader ? (<div className="loader open_sessions_loader">
					<TailSpin ariaLabel="Loading..." color="#00BFFF" />
				</div>) : (null)
			}
			<div className="w-full p-10" style={{}}>
				<form
					id="course-create-form"
					className="w-full"
					style={{ padding: "0px !important" }}
				>

					<div className="form-group">
						<label className="course__label">
							Title *
						</label>
						<input
							type="text"
							name=""
							value={data?.course_topic}
							placeholder={`Enter ${data?.document_type} Title`}
							className="form-control"
							onChange={(e) =>
								setData((pre) => ({
									...pre,
									course_topic: e.target.value,
								}))
							}
							required
						/>
					</div>
					<UploadImage
						callback={uploadBanner}
						fileName="_course-banner"
						imagePath={"courseImage/courseBanner/"}
						filetype={["image"]}
					/>
					<div className="form-group">
						<label className="course__label">
							{data?.document_type} Meta Description *
						</label>
						<textarea value={data?.course_description} name="postContent" rows={40}
							style={{ height: "10rem" }}
							placeholder={`Enter ${data?.document_type}  Meta Description`}

							className="form-control" id="exampleFormControlTextarea1"
							onChange={(e) =>
								setData((pre) => ({
									...pre,
									course_description: e.target.value,
								}))
							} />

					</div>

					<div className="form-group">
						<label className="course__label">
							{data?.document_type} Intro *
						</label>
						<textarea value={data?.course_intro} name="postContent" rows={40}
							style={{ height: "10rem" }}
							placeholder={`Enter ${data?.document_type} Intro`}

							className="form-control" id="exampleFormControlTextarea1"
							onChange={(e) =>
								setData((pre) => ({
									...pre,
									course_intro: e.target.value,
								}))
							} />
					</div>

					<div className="form-group">
						<label className="course__label">
							Language *
						</label>
						<input
							type="text"
							name=""
							placeholder="language mode"
							className="form-control"
							value={data?.language}
							onChange={(e) =>
								setData((pre) => ({
									...pre,
									language: e.target.value,
								}))
							}
							required
						/>
					</div>

					<div className="row">
						<div className="col"><div className="form-group">
							<label className="course__label">
								Maximum Seat *
							</label>
							<input
								type="number"
								placeholder="Maximum Seat"
								className="form-control"
								value={data?.course_seat}
								onChange={(e) =>
									setData((pre) => ({
										...pre,
										course_seat: e.target.value,
									}))
								}
								required
							/>
						</div></div>
						<div className="col">
							<div className="form-group">
								<label className="course__label">{data?.document_type} Type *</label>
								<select className="form-control"
									value={data?.course_type}
									onChange={(e) =>
										setData((pre) => ({
											...pre,
											course_type: e.target.value,
										}))
									}
								>
									<option disabled selected>Select {data?.document_type} Type</option>
									{expertise?.map((expertise, index) => (
										<option key={index} value={expertise.value}>
											{expertise.label}
										</option>
									))}
								</select>
							</div>
						</div>

					</div>
					<div className="row">
						<div className="col">
							<div className="form-group">
								<label htmlFor="" className='course__label'>{data?.document_type} Start From*</label>
								<input
									type="datetime-local"

									value={convertUTCtoLocalTime(data?.course_start_date)}

									placeholder=""
									className="form-control"
									// onChange={handleDateChange}
									onChange={(e) =>
										setData((pre) => ({
											...pre,
											course_start_date: convertLocalTimeToUTC(e.target.value),
										}))
									}
									required
								/>
							</div>
						</div>
						<div className="col">
							<div className="form-group">
								<label htmlFor="" className='course__label'>{data?.document_type} Time Period(in week)*</label>
								<input
									type="number"
									value={data?.course_time_period}

									placeholder=""
									className="form-control"
									onChange={(e) =>
										setData((pre) => ({
											...pre,
											course_time_period: e.target.value,
										}))
									}
									required
								/>
							</div>
						</div>
					</div>

				</form>

				{/* what will you */}

				<div className="mb-5">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<h4 className="fw-bold fs-4 text-dark">What you will learn</h4>
						<div className="d-flex align-items-center gap-2">
							<button
								type="button"
								className="btn btn-success certificate_savebtn"
								onClick={() => openWhyULearnModal()}
							>
								Add New
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr className="bg-secondary text-white">
								<th className="w-15%">ICON</th>
								<th className="w-20%">TITLE</th>
								<th className="w-50%">DESCRIPTION</th>
								<th></th>
							</tr>
						</thead>
					</table>
					{data?.what_you_will_learn && data?.what_you_will_learn.length > 0 ? (
						<>
							<table className="table">
								<tbody>
									{data?.what_you_will_learn?.map((outcome, index) => (
										<tr key={index}>
											<td className="w-15%">
												<span className="text-primary">&#xe0b3;</span>
											</td>
											<td className="w-20%">
												<div className="d-flex flex-column">
													<p className="font-weight-bold">{outcome.label}</p>
												</div>
											</td>
											<td className="w-50%">
												<p className="font-weight-normal">{outcome.value}</p>
											</td>
											<td>
												<div className="d-flex">
													<div className="d-flex">
														<span onClick={() => openWhyULearnModal(index)}>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width={16}
																height={16}
																fill="currentColor"
																className="bi bi-pencil-fill"
																viewBox="0 0 16 16"

																style={{ cursor: 'pointer' }}
															>
																<path
																	d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
																/>
															</svg>
														</span>
														{'  '}
														<span
															onClick={() => deleteWhyULearnItem(index)}
															style={{ cursor: 'pointer' }}
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width={16}
																height={16}
																fill="currentColor"
																className="bi bi-trash3"
																viewBox="0 0 16 16"
															>
																<path
																	d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
																/>
															</svg>
														</span>
													</div>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</>
					) : (
						<>
							<div className="d-flex flex-column mt-4 align-items-center justify-content-center">
								<div
									role="button"
									className="cursor-pointer bg-primary rounded-circle d-flex align-items-center justify-content-center mb-2"
									style={{ width: 48, height: 48 }}
									onClick={() => openWhyULearnModal()}
								>
									<svg
										aria-hidden="true"
										focusable="false"
										data-prefix="fal"
										data-icon="plus"
										className="svg-inline--fa fa-plus text-light"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 448 512"
										style={{ width: 24, height: 24 }}
									>
										<path
											fill="currentColor"
											d="M432 256C432 264.8 424.8 272 416 272H240V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z"
										/>
									</svg>
								</div>
								<p className="text-body font-weight-normal fs-5 text-dark">
									Add Item
								</p>
							</div>
						</>
					)}

					{/* Modal */}
					<div
						className={`modal fade ${isModalOpen ? 'show' : ''}`}
						tabIndex="-1"
						role="dialog"
						style={{ display: isModalOpen ? 'block' : 'none' }}
					>
						<div className="modal-dialog bg-white" role="document">
							<div className="modal-content bg-white">
								<div className="modal-header">
									<h5 className="modal-title">
										{isEditModal ? 'Edit Item' : 'Add New Item'}
									</h5>
									<button
										type="button"
										className="btn-close"
										onClick={closeWhyULearnModal}
									></button>
								</div>
								<div className="modal-body">
									<div className="mb-3">
										<label htmlFor="title" className="form-label">
											Title
										</label>
										<input
											type="text"
											className="form-control"
											id="title"
											value={newItem.label}
											onChange={(e) =>
												setNewItem({ ...newItem, label: e.target.value })
											}
										/>
									</div>
									<div className="mb-3">
										<label htmlFor="description" className="form-label">
											Description
										</label>
										<textarea
											className="form-control"
											id="description"
											value={newItem.value}
											onChange={(e) =>
												setNewItem({ ...newItem, value: e.target.value })
											}
										></textarea>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-success certificate_savebtn"
										onClick={closeWhyULearnModal}
									>
										Close
									</button>
									<button
										type="button"
										className="btn btn-success certificate_savebtn"
										onClick={addOrUpdateWhyULearnItem}
									>
										{isEditModal ? 'Save' : 'Add Item'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mb-5 mt-4">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<label className="fw-bold fs-4 text-dark">Key Highlights</label>
						<div className="d-flex align-items-center gap-2">
							<button type="button" className="btn btn-success certificate_savebtn" onClick={handleAddNew}>Add New</button>

						</div>
					</div>
					<div className="d-flex flex-column gap-3">
						{

							data?.highlights && data?.highlights.length > 0 ?
								(
									data?.highlights.map((highlight, index) => (
										<div className="d-flex gap-2 align-items-center" key={`highlight-${index + 1}`}>
											<img
												src="https://dz8fbjd9gwp2s.cloudfront.net/t/static/images/highlight-bullet_7d5b93dfc6ae00313bad.png"
												className="w-5 h-5"
												alt="highlight"
											/>
											<input
												className="form-control"
												placeholder={`Enter highlight ${index + 1}`}
												id={`highlight-${index + 1}`}
												name={`highlight-${index + 1}`}
												type="text"
												value={highlight?.label}
												onChange={(e) => handleInputChange(index, e.target.value)} // Call handleInputChange when the input value changes
											/>
										</div>
									))
								) : (
									<>
										<div className="d-flex flex-column mt-4 align-items-center justify-content-center">
											<div

												role="button"
												className="cursor-pointer bg-primary rounded-circle d-flex align-items-center justify-content-center mb-2"
												style={{ width: 48, height: 48 }}
											>
												<svg
													onClick={handleAddNew}
													aria-hidden="true"
													focusable="false"
													data-prefix="fal"
													data-icon="plus"
													className="svg-inline--fa fa-plus text-light"
													role="img"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 448 512"
													style={{ width: 24, height: 24 }}
												>
													<path
														fill="currentColor"
														d="M432 256C432 264.8 424.8 272 416 272H240V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z"
													/>
												</svg>
											</div>
											<p className="text-body font-weight-normal fs-5 text-dark">
												Add some highlights for your course page
											</p>
										</div>
									</>
								)
						}
					</div>
				</div>
				<div className="mb-5">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<label className="fw-bold fs-4 text-dark">Frequently Asked Questions</label>
						<div className="d-flex align-items-center gap-2">
							<button
								type="button"
								className="btn btn-success certificate_savebtn"
								onClick={handleAddNewFaq}
							>
								Add New
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr className="bg-light">
								<th className="py-4 pl-4 w-20%">
									<p className="text-dark font-weight-bold">QUESTION</p>
								</th>
								<th className="py-4 w-50%">
									<p className="text-dark font-weight-bold">ANSWER</p>
								</th>
								<th className="py-4 w-5%"></th>
							</tr>
						</thead>
						<tbody>
							{data?.faq_data && data?.faq_data.length > 0 ? (
								<>
									{data?.faq_data.map((faq, index) => (
										<tr
											key={index}
											className={`border-bottom ${index === data?.faq_data.length - 1 ? 'last:border-none' : ''
												}`}
										>
											<td className="py-3 pl-4">
												<p className="text-dark font-weight-medium">{faq.question}</p>
											</td>
											<td className="py-3">
												<p className="text-dark">{faq.answer}</p>
											</td>
											<td className="py-3">
												<div className="d-flex">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width={16}
														height={16}
														fill="currentColor"
														className="bi bi-pencil-fill"
														viewBox="0 0 16 16"
														onClick={() => handleEditFaq(index)}
														style={{ cursor: 'pointer' }}
													>
														<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
													</svg>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width={16}
														height={16}
														fill="currentColor"
														className="bi bi-trash3"
														viewBox="0 0 16 16"
														onClick={() => handleDeleteFaq(index)}
														style={{ cursor: 'pointer' }}
													>
														<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
													</svg>
												</div>
											</td>
										</tr>
									))}
								</>
							) : (
								<div className="d-flex flex-column mt-4 align-items-center justify-content-center">
									<div
										onClick={handleAddNewFaq}
										role="button"
										className="cursor-pointer bg-primary rounded-circle d-flex align-items-center justify-content-center mb-2"
										style={{ width: 48, height: 48 }}
									>
										<svg
											aria-hidden="true"
											focusable="false"
											data-prefix="fal"
											data-icon="plus"
											className="svg-inline--fa fa-plus text-light"
											role="img"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 448 512"
											style={{ width: 24, height: 24 }}
										>
											<path
												fill="currentColor"
												d="M432 256C432 264.8 424.8 272 416 272H240V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z"
											/>
										</svg>
									</div>
									<p className="text-body font-weight-normal fs-5 text-dark">
										Add frequently asked questions for your course page
									</p>
								</div>
							)}
						</tbody>
					</table>

					{/* Modal for adding/editing FAQ */}
					<div
						className={`modal fade ${modalOpen ? 'show' : ''}`}
						tabIndex="-1"
						role="dialog"
						style={{ display: modalOpen ? 'block' : 'none' }}
					>
						<div className="modal-dialog bg-white" role="document">
							<div className="modal-content bg-white">
								<div className="modal-header">
									<h5 className="modal-title">
										{isEditModal ? 'Edit FAQ' : 'Add New FAQ'}
									</h5>
									<button
										type="button"
										className="btn-close"
										onClick={handleModalClose}
									></button>
								</div>
								<div className="modal-body">
									<div className="mb-3">
										<label htmlFor="question" className="form-label">
											Question
										</label>
										<input
											type="text"
											className="form-control"
											id="question"
											value={newFaq.question}
											onChange={(e) => handleInputChangeFaq('question', e.target.value)}
										/>
									</div>
									<div className="mb-3">
										<label htmlFor="answer" className="form-label">
											Answer
										</label>
										<input
											type="text"
											className="form-control"
											id="answer"
											value={newFaq.answer}
											onChange={(e) => handleInputChangeFaq('answer', e.target.value)}
										/>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-success certificate_savebtn"
										onClick={handleModalClose}
									>
										Close
									</button>
									<button
										type="button"
										className="btn btn-success certificate_savebtn"
										onClick={handleModalSave}
									>
										{isEditModal ? 'Save' : 'Add FAQ'}
									</button>
								</div>
							</div>
						</div>
					</div>
					{/* End of Modal */}
				</div>




				{/* cours hsoted by */}
				<div className="mb-5">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<h4 className="fw-bold fs-4 text-dark">Hosted By</h4>
						<div className="d-flex align-items-center gap-2">
							<button
								type="button"
								className="btn btn-success"
								onClick={handleAddNewHostedBy}
							>
								Add New
							</button>
						</div>
					</div>

					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Image URL</th>
								<th>Job Title</th>
								<th>LinkedIn</th>
								<th>Description</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{data && data.course_hosted_by && Array.isArray(data.course_hosted_by) && data.course_hosted_by.length > 0 ? (
								<>
									{data.course_hosted_by.map((item, index) => (
										<tr key={index}>
											<td>{item.name}</td>
											<td><div className="Talent__UserInfo ">
												<a className=" Talent__StyledUserPreview  ">
													<img
														src={
															item?.image_url && item?.image_url !== ''
																? `${process.env.REACT_APP_IMAGES_BASE_URL}${item?.image_url}`
																: `${maleAvatar}`
														}
														alt={item?.name}
													/>
												</a>
											</div>

											</td>
											<td>{item.title}</td>
											<td>{item.linked_in}</td>
											<td>{item.description}</td>
											<td>
												<div className="d-flex">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width={16}
														height={16}
														fill="currentColor"
														className="bi bi-pencil-fill"
														viewBox="0 0 16 16"
														onClick={() => handleEditHostedBy(index)}
														style={{ cursor: 'pointer' }}
													>
														<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
													</svg>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width={16}
														height={16}
														fill="currentColor"
														className="bi bi-trash3"
														viewBox="0 0 16 16"
														onClick={() => handleDeleteHostedBy(index)}
														style={{ cursor: 'pointer' }}
													>
														<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
													</svg>
												</div>
											</td>
										</tr>
									))}
								</>


							) : (
								<div className="d-flex flex-column mt-4 align-items-center justify-content-center">
									<div
										onClick={handleAddNewHostedBy}
										role="button"
										className="cursor-pointer bg-primary rounded-circle d-flex align-items-center justify-content-center mb-2"
										style={{ width: 48, height: 48 }}
									>
										<svg
											aria-hidden="true"
											focusable="false"
											data-prefix="fal"
											data-icon="plus"
											className="svg-inline--fa fa-plus text-light"
											role="img"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 448 512"
											style={{ width: 24, height: 24 }}
										>
											<path
												fill="currentColor"
												d="M432 256C432 264.8 424.8 272 416 272H240V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z"
											/>
										</svg>
									</div>
									<p className="text-body font-weight-normal fs-5 text-dark">
										Add course hosted by
									</p>
								</div>
							)}
						</tbody>
					</table>

				</div>


				{
					/* Modal for adding/editing Hosted By */
				}
				<div
					className={`modal fade ${hostedByModalOpen ? "show" : ""}`}
					tabIndex="-1"
					role="dialog"
					style={{ display: hostedByModalOpen ? "block" : "none" }}
				>
					<div className="modal-dialog bg-white" role="document">
						<div className="modal-content bg-white">
							<div className="modal-header">
								<h5 className="modal-title">
									{isEditHostedBy ? "Edit Hosted By" : "Add New Hosted By"}
								</h5>
								<button
									type="button"
									className="btn-close"
									onClick={() => setHostedByModalOpen(false)}
								></button>
							</div>
							<div className="modal-body">
								<div className="mb-3">
									<label htmlFor="name" className="form-label">
										Name
									</label>
									<input
										type="text"
										className="form-control"
										id="name"
										value={newHostedBy.name}
										onChange={(e) => handleInputChangeHostedBy("name", e.target.value)}
									/>
								</div>
								<div className="mb-3">

									<UploadImage
										callback={uploadHostedByProfile}
										fileName="hosted-image"
										imagePath={"courseImage/courseHostedBy/"}
										filetype={["image"]}
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="jobTitle" className="form-label">
										Job Title
									</label>
									<input
										type="text"
										className="form-control"
										id="jobTitle"
										value={newHostedBy.title}
										onChange={(e) =>
											handleInputChangeHostedBy("title", e.target.value)
										}
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="linked_in" className="form-label">
										LinkedIn
									</label>
									<input
										type="text"
										className="form-control"
										id="linked_in"
										value={newHostedBy.linked_in}
										onChange={(e) =>
											handleInputChangeHostedBy("linked_in", e.target.value)
										}
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="description" className="form-label">
										Description
									</label>
									<textarea
										className="form-control"
										id="description"
										value={newHostedBy.description}
										onChange={(e) =>
											handleInputChangeHostedBy("description", e.target.value)
										}
									/>
								</div>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-success certificate_savebtn"
									onClick={() => setHostedByModalOpen(false)}
								>
									Close
								</button>
								<button
									type="button"
									className="btn btn-success certificate_savebtn"
									onClick={handleHostedByModalSave}
								>
									{isEditHostedBy ? "Save Changes" : "Add Hosted By"}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="get-started-cls why_choose_ment">
					<button
						onClick={UpdateCourseDetails}
						type="button"
						className="btn btn-primary"
					>
						Update {data?.document_type} Details
					</button>
				</div>
			</div >
			{detailModal?.modal && (
				<detailModal.modal
					detailModal={detailModal}
					setDetailModal={setDetailModal}
				/>
			)}
		</>
	);
}

export default Details;
