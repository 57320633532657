import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Row,
	Skeleton,
	Space,
	TimePicker,
	Typography,
	message,
} from "antd";
import {
	LuCalendar,
	LuTv,
} from "react-icons/lu";
import { AddServiceConfig, ServiceTemplateData, currencyName, getLinkWithPretag, getPricingOptions, getServiceType, isMobile } from '../../Data/Data';
import WebinarMeetingLink from './WebinarMeetingLink';
import MultipleSessionsModal from './MultipleSessionsModal/MultipleSessionsModal';
import { FiArrowLeft } from 'react-icons/fi';
import ServiceTemplates from './../PropsComponent/serviceTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { createProductAction } from '../../../../redux/actions/mentor';
import { REACT_USER_ID } from '../../../auth/tokenProvider';
import { TailSpin } from 'react-loader-spinner';
const durationList = ["15", "30", "45", "60"];

const { Title, Text, Paragraph } = Typography;
const AddNewService = () => {
	const location = useLocation();
	const [loader, setloader] = useState(false);

	const queryParams = new URLSearchParams(location.search);
	const currentTab = queryParams.get('currentTab');
	const templateId = queryParams.get('templateId');

	const GET_SERVICE_ID = {
		video: 1,
		text: 2,
		webinar: 3,
		package: 4,
		document: 5,
		subscriptions: 6,
	};
	const [form] = Form.useForm();
	const navigate = useNavigate()

	const [serviceType, setServiceType] = useState(
		GET_SERVICE_ID[String(currentTab)] || 1
	);
	const [submitting, setSubmitting] = useState(false);
	const [cumulativePrice] = useState(0);
	const [seeTemplates, setSeeTemplates] = useState(false);
	const [webinarLinkError, setWebinarLinkError] = useState(false);

	const [multipleSessionsModal, setMultipleSessionsModal] =
		useState(false);

	const disabledDate = (current) => {
		const today = new Date();
		const thirtyDaysFromNow = new Date();
		thirtyDaysFromNow.setDate(today.getDate() + 30);

		return current && (current < today || current > thirtyDaysFromNow);
	};


	const serviceInitial = templateId
		? ServiceTemplateData?.filter((service) => service?.id == templateId)?.[0]
		: {};

	const [initialValues, setInitialValues] = useState(
		templateId
			? {
				...serviceInitial,
				group_call_url: "",
				duration_select: String(serviceInitial?.duration) || durationList[1],
				custom_duration_select: undefined,
				charge: serviceInitial?.charge || 0,
				charge_select:
					String(serviceInitial?.charge) ||
					getPricingOptions(currencyName, serviceType)[0],
				custom_charge_select: undefined,
				date: undefined,
				time: undefined,
			}
			: {
				type: serviceType || undefined,
				duration_select: durationList[1],
				custom_duration_select: undefined,
				charge: 0,
				charge_select: getPricingOptions(
					currencyName,
					serviceType
				)[0],
				custom_charge_select: undefined,
				date: undefined,
				time: undefined,
				group_call_url: "",
			}
	);


	const dispatch = useDispatch()

	const addNewService = async (payload, values) => {
		const postObj = templateId ? { ...initialValues, ...payload } : payload;
		dispatch(createProductAction({ ...postObj, "mentor_id": REACT_USER_ID }))
		setSubmitting(false)
	};

	const createProductReducer = useSelector((state) => state?.createProductReducer)
	useEffect(() => {
		setloader(createProductReducer?.loading)
		if (!createProductReducer?.loading && createProductReducer?.data && createProductReducer?.error === undefined) {
			message.success("Service created successfully!");
			navigate(`/dashboard/service/edit/id=${createProductReducer?.data?.product_id}`)
			setTimeout(() => {
				dispatch(createProductAction(null))
			}, 2000);
		}
		if (!createProductReducer?.loading && createProductReducer?.data === undefined && createProductReducer?.error !== undefined) {

			message("something went wrong!")

		}


	}, [createProductReducer])



	const handleSubmit = (values) => {
		if (form?.getFieldValue("group_call_url") == "" && serviceType == 3) {
			setWebinarLinkError(true);
		} else {
			const groupCallDateTime =
				values?.date && values?.time
					? values?.date?.format("YYYY-MM-DD") +
					"T" +
					values?.time?.format("HH:mm:ss")
					: null;
			setSubmitting(true);
			if (!values.title) {
				setSubmitting(false);
			} else {
				const payload = {
					...values,
					"language_mode": ["hindi", "english"],
					course_topic: values?.title,
					document_type: getServiceType(serviceType),
					duration: serviceType == 2 ? "60" : values?.duration,
					type: serviceType === 6 ? 4 : serviceType,
					charge: [4, 6].includes(serviceType)
						? cumulativePrice
						: values?.charge,

					group_call_url:
						form?.getFieldValue("group_call_url")?.length > 0
							? getLinkWithPretag(form?.getFieldValue("group_call_url"))
							: form?.getFieldValue("group_call_url") || "",

					cohort_no_of_sessions:
						form?.getFieldValue("cohort_no_of_sessions") || 1,

					cohort_weekdays: form?.getFieldValue("cohort_weekdays"),

					cohort_noun: form?.getFieldValue("cohort_noun"),
				};
				if (groupCallDateTime) {
					payload["specific_date"] = groupCallDateTime;
				}
				if (serviceType !== 6) {
					payload["suggested_price"] = payload["charge"]
						? Math.floor(payload["charge"] * 1.25)
						: currencyName == "INR"
							? 200
							: 20;
				}

				if ([4, 6].includes(serviceType)) {
					setSubmitting(false);
				} else {
					addNewService(payload, values);
				}
			}
		}
	};

	const onServiceSelect = (value) => {
		setServiceType(value);
	};
	const layouts = {
		title: "Add New Service",
		backLink: true,
		mobileBackLink: true,
		tabsData: [],
		titleCTA: null,
	};

	return (
		<>
			{
				loader ? (<div className="loader open_sessions_loader">
					<TailSpin ariaLabel="Loading..." color="#00BFFF" />
				</div>) : (null)
			}

			<Col
				xs={{ span: 24, offset: 0 }}
				md={{ span: 24, offset: 0 }}
				className="internal-page-layout"
			>

				<Row
					className={`${"container-title-section sticky"

						}`}
				>
					{/* Title */}
					<Row>

						<Col
							xs={{ span: 22, offset: 1 }}
							md={{ span: 14, offset: 1 }}
							xl={{ span: 12, offset: 0 }}
							xxl={{ span: 12, offset: 0 }}
							className={`header-section }`}
						>
							<Title level={4} className="layout-header">
								{/* Back Link */}
								{layouts?.backLink && (
									<FiArrowLeft
										className="back-btn"
										style={{marginLeft:"0"}}
										onClick={() => {
											navigate('/dashboard/services/video')
										}}

									/>
								)}
								<Paragraph
									ellipsis={{
										rows: 1,
										expandable: false,
									}}
								>
									{layouts?.title !== undefined ? layouts?.title : <Skeleton />}
								</Paragraph>
							</Title>
							{layouts?.titleCTA?.title &&
								isMobile &&
								layouts?.titleCTA?.hideOnMobile && (
									<Button
										className="tab-cta-link tab-link-common btn-dark"
										onClick={layouts?.titleCTA?.onClick}
									>
										{layouts?.titleCTA?.icon && layouts?.titleCTA?.icon}
										<Text className="tab-title">{layouts?.titleCTA?.title}</Text>
									</Button>
								)}
						</Col>
					</Row>
				</Row>

				<Row
					className="container-children px-5"
				>   <Col xs={{ span: 24 }} lg={{ span: 12 }}>
						<Row
							justify="space-between"
							align="middle"
							style={{ marginBottom: "12px" }}
						>
							<Col>
								<Paragraph className="select-type" style={{ marginBottom: 12 }}>
									Select type
								</Paragraph>
							</Col>
							<Col >
								<Button
									onClick={() => {
										setSeeTemplates(true);
									}}
									className="template-btn"
								>
									Use Template
								</Button>
							</Col>
						</Row>

						<Col xs={{ span: 24 }} md={{ span: 24 }}>
							<Row gutter={[8, 8]}>
								<Col xs={12} md={8}>
									{/* Video Call */}
									<Paragraph
										className={
											serviceType === 1
												? "query-button-type query-button-type-selected"
												: "query-button-type"
										}
										onClick={() => {
											onServiceSelect(1);
										}}
									>
										<Space direction="vertical" align="start">
											<LuCalendar className="img-style" size={24} />
											<Paragraph className="query-button-text">
												1:1 Call
											</Paragraph>
											<Paragraph className="query-button-subtext">
												Share your time over a meeting
											</Paragraph>
										</Space>
									</Paragraph>
								</Col>

								{/* Query */}
								{/* <Col xs={12} md={8}>
									<Paragraph
										className={
											serviceType === 2
												? "query-button-type query-button-type-selected"
												: "query-button-type"
										}
										onClick={() => {
											onServiceSelect(2);
										}}
									>
										<Space direction="vertical" align="start">
											<LuMail className="img-style" size={24} />
											<Paragraph className="query-button-text">
												Priority DM
											</Paragraph>
											<Paragraph className="query-button-subtext">
												Answer a question
											</Paragraph>
										</Space>
									</Paragraph>
								</Col> */}

								<Col xs={12} md={8}>
									{/* Webinar */}
									<Paragraph
										className={
											serviceType === 3
												? "query-button-type query-button-type-selected"
												: "query-button-type"
										}
										onClick={() => {
											onServiceSelect(3);
										}}
									>
										<Space direction="vertical" align="start">
											<LuTv className="img-style" size={24} />
											<Paragraph className="query-button-text">Webinar</Paragraph>
											<Paragraph className="query-button-subtext">
												Host a sessions for the masses
											</Paragraph>
										</Space>
									</Paragraph>
								</Col>

								{/* Document */}
								{/* <Col xs={12} md={8}>
									<Paragraph
										className={
											serviceType === 5
												? "query-button-type query-button-type-selected"
												: "query-button-type"
										}
										onClick={() => {
											onServiceSelect(5);
										}}
									>
										<Space direction="vertical" align="start">
											<LuShoppingBag className="img-style" size={24} />
											<Paragraph className="query-button-text">
												Digital Product
											</Paragraph>
											<Paragraph className="query-button-subtext">
												Sell digital products
											</Paragraph>
										</Space>
									</Paragraph>
								</Col> */}

								{/* Package */}
								{/* <Col xs={12} md={8}>
									<Paragraph
										className={
											serviceType === 4
												? "query-button-type query-button-type-selected"
												: "query-button-type"
										}
										onClick={() => {
											onServiceSelect(4);
										}}
									>
										<Space direction="vertical" align="start" size={6}>
											<LuArchive className="img-style" size={24} />
											<Paragraph className="query-button-text">Package</Paragraph>
											<Paragraph className="query-button-subtext">
												Offer multiple services together
											</Paragraph>
										</Space>
									</Paragraph>
								</Col> */}

								{/* Subscription */}
								{/* <Col xs={12} md={8}>
									<Paragraph
										className={
											serviceType === 6
												? "query-button-type query-button-type-selected"
												: "query-button-type"
										}
										onClick={() => {
											onServiceSelect(6);
										}}
									>
										<Space direction="vertical" align="start" size={6}>
											<HiOutlineBellAlert className="img-style" size={24} />
											<Paragraph className="query-button-text">
												Subscription
											</Paragraph>
											<Paragraph className="query-button-subtext">
												Sell services as a monthly subscription
											</Paragraph>
										</Space>
									</Paragraph>
								</Col> */}
							</Row>

							{serviceType !== 0 && (
								<Form
									name="ServiceForm"
									form={form}
									colon={false}
									layout="vertical"
									labelAlign="left"
									className="add-service-form"
									initialValues={initialValues}
									onFinish={handleSubmit}
									requiredMark={false}
									scrollToFirstError={{
										behavior: (actions) =>
											actions.forEach(({ el, top }) => {
												el.scrollTop = top - 90;
											}),
										scrollMode: "if-needed",
									}}
								>
									{/* Service Name */}
									<Form.Item
										// title
										className="fields"
										label={AddServiceConfig?.title?.label}
										name={AddServiceConfig?.title?.name}
										rules={[...AddServiceConfig.title.rules]}
										style={{ marginTop: "32px" }}
									>
										<Input placeholder={AddServiceConfig?.title?.placeholder} />
									</Form.Item>

									{/* Service Duration */}
									{[1, 3].includes(serviceType) && (
										<Form.Item
											// duration
											label={AddServiceConfig?.custom_call_duration?.label}
											name={AddServiceConfig?.custom_call_duration?.name}
											rules={AddServiceConfig?.custom_call_duration?.rules}
											className="fields"
											required
										>
											<InputNumber
												min={5}
												style={{ width: "100%" }}
												className="service-input text-base"
												step={15}
											/>
										</Form.Item>
									)}

									{/* Webinar Only */}
									{serviceType === 3 && (
										<Row gutter={{ xs: 0, md: 12 }}>
											{/* Webinar: Date */}
											<Col xs={24} md={12}>
												<Form.Item {...AddServiceConfig?.date} className="fields">
													<DatePicker
														inputReadOnly
														// date
														placeholder={AddServiceConfig?.date?.placeholder}
														className="full-width"
														disabled={initialValues?.date}
														disabledDate={disabledDate}
														onKeyDown={(event) => {
															event.preventDefault();
														}}
													/>
												</Form.Item>
											</Col>
											{/* Webinar: Time */}
											<Col xs={24} md={12}>
												<Form.Item {...AddServiceConfig?.time} className="fields">
													<TimePicker
														// time
														inputReadOnly
														placeholder={AddServiceConfig?.time?.placeholder}
														className="full-width"
														minuteStep={15}
														showNow={false}
														use12Hours
														format="hh:mm A"
													/>
												</Form.Item>
											</Col>
											{/* Cohort: Multiple Sessions */}
											<Col xs={24} md={24} style={{ marginBottom: "12px" }}>
												<Button
													className="btn-gray"
													onClick={() => {
														setMultipleSessionsModal(true);
													}}
												>
													{form?.getFieldValue("cohort_no_of_sessions") > 1
														? `+ ${form?.getFieldValue(
															"cohort_no_of_sessions"
														)} sessions`
														: `+ Create multiple sessions`}
												</Button>
											</Col>
										</Row>
									)}

									{/* Service Price */}
									{serviceType !== 4 && serviceType !== 6 && (
										<Form.Item
											// charge
											label={`Amount (${currencyName})`}
											name={AddServiceConfig?.price?.name}
											rules={AddServiceConfig?.price?.rules}
											className="fields"
											required
										>
											<InputNumber
												min={0}
												style={{ width: "100%" }}
												className="service-input text-base"
												step={5}
												addonBefore={currencyName}
											/>
										</Form.Item>
									)}
									{serviceType === 3 && (
										<Row style={{ marginBottom: "24px" }}>
											<Col span={24} style={{ marginBottom: "8px" }}>
												<Text className="fields">Hosting options</Text>
											</Col>
											<WebinarMeetingLink
												initialValues={initialValues}
												ServiceEditForm={form}
												showError={webinarLinkError}
												setShowError={setWebinarLinkError}
											/>
										</Row>
									)}
									{/* Submit */}
									<Button
										block
										type="link"
										htmlType="submit"
										loading={submitting}
										className="new-service-submit btn-dark"
									>
										Next: Customize
									</Button>
								</Form>
							)}
						</Col>
					</Col>
				</Row>
			</Col>
			{
				seeTemplates && (
					<ServiceTemplates
						seeTemplates={seeTemplates}
						setSeeTemplates={setSeeTemplates}

					/>

				)
			}

			{multipleSessionsModal && (
				<MultipleSessionsModal
					visible={multipleSessionsModal}
					setVisible={setMultipleSessionsModal}
					onClose={() => {
						setMultipleSessionsModal(false);
					}}
					form={form}
				// initialValues={initialValues}
				/>
			)}
		</>
	);
}

export default AddNewService;
