import React, { useState } from 'react';
import { Button, Input, message, Modal } from "antd";
import { IoClose } from "react-icons/io5";
const CanccelBooking = ({ CancelModal, setCancelModal }) => {
    const { TextArea } = Input;
    const [reason, setReason] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const handleCancel = () => {
        setError("");
        setReason("");
        setCancelModal(false);
    };

    const handleChange = (e) => {
        setReason(e.target.value);
    };

    const onCancel = async () => {
        if (reason?.length === 0) setError("Required");
        else {
            setLoading(true);
            try {

                message.success("Upcoming call cancelled!");
                setCancelModal(false);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                setError("Something Went Wrong");
            }
        }
    };
    return (


        <>

            <Modal
                footer={null}
                title="Are you sure?"
                open={CancelModal}
                centered={true}
                destroyOnClose
                onCancel={handleCancel}
                width={368}
                closeIcon={<IoClose fontSize={24} color="#141414" />}
            >
                <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder="Please specify a reason for cancellation"
                    value={reason}
                    onChange={handleChange}
                    className="cancel-border"
                    style={{
                        padding: 5,
                        borderRadius: 12,
                        marginBottom: 10,
                    }}
                />
                <Button
                    type="primary"
                    block
                    className="cancel-border cancel-button"
                    onClick={onCancel}
                    loading={loading}
                    style={{
                        marginBottom: 10,
                        marginTop: 10,
                    }}
                >
                    Cancel Booking
                </Button>
                {error && (
                    <h5
                        style={{
                            textAlign: "center",
                            color: "red",
                        }}
                    >
                        &#42;{error}
                    </h5>
                )}

                <div className="cancel-desc-column cancel-border">
                    <p className="cancel-desc-text ">
                        You can alternatively choose to&nbsp;
                        <span
                            className="text-underline cursor-hover"

                        >
                            reschedule
                        </span>
                    </p>
                </div>

            </Modal>
        </>
    );
}

export default CanccelBooking;
