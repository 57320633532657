import { EditorState, convertToRaw } from 'draft-js';
import React, { useEffect, useState } from 'react';
import TextEditor from '../Modal/TextEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import SelectMultiInputs from '../../applyAsMentor/selectMultiInputs';
import { UploadImage } from '../../UploadImage';
import { useDispatch, useSelector } from 'react-redux';
import { appendFormData } from '../../../redux/actions/course';

const AddAudioChapter = ({ chapterIndex, unitIndex }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        Tags: [],
        availabilityType: 'AlwaysAvailable',
        availableFrom: '',
        availableTill: '',
        duration: '',
        autoplay: 'yes',
        link: '',
        thumbnailImage: '',
    });

    const [validationMessages, setValidationMessages] = useState({
        title: '',
        Tags: '',
        duration: '',
        availableFrom: '',
        availableTill: '',
        description: '',
        autoplay: '',
        thumbnailImage: '',
    });

    const [successMessage, setSuccessMessage] = useState('');

    const syllabusIndexData = useSelector(
        (state) => state.SyllabusIndexDataRedcuer?.data[0]
    );

    useEffect(() => {
        if (syllabusIndexData) {
            const selectedUnit = syllabusIndexData.course_syllabus?.[unitIndex];
            const selectedChapter = selectedUnit?.chapters?.[chapterIndex];
            const data = selectedChapter?.data[0];

            if (selectedChapter) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    title: data?.title || '',
                    duration: data?.duration || '',
                    availabilityType: data?.availabilityType || 'AlwaysAvailable',
                    availableFrom: data?.availableFrom || '',
                    availableTill: data?.availableTill || '',
                    description: data?.description || '',
                    enableSharing: data?.enableSharing || '',
                    autoplay: data?.autoplay || 'yes',
                    link: data?.link || '',
                    thumbnailImage: data?.thumbnailImage || '',
                }));
            }
        }
    }, [syllabusIndexData, chapterIndex, unitIndex]);

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        const { blocks } = convertToRaw(editorState.getCurrentContent());
        setFormData((prevFormData) => ({
            ...prevFormData,
            description: blocks[0]?.text,
        }));
    };

    const handleTagsChange = (item, addFunc) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            Tags: [...prevFormData.Tags, item],
        }));
    };

    const handleRemoveTag = (item) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            Tags: prevFormData.Tags.filter((tag) => tag.value !== item.value),
        }));
    };

    const uploadBanner = (val, pre) => {
        console.log('uploadbanner called', val);

        setFormData((pre) => ({
            ...pre,
            thumbnailImage: val,
        }));
    };

    const validateForm = () => {
        const newValidationMessages = {};

        if (!formData.title) {
            newValidationMessages.title = 'Title is required';
        }
        if (formData.Tags.length < 5) {
            newValidationMessages.Tags = 'At least 5 Tags are required';
        }
       
        if (!formData.description) {
            newValidationMessages.description = 'Description is required';
        }
        if (formData.availabilityType === 'TimeBased') {
            if (!formData.availableFrom) {
                newValidationMessages.availableFrom = 'Available From is required';
            }
            if (!formData.availableTill) {
                newValidationMessages.availableTill = 'Available Till is required';
            }
        }
        if (!formData.autoplay) {
            newValidationMessages.autoplay = 'AutoPlay is required';
        }
        if (!formData.thumbnailImage) {
            newValidationMessages.thumbnailImage = 'Thumbnail Image is required';
        }

        setValidationMessages(newValidationMessages);
        return Object.values(newValidationMessages).every((message) => !message);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            console.log('Form Data:', formData);
            dispatch(
                appendFormData(
                    'ADD_CHAPTER_DATA',
                    chapterIndex,
                    unitIndex,
                    formData
                )
            );
            setSuccessMessage('Chapter data has been successfully saved.');
        }
    };

    return (
        <>
            <div className='bg-white p-5'>
                <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label className="course__label">
                            Title *
                        </label>
                        <input
                            type="text"
                            name="title"
                            placeholder="Enter course Title"
                            className="form-control"
                            value={formData.title}
                            onChange={handleInputChange}
                            required
                        />
                        {validationMessages.title && (
                            <p className="text-danger">{validationMessages.title}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">
                            Tags  *
                        </label>
                        <div className="multi-inputs-container">
                            <div className="multi-inputs">
                                {formData.Tags.map((Tag) => (
                                    <div className="multi-input-design" key={Tag.value}>
                                        <div>{Tag.label}</div>
                                        <div onClick={() => handleRemoveTag(Tag)}>
                                            <FontAwesomeIcon
                                                className="multi-select-icon"
                                                icon={faXmark}
                                                style={{ color: 'grey' }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <SelectMultiInputs
                            isMulti={true}
                            title="language"
                            onChangeFunc={(e) => handleTagsChange(e, setFormData)}
                            value=""
                            name="Tags"
                            isCreatable={true}
                            placeholder="Add at least 5-6 Tags"
                            className="basic-multi-select text-left"
                            classNamePrefix="select"
                            inputData={formData.Tags}
                            closeMenuOnSelect={true}
                            style={{ borderRadius: '12px', height: '3rem' }}
                        />
                        {validationMessages.Tags && (
                            <p className="text-danger">{validationMessages.Tags}</p>
                        )}
                    </div>

                   
                  
                    <div className="form-group">
                        <label className="course__label">
                            Duration *
                        </label>
                        <input
                            type="number"
                            name="duration"
                            placeholder="Enter Duration"
                            className="form-control"
                            value={formData.duration}
                            onChange={handleInputChange}
                            required
                        />
                        {validationMessages.duration && (
                            <p className="text-danger">{validationMessages.duration}</p>
                        )}
                    </div>

                    <div className='d-flex gap-3'>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="AlwaysAvailable"
                                id="fullPaymentRadio"
                                name="availabilityType"
                                checked={formData.availabilityType === 'AlwaysAvailable'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="fullPaymentRadio">
                                Always Available
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="TimeBased"
                                id="partialPaymentRadio"
                                name="availabilityType"
                                checked={formData.availabilityType === 'TimeBased'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="partialPaymentRadio">
                                Time Based
                            </label>
                        </div>
                    </div>

                    {formData.availabilityType === 'TimeBased' && (
                        <>
                            <div className="form-group">
                                <label htmlFor="" className='course__label'>Available From: *</label>
                                <input
                                    type="datetime-local"
                                    name="availableFrom"
                                    value={formData.availableFrom}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    required
                                />
                                {validationMessages.availableFrom && (
                                    <p className="text-danger">{validationMessages.availableFrom}</p>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className='course__label'>Available Till: *</label>
                                <input
                                    type="datetime-local"
                                    name="availableTill"
                                    value={formData.availableTill}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    required
                                />
                                {validationMessages.availableTill && (
                                    <p className="text-danger">{validationMessages.availableTill}</p>
                                )}
                            </div>
                        </>
                    )}

                    <div className="form-group">
                        <TextEditor
                            label="Description: *"
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                        />
                        {validationMessages.description && (
                            <p className="text-danger">{validationMessages.description}</p>
                        )}
                    </div>

                    <div className='d-flex gap-3'>
                        <label className="course__label">
                            AutoPlay *
                        </label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="yes"
                                id="autoplayYes"
                                name="autoplay"
                                checked={formData.autoplay === 'yes'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="autoplayYes">
                                Yes
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="no"
                                id="autoplayNo"
                                name="autoplay"
                                checked={formData.autoplay === 'no'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="autoplayNo">
                                No
                            </label>
                        </div>
                    </div>

                    <div className='pt-4'>
                        <label className="course__label">
                            Thumbnail Recommended Size (600px * 400px)
                        </label>
                        <UploadImage
                            callback={uploadBanner}
                            fileName="_audio-thumbnail"
                            imagePath={"courseImage/courseThumbnail/"}
                            filetype={["image"]}
                        />
                        {validationMessages.thumbnailImage && (
                            <p className="text-danger">{validationMessages.thumbnailImage}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="" className='course__label'>Audio File</label>
                    {formData?.link ? (
                        <div>

                            <video
                                style={{ width: "100%", height: "100%" }}
                                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}${formData?.link}`}
                                controls
                            />

                        </div>
                    ) : (

                        <p className="text-center">Audio Not Avaialable</p>
                    )
                    }
                    </div>

                    {/* Success message */}
                    {successMessage && (
                        <div className="alert alert-success">{successMessage}</div>
                    )}

                    <div className="get-started-cls why_choose_ment">
                        <a >
                            <button type="submit" className="find-btn btn btn-primary">
                                Save
                            </button>
                        </a>
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddAudioChapter;
