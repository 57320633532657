import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "react-bootstrap";
import crossIcon from "../../../assets/images/cross-icon.png";
import mentorWlogo from "../../../assets/images/mentor-wlogo.png";
import EmailOtpModel from "../emailotp";
import mentorLogo from "../../../assets/images/mentor-logo.png";
import SignUpModal from "../signUpModal";
import { generateOtp } from "../../../redux/actions/login";
import ForgetPasswordModel from "../forgotPassword";
import { TailSpin } from "react-loader-spinner";
import LoginModal from "../loginModal";



import { passwordLogin } from "../../../redux/actions/login";



const LoginPasswordModal = ({ headerModal, setHeaderModal }) => {
  const [pass, setPass] = useState("");
  const [errorSignUpmsg, seterrorSignUp] = useState(false);
  const [passwordscreen, setPasswordScreen] = useState(false);
  const [userType, setUserType] = useState("mentee");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const passReducerState = useSelector((state) => state.passwordLoginReducer);
  const status = passReducerState?.status;

  useEffect(() => {
    if (status === 200) {
      setLoader(false);
      setPasswordScreen(true);
    }
  }, [status]);

  const passwordLoginReducerState = useSelector(
    (state) => state.passwordLoginReducer
  );

  const errorSignUp = passwordLoginReducerState?.headers?.["x-error-msg"]

  const dispatch = useDispatch();

  const sendOtp = () => {
    const data = {
      email: headerModal.email,
      requestId: uuidv4(),
      purpose: "LOGIN",
    };
    dispatch(generateOtp(data));
    setHeaderModal({ modal: EmailOtpModel, show: true, email: headerModal.email })
  };

  const deviceToken = useSelector(
    (state) => state.passwordLoginReducer?.headers?.["x-device-token"]
  );

  useEffect(() => {
    if (deviceToken) {
      if (headerModal?.userType === "mentor") {
        // window.location.reload()
        navigate("/mentor-dashboard");
      } else {
        navigate("/dashboard");
      }
    }
    if (errorSignUp) {
      setLoader(false)
      seterrorSignUp(true)
    }
  }, [deviceToken, errorSignUp]);

  const LoginWithPassword = () => {
    const data = {
      email: headerModal.email,
      password: pass,
      userType: headerModal?.userType,
    };
    setLoader(true);
    dispatch(passwordLogin(data));
  };
  useEffect(() => {
    seterrorSignUp(false)
  }, [])
  return (
    <Modal
      size="xl"
      centered
      show={headerModal.show}
      onHide={() =>
        setHeaderModal((prevState) => ({ ...prevState, show: false }))
      }
    >
      {loader && (
        <div className="loader" style={{ zIndex: 9999 }}>
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      )}
      <ModalBody>
        <button
          type="button"
          className="cloose"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() =>
            setHeaderModal((prevState) => ({ ...prevState, show: false }))
          }
        ><div className="closebuttons">
            <span aria-hidden="true">
              <img src={crossIcon} />
            </span>
          </div>
        </button>
        {/* <form> */}
        <div className="signup-row">
          <div className="signup-leftcol">
            <div className="sign-form-cls">
              <div className="logo-sec">
                <h4>Welcome to</h4>
                <img src={mentorLogo} alt="Mentorpal" />
              </div>
              <div className="wel-space-cls">
                <div className="form-group change-pw">
                  <p>
                    Please Enter Your Password{" "}

                  </p>

                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name=""
                    placeholder="Enter password"
                    className="form-control"
                    onChange={(e) => setPass(e.target.value)}
                  />
                </div>
                <div className="singup-upp">
                  {errorSignUpmsg && (
                    <p
                      style={{
                        color: "#800020",
                        marginTop: " 10px",
                        fontWeight: 500,
                      }}
                    >
                      {errorSignUp}
                    </p>
                  )}
                  <button className="btn " onClick={LoginWithPassword} >
                    Login
                  </button>
                </div>
                <div className="singup-otp">
                  <p>
                    Forget password?<a href="#" onClick={() =>
                      setHeaderModal({
                        modal: ForgetPasswordModel, show: true, email: headerModal.email,
                        userType: headerModal?.userType,
                      })
                    }  > Recover now</a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={() =>
                        // setHeaderModal({ modal: LoginModal, show: true, data: headerModal.data })
                        setHeaderModal({ modal: LoginModal, show: true, userType: headerModal?.userType })
                      }
                    >
                      Back to login
                    </a>
                  </p>
                </div>
                <div className="signup-or">
                  <p>
                    <span>OR</span>
                  </p>
                  {/* <h6>Continue with social media</h6> */}
                </div>
                <div className="get-started-cls">
                  <button type="button" className="btn btn-primary" onClick={sendOtp}>
                    Login with otp
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="signup-rightcol">
            <div className="signup-cont">
              <div className="signup-logo d-none d-md-block">
                <img src={mentorWlogo} alt="" />
              </div>
              <div className="signup-inner">
                <p>
                  Mentorship from the global expert. join one-on-one Live
                  session and group session from the verified mentors at your
                  fingertips
                </p>
                <button
                  type="button"
                  onClick={() =>
                    setHeaderModal({ modal: SignUpModal, show: true, as: "mentee" })
                  }
                >
                  New to mentorpal? Sign up now
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </form> */}
      </ModalBody>
    </Modal>

  );
};

export default LoginPasswordModal;
