

import Footer from "../footer";
import Header from "../header";
import '../TeamPage/ourTeam.css';
import Cards from '../TeamPage/Cards';
import Ram from "../../../src/assets/images/as a mentor.jpg"

function ourTeam() {
  return (
  <>
 <div className="myDiv">
   <Header/>
 </div>


   <div className='container d-contents' style={{
    display: "contents",
    padding:"10px"
   }}>
    <div className='row'>
        <div className='meet-center'>
    <button className="Meet-our-team">
        Meet our team
    </button>
    <p className="meet-our-diverse" >
     Meet our diverse team of world-class creators, desires, and problem solvers. </p>
    </div>
            
    <nav className="navbar navbar-expand-lg navbar-light button-simple-collapse">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon "></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
  <div className="container navbar-container-viewall">
    <ul className="navbar-nav mx-auto">
      <li className="nav-item ">
        <a className="nav-link view" href="#">
          View All
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link view" href="#">
          Management
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link view" href="#">
          Product
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link view" href="#">
          Design
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link view" href="#">
          Marketing
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link view" href="#">
          Sales
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link view" href="#">
          Customer Success
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link view" href="#">
          Operation
        </a>
      </li>
    </ul>
  </div>
  </div>
</nav>

  
            {<Cards />}
            </div>

            <div className='meet-center-load'>
          <button className='Load-more-button pl-5'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" padding="5" fill="#000" class="bi bi-download" style={{marginRight:"7px", fontWeight:"bold" }} viewBox="0 0 16 16">
      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
    </svg>
            Load Mores
          </button>
          </div>
          </div>


<div className="container">
<div className="row">
  <div className="col-12">
   
  <div className="testimonial">
    <div className="quote">
      "lorem ipsem Pellentesque habitant morbi tristique senectus et netus et malesuada
      fames ac turpis egestas"
    </div>
    <div className="d-flex">
    <img  className="image-of-mentors" src={Ram}  alt="Person 1" />
    <p className="name-section">  Ajay Kumar </p>
    </div>
    <p className="profession-section"> Front-end Developer </p>
  </div>
  </div>

 <div className="col-12"> 
  <div className="testimonial">
    <div className="quote">
      "lorem ipsem Pellentesque habitant morbi tristique senectus et netus et malesuada
      fames ac turpis egestas"
    </div>
    <div className="d-flex">
    <img  className="image-of-mentors" src={Ram}  alt="Person 1" />
    <p className="name-section">  Ajay Kumar </p>
    </div>
    <p className="profession-section"> Front-end Developer </p>
  </div>
</div>

<div className="col-12"> 
  <div className="testimonial">
    <div className="quote">
      We're hiring
    </div>
    <p className="always-looking"> We're always looking for great people.Chat to our team.  </p>

    <button className='explore-open-roles'>
      Explore open roles
    </button>
    
    <div className="d-flex justify-content-end">
     <img  className="image-of-mentors" src={Ram}  alt="Person 1" /> 
     <img  className="image-of-mentors" src={Ram}  alt="Person 1" /> 
     <img  className="image-of-mentors" src={Ram}  alt="Person 1" /> 
     <img  className="image-of-mentors" src={Ram}  alt="Person 1" /> 
    </div>
  </div>
</div>


</div>
        
   
   </div>
   <hr/>
    
   <Footer />

  </>
  );
}

export default ourTeam;

