import React from 'react'

import logo from './../../../assets/images/logo-w.png'
import styles from './TrainingHeader.module.css'
import banner from '../../../assets/images/banner.png'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const TrainingHeader = () => {

    const [Togglemenu, setTogglemenu] = useState(false)

    return (
        <div className='TrainingHeader' style={{ background: `url(${banner})`, position: 'relative' }}>
            <div className={`container ${styles.container_styles}`}>
                <div style={{ display: 'flex' }}>
                    <a className={`navbar-brand ${styles.logo_styles}`} href="/" >
                        <img src={logo} alt="logo" class="header-logo ms-auto" />
                    </a>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='navigations d-none d-lg-block' >
                            <ul className={`${styles.nav_items}`}>
                                <li>
                                    <a href='#'> What we do</a>
                                </li>
                                <li>
                                    <a href='#'>How We do it</a>
                                </li>
                                <li>
                                    <a href='#'>Resources</a>
                                </li>
                                <li>
                                    <a href='#'>Plans</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className={`${styles.login_signup_btns} d-none d-lg-block`} style={{ margin: '0', padding: '10px' }}>
                    <button className={`${styles.training_logo_btn}`} >Login</button>
                    <button className={`${styles.training_get_started_btn}`}>
                        <Link to='/request-demo' style={{ color: 'white' }}>
                            Get Started
                        </Link>
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='mobile_menu_toggle d-block d-lg-none'>
                        <button className={`${styles.toggle_btn}`}
                            onClick={
                                () => setTogglemenu(!Togglemenu)
                            }>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAQ0lEQVR4nO3WoREAIAwEQfovKyksSAQimrDbwc2bXwsAOKoq6z05JSSuEAAAaD36fnNKSPQTAQDAkPebU0LiCgGAn21ViEka78Lu0AAAAABJRU5ErkJggg==" width={'30px'} />
                        </button>
                    </div>
                </div>
            </div>
            <div style={{
                width: '90vw',
                height: '100vh',
                backgroundColor: 'black',
                position: 'absolute',
                left: Togglemenu ? "0px" : '-90vw',
                transition: 'left 0.5s ease-in-out',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <ul className={`${styles.toggleMenuListStyles}`}>
                    <li className={`${styles.TogglemenuItems}`}>What we do</li>
                    <li className={`${styles.TogglemenuItems}`}>How We do it</li>
                    <li className={`${styles.TogglemenuItems}`}>Resources</li>
                    <li className={`${styles.TogglemenuItems}`}>Plans</li>
                </ul>
            </div>
        </div>
    )
}

export default TrainingHeader