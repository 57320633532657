import React, { useState, useEffect } from "react";
import Select from "react-select";
import SelectMultiInputs from "../../selectMultiInputs";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  jobSearch,
  userMentorshipData,
  useMantorshipDataSecond,
  userSkills,
  carrerAdvice,
} from "../../../../assets/data/data";
import SelectListComponent from "../../../SelectListComponent";

const Three = ({
  registrationProcessData,
  setRegistrationProcessData,
  errors,
}) => {
  const [selectedLeadership, setSelectedLeadership] = useState([]);
  const [selectedMentorship, setSelectedMentorship] = useState([]);
  const [selectedJobSearch, setSelectedJobSearch] = useState([]);
  const [selectedCareerAdvice, setSelectedCareerAdvice] = useState([]);
  const [selectedSkills, setSelectedSkill] = useState([]);

  const customStyles = {
    input: () => ({
      // padding: "5px 0px"
    }),
    placeholder: () => ({
      // margin: "auto 0px",
      // height:"100%"
    }),
  };

  useEffect(() => {
    // ----- selectedLeadership
    if (registrationProcessData.leadership.length > 0) {
      let l = [];
      registrationProcessData?.leadership?.map((item, id) => {
        if (item !== selectedLeadership[id]?.value) {
          // let label = userMentorshipData?.filter((val) => {
          //   if (val?.value == item) return val;
          // });
          l.push({ label: item, value: item });
        }
      });
      setSelectedLeadership(l);
    }
    // ---------------- selectedMentorship
    if (registrationProcessData.mentorship.length > 0) {
      let m = [];
      registrationProcessData?.mentorship?.map((item, id) => {
        if (item !== selectedMentorship[id]?.value) {
          // let label = useMantorshipDataSecond?.filter((val) => {
          //   if (val?.value == item) return val;
          // });
          m.push({ label: item, value: item });
        }
      });
      setSelectedMentorship(m);
    }
    // ---------------- selectedJobSearch
    if (registrationProcessData.job_search.length > 0) {
      let j = [];
      registrationProcessData?.job_search?.map((item, id) => {
        if (item !== selectedJobSearch[id]?.value) {
          // let label = jobSearch?.filter((val) => {
          //   if (val?.value == item) return val;
          // });
          j.push({ label: item, value: item });
        }
      });
      setSelectedJobSearch(j);
    }
    // ---------------- selectedCareerAdvice
    if (registrationProcessData.career_advice.length > 0) {
      let c = [];
      registrationProcessData?.career_advice?.map((item, id) => {
        if (item !== selectedCareerAdvice[id]?.value) {
          // let label = carrerAdvice?.filter((val) => {
          //   if (val?.value == item) return val;
          // });
          c.push({ label: item, value: item });
        }
      });
      setSelectedCareerAdvice(c);
    }
    // ---------------- selectedSkills
    if (registrationProcessData.skills.length > 0) {
      let s = [];
      registrationProcessData?.skills?.map((item, id) => {
        if (item !== selectedSkills[id]?.value) {
          // let label = userSkills?.filter((val) => {
          //   if (val?.value == item) return val;
          // });
          s.push({ label: item, value: item });
        }
      });
      setSelectedSkill(s);
    }
  }, []);

  const handleRemove = (title,toRemove, selectedData, setSelectedData) => {
    const updatedData = selectedData.filter(item => {
      return item.value !== toRemove.value;
    });
    setSelectedData(updatedData);
    
    setRegistrationProcessData((prev) => ({
      ...prev,
      [title]: updatedData.map((title) => title.label),
    }));
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props} className="d-flex">
          <label
            className={`${props.isSelected ? "text-light" : "text-dark"} `}
          >
            {props.label}
          </label>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
        </components.Option>
      </div>
    );
  };

  return (
    <>
      <div className="registrationCard">
    
        <div className=" leftSide">
          <h3 className="mb-32 fw-bold">
            Select topics you’d like to mentor on.
          </h3>
          {/* <label className="form-label text-black" for="country">
            We recommend selecting at least 5 topic areas to explore what
            interests you.
          </label> */}
          <p className="related-context">We recommend selecting at least 5 options under each head, research better and select wisely so that you can reach the right mentees.</p>
        </div>
        {/* <div className="container ">
            <div className="form-group">
            </div>
          </div> */}

        {/* <div className="right-side p-2"> */}
        <div className="row rightSide">

          <div className="container">
            <div className="form-group">
              {selectedLeadership.length > 0 ? (
                <div className="multi-inputs-container">
                  <div className="text">Leadership:</div>
                  <div className="multi-inputs">
                    {selectedLeadership.map((leadership) => (
                      <div
                        className="multi-input-design"
                        key={leadership.value}
                      >
                        <div key={leadership.value}>{leadership.label}</div>
                        <div
                        key={leadership.value}
                          onClick={() =>
                            handleRemove(
                              "leadership",
                              leadership,
                              selectedLeadership,
                              setSelectedLeadership
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="multi-select-icon"
                            icon={faXmark}
                            style={{ color: "gray" }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : <div className=" multi-inputs-container">
              <labe>Leadership:</labe>
         </div>}
              <SelectMultiInputs
                name="leadership"
                // label={"Leadership"}
                title="leadership"
                placeholder="Leadership"
                value=""
                onChangeFunc={(e) => {
                  setSelectedLeadership((prev) => [...prev, e]);
                  setRegistrationProcessData((prev) => ({
                    ...prev,
                    leadership: [...prev.leadership, e.label],
                  }));
                }}
                is2d={true}
                IsMulti
                isCreatable={true}
                options={userMentorshipData}
                components={{
                  Option,
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                className="basic-multi-select text-left"
                classNamePrefix="select"
                inputData={selectedLeadership}
                {...errors.leadership}
              />
            </div>
          </div>

          <div className="container">
          <div className="form-group">
              {selectedMentorship.length > 0 ? (
                <div className="multi-inputs-container">
                  <div className="text">Mentorship:</div>
                  <div className="multi-inputs">
                    {selectedMentorship.map((mentorship) => (
                      <div
                        className="multi-input-design"
                        key={mentorship.value}
                      >
                        <div key={mentorship.value}>{mentorship.label}</div>
                        <div
                        key={mentorship.value}
                          onClick={() =>
                            handleRemove(
                              "mentorship",
                              mentorship,
                              selectedMentorship,
                              setSelectedMentorship
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="multi-select-icon"
                            icon={faXmark}
                            style={{ color: "gray" }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : <div className=" multi-inputs-container">
              <labe>General Mentorship:</labe>
         </div>}

            <SelectMultiInputs
              name="mentorship"
              // label="General Mentorship"
              title="mentorship"
              IsMulti
              isCreatable={true}
              placeholder="General Mentorship"
              value=""
              onChangeFunc={(e) => {
               
                setSelectedMentorship((prev)=>[...prev,e]);
                setRegistrationProcessData((prev) => ({
                  ...prev,
                  mentorship: [...prev.mentorship,e.label],
                }));
              }}
              options={useMantorshipDataSecond}
              components={{
                Option,
              }}
              is2d={true}
              allowSelectAll={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              className="basic-multi-select text-left"
                classNamePrefix="select"
                inputData={selectedMentorship}
              {...errors.mentorship}
            />
            </div>
          </div>


          <div className="container">
          <div className="form-group">
              {selectedJobSearch.length > 0 ? (
                <div className="multi-inputs-container">
                  <div className="text">Job Search:</div>
                  <div className="multi-inputs">
                    {selectedJobSearch.map((job_search) => (
                      <div
                        className="multi-input-design"
                        key={job_search.value}
                      >
                        <div key={job_search.value}>{job_search.label}</div>
                        <div
                        key={jobSearch.value}
                          onClick={() =>
                            handleRemove(
                              "job_search",
                              job_search,
                              selectedJobSearch,
                              setSelectedJobSearch
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="multi-select-icon"
                            icon={faXmark}
                            style={{ color: "gray" }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : <div className=" multi-inputs-container">
              <labe>Job Search:</labe>
         </div>}
            <SelectMultiInputs
              placeholder="Job Search"
              name="job_search"
              // label="Job Search"
              title="job search"
              IsMulti
              isCreatable={true}
              value=""
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              options={jobSearch}
              components={{
                Option,
              }}
              onChangeFunc={(e) => {
            
                setSelectedJobSearch((prev)=>[...prev,e]);
                setRegistrationProcessData((prev) => ({
                  ...prev,
                  job_search: [...prev.job_search,e.label],
                }));
              }}
              is2d={true}
              allowSelectAll={true}
              className="basic-multi-select text-left"
              classNamePrefix="select"
              inputData={selectedJobSearch}
              {...errors.job_search}
            />
            </div>
          </div>




          <div className="container ">
          <div className="form-group">
              {selectedCareerAdvice.length > 0 ? (
                <div className="multi-inputs-container">
                  <div className="text">Career Advice:</div>
                  <div className="multi-inputs">
                    {selectedCareerAdvice.map((career_advice) => (
                      <div
                        className="multi-input-design"
                        key={career_advice.value}
                      >
                        <div key={career_advice.value}>{career_advice.label}</div>
                        <div
                           key={career_advice.value}
                          onClick={() =>
                            handleRemove(
                              "career_advice",
                              career_advice,
                              selectedCareerAdvice,
                              setSelectedCareerAdvice
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="multi-select-icon"
                            icon={faXmark}
                            style={{ color: "gray" }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : <div className=" multi-inputs-container">
              <labe>Career Advice:</labe>
         </div>}




            <SelectMultiInputs
              placeholder="Career Advice"
              name="career_advice"
              title="career advice"
              // label="Career Advice"
              IsMulti
              isCreatable={true}
              value=""
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              options={carrerAdvice}
              components={{
                Option,
              }}
              onChangeFunc={(e) => {
               
                setSelectedCareerAdvice((prev)=>[...prev,e]);
                setRegistrationProcessData((prev) => ({
                  ...prev,
                  career_advice: [...prev.career_advice,e.label],
                }));
              }}
              is2d={true}
              allowSelectAll={true}
              className="basic-multi-select text-left"
              classNamePrefix="select"
              inputData={selectedCareerAdvice}
              ariaLabel="Default select example"
              {...errors.career_advice}
            />
            </div>
          </div>








          <div className="container">
          <div className="form-group">
              {selectedSkills.length > 0 ? (
                <div className="multi-inputs-container">
                  <div className="text">Skills:</div>
                  <div className="multi-inputs">
                    {selectedSkills.map((skills) => (
                      <div
                        className="multi-input-design"
                        key={skills.value}
                      >
                        <div key={skills.value}>{skills.label}</div>
                        <div onClick={() =>
                              handleRemove(
                                "skills",
                                skills,
                                selectedSkills,
                                setSelectedSkill
                              )
                            } 
                            key={skills.value}>
                          <FontAwesomeIcon
                            className="multi-select-icon"
                            icon={faXmark}
                            style={{ color: "gray" }}
                            
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) :   <div className=" multi-inputs-container">
                     <labe>Skills:</labe>
                </div>}

            <SelectMultiInputs
              placeholder="Skills"
              name="skills"
              title="skills"
              // label="Skills"
              IsMulti
              isCreatable={true}
              value=""
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              options={userSkills}
              components={{
                Option,
              }}
              onChangeFunc={(e) => {
                setSelectedSkill((prev)=>[...prev,e]);
                setRegistrationProcessData((prev) => ({
                  ...prev,
                  skills: [...prev.skills,e.label],
                }));
              }}
              is2d={true}
              allowSelectAll={true}
              className="basic-multi-select text-left"
              classNamePrefix="select"
              inputData={selectedSkills}
              ariaLabel="Default select example"
              {...errors.skills}
            />
            </div>
          </div>

          {/* </div> */}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Three;
