import React, { useEffect } from 'react';
import MasterHeader from '../MasterHeader/MasterHeader';
import MasterFooter from '../MasterFooter';
import { bannerImg } from '../../../assets/images/export-images';
import Carousel from 'react-multi-carousel';
import { useState } from 'react';

import { FilterOptions, courses, responsive } from '../data';
import Testimonial from '../../Testimonial/testimonial';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCourseAction } from '../../../redux/actions/course';
import { TailSpin } from 'react-loader-spinner';
import { subscribeAction } from '../../../redux/actions/common';
import CourseSubscribe from '../CourseSubscribe';


const MasterCourseListing = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [filters, setFilters] = useState(FilterOptions)



    const [loader, setloader] = useState();
    const [data, setdata] = useState();
    const [error, setError] = useState();


    useEffect(() => {

        dispatch(getAllCourseAction({ document_type:"course"}))


    }, [getAllCourseAction, dispatch]);


    const getAllCourse = useSelector(
        (state) => state.getAllCourseReducer
    );
    useEffect(() => {
        setloader(getAllCourse?.loading)
        setdata(getAllCourse?.data)
        setError(getAllCourse?.error)
        console.log("getAllCourse", getAllCourse)
        console.log(data)



    }, [getAllCourse, loader, data]);


    const courseDetails = (course) => {
        navigate(`/explore-courses/${course?.slug}/${course?.product_id}`);
    }
    const getLocalTime = (utcTime) => {
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };

        const localTime = new Date(utcTime).toLocaleString("en-US", options);
        return localTime;
    };
    let date = new Date();
    let datestring = date.toTimeString();
    let countryname =
        datestring?.includes("india") | datestring?.includes("India")
            ? "India"
            : "";
                const currentDate = new Date();
    const [upcomingCoursesdata, setupcomingCoursesdata] = useState();
    const [pastCoursesdata, setpastCoursesdata] = useState();


    const upcomingCourses = data ? data.filter((course) => {
        const courseStartDate = new Date(course.course_start_date);
        return courseStartDate > currentDate;
    }) : [];

    const pastCourses = data ? data.filter((course) => {
        const courseStartDate = new Date(course.course_start_date);
        return courseStartDate < currentDate;
    }) : [];


    useEffect(() => {
        if (loader === false && upcomingCourses && pastCourses)
            setupcomingCoursesdata(upcomingCourses)
        setpastCoursesdata(pastCourses)

    }, [getAllCourse, loader, data]);
    return (
        <>
            {
                loader
                    ? (
                        <div className="loader open_sessions_loader">
                            <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                        </div>
                    ) : (null)}
            <MasterHeader />
            <section
                className="banner-sec inner-banner pb-0"
                style={{ backgroundImage: `url(${bannerImg})` }}
            >
                <div className="container">
                    <div className="mentroship-sec">
                        <h1>Discover Available Courses</h1>
                    </div>

                    <div className="input-group pt-5" >
                        <input
                            className="form-control border-end-0 border rounded-pill"
                            type="search"
                            defaultValue="Full Stack"
                            id="example-search-input"
                        />

                    </div>
                    <Carousel
                        className=" pt-5 pb-2 mb-0 parent_carousel "
                        responsive={responsive}
                    >
                        {
                            filters.map((filter, idx) =>

                            (
                                <div className="carousel-filter-item ">
                                    {filter.icon}
                                    {
                                        filter.active ?
                                            <div className="carousel_text fw-bold" style={{ color: "black" }}>{filter.text}</div>
                                            :
                                            <div>{filter.text}</div>
                                    }


                                </div>

                            )
                            )
                        }
                    </Carousel>
                </div>



            </section>

            <section className="section background-color-grey">
                <div className="padding-global">
                    <div className="container-large">
                        <div className="padding-section-medium">
                            <div className="section-content-holder">
                                <div className="margin-bottom margin-medium">
                                    <div className="text-align-center">
                                        <div className="max-width-medium">
                                            <h2 className="heading-style-h3" style={{ color: '#b7308e' }}>Upcoming Master Courses</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-dyn-list">
                                  {
                                        loader
                                            ? (
                                                <div className="loader open_sessions_loader">
                                                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                                                </div>
                                            ) : (


                                                <>
                                                    {
                                                        upcomingCoursesdata && upcomingCoursesdata.length > 0 ? (
                                                            <>
                                                                <div role="list" className="course-grid w-dyn-items">
                                                                    {upcomingCoursesdata?.map((course, index) => (

                                                                        <div key={index} role="listitem" className="w-dyn-item course_card__Wrapper" onClick={() => { courseDetails(course) }}>
                                                                            <a className="course-card w-inline-block">
                                                                                <div className="c-wrap">
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}${course?.course_banner}`} alt={course?.course_topic}
                                                                                        loading="lazy"

                                                                                        sizes="100vw"
                                                                                        className="c-thumbnail"
                                                                                    />
                                                                                </div>
                                                                                <div className="course-details">
                                                                                    <div className="meta-group margin-bottom margin-xsmall">
                                                                                        <div className="small-headline paid">{course?.proBono === "true"
                                                                                            ? null
                                                                                            : countryname === "India"
                                                                                                ? "₹" + (Math.round(course?.discount_price * 83.12))
                                                                                                : "$" + (Math.round(course?.discount_price))

                                                                                        }</div>
                                                                                        <div className="small-headline">·</div>
                                                                                        <div className="small-headline">{course?.course_time_period} week</div>
                                                                                        <div className="small-headline">·</div>
                                                                                        <div className="small-headline">{getLocalTime(course?.course_start_date)}</div>
                                                                                    </div>
                                                                                    <h2 className="heading-style-h4 bold">{course?.course_topic}</h2>
                                                                                    <p>{course?.course_description?.split(' ').slice(0, 15).join(' ')} ...</p>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <section>
                                                                    <div className="container avilabilty___col">
                                                                        <div className="d-flex align-items-center justify-content-center ">
                                                                            <div className="text-center">
                                                                                <h2 className="display-1 fw-bold text-center">Comming Soon</h2>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </>
                                                        )


                                                    }

                                                </>

                                            )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section background-color-grey">
                <div className="padding-global">
                    <div className="container-large">
                        <div className="padding-section-medium">
                            <div className="section-content-holder">
                                <div className="margin-bottom margin-medium">
                                    <div className="text-align-center">
                                        <div className="max-width-medium">
                                            <h2 className="heading-style-h3" style={{ color: '#b7308e' }}>Past Master Courses</h2>
                                        </div>
                                    </div>
                                </div>
                               <div className="w-dyn-list">
                                    {
                                        loader
                                            ? (
                                                <div className="loader open_sessions_loader">
                                                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                                                </div>
                                            ) : (


                                                <>
                                                    {
                                                        pastCoursesdata && pastCoursesdata.length > 0 ? (
                                                            <>
                                                                <div role="list" className="course-grid w-dyn-items">
                                                                    {pastCoursesdata?.map((course, index) => (
                                                                        <div key={index} role="listitem" className="w-dyn-item course_card__Wrapper" onClick={() => { courseDetails(course) }}>
                                                                            <a className="course-card w-inline-block">
                                                                                <div className="c-wrap">
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}${course?.course_banner}`} alt={course?.course_topic}
                                                                                        loading="lazy"

                                                                                        sizes="100vw"
                                                                                        className="c-thumbnail"
                                                                                    />
                                                                                </div>
                                                                                <div className="course-details">
                                                                                    <div className="meta-group margin-bottom margin-xsmall">
                                                                                        <div className="small-headline paid">{course?.proBono === "true"
                                                                                            ? null
                                                                                            : countryname === "India"
                                                                                                ? "₹" + (Math.round(course?.discount_price * 83.12))
                                                                                                : "$" + (Math.round(course?.discount_price))

                                                                                        }</div>
                                                                                        <div className="small-headline">·</div>
                                                                                        <div className="small-headline">{course?.course_time_period} week</div>
                                                                                        <div className="small-headline">·</div>
                                                                                        <div className="small-headline">{getLocalTime(course?.course_start_date)}</div>
                                                                                    </div>
                                                                                    <h2 className="heading-style-h4 bold">{course?.course_topic}</h2>
                                                                                    <p>{course?.course_description?.split(' ').slice(0, 15).join(' ')} ...</p>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <section>
                                                                    <div className="container avilabilty___col">
                                                                        <div className="d-flex align-items-center justify-content-center ">
                                                                            <div className="text-center">
                                                                                <h2 className="display-1 fw-bold text-center">Comming Soon</h2>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </>
                                                        )


                                                    }

                                                </>

                                            )}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CourseSubscribe />
            <section className=" why_choose_ment">
                <div className="container">
                    {/*  testimonial section */}
                    <div className="container">
                        <center>
                            <h2 className=" Our-inspiring-mentorss my-4" style={{ color: '#b7308e' }}>
                                Our Inspiring Mentors & Empowered Mentees
                            </h2>
                        </center>
                        <Testimonial />
                    </div>
                </div>
            </section>
            <MasterFooter />
        </>
    );
}

export default MasterCourseListing;
