import React, { useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { mentorSubscriptionRequests } from "../../../redux/actions/mentor";
import { REACT_USER_ID } from "../../auth/tokenProvider";

const MentorshipRequests = () => {
  const dispatch = useDispatch();
  const user_id = REACT_USER_ID;
  useEffect(() => {
    dispatch(
      mentorSubscriptionRequests("a75dc1e4-85d3-4de2-92b0-4e9bf90b67b0")
    );
  }, []);
  const mentorSubscriptionRequestsReducerData = useSelector(
    (state) => state.mentorSubscriptionRequestsReducer
  );
  const mentorSubscriptionRequestsList =
    mentorSubscriptionRequestsReducerData?.data || [];
  const isLoading = mentorSubscriptionRequestsReducerData?.loading;
  return (
    <>
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            {/* <h1 className='h3 mb-0 text-gray-800 main-title'>Dashboard</h1> */}
          </div>
          <div className="row">
            <div className="col-xl-12 col-md-12 mb-4">
              <div className="card">
                <div className="card-header header-table">
                  <div className="card-left">
                    <h5>New Mentorship Request(s)</h5>
                  </div>
                </div>
                {mentorSubscriptionRequestsList.length > 0 ? (
                  <div className="card-body table-inner db-table">
                    <div className="table-responsive">
                      <table style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Session Type</th>
                            <th>Date</th>
                            <th>Slot</th>
                            <th>Payment</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mentorSubscriptionRequestsList.map((request) => (
                            <tr>
                              <td>{request?.mentee_id}</td>
                              <td>{request?.product_details?.title}</td>
                              <td>7 February</td>
                              <td>8 PM to 9 PM</td>
                              <td className="unpaid">Unpaid</td>
                              <td btn-bar>
                                <button className="btn active-btn">
                                  Accept
                                </button>
                                <button className="btn reject-btn">
                                  Reject
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        "text-align": "center",
                        "padding-bottom": "1rem",
                      }}
                    >
                      <h2>No Request Found</h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MentorshipRequests;
