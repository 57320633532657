import React, { useState, useEffect } from 'react';
import { Timezones_List } from '../../../assets/data/data';

const TimezoneSelector = ({ registrationProcessData, setRegistrationProcessData }) => {
    const [timezones, setTimezones] = useState([]);
    const [selectedTimezone, setSelectedTimezone] = useState('');

    useEffect(() => {
        // Set timezones state
        setTimezones(Timezones_List);

        // Check if the timezone is already set in registrationProcessData
        const savedTimezone = registrationProcessData?.timezone;

        // Get the local timezone
        const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Set the default selected timezone to the saved value or local timezone
        setSelectedTimezone(savedTimezone || localTimezone);
    }, [registrationProcessData]);

    useEffect(() => {
        // Update the registration process data when the selected timezone changes
        setRegistrationProcessData((prev) => ({
            ...prev,
            timezone: selectedTimezone,
        }));
    }, [selectedTimezone, setRegistrationProcessData]);

    const handleTimezoneChange = (event) => {
        const selectedTimezone = event.target.value;
        setSelectedTimezone(selectedTimezone);
    };

    return (
        <div>
            <label htmlFor="timezone">Select Timezone:</label>
            <select id="timezone" className="form-control" onChange={handleTimezoneChange} value={selectedTimezone}>
                {timezones.map((timezone) => (
                    <option key={timezone.timezone} value={timezone.timezone}>
                        {timezone.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default TimezoneSelector;
