import React from "react";
import "../../openSessions/openSession.css";
import Footer from "../../footer";
import Header from "../../header";
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";
import { useState } from "react";
import EnrollSession from "../EnrollSession";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { sessionDetailsAction } from "../../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import maleAvatar from "../../../assets/images/male.png";
import femaleAvatar from "../../../assets/images/female.png";
const SessionDetails = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_USER_ID") ? true : false
  );

  const [showEnrollForm, setEnrollForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { sessionId } = useParams(); // Get sessionId from URL parameter
  const dispatch = useDispatch();
  const [currentUrl, setCurrentUrl] = useState("");

  const handleClickEnroll = () => {
    console.log("Enquiry form called");
    setEnrollForm(true);
  };

  useEffect(() => {
    const data = {
      sessionId: sessionId,
    };
    dispatch(sessionDetailsAction(data));
  }, []);

  const getLocalTime = (utcTime) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const localTime = new Date(utcTime).toLocaleString("en-US", options);
    return localTime;
  };

  const sessionDetails = useSelector((state) => {
    if (isLoading && !state.sessionDetailsReducer.loading) {
      setIsLoading(false);
    } else if (!isLoading && state.sessionDetailsReducer.loading) {
      setIsLoading(true);
    }
    return state.sessionDetailsReducer.data;
  });

  useEffect(() => {
    if (sessionDetails !== undefined) {
      console.log("sessionDetails", sessionDetails);
    }
  }, [isLoading]);

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, [sessionDetails]);
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    const copyText = `Join ${sessionDetails?.mentor_name} for an exclusive live session on ${sessionDetails?.sesstiondate} and gain valuable insights from an expert in ${sessionDetails?.title}. Secure your spot now at ${currentUrl}`;
    navigator.clipboard.writeText(copyText);
    setCopied(true);
  };

  return (
    <>
      {loggedIn ? (
        <div style={{ marginTop: "0" }}>
          <ProtectedHeader extraClasses="inner-header" />
        </div>
      ) : (
        <Header extraClasses="inner-header" />
      )}

      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <>
          <section>
            <div className="Layout__Wrapper ">
              <div className="Styles__Wrapper  container">
                <div>
                  <div className="Styles__Title ">
                    <h1 className=" Title_size">{sessionDetails?.title}</h1>
                    <p className="d-flex align-items-center py-4 mb-0">
                      <svg
                        fill="none"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                      >
                        <path
                          d="M3.09264 9.40427H20.9166"
                          stroke="var(--teal)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.4421 13.3097H16.4513"
                          stroke="var(--teal)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.0046 13.3097H12.0139"
                          stroke="var(--teal)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.55789 13.3097H7.56715"
                          stroke="var(--teal)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.4421 17.1962H16.4513"
                          stroke="var(--teal)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.0046 17.1962H12.0139"
                          stroke="var(--teal)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.55789 17.1962H7.56715"
                          stroke="var(--teal)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.0437 2V5.29078"
                          stroke="var(--teal)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.9655 2V5.29078"
                          stroke="var(--teal)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.2383 3.57919H7.77096C4.83427 3.57919 3 5.21513 3 8.22222V17.2719C3 20.3262 4.83427 22 7.77096 22H16.229C19.175 22 21 20.3546 21 17.3475V8.22222C21.0092 5.21513 19.1842 3.57919 16.2383 3.57919Z"
                          stroke="var(--teal)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="Time_color">
                        {getLocalTime(sessionDetails?.sesstiondate)}
                      </span>
                    </p>
                  </div>

                  {sessionDetails?.banner_image ? (
                    <div className="Styles__Banner bCBuyg">
                      <img
                        alt="Session banner"
                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}${sessionDetails?.banner_image}`}
                        className="Styles__SessionBaner "
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="Card-session-details">
                        <div>
                          <h2> {sessionDetails?.title}</h2>
                        </div>
                        <div className="Card-session-time">
                          <span>
                            {" "}
                            {getLocalTime(sessionDetails?.sesstiondate)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="session__description">
                    <p>{sessionDetails?.description}</p>
                  </div>
                </div>
                <div>
                  <button
                    color="#fff"
                    aria-label="RSVP for this session"
                    type="button"
                    className=" Enroll_session text-truncate w-100 mb-4 btn--default btn btn-default"
                    onClick={handleClickEnroll}
                  >
                    Enroll for this session
                  </button>
                  {showEnrollForm && (
                    <EnrollSession
                      handleClose={() => setEnrollForm(false)}
                      sessionId={sessionId}
                    />
                  )}
                  <div className="session__share">
                    <p className=" session__heading font-weight-500 grey-2-text my-3">
                      Spread the word
                    </p>
                    <div className="share__url">
                      <span>
                        <a className="text-truncate">{currentUrl}</a>
                      </span>
                      <svg
                        fill="none"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mx-auto cursor-pointer"
                        onClick={handleCopyClick}
                      >
                        <path
                          d="M19.1538 9H10.8462C9.82655 9 9 9.82655 9 10.8462V19.1538C9 20.1734 9.82655 21 10.8462 21H19.1538C20.1734 21 21 20.1734 21 19.1538V10.8462C21 9.82655 20.1734 9 19.1538 9Z"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.76923 15H4.84615C4.35652 15 3.88695 14.8055 3.54073 14.4593C3.1945 14.1131 3 13.6435 3 13.1538V4.84615C3 4.35652 3.1945 3.88695 3.54073 3.54073C3.88695 3.1945 4.35652 3 4.84615 3H13.1538C13.6435 3 14.1131 3.1945 14.4593 3.54073C14.8055 3.88695 15 4.35652 15 4.84615V5.76923"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    {copied && (
                      <div className="Copy_message">
                        <span> Copied to clipboard!</span>
                      </div>
                    )}
                    {/* <div className="session__share__buttons">
                  <button
                    aria-label="Share on Linkedin"
                    type="button"
                    className=" social_button text-truncate  btn--default-outline btn btn-default"
                  >
                    <svg
                      fill="none"
                      width={25}
                      height={24}
                      viewBox="0 0 25 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        d="M18.68 0.920013H3.32001C1.99521 0.920013 0.920013 1.99521 0.920013 3.32001V18.68C0.920013 20.0048 1.99521 21.08 3.32001 21.08H18.68C20.0048 21.08 21.08 20.0048 21.08 18.68V3.32001C21.08 1.99521 20.0048 0.920013 18.68 0.920013ZM7.16001 8.60001V17.72H4.28001V8.60001H7.16001ZM4.28001 5.94561C4.28001 5.27361 4.85601 4.76001 5.72001 4.76001C6.58401 4.76001 7.12641 5.27361 7.16001 5.94561C7.16001 6.61761 6.62241 7.16001 5.72001 7.16001C4.85601 7.16001 4.28001 6.61761 4.28001 5.94561ZM17.72 17.72H14.84C14.84 17.72 14.84 13.2752 14.84 12.92C14.84 11.96 14.36 11 13.16 10.9808H13.1216C11.96 10.9808 11.48 11.9696 11.48 12.92C11.48 13.3568 11.48 17.72 11.48 17.72H8.60001V8.60001H11.48V9.82881C11.48 9.82881 12.4064 8.60001 14.2688 8.60001C16.1744 8.60001 17.72 9.91041 17.72 12.5648V17.72Z"
                        fill="#000"
                      />
                    </svg>
                    <span>Share</span>
                  </button>
                  <button
                    aria-label="Share on Twitter"
                    type="button"
                    className=" social_button text-truncate  btn--default-outline btn btn-default"
                  >
                    <svg
                      width={19}
                      height={18}
                      viewBox="0 0 25 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.03 3.01001C23.1431 3.40376 22.1925 3.66813 21.1931 3.78813C22.2131 3.17688 22.9969 2.20938 23.3644 1.05813C22.4119 1.62251 21.3525 2.03313 20.2294 2.25438C19.3294 1.29438 18.0469 0.696259 16.6275 0.696259C13.9012 0.696259 11.6906 2.90501 11.6906 5.63126C11.6906 6.01751 11.7356 6.39438 11.82 6.75626C7.71749 6.55001 4.08186 4.58501 1.64624 1.59813C1.22061 2.32938 0.978738 3.17688 0.978738 4.08063C0.978738 5.79251 1.84874 7.30376 3.17436 8.19063C2.36436 8.16438 1.60311 7.94126 0.937488 7.57188C0.937488 7.59251 0.937488 7.61313 0.937488 7.63376C0.937488 10.0263 2.63999 12.0194 4.89561 12.475C4.48311 12.5875 4.04624 12.6475 3.59624 12.6475C3.27749 12.6475 2.96811 12.6175 2.66624 12.5575C3.29624 14.5169 5.11874 15.9475 7.27686 15.9869C5.58936 17.3106 3.46124 18.0981 1.14749 18.0981C0.748113 18.0981 0.356238 18.0756 -0.0281372 18.0288C2.15436 19.4313 4.74936 20.2469 7.53749 20.2469C16.6162 20.2469 21.5812 12.7263 21.5812 6.20501C21.5812 5.99126 21.5756 5.77563 21.5681 5.56563C22.5319 4.87001 23.3681 4.00001 24.03 3.01001Z"
                        fill="#000"
                      />
                    </svg>
                    <span>Tweet</span>
                  </button>
                </div>
                <div className="d-flex align-items-center py-4 mx-3">
                  <div className="In__Meeting">
                    <img
                      alt="Avatar of participants"
                      className="avatar cursor-pointer"
                      src="https://adplist-bucket.s3.amazonaws.com/media/profile_photos/0393f9bfd9144c308a34390932d0e8c8yX9gt.webp"
                    />
                    <img
                      alt="Avatar of participants"
                      className="avatar cursor-pointer"
                      src="https://adplist-bucket.s3.amazonaws.com/media/profile_photos/4d3ca3ad539d4f3caa150ce20f0ea811GBEHc.webp"
                    />
                    <img
                      alt="Avatar of participants"
                      className="avatar cursor-pointer"
                      src="https://adplist-bucket.s3.amazonaws.com/media/profile_photos/fde75f237ec8447db0a79962adada6337Tyrs.webp"
                    />
                    <img
                      alt="Avatar of participants"
                      className="avatar cursor-pointer"
                      src="https://adplist-bucket.s3.amazonaws.com/media/profile_photos/7b4d0c026b264ccaae87d8a265ff2598f9xoJ.webp"
                    />
                    <img
                      alt="Avatar of participants"
                      className="avatar cursor-pointer"
                      src="https://adplist-bucket.s3.amazonaws.com/media/profile_photos/9291559a996b49ec99adffef193b6327Maspz.webp"
                    />
                  </div>
                  <p className=" Attending_meet grey-2-text my-12 ml-3">
                    11/30 attending
                  </p>
                </div> */}
                  </div>
                  <div className="mb-4 mt-4 mt-lg-2">
                    <p className=" session__heading font-weight-500 grey-2-text mb-3">
                      Hosted by:
                    </p>
                    <a target="Mentor" href="/" className="Mentor___profile">
                      <img
                        title={sessionDetails?.mentor_name}
                        src={
                          sessionDetails?.mentor_photo
                            ? `${process.env.REACT_APP_IMAGES_BASE_URL}${sessionDetails?.mentor_photo}`
                            : sessionDetails?.gender == "female"
                            ? `${femaleAvatar}`
                            : `${maleAvatar}`
                        }
                        className=" Mentor___img mr-3"
                        alt=""
                      />

                      <div className="Mentor___Details text-truncate">
                        <p className="Mentor___Details_Name d-flex align-items-center text-truncate">
                          <span className="text-truncate">
                            {sessionDetails?.mentor_name}
                          </span>
                        </p>
                        <p className="Mentor__Details__job grey-2-text text-truncate">
                          <span className="font-weight-400">
                            {sessionDetails?.job_role}
                          </span>
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="mb-4">
                    <p className="font-size-14 font-weight-500 grey-2-text line-height-13 mb-3">
                      Topic of expertise
                    </p>
                    <div className="mx-n1">
                      <div className=" Mentor___Expertise   font-weight-600 white-bg grey-3-border mx-1 mb-2">
                        <div>{sessionDetails?.expertise}</div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <p className="font-size-14 font-weight-500 grey-2-text line-height-13 mb-3">
                      Interest areas
                    </p>
                    <div className="mx-n1">
                      <div className=" Mentor___Expertise   font-weight-600 white-bg grey-3-border mx-1 mb-2">
                        <div>{sessionDetails?.interest_area}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <div className=" bottom-0 text-center w-100 text-center text-lg-start bg-white text-muted">
        <Footer />
      </div>
    </>
  );
};

export default SessionDetails;
