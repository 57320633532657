import { CheckCircleFilled, MoreOutlined } from "@ant-design/icons";
import { Col, Divider, Dropdown, Menu, message, Row, Typography } from "antd";
import { useState } from "react";
import ConfirmationDialogue from "./ConfirmationDialogue";
import  Outlook  from '../Modal/outlook.svg';
import  CalendarImage  from '../Modal/google-calendar.svg';


const { Paragraph } = Typography;

const DisplayEmail = (props) => {
    const { calendars, setRefreshCalendar } = props;
    const [showModal, setShowModal] = useState(false);
    const [actionCalendar, setActionCalendar] = useState();

    const removeCalendar = async () => {
        try {

            message.success("Successfully removed calendar!");
            setRefreshCalendar(true);
        } catch (error) {
            message.success("Some Error occured while removing calendar!");
        }
    };

    const markPrimary = async (id) => {
        try {
            message.success("Successfully changed primary calendar!");
            setShowModal(false);
            setRefreshCalendar(true);
        } catch (error) {
            message.success("Some Error occured while marking primary calendar!");
            setShowModal(false);
        }
    };

    const handleRemoveCalendarClick = (id) => {
        setActionCalendar(id);
        setShowModal(true);
    };

    const handleCancelModal = () => {
        setShowModal(false);
    };

    return (
        <Paragraph>
            <ConfirmationDialogue
                show={showModal}
                onConfirm={removeCalendar}
                onClose={handleCancelModal}
                content={"Are you sure you want to remove the calendar?"}
                title={"Remove calendar"}
            />
            {calendars &&
                calendars?.map((item, idx) => (
                    <Col key={item?.id} span={24}>
                        <Row justify="space-between" className="added-calendar">
                            <Row>
                                <Col>
                                    <img
                                        src={
                                            item?.calender_type === "Outlook"
                                                ? Outlook
                                                : CalendarImage
                                        }
                                        alt="google-calendar"
                                        width={18}
                                        height={18}
                                    />
                                </Col>
                                <Col style={{ marginLeft: "12px" }}>
                                    <Paragraph className="config-title">
                                        {item?.calender_type === "Outlook"
                                            ? "Outlook calendar"
                                            : "Google calendar"}
                                    </Paragraph>
                                    <Paragraph className="added-email">{item?.email}</Paragraph>
                                </Col>
                            </Row>
                            <Row align="middle">
                                {item?.primary && (
                                    <Paragraph className="primary-tag">
                                        <CheckCircleFilled className="check-icon" />
                                        Primary
                                    </Paragraph>
                                )}
                                {!item?.is_auth_valid && (
                                    <Paragraph className="sync-tag">Sync calendar</Paragraph>
                                )}
                                <Dropdown
                                    trigger={["click"]}
                                    className="calendar-dropdown"
                                    placement="bottomLeft"
                                    overlay={() => (
                                        <Menu>
                                            <Menu.Item
                                                onClick={() => handleRemoveCalendarClick(item?.id)}
                                            >
                                                Disconnect
                                            </Menu.Item>
                                            {!item?.primary && (
                                                <Menu.Item onClick={() => markPrimary(item?.id)}>
                                                    Mark Primary
                                                </Menu.Item>
                                            )}
                                        </Menu>
                                    )}
                                >
                                    <MoreOutlined />
                                </Dropdown>
                                {/* <Button type="text" onClick={handleRemoveCalendarClick}>
                  <Paragraph className="disconnect-btn">Disconnect</Paragraph>
                </Button> */}
                            </Row>
                        </Row>
                        <Divider />
                    </Col>
                ))}
        </Paragraph>
    );
};

export default DisplayEmail;
