import React from "react";

const Six = ({
  registrationProcessData,
  setRegistrationProcessData,
  errors,
}) => {
  return (
    <><div className="registrationCard">
      <div className=" leftSide">
        <h3 className="mb-32 fw-bold">
          Almost there! How would you like to be intro’d?.
        </h3>
        <p className="related-context-for-bio">Wow that was really great!! now let us know more about your journey. Write a bio which describes you the best and also share your journey up until now</p>
        <div className="container ">
              <div className="form-group ">
                <label className="form-label" for="languages">
                  Sample bio
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="8"
                  value="As a software developer with over 5 years of experience, I have worked on various projects ranging from web development to mobile app development. Currently, I am working at Cognizant as a software developer, specializing in React and Node.js. I am passionate about teaching and mentoring others and believe that knowledge sharing is the key to success. "
                  aria-label="Disabled input example"
                  disabled
                  readOnly
                ></textarea>
              </div>
            </div>
      </div>
      {/* <div className="signUpForm">
        <div className="left-side"></div>
        <div className="right-side p-2"> */}
          <div className="row rightSide" style={{flex:"1"}}>
            <div className="container ">
              <div className="form-group">
                <label  className="form-label" for="country">
                  Everyone has a story, what’s yours?
                </label>
                <textarea
                  onChange={(e) => {
                    e.preventDefault();
                    if (!errors.story?.onValidateFunc) return;
                    let msg = null;
                    if (!e.target.value && errors.story?.isReq) {
                      msg = `Please type your story.`;
                    }
                    errors.story?.onValidateFunc(msg, "story");
                    setRegistrationProcessData((prev) => ({
                      ...prev,
                      story: e.target.value,
                    }));
                  }}
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="15"
                  placeholder="Tell us more about yourself, your goals and what you love!"
                  defaultValue={registrationProcessData.story}
                ></textarea>
                {errors?.story?.errorMsg && (
                  <span className="text-danger">
                    {errors?.story?.errorMsg === true
                      ? `Please type your story.`
                      : errors?.story?.errorMsg}
                  </span>
                )}
              </div>
            </div>
            {/* <div className="container ">
              <div className="form-group ">
                <label className="form-label" for="languages">
                  Sample bio
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="8"
                  value="As a software developer with over 5 years of experience, I have worked on various projects ranging from web development to mobile app development. Currently, I am working at Cognizant as a software developer, specializing in React and Node.js. I am passionate about teaching and mentoring others and believe that knowledge sharing is the key to success. "
                  aria-label="Disabled input example"
                  disabled
                  readOnly
                ></textarea>
              </div>
            </div> */}
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default Six;
