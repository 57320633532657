import React, { useState } from 'react';
import nowebinar from '../../Image/nowebinar.svg';
import WebinarRegModal from './Modal/WebinarRegModal';
import { convertTimeRangeToLocal, convertUTCtoDate, webinarBookingUpcoming } from '../../Data/Data';


const WebinarUpcoming = () => {
    const data = 2;
    const [RegModal, setRegModal] = useState(false)
    const [ModalData, setModalData] = useState()

    return (
        <>
            <div className="ant-tabs-content ant-tabs-content-top">
                <div
                    role="tabpanel"
                    tabIndex={0}
                    aria-hidden="false"
                    className="ant-tabs-tabpane ant-tabs-tabpane-active"
                    id="rc-tabs-25-panel-1"
                    aria-labelledby="rc-tabs-25-tab-1"
                >
                    <div className="container-fluid ">
                        {
                            webinarBookingUpcoming?.count > 0 || webinarBookingUpcoming?.results.length > 0 ? (
                                <>
                                    <div className="ant-row calls-main ">
                                        <div className="ant-col ant-col-24 ">
                                            {
                                                webinarBookingUpcoming?.results.map((item, index) => (
                                                    <>

                                                        <div className="ant-typography " key={index}>
                                                            <div className="ant-typography dates ">{convertUTCtoDate(item?.time)}</div>
                                                            <div
                                                                className="ant-row "
                                                                style={{ marginLeft: "-10px", marginRight: "-10px" }}
                                                            >
                                                                <div
                                                                    className="ant-col ant-col-24 "
                                                                    style={{ paddingLeft: 10, paddingRight: 10 }}
                                                                >
                                                                    <div
                                                                        className="ant-col calls-card-container ant-col-xs-24 "
                                                                        style={{ paddingLeft: 10, paddingRight: 10 }}
                                                                    >
                                                                        <div className="ant-typography calls-card ">
                                                                            <div className="ant-row ant-row-space-between calls-header cursor-hover ">
                                                                                <div className="ant-col ">
                                                                                    <div className="ant-typography calls-time ">
                                                                                        {" "}
                                                                                        {convertTimeRangeToLocal(item?.time, item?.duration)}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="ant-typography calls-details cursor-hover ">
                                                                                <div className="ant-typography calls-name ">
                                                                                    <span className="ant-typography ">
                                                                                        <strong>  {item?.service?.type === 3
                                                                                            ? `${item?.service?.title} (${item?.duration} mins)`
                                                                                            : `${item?.consumer_name} (${item?.duration} mins)`}</strong>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="ant-typography ">
                                                                                    <span className="ant-typography calls-service-desc ">
                                                                                        {item?.service?.title}
                                                                                    </span>
                                                                                    <span className="ant-typography calls-price ">
                                                                                        (
                                                                                        <svg
                                                                                            stroke="currentColor"
                                                                                            fill="none"
                                                                                            strokeWidth={2}
                                                                                            viewBox="0 0 24 24"
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            height="1em"
                                                                                            width="1em"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <path d="M18 5h-11h3a4 4 0 0 1 0 8h-3l6 6" />
                                                                                            <path d="M7 9l11 0" />
                                                                                        </svg>
                                                                                        0)
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="ant-typography calls-footer ">
                                                                                <div
                                                                                    className="ant-row ant-row-end ant-row-middle "
                                                                                    style={{ marginLeft: "-4px", marginRight: "-4px" }}
                                                                                >
                                                                                    <div
                                                                                        className="ant-col ant-col-xs-12 ant-col-md-8 "
                                                                                        style={{ paddingLeft: 4, paddingRight: 4 }}
                                                                                    >
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() => {
                                                                                                setRegModal(true)
                                                                                                setModalData(item)
                                                                                            }}
                                                                                            className="ant-btn  ant-btn-default ant-btn-block btn-item-action"
                                                                                        >
                                                                                            <span>Registrations</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div
                                                                                        className="ant-col ant-col-xs-12 ant-col-md-8 "
                                                                                        style={{ paddingLeft: 4, paddingRight: 4 }}
                                                                                    >
                                                                                        <button
                                                                                            title="Call link will be activated 30 mins before booking time"
                                                                                            type="button"
                                                                                            className="ant-btn  ant-btn-primary ant-btn-block btn-dark"
                                                                                            disabled=""
                                                                                        >
                                                                                            <span>Start Webinar</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ant-row " />
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>

                                </>
                            ) : (
                                <>
                                    <div className="ant-row ant-row-center ant-row-middle ">
                                        <div className="ant-col text-center ant-col-xs-24 ant-col-md-7 ">
                                            <img
                                                alt="No Pending/Answered Queries"
                                                loading="lazy"
                                                width={170}
                                                height={170}
                                                decoding="async"
                                                data-nimg={1}
                                                className=""
                                                src={nowebinar}
                                                style={{ color: "transparent", height: "auto" }}
                                            />
                                            <div className="ant-space  ant-space-vertical ant-space-align-center ant-space-gap-row-middle ant-space-gap-col-middle">
                                                <div className="ant-space-item">
                                                    <h5
                                                        className="ant-typography "
                                                        style={{ marginBottom: 0, marginTop: 16 }}
                                                    >
                                                        Host a webinar
                                                    </h5>
                                                </div>
                                                <div className="ant-space-item">
                                                    <div
                                                        className="ant-typography "
                                                        style={{ margin: 0 }}
                                                    >
                                                        Address common questions by hosting a webinar for the
                                                        masses.
                                                    </div>
                                                </div>
                                                <div className="ant-space-item">
                                                    <button
                                                        type="button"
                                                        className="ant-btn  ant-btn-primary btn-dark"
                                                        style={{ marginTop: 8 }}
                                                    >
                                                        <span>+ Add New</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
            </div>
            {
                RegModal && (
                    <WebinarRegModal RegModal={RegModal} setRegModal={setRegModal} ModalData={ModalData} />
                )
            }
        </>
    );
}

export default WebinarUpcoming;
