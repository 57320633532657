import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Drawer,
    Row,
    Space,
    Table,
    Typography,
    message,
} from "antd";
import { digitalProductData, isMobile } from '../../Data/Data';
import MessageParticipants from './../Webinar/Modal/MessageParticipants';
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsArrowDownCircle, BsLightningCharge, BsSend } from "react-icons/bs";
import { IoClose, IoWalletOutline } from "react-icons/io5";

const { Paragraph, Text } = Typography;

const SalesModalDigital = ({ salesModal, setSalesModal }) => {

    const digitalPrductMainData = salesModal?.data
    const [loadingList, setLoadingList] = useState(false);
    const [consumerList, setConsumerList] = useState([]);
    const [emailScreen, setEmailScreen] = useState(false);
    const [showTestimonials, setShowTestimonials] = useState(false);
    const [buyDetails, setBuyDetails] = useState({});
    const [page, setPage] = useState(1);

    const getRegistrations = async (service_id, page) => {
        setLoadingList(true)

            .then((res) => {
                setBuyDetails(digitalProductData);
                setConsumerList(digitalProductData?.results);
                setLoadingList(false);
            })
            .catch((error) => {
                setLoadingList(false);
                message.error("Cannot fetch registrations");
            });
    };

    useEffect(() => {
        getRegistrations()
    }, [])


    const downloadRegistrations = async (id) => {

    };

    const handleTableChange = (pagination) => {
        setPage(pagination?.current);
    };


    const columns = isMobile === false
        ? [
            {
                key: 1,
                title: "Name",
                dataIndex: "consumer_name",
                width: "35%",
            },
            {
                key: 2,
                title: "Amount",
                width: "35%",
                render: (data) => {
                    return {
                        props: {},
                        children: (
                            <Row align="middle">
                                "$" {" "}
                                {data?.price}
                            </Row>
                        ),
                    };
                },
            },
        ]
        : [
            {
                key: 1,
                title: "Name",
                dataIndex: "consumer_name",
                width: "35%",
            },
            {
                key: 2,
                title: "Amount",
                width: "35%",
                render: (data) => {
                    return {
                        props: {},
                        children: (
                            <Row align="middle">
                                "$" {" "}
                                {data?.price}
                            </Row>
                        ),
                    };
                },
            },
            {
                key: 3,
                title: "Rating",
                dataIndex: "review",
                width: "35%",
                render: (data) => {
                    return {
                        props: {},
                        children: data?.rating ? `${data?.rating}/5` : "-",
                    };
                },
            },
        ];

    return (
        <>
            <Drawer
                title={null}
                placement={isMobile ? "right" : "bottom"}
                open={salesModal?.show}
                onClose={() => {
                    setSalesModal({
                        show: false,

                    });
                }}
                width={600}
                closable={false}
                height={isMobile ? "" : "75%"}
                destroyOnClose
                className="call-details-drawer"
                bodyStyle={{ padding: 0 }}
            >
                {emailScreen ? (
                    <MessageParticipants

                        setMessage={setEmailScreen}
                    />
                ) : showTestimonials ? (
                    <>

                    </>
                ) : (
                    <>
                        <Row className="details-header">
                            <Col xs={21} md={21}>
                                <Text className="webinar-title">{digitalPrductMainData?.service?.title}</Text>
                            </Col>
                            <Col xs={3} md={2} style={{ textAlign: "end" }}>
                                <IoClose
                                    className="drawer-close-icon"
                                    onClick={() => {
                                        setSalesModal?.false(false)
                                    }}
                                />
                            </Col>
                        </Row>

                        <Paragraph className="details-body">
                            <Row>
                                <Col
                                    md={{ span: 8 }}
                                    xs={{ span: 8 }}
                                    className="call-date"
                                    style={{
                                        borderTop: "0px",
                                    }}
                                >
                                    <Space align="start">
                                        <BsLightningCharge size={20} />
                                        {digitalPrductMainData?.total_sales} sales{" "}
                                    </Space>
                                </Col>
                                <Col
                                    md={{ span: 16 }}
                                    xs={{ span: 16 }}
                                    className="call-date"
                                    style={{
                                        borderLeft: "0px",
                                        borderRight: "0px",
                                        borderTop: "0px",
                                    }}
                                >
                                    <Space align="start">
                                        <IoWalletOutline size={20} />
                                        <Paragraph>
                                            Earnings: $
                                            {digitalPrductMainData?.total_earnings}
                                        </Paragraph>
                                    </Space>
                                </Col>
                            </Row>

                            {digitalPrductMainData?.total_ratings > 0 && (
                                <Paragraph
                                    className="booking-review"
                                    style={{ marginTop: "32px" }}
                                >
                                    <Row
                                        className="ask-review-desc"
                                        align="middle"
                                        style={{ padding: "12px 0px" }}
                                    >
                                        <Col md={9} xs={11} className="review-count">
                                            <Space>
                                                {"**"}
                                                <Col>
                                                    <Paragraph className="count-text">
                                                        {digitalPrductMainData?.avg_ratings}/5
                                                    </Paragraph>
                                                    <Paragraph className="review-text">
                                                        {digitalPrductMainData?.total_ratings} ratings
                                                    </Paragraph>
                                                </Col>
                                            </Space>
                                        </Col>
                                        <Col md={9} xs={8} className="review-count">
                                            <Space>
                                                {!isMobile && (
                                                    "**"
                                                )}
                                                <Col>
                                                    <Paragraph className="count-text">
                                                        {digitalPrductMainData?.total_testimonials}{" "}
                                                    </Paragraph>
                                                    <Paragraph className="review-text">
                                                        Testimonials{" "}
                                                    </Paragraph>
                                                </Col>
                                            </Space>
                                        </Col>
                                        <Col
                                            md={6}
                                            xs={5}
                                            className="all-ratings-btn"
                                            onClick={() => {

                                                setShowTestimonials(true);
                                            }}
                                        >
                                            <Button
                                                type="text"
                                                className="all-ratings-btn"
                                                disabled={digitalPrductMainData?.total_testimonials === 0}
                                            >
                                                See all <AiOutlineArrowRight />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Paragraph>
                            )}

                            <Row align="middle" justify="space-between" className="action-btns">
                                <Paragraph className="webinar-reg-title">Buyers</Paragraph>
                                <Space>
                                    <Button
                                        icon={<BsSend className="download-icon" />}
                                        block
                                        className="btn-edit"
                                        onClick={() => {
                                            setEmailScreen(true);
                                        }}
                                    >
                                        {isMobile ? "" : `Message all`}
                                    </Button>
                                    <Button
                                        icon={<BsArrowDownCircle className="download-icon" />}
                                        block
                                        className="btn-edit"
                                        onClick={() => {

                                            downloadRegistrations();
                                        }}
                                    ></Button>
                                </Space>
                            </Row>

                            <Table
                                columns={columns}
                                dataSource={consumerList}
                                loading={loadingList}
                                onChange={handleTableChange}
                                rowKey={(record, idx) => String(idx)}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <Paragraph style={{ textAlign: "center" }}>
                                            {isMobile ? (
                                                <Paragraph style={{ margin: 0 }}>
                                                    {record?.consumer_email}{" "}
                                                    {record?.consumer_phone
                                                        ? `| ${record?.consumer_phone}`
                                                        : ""}
                                                </Paragraph>
                                            ) : (
                                                <Space
                                                    align="center"
                                                    style={{
                                                        gap: 52,
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Paragraph style={{ margin: 0 }}>
                                                        Email: {record?.consumer_email}{" "}
                                                    </Paragraph>
                                                    {record?.consumer_phone && (
                                                        <Paragraph style={{ margin: 0 }}>
                                                            Mobile: {record?.consumer_phone}
                                                        </Paragraph>
                                                    )}
                                                </Space>
                                            )}
                                        </Paragraph>
                                    ),
                                    rowExpandable: (record) => record.name !== "Not Expandable",
                                }}
                                pagination={{
                                    total: buyDetails?.count,
                                    responsive: true,
                                    showSizeChanger: false,
                                    hideOnSinglePage: true,
                                    current: Number(page),
                                }}
                                scroll={isMobile ? { y: 240, x: 600 } : { y: 340 }}
                                bordered
                                className="drawer-table"
                            />
                        </Paragraph>
                    </>
                )}
            </Drawer >
        </>
    );
}

export default SalesModalDigital;
