import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "react-bootstrap";
import crossIcon from "../../../assets/images/cross-icon.png";
import mentorLogo from "../../../assets/images/mentor-logo.png";
import mentorWlogo from "../../../assets/images/mentor-wlogo.png";
import LoginModal from "../loginModal";
import { generateOtp, login, verifyEmail } from "../../../redux/actions/login";
import loginPasswordModal from "../loginwithPassword";
import SignUpModal from "../signUpModal";
import { v4 as uuidv4 } from "uuid";

const EmailOtpModel = ({ headerModal, setHeaderModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [show, setShow] = useState(true);
  const requestId = useSelector(
    (state) => state.otpReducer?.request?.requestId
  )
  const email = useSelector((state) => state.otpReducer?.request?.email);
  const deviceToken = useSelector(
    (state) => state.emailVerifyReducer?.headers?.["x-device-token"]
  );
  const verifyOtpResponseStatus = useSelector(
    (state) => state.emailVerifyReducer?.status
  );
  const userId = useSelector(
    (state) => state.emailVerifyReducer?.headers?.["x-user-id"]
  );
  let errorMsg = useSelector(
    (state) => state.emailVerifyReducer?.headers?.["x-error-msg"] || state?.loginReducer?.headers?.['x-error-msg']
  );

  useEffect(() => {
    setError(false)
  }, [])


  useEffect(() => {
    if (!error && (verifyOtpResponseStatus === 400)) {
      setError(true)
      setErrorMessage("user can't exist")
    }
  }, [verifyOtpResponseStatus])

  // const refreshToken = useSelector(
  //   (state) => state.loginReducer?.headers?.["x-refresh-token"]
  // );

  console.log("this is errrrr *** ", error, errorMessage)

  const resend = () => {
    setShow(false);
    timer();
  }
  const timer = setInterval(() => {
    setShow(true);
  }, 20000)

  const handleChangeOtp = (e) => {
    if (e.target.value.length === 6) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    setOtp(e.target.value);
  };

  // useEffect(() => {
  //   if (refreshToken) {
  //     if (headerModal?.userType === 'mentor') {
  //       navigate("/mentor-dashboard");
  //     }
  //     else {
  //       navigate("/dashboard");
  //     }
  //   }
  //   if (errorMsg) {
  //     setError(true)
  //   }

  // }, [refreshToken, errorMsg]);

  useEffect(() => {
    if (deviceToken) {
      // if (headerModal?.userType === 'mentor') {
      //   // navigate("/mentor-dashboard");
      //   window.location.href = '/mentor-dashboard'
      // }
      // else {
      //   // navigate("/dashboard");
      //   // window.location.reload()
      window.location.href = '/'
      // }
    }
  }, [deviceToken]);

  const verify = () => {
    const data = {
      requestId: requestId,
      otp,
      email: headerModal.email,
      purpose: "LOGIN",
      userType: headerModal?.userType
    };
    dispatch(verifyEmail(data));
  };

  const sendOtp = () => {
    const data = {
      email: headerModal.email,
      requestId: uuidv4(),
      purpose: "LOGIN",
    };
    dispatch(generateOtp(data));
  };

  const forlogin = (deviceToken, userId) => {
    const data = {
      userType: headerModal?.userType
    };
    dispatch(login({ data, deviceToken, userId }));
  };

  return (
    <Modal
      size="xl"
      centered
      show={headerModal.show}
      onHide={() =>
        setHeaderModal((prevState) => ({ ...prevState, show: false }))
      }
    >
      <ModalBody>
        <button
          type="button"
          className="cloose"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() =>
            setHeaderModal((prevState) => ({ ...prevState, show: false }))
          }
        >
          <div className="closebuttons">
            <span aria-hidden="true">
              <img src={crossIcon} />
            </span>
          </div>
        </button>
        {/* <form> */}
        <div className="signup-row">
          <div className="signup-leftcol">
            <div className="sign-form-cls">
              <div className="logo-sec">
                <h4>Welcome to</h4>
                <img src={mentorLogo} alt="Mentorpal" />
              </div>
              <div className="wel-space-cls">
                <div className="form-group change-pw">
                  <p>
                    Please Enter 6 Digit OTP sent{" "}
                  </p>

                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name=""
                    placeholder="Enter OTP"
                    className="form-control"
                    onChange={(e) => handleChangeOtp(e)}
                  />
                  {error && (
                    <p
                      style={{
                        color: "#800020",
                        marginTop: " 10px",
                        fontWeight: 500,
                      }}
                    >
                      {errorMessage ? errorMessage : errorMsg}
                    </p>
                  )}
                </div>
                <div className="singup-upp">
                  <button className="btn" onClick={verify} disabled={disable}>
                    Confirm
                  </button>
                </div>
                <div className="singup-otp">
                  <p>
                    Didn't receive the OTP yet? <a href="#" onClick={sendOtp} disabled={show === true ? false : true}
                    >Resend OTP</a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={() =>
                        setHeaderModal({
                          modal: loginPasswordModal,
                          show: true,
                          email,
                          userType: headerModal?.userType
                        })
                      }
                    >
                      Login with Password
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={() =>
                        // setHeaderModal({ modal: LoginModal, show: true, data: headerModal.data })
                        setHeaderModal({ modal: LoginModal, show: true, as: "mentor" })
                      }
                    >
                      Back to login
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="signup-rightcol">
            <div className="signup-cont">
              <div className="signup-logo d-none d-md-block">
                <img src={mentorWlogo} alt="" />
              </div>
              <div className="signup-inner">
                <p>
                  Mentorship from the global expert. join one-on-one Live
                  session and group session from the verified mentors at your
                  fingertips
                </p>
                <button
                  type="button"
                  onClick={() =>
                    setHeaderModal({ modal: SignUpModal, show: true, as: "mentee" })
                  }
                >
                  New to mentorpal? Sign up now
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </form> */}
      </ModalBody>
    </Modal>
  );
};

export default EmailOtpModel;
