import { Button, Popover, Typography } from "antd";
import { useEffect, useState } from "react";
import { FloatStep, isMobile } from "../../Data/Data";
import DashboardChecklist from "./DashboardChecklist";

const { Paragraph } = Typography;
const ChecklistFloating = (props) => {
    const user = {
        bookings_count: 10,
        "date_joined": "2023-04-21T16:23:42.730118+05:30",
    }
    const nextSteps = FloatStep
    const [checkList, setCheckList] = useState({});
    const [category, setCategory] = useState("");
    const [loadingSteps, setloadingSteps] = useState(false);


    useEffect(() => {
        if (nextSteps)
            if (
                user?.bookings_count > 5 ||
                nextSteps?.beginner?.total_steps ===
                nextSteps?.beginner?.completed_steps
            ) {
                setCategory("advanced");
                setCheckList(nextSteps?.advanced);
            } else {
                setCategory("beginner");
                setCheckList(nextSteps?.beginner);
            }
    }, [nextSteps]);

    const ChecklistContent = () => {
        return (
            <DashboardChecklist
                checkList={checkList}
                category={category}
                type="Floating Onboarding"
                user={user}
            />
        );
    };

    return (
        <div>
            {!loadingSteps &&
                // to show advance check
                nextSteps?.advanced?.total_steps !==
                nextSteps?.advanced?.completed_steps &&
                user?.bookings_count < 15 &&
                // mobile selective pages only
                (isMobile && (
                    <Popover
                        content={ChecklistContent}
                        trigger="click"
                        placement="top"
                        overlayClassName="checklist-popover"
                    >
                        <Button
                            className="advance-checklist-btn"
                            style={props?.style ?? {}}
                        >
                            My checklist
                            <Paragraph className="checklist-count">
                                {checkList?.total_steps - checkList?.completed_steps}
                            </Paragraph>
                        </Button>
                    </Popover>
                ))
            }
        </div>
    );
};

export default ChecklistFloating;
