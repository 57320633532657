import React, { useState } from "react";
import ReactSelect from "react-select";
import { GraduationYear, Qualification } from "../../../../assets/data/data";
// import "./UpdateCountry.css";
// import { countries } from "../../../../assets/data/data";
// import ReactSelect from "react-select";

const UpdateCourse = ({ CourseData, getUpdatedValues }) => {
    const customCourseData = { label: CourseData, value: CourseData };
    const [selectedCourse, setSelectedCourse] = useState([
        customCourseData,
    ]);
    const setCourseValue = (e) => {
        let yearList = [];
        yearList?.push(e?.label);
        setSelectedCourse(e);
    };
    const handleOnClick = () => {
        // let country = selectedCourse[0]?.label;
        let tempData = { course: selectedCourse?.label };
        getUpdatedValues(tempData);
    };
    return (
        <div className="update-country-main-container">
            <h7>Select Qualification</h7>
            <div className="country-input">
                <ReactSelect
                    value={selectedCourse ? selectedCourse : ""}
                    onChange={setCourseValue}
                    placeholder="Eg.Master's Degree "
                    name="course"
                    options={Qualification}
                    className="basic-multi-select text-left"
                    classNamePrefix="select"
                />
            </div>
            <button onClick={handleOnClick} className="update-btn-country">
                Update
            </button>
        </div>
    );
};

export default UpdateCourse;
