import React from 'react'
import '../onboarding.Main.css'
import Availability from '../../adminDashboard/Availability'
import AvailableHours from '../../adminDashboard/Availability/AvailableHours'

export const StepM3 = () => {
    return (
     <>
            <AvailableHours />
     </>

    )
}
