import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import ProtectedHeader from "../ProtectedHeader/ProtectedHeader";
import { Connect } from "react-redux";

import { bannerImg, ic1, ic3 } from "../../assets/images/export-images";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/custom.css";
import "../../assets/css/header.css";
import "../../assets/css/main-banner.css";
import "../../assets/css/world-class-mentorship.css";
import "../../assets/css/why-choose-mentorpal.css";
import "../../assets/css/already-mentoring-people.css";
import "../../assets/css/event-sec.css";
import "../../assets/css/top-mentor.css";
import "../../assets/css/save-time.css";
import "../../assets/css/help-us.css";
import "../../assets/css/footer.css";
import "../../assets/css/popular-sessions.css";
import "../../assets/css/signup-popup.css";
import "../../assets/css/media.css";
import { logged, REACT_USER_ID } from "../auth/tokenProvider";
import { getUserDetails } from "../../redux/actions/login";
import Header from "../header";
import Footer from "../footer";
import "./mbti.css";
import {
  personalityTestAction,
  testResponse,
} from "../../redux/actions/common";
import {
  getTopMentorsAction,
  getOpenSessionsAction,
} from "../../redux/actions/mentor";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
  );
  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });
  const heading = useSelector((state) => {
    const data = state.PersonalityTestReducer?.data || [];
    return data;
  });

  const testResponseData = useSelector((state) => {
    const data = state.testResponseReducer?.data || {};
    return data;
  });

  console.log("testResponse", testResponseData);

  const isAuth = useSelector((state) => {
    const data =
      (state.userDetailsReducer?.data &&
        state.userDetailsReducer?.data[0]?.user_id) ||
      state.passwordLoginReducer?.data?.user_id;
    return data ? true : false;
  });

  const questionsData = useSelector((state) => {
    const data = state.personalityTestReducer?.data;
    let str;
    if (data?.length) {
      str = data[0]?.questions;
      //let d2 = '[' + d.slice(2, d.length - 2) + ']'
      // str = JSON.parse(d2)
    }
    return data?.length > 0 ? str : [];
  });



  let lastPageIndex = currentPage * perPage;

  let firstPageIndex = lastPageIndex - perPage;

  const currentQuestions = questionsData.slice(firstPageIndex, lastPageIndex);

  let totalpages = questionsData.length;

  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(totalpages / perPage); i++) {
    pageNumber.push(i);
  }


  useEffect(() => {
    if (questions.length === 0) {
      dispatch(personalityTestAction());
    }
  }, []);

  const handleQuestionsResponse = (item, value, id, question_id = 0) => {
    // document.getElementsByName(name).classList.remove('active');
    for (let i = 0; i < 7; i++) {
      document.getElementById(question_id + "_" + i).classList.remove("active");
    }
    document.getElementById(id).classList.add("active");
    document.getElementById(question_id).classList.remove("active");
    document.getElementById(question_id).classList.add("inactive");
    let element = document.getElementById(question_id + 1);
    element.classList.add("active");
    element.classList.remove("inactive");
    setTimeout(function () {
      element.scrollIntoView({ behavior: "smooth" })

    }, 300);

    let d = answeredQuestions;
    let user_response = {
      text: item.text,
      answer: value,
      section: item.section,
    };
    let index = d.filter((val) => {
      if (val.text === item.text) {
        return d.indexOf(val);
      }
    });
    // console.log("this is index ", index)
    if (index?.length) {
      d.splice(index[0]);
      d.push(user_response);
    } else {
      d.push(user_response);
    }
    // console.log("---------------------------------------------------------------------")
    // console.log(d.indexOf(item), d)
    // console.log("---------------------------------------------------------------------")
    setAnsweredQuestions(d);
    // setAnsweredQuestions(previous => [...previous])
    // console.log(" on change value", item.text, value)
  };
  console.log(" item list - ", answeredQuestions);

  const response = () => {
    const data = {
      name: name,
      email: email,
      user_id: userDetails?.user_id,
      questions: answeredQuestions,
    };
    dispatch(testResponse(data));
    navigate("/personality-result");
  };

  const nextFun = () => {
    setCurrentPage(currentPage + 1);
    setActive(currentPage === 1 ? 0 : (currentPage - 1) * perPage);
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 400);



  };


  const l = [
    {
      ariaLabel: "agree max",
      dataIndex: 0,
      ariaChecked: "false",
      tabIndex: -1,
      value: 3,
      className: "option agree max",
    },
    {
      ariaLabel: "agree med",
      dataIndex: 1,
      ariaChecked: "false",
      tabIndex: -1,
      value: 2,
      className: "option agree med",
    },
    {
      ariaLabel: "agree min",
      dataIndex: 2,
      ariaChecked: "false",
      tabIndex: -1,
      value: 1,
      className: "option agree min",
    },
    {
      ariaLabel: "neutral",
      dataIndex: 3,
      ariaChecked: "false",
      tabIndex: -1,
      value: 0,
      className: "option neutral",
    },
    {
      ariaLabel: "disagree min",
      dataIndex: 4,
      ariaChecked: "false",
      tabIndex: -1,
      value: -1,
      className: "option disagree min",
    },
    {
      ariaLabel: "disagree med",
      dataIndex: 5,
      ariaChecked: "false",
      tabIndex: -1,
      value: -2,
      className: "option disagree med",
    },
    {
      ariaLabel: "disagree max",
      dataIndex: 6,
      ariaChecked: "false",
      tabIndex: -1,
      value: -3,
      className: "option disagree max",
    }
  ];

  return (
    <>
      {loggedIn ? <ProtectedHeader /> : <Header />}
      <section
        className="banner-sec"
        style={{ backgroundImage: `url(${bannerImg})` }}
      >
        <div className="container">
          <div className="mentroship-sec">
            <h3>Personality Assessment</h3>
          </div>
        </div>
      </section>
      <section className="mentors-tabs" id="mbti_mentor_Tabs" style={{ height: "20vh" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="box-wrap cv-wrap">
                <div className="icon-cls">
                  <img alt="icon" src={ic1} />
                </div>
                <div className="mentor-cont yourCv-cont">
                  <h4>Complete The Assessment</h4>
                  <p>
                    Complete our comprehensive personality assessment and gain
                    valuable insights to enhance self-awareness and personal
                    growth.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="box-wrap cv-wrap">
                <div className="icon-cls">
                  <img alt="icon" src={ic3} />
                </div>
                <div className="mentor-cont yourCv-cont">
                  <h4> Unleash Your Inner Strengths</h4>
                  <p>
                    Identify and maximize your innate strengths with our
                    personality test, and unlock your full potential to achieve
                    your life goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="box-wrap cv-wrap">
                <div className="icon-cls">
                  <img alt="icon" src={ic3} />
                </div>
                <div className="mentor-cont yourCv-cont">
                  <h4>Unlock Your Personality</h4>
                  <p>
                    Discover your unique traits and strengths with our
                    personality test, and unlock your full potential for a
                    fulfilling life.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="box-wrap cv-wrap">
                <div className="icon-cls">
                  <img alt="icon" src={ic3} />
                </div>
                <div className="mentor-cont yourCv-cont">
                  <h4>Delve Deeper into Your Personality</h4>
                  <p>
                    Take our comprehensive personality test and gain a deeper
                    understanding of yourself to enhance personal and
                    professional growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- World className Mentorship --> */}
        </div>
      </section>
      <section style={{ margin: "20px" }} >
        <div className="quiz-wrapper">
          <h2 style={{ "margin-left": "25em" }} >Screen {currentPage} / {totalpages / perPage}</h2>
          <div>
            <div data-chunk="quiz-chunk">
              <div className="test-questions comp">
                {currentQuestions?.map((item, idx) => (
                  <div
                    data-v-7250ab3f=""
                    key={
                      idx +
                      (currentPage === 1 ? 0 : (currentPage - 1) * perPage)
                    }
                    id={
                      idx +
                      (currentPage === 1 ? 0 : (currentPage - 1) * perPage)
                    }
                    className={
                      idx === 0 ? "question active" : "question inactive"
                    }
                  >
                    <div data-v-7250ab3f="" className="statement">
                      <span data-v-7250ab3f="" id="stmt_z1cq8kfja6">
                        {item?.text}
                      </span>
                    </div>
                    <div
                      data-v-7250ab3f=""
                      role="radiogroup"
                      aria-labelledby="stmt_z1cq8kfja6"
                      className="decision"
                    >
                      <div data-v-7250ab3f="" className="caption agree">
                        Agree
                      </div>
                      <div data-v-7250ab3f="" className="options">
                        {l?.map((val, idy) => {
                          return (
                            <div
                              data-v-7250ab3f=""
                              role="radio"
                              id={
                                idx +
                                (currentPage === 1
                                  ? 0
                                  : (currentPage - 1) * perPage) +
                                "_" +
                                val.dataIndex
                              }
                              aria-label={val.ariaLabel}
                              data-index={val.dataIndex}
                              aria-checked={val.ariaChecked}
                              tabIndex={val.tabIndex}
                              className={val.className}
                              onClick={() =>
                                handleQuestionsResponse(
                                  item,
                                  val.value,
                                  idx +
                                  (currentPage === 1
                                    ? 0
                                    : (currentPage - 1) * perPage) +
                                  "_" +
                                  val.dataIndex,
                                  idx +
                                  (currentPage === 1
                                    ? 0
                                    : (currentPage - 1) * perPage)
                                )
                              }
                            >
                              <span
                                data-v-7250ab3f=""
                                aria-hidden="true"
                              // className="far fa-check"
                              >
                                <svg style={{ width: "50px", height: "50px" }}
                                  xmlns="http://www.w3.org/2000/svg"

                                  fill="currentColor"
                                  class="bi bi-check"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                </svg>
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div data-v-7250ab3f="" className="caption disagree">
                        Disagree
                      </div>
                    </div>
                    <div data-v-7250ab3f="" className="decision mobile">
                      <div data-v-7250ab3f="" className="caption agree">
                        Agree
                      </div>
                      <div data-v-7250ab3f="" className="caption disagree">
                        Disagree
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        {currentPage < pageNumber.length ? (
          <div className="centre">
            <div className="singupp_upp">
              <button className="btn" onClick={nextFun}>
                Next
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="input_field_style">
              <div className="form-group  centre">
                <input
                  type="text"
                  name=""
                  placeholder="Name"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="form-group centre">
                <input
                  type="text"
                  name=""
                  placeholder="Email"
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="centre">
              <div className="singupp_upp">
                <button className="btn" onClick={response}>
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <!-- Footer --> */}
      <Footer />
    </>
  );
};

export default Home;
