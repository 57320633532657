import React from 'react';
import MasterHeader from '../MasterCourses/MasterHeader/MasterHeader';
import { bannerImg, crossIcon, eventBg, menuIcon } from '../../assets/images/export-images';
import profile1 from '../MasterCourses/assets/image11.jpg'
import './hostCourse.css'
import job from '../MasterCourses/assets/job.png';
import { Course_details_faqData, host_course_features } from '../MasterCourses/data';
import MasterFooter from '../MasterCourses/MasterFooter';
import Testimonial from '../Testimonial/testimonial';
import exploreImage from '../MasterCourses/assets/learn_platform.png'
import community from './assets/community.jpg'
import colleagueshaving from './assets/meeting5.png'
import course_design from './assets/course_design.jpg'
import graph from './assets/graph.jpg'
import learning_rating from './assets/learning_rating.jpg'
import pricing_plain from './assets/pricing_plain.jpg'
import studyin_class from './assets/studying-class.jpg'
import payment_gatway from './assets/mobile-banking-concept.jpg'
import {
	thunder,
	addcontact,
	book,
	world,
	money,
	courses,
	courses2,
	courses3,
	productmem,
	productweb,
	productserv
} from "../../assets/images/export-images";
import { useState } from 'react';
import logo from '../../assets/images/logo-w.png'
import LoginModal from '../modals/loginModal';
import { useNavigate } from 'react-router-dom';
import CourseSubscribe from '../MasterCourses/CourseSubscribe';



const HostCourseLandingPage = () => {
	const faq = [{
		question: "Can I offer certificates of completion for my mentorpal.ai courses?",
		answer: "Yes, we have support for providing certificates to the students automatically. You can also customise the delivery and template of the certificate."
	},
	{
		question: "Is there a limit to the number of courses I can create with the mentorpal.ai Course Creator?",
		answer: "No, you can create unlimited courses on mentorpal.ai"
	},
	{
		question: "Can I offer discounts or promotions for my mentorpal.ai courses?",
		answer: "Yes, we have support for running promotions like coupons and discounts."
	},
	{
		question: "Can I have a step-by-step learning path where the users unlock new chapters upon completion of chapters?",
		answer: "Yes, we support sequential learning path in our course builder."
	},
	{
		question: "Can I have multiple instructors within one course",
		answer: "Yes, we support multiple instructors to be a part of one course"
	}
	]

	const stepsData = [
		{ icon: courses, text: "Craft your course" },
		{ icon: courses2, text: "Share your insights" },
		{ icon: courses3, text: "Inspire learners worldwide" }
	];

	const productsData = [
		{ icon: productweb, title: "Live Webinars", text: "Engage with your audience through real-time web sessions" },
		{ icon: productmem, title: "Membership Plans", text: "Launch exclusive membership plans and nurture your community" },
		{ icon: productserv, title: "Digital Resources", text: "Monetize your expertise with e-books, guides, and more" }
	];
	const [isOpen, setIsOpen] = useState(true);
	const [headerModal, setHeaderModal] = useState({});

	const navigate = useNavigate();
	const point__svg = (<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24px"
		viewBox="0 0 24 24"
		width="24px"
		fill="#d96980"
	>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
	</svg>)

	const flag = (<svg
		xmlns="http://www.w3.org/2000/svg"
		width={25}
		height={25}
		fill="#d96980"
		className="bi bi-patch-check"
		viewBox="0 0 16 16"
	>
		<path
			fillRule="evenodd"
			d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
		/>
		<path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
	</svg>
	)
	return (
		<>
			{/* Header */}
			<header className={` header-cls`}>
				<meta
					name="viewport"
					content="width=device-width, initial scale=1.0,user-scalable=no"
				/>
				<div
					className="container"
					style={{ paddingLeft: "0", paddingRight: "0" }}
				>
					<nav
						className="menu navbar navbar-expand-lg navbar__fix"
						style={{ display: "", flexBasis: "" }}
					>
						<a className="navbar-brand" href="/master-courses">
							<img src={logo} alt="logo" className="header-logo ms-auto" />
						</a>
						<button
							className={"navbar-toggler"}
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#headerHandler"
							aria-controls="headerHandler"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<img
								onClick={() => {
									setIsOpen(!isOpen);
								}}
								alt=""
								src={isOpen ? menuIcon : crossIcon}
							/>
						</button>
						<div
							className="menu collapse navbar-collapse rounded Mobile_menu m-2"
							style={{ display: "", flexBasis: "100%" }}
							id="headerHandler"
						>
							<ul className="navbar-nav align-items-center Mobile_menu_list">


								<li className="nav-item">
									<a

										className="button btn get-started"
										onClick={() => {
											setHeaderModal({ modal: LoginModal, show: true, as: "mentor", });
										}}

									>
										Host a course
									</a>
								</li>
								<li className="nav-item">
									<a
										href='explore-courses'
										className="button btn get-started"


									>
										Explore courses
									</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</header>
			{/* hero section */}
			<section
				className="banner-sec"
				style={{ backgroundImage: `url(${bannerImg})`, padding: '7% 0% !important' }}
			>      <div className="banner-summary container  text-center">


					<div className="banner-summary-content">
						<h1>
							Allow your expertise to shine through your content
						</h1>

						<p className="text-size-medium">
							Rising above the crowd entails more than just having distinct skills; it encompasses how effectively you showcase them. TrainerCentral grants you the liberty to craft remarkable content that distinctly mirrors your wisdom and background.                                </p>

						<div className="get-started-cls d-flex flex-column flex-sm-row " style={{ textAlign: "initial" }}>
							<a onClick={() => {
								setHeaderModal({ modal: LoginModal, show: true, as: "mentor", });
							}}>
								<button type="button" className="find-btn btn btn-primary">
									Signup Free
								</button>
							</a>
							<button type="button" className="btn btn-primary d-sm-block mt-2 mt-sm-0" onClick={() => { navigate('/pricing') }}>
								Pricing
							</button>
						</div>

					</div>



				</div>
			</section>
			{/* how does the plateform work */}
			<section className="container">
				<>
					<div className="row pt-1">
						<div className="col-sm-6 col-md-4">
							<div className="benefit_header">
								<div className="benefit_sub">
									<span>

										{flag}
									</span>

									<h6 className="header_text">HOW DOES THE SYSTEM FUNCTION?</h6>
								</div>
								<h2 className="header_head">
									All You Require To Market Online Educational Courses
								</h2>
							</div>

							<div className="benefits_para benefits_para_1">
								<div className="benefits_division">
									<span className="tick">
										{point__svg}
									</span>
									<p>
										Host your digital courses in our private-label online course platform with
										no platform charges, unlimited students, and without risks.
									</p>
								</div>
								<div className="benefits_division">
									<span className="tick">
										{point__svg}
									</span>
									<p>
										Develop your very own online courses and website within minutes using
										our course and website constructor, without the need for design or technical expertise.
									</p>
								</div>
								<div className="benefits_division">
									<span className="tick">
										{point__svg}
									</span>
									<p>
										Enhance your courses using our potent marketing features. Dispatch
										newsletters, expand your email listing, optimize your advertisements.
									</p>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-md-4">
							<img
								className="benefit_desktop  img______host_course"
								src={studyin_class}
								alt="Computer Desktop"
							/>
						</div>
					</div>
				</>
			</section>

			{/* image screen  */}
			<section className="container">
				<div className="frequently_asked ">
					<div className="row">
						<div className="col-md-6">
							<div className="mentoring-cont">
								<div className="faq__pricing">
									<div className="inner-title">
										<h2>
											Interact with students in live sessions
										</h2>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="mentoring-cont">
								<ul>
									<li>
										<span className="tick">
											{point__svg}
										</span>
										<a >

											<span className="cont-cls">Conduct interactive online classes</span>
										</a>
									</li>
									<li>
										<span className="tick">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												height="24px"
												viewBox="0 0 24 24"
												width="24px"
												fill="#000000"
											>
												<path d="M0 0h24v24H0V0z" fill="none" />
												<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
											</svg>
										</span>
										<a >
											<span className="cont-cls">
												Stream your video content
											</span>
										</a>
									</li>

								</ul>
								<ul>
									<li>
										<span className="tick">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												height="24px"
												viewBox="0 0 24 24"
												width="24px"
												fill="#000000"
											>
												<path d="M0 0h24v24H0V0z" fill="none" />
												<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
											</svg>
										</span>
										<span className="cont-cls">
											Illustrate ideas using a digital whiteboard
										</span>
									</li>
									<li>
										<span className="tick">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												height="24px"
												viewBox="0 0 24 24"
												width="24px"
												fill="#000000"
											>
												<path d="M0 0h24v24H0V0z" fill="none" />
												<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
											</svg>
										</span>
										<a >
											<span className="cont-cls">
												Address questions promptly through Q&amp;A
											</span>
										</a>
									</li>

								</ul>
							</div>
						</div>
					</div>
				</div>
				<img
					className="animation-element fadeinup1 in-view"
					src={colleagueshaving}
					alt="Live Interaction with Students"
				/>
			</section>

			{/* our progress */}
			<section className="why-choose why_choose_ment">
				<div className="container">
					<div className="mentorpal-sec">
						<div className="row">
							<div className="col-sm-6 col-md-4">
								<div className="mentorpal-box">
									<div className="serve-cont">
										<div className="top-text">We mean it when we say </div> <div className="special-text">Excellence</div>

										<btn onClick={() => {
											setHeaderModal({ modal: LoginModal, show: true, as: "mentor", });
										}} className="css-button-shadow-border--blue">Start your journey</btn> <btn className="css-button-neumorphic">Request a demo</btn>
									</div>
								</div>
							</div>
							<div className="col-sm-6 col-md-4">
								<div className="mentorpal-box">
									<div className="icon">
										<img alt="icon" src={thunder} />
									</div>
									<div className="serve-cont">
										<div className="Number-text">3K+<span className="special-text"> Mentors</span></div>
										<p>Empowering growth</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 col-md-4">
								<div className="mentorpal-box">
									<div className="icon">
										<img alt="icon" src={addcontact} />
									</div>
									<div className="serve-cont">
										<div className="Number-text">100K+<span className="special-text"> Learners</span></div>
										<p>on the journey of knowledge</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 col-md-4">
								<div className="mentorpal-box">
									<div className="icon">
										<img alt="icon" src={book} />
									</div>
									<div className="serve-cont">
										<div className="Number-text">5K+<span className="special-text"> Courses</span></div>
										<p>Offering diverse expertise</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 col-md-4">
								<div className="mentorpal-box">
									<div className="icon">
										<img alt="icon" src={world} />
									</div>
									<div className="serve-cont">
										<div className="Number-text">50+</div>
										<p>countries connected</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 col-md-4">
								<div className="mentorpal-box">
									<div className="icon">
										<img alt="icon" src={money} />
									</div>
									<div className="serve-cont">
										<div className="Number-text">120M+<span className="special-text"> Earnings</span></div>
										<div className="serve-cont">
											<p>Shared with mentors </p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* create your course */}
			<section id="section-learningPlatform" className="LearningPlatform full-container">
				<div className="LearningPlatform__left ">
					<h2 className="LearningPlatform_title">
						Let's Begin Crafting Your Inaugural Course
					</h2>
					<div className="LearningPlatform_subText">
						More than 100K creators have placed their confidence in our comprehensive platform for seamless online course development and sales. Enroll today!
					</div>
					<div className="get-started-cls explore____btn ">
						<a onClick={() => {
							setHeaderModal({ modal: LoginModal, show: true, as: "mentor", });
						}}>
							<button type="button" className="find-btn btn btn-primary">
								Start Creating
							</button>
						</a>
					</div>
				</div>
				<div className="LearningPlatform__right">
					<img src={exploreImage} alt="Exploring Opportunities" />
				</div>
			</section>


			{/* online payments */}
			<section className="container">
				<>
					<div className="row">
						<div className="col-sm-6 col-md-4">
							<img
								className="benefit_desktop payment_img"
								src={payment_gatway}
								alt="Payment Desktop"
							/>
						</div>
						<div className="col-sm-6 col-md-4">
							<div className="benefit_header online_payments_sec">
								<div className="benefit_sub sodi soki">
									<span>

										{flag}
									</span>
									<h6 className="header_text sodi">ACCEPT ONLINE PAYMENTS</h6>
								</div>
								<h2 className="header_head soji">Effortlessly Process Online Payments</h2>
							</div>
							<div className="payment_shift benefits_sales benefit_counter benefit_right">
								<div className="benefits_pitch">
									<h3 className="text">Stripe and PayPal Integration</h3>
									<p className="para">
										Seamlessly connect with your preferred payment gateways (PayPal, Stripe) and start accepting secure online credit card payments from students across the globe.
									</p>
								</div>
								<div className="benefits_pitch">
									<h3 className="text">Receive Payments in Your Local Currency</h3>
									<p className="para">
										Get paid in your local currency with support for numerous currencies. Supported options include USD, CAD, EUR, GBP, INR, and more.
									</p>
								</div>
								<div className="benefits_pitch">
									<h3 className="text">Instant Payouts, No Transaction Fees</h3>
									<p className="para">
										Every payment is swiftly deposited into your Stripe or PayPal account immediately after a student's purchase. We do not impose transaction fees, and you retain full control over your funds, with the ability to withdraw at any time.
									</p>
								</div>
								<div className="benefits_pitch">
									<h3 className="text">Simplified Payment Setup</h3>
									<p className="para">
										Easily configure your Stripe or PayPal account within seconds, requiring only a single click—no technical expertise necessary.
									</p>
								</div>
							</div>
						</div>
					</div>
				</>
			</section>

			{/* course features */}
			<section className="benefits">
				<div className="container">
					<h2 className="section-heading">
						Course Features and Learning Opportunities
					</h2>
					<div className="benefits-summary">
						<div className="row">
							{host_course_features.map((benefit, index) => (
								<div key={index} className="col-md-4 col-6">
									<div className="benefit-thumb">
										<i className="icons icon-benefit" title={benefit.title}>
											<img src={benefit.icon} alt="" />
										</i>
										<h3>{benefit.title}</h3>
										<p>{benefit.description}</p>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="get-started-cls">
						<a href="https://wa.me/message/LUEGOQ2MKAI5E1">
							<button type="button" className="find-btn btn btn-primary">
								Start a Chat
							</button>
						</a>
						<button type="button" className="btn btn-primary">
							Call us
						</button>
					</div>
				</div>
			</section>


			<section className="container">
				<>
					<div className="row padding____section">
						<div className="col-sm-6 col-md-4">
							<img
								className="benefit_desktop payment_img left_desktop"
								src={course_design}
								alt="User Interface Desktop"
							/>
						</div>
						<div className="col-sm-6 col-md-4">
							<div className="benefit_header usr_frndly">
								<div className="benefit_sub sodi soki">
									<span>

										{flag}
									</span>
									<h6 className="header_text sodi">USER-FRIENDLY INTERFACE</h6>
								</div>
								<h2 className="header_head soji">
									Building Your Courses Couldn't Be Easier
								</h2>

							</div>
							<div className="">
								<p className="one_para uno">
									With our super easy-to-use website and course builder, you will have your
									professional-looking online course website up and running in no time.
								</p>
								<div className="benefits_para">
									<div className="benefits_division">
										<span className="tick">
											{point__svg}
										</span>
										<p className="uni">
											No Coding Required: You don't have to be a developer or even be
											tech-savvy to get your courses up and running. Our course and website
											builder is designed to be easily used by anyone, and if needed we will
											support you all the way.
										</p>
									</div>
									<div className="benefits_division">
										<span className="tick">
											{point__svg}
										</span>
										<p className="uni">
											No Design Skills Needed: You don't have to be a designer or have
											design skills to create a beautiful online course landing page and
											website. Just set some text and a few images, and we will create a
											highly optimized course landing page for you.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* First Class Experience */}
					<div className="row padding____section">
						<div className="col-sm-6 col-md-4">
							<div className="benefit_header">
								<div className="benefit_sub">
									<span>

										{flag}
									</span>
									<h6 className="header_text">FIRST-CLASS STUDENT EXPERIENCE</h6>
								</div>
								<h2 className="header_head">
									Give Your Students The Best Possible Course Experience
								</h2>

							</div>
							<div className="benefits_sales benefit_counter benefit_top fce_pitch">
								<div className="benefits_pitch">
									<h3 className="text">
										Make Your Course More Interactive With Assignments and Quizzes
									</h3>
									<p className="para">
										Give your students an engaging course-taking experience with
										assignments, quizzes and other interactive activities.
									</p>
								</div>
								<div className="benefits_pitch">
									<h3 className="text">Responsive Course Player</h3>
									<p className="para">
										Build beautiful video courses that are a joy to watch in desktop, tablet
										and phone, thanks to our responsive course player.
									</p>
								</div>
								<div className="benefits_pitch">
									<h3 className="text">Digital Downloads &amp; Written Lessons</h3>
									<p className="para">
										Add digital downloads to your courses (PDFs, zip files), such as
										assignments and other support material. Add complementary written
										lessons, with text and images.
									</p>
								</div>
								<div className="benefits_pitch">
									<h3 className="text">Student Discussions</h3>
									<p className="para">
										Your students will be able to engage actively with you and each other
										directly in each lesson, as well as search for answers, making the
										course more fun and interactive.
									</p>
								</div>
							</div></div>
						<div className="col-sm-6 col-md-4"><img
							className="benefit_desktop payment_img right_desktop img______host_course"
							src={learning_rating}
							alt="First Class Experience Desktop"
						/></div>
					</div>

				</>

			</section>
			{/* <!-- event --> */}
			<section
				className="event-mentor-sec space-cls"
				style={{
					backgroundImage: `url(${eventBg})`,
					backgroundAttachment: "fixed",
				}}
			>
				<div className="why_choose_ment">
					<div className="container">
						<div className="inner-title">
							<h3>Unlock Earning Potential with MentorPal.ai</h3>
							<h2>
								Join our diverse community of 150,000+ mentors on MentorPal.ai. Empower others through mentorship, create educational content, host interactive sessions, and more.
							</h2>
						</div>
						<div className="get-started-cls">
							<a href="https://wa.me/message/LUEGOQ2MKAI5E1">
								<button type="button" className="find-btn btn btn-primary">
									Start a Conversation
								</button>
							</a>
							<a type="button" className="btn btn-primary" href="mailto:help@mentorpal.ai">
								Reach Out
							</a>
						</div>
					</div>
				</div>
			</section>
			{/* choose your modal */}
			<section className="container padding____section">
				<div className="row">
					<div className="col-sm-6 col-md-4">
						<div className="benefit_header model_bnft_hdr">
							<div className="benefit_sub">
								<span>

									{flag}
								</span>
								<h6 className="header_text">CHOOSE YOUR PATH TO SUCCESS</h6>
							</div>
							<h2 className="header_head">
								Offer Courses, Bundles, Enterprise Plans, Subscriptions, and More
							</h2>
						</div>
						<div className="benefits_sales benefit_counter  fce_pitch ">
							<p>
								MentorPal.ai supports a versatile range of business models for your course creation journey.
							</p>
							<p>
								You have the freedom to sell your courses individually, with optional discount coupons. Additionally, you can integrate them into subscription plans, create enticing Bundles, and even target corporate clients through Enterprise plans for team access. Embrace the opportunity to offer Lifetime plans to your students as well.
							</p>
						</div>
					</div>
					<div className="col-sm-6 col-md-4">
						<img
							className="benefit_desktop payment_img right_desktop photo img______host_course"
							src={pricing_plain}
							alt="Model Desktop"
						/>
					</div>
				</div>
			</section>
			{/* {simple step} */}
			<section className="why-choose why_choose_ment">

				<div className="container">
					<div className="inner-title">
						<div className="title-text">3 simple steps <span style={{ color: "black" }}>to get started</span></div>
						<p>Unlock your potential with our user-friendly tools for creating impactful courses.</p>
					</div>
					<div className="mentorpal-sec">
						<div className="row boxed">
							{
								stepsData.map((item, index) => (
									<div className="col-sm-6 col-md-4" key={index}>
										<div className="mentorpal-box">
											<div className="icon-img">
												<img alt="icon" src={item.icon} />
											</div>
											<div className="serve-cont">
												<p className="course">{item.text}</p>
											</div>
										</div>
									</div>
								))
							}
						</div>
					</div>
				</div>
			</section>
			{/* Brand graph */}
			<section className="container padding____section">
				<div className="row">
					<div className="col-sm-6 col-md-4">
						<div className="benefit_header">
							<div className="benefit_sub">
								<span>

									{flag}
								</span>
								<h6 className="header_text">BUILD YOUR DISTINCT BRAND</h6>
							</div>
							<h2 className="header_head">
								Maintain Total Autonomy Over Your Online Course Enterprise
							</h2>
						</div>
						<div className="benefits_sales benefit_counter  fce_pitch" >
							<p className="pull">
								With MentorPal.ai, you are always at the helm of your online course enterprise. This translates to complete ownership of your brand, inclusive of your website URL.
							</p>
							<p className="pull">
								This also signifies that you enjoy immediate access to every payment from your students. We don't impose any fees, ensuring you can withdraw your funds in their entirety whenever you wish.
							</p>
							<p className="pull">
								Additionally, you possess ongoing access to your students' email list, affording you the opportunity to communicate with them as needed.
							</p>
						</div>
					</div>
					<div className="col-sm-6 col-md-4">
						<img
							className="benefit_desktop payment_img right_desktop photo img______host_course"
							src={graph}
							alt="Graph Desktop"
						/>
					</div>
				</div>
			</section>
			<CourseSubscribe />
			{/* Course  coummunity*/}
			<section className="container padding____section">
				<div className="row">
					<div className="col-sm-6 col-md-4">
						<div className="benefit_header">
							<div className="benefit_sub">
								<span>

									{flag}
								</span>
								<h6 className="header_text">LEARN FROM OTHER MENTORS</h6>
							</div>
							<h2 className="header_head">Become Part of the Mentor Community</h2>
							<div className="benefits_para two_para ben_para">
								<div className="benefits_division">
									<span className="tick">
										{point__svg}
									</span>
									<p>
										Discover all you need to excel as a mentor and creator of online courses through our comprehensive resources, all available in one location—for free.
									</p>
								</div>
								<div className="benefits_division">
									<span className="tick">
										{point__svg}
									</span>
									<p>
										Connect with fellow mentors who share your passion and can provide valuable insights on your mentoring journey.
									</p>
								</div>
								<div className="benefits_division">
									<span className="tick">
										{point__svg}
									</span>
									<p>
										Receive free guidance on mentoring and course creation from our community members and expert mentors.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-6 col-md-4">
						<img
							className="benefit_desktop payment_img size_img right_desktop up_desktop photo img______host_course"
							src={community}
							alt="Course Graph Desktop"
						/>
					</div>
				</div>
			</section>
			{/* other products */}
			<section className="why-choose why_choose_ment">
				<div className="container">
					<div className="inner-title">
						<h2 className="title-text" style={{ color: "black" }}>Explore Our Offerings</h2>
					</div>
					<div className="mentorpal-sec">
						<div className="row">
							{
								productsData.map((item, index) => (
									<div className="col-sm-6 col-md-4" key={index}>
										<div className="mentorpal-box">
											<div className="icon-img">
												<img alt="icon" src={item.icon} />
											</div>
											<div className="serve-cont">
												<div style={{ color: "black", fontSize: "25px", fontWeight: "bold" }}>{item.title}</div>
												<p>{item.text}</p>
											</div>
										</div>
									</div>
								))
							}
						</div>
					</div>
				</div>
			</section>
			{/*  testimonial section */}
			<section className=" why_choose_ment">
				<div className="container">
					<div className="container">
						<center>
							<h2 className="my-4" style={{ color: '#b7308e' }}>
								Our Inspiring Mentors & Empowered Mentees
							</h2>
						</center>
						<Testimonial />
					</div>
				</div>
			</section>


			{/* FAQ */}
			<section className="section background-color-grey pt-4 " style={{ paddingBottom: "4rem" }}>
				<div className="container">
					<div className="frequently_asked ">
						<div className="row">
							<div className="col-md-6">
								<div className="mentoring-cont">
									<div className="faq__pricing">
										<h3>Frequently asked Questions</h3>
										<div className="inner-title">
											<h2>
												Can't find the answer you <span> are looking for?</span>
											</h2>
										</div>
									</div>
									<ul>
										<li>
											<span className="tick">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													height="24px"
													viewBox="0 0 24 24"
													width="24px"
													fill="#000000"
												>
													<path d="M0 0h24v24H0V0z" fill="none" />
													<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
												</svg>
											</span>
											<a href="mailto:help@mentorpal.ai">

												<span className="cont-cls">Reach out to us</span>
											</a>
										</li>
										<li>
											<span className="tick">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													height="24px"
													viewBox="0 0 24 24"
													width="24px"
													fill="#000000"
												>
													<path d="M0 0h24v24H0V0z" fill="none" />
													<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
												</svg>
											</span>
											<a >
												<span className="cont-cls">
													Help & grow with MentroPal
												</span>
											</a>
										</li>
										<li>
											<span className="tick">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													height="24px"
													viewBox="0 0 24 24"
													width="24px"
													fill="#000000"
												>
													<path d="M0 0h24v24H0V0z" fill="none" />
													<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
												</svg>
											</span>
											<span className="cont-cls">
												take your knowledge to global level
											</span>
										</li>
									</ul>
									<div className="get-started-cls" id="get-started-cls">
										<a
											href="mailto:help@mentorpal.ai"
											type="button"
											className="btn btn-primary"
										>
											Reach out to us
										</a>
									</div>
								</div>
							</div>
							<div className="col-md-6 faq___overflow" >
								<div class="accordion accordion-flush" id="accordionFlushExample">
									{faq.map((faq, index) => (
										<div class="accordion-item" style={{ marginBottom: "8px" }} key={index}>
											<h2 class="accordion-header accordion-header_course" id={`flush-heading-${index}`}>
												<button
													class="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target={`#flush-collapse-${index}`}
													aria-expanded="false"
													aria-controls={`flush-collapse-${index}`}
												>
													{faq.question}
												</button>
											</h2>
											<div
												id={`flush-collapse-${index}`}
												class="accordion-collapse collapse"
												aria-labelledby={`flush-heading-${index}`}
												data-bs-parent="#accordionFlushExample"
											>
												<div class="accordion-body">{faq.answer}</div>
											</div>
										</div>
									))}
								</div>
							</div>

						</div>
					</div>
				</div>
			</section>



			<MasterFooter />
			{headerModal?.modal && (
				<headerModal.modal
					headerModal={headerModal}
					setHeaderModal={setHeaderModal}
				/>
			)}
		</>
	);
}

export default HostCourseLandingPage;
