import React from "react";
import { InlineWidget } from "react-calendly";
import DashboardHeader from "../dashboard/dashboardHeader/index";
import Footer from "../footer/index";

const Calendly = () => {
  return (
    <React.Fragment>
      <DashboardHeader />
      <div style={{ paddingTop: 60 }}>
        <InlineWidget url="https://calendly.com/hitesh25kumar" />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Calendly;
