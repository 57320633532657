import {
    Button,
    Col,
    Drawer,
    Flex,
    message,
    Modal,
    Row,
    Skeleton,
    Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { isMobile } from "../../../Data/Data";

const { Text, Paragraph } = Typography;




const PackageServices = (
    props
) => {
    const {
        open,
        onClose,
        setPackageService,
        form,
        addedService,
        setAddedService,
        setCumulativePrice,
        type,
    } = props;
    const LOADING = "loading";
    const [state, setState] = useState(LOADING);
    const [servicesList, setServicesList] = useState([]);
    const [servicesTerm, setServicesTerm] = useState(addedService);


    const getServices = async () => {


    };

    const addService = (id, service) => {
        const val = servicesTerm?.filter((item) =>
            item?.id === id ? true : false
        );
        if (val?.length > 0) {
            if (service?.type === 1 || service?.type === 2 || val[0]?.count === 0) {
                const count = val[0]?.count;
                setServicesTerm(
                    servicesTerm?.map((item) =>
                        item?.id === id
                            ? { id: id, count: count + 1, service: service }
                            : item
                    )
                );
            } else {
                message.error("Webinar and digital product can only be added once");
            }
        } else
            setServicesTerm([
                ...servicesTerm,
                { id: id, count: 1, service: service },
            ]);


    };

    const removeService = (id, service) => {
        const val = servicesTerm?.filter((item) =>
            item?.id === id ? true : false
        );
        if (val[0]?.count > 0) {
            const count = val[0]?.count;
            setServicesTerm(
                servicesTerm?.map((item) =>
                    item?.id === id
                        ? { id: id, count: count - 1, service: service }
                        : item
                )
            );
        } else {
            setServicesTerm(servicesTerm.filter((item) => item.id !== id));
        }
    };

    const submitServices = () => {
        let c = 0;
        setAddedService(servicesTerm);
        const obj = servicesTerm.map((service_id) => {
            c += service_id?.service?.charge * service_id?.count;
            return { service: { id: service_id?.id }, count: service_id?.count };
        });
        setCumulativePrice && setCumulativePrice(c);
        setPackageService(obj);
        onClose();
        form && form.submit();
    };

    useEffect(() => {
        getServices();

    }, []);

    useEffect(() => {
        setServicesTerm(addedService);
    }, [addedService]);

    const InfoBox = () => {
        return (
            <Col className="package-service-box">
                <Col className="header">
                    <img
                        src="/images/dashboard/services/package-new.svg"
                        alt="package-image"
                        width={220}
                        height={94}
                        className="package-img"
                    />
                </Col>
                <Col className="top-bar">
                    <Paragraph className="title">
                        {type === 6 ? "Add services" : "Customize Package"}
                    </Paragraph>
                    <Paragraph className="desc">
                        {type === 6
                            ? `Add services that will be part of your subscription.
                Your customers will be able to avail these services every month.`
                            : "Add more than one services to create a bundle"}
                    </Paragraph>
                </Col>
                {state == LOADING ? (
                    <Paragraph
                        style={{
                            padding: "24px 24px",
                        }}
                    >
                        <Skeleton active paragraph={{ rows: 4 }} />
                    </Paragraph>
                ) : (
                    <>
                        {servicesList?.length === 0 ? (
                            <Paragraph className="no-service">
                                You currently don&apos;t have any active services on your
                                profile. Please create a service first to create a package
                            </Paragraph>
                        ) : (
                            <Col className="service-list">
                                {servicesList?.map((service, idx) => (
                                    <Row
                                        justify="space-between"
                                        align="middle"
                                        className="service-details"
                                        key={service?.id || idx}
                                    >
                                        <Col span={20}>
                                            <Paragraph className="service-title">
                                                {service?.title}
                                            </Paragraph>
                                            <Paragraph className="details">
                                                {service?.type === 1
                                                    ? "1:1 call"
                                                    : service?.type === 2
                                                        ? "Query"
                                                        : service?.type === 5
                                                            ? "Digital Product"
                                                            : "Webinar"}{" "}
                                                {[1, 3].indexOf(service?.type) > -1 &&
                                                    `| ${service?.duration} mins`}
                                                {` | ${service?.currency?.display_text}`}
                                                {service?.charge}
                                            </Paragraph>
                                        </Col>
                                        <Col span={4}>
                                            {servicesTerm?.filter((item) =>
                                                item?.id === service?.id ? true : false
                                            )[0]?.count > 0 ? (
                                                <Row
                                                    className="selected-action"
                                                    justify="space-around"
                                                    align="middle"
                                                >
                                                    <Text
                                                        onClick={() => removeService(service?.id, service)}
                                                        className="action-text cursor-hover"
                                                    >
                                                        -
                                                    </Text>
                                                    <Text className="action-text ">
                                                        {
                                                            servicesTerm?.filter((item) =>
                                                                item?.id === service?.id ? true : false
                                                            )[0]?.count
                                                        }
                                                    </Text>
                                                    <Text
                                                        onClick={() => addService(service?.id, service)}
                                                        className="action-text cursor-hover"
                                                    >
                                                        +
                                                    </Text>
                                                </Row>
                                            ) : (
                                                <Row
                                                    onClick={() => addService(service?.id, service)}
                                                    className="action-btn cursor-hover"
                                                    justify="center"
                                                    align="middle"
                                                >
                                                    <Paragraph className="action-text">+ Add</Paragraph>
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        )}
                        <Flex className="btn-container" gap={10} wrap="wrap">
                            <Button
                                className="btn-dark add-btn"
                                block
                                disabled={
                                    servicesList?.length == 0 || servicesTerm?.length === 0
                                }
                                onClick={submitServices}
                            >
                                {type === 6 ? "Add to Subscription" : "Add Services"}
                            </Button>
                            {type === 6 && (
                                <Paragraph>
                                    Subscriptions are charged at flat <s>12%</s> 10% commission +
                                    txn fee
                                </Paragraph>
                            )}
                        </Flex>
                    </>
                )}
            </Col>
        );
    };

    return (
        <Paragraph className="package-modal">
            {isMobile ? (
                <Drawer
                    open={open}
                    onClose={onClose}
                    placement="bottom"
                    width={"50vw"}
                    height="top"
                    className="drawer-container"
                    closable={false}
                >
                    {InfoBox()}
                </Drawer>
            ) : (
                <Modal
                    onCancel={onClose}
                    width={480}
                    open={open}
                    centered
                    className="modal-container"
                    footer={null}
                    styles={{
                        body: {
                            padding: "0px",
                        },
                    }}
                >
                    {InfoBox()}
                </Modal>
            )}
        </Paragraph>
    );
};




export default PackageServices;
