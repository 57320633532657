import React from "react";

const NoBookFound = () => {
  return (
    <div>
      <hr />
      <div style={{ "text-align": "center", "padding-bottom": "1rem" }}>
        <h2>No Book Found</h2>
        {/* hello */}
        <p>You haven't added any book</p>
      </div>
    </div>
  );
};

export default NoBookFound;
