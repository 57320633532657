import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getConnectionRequestAction,
  updateConnectionRequestAction,
} from "../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import { undrawProfile } from "../../assets/images/export-images";

const PendingRequest = () => {
  const dispatch = useDispatch();

  const GetListDataFunction = () => {
    dispatch(getConnectionRequestAction());
  };
  useEffect(() => {
    GetListDataFunction();
  }, []);

  const RequestListData = useSelector(
    (state) => state.getConnectionRequestReducer?.data
  );
  const updateConnectionRequestData = useSelector(
    (state) => state.updateConnectionRequestReducer?.data
  );
  const isLoading = useSelector(
    (state) => state.getConnectionRequestReducer?.loading
  );

  const acceptRequest = (connection_id) => {
    const data = {
      connection_id: connection_id,
      status: "accepted",
    };
    dispatch(updateConnectionRequestAction(data));
    GetListDataFunction().then(setTimeout(()=>{
      window.location.reload();
    },500));
  };

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div className="table-responsive ">
          {RequestListData?.length > 0 ? (
            <table style={{ width: "100%" }}>
              <tbody>
                {RequestListData?.map((menteeRequest, index) => (
                  <tr key={index}>
                    <td className="d-flex">
                      <div className="container">
                        <div className="row mobile-view-privatesession">
                          <div className="col-lg-3 col-md-3">
                            <div className="profile-box">
                              {menteeRequest?.mentee_details?.profile_photo ? (
                                <img
                                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}${menteeRequest?.mentee_details?.profile_photo}`}
                                  alt=""
                                />
                              ) : (
                                <img alt="icon" src={undrawProfile} />
                              )}
                            </div>
                            <h3 className="pro-name">
                              {menteeRequest?.sender?.name}
                            </h3>
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <td>
                              <h3 className="pro-name ml-auto">
                                Request Status
                                <span className="small-cls">
                                  {menteeRequest?.status}
                                </span>
                              </h3>
                            </td>
                          </div>
                          <div className="col-lg-3 col-md-3">
                            {menteeRequest?.status === "pending" ? (
                              <button
                                className="btn session-start"
                                onClick={() =>
                                  acceptRequest(menteeRequest?.connection_id)
                                }
                              >
                                Accept
                              </button>
                            ) : (
                              <button className="btn session-start">
                                Already Approved
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* <hr /> */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>
              <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
                <h2>No Request Found</h2>
                <p>Check Your Mentee List</p>
                {/* hello */}
                {/* <p>You haven't added any book</p> */}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PendingRequest;
