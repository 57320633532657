import React, { useState } from "react";
import { MentorAvailability } from "./../MentorAvailability/MentorAvailability";
import { REACT_USER_ID } from "../../auth/tokenProvider";

const Step3 = ({
  createMeetingData,
  handleSubmit,
  handleChange,
  handlePreviousStep,
}) => {
  return (
    <>
      <section className="step__goal__item">
        <div>
          <div className="container">
            <div className="step__count help-cont">
              <p>STEP 3 of 3 </p>
              <h4>Select your Time & Date ?</h4>
            </div>
          </div>
          <MentorAvailability
            createMeetingData={createMeetingData}
            handleChange={handleChange}
          />

          <div className="d-flex">
            <div className="get-started-cls" id="get-started-cls">
              <button
                onClick={handlePreviousStep}
                type="button"
                className="btn btn-primary"
              >
                Previous
              </button>
            </div>
            <div className="get-started-cls" id="get-started-cls">
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step3;
