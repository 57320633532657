import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import crossImg from '../img/cross.png';

 
import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import { mentorSessionServiceFormData } from "../../../redux/actions/mentor";
 
import("../css/custom.css");

const LookSession = () => {
  const navigate = useNavigate();
  const user_id = REACT_USER_ID;
  const dispatch = useDispatch();
  const selectedSessionTypeSavedData = useSelector((state) => {
    const data = state.mentorServiceSessionTypeReducer?.data?.type || "";
    return data;
  });
  const selectedSessionAvailabilitySavedData = useSelector((state) => {
    const data = state.mentorSessionServiceAvailabilityReducer?.data || "";
    return data;
  });
  const selectedSessionDetailsSavedData = useSelector((state) => {
    const data = state.mentorSessionServiceDetailsReducer?.data || "";
    return data;
  });
  const selectedSessioServicenHelpMenteeSavedData = useSelector((state) => {
    const data =
      state.mentorSessionServiceHelpMenteeReducer?.data?.menteePrerequisets ||
      [];
    return data;
  });
  const selectedSessionServicesSavedData = useSelector((state) => {
    const data = state.mentorSessionServicesReducer?.data;
    return data;
  });
  const sessionServiceCompleteDataModel = {
    mentorId: user_id,
    title: selectedSessionDetailsSavedData?.title,
    price: selectedSessionDetailsSavedData?.price,
    currency: "INR",
    services: selectedSessionServicesSavedData?.services,
    links: selectedSessionServicesSavedData?.links,
    planDuration: selectedSessionDetailsSavedData?.planDuration,
    availability: selectedSessionAvailabilitySavedData?.availability,
    menteePrerequisets: selectedSessioServicenHelpMenteeSavedData,
    autoApproval: true,
    menteeNotes: "Test By sachin",
    type: selectedSessionTypeSavedData,
    serviceType: selectedSessionTypeSavedData,
  };
  const selectedServicesStringFormater = () => {
    let resultString = selectedSessionServicesSavedData?.services
      .filter((service) => {
        if (service.active) return service;
      })
      .map(
        (service) => service.type[0].toUpperCase() + service.type.substring(1)
      );
    return resultString.toString();
  };
  useEffect(() => {
     
    import("../css/custom.css");
  }, []);
  useEffect(() => {
    dispatch(mentorSessionServiceFormData(sessionServiceCompleteDataModel));
  }, []);

  return (
    <>
      <div id="wrapper">
        <div className="step-cont">
          <div className="step-inner-cls">
            <form action="#" method="post" id="raq_questions">
              <div className="question-container active_panel " id="question-7">
                <h5>Awesome you're almost done</h5>
                <h3>Let's take a look at your session</h3>
                <div className="custom-radio-cls">
                  <div className="custom-radio-bg">
                    <label className="custom-radio">
                      <input type="radio" checked="checked" name="radio" />
                      <span className="checkmark"></span>
                      <span className="cont">
                        {selectedSessionTypeSavedData}
                      </span>
                    </label>
                  </div>
                  <div className="custom-radio-bg">
                    <span className="label-inner">Title :</span>
                    <span className="label-inner-cls">
                      {sessionServiceCompleteDataModel.title}
                    </span>
                  </div>
                  <div className="custom-radio-bg bg-none">
                    <span className="label-inner">Fee :</span>
                    <span className="label-inner-cls">
                      {" "}
                      {sessionServiceCompleteDataModel.price} (
                      {sessionServiceCompleteDataModel.currency})
                    </span>
                  </div>
                  <div className="custom-radio-bg">
                    <span className="label-inner">Duration :</span>
                    <span className="label-inner-cls">
                      {sessionServiceCompleteDataModel.planDuration}
                    </span>
                  </div>
                  <div className="custom-radio-bg bg-none">
                    <span className="label-inner">Availability :</span>
                    <span className="label-inner-cls">
                      {" "}
                      {
                        sessionServiceCompleteDataModel.availability?.timings[0]
                          ?.startTime
                      }
                    </span>
                  </div>

                  <div className="custom-radio-bg">
                    <span className="label-inner">Services :</span>
                    <span className="label-inner-cls">
                      {selectedServicesStringFormater()}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LookSession;
