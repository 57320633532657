import { Flex, Typography } from "antd";
import { FaArrowRight } from "react-icons/fa";


const { Paragraph, Text } = Typography;

export const PricingDisplay = ({
    originalPrice,
    discountedPrice,
    currency,
    freeShow = true,
    page = "",
    tag = false,
    flexible = false,
    subscription = false,
    showArrow = false,
    className = "",
}) => {
    const showDiscountedPrice = () => {
        return (
            discountedPrice !== undefined &&
            originalPrice > 0 &&
            discountedPrice < originalPrice
        );
    };
    return (
        <Paragraph

            className={`public-profile-pricing ${className}`}
        >
            {/* Currency */}
            {((originalPrice == 0 && !tag) || originalPrice > 0 || flexible) &&
                !showDiscountedPrice() && (
                    <span style={{ marginRight: 0 }}>
                        {currency?.currency_symbol || currency?.display_text}
                    </span>
                )}

            {/* If Slashed pricing is showing  */}
            {showDiscountedPrice() ? (
                <Flex align="flex-end" justify="center" gap={4}>
                    {/* Slash Price */}
                    <s
                        style={{
                            fontWeight: 500,
                            color: "#727272",
                            fontSize: 13,
                        }}
                    >
                        {currency?.currency_symbol || currency?.display_text}{" "}
                        {`${formatNumber(originalPrice)}`}
                    </s>

                    {/* Shown Price after slash */}
                    <span
                        style={{
                            display: "inline-block",
                        }}
                    >
                        {currency?.currency_symbol || currency?.display_text}
                        {`${formatNumber(discountedPrice)}${flexible ? "+" : ""}`}
                        <span style={{ fontWeight: "400" }}>{subscription && "/mo"}</span>
                    </span>
                </Flex>
            ) : originalPrice > 0 ? (
                // No Slash Price
                <Text>
                    {`${formatNumber(originalPrice)}${flexible ? "+" : ""}`}
                    <span style={{ fontWeight: "400" }}>{subscription && "/mo"}</span>
                </Text>
            ) : freeShow ? (
                tag && !flexible ? (
                    // Free tag show without PWYW
                    <Text style={{ padding: "0 6px" }}>FREE</Text>
                ) : (
                    // Free tag show with PWYW
                    <Text>
                        {`${formatNumber(originalPrice)} ${flexible ? "+" : ""}`}
                        <span style={{ fontWeight: "400" }}>{subscription && "/mo"}</span>
                    </Text>
                )
            ) : (
                ""
            )}
            {showArrow && <FaArrowRight fontSize={12} style={{ marginLeft: 8 }} />}
        </Paragraph>
    );
};

export const formatNumber = (number, flexible = false) => {
    return (
        Number(parseFloat(number).toFixed(1)).toLocaleString() +
        (flexible ? "+" : "")
    );
}
