import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  popularIc,
  languageImg,
  homeIcon,
  arNext,
  bookImg,
} from "../../assets/images/export-images";
import maleAvatar from "../../assets/images/male.png";
import femaleAvatar from "../../assets/images/female.png";

import "../../assets/css/custom.css";
import "../../assets/css/header.css";
import "../../assets/css/main-banner.css";
import "../../assets/css/signup-popup.css";
import ReactStars from "react-stars";
import "../../assets/css/find-mentor.css";
import "../../assets/css/mentor-detail.css";
import "../../assets/css/event-sec.css";
import "../../assets/css/steps.css";
import "../../assets/css/calendar.css";
import "../../assets/css/footer.css";
import "../../assets/css/media.css";
import "react-calendar/dist/Calendar.css";
import Header from "../header";
import ProtectedHeader from "./../ProtectedHeader/ProtectedHeader";
import Footer from "../footer";
import OneTimeSessionModal from "./modals/oneTimeSessionModal";
import { useDispatch, useSelector } from "react-redux";
import { favoriteMentor, similarMentor } from "../../redux/actions/mentee";
import { TailSpin } from "react-loader-spinner";
import { viewMentorProfileAction } from "../../redux/actions/mentor";
import { createfavoriteMentor } from "../../redux/actions/mentee";
import { sendChatMessageAction } from "../../redux/actions/common";
import SendRequestModal from "./modals/sendRequest";

import SampleVdo from "../../../src/videos/New video.mp4";
import "./index.css";

import { Modal } from "react-bootstrap";
import Rating from "../Rating/Rating";
import { getReviews } from "../../network";
import Breadcrumbs from "../Breadcrumb";
let date = new Date();
const loggedIn = localStorage.getItem("REACT_TOKEN_AUTH");
let datestring = date.toTimeString();
let countryname =
  datestring?.includes("india") | datestring?.includes("India") ? "India" : "";
let currencyName = countryname === "India" ? "INR" : "USD";
const MentorDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState({});
  const [isFavourite, setIsFavourite] = useState(true);
  const [mentorReviews, setmentorReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mentor, setMentor] = useState({});
  const [show, setShow] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const USER_ID = localStorage.getItem("REACT_USER_ID");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_USER_ID") ? true : false
  );
  const location = useLocation();
  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });

  const viewProfile = (mentor) => {
    let user = mentor.name.split(" ").join("") + "_" + mentor.user_id;
    dispatch(viewMentorProfileAction(mentor.user_id));
    navigate("/mentor-details/" + user);
  };
  const similarMentorsList = useSelector((state) => {
    const data = state.similarMentorReducer?.data || [];
    return data.slice(0, 2);
  });
  console.log("similarMentorsList", similarMentorsList);

  const hanldeLoginUserStatus = (val) => {
    setLoggedIn(val);
  };
  // const [Products, setMentorProducts] = useState([]);

  const modelChange = () => {
    setModal({
      modal: OneTimeSessionModal,
      show: true,
    });
  };
  const handlePopUp = () => setShow(!show);

  const viewMentorDetails = () => {
    navigate("/mentor-details");
  };

  const findMentor = () => {
    navigate("/find-mentor");
  };

  const favoriteMentorsList = useSelector((state) => {
    const data = state.favoriteMentorReducer?.data || [];
    return data.slice(0, 2);
  });

  useSelector((state) => {
    if (
      isLoading &&
      state.viewMentorProfileReducer?.loading &&
      Object.keys(mentor).length !== 0
    )
      setMentor({});

    const request = state.viewMentorProfileReducer?.request;
    const loading = state.viewMentorProfileReducer?.loading;
    const data = state.viewMentorProfileReducer?.data;

    if (loading && !isLoading) setIsLoading(true);

    if (request === undefined) {
      if (isLoading) setIsLoading(false);
      return;
    }

    if (
      !loading &&
      Object.keys(mentor).length === 0 &&
      data !== undefined &&
      data.length !== 0
    ) {
      setMentor(data[0]);
      setIsLoading(false);
    }
  });

  useEffect(() => {
    const mentor_id = window.location.pathname.split("_")[1];
    setdisabled(true);
    setmentorReviews([]);
    const getAllReviews = async (mentor_id) => {
      // const arr = await getReviews(mentor_id);
      const arr = {data:[]}
      let isReviewPresent = false;
      if (arr.data.length > 0) {
        isReviewPresent = arr.data[0].data.some(
          (review) => review.mentee_id === USER_ID
        );
        if (isReviewPresent == false) setdisabled(false);
      } else {
        setdisabled(false);
      }
      if (arr.data.length) setmentorReviews(arr.data[0].data);
    };
    if (mentor_id) {
      dispatch(viewMentorProfileAction(mentor_id));
      getAllReviews(mentor_id);
    }
  }, [location]);

  // useEffect(() => {
  //   if (Object.keys(mentor).length > 0)
  //     dispatch(favoriteMentor(mentor.user_id));
  // }, [Object.keys(mentor).length]);

  const addToFavouriteMentor = () => {
    let data = {
      mentor_id: mentor.user_id,
      mentee_id: USER_ID,
    };
    dispatch(createfavoriteMentor(data));
    dispatch(favoriteMentor(USER_ID));
    setIsFavourite(true);
  };
  const removeFromFavourite = () => {
    setIsFavourite(false);
  };

  useEffect(() => {
    const id = window.location.pathname.split("_")[1];
    if (id) {
      dispatch(similarMentor(id));
    }
  }, []);

  const [opensendRequestModal, setOpensendRequestModal] = useState({});

  const BookSession = (mentor) => {
    let user = mentor.name.split(" ").join("") + "_" + mentor.user_id;
    navigate("/booksession/" + user);
  };

  return (
    <React.Fragment>
      {loggedIn ? (
        <div style={{ marginTop: "0" }}>
          <ProtectedHeader extraClasses="inner-header" />
        </div>
      ) : (
        <Header extraClasses="inner-header" />
      )}
      {/* Modals */}
      {modal?.modal && <modal.modal modal={modal} setModal={setModal} />}
      {isLoading ? (
        <div className="loader open_sessions_loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <>
          {" "}
          {/* <!-- Breadcrum --> */}
          {/* <div className="breadcrumb-sec">
            <div className="container">
              <div className="breadcrumb-inner">
                <span onClick={() => navigate("/")}>
                  <img src={homeIcon} alt="home icon" />
                </span>
                <span className="breadcrumb-space">
                  <img src={arNext} alt="Next" />
                </span>
                <span onClick={findMentor}>Find Mentor</span>
                <span className="breadcrumb-space">
                  <img src={arNext} alt="Next" />
                </span>
                <a href="#" className="active">
                  {mentor.name}
                </a>
              </div>
            </div>
          </div> */}
          <Breadcrumbs mentorDetails={true} />    
          {/* <!-- Mentor Detail Section --> */}
          <div className="mentor-dtl">
            <div className="container">
              <div className="mentor-dtl-inner">
                <div className="mentor-inner">
                  <div className= "mentor-dtl-left">
                    <div className="popular-logo">
                      <img alt="" src={popularIc} />
                    </div>
                    <div className="mentor-list-title">
                      <div className="mentor-profile-img">
                        <div className="profile-img">
                          {mentor?.profile_photo &&
                            mentor?.profile_photo != "" ? (
                            <img
                              src={`${process.env.REACT_APP_IMAGES_BASE_URL}${mentor?.profile_photo}`}
                              alt=""
                            />
                          ) : mentor?.gender == "female" ? (
                            <img alt="icon" src={femaleAvatar} />
                          ) : (
                            <img alt="icon" src={maleAvatar} />
                          )}
                        </div>
                        {/* <button className="btn btn-danger mt-2" style={{ width: '100%' }} onClick={handlePopUp}>Rating</button> */}
                        <Modal show={show} onHide={handlePopUp}>
                          <Modal.Header closeButton>
                            <Modal.Title>Review and Rating</Modal.Title>
                          </Modal.Header>
                          <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                            <Rating
                              setdisabled={setdisabled}
                              handlePopUp={handlePopUp}
                              mentorReviews={mentorReviews}
                              setmentorReviews={setmentorReviews}
                            />
                          </Modal.Body>
                        </Modal>
                        {isFavourite ? (
                          <div
                            onClick={() => {
                              removeFromFavourite();
                            }}
                            className="liked-sec"
                          >
                            <svg
                              className="custom-svg"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 600 500"
                            >
                              <path
                                className="fa-secondary"
                                d="M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z"
                                fill="red "
                              />
                            </svg>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              addToFavouriteMentor();
                            }}
                            className="like-sec"
                          >
                            <svg
                              className="custom-svg-2"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="-10 0 650 500"
                            >
                              <path
                                className="fa-secondary"
                                style={{
                                  fill: "none",
                                  stroke: "red",
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeWidth: "32px",
                                }}
                                d="M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z"
                                fill="white "
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                      <div className="title-left">
                        <h3>{mentor.name}</h3>
                        <h6>
                          {mentor?.title} <span>at</span>{" "}
                          {mentor?.company_or_school}
                        </h6>
                        <span className="language">
                          <span className="lang-ic">
                            <img alt="" src={languageImg} />
                          </span>
                          {Array.isArray(mentor.language)
                            ? mentor.language?.join(", ")
                            : mentor.language}
                        </span>
                        <div
                          className="get-started-cls why_choose_ment"
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "0.3rem",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {loggedIn && disabled == false && (
                            <button
                              data-toggle="modal"
                              data-target="#loginForm"
                              onClick={handlePopUp}
                              type="button"
                              className="btn btn-primary"
                              style={{
                                padding: "0rem 2rem",
                                width: "auto",
                              }}
                            >
                              Rate
                            </button>
                          )}

                          <button
                            data-toggle="modal"
                            data-target="#loginForm"
                            onClick={() =>
                              setOpensendRequestModal({
                                modal: SendRequestModal,
                                show: true,
                              })
                            }
                            type="button"
                            className="btn btn-primary"
                            style={{
                              padding: "0rem 2rem",
                              width: "auto",
                            }}
                          >
                            Message
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mentor-box-wrap">
                      <p>{mentor.bio}</p>
                    </div>
                    <div className="mentor-box-wrap">
                      <h5> {mentor?.story}</h5>
                      <p>{mentor.mentees_help_description}</p>
                      <div className="skills-sec mentor-skills-sec">
                        <h4>Core Skills</h4>
                        {/* --------------------------------------------------------------------------------- */}
                        {mentor?.leadership?.length > 0 && (
                          <>
                            <p className="">Leadership</p>
                            <li
                              style={{ listStyle: "none" }}
                              className="my-2 d-flex flex-wrap"
                            >
                              {mentor?.leadership?.map((val, id) => (
                                <ul key={id}>
                                  <li className="px-2">{val}</li>
                                </ul>
                              ))}
                            </li>
                          </>
                        )}
                        {/* --------------------------------------------------------------------------------- */}
                        {mentor?.mentorship?.length > 0 && (
                          <>
                            <p className="">Mentorship</p>
                            <li
                              style={{ listStyle: "none" }}
                              className="my-2 d-flex flex-wrap"
                            >
                              {mentor?.mentorship?.map((val, id) => (
                                <ul key={id}>
                                  <li className="px-2">{val}</li>
                                </ul>
                              ))}
                            </li>
                          </>
                        )}
                        {/* --------------------------------------------------------------------------------- */}
                        {mentor?.skills?.length > 0 && (
                          <>
                            <p className="">Skills</p>
                            <li
                              style={{ listStyle: "none" }}
                              className="my-2 d-flex flex-wrap"
                            >
                              {mentor?.skills?.map((val, id) => (
                                <ul key={id + "1"}>
                                  <li className="px-2">{val}</li>
                                </ul>
                              ))}
                            </li>
                          </>
                        )}
                        {/* --------------------------------------------------------------------------------- */}
                        {mentor?.career_advice?.length > 0 && (
                          <>
                            <p className="">Career Advice</p>
                            <li
                              style={{ listStyle: "none" }}
                              className="my-2 d-flex flex-wrap"
                            >
                              {mentor?.career_advice?.map((val, id) => (
                                <ul key={id + "2"}>
                                  <li className="px-2">{val}</li>
                                </ul>
                              ))}
                            </li>
                          </>
                        )}
                        {/* --------------------------------------------------------------------------------- */}
                        {mentor?.job_search?.length > 0 && (
                          <>
                            <p className="">Job Search</p>
                            <li
                              style={{ listStyle: "none" }}
                              className="my-2 d-flex flex-wrap"
                            >
                              {mentor?.job_search?.map((val, id) => (
                                <ul key={id + "3"}>
                                  <li className="px-2">{val}</li>
                                </ul>
                              ))}
                            </li>
                          </>
                        )}
                        {/* --------------------------------------------------------------------------------- */}
                      </div>
                    </div>
                  </div>
                  <div className="mentor-dtl-right">
                    <div className="profile-bg-sec">
                      <div className="price">
                        <h5>
                          {mentor?.is_pro_bono === "yes" ||
                            mentor?.price_per_session == 0
                            ? "Free Session"
                            : countryname === "India"
                              ? "₹" + mentor?.inr_price + "/Session"
                              : "$" + mentor?.price_per_session + "/Session"}
                        </h5>
                      </div>
                    </div>
                    {/* <MentorAvailability
                      // data={mentor.id}
                      // userDetails={userDetails}
                      setLoggedIn={hanldeLoginUserStatus}
                    /> */}
                    {/* <div
                      className="get-started-cls why_choose_ment"
                      style={{ marginTop: "10px" }}
                    >
                      <button
                        data-toggle="modal"
                        data-target="#loginForm"
                        onClick={() => {
                          BookSession(mentor);
                        }}
                        type="button"
                        className="btn btn-primary"
                        style={{
                          padding: "0rem",
                        }}
                      >
                        Book Session
                      </button>
                    </div> */}
                    <div className="profile-bg">
                      <div className="plan-view">
                        {/* <label>I’m Looking for?</label>
                        <select name="plan" id="plan">
                          <option value="Monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                        </select> */}
                      </div>
                      <div className="user-list">
                        <ul>
                          <li>
                            <span className="check-ic">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                              </svg>
                            </span>
                            Response in usually 1 day
                          </li>
                          <li>
                            <span className="check-ic">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                              </svg>
                            </span>
                            Unlimited Chats & Audio calls
                          </li>
                          <li>
                            <span className="check-ic">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                              </svg>
                            </span>
                            No Hidden costs
                          </li>
                          <li>
                            <span className="check-ic">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                              </svg>
                            </span>
                            Free Study Material
                          </li>
                          <li>
                            <span className="check-ic">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                              </svg>
                            </span>
                            Unlimited Audio, Video chats
                          </li>
                        </ul>
                      </div>
                      <div className="get-started">
                        <button
                          className="btn vew-profile-btn"
                          data-toggle="modal"
                          data-target="#applyform"
                          onClick={() => {
                            BookSession(mentor);
                          }}
                        >
                          Book Session
                        </button>
                      </div>
                      {/* <div className="explore-session">
                        <div className="explore-cont">
                          <p>Looking for quick sessions?</p>
                          <h4>Explore One-Time-Session</h4>
                          <button type="button" className="btn btn-explore">
                            Explore now
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="group-session">
              <div className="container">
                <div className="inner-title">
                  <h3>More from this Mentor</h3>
                  <h2>Group Sessions for everyone</h2>
                </div>
                {
                  console.log("this is open sessin for mentor ", mentor)
                }
                {mentor.groupSession.length > 0 ?
                  mentor.groupSession?.map(session => (
                    <div className="mentor-event-inner justify-content-between">
                      <div className="event-date-cls w-100">
                        <div className="date-box">
                          <h2>
                            10
                            <span className="d-block">Feb 2022</span>
                          </h2>
                        </div>
                        <div className="event-name">
                          <h4>
                            {session.title}
                          </h4>
                          <p>
                            <span>1 Hour Session</span>
                            <span className="arrow-line"></span> Starts {session.avilablity?.days[0]?.startDate}{" "}
                            <span className="arrow-line"></span> By- {mentor.name}
                          </p>
                        </div>
                      </div>
                      <div className="event-cont mentor-dtl-event">
                        <h3 data-toggle="modal" data-target="#gorupsession">
                          Book Your Spot
                        </h3>
                      </div>
                    </div>
                  )) :
                  <div className="text-center">
                    <h2>No group sesions available</h2>
                  </div>
                }
                comment below part
                <div className="mentor-event-inner">
                  <div className="event-date-cls">
                    <div className="date-box">
                      <h2>
                        09
                        <span className="d-block">Feb 2022</span>
                      </h2>
                    </div>
                    <div className="event-name">
                      <h4>
                        A Basic guid to start your career in java development &amp;
                        importance of java in current technical world.
                      </h4>
                      <p>
                        <span>1 Hour Session</span>
                        <span className="arrow-line"></span> Starts 7 PM{" "}
                        <span> </span>
                        <span className="arrow-line"></span> By- Indira Priyadarshini
                      </p>
                    </div>
                  </div>
                  <div className="event-cont mentor-dtl-event">
                    <h3 data-toggle="modal" data-target="#gorupsession">
                      Book Your Spot
                    </h3>
                    <span className="few-spot">Few spots left</span>
                  </div>
                </div>
                <div className="mentor-event-inner">
                  <div className="event-date-cls">
                    <div className="date-box">
                      <h2>
                        10
                        <span className="d-block">Feb 2022</span>
                      </h2>
                    </div>
                    <div className="event-name">
                      <h4>
                        A Basic guid to start your career in java development &amp;
                        importance of java in current technical world.
                      </h4>
                      <p>
                        <span>1 Hour Session</span>
                        <span className="arrow-line"></span> Starts 7 PM{" "}
                        <span className="arrow-line"></span> By- Indira Priyadarshini
                      </p>
                    </div>
                  </div>
                  <div className="event-cont mentor-dtl-event">
                    <h3>Book Your Spot</h3>
                  </div>
                </div> 
                till here ------------
              </div>
            </div> */}
          {/* books from mentor */}
          {/* <div className="reading-book-sec">
            <div className="container">
              <div className="inner-title">
                <h3>Love reading Books?</h3>
                <h2>Explore the premimum E-book From {mentor.name}</h2>
              </div>
              <div className="book-reading-slider">
                {mentor.books ? (
                  <OwlCarousel
                    className="owlslider owl-carousel owl-theme"
                    loop
                    margin={20}
                    nav
                    responsive={{
                      0: {
                        items: 2,
                      },
                      1000: {
                        items: 4,
                      },
                    }}
                  >
                    {mentor.books
                      ?.filter((book) => book.book_objective !== undefined)
                      .map((book) => (
                        <div target="1" className="item" key={book.product_id}>
                          <div className="book-slider">
                            <div className="book-img">
                              <img src={bookImg} alt="book reading" />
                            </div>
                            <div className="book-cont">
                              <h4>{book.book_objective}</h4>
                              <a href="#">Latest Edition</a>
                            </div>
                            <div className="add-reading-book">
                              <div className="book-price">
                                <h6>Price : $200</h6>
                                <button type="button" className="btn book-now">
                                  Buy Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </OwlCarousel>
                ) : (
                  <div className="text-center">
                    <h2>No books yet</h2>
                  </div>
                )}
              </div>
            </div>
          </div> */}
          {/* <!-- Mentor intro video Section --> */}
          {mentor?.mentor_intro_video && mentor?.mentor_intro_video !== "" ? (
            <div className="vdo-intro">
              <div className="container  ">
                <div className="vdo-container">
                  <div className="vdo-title">
                    <h2>Know more about your mentor..</h2>
                  </div>
                  <br />

                  <div className="intro-vdo">
                    <video
                      className="embed-responsive-item"
                      controls
                      autoPlay
                      loop
                      muted
                    >
                      {mentor?.mentor_intro_video &&
                        mentor?.mentor_intro_video !== "" ? (
                        <source
                          src={`${process.env.REACT_APP_IMAGES_BASE_URL}${mentor?.mentor_intro_video}`}
                          type="video/mp4"
                        />
                      ) : null}
                    </video>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* RATINGS */}
          <div className="reading-book-sec">
            <div className="container">
              <div className="inner-title">
                <h3>Reviews</h3>
              </div>
              {mentorReviews.length > 0 ? (
                mentorReviews.map((mentor) => (
                  <div
                    className="mentor-inner"
                    style={{
                      display: "block",
                      padding: "0.7rem",
                      marginTop: "0.7rem",
                      marginBottom: "0.7rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "3rem",
                          height: "3rem",
                          backgroundImage:
                            mentor.gender === "female"
                              ? `url(${femaleAvatar})`
                              : `url(${maleAvatar})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div className="review-header">
                          {mentor?.rating && mentor?.rating > 0 && (
                            <ReactStars
                              count={3}
                              value={mentor?.rating}
                              edit={false}
                              size={24}
                              color2={"#ffd700"}
                              style={{ padding: "0px" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className="m-0">
                        <span className="mentor-review-title">
                          {" "}
                          {mentor?.mentee_details?.name}
                        </span>

                        {mentor?.review !== "" && (
                          <>
                            <span className="mentor-review-desc">
                              "{mentor?.review}"
                            </span>
                          </>
                        )}
                      </p>
                      <div
                        className="skills-sec review-skills"
                        style={{ flexWrap: "wrap" }}
                      >
                        <ul style={{ flexWrap: "wrap" }}>
                          {mentor?.review_area_rating?.hasOwnProperty(
                            "Communication"
                          ) && (
                              <li
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div>Communication</div>
                                <ReactStars
                                  count={3}
                                  value={parseInt(
                                    mentor?.review_area_rating.Communication
                                  )}
                                  edit={false}
                                  size={20}
                                  color2="black"
                                />
                              </li>
                            )}

                          {mentor?.review_area_rating?.hasOwnProperty(
                            "Subject Knowledge"
                          ) && (
                              <li
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div>Subject Knowledge</div>
                                <ReactStars
                                  count={3}
                                  value={parseInt(
                                    mentor?.review_area_rating[
                                    "Subject Knowledge"
                                    ]
                                  )}
                                  edit={false}
                                  size={20}
                                  color2="black"
                                />
                              </li>
                            )}

                          {mentor?.review_area_rating?.hasOwnProperty(
                            "Motivational"
                          ) && (
                              <li
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  flexWrap: "wrap",
                                }}
                              >
                                <div>Motivational</div>
                                <ReactStars
                                  count={3}
                                  value={parseInt(
                                    mentor?.review_area_rating.Motivational
                                  )}
                                  edit={false}
                                  size={20}
                                  color2="black"
                                />
                              </li>
                            )}

                          {mentor?.review_area_rating?.hasOwnProperty(
                            "Problem Solving"
                          ) && (
                              <li
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div>Problem Solving</div>
                                <ReactStars
                                  count={3}
                                  value={parseInt(
                                    mentor?.review_area_rating["Problem Solving"]
                                  )}
                                  edit={false}
                                  size={20}
                                  color2="black"
                                />
                              </li>
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <h2>No Reviews Found</h2>
                </div>
              )}
            </div>
          </div>
          {/* similar mentors section */}
          <div className="reading-book-sec">
            <div className="container">
              <div className="inner-title">
                <h3>Explore Similar Mentors</h3>
                <h2>Checkout other mentors with same skills</h2>
              </div>
              {similarMentorsList.length > 0 ? (
                similarMentorsList.map((mentor) => (
                  <div className="mentor-inner">
                    <div className="mentor-left">
                      <div className="profile-bg">
                        <div className="profile-bg-sec">
                          <div className="profile-img">
                            {mentor?.profile_photo &&
                              mentor?.profile_photo != "" ? (
                              <img
                                src={`${process.env.REACT_APP_IMAGES_BASE_URL}${mentor?.profile_photo}`}
                                alt=""
                              />
                            ) : mentor?.gender == "female" ? (
                              <img alt="icon" src={femaleAvatar} />
                            ) : (
                              <img alt="icon" src={maleAvatar} />
                            )}
                          </div>
                        </div>
                        <div className="rating-cls">
                          <div className="rating-btn">
                            <button className="btn btn-rating">
                              <span className="rating-checked">
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span className="rating-checked">
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span className="rating-checked">
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span className="rating-checked">
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span className="rating-checked">
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </button>
                          </div>
                          <div className="get-started">
                            <span
                              onClick={() => viewProfile(mentor)}
                              className="btn vew-profile-btn"
                            >
                              View Profile
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mentor-right" style={{ width: "100%" }}>
                      <div className="popular-logo">
                        <img alt="" src={popularIc} />
                      </div>
                      <div className="mentor-list-title mentor-box-wrap">
                        <div className="title-left">
                          <h3>{mentor?.name}</h3>
                          <h6>{mentor?.company_or_school}</h6>
                          <span className="language">
                            <span className="lang-ic"></span>
                            {Array.isArray(mentor?.language) &&
                              mentor?.language.map((langItem) => (
                                <span key={langItem.id} className="lang-ic">
                                  {langItem.label}
                                </span>
                              ))}
                          </span>
                        </div>
                        <div className="title-right">
                          <div className="price">
                            <h5>
                              {mentor?.inr_price === "0" &&
                                mentor?.price_per_session === "0"
                                ? "Pro Bono"
                                : countryname === "India"
                                  ? "₹" + mentor?.inr_price + "/Session"
                                  : "$" + mentor?.price_per_session + "/Session"}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {mentor?.story && mentor.story.length > 0 ? (
                        <div className="mentor-box-wrap">
                          <p>{mentor.story.slice(0, 130)}...</p>
                        </div>
                      ) : (
                        <div className="mentor-box-wrap">
                          <p>No story available for this mentor.</p>
                        </div>
                      )}

                      {mentor?.skills && mentor.skills.length > 0 ? (
                        <div className="mentor-box-wrap">
                          <div className="skills-sec">
                            <ul>
                              {mentor.skills.slice(0, 2).map((skillItem, index) => (
                                <li key={index}>
                                  <a href="#">{skillItem}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div className="mentor-box-wrap">
                          <p>No skills available for this mentor.</p>
                        </div>
                      )}

                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <h2>No similar mentors</h2>
                </div>
              )}
            </div>
          </div>
          {/* <!-- Footer --> */}
          <Footer />
        </>
      )}

      {/* <!-- Apply Popup --> */}
      {/* <div className="apply-popup">
        <div
          className="modal fade"
          id="applyform"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-xl modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <div className="header-left">
                  <h3>Apply to Indira Pariyadarshini</h3>
                  <p>Just few steps to understand your requirements better!</p>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <img alt="" src={crossBtn} />
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div id="svg_wrap"></div>
                <section>
                  <div className="apply-inner">
                    <h4>Proceed with Email or Mobile</h4>
                    <p>Session details will be sent to you on given contact</p>
                    <form>
                      <div className="inner-apply-form">
                        <div className="form-group">
                          <input
                            type="text"
                            name=""
                            placeholder="Enter Email OR Mobile Number"
                            className="form-control"
                          />
                        </div>
                        <div className="signup-or">
                          <p>
                            <span>OR</span>
                          </p>
                          <h6>Continue with social media</h6>
                        </div>
                        <div className="signup-icon">
                          <a href="#">
                            <span className="popup-ic">
                              <img alt="" src={linkedInLogo1} />
                            </span>{" "}
                            <span>Linked in</span>
                          </a>
                          <a href="#">
                            <span className="popup-ic">
                              <img alt="" src={twitterLogo1} />
                            </span>{" "}
                            <span>Twitter</span>
                          </a>
                          <a href="#">
                            <span className="popup-ic">
                              <img alt="" src={fbLogo1} />
                            </span>
                            <span>Facebook</span>
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
                <section>
                  <div className="apply-inner">
                    <h4>Let’s get started....</h4>
                    <p>Define a bit expectation from the Mentor</p>
                    <form>
                      <div className="inner-apply-form">
                        <div className="form-group">
                          <input
                            type="text"
                            name=""
                            placeholder="How much you want mentor to be available for you?"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <select name="" id="" className="form-control">
                            <option value="Preffered mode of communication for session?">
                              Preffered mode of communication for session?
                            </option>
                            <option value="Preffered mode of communication for session?">
                              Preffered mode of communication for session?
                            </option>
                            <option value="Preffered mode of communication for session?">
                              Preffered mode of communication for session?
                            </option>
                            <option value="Preffered mode of communication for session?">
                              Preffered mode of communication for session?
                            </option>
                          </select>
                        </div>
                        <div className="form-group">
                          <textarea
                            id=""
                            name=""
                            rows="5"
                            className="form-control"
                          >
                            What do you expect from this mentor?
                          </textarea>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
                <section>
                  <div className="apply-inner">
                    <h4>Thank You for Applying!</h4>
                    <p>Your session is succesfully booked.</p>
                    <div className="session-box-cls">
                      <div className="session-head">
                        <h3>Reference ID</h3>
                        <h4>#MP001RM</h4>
                      </div>
                      <div className="session-detail-cls">
                        <div className="session-left">
                          <div className="sess-left-cont">
                            <h6>Mentorship from</h6>
                            <h4>Indira Priyadarshini</h4>
                          </div>
                          <div className="sess-left-cont">
                            <h6>Duration</h6>
                            <h4>Monthly</h4>
                          </div>
                        </div>
                        <div className="session-right">
                          <div className="session-price-sec">
                            <h5>Amount Paid</h5>
                            <h2>$100</h2>
                            <div className="session-date">
                              <span className="dots"></span>
                              <span>24 March, 2022</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sess-note">
                        <h6>Notes</h6>
                        <p>Please check your email for further details. </p>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="apply-inner">
                    <h4>Setup Your Account...</h4>
                    <p>
                      Mentor might know your name or a bit personal information
                    </p>
                    <form>
                      <div className="inner-apply-form">
                        <div className="form-group">
                          <input
                            type="text"
                            name=""
                            placeholder="Enter Full Name"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name=""
                            placeholder="Email"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </section>

                <div className="get-started">
                  <button
                    type="button"
                    className="btn vew-profile-btn button"
                    id="next"
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <!-- One Time Session --> */}
      {/* <div className="sign-up-popup group-session-popup">
        <div
          className="modal fade"
          id="onetimesession"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-xl modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <form>
                <div className="modal-body">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <img alt="" src={crossIcon} />
                      </span>
                    </button>
                  </div>
                  <div className="group-session-sec">
                    <div className="session-inner-cls">
                      <div className="session-left-sec">
                        <div className="session-title">
                          <h3>Interview Preparation</h3>
                          <div className="time-day">
                            <div className="time-seesion">
                              <span className="session-ic">
                                <img alt="" src={timeImg} />
                              </span>
                              <span className="session-con">
                                1 Hour Session
                              </span>
                            </div>
                            <div className="day-seesion">
                              <span className="session-ic">
                                <img alt="" src={calendarImg} />
                              </span>
                              <span className="session-con">Every Week</span>
                            </div>
                          </div>
                          <div className="sess-box-cont">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's .
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="session-right-sec">
                        <div className="session-price">
                          <h4>
                            ${sessionCharge ? sessionCharge : ""}/<span>Session</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="time-select-sec">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="time-title">
                          <h4>Choose Session Date</h4>
                        </div>
                        <div className="celender_cls">
                          <h5>Select Session Slot</h5>
                          <div className="calendar-inner-cls">
                            <Calendar
                              className="border-0"
                              onChange={setPickedDate}
                              value={pickedDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="time-title">
                          <h4>Choose sessesion timing</h4>
                        </div>
                        <div className="time_sel_cls">
                          <form>
                            {
                              sessionSlotsTiming?.map((item, idx) => (
                                <div
                                  onClick={(e) => { setSessionTiming(item) }}
                                  className={`form-group ${selectedSessionTiming == item ? "active" : ''}`}>
                                  <input
                                    disabled={true}
                                    type="text"
                                    name=""
                                    placeholder={item}
                                    className="form-control"
                                  />
                                  {
                                    selectedSessionTiming == item &&
                                    <span className="checked-cls">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        fill="#000000"
                                      >
                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"></path>
                                      </svg>
                                    </span>
                                  }
                                </div>
                              ))
                            }
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="cv-cls">
                      <h3>Upload CV</h3>
                      <div className="form-group input-group">
                        <input type="text" name="" className="form-control" />
                        <div className="file_input">
                          <input
                            type="file"
                            name="Choose a file"
                            id="file-upload"
                            className="form-btn"
                          />
                          <span htmlFor="fileToUpload">Choose a file</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="get-started">
                    <button
                      type="button"
                      className="btn vew-profile-btn"
                      onClick={() => {
                        console.log('your selected sesion data ', sessionCharge, pickedDate, selectedSessionTiming)
                        initiatePayment()
                      }}
                    >
                      Continue adlj;alkdjf
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Group Session --> */}
      {/* <div className="sign-up-popup group-session-popup">
        <div
          className="modal fade"
          id="gorupsession"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-xl modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <form>
                <div className="modal-body">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <img alt="" src={crossIcon} />
                      </span>
                    </button>
                  </div>
                  <div className="group-session-sec">
                    <div className="session-inner-cls">
                      <div className="session-left-sec">
                        <div className="session-title">
                          <h3>
                            A Basic Guide to start your career in java script
                            development
                          </h3>
                          <div className="time-day">
                            <div className="time-seesion">
                              <span className="session-ic">
                                <img alt="" src={timeImg} />
                              </span>
                              <span className="session-con">
                                1 Hour Session
                              </span>
                            </div>
                            <div className="day-seesion">
                              <span className="session-ic">
                                <img alt="" src={calendarImg} />
                              </span>
                              <span className="session-con">Every Week</span>
                            </div>
                          </div>
                          <div className="sess-box-cont">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's .
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="session-right-sec">
                        <div className="session-price">
                          <h4>
                            $50/<span>Session</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="session-highlight-cont">
                      <div className="session-highlight">
                        <h6>Session Highlights</h6>
                        <p>
                          2 Hours sessions for choosing a right session for your
                          career.
                        </p>
                      </div>
                      <div className="session-highlight">
                        <h6>Session Objectives</h6>
                        <p>
                          2 Hours sessions for choosing a right session for your
                          career.
                        </p>
                      </div>
                      <div className="session-highlight">
                        <h6>Benefits to join</h6>
                        <ul>
                          <li>
                            2 Hours sessions for choosing a right session for
                            your career.
                          </li>
                          <li>
                            2 Hours sessions for choosing a right session for
                            your career.
                          </li>
                          <li>
                            2 Hours sessions for choosing a right session for
                            your career.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="get-started">
                      <button className="btn vew-profile-btn">
                        Book Your Spot
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {opensendRequestModal?.modal && (
        <opensendRequestModal.modal
          opensendRequestModal={opensendRequestModal}
          setOpensendRequestModal={setOpensendRequestModal}
          mentor={mentor}
        />
      )}
    </React.Fragment>
  );
};

export default MentorDetails;
