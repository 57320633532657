import React from "react";
import { useState } from "react";
import "./UpdateLinkedin.css";

const UpdateLinkedin = ({ linkedinData, getUpdatedValues }) => {
  const [linkedinTxt, setLinkedinTxt] = useState(linkedinData);
  const handleOnChange = (e) => {
    setLinkedinTxt(e.target.value);
  };
  const handleOnClick = () => {
    let tempData = { linkedin_url: linkedinTxt };
    getUpdatedValues(tempData);
  };
  return (
    <div className="update-linkedin-main-container">
      <input
        className="linkedin-input"
        value={linkedinTxt}
        onChange={handleOnChange}
      ></input>
      <button className="update-btn-linkedin" onClick={handleOnClick}>
        Update
      </button>
    </div>
  );
};

export default UpdateLinkedin;
