import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const WebinarComponent = () => {
  const navigate = useNavigate();

  const tabs = [
    { label: 'Upcoming', key: 'upcoming' },
    { label: 'Completed', key: 'completed' },
  ];

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabClick = (index, tabKey) => {
    setSelectedTabIndex(index);
    navigate(`/dashboard/calls/webinar/${tabKey}`);
  };

  return (
    <>
        <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
          <div className="ant-tabs ant-tabs-top booking-tabs ">
            <div role="tablist" className="ant-tabs-nav">
              <div className="ant-tabs-nav-wrap">
                <div className="ant-tabs-nav-list" style={{ transform: "translate(0px, 0px)" }}>
                  {tabs.map((tab, index) => (
                    <div key={index} data-node-key={index + 1} className={`ant-tabs-tab ${index === selectedTabIndex ? 'ant-tabs-tab-active' : ''}`}>
                      <div
                        role="tab"
                        aria-selected={index === selectedTabIndex}
                        className="ant-tabs-tab-btn"
                        tabIndex={0}
                        id={`rc-tabs-25-tab-${index + 1}`}
                        aria-controls={`rc-tabs-25-panel-${index + 1}`}
                        onClick={() => handleTabClick(index, tab.key)}
                      >
                        {tab.label}
                      </div>
                    </div>
                  ))}
                  <div
                    className="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                    style={{
                      left: `${37.5938 + selectedTabIndex * 100}px`,
                      transform: "translateX(-50%)",
                      width: "75.1875px"
                    }}
                  />
                </div>
              </div>
              <div className="ant-tabs-nav-operations ant-tabs-nav-operations-hidden">
                <button
                  type="button"
                  className="ant-tabs-nav-more"
                  tabIndex={-1}
                  aria-hidden="true"
                  aria-haspopup="listbox"
                  aria-controls="rc-tabs-25-more-popup"
                  id="rc-tabs-25-more"
                  aria-expanded="false"
                  style={{ visibility: "hidden", order: 1 }}
                >
                  <span
                    role="img"
                    aria-label="ellipsis"
                    className="anticon anticon-ellipsis"
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="ellipsis"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <div className="ant-tabs-content-holder">
              <Outlet />
            </div>
          </div>
        </div>
     </>
  );
}

export default WebinarComponent;
