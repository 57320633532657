import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dotImg from "../img/dot.png";

import cameraImg from "../img/camera.png";

import { getUserDetails, updateProfile } from "../../../redux/actions/login";
import { Outlet, useNavigate } from "react-router-dom";

import { REACT_USER_ID } from "../../auth/tokenProvider";

import { TailSpin } from "react-loader-spinner";
import { uploadImage, uploadImageFile } from "../../../redux/actions/common";
import { v4 } from "uuid";

import maleAvatar from "../../../assets/images/male.png";
import femaleAvatar from "../../../assets/images/female.png";

const MyProfile = () => {
  const dispatch = useDispatch();
  const user_id = REACT_USER_ID;
  const navigate = useNavigate();

  const [fileData, setFileData] = useState(undefined);
  const [file, setFile] = useState();

  const [userType, setuserType] = useState(
    localStorage.getItem("REACT_USER_TYPE")
  );


  useEffect(() => {
    import("../css/custom.css");
    // import("./Profilesetting.css");
    import("./CustomProfile.css");
  }, []);

  useEffect(() => {
    dispatch(getUserDetails(user_id));
  }, []);

  const imageURLReqData = useSelector(
    (state) => state.uploadImageReducer?.data?.url
  );

  useEffect(() => {
    if (imageURLReqData) {
      const reqData = { url: imageURLReqData, fileData };
      dispatch(uploadImageFile(reqData));
    }
  }, [imageURLReqData]);

  const dragNdrop = (event) => {
    setFileData(event.target.files[0]);
    const fileName = "_profile-photo";
    const imagePath = "profilePhoto/";

    const type = event.target.files[0].type.split("/");
    if (!type[0] || type[0] !== "image") {
      console.error("You can only upload image files.");
      return;
    }
    let imageName = imagePath + v4() + fileName;

    let imageNameExtension = imageName + "." + type[1];

    dispatch(uploadImage({ documentName: imageName, format: type[1] }));

    const updateProfileImageName = {
      user_id: REACT_USER_ID,
      profile_photo: imageNameExtension,
    };

    dispatch(updateProfile(updateProfileImageName));
    setFile(URL.createObjectURL(event.target.files[0]));
  };
  const drag = () => {
    // document.getElementById('uploadFile').parentNode.className = 'draging
    // dragBox';
  };
  const drop = () => {
    // document.getElementById('uploadFile').parentNode.className = 'dragBox';
  };

  const userDetailsReducerData = useSelector((state) => {
    return state.userDetailsReducer;
  });

  const userDetailsArray = userDetailsReducerData?.data || [];
  const userDetails = userDetailsArray[0];
  const isLoading = userDetailsReducerData?.loading;
  // useEffect(()=>)
  return (
    <>
      <div className="w-100">
        <div className="d-sm-flex align-items-center justify-content-between">
          {/* <h1 className="h3 mb-0 text-gray-800 main-title">Go Back</h1> */}
        </div>
        <div className="row">
          <div className="col-xl-12 col-md-12">
            <div className="card border-0 ">
              <div className="card-header header-table tab-header">
                <ul
                  className="nav nav-tabs"
                  role="tablist"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabs-1"
                      role="tab"
                      onClick={() => navigate("")}
                    >
                      Your Profile
                    </a>
                  </li>
                  {
                    userType !== 'mentee' ? (
                      <>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-2"
                            role="tab"
                            onClick={() => navigate("security-setting")}
                          >
                            Settings
                          </a>
                        </li>
                      </>
                    )
                      : (null)
                  }

                </ul>
              </div>
              {isLoading ? (
                <div className="loader">
                  <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
              ) : (
                <>
                  <div className=" Your--profile--section">
                    <div
                      className="card  border-0 profile-card"
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <div className="profile-left-side ">
                        <div className="profile--sec profile___margin">
                          <div className="profile____box">
                            <div className="profile-pic">
                              <label className="-label" htmlFor="file">
                                <span className="glyphicon glyphicon-camera" />
                                <span className="camera___icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-camera"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                  </svg>
                                </span>
                              </label>
                              <input
                                id="file"
                                type="file"
                                accept="image/*"
                                onChange={dragNdrop}
                                onDragOver={drag()}
                                onDrop={drop()}
                              />
                              {
                                <img
                                  id="output"
                                  width={200}
                                  src={`${userDetails?.profile_photo}`}
                                  // src={
                                  //   file ? file : userDetails?.profile_photo
                                  //     ? `${process.env.REACT_APP_IMAGES_BASE_URL}${userDetails?.profile_photo}`
                                  //     : userDetails?.gender === "female"
                                  //       ? `${femaleAvatar}`
                                  //       : `${maleAvatar}`
                                  // }
                                  alt=""
                                />
                              }
                            </div>
                          </div>

                          <div className="profile-name-box">
                            <h5>{userDetails?.name}</h5>
                            <p>{userDetails?.email?.email}</p>
                          </div>
                          {/* Image upload section  */}
                          <div className="profile-dot">
                            <span className="dropdown">
                              <a
                                href="#"
                                className="dropdown-toggle"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                <img src={dotImg} alt="" />
                              </a>
                              <div
                                className="pprofile-upload dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                                x-placement="bottom-start"
                                style={{
                                  position: "absolute",
                                  willChange: "transform",
                                  top: 0,
                                  left: 0,
                                  transform: "translate3d(20px, 29px, 0px)",
                                }}
                              >
                                <ul>
                                  {/* <li>
                                    <a href="#">
                                      <span className="link-ic icon--padding">
                                        <img src={deleteImg} />
                                      </span>
                                      Remove Photo
                                    </a>
                                  </li> */}
                                  <li>
                                    <div className="file_input">
                                      <input
                                        type="file"
                                        name="Choose a file"
                                        id="file-upload"
                                        className="form-btn"
                                      />
                                      <span htmlFor="fileToUpload">
                                        <span className="link-ic">
                                          <img src={cameraImg} />
                                        </span>
                                        Update Profile Photo
                                        <input
                                          type="file"
                                          accept="image/*"
                                          onChange={dragNdrop}
                                          onDragOver={drag()}
                                          onDrop={drop()}
                                          id="uploadFile"
                                        />
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card-body border-0 table-inner">
                        <div className="tab-content">
                          <Outlet />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
