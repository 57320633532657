import React, { useState } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import crossImg from './img/cross.png';
import dashboardImg from './img/dashboard.png';
import sessionImg from './img/session.png';
import profileImg from './img/profile.png';
import eBookImg from './img/e-book.png';
import articleImg from './img/article.png';
import DashboardHeader from './DashboardHeader';
import Availability from "./createSessions/availability";
import Help from "./createSessions/help";
import Overlay from "./createSessions/overlay";
import LookSession from "./createSessions/looksession";
import Services from "./createSessions/service";
import Track from "./createSessions/track";
import Detail from "./createSessions/details";

import ChooseSession from "./createSessions/chooseSession";


  
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createMentorSessionSevice } from "../../redux/actions/mentor";
import("./css/custom.css");

const CreateSession = () => {
  const navigate = useNavigate();
  // useEffect(() => {

  //   import('./css/custom.css');
  // }, []);
  const dispatch = useDispatch();
  const [state, setState] = useState(1);

  const [card, setCard] = useState(<ChooseSession />);
  const nextPage = () => {
    setState((state) => state + 1);
    changestate(state);
  };

  const prevPage = () => {
    setState((state) => state - 1);
    changestate(state);
  };
  const sessionServiceFormData = useSelector(
    (state) => state.mentorSessionServiceFormDataReducer
  );
  const createMentorSessionServiceData = useSelector(
    (state) => state.createMentorSessionServiceReducer
  );
  const changestate = (index) => {
    switch (index) {
      case 1:
        return setCard(<ChooseSession />);
      case 2:
        return setCard(<Detail />);
      case 3:
        return setCard(<Availability />);
      case 4:
        return setCard(<Services />);
      case 5:
        return setCard(<Help />);
      case 6:
        return setCard(<Track />);
      case 7:
        return setCard(<LookSession />);
      case 8:
        return setCard(<Overlay />);
      default:
        return setCard(<ChooseSession />);
    }
  };
  const uploadSessionData = async () => {
    dispatch(createMentorSessionSevice(JSON.stringify(sessionServiceFormData?.data)));
    
    
  };
  useEffect(() => {
    if (createMentorSessionServiceData?.loading ===false){
      setState(8);
    }
  }, [createMentorSessionServiceData?.loading,createMentorSessionServiceData?.status]);
  useEffect(() => {
    changestate(state);
  }, [state]);

  return (
    <>
      <div id="wrapper">
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}

            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800 main-title">Go Back</h1>
              </div>

              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-md-12 mb-4">
                  <div className="card">
                    <div className="card-body create-session-cls">
                      <div className="aapply-mentor-sec">
                        <div className="step-cont">
                          <div className="step-inner-cls">
                            <form action="#" method="post" id="raq_questions">
                              {card}
                            </form>

                            {state == 8 || state > 8 ? (
                              ""
                            ) : (
                              <div className="button-bar next-cls">
                                <input
                                  type="button"
                                  className="btn btn-primary"
                                  value="Previous"
                                  id="raq_back"
                                  disabled={state < 2}
                                  onClick={() => prevPage()}
                                />
                                {state < 7 ? (
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    value="Next"
                                    id="raq_next"
                                    onClick={() => nextPage()}
                                  />
                                ) : (
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    value="Finish"
                                    id="raq_next"
                                    onClick={() => uploadSessionData()}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};;

export default CreateSession;
