import {
    googleCalanderConstants
  } from "../../constants";

  export const generateAuthLink = (state = {}, action) => {
    switch (action.type) {
      case googleCalanderConstants.GENERATE_AUTH_LINK_REQUEST:
        return {
          ...state,
          request: action.data,
          loading: true,
        };
      case googleCalanderConstants.GENERATE_AUTH_LINK_REQUEST_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.data,
          status: action.status,
          error: action.error,
        };
      case googleCalanderConstants.GENERATE_AUTH_LINK_REQUEST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
        case googleCalanderConstants.HANDLE_GOOGLE_REDIRECT_REQUEST:
          return {
            ...state,
            request: action.data,
            loading: true,
          };
        case googleCalanderConstants.HANDLE_GOOGLE_REDIRECT_REQUEST_SUCCESS:
          return {
            ...state,
            loading: false,
            data: action.data,
            status: action.status,
            error: action.error,
          };
        case googleCalanderConstants.HANDLE_GOOGLE_REDIRECT_REQUEST_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error,
          };
      default:
        return state;
    }
  };