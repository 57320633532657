import React, { useState } from "react";


import { menuIcon } from "../../../assets/images/export-images";
import { crossIcon } from "../../../assets/images/export-images";
import logo from '../../../assets/images/logo-w.png'
import '../../header/navbar.css'
import '../../dashboard/css/media.css'

const MasterHeader = ({ extraClasses = "" }) => {
    const [isOpen, setIsOpen] = useState(true);


    return (
        <header className={`${extraClasses} header-cls`}>
            <meta
                name="viewport"
                content="width=device-width, initial scale=1.0,user-scalable=no"
            />
            <div
                className="container"
                style={{ paddingLeft: "0", paddingRight: "0" }}
            >
                <nav
                    className="menu navbar navbar-expand-lg navbar__fix"
                    style={{ display: "", flexBasis: "" }}
                >
                    <a className="navbar-brand" href="/">
                        <img src={logo} alt="logo" className="header-logo ms-auto" />
                    </a>
                    <button
                        className={"navbar-toggler"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#headerHandler"
                        aria-controls="headerHandler"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <img
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                            alt=""
                            src={isOpen ? menuIcon : crossIcon}
                        />
                    </button>
                    <div
                        className="menu collapse navbar-collapse rounded Mobile_menu m-2"
                        style={{ display: "", flexBasis: "100%" }}
                        id="headerHandler"
                    >
                        <ul className="navbar-nav align-items-center Mobile_menu_list">


                            <li className="nav-item">
                                <a
                                    href='/host-course'
                                    className="button btn get-started"


                                >
                                    Host a course
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href='/explore-courses'
                                    className="button btn get-started"


                                >
                                    Explore courses
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default MasterHeader;
