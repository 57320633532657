import React, { useEffect, useState } from 'react';
import { findMentor } from '../../network';
import { ColorRing } from 'react-loader-spinner';
import Carousel from "react-multi-carousel";


import maleAvatar from "../../assets/images/male.png";
import femaleAvatar from "../../assets/images/female.png";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { viewMentorProfileAction } from '../../redux/actions/mentor';
import "react-multi-carousel/lib/styles.css";

const TopMentor = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isMentorsLoading, setIsMentorsLoading] = useState(true);
    const [topMentors, setTopMentors] = useState([])

    const fetchTopMentors = async () => {
        const data = {
            pageSize: 30,
            pageNo: 1,
            skills: "top",
        };
        const response = await findMentor(data)
        setTopMentors(prev => [...prev, ...response.data[0].data])
        setIsMentorsLoading(false)
    }

    useEffect(() => {
        fetchTopMentors()
    }, [])
    const responsive_for_top_mentors_carousel = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 992 },
            items: 4,
        },

        ipad: {
            breakpoint: { max: 992, min: 768 },
            items: 3,
        },

        tablet1: {
            breakpoint: { max: 768, min: 465 },
            items: 2,
        },

        mobile: {
            breakpoint: { max: 465, min: 0 },
            items: 1,
        },
    };


    let date = new Date();
    let datestring = date.toTimeString();
    let countryname =
        datestring?.includes("india") | datestring?.includes("India")
            ? "India"
            : "";

    const viewProfile = (mentor) => {
        let user = mentor.name.split(" ").join("") + "_" + mentor.user_id;
        dispatch(viewMentorProfileAction(mentor.user_id));
        navigate("/mentor-details/" + user);
    };

    return (
        <>
            <section className="container">


                {
                    isMentorsLoading ?
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem" }}>
                            <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />
                        </div>
                        :


                        <Carousel
                            autoPlaySpeed={2000}
                            rewindWithAnimation={true}
                            autoPlay={true}
                            rewind={true}
                            className="testimonial_cards"
                            responsive={responsive_for_top_mentors_carousel}
                        >
                            {topMentors.map((mentor, id) => (
                                <div
                                    key={id}
                                    // className=" mentor___list"


                                    style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "1px" }}
                                >
                                    <div className=" mentr_list__box cursor-pointer" onClick={() => viewProfile(mentor)}>
                                        <div className="mentor-avatar overflow-hidden">
                                            <div className="image-dimmer" />
                                            {mentor?.profile_photo && mentor?.profile_photo != "" ? (
                                                <img
                                                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}${mentor?.profile_photo}`}
                                                    alt={mentor?.name}
                                                />
                                            ) : mentor?.gender === "female" ? (
                                                <img
                                                        alt="femaleicon"
                                                    src={femaleAvatar}
                                                    style={{ width: "17rem" }}
                                                />
                                            ) : (
                                                <img alt="maleicon" src={maleAvatar} />
                                            )}
                                        </div>
                                        <div className=" name___box " width={1920}>
                                            <p className="preview__content__name d-flex align-items-center text-truncate">
                                                <span className="black-text font-weight-600 text-truncate">
                                                    {mentor.name}
                                                </span>
                                            </p>
                                            <div className="preview__content__job grey-1-text">
                                                <div className="item-list">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-briefcase"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                                    </svg>
                                                    <p className=" mentor___feature ml-2">
                                                        <span>{mentor.title}</span>{" "}
                                                        <span className="grey-2-text">at</span>{" "}
                                                        <span>{mentor.company_or_school}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p
                                                    className=" mentor___feature grey-1-text"
                                                    style={{ marginLeft: "11px" }}
                                                >
                                                    <span className="grey-2-text">{mentor.country}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="preview__content__metadata grey-4-bg expertise-p3 mt-auto d-flex justify-content-between">
                                            <div>
                                                <p className=" dZPGPc grey-2-text">Experience</p>
                                                <p className=" mentor___feature grey-1-text font-weight-600">
                                                    {mentor.experience}
                                                </p>
                                            </div>
                                            <div>
                                                <p className=" dZPGPc grey-2-text">Price Per Session</p>
                                                <p className=" mentor___feature grey-1-text font-weight-600">
                                                    {mentor?.inr_price === "0" &&
                                                        mentor?.price_per_session === "0"
                                                        ? "Pro Bono"
                                                        : countryname === "India"
                                                            ? "₹" + mentor?.inr_price
                                                            : "$" + mentor?.price_per_session}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </Carousel >
                }
            </section>

        </>
    );
}

export default TopMentor;
