import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import SignUpModal from "../modals/signUpModal";
import ProtectedHeader from "../ProtectedHeader/ProtectedHeader";
import { TailSpin } from "react-loader-spinner";
import Testimonial from "../Testimonial/testimonial.js";
import collage from "../../assets/images/mentorsCollage (2).png";
import Carousel from "react-multi-carousel";
import {
  eventBg,
  bannerImg,
  joinImg, mentorEvent, arR,
  ic1,
  ic2,
  ic3,
  ic4,
  ic5,
  ic6,
  ic7,
  lg1,
  lg2,
  lg3,
  pro1,
  pro2, crossIcon
} from "../../assets/images/export-images";


import ibm from "../../assets/images/IBM.png";
import amazon from "../../assets/images/amazon.png";
import adobe from "../../assets/images/adobe.png";
import skype from "../../assets/images/skype.png";
import intel from "../../assets/images/intel.png";
import hp from "../../assets/images/hp.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/custom.css";
import "../../assets/css/header.css";
import "../../assets/css/main-banner.css";
import "../../assets/css/world-class-mentorship.css";
import "../../assets/css/why-choose-mentorpal.css";
import "../../assets/css/already-mentoring-people.css";
import "../../assets/css/event-sec.css";
import "../../assets/css/top-mentor.css";
import "../../assets/css/save-time.css";
import "../../assets/css/help-us.css";
import "../../assets/css/footer.css";
import "../../assets/css/popular-sessions.css";
import "../../assets/css/signup-popup.css";
import "../../assets/css/media.css";
import "../../assets/css/cardsModal.css";
import { logged, REACT_USER_ID } from "../auth/tokenProvider";
import { getUserDetails } from "../../redux/actions/login";

import "react-multi-carousel/lib/styles.css";
import Header from "../header";
import Footer from "../footer";

import {
  findMentorAction
} from "../../redux/actions/mentor";
import TopMentor from "./TopMentor";

const Home = ({loader}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [headerModal, setHeaderModal] = useState({});
  const [search, setSearch] = useState("top");
  const [isOpenSessionsLoading, setIsOpenSessionsLoading] = useState(false);
  const [openSessions, setOpenSessions] = useState([]);
  const [userType, setUserType] = useState("");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
  );


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 992 },
      items: 5,
    },

    ipad: {
      breakpoint: { max: 992, min: 768 },
      items: 3,
    },

    tablet1: {
      breakpoint: { max: 768, min: 465 },
      items: 1,
    },

    mobile: {
      breakpoint: { max: 465, min: 0 },
      items: 1,
    },
  };

  const findMentorFilterData = [
    { label: "Content Writing", value: "Content Writing" },
    { label: "Data Science", value: "Data Science" },
    { label: "Design", value: "Design" },
    { label: "Marketing", value: "Marketing" },
    { label: "Product Management", value: "Product Management" },
    { label: "Product Research", value: "Product Research" },
    { label: "Software Development", value: "Software Development" },
  ];
  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });
  // for carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // centerMode : true
  };
  const companyDetails = [
    { src: lg1 },
    { src: lg2 },
    { src: lg3 },
    { src: ibm },
    { src: amazon },
    { src: adobe },
    { src: skype },
    { src: intel },
    { src: hp },
  ];

  const userData = [
    {
      name: "Reena bantu",
      comment:
        "I can't say enough good things about my experience with Mentorpal. As a mentee, I was matched with an amazing mentor who has been a constant source of support and guidance. He has helped me to identify and pursue my goals, and he has provided me with valuable insights and advice that have helped me to grow both personally and professionally",
      title: "Node js",
    },
    {
      name: "munesh",
      comment:
        "I feel so fortunate to have had the opportunity to work with my mentor and the Mentorpal platform. The platform is user-friendly and the support team is always there to help with any questions or concerns. I highly recommend Mentorpal to anyone seeking a mentor to help them achieve their goals and realize their full potential.",
      title: "java",
    },
    {
      name: "jitendra",
      comment:
        "I have been a mentee on the Mentorpal platform for the past six months and it has truly been a life-changing experience. My mentor has been an incredible source of knowledge, support, and motivation for me. She has helped me to clarify my goals, develop a plan to achieve them, and provided me with valuable resources and guidance along the way",
      title: "next.js",
    },
    {
      name: "jackson",
      comment:
        "I am so grateful to have had the opportunity to work with such an amazing mentor and I highly recommend the Mentorpal platform to anyone seeking guidance and support in their personal or professional development. Thank you, Mentorpal, for providing such a valuable service and for connecting me with my amazing mentor.",
      title: "react.js",
    },
    {
      name: "harpal singh",
      comment:
        "I recently had the opportunity to participate in the Mentorpal mentoring program and it has been an absolute game-changer for me. My mentor, has been a constant source of guidance, support, and encouragement throughout the program. He has a wealth of knowledge and experience in his field and he has been incredibly generous in sharing that with me.",
      title: "Design",
    },
    {
      name: "Neha",
      comment:
        "Not only has my mentor helped me to identify and pursue my goals, but he has also challenged me to think critically and push myself out of my comfort zone. I feel so much more confident and motivated as a result of this mentorship and I am truly grateful to my mentor for all of his help.",
      title: "Content store",
    },
    {
      name: "Sachin",
      comment:
        "I highly recommend the Mentorpal platform to anyone looking for a mentor. The personalized attention and support that I received from Mentorpal has been invaluable and I am confident that it will have a lasting impact on my future. Thank you, Mentorpal!",
      title: "Node.Js",
    },
  ];

  const isAuth = useSelector((state) => {
    const data =
      (state.userDetailsReducer?.data &&
        state.userDetailsReducer?.data[0]?.user_id) ||
      state.passwordLoginReducer?.data?.user_id;
    return data ? true : false;
  });

  let MENTOR_PAGE_SIZE = 12,
    MENTOR_PAGE_NUMBER = 1;
  const findMentors = () => {
    const data = {
      pageSize: MENTOR_PAGE_SIZE,
      pageNo: MENTOR_PAGE_NUMBER,
      skills: search,
    };
    dispatch(findMentorAction(data));
    navigate("/find-mentor");
  };

  useSelector((state) => {
    if (
      isOpenSessionsLoading &&
      state.openSessionsReducer?.loading &&
      Object.keys(openSessions).length !== 0
    )
      setOpenSessions([]);

    const request = state.openSessionsReducer?.request;
    const loading = state.openSessionsReducer?.loading;
    const data = state.openSessionsReducer?.data;

    if (loading && !isOpenSessionsLoading) setIsOpenSessionsLoading(true);

    if (request === undefined) {
      if (isOpenSessionsLoading) setIsOpenSessionsLoading(false);
      return;
    }
    if (
      !loading &&
      Object.keys(openSessions).length === 0 &&
      data !== undefined &&
      data.length !== 0
    ) {
      setOpenSessions(data);
      setIsOpenSessionsLoading(false);
    }
  });




  // useEffect(() => {
  //   if (openSessions.length === 0) {
  //     dispatch(
  //       getOpenSessionsAction({
  //         pageSize: 1,
  //         pageNo: 20,
  //       })
  //     );
  //   }
  // }, []);



  const handleUserStatus = (status, type) => {
    switch (status) {
      case "Signup":
        navigate("apply-as-mentor");
        return;
      case "registered":
        navigate("/");
        // alert("Your profile is under verification")
        return;
      // navigate("apply-as-mentor")
      case "onhold":
        navigate("/mentor-dashboard");
        // alert("Your profile is needed action from mentors")
        return;
      default:
        navigate("/mentor-dashboard");
    }
  };

  // route redirect
  const handleRouting = (data) => {
    if (data.user_type === "mentor") {
      if (data?.status == "activated") {
        navigate("/mentor-dashboard");
      } else {
        handleUserStatus(data.status, data.user_type);
      }
    }
    if (data.user_type === "mentee") {
      if (data.calendar_permission == "no" || !data.calendar_permission)
        navigate("/event-calendar");
      if (data.calendar_permission == "yes") navigate("/dashboard");
    }
  };

  useEffect(() => {
    if (logged || isAuth) {
      if (!userDetails) {
        dispatch(getUserDetails(REACT_USER_ID));
      } else {
        handleRouting(userDetails);
      }
    }
  }, [userDetails]);

  const clickPopular = (value) => {
    const data = {
      pageSize: MENTOR_PAGE_SIZE,
      pageNo: MENTOR_PAGE_NUMBER,
      experties: value,
    };
    dispatch(findMentorAction(data));
    navigate("find-mentor", {
      state: {
        expertise: value
      }
    });
  };

  let date = new Date();
  let datestring = date.toTimeString();
  let countryname =
    datestring?.includes("india") | datestring?.includes("India")
      ? "India"
      : "";

  const boxesData = [
    {
      icon: ic1,
      title: "Verfied Mentors across the world",
      description:
        "We have verified and superior mentors who see more talent and potential in you than you see in yourself, and help you bring it out.",
    },
    {
      icon: ic2,
      title: "One-On-One Private Sessions",
      description:
        "We organize 1:1 personal sessions which is the most effective tool for mentoring. It is a two-way encounter between a mentor and the mentee, it helps in identifying any barriers and providing mentorship to eliminate them.",
    },
    {
      icon: ic3,
      title: "Open Sessions for everyone",
      description:
        "Mentorpal offers many open, free sessions to everyone by industry veterans. It would be well structured in different disciplines.",
    },
    {
      icon: ic4,
      title: "Easy & Affordable Subscriptions",
      description:
        "As per our social concern, we would take you to the zenith of success, so we offer very easy and affordable subscriptions that would be a bittock of your income.",
    },
  ];

  const [activeTab, setActiveTab] = useState(null);

  const toggleTab = (index) => {
    setActiveTab(activeTab === index ? null : index);
  };

  return (
    <>
      {loggedIn ? <ProtectedHeader /> : <Header />}
      {
        loader && (
          <div className="loader" style={{ zIndex: 9999 }}>
       
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
        )
      }
      <section
        className="banner-sec"
        style={{ backgroundImage: `url(${bannerImg})` }}
      >
        <div className="container">
          <div className="mentroship-sec">
            <h3>Join, Learn, Grow</h3>
            <h1>One-on-One Mentorship</h1>
            <p>
              Ensuring the best mentors at fingertips. Join mentors from across
              the globe & give new height to your career.
            </p>
            <div className="form-group input-group">
              <input
                type="text"
                name=""
                style={{ borderRadius: "10p 0px 0px 10px" }}
                className="form-control"
                placeholder="Search for Mentorship eg. Java or Marketing"
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    findMentors();
                  }
                }}
              />
              <button
                className={`form-control submit-btn `}
                style={{ padding: "0px" }}
                // disabled={search == "" ? true : false}
                onClick={findMentors}
              >
                Find Mentors
              </button>
              {/* <input
                type="submit"
                name=""
               value="Find Mentors"
              /> */}
            </div>
          </div>
          <div className="popular-mentorship">
            <p>Don’t know what to search? Try Our Popular Mentorships</p>
            <ul>
              {findMentorFilterData?.map((item, id) => (
                <li key={id}>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      clickPopular(item.label);
                    }}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className="mentors-tabs">
        <div className="container">
          <div className="row">
            {boxesData.map((box, index) => (
              <div className="col-sm-6 col-md-3 col-lg-3" key={index}>
                <div className="box-wrap">
                  <div className="icon-cls">
                    <img alt="icon" src={box?.icon} />
                  </div>
                  <div className="mentor-cont">
                    <h4>{box.title}</h4>
                    <p>
                      {box.description.length > 120
                        ? box.description.substring(0, 120) + "..."
                        : box.description}
                    </p>
                    <a className="myBtn" onClick={() => toggleTab(index + 1)}>
                      Read More{" "}
                      <span className="ar-1">
                        <img alt="icon" src={arR} />
                      </span>
                    </a>
                    {activeTab === index + 1 && (
                      <div className="services__modal active-modal services__modal-overlay">
                        <div className="services__modal-content">
                          <i
                            onClick={() => toggleTab(null)}
                            className="uil uil-times services__modal-close"
                          >
                            <img src={crossIcon} />
                          </i>
                          <div className="icon-cls" id="popup-icon">
                            <img alt="icon" src={box.icon} />
                          </div>
                          <h3 className="services__modal-title">{box.title}</h3>
                          <p className="services__modal-description">
                            {box.description}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <!-- World className Mentorship --> */}
          <div className="mentorship-cls">
            <div className="inner-title">
              <h3>World Class Mentorships</h3>
              <h2>Get Mentored By Top Tech Giants</h2>
            </div>
          </div>

          <Carousel
            autoPlaySpeed={2000}
            rewindWithAnimation={true}
            rewind={true}
            className="testimonial_cards pb-5"
            autoPlay={true}
            responsive={responsive}
          >
            {companyDetails.map((data) => (
              <div className="partner-col">
                <div
                  className="partner-img"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a href="#">
                    <img alt={data.src} src={data.src} />
                  </a>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </section>

      {/* <!-- Top Mentors --> */}

      <div className="inner-title" style={{ paddingTop: '80px' }}>
        <h3>Top Mentors</h3>
        <h2>
          Get mentored by one of the pre-eminent{" "}
          <span className="d-block">mentors in the world</span>
        </h2>
      </div>
      <TopMentor />
      {/* <!-- Why Choose MentorPal --> */}
      <section className="why-choose ">
        <div className="container">
          <div className="inner-title">
            <h3>Only MentorPal! Why?</h3>
            <h2>
              Because, these features are our strength{" "}
              <span className="d-block">choose MentorPal</span>
            </h2>
          </div>
          <div className="mentorpal-sec">
            <div className="row">
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic2} />
                  </div>
                  <div className="serve-cont">
                    <h4>Helps You Achieve goals</h4>
                    <p>
                      We will provide you mentor who will help you a lot in
                      achieving your goals quickly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic6} />
                  </div>
                  <div className="serve-cont">
                    <h4>Video, Audio & Chats</h4>
                    <p>
                      We offer you video and audio chats with us so that you can
                      elaborate your issues clearly and get the solution
                      effectively.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic7} />
                  </div>
                  <div className="serve-cont">
                    <h4>24x7 Customer Support</h4>
                    <p>We have 24*7 support system to help you day or night.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic5} />
                  </div>
                  <div className="serve-cont">
                    <h4>Free Study Material</h4>
                    <p>
                      You do not need to worry about your study material. At
                      mentorpal, we provide you with free-study material on your
                      specific discipline.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic6} />
                  </div>
                  <div className="serve-cont">
                    <h4>Task & Excercises</h4>
                    <p>
                      We organize many tasks and exercises to enhance your
                      abilities and inspect your capabilities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic7} />
                  </div>
                  <div className="serve-cont">
                    <h4>Low Fee & Subcription</h4>
                    <p>
                      Our subscription fee is meager because we are trying to
                      provide the best service at a light cost.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="get-started-cls">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  setHeaderModal({
                    modal: SignUpModal,
                    show: true,
                    as: "mentee",
                  })
                }
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Already mentoring people --> */}
      <section className="already-mentor-sec ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="joinImg-img">
                <img alt="icon" src={joinImg} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mentoring-cont">
                <div className="inner-title">
                  <h3>Already mentoring people?</h3>
                  <h2>
                    Join hands with MentorPal & <span> Become a Mentor</span>
                  </h2>
                </div>
                <ul>
                  <li>
                    <span className="tick">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                      </svg>
                    </span>
                    <span className="cont-cls">Reach out more people</span>
                  </li>
                  <li>
                    <span className="tick">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                      </svg>
                    </span>
                    <span className="cont-cls">Help & grow with MentorPal</span>
                  </li>
                  <li>
                    <span className="tick">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                      </svg>
                    </span>
                    <span className="cont-cls">
                      take your knowledge to global level
                    </span>
                  </li>
                </ul>
                <div className="get-started-cls" id="get-started-cls">
                  <button
                    onClick={() =>
                      setHeaderModal({
                        modal: SignUpModal,
                        show: true,
                        as: "mentor",
                      })
                    }
                    type="button"
                    className="btn btn-primary"
                  >
                    Apply as a Mentor
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="download-wrap">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="mentoring-cont">
                  <div className="inner-title">
                    <h3>Mentorship In your pocket</h3>
                    <h2>
                      Download <span className="font-bold">Mobile App </span>
                    </h2>
                  </div>
                  <p>
                    Talk to your favirot mentors & join on the go with MentorPal
                    Mobile app. Just click the button below and we will share a
                    download link in your message. for super fast services and
                    place more content.
                  </p>
                  <div className="download-cls">
                    <a href="#">
                      <img alt="icon" src={googleStore} />
                    </a>
                    <a href="#">
                      <img alt="icon" src={appStore} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mentor-img">
                  <img alt="icon" src={appScreen} />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* <!-- event --> */}
      <section
        className="event-mentor-sec space-cls"
        style={{
          backgroundImage: `url(${eventBg})`,
          backgroundAttachment: "fixed",
        }}
      >
        <div className="why_choose_ment">
          <div className="container">
            <div className="inner-title">
              <h3>Upcoming Live sessions</h3>
              <h2>
                Join open mentorship sessions from{" "}
                <span className="d-block">our leading Mentors</span>
              </h2>
            </div>
            <div className="mentor-event-cls">
              {openSessions?.map((item, idx) => (
                <div
                  key={idx}
                  className="mentor-event-inner justify-content-between"
                >
                  <div className="event-date-cls w-100">
                    <div className="date-box">
                      <h2>
                        09
                        <span className="d-block">Feb 2022</span>
                      </h2>
                    </div>
                    <div className="event-name">
                      <h4>
                        {item?.mentees_help_description}
                        {/* A Basic guid to start your career in java development &
                        importance of java in current technical world.  */}
                      </h4>
                      <p>
                        <span>1 Hour Session</span>
                        <span className="arrow-line"></span> Starts{" "}
                        {item?.session[0]?.avilablity?.timings[0]?.start_time}
                      </p>
                    </div>
                  </div>
                  <div className="event-cont">
                    <div className="event-user">
                      <img alt="icon" src={mentorEvent} />
                    </div>
                    <div className="event-user-dtl">
                      <h5>{item.name}</h5>
                      <p>{item.job_title}</p>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="mentor-event-inner">
              <div className="event-date-cls">
                <div className="date-box">
                  <h2>
                    09
                    <span className="d-block">Feb 2022</span>
                  </h2>
                </div>
                <div className="event-name">
                  <h4>
                    A Basic guid to start your career in java development &
                    importance of java in current technical world.
                  </h4>
                  <p>
                    <span>1 Hour Session</span>
                    <span className="arrow-line"></span> Starts 7 PM
                  </p>
                </div>
              </div>
              <div className="event-cont">
                <div className="event-user">
                  <img alt="icon" src={mentorEvent} />
                </div>
                <div className="event-user-dtl">
                  <h5>Indria Priyadarsini</h5>
                  <p>Sr. Java Developer at Google LLC</p>
                </div>
              </div>
            </div>
            <div className="mentor-event-inner">
              <div className="event-date-cls">
                <div className="date-box">
                  <h2>
                    09
                    <span className="d-block">Feb 2022</span>
                  </h2>
                </div>
                <div className="event-name">
                  <h4>
                    A Basic guid to start your career in java development &
                    importance of java in current technical world.
                  </h4>
                  <p>
                    <span>1 Hour Session</span>
                    <span className="arrow-line"></span> Starts 7 PM
                  </p>
                </div>
              </div>
              <div className="event-cont">
                <div className="event-user">
                  <img alt="icon" src={mentorEvent} />
                </div>
                <div className="event-user-dtl">
                  <h5>Indria Priyadarsini</h5>
                  <p>Sr. Java Developer at Google LLC</p>
                </div>
              </div>
            </div> */}
              <div className="view-more-cls">
                <a href="/open-sessions">{"View Event Calender >>"}</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Save time --> */}
      <section className="savetime-sec ">
        <div className="container">
          <div className="save-time-sec">
            <div className="inner-title">
              <h3>Save time & Money</h3>
              <h2>
                Save your time & money by{" "}
                <span className="d-block">
                  choosing our professional Mentors
                </span>
              </h2>
            </div>
            <div className="progress-sec">
              <div className="satisfaction">
                <span className="satisfaction-ic">
                  <img alt="icon" src={pro1} />
                </span>
                <span className="satisfaction-cont">Mentee’s Satisfaction</span>
              </div>
              <div className="new-join">
                <span className="satisfaction-ic">
                  <img alt="icon" src={pro2} />
                </span>
                <span className="satisfaction-cont">New Joinee</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* collage section */}

      <div className="container-fluid">
        <img
          className="img-fluid"
          src={collage}
          alt="a collage of mentors images"
        />
      </div>

      <section className="savetime-sec why_choose_ment">
        <div className="container">
          {/*  testimonial section */}
          <div className="container">
            <center>
              <h2 className="my-5">
                Our Inspiring Mentors & Empowered Mentees
              </h2>
            </center>
            <Testimonial />
          </div>

          <div className="get-started-cls why_choose_ment">
            <a href="/find-mentor">
              <button type="button" className="find-btn btn btn-primary">
                Find Mentor
              </button>
            </a>
            <button
              onClick={() => navigate("/become-mentor")}
              type="button"
              className="btn btn-primary"
            >
              Become a Mentor
            </button>
          </div>
        </div>
      </section>
      {/* <!-- popular sessions --> */}
      {/* <section className="popular-session">
      <div className="container-fluid">
        <div className="row">
          <div className="popular-left-col padding-cls">
            <div className="populer-inner-sec">
              <h3>Popular Sessions</h3>
              <h6>
                We offer Popular Mentorships sessions from expert across the
                world.
              </h6>
              <p>
                Try 7 Days Free trail and experience full service. No Credit
                card required
              </p>
            </div>
          </div>
          <div className="popular-right-col">
            <div className="slider-cls">
              <OwlCarousel
                className="owl-theme owlslider owl-carousel"
                loop
                margin={20}
                nav
                responsive={{
                  0: {
                    items: 1.5,
                  },
                  1000: {
                    items: 2.75,
                  },
                }}
              >

                <div target="1" className="item">
                  <div className="item-box">
                    <div className="popular-logo">
                      <img alt="icon" src={popularIc} />
                    </div>
                    <div className="event-cont">
                      <div className="event-user">
                        <img alt="icon" src={mentorEvent} />
                      </div>
                      <div className="event-user-dtl">
                        <h5>Indria Priyadarsini</h5>
                        <p>Sr. Java Developer at Google LLC</p>
                      </div>
                    </div>
                    <div className="cv-feedback">
                      <a href="#">CV FEEDBACK</a>
                    </div>
                    <div className="cv-feed-cont">
                      <ul>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Video Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Audio Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={crossImg} />
                          </span>
                          Chats
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          1 Hour Session
                        </li>
                      </ul>
                    </div>
                    <div className="get-started-cls">
                      <a href="#" className="btn btn-primary">
                        Explore
                      </a>
                    </div>
                  </div>
                </div>
                <div target="1" className="item">
                  <div className="item-box">
                    <div className="popular-logo">
                      <img alt="icon" src={popularIc} />
                    </div>
                    <div className="event-cont">
                      <div className="event-user">
                        <img alt="icon" src={mentorEvent} />
                      </div>
                      <div className="event-user-dtl">
                        <h5>Indria Priyadarsini</h5>
                        <p>Sr. Java Developer at Google LLC</p>
                      </div>
                    </div>
                    <div className="cv-feedback">
                      <a href="#">CV FEEDBACK</a>
                    </div>
                    <div className="cv-feed-cont">
                      <ul>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Video Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Audio Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={crossImg} />
                          </span>
                          Chats
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          1 Hour Session
                        </li>
                      </ul>
                    </div>
                    <div className="get-started-cls">
                      <a href="#" className="btn btn-primary">
                        Explore
                      </a>
                    </div>
                  </div>
                </div>
                <div target="1" className="item">
                  <div className="item-box">
                    <div className="popular-logo">
                      <img alt="icon" src={popularIc} />
                    </div>
                    <div className="event-cont">
                      <div className="event-user">
                        <img alt="icon" src={mentorEvent} />
                      </div>
                      <div className="event-user-dtl">
                        <h5>Indria Priyadarsini</h5>
                        <p>Sr. Java Developer at Google LLC</p>
                      </div>
                    </div>
                    <div className="cv-feedback">
                      <a href="#">CV FEEDBACK</a>
                    </div>
                    <div className="cv-feed-cont">
                      <ul>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Video Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Audio Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={crossImg} />
                          </span>
                          Chats
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          1 Hour Session
                        </li>
                      </ul>
                    </div>
                    <div className="get-started-cls">
                      <a href="#" className="btn btn-primary">
                        Explore
                      </a>
                    </div>
                  </div>
                </div>
                <div target="1" className="item">
                  <div className="item-box">
                    <div className="popular-logo">
                      <img alt="icon" src={popularIc} />
                    </div>
                    <div className="event-cont">
                      <div className="event-user">
                        <img alt="icon" src={mentorEvent} />
                      </div>
                      <div className="event-user-dtl">
                        <h5>Indria Priyadarsini</h5>
                        <p>Sr. Java Developer at Google LLC</p>
                      </div>
                    </div>
                    <div className="cv-feedback">
                      <a href="#">CV FEEDBACK</a>
                    </div>
                    <div className="cv-feed-cont">
                      <ul>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Video Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Audio Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={crossImg} />
                          </span>
                          Chats
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          1 Hour Session
                        </li>
                      </ul>
                    </div>
                    <div className="get-started-cls">
                      <a href="#" className="btn btn-primary">
                        Explore
                      </a>
                    </div>
                  </div>
                </div>
                <div target="1" className="item">
                  <div className="item-box">
                    <div className="popular-logo">
                      <img alt="icon" src={popularIc} />
                    </div>
                    <div className="event-cont">
                      <div className="event-user">
                        <img alt="icon" src={mentorEvent} />
                      </div>
                      <div className="event-user-dtl">
                        <h5>Indria Priyadarsini</h5>
                        <p>Sr. Java Developer at Google LLC</p>
                      </div>
                    </div>
                    <div className="cv-feedback">
                      <a href="#">CV FEEDBACK</a>
                    </div>
                    <div className="cv-feed-cont">
                      <ul>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Video Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Audio Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={crossImg} />
                          </span>
                          Chats
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          1 Hour Session
                        </li>
                      </ul>
                    </div>
                    <div className="get-started-cls">
                      <a href="#" className="btn btn-primary">
                        Explore
                      </a>
                    </div>
                  </div>
                </div>
                <div target="1" className="item">
                  <div className="item-box">
                    <div className="popular-logo">
                      <img alt="icon" src={popularIc} />
                    </div>
                    <div className="event-cont">
                      <div className="event-user">
                        <img alt="icon" src={mentorEvent} />
                      </div>
                      <div className="event-user-dtl">
                        <h5>Indria Priyadarsini</h5>
                        <p>Sr. Java Developer at Google LLC</p>
                      </div>
                    </div>
                    <div className="cv-feedback">
                      <a href="#">CV FEEDBACK</a>
                    </div>
                    <div className="cv-feed-cont">
                      <ul>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Video Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          Audio Calls
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={crossImg} />
                          </span>
                          Chats
                        </li>
                        <li>
                          <span className="check-ic">
                            <img alt="icon" src={checkImg} />
                          </span>
                          1 Hour Session
                        </li>
                      </ul>
                    </div>
                    <div className="get-started-cls">
                      <a href="#" className="btn btn-primary">
                        Explore
                      </a>
                    </div>
                  </div>
                </div>

              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      {/* </section> */}
      {/* <!-- Didn’t find what you were looking for  --> */}
      {/* <section className="help">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="looking ">
                <center>
                  <h4>Didn’t find what you were looking for?</h4>
                </center>
                <center>
                  <p>
                    We can help you getting the right mentorship. just tell us
                    what you are looking for.
                  </p>
                </center>
                <center>
                  <button
                    onClick={() => {
                      navigate("/feedback")
                    }}
                    className="btn tell">Tell us now</button>
                </center>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {headerModal?.modal && (
        <headerModal.modal
          headerModal={headerModal}
          setHeaderModal={setHeaderModal}
        />
      )}
      {/* <!-- Footer --> */}
      <Footer />
    </>
  );
};

export default Home;
