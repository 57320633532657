import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "react-bootstrap";
import crossIcon from "../../../assets/images/cross-icon.png";
import mentorLogo from "../../../assets/images/mentor-logo.png";
import fbLogo1 from "../../../assets/images/facebook-1.png";
import twitterLogo1 from "../../../assets/images/twitter-1.png";
import linkedInLogo1 from "../../../assets/images/linkedin-1.png";
import mentorWlogo from "../../../assets/images/mentor-wlogo.png";
import LoginModal from "../loginModal";
import SignUpWithMobileNoModal from "../signUpWithMobileNoModal";
import { signUpAction } from "../../../redux/actions/login";
import { isEmptyObject } from "jquery";
import ThankYouModal from "../thanksModal";
import { TailSpin } from "react-loader-spinner";
import SocialLogin from "../../socialLogin";

const SignUpModal = ({ headerModal, setHeaderModal }) => {
  const [loader, setLoader] = useState(false);
  const signUpReducerState = useSelector((state) => state.signUpReducer);
  const status = signUpReducerState?.status;
  const errorSignUp = signUpReducerState?.message?.message;

  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isShowManualSignup, setIsShowManualSignup] = useState(false);
  const [warn, setWarn] = useState("yes");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [pass, setpass] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [confirmpass, setConfirmpass] = useState();
  const [alert, setAlert] = useState("");
  const [userType, setUserType] = useState(headerModal.as);

  useEffect(() => {
    if (status === 200 && loader) {
      setLoader(false);
      setHeaderModal({ modal: ThankYouModal, show: true });
    }
  }, [status]);

  useEffect(() => {
    if (errorSignUp != null) {
      setLoader(false);
    }
  }, [errorSignUp]);

  useEffect(() => {
    if (pass == confirmpass || confirmpass == null) {
      setError(null);
    } else {
      setError(error);
    }
  });
  useEffect(() => {
    if (
      name != null &&
      email != null &&
      pass != null &&
      confirmpass != null &&
      errormsg === null
    ) {
      setWarn(null);
    } else {
      setWarn("Please fill required field");
    }
  });

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleUserType = (e) => {
    setUserType(e.target.value);
  };

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setErrormsg("Email is invalid");
    } else {
      setEmail(event.target.value);
      setErrormsg(null);
    }
  };

  const signUpcal = () => {
    const data = {
      email: email,
      name: name,
      user_type: userType,
      password: pass,
    };
    setLoader(true);
    setIsSuccess(true);
    dispatch(signUpAction(data));
  };

  useEffect(() => {
    setErrormsg("");
  }, []);

  return (
    <>
      {loader && (
        <div className="loader" style={{ zIndex: 9999 }}>
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      )}
      <Modal
        size="xl"
        centered
        show={headerModal.show}
        onHide={() =>
          setHeaderModal((prevState) => ({ ...prevState, show: false }))
        }
      >
        <ModalBody>
          <button
            type="button"
            className="cloose"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              setHeaderModal((prevState) => ({ ...prevState, show: false }))
            }
          >
            <div className="closebuttons">
              <span aria-hidden="true">
                <img src={crossIcon} />
              </span>
            </div>
          </button>

          {/* <form> */}

          <div className="signup-row">
            <div className="signup-leftcol">
              <div className="sign-form-cls">
                <div className="logo-sec">
                  <h4>Welcome to</h4>
                  <img src={mentorLogo} alt="Mentorpal" />
                </div>

                <div className="singup-thanku">
                  <label for="radio1" className="as-mentor">
                    <input
                      type="radio"
                      id="radio1"
                      name="radios"
                      value="mentee"
                      defaultChecked={headerModal.as != "mentor"}
                      onChange={handleUserType}
                    />
                    As Mentee
                  </label>

                  <label for="radio2" className="as-mentor">
                    <input
                      type="radio"
                      id="radio2"
                      name="radios"
                      value="mentor"
                      defaultChecked={headerModal.as == "mentor"}
                      onChange={handleUserType}
                    />
                    As Mentor
                  </label>
                </div>
              </div>
              <SocialLogin modalprops={headerModal} modalType={userType} />
              <div className="signup-or">
                <p>
                  <span>OR</span>
                </p>
              </div>
              <div className="singup-otp mt-3">
                <p>
                  <a
                    href="#"
                    onClick={() => setIsShowManualSignup(!isShowManualSignup)}
                  >
                    Sign up with email
                  </a>
                </p>
              </div>
              {isShowManualSignup ? (
                <div className="mt-3">
                  <div className="form-group">
                    <input
                      type="text"
                      name=""
                      placeholder="Full Name"
                      className="form-control"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name=""
                      placeholder="Email"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </div>
                  <p>{errormsg}</p>
                  <div className="form-group">
                    <input
                      type="Password"
                      name=""
                      placeholder="Password"
                      className="form-control"
                      onChange={(e) => setpass(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="Password"
                      name=""
                      placeholder="Confirm Password"
                      className="form-control"
                      onChange={(e) => setConfirmpass(e.target.value)}
                    />
                  </div>
                  <p>{error}</p>
                  <p>{alert}</p>
                  <div className="singup-upp mb-5">
                    {errormsg && (
                      <p style={{ color: "#800020" }}>{errorSignUp}</p>
                    )}
                    <button
                      className="btn "
                      onClick={signUpcal}
                      disabled={
                        warn === null ? (error === null ? false : true) : true
                      }
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              ) : null}

              <div className="space"> </div>
              <div
                className="signup-number"
                onClick={() =>
                  setHeaderModal({ modal: SignUpWithMobileNoModal, show: true })
                }
              ></div>
              {/* <div className="singup-otp">
                <p>
                  Want To Become A Mentor? <span onClick={() => 
                    setUserType("mentor")} >Apply Here</span>
                </p>

              </div> */}
            </div>
            <div className="signup-rightcol">
              <div className="signup-cont">
                <div className="signup-logo d-none d-md-block">
                  <img src={mentorWlogo} alt="" />
                </div>
                <div className="signup-inner">
                  <p>
                    Mentorship from the global expert. join one-on-one Live
                    session and group session from the verified mentors at your
                    fingertips
                  </p>
                  <button
                    onClick={() =>
                      setHeaderModal({ modal: LoginModal, show: true })
                    }
                  >
                    Already Member?Login here
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* </form> */}
        </ModalBody>
      </Modal>
    </>
  );
};

export default SignUpModal;
