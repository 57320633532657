import { InfoCircleOutlined } from "@ant-design/icons";
import {
    Col,
    Row,
    Table,
    Tooltip,
    Typography,
} from "antd";
import { useState } from "react";
import {
    AiOutlineAppstore,
    AiOutlineRise,
    AiOutlineSwap,
} from "react-icons/ai";
import CurrencyDisplay from "./Modal/CurrencyDisplay";
import { dayjs } from 'dayjs';

const { Paragraph, Text } = Typography;


const TransactionsTable = (props) => {
    const [bottom] = useState("bottomLeft");
    const { tableData, currency } = props;
    const [table, setTable] = useState('all')
    const [page, setPage] = useState(1)

    const { transactionData, isLoading: loadingTable } = ''





    const getHeaderTitle = (title) => (
        <Paragraph
            style={{
                fontSize: 14,
                fontWeight: 600,
            }}
        >
            {title}
        </Paragraph>
    );

    const columns = () => {
        switch (table) {
            case "all":
                return transaction_columns;
            case "bookings":
                return bookings_columns;
            case "withdrawals":
                return withdrawals_columns;
            default:
                return transaction_columns
        }
    };


    const withdrawals_columns = [
        {
            title: getHeaderTitle("Date"),
            dataIndex: "created",
            key: "created",
            width: 100,
            minWidth: 50,
            responsive: ["sm"],
            render: (text) => {
                return {
                    props: {},
                    children: dayjs(text).format("DD-MM-YYYY") || "-",
                };
            },
        },

        {
            title: getHeaderTitle("Withdrawal Amount"),
            dataIndex: "amount",
            key: "withdrawal_amount",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: (
                        <Text style={{ display: "flex", alignItems: "center" }}>
                            <CurrencyDisplay harge={data} currency={currency} grayscaled={true} />

                        </Text>
                    ),
                };
            },
        },
        {
            title: getHeaderTitle("Withdrawn via"),
            dataIndex: "data",
            key: "withdrawn_via",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: data?.withdrawn_via || "-",
                };
            },
        },
    ];

    const bookings_columns = [
        {
            title: getHeaderTitle("Date"),
            dataIndex: "created",
            key: "created",
            width: 100,
            minWidth: 50,
            responsive: ["sm"],
            render: (text) => {
                return {
                    props: {},
                    children: dayjs(text).format("DD-MM-YYYY") || "-",
                };
            },
        },
        {
            title: getHeaderTitle("Service"),
            dataIndex: "data",
            key: "service",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: data?.service?.title || "-",
                };
            },
        },
        {
            title: getHeaderTitle("Booked by"),
            dataIndex: "data",
            key: "booked_by",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: data?.consumer_name || "-",
                };
            },
        },
        {
            title: getHeaderTitle("Price"),
            dataIndex: "amount",
            key: "amount",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: (
                        <Text style={{ display: "flex", alignItems: "center" }}>
                            <CurrencyDisplay harge={data} currency={currency} grayscaled={true} />

                        </Text>
                    ),
                };
            },
        },
        {
            title: (
                <Paragraph
                    style={{
                        display: "inline-flex",
                    }}
                >
                    {getHeaderTitle("Pricing Info")}
                    <Tooltip
                        title={
                            <>
                                <p>PWYW: People paid you extra through Pay What You Want</p>
                                <p>PPP: People paid you extra due to Purchase Power Parity</p>
                            </>
                        }
                    >
                        <InfoCircleOutlined
                            style={{
                                marginLeft: "4px",
                            }}
                        />
                    </Tooltip>
                </Paragraph>
            ),
            dataIndex: "data",
            key: "pricing_info",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: data?.info || "-",
                };
            },
        },
        {
            title: getHeaderTitle("Status"),
            dataIndex: "data",
            key: "status",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: <Paragraph>Completed</Paragraph>,
                };
            },
        },
    ];

    const transaction_columns = [
        {
            title: getHeaderTitle("Transaction type"),
            dataIndex: "transaction_type",
            key: "type",
            width: 150,
            minWidth: 100,
            render: (text) => {
                return {
                    props: {},
                    children: (
                        <Paragraph style={{ textTransform: "capitalize" }}>
                            {text}
                        </Paragraph>
                    ),
                };
            },
        },
        {
            title: getHeaderTitle("Date"),
            dataIndex: "created",
            key: "created",
            width: 100,
            minWidth: 50,
            responsive: ["sm"],
            render: (text) => {
                return {
                    props: {},
                    children: dayjs(text).format("DD-MM-YYYY") || "-",
                };
            },
        },
        {
            title: getHeaderTitle("Booked by"),
            dataIndex: "data",
            key: "booked_by",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: data?.consumer_name || "-",
                };
            },
        },
        {
            title: getHeaderTitle("Withdrawn via"),
            dataIndex: "data",
            key: "withdrawn_via",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: data?.payment_gateway || "-",
                };
            },
        },
        {
            title: getHeaderTitle("Amount"),
            dataIndex: "amount",
            key: "amount",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: (
                        <Text style={{ display: "flex", alignItems: "center" }}>
                            <CurrencyDisplay harge={data} currency={currency} grayscaled={true} />

                        </Text>
                    ),
                };
            },
        },
        {
            title: (
                <Paragraph
                    style={{
                        display: "inline-flex",
                    }}
                >
                    {getHeaderTitle("Info")}
                    <Tooltip
                        title={
                            <>
                                <p>PWYW: People paid you extra through Pay What You Want</p>
                                <p>PPP: People paid you extra due to Purchase Power Parity</p>
                            </>
                        }
                    >
                        <InfoCircleOutlined
                            style={{
                                marginLeft: "4px",
                            }}
                        />
                    </Tooltip>
                </Paragraph>
            ),
            dataIndex: "data",
            key: "info",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: data?.info || "-",
                };
            },
        },
        {
            title: getHeaderTitle("Status"),
            dataIndex: "data",
            key: "status",
            width: 150,
            minWidth: 100,
            responsive: ["sm"],
            render: (data) => {
                return {
                    props: {},
                    children: <Paragraph>Completed</Paragraph>,
                };
            },
        },
    ];

    const transactionTabs = [
        {
            key: 1,
            label: `All transactions`,
            icon: <AiOutlineSwap className="tab-icon" />,
            children: `Content of Tab Pane 1`,
            api_key: "all",
        },
        {
            key: 2,
            label: `Service bookings`,
            icon: <AiOutlineAppstore className="tab-icon" />,
            children: `Content of Tab Pane 2`,
            api_key: "bookings",
        },
        {
            key: 3,
            label: `Withdrawals`,
            icon: <AiOutlineRise className="tab-icon" />,
            children: `Content of Tab Pane 3`,
            api_key: "withdrawals",
        },

    ];




    return (
        <Row align="middle" className="transaction-tab">

            <Col xs={{ span: 20, offset: 2 }} md={0} className="mobile-view">

                <Paragraph className="desc">
                    For all the transaction details view this page on desktop
                </Paragraph>
            </Col>
            <Col md={24} xs={0}>
                <Row justify="space-between" align="middle" className="filters-row">
                    <Col>
                        <Row gutter={32} align="middle">
                            {transactionTabs &&
                                transactionTabs?.map((item, idx) => (
                                    <Col
                                        key={item?.key}
                                        onClick={() => setTable(item?.api_key)}

                                    >
                                        <Row
                                            className={
                                                item?.api_key == table
                                                    ? "active-table cursor-hover"
                                                    : "tabs cursor-hover"
                                            }
                                            align={"middle"}
                                        >
                                            {item.icon} {item?.label}
                                        </Row>
                                    </Col>
                                ))}
                        </Row>
                    </Col>

                </Row>
                <Table
                    className="payments-table"
                    style={{
                        marginTop: 20,
                    }}
                    dataSource={transactionData?.results}
                    columns={columns()}
                    bordered
                    loading={loadingTable}
                    pagination={{
                        total: transactionData?.count,
                        position: [bottom],
                        pageSize: 10,
                        responsive: true,
                        showSizeChanger: false,
                        hideOnSinglePage: true,
                        current: Number(page),
                    }}
                />
            </Col>
        </Row>
    );
};

export default TransactionsTable;
