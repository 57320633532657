import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { handleCalendarAndRedirection } from '../common/google-calander/utils';
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { updateCalendarSettingsAction } from '../../redux/actions/common';
import { REACT_USER_ID } from "./../auth/tokenProvider"

export const CalendarConnectRedirection = () => {

    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const search = useLocation().search;
    const code = new URLSearchParams(search).get('code');
    const navigate = useNavigate();

    const status = useSelector((state) =>
        state.updateCalendarSettingsReducer?.status
    );

    console.log("this is status ", status)

    useEffect(() => {
        if (status == "200" && !isLoading) {
            navigate("/")
        }
    }, [status])

    useEffect(() => {
        // handleCalendarAndRedirection(code)
        setIsLoading(true)
        if (code) {
            let data = {
                "calendar_token": code,
                "calendar_permission": "yes"
            }
            data["userId"] = REACT_USER_ID
            dispatch(updateCalendarSettingsAction(data))
        }
        setIsLoading(false)
    }, [code]);


    return (
        <>
            {
                isLoading ? (
                    <div className="loader">
                        <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                    </div>
                ) : null
            }
        </>
    )
}
