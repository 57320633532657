import React, { useEffect, useState } from "react";
import Activity from "../MentorDashboard/Activity";
import Notes from "../MentorDashboard/notes";
import Task from "../MentorDashboard/task";
import Meeting from "../MentorDashboard/meeting";
import ChatModule from "../ChatModule";
import "../MentorDashboard/menorDashboard.css";
import MenteeGoal from "../MenteeGoal";
import YourGoal from "./YourGoal";
import { useDispatch, useSelector } from "react-redux";
import { getConnectionListAction } from "../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import MenteeHome from "./MenteeHome";

const MenteeDashBoard = (props) => {
  const { userDetails } = props;
  console.log("searachInput Box userDeatils", userDetails);

  const [activeComponent, setActiveComponent] = useState("MenteeHome");

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConnectionListAction());
  }, []);

  const ConnectionListData = useSelector(
    (state) => state.getConnectionListReducer?.data
  );
  const isLoading = useSelector(
    (state) => state.getConnectionListReducer?.loading
  );

  const renderComponent = () => {
    switch (activeComponent) {
      case "MenteeHome":
        return <MenteeHome />;
      case "notes":
        return <Notes ConnectionListData={ConnectionListData} />;
      case "task":
        return <Task ConnectionListData={ConnectionListData} />;
      case "Goal":
        return <MenteeGoal ConnectionListData={ConnectionListData} />;
      case "chat":
        return (
          <ChatModule
            userDetails={userDetails}
            ConnectionListData={ConnectionListData}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
     
      <div className=" Search__btn mt-2" >
        <button
          type="button"
          className={`btn search____btn ${
            activeComponent === "MenteeHome" ? "active_btn" : ""
          }`}
          onClick={() => handleButtonClick("MenteeHome")}
        >
          Home
        </button>
        <button
          type="button"
          className={`btn search____btn ${
            activeComponent === "notes" ? "active_btn" : ""
          }`}
          onClick={() => handleButtonClick("notes")}
        >
          Notes
        </button>
        <button
          type="button"
          className={`btn search____btn ${
            activeComponent === "task" ? "active_btn" : ""
          }`}
          onClick={() => handleButtonClick("task")}
        >
          Task
        </button>
        <button
          type="button"
          className={`btn py-2 search____btn ${
            activeComponent === "Goal" ? "active_btn" : ""
          }`}
          onClick={() => handleButtonClick("Goal")}
        >
          Goal
        </button>
        <button
          type="button"
          className={`btn py-2 search____btn ${
            activeComponent === "chat" ? "active_btn" : ""
          }`}
          onClick={() => handleButtonClick("chat")}
        >
          Messenger
        </button>
      </div>
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div className="render___container">{renderComponent()}</div>
      )}{" "}
    </>
  );
};

export default MenteeDashBoard;
