import React, { useState } from "react";
import "./UpdateCountry.css";
import { countries } from "../../../../assets/data/data";
import ReactSelect from "react-select";

const UpdateCountry = ({ countryData, getUpdatedValues }) => {
  const customCountryData = { label: countryData, value: countryData };
  const [selectedCountries, setSelectedCountries] = useState([
    customCountryData,
  ]);
  const setCountryValue = (e) => {
    let countriesList = [];
    countriesList?.push(e?.label);
    setSelectedCountries(e);
  };
  const handleOnClick = () => {
    // let country = selectedCountries[0]?.label;
    let tempData = { country: selectedCountries?.label };
    getUpdatedValues(tempData);
  };
  return (
    <div className="update-country-main-container">
      <h7>Select a country</h7>
      <div className="country-input">
        <ReactSelect
          value={selectedCountries ? selectedCountries : ""}
          onChange={setCountryValue}
          placeholder="Eg. India,USA,Japan"
          name="countries"
          options={countries}
          className="basic-multi-select text-left"
          classNamePrefix="select"
        />
      </div>
      <button onClick={handleOnClick} className="update-btn-country">
        Update
      </button>
    </div>
  );
};

export default UpdateCountry;
