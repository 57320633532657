import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody } from "react-bootstrap";
import { crossIcon } from "../../../../assets/images/export-images";
import { useDispatch } from "react-redux";
import("../../../Feedback/feedback-form.css");



const PricingModal = ({ OpenModal, setOpenModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Freemodal");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAction = (formData) => {
    console.log("handleaction")
  };


  return (
    <>
      <Modal
        size="xl"
        centered
        show={OpenModal.show}
        onHide={() =>
          setOpenModal((prevState) => ({ ...prevState, show: false }))
        }
      >
        <ModalBody>
          <button
            type="button"
            className="cloose"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpenModal(prevState => ({ ...prevState, show: false }))}
          >
            <div className="closebuttons">
              <span aria-hidden="true">
                <img src={crossIcon} alt="Close" />
              </span>
            </div>
          </button>


          <div className=" p-0 bg-white" style={{ borderRadius: "20px" }}>
            <div className="modal-body">
              <div className="modal-container">
                <div className="">
                  <div className="text-center" style={{ color: 'black' }}>
                    <h2>
                      Add a pricing plan
                    </h2>
                  </div>
                </div>
                <div className="card border-0">
                  <div className="card-header header-table tab-header">
                    <ul
                      className="nav nav-tabs"
                      role="tablist"
                      style={{ display: "flex", flexDirection: "row" }}
                    >

                      <li className="nav-item">
                        <a

                          data-toggle="tab"
                          href="#tabs-1"
                          role="tab"
                          className={`nav-link ${activeTab === "Freemodal" ? "active" : ""}`}
                          onClick={() => handleTabClick("Freemodal")}
                        >
                          Free
                        </a>
                      </li>

                      <li className="nav-item">
                        <a

                          data-toggle="tab"
                          href="#tabs-1"
                          role="tab"
                          className={`nav-link ${activeTab === "OneTimePaymentModal" ? "active" : ""}`}
                          onClick={() => handleTabClick("OneTimePaymentModal")}
                        >
                          One time payment
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body table-inner">
                    {activeTab === "Freemodal" ? (
                      <Freemodal handleAction={handleAction} />
                    ) : (
                      <OneTimePaymentModal handleAction={handleAction} />
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal >
    </>
  );
};

export default PricingModal;


const Freemodal = () => {
  return (
    <>
      <div className="tab-content" >
        <p style={{ fontSize: '12px' }}>Leaners can access the course without any payments</p>
      </div>
      <div className="form-check" style={{ padding: '15px' }}>
        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked />
        <label className="form-check-label">This is a free course</label>
      </div>
      <div className="form-check" style={{ padding: '15px' }}>
        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked />
        <label className="form-check-label">Limit course access duration</label>
      </div>
      <div className="d-flex align-items-center gap-2 ">
        <button type="button" className="btn btn-success certificate_savebtn">Cancel</button>
        <button type="button" className="btn btn-success certificate_savebtn">Add</button>
      </div>
    </>
  )
};

const OneTimePaymentModal = () => {
  const [PaymentData, setPaymentData] = useState({
    listprice: 0,
    discountprice: 0,
  });
  console.log(PaymentData);
  return (
    <>
      <div className="tab-content" >
        <p style={{ fontSize: '12px' }}>Leaners can access the course by making one time payment</p>
      </div>
      <div className="input-group mb-3">
        <label style={{ marginRight: '440px' }}>List price</label>
        <label>Discounted price</label>
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1" style={{ height: '50px' }}>₹</span>
        <input type="text" className="border" placeholder="0" aria-label="0" aria-describedby="basic-addon1" style={{ marginRight: '10px', width: '470px' }}
          onChange={(e) =>
            setPaymentData((pre) => ({
              ...pre,
              listprice: e.target.value,
            }))
          }
        />
        <span className="input-group-text" id="basic-addon1">₹</span>
        <input type="text" className="border" placeholder="0" aria-label="0" aria-describedby="basic-addon1" style={{ width: '470px' }}
          onChange={(e) =>
            setPaymentData((pre) => ({
              ...pre,
              discountprice: e.target.value,
            }))
          }
        />
      </div>
      <div className="form-check" style={{ padding: '15px' }}>
        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked />
        <label className="form-check-label">Pass Internet Handle Fees(PG Fees) to leaners</label>
      </div>
      <div className="form-check" style={{ padding: '15px' }}>
        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked />
        <label className="form-check-label">Limit course access durstion</label>
      </div>
      <div className="d-flex align-items-center gap-2 ">
        <button type="button" className="btn btn-success certificate_savebtn">Cancel</button>
        <button type="button" className="btn btn-success certificate_savebtn">Add</button>
      </div>
    </>
  )
};