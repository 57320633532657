import React from "react";
import { useState } from "react";
import "./UpdateAbout.css";
const UpdateAbout = ({ aboutData, getUpdatedValues }) => {
  const [aboutTxt, setAboutTxt] = useState(aboutData);
  const handleOnChange = (e) => {
    setAboutTxt(e.target.value);
  };
  const handleOnClick = () => {
    let tempData = { story: aboutTxt };
    getUpdatedValues(tempData);
  };
  return (
    <div className="update-about-main-container">
      <textarea
        className="about-input"
        value={aboutTxt}
        onChange={handleOnChange}
      ></textarea>
      <button onClick={handleOnClick} className="update-btn-about">
        Update
      </button>
    </div>
  );
};

export default UpdateAbout;
