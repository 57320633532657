import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  mentorEvent,
  eventBg,
  innerBg,
} from "../../assets/images/export-images";

import Header from "../header";
import Footer from "../footer";
import liveSessions from "./livesession.json";

import { getOpenSessionsAction } from "../../redux/actions/mentor";

import "../../assets/css/custom.css";
import "../../assets/css/header.css";
import "../../assets/css/main-banner.css";
import "../../assets/css/signup-popup.css";
import "../../assets/css/find-mentor.css";
import "../../assets/css/footer.css";
import "../../assets/css/media.css";
import axios from "axios";
import "../openSessions/openSession.css";
import { navigate, useNavigate } from "react-router-dom";
import { liveSessionAction } from "../../redux/actions/common";
import maleAvatar from "../../assets/images/male.png";
import femaleAvatar from "../../assets/images/female.png";
const OpenSessions = () => {
  const dispatch = useDispatch();
  const [expertise, setExpertise] = useState("Find the session you're interested in");
  const navigate = useNavigate();

  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(liveSessionAction());
  }, []);

  const liveSessionData = useSelector((state) => {
    if (isLoading && !state.liveSessionReducer.loading) {
      setIsLoading(false);
    } else if (!isLoading && state.liveSessionReducer.loading) {
      setIsLoading(true);
    }
    return state.liveSessionReducer.data;
  });

  useEffect(() => {
    if (liveSessionData !== undefined) {
      console.log("liveSessionData", liveSessionData);
    }
  }, [isLoading]);

  const sessionDetailsCall = (sessionId) => {
    setSelectedSessionId(sessionId);
    navigate(`/session-details/${sessionId}`);
  };

  return (
    <>
      <Header />
      <section
        className="banner-sec inner-banner"
        style={{ backgroundImage: `url(${innerBg})` }}
      >
        <div className="container">
          <div className="mentroship-sec">
            <div className="form-group input-group">
              <input
                type="text"
                name=""
                className="form-control"
                placeholder={expertise}
                //onChange={(e) => setSearch(e.target.value)}
              />
              <input
                type="submit"
                name=""
                className="form-control submit-btn"
                value="Search"
                // onClick={() => findMentors(search, 1)}
              />
            </div>
          </div>
        </div>
      </section>
      <section
        className="event-mentor-sec space-cls"
        style={{
          backgroundImage: `url(${eventBg})`,
          backgroundAttachment: "fixed",
          minHeight: "100vh",
        }}
      >
        <div className="container">
          <div className="inner-title">
            <h3>Upcoming Live Sessions</h3>
            <h2>
              Join Open Mentorship Sessions From{" "}
              <span className="d-block">Our Featured Mentors</span>
            </h2>
          </div>
        </div>
        <div className="GroupSessions__Grid mb-5">
          {isLoading ? (
            <div className="loader">
              <TailSpin ariaLabel="Loading..." color="#00BFFF" />
            </div>
          ) : (
            <>
              {liveSessionData?.map((item) => (
                <div
                  key={item.session_id}
                  onClick={() => sessionDetailsCall(item.session_id)}
                >
                  <div width="100%" className="Card__Wrapper Card___Main h-100">
                    <div className="session card-tile cursor-pointer">
                      <div className="card_banner">
                        <img
                          className="banner"
                          // src={item.banner_image}
                          src={`${process.env.REACT_APP_IMAGES_BASE_URL}${item?.banner_image}`}
                          alt="banner_image"
                        />
                      </div>
                      <div className="card__Session">
                        <div className="Card_Body">
                          <div>
                            <p
                              role="heading"
                              aria-level={1}
                              aria-label="Fazendo um componente Headless no Figma em PT-BR"
                              className="Card_Body__title"
                            >
                              {item.title}
                            </p>
                          </div>
                          <div>
                            <a
                              target="mentor"
                              className="card__footer"
                              href="/"
                            >
                              <img
                                className="avatar mr-3"
                                alt="Mentor_image"
                                // src={item.mentor_photo}
                                src={
                                  item?.mentor_photo
                                    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${item?.mentor_photo}`
                                    : item?.gender == "female"
                                    ? `${femaleAvatar}`
                                    : `${maleAvatar}`
                                }
                              />
                              <div className="media-body">
                                <div className="d-flex align-items-center">
                                  <p
                                    className=" Mentor_Name font-weight-600 text-truncate"
                                    style={{ marginBottom: "0rem" }}
                                  >
                                    {item.mentor_name}
                                  </p>
                                </div>
                                <p className="font-size-14 font-weight-400 mb-0 text-truncate">
                                  <span>{item.job_role}</span>
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="time-stamps">
                            <p className="d-flex align-items-center py-4 mb-0">
                              <svg
                                fill="none"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-2"
                              >
                                <path
                                  d="M3.09264 9.40427H20.9166"
                                  stroke="var(--teal)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.4421 13.3097H16.4513"
                                  stroke="var(--teal)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.0046 13.3097H12.0139"
                                  stroke="var(--teal)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.55789 13.3097H7.56715"
                                  stroke="var(--teal)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.4421 17.1962H16.4513"
                                  stroke="var(--teal)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.0046 17.1962H12.0139"
                                  stroke="var(--teal)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.55789 17.1962H7.56715"
                                  stroke="var(--teal)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.0437 2V5.29078"
                                  stroke="var(--teal)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.9655 2V5.29078"
                                  stroke="var(--teal)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.2383 3.57919H7.77096C4.83427 3.57919 3 5.21513 3 8.22222V17.2719C3 20.3262 4.83427 22 7.77096 22H16.229C19.175 22 21 20.3546 21 17.3475V8.22222C21.0092 5.21513 19.1842 3.57919 16.2383 3.57919Z"
                                  stroke="var(--teal)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <span className="Time_color">
                                {new Date(item.sesstiondate).toLocaleString()}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default OpenSessions;
