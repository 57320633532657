import React, { useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import crossIcon from "../../../assets/images/cross-icon.png";
import { useDispatch } from 'react-redux';
import { appendFormData } from '../../../redux/actions/course';

const AddLinkModal = ({ OpenModal, setOpenModal }) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState(null);

    const [loader, setLoader] = useState(false);
    const [linkData, setLinkData] = useState({
        title: "",
        link: "",
        type: "ADD_LINK"
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLinkData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleUpload = (e) => {
        e.preventDefault();
        if (!linkData.title || !linkData.link) {
            setMessage("Please enter valid details");
            setTimeout(() => {
                setMessage(null);
            }, 2000);
        } else {
            console.log("Link Data:", linkData);
            dispatch(appendFormData("APPEND_FORM_DATA", OpenModal?.chapterIndex, OpenModal?.unitIndex, linkData));
            let actionType;

            switch (OpenModal?.add_type) {
                case 'ADD_CHAPTER':
                    actionType = "ADD_CHAPTER_TO_UNIT_BY_INDEX";
                    break;
                case 'ADD_UNIT':
                    actionType = "ADD_UNIT";
                    break;
                default:
                    actionType = "APPEND_FORM_DATA";
                    break;
            }

            dispatch(appendFormData(actionType, OpenModal?.chapterIndex, OpenModal?.unitIndex, linkData));
            handleCancel()
        }
    };
    const handleCancel = () => {

        setOpenModal((prevState) => ({ ...prevState, show: false }))
    }

    return (
        <>
            {loader && (
                <div className="loader" style={{ zIndex: 9999 }}>
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            )}
            <Modal
                size="xl"
                centered
                show={OpenModal.show}
                onHide={() =>
                    setOpenModal((prevState) => ({ ...prevState, show: false }))
                }
            >
                <ModalBody>
                    <button
                        type="button"
                        className="cloose"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                            setOpenModal((prevState) => ({ ...prevState, show: false }))
                        }
                    >
                        <div className="closebuttons">
                            <span aria-hidden="true">
                                <img src={crossIcon} alt="Close" />
                            </span>
                        </div>
                    </button>
                    <div className="create_course_modal" style={{ background: "white" }}>
                        <div className="modal-body">
                            <div className="modal-container">
                                <div className="modal-header">
                                    <div className="modal-title h5">
                                        New Link
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label className="session__label">
                                                    Title: *
                                                </label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Title"
                                                    required=""
                                                    className="form-control"
                                                    value={linkData.title}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="session__label">
                                                    Link: *
                                                </label>
                                                <input
                                                    type="url"
                                                    name="link"
                                                    placeholder="Link"
                                                    required=""
                                                    className="form-control"
                                                    value={linkData.link}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="get-started-cls why_choose_ment">
                                                <button
                                                    type="button"
                                                    className="find-btn btn btn-primary"
                                                    onClick={handleCancel}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleUpload}
                                                >
                                                    Upload
                                                </button>
                                            </div>
                                            {
                                                message && <p className='text-center'> {message}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default AddLinkModal;
