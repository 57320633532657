import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "react-bootstrap";
import crossIcon from "../../../assets/images/cross-icon.png";
import mentorLogo from "../../../assets/images/mentor-logo.png";
import mentorWlogo from "../../../assets/images/mentor-wlogo.png";
import LoginModal from "../loginModal";
import SignUpModal from "../signUpModal";
import { forgetPassword } from "../../../redux/actions/login";



const ForgetPasswordModel = ({ headerModal, setHeaderModal }) => {
  const dispatch = useDispatch();

  const errorMsg = useSelector(
    (state) => state.forgetPasswordReducer?.headers?.["x-error-msg"]
  );
  const successMsg = useSelector(
    (state) => state.forgetPasswordReducer?.headers?.["user_id"]
  );

  const [email, setEmail] = useState(headerModal?.email ? headerModal?.email : " ");

  const passwordlink = () => {
    const data = {
      email: email,
      user_type: headerModal?.userType
    }
    dispatch(forgetPassword(data));
  }


  return (
    <Modal
      size="xl"
      centered
      show={headerModal.show}
      onHide={() =>
        setHeaderModal((prevState) => ({ ...prevState, show: false }))
      }
    >
      <ModalBody>
        <button
          type="button"
          className="cloose"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() =>
            setHeaderModal((prevState) => ({ ...prevState, show: false }))
          }
        >
          <div className="closebuttons">
            <span aria-hidden="true">
              <img src={crossIcon} />
            </span>
          </div>
        </button>

        <div className="signup-row">
          <div className="signup-leftcol">
            <div className="sign-form-cls">
              <div className="logo-sec">
                <h4>Welcome to</h4>
                <img src={mentorLogo} alt="Mentorpal" />
              </div>
              <div className="wel-space-cls">
                <div className="form-group change-pw">
                  <p>
                    Forget password{" "}

                  </p>

                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name=""
                    placeholder="Enter Email"
                    className="form-control"
                    value={email ? email : " "}
                    onChange={(e) => setEmail(e.target.value)}

                  />

                </div>
                <div className="singup-upp">
                  {errorMsg && <p style={{ color: '#800020' }}>{errorMsg}</p>}
                  {successMsg && <p style={{ color: '#800020' }}>Check your mail</p>}

                  <button className="btn " onClick={passwordlink}>
                    Send link
                  </button>

                </div>
                <div className="singup-otp">

                  <p>
                    <a href="#" onClick={() =>
                      setHeaderModal({ modal: LoginModal, show: true })
                    }>Back to login</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="signup-rightcol">
            <div className="signup-cont">
              <div className="signup-logo d-none d-md-block">
                <img src={mentorWlogo} alt="" />
              </div>
              <div className="signup-inner">
                <p>
                  Mentorship from the global expert. join one-on-one Live
                  session and group session from the verified mentors at your
                  fingertips
                </p>
                <button
                  type="button"
                  onClick={() =>
                    setHeaderModal({ modal: SignUpModal, show: true, as: "mentee" })
                  }
                >
                  New to mentorpal? Sign up now
                </button>
              </div>
            </div>
          </div>
        </div>

      </ModalBody>
    </Modal>
  );
};

export default ForgetPasswordModel;
