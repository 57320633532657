import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import SelectMultiInputs from '../../applyAsMentor/selectMultiInputs';
import { UploadImage } from '../../UploadImage';
import { appendFormData } from '../../../redux/actions/course';
import { useDispatch, useSelector } from 'react-redux';

const AddLiveClassChapter = ({ chapterIndex, unitIndex }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        title: '',
        Tags: [],
        availabilityType: 'AlwaysAvailable',
        availableFrom: '',
        availableTill: '',
        preMessage: '',
        postMessage: '',
        thumbnailImage: '',
        classMode: 'meet',
        recordingtype: 'yes',
        notify: true, // Changed to a boolean value
    });
    const [validationMessages, setValidationMessages] = useState({
        title: '',
        Tags: '',
        availableFrom: '',
        availableTill: '',
        preMessage: '',
        postMessage: '',
        thumbnailImage: '',
    });
    const [successMessage, setSuccessMessage] = useState('');

    const syllabusIndexData = useSelector(
        (state) => state.SyllabusIndexDataRedcuer?.data[0]
    );

    useEffect(() => {
        if (syllabusIndexData) {
            const selectedUnit = syllabusIndexData.course_syllabus?.[unitIndex];
            const selectedChapter = selectedUnit?.chapters?.[chapterIndex];
            const data = selectedChapter?.data[0];

            if (selectedChapter) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    title: data?.title || '',
                    availabilityType: data?.availabilityType || '',
                    availableFrom: data?.availableFrom || '',
                    availableTill: data?.availableTill || '',
                    preMessage: data?.preMessage || '',
                    postMessage: data?.postMessage || '',
                    thumbnailImage: data?.thumbnailImage || '',
                    classMode: data?.classMode || 'meet',
                    recordingtype: data?.recordingtype || '',
                    notify: data?.notify || true, // Changed to a boolean value
                }));
            }
        }
    }, [syllabusIndexData, chapterIndex, unitIndex]);

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleTagsChange = (item, addFunc) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            Tags: [...prevFormData.Tags, item],
        }));
    };

    const handleRemoveTag = (item) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            Tags: prevFormData.Tags.filter((tag) => tag.value !== item.value),
        }));
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const newValidationMessages = {};

        // Validate presence of required fields
        if (!formData.title) {
            newValidationMessages.title = 'Title is required';
        }
        if (formData.Tags.length < 5) {
            newValidationMessages.Tags = 'At least 5 Tags are required';
        }
        if (!formData.availableFrom) {
            newValidationMessages.availableFrom = 'Available From is required';
        }
        if (!formData.availableTill) {
            newValidationMessages.availableTill = 'Available Till is required';
        }
        if (!formData.preMessage) {
            newValidationMessages.preMessage = 'Pre Class Message is required';
        }
        if (!formData.postMessage) {
            newValidationMessages.postMessage = 'Post Class Message is required';
        }
        if (!formData.thumbnailImage) {
            newValidationMessages.thumbnailImage = 'Thumbnail Image is required';
        }
        if (!formData.classMode) {
            newValidationMessages.classMode = 'Class Mode is required';
        }
        if (!formData.classLink) {
            newValidationMessages.classLink =
                formData.classMode === 'meet'
                    ? 'Meet Link is required'
                    : 'Zoom Link is required';
        }


        // Set validation messages
        setValidationMessages(newValidationMessages);

        // Check if any validation message exists
        const isValid = Object.values(newValidationMessages).every(
            (message) => !message
        );

        if (isValid) {
            // If all fields are valid, proceed with form submission
            console.log('Form Data:', formData);
            dispatch(
                appendFormData(
                    'ADD_CHAPTER_DATA',
                    chapterIndex,
                    unitIndex,
                    formData
                )
            );
            setSuccessMessage('Chapter data has been successfully saved.');
        }
    };

    const uploadThumbnail = (val, pre) => {
        setFormData((prevData) => ({
            ...prevData,
            thumbnailImage: val,
        }));
    };

    return (
        <>
            <div className='bg-white p-5'>
                <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label className="course__label">Title *</label>
                        <input
                            type="text"
                            name="title"
                            placeholder="Title"
                            className="form-control"
                            value={formData.title}
                            onChange={handleInputChange}
                        />
                        {validationMessages.title && (
                            <p className="text-danger">{validationMessages.title}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">Tags *</label>
                        <div className="multi-inputs-container">
                            <div className="multi-inputs">
                                {formData.Tags.map((Tag) => (
                                    <div className="multi-input-design" key={Tag.value}>
                                        <div>{Tag.label}</div>
                                        <div onClick={() => handleRemoveTag(Tag)}>
                                            <FontAwesomeIcon
                                                className="multi-select-icon"
                                                icon={faXmark}
                                                style={{ color: 'grey' }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <SelectMultiInputs
                            isMulti={true}
                            title="language"
                            onChangeFunc={(e) => handleTagsChange(e, setFormData)}
                            value=""
                            name="Tags"
                            isCreatable={true}
                            placeholder="Add at least 5-6 Tags"
                            className="basic-multi-select text-left"
                            classNamePrefix="select"
                            inputData={formData.Tags}
                            closeMenuOnSelect={true}
                            style={{ borderRadius: '12px', height: '3rem' }}
                        />
                        {validationMessages.Tags && (
                            <p className="text-danger">{validationMessages.Tags}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">Available From *</label>
                        <input
                            type="datetime-local"
                            name="availableFrom"
                            value={formData.availableFrom}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                        {validationMessages.availableFrom && (
                            <p className="text-danger">{validationMessages.availableFrom}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">Available Till *</label>
                        <input
                            type="datetime-local"
                            name="availableTill"
                            value={formData.availableTill}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                        {validationMessages.availableTill && (
                            <p className="text-danger">{validationMessages.availableTill}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">Pre Class Message *</label>
                        <input
                            type="text"
                            name="preMessage"
                            className="form-control"
                            value={formData.preMessage}
                            onChange={handleInputChange}
                        />
                        {validationMessages.preMessage && (
                            <p className="text-danger">{validationMessages.preMessage}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">Post Class Message *</label>
                        <input
                            type="text"
                            name="postMessage"
                            className="form-control"
                            value={formData.postMessage}
                            onChange={handleInputChange}
                        />
                        {validationMessages.postMessage && (
                            <p className="text-danger">{validationMessages.postMessage}</p>
                        )}
                    </div>
                    <div className="form-group d-flex gap-4" >
                        <label className="course__label">How learners will join *</label> {'  '}
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="meet"
                                id="meetRadio"
                                name="classMode"
                                checked={formData.classMode === 'meet'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="meetRadio">
                                Meet
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="zoom"
                                id="zoomRadio"
                                name="classMode"
                                checked={formData.classMode === 'zoom'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="zoomRadio">
                                Zoom
                            </label>
                        </div>
                        {validationMessages.classMode && (
                            <p className="text-danger">{validationMessages.classMode}</p>
                        )}
                    </div>

                    {formData.classMode && (
                        <div className="form-group">
                            <label className="course__label">
                                {formData.classMode === 'meet' ? 'Meet Link *' : 'Zoom Link *'}
                            </label>
                            <input
                                type="url"
                                name="classLink"
                                placeholder={`Enter ${formData.classMode === 'meet' ? 'Meet' : 'Zoom'
                                    } Link`}
                                required=""
                                className={`form-control ${validationMessages.classLink ? 'is-invalid' : ''
                                    }`}
                                value={formData.classLink}
                                onChange={handleInputChange}
                            />
                            {validationMessages.classLink && (
                                <div className="invalid-feedback">
                                    {validationMessages.classLink}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="form-group">
                        <div className="form-check form-switch d-flex">
                            <input
                                className="form-check-input"
                                role="switch"
                                id="notifySwitch"
                                type="checkbox"
                                checked={formData.notify}
                                onChange={handleInputChange}
                                name="notify"
                            />
                            <label className="course__label" htmlFor="notifySwitch">
                                Notify learners
                            </label>
                        </div>
                    </div>


                    <div className="form-group">
                        <label className="course__label">Thumbnail Recommended Size (600px * 400px) *</label>
                        <UploadImage
                            callback={uploadThumbnail}
                            fileName="_course-thumbnail"
                            imagePath={"courseImage/courseThumbnail/"}
                            filetype={["image"]}
                        />
                        {validationMessages.thumbnailImage && (
                            <p className="text-danger">{validationMessages.thumbnailImage}</p>
                        )}
                    </div>
                    {/* Success message */}
                    {successMessage && (
                        <div className="alert alert-success">{successMessage}</div>
                    )}
                    <div className="get-started-cls why_choose_ment">
                        <a>
                            <button type="submit" className="find-btn btn btn-primary">
                                Save
                            </button>
                        </a>
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddLiveClassChapter;
