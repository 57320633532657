import React from 'react';

const CourseCommunity = () => {
    return (
        <div>
            <div className="container d-flex justify-content-center align-items-center vh-100">
                <div className="text-center">
                    <h1 className="display-4">Coming Soon</h1>
                    <p className="lead">We're working on something awesome! Stay tuned.</p>
                </div>
            </div>
        </div>
    );
}

export default CourseCommunity;
