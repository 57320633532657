import { combineReducers } from "redux";

import {
  otpReducer,
  emailVerifyReducer,
  loginReducer,
  passwordLoginReducer,
  signUpReducer,
  socialLoginReducer,
  updateProfileReducer,
  signUpVerifyReducer,
  forgetPasswordReducer,
  userDetailsReducer,
  updateUserPasswordReducer,
} from "./login";

import {
  findMentorReducer,
  createMentorReducer,
  viewMentorProfileReducer,
  mentorServiceSessionTypeReducer,
  mentorSessionServiceDetailsReducer,
  mentorSessionServiceAvailabilityReducer,
  mentorSessionServiceHelpMenteeReducer,
  mentorSessionServiceMileStoneReducer,
  mentorSessionServicesReducer,
  mentorSessionServiceFormDataReducer,
  createMentorSessionServiceReducer,
  publishEbookReducer,
  mentorProductsReducer,
  mentorEbooksReducer,
  mentorTotalSubscriptionReducer,
  topMentorsReducer,
  openSessionsReducer,
  mentorActiveSubscriptionReducer,
  mentorSubscriptionRequestsReducer,
  mentorAvailabilityDaysReducer,
  mentorAvailabilityTimeSlotsReducer,
  mentorAvailableHoursReducer,
  getMentorAvailableHoursReducer,
  getAllMentorSessionReducer,
  addNotesReducer,
  getNotesReducer,
  updateNotesReducer,
  getUpcomingTaskReducer,
  getUpcomingMeetingReducer,
  getHistoryMeetingReducer,
  createMeetingReducer,
  createTaskReducer,
  updateTaskReducer,
  cancelMeetingReducer,
  createProductReducer,
  shareOnLinkedInReducer
} from "./mentor";

import {
  favoriteMentorReducer,
  subscribedMentorReducer,
  similarMentorReducer,
  createFavoriteMentorReducer,
  goalSimilarMentorReducer,
} from "./mentee";

import { orderReducer, createPaymentLinkReducer } from "./session";

import { generateAuthLink } from "./google-calander";
import {
  uploadImageReducer,
  uploadImageFileReducer,
  feedbackReducer,
  updateCalendarSettingsReducer,
  personalityTestReducer,
  fullStackReducer,
  testResponseReducer,
  liveSessionReducer,
  sessionDetailsReducer,
  enrollSessionReducer,
  createSessionReducer,
  menteeGoalReducer,
  sendChatMessageReducer,
  getChatMessageReducer,
  getConnectionRequestReducer,
  updateConnectionRequestReducer,
  getConnectionListReducer,
  getGoalReducer,
  getNotificationReducer,
  updateNotificationStatusReducer,
  subscribeReducer,
  contactReducer,
  mpqTestDataReducer,
  addBankAccountReducer,
  getBankDetailsReducer
} from "./common";
import {SyllabusIndexDataRedcuer,
createCourseReducer,
getMentorCourseReducer,
updateMentorCourseReducer,
deleteMentorCourseReducer,
getAllCourseReducer,
getCourseDetailsReducer,
CoursePrewiewReducer,
navReducer
} from "./course"

const allReducers = combineReducers({
  otpReducer,
  emailVerifyReducer,
  loginReducer,
  findMentorReducer,
  passwordLoginReducer,
  signUpReducer,
  socialLoginReducer,
  updateProfileReducer,
  signUpVerifyReducer,
  createMentorReducer,
  forgetPasswordReducer,
  viewMentorProfileReducer,
  userDetailsReducer,
  favoriteMentorReducer,
  subscribedMentorReducer,
  similarMentorReducer,
  orderReducer,
  mentorServiceSessionTypeReducer,
  mentorSessionServiceDetailsReducer,
  mentorSessionServiceAvailabilityReducer,
  mentorSessionServiceHelpMenteeReducer,
  mentorSessionServiceMileStoneReducer,
  mentorSessionServicesReducer,
  mentorSessionServiceFormDataReducer,
  createMentorSessionServiceReducer,
  publishEbookReducer,
  mentorProductsReducer,
  mentorEbooksReducer,
  mentorTotalSubscriptionReducer,
  updateUserPasswordReducer,
  
  topMentorsReducer,
  openSessionsReducer,
  mentorActiveSubscriptionReducer,
  mentorSubscriptionRequestsReducer,
  generateAuthLink,
  uploadImageReducer,
  uploadImageFileReducer,
  mentorAvailabilityDaysReducer,
  mentorAvailabilityTimeSlotsReducer,
  feedbackReducer,
  createFavoriteMentorReducer,
  mentorAvailableHoursReducer,
  getMentorAvailableHoursReducer,
  getAllMentorSessionReducer,
  updateCalendarSettingsReducer,
  personalityTestReducer,
  testResponseReducer,
  fullStackReducer,
  liveSessionReducer,
  sessionDetailsReducer,
  enrollSessionReducer,
  createSessionReducer,
  menteeGoalReducer,
  goalSimilarMentorReducer,
  addNotesReducer,
  getNotesReducer,
  updateNotesReducer,

  getUpcomingTaskReducer,
  getUpcomingMeetingReducer,
  getHistoryMeetingReducer,
  createMeetingReducer,
  createTaskReducer,
  updateTaskReducer,
  cancelMeetingReducer,
  sendChatMessageReducer,
  getChatMessageReducer,
  getConnectionRequestReducer,
  updateConnectionRequestReducer,
  getConnectionListReducer,
  getGoalReducer,
  getNotificationReducer,
  updateNotificationStatusReducer,
  createPaymentLinkReducer,
  subscribeReducer,
  contactReducer,
  SyllabusIndexDataRedcuer,
  createCourseReducer,
  getMentorCourseReducer,
  updateMentorCourseReducer,
  deleteMentorCourseReducer,
  getAllCourseReducer,
  getCourseDetailsReducer,
  CoursePrewiewReducer,
  navReducer,
  mpqTestDataReducer,
  createProductReducer,
  shareOnLinkedInReducer,
  addBankAccountReducer,
  getBankDetailsReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_REQUEST") {
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
