import React, { useState } from 'react';
// import Pdf from "@mikecousins/react-pdf";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import SelectMultiInputs from '../../applyAsMentor/selectMultiInputs';
import TextEditor from '../Modal/TextEditor';
import { EditorState, convertToRaw } from 'draft-js';

const AddAnyFileChapter = () => {
    const [formData, setFormData] = useState({
        courseTitle: '',
        enableSharing: false,
        description: "",
        Tags: [],
        availabilityType: 'AlwaysAvailable',
        availableFrom: '',
        availableTill: '',
        ConfirmationMessage: " "
    });

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const onEditorStateChange = (editorState, e) => {
        setEditorState(editorState);
        const { blocks } = convertToRaw(editorState.getCurrentContent());
        setFormData(prevFormData => ({
            ...prevFormData,
            description: blocks[0]?.text,
        }));
    };

    const handleTagsChange = (item, addFunc) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            Tags: [...prevFormData.Tags, item]
        }));
    };

    const handleRemoveTag = (item) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            Tags: prevFormData.Tags.filter(tag => tag.value !== item.value)
        }));
    };

    const validateForm = () => {
        const errors = {};

        if (!formData.courseTitle) {
            errors.courseTitle = 'Title is required';
        }
        if (formData.Tags.length < 5) {
            errors.Tags = 'At least 5 Tags are required';
        }
        if (formData.availabilityType === 'TimeBased') {
            if (!formData.availableFrom) {
                errors.availableFrom = 'Available From is required';
            }
            if (!formData.availableTill) {
                errors.availableTill = 'Available Till is required';
            }
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            console.log('Form Data:', formData);
            // You can dispatch an action or perform other actions here.
        }
    };

    return (
        <>
            <div className='bg-white p-5'>
                <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label className="course__label">
                            Title *
                        </label>
                        <input
                            type="text"
                            name="courseTitle"
                            placeholder="Enter course Title"
                            className="form-control"
                            value={formData.courseTitle}
                            onChange={handleInputChange}
                            required
                        />
                        {validationErrors.courseTitle && (
                            <p className="text-danger">{validationErrors.courseTitle}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="course__label">
                            Tags  *
                        </label>
                        <div className="multi-inputs-container">
                            <div className="multi-inputs">
                                {formData.Tags.map((Tag) => (
                                    <div className="multi-input-design" key={Tag.value}>
                                        <div>{Tag.label}</div>
                                        <div onClick={() => handleRemoveTag(Tag)}>
                                            <FontAwesomeIcon
                                                className="multi-select-icon"
                                                icon={faXmark}
                                                style={{ color: 'grey' }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <SelectMultiInputs
                            isMulti={true}
                            title="language"
                            onChangeFunc={(e) => handleTagsChange(e, setFormData)}
                            value=""
                            name="Tags"
                            isCreatable={true}
                            placeholder="Add at least 5-6 Tags"
                            className="basic-multi-select text-left"
                            classNamePrefix="select"
                            inputData={formData.Tags}
                            closeMenuOnSelect={true}
                            style={{ borderRadius: '12px', height: '3rem' }}
                        />
                        {validationErrors.Tags && (
                            <p className="text-danger">{validationErrors.Tags}</p>
                        )}
                    </div>

                    <div className='d-flex gap-3'>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="AlwaysAvailable"
                                id="fullPaymentRadio"
                                name="availabilityType"
                                checked={formData.availabilityType === 'AlwaysAvailable'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="fullPaymentRadio">
                                Always Available
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="TimeBased"
                                id="partialPaymentRadio"
                                name="availabilityType"
                                checked={formData.availabilityType === 'TimeBased'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="partialPaymentRadio">
                                Time Based
                            </label>
                        </div>
                    </div>

                    {formData.availabilityType === 'TimeBased' && (
                        <div className="form-group">
                            <label htmlFor="" className='course__label'>Available From: *</label>
                            <input
                                type="datetime-local"
                                name="availableFrom"
                                value={formData.availableFrom}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                            {validationErrors.availableFrom && (
                                <p className="text-danger">{validationErrors.availableFrom}</p>
                            )}
                        </div>
                    )}
                    {formData.availabilityType === 'TimeBased' && (
                        <div className="form-group">
                            <label htmlFor="" className='course__label'>Available Till: *</label>
                            <input
                                type="datetime-local"
                                name="availableTill"
                                value={formData.availableTill}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                            {validationErrors.availableTill && (
                                <p className="text-danger">{validationErrors.availableTill}</p>
                            )}
                        </div>
                    )}
                    <div className="form-group">
                        <TextEditor
                            label="Instructions: *"
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                        />
                    </div>

                    <div className="get-started-cls why_choose_ment">
                        <a>
                            <button type="submit" className="find-btn btn btn-primary">
                                Save
                            </button>
                        </a>
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddAnyFileChapter;
