import React, { useState } from 'react';
import TextEditor from '../Modal/TextEditor';
import { EditorState, convertToRaw } from 'draft-js';
import SelectMultiInputs from '../../applyAsMentor/selectMultiInputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { appendFormData } from '../../../redux/actions/course';
import { useEffect } from 'react';

const AddTextChapter = ({ chapterIndex, unitIndex }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        title: '',
        enableSharing: false,
        text: "",
        Tags: [],
        availabilityType: 'AlwaysAvailable',
        availableFrom: '',
        availableTill: ''
    });
    const syllabusIndexData = useSelector(
        (state) => state.SyllabusIndexDataRedcuer?.data[0]
    )
    useEffect(() => {
        if (syllabusIndexData) {
            const selectedUnit = syllabusIndexData.course_syllabus?.[unitIndex];
            const selectedChapter = selectedUnit?.chapters?.[chapterIndex];
            const data = selectedChapter?.data[0]

            if (selectedChapter) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    title: data?.title || '',
                    enableSharing: data?.enableSharing || '',
                    text: data?.text || '',
                    availabilityType: data?.availabilityType || 'AlwaysAvailable',
                    availableFrom: data?.availableFrom || '',
                    availableTill: data?.availableTill || '',
                    Tags: data?.Tags || '',
                }));
            }
        }
    }, [syllabusIndexData, chapterIndex, unitIndex]);

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState, e) => {
        setEditorState(editorState);
        const { blocks } = convertToRaw(editorState.getCurrentContent());
        setFormData(prevFormData => ({
            ...prevFormData,
            text: blocks[0]?.text,
        }));
    };

    const handleTagsChange = (item, addFunc) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            Tags: [...prevFormData.Tags, item]
        }));
    };

    const handleRemoveTag = (item) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            Tags: prevFormData.Tags.filter(tag => tag.value !== item.value)
        }));
    };
    const [validationMessages, setValidationMessages] = useState({
        title: '',
        Tags: '',
        text: '',
        availableFrom: '',
        availableTill: '',
        success:''
    });
    const handleFormSubmit = (event) => {
        event.preventDefault();
        const newValidationMessages = {}; // Store validation messages for all fields

        // Validation logic for each field
        if (!formData.title) {
            newValidationMessages.title = 'Title is required';
        }
        if (formData.Tags.length < 5) {
            newValidationMessages.Tags = 'At least 5 Tags are required';
        }
        if (!formData.text) {
            newValidationMessages.text = 'Text is required';
        }
        if (formData.availabilityType === 'TimeBased') {
            if (!formData.availableFrom) {
                newValidationMessages.availableFrom = 'Available From is required';
            }
            if (!formData.availableTill) {
                newValidationMessages.availableTill = 'Available Till is required';
            }
        }

        // Set validation messages
        setValidationMessages(newValidationMessages);

        // Check if any validation message exists
        const isValid = Object.values(newValidationMessages).every((message) => !message);

        if (isValid) {
            // If all fields are valid, proceed with form submission
            console.log('Form Data:', formData);
            dispatch(appendFormData("ADD_CHAPTER_DATA", chapterIndex, unitIndex, formData));
            newValidationMessages.success = 'Saved Successfully';
        }

    };

    return (
        <div className='bg-white p-5'>
            <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                    <label className="course__label">
                        Title *
                    </label>
                    <input
                        type="text"
                        name="title"
                        placeholder="Enter course Title"
                        className="form-control"
                        value={formData.title}
                        onChange={handleInputChange}
                        required
                    />
                    {validationMessages.title && (
                        <p className="text-danger">{validationMessages.title}</p>
                    )}
                </div>
                <div className="form-group">
                    <label className="course__label">
                        Tags  *
                    </label>
                    <div className="multi-inputs-container">
                        <div className="multi-inputs">
                            {formData?.Tags && formData?.Tags?.map((Tag) => (
                                <div className="multi-input-design" key={Tag.value}>
                                    <div>{Tag.label}</div>
                                    <div onClick={() => handleRemoveTag(Tag)}>
                                        <FontAwesomeIcon
                                            className="multi-select-icon"
                                            icon={faXmark}
                                            style={{ color: 'grey' }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <SelectMultiInputs
                        isMulti={true}
                        title="language"
                        onChangeFunc={(e) => handleTagsChange(e, setFormData)}
                        value=""
                        name="Tags"
                        isCreatable={true}
                        placeholder="Add at least 5-6 Tags"
                        className="basic-multi-select text-left"
                        classNamePrefix="select"
                        inputData={formData.Tags}
                        closeMenuOnSelect={true}
                        style={{ borderRadius: '12px', height: '3rem' }}
                    />
                    {validationMessages.Tags && (
                        <p className="text-danger">{validationMessages.Tags}</p>
                    )}
                </div>
                <div className="form-group">
                    <div className="form-check form-switch d-flex">
                        <input
                            className="form-check-input"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            checked={formData.enableSharing}
                            onChange={handleInputChange}
                            name="enableSharing"
                        />
                        <label className="course__label" htmlFor="flexSwitchCheckDefault">
                            Enable Sharing
                        </label>
                    </div>
                </div>

                <div className='d-flex gap-3'>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            value="AlwaysAvailable"
                            id="fullPaymentRadio"
                            name="availabilityType"
                            checked={formData.availabilityType === 'AlwaysAvailable'}
                            onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="fullPaymentRadio">
                            Always Available
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            value="TimeBased"
                            id="partialPaymentRadio"
                            name="availabilityType"
                            checked={formData.availabilityType === 'TimeBased'}
                            onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="partialPaymentRadio">
                            Time Based
                        </label>
                    </div>
                </div>
                {formData.availabilityType === 'TimeBased' && (
                    <div className="form-group">
                        <label htmlFor="" className='course__label'>Available From: *</label>
                        <input
                            type="datetime-local"
                            name="availableFrom"
                            value={formData.availableFrom}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                        {validationMessages.availableFrom && (
                            <p className="text-danger">{validationMessages.availableFrom}</p>
                        )}
                    </div>
                )}
                {formData.availabilityType === 'TimeBased' && (
                    <div className="form-group">
                        <label htmlFor="" className='course__label'>Available Till: *</label>
                        <input
                            type="datetime-local"
                            name="availableTill"
                            value={formData.availableTill}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                        {validationMessages.availableTill && (
                            <p className="text-danger">{validationMessages.availableTill}</p>
                        )}
                    </div>
                )}
                <div className="form-group">
                    <TextEditor
                        label="Text to add *"
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                    />
                    {validationMessages.text && (
                        <p className="text-danger">{validationMessages.text}</p>
                    )}
                </div>

                <div className="get-started-cls why_choose_ment">
                    <button type="submit" className="find-btn btn btn-primary">
                        Save
                    </button>
                </div>
                {validationMessages.success && (
                    <p className="text-success text-center">{validationMessages.success}</p>
                )}
            </form>
        </div>
    );
}

export default AddTextChapter;
