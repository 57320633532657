import { createCourseConstants, 
        deleteMentorCourseConstants, 
        getAllCourseConstants,
        getCourseDetailsConstants, 
        getMentorCourseConstants,
        updateMentorCourseConstants 
      } from "../../constants";

export const SyllabusIndexDataAction = (data) => {
  return { 
    type:data?.type ,
    payload: data?.data,
  };
};

export const appendFormData = (type, chapterIndex, unitIndex, formData) => ({
  type,
  payload: {
    chapterIndex,
    unitIndex,
    formData,
  },
});

export function createCourseAction(data) {
  return {
    type: createCourseConstants.CREATE_COURSE_REQUEST,
    data,
  };
}
export function getMentorCourseAction(data) {
  return {
    type: getMentorCourseConstants.GET_MENTOR_COURSE_REQUEST,
    data,
  };
}
export function updateMentorCourseAction(data) {
  return {
    type: updateMentorCourseConstants.UPDATE_MENTOR_COURSE_REQUEST,
    data,
  };
}
export function deleteMentorCourseAction(data) {
  return {
    type: deleteMentorCourseConstants.DELETE_MENTOR_COURSE_REQUEST,
    data,
  };
}
export function getAllCourseAction(data) {
  return {
    type: getAllCourseConstants.GET_ALL_COURSE_REQUEST,
    data,
  };
}
export function getCourseDetailsAction(data) {
  return {
    type: getCourseDetailsConstants.GET_COURSE_DETAIL_REQUEST,
    data,
  };
}
export function preViewCourseAction(data) {
  return {
    type:"PREVIEW_COURSE_ACTION",
    payload:data
  };
}

export const triggerOpenNav = () => {
  return {
    type: 'TRIGGER_OPEN_NAV',
  };
};
