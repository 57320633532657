import React, { forwardRef, useEffect, useState } from 'react';
import { REACT_USER_ID } from '../../auth/tokenProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getMentorAvailableHoursRequest, mentorAvailableHoursRequest } from '../../../redux/actions/mentor';
import TimePicker, { setDefaultLocale } from "react-datepicker"; import closeIconSvg from '../../adminDashboard/img/closeIconSvg.svg'
import addButtonPng from '../../adminDashboard/img/add-symbol-png.png'
import { TailSpin } from 'react-loader-spinner';
import { IoCloseCircle } from "react-icons/io5";
import { AiOutlinePlusCircle } from "react-icons/ai";

import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Menu,
    message,
    Row,
    Select,
    Space,
    Typography,
} from "antd";
const { Paragraph } = Typography;

const Schedule = () => {
    useEffect(() => {
        import("react-datepicker/dist/react-datepicker.css");
        import("../../adminDashboard/Availability/AvailableHours/AvailableHours.css");
    }, []);
    const user_id = REACT_USER_ID;
    const dispatch = useDispatch();

    const currentDate = new Date().toISOString().slice(0, 11);
    const slotIntervalInMinutes = 30;
    const slotIntervalInMiliseconds = slotIntervalInMinutes * 60 * 1000;
    const currentTimeInMiliseconds = new Date(currentDate + "09:00:00").getTime();
    const setTimeIntervalSlots = (lastTimeValue, intervalValue) => {
        return new Date(lastTimeValue + intervalValue);
    };
    const defalutSlotData = {
        startTime: setTimeIntervalSlots(currentTimeInMiliseconds, 0),
        endTime: setTimeIntervalSlots(
            currentTimeInMiliseconds,
            slotIntervalInMiliseconds
        ),
    };
    const setDefaultSlotData = (slotTime) => {
        const slotStartTime = new Date(slotTime).getTime();
        return {
            startTime: setTimeIntervalSlots(slotStartTime, 0),
            endTime: setTimeIntervalSlots(slotStartTime, slotIntervalInMiliseconds),
        };
    };
    const getPreviousSlotEndTime = (dataArray) => {
        try {
            if (dataArray.length > 1) return dataArray.slice(-2)[0]?.endTime;
            else return new Date(currentTimeInMiliseconds);
        } catch {
            return new Date(currentTimeInMiliseconds);
        }
    };

    useEffect(() => {
        let data = { id: user_id };
        dispatch(getMentorAvailableHoursRequest(data));
    }, []);

    const getWeekDayInitialState = (day, hoursData) => {
        let checkBoxValue;
        let data = hoursData?.available_days
            ?.filter((item) => {
                if (item?.day.toLowerCase() == day.toLowerCase()) {
                    checkBoxValue = true;
                    return item;
                }
            })[0]
            ?.hours?.map((item) => {
                return {
                    startTime: new Date(item?.start_time),
                    endTime: new Date(item?.end_time),
                };
            });
        if (data?.length > 0) {
            return {
                checkValue: checkBoxValue,
                data: data,
            };
        } else {
            return { checkValue: false, data: [defalutSlotData] };
        }
    };
    var weekDataMapping = {};
    const getMentorAvailableHoursReducerData = useSelector((state) => {
        const getMentorAvailableHoursData =
            state?.getMentorAvailableHoursReducer?.data;
        let weekDays = [
            "Saturday",
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
        ];

        weekDays?.map((item) => {
            weekDataMapping[item] = getWeekDayInitialState(
                item,
                getMentorAvailableHoursData
            );
        });

        return state?.getMentorAvailableHoursReducer;
    });

    const [sundayData, setSundayData] = useState();
    const [mondayData, setMondayData] = useState();

    const [tuesdayData, setTuesdayData] = useState();
    const [wednesdayData, setWednesdayData] = useState();
    const [thursdayData, setThursdayData] = useState();
    const [fridayData, setFridayData] = useState();
    const [saturdayData, setSaturdayData] = useState();
    const weekData = [
        {
            day: "Saturday",
            weekDayData: saturdayData ||
                weekDataMapping["Saturday"] || {
                checkValue: false,
                data: [defalutSlotData],
            },
            setWeekDayData: setSaturdayData,
        },
        {
            day: "Sunday",
            weekDayData: sundayData ||
                weekDataMapping["Sunday"] || {
                checkValue: false,
                data: [defalutSlotData],
            },
            setWeekDayData: setSundayData,
        },
        {
            day: "Monday",
            weekDayData: mondayData ||
                weekDataMapping["Monday"] || {
                checkValue: false,
                data: [defalutSlotData],
            },
            setWeekDayData: setMondayData,
        },
        {
            day: "Tuesday",
            weekDayData: tuesdayData ||
                weekDataMapping["Tuesday"] || {
                checkValue: false,
                data: [defalutSlotData],
            },
            setWeekDayData: setTuesdayData,
        },
        {
            day: "Wednesday",
            weekDayData: wednesdayData ||
                weekDataMapping["Wednesday"] || {
                checkValue: false,
                data: [defalutSlotData],
            },
            setWeekDayData: setWednesdayData,
        },
        {
            day: "Thursday",
            weekDayData: thursdayData ||
                weekDataMapping["Thursday"] || {
                checkValue: false,
                data: [defalutSlotData],
            },
            setWeekDayData: setThursdayData,
        },
        {
            day: "Friday",
            weekDayData: fridayData ||
                weekDataMapping["Friday"] || {
                checkValue: false,
                data: [defalutSlotData],
            },
            setWeekDayData: setFridayData,
        },
    ];

    const setDayCheckValue = (dayData, setDayData, value) => {
        if (value) setDayData({ ...dayData, checkValue: value });
        else setDayData({ checkValue: value, data: [defalutSlotData] });
    };
    const createDynamicSlots = (dayData, setDayData) => {
        setDayData({
            ...dayData,
            data: [
                ...dayData.data,
                setDefaultSlotData(dayData.data.slice(-1)[0].endTime),
            ],
        });
    };

    const setSlotsValues = (dayData, setDayData, index, value) => {
        var currentSlot = dayData.data[index];
        if (value[0] === "startTime")
            var newSlot = { ...currentSlot, startTime: value[1] };
        else var newSlot = { ...currentSlot, endTime: value[1] };
        dayData.data[index] = newSlot;
        setDayData({ ...dayData, data: dayData.data });
    };

    const removeDynamicSlots = (dayData, setDayData, index) => {
        dayData.data.splice(index, 1);
        if (dayData.data.length < 1)
            setDayData({ checkValue: false, data: [defalutSlotData] });
        else setDayData({ ...dayData });
    };

    const setMentorAvailableHours = () => {
        let apiDataModel = { mentor_id: user_id, available_days: [] };
        const formatedWeekData = weekData
            ?.filter((item) => {
                if (item?.weekDayData?.checkValue == true) return item;
            })
            ?.map((item) => {
                return {
                    day: item?.day,
                    hours: item?.weekDayData?.data?.map((item) => {
                        return {
                            start_time: item?.startTime,
                            end_time: item?.endTime,
                        };
                    }),
                };
            });
        apiDataModel["available_days"] = formatedWeekData;
        dispatch(mentorAvailableHoursRequest(apiDataModel));
    };
    const isLoadingUpdate = useSelector(
        (state) => state?.mentorAvailableHoursReducer?.loading
    );
    const isLoadingInitalData = getMentorAvailableHoursReducerData?.loading;
    const isLoading = isLoadingInitalData || isLoadingUpdate;
    const mentorAvailabilityState = useSelector(
        (state) => state.mentorAvailableHoursReducer
    );
    useEffect(() => {
        const { data, isSuccess, error, loading } = mentorAvailabilityState
        if (!loading && data !== undefined && isSuccess) {
            message.success("Slots Updated Successfully!")
        }
        if (!loading && data === undefined && !isSuccess && error) {

            message.error("Something went wrong!")
            console.log("error", error)
        }

    }, [mentorAvailabilityState])


    // getWeekDayInitialState("Saturday");

    // useEffect(() => {
    // }, [getMentorAvailableHoursReducerData]);

    // let formatDataForUi =
    //   getMentorAvailableHoursReducerData?.data?.available_days?.map((item) => {
    //     weekStateMapping[item?.day]({
    //       checkedValue: true,
    //       data: item?.hours?.map((item) => {
    //         return {
    //           startTime: item?.start_time,
    //           endTime: item?.end_time,
    //         };
    //       }),
    //     });
    //   });

    const SelectCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="select-custom-input-container" onClick={onClick} ref={ref}>
            <div className="dropdown-value">{value}</div>
            <div className="dropdown-arrow">
                <svg
                    height="20"
                    width="20"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    focusable="false"
                    className="drop-down-svg"
                >
                    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                </svg>
            </div>
        </div>
    ));
    return (
        <>
            <Row >
                <Col span={24} className="add-slots">

                    <Row>
                        {/* Other than Default */}
                        <Col span={24} className="add-slots-header-section">
                            <Paragraph className="slots-header" ellipsis={{ rows: 2 }}>
                                Default
                            </Paragraph>
                            <Space>
                                <Button
                                    className="btn-dark"
                                    onClick={() => setMentorAvailableHours()}
                                // loading={submitting}
                                >
                                    Save
                                </Button>


                            </Space>
                        </Col>
                    </Row>
                    <div className="container-div">
                        {/* <!-- Default switch --> */}
                        {weekData.map((weekDay, index) => (
                            <div className="Available-mainDiv" key={index}>
                                <div className="container-row">
                                    <div className="row-btn-day-box">
                                        <div className="custom-control custom-switch ">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={`day_${weekDay.day}`}
                                                checked={weekDay?.weekDayData?.checkValue}
                                                onChange={() =>
                                                    setDayCheckValue(
                                                        weekDay.weekDayData,
                                                        weekDay.setWeekDayData,
                                                        !weekDay.weekDayData.checkValue
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor-pointer"
                                                htmlFor={`day_${weekDay.day}`}
                                            ></label>
                                        </div>
                                        <div className="weekday-div">
                                            <div>{weekDay.day}</div>
                                        </div>
                                    </div>
                                    {weekDay.weekDayData.checkValue ? (
                                        <div className="row-time-main-container">
                                            {weekDay.weekDayData.data.map((item, index) => (
                                                <div key={index} className="time-cross-box">
                                                    <div className="cursor-pointer">
                                                        <TimePicker
                                                            className="time-selector cursor-pointer"
                                                            minTime={getPreviousSlotEndTime(
                                                                weekDay.weekDayData.data
                                                            )}
                                                            maxTime={new Date(currentDate + "23:59:59")}
                                                            selected={item.startTime}
                                                            onChange={(date) =>
                                                                setSlotsValues(
                                                                    weekDay.weekDayData,
                                                                    weekDay.setWeekDayData,
                                                                    index,
                                                                    ["startTime", date]
                                                                )
                                                            }
                                                            customInput={<SelectCustomInput />}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={slotIntervalInMinutes}
                                                            timeCaption="Time"
                                                            dateFormat="h:mm aa"
                                                        />
                                                    </div>
                                                    <span className="betweento" np>
                                                        to
                                                    </span>
                                                    <div className="cursor-pointer">
                                                        <TimePicker
                                                            className="time-selector"
                                                            selected={item.endTime}
                                                            minTime={item.startTime}
                                                            maxTime={new Date(currentDate + "23:59:59")}
                                                            onChange={(date) =>
                                                                setSlotsValues(
                                                                    weekDay.weekDayData,
                                                                    weekDay.setWeekDayData,
                                                                    index,
                                                                    ["endTime", date]
                                                                )
                                                            }
                                                            customInput={<SelectCustomInput />}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={slotIntervalInMinutes}
                                                            timeCaption="Time"
                                                            dateFormat="h:mm aa"
                                                        />
                                                    </div>

                                                    {index !== 0 ? (
                                                        // Delete Slot Button
                                                        <Button

                                                            type="text"
                                                            className="add-slot-btn"
                                                            onClick={() =>
                                                                removeDynamicSlots(
                                                                    weekDay.weekDayData,
                                                                    weekDay.setWeekDayData,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <IoCloseCircle style={{ height: '30px', width: '30px' }} />
                                                        </Button>
                                                    ) : (
                                                        // Add Slot Btn
                                                        <Button

                                                            type="text"
                                                            className="add-slot-btn"
                                                            onClick={() =>
                                                                createDynamicSlots(
                                                                    weekDay.weekDayData,
                                                                    weekDay.setWeekDayData
                                                                )
                                                            }

                                                        >
                                                            <AiOutlinePlusCircle style={{ height: '30px', width: '30px' }} />
                                                        </Button>
                                                    )}

                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>Unavailable</div>
                                    )}

                                </div>
                                <hr />
                            </div>
                        ))}


                        {isLoading ? (
                            <div className="loader" style={{ zIndex: 9999 }}>
                                <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                            </div>
                        ) : null}
                    </div>
                </Col>
            </Row>


        </>
    );
}

export default Schedule;
