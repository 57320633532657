import {
    Button,
    Col,
    Dropdown,
    Form,
    Input,
    MenuProps,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { IoIosAddCircle } from "react-icons/io";
import { AddServiceConfig, getLinkWithPretag } from "../../Data/Data";

const { Paragraph, Text } = Typography;
export const WebsiteRegexPattern =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,15}(:[0-9]{1,5})?(\/.*)?$/;
const WebinarMeetingLink = (props) => {
    const {
        ServiceEditForm,
        initialValues,
        showError = false,
        setShowError,
        data,
    } = props;
    const [webinarPersonalLink, setWebinarPersonalLink] = useState();
    const meetingMenuItems = {
        items: [
            {
                key: "1",
                label: "Use Default (Max 300 people)",
                onClick: () => {
                    setShowError && setShowError(false);
                    ServiceEditForm?.setFieldValue("group_call_url", null);
                    setWebinarPersonalLink(false);
                },
            },
            {
                key: "2",
                label: "Personal link (Zoom, Meet..)",
                onClick: () => {
                    setShowError && setShowError(false);
                    ServiceEditForm?.setFieldValue(
                        "group_call_url",
                        initialValues?.group_call_url?.length > 0
                            ? getLinkWithPretag(initialValues?.group_call_url)
                            : initialValues?.group_call_url || ""
                    );
                    setWebinarPersonalLink(true);
                },
            },
        ],
    };

    useEffect(() => {
        if (
            initialValues?.group_call_url?.length > 0 ||
            ServiceEditForm.getFieldValue("cohort_no_of_sessions") > 1
        ) {
            setWebinarPersonalLink(true);
        }
    }, [
        initialValues?.group_call_url,
        ServiceEditForm.getFieldValue("cohort_no_of_sessions"),
    ]);

    return (
        <Col span={24} className="webinar-link-box">
            {ServiceEditForm?.getFieldValue("group_call_url") == "" &&
                !webinarPersonalLink ? (
                <>
                    <Dropdown
                        menu={meetingMenuItems}
                        trigger={["click"]}
                        className="ellipsis-icon"
                    >
                        <Button icon={<IoIosAddCircle />} className="btn-gray">
                            Add meeting client
                        </Button>
                    </Dropdown>
                    {showError && (
                        <Paragraph type="danger">Select meeting url type</Paragraph>
                    )}
                </>
            ) : (
                <>
                    <Paragraph className="link-dropdown-arrow">
                        {webinarPersonalLink ? (
                            <Form.Item
                                {...AddServiceConfig?.group_call_url}
                                className="title-fields"
                                style={{ width: "100%" }}
                                rules={
                                    webinarPersonalLink
                                        ? [
                                            {
                                                required: true,
                                                message: "Please enter meeting link",
                                            },
                                            {
                                                pattern: WebsiteRegexPattern,
                                                message: "Invalid URL entered, please check URL",
                                            },
                                        ]
                                        : []
                                }
                            >
                                <Input
                                    disabled={data?.can_edit_date == false ? true : false}
                                    placeholder="Your meeting link"
                                />
                            </Form.Item>
                        ) : (
                            <Input value={"google.meeet.com/xxx-xxx"} disabled={true} />
                        )}

                        {ServiceEditForm?.getFieldValue("cohort_no_of_sessions") > 1 ? (
                            <></>
                        ) : (
                            <Dropdown
                                menu={meetingMenuItems}
                                trigger={["click"]}
                                disabled={data?.can_edit_date == false ? true : false}
                            >
                                <Button
                                    type="text"
                                    onClick={() => { }}
                                    className="edit-input-btn"
                                    style={{ border: "none" }}
                                >
                                    <FiEdit2 />
                                </Button>
                            </Dropdown>
                        )}
                    </Paragraph>
                    {!webinarPersonalLink && (
                        <Paragraph className="webinar-link-desc">
                            The link will be available 30mins before start time
                        </Paragraph>
                    )}
                </>
            )}
        </Col>
    );
};

export default WebinarMeetingLink;
