export const QuestionnaireData = [
    {
        "_id": "63ebbb62075d621974d31921",
        "questions": [
            {
                "text": "Convince others to adopt my suggestions.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Having a large social circle.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Choosing tasks that are challenging to complete.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Assisting someone in need, even if it requires extra effort or resources.",
                "section": "EM",
                "answer": 0
            },
            {
                "text": "Showing and proving my expertise and ensuring it is acknowledged and accepted by others.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Hosting social gatherings and events at my residence for others to attend.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Starting undertakings, even if there is a possibility of failure.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Assuming personal responsibility for addressing the concerns of others.",
                "section": "EM",
                "answer": 0
            },
            {
                "text": "Obtaining what I want, even if it requires struggle or confrontation.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Extending warm wishes and congratulations to others on their birthdays and other personal or family celebrations.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Performing actions that are distinctive and stand out from the norm.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Advocating for a cause or issue of importance to one's nation or community.",
                "section": "EM",
                "answer": 0
            },
            {
                "text": "Consulting with a higher-up or supervisor before taking action.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Wearing suitable clothing for the occasion.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "I strive to outperform others in my field of work through improved performance.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Making sacrifices for the benefit of others.",
                "section": "EM",
                "answer": 0
            },
            {
                "text": "Adhering strictly to established guidelines and rules for any task or responsibility.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Making decisions thoughtfully and with consideration.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Taking the necessary steps to advance my professional development and growth.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Comforting or providing emotional support to someone who is troubled or upset.",
                "section": "EM",
                "answer": 0
            },
            {
                "text": "Managing or directing the actions of employees through various methods.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Focusing on the emotional well-being of others rather than on the task at hand.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Initiating activities, even when the details or outcome are uncertain.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Helping others to comprehend what is in their best interest.",
                "section": "EM",
                "answer": 0
            },
            {
                "text": "Establishing guidelines and rules that others are expected to adhere to.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Steering clear of disputes or disagreements.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Innovating or trying something novel.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Educating or informing individuals about their privileges and entitlements.",
                "section": "EM",
                "answer": 0
            },
            {
                "text": "Imposing penalties or sanctions on individuals who are arrogant or vain.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Recognizing or acknowledging the efforts of others for their input or involvement.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Taking a systematic approach by preparing and organizing before executing any actions.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Assisting or guiding individuals in enhancing their understanding and abilities.",
                "section": "EM",
                "answer": 0
            },
            {
                "text": "Leading or guiding others towards what I believe to be the correct course of action.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Arranging or coordinating gatherings or events for a community or organization.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Demonstrating capability by undertaking a significant task.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Collaborating with team members to enhance their skills and abilities.",
                "section": "EM",
                "answer": 0
            }, 
            {
                "text": "Undertaking tasks that elevate one's standing or reputation.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Interacting with others on a personal level, regardless of their position or rank.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Finding joy in overcoming obstacles and challenges.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Showing concern and investing in the professional development of others.",
                "section": "EM",
                "answer": 0
            },
            {
                "text": "Starting tasks or projects that others should emulate or continue.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Granting autonomy and letting others choose their own tasks or projects.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Starting a task or project with a strong resolve to finish it.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Encouraging or convincing others to adopt new habits for achieving success.",
                "section": "EM",
                "answer": 0
            },
            {
                "text": "Carrying out assignments or duties as directed by one's supervisor or manager.",
                "section": "PM",
                "answer": 0
            },
            {
                "text": "Assigning tasks or responsibilities to team members or subordinates.",
                "section": "AFM",
                "answer": 0
            },
            {
                "text": "Making new attempts with adjustments or changes in order to reach a goal that was not accomplished previously.",
                "section": "ACM",
                "answer": 0
            },
            {
                "text": "Commending or praising team members or subordinates for their achievements or positive feedback.",
                "section": "EM",
                "answer": 0
            }
        ]
    }
]
