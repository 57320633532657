import React, { useState } from "react";
import "./UpdateSkill.css";
import { userSkills } from "../../../../assets/data/data";
import ReactSelect from "react-select";

const UpdateSkill = (props) => {
  const customKeySkill = props?.skill?.map((item) => {
    return { label: item, value: item };
  });
  const [selectedSkill, setSelectedSkill] = useState(customKeySkill);
  const setSkillValue = (e) => {
    let tempList = selectedSkill;
    e?.map((item) => tempList?.push(item?.label));
    tempList.push(e);

    setSelectedSkill(e);
  };
  const handleOnClick = () => {
    let skills = selectedSkill?.map((item) => item?.value);
    let tempData = { skills: skills };
    props?.getUpdatedValues(tempData);
  };
  return (
    <div className="update-skill-main-container">
      {/* <h7>What skill(s) do you speak?</h7> */}
      <div className="skill-input">
        <ReactSelect
          required={true}
          value={selectedSkill ? selectedSkill : ""}
          isMulti
          name="skill"
          onChange={setSkillValue}
          options={userSkills}
        />
      </div>
      <button className="update-btn-skill" onClick={handleOnClick}>
        Update
      </button>
    </div>
  );
};

export default UpdateSkill;
