import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import crossImg from '../img/cross.png';

 
import e from "cors";
import { useDispatch, useSelector } from "react-redux";
import { mentorSessionServices } from "../../../redux/actions/mentor";
 
import("../css/custom.css");

const Services = () => {
  const navigate = useNavigate();
  const selectedSessionTypeSavedData = useSelector((state) => {
    const data = state.mentorServiceSessionTypeReducer?.data?.type || "";
    return data;
  });
  const selectedSessionServicesSavedData = useSelector((state) => {
    const data = state.mentorSessionServicesReducer?.data?.services || [];
    return data;
  });
  const [audioCall, setAudioCall] = useState(
    selectedSessionServicesSavedData[1]?.active || false
  );

  const [videoCall, setVideoCall] = useState(
    selectedSessionServicesSavedData[0]?.active || false
  );

  const [chats, setChats] = useState(
    selectedSessionServicesSavedData[2]?.active || false
  );

  const [freeStudyMaterial, setFreeStudyMaterial] = useState(
    selectedSessionServicesSavedData[3]?.active || false
  );

  const [dailyProgressReport, setDailyProgressReport] = useState(
    selectedSessionServicesSavedData[4]?.active || false
  );

  const [frequency, setFrequency] = useState(
    selectedSessionServicesSavedData[0]?.frequency || false
  );

  const dispatch = useDispatch();

  const stateDataObject = {
    audioCall: audioCall,
    videoCall: videoCall,
    chats: chats,
    freeStudyMaterial: freeStudyMaterial,
    dailyProgressReport: dailyProgressReport,
  };

  const serviceTimeChoice = [
    {
      text: "Once a week",
      value: "Once a week",
    },
    {
      text: "Only when required",
      value: "Only when required",
    },
    {
      text: "I will decide later",
      value: "I will decide later",
    },
  ];

  const servicesDataModel = {
    services: [
      {
        type: "videoCall",
        frequency: "",
        active: false,
      },
      {
        type: "audioCall",
        frequency: "",
        active: false,
      },
      {
        type: "chats",
        frequency: "",
        active: false,
      },
      {
        type: "freeStudyMaterial",
        frequency: "",
        active: false,
      },
      {
        type: "dailyProgressReport",
        frequency: "",
        active: false,
      },
    ],
    links: [],
  };
  const setServiceDetails = () => {
    const services = servicesDataModel.services.map((item) => {
      return {
        type: item.type,
        frequency: frequency,
        active: stateDataObject[item.type],
      };
    });
    servicesDataModel.services = services;
  };
  useEffect(() => {
     
    import("../css/custom.css");
  }, []);
  useEffect(() => {
    setServiceDetails();
    dispatch(mentorSessionServices(servicesDataModel));
  }, [
    audioCall,
    videoCall,
    freeStudyMaterial,
    dailyProgressReport,
    chats,
    frequency,
  ]);

  return (
    <>
      <div id="wrapper">
        <div className="step-cont">
          <div className="step-inner-cls">
            <form action="#" method="post" id="raq_questions">
              <div className="question-container active_panel " id="question-4">
                <h3>what services are you going to provide?</h3>
                <div className="custom-radio-cls">
                  <div className="custom-radio-bg">
                    <label className="custom-radio">
                      <input
                        type="radio"
                        checked="checked"
                        name="radio"
                        disabled
                      />
                      <span className="checkmark"></span>
                      <span className="cont">
                        {selectedSessionTypeSavedData}
                      </span>
                    </label>
                  </div>
                  <div className="form-group input-group">
                    <div className="custom-radio-bg">
                      <label className="form-check-label" htmlFor="check1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="check1"
                          name="option1"
                          checked={audioCall}
                          onChange={(e) => setAudioCall(!audioCall)}
                        />
                        Audio Calls
                      </label>
                    </div>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {frequency ? frequency : "Select Time"}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {serviceTimeChoice.map((choice) => (
                          <a
                            className="dropdown-item"
                            onClick={() => setFrequency(choice.value)}
                          >
                            {choice.text}
                          </a>
                        ))}

                        {/* <a className="dropdown-item" href="#">
                          Only when required
                        </a>
                        <a className="dropdown-item" href="#">
                          I will decide later
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="custom-radio-bg">
                    <label className="form-check-label" htmlFor="check2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="check2"
                        name="option2"
                        // value="something"
                        checked={videoCall}
                        onChange={(e) => setVideoCall(!videoCall)}
                      />
                      Video Calls
                    </label>
                  </div>
                  <div className="custom-radio-bg">
                    <label className="form-check-label" htmlFor="check3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="check3"
                        name="option3"
                        value="something"
                        checked={chats}
                        onChange={() => setChats(!chats)}
                      />
                      Chats
                    </label>
                  </div>
                  <div className="custom-radio-bg">
                    <label className="form-check-label" htmlFor="check4">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="check4"
                        name="option4"
                        value="something"
                        checked={dailyProgressReport}
                        onChange={() =>
                          setDailyProgressReport(!dailyProgressReport)
                        }
                      />
                      Daily Progress Report
                    </label>
                  </div>
                  <div className="custom-radio-bg">
                    <label className="form-check-label" htmlFor="check5">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="check5"
                        name="option5"
                        value="something"
                        checked={freeStudyMaterial}
                        onChange={() =>
                          setFreeStudyMaterial(!freeStudyMaterial)
                        }
                      />
                      Free Study Material
                    </label>
                  </div>
                  <div className="form-group file-pdf">
                    <div className="input-group">
                      <div className="bg-cls">
                        File.PDF{" "}
                        <a href="#">
                          <img src={crossImg} />
                        </a>
                      </div>
                      <div className="file_input">
                        <input
                          type="file"
                          name="Choose a file"
                          id="file-upload"
                          className="form-btn"
                        />
                        <span htmlFor="fileToUpload">Add More...</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group file-url">
                    <div className="input-group">
                      <div className="bg-cls">
                        Youtuve or other URL{" "}
                        <a href="#">
                          <img src={crossImg} />
                        </a>
                      </div>
                      <div className="file_input">
                        <input
                          type="file"
                          name="Choose a file"
                          id="file-upload"
                          className="form-btn"
                        />
                        <span htmlFor="fileToUpload">Add More...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
