import {
    Button,
    Col,
    Dropdown,
    Menu,
    message,
    Row,
    Space,
    Typography,
} from "antd";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import openDt from '../../Image/openDet.svg'
import iconserviceanalyticsclosed from '../../Image/iconserviceanalyticsclosed.svg'
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiShare } from "react-icons/fi";
import { IoEyeOffSharp, IoEyeOutline } from "react-icons/io5";
import DeleteConfirmation from "./DeleteConfirmation";
import CountUp from 'react-countup';
import { currencyName } from './../../Data/Data';
import { useNavigate } from "react-router-dom";
import { deleteMentorCourseAction } from "../../../../redux/actions/course";
import { useDispatch, useSelector } from "react-redux";
const { Paragraph, Text, Title } = Typography;

const ServiceCard = ({ service, idx }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { title, duration, charge, product_id, type, promised_response_time } = service;
    const [cardExpanded, setCardExpanded] = useState(idx === 0 ? true : false);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

    const easingFn = (t, b, c, d) => {
        const ts = (t /= d) * t;
        const tc = ts * t;
        return b + c * (tc + -3 * ts + 3 * t);
    };

    const gotoEdit = (source) => {

        navigate(`/dashboard/service/edit/id=${product_id}`)

    };
    const formatNumber = (number, flexible = false) => {
        return (
            Number(parseFloat(number).toFixed(1)).toLocaleString() +
            (flexible ? "+" : "")
        );
    };

    const getCharge = (charge, flexible) => {
        return formatNumber(charge, flexible);
    };

    const copyContent = () => {
        const url = `/dashboard/service/edit/id=${product_id}`;
        const fullUrl = window.location.origin + url;

        navigator.clipboard.writeText(fullUrl)
            .then(() => message.success("Service link copied to clipboard"))
            .catch((err) => console.error('Unable to copy to clipboard', err));
    };

    const getConversionRate = (bookings, clicks) => {
        if (clicks <= 0) {
            return 0;
        }
        return (bookings / clicks) * 100;
    };

    const createDuplicateService = (data) => {
        message.error("Comming soon")

    };
    const deleteProductReducer = useSelector((state) => state.deleteMentorCourseReducer);

    const deleteService = (product_id) => {
        dispatch(deleteMentorCourseAction(product_id)).then(({ data, loading, error }) => {
            if (!loading && data === undefined && error === undefined) {
                message.success("Service deleted");
            } else if (!loading && data !== undefined && error !== undefined) {
                message.error("Something went wrong");
            }
        });
    };
    const handleDropdownClick = (option, service) => {
        switch (option?.value) {
            case "copy":
                copyContent();
                break;
            case "duplicate":
                createDuplicateService(service);
                break;
            case "delete":
                setDeleteConfirmationModal(true);
                break;
            default:
                message.error("Invalid option selected");
        }
    };

    const EditOptions = [
        {
            key: "1",
            text: "Copy Link",
            value: "copy",
        },
        // {
        //     key: "2",
        //     text: "Duplicate Service",
        //     value: "duplicate",
        // },
        {
            key: "3",
            text: "Delete Service",
            value: "delete",
        },
    ];

    return (
        <Col span={24}>
            <Row>
                <Col
                    xs={24}
                    md={13}
                    lg={11}
                    className={cardExpanded ? `service-card expanded` : `service-card`}
                    onClick={() => gotoEdit("card")}
                    style={{ cursor: "pointer" }}
                >
                    <Row style={{ width: "100%" }}>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            className="service-card-main"
                        >
                            <Paragraph className="title-section">
                                <Title
                                    className="service-title"
                                    ellipsis={{ rows: 1 }}
                                    title={title}
                                >
                                    {title}
                                </Title>
                                <Paragraph>
                                    {service?.is_visible ? (
                                        <Space align="center">
                                            <IoEyeOutline fontSize={18} />
                                            Public
                                        </Space>
                                    ) : (
                                        <Space align="center">
                                            <IoEyeOffSharp fontSize={18} />
                                            Hidden
                                        </Space>
                                    )}
                                </Paragraph>
                            </Paragraph>
                            <Space align="center" size={8}>
                                {/* Response Time and Service Duration */}
                                {[1, 2].indexOf(service?.type) > -1 && (
                                    <Text>
                                        {type === 1
                                            ? duration == "1"
                                                ? `${duration} min`
                                                : `${duration} mins`
                                            : promised_response_time == "0"
                                                ? "No limit"
                                                : promised_response_time == "24"
                                                    ? ` ${promised_response_time / 24} day`
                                                    : ` ${promised_response_time / 24} days`}
                                        <Text style={{ marginLeft: 6 }}>|</Text>
                                    </Text>
                                )}
                                {/* Webinar Time */}
                                {service?.type === 3 && (
                                    <Text>
                                        {`${dayjs(service?.specific_date).format(
                                            "ddd, D MMM | h:mm A"
                                        )}`}
                                        <Text style={{ marginLeft: 6 }}>|</Text>
                                    </Text>
                                )}
                                {/* Package Sessions */}
                                {service?.type === 4 && (
                                    <Text>
                                        {`${service?.session_count} sessions`}
                                        <Text style={{ marginLeft: 6 }}>|</Text>
                                    </Text>
                                )}

                                {/* Service Price */}
                                <Text>{`${currencyName === "INR" ? '₹' : "$"} ${getCharge(
                                    service?.charge,
                                    service?.has_flexible_charge
                                )} `}</Text>
                            </Space>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            className="service-card-footer"
                        >
                            <Paragraph
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Space align="center" size={12}>
                                    <Button
                                        type="text"
                                        shape="round"
                                        style={{
                                            backgroundColor: "#F3F3F1",
                                            padding: "0 20px",
                                            fontWeight: 700,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            gotoEdit("button");
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        type="text"
                                        shape="circle"
                                        style={{ backgroundColor: "#F3F3F1", padding: 0 }}
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            navigate(`/dashboard/service/edit/id=${product_id}`)

                                        }}
                                    >
                                        <FiShare size={20} />
                                    </Button>
                                    <Button
                                        type="text"
                                        style={{ backgroundColor: "#FFF", padding: 0 }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Dropdown
                                            trigger={["click"]}
                                            placement="bottomLeft"
                                            dropdownRender={() => (
                                                <Menu>
                                                    {EditOptions?.map((option) => (
                                                        <Menu.Item
                                                            key={option?.value}
                                                            onClick={() => {
                                                                handleDropdownClick(option, service);
                                                            }}
                                                            disabled={
                                                                option?.value === "duplicate" &&
                                                                service?.is_subscription_enabled
                                                            }
                                                        >
                                                            {option?.text}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu>
                                            )}
                                        >
                                            <Button
                                                block
                                                className="btn-call-action"
                                                style={{ borderRadius: "50%" }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <BsThreeDotsVertical />
                                            </Button>
                                        </Dropdown>
                                    </Button>
                                </Space>
                                {/* {service?.type === 1 && ( */}
                                <Button
                                    type="text"
                                    shape="circle"
                                    style={{ backgroundColor: "#F3F3F1", padding: 0 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCardExpanded(!cardExpanded);

                                    }}
                                >
                                    {cardExpanded ? (
                                        <img
                                            src={openDt}
                                            alt="analytics open"
                                            width={40}
                                        />
                                    ) : (
                                        <img
                                            src={iconserviceanalyticsclosed}
                                            alt="analytics closed"
                                            width={40}
                                        />
                                    )}
                                </Button>
                                {/* )} */}
                            </Paragraph>
                        </Col>
                    </Row>
                </Col>
                {/* {service?.type === 1 && ( */}
                <Col
                    className="service-card-analytics"
                    xs={{ span: cardExpanded ? 24 : 0 }}
                    md={{ span: cardExpanded ? 9 : 0 }}
                    lg={{ span: cardExpanded ? 8 : 0 }}
                    xl={{ span: cardExpanded ? 5 : 0 }}
                >
                    <motion.div
                        exit={{ opacity: cardExpanded ? 0 : 1 }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: cardExpanded ? 1 : 0 }}
                        transition={{ duration: 0.8, delay: 0.1 }}
                    >
                        <Row className="sca-grid">
                            <Col span={24} className="sca-section">
                                <Paragraph className="sca-title">Views</Paragraph>
                                <Paragraph className="sca-value">
                                    {cardExpanded && (
                                        <CountUp
                                            // start={0}
                                            end={service?.visits ? Number(service?.visits) : 0}
                                            duration={2}
                                            // suffix="k"
                                            easingFn={easingFn}
                                            useEasing
                                        />
                                    )}
                                </Paragraph>
                            </Col>
                            <Col span={24} className="sca-section">
                                <Paragraph className="sca-title">Bookings</Paragraph>
                                <Paragraph className="sca-value">
                                    {cardExpanded && (
                                        <CountUp
                                            // start={0}
                                            end={
                                                service?.bookings_count
                                                    ? Number(service?.bookings_count)
                                                    : 0
                                            }
                                            duration={2}
                                            // suffix="k"
                                            easingFn={easingFn}
                                            useEasing
                                        />
                                    )}
                                </Paragraph>
                            </Col>
                            <Col span={24} className="sca-section">
                                <Paragraph className="sca-title">Earnings</Paragraph>
                                <Paragraph className="sca-value">
                                    {cardExpanded && (
                                        <CountUp
                                            // start={0}
                                            end={service?.earnings ? Number(service?.earnings) : 0}
                                            duration={2}
                                            // prefix={}
                                            easingFn={easingFn}
                                            useEasing
                                        />
                                    )}
                                </Paragraph>
                            </Col>
                            <Col span={24} className="sca-section">
                                <Paragraph className="sca-title">Conversion</Paragraph>
                                <Paragraph className="sca-value">
                                    {cardExpanded && (
                                        <CountUp
                                            // start={0}
                                            end={getConversionRate(
                                                service?.bookings_count,
                                                service?.visits
                                            )}
                                            decimals={2}
                                            duration={2}
                                            suffix="%"
                                            easingFn={easingFn}
                                            useEasing
                                        />
                                    )}
                                </Paragraph>
                            </Col>
                        </Row>
                    </motion.div>
                </Col>
                {/* )} */}
            </Row>

            {deleteConfirmationModal && (
                <DeleteConfirmation
                    show={deleteConfirmationModal}
                    onConfirm={() => {
                        deleteService(product_id);
                        setDeleteConfirmationModal(false);
                    }}
                    onClose={() => setDeleteConfirmationModal(!deleteConfirmationModal)}
                    content={`Are you sure you want to delete the service "${title}"`}
                    title={"Delete Service"}
                />
            )}
        </Col>
    );
};

export default ServiceCard;
