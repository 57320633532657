import {
  createMentorConstants,
  findMentorConstants,
  mentorSessionServiceConstants,
  viewMentorProfileConstants,
  createMentorSessionServiceConstants,
  publishEbookConstants,
  mentorProductsConstants,
  mentorEbooksConstants,
  mentorTotalSubscriptionConstants,
  openSessionsConstants,
  topMentorsConstants,
  mentorActiveSubscriptionConstants,
  mentorSubscriptionRequestsConstants,
  availableDayConstants,
  availableTimeConstants,
  mentorAvailableHoursConstants,
  getMentorAvailableHoursConstants,
  getAllMentorSessionConstants,
  addNotesConstants,
  getNotesConstants,
  updateNotesConstants,
  getUpcominActivityConstants,
  getHistoryActivityConstants,
  getUpcomingActivityConstants,
  getUpcomingTaskConstants,
  getHistoryTaskConstants,
  getHistoryMeetingConstants,
  getUpcomingMeetingConstants,
  createMeetingConstants,
  createTaskConstants,
  updateTaskConstants,
  cancelMeetingConstants,
  createProductConstants,
  shareOnLinkedInConstants,
} from "../../constants";

export const findMentorAction = (data) => ({
  type: findMentorConstants.FIND_MENTOR_REQUEST,
  data,
});

export const createMentorAction = (data) => ({
  type: createMentorConstants.CREATE_MENTOR_REQUEST,
  data,
});

export const viewMentorProfileAction = (data) => ({
  type: viewMentorProfileConstants.VIEW_MENTOR_PROFILE_REQUEST,
  data,
});

export const mentorChooseSessionServiceType = (data) => ({
  type: mentorSessionServiceConstants.SESSION_TYPE_REQUEST,
  data,
});

export const mentorSessionServiceDetails = (data) => ({
  type: mentorSessionServiceConstants.SESSION_DETAILS_REQUEST,
  data,
});

export const mentorSessionServiceAvailability = (data) => ({
  type: mentorSessionServiceConstants.SESSION_AVAILABILITY_REQUEST,
  data,
});

export const mentorSessionServices = (data) => ({
  type: mentorSessionServiceConstants.SESSION_SERVICES_REQUEST,
  data,
});

export const mentorSessionServiceHelpMentee = (data) => ({
  type: mentorSessionServiceConstants.SESSION_HELP_MENTEE_REQUEST,
  data,
});

export const mentorSessionServiceMileStone = (data) => ({
  type: mentorSessionServiceConstants.SESSION_MILESTONE_REQUEST,
  data,
});

export const mentorSessionServiceFormData = (data) => ({
  type: mentorSessionServiceConstants.SESSION_SERVICE_FORM_DATA_REQUEST,
  data,
});

export const createMentorSessionSevice = (data) => ({
  type: createMentorSessionServiceConstants.CREATE_MENTOR_SESSION_SERVICE_REQUEST,
  data,
});

export const publishMentorEbook = (data) => ({
  type: publishEbookConstants.PUBLISH_EBOOK_REQUEST,
  data,
});

export const mentorProducts = (data) => ({
  type: mentorProductsConstants.MENTOR_PRODUCTS_REQUEST,
  data,
});

export const mentorEbooks = (data) => ({
  type: mentorEbooksConstants.MENTOR_EBOOKS_REQUEST,
  data,
});
export const mentorTotalSubscription = (data) => ({
  type: mentorTotalSubscriptionConstants.MENTOR_TOTAL_SUBSCRIPTION_REQUEST,
  data,
});
export const getOpenSessionsAction = (data) => ({
  type: openSessionsConstants.OPEN_SESSIONS_REQUEST,
  data,
});
export const getTopMentorsAction = (data) => ({
  type: topMentorsConstants.TOP_MENTOR_REQUEST,
  data,
});
export const mentorActiveSubscription = (data) => ({
  type: mentorActiveSubscriptionConstants.MENTOR_ACTIVE_SUBSCRIPTION_REQUEST,
  data,
});
export const mentorSubscriptionRequests = (data) => ({
  type: mentorSubscriptionRequestsConstants.MENTOR_SUBSCRIPTION_REQUESTS_REQUEST,
  data,
});
export const mentorAvailabilityDaysRequest = (data) => ({
  type: availableDayConstants.AVAILABLE_DAY_REQUEST,
  data,
});
export const mentorAvailabilityTimeSlotsRequest = (data) => ({
  type: availableTimeConstants.AVAILABLE_TIME_REQUEST,
  data,
});

export const mentorAvailableHoursRequest = (data) => ({
  type: mentorAvailableHoursConstants.MENTOR_AVAILABLE_HOURS_REQUEST,
  data,
});
export const getMentorAvailableHoursRequest = (data) => ({
  type: getMentorAvailableHoursConstants.GET_MENTOR_AVAILABLE_HOURS_REQUEST,
  data,
});

export const getAllMentorSessionRequest = (data) => ({
  type: getAllMentorSessionConstants.GET_ALL_MENTOR_SESSION_REQUEST,
  data,
});
export const addNotesAction = (data) => ({
  type: addNotesConstants.ADD_NOTES_REQUEST,
  data,
});
export const getNotesAction = (data) => ({
  type: getNotesConstants.GET_NOTES_REQUEST,
  data,
});
export const updateNotesAction = (data) => ({
  type: updateNotesConstants.UPDATE_NOTES_REQUEST,
  data,
});

export const getUpcomingTaskAction = (data) => ({
  type: getUpcomingTaskConstants.UPCOMING_TASK_REQUEST,
  data,
});

export const getUpcomingMeetingAction = (data) => ({
  type: getUpcomingMeetingConstants.UPCOMING_MEETING_REQUEST,
  data,
});
export const getHistoryMeetingAction = (data) => ({
  type: getHistoryMeetingConstants.HISTORY_MEETING_REQUEST,
  data,
});
export const createMeetingAction = (data) => ({
  type: createMeetingConstants.CREATE_MEETING_REQUEST,
  data,
});
export const createTaskAction = (data) => ({
  type: createTaskConstants.CREATE_TASK_REQUEST,
  data,
});
export const updateTaskAction = (data) => ({
  type: updateTaskConstants.UPDATE_TASK_REQUEST,
  data,
});
export const cancelMeetingAction = (data) => ({
  type: cancelMeetingConstants.CANCEL_MEETING_REQUEST,
  data,
});
export const createProductAction = (data) => ({
  type: createProductConstants.CREATE_PRODUCTS_REQUEST,
  data,
});

export const shareOnLinkedInAction= (data) => ({
  type: shareOnLinkedInConstants.SHARE_ON_LINKEDIN_REQUEST,
  data,
});
