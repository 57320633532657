import React, { useState } from "react";
import "./UpdateInterest.css";
import { useMantorshipDataSecond } from "../../../../assets/data/data";
import ReactSelect from "react-select";

const UpdateInterest = ({ interest, getUpdatedValues }) => {
  const customKeyInterest = interest?.map((item) => {
    return { label: item, value: item };
  });
  const [selectedInterest, setSelectedInterest] = useState(customKeyInterest);
  const setInterestValue = (e) => {
    let tempList = selectedInterest;
    e?.map((item) => tempList?.push(item?.label));
    tempList.push(e);

    setSelectedInterest(e);
  };
  const handleOnClick = () => {
    let mentorships = selectedInterest?.map((item) => item?.value);
    let tempData = { mentorship: mentorships };
    getUpdatedValues(tempData);
  };
  return (
    <div className="update-interest-main-container">
      {/* <h7>What interest(s) do you speak?</h7> */}
      <div className="interest-input">
        <ReactSelect
          required={true}
          value={selectedInterest ? selectedInterest : ""}
          isMulti
          name="interest"
          onChange={setInterestValue}
          options={useMantorshipDataSecond}
        />
      </div>
      <button className="update-btn-interest" onClick={handleOnClick}>
        Update
      </button>
    </div>
  );
};

export default UpdateInterest;
