import React, {  useState } from 'react';
import MyMentorEbooks from './MyMentorEbooks';
import PublisheMentorEbooks from './PublisheMentorEbooks';

const MentorEbooks = () => {
    const [activeTab, setActiveTab] = useState('mybooks'); // Default active tab

    const tabs = [
        { label: 'My Books', key: "mybooks", href: '/dashboard/e-books/my-ebooks' },
        { label: 'Publish Books', key: "publishbooks", href: '/dashboard/e-books/publish-ebooks' },
    ];

    const handleTabClick = (tabKey) => {
        setActiveTab(tabKey);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'mybooks':
                return <MyMentorEbooks />;
            case 'publishbooks':
                return <PublisheMentorEbooks />;

            default:
                return <MyMentorEbooks />;
        }
    };
    return (
        <>
            <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
                <div className="ant-row container-title-section sticky ">
                    <div className="ant-col header-section ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-14 ant-col-md-offset-1 ant-col-xl-12 ant-col-xl-offset-1 ant-col-xxl-12 ant-col-xxl-offset-1 ">
                        <h4 className="ant-typography layout-header ">
                            <div className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line ">
                                E-Books
                            </div>
                        </h4>
                    </div>
                    <div className="ant-col tab-container ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xl-22 ant-col-xl-offset-1 ant-col-xxl-22 ant-col-xxl-offset-1 ">
                        <span className="ant-typography flex-center " style={{ overflowY: 'auto' }}>
                            {tabs.map((tab) => (
                                <a
                                    key={tab.key}
                                    className={`tab-link tab-link-common ${activeTab === tab.key ? 'active' : ''}`}
                                    onClick={() => handleTabClick(tab.key)}
                                >
                                    <span className="ant-typography tab-title ">{tab.label}</span>
                                </a>
                            ))}
                        </span>

                    </div>
                </div>
                <div className='mx-5'>
                    {renderContent()}
                </div>
            </div>

        </>
    );
}

export default MentorEbooks;
