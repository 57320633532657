
import { EditorState, convertToRaw } from 'draft-js';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UploadImage } from '../UploadImage';
import TextEditor from '../HostCourse/Modal/TextEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { REACT_USER_ID } from '../auth/tokenProvider';
import SelectMultiInputs from '../applyAsMentor/selectMultiInputs';
import { expertise } from '../../assets/data/data';
import { createCourseAction } from '../../redux/actions/course';
import { useEffect } from 'react';

const CreateWebinar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const mentor_id = REACT_USER_ID;
  let date = new Date();
  let datestring = date.toTimeString();
  let countryname =
    datestring?.includes("india") | datestring?.includes("India")
      ? "India"
      : "";

  const [isCourseProBono, SetIsCourseProBono] = useState(false);
  const [price, setPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [proBono, setProBono] = useState('no');
  const [course_intro, setCourseDescription] = useState();
  const [what_you_will_learn, setYou_learn] = useState([]);
  const [highlights, setSelectedKeyHighlights] = useState([]);
  const [createwebinarData, setcreatewebinarData] = useState({
    course_title: "",
    course_seat: "",
    course_start_date: "",
    mentor_id: mentor_id,
    course_banner: "",
    course_type: "",
    faq_data: [],
  });

  const createwebinar = (event) => {
    event.preventDefault();
    const validationErrors = [];

    const requiredFields = [
      { field: createwebinarData.course_title, name: 'Webinar title' },
      { field: createwebinarData.course_seat, name: 'Webinar seat' },
      { field: createwebinarData.course_start_date, name: 'Webinar start date' },
      { field: createwebinarData.course_banner, name: 'Webinar banner' },
      // { field: createwebinarData.course_intro_video, name: 'Course intro video' },
      { field: createwebinarData.course_type, name: 'Webinar type' },
      // ... (other required fields)
    ];

    requiredFields.forEach(({ field, name }) => {
      if (!field) {
        validationErrors.push(`${name} is required.`);
      }
    });

    if (!isCourseProBono && (price === 0 || discountedPrice === 0)) {
      validationErrors.push('Price and discounted price are required for paid Webinar.');
    }


    if (what_you_will_learn.length === 0) {
      validationErrors.push("At least one 'What you will learn' item is required.");
    }

    if (highlights.length === 0) {
      validationErrors.push('At least one highlight is required.');
    }

    if (validationErrors.length > 0) {
      alert(validationErrors.join('\n'));
      return;
    }

    let data = {
      price,
      discount_price: discountedPrice,
      proBono: isCourseProBono,
      course_intro,
      what_you_will_learn,
      highlights,
      course_topic: createwebinarData?.course_topic,
      course_seat: createwebinarData?.course_seat,
      course_start_date: createwebinarData?.course_start_date,
      course_banner: createwebinarData?.course_banner,
      course_type: createwebinarData?.course_type,
      faq_data: createwebinarData?.faq_data,
      mentor_id: mentor_id,
      document_type: "webinar",
      currency: countryname === "India" ? "INR" : "USD",
    };

    dispatch(createCourseAction(data))

  };

  const [loader, setloader] = useState(false);
  const [success, setSuccess] = useState();
  const [error, seterror] = useState();


  const createCourseReducer = useSelector(
    (state) => state.createCourseReducer
  )

  useEffect(() => {
    if (createCourseReducer) {
      setloader(createCourseReducer?.loading)
      setSuccess(createCourseReducer?.status)
      seterror(createCourseReducer?.error)
    }

  }, [createCourseReducer]);



  useEffect(() => {
    if (success === 200 && loader === false) {

      navigate('/webinar')

    }

  }, [success, loader, navigate]);

  const mentionSuggestions = [
    { text: "APPLE", value: "apple" },
    { text: "BANANA", value: "banana", url: "banana" },
    // ... other mention suggestions ...
  ];
  const [CourseeditorState, setCourseEditorState] = useState(EditorState.createEmpty());


  const CourseonEditorStateChange = (editorState) => {
    setCourseEditorState(editorState);
    const { blocks } = convertToRaw(editorState.getCurrentContent());
    setCourseDescription(blocks[0].text);
  };

  // tags input and remove
  const handleRemove = (item, removeFunc) => {
    removeFunc((prev) => prev.filter(entry => entry.value !== item.value));
  };

  const handleAdd = (item, addFunc) => {
    addFunc((prev) => [...prev, item]);
  };

  // paid or pro_bono functionality
  const handlePaidToggle = () => {
    SetIsCourseProBono(!isCourseProBono);
    if (isCourseProBono !== false) {
      setPrice(0);
      setDiscountedPrice(0);
    } else {

      setPrice(0);
      setDiscountedPrice(0);
    }
  };

  const uploadBanner = (val, pre) => {

    setcreatewebinarData((pre) => ({
      ...pre,
      course_banner: val,
    }));
  };
  const handleDateChange = (e) => {
    // Convert the local date to UTC
    const localDate = new Date(e.target.value);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();

    setcreatewebinarData((prevState) => ({
      ...prevState,
      course_start_date: utcDate,
    }));
  };

  return (
    <>


      <div id="wrapper">
        <div id="content-wrapper">
          <div id="content">
            <div className="w-100" >
              <div>
                <div className="mb-4">
                  <div className="mb-4">
                    <div className="card card-cls ebook-card">
                      <div className=" d-flex w-100  py-3 bg-white shadow-sm z-50">
                        <a className=" w-auto font-semibold btn-md me-2 ms-3 p-0 rounded-circle">
                          <span className="me-2" onClick={() => navigate("/webinar")}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={25}
                              height={25}
                              fill="currentColor"
                              className="bi bi-arrow-left-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                              />
                            </svg>
                          </span>
                        </a>

                        <div className="flex-grow">
                          <h5 className="text-title5 text-black fw-semibold mb-1" onClick={() => { navigate('webinar/create-webinar') }}>Create a webinar</h5>
                        </div>

                      </div>
                      <div className="card-body create__sessionCard ">
                        <div className="row">
                          <form action="#">
                            <div className="form-group">

                              <div className="form-group">
                                <label className="webinar__label">
                                  Title *
                                </label>
                                <input
                                  type="text"
                                  name=""
                                  placeholder="Enter webinar Title"
                                  className="form-control"
                                  onChange={(e) =>
                                    setcreatewebinarData((pre) => ({
                                      ...pre,
                                      course_title: e.target.value,
                                      course_topic: e.target.value,
                                    }))
                                  }
                                  required
                                />
                              </div>
                              <UploadImage
                                callback={uploadBanner}
                                fileName="_webinar-banner"
                                imagePath={"webinarImage/webinarBanner/"}
                                filetype={["image"]}
                              />
                              <div className="form-group">
                                <TextEditor
                                  label="webinar Description *"
                                  editorState={CourseeditorState} // Use a separate editorState if needed
                                  onEditorStateChange={CourseonEditorStateChange} // Use a separate handler if needed
                                  mentionSuggestions={mentionSuggestions} // Use separate suggestions if needed
                                />
                              </div>

                              <div className="row">
                                <div className="col"><div className="form-group">
                                  <label className="webinar__label">
                                    Maximum Seat *
                                  </label>
                                  <input
                                    type="number"
                                    webinar_seat=""
                                    placeholder=""
                                    className="form-control"
                                    onChange={(e) =>
                                      setcreatewebinarData((pre) => ({
                                        ...pre,
                                        course_seat: e.target.value,
                                      }))
                                    }
                                    required
                                  />
                                </div></div>
                                <div className="col">
                                  <div className="form-group">
                                    <label className="webinar__label">webinar Type *</label>
                                    <select className="form-control"
                                      onChange={(e) =>
                                        setcreatewebinarData((pre) => ({
                                          ...pre,
                                          course_type: e.target.value,
                                        }))
                                      }
                                    >
                                      <option value="" disabled selected>Select webinar Type</option>
                                      {expertise.map((expertise, index) => (
                                        <option key={index} value={expertise.value}>
                                          {expertise.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <label htmlFor="" className="webinar__label">
                                      webinar Start From*
                                    </label>
                                    <input
                                      type="datetime-local"
                                      placeholder=""
                                      className="form-control"
                                      onChange={handleDateChange}

                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>



                            <div className="form-group">
                              {/* Language */}
                              <div className="multi-inputs-container">
                                <label className="webinar__label">
                                  What you will learn *
                                </label>
                                <div className="multi-inputs">
                                  {what_you_will_learn.map((language) => (
                                    <div className="multi-input-design" key={language.value}>
                                      <div>{language.label}</div>
                                      <div onClick={() => handleRemove(language, setYou_learn)}>
                                        <FontAwesomeIcon
                                          className="multi-select-icon"
                                          icon={faXmark}
                                          style={{ color: 'grey' }}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <SelectMultiInputs
                                isMulti={true}
                                title="language"
                                onChangeFunc={(e) => handleAdd(e, setYou_learn)}
                                value=""
                                name="language"
                                isCreatable={true}
                                placeholder="Add at least 5-6 Tags"
                                className="basic-multi-select text-left"
                                classNamePrefix="select"
                                inputData={what_you_will_learn}
                                closeMenuOnSelect={true}
                                style={{ borderRadius: '12px', height: '3rem' }}
                              />

                              {/* Framework */}
                              <div className="multi-inputs-container">
                                <label className="webinar__label">
                                  Key Highlights*
                                </label>
                                <div className="multi-inputs">
                                  {highlights.map((framework) => (
                                    <div className="multi-input-design" key={framework.value}>
                                      <div>{framework.label}</div>
                                      <div onClick={() => handleRemove(framework, setSelectedKeyHighlights)}>
                                        <FontAwesomeIcon
                                          className="multi-select-icon"
                                          icon={faXmark}
                                          style={{ color: 'grey' }}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <SelectMultiInputs
                                isMulti={true}
                                title="framework"
                                onChangeFunc={(e) => handleAdd(e, setSelectedKeyHighlights)}
                                value=""
                                name="framework"
                                isCreatable={true}
                                placeholder="Add at least 5-6 Tags"
                                className="basic-multi-select text-left"
                                classNamePrefix="select"
                                inputData={highlights}
                                closeMenuOnSelect={true}
                                style={{ borderRadius: '12px', height: '3rem' }}
                              />

                            </div>
                            <div className="form-group">
                              <div className="form-check form-switch d-flex">
                                <input
                                  className="form-check-input"

                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  type="checkbox"
                                  checked={isCourseProBono}
                                  onChange={handlePaidToggle}

                                />
                                <label className="webinar__label" htmlFor="flexSwitchCheckDefault">
                                  {"  "} ProBono webinar
                                </label>
                              </div>

                            </div>
                            {
                              isCourseProBono === false && (
                                <>
                                  <div className="row">
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="webinar__label">
                                          Price *
                                        </label>
                                        <div className="input-group">
                                          <span className="input-group-text price___default">
                                            $
                                          </span>
                                          <input
                                            className="form-control"
                                            type="number"
                                            id="customInput"

                                            placeholder="Price"
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="webinar__label">
                                          Discounted price *
                                        </label>
                                        <div className="input-group">
                                          <span className="input-group-text price___default">
                                            $
                                          </span>

                                          <input
                                            className="form-control"
                                            type="number"
                                            id="customInput"

                                            placeholder="Discounted Price"
                                            value={discountedPrice}
                                            onChange={(e) => setDiscountedPrice(e.target.value)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            }


                            <div className="get-started-cls why_choose_ment">
                              <button
                                onClick={createwebinar}
                                type="button"
                                className="btn btn-primary"
                              >
                                Publish
                              </button>
                            </div>

                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateWebinar;
