import React, { useRef, useState } from "react";
import { expertise } from "../../../assets/data/data";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { UploadImage } from "../../UploadImage";
import { createSessionAction } from "../../../redux/actions/common";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import { useNavigate } from "react-router-dom";
const NewSession = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [time, settime] = useState("");
  const [isCreateSesssion, setisCreateSesssion] = useState(false);

  const mentor_id = REACT_USER_ID;
  const uploadBanner = (val, pre) => {
    console.log("uploadbanner called", val);

    setCreateSession((pre) => ({
      ...pre,
      banner_image: val,
    }));
  };
  const expertiseOption = [
    { label: "Software Development", value: "Software Development" },
    { label: "Design", value: "Design" },
    { label: "Product Managment", value: "Product Managment" },
    { label: "Content Writing", value: "Content Writing" },
    { label: "Data Science", value: "Data Science" },
    { label: "Marketing", value: "Marketing" },
    { label: "Product Research", value: "Product Research" },
    {
      label: "Achievement Motivation Trainer",
      value: "Achievement Motivation Trainer",
    },
    { label: "Life Coach", value: "Life Coach" },
    {
      label: "Entrepreneurial Motivation Trainer",
      value: "Entrepreneurial Motivation Trainer",
    },
  ];
  const IntersetAreaData = [
    { label: "LeaderShip ", value: "LeaderShip " },
    { label: "MentorShip ", value: "MentorShip " },
    { label: "JobSearch ", value: "JobSearch " },
    { label: "Carrer Advice ", value: "Carrer Advice " },
    { label: "Expertise ", value: "Expertise " },
  ];

  const [createSessionData, setCreateSession] = useState({
    banner_image: "",
    title: "",
    description: "",
    expertise: "",
    interest_area: "",
    max_participants: "",
    meeting_link: "",
    sesstiondate: "",
    mentor_id: mentor_id,
  });
  const timepickerChange = (e) => {
    const datetimeValue = e.target.value;
    const utcDate = new Date(datetimeValue).toISOString();

    setCreateSession((prevState) => ({
      ...prevState,
      sesstiondate: utcDate,
    }));
  };
  const createSessionbtn = (event) => {
    event.preventDefault();
    console.log(createSessionData);
    setisCreateSesssion(true);
    dispatch(createSessionAction(createSessionData));
    setCreateSession("");
    navigate("/sessions");
  };

  return (
    <>
      <div id="wrapper">
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}

            <div className="w-100" >
              <div>
                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="mb-4">
                  <div className="card card-cls ebook-card">
                    <div className="card-body create__sessionCard ">
                      <div className="row">
                        <form action="#">
                          <div className="form-group">
                            <div className="form-group">
                              <label
                                className="session__label"
                                htmlFor="country"
                              >
                                Have a Session Banner Image? Upload it now.
                              </label>
                              <UploadImage
                                callback={uploadBanner}
                                fileName="_banner-image"
                                imagePath={"sessionImage/"}
                                filetype={["image"]}
                              />
                            </div>

                            <div className="form-group">
                              <label className="session__label">
                                Session name
                              </label>
                              <input
                                type="text"
                                name=""
                                placeholder="Morning portfolio review"
                                className="form-control"
                                onChange={(e) =>
                                  setCreateSession((pre) => ({
                                    ...pre,
                                    title: e.target.value,
                                  }))
                                }
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label className="session__label">
                                Tell people what this session is about
                              </label>
                              <textarea
                                type="text"
                                name=""
                                className="form-control"
                                required
                                style={{ height: "10rem" }}
                                onChange={(e) =>
                                  setCreateSession((pre) => ({
                                    ...pre,
                                    description: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="session__label">
                                  Schedule Date
                                </label>
                                <input
                                  type="datetime-local"
                                  name=""
                                  placeholder="Choose Session DateTime"
                                  className="form-control"
                                  // onChange={(e) => {
                                  //   setDate(e.target.value);
                                  // }}
                                  onChange={timepickerChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col">
                              <div className="form-group">
                                <label className="session__label">
                                  Max attendees in this session
                                </label>
                                <input
                                  type="text"
                                  name=""
                                  placeholder="Max of 600 allowed"
                                  className="form-control"
                                  required
                                  onChange={(e) =>
                                    setCreateSession((pre) => ({
                                      ...pre,
                                      max_participants: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="session__label">
                              Meeting Link
                            </label>
                            <input
                              type="link"
                              name=""
                              placeholder="https://"
                              className="form-control"
                              required
                              onChange={(e) =>
                                setCreateSession((pre) => ({
                                  ...pre,
                                  meetinglink: e.target.value,
                                }))
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label className="session__label">
                              Select a topic of expertise
                            </label>

                            <select
                              className=" text-left form-control"
                              placeholder="Eg: Design ,Marketing"
                              style={{
                                height: "50px ",
                                width: "100%",
                                borderRadius: "13px ",
                              }}
                              required
                              value={createSessionData.expertise}
                              onChange={(e) => {
                                e.preventDefault();
                                setCreateSession((pre) => ({
                                  ...pre,
                                  expertise: e.target.value,
                                }));
                              }}
                            >
                              <option value="">Eg: Design ,Marketing</option>
                              {expertiseOption.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label className="session__label">
                              Select a session interest area
                            </label>

                            <select
                              className=" text-left form-control"
                              placeholder="Eg: Design ,Marketing"
                              style={{
                                height: "50px ",
                                width: "100%",
                                borderRadius: "13px ",
                              }}
                              required
                              value={createSessionData.interest_area}
                              onChange={(e) => {
                                e.preventDefault();
                                setCreateSession((pre) => ({
                                  ...pre,
                                  interest_area: e.target.value,
                                }));
                              }}
                            >
                              <option value="">
                                Eg: LeaderShip ,Mentorship
                              </option>
                              {IntersetAreaData.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                      <center>    <button
                            type="submit"
                            className="publish__sessionBtn px-3"
                            onClick={createSessionbtn}
                          >
                            {" "}
                            Publish Session
                          </button></center>
                          {isCreateSesssion && (
                            <p>Session Is Created Successfully !</p>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSession;
