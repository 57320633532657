import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Typography,
    Upload,
    message,
} from "antd";

import { PlusOutlined, EyeOutlined, LoadingOutlined, CameraOutlined } from '@ant-design/icons';
import BrandModal from './MainModal/BrandModal';
import { IoClose } from 'react-icons/io5';
import { TailSpin } from 'react-loader-spinner';
import { Timezones_List, compaines, countries, experience, expertise, jobSearch, languages, stdCodeData, title, useMantorshipDataSecond, userMentorshipData, userSkills } from '../../../assets/data/data';
import { currencyName } from '../Data/Data';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../../redux/actions/login';
import { REACT_USER_ID } from '../../auth/tokenProvider';
import ImgCrop from 'antd-img-crop';
import { uploadImage, uploadImageFile } from '../../../redux/actions/common';
import { WebsiteRegexPattern } from './../Services/AddNewService/WebinarMeetingLink';
import { DownloadOutlined } from '@mui/icons-material';
const { Paragraph, Text, Title } = Typography;
const { Option } = Select;
const ProfileBasicDetails = (props) => {
    const dispatch = useDispatch()
    const { form, profile, loading, setLoading } = props;



    const [isProBono, setIsProBono] = useState(true)
    const [minimumAmount, setMinimumAmount] = useState(0);
    const [formEdit, setFormEdit] = useState([]);
    const [themingModal, setThemingModal] = useState(false);
    const [socialUrls, setSocialUrls] = useState([
        {
            name: "linkedin",
            display: "linkedin",
            link: "",
        },
        {
            name: "instagram",
            display: "instagram",
            link: "",
        },
        {
            name: "youtube",
            display: "youtube",
            link: "",
        },
        {
            name: "twitter",
            display: "twitter",
            link: "",
        },
        {
            name: "github",
            display: "github",
            link: "",
        },
        {
            name: "medium",
            display: "medium",
            link: "",
        },
        {
            name: "tiktok",
            display: "tiktok",
            link: "",
        },
        {
            name: "custom",
            display: "custom",
            link: "",
        },
    ]);


    const onProfileSave = async (fields) => {

        let postObj = {
            ...fields,
            user_id: REACT_USER_ID,
            social_links: [],
        };

        if (fields?.socials?.length > 0) {
            fields?.socials.forEach((social) => {
                postObj?.social_links.push({
                    name: social?.name,
                    link: social?.link,
                });
            });
        }

        if (fields?.is_pro_bono === true) {
            postObj.price_per_session = 0;
            postObj.inr_price = 0;
        }

        try {
            console.log("onProfileSave", postObj)
            dispatch(updateProfile(postObj));
            message.success("Profile Updated Successfully!", 4);


        } catch (error) {
            message.error("Some Error Occured! Please try again!", 4);
        }
    };


    const updateProfileReducer = useSelector((state) => {
        return state.updateProfileReducer;
    });
    useEffect(() => {
        const { data, loading, error, status } = updateProfileReducer
        setLoading(loading)
    }, [updateProfileReducer])


    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const initialValues = {
        user_id: REACT_USER_ID,
        name: profile?.name,
        timezone: profile?.timezone ? profile?.timeZone : localTimezone,
        language: profile?.language,
        price_per_session: profile?.price_per_session,
        career_advice: profile?.career_advice,
        company_or_school: profile?.company_or_school,
        country: profile?.country,
        experience: profile?.experience,
        experties: profile?.experties,
        job_search: profile?.job_search,
        leadership: profile?.leadership,
        mentorship: profile?.mentorship,
        skills: profile?.skills,
        story: profile?.story,
        title: profile?.title,
        is_pro_bono: profile?.is_pro_bono,
        inr_price: profile?.inr_price,
        // socials:
        //     profile?.social_links?.filter(
        //         (social) => String(social?.name).toLowerCase() !== "website"
        //     ) || [],
        socials: profile?.social_links || [],
    };
    const [Mainfile, setMainFile] = useState(null)
    const [Imageloading, setImageLoading] = useState(false);

    const beforeUpload = (file) => {
        console.log("File", file);
        const fileName = "_profile-photo";
        const imagePath = "profilePhoto/";
        let imageName = imagePath + REACT_USER_ID + fileName;
        let extension = file?.name?.split('.').pop().toLowerCase();

        setMainFile(file);
        dispatch(uploadImage({ documentName: imageName, format: extension }));

        // Remove curly braces around extension
        const imageNameEx = 'profilePhoto/' + REACT_USER_ID + '_profile-photo' + '.' + extension;

        dispatch(updateProfile({
            user_id: REACT_USER_ID, profile_photo: imageNameEx
        }));

        return false; // Prevent automatic upload
    };

    const imageURLReqData = useSelector(
        (state) => state.uploadImageReducer
    );

    useEffect(() => {
        const { data, loading, status, error } = imageURLReqData
        setImageLoading(loading)
        if (data !== undefined && data?.url) {
            const reqData = { url: data?.url, fileData: Mainfile };
            dispatch(uploadImageFile(reqData));

            message.success("profile image uploaded")
        }
    }, [dispatch, imageURLReqData]);




    const uploadButton = (
        <div>
            {Imageloading ? <LoadingOutlined /> : <CameraOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

 

    

   

    return (
        <>
            {loading ? (
                <div className="loader open_sessions_loader ">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            ) : (null)}
            <Row >

                <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 16, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                >

                    <Form
                        onFinish={onProfileSave}
                        form={form}
                        initialValues={initialValues}
                        onValuesChange={(val) => {
                            const key = Object.keys(val)[0];
                            initialValues[key] !== val[key]
                                ? formEdit?.indexOf(key) < 0 && setFormEdit([...formEdit, key])
                                : formEdit?.indexOf(key) > -1 &&
                                setFormEdit(formEdit.filter((item) => item !== key));
                        }}
                        layout="vertical"
                        scrollToFirstError={true}
                        requiredMark={false}
                        className="profile-details-form"
                    >


                        <Form.Item style={{ marginBottom: 0 }} label="Profile Photo">
                            <ImgCrop rotationSlider
                                modalTitle="Crop/Rotate Image"
                                modalClassName="profile-img-crop-modal"

                                quality={0.9}
                                modalOk="Save"
                            >
                                <Upload
                                    accept="image/*"
                                    name="avatar"
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    showUploadList={false}

                                    beforeUpload={beforeUpload}
                                >

                                    {profile?.profile_photo ? (
                                        <div style={{ position: 'relative' }}>
                                            <Image
                                                src={`${process.env.REACT_APP_IMAGES_BASE_URL}${profile?.profile_photo}`}
                                                alt="avatar"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                            
                                        </div>
                                    ) : (
                                        uploadButton
                                    )}

                                </Upload>
                            </ImgCrop>

                        </Form.Item>
                        {/* image previe */}
                        

                        {/* Display Name */}
                        <Form.Item
                            label="Display Name"
                            name="name"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "name is required.",
                                },
                                {
                                    whitespace: true,
                                    message: "Name cannot be empty!",
                                },
                                {
                                    min: 3,
                                    message: "This is too short!",
                                },
                                {
                                    max: 40,
                                    message: "Max 40 characters allowed!",
                                },
                            ]}
                            required
                        >
                            <Input placeholder="This is the name people will see on your profile" />
                        </Form.Item>
                        {/* proBono */}
                        <Form.Item
                            name='is_pro_bono'
                            label="Is proBono"
                            valuePropName="checked"
                            labelCol={{
                                xs: { span: 20, order: 2 },
                                md: { span: 12, order: 2 },
                                order: 2,
                            }}
                            wrapperCol={{
                                xs: { span: 3, order: 1 },
                                md: { span: 3, order: 1 },
                                order: 1,
                            }}
                            className="switch-input"
                            style={{ marginBottom: 10 }}
                        >
                            <Switch
                                size={"40"}
                                className="payWhatYouWantRef"
                                checkedChildren="yes"
                                unCheckedChildren="no"
                                defaultChecked={isProBono}
                                onChange={(checked) => {
                                    setIsProBono(checked);
                                }
                                }
                            />
                        </Form.Item>

                        {!isProBono && (
                            <Card>
                                <Row align="middle" justify="space-between" gutter={12}>

                                    <Col span={12}>
                                        <Form.Item
                                            name={currencyName === "INR" ? 'inr_price' : "price_per_session"}
                                            label="Price"
                                            className="title-fields"
                                            rules={[
                                                {
                                                    min: minimumAmount + 1,
                                                    type: "number",
                                                    message: "Price should be more then 0",
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                name="inr_price"
                                                style={{ width: "100%" }}
                                                className="title-input"
                                                prefix={currencyName === "INR" ? '₹' : "$"}
                                                step={5}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        )}

                        {/* country */}
                        <Form.Item
                            name="country"
                            label="Country"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Select Country!",
                                },
                            ]}
                        >
                            <Select
                                size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{

                                    width: "100%",
                                    borderRadius: "13px ",
                                }}
                            >
                                {countries.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* language */}
                        <Form.Item
                            name="language"
                            label="Language"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Select at least one language!",
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{

                                    width: "100%",
                                    borderRadius: "13px ",
                                }}
                            >
                                {languages.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Divider />
                        {/* title */}
                        <Form.Item
                            name="title"
                            label="Job Title"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Select company_or_school!",
                                },
                            ]}
                        >
                            <Select

                                size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{

                                    width: "100%",
                                    borderRadius: "13px ",
                                }}
                            >
                                {title.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* experties */}
                        <Form.Item
                            name="experties"
                            label="Experties"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Select at least one experties!",
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{

                                    width: "100%",
                                    borderRadius: "13px ",
                                }}
                            >
                                {expertise.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* experience */}
                        <Form.Item
                            name="experience"
                            label="Experience"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Select experience!",
                                },
                            ]}
                        >
                            <Select
                                size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{

                                    width: "100%",
                                    borderRadius: "13px ",
                                }}
                            >
                                {experience.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* company_or_school */}
                        <Form.Item

                            name="company_or_school"
                            label="Company"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Select company_or_school!",
                                },
                            ]}
                        >
                            <Select

                                maxCount={1}
                                size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{

                                    width: "100%",
                                    borderRadius: "13px ",
                                }}
                            >
                                {compaines.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Divider />
                        {/* leadership */}
                        <Form.Item
                            name="leadership"
                            label="Leadership"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Select at least one leadership!",
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{

                                    width: "100%",
                                    borderRadius: "13px ",
                                }}
                            >
                                {userMentorshipData.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* mentorship */}
                        <Form.Item
                            name="mentorship"
                            label="Mentorship"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Select at least one mentorship!",
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{

                                    width: "100%",
                                    borderRadius: "13px ",
                                }}
                            >
                                {useMantorshipDataSecond.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* job_search */}
                        <Form.Item
                            name="job_search"
                            label="Job Search"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Select at least one job_search!",
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{

                                    width: "100%",
                                    borderRadius: "13px ",
                                }}
                            >
                                {jobSearch.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* skills */}
                        <Form.Item
                            name="skills"
                            label="Skills"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Select at least one skills!",
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{

                                    width: "100%",
                                    borderRadius: "13px ",
                                }}
                            >
                                {userSkills.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* { Story} */}
                        <Form.Item
                            name="story"
                            label="Your mentorpal intro (Required)"
                            className="title-fields name-field"
                            rules={[
                                {
                                    required: true,
                                    message: "Intro is required.",
                                },
                                {
                                    whitespace: true,
                                    message: "Intro cannot be empty!",
                                },
                                {
                                    min: 1,
                                    message: "This is too short!",
                                },
                                {
                                    max: 1240,
                                    message: "Max 1240 characters allowed!",
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder="Tell us more about yourself, your interests and your experience"
                                autoSize={{ minRows: 4, maxRows: 8 }}
                            />
                        </Form.Item>

                        {/*  */}

                        <Divider />


                        {/* <Title
                            level={5}
                            style={{ fontWeight: 700, marginBottom: 20, marginTop: 45 }}
                        >
                            Display
                        </Title> */}

                        {/* Profile Theming */}
                        {/* <Row>
                            <Col span={24}>
                                <Row justify="space-between" align="middle">
                                    <Paragraph className="service-title">Profile theme</Paragraph>
                                    <Space size={8}>
                                        <Paragraph
                                            className="profile-color-selector"
                                            onClick={() => {
                                                setThemingModal(true);
                                            }}
                                        >
                                            <span
                                                style={{
                                                    backgroundColor:
                                                        profile?.theme_details?.theme || "#D5534D",
                                                }}
                                            />
                                        </Paragraph>
                                        <Button
                                            type="text"
                                            onClick={() => {
                                                setThemingModal(true);
                                            }}
                                        >
                                            <Paragraph className="edit-btn">Edit</Paragraph>
                                        </Button>
                                    </Space>
                                </Row>
                                <Paragraph className="desc">
                                    Customize your profile to your brand
                                </Paragraph>
                            </Col>
                            <Divider />
                        </Row> */}



                        {/* Social Links */}
                        <Title
                            level={5}
                            style={{ fontWeight: 700, marginBottom: 20, marginTop: 45 }}
                        >
                            Social Links
                        </Title>

                        <Form.List name="socials">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Input.Group compact key={key} className="socials-input">
                                            <Form.Item
                                                {...restField}
                                                name={[name, "name"]}
                                                required
                                                className="socials-input-options"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "required",
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Select">
                                                    {socialUrls?.map((social) => (
                                                        <Select.Option
                                                            key={social?.name}
                                                            value={social?.display}
                                                        >
                                                            {social?.display}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "link"]}
                                                required
                                                className="socials-input-link"
                                                rules={[
                                                    { required: true, message: "Link is required" },
                                                    {
                                                        pattern: WebsiteRegexPattern,
                                                        message: "Please enter a valid link",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder={"Add social url"} allowClear />
                                            </Form.Item>
                                            <IoClose
                                                fontSize={18}
                                                color="#141414"
                                                className="remove-social"
                                                onClick={() => remove(name)}
                                            />
                                        </Input.Group>
                                    ))}
                                    {fields?.length < socialUrls?.length && (
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add social link
                                            </Button>
                                        </Form.Item>
                                    )}
                                </>
                            )}
                        </Form.List>

                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 0 }}>
                                <Form.Item>
                                    <Button
                                        htmlType="submit"
                                        className="btn-dark"
                                        loading={loading}
                                        block
                                        disabled={loading}
                                        style={{ marginTop: 20 }}

                                    >
                                        Save Changes
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>

                {themingModal && (
                    <BrandModal
                        visible={themingModal}
                        setVisible={setThemingModal}

                    />
                )}


            </Row>
        </>
    );
}

export default ProfileBasicDetails;
