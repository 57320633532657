import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import {
    Alert,
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Flex,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
    TimePicker,
    Tooltip,
    Typography,
    Upload,
    message,
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { Suspense, useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";

import { AddServiceConfig, AllCountriesList } from "../../Data/Data";
import WebinarMeetingLink from "../AddNewService/WebinarMeetingLink";
import AddQuestion from './Modal/AddQuestion';
import MultipleSessionsModal from "../AddNewService/MultipleSessionsModal/MultipleSessionsModal";
import DeleteConfirmation from "../PropsComponent/DeleteConfirmation";
import PackageServices from "./Modal/PackageService";
import { PricingDisplay } from "./Modal/PricingDisplay";
import { currencyName } from './../../Data/Data';
import { deleteMentorCourseAction } from "../../../../redux/actions/course";
import { useDispatch, useSelector } from "react-redux";




const { Paragraph, Title, Text } = Typography;




const ServiceForm = (props) => {
    const {
        type,
        data,
        initialValues,
        handleSubmit,
        ServiceEditForm,
        buttonText,
        questionduration,
        questionHeader,
        setQuestionduration,
        activeQuestion,
        editQuestion,
        questionType,
        questionModal,
        setQuestionModal,
        onShowModal,
        setFormEdit,
        formEdit,
        setNavigationType,
        setThumbnailUrl,
        uploadList,
        thumbnailList,
        setUplaodList,
        setThumbnailList,
        setPackageService,
        addQuestion,
        setaddQuestion

    } = props;
    const [serviceType] = useState(type);
    console.log("ServiceEditForm", ServiceEditForm)
    console.log("initialValues", initialValues)
    const replyTime = [
        { value: "24", label: "1 Day" },
        { value: "48", label: "2 Days" },
        { value: "72", label: "3 Days" },
        { value: "168", label: "1 Week" },
        { value: "0", label: "No limit" },
    ];

    const package_validity = [
        { label: "No limit", value: 0 },
        { label: "1 month", value: 1 },
        { label: "2 months", value: 2 },
        { label: "3 months", value: 3 },
        { label: "6 months", value: 6 },
        { label: "12 months", value: 12 },
    ];


    const [CountryEditForm] = Form.useForm();

    const [requestInProgress, setRequestInProgress] = useState(false);
    const [addedService, setAddedService] = useState([]);
    const [askServices, setAskServices] = useState(false);
    const [cumulativePrice, setCumulativePrice] = useState(data?.total_charge);

    const duplicate = false
    const [countryModalShown, setCountryModalShown] = useState(false);

    const [isBookingLimitVisibile, setBookingLimitVisibile] = useState(
        initialValues?.bookings_limit > 0 ? true : false
    );
    const [isMinimumAmountVisible, setMinimumAmountVisible] = useState(
        initialValues?.has_flexible_charge
    );
    const [minimumAmount, setMinimumAmount] = useState(
        initialValues?.charge
    );

    const [originalAmount, setOriginalAmount] = useState(
        initialValues?.original_charge
    );

    const [thumbnailUploadInProgress, setThumbnailUploadInProgress] =
        useState(false);
    const [coverUploadInProgress, setCoverUploadInProgress] = useState(false);
    // const [basicTour, setBasicTour] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);

    const handleDurationChange = (e) => {
        setQuestionduration(e?.target?.value);
    };
    const [multipleSessionsModal, setMultipleSessionsModal] =
        useState(false);

    const onBookingLimitChange = (checked) => {

        setBookingLimitVisibile(checked);
    };


    const disabledDate = (current) => {
        // Disable dates before today
        const currentDate = new Date();
        return current && current < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    };


    const addService = (id, service) => {
        const val = addedService?.filter((item) =>
            item?.id === id ? true : false
        );

        if (val?.length > 0) {
            if (service?.type === 1 || service?.type === 2 || val[0]?.count === 0) {
                const count = val[0]?.count;
                setCumulativePrice(cumulativePrice + service?.charge);
                setAddedService(
                    addedService?.map((item) =>
                        item?.id === id
                            ? { id: id, count: count + 1, service: service }
                            : item
                    )
                );
            } else {
                message.error("Webinar and digital product can only be added once");
            }
        } else {
            setCumulativePrice(cumulativePrice + service?.charge);
            setAddedService([
                ...addedService,
                { id: id, count: 1, service: service },
            ]);
        }
    };

    const removeService = (id, service) => {
        const val = addedService?.filter((item, idx) =>
            item?.id === id ? true : false
        );
        setCumulativePrice(cumulativePrice - service?.charge);
        if (val[0]?.count > 0) {
            const count = val[0]?.count;
            setAddedService(
                addedService?.map((item, idx) =>
                    item?.id === id
                        ? { id: id, count: count - 1, service: service }
                        : item
                )
            );
        } else {
            setAddedService(addedService.filter((item) => item.id !== id));
        }
    };

    const onValuesChanged = (field) => {
        // Handle Form Values Changed Here
        if (field?.is_hidden) {

        }
        if (field?.charge == 0) {
            ServiceEditForm.setFieldValue("has_flexible_charge", true);
            ServiceEditForm.setFieldValue(
                "suggested_price",
                ServiceEditForm?.getFieldValue("suggested_price")
                    ? ServiceEditForm?.getFieldValue("suggested_price")
                    : field?.charge
                        ? Math.floor(field?.charge * 1.25)
                        : currencyName == "INR"
                            ? 200
                            : 20
            );
            setMinimumAmountVisible(true);
        }
        if (field?.charge >= 0) {
            setMinimumAmount(field?.charge || 0);
            setOriginalAmount(
                originalAmount > field?.charge ? originalAmount : field?.charge * 1.2
            );
        }
        if (field?.original_charge) {
            setOriginalAmount(
                field?.original_charge > minimumAmount
                    ? field?.original_charge
                    : minimumAmount * 1.2
            );
        }

        if (field?.bundle_price >= 0) {
            setMinimumAmount(field?.bundle_price || 0);
        }
        if (field?.has_flexible_charge === true) {
            setMinimumAmountVisible(true);
            ServiceEditForm.setFieldValue(
                "suggested_price",
                ServiceEditForm?.getFieldValue("suggested_price")
                    ? ServiceEditForm?.getFieldValue("suggested_price")
                    : minimumAmount
                        ? Math.floor(minimumAmount * 1.25)
                        : currencyName == "INR"
                            ? 200
                            : 20
            );

        }
        if (field?.has_flexible_charge === false) {

            setMinimumAmountVisible(false);
        }
    };





    const onUploadThumbnail = async ({
        file,
        onSuccess,
        onError,
    }) => {
        setThumbnailUploadInProgress(true);
        // await UploadToS3(file).then(onSuccess).catch(onError);
    };

    const onUploadCover = async ({
        file,
        onSuccess,
        onError,
    }) => {
        setCoverUploadInProgress(true);
        // await UploadToS3(file).then(onSuccess).catch(onError);
    };

    const handleThumbnailChange = async ({
        fileList: newFileList,
    }) => {

        const list = newFileList.map((item) => {
            return {
                uid: item?.uid,
                name: item?.name,
                status: item?.status,
                url: item?.response?.Location,
            };
        });
        setThumbnailList(list);
        setThumbnailUploadInProgress(false);
        setThumbnailUrl(list[0]?.url || "");
    };

    const handleCoverChange = async ({
        fileList: newFileList,
    }) => {

        const list = newFileList.map((item) => {
            return {
                uid: item?.uid,
                name: item?.name,
                status: item?.status,
                url: item?.url || item?.response?.Location,
            };
        });
        setUplaodList(list);
        setCoverUploadInProgress(false);
    };

    useEffect(() => {
        setPackageService(addedService);
    }, [addedService]);



    useEffect(() => {
        if (serviceType === 4 || serviceType === 6) {
            const obj = data?.package_services?.map((ser) => {
                return {
                    id: ser?.service?.id,
                    count: ser?.count,
                    service: ser?.service,
                };
            });
            setAddedService(obj);
        }
    }, [data]);

    const onFieldsChange = (fields) => { };

    const submitCountry = async (fields) => {
        setRequestInProgress(true);
        const {
            country: { value: country_code },
        } = fields;
        const payload = {
            country: country_code,
        };
        try {
            setCountryModalShown(false);
            setRequestInProgress(false);
            ServiceEditForm.setFieldValue("ppp_enabled", true);
            message.success(`${"Country Updated Successfully!"}`, 4);
        } catch (error) {
            setCountryModalShown(false);
            setRequestInProgress(false);
            message.error("Some Error Occured in updating!", 4);
        }
    };
    const dispatch = useDispatch()
    const deleteProductReducer = useSelector((state) => state.deleteMentorCourseReducer);


    const handleDelete = (id) => {
        console.log("id", id)
        dispatch(deleteMentorCourseAction(id))

        setTimeout(() => {
            const { data, loading, error } = deleteProductReducer;

            if (!loading && data === undefined && error === undefined) {
                message.success("Service deleted");
            } else if (!loading && data !== undefined && error !== undefined) {
                message.error("Something went wrong");
            }
        }, deleteProductReducer?.loading);



    };

    const UpdateCountryModal = () => (
        <Modal
            open={countryModalShown}
            destroyOnClose
            onCancel={() => {
                setCountryModalShown(false);
                setRequestInProgress(false);
            }}
            title="Confirm your country"
            footer={null}
            width={390}
            centered
        >
            <Form
                form={CountryEditForm}
                initialValues={initialValues}
                requiredMark={false}
                layout="vertical"
                onFinish={submitCountry}
                onFieldsChange={onFieldsChange}
                style={{ marginTop: "30px" }}
            >
                <Form.Item
                    name="country"
                    // label="Country"
                    rules={[
                        {
                            required: true,
                            message: "Please select a country",
                        },
                    ]}
                >
                    <Select
                        options={AllCountriesList}
                        placeholder="Select a Country"
                        labelInValue
                        style={{ width: "100%" }}
                        fieldNames={{ label: "country", value: "code" }}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.country ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                    />
                </Form.Item>
                {/* Submit */}
                <Button
                    className="btn-dark save-btn"
                    htmlType="submit"
                    block
                    disabled={requestInProgress}
                    loading={requestInProgress}
                >
                    {duplicate ? "Create Service" : "Save Changes"}
                </Button>
            </Form>
        </Modal>
    );


    return (
        <>
            <Form
                style={{ padding: "0 7%" }}
                form={ServiceEditForm}
                name="basic"
                layout="vertical"
                initialValues={initialValues}
                requiredMark={false}
                scrollToFirstError={{
                    behavior: (actions) =>
                        // list is sorted from innermost (closest parent to your target) to outermost (often the document.body or viewport)
                        actions.forEach(({ el, top }) => {
                            // implement the scroll anyway you want
                            el.scrollTop = top - 90;
                        }),
                    scrollMode: "if-needed",
                }}
                className="service-edit-form pb-4"
                onValuesChange={(val) => {
                    onValuesChanged(val);
                    const key = Object.keys(val)[0];
                    initialValues[key] !== val[key] && key !== "description"
                        ? formEdit?.indexOf(key) < 0 && setFormEdit([...formEdit, key])
                        : formEdit?.indexOf(key) > -1 &&
                        setFormEdit(formEdit.filter((item) => item !== key));
                }}
                onFinish={(val) => {
                    [4].includes(serviceType) && cumulativePrice < val?.bundle_price
                        ? message?.error("Price should be less than cumulative Price ")
                        : handleSubmit(val);
                }}
            >
                {askServices && (
                    <PackageServices
                        open={askServices}
                        onClose={() => setAskServices(false)}
                        setPackageService={setPackageService}
                        setAddedService={setAddedService}
                        setCumulativePrice={setCumulativePrice}
                        addedService={addedService}
                        type={serviceType}
                    />
                )}
                {/* Title */}
                <Form.Item
                    label={AddServiceConfig?.title?.label}
                    name={AddServiceConfig?.title?.name}
                    rules={[...AddServiceConfig.title.rules]}
                    className="title-fields"
                    required
                    style={{ marginTop: "32px" }}
                >
                    <Input
                        placeholder={AddServiceConfig?.title?.placeholder}
                        className="title-input"
                    />
                </Form.Item>

                {/* Custom Short Description */}
                <Form.Item
                    label={AddServiceConfig?.short_description?.label}
                    name={AddServiceConfig?.short_description?.name}
                    rules={[...AddServiceConfig.short_description.rules]}
                    className="title-fields"
                    required
                >
                    <Input
                        placeholder={AddServiceConfig?.short_description?.placeholder}
                    />
                </Form.Item>

                {/* Description */}
                <Form.Item
                    label={AddServiceConfig?.description?.label}
                    name={AddServiceConfig?.description?.name}
                    rules={[...AddServiceConfig.description.rules]}
                    className="title-fields"
                    required
                >
                    <Input
                        placeHolder={AddServiceConfig?.description?.placeholder}
                        onChange={() => { }}
                    />
                </Form.Item>

                {(serviceType == 1 || serviceType == 3) && (
                    <Form.Item
                        label={AddServiceConfig?.custom_call_duration?.label}
                        name={AddServiceConfig?.custom_call_duration?.name}
                        rules={AddServiceConfig?.custom_call_duration?.rules}
                        className="title-fields"
                        required
                    >
                        <InputNumber
                            min={5}
                            style={{ width: "100%" }}
                            className="service-input text-base"
                            step={15}
                        />
                    </Form.Item>
                )}

                {/* Package Details */}
                {(serviceType === 4 || serviceType === 6) && (
                    <Title level={5} className="field-heading">
                        {data?.is_subscription_enabled
                            ? "Subscription offerings"
                            : "Package"}
                    </Title>
                )}

                {(serviceType === 4 || serviceType === 6) && (
                    <>
                        <Col className="service-list">
                            {addedService?.map((pack_ser, idx) => (
                                <Row
                                    justify="space-between"
                                    align="middle"
                                    className="service-details"
                                    key={pack_ser?.id || idx}
                                >
                                    <Col>
                                        <Paragraph className="service-title">
                                            {pack_ser?.service?.title}
                                        </Paragraph>
                                        {pack_ser?.service?.type === 5 ? (
                                            <Paragraph className="details">
                                                {currencyName}
                                                {pack_ser?.service?.charge}
                                            </Paragraph>
                                        ) : (
                                            <Paragraph className="details">
                                                {currencyName}
                                                {pack_ser?.service?.charge}
                                                {[1, 3].indexOf(pack_ser?.service?.type) > -1 &&
                                                    ` | ${pack_ser?.service?.duration}mins`}
                                            </Paragraph>
                                        )}
                                    </Col>
                                    <Paragraph>
                                        {pack_ser?.count > 0 ? (
                                            <Row
                                                className="selected-action"
                                                justify="space-around"
                                                align="middle"
                                            >
                                                <Text
                                                    onClick={() =>
                                                        removeService(
                                                            pack_ser?.service?.id,
                                                            pack_ser?.service
                                                        )
                                                    }
                                                    className="action-text cursor-hover"
                                                >
                                                    -
                                                </Text>
                                                <Text className="action-text ">{pack_ser?.count}</Text>
                                                <Text
                                                    onClick={() =>
                                                        addService(pack_ser?.service?.id, pack_ser?.service)
                                                    }
                                                    className="action-text cursor-hover"
                                                >
                                                    +
                                                </Text>
                                            </Row>
                                        ) : (
                                            <Row
                                                onClick={() =>
                                                    addService(pack_ser?.service?.id, pack_ser?.service)
                                                }
                                                className="action-btn cursor-hover"
                                                justify="center"
                                                align="middle"
                                            >
                                                <Paragraph className="action-text">+ Add</Paragraph>
                                            </Row>
                                        )}
                                    </Paragraph>
                                </Row>
                            ))}
                        </Col>
                        <Button
                            onClick={() => setAskServices(true)}
                            className="pack-service"
                        >
                            + Add Service
                        </Button>
                    </>
                )}

                {/* Webinar Date & Time */}
                {serviceType === 3 && (
                    <>

                        <Row gutter={{ xs: 0, md: 12 }}>
                            {/* Webinar: Date */}
                            <Col xs={24} md={12}>
                                <Form.Item {...AddServiceConfig?.date} className="fields">
                                    <DatePicker
                                        inputReadOnly
                                        initialValue={data?.specific_date ? new Date(data.specific_date) : undefined}
                                        placeholder={AddServiceConfig?.date?.placeholder}
                                        className="full-width"
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                            </Col>
                            {/* Webinar: Time */}
                            <Col xs={24} md={12}>
                                <Form.Item {...AddServiceConfig?.time} className="fields" >
                                    <TimePicker
                                        initialValue={data?.specific_date ? new Date(data.specific_date) : undefined}

                                        inputReadOnly
                                        placeholder={AddServiceConfig?.time?.placeholder}
                                        className="full-width"
                                        minuteStep={15}
                                        showNow={false}
                                        use12Hours
                                        format="h:mm A"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Col xs={24} md={24} style={{ marginBottom: "12px" }}>
                            {(!ServiceEditForm?.getFieldValue("cohort_no_of_sessions") ||
                                ServiceEditForm?.getFieldValue("cohort_no_of_sessions") ===
                                1) ? (
                                <>
                                </>
                            ) : (
                                <Button
                                    className="btn-gray"
                                    onClick={() => {

                                        setMultipleSessionsModal(true);
                                    }}
                                >
                                    {ServiceEditForm?.getFieldValue("cohort_no_of_sessions") > 1
                                        ? `+ ${ServiceEditForm?.getFieldValue(
                                            "cohort_no_of_sessions"
                                        )} sessions`
                                        : `+ Create multiple sessions`}
                                </Button>
                            )}
                        </Col>
                    </>
                )}

                <Divider style={{ margin: "4px 0 32px", float: "left" }} />

                <Title level={5} className="field-heading">
                    Pricing
                </Title>

                {(serviceType === 4 || serviceType === 6) && (
                    <Row align="middle" justify="space-between" gutter={12}>
                        {/* Package Cumulative Price */}
                        <Col span={12}>
                            <Form.Item
                                label={
                                    serviceType === 6
                                        ? `Normal price ( / month)`
                                        : `Cumulative pricing`
                                }
                                className="title-fields"
                                required
                            >
                                <Input
                                    type="number"
                                    className="title-input"
                                    min={1}
                                    disabled
                                    prefix={currencyName === "INR" ? '₹' : "$"}
                                    value={cumulativePrice}
                                />
                            </Form.Item>
                        </Col>

                        {/* Package Bundle Price */}
                        <Col span={12}>
                            <Form.Item
                                name="bundle_price"
                                label={
                                    serviceType === 6
                                        ? `Subscription price ( /month)`
                                        : `Price of Bundle`
                                }
                                className="title-fields"
                                required
                                rules={[
                                    {
                                        max: serviceType === 6 ? null : cumulativePrice || 0,
                                        type: "number",
                                        message: `Maximum price is ${cumulativePrice || 0}`,
                                    },
                                    {
                                        min: serviceType === 6 ? 1 : 0,
                                        type: "number",
                                        message: `Subscription cannot be free`,
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{ width: "100%" }}
                                    className="title-input"
                                    prefix={currencyName === "INR" ? '₹' : "$"}
                                    min={0}
                                    step={5}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                {/* Amount */}
                {![4, 6].includes(serviceType) && (
                    <Form.Item
                        label={`Amount (${currencyName})`}
                        name={AddServiceConfig?.price?.name}
                        rules={AddServiceConfig?.price?.rules}
                        className="title-fields"
                        required
                    >
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                            className="service-input text-base"
                            step={5}
                            addonBefore={currencyName}
                        />
                    </Form.Item>
                )}

                {/* Pay What you want: disabled for subscriptions */}

                {!data?.is_subscription_enabled && (
                    <Form.Item
                        {...AddServiceConfig?.has_flexible_charge}
                        valuePropName="checked"
                        labelCol={{
                            xs: { span: 20, order: 2 },
                            md: { span: 12, order: 2 },
                            order: 2,
                        }}
                        wrapperCol={{
                            xs: { span: 3, order: 1 },
                            md: { span: 3, order: 1 },
                            order: 1,
                        }}
                        className="switch-input"
                        style={{ marginBottom: 10 }}
                    >
                        {minimumAmount == 0 && serviceType !== 1 ? (
                            <Switch
                                size="default"
                                className="payWhatYouWantRef"
                                disabled={minimumAmount == 0 ? true : false}
                            />
                        ) : data?.show_discount_code ? (
                            <Tooltip
                                placement="top"
                                title={
                                    data?.show_discount_code
                                        ? "Please delete discount codes to enable pay what you want"
                                        : ""
                                }
                            >
                                <Switch
                                    size="default"
                                    className="payWhatYouWantRef"
                                    disabled={
                                        minimumAmount == 0 && serviceType !== 1
                                            ? true
                                            : data?.show_discount_code
                                                ? true
                                                : false
                                    }
                                />
                            </Tooltip>
                        ) : (
                            <Switch
                                size="default"
                                className="payWhatYouWantRef"
                                disabled={
                                    minimumAmount == 0 && serviceType !== 1 ? true : false
                                }
                            />
                        )}
                    </Form.Item>
                )}

                {/* Minimum Amount */}
                {isMinimumAmountVisible && (
                    <Card>
                        <Row align="middle" justify="space-between" gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    name="charge"
                                    label="Minimum amount"
                                    className="title-fields"
                                    required
                                >
                                    <InputNumber
                                        name="charge"
                                        style={{ width: "100%" }}
                                        className="title-input"
                                        disabled
                                        prefix={currencyName === "INR" ? '₹' : "$"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="suggested_price"
                                    label="Suggested amount"
                                    className="title-fields"
                                    rules={[
                                        {
                                            min: minimumAmount + 1,
                                            type: "number",
                                            message: "Suggested price should be more than price",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        name="suggested_price"
                                        style={{ width: "100%" }}
                                        className="title-input"
                                        prefix={currencyName === "INR" ? '₹' : "$"}
                                        step={5}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                )}

                <Alert
                    style={{ marginTop: 10 }}
                    closable
                    type="warning"
                    message={
                        <Text>
                            {serviceType === 4
                                ? "💡 Pro tip: Try giving a minimum 10% discount on package for better conversions. Go to Advanced for discount codes, dynamic pricing and more."
                                : serviceType === 6
                                    ? "💡 Tip: Try giving a minimum of 10% discount on subscription  for better conversion"
                                    : "💡 Pro tip: Go to Advanced for discount codes, dynamic pricing and more"}
                        </Text>
                    }
                />



                {serviceType === 5 && (
                    <Form.Item
                        {...AddServiceConfig?.images}
                        className="title-fields"
                        required
                    >
                        <Divider />
                        <Title level={5} className="field-heading field-no-margin">
                            Cover Image(s)
                        </Title>
                        <Paragraph className="field-sub-heading">
                            Displayed on the service page, helps customers understand the
                            value of what they’re buying
                        </Paragraph>
                        <Col xs={24} md={20} style={{ marginTop: "24px" }}>
                            <ImgCrop
                                aspect={3 / 2}
                                aspectSlider
                                modalTitle="Crop/Rotate Image"
                                modalClassName="profile-img-crop-modal"
                                rotationSlider
                                quality={0.9}
                                modalOk="Save"
                            >
                                <Upload
                                    listType="picture"
                                    onChange={handleCoverChange}
                                    customRequest={onUploadCover}
                                    openFileDialogOnClick={uploadList?.length >= 7 ? false : true}
                                    maxCount={7}
                                    beforeUpload={(file) => {
                                        if (file?.size > 5000000) {
                                            message.error(
                                                "Currently we support file sizes upto 5MB. If you wish to upload a larger file, please email us the file and service URL at support@mentorpal.ai",
                                                5
                                            );
                                            return Upload.LIST_IGNORE;
                                        } else return true || Upload.LIST_IGNORE;
                                    }}
                                    accept=".jpg, .jpeg, .png, .svg"
                                    defaultFileList={[...uploadList]}
                                >
                                    <Flex align="center" gap={24}>
                                        <Tooltip
                                            title={
                                                uploadList?.length >= 7
                                                    ? "Max 7 images can be uploaded"
                                                    : undefined
                                            }
                                        >
                                            <Button
                                                icon={<UploadOutlined />}

                                                disabled={
                                                    coverUploadInProgress || uploadList?.length >= 7
                                                }
                                            >
                                                Upload
                                            </Button>
                                        </Tooltip>
                                        <Paragraph className="thumbnail-desc">
                                            Recommended size 1280x780px
                                        </Paragraph>
                                    </Flex>
                                </Upload>
                            </ImgCrop>
                        </Col>
                    </Form.Item>
                )}

                {serviceType === 5 && (
                    <Form.Item
                        {...AddServiceConfig?.thumbnail}
                        className="title-fields"
                        required
                    >
                        <Divider />
                        <Title level={5} className="field-heading field-no-margin">
                            Thumbnail
                        </Title>
                        <Paragraph className="field-sub-heading">
                            Thumbnail is visible on your topmate profile. Add a thumbnail that
                            pulls your customer’s attention
                        </Paragraph>
                        <Col xs={24} md={20} style={{ marginTop: "24px" }}>
                            <ImgCrop
                                aspect={1 / 1}
                                modalTitle="Crop/Rotate Image"
                                modalClassName="profile-img-crop-modal"
                                rotationSlider
                                quality={0.9}
                                modalOk="Save"
                            >
                                <Upload
                                    listType="picture"
                                    onChange={handleThumbnailChange}
                                    customRequest={onUploadThumbnail}
                                    onRemove={() => {
                                        setThumbnailUrl("")
                                        setThumbnailList([])
                                    }}
                                    disabled={thumbnailUploadInProgress}
                                    beforeUpload={(file) => {
                                        if (file?.size > 5000000) {
                                            message.error(
                                                "Currently we support file sizes upto 5MB. If you wish to upload a larger file, please email us the file and service URL at support@mentorpal.ai",
                                                5
                                            );
                                            return Upload.LIST_IGNORE;
                                        } else return true || Upload.LIST_IGNORE;
                                    }}
                                    accept=".jpg, .jpeg, .png, .svg"
                                    maxCount={1}
                                    defaultFileList={[...thumbnailList]}
                                >
                                    <Flex align="center" gap={24}>
                                        <Button
                                            icon={<UploadOutlined />}


                                        >
                                            Upload
                                        </Button>
                                        <Paragraph className="thumbnail-desc">
                                            Recommended size 600x600px
                                        </Paragraph>
                                    </Flex>
                                </Upload>
                            </ImgCrop>
                        </Col>
                    </Form.Item>
                )}

                {/* Webinar group call link */}
                {serviceType === 3 && (
                    <>
                        {" "}
                        <Divider />
                        <Row>
                            <Col span={24} style={{ marginBottom: "24px" }}>
                                <Text className="field-heading">Hosting options</Text>
                            </Col>
                            <WebinarMeetingLink
                                initialValues={initialValues}
                                data={data}
                                ServiceEditForm={ServiceEditForm}
                            />
                        </Row>
                    </>
                )}

                {/* Add Questions */}
                {![4, 5, 6].includes(serviceType) && (
                    <Row>
                        <Col span={24}>
                            <Divider style={{ float: "left" }} />
                            <Paragraph>
                                <Text className="field-heading">
                                    {data?.type === 3
                                        ? "Questions for attendee"
                                        : data?.type === 1
                                            ? "Invitee Questions"
                                            : "Additional Questions"}
                                </Text>
                            </Paragraph>
                            {/* Existing Questions */}
                            {addQuestion?.map((getdata, i) => {
                                return (
                                    <Paragraph key={i} id={i} className="add-question">
                                        <Paragraph className="title-fields">
                                            {`Question ${i + 1} (${getdata.type == "text" ? "Text" : "Upload "
                                                })`}
                                        </Paragraph>
                                        <Paragraph className="question-dropdown-arrow">
                                            <Paragraph className="question-set">
                                                {getdata.question}
                                            </Paragraph>
                                            <Button
                                                type="text"
                                                onClick={() => editQuestion(getdata)}
                                                className="edit-input-btn"
                                                style={{ border: "none" }}
                                            >
                                                <FiEdit2 />
                                            </Button>
                                        </Paragraph>
                                    </Paragraph>
                                );
                            })}

                            {/* Add New Question */}
                            <Button
                                type="text"
                                onClick={onShowModal}
                                style={{ background: "#F4F4F4", marginTop: 30 }}
                            >
                                <Paragraph className="question-button-text addQuestionsRef">
                                    + Add Question
                                </Paragraph>
                            </Button>
                        </Col>
                    </Row>
                )}

                {/* Configurations */}
                <Row>
                    <Divider style={{ margin: "32px 0", float: "left" }} />
                    <Col span={24}>
                        <Paragraph className="field-heading">Configurations</Paragraph>

                        {/* Response Time */}
                        {serviceType === 2 && (
                            <Form.Item
                                label="Promised response time"
                                name="promised_response_time"
                                className="title-fields"
                            >
                                <Radio.Group
                                    className="radio-checkbox"
                                    name="promised_response_time"
                                    onChange={handleDurationChange}
                                    value={questionduration}
                                >
                                    {replyTime &&
                                        replyTime.map((time) => (
                                            <Radio.Button
                                                key={time?.value}
                                                value={time?.value}
                                                className="radio-checkbox-item"
                                            >
                                                {time?.label}
                                            </Radio.Button>
                                        ))}
                                </Radio.Group>
                                <Paragraph className="response-time-tag">
                                    <img
                                        src="/images/dashboard/services/icon-sun.svg"
                                        styles={{
                                            paddingRight: "14px",
                                            objectFit: "contain",
                                            marginTop: 4,
                                        }}
                                        alt="Queries"
                                        width={40}
                                    />
                                    Priority DMs that are not answered within the promised
                                    response time are cancelled and a refund is initiated.
                                </Paragraph>
                            </Form.Item>
                        )}

                        {serviceType === 4 && (
                            <Row
                                justify="space-between"
                                align="middle"
                                className="validity-field"
                                style={{ marginBottom: 36 }}
                            >
                                <Col xs={16} md={16}>
                                    <Text>Package validity in months</Text>
                                </Col>
                                <Col span={8} md={8}>
                                    <Form.Item
                                        name="package_validity_in_months"
                                        className="title-fields"
                                        style={{ margin: 0 }}
                                    >
                                        <Select
                                            className="validityRef"
                                            style={{ minWidth: 100 }}
                                            defaultValue={initialValues?.package_validity_in_months}
                                            options={package_validity}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                        {/* Hide Services */}
                        <Form.Item
                            {...AddServiceConfig?.hide_service}
                            valuePropName="checked"
                            labelCol={{
                                xs: { span: 20, order: 2 },
                                md: { span: 10, order: 2 },
                                order: 2,
                            }}
                            wrapperCol={{
                                xs: { span: 3, order: 1 },
                                md: { span: 3, order: 1 },
                                order: 1,
                            }}
                            className="switch-input"
                            style={{ marginBottom: 10 }}
                        >
                            <Switch size="default" className="hideServicesRef" />
                        </Form.Item>
                        {![4, 6].includes(serviceType) && (
                            <>
                                <Row align="middle" justify="space-between" gutter={12}>
                                    <Col md={18} xs={24}>
                                        <Form.Item
                                            {...AddServiceConfig?.has_flexible_charge}
                                            label="Increase conversion by slash pricing"
                                            name="show_original_charge"
                                            valuePropName="checked"
                                            labelCol={{
                                                xs: { span: 20, order: 2 },
                                                md: { span: 15, order: 2 },
                                                order: 2,
                                            }}
                                            wrapperCol={{
                                                xs: { span: 3, order: 1 },
                                                md: { span: 4, order: 1 },
                                                order: 1,
                                            }}
                                            className="switch-input"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <Switch
                                                className="slasedPriceRef"
                                                size="default"
                                                onClick={() => {
                                                    ServiceEditForm?.setFieldValue(
                                                        "original_charge",
                                                        Math.ceil(minimumAmount * 1.2)
                                                    );
                                                    setOriginalAmount(Math.ceil(minimumAmount * 1.2));
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {originalAmount > 0 && (
                                        <Col>
                                            <PricingDisplay
                                                originalPrice={originalAmount}
                                                discountedPrice={minimumAmount}
                                                currency={data?.currency}
                                            />
                                        </Col>
                                    )}
                                </Row>
                                {ServiceEditForm?.getFieldValue("show_original_charge") && (
                                    <Card style={{ margin: "20px 0px" }}>
                                        <Row align="middle" justify="space-between" gutter={12}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="charge"
                                                    label="Actual amount"
                                                    className="title-fields"
                                                    required
                                                >
                                                    <InputNumber
                                                        name="charge"
                                                        style={{ width: "100%" }}
                                                        className="title-input"
                                                        disabled
                                                        prefix={currencyName === "INR" ? '₹' : "$"}
                                                        min={minimumAmount}
                                                        step={5}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="original_charge"
                                                    label="Slashed amount"
                                                    className="title-fields"
                                                    rules={[
                                                        { required: true, message: "Please enter amount" },
                                                        {
                                                            min: minimumAmount + 1,
                                                            type: "number",
                                                            message:
                                                                "Slashed price should be more than price",
                                                        },
                                                    ]}
                                                    required
                                                >
                                                    <InputNumber
                                                        name="original_charge"
                                                        style={{ width: "100%" }}
                                                        className="title-input"
                                                        prefix={currencyName === "INR" ? '₹' : "$"}
                                                        min={minimumAmount + 1}
                                                        step={5}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                )}
                            </>
                        )}
                        {/* Participation Limit */}
                        {serviceType === 3 && (
                            <>
                                <Form.Item
                                    label="Limit participants"
                                    name="limit_participants"
                                    valuePropName="checked"
                                    labelCol={{ xs: { span: 20, order: 2 }, md: { span: 10 } }}
                                    wrapperCol={{ xs: { span: 3, order: 1 }, md: { span: 3 } }}
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginBottom: 10,
                                    }}
                                    className="switch-input"
                                >
                                    <Switch
                                        onChange={onBookingLimitChange}
                                        className="limitParicipantsRef"
                                    />
                                </Form.Item>
                                {/* Booking Limits */}
                                <Row>
                                    <Col span={12}>
                                        {serviceType === 3 && isBookingLimitVisibile && (
                                            <Form.Item {...AddServiceConfig?.bookings_limit}>
                                                <InputNumber min={1} className="width-full" />
                                            </Form.Item>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        )}

                        {/* Seats Left Count */}
                        {serviceType === 3 && (
                            <Form.Item
                                label="Show seats left count"
                                name="show_seats_left"
                                valuePropName="checked"
                                labelCol={{ xs: { span: 20, order: 2 }, md: { span: 10 } }}
                                wrapperCol={{ xs: { span: 3, order: 1 }, md: { span: 3 } }}
                                style={{ flexDirection: "row", alignItems: "center" }}
                                className="switch-input"
                            >
                                <Switch disabled={!isBookingLimitVisibile} />
                            </Form.Item>
                        )}

                        {/* Show Sales Count */}
                        {serviceType === 5 && (
                            <Form.Item
                                label="Show sales count"
                                name="show_sale_count"
                                valuePropName="checked"
                                labelCol={{ xs: { span: 20, order: 2 }, md: { span: 10 } }}
                                wrapperCol={{ xs: { span: 3, order: 1 }, md: { span: 3 } }}
                                style={{ flexDirection: "row", alignItems: "center" }}
                                className="switch-input"
                            >
                                <Switch />
                            </Form.Item>
                        )}
                    </Col>

                    <Col xs={24} md={0} className="save-btn">
                        <Paragraph className="booking-mobile-footer">
                            <Button
                                block
                                className="btn-dark"
                                onClick={() => {


                                    setNavigationType(true)
                                    ServiceEditForm.submit()
                                }}
                            >
                                {duplicate ? "Create Service" : "Save Changes"}
                            </Button>
                        </Paragraph>
                    </Col>
                </Row>

                {!duplicate && (
                    <Row gutter={24}>
                        <Col style={{ marginTop: 40 }}>
                            <Button
                                type="text"
                                danger
                                icon={<DeleteOutlined />}
                                style={{
                                    background: "#F3F3F3",
                                    borderRadius: 6,
                                    fontWeight: 700,
                                }}
                                onClick={() => setShowModal(true)}
                            >
                                Delete Service
                            </Button>
                        </Col>

                        {/* Duplicate Service Button */}
                        {/* {!data?.is_subscription_enabled && (
                            <Col style={{ marginTop: 40 }}>
                                <Button className="btn-gray">
                                    Duplicate Service
                                </Button>
                            </Col>
                        )} */}
                    </Row>
                )}

                {/* Add Question Modal */}
                <Suspense fallback={<Spin />}>
                    <AddQuestion
                        showModal={questionModal}
                        setShowModal={setQuestionModal}
                        id={data?.id}
                        addQuestion={addQuestion}
                        setaddQuestion={setaddQuestion}

                        questionHeader={questionHeader}
                        buttonText={buttonText}
                        activeQuestion={activeQuestion}
                        questionType={questionType}
                    />
                </Suspense>
            </Form >

            {countryModalShown && <UpdateCountryModal />
            }

            {
                showModal && (
                    <DeleteConfirmation
                        show={showModal}
                        onConfirm={() => {
                            handleDelete(data?.product_id);
                            setShowModal(false);
                        }}
                        onClose={() => setShowModal(!showModal)}
                        content={`Are you sure you want to delete the service "${data?.title}"`}
                        title={"Delete Service"}
                    />
                )
            }

            {
                multipleSessionsModal && (
                    <MultipleSessionsModal
                        visible={multipleSessionsModal}
                        setVisible={setMultipleSessionsModal}
                        onClose={() => {
                            setMultipleSessionsModal(false);
                        }}
                        form={ServiceEditForm}
                        initialValues={data}
                    />
                )
            }
        </>
    );
};

export default ServiceForm;



