import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { mentorEbooks } from "../../../redux/actions/mentor";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import book1Img from "../img/book-1.png";
import NoBookFound from "./NoBookFound";
import("../../dashboard/css/manage-ebook.css");
import("../../dashboard/css/media.css");

const Ebooks = () => {
  const dispatch = useDispatch();
  // const [isLoading, setIsLoading] = useState(true);
  const user_id = REACT_USER_ID;
  useEffect(() => {
    dispatch(mentorEbooks(user_id));
  }, []);
  const mentorReducerData = useSelector((state) => {
    return state.mentorEbooksReducer;
  });

  const mentorEbooksList = mentorReducerData?.data || [];
  const isLoading = mentorReducerData?.loading;

  const dateFormater = (date) => {
    const monthArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let newDate = new Date(date);
    let formatedDate =
      ("0" + newDate.getDate()).slice(-2) +
      " " +
      monthArray[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      ("0" + newDate.getHours()).slice(-2) +
      ":" +
      ("0" + newDate.getMinutes()).slice(-2);
    return formatedDate;
  };
  return (
    <div className="container w-100" >
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div className="ebook-manage-cls">
          <div className="ebook-download-sec">
            <div className="ebook-bg">
              <div className="ebbok-title-cls">
                <h3>Your E-Books</h3>
              </div>
              {mentorEbooksList.length > 0 ? (
                <>
                  {mentorEbooksList.map((book) => (
                    <div className="single-book-cls">
                      <div className="signle-book-left">
                        <div className="prd-book-dtl">
                          <div className="book-img">
                            {
                              book?.picture != "" 
                              ? <img src={`${process.env.REACT_APP_IMAGES_BASE_URL}${book?.picture}`} alt="" style={{width:"8rem"}} />
                              : <img src={book1Img} alt="" />
                            }
                          </div>
                          <div className="book-cont">
                            <h5>{book?.title}</h5>
                            <p>Author: {book?.author}</p>
                            <p>Description: {book?.description}</p>
                            <p>Book objective: {book?.book_objective}</p>
                            <p className="price-p">Price: ${book?.price}</p>
                          </div>
                        </div>
                        <div className="book-download-sec">
                          <p>
                            Publish Date :{" "}
                            {dateFormater(book?.last_modified_at)}
                          </p>
                          <button type="button" className="btn btn-green">
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <NoBookFound />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ebooks;
