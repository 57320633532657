import { InfoCircleOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Form,
    InputNumber,
    Modal,
    Row,
    Select,
    Space,
    Tag,
    TimePicker,
    Tooltip,
    Typography,
} from "antd";
import styles from './Multipe.module.css'
import { useEffect, useState } from "react";
import { AddServiceConfig, getLinkWithPretag, isMobile } from "../../../Data/Data";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

const { Paragraph, Text } = Typography;
const { CheckableTag } = Tag;
const WEEKDAYSMapping = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
};
const MultipleSessionsModal = ({
    visible,
    setVisible,
    onClose,
    initialValues,
    form,
}) => {
    const [selectedDays, setSelectedDays] = useState(
        form.getFieldValue("cohort_weekdays") || ["Mon"]
    );
    const [displayDays, setDisplayDays] = useState([]);
    const [SessionsForm] = Form.useForm();

    const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const getInitialValues = () => {
        const date = form.getFieldValue("date");
        const time = form.getFieldValue("time");
        const cohort_no_of_sessions = form.getFieldValue("cohort_no_of_sessions");
        const cohort_noun = form.getFieldValue("cohort_noun");
        return cohort_no_of_sessions > 1
            ? {
                cohort_noun: cohort_noun,
                cohort_no_of_sessions: cohort_no_of_sessions,
                date: date,
                time: time,
            }
            : { date: date, time: time, cohort_no_of_sessions: 2 };
    };

    const disabledDate = (current) => {
        const yesterday = dayjs().subtract(1, "days");
        // Can not select days before today and today
        return current && current < yesterday;
    };

    const updateDisplayDays = (days = selectedDays) => {
        const selected_date = SessionsForm?.getFieldValue("date");
        const arr = SessionsForm?.getFieldValue("date")
            ? [dayjs(selected_date).toISOString()]
            : [];
        const noOfSessions =
            SessionsForm?.getFieldValue("cohort_no_of_sessions") > 1
                ? SessionsForm?.getFieldValue("cohort_no_of_sessions")
                : 2;

        for (let i = 0; i <= noOfSessions; i++) {
            for (let j = 0; j < days?.length && noOfSessions > arr?.length; j++) {
                dayjs(selected_date).add(i, "week").weekday(WEEKDAYSMapping[days[j]]) >
                    dayjs(selected_date) &&
                    arr.push(
                        dayjs(selected_date)
                            .add(i, "week")
                            .weekday(WEEKDAYSMapping[days[j]])
                            .toISOString()
                    );
            }
        }
        setDisplayDays(arr);
    };

    const handleWeekDaysChange = async (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedDays, tag]
            : selectedDays.filter((t) => t !== tag);

        nextSelectedTags.sort(function sortByDay(a, b) {
            return WEEKDAYSMapping[a] - WEEKDAYSMapping[b];
        });
        await setSelectedDays(nextSelectedTags);
        updateDisplayDays(nextSelectedTags);
    };

    const onValuesChanged = (field) => {
        if (Object.prototype.hasOwnProperty.call(field, "date")) {
            const selected_date = SessionsForm?.getFieldValue("date");
            selected_date?.format("ddd") &&
                !selectedDays?.includes(selected_date?.format("ddd")) &&
                setSelectedDays([...selectedDays, selected_date?.format("ddd")]);
        }
        if (Object.prototype.hasOwnProperty.call(field, "cohort_no_of_sessions")) {
            updateDisplayDays();
        }
    };

    const onFormFinish = (fields) => {
        const groupCallDateTime =
            fields?.date && fields?.time
                ? fields?.date?.format("YYYY-MM-DD") +
                "T" +
                fields?.time?.format("HH:mm:ss")
                : null;
        const payload = {
            date: fields?.date,
            time: fields?.time,
            specific_date: groupCallDateTime,
            cohort_no_of_sessions: fields?.cohort_no_of_sessions || 2,
            cohort_noun: fields?.cohort_noun,
            cohort_weekdays: selectedDays || [],
            group_call_url:
                initialValues?.group_call_url?.length > 0
                    ? getLinkWithPretag(initialValues?.group_call_url)
                    : initialValues?.group_call_url || "",
        };
        form.setFieldsValue(payload);
        onClose();
    };

    const handleMenuClick = (value) => {
        form.setFieldValue("cohort_noun", value);
    };

    const ClearDetails = () => {
        const payload = {
            cohort_no_of_sessions: null,
            cohort_noun: null,
            cohort_weekdays: [],
        };
        form.setFieldsValue(payload);
        onClose();
    };

    const closeModal = () => {

        setVisible(false);
    };

    const items = [
        {
            label: "Bootcamp",
            value: "Bootcamp",
        },
        {
            label: "Cohort",
            value: "Cohort",
        },
        {
            label: "Webinar",
            value: "Webinar",
        },
        {
            label: "Weekly hangout",
            value: "Weekly hangout",
        },
        {
            label: "Masterclass",
            value: "Masterclass",
        },
    ];

    useEffect(() => {
        const selected_date = SessionsForm?.getFieldValue("date");
        selected_date?.format("ddd") &&
            !selectedDays?.includes(selected_date?.format("ddd")) &&
            setSelectedDays([...selectedDays, selected_date?.format("ddd")]);
        updateDisplayDays();
    }, [SessionsForm?.getFieldValue("date")]);

    const ModalContent = () => {
        return (
            <Row>
                <Col span={24}>
                    {isMobile ? (
                        <><Paragraph className={styles.Title}>
                            Add recurring sessions
                        </Paragraph></>
                    ) : (
                        <></>
                    )}

                    <Form
                        contentEditable={false}
                        onFinish={onFormFinish}
                        onValuesChange={onValuesChanged}
                        className={styles.Form}
                        form={SessionsForm}
                        initialValues={getInitialValues()}
                    >
                        <Row gutter={{ xs: 0, md: 12 }}>
                            <Col span={24}>
                                <Paragraph strong className={styles.FormLabel}>
                                    First Session
                                </Paragraph>
                            </Col>
                            {/* Webinar: Date */}
                            <Col xs={24} md={12}>
                                <Form.Item
                                    {...AddServiceConfig?.date}
                                    label={null}
                                    className="fields"
                                >
                                    <DatePicker
                                        inputReadOnly
                                        placeholder={AddServiceConfig?.date?.placeholder}
                                        className="full-width"
                                        onChange={() => updateDisplayDays()}
                                        disabled={
                                            initialValues?.can_edit_date === false ? true : false
                                        }
                                        disabledDate={disabledDate}
                                        onKeyDown={(event) => {
                                            event.preventDefault();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            {/* Webinar: Time */}
                            <Col xs={24} md={12}>
                                <Form.Item
                                    {...AddServiceConfig?.time}
                                    label={null}
                                    className="fields"
                                >
                                    <TimePicker
                                        inputReadOnly
                                        disabled={
                                            initialValues?.can_edit_date === false ? true : false
                                        }
                                        placeholder={AddServiceConfig?.time?.placeholder}
                                        className="full-width"
                                        minuteStep={15}
                                        showNow={false}
                                        use12Hours
                                        format="hh:mm A"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* Webinar: Days */}
                        <Form.Item>
                            <Paragraph strong className={styles.FormLabel}>
                                Repeats Every
                            </Paragraph>
                            <Space size={[0, 8]} wrap>
                                {WEEKDAYS.map((tag) => (
                                    <CheckableTag
                                        key={tag}
                                        className={styles.CheckableTag}
                                        style={{
                                            cursor:
                                                SessionsForm?.getFieldValue("date")?.format("ddd") ==
                                                    tag
                                                    ? "not-allowed"
                                                    : "pointer",
                                        }}
                                        checked={selectedDays.includes(tag)}
                                        onChange={(checked) =>
                                            SessionsForm?.getFieldValue("date")?.format("ddd") !==
                                            tag && handleWeekDaysChange(tag, checked)
                                        }
                                    >
                                        {tag}
                                    </CheckableTag>
                                ))}
                            </Space>
                            {selectedDays?.length < 1 && (
                                <Paragraph
                                    type="danger"
                                    style={{
                                        marginTop: 6,
                                    }}
                                >
                                    Please select at least one day
                                </Paragraph>
                            )}
                            {SessionsForm?.getFieldValue("cohort_no_of_sessions") <
                                selectedDays?.length &&
                                selectedDays?.length > 1 && (
                                    <Paragraph
                                        type="danger"
                                        style={{
                                            marginTop: 6,
                                        }}
                                    >
                                        Day selected should be less than total session
                                    </Paragraph>
                                )}
                        </Form.Item>

                        {/* Total Sessions */}
                        <Col xs={24} md={12} xl={11}>
                            <Paragraph strong className={styles.FormLabel}>
                                Total Sessions
                            </Paragraph>
                            <Form.Item
                                name="cohort_no_of_sessions"
                                rules={[
                                    {
                                        required: true,
                                        message: "Sessions is required.",
                                    },
                                    {
                                        min: 2,
                                        type: "number",
                                        message: "Atleast 2 sessions required.",
                                    },
                                ]}
                            >
                                <InputNumber min={2} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        {SessionsForm.getFieldValue("date") && displayDays?.length > 0 && (
                            <>
                                {" "}
                                <Paragraph strong className={styles.FormLabel}>
                                    Session Dates
                                </Paragraph>
                                <Form.Item>
                                    <Col span={24} className={styles.DateBlockContainer}>
                                        <Space size={10}>
                                            {displayDays.slice(0, 6)?.map((x, idx) => (
                                                <Paragraph key={idx}>
                                                    <DateCalendarBlock date={x} />
                                                </Paragraph>
                                            ))}
                                            {displayDays?.length > 6 && (
                                                <div
                                                    className={styles.DateBlock}
                                                    style={{
                                                        paddingTop: 0,
                                                    }}
                                                >
                                                    <Text
                                                        className={styles.Day}
                                                        style={{
                                                            padding: "15px 0",
                                                        }}
                                                    >
                                                        +{displayDays?.length - 6}
                                                    </Text>
                                                </div>
                                            )}
                                        </Space>
                                    </Col>
                                </Form.Item>
                            </>
                        )}
                        <Col xs={24} md={24} xl={24}>
                            <Paragraph strong className={styles.FormLabel}>
                                What are we calling the sessions?{" "}
                                <Tooltip
                                    title="Positioning your sessions
                        correctly helps your audience better understand what they're
                        getting"
                                >
                                    <InfoCircleOutlined
                                        className="info-icon"
                                        style={{ marginLeft: 6 }}
                                    />
                                </Tooltip>
                            </Paragraph>
                            <Form.Item
                                name="cohort_noun"
                                rules={[
                                    {
                                        required: true,
                                        message: "Required.",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Session name"
                                    onChange={handleMenuClick}
                                    style={{ width: 200 }}
                                    options={items}
                                />
                            </Form.Item>
                        </Col>
                        <Button
                            htmlType="submit"
                            block
                            className="btn-dark"
                            disabled={
                                selectedDays?.length < 1 ||
                                    SessionsForm?.getFieldValue("cohort_no_of_sessions") <
                                    selectedDays?.length ||
                                    initialValues?.can_edit_date === false
                                    ? true
                                    : false
                            }
                        >
                            Add sessions
                        </Button>
                        {initialValues?.can_edit_date === false ? (
                            <></>
                        ) : (
                            <>
                                <Divider style={{ margin: "20px 0px 16px 0px" }} />
                                <Paragraph>
                                    If you wanted to create just a single session click{" "}
                                    <a onClick={ClearDetails} className={styles.SingleSessionBtn}>
                                        here
                                    </a>
                                </Paragraph>{" "}
                            </>
                        )}
                    </Form>
                </Col>
            </Row>
        );
    };

    return (
        <>
            {isMobile ? (
                <Modal
                    open={visible}
                    onCancel={closeModal}
                    footer={null}
                    className={styles.MSM}
                    width={476}
                >
                    {" "}
                    <ModalContent />
                </Modal>
            ) : (

                <Drawer
                    open={visible}
                    onClose={closeModal}
                    placement="bottom"
                    width={"50vw"}
                    style={{
                        maxHeight: "90vh",
                    }}
                    height="unset"
                    title="Add recurring sessions"
                >
                    <ModalContent />
                </Drawer>
            )}
        </>
    );
};

export default MultipleSessionsModal;

const DateCalendarBlock = ({ date }) => {
    const { Text } = Typography;
    return (
        <Space
            size={2}
            key={date}
            className={styles.DateBlock}
            direction="vertical"
        >
            <Text>{`${dayjs(date).format("MMM")}`}</Text>
            <Text className={styles.Day}>{`${dayjs(date).format("D")}`}</Text>
        </Space>
    );
};