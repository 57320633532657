import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CalanderConnect from "../../common/google-calander";
import DashboardHeader from "../dashboardHeader";
import styles from "./event.module.css";
const Events = () => {
  const navigate = useNavigate();

  useEffect(() => {
    import("../../../assets/css/bootstrap.min.css");
    import("../../../assets/css/font-awesome.min.css");
    import("../../../assets/css/owl.carousel.min.css");
    import("../../../assets/css/owl.theme.default.min.css");
    import("./../../../assets/css/custom.css");
    import("../../../assets/css/header.css");
    import("../../../assets/css/signup-popup.css");
    import("../../../assets/css/apply-mentor.css");
    import("../../../assets/css/footer.css");
    import("./../../../assets/css/media.css");
    import(
      "../../../components/applyAsMentor/RegistrationProcess/registration.css"
    );
  }, []);

  return (
    <>
      {/* <DashboardHeader /> */}
      <form
        className={`py-lg-5 mx-auto ${styles.form_wrapper}`}
        style={{ maxWidth: "390px" }}
      >
        <div className="container">
          <h3 className="mb-32 fw-bold">Connect your calendar(s).</h3>
          <p className={`${styles.event_calendar_subtext} mb-32`}>
            Connect your calendar to auto-check for busy times and add new
            sessions as they are scheduled.
          </p>
        </div>

        <div className="container">
          <div className="content mt-3">
            <div className="sc-ACYlI kAnuDI">
              <div className="sc-bjCGfv fsmHDz">
                <div className="item__icon">
                  <svg
                    fill="none"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.1666 10.8333H10.8333V29.1667H29.1666V10.8333Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.4 17.4333L22.24 18.6333L23.56 17.6733V24.6333H25V15.5133H23.8L21.4 17.4333Z"
                      fill="#1E88E5"
                    ></path>
                    <path
                      d="M19.1192 19.7875C19.64 19.3092 19.9634 18.6458 19.9634 17.9133C19.9634 16.4575 18.6859 15.2733 17.1159 15.2733C15.7809 15.2733 14.6392 16.1142 14.3409 17.3175L15.7217 17.6683C15.8592 17.115 16.445 16.7133 17.1159 16.7133C17.9009 16.7133 18.54 17.2517 18.54 17.9133C18.54 18.575 17.9009 19.1133 17.1159 19.1133H16.285V20.5533H17.1159C18.0167 20.5533 18.7767 21.1792 18.7767 21.92C18.7767 22.6733 18.055 23.2867 17.1675 23.2867C16.3659 23.2867 15.6809 22.7783 15.5725 22.105L14.1667 22.335C14.385 23.6983 15.675 24.7267 17.1667 24.7267C18.8392 24.7267 20.2 23.4675 20.2 21.92C20.2 21.0675 19.78 20.3025 19.1192 19.7875Z"
                      fill="#1E88E5"
                    ></path>
                    <path
                      d="M28.3333 35H11.6666L10.8333 31.6667L11.6666 28.3333H28.3333L29.1666 31.6667L28.3333 35Z"
                      fill="#FBC02D"
                    ></path>
                    <path
                      d="M31.6666 29.1667L35 28.3333V11.6667L31.6666 10.8333L28.3333 11.6667V28.3333L31.6666 29.1667Z"
                      fill="#4CAF50"
                    ></path>
                    <path
                      d="M28.3333 11.6667L29.1667 8.33333L28.3333 5H7.5C6.11917 5 5 6.11917 5 7.5V28.3333L8.33333 29.1667L11.6667 28.3333V11.6667H28.3333Z"
                      fill="#1E88E5"
                    ></path>
                    <path
                      d="M28.3333 28.3333V35L35 28.3333H28.3333Z"
                      fill="#E53935"
                    ></path>
                    <path
                      d="M32.5 5H28.3333V11.6667H35V7.5C35 6.11917 33.8808 5 32.5 5Z"
                      fill="#1565C0"
                    ></path>
                    <path
                      d="M7.5 35H11.6667V28.3333H5V32.5C5 33.8808 6.11917 35 7.5 35Z"
                      fill="#1565C0"
                    ></path>
                  </svg>
                </div>
                <div className="">Google calendar</div>
                <div className="item__btn">
                  <CalanderConnect redirectUrl="/calendar-redirection" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sc-gXnjX joIaGT d-flex">
          <p className="sc-pyfCe hAXIJe ml-2 grey-1-text">
            We'll never control apps without your consent. You can disconnect
            your permission anytime.
          </p>
        </div>
        <center>
          <div className="get-started-cls">
            <a href="/dashboard">
              <button
                type="button"
                className="find-btn btn btn-primary skipnow"
              >
                Skip for now
              </button>
            </a>
          </div>
        </center>
      </form>
    </>
  );
};

export default Events;
