import React, { useEffect } from 'react';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../CreateCourse/custom.createcourse.css'
import './custom.information.css'
import { useDispatch, useSelector } from 'react-redux';
import { getCourseDetailsAction } from '../../../redux/actions/course';
import { TailSpin } from 'react-loader-spinner';

const CourseAllInfo = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [activeButton, setActiveButton] = useState("info");
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        navigate(buttonName);
    };
    const [ProductId, setProductId] = useState('');
    const [loader, setloader] = useState(false);
    const [data, setData] = useState('');
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    const location = useLocation();
    const { slug, product_id } = useParams();
    useEffect(() => {
        setProductId(product_id);
    }, []);
    // useEffect(() => {
    //     const segments = window.location.pathname.split('/');
    //     const slug = segments[segments.indexOf('course') + 1];
    //     setSlug(slug);
    // }, []);
    useEffect(() => {
        console.log("ProductId", ProductId);
        dispatch(getCourseDetailsAction(ProductId))
    }, [ProductId, dispatch]);
    const getCourseDetailsReducer = useSelector(
        (state) => state.getCourseDetailsReducer
    )
    useEffect(() => {
        setloader(getCourseDetailsReducer?.loading)
        setData(getCourseDetailsReducer?.data)
        setStatus(getCourseDetailsReducer?.status)
        setError(getCourseDetailsReducer?.error)
    }, [getCourseDetailsReducer]);


    const getLocalTime = (utcTime) => {
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };

        const localTime = new Date(utcTime).toLocaleString("en-US", options);
        return localTime;
    };
    const [isOpen, setIsOpen] = useState(false);

    const openNav = () => {
        setIsOpen(true);
    };

    const closeNav = () => {
        setIsOpen(false);
    };


    const triggerOpenNavState = useSelector((state) => state?.navReducer?.triggerOpenNav);

    useEffect(() => {
        console.log(triggerOpenNavState, "triggernave")
        if (triggerOpenNavState === true) {
            setIsOpen(true);
            openNav();
        }
    }, [triggerOpenNavState]);


    return (
        <>
            {
                loader ? (<div className="loader open_sessions_loader">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>) : (null)
            }

            <div
                id="courseCont"
                style={{ minHeight: "100%" }}
            >
                <div className="">

                    <div className="content h-100" >
                        <div className="left">
                            <div className="leftContent dekstop_course">
                                <div className="courseMeta">
                                    <div className="coverCont relative">
                                        <div
                                            className="actions absolute c-hand"
                                            id="uploadcover"
                                            style={{ border: "1px solid #e5e5e5", background: "#fff" }}
                                        >
                                            {
                                                data?.course_banner !== '' ? (
                                                    <img src={`${process.env.REACT_APP_IMAGES_BASE_URL}${data?.course_banner}`} alt={data?.course_topic} />
                                                ) : (
                                                    <>
                                                        <div className="text-center">
                                                            <i className="fa-thin fa-rectangle-history-circle-plus" />
                                                        </div>
                                                        <div>Add course cover</div>



                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="get-started-cls why_choose_ment mt-0">
                                        <a href="#">
                                            {data?.course_topic}
                                        </a>


                                    </div>


                                    <span class="badge bg-secondary">Created <b>{getLocalTime(data?.created_at)}</b></span>
                                    <span class="badge bg-secondary">Modified <b>{getLocalTime(data?.created_at)}</b></span>

                                </div>


                                <div className="itemsCont">

                                    <ul className="nav flex-column">


                                        <li
                                            className={`nav-item itemss ${activeButton === "info" ? "active___side_tabs" : ""
                                                }`}
                                        >
                                            <a
                                                className="nav-link linkss"
                                                onClick={() => handleButtonClick("info")}
                                            >
                                                <span className="d-flex align-items-center">
                                                    {" "}
                                                    <span className="sidebar-icon-img d-flex align-items-center navi-name">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={25}
                                                            height={25}
                                                            fill="currentColor"
                                                            className="bi bi-info-circle"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                        </svg>

                                                        <span className="ms-2" style={{ color: "grey" }}>
                                                            Information
                                                        </span>
                                                    </span>{" "}
                                                </span>
                                            </a>
                                        </li>
                                        {data && data?.document_type === "course" ? (<li
                                            className={`nav-item itemss ${activeButton === "add-syllabus"
                                                ? "active___side_tabs"
                                                : ""
                                                }`}
                                        >
                                            {" "}
                                            <a
                                                className="nav-link linkss"
                                                onClick={() => handleButtonClick("builder")}
                                            >
                                                {" "}
                                                <span className="d-flex align-items-center">
                                                    {" "}
                                                    <span className="sidebar-icon-img d-flex align-items-center navi-name">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={25}
                                                            height={25}
                                                            fill="currentColor"
                                                            className="bi bi-tools"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                                                        </svg>

                                                        <article className="ms-2" style={{ color: "grey" }}>
                                                            Course Builder
                                                        </article>
                                                    </span>{" "}
                                                </span>
                                            </a>
                                        </li>) : (null)}
                                        <li
                                            className={`nav-item itemss ${activeButton === "learner" ? "active___side_tabs" : ""
                                                }`}
                                        >
                                            <a
                                                className="nav-link linkss"
                                                onClick={() => handleButtonClick("learner")}
                                            >
                                                {" "}
                                                <span className="d-flex align-items-center">
                                                    {" "}
                                                    <span className="sidebar-icon-img d-flex align-items-center navi-name">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={25}
                                                            height={25}
                                                            fill="currentColor"
                                                            className="bi bi-people-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                                        </svg>

                                                        <article className="ms-2" style={{ color: "grey" }}>
                                                            Learner
                                                        </article>
                                                    </span>{" "}
                                                </span>
                                            </a>
                                        </li>

                                        <li
                                            className={`nav-item itemss ${activeButton === "certificates" ? "active___side_tabs" : ""
                                                }`}
                                        >
                                            <a
                                                className="nav-link linkss"
                                                onClick={() => handleButtonClick("certificates")}
                                            >
                                                {" "}
                                                <span className="d-flex align-items-center">
                                                    {" "}
                                                    <span className="sidebar-icon-img d-flex align-items-center navi-name">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={25}
                                                            height={25}
                                                            fill="currentColor"
                                                            className="bi bi-award"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" />
                                                            <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                                                        </svg>

                                                        <article className="ms-2" style={{ color: "grey" }}>
                                                            Certificates
                                                        </article>
                                                    </span>{" "}
                                                </span>
                                            </a>
                                        </li>

                                        <li
                                            className={`nav-item itemss ${activeButton === "back-course" ? "active___side_tabs" : ""
                                                }`}
                                        >
                                            <a
                                                className="nav-link linkss"
                                                onClick={() =>
                                                    handleButtonClick(
                                                        data && data?.document_type === "course" ? "/course" : "/webinar"
                                                    )
                                                }
                                            >
                                                <span className="d-flex align-items-center">
                                                    {" "}
                                                    <span className="sidebar-icon-img d-flex align-items-center navi-name">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={25}
                                                            height={25}
                                                            fill="currentColor"
                                                            className="bi bi-arrow-return-left"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"
                                                            />
                                                        </svg>


                                                        <span className="ms-2" style={{ color: "grey" }}>
                                                            Back To {data?.document_type}
                                                        </span>
                                                    </span>{" "}
                                                </span>
                                            </a>
                                        </li>

                                    </ul>



                                </div>
                            </div>
                            <div id="mySidenav" className={`sidenav__courseDetails mobile_viewCourse ${isOpen ? 'open' : ''}`}>
                                <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
                                    &times;
                                </a>
                                <a href="info">Information</a>

                                {data && data?.document_type === "course" ? (<a href="builder">Course Builder</a>) : (null)}
                                <a href="learner">Learner</a>
                                <a href="certificates">Certificates</a>
                                <a href={data && data?.document_type === "course" ? ("/course") : ("/webinar")}>   Back To Courses</a>
                            </div>

                        </div>
                        <div className="right" >
                            <div
                                className="d-flex"
                                style={{
                                    flexDirection: "column",
                                    height: "100vh",
                                    overflowX: "scroll"

                                }}
                            >
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default CourseAllInfo;
