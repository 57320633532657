import React from "react";

const Step1 = ({
  createMeetingData,
  handleChange,
  handleNextStep,
  ConnectionListData,
}) => {
  const { mentee_id } = createMeetingData;
  console.log("ConnectionListData", ConnectionListData);

  return (
    <div>
      <section className="step__goal__item">
        <div>
          <div className="container">
            <div className="step__count help-cont">
              <p>STEP 1 of 3 : Meeting Details</p>
              <h4>Select your Mentee ?</h4>
            </div>
          </div>
          <form action="">
            <div className="form-group">
              <div className="form-group">
                <select
                  className="text-left form-control"
                  style={{
                    height: "50px",
                    width: "100%",
                    borderRadius: "13px",
                  }}
                  required
                  value={mentee_id}
                  onChange={(e) => handleChange("mentee_id", e.target.value)}
                >
                  <option value=""> Choose your Mentee</option>
                  {ConnectionListData?.map((option) => (
                    <option
                      key={option?.connection_details?.user_id}
                      value={option?.connection_details?.user_id}
                    >
                      {option?.connection_details?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="get-started-cls" id="get-started-cls">
              <button
                onClick={handleNextStep}
                type="button"
                className="btn btn-primary"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Step1;
