import React, { useEffect, useState } from "react";
import {
  cancelMeetingAction,
  createMeetingAction,
  getUpcomingMeetingAction,
} from "../../redux/actions/mentor";
import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_ID } from "../auth/tokenProvider";
import { TailSpin } from "react-loader-spinner";
import CreateMeetingModule from "./Module/CreateMeetingModule";

const Meeting = (props) => {
  const { menteeListingData } = props;

  const dispatch = useDispatch();
  const mentor_id = REACT_USER_ID;
  const [UserMeetingData, setUserMeetingData] = useState([]);

  const getMeetingDataFunction = () => {
    dispatch(getUpcomingMeetingAction());
  };

  useState(() => {
    getMeetingDataFunction();
  }, []);
  const isLoading = useSelector(
    (state) => state.getUpcomingMeetingReducer?.loading
  );

  const getUpcomingMeetingData = useSelector(
    (state) => state.getUpcomingMeetingReducer?.data
  );

  useEffect(() => {
    if (isLoading === false && getUpcomingMeetingData) {
      const filteredData = getUpcomingMeetingData.filter(
        (item) => item.mentor_id === mentor_id
      );
      setUserMeetingData(filteredData);
    }
  }, [isLoading, getUpcomingMeetingData, mentor_id]);

  console.log("UserMeetingData outside", UserMeetingData);

  const handlCancelMeeting = (id) => {
    console.log("cancel automatically call", id);
    const data = {
      id: id,
      meeting_status: "cancel",
    };
    dispatch(cancelMeetingAction(data));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    let period = "AM";

    let formattedHours = parseInt(hours, 10);
    if (formattedHours === 0) {
      formattedHours = 12;
    } else if (formattedHours === 12) {
      period = "PM";
    } else if (formattedHours > 12) {
      formattedHours -= 12;
      period = "PM";
    }

    return `${formattedHours}:${minutes} ${period}`;
  };

  const [createMeetingModal, setcreateMeetingModal] = useState({});

  return (
    <>
      {/* <div className="filter__bar d-flex">
        <div className="filter__sub_item">
          <div id="Filter______Col">
            <select name="" id="" className="filter_____Admin">
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
            </select>
          </div>
          <div className="all_user_select">
            <select name="" id="" className="filter_____Admin mx-3 px-4">
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
            </select>
          </div>
        </div>
      </div> */}
      <section className="User___Activity">
        <div className="title__activity">
          <h4 className="activity_heading">
            <b>Create a Meeting </b>
          </h4>
        </div>
        <div className=" Add_____notes mb-3">
          <div className="get-started-cls">
            <button
              data-toggle="modal"
              data-target="#loginForm"
              onClick={() =>
                setcreateMeetingModal({
                  modal: CreateMeetingModule,
                  show: true,
                })
              }
              type="button"
              className="btn btn-primary"
              style={{
                padding: "0rem",
              }}
            >
              Create a Meeting
            </button>
          </div>
        </div>
        <div className="title__activity">
          <h6 className="activity_heading">Upcoming Meetings</h6>
        </div>
        {isLoading ? (
          <div className="loader">
            <TailSpin ariaLabel="Loading..." color="#00BFFF" />
          </div>
        ) : UserMeetingData && UserMeetingData.length > 0 ? (
          UserMeetingData &&
          UserMeetingData.map((meet, id) => (
            <div key={meet.self_meeting_id}>
              <div className="accordion pb-3" id={`accordionExample${id}`}>
                <div className="accordion-item Item____Radius px-2">
                  <h2 className="accordion-header" id={`headingOne${id}`}>
                    <button
                      className="accordion-button collapsed Item____Radius"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapseOne${id}`}
                      aria-expanded="false"
                      aria-controls={`collapseOne${id}`}
                    >
                      <span className="w-auto d-flex">
                        <span className="me-3 icon_circle">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="blue"
                            className="bi bi-journal-text"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                            <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                            <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                          </svg>
                        </span>
                        <span className="task_created">
                          Meeting Created by <br></br> <b> Esther Howard</b>
                        </span>
                      </span>
                      <span
                        className="user__Task__list "
                        style={{ fontSize: "12px" }}
                      >
                        <span className="mx-1 due"> Due:</span>

                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="#6F7E8E"
                            className="bi bi-calendar"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                          </svg>
                        </span>
                        <span className="day_time">
                          {formatDate(meet.date)},{formatTime(meet.timing)}
                        </span>
                      </span>
                    </button>
                  </h2>
                  <div
                    id={`collapseOne${id}`}
                    className="accordion-collapse collapse "
                    aria-labelledby={`headingOne${id}`}
                    data-bs-parent={`#accordionExample${id}`}
                  >
                    <div className="accordion-body">
                      <div className="activity_item">
                        <div className="row">
                          <div
                            className="Activity__item_Select me-1 mt-1"
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "0",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-telephone"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                          </div>
                          <div className="col">
                            <p className="internal_heading">
                              <b>Prepare quote for Jerome Bell</b>
                            </p>
                            <p className="internal_heading">{meet.topic}</p>
                          </div>
                        </div>
                        <div className="row Activity__item__box_input">
                          <div className="col  Activity__item__gridVIew">
                            <label htmlFor="" className="html_for">
                              Reminder
                            </label>
                            <select className="select_options" name="" id="">
                              <option value="1">20 min before</option>
                              <option value="3">1</option>
                              <option value="2">2</option>
                              <option value="4">3</option>
                              <option value="5">4</option>
                              <option value="6">5</option>
                            </select>
                          </div>
                          <div className="col  Activity__item__gridVIew">
                            <label htmlFor="" className="html_for">
                              Duration
                            </label>
                            <select className="select_options" name="" id="">
                              <option value="1">1 Hour</option>
                              <option value="3">2 Hour</option>
                            </select>
                          </div>
                          <div className="col d-grid">
                            <label htmlFor="" className="html_for">
                              Attendance
                            </label>
                            <select className="select_options" name="" id="">
                              <option value="1">
                                Esther Howard and +3 more
                              </option>
                              <option value="3">aaaa</option>
                              <option value="2">ssss</option>
                              <option value="4">dddd</option>
                              <option value="5">ffff</option>
                              <option value="6">gggg</option>
                            </select>
                          </div>
                          <hr className="task_hr" />

                          <div>
                            <span>Notes</span>
                            <p className="internal_para">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Necessitatibus placeat dolor aut nisi
                              magnam, ducimus omnis labore commodi, autem
                              perferendis, eveniet non quas sit voluptate
                              accusamus officia repellendus quod minus!
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="get-started-cls text-end mb-3 mt-0"
                        style={{ padding: "1%" }}
                      >
                        <button
                          type="button"
                          onClick={() =>
                            handlCancelMeeting(meet.self_meeting_id)
                          }
                          className="find-btn btn btn-primary"
                        >
                          Cancel Meeting
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className=" Add_____notes mb-3 p-4 text-center">
            <h2>No Meeting To display</h2>
          </div>
        )}
        {createMeetingModal?.modal && (
          <createMeetingModal.modal
            createMeetingModal={createMeetingModal}
            setcreateMeetingModal={setcreateMeetingModal}
            menteeListingData={menteeListingData}
          />
        )}
      </section>
    </>
  );
};

export default Meeting;
