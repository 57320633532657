import { useState } from 'react'
import Select from 'react-select'
import './rating.css'
import smileFace from '../../assets/images/smile-face.png'
import smileFaceGreen from '../../assets/images/smile-face-green.png'
import sadFace from '../../assets/images/sad-face.png'
import sadFaceRed from '../../assets/images/sad-face-red.png'
import neutralFace from '../../assets/images/neutral-face.png'
import neutralFaceYellow from '../../assets/images/neutral-face-yellow.png'
import star from '../../assets/images/review-star.png'
import starGolden from '../../assets/images/star-golden.png'
import { Button } from 'react-bootstrap'
import { createReview } from '../../network'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const relevantKeywordsOptions = [
    { value: "Positive attitude", label: "Positive attitude" },
    { value: "Tactful", label: "Tactful" },
    { value: "Mindful", label: "Mindful" },
    { value: "Compassionate", label: "Compassionate" },
    { value: "Patient", label: "Patient" },
    { value: "Flexible", label: "Flexible" },
    { value: "Collaborative", label: "Collaborative" },
    { value: "Problem-solving", label: "Problem-solving" },
    { value: "Effective communication", label: "Effective communication" },
    { value: "Emotionally intelligent", label: "Emotionally intelligent" },
    { value: "Self-aware", label: "Self-aware" },
    { value: "Calm under pressure", label: "Calm under pressure" },
    { value: "Empowering", label: "Empowering" },
    { value: "Trustworthy", label: "Trustworthy" },
    { value: "Constructive", label: "Constructive" },
    { value: "Grateful", label: "Grateful" },
    { value: "Optimistic", label: "Optimistic" },
    { value: "Adaptive", label: "Adaptive" },
    { value: "Resilient", label: "Resilient" },
    { value: "Attentive", label: "Attentive" },
    { value: "Proactive", label: "Proactive" },
    { value: "Resourceful", label: "Resourceful" },
    { value: "Self-motivated", label: "Self-motivated" },
    { value: "Accountable", label: "Accountable" },
    { value: "Team player", label: "Team player" },
    { value: "Detail-oriented", label: "Detail-oriented" },
    { value: "Innovative", label: "Innovative" },
    { value: "Ethical", label: "Ethical" },
    { value: "Motivated", label: "Motivated" },
    { value: "Inspiring", label: "Inspiring" },
]


export default function Rating(props) {
    // mentorReviews setmentorReview
    const [active, setActive] = useState({})
    const [page, setPage] = useState(1)
    const [review, setReview] = useState('')
    const [overallExperience, setOverallExperience] = useState({})
    const [relevantKeywords, setRelevantKeywords] = useState([])
    const params = useParams()
    const menteeId = useSelector((state) => state.userDetailsReducer.request)
    const notify = (value) => toast(value);
    function handlePage(action) {
        if (action === 'back')
            setPage(page - 1)
        else if (action === 'continue')
        {  
             if(page === 1 && (active.hasOwnProperty('Motivational') == false || active.hasOwnProperty('Communication') == false))       
             {  
                notify("Kindly fill the details")
                return
             }       

             if(page === 2 && (active.hasOwnProperty('Subject Knowledge') == false || active.hasOwnProperty('Problem Solving') == false))       
             {  
                notify("Kindly fill the details")
                return
             }  

             if(page === 3 && review === ""){
                notify("Kindly give a brief review")
                return
             }
             setPage(page + 1)
                
        }
    }

    function handleRating(e, category) {
        let id = e.target.id
        setActive(prev => {
            return { ...prev, [category]: id }
        })
    }


    function handleReviewChange(e) {
        setReview(e.target.value)
    }

    function handleSelectChange(e) {
        setOverallExperience(e)
    }

    function handleRelevantKeywords(event) {
        let keywords = []
        for (let i in event) {
            keywords.push(event[i].value)
        }
        setRelevantKeywords(keywords)

    }

    async function handleSubmit() {
        if(active.hasOwnProperty('Rating') == false){
            toast("Kindly Provide a Rating")
            return
        }
        let payload = {
            mentor_id: params.user.split("_")[1],
            mentee_id: menteeId,
            review_area_rating: {
                "Communication": active["Communication"],
                "Motivational": active["Motivational"],
                "Subject Knowledge": active["Subject Knowledge"],
                "Problem Solving": active["Problem Solving"]
            },
            review: review,
            overall_experience: overallExperience.value,
            relevant_keywords: relevantKeywords,
            rating: active.Rating,
            review_type: "MentorReview"
        }
        if (props.handlePopUp)
            props.handlePopUp()

        const currentReview = await createReview(payload)
        if(currentReview)
        {props.setmentorReviews(prev => [...prev,currentReview.data ])
        props.setdisabled(true)
        }
    }

    return (
        <div className='d-flex flex-column ms-3'>
            <h3>Tell us about your experience</h3>
            <p className='mt-1'>Take a minute to reflect on your session and share a quick review for mentor</p>

            <div className='d-flex align-items-center mt-2'>
                <div
                    className='page-dots'
                    style={{ background: page === 1 ? 'linear-gradient(13deg, #d96980, #62389b) border-box' : '#00000033' }}></div>
                <div
                    className='page-dots'
                    style={{ background: page === 2 ? 'linear-gradient(13deg, #d96980, #62389b) border-box' : '#00000033' }}></div>
                <div
                    className='page-dots'
                    style={{ background: page === 3 ? 'linear-gradient(13deg, #d96980, #62389b) border-box' : '#00000033' }}></div>
                <div
                    className='page-dots'
                    style={{ background: page === 4 ? 'linear-gradient(13deg, #d96980, #62389b) border-box' : '#00000033' }}></div>
            </div>
            <div style={{ display: page === 1 ? 'block' : 'none' }} >
                <div className='mt-2'>
                    <h4>Motivational</h4>
                    <p className='mt-0'>How would you rate their approach?</p>
                    <div className="d-flex">
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Motivational'] === '1' ? sadFaceRed : sadFace}
                                id='1'
                                onClick={(e) => handleRating(e, 'Motivational')}
                                className='face-img' />
                            <p className='mt-0'>Not Great</p>
                        </div>
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Motivational'] === '2' ? neutralFaceYellow : neutralFace}
                                id='2'
                                onClick={(e) => handleRating(e, 'Motivational')}
                                className='face-img' />
                            <p className='mt-0'>Average</p>
                        </div>
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Motivational'] === '3' ? smileFaceGreen : smileFace}
                                id='3'
                                onClick={(e) => handleRating(e, 'Motivational')}
                                className='face-img' />
                            <p className='mt-0'>Amazing</p>
                        </div>
                    </div>
                </div>
                <div className='mt-2'>
                    <h4>Communication</h4>
                    <p className='mt-0'>How would you rate their communication skills?</p>
                    <div className="d-flex">
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Communication'] === '1' ? sadFaceRed : sadFace}
                                id='1'
                                onClick={(e) => handleRating(e, 'Communication')}
                                className='face-img' />
                            <p className='mt-0'>Not Great</p>
                        </div>
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Communication'] === '2' ? neutralFaceYellow : neutralFace}
                                id='2'
                                onClick={(e) => handleRating(e, 'Communication')}
                                className='face-img' />
                            <p className='mt-0'>Average</p>
                        </div>
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Communication'] === '3' ? smileFaceGreen : smileFace}
                                id='3'
                                onClick={(e) => handleRating(e, 'Communication')}
                                className='face-img' />
                            <p className='mt-0'>Amazing</p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: page === 2 ? 'block' : 'none' }}>
                <div className='mt-2'>
                    <h4>Subject Knowledge</h4>
                    <p className='mt-0'>How would you rate their technical understanding?</p>
                    <div className="d-flex">
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Subject Knowledge'] === '1' ? sadFaceRed : sadFace}
                                id='1'
                                onClick={(e) => handleRating(e, 'Subject Knowledge')}
                                className='face-img' />
                            <p className='mt-0'>Not Great</p>
                        </div>
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Subject Knowledge'] === '2' ? neutralFaceYellow : neutralFace}
                                id='2'
                                onClick={(e) => handleRating(e, 'Subject Knowledge')}
                                className='face-img' />
                            <p className='mt-0'>Average</p>
                        </div>
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Subject Knowledge'] === '3' ? smileFaceGreen : smileFace}
                                id='3'
                                onClick={(e) => handleRating(e, 'Subject Knowledge')}
                                className='face-img' />
                            <p className='mt-0'>Amazing</p>
                        </div>
                    </div>
                </div>
                <div className='mt-2'>
                    <h4>Problem Solving</h4>
                    <p className='mt-0'>How would you rate their ability to drive solutions?</p>
                    <div className="d-flex">
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Problem Solving'] === '1' ? sadFaceRed : sadFace}
                                id='1'
                                onClick={(e) => handleRating(e, 'Problem Solving')}
                                className='face-img' />
                            <p className='mt-0'>Not Great</p>
                        </div>
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Problem Solving'] === '2' ? neutralFaceYellow : neutralFace}
                                id='2'
                                onClick={(e) => handleRating(e, 'Problem Solving')}
                                className='face-img' />
                            <p className='mt-0'>Average</p>
                        </div>
                        <div className='d-flex align-items-center flex-column'>
                            <img
                                src={active['Problem Solving'] === '3' ? smileFaceGreen : smileFace}
                                id='3'
                                onClick={(e) => handleRating(e, 'Problem Solving')}
                                className='face-img' />
                            <p className='mt-0'>Amazing</p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: page === 3 ? 'block' : 'none' }}>
                <div className='mt-2'>
                    <h4>Overall Experience</h4>
                    <p className='mt-0'>How effective was the mentorship session as compared to your expectations?</p>
                    <Select
                        className='mt-3'
                        styles={{ container: (base) => ({ ...base, width: '400px' }) }}
                        classNamePrefix="select"
                        placeholder='Select an option'
                        onChange={handleSelectChange}
                        name="color"
                        options={[
                            { value: 'Above expectations', label: 'Above expectations' },
                            { value: 'Average', label: 'Average' },
                            { value: 'Below expectations', label: 'Below expectations' }
                        ]}
                    />
                </div>
                <div className='mt-2'>
                    <h4>Public review</h4>
                    <p className='mt-0'>What are the highlights of your session?</p>
                    <div >
                        <textarea
                            className='mt-3'
                            style={{ width: '400px', height: '100px' }}
                            onChange={handleReviewChange}
                            value={review}
                            placeholder='Share some insights about mentorship session. Areas that the mentor helped you with,Their overall behaviour and approach'
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: page === 4 ? 'block' : 'none' }}>
                <div className='mt-2'>
                    <h4>Add relevent keywords</h4>
                    {/* <p className='mt-0'>How would you rate their technical understanding?</p> */}

                    <Select
                        className='mt-3'
                        styles={{ container: (base) => ({ ...base, width: '400px' }) }}
                        classNamePrefix="select"
                        placeholder='Select an option'
                        onChange={handleRelevantKeywords}
                        isMulti
                        name="color"
                        options={relevantKeywordsOptions}
                    />

                </div>
                <div className='mt-2'>
                    <h4>Rating</h4>
                    <p className='mt-0'>What are the highlights of your session?</p>
                    <div >
                        <div className="d-flex">
                            <div className='d-flex align-items-center flex-column'>
                                <img
                                    src={active['Rating'] >= '1' ? starGolden : star}
                                    id='1'
                                    onClick={(e) => handleRating(e, 'Rating')}
                                    className='face-img' />
                                <p className='mt-0'>Not Great</p>
                            </div>
                            <div className='d-flex align-items-center flex-column'>
                                <img
                                    src={active['Rating'] >= '2' ? starGolden : star}
                                    id='2'
                                    onClick={(e) => handleRating(e, 'Rating')}
                                    className='face-img' />
                                <p className='mt-0'>Average</p>
                            </div>
                            <div className='d-flex align-items-center flex-column'>
                                <img
                                    src={active['Rating'] >= '3' ? starGolden : star}
                                    id='3'
                                    onClick={(e) => handleRating(e, 'Rating')}
                                    className='face-img' />
                                <p className='mt-0'>Amazing</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex'>

                {page !== 1 && <button className="btn btn-danger mt-3 ms-2" onClick={() => handlePage('back')}>{`<`} Back</button>}
                {page !== 4 && <button className="btn btn-danger mt-3 ms-2" onClick={() => handlePage('continue')}>Continue</button>}
                {page === 4 && <button className="btn btn-danger mt-3 ms-2" onClick={handleSubmit}>Submit</button>}

            </div>
            <ToastContainer />
        </div>
    )
}