import React, { useEffect } from "react";

import {
  menuIcon,
  bookImg,
  crossBtn,
  fbLogo,
  twitterLogo,
  linkedInLogo,
  instaLogo,
  youtubeLogo,
} from "../../../assets/images/export-images";

import bellIcon from "../images/bell.png";
import arrowDownImg from "../images/arrow-down.png";
import priceImg from "../images/price.png";
import preSess1Img from "../images/pre-sess-1.png";
import preSess2Img from "../images/pre-sess-2.png";
import preSess3Img from "../images/pre-sess-3.png";
import dotImg from "../images/dot.png";
import book1Img from "../images/book-1.png";
import book2Img from "../images/book-2.png";
import bookMarkImg from "../images/bookmark.png";
import removeImg from "../images/remove.png";
import articaleImg from "../images/article.png";
import DashboardHeader from "../dashboardHeader";

const Ebooks = () => {
  useEffect(() => {
    import("../css/bootstrap.min.css");
    import("../css/font-awesome.min.css");
    import("../css/owl.carousel.min.css");
    import("../css/owl.theme.default.min.css");
    import("../css/custom.css");
    import("../css/header.css");
    import("../css/dashboard.css");
    import("../css/manage-ebook.css");
    import("../css/footer.css");
    import("../css/media.css");
  }, []);

  return (
    <>
      <DashboardHeader />
      {/* <!-- Welcome Section --> */}
      <main>
        <section className="welcome-sec">
          <div className="container">
            <div className="welcome-inner">
              <div className="welcome-left-sec">
                <h3>Manage e-Books</h3>
                <p>
                  Bookmark, Download & Manage your favorite books and article
                </p>
              </div>
              <div className="welcome-right-sec dropdown">
                <button
                  type="button"
                  className="btn notification-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src={bellIcon} /> Notification{" "}
                  <span className="no-of-notification">5</span>
                </button>
                <div
                  className="notification-dropdown dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div className="notification-inner">
                    <span className="time">1 day </span>
                    <h6>Lorem Ipsum</h6>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                  <div className="notification-inner">
                    <span className="time">1 day </span>
                    <h6>Lorem Ipsum</h6>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                  <div className="notification-inner">
                    <span className="time">1 day </span>
                    <h6>Lorem Ipsum</h6>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                  <div className="notification-inner">
                    <span className="time">1 day </span>
                    <h6>Lorem Ipsum</h6>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ebook-manage-cls">
              <div className="ebook-download-sec">
                <div className="ebook-bg">
                  <div className="ebbok-title-cls">
                    <h3>Your Downloads</h3>
                  </div>
                  <div className="single-book-cls">
                    <div className="signle-book-left">
                      <div className="prd-book-dtl">
                        <div className="book-img">
                          <img src={book1Img} alt="" />
                        </div>
                        <div className="book-cont">
                          <h5>The Sin Eater</h5>
                          <p>Author: Indira Priya Darshini</p>
                          <p className="price-p">Price: $50</p>
                        </div>
                      </div>
                      <div className="book-download-sec">
                        <p>Buying Date : 24 March’22</p>
                        <button type="button" className="btn btn-green">
                          Download Again
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="single-book-cls">
                    <div className="signle-book-left">
                      <div className="prd-book-dtl">
                        <div className="book-img">
                          <img src={book1Img} alt="" />
                        </div>
                        <div className="book-cont">
                          <h5>The Sin Eater</h5>
                          <p>Author: Indira Priya Darshini</p>
                          <p className="price-p">Price: $50</p>
                        </div>
                      </div>
                      <div className="book-download-sec">
                        <p>Buying Date : 24 March’22</p>
                        <button type="button" className="btn btn-green">
                          Download Again
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ebook-right-sec">
                <div className="bookmarks">
                  <div className="bookmark-title">
                    <h3>
                      <span className="bookmark-ic">
                        <img src={bookMarkImg} />
                      </span>
                      Bookmarks
                    </h3>
                  </div>
                  <div className="bookmark-cont">
                    <div className="prd-book-dtl">
                      <div className="book-img">
                        <img src={book1Img} alt="" />
                      </div>
                      <div className="book-cont">
                        <h5>
                          The Sin Eater
                          <span className="remove-ic">
                            <a href="#">
                              <img src={removeImg} />
                            </a>
                          </span>
                        </h5>
                        <p>Author: Indira Priya Darshini</p>
                        <p className="price-p">Price: $50</p>
                      </div>
                    </div>
                    <div className="prd-book-dtl">
                      <div className="book-img">
                        <img src={book2Img} alt="" />
                      </div>
                      <div className="book-cont">
                        <h5>
                          The Sin Eater
                          <span className="remove-ic">
                            <a href="#">
                              <img src={removeImg} />
                            </a>
                          </span>
                        </h5>
                        <p>Author: Indira Priya Darshini</p>
                        <p className="price-p">Price: $50</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="article">
                  <div className="bookmark-title">
                    <h3>
                      <span className="bookmark-ic">
                        <img src={articaleImg} />
                      </span>
                      Saved Articles
                      <span className="right-menu">
                        <img src={dotImg} />
                      </span>
                    </h3>
                  </div>
                  <div className="article-cont">
                    <div className="auuthor">
                      <h5>
                        You can master Java script only if you are logically
                        strong and you.....
                      </h5>
                      <span>Author: Indira Priya Darshini</span>
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500..
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- Footer --> */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-6 col-sm-6 col-md-3">
              <div className="met_fot1">
                <h4>MentorPal</h4>
                <p>Best mentorship and guidance under one roof.</p>
                <h6>Follow us:</h6>
                <div className="foot_ican">
                  <ul>
                    <li>
                      <a href="#">
                        <img src={fbLogo} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={twitterLogo} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={linkedInLogo} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={instaLogo} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={youtubeLogo} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div className="met_fot1">
                <h5 className="footer-title">Services</h5>
                <div className="foot_cont">
                  <ul className="footer-menu">
                    <li>
                      <a href="/find-mentor">Explore Mentors</a>
                    </li>
                    <li>
                      <a href="#">Book a Session</a>
                    </li>
                    <li>
                      <a href="/apply-as-mentor">Apply as a mentor</a>
                    </li>
                    <li>
                      <a href="#">Group Mentorship</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div className="met_fot1 met_fot2">
                <h5 className="footer-title">Get in Touch</h5>
                <div className="foot_cont">
                  <p>#D1004,Goyal Orchid Whitefield
                    <br /> Bengaluru,Karnataka (560066)
                    <br /> India
                  </p>
                </div>
                <div className="foot_cont">
                  <a href="mailto:help@mentorpal.ai">help@mentorpal.ai</a>
                  <a href="tel:9660367722">+91 8619825569 </a>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div className="met_fot1">
                <h5 className="footer-title">About MentorPal</h5>
                <div className="foot_cont">
                  <ul className="footer-menu">
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>

                    <li>
                      <a href="#">Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="#">Mail us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright">
        <div className="container">
          <div className="copyright-cont text-center">
            <p>2022 All rights are reserved @Mentorpal Company Ltd.</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ebooks;
