import React from 'react';

const PendingQueries = () => {
    const data = true;
    return (
        <>
            {
                data ? (
                    <>
                        <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
                            <div className="ant-row queries ">
                                <div className="ant-col ant-col-xs-0 ant-col-md-24 ">
                                    <div className="ant-row ">
                                        <div id="el" className="ant-col ant-col-6 query-listing ">
                                            <div className="ant-row ">
                                                <div className="ant-col queries-select ant-col-xs-24 ant-col-md-24 ">
                                                    <div className="ant-select ant-select-lg  ant-select-single ant-select-show-arrow">
                                                        <div className="ant-select-selector">
                                                            <span className="ant-select-selection-search">
                                                                <input
                                                                    type="search"
                                                                    autoComplete="off"
                                                                    className="ant-select-selection-search-input"
                                                                    role="combobox"
                                                                    aria-expanded="false"
                                                                    aria-haspopup="listbox"
                                                                    aria-owns="rc_select_67_list"
                                                                    aria-autocomplete="list"
                                                                    aria-controls="rc_select_67_list"
                                                                    readOnly=""
                                                                    unselectable="on"
                                                                    defaultValue=""
                                                                    style={{ opacity: 0 }}
                                                                    id="rc_select_67"
                                                                />
                                                            </span>
                                                            <span className="ant-select-selection-item" title="All DMs">
                                                                All DMs
                                                            </span>
                                                        </div>
                                                        <span
                                                            className="ant-select-arrow"
                                                            unselectable="on"
                                                            aria-hidden="true"
                                                            style={{ userSelect: "none" }}
                                                        >
                                                            <span
                                                                role="img"
                                                                aria-label="down"
                                                                className="anticon anticon-down ant-select-suffix"
                                                            >
                                                                <svg
                                                                    viewBox="64 64 896 896"
                                                                    focusable="false"
                                                                    data-icon="down"
                                                                    width="1em"
                                                                    height="1em"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ant-row ">
                                                <div className="ant-col query-list-item active ant-col-xs-24 ant-col-md-0 ">
                                                    <div className="ant-typography query-list-title-section ">
                                                        <span className="ant-typography query-user-name ">
                                                            Ankit
                                                        </span>
                                                        <span className="ant-typography query-user-subtitle ">
                                                            23:06
                                                        </span>
                                                    </div>
                                                    <div className="ant-typography ">
                                                        <span className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line query-user-subtitle ">
                                                            mentorship
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="ant-col query-list-item active ant-col-xs-0 ant-col-md-24 ">
                                                    <div className="ant-typography query-list-title-section ">
                                                        <span className="ant-typography query-user-name ">
                                                            Ankit
                                                        </span>
                                                        <span className="ant-typography query-user-subtitle ">
                                                            23:06
                                                        </span>
                                                    </div>
                                                    <div className="ant-typography ">
                                                        <span className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line query-user-subtitle ">
                                                            mentorship
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ant-col ant-col-17 ant-col-offset-1 ">
                                            <div className="ant-row ant-row-middle ">
                                                <div className="ant-col query-header-section ant-col-xs-24 ant-col-md-18 ">
                                                    <img
                                                        alt="Query Img"
                                                        loading="lazy"
                                                        width={40}
                                                        height={40}
                                                        decoding="async"
                                                        data-nimg={1}
                                                        className="query-user-img"
                                                        srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Fqueries%2Ffollower_query.png&w=48&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Fqueries%2Ffollower_query.png&w=96&q=75 2x"
                                                        src="/_next/image?url=%2Fimages%2Fdashboard%2Fqueries%2Ffollower_query.png&w=96&q=75"
                                                        style={{ color: "transparent", height: "auto" }}
                                                    />
                                                    <div className="ant-typography ">
                                                        <div className="ant-typography query-user-name ">
                                                            Ankit
                                                        </div>
                                                        <div className="ant-typography query-user-subtitle ">
                                                            codewithankit047@gmail.com | +919467943789
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ant-col header-tags ant-col-xs-0 ant-col-md-6 ">
                                                    <div className="ant-space  ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small">
                                                        <div className="ant-space-item">
                                                            <span className="ant-typography time-left-tag ">
                                                                <span
                                                                    role="img"
                                                                    aria-label="clock-circle"
                                                                    className="anticon anticon-clock-circle"
                                                                >
                                                                    <svg
                                                                        viewBox="64 64 896 896"
                                                                        focusable="false"
                                                                        data-icon="clock-circle"
                                                                        width="1em"
                                                                        height="1em"
                                                                        fill="currentColor"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                                                        <path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z" />
                                                                    </svg>
                                                                </span>{" "}
                                                                a day
                                                            </span>
                                                        </div>
                                                        <div className="ant-space-item">
                                                            <span className="ant-typography public-profile-pricing ">
                                                                Free
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ant-col ant-col-24 query-details-section ">
                                                    <div className="ant-typography query-value ">
                                                        <span className="ant-typography query-details-title ">
                                                            Priority DM
                                                        </span>
                                                        <span className="ant-typography query-details-text ">
                                                            mentorship
                                                        </span>
                                                    </div>
                                                    <div className="ant-typography query-questions " />
                                                    <form className="ant-form ant-form-horizontal ">
                                                        <div className="ant-form-item ">
                                                            <div className="ant-row ant-form-item-row ">
                                                                <div className="ant-col ant-form-item-control ">
                                                                    <div className="ant-form-item-control-input">
                                                                        <div className="ant-form-item-control-input-content">
                                                                            <textarea
                                                                                name="query-input"
                                                                                placeholder="Your Response"
                                                                                id="input_query"
                                                                                aria-required="true"
                                                                                className="ant-input  text-area"
                                                                                style={{
                                                                                    overflowY: "hidden",
                                                                                    resize: "none",
                                                                                    height: 206,
                                                                                    minHeight: 206,
                                                                                    maxHeight: 494
                                                                                }}
                                                                                defaultValue={""}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ant-space  ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small">
                                                            <div className="ant-space-item">
                                                                <button
                                                                    type="submit"
                                                                    className="ant-btn  ant-btn-default btn-dark"
                                                                >
                                                                    <span>Send reply</span>
                                                                </button>
                                                            </div>
                                                            <div className="ant-space-item">
                                                                <button
                                                                    type="button"
                                                                    className="ant-btn  ant-btn-default ant-btn-dangerous"
                                                                >
                                                                    <span>Discard DM</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ant-col ant-col-xs-24 ant-col-md-0 ">
                                    <div id="elmobile" className="ant-col query-listing ">
                                        <div className="ant-row ">
                                            <div className="ant-col queries-select ant-col-xs-24 ant-col-md-24 ">
                                                <div className="ant-select ant-select-lg  ant-select-single ant-select-show-arrow">
                                                    <div className="ant-select-selector">
                                                        <span className="ant-select-selection-search">
                                                            <input
                                                                type="search"
                                                                autoComplete="off"
                                                                className="ant-select-selection-search-input"
                                                                role="combobox"
                                                                aria-expanded="false"
                                                                aria-haspopup="listbox"
                                                                aria-owns="rc_select_68_list"
                                                                aria-autocomplete="list"
                                                                aria-controls="rc_select_68_list"
                                                                readOnly=""
                                                                unselectable="on"
                                                                defaultValue=""
                                                                style={{ opacity: 0 }}
                                                                id="rc_select_68"
                                                            />
                                                        </span>
                                                        <span className="ant-select-selection-item" title="All DMs">
                                                            All DMs
                                                        </span>
                                                    </div>
                                                    <span
                                                        className="ant-select-arrow"
                                                        unselectable="on"
                                                        aria-hidden="true"
                                                        style={{ userSelect: "none" }}
                                                    >
                                                        <span
                                                            role="img"
                                                            aria-label="down"
                                                            className="anticon anticon-down ant-select-suffix"
                                                        >
                                                            <svg
                                                                viewBox="64 64 896 896"
                                                                focusable="false"
                                                                data-icon="down"
                                                                width="1em"
                                                                height="1em"
                                                                fill="currentColor"
                                                                aria-hidden="true"
                                                            >
                                                                <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ant-row ">
                                            <div className="ant-col query-list-item active ant-col-xs-24 ant-col-md-0 ">
                                                <div className="ant-typography query-list-title-section ">
                                                    <span className="ant-typography query-user-name ">
                                                        Ankit
                                                    </span>
                                                    <span className="ant-typography query-user-subtitle ">
                                                        23:06
                                                    </span>
                                                </div>
                                                <div className="ant-typography ">
                                                    <span className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line query-user-subtitle ">
                                                        mentorship
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="ant-col query-list-item active ant-col-xs-0 ant-col-md-24 ">
                                                <div className="ant-typography query-list-title-section ">
                                                    <span className="ant-typography query-user-name ">
                                                        Ankit
                                                    </span>
                                                    <span className="ant-typography query-user-subtitle ">
                                                        23:06
                                                    </span>
                                                </div>
                                                <div className="ant-typography ">
                                                    <span className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line query-user-subtitle ">
                                                        mentorship
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (

                    <>
                        <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
                            <div
                                className="ant-row ant-row-center ant-row-middle "
                                style={{ marginTop: 30 }}
                            >
                                <div className="ant-col text-center ant-col-xs-24 ant-col-md-10 ">
                                    <img
                                        alt="No Pending/Answered Queries"
                                        loading="lazy"
                                        width={298}
                                        height={194}
                                        decoding="async"
                                        data-nimg={1}
                                        className=""
                                        srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Fservices%2Fimg-query-empty-state.svg&w=384&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Fservices%2Fimg-query-empty-state.svg&w=640&q=75 2x"
                                        src="/_next/image?url=%2Fimages%2Fdashboard%2Fservices%2Fimg-query-empty-state.svg&w=640&q=75"
                                        style={{ color: "transparent", height: "auto", marginBottom: 10 }}
                                    />
                                    <div className="ant-space  ant-space-vertical ant-space-align-center ant-space-gap-row-middle ant-space-gap-col-middle">
                                        <div className="ant-space-item">
                                            <h5
                                                className="ant-typography "
                                                style={{ marginBottom: 0, marginTop: 16 }}
                                            >
                                                Try Priority DM
                                            </h5>
                                        </div>
                                        <div className="ant-space-item">
                                            <div className="ant-typography " style={{ margin: 0 }}>
                                                Priority DM allows you to accept DM requests without revealing
                                                your information and reply seamlessly through <b>WhatsApp</b>.
                                            </div>
                                        </div>
                                        <div className="ant-space-item">
                                            <button
                                                type="button"
                                                className="ant-btn  ant-btn-primary btn-dark"
                                                style={{ marginTop: 8 }}
                                            >
                                                <span>Add Priority DM</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>

    );
}

export default PendingQueries;
