import React, { useEffect, useState } from 'react';
import MasterHeader from '../MasterHeader/MasterHeader';
import { bannerImg } from '../../../assets/images/export-images';
import companies from '../assets/companies.png'
import './coursedetails.css'
import MasterFooter from '../MasterFooter';
import { Course_details_faqData, Profile_hosts, benefitsData, companyLogos, course_details, featuresData, hosts, testimonialsData } from '../data';
import GetMore from '../GetMore';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fullStackAction } from '../../../redux/actions/common';
import CallBackModal from '../ApplyCourseModal/CallBack';
import TopMentor from '../../home/TopMentor';
import { useNavigate, useParams } from 'react-router-dom';
import Testimonial from '../../Testimonial/testimonial';
import { getCourseDetailsAction } from '../../../redux/actions/course';
import CountdownTimer from '../CountDownTimer';
const CourseDetails = () => {
    const navigate = useNavigate();
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [applyCourseModal, setApplyCourseModal] = useState(false);
    const [callbackModal, setcallbackModal] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 40 * parseFloat(getComputedStyle(document.documentElement).fontSize)) {
            setNavbarVisible(true);
        } else {
            setNavbarVisible(false);
        }
    };


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const dispatch = useDispatch();
    const [successMsg, setSuccessMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const [ProductId, setProductId] = useState('');
    const [loader, setloader] = useState(true);
    const [data, setData] = useState({});
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');



    const { slug, product_id } = useParams();
    useEffect(() => {
        setProductId(product_id);
    }, []);

    useEffect(() => {
        console.log("ProductId", ProductId);
        dispatch(getCourseDetailsAction(ProductId))
    }, [ProductId, dispatch]);

    const getCourseDetailsReducer = useSelector(
        (state) => state.getCourseDetailsReducer
    )
    useEffect(() => {
        setloader(getCourseDetailsReducer?.loading)
        setData(getCourseDetailsReducer?.data)
        setStatus(getCourseDetailsReducer?.status)
        setError(getCourseDetailsReducer?.error)
    }, [getCourseDetailsReducer]);
    useEffect(() => {
        console.log("data top", data)
    }, [data]);



    const [callBackForm, setcallBackForm] = useState({
        name: "",
        email: "",
        mobile: "",
        inquiry_type: `${data?.slug + "_" + data?.product_id}_call_back`,
        message: `${data?.slug + " " + data?.product_id + " "} call_back`,
    })
    const handleCallBack = (e) => {
        e.preventDefault();

        if (
            callBackForm.name.trim() !== '' &&
            callBackForm.email.trim() !== '' &&
            callBackForm.mobile.trim() !== ''
        ) {

            dispatch(fullStackAction(callBackForm)).then(() => {
                setloader(false);
                setTimeout(() => {
                    setSuccessMsg(null);
                }, 2000);
            });
        } else {
            setErrorMsg('Please enter all details');
            setTimeout(() => {
                setErrorMsg(null);
            }, 2000);
        }
    };
    const callBackFromStatus = useSelector(
        (state) => state.fullStackReducer?.status
    );

    useEffect(() => {
        if (callBackFromStatus === 200) {
            setSuccessMsg('Form submitted successfully');
            setTimeout(() => {
                setSuccessMsg(null);
            }, 2000);
        } else if (callBackFromStatus !== 200 && callBackFromStatus === undefined) {
            setErrorMsg('Please try again later');
            setTimeout(() => {
                setErrorMsg(null);
            }, 2000);
        }
    }, [callBackFromStatus]);

    const [isSticky, setIsSticky] = useState(false);
    const [transitionDuration, setTransitionDuration] = useState('3s'); // Initial duration is 0s

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 90 * parseFloat(getComputedStyle(document.documentElement).fontSize)) {
                setIsSticky(true);
                setTransitionDuration('3.3s'); // Apply the transition duration
            } else {
                setIsSticky(false);
                setTransitionDuration('3s'); // Reset transition duration
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [copySuccess, setCopySuccess] = useState(false);

    const UrlcopyToClipboard = () => {
        const currentURL = window.location.href;

        navigator.clipboard.writeText(currentURL)
            .then(() => {
                setCopySuccess(true);
                setTimeout(() => {
                    setCopySuccess(false);
                }, 2000);
            })
            .catch(err => {
                console.error('Failed to copy URL:', err);
            });
    };
    let date = new Date();
    let datestring = date.toTimeString();
    let countryname =
        datestring?.includes("india") | datestring?.includes("India")
            ? "India"
            : "";


    const handleCheckoutCart = () => {
        navigate(`/product-checkout/${data?.slug}/${data?.product_id}`);

    }




    const [expandedIndexes, setExpandedIndexes] = useState([]);

    const toggleCollapse = (index) => {
        if (expandedIndexes.includes(index)) {
            setExpandedIndexes(expandedIndexes.filter((unit) => unit !== index));
        } else {
            setExpandedIndexes([...expandedIndexes, index]);
        }
    };
    const [inr_partial, setinr_partial] = useState();
    const [dollar_partial, setdollar_partial] = useState();
    useEffect(() => {
        if (data?.discount_price) {
            setinr_partial(
                data?.discount_price * 83.12 * 0.5
            )
            setdollar_partial(
                data?.discount_price * 0.5
            )
        }
    }, [data, countryname]);


    const getLocalTime = (utcTime) => {
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };

        const localTime = new Date(utcTime).toLocaleString("en-US", options);
        return localTime;
    };
    return (
        <>
            {/* Scrollable navbar */}
            {
                loader
                    ? (
                        <div className="loader open_sessions_loader">
                            <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                        </div>
                    ) : (

                        <>
                            <MasterHeader />
                            <>

                                <div style={{ overflowX: "hidden" }}>

                                    <section
                                        className={`banner__Scroolable ${navbarVisible ? 'show__code' : 'hide'}`} id="navbar"
                                        style={{
                                            willChange: "transform",
                                            transform:
                                                "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                            transformStyle: "preserve-3d"
                                        }}
                                    >
                                        <div className="padding-global">
                                            <div className="banner-content-wrap">
                                                <div className="banner-content">
                                                    <div className="banner8_text-wrapper">
                                                        <div className="text-weight-semibold course-topic text-color-white">
                                                            {data?.course_topic}
                                                        </div>
                                                        <div className="meta-group margin-top margin-xxsmall">
                                                            <div className="small-headline text-color-white">{getLocalTime(data?.course_start_date)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="banner8_button-row">
                                                    <a
                                                        // onClick={handleCheckoutCart}
                                                        onClick={handleCheckoutCart}
                                                        className="btn btn-success"
                                                    >
                                                        Apply now
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    {/* Banner section */}
                                    <section
                                        className="banner-sec "
                                        style={{ backgroundImage: `url(${bannerImg})`, padding: " 130px 0px 55px 0px !important" }}
                                    >      <div className="banner-summary container ">
                                            <div className="row">
                                                <div className="col ">
                                                    <div className="cat-wrap">
                                                        <div className="cat-tag live">
                                                            <div className="icon-dot w-embed">
                                                                <svg
                                                                    width={8}
                                                                    height={8}
                                                                    viewBox="0 0 8 8"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <circle cx={4} cy={4} r={4} fill="currentColor" />
                                                                </svg>
                                                            </div>
                                                            <div>Live {data?.document_type}</div>
                                                        </div>
                                                    </div>

                                                    <div className="banner-summary-content">
                                                        <h1>
                                                            {data?.course_topic}
                                                        </h1>
                                                        <div className="margin-bottom margin-small">

                                                            <div className="meta-group margin-top margin-small">
                                                                <div className="small-headline text-color-white">{data?.course_type}</div>
                                                                <div className="small-headline text-color-white">·</div>
                                                                <div className="small-headline text-color-white">{data?.course_time_period}weeks</div>
                                                                <div className="small-headline text-color-white">·</div>
                                                                <div className="small-headline text-color-white">{getLocalTime(data?.course_start_date)}</div>
                                                            </div>
                                                        </div>
                                                        <p className="text-size-medium">
                                                            {data?.course_description}
                                                        </p>

                                                        <div className="get-started-cls d-flex flex-column flex-sm-row " style={{ textAlign: "initial" }}>
                                                            <a onClick={UrlcopyToClipboard}>
                                                                <button type="button" className="find-btn btn btn-primary">
                                                                    <span>
                                                                        Share this course
                                                                    </span>
                                                                    <span>
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={22}
                                                                            height={22}
                                                                            fill="currentColor"
                                                                            className="bi bi-box-arrow-up"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1h-2z"
                                                                            />
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708l3-3z"
                                                                            />
                                                                        </svg>


                                                                    </span>

                                                                </button>
                                                            </a>
                                                            <a onClick={handleCheckoutCart} type="button" className="btn btn-primary d-sm-block mt-2 mt-sm-0">
                                                                Apply now
                                                            </a>
                                                        </div>
                                                        {copySuccess && <p>copied to clipboard!</p>}


                                                    </div>
                                                </div>
                                                <div className=" col ">
                                                    <div className="bnr-img-summary">
                                                        <img
                                                            src={`${process.env.REACT_APP_IMAGES_BASE_URL}${data?.course_banner}`} alt={data?.course_topic}
                                                            loading="lazy"
                                                            className="bnr-img"
                                                        />

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className='container'>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="">
                                                    <div className="margin-bottom margin-small">
                                                        <div className=" host_heading">{data?.document_type} overview</div>
                                                    </div>
                                                    <div className="course-intro">

                                                        <p>
                                                            {data?.course_intro}
                                                        </p>
                                                    </div>
                                                    <div className="course-info-left">
                                                        <div className="margin-bottom margin-small">
                                                            <div className=" host_heading">Hosted by</div>
                                                        </div>
                                                        <div className="d-grid course__host">
                                                            {data?.course_hosted_by.map((host, id) => (
                                                                <a
                                                                    key={id}
                                                                    href="#host"
                                                                    className="host-card w-inline-block"
                                                                >
                                                                    <div className="host-card-left">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_IMAGES_BASE_URL}${host?.image_url}`} alt={host?.name}
                                                                            loading="lazy"
                                                                            className="host-card-image"
                                                                        />
                                                                        <div className="host-card-info">
                                                                            <div className="host-card-title">{host.name}</div>
                                                                            {host.title && (
                                                                                <p>{host.title}</p>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </a>
                                                            ))}
                                                        </div>


                                                    </div>
                                                </div>

                                                {/* what will you learn */}
                                                <div className="">
                                                    <div>

                                                        <div className="margin-bottom margin-small">
                                                            <div className=" host_heading">Key Highlights</div>
                                                        </div>
                                                        <div className="d-grid gap-4 grid-cols-1 sm:grid-cols-2 bg-white">
                                                            {data?.highlights.map((highlight, index) => (
                                                                <div className="d-flex" key={index}>
                                                                    <span className="font-graphy-icons text-title3 text-black"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={25}
                                                                        height={25}
                                                                        fill="currentColor"
                                                                        className="bi bi-patch-check"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                                                        />
                                                                        <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                                                                    </svg>
                                                                    </span>
                                                                    <p className="text-body font-normal leading-title6 text-darkSlate01 ml-2 ">
                                                                        {highlight?.label}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className='pb-3'>
                                                        <div className="margin-bottom margin-small">
                                                            <div className=" host_heading">What you will learn</div>
                                                        </div>
                                                        <div className="d-grid gap-4 grid-cols-1 sm:grid-cols-2 bg-white">
                                                            {data?.what_you_will_learn.map((learnItem, index) => (
                                                                <div className="bg-dust p-4" key={index}>
                                                                    <span className="font-graphy-icons text-title3 text-black"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={25}
                                                                        height={25}
                                                                        fill="currentColor"
                                                                        className="bi bi-patch-check"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                                                        />
                                                                        <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                                                                    </svg>

                                                                    </span>
                                                                    <p className="text-title5 font-semibold leading-title5 text-neutralBlack mb-2 ">
                                                                        {learnItem.label}
                                                                    </p>
                                                                    {learnItem.value && (
                                                                        <p className="text-body font-normal leading-title6 text-darkSlate01 break-words ">

                                                                            {learnItem?.value}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 pt-3" >
                                                <div className="course-detail-card">
                                                    <div className="text-align-center flex vertical spacing-xxsmall">
                                                        {data?.proBono === 'true' ? (
                                                            <>
                                                                <h2 className="heading-style-h4 price-mobile" > {data?.proBono === "true"
                                                                    ? "Pro Bono"
                                                                    : countryname === "India"
                                                                        ? "₹" + (Math.round(data?.price * 83.12))
                                                                        : "$" + (Math.round(data?.price))

                                                                }</h2>
                                                            </>
                                                        ) : (

                                                            <>
                                                                <h2 className="heading-style-h4 price-mobile" style={{ textDecoration: "line-through" }}> {data?.proBono === "true"
                                                                    ? "Pro Bono"
                                                                    : countryname === "India"
                                                                        ? "₹" + (Math.round(data?.price * 83.12))
                                                                        : "$" + (Math.round(data?.price))

                                                                }</h2>
                                                                <span class="badge rounded-pill bg-success">  {((data?.price - data?.discount_price) / data?.price * 100).toFixed(0)}% off</span>
                                                                <div className="heading-style-h4 price-mobile lighter" >{data?.proBono === "true"
                                                                    ? null
                                                                    : countryname === "India"
                                                                        ? "₹" + (Math.round(data?.discount_price * 83.12))
                                                                        : "$" + (Math.round(data?.discount_price))

                                                                }</div>

                                                                <a className="text-weight-semibold">
                                                                    Total Price
                                                                </a>
                                                            </>
                                                        )}
                                                    </div>

                                                    <div className="line-divider text-align-center" />
                                                    <div className="text-align-center flex vertical spacing-xxsmall">
                                                        <div className="small-headline text-success">schedule</div>
                                                        <div className="host-card-title">{getLocalTime(data?.course_start_date)}</div>
                                                        <CountdownTimer courseStartDateUtc={data?.course_start_date} />
                                                    </div>
                                                    <a
                                                        onClick={handleCheckoutCart}

                                                        className="button is-small w-button text-align-center"
                                                    >
                                                        Apply now
                                                    </a>
                                                    <div className="feature-card">
                                                        <div className="margin-bottom margin-small">
                                                            <div className="small-headline lighter">this {data?.document_type} includes</div>
                                                        </div>
                                                        <div className="w-layout-grid _1-col-grid">
                                                            <div className="host-card-left">
                                                                <div className="card-icon w-embed">
                                                                    <svg
                                                                        width={36}
                                                                        height={36}
                                                                        viewBox="0 0 36 36"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <circle cx={18} cy="18.0001" r={18} fill="#F9FAFA" />
                                                                        <path
                                                                            d="M17.9997 21.3335C15.6064 21.3335 13.6663 19.3934 13.6663 17.0001V13.0372C13.6663 12.6917 13.6663 12.519 13.7288 12.385C13.795 12.243 13.9092 12.1288 14.0513 12.0626C14.1852 12.0001 14.3579 12.0001 14.7034 12.0001H21.2959C21.6414 12.0001 21.8141 12.0001 21.9481 12.0626C22.0901 12.1288 22.2043 12.243 22.2705 12.385C22.333 12.519 22.333 12.6917 22.333 13.0372V17.0001C22.333 19.3934 20.3929 21.3335 17.9997 21.3335ZM17.9997 21.3335V24.0001M21.333 24.0001H14.6663M24.6663 13.3335V16.6668M11.333 13.3335V16.6668"
                                                                            stroke="#011825"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <div className="host-card-info">
                                                                    <div className="host-card-title smaller">
                                                                        Certificate of completion
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="host-card-left">
                                                                <div className="card-icon w-embed">
                                                                    <svg
                                                                        width={36}
                                                                        height={36}
                                                                        viewBox="0 0 36 36"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <circle cx={18} cy="18.0001" r={18} fill="#F9FAFA" />
                                                                        <g clipPath="url(#clip0_80_5273)">
                                                                            <path
                                                                                d="M20.667 12.3333C20.667 11.781 20.2193 11.3333 19.667 11.3333C19.1147 11.3333 18.667 11.781 18.667 12.3333V15.6666C18.667 16.2189 19.1147 16.6666 19.667 16.6666C20.2193 16.6666 20.667 16.2189 20.667 15.6666V12.3333Z"
                                                                                stroke="#011825"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M22.667 15.6667V16.6667H23.667C23.8648 16.6667 24.0581 16.608 24.2226 16.4982C24.387 16.3883 24.5152 16.2321 24.5909 16.0494C24.6666 15.8666 24.6864 15.6656 24.6478 15.4716C24.6092 15.2776 24.514 15.0994 24.3741 14.9596C24.2342 14.8197 24.0561 14.7245 23.8621 14.6859C23.6681 14.6473 23.467 14.6671 23.2843 14.7428C23.1016 14.8185 22.9454 14.9467 22.8355 15.1111C22.7256 15.2756 22.667 15.4689 22.667 15.6667Z"
                                                                                stroke="#011825"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M17.333 20.3333C17.333 19.781 16.8853 19.3333 16.333 19.3333C15.7807 19.3333 15.333 19.781 15.333 20.3333V23.6666C15.333 24.2189 15.7807 24.6666 16.333 24.6666C16.8853 24.6666 17.333 24.2189 17.333 23.6666V20.3333Z"
                                                                                stroke="#011825"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M13.333 20.3333V19.3333H12.333C12.1352 19.3333 11.9419 19.392 11.7774 19.5018C11.613 19.6117 11.4848 19.7679 11.4091 19.9506C11.3334 20.1334 11.3136 20.3344 11.3522 20.5284C11.3908 20.7224 11.486 20.9006 11.6259 21.0404C11.7658 21.1803 11.9439 21.2755 12.1379 21.3141C12.3319 21.3527 12.533 21.3329 12.7157 21.2572C12.8984 21.1815 13.0546 21.0533 13.1645 20.8889C13.2744 20.7244 13.333 20.5311 13.333 20.3333Z"
                                                                                stroke="#011825"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M23.6663 18.6667H20.333C19.7807 18.6667 19.333 19.1144 19.333 19.6667C19.333 20.219 19.7807 20.6667 20.333 20.6667H23.6663C24.2186 20.6667 24.6663 20.219 24.6663 19.6667C24.6663 19.1144 24.2186 18.6667 23.6663 18.6667Z"
                                                                                stroke="#011825"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M20.333 22.6667H19.333V23.6667C19.333 23.8645 19.3917 24.0578 19.5015 24.2223C19.6114 24.3867 19.7676 24.5149 19.9503 24.5906C20.1331 24.6663 20.3341 24.6861 20.5281 24.6475C20.7221 24.6089 20.9003 24.5136 21.0401 24.3738C21.18 24.2339 21.2752 24.0558 21.3138 23.8618C21.3524 23.6678 21.3326 23.4667 21.2569 23.284C21.1812 23.1013 21.053 22.9451 20.8886 22.8352C20.7241 22.7253 20.5308 22.6667 20.333 22.6667Z"
                                                                                stroke="#011825"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M15.6663 15.3333H12.333C11.7807 15.3333 11.333 15.781 11.333 16.3333C11.333 16.8856 11.7807 17.3333 12.333 17.3333H15.6663C16.2186 17.3333 16.6663 16.8856 16.6663 16.3333C16.6663 15.781 16.2186 15.3333 15.6663 15.3333Z"
                                                                                stroke="#011825"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M15.667 13.3333H16.667V12.3333C16.667 12.1355 16.6083 11.9422 16.4985 11.7777C16.3886 11.6133 16.2324 11.4851 16.0497 11.4094C15.867 11.3337 15.6659 11.3139 15.4719 11.3525C15.2779 11.3911 15.0997 11.4864 14.9599 11.6262C14.82 11.7661 14.7248 11.9442 14.6862 12.1382C14.6476 12.3322 14.6674 12.5333 14.7431 12.716C14.8188 12.8987 14.947 13.0549 15.1114 13.1648C15.2759 13.2747 15.4692 13.3333 15.667 13.3333Z"
                                                                                stroke="#011825"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_80_5273">
                                                                                <rect
                                                                                    width={16}
                                                                                    height={16}
                                                                                    fill="white"
                                                                                    transform="translate(10 10)"
                                                                                />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </div>
                                                                <div className="host-card-info">
                                                                    <div className="host-card-title smaller">
                                                                        Private Slack Community
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="host-card-left">
                                                                <div className="card-icon w-embed">
                                                                    <svg
                                                                        width={36}
                                                                        height={36}
                                                                        viewBox="0 0 36 36"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <circle cx={18} cy="18.0001" r={18} fill="#F9FAFA" />
                                                                        <path
                                                                            d="M17.9997 24.0001L17.9329 23.9001C17.4699 23.2055 17.2383 22.8581 16.9324 22.6067C16.6616 22.3841 16.3495 22.2171 16.0141 22.1152C15.6352 22.0001 15.2177 22.0001 14.3829 22.0001H13.4663C12.7196 22.0001 12.3462 22.0001 12.061 21.8548C11.8101 21.727 11.6062 21.523 11.4783 21.2721C11.333 20.9869 11.333 20.6135 11.333 19.8668V14.1335C11.333 13.3867 11.333 13.0133 11.4783 12.7281C11.6062 12.4772 11.8101 12.2733 12.061 12.1454C12.3462 12.0001 12.7196 12.0001 13.4663 12.0001H13.733C15.2265 12.0001 15.9732 12.0001 16.5436 12.2908C17.0454 12.5464 17.4533 12.9544 17.709 13.4561C17.9997 14.0266 17.9997 14.7733 17.9997 16.2668M17.9997 24.0001V16.2668M17.9997 24.0001L18.0664 23.9001C18.5295 23.2055 18.761 22.8581 19.0669 22.6067C19.3377 22.3841 19.6498 22.2171 19.9853 22.1152C20.3641 22.0001 20.7816 22.0001 21.6165 22.0001H22.533C23.2797 22.0001 23.6531 22.0001 23.9383 21.8548C24.1892 21.727 24.3932 21.523 24.521 21.2721C24.6663 20.9869 24.6663 20.6135 24.6663 19.8668V14.1335C24.6663 13.3867 24.6663 13.0133 24.521 12.7281C24.3932 12.4772 24.1892 12.2733 23.9383 12.1454C23.6531 12.0001 23.2797 12.0001 22.533 12.0001H22.2663C20.7729 12.0001 20.0261 12.0001 19.4557 12.2908C18.9539 12.5464 18.546 12.9544 18.2903 13.4561C17.9997 14.0266 17.9997 14.7733 17.9997 16.2668"
                                                                            stroke="#011825"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <div className="host-card-info">
                                                                    <div className="host-card-title smaller">Mentorship sessions</div>
                                                                </div>
                                                            </div>
                                                            <div className="host-card-left">
                                                                <div className="card-icon w-embed">
                                                                    <svg
                                                                        width={36}
                                                                        height={36}
                                                                        viewBox="0 0 36 36"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <circle cx={18} cy="18.0001" r={18} fill="#F9FAFA" />
                                                                        <path
                                                                            d="M15.0475 24.0001C15.9374 23.5733 16.9397 23.3335 17.9997 23.3335C19.0597 23.3335 20.0619 23.5733 20.9518 24.0001M14.533 21.3335H21.4663C22.5865 21.3335 23.1465 21.3335 23.5743 21.1155C23.9507 20.9237 24.2566 20.6178 24.4483 20.2415C24.6663 19.8136 24.6663 19.2536 24.6663 18.1335V15.2001C24.6663 14.08 24.6663 13.52 24.4483 13.0921C24.2566 12.7158 23.9507 12.4099 23.5743 12.2181C23.1465 12.0001 22.5865 12.0001 21.4663 12.0001H14.533C13.4129 12.0001 12.8528 12.0001 12.425 12.2181C12.0487 12.4099 11.7427 12.7158 11.551 13.0921C11.333 13.52 11.333 14.08 11.333 15.2001V18.1335C11.333 19.2536 11.333 19.8136 11.551 20.2415C11.7427 20.6178 12.0487 20.9237 12.425 21.1155C12.8528 21.3335 13.4129 21.3335 14.533 21.3335Z"
                                                                            stroke="#011825"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <div className="host-card-info">
                                                                    <div className="host-card-title smaller">Session recordings</div>
                                                                </div>
                                                            </div>
                                                            <div className="host-card-left">
                                                                <div className="card-icon w-embed">
                                                                    <svg
                                                                        width={36}
                                                                        height={36}
                                                                        viewBox="0 0 36 36"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <circle cx={18} cy="18.0001" r={18} fill="#F9FAFA" />
                                                                        <g clipPath="url(#clip0_314_23)">
                                                                            <path
                                                                                d="M23.3337 18V24.6667H12.667V18"
                                                                                stroke="black"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M24.6663 14.6667H11.333V18H24.6663V14.6667Z"
                                                                                stroke="black"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M18 24.6667V14.6667"
                                                                                stroke="black"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M17.9997 14.6666H14.9997C14.5576 14.6666 14.1337 14.4911 13.8212 14.1785C13.5086 13.8659 13.333 13.442 13.333 13C13.333 12.558 13.5086 12.134 13.8212 11.8215C14.1337 11.5089 14.5576 11.3333 14.9997 11.3333C17.333 11.3333 17.9997 14.6666 17.9997 14.6666Z"
                                                                                stroke="black"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M18 14.6666H21C21.442 14.6666 21.866 14.4911 22.1785 14.1785C22.4911 13.8659 22.6667 13.442 22.6667 13C22.6667 12.558 22.4911 12.134 22.1785 11.8215C21.866 11.5089 21.442 11.3333 21 11.3333C18.6667 11.3333 18 14.6666 18 14.6666Z"
                                                                                stroke="black"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_314_23">
                                                                                <rect
                                                                                    width={16}
                                                                                    height={16}
                                                                                    fill="white"
                                                                                    transform="translate(10 10)"
                                                                                />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </div>
                                                                <div className="host-card-info">
                                                                    <div className="host-card-title smaller">Exclusive resources</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`sticky-box ${isSticky ? 'sticky' : ''}`} style={{ transitionDuration }} >
                                                    <div className="host-card-left padded-card newsletter">
                                                        <div className="text-weight-semibold">
                                                            Ready to Learn? Get a Call Back
                                                        </div>
                                                        <div className="form-block w-form">
                                                            <form
                                                                className="form margin-top margin-small"
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="form_input w-input"
                                                                    name="Email-address"
                                                                    placeholder="Name"
                                                                    required=""
                                                                    onChange={(e) =>
                                                                        setcallBackForm((pre) => ({
                                                                            ...pre,
                                                                            name: e.target.value,
                                                                        }))
                                                                    }
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className="form_input w-input"
                                                                    name="Email-address"
                                                                    placeholder="Email address"
                                                                    required=""
                                                                    onChange={(e) =>
                                                                        setcallBackForm((pre) => ({
                                                                            ...pre,
                                                                            email: e.target.value,
                                                                        }))
                                                                    }
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className="form_input w-input"
                                                                    name="Email-address"
                                                                    placeholder="Phone"
                                                                    required=""
                                                                    onChange={(e) =>
                                                                        setcallBackForm((pre) => ({
                                                                            ...pre,
                                                                            mobile: e.target.value,
                                                                        }))
                                                                    }
                                                                />

                                                                <button
                                                                    onClick={handleCallBack}
                                                                    type="submit"
                                                                    data-wait="Please wait..."
                                                                    className="button is-small w-button"
                                                                >Call me back</button>



                                                                {errorMsg && <p>{errorMsg}</p>}
                                                                {successMsg && <p>{successMsg}</p>}


                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </section>

                                    {/* Description and hosted by */}

                                </div>


                            </>

                            <section className="benefits">
                                <div className="container">
                                    <h2 className="section-heading">
                                        Elevate Your Career with This Comprehensive {data?.document_type}. <b>Here's How.</b>
                                    </h2>
                                    <div className="benefits-summary">
                                        <div className="row">
                                            {benefitsData.map((benefit, index) => (
                                                <div key={index} className="col-md-4 col-6">
                                                    <div className="benefit-thumb">
                                                        <i className="icons icon-benefit" title={benefit.title}>
                                                            <img src={benefit.icon} alt="" />
                                                        </i>
                                                        <h3>{benefit.title}</h3>
                                                        <p>{benefit.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="get-started-cls">
                                        <a href="https://wa.me/message/LUEGOQ2MKAI5E1 ">
                                            <button type="button" className="find-btn btn btn-primary" style={{ background: "green !important" }}>
                                                <span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={25}
                                                        height={25}
                                                        fill="currentColor"
                                                        className="bi bi-whatsapp"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                                    </svg>

                                                </span>
                                                <span>

                                                    Whatsup
                                                </span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </section>

                            {/* Syllabus module */}
                            {
                                data?.document_type === 'course' ? (
                                    data?.course_syllabus.length === 0 ? (
                                        <>
                                            <section
                                                id="section-downloadSyllabus"
                                                className="DownloadSyllabus_main full-container "
                                            >
                                                <div className="DownloadSyllabus_leftWrapper_">
                                                    <h2 className="DownloadSyllabus_title text-center">Syllabus Comming Soon</h2>

                                                </div>



                                            </section>
                                        </>
                                    ) : (
                                        <>
                                            <section
                                                id="section-syllabus"
                                                className="Syllabus_main  full-container"
                                            >
                                                <h2 className="Syllabus_title">
                                                    Course curriculum
                                                </h2>
                                                <div className="Syllabus_description pb-3">
                                                    <span className="Syllabus_subtitle">

                                                        Top-notch educational material delivered by esteemed professors and industry experts through a diverse range of multimedia resources, including high-quality videos, real-world case studies, hands-on projects, thought-provoking assignments, and engaging live interactive sessions.
                                                    </span>

                                                    <div className="get-started-cls text-center ">

                                                        <button
                                                            onClick={() => {

                                                                setcallbackModal({
                                                                    modal: CallBackModal, show: true,
                                                                    inquiry_type: 'Full_stack_call_back',
                                                                    headingTitle: " Download Course curriculum",
                                                                    message: `${data?.slug + "" + data?.product_id + " "} Enroll data to download course curriculam`
                                                                });
                                                            }}
                                                            type="button"
                                                            className="btn btn-primary"
                                                        >
                                                            Download curriculum
                                                        </button>
                                                    </div>
                                                </div>


                                                <>


                                                    {data?.course_syllabus?.map((unit, unitIndex) => (
                                                        <div key={`unit-${unitIndex}`}>
                                                            <div
                                                                className={`mb-3 SyllabusAccordion_${expandedIndexes.includes(unitIndex) ? 'expanded' : 'collapsed'
                                                                    }__TvpAE`}
                                                                style={{ maxHeight: expandedIndexes.includes(unitIndex) ? 'none' : 94 }}
                                                            >
                                                                <div className="SyllabusAccordion_head" onClick={() => toggleCollapse(unitIndex)}>
                                                                    <div className="SyllabusAccordion_head__left">
                                                                        <h3 className="SyllabusAccordion_head__title">Unit {" "} {unitIndex + 1}:{" "}{unit?.unitName}</h3>
                                                                        <div className="SyllabusAccordion_head__bottom">
                                                                            {unit?.weeks === "" ? (null) : (
                                                                                <>
                                                                                    <div className="SyllabusAccordion_head__bottom__wrapper">
                                                                                        <span className='span1'>
                                                                                            <span className='span2'>
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={16}
                                                                                                    height={16}
                                                                                                    fill="currentColor"
                                                                                                    className="bi bi-alarm"
                                                                                                    viewBox="0 0 16 16"
                                                                                                >
                                                                                                    <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z" />
                                                                                                    <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z" />
                                                                                                </svg>
                                                                                            </span>
                                                                                        </span>
                                                                                        <span className="SyllabusAccordion_head__bottom__des">
                                                                                            {unit?.weeks} {" "} weeks

                                                                                        </span>
                                                                                    </div>
                                                                                </>)}

                                                                        </div>
                                                                    </div>
                                                                    <div className="SyllabusAccordion_head__right">
                                                                        <span className='span5'>
                                                                            <span className='span6'>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={16}
                                                                                    height={16}
                                                                                    fill="currentColor"
                                                                                    className="bi bi-chevron-up span7"
                                                                                    viewBox="0 0 16 16"
                                                                                >
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                                                                                    />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <ul className="SyllabusAccordion_body">
                                                                    {unit?.chapters?.map((chapter, chapterIndex) => (
                                                                        <li key={`chapter-${unitIndex}-${chapterIndex}`} className="SyllabusAccordion_body__wrapper">
                                                                            <span className="SyllabusAccordion_body__title">Chapter {" "} {chapterIndex + 1} : {" "}{chapter?.title}</span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    ))}


                                                </>
                                            </section>

                                        </>
                                    )) : (null)

                            }

                            {/* Secrue Dream job */}
                            {data?.document_type === "course" ? (
                                <>
                                    <section className="dream-job">
                                        <div className="container">
                                            <h2>Secure Your Dream Job after completion of course</h2>
                                            <p>
                                                Embark on a Comprehensive {data?.course_topic}, meticulously designed to equip you with job-ready skills through our holistic Career Services and personalized mentorship.
                                            </p>
                                            <div className="placement">
                                                <h3>Our Impressive Placement Statistics*</h3>
                                                <div className="dream_card">
                                                    <p>Maximum salary hike</p>
                                                    <span>316.67%</span>
                                                </div>
                                                <div className="dream_card">
                                                    <p>Diverse industry placements</p>
                                                    <span>10+</span>
                                                </div>
                                                <div className="dream_card">
                                                    <p>Hiring partners</p>
                                                    <span>200+</span>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="container">


                                            <div className="">
                                                <h2>Our Graduates Thriving in Leading Organizations</h2>
                                                <div className="company-dream_cards">

                                                    < img src={companies} alt="companies" />
                                                </div>
                                            </div>
                                            <p className="mob-disclimer">
                                                Disclaimer: General Career Progression following the successful completion of Mentorpal.ai's Programs. Please be aware that previous achievements do not necessarily guarantee future outcomes. Adjusted for similarity.					</p>
                                        </div>

                                    </section >
                                    {/* Benefits */}
                                    {/* Download syllabus */}
                                    <section
                                        id="section-downloadSyllabus"
                                        className="DownloadSyllabus_main full-container "
                                    >
                                        <div className="DownloadSyllabus_leftWrapper_">
                                            <h2 className="DownloadSyllabus_title">Syllabus</h2>
                                            <span className="DownloadSyllabus_subtitle">
                                                <p>
                                                    Exceptional Content Delivered by Esteemed Educators and Industry Pioneers, encompassing an array of videos, real-life case studies, and hands-on projects.
                                                </p>
                                            </span>
                                        </div>

                                        <div className="get-started-cls" >
                                            <a onClick={() => {

                                                setcallbackModal({
                                                    modal: CallBackModal, headingTitle: "  Download Course curriculum", show: true,

                                                    inquiry_type: 'Full_stack_download_curriculum',
                                                    message: `${data?.slug + "" + data?.product_id + " "} Enroll data to download course curriculam`

                                                });
                                            }}>
                                                <button type="button" className="find-btn btn btn-primary">
                                                    DOWNLOAD SYLLABUS
                                                </button>
                                            </a>
                                        </div>

                                    </section>
                                </>) : (null)

                            }

                            {/* Features */}
                            <section className=" why_choose_ment" style={{ background: "#f8f8f9", padding: "80px 0" }}>
                                <div className="container">
                                    <div className="inner-title">
                                        <h3>Why Join our {data?.course_topic}</h3>
                                        <h2>
                                            Because, these features are our strength
                                            <span className="d-block">choose MentorPal</span>
                                        </h2>
                                    </div>
                                    <div className="mentorpal-sec">
                                        <div className="row">
                                            {featuresData.map((feature, index) => (
                                                <div className="col-sm-6 col-md-4" key={index}>
                                                    <div className="mentorpal-box">
                                                        <div className="icon">
                                                            <img alt="icon" src={feature.icon} />
                                                        </div>
                                                        <div className="serve-cont">
                                                            <h4>{feature.title}</h4>
                                                            <p>{feature.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* Admission process */}
                            <section className="admission">
                                <div className="container">
                                    <h2 className="section-heading text-center">

                                        Admission <b>Process</b>
                                    </h2>
                                    <ul>
                                        <li>
                                            <span className="count">1</span>
                                            <h3 className="content">Click on Apply Now</h3>
                                        </li>
                                        <li>
                                            <span className="count">2</span>
                                            <h3 className="content">Complete the application form</h3>
                                        </li>
                                        <li>
                                            <span className="count">3</span>
                                            <h3 className="content">Pay to reserve your seat</h3>
                                        </li>
                                        <li>
                                            <span className="count">4</span>
                                            <h3 className="content">Seat Confirm</h3>
                                        </li>
                                    </ul>
                                </div>
                            </section>
                            {/*  pricing-plans*/}

                            <section className="pricing-plans">
                                <div className="container">
                                    <h2 className="section-heading text-center">

                                        Pricing <b>Plans</b>
                                    </h2>
                                    {
                                        data?.proBono === 'true' ? (
                                            <>
                                                <div className="pricing-summary-thumb">
                                                    <div className="pricing-summary">
                                                        <div className="price-thumb">
                                                            <div className="price-thumb-content-summary">
                                                                <div className="price-thumb-title">
                                                                    <span></span> Probono {data?.document_type}
                                                                </div>
                                                            </div>
                                                            <div className="pricing-display-summary">
                                                                <div className="pricing-display">
                                                                    <strong> Free {data?.document_type}</strong>


                                                                </div>
                                                            </div>
                                                            <div className="pricing-apply-now-summary  d-sm-flex">

                                                                <a
                                                                    className="btn btn-success"
                                                                    onClick={handleCheckoutCart}
                                                                >

                                                                    Apply Now
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="fee-details-new-3  itemPay1 collapse" style={{}}>
                                                            <div className="fee-details">
                                                                <div className="emi-grp-summary">
                                                                    <div className="emi-tabs pay-later-left"></div>
                                                                    <div className="emi-table clearfix">
                                                                        <div className="table-responsive">
                                                                            <table className="table">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Total Price&nbsp;</td>
                                                                                        <td style={{ textDecoration: "line-through" }}> {data?.proBono === "true"
                                                                                            ? "Pro Bono"
                                                                                            : countryname === "India"
                                                                                                ? "₹" + (Math.round(data?.price * 83.12))
                                                                                                : "$" + (Math.round(data?.price))

                                                                                        }</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Discount</td>
                                                                                        <td>  {((data?.price - data?.discount_price) / data?.price * 100).toFixed(0)}% off</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Paid at the time of enrollment&nbsp;</td>
                                                                                        <td> {data?.proBono === "true"
                                                                                            ? "Pro Bono"
                                                                                            : countryname === "India"
                                                                                                ? "₹" + (Math.round(data?.discount_price * 83.12))
                                                                                                : "$" + (Math.round(data?.discount_price))

                                                                                        }</td>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="tax-note clearfix">
                                                                            <span className="">
                                                                                * Inclusive of all taxes&nbsp;&nbsp;&nbsp;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>) : (
                                            <>
                                                <div className="pricing-summary-thumb">
                                                    <div className="pricing-summary">
                                                        <div className="price-thumb">
                                                            <div className="price-thumb-content-summary">
                                                                <div className="price-thumb-title">
                                                                    <span></span> One time Payment
                                                                </div>
                                                            </div>
                                                            <div className="pricing-display-summary">
                                                                <div className="pricing-display">
                                                                    <strong> {data?.proBono === "true"
                                                                        ? "Pro Bono"
                                                                        : countryname === "India"
                                                                            ? "₹" + (Math.round(data?.discount_price * 83.12))
                                                                            : "$" + (Math.round(data?.discount_price))

                                                                    }</strong>
                                                                    <span className="non-refund-text">OneTime Payment</span>

                                                                </div>
                                                            </div>
                                                            <div className="pricing-apply-now-summary  d-sm-flex">
                                                                <a
                                                                    className="view-more collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target=".itemPay1"
                                                                    aria-expanded="false"
                                                                >

                                                                    More details
                                                                </a>
                                                                <a
                                                                    className="btn btn-success"
                                                                    onClick={handleCheckoutCart}
                                                                >

                                                                    Apply Now
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="fee-details-new-3  itemPay1 collapse" style={{}}>
                                                            <div className="fee-details">
                                                                <div className="emi-grp-summary">
                                                                    <div className="emi-tabs pay-later-left"></div>
                                                                    <div className="emi-table clearfix">
                                                                        <div className="table-responsive">
                                                                            <table className="table">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Total Price&nbsp;</td>
                                                                                        <td style={{ textDecoration: "line-through" }}> {data?.proBono === "true"
                                                                                            ? "Pro Bono"
                                                                                            : countryname === "India"
                                                                                                ? "₹" + (Math.round(data?.price * 83.12))
                                                                                                : "$" + (Math.round(data?.price))

                                                                                        }</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Discount</td>
                                                                                        <td>  {((data?.price - data?.discount_price) / data?.price * 100).toFixed(0)}% off</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Paid at the time of enrollment&nbsp;</td>
                                                                                        <td> {data?.proBono === "true"
                                                                                            ? "Pro Bono"
                                                                                            : countryname === "India"
                                                                                                ? "₹" + (Math.round(data?.discount_price * 83.12))
                                                                                                : "$" + (Math.round(data?.discount_price))

                                                                                        }</td>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="tax-note clearfix">
                                                                            <span className="">
                                                                                * Inclusive of all taxes&nbsp;&nbsp;&nbsp;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="pricing-summary-thumb">
                                                    <div className="pricing-summary">
                                                        <div className="price-thumb">
                                                            <div className="price-thumb-content-summary">
                                                                <div className="price-thumb-title">
                                                                    <span></span> Partial Payment
                                                                </div>
                                                            </div>
                                                            <div className="pricing-display-summary">
                                                                <div className="pricing-display">
                                                                    <strong> {data?.proBono === "true"
                                                                        ? "Pro Bono"
                                                                        : countryname === "India"
                                                                            ? "₹" + (Math.round(inr_partial))
                                                                            : "$" + (Math.round(dollar_partial))

                                                                    }</strong>
                                                                    <span className="non-refund-text">Upfront Payment</span>

                                                                </div>

                                                            </div>
                                                            <div className="pricing-apply-now-summary  d-sm-flex">
                                                                <a
                                                                    className="view-more collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target=".itemPay2"
                                                                    aria-expanded="false"
                                                                >

                                                                    More details
                                                                </a>
                                                                <a
                                                                    className="btn btn-success"
                                                                    onClick={handleCheckoutCart}
                                                                >

                                                                    Apply Now
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="fee-details-new-3 itemPay2 collapse" style={{}}>
                                                            <div className="fee-details">
                                                                <div className="emi-grp-summary">
                                                                    <div className="emi-tabs pay-later-left"></div>
                                                                    <div className="emi-table clearfix">
                                                                        <div className="table-responsive">
                                                                            <table className="table">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Total Price&nbsp;</td>
                                                                                        <td style={{ textDecoration: "line-through" }}> {data?.proBono === "true"
                                                                                            ? "Pro Bono"
                                                                                            : countryname === "India"
                                                                                                ? "₹" + (Math.round(data?.price * 83.12))
                                                                                                : "$" + (Math.round(data?.price))

                                                                                        }</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Discount</td>
                                                                                        <td>  {((data?.price - data?.discount_price) / data?.price * 100).toFixed(0)}% off</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Total amount after discount&nbsp;</td>
                                                                                        <td> {data?.proBono === "true"
                                                                                            ? "Pro Bono"
                                                                                            : countryname === "India"
                                                                                                ? "₹" + Math.round(data?.discount_price * 83.12)
                                                                                                : "$" + Math.round(data?.discount_price)

                                                                                        }</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Paid at the time of enrollment&nbsp;</td>
                                                                                        <td>{data?.proBono === "true"
                                                                                            ? "Pro Bono"
                                                                                            : countryname === "India"
                                                                                                ? "₹" + Math.round(inr_partial)
                                                                                                : "$" + Math.round(dollar_partial)
                                                                                        }</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Pay After a month</td>
                                                                                        <td> {data?.proBono === "true"
                                                                                            ? "Pro Bono"
                                                                                            : countryname === "India"
                                                                                                ? "₹" + Math.round(data?.discount_price * 83.12 - inr_partial)
                                                                                                : "$" + Math.round(data?.discount_price - dollar_partial)

                                                                                        }</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="tax-note clearfix">
                                                                            <span className="">
                                                                                * Inclusive of all taxes&nbsp;&nbsp;&nbsp;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </section>



                            {/* Host Members */}
                            <section className="container">
                                <div className="section-content-holder">
                                    <div className="text-align-center">
                                        <div className="host_heading">Hosting this {data?.document_type}</div>

                                        {data?.course_hosted_by?.map((host, index) => (
                                            <div key={index} className={`host-main-card long ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                                <img

                                                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}${host?.image_url}`} alt={host?.name}

                                                    className="host-image"
                                                />
                                                <div className="host-details h-info">
                                                    <div className="host-card-info">
                                                        <div className="host-card-title">{host.name}</div>
                                                        <p>{host.title}</p>
                                                    </div>
                                                    <p>{host.description}</p>
                                                    {host.linked_in && (
                                                        <a
                                                            href={host.linked_in}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="meta-group ghost-btn w-inline-block"
                                                        >
                                                            <div className="small-headline">View LinkedIn profile</div>
                                                        </a>
                                                    )}

                                                </div>
                                            </div>
                                        ))}



                                    </div>
                                </div>
                            </section>
                            <div className="inner-title" style={{ paddingTop: '80px' }}>
                                <h3>Top Mentors</h3>
                                <h2>
                                    Get mentored by one of the pre-eminent{" "}
                                    <span className="d-block">mentors in the world</span>
                                </h2>
                            </div>
                            <TopMentor />
                            {/* Get more module */}
                            <GetMore data={data} />

                            {/* testimonial */}
                            <section className="container">
                                <div className="section-content-holder">
                                    <div className="margin-bottom margin-medium">
                                        <div className="text-align-center">
                                            <div className="max-width-large">
                                                <div className="margin-bottom margin-small">
                                                </div>
                                                <h2 className="heading-style-h4">
                                                    What Our Learners Are Saying About Our Programs
                                                    <br />
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="container">

                                <Testimonial />
                            </div>

                            {/* FAQ */}
                            <section className="section background-color-grey pt-4 " style={{ paddingBottom: "4rem" }}>
                                <div className="container">
                                    <div className="frequently_asked ">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mentoring-cont">
                                                    <div className="faq__pricing">
                                                        <h3>Frequently asked Questions</h3>
                                                        <div className="inner-title">
                                                            <h2>
                                                                Can't find the answer you <span> are looking for?</span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <span className="tick">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    height="24px"
                                                                    viewBox="0 0 24 24"
                                                                    width="24px"
                                                                    fill="#000000"
                                                                >
                                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                                                                </svg>
                                                            </span>
                                                            <a href="mailto:help@mentorpal.ai">

                                                                <span className="cont-cls">Reach out to us</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span className="tick">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    height="24px"
                                                                    viewBox="0 0 24 24"
                                                                    width="24px"
                                                                    fill="#000000"
                                                                >
                                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                                                                </svg>
                                                            </span>
                                                            <a >
                                                                <span className="cont-cls">
                                                                    Help & grow with MentroPal
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span className="tick">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    height="24px"
                                                                    viewBox="0 0 24 24"
                                                                    width="24px"
                                                                    fill="#000000"
                                                                >
                                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                                                                </svg>
                                                            </span>
                                                            <span className="cont-cls">
                                                                take your knowledge to global level
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <div className="get-started-cls" id="get-started-cls">
                                                        <a
                                                            href="mailto:help@mentorpal.ai"
                                                            type="button"
                                                            className="btn btn-primary"
                                                        >
                                                            Reach out to us
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 faq___overflow" >
                                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                                    {
                                                        data?.faq_data.length === 0 ? (
                                                            <>
                                                                {Course_details_faqData?.map((faq, index) => (
                                                                    <div class="accordion-item" style={{ marginBottom: "8px" }} key={index}>
                                                                        <h2 class="accordion-header accordion-header_course" id={`flush-heading-${index}`}>
                                                                            <button
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#flush-collapse-${index}`}
                                                                                aria-expanded="false"
                                                                                aria-controls={`flush-collapse-${index}`}
                                                                            >
                                                                                {faq.question}
                                                                            </button>
                                                                        </h2>
                                                                        <div
                                                                            id={`flush-collapse-${index}`}
                                                                            class="accordion-collapse collapse"
                                                                            aria-labelledby={`flush-heading-${index}`}
                                                                            data-bs-parent="#accordionFlushExample"
                                                                        >
                                                                            <div class="accordion-body">{faq.answer}</div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                }
                                                            </>
                                                        ) : (

                                                            <>
                                                                {data?.faq_data?.map((faq, index) => (
                                                                    <div class="accordion-item" style={{ marginBottom: "8px" }} key={index}>
                                                                        <h2 class="accordion-header accordion-header_course" id={`flush-heading-${index}`}>
                                                                            <button
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#flush-collapse-${index}`}
                                                                                aria-expanded="false"
                                                                                aria-controls={`flush-collapse-${index}`}
                                                                            >
                                                                                {faq.question}
                                                                            </button>
                                                                        </h2>
                                                                        <div
                                                                            id={`flush-collapse-${index}`}
                                                                            class="accordion-collapse collapse"
                                                                            aria-labelledby={`flush-heading-${index}`}
                                                                            data-bs-parent="#accordionFlushExample"
                                                                        >
                                                                            <div class="accordion-body">{faq.answer}</div>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </>
                                                        )

                                                    }


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="container-fluid">
                                <div id="course_mySidenav" className="course_sidenav">
                                    <a
                                        onClick={() => {

                                            setcallbackModal({
                                                modal: CallBackModal, headingTitle: " Ready to Learn? Get a Call Back", show: true,

                                                inquiry_type: data?.slug,
                                                message: `${data?.slug + "" + data?.product_id + " "} Enroll data to call back`

                                            });
                                        }}

                                        id="call__back">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={40}
                                            height={40}
                                            fill="currentColor"
                                            className="bi bi-telephone-plus"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                            <path
                                                fillRule="evenodd"
                                                d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"
                                            />
                                        </svg>

                                    </a>
                                    <a href="https://wa.me/qr/FCPHIW3ZEU3AC1" id="whatsup___msg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={40}
                                            height={40}
                                            fill="white"
                                            className="bi bi-whatsapp"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                        </svg>

                                    </a>
                                </div>
                            </div>

                            <MasterFooter />
                        </>

                    )
            }




            {
                applyCourseModal?.modal && (
                    <applyCourseModal.modal
                        applyCourseModal={applyCourseModal}
                        setApplyCourseModal={setApplyCourseModal}
                    />
                )
            }
            {
                callbackModal?.modal && (
                    <callbackModal.modal
                        callbackModal={callbackModal}
                        setcallbackModal={setcallbackModal}
                    />
                )
            }
        </>
    );
}

export default CourseDetails;
