import React, { useState } from "react";
import "./UpdateExpertise.css";
import { expertise } from "../../../../assets/data/data";
import ReactSelect from "react-select";

const UpdateExpertise = (props) => {
  const customKeyExpertise = props?.expertise?.map((item) => {
    return { label: item, value: item };
  });
  const [selectedExpertise, setSelectedExpertise] =
    useState(customKeyExpertise);
  const setExpertiseValue = (e) => {
    let tempList = selectedExpertise;
    e?.map((item) => tempList?.push(item?.label));
    tempList.push(e);

    setSelectedExpertise(e);
  };
  const handleOnClick = () => {
    let expertises = selectedExpertise?.map((item) => item?.value);
    let tempData = { experties: expertises };
    props?.getUpdatedValues(tempData);
  };
  return (
    <div className="update-expertise-main-container">
      {/* <h7>What expertise(s) do you speak?</h7> */}
      <div className="expertise-input">
        <ReactSelect
          required={true}
          value={selectedExpertise ? selectedExpertise : ""}
          isMulti
          name="expertise"
          onChange={setExpertiseValue}
          options={expertise}
        />
      </div>
      <button className="update-btn-expertise" onClick={handleOnClick}>
        Update
      </button>
    </div>
  );
};

export default UpdateExpertise;
