import { TailSpin } from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createMentorAction } from "../../../redux/actions/mentor";

const Overlay = ({ overlayData, setOverlayData }) => {
	const dispatch = useDispatch();

	const [data, setData] = useState(overlayData);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setOverlayData(data);
	}, [data]);
	
	useSelector((state) => {
		if (!state.createMentorReducer?.request) {
			dispatch(createMentorAction(data));
		}

		if (!state.createMentorReducer?.loading) {
			if(isLoading){
				setIsLoading(false);
				setData({});
			}	
		}
	});

	return (
		<>
			{
				isLoading 
					? <div className="loader">
							<TailSpin ariaLabel="Loading..." color="#00BFFF" />
						</div>
					:
				<section className='aapply-mentor-sec'>

					<div id='overlay '>
						<div className='thanks-sec'>
							<div className='thankyou'>
								<h3>Application Successful</h3>
								<p>
									We successfully received your application to become mentor. Further details Overlay approval
									will be shared on your registered email shortly.
								</p>
								<div className='get-started-cls'>
									<a href="/mentor-dashboard">
											<button className='btn btn-primary'>Go to Home</button>
									</a>
								</div>
							</div>
						</div>

					</div>
				</section>
			}
		</>
	);
};
export default Overlay;