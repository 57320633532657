import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import crossImg from '../img/cross.png';

 
import { useSelector } from "react-redux";
 
import("../css/custom.css");

const Track = () => {
  const navigate = useNavigate();
  const selectedSessionTypeSavedData = useSelector((state) => {
    const data = state.mentorServiceSessionTypeReducer?.data?.type || "";
    return data;
  });

  const [aboutSession, setAboutSession] = useState("");
  const aboutSessionChoices = [
    {
      text: "Gather Personal Information",
      value: "Gather Personal Information",
    },
    {
      text: "Provide Book References",
      value: "Provide Book References",
    },
    {
      text: "Create a task for mentee",
      value: "Create a task for mentee",
    },
  ];
  useEffect(() => {
     
    import("../css/custom.css");
  }, []);

  return (
    <>
      <div id="wrapper">
        <div className="step-cont">
          <div className="step-inner-cls">
            <form action="#" method="post" id="raq_questions">
              <div
                className="question-container  active_panel "
                id="question-6"
              >
                <div className="sign-form-cls">
                  <h3>Keep a note to track your sessions completion</h3>
                  <div className="custom-radio-cls">
                    <label>
                      What's your Milestone to achieve in first session?
                    </label>
                    <div className="custom-radio-bg">
                      <label className="custom-radio">
                        <input type="radio" checked="checked" name="radio" />
                        <span className="checkmark"></span>
                        <span className="cont">
                          {selectedSessionTypeSavedData}
                        </span>
                      </label>
                    </div>
                    {aboutSessionChoices.map((choice) => (
                      <div className="custom-radio-bg">
                        <label className="form-check-label" htmlFor="check1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="check1"
                            name="option1"
                            value={choice.value}
                            // onClick={()=>}
                            // checked
                          />
                          {choice.text}
                        </label>
                      </div>
                    ))}
                    {/* <div className="custom-radio-bg">
                        <label className="form-check-label" htmlFor="check2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="check2"
                            name="option2"
                            value="something"
                          />
                          Provide Book References
                        </label>
                      </div>
                      <div className="custom-radio-bg">
                        <label className="form-check-label" htmlFor="check3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="check3"
                            name="option3"
                            value="something"
                          />
                          Create a task for mentee
                        </label>
                      </div> */}
                    <div className="custom-radio-bg">
                      <a href="#">
                        <span>+</span> Add custom
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Track;
