import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AvailableHours from "./AvailableHours";
import BookingLimit from "./bookingLimit";
import LeaveDates from "./leaveDates";
import SessionSettings from "./sessionSettings";

const Availability = () => {
  const [showAvailabilityComponents, setShowAvailabilityComponents] =
    useState(1);
  const [currentComponent, setCurrentComponent] = useState();

  const setChildComp = (showAvailabilityComponents) => {
    switch (showAvailabilityComponents) {
      case 1:
        return setCurrentComponent(<AvailableHours />);
      // case 2:
      //   return setCurrentComponent(<LeaveDates />);
      // case 3:
      //   return setCurrentComponent(<SessionSettings />);
      // case 4:
      //   return setCurrentComponent(<BookingLimit />);
      default:
        return setCurrentComponent(<AvailableHours />);
    }
  };
  useEffect(() => {
    setChildComp(showAvailabilityComponents);
  }, [showAvailabilityComponents]);

  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="w-100">
      {/* <!-- Page Heading --> */}
      <div className="d-sm-flex align-items-center justify-content-between" >
        {/* <h1 className="h3 mb-0 text-gray-800 main-title">Go Back</h1> */}
      </div>

      {/* <!-- Content Row --> */}
      <div className="row">
        <div className="col-xl-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-header header-table tab-header">
              <ul
                className="nav nav-tabs"
                role="tablist"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#tabs-1"
                    role="tab"
                    onClick={() => {
                      setShowAvailabilityComponents(1);
                    }}
                  >
                    Available Hours
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#tabs-2"
                    role="tab"
                    onClick={() => {
                      setShowAvailabilityComponents(2);
                    }}
                  >
                    Blockout Dates
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#tabs-2"
                    role="tab"
                    onClick={() => {
                      setShowAvailabilityComponents(3);
                    }}
                    // onClick={() => navigate("open-sessions")}
                  >
                    Session Settings
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#tabs-2"
                    role="tab"
                    onClick={() => {
                      setShowAvailabilityComponents(4);
                    }}
                    // onClick={() => navigate("open-sessions")}
                  >
                    Booking limits
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="card-body table-inner">
              <div className="tab-content">{currentComponent}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Availability;
