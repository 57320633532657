import React from "react";
import { useState } from "react";
import "./UpdateName.css";

const UpdateName = ({ nameData, getUpdatedValues }) => {
  const [userName, setUserName] = useState(nameData);
  const changeToCamelCase = (name) => {
    const nameSplit = name.split(" ");
    let finalName = nameSplit
      ?.map((item) => {
        if (item) return item[0]?.toUpperCase() + item?.slice(1);
      })
      ?.join(" ");

    return finalName;
  };
  const handleOnChange = (e) => {
    let tempValue = changeToCamelCase(e.target.value);
    setUserName(tempValue);
  };
  const handleOnClick = () => {
    let tempData = { name: userName };
    getUpdatedValues(tempData);
  };
  return (
    <div className="update-name-main-container">
      <h7>Full Name</h7>
      <input
        className="name-input"
        value={userName}
        onChange={handleOnChange}
      ></input>
      <button onClick={handleOnClick} className="update-btn-name">
        Update
      </button>
    </div>
  );
};

export default UpdateName;
