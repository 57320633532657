import React, { useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import crossIcon from "../../../assets/images/cross-icon.png";
import { appendFormData } from '../../../redux/actions/course';
import { useDispatch } from 'react-redux';
import { UploadImage } from '../../UploadImage';

const AudioModal = ({ OpenModal, setOpenModal }) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState(null)
    const [formData, setFormData] = useState({
        link: "",
        title:""
    });




    const uploadAudio = (val, pre) => {
        console.log("uploadAudio called", val);

        setFormData((pre) => ({
            ...pre,
            link: val,
        }));
    };
  const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleUpload = (e) => {
        e.preventDefault();
        if (!formData?.link || !formData?.title) {
            setMessage("Audio not upload yet!!!")
            setTimeout(() => {
                setMessage('')
            }, 2000);
        }
        else {

            const UploadData = {
                ...formData,
                type: "ADD_AUDIO"
            };
             let actionType;

            switch (OpenModal?.add_type) {
                case 'ADD_CHAPTER':
                    actionType = "ADD_CHAPTER_TO_UNIT_BY_INDEX";
                    break;
                case 'ADD_UNIT':
                    actionType = "ADD_UNIT";
                    break;
                default:
                    actionType = "APPEND_FORM_DATA";
                    break;
            }

            dispatch(appendFormData(actionType, OpenModal?.chapterIndex, OpenModal?.unitIndex, UploadData));
            handleClose()
        }
    };
    const handleClose = () => {
        setOpenModal((prevState) => ({ ...prevState, show: false }))

    }
    return (
        <>

            <Modal
                size="xl"
                centered
                show={OpenModal.show}
                onHide={() =>
                    setOpenModal((prevState) => ({ ...prevState, show: false }))
                }
            >
                <ModalBody>
                    <button
                        type="button"
                        className="cloose"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                            setOpenModal((prevState) => ({ ...prevState, show: false }))
                        }
                    >
                        <div className="closebuttons">
                            <span aria-hidden="true">
                                <img src={crossIcon} alt="Close" />
                            </span>
                        </div>
                    </button>
                    <div className="create_course_modal" style={{ background: "white" }}>
                        <div className="modal-body">
                            <div className="modal-container">
                                <div className="modal-header">
                                    <div className="modal-title h5">
                                        New audio <small>Max audio size :: 200MB</small>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <div className="form-group">
                                                <label className="session__label">
                                                    Title: *
                                                </label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Title"
                                                    required=""
                                                    className="form-control"
                                                    value={formData.title}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        <form>
                                            <UploadImage
                                                callback={uploadAudio}
                                                fileName="_upload-audio"
                                                imagePath={"courseImage/courseAudio/"}
                                                filetype={["audio"]}
                                            />
                                            <div className="get-started-cls why_choose_ment">
                                                <a >
                                                    <button type="button" className="find-btn btn btn-primary">
                                                        Cancel
                                                    </button>
                                                </a>
                                                <button
                                                    onClick={handleUpload}
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {
                                    message && (<p>{message}</p>)
                                }
                                <h2 className="hr-lines"> OR </h2>
                                <div className="text-center">
                                    <button className="btn btn-sm googleDriveUploadBtn" data-type="pdf">
                                        <img src="https://dz8fbjd9gwp2s.cloudfront.net/resources/images/icons8-google-drive-48.png" alt="Google Drive" />{" "}
                                        Import from Google Drive
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default AudioModal;
