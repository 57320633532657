import React, { useState, useEffect, useCallback } from "react";
import { REACT_USER_ID } from "../auth/tokenProvider";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Select, message } from "antd";
import { TailSpin } from "react-loader-spinner";
import {
  createTaskAction,
  getUpcomingTaskAction,
  updateTaskAction,
} from "../../redux/actions/mentor";
import arrowDownImg from "../../components/dashboard/images/arrow-down.png";
import priceImg from "../../components/dashboard/images/price.png";
import noMentorImg from "../../components/dashboard/images/no-mentor.png";
import styles from '../MenteeAdminDashboard/MenteeAdminDashboard.module.css'

import { calendarImg, languageImg, mentorEvent } from "../../assets/images/export-images";
import { UploadImage } from "../UploadImage";

const { Option } = Select;

const Task = (props) => {
  const { ConnectionListData } = props;
  const dispatch = useDispatch();
  const mentor_id = REACT_USER_ID;

  const fileTypesJSON = {
    "fileTypes": [

      {
        "value": "image",
        "label": "Image"
      },
      {
        "value": "pdf",
        "label": "PDF"
      },
      {
        "value": "document",
        "label": "Document"
      },
      {
        "value": "spreadsheet",
        "label": "Spreadsheet"
      },
      {
        "value": "audio",
        "label": "Audio"
      },
      {
        "value": "video",
        "label": "Video"
      },
      {
        "value": "other",
        "label": "Other"
      }
      // Add more file types as needed

    ]
  }



  const [fileType, setFileType] = useState(""); // To store the selected file type
  const [fileData, setFileData] = useState({
    file: ""
  }); // To store the selected file type
  const [TaskData, setTaskData] = useState([])
  const [isLoading, setIsLoading] = useState()
  const [date, setDate] = useState("");
  const [editVisibility, setEditVisibility] = useState(false);


  // const onLoadGetTask = useCallback(() => {
  //   dispatch(getUpcomingTaskAction(mentor_id));
  // }, [mentor_id, dispatch]);


  const onLoadGetTask = () => {
    dispatch(getUpcomingTaskAction(mentor_id));

  }

  // Get the current date
  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };


  useEffect(() => {

    setDate(getCurrentDate());
  }, []);


  useEffect(() => {

    import("../../assets/css/bootstrap.min.css");
    import("../../assets/css/font-awesome.min.css");
    import("../../assets/css/owl.carousel.min.css");
    import("../../assets/css/owl.theme.default.min.css");
    import("../../components/dashboard/css/dashboard-mentor-subcription.css");
    import("../../assets/css/custom.css");
    import("../../assets/css/header.css");
    import("../../assets/css/dashboard.css");
    import("../../assets/css/footer.css");
    import("../../assets/css/media.css");

  }, []);
  useEffect(() => {

    onLoadGetTask()
  }, []);


  const getUpcomingTaskReducer = useSelector(
    (state) => state.getUpcomingTaskReducer
  );
  useEffect(() => {
    if (!getUpcomingTaskReducer?.loading
      && getUpcomingTaskReducer?.data
      && getUpcomingTaskReducer?.error === undefined) {
      setTaskData(getUpcomingTaskReducer?.data)
    }
    setIsLoading(getUpcomingTaskReducer?.loading)

  }, [getUpcomingTaskReducer])






  const [addTaskForm] = Form.useForm();
  const [updateTaskForm] = Form.useForm();

  const onFinish = (values) => {
    const data = {
      topic: values.topic,
      tasks_title: values.tasks_title,
      target_user_id: values.target_user_id,
      tasks_description: values.tasks_description,
      date,
      file: fileData?.file
    };
    dispatch(createTaskAction(data));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const createTaskReducer = useSelector((state) => state.createTaskReducer);


  useEffect(() => {
    setIsLoading(createTaskReducer?.loading)

    if (createTaskReducer?.data && !createTaskReducer?.loading && createTaskReducer?.isSuccess && createTaskReducer?.error === undefined) {
      message.success("Task has been added successfully");
      addTaskForm.resetFields();
      setTimeout(() => {
        onLoadGetTask()
      }, 1000);

    }
    if (createTaskReducer?.data === undefined && !createTaskReducer?.loading && createTaskReducer?.error !== undefined) {

      message.error(createTaskReducer?.error ? createTaskReducer?.error : "Something went wrong! ");

    }

  }, [createTaskReducer]);




  const handleEditClick = (id) => {
    setEditVisibility({ ...editVisibility, [id]: true });
  };

  const onFinishUpdateTask = (values) => {
    const updatedData = {
      topic: values.updatedTopic,
      tasks_title: values.updatedTitle,
      tasks_description: values.updatedDescription,
      tasks_id: values.tasks_id,
      date,
    };
    dispatch(updateTaskAction(updatedData));
  };

  const onFinishFailedUpdateTask = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const updateTaskReducer = useSelector((state) => state.updateTaskReducer);

  useEffect(() => {
    setIsLoading(updateTaskReducer?.loading)
    if (!updateTaskReducer?.loading && updateTaskReducer?.data !== undefined && updateTaskReducer?.error === undefined) {

      message.success("Task has been updated successfully");
      setEditVisibility({
        ...editVisibility,
        [updateTaskReducer?.data?.tasks_id]: false,
      });
      setTimeout(() => {
        onLoadGetTask()
      }, 1000);

    }

    if (!updateTaskReducer?.loading && !updateTaskReducer?.error !== undefined && updateTaskReducer?.data === undefined) {
      message.error(updateTaskReducer?.error ? updateTaskReducer?.error : "Something went wrong! ")
    }
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };
  const uploadFile = (val, pre) => {
    console.log("uploadAudio called", val);

    setFileData((pre) => ({
      ...pre,
      file: val,
    }));
  };

  return (
    <>


      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (null)}


      <section className="User___Activity">
        <h4>Create Task</h4>
        <div className=" Add_____notes mb-3">
          <Form
            className="px-3 py-3"
            form={addTaskForm}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >

            <div className="row">
              <Form.Item
                className="pt-2 task_select col"
                label="Select User"
                name="target_user_id"
                rules={[{ required: true, message: "Please select a user!" }]}
              >
                <Select
                  size={'large'}

                  placeholder="Select User"
                  className="rounded-3"
                >
                  {ConnectionListData?.map((option) => (
                    <Option
                      key={option?.connection_details?.user_id}
                      value={option?.connection_details?.user_id}
                    >
                      {option?.connection_details?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="col"
                label="Task Title"
                name="tasks_title"

                rules={[{ required: true, message: "Please input task title!" }]}
              >
                <Input style={{ borderRadius: '15px', boxShadow: 'none' }} />
              </Form.Item>

            </div>
            <Form.Item
              label="Task Description"
              name="tasks_description"
              rules={[
                { required: true, message: "Please input task description!" },
              ]}
            >
              <Input.TextArea
                style={{ height: "6rem", fontSize: "1rem", borderRadius: '15px', boxShadow: 'none' }}
              />
            </Form.Item>
            <Form.Item
              label="File Type"
              name="fileType"
              className="task_select"
              rules={[{ required: true, message: "Please select a file type!" }]}
            >
              <Select
                size={'large'}
                placeholder="Select File Type"
                style={{ width: "100%" }}
                onChange={(value) => setFileType(value)}
              >
                {fileTypesJSON.fileTypes.map((fileType) => (
                  <Option key={fileType.value} value={fileType.value}>
                    {fileType.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Conditionally render file upload input based on file type */}
            {fileType && (
              <>
                <UploadImage
                  callback={uploadFile}
                  fileName={`_upload-${fileType}`}
                  imagePath={`task/${fileType}/`}
                  filetype={[`${fileType}`]}
                />
              </>
            )}
            <Form.Item>
              <div className="get-started-cls text-end mb-1 mt-0 pb-2">
                <Button type="primary find-btn btn btn-primary" htmlType="submit">
                  Add Task
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>

        <div className="title__activity">
          <h6 className="activity_heading">Upcoming Task </h6>
        </div>




      </section>


      <section className="p-1">
        <div className="">
          <div className="subscribed-mentor-sec">
            <div
              className={`subscribe-mentor subscribe_box ${styles.home_tab_mentors}`}
            >
              <h4>Upcoming Task</h4>
              {TaskData && TaskData.length > 0 ? (
                TaskData.map((task, id) => (
                  <div key={task?.tasks_id} className="card my-2">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href={`#collapse${id}`}
                      >
                        <span className="title-main-cls">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="white"
                            className="bi bi-journal-text"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                            <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                            <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                          </svg>

                          {" "}Created by  <b>{task.sender?.name}</b>{" "}
                          <span className="dot"></span>Due Date on :{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="white"
                            className="bi bi-calendar"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                          </svg> {" "}
                          {" "}  {formatDate(task.date)}
                        </span>

                        <span className="arrow-ic " style={{ marginLeft: 'auto' }}>
                          <img alt='icon' src={arrowDownImg} />
                        </span>
                      </a>
                    </div>
                    <div
                      id={`collapse${id}`}
                      className="collapse"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        {editVisibility[task.tasks_id] ? (
                          <div className="Update___content">
                            <Form
                              form={updateTaskForm}
                              layout="vertical"
                              onFinish={onFinishUpdateTask}
                              onFinishFailed={onFinishFailedUpdateTask}
                            >
                              <Form.Item
                                label="Task Topic"
                                name="updatedTopic"
                                initialValue={task?.topic}
                                rules={[
                                  { required: true, message: "Please input task topic!" },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                label="Task Topic"
                                name="updatedTitle"
                                initialValue={task?.tasks_title}

                                rules={[
                                  { required: true, message: "Please input task title!" },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                label="Task Description"
                                name="updatedDescription"
                                initialValue={task?.tasks_description}

                                rules={[
                                  {
                                    required: true,
                                    message: "Please input task description!",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  style={{ height: "6rem", fontSize: "1rem" }}
                                />
                              </Form.Item>
                              <Form.Item
                                name="tasks_id"
                                hidden
                                initialValue={task.tasks_id}
                              />

                              <div className="get-started-cls text-end mb-3 mt-0">
                                <Button
                                  className="find-btn btn btn-primary"
                                  type="default"
                                  onClick={() =>
                                    setEditVisibility({
                                      ...editVisibility,
                                      [task.tasks_id]: false,
                                    })
                                  }
                                >
                                  Cancel
                                </Button>
                                <Button type="primary" className="find-btn btn btn-primary" htmlType="submit">
                                  Update Task
                                </Button>
                              </div>
                            </Form>
                          </div>
                        ) : (
                          <>
                            <div className="event-cont mw-100">
                              <div className="fav-event">
                                <div className="event-user">
                                  {task?.receiver?.profile_photo ? (
                                    <img
                                      src={`${process.env.REACT_APP_IMAGES_BASE_URL}${task?.receiver?.profile_photo}`}
                                      alt=""
                                    />
                                  ) : (
                                    <img alt='icon' src={mentorEvent} />
                                  )}
                                </div>
                              </div>
                              <div className="event-user-dtl">
                                <h5>{task?.receiver?.name}             <span
                                  style={{ marginLeft: "auto" }}
                                  onClick={() => handleEditClick(task.tasks_id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="black"
                                    class="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path
                                      fillRule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    />
                                  </svg>
                                </span>
                                </h5>

                                {/* title */}
                                <span className="language">
                                  <span className="lang-ic">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={20}
                                      height={20}
                                      fill="currentColor"
                                      className="bi bi-textarea-t"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M1.5 2.5A1.5 1.5 0 0 1 3 1h10a1.5 1.5 0 0 1 1.5 1.5v3.563a2 2 0 0 1 0 3.874V13.5A1.5 1.5 0 0 1 13 15H3a1.5 1.5 0 0 1-1.5-1.5V9.937a2 2 0 0 1 0-3.874zm1 3.563a2 2 0 0 1 0 3.874V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V9.937a2 2 0 0 1 0-3.874V2.5A.5.5 0 0 0 13 2H3a.5.5 0 0 0-.5.5zM2 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2m12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                                      <path d="M11.434 4H4.566L4.5 5.994h.386c.21-1.252.612-1.446 2.173-1.495l.343-.011v6.343c0 .537-.116.665-1.049.748V12h3.294v-.421c-.938-.083-1.054-.21-1.054-.748V4.488l.348.01c1.56.05 1.963.244 2.173 1.496h.386z" />
                                    </svg>

                                    {" "}{task?.tasks_title}
                                  </span>

                                </span>
                                {/* topic */}
                                <span className="language">
                                  <span className="lang-ic">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={20}
                                      height={20}
                                      fill="currentColor"
                                      className="bi bi-file-text"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
                                      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
                                    </svg>

                                    {" "}{task?.topic}
                                  </span>

                                </span>
                                <div className="date-payment-sec">
                                  <a
                                    href="#"

                                  >
                                    <span className="date-wrap">
                                      <span className="date-ic">
                                        <img alt='icon' src={calendarImg} />
                                      </span>
                                      <span className="date-cont">
                                        {formatDate(task.date)}
                                      </span>
                                    </span>
                                  </a>
                                  <span className="payment-wrap">
                                    <span className="date-ic">
                                      <img alt='icon' src={priceImg} />
                                    </span>
                                    <span className="date-cont">
                                      {task?.visibility}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="pre-session">
                              <span className="bold">Task Description : </span>


                              <span>   {task?.tasks_description}</span>



                            </div>
                            <div className="skills-sec">

                            </div>
                          </>
                        )}

                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-active-mentor-sec">
                  <div className="no-active-cont">
                    <h3>No Upcoming Task</h3>
                    {/* <h3>No Active Mentorship</h3> */}
                    <p>You haven't created  any task yet</p>
                    <div className="get-started-cls">

                    </div>
                  </div>
                  <div className="no-active-img">
                    <img alt='icon' src={noMentorImg} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default Task;
