import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import {
  getMentorAvailableHoursRequest,
  mentorAvailabilityDaysRequest,
  mentorAvailabilityTimeSlotsRequest,
  viewMentorProfileAction,
} from "../../../redux/actions/mentor";
import { TailSpin } from "react-loader-spinner";

const MentorAvailabilityComponent = ({ createMeetingData, handleChange }) => {
  const { timing, booked_date } = createMeetingData;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  import("./session.css");

  const mentor_id = REACT_USER_ID;

  const [availableDays, setAvailableDays] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState({});

  const [isDaysLoading, setIsDaysLoading] = useState(true);
  const [isTimeSlotsLoading, setIsTimeSlotsLoading] = useState(true);

  const [startIndex, setStartIndex] = useState(0);

  const [selectedAvailableDays, setSelectedDays] = useState("");
  const [selectedAvailableTimeSlots, setSelectedTimeSlots] = useState("");

  useEffect(() => {
    dispatch(mentorAvailabilityDaysRequest({ id: mentor_id }));
    dispatch(getMentorAvailableHoursRequest({ id: mentor_id }));
  }, [dispatch, mentor_id]);

  const availableDaysData = useSelector(
    (state) => state.mentorAvailabilityDaysReducer?.data
  );
  const availableTimeSlotsData = useSelector(
    (state) => state?.mentorAvailabilityTimeSlotsReducer?.data
  );

  const availableDaysDataLoading = useSelector(
    (state) => state?.mentorAvailabilityDaysReducer?.loading
  );
  const availableTimeSlotsDataLoading = useSelector(
    (state) => state?.mentorAvailabilityTimeSlotsReducer?.loading
  );
  useEffect(() => {
    setAvailableDays(availableDaysData);
    setIsDaysLoading(availableDaysDataLoading);
    setIsTimeSlotsLoading(availableTimeSlotsDataLoading);
    if (
      !availableTimeSlotsDataLoading &&
      Object.keys(availableTimeSlots).length === 0 &&
      availableTimeSlotsData !== undefined &&
      availableTimeSlotsData.length !== 0
    ) {
      setAvailableTimeSlots(availableTimeSlotsData);

      setSelectedTimeSlots(
        availableTimeSlotsData?.slotTime
          ? availableTimeSlotsData.slotTime[0]
          : ""
      );
      setIsTimeSlotsLoading(false);
    }
  }, [
    availableDaysData,
    availableTimeSlotsData,
    availableDaysDataLoading,
    availableTimeSlotsDataLoading,
  ]);

  useEffect(() => {
    if (availableDays && availableDays.length) {
      setSelectedDays(availableDays[0]?.iso);
      dispatch(
        mentorAvailabilityTimeSlotsRequest({
          id: mentor_id,
          date: availableDays[0]?.iso,
        })
      );
    }
  }, [availableDays]);

  let date = new Date();
  let datestring = date.toTimeString();

  var myTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatTimeInAMPM = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return dateTime.toLocaleString("en-US", options);
  };

  // Function to format date in "13 July, 2023" format in local timezone
  const formatDateToLocalFormat = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return dateTime.toLocaleString("en-US", options);
  };
  return (
    <div className="Model " style={{ background: "#f8f9fa" }}>
      {isDaysLoading || isTimeSlotsLoading ? (
        <div className="loader open_sessions_loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        ""
      )}
      <div className="container">
        <div className="  avilabilty___col ">
          <div className="Model_Padding mb-4 avilabilty__Modal">
            <div className="pb-3 grey-3-border border-bottom mb-3">
              <p className="Model_Heading font">Select Date</p>
              <p>
                In your local timezone
                {myTimeZone ? myTimeZone : "Not Detected"} &nbsp;
                <a href="/" className="Update_Color">
                  Update
                </a>
              </p>
            </div>
            <div className="d-flex" style={{ overflow: "auto" }}>
              {!isDaysLoading
                ? availableDays?.slice(startIndex).map((item, id) => (
                    <div
                      key={id}
                      slot={8}
                      className={`${
                        item?.iso == selectedAvailableDays
                          ? "selected-date"
                          : ""
                      } mx-2 Date_Picker Date_Content`}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedDays(item?.iso);
                        dispatch(
                          mentorAvailabilityTimeSlotsRequest({
                            id: mentor_id,
                            date: item?.iso,
                          })
                        );
                      }}
                    >
                      <div className="m-auto date-tab">
                        <small className="date__day">{item?.dayName}</small>
                        <span className="d-block Date_size date__date">
                          {item?.dateName}
                        </span>
                      </div>
                    </div>
                  ))
                : [1, 2, 3, 4].map((val, id) => (
                    <div
                      key={id}
                      height="74px"
                      slot={8}
                      className="mx-2 Date_Picker Date_Content"
                    >
                      <div className="m-auto">
                        <small className="date__day"></small>
                        <span className="d-block Date_size date__date"></span>
                        <small className="date__slot">Loading..</small>
                      </div>
                    </div>
                  ))}
            </div>
            <div className="mb-4">
              <div
                className="pb-3 d-flex align-items-center 
                      justify-content-between border-bottom grey-3-border mb-3"
              >
                <p className="Model_Heading font">Select Time</p>
              </div>
              <div className=" Time_slot">
                {!isTimeSlotsLoading
                  ? availableTimeSlots?.slotTime &&
                    availableTimeSlots?.slotTime?.map((item, id) => {
                      let date = new Date(item);
                      let hours = date.getHours()?.toString();
                      let minute = date.getMinutes()?.toString();
                      return (
                        <div
                          key={id}
                          onClick={() => {
                            setSelectedTimeSlots(item);
                          }}
                          className={`${
                            item == selectedAvailableTimeSlots
                              ? "selected-time"
                              : ""
                          } Time_slot_Box`}
                        >
                          <span className="m-auto" height="50px">
                            {hours.length === 1 ? "0" + hours : hours}:
                            {minute.length === 1 ? "0" + minute : minute}
                            {hours < 12 ? " AM" : " PM"}
                          </span>
                        </div>
                      );
                    })
                  : [1, 2, 3].map((item, id) => (
                      <div key={id} className="Time_slot_Box">
                        <span className="m-auto" height="50px">
                          loading...
                        </span>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MentorAvailability = React.memo(MentorAvailabilityComponent);
