import React, { useEffect, useState } from 'react';
import { Footer } from 'rsuite';
import DashboardHeader from '../../adminDashboard/DashboardHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { arNext, homeIcon } from '../../../assets/images/export-images';
import { useDispatch, useSelector } from 'react-redux';
import MentorAvailability from '../../MentorAvailability/main';
import { viewMentorProfileAction } from '../../../redux/actions/mentor';

const BookingMeetDetails = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { user } = useParams();
    const mentor_id = user.split("_")[1];

    console.log("mentor_id", mentor_id);
    const [mentor, setMentor] = useState({});
    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        dispatch(viewMentorProfileAction(mentor_id));
    }, [dispatch, mentor_id]);


    const mentorData = useSelector((state) => {
        const data = state.viewMentorProfileReducer?.data || [];
        return data[0];
    });

    const mentorDataLoading = useSelector(
        (state) => state?.viewMentorProfileReducer?.loading
    );
    useEffect(() => {
        setMentor(mentorData);
        setIsLoading(mentorDataLoading);

    }, [
        mentorData, mentorDataLoading]);

    return (
        <>

            <DashboardHeader />

            <div className="breadcrumb-sec">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <span onClick={() => navigate("/")}>
                            <img src={homeIcon} alt="home icon" />
                        </span>
                        <span className="breadcrumb-space">
                            <img src={arNext} alt="Next" />
                        </span>
                        <span
                            onClick={() => {
                                navigate("/find-mentor");
                            }}
                        >
                            Find Mentor
                        </span>
                        <span className="breadcrumb-space">
                            <img src={arNext} alt="Next" />
                        </span>
                        <a href="#" className="active">
                            {mentor?.name}
                        </a>
                    </div>
                </div>
            </div>

            <MentorAvailability mentor_id={mentor_id} mentor={mentor} isLoading={isLoading} />




            <Footer />
        </>
    );
}

export default BookingMeetDetails;
