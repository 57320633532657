import React, { useState } from 'react';
import Footer from '../footer';
import Header from '../header';
import { bannerImg } from '../../assets/images/export-images';
import ProtectedHeader from '../ProtectedHeader/ProtectedHeader';
import './enquiry.css';
import image1 from './img-01.webp';
import { useDispatch, useSelector } from 'react-redux';
import { fullStackAction } from '../../redux/actions/common';
import { useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';

const EnquiryPage = () => {
    const dispatch = useDispatch()

    const [type, setType] = useState(false);

    useEffect(() => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const type = url.searchParams.get('type');
        console.log(type);
        if (type !== '') {
            setType(type)
        }
        else {
            console.log('Invalid enquriy type ')
            setType('Outside enquiry')
        }
    }, []);

    const [loader, setLoader] = useState(false);
    const [successMsg, setSuccessMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [loggedIn, setLoggedIn] = useState(localStorage.getItem("REACT_TOKEN_AUTH") ? true : false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        inquiry_type: type,
    });

    const [validationErrors, setValidationErrors] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        // Clear the validation error for the field being updated
        setValidationErrors({ ...validationErrors, [name]: '' });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validate the form data
        let isValid = true;

        if (!formData.name.trim()) {
            setValidationErrors({ ...validationErrors, name: 'Name is required' });
            isValid = false;
        }

        if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
            setValidationErrors({ ...validationErrors, email: 'Valid email is required: ex@abc.xyz' });
            isValid = false;
        }

        if (!formData.message.trim()) {
            setValidationErrors({ ...validationErrors, message: 'Message is required' });
            isValid = false;
        }

        if (isValid) {
            // Data is valid, you can console log it and perform your desired action here
            console.log("data", formData);
            dispatch(fullStackAction(formData))
        }
    };
    const EnquiryFormStatus = useSelector(
        (state) => state.fullStackReducer?.status
    );
    const EnquiryFormReducer = useSelector(
        (state) => state.fullStackReducer
    );
    useEffect(() => {
        setLoader(EnquiryFormReducer?.loading)
    }, [EnquiryFormReducer]);

    useEffect(() => {
        if (EnquiryFormStatus === 200) {
            setSuccessMsg('Form submitted successfully');
            setTimeout(() => {
                setSuccessMsg(null)
            }, 2000);
        }
        if (EnquiryFormReducer?.error !== undefined && EnquiryFormReducer?.loading === false) {
            setErrorMsg('Please try again later');
            setTimeout(() => {
                setErrorMsg(null)

            }, 2000);
        }
    }, [EnquiryFormStatus, EnquiryFormReducer])
    return (
        <>
            {loader && (
                <div className="loader" style={{ zIndex: 9999 }}>
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            )}
            {loggedIn ? <ProtectedHeader /> : <Header />}
            <section className="banner-sec" style={{ backgroundImage: `url(${bannerImg})`, padding: "5% 0" }}>
                <div className="container">
                    <div className="mentroship-sec pt-4">
                        <h1>Do you have any questions?</h1>
                        <p>
                            Please do not hesitate to contact us directly.
                            Our team will come back to you within a matter of hours to help you.
                        </p>
                    </div>
                </div>
            </section>
            <div className="bg-contact100" style={{ backgroundImage: `url("images/bg-01.jpg")` }}>
                <div className="container-contact100 container">
                    <div className="wrap-contact100">
                        <div className="contact100-pic js-tilt" data-tilt="" style={{ willChange: "transform", transform: "perspective(300px) rotateX(0deg) rotateY(0deg)" }}>
                            <img src={image1} alt="IMG" />
                        </div>
                        <form className="contact100-form validate-form">
                            <span className="contact100-form-title">Get in touch</span>
                            <div className={`wrap-input100 validate-input ${validationErrors.name && 'alert-validate'}`} data-validate="Name is required">
                                <input className="input100" type="text" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} />
                                <span className="focus-input100" />
                                <span className="symbol-input100">
                                    <i className="fa fa-user" aria-hidden="true" />
                                </span>
                            </div>
                            {/* {validationErrors.name && <p className="text-danger">{validationErrors.name}</p>} */}
                            <div className={`wrap-input100 validate-input ${validationErrors.email && 'alert-validate'}`} data-validate="Valid email is required: ex@abc.xyz">
                                <input className="input100" type="text" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} />
                                <span className="focus-input100" />
                                <span className="symbol-input100">
                                    <i className="fa fa-envelope" aria-hidden="true" />
                                </span>
                            </div>
                            {/* {validationErrors.email && <p className="text-danger">{validationErrors.email}</p>} */}
                            <div className={`wrap-input100 validate-input ${validationErrors.message && 'alert-validate'}`} data-validate="Message is required">
                                <textarea className="input100" name="message" placeholder="Message" value={formData.message} onChange={handleInputChange} />
                                <span className="focus-input100" />
                            </div>
                            {/* {validationErrors.message && <p className="text-danger">{validationErrors.message}</p>} */}
                            <div className="container-contact100-form-btn">
                                <button type="submit" onClick={handleSubmit} className="contact100-form-btn">Send</button>
                            </div>
                            {errorMsg && <p>{errorMsg}</p>}
                            {successMsg && <p>{successMsg}</p>}
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default EnquiryPage;
