import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import crossIcon from "../../../assets/images/cross-icon.png";
import mentorLogo from "../../../assets/images/mentor-logo.png";
import fbLogo1 from "../../../assets/images/facebook-1.png";
import twitterLogo1 from "../../../assets/images/twitter-1.png";
import linkedInLogo1 from "../../../assets/images/linkedin-1.png";
import mentorWlogo from "../../../assets/images/mentor-wlogo.png";
import LoginModal from "../loginModal";

const ThankYouModal = ({ headerModal, setHeaderModal }) => {
  return (
    <Modal
      size="xl"
      centered
      show={headerModal.show}
      onHide={() =>
        setHeaderModal((prevState) => ({ ...prevState, show: false }))
      }
    >
      <ModalBody>
      <button
            type="button"
            className="cloose"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              setHeaderModal((prevState) => ({ ...prevState,
                modal:LoginModal,
                show: true }))
            }
          >
            <div className="closebuttons">
              <span aria-hidden="true">
                <img src={crossIcon} />
              </span>
            </div>
          </button>
        <form>
          <div className="signup-row">
            <div className="signup-leftcol">
              <div className="sign-form-cls">
                <div className="logo-sec">
                  <h4>Welcome to</h4>
                  <img src={mentorLogo} alt="Mentorpal" />
                </div>
                <div className="wel-space-cls">
                  <div className="singup-thanku">
                    <h5>Thank You!</h5>
                    <p>
                     Successfylly signed up.
                      <span>
                        You may login now!
                        {/* <span>verification link</span> */}
                      </span>
                    </p>
                  </div>
                  {/* <div className="form-group">
                      <button
                        type="button"
                        className="submit-btn form-control verified-mail"
                        data-toggle="modal"
                        data-target="#EnterMobileNumber	"
                      >
                        Verify Email
                      </button>
                    </div> */}
                </div>
              </div>
              {/* <div className="signup-or">
                <h5 >Thank You For Joining Us!!</h5>
              </div> */}

              {/* <div className="singup-upp">
                <button type="button" className="btn btn-primary">
                  Verify Email
                </button>
              </div> */}
            </div>
            <div className="signup-rightcol">
              <div className="signup-cont">
                <div className="signup-logo d-none d-md-block">
                  <img src={mentorWlogo} alt="" />
                </div>
                <div className="signup-inner">
                  <p>
                    Mentorship from the global expert. join one-on-one Live
                    session and group session from the verified mentors at your
                    fingertips
                  </p>
                  <button
                    onClick={() =>
                      setHeaderModal({ modal: LoginModal, show: true })
                    }
                  >
                    Already Member?Login here
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default ThankYouModal;
