import React from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import crossIcon from "../../../../assets/images/cross-icon.png";
import timeImg from "../../../../assets/images/time.png";
import calendarImg from "../../../../assets/images/celender.png";
import useRazorpay from "react-razorpay";
import { useDispatch, useSelector } from "react-redux";
import { getOrderId } from "../../../../redux/actions/session";

const OneTimeSessionModal = ({ modal, setModal }) => {
  const Razorpay = useRazorpay();
  const dispatch = useDispatch();
  const handlePayment = async () => {
    const options = {
      key_id: 'rzp_live_XfgN7yl01ogS37',
      key_secret: 'jnEtdUK5KM585GuRFcg1LPjO',
      amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Acme Corp ltd",
      description: "Test Transaction",
      order_id: "order_KLny6vukPvVe1t",
      image: "https://example.com/your_logo",
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  }

  const initiatePayment = async () => {

    let data = {
      "amount": 50000,
      "currency": "INR",
      "receipt": "receipt#1",
      "notes": {
        "key1": "value3",
        "key2": "value2"
      },
      "mentee_id": "123",
      "mentor_id": "1234",
      "product_id": "123456"
    }

    dispatch(getOrderId(data))
    // await handlePayment()
  }



  return (
    <Modal
      size="xl"
      centered
      show={modal.show}
      onHide={() => setModal((prevState) => ({ ...prevState, show: false }))}
    >
      <ModalBody>
        <form>
          <ModalHeader>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <img
                  src={crossIcon}
                  onClick={() =>
                    setModal((prevState) => ({ ...prevState, show: false }))
                  }
                />
              </span>
            </button>
          </ModalHeader>
          <div className="group-session-sec">
            <div className="session-inner-cls">
              <div className="session-left-sec">
                <div className="session-title">
                  <h3>Interview Preparation</h3>
                  <div className="time-day">
                    <div className="time-seesion">
                      <span className="session-ic">
                        <img src={timeImg} alt="" />
                      </span>
                      <span className="session-con">1 Hour Session</span>
                    </div>
                    <div className="day-seesion">
                      <span className="session-ic">
                        <img src={calendarImg} alt="" />
                      </span>
                      <span className="session-con">Every Week</span>
                    </div>
                  </div>
                  <div className="sess-box-cont">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="session-right-sec">
                <div className="session-price">
                  <h4>
                    $50/<span>Session</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="time-select-sec">
            <div className="row">
              <div className="col-md-6">
                <div className="time-title">
                  <h4>Choose Session Date</h4>
                </div>
                <div className="celender_cls">
                  <h5>Select Session Slot</h5>
                  <div className="calendar-inner-cls">
                    <ul className="weekdays">
                      <li>Mo</li>
                      <li>Tu</li>
                      <li>We</li>
                      <li>Th</li>
                      <li>Fr</li>
                      <li>Sa</li>
                      <li>Su</li>
                    </ul>
                    <ul className="days">
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                      <li>4</li>
                      <li>5</li>
                      <li>6</li>
                      <li>7</li>
                      <li>8</li>
                      <li>9</li>
                      <li>
                        <span className="active">10</span>
                      </li>
                      <li>11</li>
                      <li>12</li>
                      <li>13</li>
                      <li>14</li>
                      <li>15</li>
                      <li>16</li>
                      <li>17</li>
                      <li>18</li>
                      <li>19</li>
                      <li>20</li>
                      <li>21</li>
                      <li>22</li>
                      <li>23</li>
                      <li>24</li>
                      <li>25</li>
                      <li>26</li>
                      <li>27</li>
                      <li>28</li>
                      <li>29</li>
                      <li>30</li>
                      <li>31</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="time-title">
                  <h4>Choose sessesion timing</h4>
                </div>
                <div className="time_sel_cls">
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        name=""
                        placeholder="7:00 PM to 8 PM"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group active">
                      <input
                        type="text"
                        name=""
                        placeholder="9:00 PM to 10 PM"
                        className="form-control"
                        checked=""
                      />
                      <span className="checked-cls">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none"></path>
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"></path>
                        </svg>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="cv-cls">
              <h3>Upload CV</h3>
              <div className="form-group input-group">
                <input type="text" name="" className="form-control" />
                <div className="file_input">
                  <input
                    type="file"
                    name="Choose a file"
                    id="file-upload"
                    className="form-btn"
                  />
                  <span for="fileToUpload">Choose a file</span>
                </div>
              </div>
            </div>
          </div>
          <div className="get-started">
            <button
              onClick={() => {
                initiatePayment()
              }}
              type="button"
              className="btn vew-profile-btn"
            >
              Continue
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal >
  );
};

export default OneTimeSessionModal;
