import {
  commonConstants,
  feedbackConstants,
  updateCalendarSettings,
  personalityTest,
  testResponseConstants,
  fullStackConstants,
  liveSessionConstants,
  sessionDetailsConstants,
  enrollSessionConstants,
  createSessionConstants,
  menteeGoalActionConstants,
  sendChatMessageConstants,
  getChatMessageConstants,
  getConnectionRequestConstants,
  updateConnectionRequestConstants,
  getConnectionListConstants,
  getGoalConstants,
  getNotificationConstants,
  updateNotificationStatusConstants,
  contactConstants,
  SubscribeConstants,
  addBankAccountConstants,
  getBankDetailsConstants,
} from "../../constants";

export function uploadImage(data) {
  return { type: commonConstants.UPLOAD_IMAGE_REQUEST, data };
}

export function uploadImageFile(data) {
  return { type: commonConstants.UPLOAD_IMAGE_FILE_REQUEST, data };
}

export function feedbackformSubmitDataAction(data) {
  return { type: feedbackConstants.FEEDBACK_SUBMIT_REQUEST, data };
}

export function updateCalendarSettingsAction(data) {
  return {
    type: updateCalendarSettings.UPDATE_CALENDAR_SETTINGS_REQUEST,
    data,
  };
}

export function personalityTestAction(data) {
  return { type: personalityTest.TEST_REQUEST, data };
}

export function testResponse(data) {
  return { type: testResponseConstants.TEST_SUBMIT_REQUEST, data };
}
export function fullStackAction(data) {
  return { type: fullStackConstants.FULL_SUBMIT_REQUEST, data };
}
export function liveSessionAction(data) {
  return { type: liveSessionConstants.LIVESESSION_SUBMIT_REQUEST, data };
}
export function sessionDetailsAction(data) {
  return { type: sessionDetailsConstants.SESSION_DETAILS_REQUEST, data };
}
export function enrollSessionAction(data) {
  return { type: enrollSessionConstants.ENROLL_SESSION_REQUEST, data };
}
export function createSessionAction(data) {
  return { type: createSessionConstants.CREATE_SESSION_REQUEST, data };
}
export function menteeGoalAction(data) {
  return { type: menteeGoalActionConstants.MENTEE_GOAL_REQUEST, data };
}
export function sendChatMessageAction(data) {
  return { type: sendChatMessageConstants.SEND_CHAT_MESSAGE_REQUEST, data };
}
export function getChatMessageAction(data) {
  return { type: getChatMessageConstants.GET_CHAT_MESSAGE_REQUEST, data };
}

export function getConnectionRequestAction(data) {
  return {
    type: getConnectionRequestConstants.GET_CONNECTION_REQUEST_REQUEST,
    data,
  };
}
export function updateConnectionRequestAction(data) {
  return {
    type: updateConnectionRequestConstants.UPDATE_CONNECTION_REQUEST_REQUEST,
    data,
  };
}
export function getConnectionListAction(data) {
  return {
    type: getConnectionListConstants.GET_CONNECTION_LIST_REQUEST,
    data,
  };
}
export function getGoalAction(data) {
  return {
    type: getGoalConstants.GET_GOAL_REQUEST,
    data,
  };
}
export function getNotificationAction(data) {
  return {
    type: getNotificationConstants.GET_NOTIFICATION_REQUEST,
    data,
  };
}
export function updateNotificationStatusAction(data) {
  return {
    type: updateNotificationStatusConstants.UPDATE_NOTIFICATION_STATUS_REQUEST,
    data,
  };
}

export function subscribeAction(data) {
  return {
    type: SubscribeConstants.SUBSCRIBE_REQUEST,
    data,
  };
}


export function contactSubmitDataAction(data) {
  return {
    type: contactConstants.CONTACT_REQUEST,
    data,
  };
}
export function addBankAccountAction(data) {
  return {
    type: addBankAccountConstants.ADD_ACCOUNT_REQUEST,
    data,
  };
}
export function getBankDetailsAction(data) {
  return {
    type: getBankDetailsConstants.GET_ACCOUNT_REQUEST,
    data,
  };
}
export function mpqTestDataAction(data) {
  return {
    type: 'SET_DATA',
    data:data,
  };
}

