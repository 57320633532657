
import Ajay from "../../../src/assets/images/Ajay.jpg";
import Brijlal from "../../../src/assets/images/BrijLal Yogi copy.jpg";
import Utkarsh from "../../../src/assets/images/utkar - Utkarsh copy.jpg";
import Manish from "../../../src/assets/images/manish jangra.jpg";
import Ankit from "../../../src/assets/images/Ankit copy.jpg";
import Ankush from "../../../src/assets/images/Ankush Goel copy.jpg";
import Sarthak from "../../../src/assets/images/SARTHAK GOEL.jpg";
import Rajkumar from "../../../src/assets/images/RAJ Kumar copy.jpg";
import Surender from "../../../src/assets/images/Surender.jpg";
import AnkitSingh from "../../../src/assets/images/ankit singh copy.jpg";



const Cardsdata = [
    {
        id: 1,
        fname: "Brijlal Yogi",
        imgdata: Brijlal,
        profession: " CEO at Mentorpal.ai",
    },
   
   
    {
        id: 2,
        fname: "Utkarsh Kislay",
        imgdata: Utkarsh,
        profession: "Full-Stack Web-Developer",
          
    },

    {
        id: 3,
        fname: "Manish Jangra",
        imgdata: Manish,
        profession: "Cloud Engineer",
          
    },

    {
        id: 4,
        fname: "Ankit",
        imgdata: Ankit,
        profession: "Full-Stack Web-Developer",
          
    },
    {
        id: 5,
        fname: "Ajay",
       imgdata: Ajay,
        profession: "Front-end Developer",
          
    },

    {
        id: 6,
        fname: "Ankush Goel",
        imgdata: Ankush,
        profession: " Full Stack Web Developer",
          
    },


    {
        id: 7,
        fname: "Sarthak Goel",
        imgdata: Sarthak,
        profession: "Full Stack Web Developer",
          
    },

    {
        id: 8,
        fname: "RajKumar Prajapat",
        imgdata: Rajkumar,
        profession: "Front-end Developer",
          
    },
    {
        id: 9,
        fname: "Surender Prajapat",
       imgdata: Surender,
        profession: "Graphic Designer",
          
    },

    {
        id: 10,
        fname: "Ankit Singh",
        imgdata: AnkitSingh,      
          profession: "Full-Stack Web Developer",
          
    },
];

export default Cardsdata;