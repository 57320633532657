import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './testimonial.css'
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import maleImg from '../../assets/images/male.png'
import femaleImg from '../../assets/images/female.png'
import narendra from '../../assets/images/testiImages/narendra.jpg'
import surendra from '../../assets/images/testiImages/surendra.jpg'
import hitesh from '../../assets/images/testiImages/hitesh.png'
import munesh from '../../assets/images/testiImages/munesh.jpg'
import snu from '../../assets/images/testiImages/snu.jpg'
import shbh from '../../assets/images/testiImages/3rd.jpeg'
import first from '../../assets/images/testiImages/2nd.jpg'
import second from '../../assets/images/testiImages/1st.jpg'
import fourth from '../../assets/images/topmentor2.jpg'
import srbh from '../../assets/images/testiImages/saurabh.jpg'
import ankit from '../../assets/images/testiImages/ankit.jpeg'
import lima from '../../assets/images/testiImages/lima.jpeg'
import nav from '../../assets/images/testiImages/nav.jpg'
import feb from '../../assets/images/testiImages/feb.jpeg'
import sat from '../../assets/images/testiImages/satyendra.jpeg'
import neha from '../../assets/images/testiImages/neha.jpeg'
import salman from '../../assets/images/testiImages/salman.jpeg'
import greta from '../../assets/images/top-mentor-1.png'

const Testimonial = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 992 },
      items: 3
    },

    ipad: {
      breakpoint: { max: 992, min: 768 },
      items: 2
    },

    tablet1: {
      breakpoint: { max: 768, min: 465 },
      items: 1
    },

    mobile: {
      breakpoint: { max: 465, min: 0 },
      items: 1
    }
  };
  return (
    <>

      <Carousel autoPlaySpeed={2000} rewindWithAnimation={true} rewind={true} autoPlay={true} className='testimonial_cards' responsive={responsive}>

        <Card className='testiCard'>


          <Card.Body>
            <Card.Text><FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />
              MentorPal.ai revolutionized my career, refining my skills with a seasoned mentor. Supported by their expertise and guidance, I confidently mastered development concepts, accelerating my success. Choose MentorPal.ai for transformative mentorship.<FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>

            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={first} alt="Reviewer's image" className='testi__image' style={{ width: '30px', height: '30px' }} /></span>
              <span><Card.Title className='fs-6'>Jacob Wasi
                <p className="reviewer_title">Web Developer</p></Card.Title></span>        </span>

          </Card.Body>
        </Card>

        <Card className='testiCard'>


          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />
              MentorPal.ai played a crucial role in my career, my mentor guide me through real-world data science projects. I gained invaluable hands-on experience, honed my analytical skills, and found a nurturing platform to thrive as an aspiring data scientist.<FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={shbh} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Shubhangi Shah
                <p className="reviewer_title">Data Scientist</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>

        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />
              MentorPal.ai empowers me as a mentor to guide aspiring software engineers seamlessly. Its intuitive interface and  support make mentoring a rewarding experience. Highly recommended for mentors and mentees in software engineering.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={ankit} alt="Reviewer's image" className=' testi__image' /></span>
              <span><Card.Title className='fs-6'>Ankit K.
                <p className="reviewer_title">Software Engineer</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />
              MentorPal.ai has transformed my mentoring experience with aspiring engineers. Its seamless interface and comprehensive resources empower mentors and mentees to connect and drive career growth. A truly empowering platform for all.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={munesh} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Munesh Kumar
                <p className="reviewer_title">SDE-2</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />MentorPal.ai has been my ultimate gateway to transformative mentorship and growth in the field of UI/UX. My mentors have elevated my skills, opening doors to career and many growth possibilities. MentorPal.ai is a game-changer for aspiring UI/UX.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={second} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Marlon Redro<br></br>
                <p className="reviewer_title">UI/UX</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />
              MentorPal.ai has been an incredible platform for me as a graphics design mentee. The experienced mentors have helped me refine my skills and explore new creative graphics techniques. I'm grateful for the guidance and inspiration I've received.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={narendra} alt="Reviewer's image" className=' testi__image' /></span>
              <span><Card.Title className='fs-6'>Narendra
                <p className="reviewer_title">Graphics Designer</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />As a Content Writer on MentorPal.ai, I've experienced remarkable growth. My mentor's guidance has enhanced my writing skills, enabling me to produce and deliver high-quality content to my clients. MentorPal.ai is a game-changer for aspiring writers.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={sat} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Satyendra
                <p className="reviewer_title">Content Writer</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />
              At MentorPal.ai my mentor has elevated my graphic design skills to new heights. Their insights and guidance have empowered me to create visually stunning designs that exceed client expectations. MentorPal.ai is the go-to platform for aspiring graphic designers.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={surendra} alt="Reviewer's image" className=' testi__image' /></span>
              <span><Card.Title className='fs-6'>Surendra Singh
                <p className="reviewer_title">Graphics Designer</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />
              I am extremely happy here as a product research mentor. The platform's seamless interface and passionate mentees have made mentoring an incredibly experience. MentorPal.ai is the go-to platform for aspiring product researchers seeking invaluable guidance.

              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={neha} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Neha Vashishth
                <p className="reviewer_title">Product Research</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />MentorPal.ai has transformed my role as an SDE-2 mentor. The platform's seamless interface and unparalleled support have made mentoring an incredibly experience. I recommend MentorPal.ai for aspiring developers seeking guidance and growth success.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={hitesh} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Hitesh Kumar
                <p className="reviewer_title">SDE-2</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />The platform's passionate and dedicated mentees are eager to learn and grow, making mentoring an enriching and rewarding journey. MentorPal.ai empowers me to share my expertise and make a lasting and meaningful impact on aspiring data science.

              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={lima} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>John Lima
                <p className="reviewer_title">Sr. Data-Scientist</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>


        <Card className='testiCard'>


          <Card.Body>
            <Card.Text><FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />

              My mentor on MentorPal.ai truly empowers me to shape the future of product management. Guiding aspiring PMs and witnessing their remarkable growth is incredibly rewarding. It's a dynamic platform that fuels mentorship and fosters a lasting impact.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={nav} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Nav Merchi <br></br>
                <p className="reviewer_title">Product Manager</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />MentorPal.ai has been my gateway to transformative mentorship and growth in the field of UI/UX. My mentors have elevated my skills, opening doors to career prospects and many possibilities. MentorPal.ai is truly a game-changer for aspiring UI/UX.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={srbh} alt="Reviewer's image" className=' testi__image' /></span>
              <span><Card.Title className='fs-6'>Saurabh Yadav<br></br>
                <p className="reviewer_title">UI/UX</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />MentorPal.ai revolutionized my front-end development career. Guided by a skilled mentor, I learned concepts, best practices, and emerging technologies. With resources and regular mentorship, MentorPal.ai kept me motivated and focused on my goals.<FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={snu} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Joshua k.
                <p className="reviewer_title">Front-end Developer</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />The platform's diverse pool of experienced mentors guided me through real-world projects, honing my coding skills and keeping me updated with the latest industry trends. Thanks to MentorPal.ai, I'm confident in tackling any web development challenge.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={greta} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Greta skavo<br></br>
                <p className="reviewer_title">Software Developer</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />Mentorpal.ai connected me with exceptional mentors who provided invaluable guidance and insights. I gained practical experience, learned  techniques, and built a strong foundation in data science. It's the perfect platform for aspiring data scientists.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={feb} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Febrizio<br></br>
                <p className="reviewer_title">Data Scientist</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />The MentorPal platform connected me with experienced mentors who helped me refine my writing skills, develop my unique voice, and deliver high-quality content. MentorPal is a valuable resource for aspiring content writers seeking guidance.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={salman} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Salman Ali<br></br>
                <p className="reviewer_title">Content Writer</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>
        <Card className='testiCard'>

          <Card.Body>
            <Card.Text>
              <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="me-1 quote_size" />The experienced mentors on the platform have guided me through the intricacies of product strategy, market analysis, and user insights. With MentorPal.ai, I've gained the skills and knowledge needed to excel in the field of product management.
              <FontAwesomeIcon icon={faQuoteRight} size="2x" className="ms-1 quote_size" />
            </Card.Text>
            <span className='d-flex justify-content-start'>
              <span><img variant="top" src={fourth} alt="Reviewer's image" className='testi__image' /></span>
              <span><Card.Title className='fs-6'>Andrea Takani<br></br>
                <p className="reviewer_title">Product Management</p></Card.Title></span>        </span>
          </Card.Body>
        </Card>


      </Carousel>
    </>
  )
}

export default Testimonial;