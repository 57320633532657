import React, { useState } from "react";
import DashboardHeader from "../adminDashboard/DashboardHeader";
import { Outlet, useNavigate } from "react-router-dom";
import { logoutUser } from "../../redux/actions/login";
import { useDispatch, useSelector } from "react-redux";
import RightSidebar from "../MentorDashboard/RightSidebar/index";
import crossIcon from "../../assets/images/cross-icon.png";
import ProfileCard from "./profileCard";

const AdminSideNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogOut = () => {
    window.location.assign("/");
    localStorage.removeItem("REACT_TOKEN_AUTH");
    localStorage.removeItem("REACT_USER_ID");
    dispatch(logoutUser(""));
  };
  const [showSidebar, setShowSidebar] = useState(true);
  const showSideNavbar = () => {
    // Function logic goes here
    console.log("Showing side navbar...");
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      <DashboardHeader showSideNavbar={showSideNavbar} />
      <div className="sidebar__container d-flex ">
        <div
          className="sidebar-overlay"
          style={{
            display: showSidebar ? "block" : "none",
            "@media screen and (max-width: 768px)": {
              display: "block",
            },
          }}
          onClick={showSideNavbar}
        />

        {/* Sidebar Content */}
        <div
          className="sidebar-content"
          style={{
            display: showSidebar ? "block" : "none",
            transform: showSidebar ? "translateX(0)" : "none",
            transition: "transform 0.3s ease",
            "@media screen and (max-width: 768px)": {
              display: "block",
              transform: "none",
            },
          }}
        >
          <ul className="nav flex-column">
            <li className="nav-item itemss " id="cross___Icon">
              <a className="nav-link linkss " onClick={showSideNavbar}>
                <span className="d-flex align-items-center">
                  {" "}
                  <img src={crossIcon} alt="" />
                </span>{" "}
              </a>
            </li>
            <li className="nav-item itemss ">
              <a className="nav-link linkss" onClick={() => navigate("")}>
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="black"
                      class="bi bi-speedometer2 ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
                      />
                    </svg>
                    <span className="ms-2" style={{ color: "grey" }}>
                      Dashboard
                    </span>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("connection-request")}
              >
                {" "}
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-plus-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      <path
                        fill-rule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                    <article className="ms-2" style={{ color: "grey" }}>
                      Connection Requests
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("create-sessions")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-plus-square-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      Master class
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("sessions/upcoming")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-people-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                    </svg>
                    <article className="ms-2" style={{ color: "grey" }}>
                      Your Session
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("availability")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="black"
                      className="bi bi-check-square ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      Availabiity
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("connect-calendar")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-calendar3 ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                      <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      Connect Calender
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("my-ebooks")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-file-earmark-lock-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7 7a1 1 0 0 1 2 0v1H7V7zM6 9.3c0-.042.02-.107.105-.175A.637.637 0 0 1 6.5 9h3a.64.64 0 0 1 .395.125c.085.068.105.133.105.175v2.4c0 .042-.02.107-.105.175A.637.637 0 0 1 9.5 12h-3a.637.637 0 0 1-.395-.125C6.02 11.807 6 11.742 6 11.7V9.3z" />
                      <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 1 1 4 0z" />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      Your E-books
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("publish-ebooks")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-book-half ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      Publish E-book
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>

            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("post-article")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-text-paragraph ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"
                      />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      Post an Article
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("profile")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-person-circle ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                    <article className="ms-2" style={{ color: "grey" }}>
                      Your Profile
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>

            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("upload-intro")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-camera-video-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
                      />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      Intro Video
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("course")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    {" "} <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-journal-bookmark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="black"
                        d="M6 8V1h1v6.117L8.743 6.07a.5.5 0 0 1 .514 0L11 7.117V1h1v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8z"
                      />
                      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                    </svg>{" "}

                    <article className="ms-2" style={{ color: "black" }}>
                      Course
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li className="nav-item itemss">
              <a
                className="nav-link linkss"
                onClick={() => navigate("webinar")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    {" "} <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-person-video2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                      <path d="M2 1a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2ZM1 3a1 1 0 0 1 1-1h2v2H1V3Zm4 10V2h9a1 1 0 0 1 1 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3H5Zm-4-2h3v2H2a1 1 0 0 1-1-1v-1Zm3-1H1V8h3v2Zm0-3H1V5h3v2Z" />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      Webinar
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>

            <li className="nav-item itemss">
              <a className="nav-link linkss" onClick={handleLogOut}>
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-box-arrow-left ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                      />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      LogOut
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div id="content-wrapper" className=" w-100">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Outlet />
            {/* <AdminDashboardSection /> */}
            {/* <!-- End of Topbar --> */}
          </div>
        </div>
        {/* <div className="RightSide_Card____item">
          <ProfileCard />
        
        </div> */}
      </div>
    </>
  );
};

export default AdminSideNavBar;
