import React from 'react';
import '../custom.createcourse.css'
import { useState } from 'react';
import AddItemModal from '../../Modal/addItemModal';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddTextChapter from '../../SyllabusData/AddTextChapter';
import AddLinkChapter from '../../SyllabusData/AddLinkChapter';
import AddLiveClassChapter from '../../SyllabusData/AddLiveClassChapter';
import AddYoutubeLive from '../../SyllabusData/AddYoutubeLive';
import AddAssignmentChapter from '../../SyllabusData/AddAssignmentChapter';
import AddFormChapter from '../../SyllabusData/AddFormChapter';
import AddPdfChapterData from '../../SyllabusData/AddPdfChapterData';
import AddUploadVideoChapter from '../../SyllabusData/AddUploadVideoChapter';
import AddEmbedCodeChapter from '../../SyllabusData/AddEmbedCodeChapter';
import AddAudioChapter from '../../SyllabusData/AddAudioChapter';
import AddAnyFileChapter from '../../SyllabusData/AddAnyFileChapter';
import { chapter_icons } from '../../assets/data';
import AddYouTubeVideoChapter from '../../SyllabusData/AddYouTubeVideoChapter';
import { SyllabusIndexDataAction, appendFormData, createCourseAction, preViewCourseAction } from '../../../../redux/actions/course';
import { TailSpin } from 'react-loader-spinner';

const AddContent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [headerModal, setHeaderModal] = useState({});
    const [indexData, setIndexData] = useState();
    const syllabusIndexData = useSelector(
        (state) => state.SyllabusIndexDataRedcuer?.data
    )


    useEffect(() => {
        setIndexData(syllabusIndexData)
        console.log("indexData", indexData)
    }, [syllabusIndexData, indexData]); // Add 'indexData' to the dependency array

    const handleBack = () => {
        dispatch(SyllabusIndexDataAction({ type: 'RESET_STATE' }))
        navigate('/course/create-course')
    }
    const [activeTab, setActiveTab] = useState("Upload");
    const [chapterIndex, setchapterIndex] = useState('');
    const [unitIndex, setunitIndex] = useState('');
    const [loader, setloader] = useState(false);
    const [success, setSuccess] = useState();
    const [error, seterror] = useState();


    const handleTabClick = (chapter, cIndex, uIndex) => {
        console.log(chapter, cIndex, uIndex)
        console.log(indexData)
        setchapterIndex(cIndex)
        setunitIndex(uIndex)
        if (chapter?.type) {
            setchapterIndex(cIndex)
            setunitIndex(uIndex)
            setActiveTab(chapter?.type);
        }
        if (chapter?.type === '') {

            setHeaderModal({
                modal: AddItemModal,
                show: true,
                chapterIndex: cIndex,
                unitIndex: uIndex,
            });
        }
    };
    useEffect(() => {
        if (indexData && Array.isArray(indexData) && indexData.length > 0) {
            const unitData = indexData[0].course_syllabus?.[unitIndex];

            if (unitData && unitData.chapters && unitData.chapters.length > chapterIndex) {
                const chapterType = unitData.chapters[chapterIndex]?.type;

                if (chapterType) {
                    console.log('Chapter Type:', chapterType);
                    setActiveTab(chapterType);
                } else {
                    console.error('Chapter type is undefined.');
                }
            } else {
                console.error('Invalid chapterIndex or unit data.');
            }
        } else {
            console.error('Invalid indexData or indexData is empty.');
        }
    }, [indexData, setActiveTab, chapterIndex, unitIndex]);



    const renderChapterTabContent = () => {
        switch (activeTab) {
            case 'Upload':
                return <DefaultRenderComponent handleAddChapterManually={handleAddChapterManually} chapterIndex={chapterIndex} unitIndex={unitIndex} />
            case 'ADD_TEXT':
            case 'ADD_HEADING':
                return <AddTextChapter chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            case 'ADD_LINK':
                return <AddLinkChapter chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            case 'ADD_ASSIGNMENT':
                return <AddAssignmentChapter chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            case 'ADD_YOUTUBE_LIVE':
                return <AddYoutubeLive chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            case 'ADD_FORM':
                return <AddFormChapter chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            case 'ADD_PDF_PUBLIC_URL':
            case 'ADD_PDF_BY_UPLOAD':
                return <AddPdfChapterData chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            case 'ADD_CLASS_LIVE':
            case 'ADD_WEBINAR_LIVE':
                return <AddLiveClassChapter chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            case 'ADD_YOUTUBE_VIDEO':
            case 'ADD_LINK_VIDEO':
                return < AddYouTubeVideoChapter chapterIndex={chapterIndex} unitIndex={unitIndex} />
            case 'ADD_UPLOAD_VIDEO':
                return <AddUploadVideoChapter chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            case 'ADD_EMBED_CODE_VIDEO':
                return <AddEmbedCodeChapter chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            case 'ADD_AUDIO':
                return <AddAudioChapter chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            case 'ADD_ANY_FILE':
                return <AddAnyFileChapter chapterIndex={chapterIndex} unitIndex={unitIndex} />;
            default:
                return null;
        }
    };



    const handleAddChapterManually = () => {
        setHeaderModal({
            modal: AddItemModal,
            show: true,
            add_type: "ADD_UNIT"
        });
    };
    const publishCourse = () => {
        console.log("pubish data", indexData[0])
        dispatch(createCourseAction(indexData[0]))

    }

    const createCourseReducer = useSelector(
        (state) => state.createCourseReducer
    )

    useEffect(() => {
        if (createCourseReducer) {
            setloader(createCourseReducer?.loading)
            setSuccess(createCourseReducer?.status)
            seterror(createCourseReducer?.error)
        }

    }, [createCourseReducer]);



    useEffect(() => {
        if (success === 200 && loader === false) {

            navigate('/course')

        }

    }, [success, loader, navigate]);

    const previewCourse = () => {
        dispatch(preViewCourseAction(indexData[0]));
        const newTabURL = '/course-preview';
        const newTab = window.open(newTabURL, '_blank');
        if (newTab) {
            newTab.focus();
        } else {
            alert('A popup blocker prevented opening the new tab. Please allow popups for this site.');
        }
    };
    const handleDeleteItem = (data) => {
        console.log("here is the data", data)
        let actionType;

        switch (data?.type) {
            case 'DELETE_UNIT':
                actionType = "DELETE_UNIT_BY_INDEX";
                break;
            case 'DELETE_CHAPTER':
                actionType = "DELETE_CHAPTER_BY_INDEX";
                break;
            default:
                alert("Invalid Error")
                break;
        }

        dispatch(appendFormData(actionType, data?.chapterIndex, data?.unitIndex));

    }


    return (
        <>
            {loader ? (
                <div className="loader">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            ) : (null)}
            {
                indexData === undefined || indexData.length === 0 ? (
                    <>
                        <section>
                            <div className="container avilabilty___col">
                                <div className="d-flex align-items-center justify-content-center vh-100">
                                    <div className="text-center">
                                        <h1 className="display-1 fw-bold">Oops!</h1>
                                        <p className="fs-3">
                                            <span className="text-danger">Course!</span> Data not found.
                                        </p>
                                        <p className="lead">It seems like there has been an issue with your course data.
                                            <span>The data may be lost or unavailable. </span>
                                            Please use the "Save" button to ensure your course data is backed up.
                                        </p>
                                        <div className="get-started-cls why_choose_ment">
                                            <a href="/create-course">
                                                <button type="button" className="find-btn btn btn-primary">
                                                    Create course
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                    </>
                )
                    : (
                        <>
                            {indexData?.map((course, index) => (
                                <div key={index}>
                                    <div
                                        id="courseCont"
                                        style={{ minHeight: "100%" }}
                                    >
                                        <div className="">
                                            <div className="header ">
                                                <div className="" onClick={handleBack}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={25}
                                                        height={25}
                                                        fill="currentColor"
                                                        className="bi bi-arrow-left-circle-fill"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                                                    </svg>

                                                </div>
                                                <div
                                                    className="ctitle"
                                                    style={{ maxWidth: 1872 }}
                                                >
                                                    {course?.course_title}
                                                </div>
                                                <a onClick={previewCourse}
                                                    className="btn"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={25}
                                                        height={25}
                                                        fill="currentColor"
                                                        className="bi bi-eye"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                    </svg>

                                                </a>
                                                <div className="get-started-cls why_choose_ment mt-0">
                                                    <a onClick={publishCourse}>
                                                        <button type="button" className="find-btn btn btn-primary">
                                                            Publish course
                                                        </button>
                                                    </a>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                    >
                                                        Save
                                                        course
                                                    </button>
                                                </div>

                                            </div>
                                            <div className="content">
                                                <div className="left">
                                                    <div className="leftContent">
                                                        <div className="courseMeta">
                                                            <div className="coverCont relative">
                                                                <div
                                                                    className="actions absolute c-hand"
                                                                    id="uploadcover"
                                                                    style={{ border: "1px solid #e5e5e5", background: "#fff" }}
                                                                >
                                                                    {
                                                                        course?.course_banner !== '' ? (
                                                                            <img src={`${process.env.REACT_APP_IMAGES_BASE_URL}${course?.course_banner}`} alt="course_banner" />
                                                                        ) : (
                                                                            <>
                                                                                <div className="text-center">
                                                                                    <i className="fa-thin fa-rectangle-history-circle-plus" />
                                                                                </div>
                                                                                <div>Add course cover</div>



                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="get-started-cls why_choose_ment mt-0">
                                                                <a href="#">
                                                                    <button type="button" className="find-btn btn btn-primary">
                                                                        preview
                                                                    </button>
                                                                </a>

                                                            </div>

                                                        </div>
                                                        <div className="itemsCont">
                                                            <div class="accordion accordion-flush" id="accordionFlushExample">

                                                                {course?.course_syllabus?.map((unit, uIndex) => (
                                                                    <div key={uIndex}>
                                                                        <div class="accordion-item" style={{ marginBottom: "8px" }} key={uIndex}>
                                                                            <h2 class="accordion-header accordion-header_course" id={`flush-heading-${index}`}>
                                                                                <button
                                                                                    class="accordion___addContent collapsed"
                                                                                    type="button"


                                                                                >
                                                                                    <span
                                                                                        style={{ width: '98%' }}
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#flush-collapse-${uIndex}`}
                                                                                        aria-expanded="false"
                                                                                        aria-controls={`flush-collapse-${uIndex}`}
                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width={16}
                                                                                            height={16}
                                                                                            fill="currentColor"
                                                                                            className="bi bi-book"
                                                                                            viewBox="0 0 16 16"
                                                                                        >
                                                                                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                                                                        </svg>

                                                                                        {'  '}

                                                                                        {unit.unitName}
                                                                                    </span>
                                                                                    <span
                                                                                        className="nav-link header_nav_item dropdown-toggle m-auto"
                                                                                        href="#"
                                                                                        id={`navbarDropdownMenuLink-${uIndex}`}
                                                                                        data-toggle="dropdown"
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded="false"

                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width={20}
                                                                                            height={20}
                                                                                            fill="currentColor"
                                                                                            className="bi bi-three-dots-vertical"
                                                                                            viewBox="0 0 16 16"
                                                                                        >
                                                                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                                        </svg>

                                                                                    </span>
                                                                                    <div
                                                                                        className="dropdown-menu"
                                                                                        aria-labelledby={`navbarDropdownMenuLink-${uIndex}`}
                                                                                    >
                                                                                        <p
                                                                                            className="pl-2 dropdown-item"
                                                                                        >
                                                                                            <div className="header_find_mentor_dropdown" onClick={() => {

                                                                                                setHeaderModal({
                                                                                                    modal: AddItemModal,
                                                                                                    show: true,
                                                                                                    add_type: "ADD_CHAPTER",
                                                                                                    unitIndex: uIndex,
                                                                                                    chapterIndex: '',
                                                                                                });
                                                                                            }}>
                                                                                                <span>
                                                                                                    <svg
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        width={20}
                                                                                                        height={20}
                                                                                                        fill="currentColor"
                                                                                                        className="bi bi-folder-plus"
                                                                                                        viewBox="0 0 16 16"
                                                                                                    >
                                                                                                        <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2Zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672Z" />
                                                                                                        <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5Z" />
                                                                                                    </svg>

                                                                                                </span>
                                                                                                Add Chapter item
                                                                                            </div>
                                                                                        </p>
                                                                                        <p
                                                                                            className="pl-2 dropdown-item"
                                                                                        >
                                                                                            <div className="header_find_mentor_dropdown" onClick={() => {
                                                                                                handleDeleteItem({
                                                                                                    type: "DELETE_UNIT",
                                                                                                    unitIndex: uIndex
                                                                                                })
                                                                                            }}>
                                                                                                <span>
                                                                                                    <svg
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        width={16}
                                                                                                        height={16}
                                                                                                        fill="currentColor"
                                                                                                        className="bi bi-trash"
                                                                                                        viewBox="0 0 16 16"
                                                                                                    >
                                                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                                                                    </svg>

                                                                                                </span>
                                                                                                Remove
                                                                                            </div>
                                                                                        </p>


                                                                                    </div>
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id={`flush-collapse-${uIndex}`}
                                                                                class="accordion-collapse collapse"
                                                                                aria-labelledby={`flush-heading-${uIndex}`}
                                                                                data-bs-parent="#accordionFlushExample"
                                                                            >
                                                                                <div class="accordion-body">
                                                                                    <ul className="list-unstyled">
                                                                                        {unit?.chapters?.map((chapter, cIndex) => (
                                                                                            <>

                                                                                                <li key={cIndex}

                                                                                                    className='d-flex cursor-pointer hover:text-secondary'
                                                                                                >
                                                                                                    <span onClick={() => {
                                                                                                        handleTabClick(chapter, cIndex, uIndex)

                                                                                                    }}
                                                                                                        style={{ width: '98%' }}
                                                                                                    >
                                                                                                        {chapter?.type ? (
                                                                                                            chapter_icons[chapter?.type]
                                                                                                        ) : (
                                                                                                            chapter_icons?.Upload
                                                                                                        )}
                                                                                                        {
                                                                                                            ' '
                                                                                                        }

                                                                                                        {chapter?.title}
                                                                                                    </span>
                                                                                                    <span
                                                                                                        className="nav-link header_nav_item dropdown-toggle m-auto"
                                                                                                        href="#"
                                                                                                        id={`navbarDropdownMenuLink-${cIndex}`}
                                                                                                        data-toggle="dropdown"
                                                                                                        aria-haspopup="true"
                                                                                                        aria-expanded="false"

                                                                                                    >
                                                                                                        <svg
                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                            width={20}
                                                                                                            height={20}
                                                                                                            fill="currentColor"
                                                                                                            className="bi bi-three-dots-vertical"
                                                                                                            viewBox="0 0 16 16"
                                                                                                        >
                                                                                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                                                        </svg>

                                                                                                    </span>
                                                                                                    <div
                                                                                                        className="dropdown-menu"
                                                                                                        aria-labelledby={`navbarDropdownMenuLink-${cIndex}`}
                                                                                                    >
                                                                                                        <p
                                                                                                            className="pl-2 dropdown-item"
                                                                                                        >
                                                                                                            <div className="header_find_mentor_dropdown" onClick={() => {

                                                                                                                setHeaderModal({
                                                                                                                    modal: AddItemModal,
                                                                                                                    show: true,
                                                                                                                    add_type: "ADD_CHAPTER",
                                                                                                                    unitIndex: uIndex,
                                                                                                                    chapterIndex: '',
                                                                                                                });
                                                                                                            }}>
                                                                                                                <span>
                                                                                                                    <svg
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                        width={20}
                                                                                                                        height={20}
                                                                                                                        fill="currentColor"
                                                                                                                        className="bi bi-folder-plus"
                                                                                                                        viewBox="0 0 16 16"
                                                                                                                    >
                                                                                                                        <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2Zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672Z" />
                                                                                                                        <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5Z" />
                                                                                                                    </svg>

                                                                                                                </span>
                                                                                                                Add Chapter item
                                                                                                            </div>
                                                                                                        </p>
                                                                                                        <p
                                                                                                            className="pl-2 dropdown-item"
                                                                                                        >
                                                                                                            <div className="header_find_mentor_dropdown" onClick={() => {
                                                                                                                handleDeleteItem({
                                                                                                                    type: "DELETE_CHAPTER",
                                                                                                                    unitIndex: uIndex,
                                                                                                                    chapterIndex: cIndex,
                                                                                                                })
                                                                                                            }}>
                                                                                                                <span>
                                                                                                                    <svg
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                        width={16}
                                                                                                                        height={16}
                                                                                                                        fill="currentColor"
                                                                                                                        className="bi bi-trash"
                                                                                                                        viewBox="0 0 16 16"
                                                                                                                    >
                                                                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                                                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                                                                                    </svg>

                                                                                                                </span>
                                                                                                                Remove
                                                                                                            </div>
                                                                                                        </p>


                                                                                                    </div>
                                                                                                </li>


                                                                                            </>
                                                                                        ))}
                                                                                        <a className='text-primary cursor-pointer' onClick={() => {

                                                                                            setHeaderModal({
                                                                                                modal: AddItemModal,
                                                                                                show: true,
                                                                                                add_type: "ADD_CHAPTER",
                                                                                                unitIndex: uIndex,
                                                                                                chapterIndex: '',
                                                                                            });
                                                                                        }}>Add new chapter item</a>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" p-2 newItemBtn " onClick={() => {

                                                        setHeaderModal({
                                                            modal: AddItemModal,
                                                            show: true,
                                                            add_type: "ADD_UNIT"
                                                        });
                                                    }}>
                                                        <span><svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={25}
                                                            height={25}
                                                            fill="currentColor"
                                                            className="bi bi-plus-square"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                        </svg>
                                                        </span>
                                                        <span>Add new chapter</span>
                                                    </div>
                                                </div>
                                                <div className="right" id="chapterData">
                                                    <div
                                                        className="d-flex"
                                                        style={{
                                                            gap: 24,
                                                            paddingTop: 40,
                                                            marginRight: "4rem",
                                                            flexDirection: "column",
                                                            height: "100vh",
                                                            overflowX: "scroll"

                                                        }}
                                                    >
                                                        {renderChapterTabContent()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )


            }



            {headerModal?.modal && (
                <headerModal.modal
                    headerModal={headerModal}
                    setHeaderModal={setHeaderModal}
                />
            )}

        </>
    );
}

export default AddContent;

const DefaultRenderComponent = ({ handleAddChapterManually }) => {

    return (
        <>
            <div
                className="d-flex"
                style={{
                    background: "#F8F9FA",
                    padding: 16,
                    alignItems: "center",
                    gap: 48
                }}
            >
                <div
                    className="d-flex"
                    style={{ flexGrow: 1, gap: 8, flexDirection: "column" }}
                >
                    <div
                        className="d-flex"
                        style={{
                            color: "#232228",
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                    >
                        Add first chapter manually
                    </div>
                    <div
                        style={{
                            color: "#393F41",
                            fontWeight: 400,
                            fontSize: 14,
                            letterSpacing: "0.015em"
                        }}
                    >
                        Use <b>Headings</b> for adding the Chapter Heading and{" "}
                        <b>Chapter Item</b> for adding Chapter Content
                    </div>
                </div>
                <div className="empty-action">
                    <div className="get-started-cls why_choose_ment mt-0 ">

                        <button
                            onClick={handleAddChapterManually}
                            type="button"
                            className="btn btn-primary"
                        >
                            Add  manually
                        </button>
                    </div>
                </div>
            </div>


        </>)
}