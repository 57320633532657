import React, { useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { appendFormData } from '../../../redux/actions/course';
import crossIcon from "../../../assets/images/cross-icon.png";
import '../CreateCourse/custom.createcourse.css';
import { UploadImage } from '../../UploadImage';

const PublicUrl = ({ handleAction, handleCancel }) => {
	const [link, setLink] = useState("");
	const [title, setTitle] = useState("");
	const [message, setMessage] = useState(null)


	const handleSubmitPublicUrl = (e) => {
		e.preventDefault();
		if (!link || !title) {
			setMessage("Please enter link")
			setTimeout(() => {
				setMessage('')
			}, 2000);
		}
		else {
			handleAction({ link, title, type: "ADD_PDF_PUBLIC_URL" });
		}
	};

	return (
		<div className="form-group">
			<label className="session__label">Title: *</label>
			<input
				type="url"
				name="link"
				id="link"
				placeholder="Public URL"
				required=""
				className="form-control"
				value={link}
				onChange={(e) => setLink(e.target.value)}
			/>
			<label className="session__label">Public URL: *</label>
			<input
				type="text"
				name="title"
				id="title"
				placeholder="enter title"
				required=""
				className="form-control"
				value={link}
				onChange={(e) => setTitle(e.target.value)}
			/>
			<div className="get-started-cls why_choose_ment">
				<button type="button" className="find-btn btn btn-primary" onClick={() => { handleCancel() }}>
					Cancel
				</button>
				<button onClick={handleSubmitPublicUrl} type="button" className="btn btn-primary">
					Upload
				</button>
			</div>
			{
				message && (<p className="text-center">{message}</p>)
			}
		</div>
	);
};

const Upload = ({ handleAction, handleCancel }) => {
	const [formData, setFormData] = useState({
		title: '',
		link: ''
	});


	const handleSubmitUpload = (e) => {
		e.preventDefault();
		handleAction({ formData, type: "ADD_PDF_BY_UPLOAD" });
	};
	const uploadPdf = (val, pre) => {
		console.log("uploadAudio called", val);

		setFormData((pre) => ({
			...pre,
			link: val,
		}));
	};

	return (
		<div className="form-group">
			<label className="session__label">Title *</label>
			<input
				type="text"
				name="title"
				id="title"
				required=""
				className="form-control"
				onChange={(e) => {
					setFormData((pre) => ({
						...pre,
						title: e.target.value,
					}));

				}}
			/>
			<UploadImage
				callback={uploadPdf}
				fileName="_upload-pdf"
				imagePath={"courseImage/coursePdf/"}
				filetype={["pdf"]}
			/>
			<div className="get-started-cls why_choose_ment">
				<button type="button" className="find-btn btn btn-primary" onClick={() => { handleCancel() }}>
					Cancel
				</button>
				<button onClick={handleSubmitUpload} type="button" className="btn btn-primary">
					Upload
				</button>
			</div>
		</div>
	);
};

const PdfModal = ({ OpenModal, setOpenModal }) => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState("Upload");

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const handleAction = (formData) => {
		dispatch(appendFormData("APPEND_FORM_DATA", OpenModal?.chapterIndex, OpenModal?.unitIndex, formData))
		let actionType;

		switch (OpenModal?.add_type) {
			case 'ADD_CHAPTER':
				actionType = "ADD_CHAPTER_TO_UNIT_BY_INDEX";
				break;
			case 'ADD_UNIT':
				actionType = "ADD_UNIT";
				break;
			default:
				actionType = "APPEND_FORM_DATA";
				break;
		}

		dispatch(appendFormData(actionType, OpenModal?.chapterIndex, OpenModal?.unitIndex, formData));
		handleCancel()
	};
	const handleCancel = () => {
		setOpenModal((prevState) => ({ ...prevState, show: false }))

	}
	return (
		<Modal size="xl" centered show={OpenModal.show} onHide={() => setOpenModal(prevState => ({ ...prevState, show: false }))}>
			<ModalBody>
				<button
					type="button"
					className="cloose"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => setOpenModal(prevState => ({ ...prevState, show: false }))}
				>
					<div className="closebuttons">
						<span aria-hidden="true">
							<img src={crossIcon} alt="Close" />
						</span>
					</div>
				</button>
				<div className="create_course_modal" style={{ background: "white" }}>
					<div className="modal-body">
						<div className="modal-container">
							<div className="modal-header">
								<div className="modal-title h5">
									New PDF <small>Max pdf size :: 250MB</small>
								</div>
							</div>
							<div className="modal-body">
								<div className="card border-0">
									<div className="card-header header-table tab-header">
										<ul className="nav nav-tabs" role="tablist">
											<li className="nav-item">
												<a
													data-toggle="tab"
													href="#tabs-1"
													role="tab"
													className={`nav-link ${activeTab === "Upload" ? "active" : ""}`}
													onClick={() => handleTabClick("Upload")}
												>
													Upload
												</a>
											</li>
											<li className="nav-item">
												<a
													data-toggle="tab"
													href="#tabs-2"
													role="tab"
													className={`nav-link ${activeTab === "PublicUrl" ? "active" : ""}`}
													onClick={() => handleTabClick("PublicUrl")}
												>
													Public URL
												</a>
											</li>
										</ul>
									</div>
									<div className="card-body table-inner">
										<div className="tab-content">
											<div id="pdfCont">
												{activeTab === "PublicUrl" ? (
													<PublicUrl handleAction={handleAction} handleCancel={handleCancel} />
												) : (
														<Upload handleAction={handleAction} handleCancel={handleCancel} />
												)}
												<h2 className="hr-lines"> OR </h2>
												<div className="text-center">
													<button className="btn btn-sm googleDriveUploadBtn" data-type="pdf">
														<img src="https://dz8fbjd9gwp2s.cloudfront.net/resources/images/icons8-google-drive-48.png" alt="Google Drive" />{" "}
														Import from Google Drive
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default PdfModal;
