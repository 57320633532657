import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import { lightGreen } from "@mui/material/colors";
import WebCam from "./Webcam";
import RecordView from "./RecordView";
import './Recorder.css'
import { Modal, ModalBody } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import crossIcon from "../../assets/images/cross-icon.png";

const CamDashboard = ({ reordModel, setRecordModel }) => {
    const [isCameraOn, setIsCameraOn] = useState(false);

    const handleStartCamera = () => {
        setIsCameraOn(true);
    };

    const handleStopCamera = () => {
        setIsCameraOn(false);
    };

    const loader = false;
    return (
        <>
            {loader && (
                <div className="loader" style={{ zIndex: 9999 }}>
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            )}
            <Modal
                size="xl"
                centered
                show={reordModel.show}
                onHide={() =>
                    setRecordModel((prevState) => ({ ...prevState, show: false }))
                }
            >

                <ModalBody>

                    <button
                        type="button"
                        className="cloose"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                            setRecordModel((prevState) => ({ ...prevState, show: false }))
                        }
                    >
                        <div className="closebuttons">
                            <span aria-hidden="true">
                                <img src={crossIcon} alt="Close Icon" />
                            </span>
                        </div>
                    </button>

                    <div className="create_course_modal video_recorder" style={{ background: "white" }}>
                        <div className="modal-body">
                            <div className="dashboard-container">
                                <div className="webcam-container">
                                    <div className="webcam-screen">
                                        <div className="webcam-username-div">
                                            <h1>Camera</h1>
                                        </div>
                                        <div className="webcam-stream-div">
                                            <WebCam permisssions={isCameraOn} />
                                        </div>
                                    </div>
                                    <div className="webcam-on-off-btn-div">
                                        {isCameraOn ? (
                                            <button className="cam-on-off-btn" onClick={handleStopCamera}>
                                                <VideocamIcon sx={{ color: lightGreen[50], fontSize: 30 }} />
                                            </button>
                                        ) : (
                                            <button className="cam-on-off-btn" onClick={handleStartCamera}>
                                                <VideocamOffIcon sx={{ color: lightGreen[50], fontSize: 30 }} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <RecordView />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </>
    );
}

export default CamDashboard;
