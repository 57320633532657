import React from "react"
import ProtectedHeader from "../ProtectedHeader/ProtectedHeader";
import { arR, bannerImg, crossIcon, ic1, ic3 } from "../../assets/images/export-images";
import { useState } from "react";
import { useSelector } from "react-redux";
import { QuestionnaireData } from "./data/data";
import Header from './../header/index';
import Footer from './../footer/index';
import ResultModal from "./Modal";

const Questionnaire = (props) => {
    const [loggedIn, setLoggedIn] = useState(
        localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
    );
    const [active, setActive] = useState(0);
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [result, setresult] = useState();
    const [resultModal, setResultModal] = useState();

    const userDetails = useSelector((state) => {
        const data = state.userDetailsReducer?.data || [];
        return data[0];
    });


    const testResponseData = useSelector((state) => {
        const data = state.testResponseReducer?.data || {};
        return data;
    });

    console.log("testResponse", testResponseData);


    const questionsData = useSelector((state) => {
        const data = QuestionnaireData
        let str;
        if (data?.length) {
            str = data[0]?.questions;
        }
        return data?.length > 0 ? str : [];
    });



    let lastPageIndex = currentPage * perPage;

    let firstPageIndex = lastPageIndex - perPage;

    const currentQuestions = questionsData.slice(firstPageIndex, lastPageIndex);

    let totalpages = questionsData.length;

    const pageNumber = [];

    for (let i = 1; i <= Math.ceil(totalpages / perPage); i++) {
        pageNumber.push(i);
    }


    const handleQuestionsResponse = (item, value, id, question_id = 0) => {
        for (let i = 0; i < 7; i++) {
            let element = document.getElementById(question_id + "_" + i);
            if (element) {
                element.classList.remove("active");
            }
        }

        let currentQuestionElement = document.getElementById(id);
        if (currentQuestionElement) {
            currentQuestionElement.classList.add("active");
            let previousQuestionElement = document.getElementById(question_id);
            if (previousQuestionElement) {
                previousQuestionElement.classList.remove("active");
                previousQuestionElement.classList.add("inactive");
            }

            let nextElement = document.getElementById(question_id + 1);
            if (nextElement) {
                nextElement.classList.add("active");
                nextElement.classList.remove("inactive");
                setTimeout(function () {
                    nextElement.scrollIntoView({ behavior: "smooth" });
                }, 300);
            }
        }

        let d = answeredQuestions;
        let user_response = {
            text: item.text,
            answer: value,
            section: item.section,
        };

        let index = d.findIndex((val) => val.text === item.text);
        if (index !== -1) {
            d.splice(index, 1);
        }
        d.push(user_response);

        setAnsweredQuestions(d);
    };



    const nextFun = () => {
        setCurrentPage(currentPage + 1);
        setActive(currentPage === 1 ? 0 : (currentPage - 1) * perPage);
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 400);



    };
    const goBackFunc = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setActive((currentPage - 2) * perPage); // Update the active index accordingly
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 600);
        }
    };
    const userDetailsfunc = () => {
        if (answeredQuestions?.length === 48) {
            console.log("answeredQuestions", answeredQuestions)
            setResultModal({
                modal: ResultModal,
                show: true,
            });
        }
        else {
            alert("give the answer of all questions")
        }
    };


    const l = [
        {
            ariaLabel: "agree triplemax",
            dataIndex: 0,
            ariaChecked: "false",
            tabIndex: -1,
            value: 5,
            className: "option agree triplemax",
        },
        {
            ariaLabel: "agree doubleemax",
            dataIndex: 1,
            ariaChecked: "false",
            tabIndex: -1,
            value: 4,
            className: "option agree doublemax",
        },
        {
            ariaLabel: "agree max",
            dataIndex: 2,
            ariaChecked: "false",
            tabIndex: -1,
            value: 3,
            className: "option agree max",
        },
        {
            ariaLabel: "agree med",
            dataIndex: 3,
            ariaChecked: "false",
            tabIndex: -1,
            value: 2,
            className: "option agree med",
        },
        {
            ariaLabel: "agree min",
            dataIndex: 4,
            ariaChecked: "false",
            tabIndex: -1,
            value: 1,
            className: "option agree min",
        },
        // {
        //     ariaLabel: "neutral",
        //     dataIndex: 5,
        //     ariaChecked: "false",
        //     tabIndex: -1,
        //     value: 0,
        //     className: "option neutral",
        // }
    ];
    const boxesData = [
        {
            "icon": ic1,
            "title": "Know your personality drive Motivator",
            "description": "Each person has a dominant motivator, depending on their life experiences and cultural background. Based on each individual’s prime motivator, they would show unique characteristics."
        },
        {
            "icon": ic3,
            "title": "Identify your strengths and weaknesses",
            "description": "You need to identify the areas where you excel and the areas where you need improvement. You can do this by comparing your self-analysis with the characteristics of each motivation drive."
        },
        {
            "icon": ic3,
            "title": "Performance, Productivity, and Satisfaction",
            "description": "Different situations and domains. You can do this by asking yourself questions such as: What are the goals that I pursue and why? How do I cope with challenges, risks, and failures?"
        },
        {
            "icon": ic3,
            "title": "Seeking feedback",
            "description": "If you are seeking feedback on your motivational profile according to the Achievement Motivation Training (AMT), you can use one of the questionnaires that measure different aspects of achievement motivation."
        }
    ];
    const [activeTab, setActiveTab] = useState(null);

    const toggleTab = (index) => {
        setActiveTab(activeTab === index ? null : index);
    };

    return (
        <>
            {loggedIn ? <ProtectedHeader /> : <Header />}
            <section
                className="banner-sec"
                style={{ backgroundImage: `url(${bannerImg})` }}
            >
                <div className="container">
                    <div className="mentroship-sec">
                        <h3>Motivational Profile Questionnaire </h3>
                    </div>
                </div>
            </section>
            <section className="mentors-tabs" id="mbti_mentor_Tabs" style={{ height: "20vh" }}>
                <div className="container">
                    <div className="row">

                        {boxesData.map((box, index) => (
                            <div className="col-sm-6 col-md-3 col-lg-3" key={index}>
                                <div className="box-wrap">
                                    <div className="icon-cls">
                                        <img alt="icon" src={box?.icon} />
                                    </div>
                                    <div className="mentor-cont">
                                        <h4>{box.title}</h4>
                                        <p>
                                            {box.description.length > 120
                                                ? box.description.substring(0, 120) + "..."
                                                : box.description}
                                        </p>
                                        <a className="myBtn" onClick={() => toggleTab(index + 1)}>
                                            Read More{" "}
                                            <span className="ar-1">
                                                <img alt="icon" src={arR} />
                                            </span>
                                        </a>
                                        {activeTab === index + 1 && (
                                            <div className="services__modal active-modal services__modal-overlay">
                                                <div className="services__modal-content">
                                                    <i
                                                        onClick={() => toggleTab(null)}
                                                        className="uil uil-times services__modal-close"
                                                    >
                                                        <img src={crossIcon} alt="crossIcon" />
                                                    </i>
                                                    <div className="icon-cls" id="popup-icon">
                                                        <img alt="icon" src={box.icon} />
                                                    </div>
                                                    <h3 className="services__modal-title">{box.title}</h3>
                                                    <p className="services__modal-description">
                                                        {box.description}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <!-- World className Mentorship --> */}
                </div>
            </section>
            <section style={{ margin: "20px" }} >
                <div className="quiz-wrapper">
                    <h2 style={{ "margin-left": "25em" }} >Screen {currentPage} / {Math.ceil(totalpages / perPage)}</h2>
                    <div>
                        <div data-chunk="quiz-chunk">
                            <div className="test-questions comp">
                                {currentQuestions?.map((item, idx) => (
                                    <div
                                        data-v-7250ab3f=""
                                        key={
                                            idx +
                                            (currentPage === 1 ? 0 : (currentPage - 1) * perPage)
                                        }
                                        id={
                                            idx +
                                            (currentPage === 1 ? 0 : (currentPage - 1) * perPage)
                                        }
                                        className={
                                            idx === 0 ? "question active" : "question inactive"
                                        }
                                    >
                                        <div data-v-7250ab3f="" className="statement">
                                            <span data-v-7250ab3f="" id="stmt_z1cq8kfja6">
                                                {item?.text}
                                            </span>
                                        </div>
                                        <div
                                            data-v-7250ab3f=""
                                            role="radiogroup"
                                            aria-labelledby="stmt_z1cq8kfja6"
                                            className="decision"
                                        >
                                            <div data-v-7250ab3f="" className="caption agree">
                                                Like
                                            </div>
                                            <div data-v-7250ab3f="" className="options">
                                                {l?.map((val, idy) => {
                                                    return (
                                                        <div
                                                            data-v-7250ab3f=""
                                                            role="radio"
                                                            id={
                                                                idx +
                                                                (currentPage === 1
                                                                    ? 0
                                                                    : (currentPage - 1) * perPage) +
                                                                "_" +
                                                                val.dataIndex
                                                            }
                                                            aria-label={val.ariaLabel}
                                                            data-index={val.dataIndex}
                                                            aria-checked={val.ariaChecked}
                                                            tabIndex={val.tabIndex}
                                                            className={val.className}
                                                            onClick={() =>
                                                                handleQuestionsResponse(
                                                                    item,
                                                                    val.value,
                                                                    idx +
                                                                    (currentPage === 1
                                                                        ? 0
                                                                        : (currentPage - 1) * perPage) +
                                                                    "_" +
                                                                    val.dataIndex,
                                                                    idx +
                                                                    (currentPage === 1
                                                                        ? 0
                                                                        : (currentPage - 1) * perPage)
                                                                )
                                                            }
                                                        >
                                                            <span
                                                                data-v-7250ab3f=""
                                                                aria-hidden="true"
                                                            // className="far fa-check"
                                                            >
                                                                <svg style={{ width: "50px", height: "50px" }}
                                                                    xmlns="http://www.w3.org/2000/svg"

                                                                    fill="currentColor"
                                                                    class="bi bi-check"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div data-v-7250ab3f="" className="caption disagree">
                                                Unlike
                                            </div>
                                        </div>
                                        <div data-v-7250ab3f="" className="decision mobile">
                                            <div data-v-7250ab3f="" className="caption agree">
                                                Like
                                            </div>
                                            <div data-v-7250ab3f="" className="caption disagree">
                                                Unlike
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                {currentPage === 1 ? (
                    <div className="centre">
                        <div className="get-started-cls why_choose_ment">
                            <button onClick={nextFun} type="button" className="btn btn-primary">
                                Next
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="centre">
                        <div className="get-started-cls why_choose_ment">
                            <a onClick={goBackFunc}>
                                <button type="button" className="find-btn btn btn-primary">
                                    Back
                                </button>
                            </a>
                            {currentPage < pageNumber.length ? (
                                <button onClick={nextFun} type="button" className="btn btn-primary">
                                    Next
                                </button>
                            ) : (
                                <button
                                    onClick={userDetailsfunc}
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    Next
                                </button>
                            )}
                        </div>
                    </div>
                )}

            </div>

            {/* <!-- Footer --> */}
            <Footer />
            {resultModal?.modal && (
                <resultModal.modal
                    resultModal={resultModal}
                    setResultModal={setResultModal}
                    answeredQuestions={answeredQuestions}
                />
            )}
        </>
    )
};

export default Questionnaire;
