import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { triggerOpenNav } from '../../../../redux/actions/course';
import '../../CourseInfo/custom.information.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';

const Information = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [data, setData] = useState({ document_type: "", product_id: "" });
    const [loader, setloader] = useState(false);


    const getCourseDetailsReducer = useSelector((state) => state.getCourseDetailsReducer);
    useEffect(() => {

        if (getCourseDetailsReducer?.data) {
            setData({
                ...data,
                product_id: getCourseDetailsReducer.data.product_id || '',
                document_type: getCourseDetailsReducer.data.document_type || '',
            });
        }
        setloader(getCourseDetailsReducer?.loading)


    }, [getCourseDetailsReducer, data]);

    const handleTriggerOpenNavFromB = () => {
        dispatch(triggerOpenNav()); // Dispatch the action to trigger openNav
    };

    return (
        <> {
            loader ? (<div className="loader open_sessions_loader">
                <TailSpin ariaLabel="Loading..." color="#00BFFF" />
            </div>) : (

                <>
                    <section id="Header">
                        <div className="header ">
                            <span
                                className='mobile_viewCourse'
                                style={{ fontSize: '30px', cursor: 'pointer' }}
                                onClick={handleTriggerOpenNavFromB}
                            >
                                &#9776;
                            </span>
                            <div
                                className="ctitle"
                                style={{ maxWidth: 1872 }}
                            >
                                Information
                            </div>

                            <div className="get-started-cls why_choose_ment mt-0">
                                <a >
                                    <button type="button" className="btn btn-success certificate_savebtn">
                                        Copy {data?.document_type} URL
                                    </button>
                                </a>
                                <button
                                    type="button"
                                    className="btn btn-success certificate_savebtn"
                                >
                                    Preview
                                </button>
                            </div>

                        </div>

                    </section>
                    <section>
                        {/* <div className="container">
                    <div className="text-center">
                        <h1 className="text-center">
                            Information Page
                        </h1>
                    </div>
                </div> */}
                        <div className="row">
                            <div className="col-xl-12 col-md-12">
                                <div className="card border-0 ">
                                    <div className="card-header header-table tab-header">
                                        <ul
                                            className="nav nav-tabs"
                                            role="tablist"
                                            style={{ display: "flex", flexDirection: "row" }}
                                        >
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="tab"
                                                    href="#tabs-1"
                                                    role="tab"
                                                    onClick={() => navigate("")}
                                                >
                                                    Details
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <a
                                                    className="nav-link "
                                                    data-toggle="tab"
                                                    href="#tabs-1"
                                                    role="tab"
                                                    onClick={() => navigate("pricing")}
                                                >
                                                    Pricing
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <a
                                                    className="nav-link "
                                                    data-toggle="tab"
                                                    href="#tabs-1"
                                                    role="tab"
                                                    onClick={() => navigate("pages")}
                                                >
                                                    Pages
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tabs-1"
                                                    role="tab"
                                                    onClick={() => navigate("advanced")}
                                                >
                                                    Advanced
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body table-inner">
                                        <div className="tab-content">
                                            <Outlet />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
        }

        </>
    );
}

export default Information;
