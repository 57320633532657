import React, { useState, useEffect } from 'react';
import { crossIcon } from '../../../assets/images/export-images';
import { Modal, ModalBody } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fullStackAction } from '../../../redux/actions/common';
import FullStackSyllabus from '../data/FullStack_Course_Syllabus.pdf';

const CallBackModal = ({ callbackModal, setcallbackModal }) => {
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    useEffect(() => {
        console.log(callbackModal)

    }, [callbackModal]);

    const [callBackForm, setcallBackForm] = useState({
        name: '',
        email: '',
        mobile: '',
        jobRole: '',
        company: '',
        inquiry_type: callbackModal?.inquiry_type,
        message: callbackModal?.message,
    });

    const handleCallBack = (e) => {
        e.preventDefault();

        if (
            callBackForm.name.trim() !== '' &&
            callBackForm.email.trim() !== '' &&
            callBackForm.mobile.trim() !== ''
        ) {
            console.log("hadnlecallback", callBackForm)
            // setLoader(true);
            dispatch(fullStackAction(callBackForm))
            // .then(() => {
            //     setLoader(false);
            //     setSuccessMsg('Form submitted successfully');
            //     setTimeout(() => {
            //         setSuccessMsg(null);
            //     }, 2000);
            // });
        } else {
            setErrorMsg('Please enter all details');
            setTimeout(() => {
                setErrorMsg(null);
            }, 2000);
        }
    };

    const callBackFromStatus = useSelector(
        (state) => state.fullStackReducer?.status
    );
    const fullStackReducer = useSelector(
        (state) => state.fullStackReducer
    );
    useEffect(() => {
        setLoader(fullStackReducer?.loading)
    }, [fullStackReducer]);

    useEffect(() => {
        if (callBackFromStatus === 200) {
            setSuccessMsg('Form submitted successfully');
            setTimeout(() => {
                setSuccessMsg(null)
                if (callbackModal.inquiry_type === "Full_stack_call_back") {
                    // Trigger the PDF download
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', FullStackSyllabus, true);
                    xhr.responseType = 'blob';
                    xhr.onload = function () {
                        const blob = xhr.response;
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'Full_stack_curriculam.pdf'; // Set the desired filename
                        a.click();
                        URL.revokeObjectURL(url);
                    };
                    xhr.send();
                }
            }, 2000);
        }
        if (fullStackReducer?.error !== undefined && fullStackReducer?.loading === false) {
            setErrorMsg('Please try again later');
            setTimeout(() => {
                setErrorMsg(null)

            }, 2000);
        }
    }, [callBackFromStatus, fullStackReducer, callbackModal]);

    return (
        <>
            {loader && (
                <div className="loader" style={{ zIndex: 9999 }}>
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            )}
            <Modal
                size="xl"
                centered
                show={callbackModal.show}
                onHide={() =>
                    setcallbackModal((prevState) => ({ ...prevState, show: false }))
                }
            >
                <ModalBody className="enroll_courseModal">
                    <button
                        type="button"
                        className="cloose"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                            setcallbackModal((prevState) => ({
                                ...prevState,
                                show: false,
                            }))
                        }
                    >
                        <div className="closebuttons">
                            <span aria-hidden="true">
                                <img src={crossIcon} alt="Close" />
                            </span>
                        </div>
                    </button>
                    <div className="create_course_modal" style={{ background: 'white' }}>
                        <div className="modal-body">
                            <h2 className="heading-style-h3" style={{ color: 'rgb(183, 48, 142)' }}>
                                {callbackModal?.headingTitle}
                            </h2>
                            <div className="modal-container">
                                <div className="modal-body">
                                    <div className="form-block w-form">
                                        <form className="form margin-top margin-small">
                                            <input
                                                type="text"
                                                className="form_input w-input"
                                                name="name"
                                                placeholder="Name"
                                                required=""
                                                onChange={(e) =>
                                                    setcallBackForm((prev) => ({
                                                        ...prev,
                                                        name: e.target.value,
                                                    }))
                                                }
                                            />
                                            <input
                                                type="text"
                                                className="form_input w-input"
                                                name="email"
                                                placeholder="Email address"
                                                required=""
                                                onChange={(e) =>
                                                    setcallBackForm((prev) => ({
                                                        ...prev,
                                                        email: e.target.value,
                                                    }))
                                                }
                                            />
                                            <input
                                                type="text"
                                                className="form_input w-input"
                                                name="phone"
                                                placeholder="Phone"
                                                required=""
                                                onChange={(e) =>
                                                    setcallBackForm((prev) => ({
                                                        ...prev,
                                                        mobile: e.target.value,
                                                    }))
                                                }
                                            />
                                            {
                                                callbackModal?.inquiry_type === "Buy_MentorPal_courses_for_your_team" || callbackModal?.inquiry_type === "Reimbursement_from_Your_Employer" ? (
                                                    <>
                                                        <input
                                                            type="text"
                                                            className="form_input w-input"
                                                            name="Company name"
                                                            placeholder="company name"
                                                            required=""
                                                            onChange={(e) =>
                                                                setcallBackForm((prev) => ({
                                                                    ...prev,
                                                                    company: e.target.value,
                                                                }))
                                                            }
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form_input w-input"
                                                            name="Job Role"
                                                            placeholder="Job Role"
                                                            required=""
                                                            onChange={(e) =>
                                                                setcallBackForm((prev) => ({
                                                                    ...prev,
                                                                    jobRole: e.target.value,
                                                                }))
                                                            }
                                                        />
                                                    </>
                                                ) : (null)



                                            }
                                            <button
                                                onClick={handleCallBack}
                                                type="button"
                                                className="button is-small w-button"
                                            >
                                                {callbackModal?.inquiry_type === "Full_stack_call_back_side" ? "Call me back " : "Submit"}
                                            </button>
                                            {errorMsg && <p>{errorMsg}</p>}
                                            {successMsg && <p>{successMsg}</p>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default CallBackModal;
