import React, { useState } from "react";
import DashboardHeader from "./DashboardHeader";
import { Route, Routes, useNavigate } from "react-router-dom";
import logoImg from "./img/logo.png";
import dashboardImg from "./img/dashboard.png";
import sessionImg from "./img/session.png";
import profileImg from "./img/profile.png";
import eBookImg from "./img/e-book.png";
import articleImg from "./img/article.png";
import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_ID } from "../auth/tokenProvider";
import { publishMentorEbook } from "../../redux/actions/mentor";
import EbookSuccess from "./publishEbook/ebookSuccess";
import { TailSpin } from "react-loader-spinner";
import { UploadImage } from "../UploadImage";
import { v4 } from "uuid";

const PublishEbook = () => {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [picture, setPicture] = useState("");
  const [author, setAuthor] = useState("");
  const [description, setDescription] = useState("");
  const [bookObjective, setBookObjective] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_id = REACT_USER_ID;
  const publishEbookDataModel = {
    title: title,
    mentor_id: user_id,
    price: price,
    picture: picture,
    author: author,
    description: description,
    book_objective: bookObjective,
    ebook_file: "Need to be implemented...",
  };
  const publishEbookMethod = () => {
    dispatch(publishMentorEbook(JSON.stringify(publishEbookDataModel)));
  };
  const publishEbookReducerData = useSelector(
    (state) => state.publishEbookReducer
  );

  const handleCoverPicture = (val) => {
    setPicture(val);
  };

  const handleBook = (val) => {
  };

  const ebookSuccessMessage = publishEbookReducerData?.success;
  const isLoading = publishEbookReducerData?.loading;
  return (
    <>
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : null}
      <div id="wrapper">
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column" style={{height:"850px"}}>
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}

            <div className="publish_E_book w-100 mt-2 px-2">
              {/* <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800 main-title">
                  Publish E-book
                </h1>
              </div>

              {/* <!-- Content Row --> */}
              {ebookSuccessMessage === true ? (
                <EbookSuccess />
              ) : (
                <div className="row">
                  {/* <!-- Earnings (Monthly) Card Example --> */}
                  <div className="col-xl-12 col-md-12 mb-4">
                    <div className="card card-cls ebook-card border-0 ">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Book Title</label>
                              <input
                                type="text"
                                name=""
                                placeholder="Why is Java is a future?"
                                className="form-control"
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 ">
                            <div className="form-group">
                              <label>Price</label>
                              <input
                                type="text"
                                name=""
                                placeholder="Free or Input your price"
                                className="form-control"
                                onChange={(e) => setPrice(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 ">
                            <div className="form-group">
                              <label>Author</label>
                              <input
                                type="text"
                                name=""
                                placeholder="Name"
                                className="form-control"
                                onChange={(e) => setAuthor(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 ">
                            <div className="form-group">
                              <label>Book objective</label>
                              <input
                                type="text"
                                name=""
                                placeholder="Enter a short objective how it can help readers."
                                className="form-control"
                                onChange={(e) =>
                                  setBookObjective(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-6 ">
                            <div className="form-group">
                              <label>Upload cover picture</label>
                              <UploadImage
                                callback={handleCoverPicture}
                                fileName={"_cover-picture"}
                                imagePath={"ebook/coverPhoto/"}
                                 filetype={["image"]}
                              />
                              {/* <div className="input-group">
                                <input
                                  type="text"
                                  name=""
                                  placeholder="photo.jpg"
                                  className="form-control"
                                  onChange={(e) => setPicture(e.target.value)}
                                />
                                <div className="file_input">
                                  <input
                                    type="file"
                                    name="Upload File"
                                    id="file-upload"
                                    className="form-btn"
                                  />
                                  <span for="fileToUpload">Upload File</span>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Upload Book</label>
                              <UploadImage
                                callback={handleBook}
                                fileName="_book"
                                imagePath={"ebook/ebookFile/"}
                                filetype={["pdf"]}
                              />
                              {/* <div className="input-group">
                                <input
                                  type="text"
                                  name=""
                                  placeholder="File (.pdf only)"
                                  className="form-control"
                                />
                                <div className="file_input">
                                  <input
                                    type="file"
                                    name="Upload File"
                                    id="file-upload"
                                    className="form-btn"
                                  />
                                  <span for="fileToUpload">Upload File</span>
                                </div>
                              </div> */}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Description</label>
                              <textarea
                                id="w3review"
                                name="w3review"
                                rows="6"
                                className="form-control"
                                onChange={(e) => setDescription(e.target.value)}
                              ></textarea>
                            </div>
                          </div>
                          {/* <div className="col-md-12">
                            <div className="form-group">
                              <label>Book objective</label>
                              <input
                                type="text"
                                name=""
                                placeholder="Enter a short objective how it can help readers."
                                className="form-control"
                              />
                            </div>
                          </div> */}
                          <div className="col-md-12">
                          <center>  <div className="publish-sec">
                              <button
                                type="button"
                                className="pblish_btnn "
                                onClick={() => publishEbookMethod()}
                              >
                                Publish
                              </button>
                            </div></center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishEbook;
