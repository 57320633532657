import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Select, Button, message } from "antd";

const MessageMentor = (props) => {
  const {
    activeTab,
    handleBack,
    handleSubmitGoal,
    menteeGoalData,
    setmenteeGoalData,
  } = props;

  const paragraphs = [
    {
      id: 1,
      content:
        "Hello [NAME]! I greatly admire your expertise in [SUBJECT MATTER]. Seeking guidance and a brief conversation to learn from your valuable experience. Thank you!",
    },
    {
      id: 2,
      content:
        "I am seeking a mentor to guide me in [SUBJECT MATTER], specifically in areas like [EXAMPLES OF MATTER]. Your advice and expertise would be immensely helpful to me. I am eager to learn from you and would be grateful for any insights you can provide. Thank you for considering my request!",
    },
    {
      id: 3,
      content:
        "Hello [NAME]! I am eager to receive guidance on [SUBJECT MATTER], and your valuable experience is highly admired. A brief conversation to learn from you would be greatly appreciated. Thank you for considering my request!",
    },
  ];

  const handleCopy = (id) => {
    const paragraph = paragraphs.find((p) => p.id === id).content;
    navigator.clipboard.writeText(paragraph);
    message.success("Paragraph copied to clipboard!");
  };

  const [form] = Form.useForm();
  useEffect(() => {
    // Set the form values when the component mounts
    form.setFieldsValue({
      message_for_mentor: menteeGoalData?.message_for_mentor,
    });
  }, [form, menteeGoalData]);

  const onFinish = (values) => {
    // Handle form submission logic here
    console.log('Received values:', values);
    handleSubmitGoal();
  };

  return (
    <>
      <section className="step__goal__item">
        <div className="step__count">
          <p>STEP 3 of 3</p>
        </div>
        <div className={activeTab === 2 ? "active" : "inactive"}>
          <Form form={form} onFinish={onFinish}>
            <div className="form-group">
              <div className="help-cont">
                <h4> Message for your mentors </h4>
              </div>
              <div className="alert__message">
             
                <div className="form-group">
                  <Form.Item
                    name="message_for_mentor"
                    rules={[
                      {
                        required: !menteeGoalData?.message_for_mentor,
                        message: "Please enter your message!",
                      },
                    ]}
                  >
                    <textarea
                      type="text"
                      name=""
                      className="form-control"
                      required
                      style={{ height: "10rem" }}
                      placeholder="Enter your message"
                      defaultValue={menteeGoalData?.message_for_mentor || " "}
                      onChange={(e) =>
                        setmenteeGoalData((pre) => ({
                          ...pre,
                          message_for_mentor: e.target.value,
                        }))
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex align-items-center justify-content-between mb-3 mt-4">
                <p className=" Sample___message grey-1-text">
                  Sample messages
                </p>
              </div>
              <div className=" Sample__msg__item">
                {paragraphs.map((p) => (
                  <div
                    className=" Sample__msg__Content  Sample___Size"
                    width={1366}
                    key={p.id}
                  >
                    <p className=" Sample___message grey-2-text text-justify  ">
                      {p.content}
                    </p>
                    <svg
                      onClick={() => handleCopy(p.id)}

                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="currentColor"
                      className="bi bi-clipboard"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                    </svg>

               
                  </div>
                ))}
              </div>
            </div>

            <div className="get-started-cls">
              <a onClick={handleBack}>
                <Button type="button" className="find-btn btn btn-primary">
                  Back
                </Button>
              </a>
              <Button
                htmlType="submit"
                className="btn btn-primary"
                disabled={form.getFieldsError().some((field) => field.errors.length > 0)}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default MessageMentor;
