import React, { useState } from 'react';
import { Col, Form, message, Row, Select, Typography } from "antd";
import { FiCreditCard, FiShoppingBag } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Timezones_List } from '../../../assets/data/data';
import { currencyName } from '../Data/Data';
import { TailSpin } from 'react-loader-spinner';
import { REACT_USER_ID } from '../../auth/tokenProvider';
import { updateProfile } from '../../../redux/actions/login';
import { useDispatch } from 'react-redux';
import { localTimezone } from '../Data/exportConst';




const { Paragraph, Text } = Typography;
const Setting = (props) => {
    const dispatch = useDispatch()
    const { form, profile, loading } = props;
    const [formEdit, setFormEdit] = useState([]);

    const initialValues = {
        timezone: profile?.timezone ? profile?.timezone : localTimezone,
    };
    const [TimeZone, setTimezone] = useState(profile?.timezone ? profile?.timezone : localTimezone)
    const onSettingSave = async (values) => {
        const postObj = {
            timezone: TimeZone,
            user_id: REACT_USER_ID
        }
        try {
            console.log("onProfileSave", postObj)
            dispatch(updateProfile(postObj));
            message.success("Settings Updated Successfully!");


        } catch (error) {
            message.error("Some Error Occured! Please try again!", 4);
        }
    };





    return (
        <>
            {loading ? (
                <div className="loader open_sessions_loader ">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            ) : (null)}
            <Row className="profile-settings mx-4" >
                {/* Fake form added just for sending success message */}
                <Form
                    onFinish={onSettingSave}
                    form={form}
                    initialValues={initialValues}
                    layout="vertical"
                    scrollToFirstError={true}
                    requiredMark={false}
                    className="profile-details-form"
                />
                <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 18, offset: 0 }}
                    xl={{ span: 14, offset: 0 }}
                >
                    <Row className="configurations">
                        <Col xs={24} md={24}>
                            {/* Timezone */}
                            <Row className="config-row" justify="space-between">
                                {/* Configuration Type */}
                                <Col xs={24} md={14} className="config-type">
                                    <HiOutlineLocationMarker className="config-icon" />
                                    <Paragraph>
                                        <Paragraph className="config-title">Timezone</Paragraph>
                                        <Paragraph className="config-description">
                                            Required for timely communications
                                        </Paragraph>
                                    </Paragraph>
                                </Col>

                                {/* Configuration Values */}
                                <Col xs={{ span: 21 }} md={{ span: 10, offset: 0 }}>
                                    {/* timezone */}
                                    <Form.Item
                                        name="timezone"
                                        label="TimeZone"
                                        className="title-fields name-field"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Select timezone!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            size="large"
                                            autoSize={{ minRows: 1, maxRows: 6 }}
                                            style={{
                                                width: "100%",
                                                borderRadius: "13px ",
                                            }}
                                            onChange={(value) => {
                                                setTimezone(value)
                                            }}
                                            defaultValue={TimeZone}
                                        >
                                            {Timezones_List.map((option) => (
                                                <Select.Option key={option.timezone} value={option.timezone}>
                                                    {option.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>



                                </Col>
                            </Row>

                            {/* Currency */}
                            <Row className="config-row" justify="space-between">
                                {/* Configuration Type */}
                                <Col xs={24} md={14} className="config-type">
                                    <FiShoppingBag className="config-icon" />
                                    <Paragraph>
                                        <Paragraph className="config-title">Sell in</Paragraph>
                                        <Paragraph className="config-description">
                                            Display your services in your prefered currency
                                        </Paragraph>
                                    </Paragraph>
                                </Col>

                                {/* Configuration Values */}
                                <Col xs={{ span: 21, offset: 0 }} md={{ span: 10, offset: 0 }}>
                                    <Select
                                        // options={CurrencyOptions}
                                        placeholder="Select a Currency"
                                        labelInValue
                                        defaultValue={currencyName === "INR" ? '₹' : "$"}
                                        style={{ width: "100%" }}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>

                            {/* Bank account */}
                            <Row className="config-row" justify="space-between">
                                {/* Configuration Type */}
                                <Col xs={18} md={16} className="config-type">
                                    <FiCreditCard className="config-icon" />
                                    <Paragraph>
                                        <Paragraph className="config-title">Payouts</Paragraph>
                                        <Paragraph className="config-description">
                                            Instant and safe payouts to your preferred account
                                        </Paragraph>
                                    </Paragraph>
                                </Col>
                                <Col
                                    xs={6}
                                    md={8}
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    <a
                                        href={`/dashboard/payments/`}
                                        onClick={() => {

                                        }}
                                    >
                                        <Text strong underline style={{ fontSize: 16 }}>
                                            Update
                                        </Text>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    );
}

export default Setting;
