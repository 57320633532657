import React from "react";
import { useState } from "react";
import "./UpdateRole.css";

const UpdateRole = ({ roleData, getUpdatedValues }) => {
  const [userRole, setUserRole] = useState(roleData);
  const changeToCamelCase = (role) => {
    const roleSplit = role.split(" ");
    let finalRole = roleSplit
      ?.map((item) => {
        if (item) return item[0]?.toUpperCase() + item?.slice(1);
      })
      ?.join(" ");

    return finalRole;
  };
  const handleOnChange = (e) => {
    let tempValue = changeToCamelCase(e.target.value);
    setUserRole(tempValue);
  };
  const handleOnClick = () => {
    let tempData = { title: userRole };
    getUpdatedValues(tempData);
  };
  return (
    <div className="update-role-main-container">
      {/* <h7>Full Name</h7> */}
      <input
        className="role-input"
        value={userRole}
        onChange={handleOnChange}
      ></input>
      <button className="update-btn-role" onClick={handleOnClick}>
        Update
      </button>
    </div>
  );
};

export default UpdateRole;
