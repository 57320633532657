import React, { useEffect, useState } from "react";

export const TimeZoneAndPrice = ({
  registrationProcessData,
  setRegistrationProcessData,
  errors,
}) => {
  const [showDiv, setShowDiv] = useState(false);
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    setAmount(registrationProcessData.price_per_session);
  }, []);

  const handleToggle = (e) => {
    setShowDiv(!showDiv);
    if (!showDiv) {
      errors.price_per_session?.onValidateFunc(null, "price_per_session");
      setAmount(0);
      setRegistrationProcessData((prev) => ({
        ...prev,
        price_per_session: "0",
        is_pro_bono: "yes",
      }));
    } else {
      setRegistrationProcessData((prev) => ({
        ...prev,
        is_pro_bono: "no",
      }));
    }
  };
  const handlePriceChange = (e) => {
    e.preventDefault();
    if (!errors.price_per_session?.onValidateFunc) return;
    let msg = null;
    if (!e.target.value && errors.price_per_session?.isReq) {
      msg = `Please type your price(USD) per session .`;
    }
    errors.price_per_session?.onValidateFunc(msg, "price_per_session");
    setRegistrationProcessData((prev) => ({
      ...prev,
      price_per_session: e.target.value,
    }));
    setAmount(e.target.value);
    
  };
  return (
    <div className="registrationCard">
      <div className=" leftSide">
        <h3 className=" mb-32 fw-bold">What’s your price per session?.</h3>
        <p className="related-context">
          Let us know what you would like to charge in the beginning from your
          mentees if you want you can also do free sessions which can help you
          in increasing your mentee-base.
        </p>
      </div>

      <div className="row rightSide">
        <div className="container ">
          <div className="form-group">
            <div className="row-btn-day-box">
              <div className="custom-control custom-switch ">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="is_pro_bono"
                  onChange={handleToggle}
                  // defaultChecked
                  //  checked={registrationProcessData.price_per_session===0}
                />
                <label
                  className="custom-control-label cursor-pointer"
                  htmlFor="is_pro_bono"
                >
                  Free Session
                </label>
              </div>
            </div>
            <div
              style={{
                opacity: showDiv ? 0.5 : 1,
                pointerEvents: showDiv ? "none" : "auto",
              }}
            >
              <label className="form-label" htmlFor="country">
                Price per session (In US Dollar)
              </label>
              <input
                onChange={(e) => {
                  handlePriceChange(e);
                }}
                type="number"
                className="form-control"
                placeholder="Price per session IN USD."
                required
                value={amount}
                defaultValue={
                  showDiv ? "0" : registrationProcessData.price_per_session
                }
              />
            </div>

            {errors?.price_per_session?.errorMsg && (
              <span className="text-danger">
                {errors?.price_per_session?.errorMsg === true
                  ? `Please type your price(USD) per session.`
                  : errors?.price_per_session?.errorMsg}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
    
  );
};
