import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { REACT_USER_ID } from '../../../auth/tokenProvider';
import { useState } from 'react';
import { convertToRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectMultiInputs from '../../../applyAsMentor/selectMultiInputs';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { expertise } from '../../../../assets/data/data';
import closeIconSvg from '../../../adminDashboard/img/closeIconSvg.svg'
import TextEditor from '../../Modal/TextEditor';
import { UploadImage } from '../../../UploadImage';
import { SyllabusIndexDataAction } from '../../../../redux/actions/course';


const CreateCourse = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const mentor_id = REACT_USER_ID;
    let date = new Date();
    let datestring = date.toTimeString();
    let countryname =
        datestring?.includes("india") | datestring?.includes("India")
            ? "India"
            : "";

    const [course_syllabus, setSyllabus] = useState([
        // {
        //     unitName: '',
        //     weeks: "",
        //     chapters: [{
        //         title: "",
        //         type: "",
        //         data: []
        //     }]
        // }
    ]);
    const [isCourseProBono, SetIsCourseProBono] = useState(false);
    const [price, setPrice] = useState(0);
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [proBono, setProBono] = useState('no');
    const [course_description, setMetaDescription] = useState();
    const [course_intro, setCourseDescription] = useState();
    const [what_you_will_learn, setYou_learn] = useState([]);
    const [highlights, setSelectedKeyHighlights] = useState([]);
    const [createCourseData, setcreateCourseData] = useState({
        course_title: "",
        course_seat: "",
        course_start_date: "",
        course_time_period: "",
        mentor_id: mentor_id,
        course_banner: "",
        course_intro_video: "",
        course_type: "",
        course_topic: "",
        faq_data: [],
    });

    const createCourse = (event) => {
        event.preventDefault();
        const validationErrors = [];

        const requiredFields = [
            { field: createCourseData.course_title, name: 'Course title' },
            { field: createCourseData.course_seat, name: 'Course seat' },
            { field: createCourseData.course_start_date, name: 'Course start date' },
            { field: createCourseData.course_time_period, name: 'Course start time' },
            { field: createCourseData.course_banner, name: 'Course banner' },
            // { field: createCourseData.course_intro_video, name: 'Course intro video' },
            { field: createCourseData.course_type, name: 'Course type' },
            // ... (other required fields)
        ];

        requiredFields.forEach(({ field, name }) => {
            if (!field) {
                validationErrors.push(`${name} is required.`);
            }
        });

        if (!isCourseProBono && (price === 0 || discountedPrice === 0)) {
            validationErrors.push('Price and discounted price are required for paid courses.');
        }

        if (!course_description) {
            validationErrors.push('Course description is required.');
        }

        if (!course_intro) {
            validationErrors.push('Course introduction is required.');
        }

        if (what_you_will_learn.length === 0) {
            validationErrors.push("At least one 'What you will learn' item is required.");
        }

        if (highlights.length === 0) {
            validationErrors.push('At least one highlight is required.');
        }

        if (validationErrors.length > 0) {
            alert(validationErrors.join('\n'));
            return;
        }

        let data = {
            price,
            discount_price: discountedPrice,
            proBono: isCourseProBono,
            course_description,
            course_intro,
            what_you_will_learn,
            highlights,
            course_syllabus,
            course_topic: createCourseData?.course_topic,
            course_seat: createCourseData?.course_seat,
            course_start_date: createCourseData?.course_start_date,
            course_time_period: createCourseData?.course_time_period,
            course_banner: createCourseData?.course_banner,
            course_intro_video: createCourseData?.course_intro_video,
            course_type: createCourseData?.course_type,
            faq_data: createCourseData?.faq_data,
            mentor_id: mentor_id,
            document_type: "course",
            currency: countryname === "India" ? "INR" : "USD",
        };
        console.log(data);

        dispatch(SyllabusIndexDataAction({ type: 'ADD_BASIC_COURSE_DATA', data }));
        navigate('add-syllabus');
    };
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const mentionSuggestions = [
        { text: "APPLE", value: "apple" },
        { text: "BANANA", value: "banana", url: "banana" },
        // ... other mention suggestions ...
    ];

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        const { blocks } = convertToRaw(editorState.getCurrentContent());
        setMetaDescription(blocks[0].text);
    };
    const [CourseeditorState, setCourseEditorState] = useState(EditorState.createEmpty());


    const CourseonEditorStateChange = (editorState) => {
        setCourseEditorState(editorState);
        const { blocks } = convertToRaw(editorState.getCurrentContent());
        setCourseDescription(blocks[0].text);
    };

    // tags input and remove
    const handleRemove = (item, removeFunc) => {
        removeFunc((prev) => prev.filter(entry => entry.value !== item.value));
    };

    const handleAdd = (item, addFunc) => {
        addFunc((prev) => [...prev, item]);
    };

    // paid or pro_bono functionality
    const handlePaidToggle = () => {
        SetIsCourseProBono(!isCourseProBono);
        if (isCourseProBono !== false) {
            setPrice(0);
            setDiscountedPrice(0);
        } else {

            setPrice(0);
            setDiscountedPrice(0);
        }
    };

    const uploadBanner = (val, pre) => {
        console.log("uploadbanner called", val);

        setcreateCourseData((pre) => ({
            ...pre,
            course_banner: val,
        }));
    };
    console.log(countryname)
    const handleDateChange = (e) => {
        // Convert the local date to UTC
        const localDate = new Date(e.target.value);
        const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();

        setcreateCourseData((prevState) => ({
            ...prevState,
            course_start_date: utcDate,
        }));
    };

    return (
        <>


            <div id="wrapper">
                <div id="content-wrapper">
                    <div id="content">
                        <div className="w-100" >
                            <div>
                                <div className="mb-4">
                                    <div className="mb-4">
                                        <div className="card card-cls ebook-card">
                                            <div className=" d-flex w-100  py-3 bg-white shadow-sm z-50">
                                                <a className=" w-auto font-semibold btn-md me-2 ms-3 p-0 rounded-circle">
                                                    <span className="me-2" onClick={() => navigate("/course")}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={25}
                                                            height={25}
                                                            fill="currentColor"
                                                            className="bi bi-arrow-left-circle"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                                            />
                                                        </svg>
                                                    </span>
                                                </a>

                                                <div className="flex-grow">
                                                    <h5 className="text-title5 text-black fw-semibold mb-1" onClick={() => { navigate('course/create-course') }}>Create a course</h5>
                                                </div>

                                            </div>
                                            <div className="card-body create__sessionCard ">
                                                <div className="row">
                                                    <form action="#">
                                                        <div className="form-group">

                                                            <div className="form-group">
                                                                <label className="course__label">
                                                                    Title *
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name=""
                                                                    placeholder="Enter course Title"
                                                                    className="form-control"
                                                                    onChange={(e) =>
                                                                        setcreateCourseData((pre) => ({
                                                                            ...pre,
                                                                            course_title: e.target.value,
                                                                            course_topic: e.target.value,
                                                                        }))
                                                                    }
                                                                    required
                                                                />
                                                            </div>
                                                            <UploadImage
                                                                callback={uploadBanner}
                                                                fileName="_course-banner"
                                                                imagePath={"courseImage/courseBanner/"}
                                                                filetype={["image"]}
                                                            />
                                                            <div className="form-group">

                                                                <TextEditor
                                                                    label="Meta Description *"
                                                                    editorState={editorState}
                                                                    onEditorStateChange={onEditorStateChange}
                                                                    mentionSuggestions={mentionSuggestions}
                                                                />


                                                            </div>
                                                            <div className="form-group">
                                                                <TextEditor
                                                                    label="Course Description *"
                                                                    editorState={CourseeditorState} // Use a separate editorState if needed
                                                                    onEditorStateChange={CourseonEditorStateChange} // Use a separate handler if needed
                                                                    mentionSuggestions={mentionSuggestions} // Use separate suggestions if needed
                                                                />
                                                            </div>

                                                            <div className="row">
                                                                <div className="col"><div className="form-group">
                                                                    <label className="course__label">
                                                                        Maximum Seat *
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        course_seat=""
                                                                        placeholder=""
                                                                        className="form-control"
                                                                        onChange={(e) =>
                                                                            setcreateCourseData((pre) => ({
                                                                                ...pre,
                                                                                course_seat: e.target.value,
                                                                            }))
                                                                        }
                                                                        required
                                                                    />
                                                                </div></div>
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="course__label">Course Type *</label>
                                                                        <select className="form-control"
                                                                            onChange={(e) =>
                                                                                setcreateCourseData((pre) => ({
                                                                                    ...pre,
                                                                                    course_type: e.target.value,
                                                                                }))
                                                                            }
                                                                        >
                                                                            <option value="" disabled selected>Select Course Type</option>
                                                                            {expertise.map((expertise, index) => (
                                                                                <option key={index} value={expertise.value}>
                                                                                    {expertise.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label htmlFor="" className="course__label">
                                                                            Course Start From*
                                                                        </label>
                                                                        <input
                                                                            type="datetime-local"
                                                                            placeholder=""
                                                                            className="form-control"
                                                                            onChange={handleDateChange}

                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label htmlFor="" className='course__label'>Course Time Period(in week)*</label>
                                                                        <input
                                                                            type="number"

                                                                            placeholder=""
                                                                            className="form-control"
                                                                            onChange={(e) =>
                                                                                setcreateCourseData((pre) => ({
                                                                                    ...pre,
                                                                                    course_time_period: e.target.value,
                                                                                }))
                                                                            }
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div className="form-group">
                                                            {/* Language */}
                                                            <div className="multi-inputs-container">
                                                                <label className="course__label">
                                                                    What you will learn *
                                                                </label>
                                                                <div className="multi-inputs">
                                                                    {what_you_will_learn.map((language) => (
                                                                        <div className="multi-input-design" key={language.value}>
                                                                            <div>{language.label}</div>
                                                                            <div onClick={() => handleRemove(language, setYou_learn)}>
                                                                                <FontAwesomeIcon
                                                                                    className="multi-select-icon"
                                                                                    icon={faXmark}
                                                                                    style={{ color: 'grey' }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <SelectMultiInputs
                                                                isMulti={true}
                                                                title="language"
                                                                onChangeFunc={(e) => handleAdd(e, setYou_learn)}
                                                                value=""
                                                                name="language"
                                                                isCreatable={true}
                                                                placeholder="Add at least 5-6 Tags"
                                                                className="basic-multi-select text-left"
                                                                classNamePrefix="select"
                                                                inputData={what_you_will_learn}
                                                                closeMenuOnSelect={true}
                                                                style={{ borderRadius: '12px', height: '3rem' }}
                                                            />

                                                            {/* Framework */}
                                                            <div className="multi-inputs-container">
                                                                <label className="course__label">
                                                                    Key Highlights*
                                                                </label>
                                                                <div className="multi-inputs">
                                                                    {highlights.map((framework) => (
                                                                        <div className="multi-input-design" key={framework.value}>
                                                                            <div>{framework.label}</div>
                                                                            <div onClick={() => handleRemove(framework, setSelectedKeyHighlights)}>
                                                                                <FontAwesomeIcon
                                                                                    className="multi-select-icon"
                                                                                    icon={faXmark}
                                                                                    style={{ color: 'grey' }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <SelectMultiInputs
                                                                isMulti={true}
                                                                title="framework"
                                                                onChangeFunc={(e) => handleAdd(e, setSelectedKeyHighlights)}
                                                                value=""
                                                                name="framework"
                                                                isCreatable={true}
                                                                placeholder="Add at least 5-6 Tags"
                                                                className="basic-multi-select text-left"
                                                                classNamePrefix="select"
                                                                inputData={highlights}
                                                                closeMenuOnSelect={true}
                                                                style={{ borderRadius: '12px', height: '3rem' }}
                                                            />

                                                        </div>
                                                        <div className="form-group">
                                                            <div className="form-check form-switch d-flex">
                                                                <input
                                                                    className="form-check-input"

                                                                    role="switch"
                                                                    id="flexSwitchCheckDefault"
                                                                    type="checkbox"
                                                                    checked={isCourseProBono}
                                                                    onChange={handlePaidToggle}

                                                                />
                                                                <label className="course__label" htmlFor="flexSwitchCheckDefault">
                                                                    ProBono Course
                                                                </label>
                                                            </div>

                                                        </div>
                                                        {
                                                            isCourseProBono === false && (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="course__label">
                                                                                    Price *
                                                                                </label>
                                                                                <div className="input-group">
                                                                                    <span className="input-group-text price___default">
                                                                                        $
                                                                                    </span>
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="number"
                                                                                        id="customInput"

                                                                                        placeholder="Price"
                                                                                        value={price}
                                                                                        onChange={(e) => setPrice(e.target.value)}
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="course__label">
                                                                                    Discounted price *
                                                                                </label>
                                                                                <div className="input-group">
                                                                                    <span className="input-group-text price___default">
                                                                                        $
                                                                                    </span>

                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="number"
                                                                                        id="customInput"

                                                                                        placeholder="Discounted Price"
                                                                                        value={discountedPrice}
                                                                                        onChange={(e) => setDiscountedPrice(e.target.value)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }


                                                        <div className="get-started-cls why_choose_ment">
                                                            <button
                                                                onClick={createCourse}
                                                                type="button"
                                                                className="btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateCourse;
