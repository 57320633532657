import {
    ic2,
    ic5,
    ic6,
    ic7,
    pro1,
    pro2, bannerImg
} from '../../../assets/images/export-images';
import { AiOutlineHeart } from 'react-icons/ai';
import { MdContentPaste, MdOutlineProductionQuantityLimits, MdProductionQuantityLimits } from 'react-icons/md';
import { BsDatabaseAdd } from 'react-icons/bs';
import { SiDesignernews, SiMarketo } from 'react-icons/si';
import { CgSoftwareDownload } from 'react-icons/cg';
import profile1 from '../assets/profie1.jpg'
import profile2 from '../assets/profie2.jpg'
import profile3 from '../assets/profie3.jpg'
import profile4 from '../assets/profie4.jpg'
import profile5 from '../assets/profie5.jpg'
import profile6 from '../assets/profie6.jpg'
import profile7 from '../assets/profie7.jpg'
import profile8 from '../assets/profie8.jpg'
import profile9 from '../assets/profie9.jpg'
import profile10 from '../assets/profie10.jpg'
import image1 from '../assets/image1.jpg'
import image2 from '../assets/image2.jpg'
import image3 from '../assets/image3.jpg'
import image4 from '../assets/image4.jpg'
import image5 from '../assets/image5.jpg'
import image6 from '../assets/image6.jpg'
import image7 from '../assets/image7.jpg'
import image8 from '../assets/image8.jpg'
import image9 from '../assets/image9.jpg'
import image10 from '../assets/image10.jpg'
import image11 from '../assets/image11.jpg'
import image12 from '../assets/image12.jpg'
import fullStackBanner from '../assets/fullStackBanner.jpg'
import clock from '../assets/clock.png'
import collab from '../assets/collab.png'
import job from '../assets/job.png'
import learning from '../assets/learning.png'
import projects from '../assets/projects.png'
import opport from '../assets/opport.png'
import nitin from '../assets/nitin.jpg'
import anshika from '../assets/anshika.jpg'
import pritam from '../assets/pritam.jpg'
import harpal from '../assets/harpal.jpg'
import brijlal from '../assets/brijlal.jpg'
import community from '../assets/community.jpg'

import img1 from '../assets/img1.png'
import { v4 } from 'uuid';



export const courses = [
    {
        title: "MentorPal Originals: AI Powered Product Management",
        image: image1,
        price: "$29 USD",
        duration: "1.5 Weeks",
        date: "July 18, 2023",
        description: "Unlock AI's potential in product management, revamping your development journey.",
    },
    {
        title: "MentorPal Originals: AI Powered Design",
        image: image5,
        price: "$30 USD",
        duration: "1.5 Weeks",
        date: "August 8, 2023",
        description: "Unlock the Power of Design with AI.",
    },
    {
        title: "MentorPal Originals: Webflow Series",
        image: image3,
        duration: "August 31, 2023",
        description: "Bring your imagination to reality, and build impactful websites that stand out.",
    },
];
export const faqData = [
    {
        question: "What is MentorPal?",
        answer: "MentorPal is a tool that enables you to connect with your audience through 1:1 sessions - to share your knowledge and monetise your time better. Just create your personal link, add your services, availability and charges; and share your link with your followers. Your followers can now book a call to connect with you",
    },
    {
        question: "What makes MentorPal courses unique?",
        answer: "MentorPal courses are designed by industry experts who bring their real-world experience and mentorship insights into course creation. This ensures that every lesson is personalized and tailored to your learning needs, making your learning journey more effective and engaging."
    },
    {
        question: "Can I conduct paid sessions?",
        answer: "Absolutely, we enable you to conduct both free and paid sessions with your followers.",
    },
    {
        question: "How much can I charge for my sessions?",
        answer: "It really depends on how much value you think you're providing and how much are your followers willing to pay. For idea, our creators charge anywhere from $50 to $500 for a session.",
    },
    {
        question: "How is the course price determined?",
        answer: "The pricing of MentorPal courses takes into account various factors, including course length, intensity, contact hours, and the expertise of the mentors. We strive to make our courses accessible while acknowledging the effort mentors put into creating original content and exercises. Our courses are, on average, priced 67% lower than similar offerings."
    },
    {
        question: "Can I seek reimbursement from my employer?",
        answer: "Absolutely! After enrolling in a course, you'll receive an invoice for your purchase. You can forward this invoice to your employer for reimbursement. You can also attach your certificate of completion or use our provided email template. Feel free to contact us at support@mentorpal.ai if you need assistance customizing the information."
    },
    {
        question: "Do you offer scholarships or financial aid?",
        answer: "Yes, scholarships and financial aid availability may vary by course. Some mentors generously offer up to 100% scholarships. Check the individual course page for details on available scholarships and financial aid options."
    },
    {
        question: "Will I receive a certificate upon completion?",
        answer: "Certainly! Upon successful completion of a course, you'll be eligible to receive a certificate to showcase your achievement."
    },
    {
        question: "Can I purchase courses for my team?",
        answer: "Absolutely! If you'd like to purchase courses for your team, please reach out to us at help@mentorpal.ai, and we'll be happy to assist you."
    },
    {
        question: "Can I sponsor a course seat for someone else?",
        answer: "Yes, you can sponsor a course seat for someone else. You can purchase a course seat of your choice and indicate in the notes that you're sponsoring it for another person. Alternatively, you can contact us at support@mentorpal.ai to arrange sponsorship. We'll be delighted to recognize your gesture."
    },
    {
        question: "Is there a refund policy?",
        answer: "Refund policies vary by course. Many MentorPal mentors offer a generous 100% refund policy for their courses. Please refer to the individual course page for specific refund policy details."
    }
];
export const featuresData = [
    {
        icon: ic2,
        title: "Helps You Achieve Goals",
        description: "We will provide you mentor who will help you a lot in achieving your goals quickly."
    },
    {
        icon: ic6,
        title: "Video, Audio & Chats",
        description: "We offer you video and audio chats with us so that you can elaborate your issues clearly and get the solution effectively."
    },
    {
        icon: ic7,
        title: "24x7 Customer Support",
        description: "We have 24*7 support system to help you day or night."
    },
    {
        icon: ic5,
        title: "Free Study Material",
        description: "You do not need to worry about your study material. At MentorPal, we provide you with free-study material on your specific discipline."
    },
    {
        icon: ic6,
        title: "Task & Exercises",
        description: "We organize many tasks and exercises to enhance your abilities and inspect your capabilities."
    },
    {
        icon: ic7,
        title: "Low Fee & Subscription",
        description: "Our subscription fee is meager because we are trying to provide the best service at a light cost."
    }
];
export const FilterOptions = [
    {
        icon: <AiOutlineHeart className="carousel_item_icon" />,
        text: "Pro Bono",
        active: false
    },
    {
        icon: <MdContentPaste className="carousel_item_icon" />,
        text: "Content Writing",
        active: false
    },
    {
        icon: <BsDatabaseAdd className="carousel_item_icon" />,
        text: "Data Science",
        active: false
    },

    {
        icon: <SiDesignernews className="carousel_item_icon" />,
        text: "Design",
        active: false
    },
    {
        icon: <SiMarketo className="carousel_item_icon" />,
        text: "Marketing",
        active: false
    },
    {
        icon: <MdOutlineProductionQuantityLimits className="carousel_item_icon" />,
        text: "Product Management",
        active: false
    },
    {
        icon: <MdProductionQuantityLimits className="carousel_item_icon" />,
        text: "Product Research",
        active: false
    },
    {
        icon: <CgSoftwareDownload className="carousel_item_icon" />,
        text: "Software Development",
        active: false
    },
]

export const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 992 },
        items: 8,
    },

    ipad: {
        breakpoint: { max: 992, min: 768 },
        items: 7,
    },

    tablet1: {
        breakpoint: { max: 768, min: 465 },
        items: 6,
    },

    mobile: {
        breakpoint: { max: 465, min: 0 },
        items: 4,
    },
};

export const Course_details_faqData = [

    {
        "question": "Do I need any prior experience in web development?",
        "answer": "No prior experience is required, but basic knowledge of programming concepts will be helpful. This course starts with the fundamentals and gradually progresses to advanced topics, making it suitable for beginners and those looking to enhance their skills."
    },

    {
        "question": "Will I build real-world projects?",
        "answer": "Yes, you'll work on various real-world projects throughout the course. These projects are designed to give you practical experience and apply the concepts you learn."
    },
    {
        "question": "How is the course structured?",
        "answer": "The course is divided into units, each focusing on specific topics. Each unit contains multiple chapters that dive deep into the subject matter. You'll have hands-on exercises, quizzes, and assignments to reinforce your learning."
    },
    {
        "question": "Is there any support for doubts and questions?",
        "answer": "Yes, there is a dedicated discussion forum where you can ask questions, discuss concepts, and interact with instructors and fellow learners. Additionally, you'll have access to live Q&A sessions and personalized feedback."
    },
    {
        "question": "Will I receive a certificate upon completion?",
        "answer": "Yes, upon successfully completing the course, you'll receive a certificate of completion. This certificate can be added to your portfolio and shared with potential employers."
    },
    {
        "question": "Are there internship opportunities?",
        "answer": "Yes, we offer internship opportunities for top-performing students. These internships provide valuable hands-on experience and a chance to work on real-world projects, enhancing your practical skills."
    },
    {
        "question": "What is the course duration?",
        "answer": "The course is designed to be completed in approximately 6 months. However, you can learn at your own pace, and the content will be accessible to you even after the course duration ends."
    },
    {
        "question": "Do I need any special software or hardware?",
        "answer": "You'll need a computer with an internet connection to access the course materials, IDEs (Integrated Development Environments) for coding, and web browsers for testing your applications."
    },

]


export const testimonialsData = [
    {
        id: 1,
        name: 'Michael Johnson',
        role: 'AI Developer, InnovateTech Inc.',
        image: profile6,
        content: "mentorpal.ai's AI Mastery Series was an insightful, strategy-packed, enjoyable, and educational journey. I wholeheartedly recommend advancing or refining your professional skills in any upcoming mentorpal.ai certification!",
    },
    {
        id: 2,
        image: profile7,
        role: 'Data Scientist, Data Insights Co.',
        content: "mentorpal.ai's AI Mastery Series was a high-level, strategy-packed, enjoyable, and informative experience. I highly recommend enhancing or refreshing your skills in any future mentorpal.ai certification!",
    },
    {
        id: 3,
        name: 'Oliver Davis',
        role: 'UX Designer, InnovateUX',
        image: profile8,
        content: "I absolutely adore it! The AI Mastery Series offered us a systematic, yet creative playground for honing our abilities. Throughout the program, we learned step-by-step from foundational elements to advanced techniques, showcasing the versatility and potential of this field! A big thanks to mentorpal.ai for offering this to all of us!",
    },
    {
        id: 4,
        name: 'Isabella White',
        role: 'Machine Learning Engineer, TechGenius Corp.',
        image: profile9,
        content: "The mentorpal.ai AI Mastery Series provided a comprehensive and engaging learning experience. It's a well-structured program with practical insights that I can immediately apply to real-world projects. I'm truly grateful for the opportunity to be a part of this exceptional program!",
    },
    {
        id: 5,
        name: 'Daniel Miller',
        role: 'AI Researcher, Insights Labs',
        image: profile10,
        content: "As an aspiring AI enthusiast, mentorpal.ai's AI Mastery Series was an eye-opening journey that expanded my knowledge and skills. The engaging content, hands-on projects, and expert mentorship have empowered me to pursue cutting-edge AI research and development.",
    },
    {
        id: 6,
        name: 'Emma Wilson',
        role: 'Data Analyst, DataSense Solutions',
        image: profile1,
        content: "mentorpal.ai's AI Mastery Series was an incredible learning experience. The well-structured curriculum and practical exercises helped me develop a deeper understanding of AI concepts and applications. I'm now equipped with valuable skills to excel in my career as a data analyst.",
    },
];
export const benefitsData = [
    {
        icon: clock,
        title: "100+ hours of enriching learning",
        description:
            "Gain insights from world-class instructors affiliated with leading product companies and enhance your job readiness.",
    },
    {
        icon: learning,
        title: "Immersive learning environment",
        description:
            "Receive comprehensive assessments of your technical skills via hands-on coding using CloudLabs.",
    },
    {
        icon: projects,
        title: "20+ real-world projects and case studies",
        description:
            "Master over 20 tools and technologies through practical industry projects.",
    },
    {
        icon: job,
        title: "Outcome-focused teaching approach",
        description:
            "Receive comprehensive career guidance including mock interviews, CV optimization, LinkedIn, GitHub, and soft skills training.",
    },
    {
        icon: opport,
        title: "Unlock 10X more job opportunities",
        description:
            "Access an array of job prospects, positioning yourself ahead of your peers.",
    },
    {
        icon: collab,
        title: "16+ hours of collaborative career coaching",
        description:
            "Acquire workforce readiness through interactions with distinguished tech industry leaders.",
    },
];
export const host_course_features = [
    {
        icon: clock,
        title: "Personalized Learning Paths",
        description: "Create customized learning paths for each individual learner based on their skill level, interests, and goals.",
    },
    {
        icon: learning,
        title: "Interactive Assessments",
        description: "Engage learners with interactive assessments, quizzes, and challenges to reinforce their understanding.",
    },
    {
        icon: projects,
        title: "Real-world Projects",
        description: "Assign real-world projects and tasks to help learners apply their knowledge in practical scenarios.",
    },
    {
        icon: job,
        title: "Collaborative Learning",
        description: "Foster collaboration among learners through group projects, discussions, and peer-to-peer feedback.",
    },
    {
        icon: opport,
        title: "Skill Tracking & Reporting",
        description: "Track learner progress and skill development with detailed reports and analytics.",
    },
    {
        icon: collab,
        title: "Certification & Recognition",
        description: "Award certificates and badges to learners upon completion to recognize their achievements.",
    },
];
export const hireTaletnFeatures = [
    {
        icon: clock,
        title: "Job Description Analysis",
        description: " Once you finalize the job description, we get to work, aligning our understanding of the required skills and your company's unique culture.",
    },
    {
        icon: projects,
        title: "Algorithmic Shortlisting",
        description: "Our proprietary matching algorithm sifts through candidates, selecting only those who meet the stringent criteria set by you.",
    },
    {
        icon: learning,
        title: "Human Touch",
        description: " Post-algorithmic shortlisting, candidates face a rigorous technical interview conducted by our elite panel. Only the best progress.",
    },
    {
        icon: opport,
        title: "Feedback Loop",
        description: " Every candidate feedback, especially rejections, is a learning curve for us. We fine-tune our process continuously, ensuring better matches with each iteration.",
    },
    
];

export const course_details = [
    {
        "product_id": `${process.env.REACT_APP_MENTORPAL_PRODUCT_ID}`,
        "mentor_id": `${process.env.REACT_APP_MENTORPAL_MENTOR_ID}`,
        "course_title": "MERN stack",
        "course_topic": "Mastering Full Stack Web Development",
        "course_type": "Software Development",
        "course_time_period": "54 weeks",
        "course_start_date": "Sep 07,2023",
        "course_description": "Become a proficient full stack web developer with our comprehensive course on the MERN stack. Learn to build dynamic and responsive web applications from scratch using MongoDB, Express.js, React, and Node.js. Enroll now and unlock the skills to create modern, feature-rich web solutions.",
        "course_curriculum": "https//",
        "course_banner": fullStackBanner,
        "course_intro_video": "http://",
        "course_intro": `Our "Mastering MERN Stack Web Development Course" is your gateway to becoming a proficient full stack web developer. By the end of this journey, you'll have the skills and knowledge to create modern web applications from scratch, integrating frontend and backend seamlessly. Whether you're a beginner or experienced developer, this course empowers you to bring your web development aspirations to life.Enroll now and embark on a transformative learning experience that opens doors to exciting opportunities in the dynamic world of MERN stack web development.Join us and embark on a journey of growth, creativity, and achievement..`,
        "course_seat": "150",
        "course_hosted_by": [
            {
                imageUrl: community,
                name: 'Mentorpal.ai',
                linkedin: 'https://www.linkedin.com/company/mentorpal/',
                description: 'At MentorPal.ai, we believe in the transformative power of one-on-one mentorship and comprehensive courses tailored to empower students and guide individuals towards excellence. Our platform is your dedicated space to unlock your full potential, bridging the gap between ambition and achievement.',

                title: 'Mentorpal Community',
            },
            {
                "imageUrl": pritam,
                "name": 'Pritam Sen',
                "title": 'Senior Software Engineer | Ex - bokmyshow',
                "linkedin": 'https://www.linkedin.com/in/pritam-se-n/',
                "description": 'A backend focused full-stack developer mainly using Node JS , python. You can think of me as a Professional software developer, deeply involved with design, development, and customization of the code with close to 6 years of experience in Agile development. I am an enthusiastic learner who is pragmatic and objective- driven.Looking for a challenging position where I can achieve my career aspirations & contribute to the growth of the organization.',
            },
            {
                imageUrl: nitin,
                name: 'Nitin Chavan ',
                title: 'IIT Bombay',
                linkedin: 'https://www.linkedin.com/in/nitin-chavan-363476114/',
                description: `I love implementing novel ideas and theories in easy and simple codes. As a student in, I loved working on 'face- recognition', 'scale invariable feature vector extraction' and developing simple communication protocol for network of quad-copters for co-operative tasks. Now web development and design is my new fascination. When I am not doing any of that, I bury myself into novels.Besides these, I love cooking and experimenting with new recipes..`,
            },
            {
                imageUrl: harpal,
                name: 'Harpal Singh',
                title: 'Engineering Manager at Niyo Solutions Inc. | Ex - Times of India',
                linkedin: 'https://www.linkedin.com/in/harpal-singh-43291837/',
                description: ' Advanced knowledge of management concepts, practices and techniques. Experienced in leading a centralized team providing support to multiple aligned development teams. Excellent problem solving, issue identification and technical documentation skills. Gauging progress of various projects. Communicating effectively with customers. Collaborating with the engineering team to create new products.',
            },
            {
                imageUrl: brijlal,
                name: 'BrijLal Yogi',
                title: 'Chief Executive Officer at Mentorpal.ai | Master trainer from NIESBUD',
                linkedin: 'https://www.linkedin.com/in/brijlal-yogi-39a53622b/',
                description: 'Mentorpal is a leading mentoring platform that connects individuals with experienced mentors in their desired fields. Our platform offers personalized support and guidance to help achieve your career and personal goals. Join the Mentorpal community today and take your skills to the next level!',
            },
            {
                imageUrl: anshika,
                name: 'ANSHIKA AGARWAL',
                title: 'SDE II at GitHub',
                linkedin: 'https://www.linkedin.com/in/anshika-agarwal-45a53056/',
                description: 'ANSHIKA is a passionate AI enthusiast and researcher, specializing in applying artificial intelligence to enhance the MentorPal.ai platform. With a deep understanding of natural language processing and machine learning, Alex ensures that the platform delivers personalized and relevant learning experiences.',
            },
        ],
        "course_syllabus": [
            {
                "title": "Introduction to Web Development",
                "weeks": 1,
                "topics": [
                    "Introduction to Web Development",
                    "Client-side vs. Server-side",
                    "Web development technologies and tools",
                    "Web development process and best practices"
                ]
            },
            {
                "title": "HTML Fundamentals",
                "weeks": 2,
                "topics": [
                    "Introduction to HTML",
                    "HTML syntax and structure",
                    "HTML elements and attributes",
                    "Semantic HTML",
                    "HTML forms and input elements"
                ]
            },
            {
                "title": "CSS Basics",
                "weeks": 2,
                "topics": [
                    "Introduction to CSS",
                    "CSS selectors and properties",
                    "Box model and layout",
                    "CSS Flexbox and Grid",
                    "Responsive design and media queries",
                    "CSS frameworks (Bootstrap 5)"
                ]
            },
            {
                "title": "Intermediate CSS",
                "weeks": 2,
                "topics": [
                    "CSS variables and custom properties",
                    "CSS transitions and animations",
                    "Advanced positioning and layouts",
                    "Styling for different screen sizes",
                    "CSS best practices and optimization"
                ]
            },
            {
                "title": "Advanced CSS",
                "weeks": 2,
                "topics": [
                    "CSS preprocessors (e.g., Sass)",
                    "CSS-in-JS libraries (e.g., Styled Components)",
                    "Responsive typography and design systems",
                    "CSS architecture and modularization",
                    "Cross-browser compatibility and vendor prefixes"
                ]
            },
            {
                "title": "Introduction to JavaScript",
                "weeks": 2,
                "topics": [
                    "Introduction to JavaScript",
                    "Variables, data types, and operators",
                    "Control flow and conditional statements",
                    "Functions and scope",
                    "Arrays, objects, and loops"
                ]
            },
            {
                "title": "DOM Manipulation and Events",
                "weeks": 2,
                "topics": [
                    "Document Object Model (DOM)",
                    "Selecting and manipulating DOM elements",
                    "Event handling and listeners",
                    "Managing form data and validation",
                    "AJAX and fetching data"
                ]
            },
            {
                "title": "ES6+ and Modern JavaScript",
                "weeks": 2,
                "topics": [
                    "Introduction to ES6+ features",
                    "Arrow functions and lexical scope",
                    "Destructuring and spread/rest operators",
                    "Promises and asynchronous programming",
                    "Modules and import/export"
                ]
            },
            {
                "title": "React.js Basics",
                "weeks": 2,
                "topics": [
                    "Introduction to React.js",
                    "Components and props",
                    "State and lifecycle",
                    "Handling events and forms",
                    "React JSX and Babel"
                ]
            },
            {
                "title": "React Components and Props",
                "weeks": 2,
                "topics": [
                    "Functional vs. Class components",
                    "Props and prop types",
                    "Component composition and reusability",
                    "State management and lifting state up",
                    "Conditional rendering and key props"
                ]
            },
            {
                "title": "React State and Lifecycle",
                "weeks": 2,
                "topics": [
                    "State and local state management",
                    "Lifecycle methods and hooks",
                    "Component lifecycle events",
                    "Managing side effects with useEffect",
                    "Optimizing performance with shouldComponentUpdate and React.memo"
                ]
            },
            {
                "title": "React Forms and Validation",
                "weeks": 2,
                "topics": [
                    "Controlled vs. uncontrolled components",
                    "Form handling and controlled inputs",
                    "Form validation and error handling",
                    "Using libraries like Formik and Yup",
                    "Building custom form components"
                ]
            },
            {
                "title": "React Redux - State Management",
                "weeks": 3,
                "topics": [
                    "Introduction to Redux",
                    "Redux store and reducers",
                    "Actions and action creators",
                    "Connecting React with Redux",
                    "Using mapStateToProps and mapDispatchToProps",
                    "Async operations with Redux Thunk",
                    "Normalized state and selectors",
                    "Redux DevTools and debugging"
                ]
            },
            {
                "title": "React Context and Reducing Redundant Context",
                "weeks": 2,
                "topics": [
                    "Using React Context for state management",
                    "Creating and consuming context",
                    "Optimizing context performance",
                    "Context providers and consumers",
                    "Combining React Context with Redux",
                    "Reducing redundant context",
                    "Context vs. Redux: When to use each"
                ]
            },
            {
                "title": "Node.js Introduction",
                "weeks": 2,
                "topics": [
                    "Introduction to Node.js",
                    "Setting up a Node.js environment",
                    "Node.js modules and NPM",
                    "Creating a basic Node.js server",
                    "Understanding the event loop and asynchronous programming"
                ]
            },
            {
                "title": "Building APIs with Express.js",
                "weeks": 3,
                "topics": [
                    "Introduction to Express.js",
                    "Setting up an Express.js application",
                    "Routing and middleware",
                    "Handling HTTP requests and responses",
                    "Error handling and status codes",
                    "RESTful API design principles"
                ]
            },
            {
                "title": "Database Integration with MongoDB",
                "weeks": 3,
                "topics": [
                    "Introduction to MongoDB",
                    "Data modeling and schema design",
                    "CRUD operations with MongoDB",
                    "Indexing and performance optimization",
                    "Connecting Express.js to MongoDB",
                    "Querying and aggregations"
                ]
            },
            {
                "title": "Authentication and Authorization",
                "weeks": 2,
                "topics": [
                    "User authentication strategies",
                    "Password hashing and salting",
                    "JSON Web Tokens (JWT)",
                    "OAuth and third-party authentication",
                    "Role-based access control (RBAC)"
                ]
            },
            {
                "title": "Advanced Backend Development",
                "weeks": 3,
                "topics": [
                    "Real-time applications with WebSockets",
                    "Background jobs and task scheduling",
                    "Logging and monitoring applications",
                    "Security best practices and CORS",
                    "API versioning and documentation",
                    "Testing APIs with Postman and Jest"
                ]
            },
            {
                "title": "Advanced Database Management",
                "weeks": 3,
                "topics": [
                    "Database performance optimization",
                    "Database indexing and query optimization",
                    "Replication and data consistency",
                    "Database backups and disaster recovery",
                    "SQL vs. NoSQL databases",
                    "Advanced data modeling and relationships"
                ]
            },
            {
                "title": "Containerization and DevOps",
                "weeks": 2,
                "topics": [
                    "Introduction to Docker and containerization",
                    "Managing containers and images",
                    "Orchestration with Docker Compose",
                    "CI/CD pipelines with Jenkins or GitLab",
                    "Infrastructure as Code (IaC) with Terraform"
                ]
            },
            {
                "title": "Advanced Backend and Cloud Deployment",
                "weeks": 3,
                "topics": [
                    "Load balancing and scaling applications",
                    "High availability and fault tolerance",
                    "Serverless architecture with AWS Lambda",
                    "Monitoring and alerting with Prometheus and Grafana",
                    "Database scaling and replication",
                    "Data sharding and partitioning"
                ]
            },
            {
                "title": "Final Project and Portfolio Development",
                "weeks": 4,
                "topics": [
                    "Planning and designing a complex application",
                    "Implementing frontend and backend features",
                    "User experience and usability testing",
                    "Code refactoring and optimization",
                    "Documentation and creating a portfolio",
                    "Project presentation and demo",
                    "Job search and interview preparation"
                ]
            }
        ]
        ,
        "inr_price": "99999",
        "dollar_price": "1204.37",
        "inr_discount": "49999",
        "dollar_discount": "602.18",
        "inr_partial": "10000",
        "dollar_partial": "124.32",
        "discount_off": "50",
        "highlights": [
            {
                title: 'Master both frontend and backend development',
                description: 'Learn HTML, CSS, JavaScript, React, Angular, Node.js, Express.js, MongoDB...',
            },
            {
                title: 'Build dynamic and interactive web applications',
                description: 'Learn to create web applications with engaging user interfaces.',
            },
            {
                title: 'Learn the latest web technologies and frameworks',
                description: 'Stay up-to-date with the ever-evolving landscape of web development.',
            },
            {
                title: 'Gain hands-on experience through real-world projects',
                description: 'Apply your skills by working on practical projects that simulate real-world scenarios.',
            },
            // Add more highlight items as needed
        ],
        "what_you_will_learn": [
            {
                title: 'Learn Frontend Development',
                description: 'Master HTML, CSS, JavaScript, and frontend frameworks like React and Angular.',
            },
            {
                title: 'Understand Backend Development',
                description: 'Become proficient in server-side programming with Node.js, Express.js, and databases like MongoDB.',
            },
            {
                title: 'Database Integration',
                description: 'Learn how to integrate databases into your web applications and work with SQL and NoSQL databases.',
            },
            {
                title: 'API Development',
                description: 'Build RESTful APIs and understand concepts like server-client architecture and data exchange.',
            },
            {
                title: 'Deployment and DevOps',
                description: 'Learn how to deploy web applications, configure servers, and work with tools like Git and Docker.',
            },
            // Add more learn items as needed
        ],
    }
]
