import {
    Button,
    Col,
    ColorPicker,
    Drawer,
    Modal,
    Row,
    Space,
    Typography,
    message,
} from "antd";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { IoMdColorFilter } from "react-icons/io";

import imgThemeModal from '../../Image/imgThemeModal.svg'
import { isMobile } from './../../Data/Data';


const { Paragraph } = Typography;

const BrandModal = ({
    visible,
    setVisible,
    onSuccess = () => { },
}) => {
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState(
        "#D5534D"
    );

    const onThemeSelection = async () => {
        setRequestInProgress(true);
        const postObj = {
            theme_details: {
                theme: selectedTheme,
                backgroundColor: selectedTheme,
            },
        };
        try {

            setRequestInProgress(true);
            message.success(
                "Theme Updated Successfully! Refresh page if it does not reflect in a few seconds",
                5
            );

            onSuccess();
            setVisible(false);
        } catch (error) {
            message.error("Some Error Occured! Please try again!", 4);
            setRequestInProgress(true);
        }
    };

    const DefaultThemes = [
        {
            primary: "#D5534D",
            id: 1,
        },
        {
            primary: "#A88BF8",
            id: 2,
        },
        {
            primary: "#93C5F9",
            id: 3,
        },
        {
            primary: "#E18C45",
            id: 4,
        },
        {
            primary: "#FFD699",
            id: 5,
        },
    ];

    const ThemeSection = () => (
        <Row>
            <Col
                xs={24}
                md={12}
                className="img-container"
                style={{
                    backgroundColor: selectedTheme,
                }}
            >
                <motion.div
                    exit={{ opacity: 0 }}
                    initial={
                        requestInProgress ? false : { opacity: 0, scale: 0.7, y: 150 }
                    }
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                >
                    <img
                        src={imgThemeModal}
                        alt="web image"
                        width={300}
                        height={325}
                    />
                </motion.div>
            </Col>
            <Col xs={24} md={{ span: 10, offset: 1 }} className="tm-content">
                <Paragraph>
                    <Paragraph className="tm-title">Brand your page</Paragraph>
                    <Paragraph className="tm-desc">
                        Customize and style your profile colors to your Brand
                    </Paragraph>
                    <Space size={12} direction="vertical">
                        <Space align="center">
                            {DefaultThemes?.map((theme) => (
                                <Paragraph
                                    key={theme?.id}
                                    className={`profile-color-selector ${selectedTheme === theme?.primary ? "active" : ""
                                        }`}
                                    onClick={() => setSelectedTheme(theme?.primary)}
                                >
                                    <span
                                        style={{
                                            backgroundColor: theme?.primary,
                                        }}
                                    />
                                </Paragraph>
                            ))}
                        </Space>

                        <Row
                            className="theme-container"
                            justify="space-between"
                            align="middle"
                        >
                            <Row align="middle" gutter={12}>
                                <Col>
                                    <Paragraph className="color-icon">
                                        <IoMdColorFilter size={16} />
                                    </Paragraph>
                                </Col>
                                <Col>
                                    <Paragraph className="field-title">
                                        Select your theme
                                    </Paragraph>
                                </Col>
                            </Row>
                            <ColorPicker
                                value={selectedTheme}
                                onChange={(val) => setSelectedTheme(val.toHexString())}
                            />
                        </Row>
                    </Space>
                </Paragraph>
                <Button
                    block
                    className="btn-dark tm-cta"
                    onClick={() => onThemeSelection()}
                    loading={requestInProgress}
                    disabled={requestInProgress}
                >
                    Save Changes
                </Button>
            </Col>
        </Row>
    );

    return !isMobile ? (
        <Drawer
            open={visible}
            onClose={() => setVisible(false)}
            destroyOnClose
            className="theme-modal"
            styles={{
                header: { display: "none" },
                body: {
                    padding: 0,
                    // background: "#fff",
                    overflow: "hidden",
                },
            }}
            footer={null}
            placement="bottom"
            height="unset"
            style={{
                maxHeight: "100vh",
            }}
        >
            {ThemeSection()}
        </Drawer>
    ) : (
        <Modal
            width={800}
            open={visible}
            onCancel={() => setVisible(false)}
            destroyOnClose
            footer={null}
            className="theme-modal"
        >
            {ThemeSection()}
        </Modal>
    );
};

export default BrandModal;
