import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeAction } from '../../redux/actions/common';
import { Input, Button, message } from 'antd';
const CourseSubscribe = () => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const subscriberesponce = useSelector((state) => state.subscribeReducer);

    console.log("respons", subscriberesponce);

    useEffect(() => {
        if (subscriberesponce?.error !== undefined && loading === false) {
            message.warning("You already subscribed to our newsletter!");
        }
        if (subscriberesponce?.error === undefined && subscriberesponce?.status === 200 && loading === false) {
            message.success("Subscribed Successfully!");
        }
    }, [subscriberesponce, loading]);

    const submitemail = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        setLoading(validateEmail(email));

        if (email === "") {
            message.error("Enter an email address");
            setLoading(false);
        } else if (validateEmail(email)) {
            // Simulate a loading delay of 1 second
            await new Promise((resolve) => setTimeout(resolve, 1000));

            const data = { email: email };
            try {
                await dispatch(subscribeAction(data)); // Make the API call only when the email is valid
            } finally {
                setLoading(false);
            }
        } else {
            message.error("Enter a valid email address");
            setLoading(false);
        }
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
    };

    const validateEmail = (inputEmail) => {
        // Basic email validation pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(inputEmail);
    };

    return (
        <section className="section background-color-black">
            <div className="padding-global">
                <div className="container-small">
                    <div className="padding-section-large">
                        <div className="text-align-center">
                            <div className="margin-bottom margin-small">
                                <h2>Stay well-informed about upcoming  opportunities</h2>
                            </div>
                            <div className="margin-top margin-medium">
                                <div className="w-form">
                                    <form>
                                        <div className="form-wrap">
                                            <Input
                                                type="email"
                                                className="form_input w-input"
                                                maxLength={256}
                                                placeholder="Enter your email"
                                                onChange={handleEmailChange}
                                            />
                                            <Button
                                                type="primary"
                                                onClick={(event) => submitemail(event)}
                                                className="button is-inverted  add__me p-4 mx-2"
                                            >
                                                Subscribe
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CourseSubscribe;
