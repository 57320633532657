import React, { useEffect, useState } from 'react'
import './css/publicProfileV2.css'
import './css/mainSidebar.css';
import './css/layout.css';
import './css/home.css';
import './css/BookingMileStone.css';
import './css/antReset.css';
import './css/Services.css';
import './css/payment.css';
import './css/referral.css';
import './css/rewards.css';
import './css/profile.css';
import './css/queries.css';
import './css/availability.css';
import './css/addSlots.css';
import './css/calls.css';
import './css/shareOptions.css';
import { mentorLogo } from '../../assets/images/export-images';
import profilepic from './Image/profilepic.jpg'
import christmas_banner_nav from './Image/christmas_banner_nav.svg'
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
    LuBellRing,
    LuBookOpen,
    LuGift,
    LuHeart,
    LuMail,
    LuUser,
    LuWallet,
    LuZap,
} from "react-icons/lu";
import { HiMenu } from "react-icons/hi";
import { AiOutlineGift } from "react-icons/ai";
import {
    BiBookContent,
    BiCalendar,
    BiEnvelopeOpen,
    BiHomeAlt,
    BiMessageSquareDots,
    BiPhoneCall,
    BiUser,
    BiWalletAlt,
} from "react-icons/bi";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { RxRocket } from "react-icons/rx";
import { FiPhoneCall } from "react-icons/fi";
import { Button, Col, Drawer, Layout, Row } from 'antd';
import ChecklistFloating from './HomeComponent/CheckFloatingStep/ChecklistFloating';
import { Book } from '@mui/icons-material';
const { Content, Sider } = Layout;
const menuItems = [
    {
        title: 'Home',
        link: '/dashboard/home',
        icon: (
            <BiHomeAlt className="menu-logo" />

        ),
    },
    {
        title: 'Bookings',
        link: '/dashboard/calls/video',
        icon: (
            <BiPhoneCall className="menu-logo" />

        ),
    },
    {
        title: 'Communications',
        link: '/dashboard/communications/messages',
        icon: (
            <BiMessageSquareDots className="menu-logo" />

        ),
    },
    // {
    //     title: 'Testimonials',
    //     link: '',
    //     icon: (
    //         <LuHeart className="menu-logo" />

    //     ),

    // },
    {
        title: 'E-books',
        link: '/dashboard/e-books',
        icon: (
            <LuBookOpen className="menu-logo" />
        ),
        marginBottom: true,
    },
    {
        title: 'Calendar',
        link: "/dashboard/availability/configurations",
        icon: (
            <BiCalendar className="menu-logo" />
        ),
    },
    {
        title: 'Services',
        link: "/dashboard/services/video",
        icon: (
            <BiBookContent className="menu-logo" />

        ),
    },
    {
        title: 'Payments',
        link: "/dashboard/payments",
        icon: (
            <BiWalletAlt className="menu-logo" />

        ),
    },
    {
        title: 'Profile',
        link: "/dashboard/profile",
        icon: (
            <BiUser className="menu-logo" />

        ),
        marginBottom: true,

    },
    {
        title: "What's New",
        link: '/dashboard/calls/video/',
        icon: (
            <MdOutlineNotificationsNone className="menu-logo" />

        ),

    },
    {
        title: ' Invite & Earn',
        link: "/dashboard/referral",
        icon: (

            <BiEnvelopeOpen className="menu-logo" />
        ),
    },
    {
        title: 'Rewards',
        link: "/dashboard/rewards",
        icon: (
            <AiOutlineGift className="menu-logo" />
        ),
    },

    // Add more menu items as needed
];
const MobilemenuItems = [
    {
        title: 'Home',
        link: '/dashboard/home',
        icon: <BiHomeAlt fontSize={20} className="bn-icon" />,
    },
    {
        title: 'Bookings',
        link: '/dashboard/calls/video',
        icon: <FiPhoneCall fontSize={20} className="bn-icon" />,
    },
    {
        title: 'Services',
        link: '/dashboard/services/video',
        icon: <BiBookContent fontSize={20} className="bn-icon" />,
    },
    {
        title: 'Calendar',
        link: '/dashboard/availability/configurations',
        icon: <BiCalendar fontSize={20} className="bn-icon" />,
    },
    {
        title: 'More',
        link: '/dashboard/availability/configurations',
        icon: (
            <HiMenu fontSize={22} className="bn-icon" />
        ),
        modal: true
    },
    // Add more menu items as needed
];
export const MentorDashboardComponent = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState(0);
    useEffect(() => {
        const pathname = window.location.pathname;
        menuItems?.forEach((item, index) => {
            if (item?.link === pathname) {
                setSelectedMenuItem(index);
            }
        });

    }, []);

    const [isMenuOpen, setMenuOpen] = useState(false);
    const openMenu = () => {
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };




    const navigate = useNavigate()
    const handleMenuItemClick = (index, item) => {
        setSelectedMenuItem(index);
        navigate(item?.link)
    };

    return (
        <>
            <div id="__next">
                <div style={{ opacity: 1 }}>

                    <Row >

                        {/* <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                            <ChecklistFloating />
                        </Col> */}


                        <div className="ant-col ant-col-24 main-layout ">
                            <div className="ant-layout ant-layout-has-sider sidebar ">
                                <div className="ant-row ">
                                    {/* Mobile View */}
                                    <Col xs={24} md={0}>
                                        <Row className="mobile-dashboard-nav ">

                                            <Row >
                                                <div className="ant-col dashboard-bottom-nav visible ant-col-xs-24 ant-col-md-0 ">
                                                    <Row className="bottom-nav-list ">
                                                        {
                                                            MobilemenuItems?.map((item, index) => (
                                                                <div key={index} className="ant-col  " style={{
                                                                    display: "block",
                                                                    flex: " 0 0 19.833333%",
                                                                    maxWidth: "19.833333% "
                                                                }}>
                                                                    <a className={`bn-link ${index === selectedMenuItem ? 'active' : ''}`}
                                                                        onClick={() => { item?.modal === true ? (openMenu(true)) : (handleMenuItemClick(index, item)) }} >
                                                                        {item?.icon}
                                                                        <span className="ant-typography bn-title ">
                                                                            {item?.title}
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            ))

                                                        }

                                                    </Row>
                                                </div>
                                            </Row>
                                        </Row>
                                    </Col>

                                    {/* Dekstop view */}
                                    <Col xs={0} md={24}>
                                        {/* Desktop View */}
                                        <Sider width={288} theme="light" className="main-sidebar">
                                            <div className="ant-layout-sider-children">
                                                <a className="logo" href="/dashboard/home">
                                                    <img alt="mentorpal Logo" loading="lazy" width={150} height={29} src={mentorLogo} />
                                                </a>
                                                <div className="ant-divider ant-divider-horizontal" role="separator" style={{ margin: '20px 0px 30px' }} />
                                                <ul className="ant-menu ant-menu-root ant-menu-vertical ant-menu-light sidebar-menu">
                                                    {menuItems.map((item, index) => (
                                                        <li key={index} className="ant-menu-item ant-menu-item-only-child menu-item" role="menuitem" tabIndex={-1} data-menu-id={`rc-menu-uuid-46357-4-${item.link}`} style={item.marginBottom ? { marginBottom: 28 } : {}}>
                                                            <span className="ant-menu-title-content">
                                                                <a className={`menu-link ${index === selectedMenuItem ? 'active' : ''}`}
                                                                    onClick={() => handleMenuItemClick(index, item)} >
                                                                    {item.icon}
                                                                    <span className="ant-typography menu-title">{item.title}</span>
                                                                </a>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Sider>
                                    </Col>
                                </div>
                            </div>
                            <div className="ant-layout main-container ">
                                <main style={{ opacity: 1 }}>
                                    <div className="ant-row ant-row-center ant-row-middle ">
                                        <Outlet />
                                    </div>
                                </main>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>

            <Drawer
                open={isMenuOpen}
                onClose={closeMenu}
                placement="bottom"
                width={"50vw"}
                height={"98%"}
                styles={{
                    body: { overflow: "scroll" },
                }}
                className="mobile-drawer"
            // closable={false}
            >
                <DrawerMenu closeMenu={closeMenu} />
            </Drawer>
        </>
    )
}

const DrawerMenu = ({ closeMenu }) => {
    const closeFunc = () => {
        closeMenu()
    }
    const navigate = useNavigate()
    const handleMenuItemClick = (item) => {
        navigate(item?.link)
        closeFunc()
    };

    const TopMenuItems = [
        {
            id: 3,
            title: "Profile",
            icon: <LuUser fontSize={20} className="bn-icon" />,
            showBadge: false,
            link: "/dashboard/profile",
        },
        {
            id: 2,
            title: "Payments",
            icon: <LuWallet fontSize={20} className="bn-icon" />,
            showBadge: false,
            link: "/dashboard/payments",
        },
        {
            id: 1,
            title: "Communications",
            icon: <LuMail fontSize={20} className="bn-icon" />,
            link: "/dashboard/communications/messages",
        },
     
    ];
    const bottomNavData = [
        { icon: <LuBellRing fontSize={20} className="bn-icon" />, title: "What's New", link: "/dashboard/whats-new" },
        { icon: <LuGift fontSize={20} className="bn-icon" />, title: 'Invite & Earn', link: '/dashboard/referral' },
        { icon: <LuZap fontSize={20} className="bn-icon" />, title: 'Rewards', link: '/dashboard/rewards' },
    ];
    return (
        <>

            <div className="ant-space-item">
                <div className="ant-row bottom-nav-list drawer ">
                    {
                        TopMenuItems?.map((item, index) => (

                            <div key={index} className="ant-col ant-col-xs-24 ">
                                <a className="bn-link" onClick={() => handleMenuItemClick(item)}>
                                    <div className="ant-row ant-row-space-between ant-row-middle top-tabs ">
                                        <div className="ant-row ant-row-middle ">
                                            <span className="ant-typography ">
                                                {item?.icon}
                                            </span>
                                            <span className="ant-typography bn-title ">
                                                {item?.title}
                                            </span>
                                        </div>
                                        <div className="ant-col " />
                                    </div>
                                </a>
                            </div>
                        ))
                    }

                </div>
            </div>
            <div className="ant-space-item">
                <div className="ant-row bottom-nav-list drawer">
                    {bottomNavData.map((tab, index) => (
                        <div key={index} className="ant-col ant-col-xs-24">
                            <a className="bottom-bn-link" href={tab.link}>
                                <div className="ant-row ant-row-space-between ant-row-middle bottom-tabs">
                                    <div className="ant-row ant-row-middle">
                                        {tab.icon}
                                        <span className="ant-typography bn-title">{tab.title}</span>
                                    </div>
                                    <div className="ant-col" />
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}