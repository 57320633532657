import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { crossIcon } from "../../../assets/images/export-images";
import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import { createMeetingAction } from "../../../redux/actions/mentor";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import { getConnectionListAction } from "../../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";

const CreateMeetingModule = ({ createMeetingModal, setcreateMeetingModal }) => {
  const dispatch = useDispatch();
  const mentor_id = REACT_USER_ID;
  const [step, setStep] = useState(1);

  const [createMeetingData, setcreateMeetingData] = useState({
    mentee_id: "",
    topic: "",
    timing: "",
    date: "",
    meeting_link: "",
    mentor_id: mentor_id,
  });

  const handleChange = (name, value) => {
    setcreateMeetingData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleNextStep = () => {
    setStep((prevStep) => (prevStep < 5 ? prevStep + 1 : 1));
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => (prevStep > 1 ? prevStep - 1 : 5));
  };
  const isSuccess = useSelector(
    (state) => state.createMeetingReducer?.isSuccess
  );

  const handleSubmit = () => {
    // Handle form submission
    console.log(createMeetingData);
    dispatch(createMeetingAction(createMeetingData));
    if (isSuccess) {
      setTimeout(() => {
        setStep(0);
        setcreateMeetingModal((prevState) => ({
          ...prevState,
          show: false,
        }));
      }, 2000);
    }
  };

  const ConnectionListData = useSelector(
    (state) => state.getConnectionListReducer?.data
  );
  const isLoading = useSelector(
    (state) => state.getConnectionListReducer?.loading
  );
  console.log("ConnectionListData11", ConnectionListData);

  return (
    <>
      {" "}
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <Modal
          size="xl"
          centered
          show={createMeetingModal.show}
          onHide={() =>
            setcreateMeetingModal((prevState) => ({
              ...prevState,
              show: false,
            }))
          }
        >
          <ModalBody>
            <button
              type="button"
              className="cloose"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() =>
                setcreateMeetingModal((prevState) => ({
                  ...prevState,
                  show: false,
                }))
              }
            >
              <div className="closebuttons">
                <span aria-hidden="true">
                  <img src={crossIcon} />
                </span>
              </div>
            </button>
            <div className="MenteeGoal__Modal">
              <div className=" Set___Goal">
                <div className="activation__header">
                  <div className="menteegoal_title text-center">
                    <h3>Create A Meeting</h3>
                  </div>
                </div>
                <div>
                  {step === 1 && (
                    <Step1
                      createMeetingData={createMeetingData}
                      handleChange={handleChange}
                      handleNextStep={handleNextStep}
                      ConnectionListData={ConnectionListData}
                    />
                  )}
                  {step === 2 && (
                    <Step2
                      createMeetingData={createMeetingData}
                      handleChange={handleChange}
                      handleNextStep={handleNextStep}
                      handlePreviousStep={handlePreviousStep}
                    />
                  )}
                  {step === 3 && (
                    <Step3
                      createMeetingData={createMeetingData}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handlePreviousStep={handlePreviousStep}
                    />
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default CreateMeetingModule;
