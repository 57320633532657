import React, { useState } from "react";
import "../cutsom.css";
import { OpenToData, hireTalentFilter } from "./constant";
import MobileFilter from "./mobileFilter";
import filter from "../img/filter.png";
const Filter = () => {
  const [filterModal, setFilterModal] = useState({});

  return (
    <>
      <div className="HireTalents__Aside">
        <header className=" border-bottom">
          <p className="  hire-subpara ">Filters</p>

          <div className=" search-wrapper ">
            <div className=" filter____wrapper  ">
              <div
                className=" align-items-center input-container "
                id="search_box"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </div>
                <input
                  placeholder="Search keywords"
                  title="Search keywords"
                  defaultValue=""
                  name="search"
                  id="search"
                  className="form-control SearchField__Search text-truncate px-0 "
                />
              </div>
              <div className="align-items-center filter__icon me-auto  ">
                <img
                  className="filter____image"
                  onClick={() => {
                    setFilterModal({
                      modal: MobileFilter,
                      show: true,
                    });
                  }}
                  src={filter}
                  alt={"icon"}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="Filters__Wrapper custom-div ">
          <div className=" bJvaep">
            <div className="Filters__OpenToFilters border-bottom ">
              <p
                className="  hire-subtext font-weight-400  grey-2-text"
              >
                Open to
              </p>
              {OpenToData.map((item, index) => (
                <div
                  className="d-flex justify-content-between align-items-center"
                  key={index}
                >
                  <div className="d-flex align-items-center">
                    <div
                      color="#b7308e"
                      radius={4}
                      width="32px"
                      height="32px"
                      className=" open__icon"
                    >
                      {item.icon}
                    </div>
                    <div className="ml-3">
                      <p className="font-weight-700">{item.title}</p>
                      {/* <p className="hire__subtext grey-2-text">
                        {item.subtitle}
                      </p> */}
                    </div>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id={`open-to-toggle-${index}`}
                      className="custom-control-input"
                    />
                    <label
                      title=""
                      htmlFor={`open-to-toggle-${index}`}
                      className="custom-control-label"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div width={1920} className=" Talent___box">
              <div className="d-flex p-3 RecommendedProfilesToggle">
                <div className="d-flex align-items-center me-2 me-sm-4">
                  <p className="grey-1-text font-weight-700 me-1">
                    Recommended talents
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-exclamation-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                  </svg>
                </div>
                <div className=" me-2 custom-control custom-switch ">
                  <input type="checkbox" className="custom-control-input" />
                </div>
              </div>
              {hireTalentFilter?.map((dropdown) => (
                <div className="" key={dropdown.title}>
                  <p className="hire-subtext font-weight-400 mb-8 grey-2-text">
                    {dropdown.title}
                  </p>
                  <div className="form-group">
                    <select
                      className="text-left form-control"
                      style={{
                        height: "50px",
                        width: "100%",
                        borderRadius: "13px",
                      }}
                      required
                    >
                      <option value=""> {`Eg: ${dropdown.title}`} </option>
                      {dropdown.options.map((option) => (
                        <option value={option.value} key={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ))}
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                id="open-to-toggle-0"
                className="custom-control-input"
              />
              <label
                className="form-check-label hire-subtext font-weight-700"
                htmlFor="work_remotely"
              >
                Open to remote work
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                id="open-to-toggle-0"
                className="custom-control-input"
              />
              <label
                className="form-check-label hire-subtext font-weight-700"
                htmlFor="work_relocation"
              >
                Open to relocation
              </label>
            </div>
          </div>
        </div>
      </div>

      {filterModal?.modal && (
        <filterModal.modal
          filterModal={filterModal}
          setFilterModal={setFilterModal}
        />
      )}
    </>
  );
};

export default Filter;
