import { createPaymentLinkConstants, orderConstants } from "../../constants";

export const orderReducer = (state = {}, action) => {
  switch (action.type) {
    case orderConstants.GENERATE_ORDER_ID_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
        isSuccess: "",
      };
    case orderConstants.GENERATE_ORDER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSuccess: true,
        status: action.status,
        error: action.error,
      };
    case orderConstants.GENERATE_ORDER_ID_FAILURE:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const createPaymentLinkReducer = (state = {}, action) => {
  switch (action.type) {
    case createPaymentLinkConstants.CREATE_PAYMENT_LINK_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case createPaymentLinkConstants.CREATE_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSuccess: true,
        status: action.status,
        error: action.error,
      };
    case createPaymentLinkConstants.CREATE_PAYMENT_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    default:
      return state;
  }
};
