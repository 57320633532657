import {
  favoriteMentorsConstants,
  similarMentorsConstants,
  subscribedMentorsConstants,
  createFavoriteMentorsConstants,
  goalSimilarMentorsConstants,
  // removeFavoriteMentorsConstants
} from "../../constants";

export const favoriteMentor = (data) => ({
  type: favoriteMentorsConstants.FAVORITE_MENTOR_REQUEST,
  data,
});
export const createfavoriteMentor = (data) => ({
  type: createFavoriteMentorsConstants.CREATE_FAVORITE_MENTOR_REQUEST,
  data,
});

export const removeFavoriteMentor = (arr, data) => {
  var newlist = arr;
  const objWithIdIndex = newlist.findIndex(
    (mentor) => mentor.favorite_id === data
  );
  newlist.splice(objWithIdIndex, 1);
  return {
    type: favoriteMentorsConstants.REMOVE_FAVORITE_MENTOR,
    data: newlist,
  };
};

export const subscribedMentor = (data) => ({
  type: subscribedMentorsConstants.SUBSCRIBED_MENTOR_REQUEST,
  data,
});

export const similarMentor = (data) => {
  return {
    type: similarMentorsConstants.SIMILAR_MENTOR_REQUEST,
    data,
  };
};
export const goalSimilarMentor = (data) => {
  return {
    type: goalSimilarMentorsConstants.GOAL_SIMILAR_MENTOR_REQUEST,
    data,
  };
};
