import React from "react";
import { useNavigate } from "react-router-dom";

const EbookSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="row">
      {/* <!-- Earnings (Monthly) Card Example --> */}
      <div className="col-xl-12 col-md-12 mb-4">
        <div className="card card-cls ebook-thanks-card">
          <div className="card-body">
            <h3>Congratulations</h3>
            <h6>
            Your book will be reviewed and published shortly. Thank you for your patience.
            </h6>
            <div className="publish-sec">
              <div className="singup-uppp">
                <a className="btn" href="publish-ebooks">
                  Publish More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EbookSuccess;
