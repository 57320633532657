import React from "react";
import crossIcon from "../../../assets/images/cross.png";
import timeImg from "../../../assets/images/time.png";
import calendarImg from "../../../assets/images/celender.png";
import DashboardHeader from "../DashboardHeader";

const SessionSchedule = () => {
    return (
      <>
        {/* <DashboardHeader /> */}
        <div className="heading">
          <h1>Let's publish this session to the community.</h1>
        </div>
        <h4 className="sub-heading">Session detail and schedule.</h4>
        <div className="container">
          <div className="panel panel-primary">
            <div className="panel-body">
              <div className="row"></div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      id="date"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Time</label>
                    <div className="input-group date" id="datetimepicker1">
                      <input type="time" className="form-control" />
                      <span className="input-group-addon">
                        <span className="glyphicon glyphicon-calendar"></span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="panel-heading">
                  In your timezone.People will see in their timezone.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="signup-row">
          <div className="signup-leftcol">
            <div className="sign-form-cls">
              <div className="form-group">
                <label className="control-label">
                  Max attende in this session
                </label>
                <input
                  type="text"
                  name=""
                  placeholder="Full Name"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Select a topic of expertise
                </label>
                <select className="form-control" name="category" id="category">
                  <option value="Computer Application">
                    Digital Marketing
                  </option>
                  <option value="Computer Application">
                    Digital Marketing
                  </option>
                  <option value="Computer Application">
                    Digital Marketing
                  </option>
                  <option value="Computer Application">
                    Digital Marketing
                  </option>
                </select>
              </div>

              <div className="form-group">
                <label className="control-label">
                  Select a session interest area
                </label>
                <select className="form-control" name="category">
                  <option value="Computer Application">Mentorship</option>
                  <option value="Computer Application">Leadership</option>
                  <option value="Computer Application">Job search</option>
                  <option value="Computer Application">Carrer Advice</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="singgup-upp">
          <button className="btn " style={{ width: "124px" }}>
            Create Session
          </button>
        </div>

        <div className="get-started-cls">
          <button
            style={{
              width: "124px",
              "margin-right": "-280px",
              "margin-top": "-147px",
            }}
            type="button"
            className="btn btn-primary"
          >
            Back
          </button>
        </div>
      </>
    );
};

export default SessionSchedule;
