import React, { useState } from 'react';
import { ColorPicker, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd';
import './SetPage.Custom.css'
import ImgCrop from 'antd-img-crop';
const SetUpYourPage = () => {
    const [open, setOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList.slice(-1)); // Keep only the last uploaded file
    };

    const customRequest = ({ file, onSuccess }) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            onSuccess("ok");
        }, 1000); // Simulating an asynchronous request
    };

    const onPreview = async () => {
        // Add logic for image preview
        // This function is called when you click on the preview button
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <>
            <Button type="primary" onClick={() => setOpen(true)}>
                Open Modal of 1000px width
            </Button>
            <Modal
                centered
                open={open}
                width={1000}
                footer={null}
                style={{padding:'0'}}
            >
                <div className="ant-row ">
                    <div
                        className="ant-row profile-modal-info "
                        style={{ backgroundColor: "rgb(213, 83, 77)" }}
                    >
                        <div className="ant-col ant-col-24 modal-image ">
                            <div className="ant-col nudge-profile ">
                                <div className="user-profile">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 500 500"
                                        width={500}
                                        height={500}
                                        preserveAspectRatio="xMidYMid meet"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            transform: "translate3d(0px, 0px, 0px)",
                                            contentVisibility: "visible"
                                        }}
                                    >
                                        <defs>
                                            <clipPath id="__lottie_element_6137">
                                                <rect width={500} height={500} x={0} y={0} />
                                            </clipPath>
                                            <g id="__lottie_element_6138">
                                                <g
                                                    style={{ display: "block" }}
                                                    transform="matrix(1,0,0,1,250,250)"
                                                    opacity={1}
                                                >
                                                    <g opacity={1} transform="matrix(1,0,0,1,0,0)">
                                                        <path
                                                            fill="rgb(237,221,214)"
                                                            fillOpacity={1}
                                                            d=" M250,0.027000000700354576 C250,32.25199890136719 243.88699340820312,63.08300018310547 232.78799438476562,91.39399719238281 C224.15499877929688,113.48500061035156 212.41299438476562,133.9669952392578 198.04299926757812,152.46600341796875 C195.5229949951172,155.7899932861328 192.89500427246094,159.00799560546875 190.10699462890625,162.1719970703125 C188.177001953125,164.47799682617188 186.19400024414062,166.72900390625 184.156005859375,168.9810028076172 C170.0540008544922,184.3699951171875 154.0749969482422,198.04299926757812 136.48800659179688,209.46400451660156 C131.12600708007812,212.94900512695312 125.65699768066406,216.2740020751953 120.0270004272461,219.3300018310547 C84.37000274658203,238.9010009765625 43.512001037597656,250 -0.027000000700354576,250 C-51.18000030517578,250 -98.68599700927734,234.61099243164062 -138.31100463867188,208.28399658203125 C-139.3300018310547,207.58700561523438 -140.34800720214844,206.88999938964844 -141.36700439453125,206.13900756835938 C-150.48199462890625,199.97300720214844 -159.16900634765625,193.16299438476562 -167.31900024414062,185.7100067138672 C-168.2310028076172,184.906005859375 -169.14199829101562,184.1020050048828 -170,183.24400329589844 C-173.7530059814453,179.81199645996094 -177.3459930419922,176.2729949951172 -180.83099365234375,172.6269989013672 C-182.60000610351562,170.75 -184.3699951171875,168.87399291992188 -186.08599853515625,166.94400024414062 C-186.3000030517578,166.67599487304688 -186.56900024414062,166.40699768066406 -186.7830047607422,166.13900756835938 C-226.08599853515625,122.01000213623047 -250,63.83399963378906 -250,0.027000000700354576 C-250,-138.04299926757812 -138.04299926757812,-250 -0.027000000700354576,-250 C138.04299926757812,-250 250,-138.04299926757812 250,0.027000000700354576z"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                            <clipPath id="__lottie_element_6142">
                                                <path d="M0,0 L500,0 L500,500 L0,500z" />
                                            </clipPath>
                                            <mask id="__lottie_element_6138_1" mask-type="alpha">
                                                <use xlinkHref="#__lottie_element_6138" />
                                            </mask>
                                        </defs>
                                        <g clipPath="url(#__lottie_element_6137)">
                                            <g
                                                style={{ display: "block" }}
                                                transform="matrix(1,0,0,1,250,250)"
                                                opacity={1}
                                            >
                                                <g opacity={1} transform="matrix(1,0,0,1,0,0)">
                                                    <path
                                                        fill="rgb(237,221,214)"
                                                        fillOpacity={1}
                                                        d=" M250,0.027000000700354576 C250,32.25199890136719 243.88699340820312,63.08300018310547 232.78799438476562,91.39399719238281 C224.15499877929688,113.48500061035156 212.41299438476562,133.9669952392578 198.04299926757812,152.46600341796875 C195.5229949951172,155.7899932861328 192.89500427246094,159.00799560546875 190.10699462890625,162.1719970703125 C188.177001953125,164.47799682617188 186.19400024414062,166.72900390625 184.156005859375,168.9810028076172 C170.0540008544922,184.3699951171875 154.0749969482422,198.04299926757812 136.48800659179688,209.46400451660156 C131.12600708007812,212.94900512695312 125.65699768066406,216.2740020751953 120.0270004272461,219.3300018310547 C84.37000274658203,238.9010009765625 43.512001037597656,250 -0.027000000700354576,250 C-51.18000030517578,250 -98.68599700927734,234.61099243164062 -138.31100463867188,208.28399658203125 C-139.3300018310547,207.58700561523438 -140.34800720214844,206.88999938964844 -141.36700439453125,206.13900756835938 C-150.48199462890625,199.97300720214844 -159.16900634765625,193.16299438476562 -167.31900024414062,185.7100067138672 C-168.2310028076172,184.906005859375 -169.14199829101562,184.1020050048828 -170,183.24400329589844 C-173.7530059814453,179.81199645996094 -177.3459930419922,176.2729949951172 -180.83099365234375,172.6269989013672 C-182.60000610351562,170.75 -184.3699951171875,168.87399291992188 -186.08599853515625,166.94400024414062 C-186.3000030517578,166.67599487304688 -186.56900024414062,166.40699768066406 -186.7830047607422,166.13900756835938 C-226.08599853515625,122.01000213623047 -250,63.83399963378906 -250,0.027000000700354576 C-250,-138.04299926757812 -138.04299926757812,-250 -0.027000000700354576,-250 C138.04299926757812,-250 250,-138.04299926757812 250,0.027000000700354576z"
                                                    />
                                                </g>
                                            </g>
                                            <g
                                                mask="url(#__lottie_element_6138_1)"
                                                style={{ display: "block" }}
                                            >
                                                <g
                                                    clipPath="url(#__lottie_element_6142)"
                                                    transform="matrix(1,0,0,1,0,0)"
                                                    opacity={1}
                                                >
                                                    <g
                                                        style={{ display: "block" }}
                                                        transform="matrix(1,0,0,1,-0.0000025634765279392013,-0.000012695312761934474)"
                                                        opacity={1}
                                                    >
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,251.66200256347656,389.1929931640625)"
                                                        >
                                                            <path
                                                                fill="rgb(247,115,79)"
                                                                fillOpacity={1}
                                                                d=" M286.4930114746094,107.78800201416016 C272.3909912109375,123.177001953125 214.41299438476562,132.85000610351562 196.8260040283203,144.27099609375 C191.46400451660156,147.75599670410156 157.9949951171875,161.0800018310547 152.36500549316406,164.13600158691406 C116.70800018310547,183.70700073242188 66.8499984741211,164.8070068359375 23.31100082397461,164.8070068359375 C-27.841999053955078,164.8070068359375 -79.3479995727539,183.41799926757812 -118.9729995727539,157.09100341796875 C-119.99199676513672,156.3939971923828 -137.01100158691406,160.69700622558594 -138.02999877929688,159.9459991455078 C-147.14500427246094,153.77999877929688 -178.83099365234375,171.97000122070312 -186.9810028076172,164.51699829101562 C-187.89300537109375,163.71299743652344 -214.8040008544922,170.90899658203125 -215.66200256347656,170.05099487304688 C-219.4149932861328,166.61900329589844 -269.00799560546875,129.0800018310547 -272.4930114746094,125.43399810791016 C-263.0559997558594,96.96199798583984 -167.66299438476562,-25.194000244140625 -142.98699951171875,-57.61199951171875 C-121.25800323486328,-86.16000366210938 -83.2979965209961,-107.26300048828125 -47.534000396728516,-109.94400024414062 C-1.9579999446868896,-113.26799774169922 53.96799850463867,-106.45899963378906 94.18199920654297,-98.52300262451172 C110.75,-95.25199890136719 137.58099365234375,-84.49700164794922 151.5229949951172,-69.76200103759766 C182.33799743652344,-37.194000244140625 273.3030090332031,74.43699645996094 286.4930114746094,107.78800201416016z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,245.427001953125,269.4469909667969)"
                                                        >
                                                            <path
                                                                fill="rgb(244,146,130)"
                                                                fillOpacity={1}
                                                                d=" M42.702999114990234,-40.27000045776367 C42.702999114990234,-40.27000045776367 38.362998962402344,20.290000915527344 38.362998962402344,20.290000915527344 C38.362998962402344,20.290000915527344 27.43400001525879,37.37200164794922 -7.547999858856201,39.902000427246094 C-33.606998443603516,41.7869987487793 -42.702999114990234,35.819000244140625 -42.702999114990234,35.819000244140625 C-42.702999114990234,35.819000244140625 -38.803001403808594,5.5879998207092285 -38.803001403808594,5.5879998207092285 C-38.803001403808594,5.5879998207092285 -34.96200180053711,-23.892000198364258 -34.96200180053711,-23.892000198364258 C-34.96200180053711,-23.892000198364258 28.283000946044922,-37.21500015258789 28.283000946044922,-37.21500015258789 C28.283000946044922,-37.21500015258789 42.702999114990234,-40.27000045776367 42.702999114990234,-40.27000045776367z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,240.16600036621094,253.63299560546875)"
                                                        >
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M33.542999267578125,-21.402000427246094 C33.37699890136719,-19.77199935913086 32.7400016784668,-17.85099983215332 31.7549991607666,-15.652000427246094 C18.118999481201172,14.07699966430664 -18.492000579833984,20.18899917602539 -33.542999267578125,21.402000427246094 C-33.542999267578125,21.402000427246094 -29.701000213623047,-8.079000473022461 -29.701000213623047,-8.079000473022461 C-29.701000213623047,-8.079000473022461 33.542999267578125,-21.402000427246094 33.542999267578125,-21.402000427246094z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,243.25599670410156,299.77301025390625)"
                                                        >
                                                            <path
                                                                fill="rgb(70,70,127)"
                                                                fillOpacity={1}
                                                                d=" M-17.66699981689453,11.586000442504883 C-30.972999572753906,11.586000442504883 -40.314998626708984,9.428999900817871 -41.15800094604492,9.225000381469727 C-42.597999572753906,8.880000114440918 -43.481998443603516,7.434000015258789 -43.141998291015625,5.99399995803833 C-42.801998138427734,4.553999900817871 -41.36199951171875,3.674999952316284 -39.9109992980957,4.010000228881836 C-39.465999603271484,4.114999771118164 4.749000072479248,14.255999565124512 38.93600082397461,-11.060999870300293 C40.130001068115234,-11.940999984741211 41.81100082397461,-11.689000129699707 42.685001373291016,-10.505999565124512 C43.56999969482422,-9.312000274658203 43.319000244140625,-7.636000156402588 42.130001068115234,-6.756999969482422 C21.959999084472656,8.182000160217285 -0.968999981880188,11.586000442504883 -17.66699981689453,11.586000442504883z"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        style={{ display: "block" }}
                                                        transform="matrix(1,0.00019371183589100838,-0.00019371183589100838,1,0.04543811082839966,-0.04536181688308716)"
                                                        opacity={1}
                                                    >
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,321.5220031738281,69.31199645996094)"
                                                        >
                                                            <path
                                                                fill="rgb(119,114,211)"
                                                                fillOpacity={1}
                                                                d=" M-9.612000465393066,-0.03700000047683716 C-9.612000465393066,-0.03700000047683716 -11.826000213623047,-14.163000106811523 1.6579999923706055,-8.383999824523926 C15.142000198364258,-2.6050000190734863 9.36299991607666,11.520999908447266 1.6579999923706055,9.593999862670898 C-6.046999931335449,7.668000221252441 -9.612000465393066,-0.03700000047683716 -9.612000465393066,-0.03700000047683716z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,307.12799072265625,166.9409942626953)"
                                                        >
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M30.81999969482422,-8.496000289916992 C30.81999969482422,-8.496000289916992 28.70599937438965,29.597000122070312 11.557000160217285,31.347000122070312 C-5.5920000076293945,33.09600067138672 -30.81999969482422,11.425999641418457 -30.81999969482422,11.425999641418457 C-30.81999969482422,11.425999641418457 -18.999000549316406,-31.447999954223633 -18.999000549316406,-31.447999954223633 C-18.999000549316406,-31.447999954223633 30.81999969482422,-8.496000289916992 30.81999969482422,-8.496000289916992z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,191.2259979248047,122.68299865722656)"
                                                        >
                                                            <path
                                                                fill="rgb(92,92,198)"
                                                                fillOpacity={1}
                                                                d=" M15.843000411987305,24.926000595092773 C15.843000411987305,24.926000595092773 -40.124000549316406,6.947999954223633 -48.47100067138672,-16.80900001525879 C-56.81800079345703,-40.566001892089844 48.939998626708984,-4.610000133514404 48.939998626708984,-4.610000133514404 C48.939998626708984,-4.610000133514404 15.843000411987305,24.926000595092773 15.843000411987305,24.926000595092773z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,251.3070068359375,172.92100524902344)"
                                                        >
                                                            <path
                                                                fill="rgb(244,146,130)"
                                                                fillOpacity={1}
                                                                d=" M-52.95600128173828,-63.6870002746582 C-52.95600128173828,-63.6870002746582 -84.98100280761719,10.95300006866455 -72.98899841308594,52.6879997253418 C-60.99700164794922,94.4229965209961 -22.945999145507812,87.58999633789062 -0.20600000023841858,79.35700225830078 C22.533000946044922,71.12300109863281 33.020999908447266,57.07500076293945 33.020999908447266,57.07500076293945 C33.020999908447266,57.07500076293945 54.36000061035156,64.15299987792969 68.47000122070312,44.26100158691406 C82.58000183105469,24.368000030517578 73.66100311279297,1.6890000104904175 61.470001220703125,-3.440999984741211 C49.27899932861328,-8.571000099182129 34.87900161743164,0.30300000309944153 34.87900161743164,0.30300000309944153 C34.87900161743164,0.30300000309944153 46.06700134277344,-21.400999069213867 46.06700134277344,-21.400999069213867 C46.06700134277344,-21.400999069213867 -2.3310000896453857,-131.0050048828125 -52.95600128173828,-63.6870002746582z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,301.6759948730469,193.56199645996094)"
                                                        >
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M-13.545999526977539,12.244000434875488 C-14.383999824523926,12.244000434875488 -15.217000007629395,11.85099983215332 -15.734999656677246,11.107999801635742 C-16.58799934387207,9.89799976348877 -16.301000595092773,8.229000091552734 -15.090999603271484,7.375 C-15.090999603271484,7.375 12.00100040435791,-11.748000144958496 12.00100040435791,-11.748000144958496 C13.194999694824219,-12.607000350952148 14.88700008392334,-12.324000358581543 15.734999656677246,-11.104000091552734 C16.589000701904297,-9.894000053405762 16.301000595092773,-8.225000381469727 15.090999603271484,-7.370999813079834 C15.090999603271484,-7.370999813079834 -12.001999855041504,11.751999855041504 -12.001999855041504,11.751999855041504 C-12.468000411987305,12.086999893188477 -13.01200008392334,12.244000434875488 -13.545999526977539,12.244000434875488z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,303.82598876953125,200.6490020751953)"
                                                        >
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M8.840999603271484,5.041999816894531 C8.616000175476074,5.041999816894531 8.380999565124512,5.010000228881836 8.149999618530273,4.953000068664551 C8.149999618530273,4.953000068664551 -9.531999588012695,0.2290000021457672 -9.531999588012695,0.2290000021457672 C-10.961000442504883,-0.15299999713897705 -11.8149995803833,-1.625 -11.432999610900879,-3.053999900817871 C-11.050999641418457,-4.478000164031982 -9.600000381469727,-5.326000213623047 -8.149999618530273,-4.953999996185303 C-8.149999618530273,-4.953999996185303 9.531999588012695,-0.23100000619888306 9.531999588012695,-0.23100000619888306 C10.961000442504883,0.1509999930858612 11.8149995803833,1.6230000257492065 11.432999610900879,3.052000045776367 C11.11400032043457,4.250999927520752 10.029999732971191,5.041999816894531 8.840999603271484,5.041999816894531z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,200.92599487304688,183.48199462890625)"
                                                        >
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M-0.23100000619888306,18.235000610351562 C-1.4140000343322754,18.235000610351562 -2.497999906539917,17.444000244140625 -2.818000078201294,16.25 C-7.074999809265137,0.4050000011920929 -1.100000023841858,-15.817000389099121 -0.8429999947547913,-16.496999740600586 C-0.32499998807907104,-17.885000228881836 1.225000023841858,-18.58099937438965 2.6019999980926514,-18.062999725341797 C3.990000009536743,-17.545000076293945 4.690999984741211,-16 4.172999858856201,-14.618000030517578 C4.119999885559082,-14.465999603271484 -1.440000057220459,0.7080000042915344 2.3559999465942383,14.857000350952148 C2.74399995803833,16.285999298095703 1.8949999809265137,17.757999420166016 0.4659999907016754,18.139999389648438 C0.23000000417232513,18.202999114990234 0,18.235000610351562 -0.23100000619888306,18.235000610351562z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,192.1540069580078,158.89999389648438)"
                                                        >
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M-1.9320000410079956,9.519000053405762 C-2.1730000972747803,9.519000053405762 -2.4189999103546143,9.48799991607666 -2.6600000858306885,9.420000076293945 C-4.084000110626221,9.017000198364258 -4.916999816894531,7.533999919891357 -4.513999938964844,6.110000133514404 C-4.513999938964844,6.110000133514404 -0.6489999890327454,-7.560999870300293 -0.6489999890327454,-7.560999870300293 C-0.2460000067949295,-8.989999771118164 1.246999979019165,-9.824000358581543 2.6600000858306885,-9.414999961853027 C4.085000038146973,-9.01200008392334 4.916999816894531,-7.531000137329102 4.513999938964844,-6.105999946594238 C4.513999938964844,-6.105999946594238 0.6489999890327454,7.565999984741211 0.6489999890327454,7.565999984741211 C0.3140000104904175,8.74899959564209 -0.7590000033378601,9.519000053405762 -1.9320000410079956,9.519000053405762z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,236.00100708007812,160.10699462890625)"
                                                        >
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M-1.9299999475479126,9.517000198364258 C-2.1710000038146973,9.517000198364258 -2.4159998893737793,9.486000061035156 -2.6570000648498535,9.418000221252441 C-4.080999851226807,9.015000343322754 -4.914000034332275,7.5320000648498535 -4.511000156402588,6.107999801635742 C-4.511000156402588,6.107999801635742 -0.6520000100135803,-7.564000129699707 -0.6520000100135803,-7.564000129699707 C-0.24899999797344208,-8.98799991607666 1.2430000305175781,-9.8149995803833 2.6570000648498535,-9.416999816894531 C4.080999851226807,-9.013999938964844 4.914000034332275,-7.5320000648498535 4.511000156402588,-6.107999801635742 C4.511000156402588,-6.107999801635742 0.6520000100135803,7.564000129699707 0.6520000100135803,7.564000129699707 C0.31700000166893005,8.746999740600586 -0.7570000290870667,9.517000198364258 -1.9299999475479126,9.517000198364258z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,252.9980010986328,126.63400268554688)"
                                                        >
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M-58.91299819946289,-6.560999870300293 C-58.91299819946289,-6.560999870300293 -54.492000579833984,-12.527000427246094 -39.584999084472656,-12.496000289916992 C-24.67799949645996,-12.46500015258789 -16.06800079345703,-1.8899999856948853 -16.06800079345703,-1.8899999856948853 C-16.06800079345703,-1.8899999856948853 -14.817999839782715,25.132999420166016 5.331999778747559,38.64699935913086 C25.482999801635742,52.1609992980957 33.18899917602539,46.590999603271484 33.18899917602539,46.590999603271484 C33.18899917602539,46.590999603271484 47.9370002746582,41.48099899291992 47.9370002746582,41.48099899291992 C47.9370002746582,41.48099899291992 58.91299819946289,20.094999313354492 58.91299819946289,20.094999313354492 C58.91299819946289,20.094999313354492 16.788999557495117,-26.1200008392334 16.788999557495117,-26.1200008392334 C16.788999557495117,-26.1200008392334 -33.834999084472656,-47.72800064086914 -33.834999084472656,-47.72800064086914 C-33.834999084472656,-47.72800064086914 -58.91299819946289,-19.841999053955078 -58.91299819946289,-19.841999053955078 C-58.91299819946289,-19.841999053955078 -58.91299819946289,-6.560999870300293 -58.91299819946289,-6.560999870300293z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,267.06298828125,109.53900146484375)"
                                                        >
                                                            <path
                                                                fill="rgb(119,114,211)"
                                                                fillOpacity={1}
                                                                d=" M-74.90899658203125,-5.931000232696533 C-74.90899658203125,-5.931000232696533 -53.792999267578125,-65.947998046875 21.066999435424805,-53.10599899291992 C95.927001953125,-40.263999938964844 70.88500213623047,54.90800094604492 70.88500213623047,54.90800094604492 C70.88500213623047,54.90800094604492 36.76300048828125,43.82899856567383 36.76300048828125,43.82899856567383 C36.76300048828125,43.82899856567383 -74.90899658203125,-5.931000232696533 -74.90899658203125,-5.931000232696533z"
                                                            />
                                                        </g>
                                                        <g
                                                            opacity={1}
                                                            transform="matrix(1,0,0,1,239.0330047607422,127.16600036621094)"
                                                        >
                                                            <path
                                                                fill="rgb(92,92,198)"
                                                                fillOpacity={1}
                                                                d=" M-68.65499877929688,-27.79199981689453 C-68.65499877929688,-27.79199981689453 -42.446998596191406,-26.834999084472656 25.076000213623047,4.427000045776367 C48.35100173950195,15.20300006866455 68.65499877929688,27.79199981689453 68.65499877929688,27.79199981689453 C68.65499877929688,27.79199981689453 -9.069999694824219,-4.853000164031982 -39.28300094604492,-4.482999801635742 C-50.88100051879883,-4.341000080108643 -58.39500045776367,0 -58.39500045776367,0 C-58.39500045776367,0 -68.65499877929688,-27.79199981689453 -68.65499877929688,-27.79199981689453z"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        style={{ display: "block" }}
                                                        transform="matrix(0.9982253313064575,0.05954967066645622,-0.05954967066645622,0.9982253313064575,238.48204040527344,139.92086791992188)"
                                                        opacity={1}
                                                    >
                                                        <g opacity={1} transform="matrix(1,0,0,1,0,0)">
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M9.61400032043457,5.859000205993652 C8.807999610900879,5.859000205993652 8.006999969482422,5.493000030517578 7.478000164031982,4.796000003814697 C5.1479997634887695,1.7170000076293945 2.5399999618530273,-0.03200000151991844 -0.26600000262260437,-0.414000004529953 C-4.454999923706055,-0.953000009059906 -7.938000202178955,1.6649999618530273 -7.973999977111816,1.6859999895095825 C-9.13599967956543,2.5810000896453857 -10.822999954223633,2.381999969482422 -11.729000091552734,1.2089999914169312 C-12.635000228881836,0.04699999839067459 -12.4350004196167,-1.6230000257492065 -11.27299976348877,-2.5350000858306885 C-11.062999725341797,-2.7019999027252197 -6.026000022888184,-6.5980000495910645 0.367000013589859,-5.734000205993652 C4.676000118255615,-5.184000015258789 8.503999710083008,-2.7279999256134033 11.75100040435791,1.559999942779541 C12.645999908447266,2.73799991607666 12.414999961853027,4.419000148773193 11.232000350952148,5.314000129699707 C10.75,5.681000232696533 10.180000305175781,5.859000205993652 9.61400032043457,5.859000205993652z"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        style={{ display: "block" }}
                                                        transform="matrix(1,0.00019371183589100838,-0.00019371183589100838,1,224.26158142089844,210.92208862304688)"
                                                        opacity={1}
                                                    >
                                                        <g opacity={1} transform="matrix(1,0,0,1,0,0)">
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M-8.482000350952148,8.166999816894531 C-9.4399995803833,8.166999816894531 -10.053000450134277,8.109999656677246 -10.168000221252441,8.093999862670898 C-11.640000343322754,7.941999912261963 -12.70199966430664,6.627999782562256 -12.550000190734863,5.1620001792907715 C-12.402999877929688,3.690999984741211 -11.067999839782715,2.6010000705718994 -9.618000030517578,2.763000011444092 C-9.152000427246094,2.7890000343322754 2.4509999752044678,3.7950000762939453 7.461999893188477,-6.651000022888184 C8.111000061035156,-7.986999988555908 9.708999633789062,-8.53600025177002 11.043999671936035,-7.9079999923706055 C12.378999710083008,-7.263999938964844 12.9399995803833,-5.6620001792907715 12.300999641418457,-4.326000213623047 C6.849999904632568,7.020999908447266 -4.241000175476074,8.166999816894531 -8.482000350952148,8.166999816894531z"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        style={{ display: "block" }}
                                                        transform="matrix(0.9982253313064575,0.05954967066645622,-0.05954967066645622,0.9982253313064575,195.57591247558594,136.04080200195312)"
                                                        opacity={1}
                                                    >
                                                        <g opacity={1} transform="matrix(1,0,0,1,0,0)">
                                                            <path
                                                                fill="rgb(0,0,0)"
                                                                fillOpacity={1}
                                                                d=" M-5.359000205993652,4.2170000076293945 C-6.138999938964844,4.2170000076293945 -6.908999919891357,3.875999927520752 -7.442999839782715,3.2269999980926514 C-8.375,2.075000047683716 -8.197999954223633,0.3889999985694885 -7.051000118255615,-0.5479999780654907 C-3.437999963760376,-3.4700000286102295 -0.07599999755620956,-4.6479997634887695 2.8929998874664307,-4.077000141143799 C6.124000072479248,-3.443000078201294 7.558000087738037,-0.9879999756813049 7.710000038146973,-0.7110000252723694 C8.42199993133545,0.5879999995231628 7.946000099182129,2.2219998836517334 6.6529998779296875,2.934000015258789 C5.369999885559082,3.625 3.7679998874664307,3.196000099182129 3.0450000762939453,1.934000015258789 C2.9820001125335693,1.840000033378601 2.5899999141693115,1.2999999523162842 1.7879999876022339,1.1749999523162842 C1.0290000438690186,1.0290000438690186 -0.6990000009536743,1.2059999704360962 -3.6679999828338623,3.619999885559082 C-4.165999889373779,4.0229997634887695 -4.76200008392334,4.2170000076293945 -5.359000205993652,4.2170000076293945z"
                                                            />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <span className="ant-typography user-desc " />
                            </div>
                        </div>
                    </div>
                    <div className="ant-col ant-col-11 ">
                        <div className="ant-typography info-box ">
                            <div className="ant-typography info-content ">
                                <div className="ant-typography upper-content ">
                                    <span className="ant-typography title ">
                                        Set up your page
                                    </span>
                                </div>
                                <div className="ant-col profile-form ">
                                    <form className="ant-form ant-form-vertical ">
                                        <div className="ant-typography profile-img-container ">
                                            <span className="ant-upload-wrapper ">
                                                <div className="ant-upload ant-upload-select">
                                                    <span
                                                        tabIndex={0}
                                                        className="ant-upload"
                                                        role="button"
                                                    >
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            style={{ display: "none" }}
                                                        />
                                                        <div
                                                            className="ant-space  ant-space-horizontal ant-space-align-center"
                                                            style={{ gap: 14 }}
                                                        >
                                                            <div className="ant-space-item">
                                                           
                                                                <ImgCrop rotate>
                                                                    <Upload
                                                                        customRequest={customRequest}
                                                                        listType="picture-circle"
                                                                        fileList={fileList}
                                                                        onChange={onChange}
                                                                        onPreview={onPreview}
                                                                        beforeUpload={() => fileList.length === 0} // Allow only one file in the list
                                                                        showUploadList={{
                                                                            showRemoveIcon: true,
                                                                            showPreviewIcon: true,
                                                                        }}
                                                                    >
                                                                        {fileList.length === 0 ? uploadButton : null}
                                                                    </Upload>
                                                                </ImgCrop>
                                                            </div>
                                                            <div className="ant-space-item">
                                                                <div className="ant-space  ant-space-vertical ant-space-gap-row-small ant-space-gap-col-small">
                                                                    <div className="ant-space-item">
                                                                        <span className="ant-typography ">
                                                                            <strong>Add a profile picture</strong>
                                                                        </span>
                                                                    </div>
                                                                    <div className="ant-space-item">
                                                                        <span className="ant-typography img-size ">
                                                                            Less than 5mb
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                        <div className="ant-form-item title-fields name-field ">
                                            <div className="ant-form-item-row ">
                                                <div className="ant-col ant-form-item-label ">
                                                    <label
                                                        htmlFor="description"
                                                        className="ant-form-item-required"
                                                        title="Your topmate intro (Required)"
                                                    >
                                                        Your mentorpal intro (Required)
                                                    </label>
                                                </div>
                                                <div className="ant-col ant-form-item-control ">
                                                    <div className="ant-form-item-control-input">
                                                        <div className="ant-form-item-control-input-content">
                                                            <textarea
                                                                placeholder="This is the first thing people will see"
                                                                id="description"
                                                                aria-required="true"
                                                                className="ant-input "
                                                                style={{
                                                                    overflowY: "hidden",
                                                                    resize: "none",
                                                                    height: 38,
                                                                    minHeight: 38,
                                                                    maxHeight: 38
                                                                }}
                                                                defaultValue={""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ant-row ant-row-space-between ant-row-middle theme-container ">
                                            <div
                                                className="ant-row ant-row-middle "
                                                style={{ marginLeft: "-6px", marginRight: "-6px" }}
                                            >
                                                <div
                                                    className="ant-col "
                                                    style={{ paddingLeft: 6, paddingRight: 6 }}
                                                >
                                                    <div className="ant-typography color-icon ">
                                                        <svg
                                                            stroke="currentColor"
                                                            fill="currentColor"
                                                            strokeWidth={0}
                                                            viewBox="0 0 512 512"
                                                            height={16}
                                                            width={16}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M441.8 120.1l-49.9-49.9c-8.3-8.3-21.8-8.3-30.1 0l-66.6 66.6L254.1 96 224 126.1l30.3 30.3L64 346.7V448h101.3l190.3-190.3 30.3 30.3 30.1-30.1-41-41 66.6-66.6c8.5-8.4 8.5-21.8.2-30.2zM147.6 405.4l-41-41 171.9-171.9 41 41-171.9 171.9z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div
                                                    className="ant-col "
                                                    style={{ paddingLeft: 6, paddingRight: 6 }}
                                                >
                                                    <div className="ant-typography field-title ">
                                                        Select your theme
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ant-col ">
                                                {/* <div className="ant-color-picker-trigger ">
                                                    <div className="ant-color-picker-color-block">
                                                        <div
                                                            className="ant-color-picker-color-block-inner"
                                                            style={{ background: "rgb(213, 83, 77)" }}
                                                        />
                                                    </div>
                                                </div> */}
                                                <ColorPicker size="large" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="ant-typography btm-btns ">
                                    <div
                                        className="ant-divider  ant-divider-horizontal"
                                        role="separator"
                                        style={{ margin: 12 }}
                                    />
                                    <div className="ant-row ant-row-space-between btn-container ">
                                        <button
                                            type="button"
                                            className="ant-btn  ant-btn-text"
                                        >
                                            <span className="ant-typography ">
                                                <u>Skip for now</u>
                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            className="ant-btn  ant-btn-default btn-dark"
                                        >
                                            <span>Save</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default SetUpYourPage;