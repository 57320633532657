import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { REACT_USER_ID } from '../../../auth/tokenProvider';
import { getAllMentorSessionRequest } from '../../../../redux/actions/mentor';
import docempty from '../../Image/docempty.svg';
import CallCards from './CallCards';
import { TailSpin } from 'react-loader-spinner';


const Calls = () => {
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabKey, setTabKey] = useState('upcoming');
  const [CallsData, setCallsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const tabs = [
    { label: 'Upcoming', key: 'upcoming' },
    { label: 'Completed', key: 'completed' },
  ];

  const handleTabClick = (index, tabKey) => {
    setSelectedTabIndex(index);
    setTabKey(tabKey)
  };
  const dispatch = useDispatch();
  const user_id = REACT_USER_ID;
  useEffect(() => {
    dispatch(getAllMentorSessionRequest(user_id));
  }, []);


  const dateFormater = (date) => {
    const monthArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let newDate = new Date(date);
    let formatedDate =
      ("0" + newDate.getDate()).slice(-2) +
      " " +
      monthArray[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      ("0" + newDate.getHours()).slice(-2) +
      ":" +
      ("0" + newDate.getMinutes()).slice(-2);
    return formatedDate;
  };

  const mentorSessionReducerData = useSelector(
    (state) => state?.getAllMentorSessionReducer
  );

  const CallsFilter = (meetingTime) => {
    const currentDateTime = new Date();
    const meetingDateTime = new Date(meetingTime);
    return tabKey == 'upcoming' ? meetingDateTime >= currentDateTime : meetingDateTime < currentDateTime;
  };

  useEffect(() => {
    const { loading, status, data, error } = mentorSessionReducerData
    setIsLoading(loading)
    if (!loading && data?.length > 0) {
      const filteredSessions = data?.filter(
        (menteeRequest) =>
          menteeRequest.mentee_details.meeting_time.includes("T")
            ? CallsFilter(menteeRequest.mentee_details.meeting_time)
            : CallsFilter(
              `${menteeRequest.mentee_details.meeting_date}T${menteeRequest.mentee_details.meeting_time}:00.000Z`
            )
      );

      setCallsData(filteredSessions);
    }
    else {
      setCallsData([])
      console.warn("data is Empty")
    }

  }, [mentorSessionReducerData, tabKey]);
  useEffect(() => {

    console.log("CallsData", tabKey, CallsData)


  }, [tabKey])

  return (
    <>
      {isLoading ? (
        <div className="loader open_sessions_loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        null
      )}
      <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
        <div className="ant-tabs ant-tabs-top booking-tabs ">
          <div role="tablist" className="ant-tabs-nav">
            <div className="ant-tabs-nav-wrap">
              <div className="ant-tabs-nav-list" style={{ transform: "translate(0px, 0px)" }}>
                {tabs.map((tab, index) => (
                  <div key={index} data-node-key={index + 1} className={`ant-tabs-tab ${index === selectedTabIndex ? 'ant-tabs-tab-active' : ''}`}>
                    <div
                      role="tab"
                      aria-selected={index === selectedTabIndex}
                      className="ant-tabs-tab-btn"
                      tabIndex={0}
                      id={`rc-tabs-14-tab-${index + 1}`}
                      aria-controls={`rc-tabs-14-panel-${index + 1}`}
                      onClick={() => handleTabClick(index, tab.key)}
                    >
                      {tab.label}
                    </div>
                  </div>
                ))}
                <div
                  className="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                  style={{
                    left: `${37.5938 + selectedTabIndex * 150}px`,
                    transform: "translateX(-50%)",
                    width: "75.1875px"
                  }}
                />
              </div>
            </div>
            <div className="ant-tabs-nav-operations ant-tabs-nav-operations-hidden">
              <button
                type="button"
                className="ant-tabs-nav-more"
                tabIndex={-1}
                aria-hidden="true"
                aria-haspopup="listbox"
                aria-controls="rc-tabs-14-more-popup"
                id="rc-tabs-14-more"
                aria-expanded="false"
                style={{ visibility: "hidden", order: 1 }}
              >
                <span
                  role="img"
                  aria-label="ellipsis"
                  className="anticon anticon-ellipsis"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="ellipsis"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div className="ant-tabs-content-holder">
            <div className="ant-tabs-content ant-tabs-content-top">
              <div
                role="tabpanel"
                tabIndex={0}
                aria-hidden="false"
                className="ant-tabs-tabpane ant-tabs-tabpane-active tab-color"
                id="rc-tabs-14-panel-1"
                aria-labelledby="rc-tabs-14-tab-1"
              >
                <div className="container-fluid ">
                  {
                    CallsData && CallsData?.length > 0 ? (
                      <>
                        <div className="ant-row calls-main ">
                          <div className="ant-col ant-col-24 ">
                            <CallCards data={CallsData} tabKey={tabKey} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="ant-row ant-row-center ant-row-middle ">
                          <div className="ant-col text-center ant-col-xs-24 ant-col-md-7 ">
                            <img
                              alt="No Pending/Answered Queries"
                              loading="lazy"
                              width={170}
                              height={170}
                              decoding="async"
                              data-nimg={1}
                              className=""
                              src={docempty}
                              style={{ color: "transparent", height: "auto" }}
                            />
                            <div className="ant-space  ant-space-vertical ant-space-align-center ant-space-gap-row-middle ant-space-gap-col-middle">
                              <div className="ant-space-item">
                                <h5
                                  className="ant-typography "
                                  style={{ marginBottom: 0, marginTop: 16 }}
                                >
                                  {" "}
                                  Share your page
                                </h5>
                              </div>
                              <div className="ant-space-item">
                                <div
                                  className="ant-typography "
                                  style={{ margin: 0 }}
                                >
                                  A new booking might just be around the corner, share
                                  your page today!
                                </div>
                              </div>
                              <div className="ant-space-item">
                                <button
                                  type="button"
                                  className="ant-btn  ant-btn-primary btn-dark"
                                  style={{ marginTop: 8 }}
                                >
                                  <span> Share page</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>

                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Calls;
