import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, message, Row, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { stdCodeData } from '../../../assets/data/data';
import { TailSpin } from 'react-loader-spinner';
import { REACT_TOKEN_AUTH, REACT_USER_ID } from '../../auth/tokenProvider';
import { logoutUser, updateProfile, updateUserPassword } from '../../../redux/actions/login';

const { Paragraph, Title, Text } = Typography;
const { Option } = Select;

const AdvancedProfile = (props) => {
  const { form, profile, loading, setLoading } = props;

  const [data, setdata] = useState(profile);
  const [emailEditing, setEmailEditing] = useState(false);
  const [mobileEditing, setMobileEditing] = useState(false);
  const [passwordEditing, setPasswordEditing] = useState(false);
  const [notificationsEditing, setNotificationsEditing] = useState(false);

  const defaultCountryCode = profile?.mobile && profile?.mobile?.length > 0 ? profile.mobile.slice(0, 2) : "";
  const defaultPhoneNumber = profile?.mobile && profile?.mobile?.length > 0 ? profile.mobile.slice(2) : "";


  const [std, setStd] = useState(defaultCountryCode ? defaultCountryCode : stdCodeData[0].code);
  const [mobile, setmobile] = useState(defaultPhoneNumber);

  const dispatch = useDispatch();




  const updatePasswordPatch = async (data) => {
    try {
      console.log("data", data)
      const passwordDataModal = {
        deviceToken: REACT_TOKEN_AUTH.slice(1, -1),
        userId: REACT_USER_ID,
        password: data?.newPassword,
        passwordData: {
          password: data?.newPassword,
          user_id: REACT_USER_ID,
        },
      };
      dispatch(updateUserPassword(passwordDataModal));

      message.success('Password Updated Successfully!', 4);
      setPasswordEditing(false);
      setdata(false);
    } catch (error) {
      message.error('Some Error Occurred! Please try again!', 4);
      setdata(false);
    }
  };
  const updateUserPasswordReducerData = useSelector(
    (state) => state.updateUserPasswordReducer
  );
  useEffect(() => {
    const { loading, success, data } = updateUserPasswordReducerData
    setLoading(loading)

  }, [updateUserPasswordReducerData])


  const updateMobile = async (data) => {
    try {

      const postObj = {
        user_id: REACT_USER_ID,
        mobile: data?.mobile
      }
      dispatch(updateProfile(postObj));

      message.success('Mobile Updated Successfully!', 4);
      setMobileEditing(false);
      setdata(false);
    } catch (error) {
      message.error('Some Error Occurred! Please try again!', 4);
      setdata(false);
    }
  };

  const updateProfilePatch = (type, value) => {
    switch (type) {
      case 'email':
        // updatePasswordPatch({ email: value });

        setEmailEditing(false);
        break;
      case 'mobile':
        updateMobile({ mobile: std + mobile });

        setMobileEditing(false);
        break;
      case 'password':
        console.log("Value", value)
        updatePasswordPatch({ newPassword: value });
        setPasswordEditing(false);
        break;
      case 'notifications':
        updatePasswordPatch({ notifications: value });

        setNotificationsEditing(false);
        break;
      default:
        setdata(false);
        break;
    }
  };

  const onSave = (fields, type) => {
    const { whatsapp, newPassword, phone, email } = fields;
    switch (type) {
      case 'email':
        updateProfilePatch(type, email);
        break;
      case 'mobile':
        updateProfilePatch(type, phone);
        break;
      case 'password':
        updateProfilePatch(type, newPassword);
        break;
      case 'notifications':
        updateProfilePatch(type, whatsapp);
        break;
      default:
        break;
    }
  };

  const setEditing = (type) => {
    switch (type) {
      case 'email':
        setEmailEditing(true);
        break;
      case 'mobile':
        setMobileEditing(true);
        break;
      case 'password':
        setPasswordEditing(true);
        break;
      case 'notifications':
        setNotificationsEditing(true);
        break;
      default:
        break;
    }
  };

  const onCancelClick = (type) => {
    switch (type) {
      case 'email':
        setEmailEditing(false);
        break;
      case 'mobile':
        setMobileEditing(false);
        break;
      case 'password':
        setPasswordEditing(false);
        break;
      case 'notifications':
        setNotificationsEditing(false);
        break;
      default:
        break;
    }
  };
  const handleInputChange = (field, value) => {
    setmobile(value)
  };
  const validateMobile = (_, value) => {
    const phoneRegex = /^[0-9]+$/;
    if (!value || (value.length > 6 && phoneRegex.test(value))) {
      return Promise.resolve();
    }
    return Promise.reject('Please enter a valid  mobile number');
  };
  const handleLogOut = () => {
    window.location.assign("/");
    localStorage.removeItem("REACT_TOKEN_AUTH");
    localStorage.removeItem("REACT_USER_ID");
    dispatch(logoutUser(""));
  };
  return (
    <>
      {loading ? (
        <div className="loader open_sessions_loader ">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (null)}
      <Row className='mx-md-0 mx-lg-5'>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} xl={{ span: 14, offset: 0 }}>
          <Title level={5} style={{ fontWeight: 700, marginBottom: 0, marginTop: 15 }}>
            Your details
          </Title>
          <Row className="configurations">
            <Col xs={24} md={24}>
              {/* <Row className="config-row">
                <Col
                  xs={24}
                  md={24}
                  className="config-type"
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Paragraph className="config-title">Email address</Paragraph>
                  {emailEditing ? (
                    <Button type="link" onClick={() => onCancelClick('email')} style={{ padding: 0 }}>
                      <Text strong underline style={{ fontSize: 16 }}>
                        Cancel
                      </Text>
                    </Button>
                  ) : (
                    <Button type="link" onClick={() => setEditing('email')} style={{ padding: 0 }}>
                      <Text strong underline style={{ fontSize: 16 }}>
                        Edit
                      </Text>
                    </Button>
                  )}
                </Col>
                <Col xs={24} md={24}>
                  {emailEditing ? (
                    <Form
                      form={form}
                      layout="vertical"
                      scrollToFirstError
                      requiredMark={false}
                      initialValues={{ email: data?.email }}
                      onFinish={(fields) => onSave(fields, 'email')}
                    >
                      <Form.Item
                        name="email"
                        className="title-fields"
                        style={{ marginBottom: 16 }}
                        rules={[
                          {
                            required: true,
                            message: 'Email is required.',
                          },
                          {
                            whitespace: true,
                            message: 'Email cannot be empty!',
                          },
                          { type: 'email', message: 'Invalid email Entered!' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Button htmlType="submit" className="btn-dark">
                        Save
                      </Button>
                    </Form>
                  ) : (
                    <Paragraph className="config-description">{data?.email || "no email"}</Paragraph>
                  )}
                </Col>
              </Row> */}

              {/* Mobile Number */}
              <Row className="config-row">
                <Col
                  xs={24}
                  md={24}
                  className="config-type"
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Paragraph className="config-title">Mobile number</Paragraph>
                  {mobileEditing ? (
                    <Button type="link" onClick={() => onCancelClick('mobile')} style={{ padding: 0 }}>
                      <Text strong underline style={{ fontSize: 16 }}>
                        Cancel
                      </Text>
                    </Button>
                  ) : (
                    <Button type="link" onClick={() => setEditing('mobile')} style={{ padding: 0 }}>
                      <Text strong underline style={{ fontSize: 16 }}>
                        Edit
                      </Text>
                    </Button>
                  )}
                </Col>
                <Col xs={24} md={24}>
                  {mobileEditing ? (
                    <Form
                      form={form}
                      layout="vertical"
                      scrollToFirstError
                      requiredMark={false}
                      initialValues={{ phone: data?.phone }}
                      onFinish={(fields) => onSave(fields, 'mobile')}
                    >
                      {/* mobile */}
                      <Form.Item

                        name="mobile"
                        hasFeedback
                        rules={[
                          { required: true, message: 'Please enter your mobile number!' },
                          { validator: validateMobile },
                        ]}
                      >
                        <Row gutter={8}>
                          <Col span={6}>
                            <Form.Item
                              name={['stdCode']}
                              noStyle
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!std) {
                                      return Promise.reject('Please select STD code!');
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}

                            >
                              <Select defaultValue={std} value={std} onChange={(value) => setStd(value)}>
                                {stdCodeData.map((code) => (
                                  <Option key={code.code} value={code.code}>
                                    {`${code.iso} (+${code.code})`}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={18}>
                            <Form.Item name={['mobile']} noStyle>
                              <Input
                                onChange={(e) => handleInputChange('mobile', e.target.value)}
                                placeholder="Enter your mobile number without STD code"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Button htmlType="submit" className="btn-dark">
                        Save
                      </Button>
                    </Form>
                  ) : (
                    <Paragraph className="config-description">{data?.mobile || "no mobile"}</Paragraph>
                  )}
                </Col>
              </Row>

              {/* Password */}
              {/* Password */}
              <Row className="config-row">
                <Col
                  xs={24}
                  md={24}
                  className="config-type"
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Paragraph className="config-title">Password</Paragraph>
                  {passwordEditing ? (
                    <Button type="link" onClick={() => onCancelClick('password')} style={{ padding: 0 }}>
                      <Text strong underline style={{ fontSize: 16 }}>
                        Cancel
                      </Text>
                    </Button>
                  ) : (
                    <Button type="link" onClick={() => setEditing('password')} style={{ padding: 0 }}>
                      <Text strong underline style={{ fontSize: 16 }}>
                        Edit
                      </Text>
                    </Button>
                  )}
                </Col>
                <Col xs={24} md={24}>
                  {passwordEditing && (
                    <Form
                      form={form}
                      layout="vertical"
                      scrollToFirstError
                      requiredMark={false}
                      onFinish={(fields) => onSave(fields, 'password')}
                    >


                      {/* New Password */}
                      <Form.Item
                        name="newPassword"
                        className="title-fields"
                        style={{ marginBottom: 16 }}
                        rules={[
                          {
                            required: true,
                            message: 'New password is required.',
                          },
                          {
                            min: 8,
                            message: 'Password must be at least 8 characters long.',
                          },
                          {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message: 'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
                          },
                          // Add more validation rules as needed
                        ]}
                      >
                        <Input.Password
                          type="password"
                          visibilityToggle
                          autoComplete="off"
                          placeholder="Enter New Password"
                        />
                      </Form.Item>


                      {/* Confirm Password */}
                      <Form.Item
                        name="confirmPassword"
                        className="title-fields"
                        style={{ marginBottom: 16 }}
                        dependencies={['newPassword']}
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password.',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject('The passwords do not match.');
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          type="password"
                          visibilityToggle
                          autoComplete="off"
                          placeholder="Confirm New Password"
                        />
                      </Form.Item>

                      <Button htmlType="submit" className="btn-dark">
                        Save
                      </Button>
                    </Form>
                  )}
                </Col>
              </Row>


              {/* Notifications */}
              <Title level={5} style={{ fontWeight: 700, marginBottom: 0, marginTop: 50 }}>
                Notifications
              </Title>

              {/* Notifications */}
              <Row className="config-row">
                <Col
                  xs={24}
                  md={24}
                  className="config-type"
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Paragraph className="config-title">Booking notifications</Paragraph>
                  {notificationsEditing ? (
                    <Button
                      type="link"
                      onClick={() => onCancelClick('notifications')}
                      style={{ padding: 0 }}
                    >
                      <Text strong underline style={{ fontSize: 16 }}>
                        Cancel
                      </Text>
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      onClick={() => setEditing('notifications')}
                      style={{ padding: 0 }}
                    >
                      <Text strong underline style={{ fontSize: 16 }}>
                        Edit
                      </Text>
                    </Button>
                  )}
                </Col>
                <Col xs={24} md={24}>
                  {notificationsEditing ? (
                    <Form
                      disabled={true}
                      layout="vertical"
                      scrollToFirstError
                      onFinish={(fields) => onSave(fields, 'notifications')}
                      initialValues={{ whatsapp: data?.subscribe_to_whatsapp }}
                    >
                      <Form.Item
                        name="whatsapp"
                        className="title-fields"
                        valuePropName="checked"
                        style={{ marginBottom: 16 }}
                      >
                        <Checkbox defaultChecked >Whatsapp</Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="email"
                        className="title-fields"
                        valuePropName="checked"
                        style={{ marginBottom: 16 }}
                      >
                        <Checkbox defaultChecked >Email</Checkbox>
                      </Form.Item>
                      <Form.Item>
                        <Button htmlType="submit" className="btn-dark">
                          Save
                        </Button>
                      </Form.Item>
                    </Form>
                  ) : (
                    <Paragraph className="config-description">On: Email, Whatsapp</Paragraph>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Paragraph style={{ marginTop: 30, fontSize: 16 }}>
                    <Text style={{ marginRight: 4 }}>
                      Do you need help with your account settings?
                    </Text>
                    <a
                      href="/dashboard/feedback"
                    >
                      <Text underline>Send a query</Text>
                    </a>
                  </Paragraph>
                </Col>
              </Row>

              {/* Logout */}
              <Row>
                <Col xs={24} md={24} lg={12}>

                  <Button
                    danger
                    type="default"
                    style={{ marginTop: 60 }}
                    onClick={handleLogOut}
                  >
                    Logout
                  </Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AdvancedProfile;
