import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import crossIcon from "../../../assets/images/cross-icon.png";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UploadImage } from '../../UploadImage';
import { appendFormData } from '../../../redux/actions/course';


const NewVideoModal = ({ OpenModal, setOpenModal }) => {
    const loader = false;
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState("Upload");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleSubmit = (formData) => {

        let actionType;

            switch (OpenModal?.add_type) {
                case 'ADD_CHAPTER':
                    actionType = "ADD_CHAPTER_TO_UNIT_BY_INDEX";
                    break;
                case 'ADD_UNIT':
                    actionType = "ADD_UNIT";
                    break;
                default:
                    actionType = "APPEND_FORM_DATA";
                    break;
            }

            dispatch(appendFormData(actionType, OpenModal?.chapterIndex, OpenModal?.unitIndex, formData));
        handleCancel()

    }

const handleCancel = () => {
    setOpenModal((prevState) => ({ ...prevState, show: false }))

}

    return (
        <>
            {loader && (
                <div className="loader" style={{ zIndex: 9999 }}>
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            )}
            <Modal
                size="xl"
                centered
                show={OpenModal.show}
                onHide={() =>
                    setOpenModal((prevState) => ({ ...prevState, show: false }))
                }
            >

                <ModalBody>

                    <button
                        type="button"
                        className="cloose"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                            setOpenModal((prevState) => ({ ...prevState, show: false }))
                        }
                    >
                        <div className="closebuttons">
                            <span aria-hidden="true">
                                <img src={crossIcon} />
                            </span>
                        </div>
                    </button>

                    <div className="create_course_modal" style={{ background: "white" }}>
                        <div className="modal-body">

                            <div className="modal-container" >
                                <div className="modal-header">

                                    <div className="modal-title h5">New video</div>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="card border-0 ">
                                                <div className="card-header header-table tab-header">
                                                    <ul
                                                        className="nav nav-tabs"
                                                        role="tablist"
                                                        style={{ display: "flex", flexDirection: "row" }}
                                                    >
                                                        <li className="nav-item">
                                                            <a
                                                                data-toggle="tab"
                                                                href="#tabs-1"
                                                                role="tab"
                                                                className={`nav-link ${activeTab === "Upload" ? "active" : ""
                                                                    }`}
                                                                onClick={() => handleTabClick("Upload")}
                                                            >
                                                                Upload
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                data-toggle="tab"
                                                                href="#tabs-2"
                                                                role="tab"
                                                                className={`nav-link ${activeTab === "Youtube" ? "active" : ""
                                                                    }`}
                                                                onClick={() => handleTabClick("Youtube")}
                                                            >
                                                                Youtube
                                                            </a>
                                                        </li>
                                                        {/* <li className="nav-item disabled">
                                                            <a
                                                                data-toggle="tab"
                                                                href="#tabs-2"
                                                                role="tab"
                                                                className={`nav-link ${activeTab === "Vimeo" ? "active" : ""
                                                                    }`}
                                                                onClick={() => handleTabClick("Vimeo")}
                                                            >
                                                                Vimeo
                                                            </a>
                                                        </li>
                                                        <li className="nav-item disabled">
                                                            <a
                                                                data-toggle="tab"
                                                                href="#tabs-2"
                                                                role="tab"
                                                                className={`nav-link ${activeTab === "SproutVideo" ? "active" : ""
                                                                    }`}
                                                                onClick={() => handleTabClick("SproutVideo")}
                                                            >
                                                                SproutVideo
                                                            </a>
                                                        </li> */}
                                                        <li className="nav-item ">
                                                            <a
                                                                data-toggle="tab"
                                                                href="#tabs-2"
                                                                role="tab"
                                                                className={`nav-link ${activeTab === "Link" ? "active" : ""
                                                                    }`}
                                                                onClick={() => handleTabClick("Link")}
                                                            >
                                                                Link
                                                            </a>
                                                        </li>
                                                        <li className="nav-item ">
                                                            <a
                                                                data-toggle="tab"
                                                                href="#tabs-2"
                                                                role="tab"
                                                                className={`nav-link ${activeTab === "EmbedCode" ? "active" : ""
                                                                    }`}
                                                                onClick={() => handleTabClick("EmbedCode")}
                                                            >
                                                                Embed Code
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="card-body table-inner">
                                                    <div className="tab-content">
                                                        <div id="pdfCont">
                                                            {activeTab === "Upload" && <Upload handleSubmit={handleSubmit} handleCancel={handleCancel}/>}
                                                            {activeTab === "Youtube" && <Youtube handleSubmit={handleSubmit} handleCancel={handleCancel} />}
                                                            {activeTab === "Vimeo" && <Vimeo handleSubmit={handleSubmit} handleCancel={handleCancel} />}
                                                            {activeTab === "SproutVideo" && <SproutVideo handleSubmit={handleSubmit} handleCancel={handleCancel} />}
                                                            {activeTab === "Link" && <Link handleSubmit={handleSubmit} handleCancel={handleCancel} />}
                                                            {activeTab === "EmbedCode" && <EmbedCode handleSubmit={handleSubmit} handleCancel={handleCancel} />}

                                                            <h2 className="hr-lines"> OR </h2>
                                                            <div className="text-center">
                                                                <button className="btn btn-sm googleDriveUploadBtn" >
                                                                    <img src="https://dz8fbjd9gwp2s.cloudfront.net/resources/images/icons8-google-drive-48.png" />{" "}
                                                                    Import from Google Drive
                                                                </button>
                                                                <button className="btn btn-sm googleDriveUploadBtn" >
                                                                    <img src="https://dz8fbjd9gwp2s.cloudfront.net/resources/images/icons8-dropbox-48.png" />{" "}
                                                                    Import from DropBox
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </ModalBody>

            </Modal>
        </>
    );
}

export default NewVideoModal;

const Upload = ({ handleSubmit ,handleCancel}) => {
    const [message, setMessage] = useState(null)

    const [formData, setFormData] = useState({
        link: "",
        title:""
    });


    const handleSubmitUpload = (e) => {
        e.preventDefault();
        const UploadData = {
            ...formData,
            type: "ADD_UPLOAD_VIDEO"
        };
        if (!formData?.link) {
            setMessage("Please Enter valid data")
            setTimeout(() => {
                setMessage('')
            }, 2000);
        }
        else {

            handleSubmit(UploadData);
        }
    };
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const uploadVideo = (val, pre) => {
        console.log("uploadbanner called", val);

        setFormData((pre) => ({
            ...pre,
            link: val,
        }));
    };
    return (
        <div>
            <form>
                <div className="form-group">
                    <label className="session__label">
                        Title: *
                    </label>
                    <input
                        type="text"
                        name="title"
                        placeholder="Title"
                        required=""
                        className="form-control"
                        value={formData.title}
                        onChange={handleInputChange}
                    />
                </div>
                <UploadImage
                    callback={uploadVideo}
                    fileName="_upload-video"
                    imagePath={"courseImage/courseVideo/"}
                    filetype={["video"]}
                />
                <div className="get-started-cls why_choose_ment">
                    <a >
                        <button type="button" className="find-btn btn btn-primary" onClick={()=>{handleCancel()}}>
                            Cancel
                        </button>
                    </a>
                    <button
                        onClick={handleSubmitUpload}
                        type="button"
                        className="btn btn-primary"
                    >
                        Submit
                    </button>
                    {
                        message && (<p className="text-center">{message}</p>)
                    }
                </div>
            </form>
        </div>
    );
};

const Youtube = ({ handleSubmit ,handleCancel}) => {
    const [message, setMessage] = useState(null)

    const [formData, setformData] = useState({
        title: '',
        link: ''
    });
    const handleSubmitYoutube = (e) => {
        e.preventDefault();
        const UploadData = {
            ...formData,
            type: "ADD_YOUTUBE_VIDEO"
        };
        if (!formData?.link && !formData?.title) {
            setMessage("Please Enter valid data")
            setTimeout(() => {
                setMessage('')
            }, 2000);
        }
        else {

            handleSubmit(UploadData);
        }
    };

    const handleYoutubeLinkChange = (event) => {
        setformData({ ...formData, title: event.target.value });
    };

    const handleYoutubeUrlChange = (event) => {
        setformData({ ...formData, link: event.target.value });
    };

    return (
        <div>
            <div className="form-group">
                <label className="session__label">Youtube Link: *</label>
                <input
                    type="url"
                    name="title"
                    placeholder="title"
                    required=""
                    className="form-control"
                    value={formData.title}
                    onChange={handleYoutubeLinkChange}
                />
            </div>
            <div className="form-group">
                <label className="session__label">Youtube Url: *</label>
                <input
                    type="url"
                    name="link"
                    placeholder="Youtube Url"
                    required=""
                    className="form-control"
                    value={formData.link}
                    onChange={handleYoutubeUrlChange}
                />
            </div>
            <div className="get-started-cls why_choose_ment">
                <a >
                    <button type="button" className="find-btn btn btn-primary" onClick={()=>{handleCancel()}}>
                        Cancel
                    </button>
                </a>
                <button
                    onClick={handleSubmitYoutube}
                    type="button"
                    className="btn btn-primary"
                >
                    Submit
                </button>
                {
                    message && (<p className="text-center">{message}</p>)
                }
            </div>
        </div>
    );
};



const Vimeo = () => {
    const [vimeoData, setVimeoData] = useState({
        vimeoLink: '',
        vimeoTitle: ''
    });

    const handleVimeoLinkChange = (event) => {
        setVimeoData({ ...vimeoData, vimeoLink: event.target.value });
    };

    const handleVimeoTitleChange = (event) => {
        setVimeoData({ ...vimeoData, vimeoTitle: event.target.value });
    };

    return (
        <div>
            <div className="form-group">
                <label className="session__label">Vimeo Link: *</label>
                <input
                    type="url"
                    name="vimeoLink"
                    placeholder="Vimeo Link"
                    required=""
                    className="form-control"
                    value={vimeoData.vimeoLink}
                    onChange={handleVimeoLinkChange}
                />
            </div>
            <div className="form-group">
                <label className="session__label">Title: *</label>
                <input
                    type="text"
                    name="vimeoTitle"
                    placeholder="Title"
                    required=""
                    className="form-control"
                    value={vimeoData.vimeoTitle}
                    onChange={handleVimeoTitleChange}
                />
            </div>
        </div>
    );
};

const SproutVideo = () => {
    const [sproutVideoData, setSproutVideoData] = useState({
        sproutVideoLink: '',
        sproutVideoTitle: ''
    });

    const handleSproutVideoLinkChange = (event) => {
        setSproutVideoData({ ...sproutVideoData, sproutVideoLink: event.target.value });
    };

    const handleSproutVideoTitleChange = (event) => {
        setSproutVideoData({ ...sproutVideoData, sproutVideoTitle: event.target.value });
    };

    return (
        <div>
            <div className="form-group">
                <label className="session__label">SproutVideo Link: *</label>
                <input
                    type="url"
                    name="sproutVideoLink"
                    placeholder="SproutVideo Link"
                    required=""
                    className="form-control"
                    value={sproutVideoData.sproutVideoLink}
                    onChange={handleSproutVideoLinkChange}
                />
            </div>
            <div className="form-group">
                <label className="session__label">Title: *</label>
                <input
                    type="text"
                    name="sproutVideoTitle"
                    placeholder="Title"
                    required=""
                    className="form-control"
                    value={sproutVideoData.sproutVideoTitle}
                    onChange={handleSproutVideoTitleChange}
                />
            </div>
        </div>
    );
};

const Link = ({ handleSubmit ,handleCancel}) => {
    const [message, setMessage] = useState(null)

    const [formData, setformData] = useState({
        link: '',
        title: ''
    });
    const handleSubmitLink = (e) => {
        e.preventDefault();
        const UploadData = {
            ...formData,
            type: "ADD_LINK_VIDEO"
        };
        if (!formData?.link && !formData?.title) {
            setMessage("Please Enter valid data")
            setTimeout(() => {
                setMessage('')
            }, 2000);
        }
        else {

            handleSubmit(UploadData);
        }
    };

    const handleLinkUrlChange = (event) => {
        setformData({ ...formData, link: event.target.value });
    };

    const handleLinkTitleChange = (event) => {
        setformData({ ...formData, title: event.target.value });
    };

    return (
        <div>
            <div className="form-group">
                <label className="session__label">Link: *</label>
                <input
                    type="url"
                    name="link"
                    placeholder="Link"
                    required=""
                    className="form-control"
                    value={formData.link}
                    onChange={handleLinkUrlChange}
                />
            </div>
            <div className="form-group">
                <label className="session__label">Title: *</label>
                <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    required=""
                    className="form-control"
                    value={formData.title}
                    onChange={handleLinkTitleChange}
                />
            </div>
            <div className="get-started-cls why_choose_ment">
                <a >
                    <button type="button" className="find-btn btn btn-primary" onClick={()=>{handleCancel()}}>
                        Cancel
                    </button>
                </a>
                <button
                    onClick={handleSubmitLink}
                    type="button"
                    className="btn btn-primary"
                >
                    Submit
                </button>
                {
                    message && (<p className="text-center">{message}</p>)
                }
            </div>
        </div>
    );
};


const EmbedCode = ({ handleSubmit ,handleCancel}) => {
    const [message, setMessage] = useState(null)

    const [formData, setformData] = useState({
        embedCode: '',
        title: '',
        duration: ''
    });
    const handleSubmitEmbedCode = (e) => {
        e.preventDefault();
        const UploadData = {
            ...formData,
            type: "ADD_EMBED_CODE_VIDEO"
        };
        if (!formData?.title && !formData?.embedCode && !formData?.duration) {
            setMessage("Please Enter valid data")
            setTimeout(() => {
                setMessage('')
            }, 2000);
        }
        else {

            handleSubmit(UploadData);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setformData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <div>
            <div className="form-group">
                <label className="session__label">Paste Your Embed code here: *</label>
                <input
                    type="text"
                    name="embedCode"
                    placeholder="Paste Your Embed code here"
                    required=""
                    className="form-control"
                    value={formData.embedCode}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group">
                <label className="session__label">Title: *</label>
                <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    required=""
                    className="form-control"
                    value={formData.title}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group">
                <label className="session__label">Duration (in minutes): *</label>
                <input
                    type="number"
                    name="duration"
                    placeholder="Duration (in minutes)"
                    required=""
                    className="form-control"
                    value={formData.duration}
                    onChange={handleInputChange}
                />
            </div>
            <div className="get-started-cls why_choose_ment">
                <a >
                    <button type="button" className="find-btn btn btn-primary" onClick={()=>{handleCancel()}}>
                        Cancel
                    </button>
                </a>
                <button
                    onClick={handleSubmitEmbedCode}
                    type="button"
                    className="btn btn-primary"
                >
                    Submit
                </button>
                {
                    message && (<p className="text-center">{message}</p>)
                }
            </div>
        </div>
    );
};