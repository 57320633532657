import React, { useState, useEffect } from "react";
import {  useNavigate } from 'react-router-dom';



 
  
import('../css/custom.css');

const Overlay = () => {
    const navigate = useNavigate();
    useEffect(() => {
          
        import('../css/custom.css');
    }, []);

    return (

        <>

            <div id='wrapper'>


                <div className='step-cont'>
                    <div className='step-inner-cls'>
                        <form action='#' method='post' id='raq_questions'>
                            <div className='question-container active_panel ' id='question-5'>
                                <div className='thanks-sec'>
                                    <div className='thankyou'>
                                        <h3>Session created successfully!</h3>
                                        <p>
                                            Your session probably will be listed on MentorPal very soon. You can checkout how does
                                            your session looks,
                                        </p>
                                        <button className='btn btn-primary' onClick={() => navigate('/mentor-dashboard')}>Visit your mentorpal profile</button>
                                    </div>
                                </div>

                            </div>


                        </form>

                    </div>
                </div>


            </div>




            {/* 
            <div id='wrapper'>

                <div id='content-wrapper' className='d-flex flex-column'>

                    <div id='content'>


                        <div className='container-fluid'>
                        
                            <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                                <h1 className='h3 mb-0 text-gray-800 main-title'>Go Back</h1>
                            </div>

                          
                            <div className='row'>
                                <div className='col-xl-12 col-md-12 mb-4'>
                                    <div className='card'>
                                        <div className='card-body create-session-cls'>
                                            <div className='aapply-mentor-sec'>
                                                <div id='overlay'>
                                                    <div className='thanks-sec'>
                                                        <div className='thankyou'>
                                                            <h3>Session created successfully!</h3>
                                                            <p>
                                                                Your session probably will be listed on MentorPal very soon. You can checkout how does
                                                                your session looks,
                                                            </p>
                                                            <button className='btn btn-primary'>Visit your mentorpal profile</button>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div> */}

        </>






    );
};

export default Overlay;
