import {
    googleCalanderConstants
  } from "../../constants";
  export function creategoogleAuthLink(data) {
    return {
      type: googleCalanderConstants.GENERATE_AUTH_LINK_REQUEST,data
    };
  }

  export function handleGoogleRedirection(data) {
    return {
      type: googleCalanderConstants.HANDLE_GOOGLE_REDIRECT_REQUEST,data
    };
  }