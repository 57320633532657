import { createCourseConstants, deleteMentorCourseConstants, getAllCourseConstants, getCourseDetailsConstants, getMentorCourseConstants, updateMentorCourseConstants } from "../../constants";
import { call, put } from "redux-saga/effects";
import { createCourseCall, deleteMentorCourseCall, getAllCourseCall, getCourseDetailsCall, getMentorCourseCall, subscribeCall, updateMentorCourseCall } from "../../network";

export function* createCourseSaga(action) {
  try {
    const response = yield call(createCourseCall, action.data);

    yield put({
      type: createCourseConstants.CREATE_COURSE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: createCourseConstants.CREATE_COURSE_FAILURE,
      error: e.message,
    });
  }
}
export function* getMentorCourseSaga(action) {
  try {
    const response = yield call(getMentorCourseCall, action.data);

    yield put({
      type: getMentorCourseConstants.GET_MENTOR_COURSE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getMentorCourseConstants.GET_MENTOR_COURSE_FAILURE,
      error: e.message,
    });
  }
}
export function* updateMentorCourseSaga(action) {
  try {
    const response = yield call(updateMentorCourseCall, action.data);

    yield put({
      type: updateMentorCourseConstants.UPDATE_MENTOR_COURSE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: updateMentorCourseConstants.UPDATE_MENTOR_COURSE_FAILURE,
      error: e.message,
    });
  }
}
export function* deleteMentorCourseSaga(action) {
  try {
    const response = yield call(deleteMentorCourseCall, action.data);

    yield put({
      type: deleteMentorCourseConstants.DELETE_MENTOR_COURSE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: deleteMentorCourseConstants.DELETE_MENTOR_COURSE_FAILURE,
      error: e.message,
    });
  }
}
export function* getAllCourseSaga(action) {
  try {
    const response = yield call(getAllCourseCall, action.data);

    yield put({
      type: getAllCourseConstants.GET_ALL_COURSE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getAllCourseConstants.GET_ALL_COURSE_FAILURE,
      error: e.message,
    });
  }
}
export function* getCourseDetailsSaga(action) {
  try {
    const response = yield call(getCourseDetailsCall, action.data);

    yield put({
      type: getCourseDetailsConstants.GET_COURSE_DETAIL_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getCourseDetailsConstants.GET_COURSE_DETAIL_FAILURE,
      error: e.message,
    });
  }
}