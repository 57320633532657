import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import crossIcon from "../../../assets/images/cross-icon.png";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { appendFormData } from '../../../redux/actions/course';
const LiveClass = ({ handleAction, handleCancel }) => {
    const [message, setMessage] = useState(null)

    const [formData, setFormData] = useState({
        title: "",
        availableFrom: ""
    });

    const handleSubmitliveClass = (e) => {
        e.preventDefault();
        const liveClassData = {
            ...formData,
            type: "ADD_CLASS_LIVE"
        };
        if (!formData?.title && !formData?.availableFrom) {
            setMessage("Please Enter valid data")
            setTimeout(() => {
                setMessage('')
            }, 2000);
        }
        else {
            handleAction(liveClassData);
            handleCancel()
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    return (
        <div>
            <p>Your learners will be attending live classes on the Zoom interface. On your current plan, a maximum of 500 learners can join in a single class.</p>
            <form>
                <div className="form-group">
                    <label className="session__label">
                        Title *
                    </label>
                    <input
                        type="text"
                        name="title"
                        placeholder="Title"
                        required=""
                        className="form-control"
                        value={formData.title}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label className="session__label">
                        Available From: *
                    </label>
                    <input
                        type="datetime-local"
                        name="availableFrom"
                        placeholder="Available From"
                        required=""
                        className="form-control"
                        value={formData.availableFrom}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="get-started-cls why_choose_ment">
                    <a href="#">
                        <button type="button" className="find-btn btn btn-primary">
                            Cancel
                        </button>
                    </a>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmitliveClass}
                    >
                        Submit
                    </button>

                </div>
                {
                    message && (<p className='text-center text-danger'>{message}</p>)
                }
            </form>
        </div>
    );
};

const LiveWebinar = ({ handleAction, handleCancel }) => {
    const [message, setMessage] = useState(null)

    const [formData, setFormData] = useState({
        title: "",
        availableFrom: ""
    });

    const handleSubmitWebinar = (e) => {
        e.preventDefault();
        const webinarData = {
            ...formData,
            type: "ADD_WEBINAR_LIVE"
        };
        if (!formData?.title && !formData?.availableFrom) {
            setMessage("Please Enter valid data")
            setTimeout(() => {
                setMessage('')
            }, 2000);
        }
        else {
            handleAction(webinarData);
            handleCancel()
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    return (
        <div>
            <p>Your learners will be attending webinars on the Zoom interface. On your current plan, a maximum of 500 learners can join in a single webinar.</p>
            <form>
                <div className="form-group">
                    <label className="session__label">
                        Title *
                    </label>
                    <input
                        type="text"
                        name="title"
                        placeholder="Title"
                        required=""
                        className="form-control"
                        value={formData.title}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label className="session__label">
                        Available From: *
                    </label>
                    <input
                        type="datetime-local"
                        name="availableFrom"
                        placeholder="Available From"
                        required=""
                        className="form-control"
                        value={formData.availableFrom}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="get-started-cls why_choose_ment">
                    <a href="#">
                        <button type="button" className="find-btn btn btn-primary">
                            Cancel
                        </button>
                    </a>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmitWebinar}
                    >
                        Submit
                    </button>

                </div>
                {
                    message && (<p className='text-center text-danger'>{message}</p>)
                }
            </form>
        </div>
    );
};

const YoutubeLive = ({ handleAction, handleCancel }) => {
    const [message, setMessage] = useState(null)

    const [formData, setFormData] = useState({
        link: "",
        title: "",
        availableFrom: "",
        availableTill: ""
    });

    const handleSubmitYoutubeLive = (e) => {
        e.preventDefault();
        const youtubeLiveData = {
            ...formData,
            type: "ADD_YOUTUBE_LIVE"
        };
        if (!formData?.title && !formData?.availableFrom && !formData?.link && !formData?.availableTill) {
            setMessage("Please Enter valid data")
            setTimeout(() => {
                setMessage('')
            }, 2000);
        }
        else {
            handleAction(youtubeLiveData);
            handleCancel()
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    return (
        <div>
            <p>Your learners will be attending live classes on your website and apps. There is no limit on the number of learners who can join your live class.</p>
            <form>
                <div className="form-group">
                    <label className="session__label">
                        Youtube Live Link *
                    </label>
                    <input
                        type="text"
                        name="link"
                        placeholder="Youtube Live Link"
                        required=""
                        className="form-control"
                        value={formData.link}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label className="session__label">
                        Title *
                    </label>
                    <input
                        type="text"
                        name="title"
                        placeholder="Title"
                        required=""
                        className="form-control"
                        value={formData.title}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label className="session__label">
                        Available From: *
                    </label>
                    <input
                        type="datetime-local"
                        name="availableFrom"
                        placeholder="Available From"
                        required=""
                        className="form-control"
                        value={formData.availableFrom}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label className="session__label">
                        Available Till: *
                    </label>
                    <input
                        type="datetime-local"
                        name="availableTill"
                        placeholder="Available Till"
                        required=""
                        className="form-control"
                        value={formData.availableTill}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="get-started-cls why_choose_ment">
                    <a href="#">
                        <button type="button" className="find-btn btn btn-primary">
                            Cancel
                        </button>
                    </a>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmitYoutubeLive}
                    >
                        Submit
                    </button>

                </div>
                {
                    message && (<p className='text-center text-danger'>{message}</p>)
                }
            </form>
        </div>
    );
};

const LiveClassModal = ({ OpenModal, setOpenModal }) => {
    const dispatch = useDispatch();

    const loader = false;
    const [activeTab, setActiveTab] = useState("LiveClass");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const handleAction = (formData) => {
             let actionType;

            switch (OpenModal?.add_type) {
                case 'ADD_CHAPTER':
                    actionType = "ADD_CHAPTER_TO_UNIT_BY_INDEX";
                    break;
                case 'ADD_UNIT':
                    actionType = "ADD_UNIT";
                    break;
                default:
                    actionType = "APPEND_FORM_DATA";
                    break;
            }

            dispatch(appendFormData(actionType, OpenModal?.chapterIndex, OpenModal?.unitIndex, formData));
        handleCancel()

    };
    const handleCancel = () => {
        setOpenModal((prevState) => ({ ...prevState, show: false }))
    }
    return (
        <>
            {loader && (
                <div className="loader" style={{ zIndex: 9999 }}>
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            )}
            <Modal
                size="xl"
                centered
                show={OpenModal.show}
                onHide={() =>
                    setOpenModal((prevState) => ({ ...prevState, show: false }))
                }
            >

                <ModalBody>

                    <button
                        type="button"
                        className="cloose"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                            setOpenModal((prevState) => ({ ...prevState, show: false }))
                        }
                    >
                        <div className="closebuttons">
                            <span aria-hidden="true">
                                <img src={crossIcon} />
                            </span>
                        </div>
                    </button>
                    <div className="create_course_modal" style={{ background: "white" }}>
                        <div className="modal-body">

                            <div className="modal-container" >
                                <div className="modal-header">

                                    <div className="modal-title h5">New Live class</div>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="card border-0 ">
                                                <div className="card-header header-table tab-header">
                                                    <ul
                                                        className="nav nav-tabs"
                                                        role="tablist"
                                                        style={{ display: "flex", flexDirection: "row" }}
                                                    >
                                                        <li className="nav-item">
                                                            <a
                                                                data-toggle="tab"
                                                                href="#tabs-1"
                                                                role="tab"
                                                                className={`nav-link ${activeTab === "LiveClass" ? "active" : ""
                                                                    }`}
                                                                onClick={() => handleTabClick("LiveClass")}
                                                            >
                                                                Live Class
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                data-toggle="tab"
                                                                href="#tabs-2"
                                                                role="tab"
                                                                className={`nav-link ${activeTab === "LiveWebinar" ? "active" : ""
                                                                    }`}
                                                                onClick={() => handleTabClick("LiveWebinar")}
                                                            >
                                                                Live Webinar
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                data-toggle="tab"
                                                                href="#tabs-3"
                                                                role="tab"
                                                                className={`nav-link ${activeTab === "YoutubeLive" ? "active" : ""
                                                                    }`}
                                                                onClick={() => handleTabClick("YoutubeLive")}
                                                            >
                                                                Youtube Live
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="card-body table-inner">
                                                    <div className="tab-content">
                                                        <div id="pdfCont">
                                                            {activeTab === "LiveClass" && <LiveClass handleAction={handleAction} handleCancel={handleCancel} />}
                                                            {activeTab === "LiveWebinar" && <LiveWebinar handleAction={handleAction} handleCancel={handleCancel} />}
                                                            {activeTab === "YoutubeLive" && <YoutubeLive handleAction={handleAction} handleCancel={handleCancel} />}
                                                        </div>
                                                    </div>
                                                    <h2 className="hr-lines"> OR </h2>
                                                    <div className="text-center">
                                                        <a href="#"> I want to use my own zoom meeting/webinar license</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </ModalBody>

            </Modal>


        </>
    );
}

export default LiveClassModal;
