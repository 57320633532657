import React from "react";
import { useState } from "react";
import "./UpdateCompany.css";

const UpdateCompany = ({ companyData, getUpdatedValues }) => {
  const [userCompany, setUserCompany] = useState(companyData);
  const changeToCamelCase = (company) => {
    const companySplit = company.split(" ");
    let finalCompany = companySplit
      ?.map((item) => {
        if (item) return item[0]?.toUpperCase() + item?.slice(1);
      })
      ?.join(" ");

    return finalCompany;
  };
  const handleOnChange = (e) => {
    let tempValue = changeToCamelCase(e.target.value);
    setUserCompany(tempValue);
  };
  const handleOnClick = () => {
    let tempData = { company_or_school: userCompany };
    getUpdatedValues(tempData);
  };
  return (
    <div className="update-company-main-container">
      {/* <h7>Full Name</h7> */}
      <input
        className="company-input"
        value={userCompany}
        onChange={handleOnChange}
      ></input>
      <button className="update-btn-company" onClick={handleOnClick}>
        Update
      </button>
    </div>
  );
};

export default UpdateCompany;
