import React from 'react'

import Trainingimg1 from '../../../assets/images/Trainingimg1.jpeg'
import Trainingimg2 from '../../../assets/images/Trainingimg2.jpeg'
import Trainingimg3 from '../../../assets/images/Trainingimg3.jpeg'
import Trainingimg4 from '../../../assets/images/Trainingimg4.jpeg'
import RightArrow from './../../../assets/images/right-arrow-1.png'
import styles from './TrainingInfoSection.module.css'
import { Link } from 'react-router-dom'

const TrainingInfoSection = () => {
    return (
        <div className='TrainingInfoSection'>
            <div className={`container ${styles.info_container}`} >
                <h1 style={{ paddingTop: '40px' }}>
                    Stay Agile in the Face of Change and Elevate Your Expertise
                </h1>
                <p className={`${styles.info_subheading}`}>
                    Empower your workforce to steer business achievements through strategic learning
                </p>
                <div className='row'>
                    <div className='col-sm-6'>
                        <img src={Trainingimg1} className={`${styles.infoimgstyles}`} width={'400px'} style={{ borderRadius: '100px' }} />
                    </div>
                    <div className={`col-sm-6 ${styles.info_right_col}`} >
                        <div>
                            <h3 style={{ textAlign: 'left' }}>
                                Visualize a group of enthusiastic professionals sharing ideas around a table
                            </h3>
                            <ul style={{ paddingTop: '20px' }}>
                                <li className={`${styles.info_points}`} >
                                    Nurture, Enhance, and Retain Your Talent
                                </li>
                                <li className={`${styles.info_points}`} >
                                    Cultivate a culture of continuous learning within your organization
                                </li>
                                <li className={`${styles.info_points}`} >
                                    Uphold your team's growth journey throughout their career cycle
                                </li>
                                <li className={`${styles.info_points}`} >
                                    Invest in amplifying your employees’ skills and personal development
                                </li>
                            </ul>
                            <h5 className={`${styles.calltoAction}`} >
                                <Link to='/enterprise'>
                                    Find out more
                                </Link>
                                <span style={{ marginLeft: '5px' }}>
                                    <img src={RightArrow} />
                                </span>
                            </h5>
                        </div>
                    </div>
                    <hr className={`d-block d-sm-none ${styles.hrstyles}`} />
                </div>
                <div className='row'>
                    <hr className={`d-block order-3 d-sm-none ${styles.hrstyles}`} />

                    <div className={`col-sm-6 order-2 order-sm-1 ${styles.info_right_col}`} >
                        <div>
                            <h3 style={{ textAlign: 'left' }}>
                                Craft a Workforce Ready for Tomorrow
                            </h3>
                            <ul style={{ paddingTop: '20px' }}>
                                <li className={`${styles.info_points}`} >
                                    Utilize learning as a compass for navigating shifts and upholding business flow
                                </li>
                                <li className={`${styles.info_points}`} >
                                    Equip your teams with the latest sought-after proficiencies
                                </li>
                                <li className={`${styles.info_points}`} >
                                    Boost your staff's well-being with holistic wellness initiatives
                                </li>
                            </ul>
                            <h5 className={`${styles.calltoAction}`} >
                                Discover more
                                <span style={{ marginLeft: '5px' }}>
                                    <img src={RightArrow} />
                                </span>
                            </h5>
                        </div>
                    </div>
                    <div className='col-sm-6 order-1 order-sm-2'>
                        <img src={Trainingimg2} className={`${styles.infoimgstyles}`} width={'400px'} style={{ borderRadius: '100px' }} />
                    </div>

                </div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <img src={Trainingimg3} className={`${styles.infoimgstyles}`} width={'400px'} style={{ borderRadius: '100px' }} />
                    </div>
                    <div className={`col-sm-6 ${styles.info_right_col}`} >
                        <div>
                            <h3 style={{ textAlign: 'left' }}>
                                Empower Your Tech Squads to Lead the Pack
                            </h3>
                            <ul style={{ paddingTop: '20px' }}>
                                <li className={`${styles.info_points}`} >
                                    Empower your tech talents with contemporary, competitive skills
                                </li>
                                <li className={`${styles.info_points}`} >
                                    Bridge skill gaps for heightened innovation and expansion
                                </li>
                                <li className={`${styles.info_points}`} >
                                    Prepare your team for certification triumphs and drive digital revolution
                                </li>
                            </ul>
                            <h5 className={`${styles.calltoAction}`} >
                                Uncover more
                                <span style={{ marginLeft: '5px' }}>
                                    <img src={RightArrow} />
                                </span>
                            </h5>
                        </div>
                    </div>
                    <hr className={`d-block d-sm-none ${styles.hrstyles}`} />
                </div>
                <div className='row'>

                    <div className={`col-sm-6 order-2 order-sm-1 ${styles.info_right_col}`} >
                        <div>
                            <h3 style={{ textAlign: 'left' }}>
                                Strengthen Leaders and Managers
                            </h3>
                            <ul style={{ paddingTop: '20px' }}>
                                <li className={`${styles.info_points}`} >
                                    Equip them with the tools to lead, inspire, and unite hybrid teams
                                </li>
                                <li className={`${styles.info_points}`} >
                                    Forge the essential skills to foster trust and inclusivity
                                </li>
                                <li className={`${styles.info_points}`} >
                                    Harmonize leadership groups through collaborative approaches
                                </li>
                            </ul>
                            <h5 className={`${styles.calltoAction}`} >
                                Learn more
                                <span style={{ marginLeft: '5px' }}>
                                    <img src={RightArrow} />
                                </span>
                            </h5>
                        </div>
                    </div>
                    <div className='col-sm-6 order-1 order-sm-2'>
                        <img src={Trainingimg4} className={`${styles.infoimgstyles}`} width={'400px'} style={{ borderRadius: '100px' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrainingInfoSection