import React from "react";
import { useState } from "react";

const UpdateExperience = ({ ExperienceData, getUpdatedValues }) => {
    const [ExperienceTxt, setExperienceTxt] = useState(ExperienceData);
    const handleOnChange = (e) => {
        setExperienceTxt(e.target.value);
    };
    const handleOnClick = () => {
        let tempData = { experience: ExperienceTxt };
        getUpdatedValues(tempData);
    };
    return (
        <div className="update-linkedin-main-container">
            <input
                type="number"
                className="linkedin-input"
                value={ExperienceTxt}
                onChange={handleOnChange}
            ></input>
            <button className="update-btn-linkedin" onClick={handleOnClick}>
                Update
            </button>
        </div>
    );
};

export default UpdateExperience;
