import { Button, Modal, Typography } from "antd";
import { useEffect, useState } from "react";

const { Paragraph } = Typography;

const DeleteConfirmation = ({ show, onConfirm, onClose, title, content }) => {
    const [open, setOpen] = useState();

    useEffect(() => {
        setOpen(show);
    }, [show]);

    return (
        <Modal
            onCancel={() => {
                onClose();
            }}
            width={400}
            open={open}
            centered
            className="internal-page-modal"
            title={title || "Delete service"}
            footer={[
                <Button onClick={() => onClose()} key="cancel">
                    Cancel
                </Button>,
                <Button type="primary" onClick={onConfirm} key="delete">
                    Yes, Delete
                </Button>,
            ]}
        >
            <Paragraph style={{ lineHeight: "24px" }}>
                {content || "Are you sure you want to delete?"}
            </Paragraph>
        </Modal>
    );
};

export default DeleteConfirmation;
