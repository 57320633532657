import React, { useEffect } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import crossIcon from "../../../assets/images/cross-icon.png";
import { useState } from 'react';
import PdfModal from './pdfModal';
import NewVideoModal from './newVideoModal';
import AudioModal from './audioModal';
import ScormModal from './scormModal';
import AnyFileModal from './AnyFileModal';
import AddHeadingModal from './addHeadingModal';
import AddTextModal from './addTextModal';
import AddLinkModal from './addLinkModal';
import AddAssignmentModal from './addAssignment';
import CodingTestModal from './codingTestModal';
import AddFormModal from './addFormModal';
import AddNewQuizModal from './addNewQuizModal';
import LiveClassModal from './liveClassModal';
import LiveTestModal from './liveTestModal';

const AddItemModal = ({ headerModal, setHeaderModal }) => {
    const loader = false;
    const [OpenModal, setOpenModal] = useState({});
    const HandleClose = () => {

        setHeaderModal((prevState) => ({ ...prevState, show: false }))

    }

    return (
        <>
            {loader && (
                <div className="loader" style={{ zIndex: 9999 }}>
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            )}
            <Modal
                size="xl"
                centered
                show={headerModal.show}
                onHide={() =>
                    setHeaderModal((prevState) => ({ ...prevState, show: false }))
                }
            >

                <ModalBody>

                    <button
                        type="button"
                        className="cloose"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                            setHeaderModal((prevState) => ({ ...prevState, show: false }))
                        }
                    >
                        <div className="closebuttons">
                            <span aria-hidden="true">
                                <img src={crossIcon} />
                            </span>
                        </div>
                    </button>

                    <div className=" create_course_modal" style={{ background: "white" }}>
                        <div className="modal-body">

                            <div className="modal-container">

                                <div className="d-flex">
                                    <div
                                        className=" col"
                                        style={{ wordBreak: "break-word", fontSize: "smaller" }}
                                    >
                                        <h5 className="text-center">Upload new item</h5>
                                        <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: PdfModal,
                                                        show: true,
                                                        unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>PDF:</b> Add a PDF file in the course.
                                        </label>
                                        <br />
                                        <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: NewVideoModal,
                                                        show: true,
                                                        unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>Video:</b> All uploaded videos are
                                            completely secure and non downloadable. It can also be used to embed
                                            youtube and Vimeo videos.
                                        </label>
                                        <br />
                                        <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: AudioModal,
                                                        show: true,
                                                        unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>Audio</b>
                                        </label>
                                        <br />
                                        {/* <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: ScormModal,
                                                        show: true,
                                                         unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                                onclick="ycoursebuilder.prefillTitleAndShowModal('createSCORM')"
                                            />
                                            <i className="form-icon" /> <b>SCORM:</b> Import SCORM packages in the
                                            course. For more information on Scorm, please visit here
                                            (https://en.wikipedia.org/wiki/Sharable_Content_Object_Reference_Model)
                                        </label> */}
                                        {/* <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: AnyFileModal,
                                                        show: true,
                                                         unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>File:</b> Add any file type for learners
                                            to download.
                                        </label> */}
                                    </div>
                                    <div
                                        className=" col "
                                        style={{ wordBreak: "break-word", fontSize: "smaller" }}
                                    >
                                        <h5 className="text-center">Create new item</h5>
                                        <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: AddHeadingModal,
                                                        show: true,
                                                        unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>Heading:</b> Define your chapter or
                                            section headings
                                        </label>
                                        <br />
                                        <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: AddTextModal,
                                                        show: true,
                                                        unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex,
                                                        add_type: headerModal?.add_type,
                                                        
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>Text:</b> Add custom text or iFrame and
                                            HTML
                                        </label>
                                        <br />
                                        <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: AddLinkModal,
                                                        show: true,
                                                        unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>WebPage Link:</b> Add Link which will be embedded
                                            in iFrame
                                        </label>
                                        <br />
                                        {/* <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: AddNewQuizModal,
                                                        show: true,,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>Quiz: </b>
                                            <a
                                                target="_blank"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className="bi bi-info-circle"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                </svg>

                                            </a>
                                            <br /> Learners can any time attempt &amp; get results
                                        </label> */}
                                        {/* <br /> */}
                                        {/* <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: LiveTestModal,
                                                        show: true,,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>Live test: </b>
                                            <a
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className="bi bi-info-circle"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                </svg>

                                            </a>
                                            <br /> Learners can attempt it during specified time window. Leadership
                                            visible post result declaration.
                                        </label> */}
                                        {/* <br /> */}
                                        <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: LiveClassModal,
                                                        show: true,
                                                        unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>Live class:</b> Conduct live classes and
                                            webinars
                                        </label>
                                        <br />
                                        <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: AddAssignmentModal,
                                                        show: true,
                                                        unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex,
                                                        add_type: headerModal?.add_type,
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>Assignment: </b>
                                            <a
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className="bi bi-info-circle"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                </svg>

                                            </a>
                                            <br /> Take assignments from your learners
                                        </label>
                                        {/* <br />
                                        <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: CodingTestModal,
                                                        show: true,
                                                         unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" />
                                            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                                                <b>Coding test: </b>
                                                <span
                                                    style={{
                                                        display: "inline-flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        backgroundColor: "#4E5DE0",
                                                        height: 12,
                                                        color: "white",
                                                        padding: "2px 4px",
                                                        borderRadius: 1000,
                                                        fontSize: 8
                                                    }}
                                                >
                                                    New
                                                </span>
                                                <a
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={16}
                                                        height={16}
                                                        fill="currentColor"
                                                        className="bi bi-info-circle"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                    </svg>

                                                </a>
                                            </div>
                                            Learners can write and run code to solve problems
                                        </label> */}
                                        {/* <br />
                                        <label className="form-radio">
                                            <input
                                                onClick={() => {
                                                    setOpenModal({
                                                        modal: AddFormModal,
                                                        show: true,
                                                        unitIndex: headerModal?.unitIndex,
                                                        chapterIndex: headerModal?.chapterIndex
                                                    });
                                                }}
                                                type="radio"
                                                name="itemType"
                                            />
                                            <i className="form-icon" /> <b>Form: </b>
                                            <a
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className="bi bi-info-circle"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                </svg>

                                            </a>
                                            <br /> Collect information from learning during course
                                        </label> */}
                                    </div>
                                    {/* <div className=" col-3  d-flex flex-centered">
                                        <div className="text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={25}
                                                height={25}
                                                fill="currentColor"
                                                className="bi bi-collection"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13z" />
                                            </svg>

                                            <br />
                                            <div className="text-small text-gray">
                                                Import from your existing course content
                                            </div>
                                            <button className="btn browseLibrary mt-2">
                                                Import from Asset Library
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </ModalBody>

            </Modal>
            {OpenModal?.modal && (
                <OpenModal.modal
                    OpenModal={OpenModal}
                    setOpenModal={setOpenModal}
                />
            )}
        </>
    );
}

export default AddItemModal;
