import { call, put } from "redux-saga/effects";
import {
  createFavoriteMentorsConstants,
  favoriteMentorsConstants,
  goalSimilarMentorsConstants,
  menteeGoalActionConstants,
  similarMentorsConstants,
  subscribedMentorsConstants,
} from "../../constants";
import {
  createFavoriteMentorCall,
  favoriteMentorCall,
  menteeGoalCall,
  similarMentorCall,
  similarMentorGoalCall,
  subscribedMentorCall,
} from "../../network";
import { menteeGoalAction } from "../actions/common";

export function* favoriteMentorSaga(action) {
  try {
    const response = yield call(favoriteMentorCall, action.data);

    yield put({
      type: favoriteMentorsConstants.FAVORITE_MENTOR_SUCCESS,
      data: response.data,
      error: response.error,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: favoriteMentorsConstants.FAVORITE_MENTOR_FAILURE,
      error: e.message,
      headers: e.headers,
    });
  }
}

export function* createFavoriteMentorSaga(action) {
  try {
    const response = yield call(createFavoriteMentorCall, action.data);

    yield put({
      type: createFavoriteMentorsConstants.CREATE_FAVORITE_MENTOR_SUCCESS,
      data: response.data,
      error: response.error,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: createFavoriteMentorsConstants.CREATE_FAVORITE_MENTOR_FAILURE,
      error: e.message,
      headers: e.headers,
    });
  }
}

export function* subscribedMentorSaga(action) {
  try {
    const response = yield call(subscribedMentorCall, action.data);

    yield put({
      type: subscribedMentorsConstants.SUBSCRIBED_MENTOR_SUCCESS,
      data: response.data,
      error: response.error,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: subscribedMentorsConstants.SUBSCRIBED_MENTOR_FAILURE,
      error: e.message,
      headers: e.headers,
    });
  }
}
export function* similarMentorSaga(action) {
  try {
    const response = yield call(similarMentorCall,action.data);

    yield put({
      type: similarMentorsConstants.SIMILAR_MENTOR_SUCCESS,
      data: response.data,
      error: response.error,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: similarMentorsConstants.SIMILAR_MENTOR_FAILURE,
      error: e.message,
      headers: e.headers,
    });
  }
}
export function* menteeGoalSaga(action) {
  try {
    const response = yield call(menteeGoalCall,action.data);

    yield put({
      type: menteeGoalActionConstants.MENTEE_GOAL_SUCCESS,
      data: response.data,
      error: response.error,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: menteeGoalActionConstants.MENTEE_GOAL_FAILURE,
      error: e.message,
      headers: e.headers,
    });
  }
}
export function* goalSimilarMentorSaga(action) {
  try {
    const response = yield call(similarMentorGoalCall,action.data);

    yield put({
      type: goalSimilarMentorsConstants.GOAL_SIMILAR_MENTOR_SUCCESS,
      data: response.data,
      error: response.error,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: goalSimilarMentorsConstants.GOAL_SIMILAR_MENTOR_FAILURE,
      error: e.message,
      headers: e.headers,
    });
  }
}