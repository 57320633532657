import React, { useState } from 'react';
import packageempty from '../../Image/packageempty.svg';
import PackageDetailDrawer from './Modal/PackageDetailDrawer';
import { packageBookingUpcoming } from '../../Data/Data';
import EmailDrawer from './Modal/EmailDrawer';

const PackagesUpcoming = () => {
    const data = true

    const [emailModal, setEmailModal] = useState(false)
    const [packageDetailModal, setPackageDetailModal] = useState(false)
    const [ModalData, setModalData] = useState()


    return (
        <>
            <div className="ant-tabs-content ant-tabs-content-top">
                <div
                    role="tabpanel"
                    tabIndex={0}
                    aria-hidden="false"
                    className="ant-tabs-tabpane ant-tabs-tabpane-active"
                    id="rc-tabs-4-panel-1"
                    aria-labelledby="rc-tabs-4-tab-1"
                >
                    {
                        packageBookingUpcoming?.count > 0 || packageBookingUpcoming?.results.length > 0 ? (
                            <>
                                <div className="ant-row calls-main ">
                                    <div className="ant-col ant-col-24 ">
                                        {
                                            packageBookingUpcoming?.results?.map((item, index) => (

                                                <>
                                                    <div key={index} className="ant-col calls-card-container ant-col-xs-24 ">
                                                        <div className="ant-typography calls-card ">
                                                            <div className="ant-row ant-row-space-between calls-header cursor-hover ">
                                                                <div className="ant-col ant-col-24 ">
                                                                    <div className="ant-flex  ant-flex-align-center ant-flex-justify-space-between">
                                                                        <div className="ant-typography calls-time ">
                                                                            <svg
                                                                                stroke="currentColor"
                                                                                fill="currentColor"
                                                                                strokeWidth={0}
                                                                                viewBox="0 0 16 16"
                                                                                height="1em"
                                                                                width="1em"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                style={{ marginRight: 8 }}
                                                                            >
                                                                                <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                                                            </svg>
                                                                            {item?.service_count}  services
                                                                        </div>
                                                                        <div
                                                                            className="ant-flex  ant-flex-align-center"
                                                                            style={{
                                                                                borderRadius: 28,
                                                                                background: "rgb(242, 247, 252)",
                                                                                padding: "5px 10px",
                                                                                fontSize: 12,
                                                                                lineHeight: "175%",
                                                                                gap: 6
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeWidth={2}
                                                                                viewBox="0 0 24 24"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                fontSize={16}
                                                                                height="1em"
                                                                                width="1em"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <rect width={20} height={5} x={2} y={4} rx={2} />
                                                                                <path d="M4 9v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9" />
                                                                                <path d="M10 13h4" />
                                                                            </svg>
                                                                            <strong>Package</strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="ant-typography calls-details cursor-hover ">
                                                                <span className="ant-typography calls-name ">
                                                                    <strong>{item?.consumer_name}</strong>
                                                                </span>
                                                                <div className="ant-typography calls-service-desc ">
                                                                    {item?.title}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="ant-row ant-row-space-between ant-row-middle package-calls-footer "
                                                                style={{ marginLeft: "-6px", marginRight: "-6px" }}
                                                            >
                                                                <div
                                                                    className="ant-col "
                                                                    style={{ paddingLeft: 6, paddingRight: 6 }}
                                                                />
                                                                <div
                                                                    className="ant-col "
                                                                    style={{ paddingLeft: 6, paddingRight: 6 }}
                                                                >
                                                                    <div
                                                                        className="ant-row ant-row-middle "
                                                                        style={{ marginLeft: "-5px", marginRight: "-5px" }}
                                                                    >
                                                                        <div
                                                                            className="ant-col "
                                                                            style={{ paddingLeft: 5, paddingRight: 5 }}
                                                                        >
                                                                            <button
                                                                                onClick={() => {
                                                                                    setEmailModal(true)
                                                                                    setModalData(item)
                                                                                }}
                                                                                type="button"
                                                                                className="ant-btn  ant-btn-default btn-call-action"
                                                                            >
                                                                                <span>Send Email</span>
                                                                            </button>
                                                                        </div>
                                                                        <div
                                                                            className="ant-col ant-col-xs-15 ant-col-md-12 "
                                                                            style={{ paddingLeft: 5, paddingRight: 5 }}
                                                                        >
                                                                            <button
                                                                                onClick={() => {
                                                                                    setPackageDetailModal(true)
                                                                                    setModalData(item)

                                                                                }}
                                                                                type="button"
                                                                                className="ant-btn  ant-btn-default btn-dark"
                                                                            >
                                                                                <span>View details</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ant-row " />
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>



                            </>
                        ) : (

                            <>

                                <div className="ant-row ant-row-center ant-row-middle ">
                                    <div className="ant-col text-center ant-col-xs-24 ant-col-md-7 ">
                                        <img
                                            alt="No Pending/Answered Queries"
                                            loading="lazy"
                                            width={170}
                                            height={170}
                                            decoding="async"
                                            data-nimg={1}
                                            className=""
                                            src={packageempty}
                                            style={{ color: "transparent", height: "auto" }}
                                        />
                                        <div className="ant-space  ant-space-vertical ant-space-align-center ant-space-gap-row-middle ant-space-gap-col-middle">
                                            <div className="ant-space-item">
                                                <h5
                                                    className="ant-typography "
                                                    style={{ marginBottom: 0, marginTop: 16 }}
                                                >
                                                    {" "}
                                                    Create a package
                                                </h5>
                                            </div>
                                            <div className="ant-space-item">
                                                <div
                                                    className="ant-typography "
                                                    style={{ margin: 0 }}
                                                >
                                                    Packages are a follower favourite and get 24%better
                                                    conversions
                                                </div>
                                            </div>
                                            <div className="ant-space-item">
                                                <button
                                                    type="button"
                                                    className="ant-btn  ant-btn-primary btn-dark"
                                                    style={{ marginTop: 8 }}
                                                >
                                                    <span>+ Add New</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )
                    }

                </div>
            </div>
            {
                emailModal && (
                    <EmailDrawer emailModal={emailModal} setEmailModal={setEmailModal} ModalData={ModalData} />
                )
            }
            {
                packageDetailModal && (
                    <PackageDetailDrawer packageDetailModal={packageDetailModal} setPackageDetailModal={setPackageDetailModal} ModalData={ModalData} />
                )
            }
        </>
    );
}

export default PackagesUpcoming;
