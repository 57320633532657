import { message } from "antd";

export let isMobileView = window.innerWidth <= 768;

const NumericPattern = /^(0|[1-9][0-9]*)$/;
let date = new Date();
let datestring = date.toTimeString();
let countryname =
    datestring?.includes("india") | datestring?.includes("India")
        ? "India"
        : "";
export let currencyName = countryname === "India" ? "INR" : "USD";
export const timeZoneData = () => [
    {
        "value": "Dateline Standard Time",
        "abbr": "DST",
        "offset": -12,
        "isdst": false,
        "text": "(UTC-12:00) International Date Line West",
        "utc": [
            "Etc/GMT+12"
        ]
    },
    {
        "value": "UTC-11",
        "abbr": "U",
        "offset": -11,
        "isdst": false,
        "text": "(UTC-11:00) Coordinated Universal Time-11",
        "utc": [
            "Etc/GMT+11",
            "Pacific/Midway",
            "Pacific/Niue",
            "Pacific/Pago_Pago"
        ]
    },
    {
        "value": "Hawaiian Standard Time",
        "abbr": "HST",
        "offset": -10,
        "isdst": false,
        "text": "(UTC-10:00) Hawaii",
        "utc": [
            "Etc/GMT+10",
            "Pacific/Honolulu",
            "Pacific/Johnston",
            "Pacific/Rarotonga",
            "Pacific/Tahiti"
        ]
    },
    {
        "value": "Alaskan Standard Time",
        "abbr": "AKDT",
        "offset": -8,
        "isdst": true,
        "text": "(UTC-09:00) Alaska",
        "utc": [
            "America/Anchorage",
            "America/Juneau",
            "America/Nome",
            "America/Sitka",
            "America/Yakutat"
        ]
    },
    {
        "value": "Pacific Standard Time (Mexico)",
        "abbr": "PDT",
        "offset": -7,
        "isdst": true,
        "text": "(UTC-08:00) Baja California",
        "utc": [
            "America/Santa_Isabel"
        ]
    },
    {
        "value": "Pacific Daylight Time",
        "abbr": "PDT",
        "offset": -7,
        "isdst": true,
        "text": "(UTC-07:00) Pacific Daylight Time (US & Canada)",
        "utc": [
            "America/Los_Angeles",
            "America/Tijuana",
            "America/Vancouver"
        ]
    },
    {
        "value": "Pacific Standard Time",
        "abbr": "PST",
        "offset": -8,
        "isdst": false,
        "text": "(UTC-08:00) Pacific Standard Time (US & Canada)",
        "utc": [
            "America/Los_Angeles",
            "America/Tijuana",
            "America/Vancouver",
            "PST8PDT"
        ]
    },
    {
        "value": "US Mountain Standard Time",
        "abbr": "UMST",
        "offset": -7,
        "isdst": false,
        "text": "(UTC-07:00) Arizona",
        "utc": [
            "America/Creston",
            "America/Dawson",
            "America/Dawson_Creek",
            "America/Hermosillo",
            "America/Phoenix",
            "America/Whitehorse",
            "Etc/GMT+7"
        ]
    },
    {
        "value": "Mountain Standard Time (Mexico)",
        "abbr": "MDT",
        "offset": -6,
        "isdst": true,
        "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
        "utc": [
            "America/Chihuahua",
            "America/Mazatlan"
        ]
    },
    {
        "value": "Mountain Standard Time",
        "abbr": "MDT",
        "offset": -6,
        "isdst": true,
        "text": "(UTC-07:00) Mountain Time (US & Canada)",
        "utc": [
            "America/Boise",
            "America/Cambridge_Bay",
            "America/Denver",
            "America/Edmonton",
            "America/Inuvik",
            "America/Ojinaga",
            "America/Yellowknife",
            "MST7MDT"
        ]
    },
    {
        "value": "Central America Standard Time",
        "abbr": "CAST",
        "offset": -6,
        "isdst": false,
        "text": "(UTC-06:00) Central America",
        "utc": [
            "America/Belize",
            "America/Costa_Rica",
            "America/El_Salvador",
            "America/Guatemala",
            "America/Managua",
            "America/Tegucigalpa",
            "Etc/GMT+6",
            "Pacific/Galapagos"
        ]
    },
    {
        "value": "Central Standard Time",
        "abbr": "CDT",
        "offset": -5,
        "isdst": true,
        "text": "(UTC-06:00) Central Time (US & Canada)",
        "utc": [
            "America/Chicago",
            "America/Indiana/Knox",
            "America/Indiana/Tell_City",
            "America/Matamoros",
            "America/Menominee",
            "America/North_Dakota/Beulah",
            "America/North_Dakota/Center",
            "America/North_Dakota/New_Salem",
            "America/Rainy_River",
            "America/Rankin_Inlet",
            "America/Resolute",
            "America/Winnipeg",
            "CST6CDT"
        ]
    },
    {
        "value": "Central Standard Time (Mexico)",
        "abbr": "CDT",
        "offset": -5,
        "isdst": true,
        "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
        "utc": [
            "America/Bahia_Banderas",
            "America/Cancun",
            "America/Merida",
            "America/Mexico_City",
            "America/Monterrey"
        ]
    },
    {
        "value": "Canada Central Standard Time",
        "abbr": "CCST",
        "offset": -6,
        "isdst": false,
        "text": "(UTC-06:00) Saskatchewan",
        "utc": [
            "America/Regina",
            "America/Swift_Current"
        ]
    },
    {
        "value": "SA Pacific Standard Time",
        "abbr": "SPST",
        "offset": -5,
        "isdst": false,
        "text": "(UTC-05:00) Bogota, Lima, Quito",
        "utc": [
            "America/Bogota",
            "America/Cayman",
            "America/Coral_Harbour",
            "America/Eirunepe",
            "America/Guayaquil",
            "America/Jamaica",
            "America/Lima",
            "America/Panama",
            "America/Rio_Branco",
            "Etc/GMT+5"
        ]
    },
    {
        "value": "Eastern Standard Time",
        "abbr": "EST",
        "offset": -5,
        "isdst": false,
        "text": "(UTC-05:00) Eastern Time (US & Canada)",
        "utc": [
            "America/Detroit",
            "America/Havana",
            "America/Indiana/Petersburg",
            "America/Indiana/Vincennes",
            "America/Indiana/Winamac",
            "America/Iqaluit",
            "America/Kentucky/Monticello",
            "America/Louisville",
            "America/Montreal",
            "America/Nassau",
            "America/New_York",
            "America/Nipigon",
            "America/Pangnirtung",
            "America/Port-au-Prince",
            "America/Thunder_Bay",
            "America/Toronto"
        ]
    },
    {
        "value": "Eastern Daylight Time",
        "abbr": "EDT",
        "offset": -4,
        "isdst": true,
        "text": "(UTC-04:00) Eastern Daylight Time (US & Canada)",
        "utc": [
            "America/Detroit",
            "America/Havana",
            "America/Indiana/Petersburg",
            "America/Indiana/Vincennes",
            "America/Indiana/Winamac",
            "America/Iqaluit",
            "America/Kentucky/Monticello",
            "America/Louisville",
            "America/Montreal",
            "America/Nassau",
            "America/New_York",
            "America/Nipigon",
            "America/Pangnirtung",
            "America/Port-au-Prince",
            "America/Thunder_Bay",
            "America/Toronto"
        ]
    },
    {
        "value": "US Eastern Standard Time",
        "abbr": "UEDT",
        "offset": -5,
        "isdst": false,
        "text": "(UTC-05:00) Indiana (East)",
        "utc": [
            "America/Indiana/Marengo",
            "America/Indiana/Vevay",
            "America/Indianapolis"
        ]
    },
    {
        "value": "Venezuela Standard Time",
        "abbr": "VST",
        "offset": -4.5,
        "isdst": false,
        "text": "(UTC-04:30) Caracas",
        "utc": [
            "America/Caracas"
        ]
    },
    {
        "value": "Paraguay Standard Time",
        "abbr": "PYT",
        "offset": -4,
        "isdst": false,
        "text": "(UTC-04:00) Asuncion",
        "utc": [
            "America/Asuncion"
        ]
    },
    {
        "value": "Atlantic Standard Time",
        "abbr": "ADT",
        "offset": -3,
        "isdst": true,
        "text": "(UTC-04:00) Atlantic Time (Canada)",
        "utc": [
            "America/Glace_Bay",
            "America/Goose_Bay",
            "America/Halifax",
            "America/Moncton",
            "America/Thule",
            "Atlantic/Bermuda"
        ]
    },
    {
        "value": "Central Brazilian Standard Time",
        "abbr": "CBST",
        "offset": -4,
        "isdst": false,
        "text": "(UTC-04:00) Cuiaba",
        "utc": [
            "America/Campo_Grande",
            "America/Cuiaba"
        ]
    },
    {
        "value": "SA Western Standard Time",
        "abbr": "SWST",
        "offset": -4,
        "isdst": false,
        "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
        "utc": [
            "America/Anguilla",
            "America/Antigua",
            "America/Aruba",
            "America/Barbados",
            "America/Blanc-Sablon",
            "America/Boa_Vista",
            "America/Curacao",
            "America/Dominica",
            "America/Grand_Turk",
            "America/Grenada",
            "America/Guadeloupe",
            "America/Guyana",
            "America/Kralendijk",
            "America/La_Paz",
            "America/Lower_Princes",
            "America/Manaus",
            "America/Marigot",
            "America/Martinique",
            "America/Montserrat",
            "America/Port_of_Spain",
            "America/Porto_Velho",
            "America/Puerto_Rico",
            "America/Santo_Domingo",
            "America/St_Barthelemy",
            "America/St_Kitts",
            "America/St_Lucia",
            "America/St_Thomas",
            "America/St_Vincent",
            "America/Tortola",
            "Etc/GMT+4"
        ]
    },
    {
        "value": "Pacific SA Standard Time",
        "abbr": "PSST",
        "offset": -4,
        "isdst": false,
        "text": "(UTC-04:00) Santiago",
        "utc": [
            "America/Santiago",
            "Antarctica/Palmer"
        ]
    },
    {
        "value": "Newfoundland Standard Time",
        "abbr": "NDT",
        "offset": -2.5,
        "isdst": true,
        "text": "(UTC-03:30) Newfoundland",
        "utc": [
            "America/St_Johns"
        ]
    },
    {
        "value": "E. South America Standard Time",
        "abbr": "ESAST",
        "offset": -3,
        "isdst": false,
        "text": "(UTC-03:00) Brasilia",
        "utc": [
            "America/Sao_Paulo"
        ]
    },
    {
        "value": "Argentina Standard Time",
        "abbr": "AST",
        "offset": -3,
        "isdst": false,
        "text": "(UTC-03:00) Buenos Aires",
        "utc": [
            "America/Argentina/Buenos_Aires",
            "America/Argentina/Catamarca",
            "America/Argentina/Cordoba",
            "America/Argentina/Jujuy",
            "America/Argentina/La_Rioja",
            "America/Argentina/Mendoza",
            "America/Argentina/Rio_Gallegos",
            "America/Argentina/Salta",
            "America/Argentina/San_Juan",
            "America/Argentina/San_Luis",
            "America/Argentina/Tucuman",
            "America/Argentina/Ushuaia",
            "America/Buenos_Aires",
            "America/Catamarca",
            "America/Cordoba",
            "America/Jujuy",
            "America/Mendoza"
        ]
    },
    {
        "value": "SA Eastern Standard Time",
        "abbr": "SEST",
        "offset": -3,
        "isdst": false,
        "text": "(UTC-03:00) Cayenne, Fortaleza",
        "utc": [
            "America/Araguaina",
            "America/Belem",
            "America/Cayenne",
            "America/Fortaleza",
            "America/Maceio",
            "America/Paramaribo",
            "America/Recife",
            "America/Santarem",
            "Antarctica/Rothera",
            "Atlantic/Stanley",
            "Etc/GMT+3"
        ]
    },
    {
        "value": "Greenland Standard Time",
        "abbr": "GDT",
        "offset": -3,
        "isdst": true,
        "text": "(UTC-03:00) Greenland",
        "utc": [
            "America/Godthab"
        ]
    },
    {
        "value": "Montevideo Standard Time",
        "abbr": "MST",
        "offset": -3,
        "isdst": false,
        "text": "(UTC-03:00) Montevideo",
        "utc": [
            "America/Montevideo"
        ]
    },
    {
        "value": "Bahia Standard Time",
        "abbr": "BST",
        "offset": -3,
        "isdst": false,
        "text": "(UTC-03:00) Salvador",
        "utc": [
            "America/Bahia"
        ]
    },
    {
        "value": "UTC-02",
        "abbr": "U",
        "offset": -2,
        "isdst": false,
        "text": "(UTC-02:00) Coordinated Universal Time-02",
        "utc": [
            "America/Noronha",
            "Atlantic/South_Georgia",
            "Etc/GMT+2"
        ]
    },
    {
        "value": "Mid-Atlantic Standard Time",
        "abbr": "MDT",
        "offset": -1,
        "isdst": true,
        "text": "(UTC-02:00) Mid-Atlantic - Old",
        "utc": []
    },
    {
        "value": "Azores Standard Time",
        "abbr": "ADT",
        "offset": 0,
        "isdst": true,
        "text": "(UTC-01:00) Azores",
        "utc": [
            "America/Scoresbysund",
            "Atlantic/Azores"
        ]
    },
    {
        "value": "Cape Verde Standard Time",
        "abbr": "CVST",
        "offset": -1,
        "isdst": false,
        "text": "(UTC-01:00) Cape Verde Is.",
        "utc": [
            "Atlantic/Cape_Verde",
            "Etc/GMT+1"
        ]
    },
    {
        "value": "Morocco Standard Time",
        "abbr": "MDT",
        "offset": 1,
        "isdst": true,
        "text": "(UTC) Casablanca",
        "utc": [
            "Africa/Casablanca",
            "Africa/El_Aaiun"
        ]
    },
    {
        "value": "UTC",
        "abbr": "UTC",
        "offset": 0,
        "isdst": false,
        "text": "(UTC) Coordinated Universal Time",
        "utc": [
            "America/Danmarkshavn",
            "Etc/GMT"
        ]
    },
    {
        "value": "GMT Standard Time",
        "abbr": "GMT",
        "offset": 0,
        "isdst": false,
        "text": "(UTC) Edinburgh, London",
        "utc": [
            "Europe/Isle_of_Man",
            "Europe/Guernsey",
            "Europe/Jersey",
            "Europe/London"
        ]
    },
    {
        "value": "British Summer Time",
        "abbr": "BST",
        "offset": 1,
        "isdst": true,
        "text": "(UTC+01:00) Edinburgh, London",
        "utc": [
            "Europe/Isle_of_Man",
            "Europe/Guernsey",
            "Europe/Jersey",
            "Europe/London"
        ]
    },
    {
        "value": "GMT Standard Time",
        "abbr": "GDT",
        "offset": 1,
        "isdst": true,
        "text": "(UTC) Dublin, Lisbon",
        "utc": [
            "Atlantic/Canary",
            "Atlantic/Faeroe",
            "Atlantic/Madeira",
            "Europe/Dublin",
            "Europe/Lisbon"
        ]
    },
    {
        "value": "Greenwich Standard Time",
        "abbr": "GST",
        "offset": 0,
        "isdst": false,
        "text": "(UTC) Monrovia, Reykjavik",
        "utc": [
            "Africa/Abidjan",
            "Africa/Accra",
            "Africa/Bamako",
            "Africa/Banjul",
            "Africa/Bissau",
            "Africa/Conakry",
            "Africa/Dakar",
            "Africa/Freetown",
            "Africa/Lome",
            "Africa/Monrovia",
            "Africa/Nouakchott",
            "Africa/Ouagadougou",
            "Africa/Sao_Tome",
            "Atlantic/Reykjavik",
            "Atlantic/St_Helena"
        ]
    },
    {
        "value": "W. Europe Standard Time",
        "abbr": "WEDT",
        "offset": 2,
        "isdst": true,
        "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        "utc": [
            "Arctic/Longyearbyen",
            "Europe/Amsterdam",
            "Europe/Andorra",
            "Europe/Berlin",
            "Europe/Busingen",
            "Europe/Gibraltar",
            "Europe/Luxembourg",
            "Europe/Malta",
            "Europe/Monaco",
            "Europe/Oslo",
            "Europe/Rome",
            "Europe/San_Marino",
            "Europe/Stockholm",
            "Europe/Vaduz",
            "Europe/Vatican",
            "Europe/Vienna",
            "Europe/Zurich"
        ]
    },
    {
        "value": "Central Europe Standard Time",
        "abbr": "CEDT",
        "offset": 2,
        "isdst": true,
        "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        "utc": [
            "Europe/Belgrade",
            "Europe/Bratislava",
            "Europe/Budapest",
            "Europe/Ljubljana",
            "Europe/Podgorica",
            "Europe/Prague",
            "Europe/Tirane"
        ]
    },
    {
        "value": "Romance Standard Time",
        "abbr": "RDT",
        "offset": 2,
        "isdst": true,
        "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
        "utc": [
            "Africa/Ceuta",
            "Europe/Brussels",
            "Europe/Copenhagen",
            "Europe/Madrid",
            "Europe/Paris"
        ]
    },
    {
        "value": "Central European Standard Time",
        "abbr": "CEDT",
        "offset": 2,
        "isdst": true,
        "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
        "utc": [
            "Europe/Sarajevo",
            "Europe/Skopje",
            "Europe/Warsaw",
            "Europe/Zagreb"
        ]
    },
    {
        "value": "W. Central Africa Standard Time",
        "abbr": "WCAST",
        "offset": 1,
        "isdst": false,
        "text": "(UTC+01:00) West Central Africa",
        "utc": [
            "Africa/Algiers",
            "Africa/Bangui",
            "Africa/Brazzaville",
            "Africa/Douala",
            "Africa/Kinshasa",
            "Africa/Lagos",
            "Africa/Libreville",
            "Africa/Luanda",
            "Africa/Malabo",
            "Africa/Ndjamena",
            "Africa/Niamey",
            "Africa/Porto-Novo",
            "Africa/Tunis",
            "Etc/GMT-1"
        ]
    },
    {
        "value": "Namibia Standard Time",
        "abbr": "NST",
        "offset": 1,
        "isdst": false,
        "text": "(UTC+01:00) Windhoek",
        "utc": [
            "Africa/Windhoek"
        ]
    },
    {
        "value": "GTB Standard Time",
        "abbr": "GDT",
        "offset": 3,
        "isdst": true,
        "text": "(UTC+02:00) Athens, Bucharest",
        "utc": [
            "Asia/Nicosia",
            "Europe/Athens",
            "Europe/Bucharest",
            "Europe/Chisinau"
        ]
    },
    {
        "value": "Middle East Standard Time",
        "abbr": "MEDT",
        "offset": 3,
        "isdst": true,
        "text": "(UTC+02:00) Beirut",
        "utc": [
            "Asia/Beirut"
        ]
    },
    {
        "value": "Egypt Standard Time",
        "abbr": "EST",
        "offset": 2,
        "isdst": false,
        "text": "(UTC+02:00) Cairo",
        "utc": [
            "Africa/Cairo"
        ]
    },
    {
        "value": "Syria Standard Time",
        "abbr": "SDT",
        "offset": 3,
        "isdst": true,
        "text": "(UTC+02:00) Damascus",
        "utc": [
            "Asia/Damascus"
        ]
    },
    {
        "value": "E. Europe Standard Time",
        "abbr": "EEDT",
        "offset": 3,
        "isdst": true,
        "text": "(UTC+02:00) E. Europe",
        "utc": [
            "Asia/Nicosia",
            "Europe/Athens",
            "Europe/Bucharest",
            "Europe/Chisinau",
            "Europe/Helsinki",
            "Europe/Kyiv",
            "Europe/Mariehamn",
            "Europe/Nicosia",
            "Europe/Riga",
            "Europe/Sofia",
            "Europe/Tallinn",
            "Europe/Uzhgorod",
            "Europe/Vilnius",
            "Europe/Zaporozhye"

        ]
    },
    {
        "value": "South Africa Standard Time",
        "abbr": "SAST",
        "offset": 2,
        "isdst": false,
        "text": "(UTC+02:00) Harare, Pretoria",
        "utc": [
            "Africa/Blantyre",
            "Africa/Bujumbura",
            "Africa/Gaborone",
            "Africa/Harare",
            "Africa/Johannesburg",
            "Africa/Kigali",
            "Africa/Lubumbashi",
            "Africa/Lusaka",
            "Africa/Maputo",
            "Africa/Maseru",
            "Africa/Mbabane",
            "Etc/GMT-2"
        ]
    },
    {
        "value": "FLE Standard Time",
        "abbr": "FDT",
        "offset": 3,
        "isdst": true,
        "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        "utc": [
            "Europe/Helsinki",
            "Europe/Kyiv",
            "Europe/Mariehamn",
            "Europe/Riga",
            "Europe/Sofia",
            "Europe/Tallinn",
            "Europe/Uzhgorod",
            "Europe/Vilnius",
            "Europe/Zaporozhye"
        ]
    },
    {
        "value": "Turkey Standard Time",
        "abbr": "TDT",
        "offset": 3,
        "isdst": false,
        "text": "(UTC+03:00) Istanbul",
        "utc": [
            "Europe/Istanbul"
        ]
    },
    {
        "value": "Israel Standard Time",
        "abbr": "JDT",
        "offset": 3,
        "isdst": true,
        "text": "(UTC+02:00) Jerusalem",
        "utc": [
            "Asia/Jerusalem"
        ]
    },
    {
        "value": "Libya Standard Time",
        "abbr": "LST",
        "offset": 2,
        "isdst": false,
        "text": "(UTC+02:00) Tripoli",
        "utc": [
            "Africa/Tripoli"
        ]
    },
    {
        "value": "Jordan Standard Time",
        "abbr": "JST",
        "offset": 3,
        "isdst": false,
        "text": "(UTC+03:00) Amman",
        "utc": [
            "Asia/Amman"
        ]
    },
    {
        "value": "Arabic Standard Time",
        "abbr": "AST",
        "offset": 3,
        "isdst": false,
        "text": "(UTC+03:00) Baghdad",
        "utc": [
            "Asia/Baghdad"
        ]
    },
    {
        "value": "Kaliningrad Standard Time",
        "abbr": "KST",
        "offset": 3,
        "isdst": false,
        "text": "(UTC+02:00) Kaliningrad",
        "utc": [
            "Europe/Kaliningrad"
        ]
    },
    {
        "value": "Arab Standard Time",
        "abbr": "AST",
        "offset": 3,
        "isdst": false,
        "text": "(UTC+03:00) Kuwait, Riyadh",
        "utc": [
            "Asia/Aden",
            "Asia/Bahrain",
            "Asia/Kuwait",
            "Asia/Qatar",
            "Asia/Riyadh"
        ]
    },
    {
        "value": "E. Africa Standard Time",
        "abbr": "EAST",
        "offset": 3,
        "isdst": false,
        "text": "(UTC+03:00) Nairobi",
        "utc": [
            "Africa/Addis_Ababa",
            "Africa/Asmera",
            "Africa/Dar_es_Salaam",
            "Africa/Djibouti",
            "Africa/Juba",
            "Africa/Kampala",
            "Africa/Khartoum",
            "Africa/Mogadishu",
            "Africa/Nairobi",
            "Antarctica/Syowa",
            "Etc/GMT-3",
            "Indian/Antananarivo",
            "Indian/Comoro",
            "Indian/Mayotte"
        ]
    },
    {
        "value": "Moscow Standard Time",
        "abbr": "MSK",
        "offset": 3,
        "isdst": false,
        "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
        "utc": [
            "Europe/Kirov",
            "Europe/Moscow",
            "Europe/Simferopol",
            "Europe/Volgograd",
            "Europe/Minsk"
        ]
    },
    {
        "value": "Samara Time",
        "abbr": "SAMT",
        "offset": 4,
        "isdst": false,
        "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
        "utc": [
            "Europe/Astrakhan",
            "Europe/Samara",
            "Europe/Ulyanovsk"
        ]
    },
    {
        "value": "Iran Standard Time",
        "abbr": "IDT",
        "offset": 4.5,
        "isdst": true,
        "text": "(UTC+03:30) Tehran",
        "utc": [
            "Asia/Tehran"
        ]
    },
    {
        "value": "Arabian Standard Time",
        "abbr": "AST",
        "offset": 4,
        "isdst": false,
        "text": "(UTC+04:00) Abu Dhabi, Muscat",
        "utc": [
            "Asia/Dubai",
            "Asia/Muscat",
            "Etc/GMT-4"
        ]
    },
    {
        "value": "Azerbaijan Standard Time",
        "abbr": "ADT",
        "offset": 5,
        "isdst": true,
        "text": "(UTC+04:00) Baku",
        "utc": [
            "Asia/Baku"
        ]
    },
    {
        "value": "Mauritius Standard Time",
        "abbr": "MST",
        "offset": 4,
        "isdst": false,
        "text": "(UTC+04:00) Port Louis",
        "utc": [
            "Indian/Mahe",
            "Indian/Mauritius",
            "Indian/Reunion"
        ]
    },
    {
        "value": "Georgian Standard Time",
        "abbr": "GET",
        "offset": 4,
        "isdst": false,
        "text": "(UTC+04:00) Tbilisi",
        "utc": [
            "Asia/Tbilisi"
        ]
    },
    {
        "value": "Caucasus Standard Time",
        "abbr": "CST",
        "offset": 4,
        "isdst": false,
        "text": "(UTC+04:00) Yerevan",
        "utc": [
            "Asia/Yerevan"
        ]
    },
    {
        "value": "Afghanistan Standard Time",
        "abbr": "AST",
        "offset": 4.5,
        "isdst": false,
        "text": "(UTC+04:30) Kabul",
        "utc": [
            "Asia/Kabul"
        ]
    },
    {
        "value": "West Asia Standard Time",
        "abbr": "WAST",
        "offset": 5,
        "isdst": false,
        "text": "(UTC+05:00) Ashgabat, Tashkent",
        "utc": [
            "Antarctica/Mawson",
            "Asia/Aqtau",
            "Asia/Aqtobe",
            "Asia/Ashgabat",
            "Asia/Dushanbe",
            "Asia/Oral",
            "Asia/Samarkand",
            "Asia/Tashkent",
            "Etc/GMT-5",
            "Indian/Kerguelen",
            "Indian/Maldives"
        ]
    },
    {
        "value": "Yekaterinburg Time",
        "abbr": "YEKT",
        "offset": 5,
        "isdst": false,
        "text": "(UTC+05:00) Yekaterinburg",
        "utc": [
            "Asia/Yekaterinburg"
        ]
    },
    {
        "value": "Pakistan Standard Time",
        "abbr": "PKT",
        "offset": 5,
        "isdst": false,
        "text": "(UTC+05:00) Islamabad, Karachi",
        "utc": [
            "Asia/Karachi"
        ]
    },
    {
        "value": "India Standard Time",
        "abbr": "IST",
        "offset": 5.5,
        "isdst": false,
        "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        "utc": [
            "Asia/Kolkata",
            "Asia/Calcutta"
        ]
    },
    {
        "value": "Sri Lanka Standard Time",
        "abbr": "SLST",
        "offset": 5.5,
        "isdst": false,
        "text": "(UTC+05:30) Sri Jayawardenepura",
        "utc": [
            "Asia/Colombo"
        ]
    },
    {
        "value": "Nepal Standard Time",
        "abbr": "NST",
        "offset": 5.75,
        "isdst": false,
        "text": "(UTC+05:45) Kathmandu",
        "utc": [
            "Asia/Kathmandu"
        ]
    },
    {
        "value": "Central Asia Standard Time",
        "abbr": "CAST",
        "offset": 6,
        "isdst": false,
        "text": "(UTC+06:00) Nur-Sultan (Astana)",
        "utc": [
            "Antarctica/Vostok",
            "Asia/Almaty",
            "Asia/Bishkek",
            "Asia/Qyzylorda",
            "Asia/Urumqi",
            "Etc/GMT-6",
            "Indian/Chagos"
        ]
    },
    {
        "value": "Bangladesh Standard Time",
        "abbr": "BST",
        "offset": 6,
        "isdst": false,
        "text": "(UTC+06:00) Dhaka",
        "utc": [
            "Asia/Dhaka",
            "Asia/Thimphu"
        ]
    },
    {
        "value": "Myanmar Standard Time",
        "abbr": "MST",
        "offset": 6.5,
        "isdst": false,
        "text": "(UTC+06:30) Yangon (Rangoon)",
        "utc": [
            "Asia/Rangoon",
            "Indian/Cocos"
        ]
    },
    {
        "value": "SE Asia Standard Time",
        "abbr": "SAST",
        "offset": 7,
        "isdst": false,
        "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
        "utc": [
            "Antarctica/Davis",
            "Asia/Bangkok",
            "Asia/Hovd",
            "Asia/Jakarta",
            "Asia/Phnom_Penh",
            "Asia/Pontianak",
            "Asia/Saigon",
            "Asia/Vientiane",
            "Etc/GMT-7",
            "Indian/Christmas"
        ]
    },
    {
        "value": "N. Central Asia Standard Time",
        "abbr": "NCAST",
        "offset": 7,
        "isdst": false,
        "text": "(UTC+07:00) Novosibirsk",
        "utc": [
            "Asia/Novokuznetsk",
            "Asia/Novosibirsk",
            "Asia/Omsk"
        ]
    },
    {
        "value": "China Standard Time",
        "abbr": "CST",
        "offset": 8,
        "isdst": false,
        "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        "utc": [
            "Asia/Hong_Kong",
            "Asia/Macau",
            "Asia/Shanghai"
        ]
    },
    {
        "value": "North Asia Standard Time",
        "abbr": "NAST",
        "offset": 8,
        "isdst": false,
        "text": "(UTC+08:00) Krasnoyarsk",
        "utc": [
            "Asia/Krasnoyarsk"
        ]
    },
    {
        "value": "Singapore Standard Time",
        "abbr": "MPST",
        "offset": 8,
        "isdst": false,
        "text": "(UTC+08:00) Kuala Lumpur, Singapore",
        "utc": [
            "Asia/Brunei",
            "Asia/Kuala_Lumpur",
            "Asia/Kuching",
            "Asia/Makassar",
            "Asia/Manila",
            "Asia/Singapore",
            "Etc/GMT-8"
        ]
    },
    {
        "value": "W. Australia Standard Time",
        "abbr": "WAST",
        "offset": 8,
        "isdst": false,
        "text": "(UTC+08:00) Perth",
        "utc": [
            "Antarctica/Casey",
            "Australia/Perth"
        ]
    },
    {
        "value": "Taipei Standard Time",
        "abbr": "TST",
        "offset": 8,
        "isdst": false,
        "text": "(UTC+08:00) Taipei",
        "utc": [
            "Asia/Taipei"
        ]
    },
    {
        "value": "Ulaanbaatar Standard Time",
        "abbr": "UST",
        "offset": 8,
        "isdst": false,
        "text": "(UTC+08:00) Ulaanbaatar",
        "utc": [
            "Asia/Choibalsan",
            "Asia/Ulaanbaatar"
        ]
    },
    {
        "value": "North Asia East Standard Time",
        "abbr": "NAEST",
        "offset": 8,
        "isdst": false,
        "text": "(UTC+08:00) Irkutsk",
        "utc": [
            "Asia/Irkutsk"
        ]
    },
    {
        "value": "Japan Standard Time",
        "abbr": "JST",
        "offset": 9,
        "isdst": false,
        "text": "(UTC+09:00) Osaka, Sapporo, Tokyo",
        "utc": [
            "Asia/Dili",
            "Asia/Jayapura",
            "Asia/Tokyo",
            "Etc/GMT-9",
            "Pacific/Palau"
        ]
    },
    {
        "value": "Korea Standard Time",
        "abbr": "KST",
        "offset": 9,
        "isdst": false,
        "text": "(UTC+09:00) Seoul",
        "utc": [
            "Asia/Pyongyang",
            "Asia/Seoul"
        ]
    },
    {
        "value": "Cen. Australia Standard Time",
        "abbr": "CAST",
        "offset": 9.5,
        "isdst": false,
        "text": "(UTC+09:30) Adelaide",
        "utc": [
            "Australia/Adelaide",
            "Australia/Broken_Hill"
        ]
    },
    {
        "value": "AUS Central Standard Time",
        "abbr": "ACST",
        "offset": 9.5,
        "isdst": false,
        "text": "(UTC+09:30) Darwin",
        "utc": [
            "Australia/Darwin"
        ]
    },
    {
        "value": "E. Australia Standard Time",
        "abbr": "EAST",
        "offset": 10,
        "isdst": false,
        "text": "(UTC+10:00) Brisbane",
        "utc": [
            "Australia/Brisbane",
            "Australia/Lindeman"
        ]
    },
    {
        "value": "AUS Eastern Standard Time",
        "abbr": "AEST",
        "offset": 10,
        "isdst": false,
        "text": "(UTC+10:00) Canberra, Melbourne, Sydney",
        "utc": [
            "Australia/Melbourne",
            "Australia/Sydney"
        ]
    },
    {
        "value": "West Pacific Standard Time",
        "abbr": "WPST",
        "offset": 10,
        "isdst": false,
        "text": "(UTC+10:00) Guam, Port Moresby",
        "utc": [
            "Antarctica/DumontDUrville",
            "Etc/GMT-10",
            "Pacific/Guam",
            "Pacific/Port_Moresby",
            "Pacific/Saipan",
            "Pacific/Truk"
        ]
    },
    {
        "value": "Tasmania Standard Time",
        "abbr": "TST",
        "offset": 10,
        "isdst": false,
        "text": "(UTC+10:00) Hobart",
        "utc": [
            "Australia/Currie",
            "Australia/Hobart"
        ]
    },
    {
        "value": "Yakutsk Standard Time",
        "abbr": "YST",
        "offset": 9,
        "isdst": false,
        "text": "(UTC+09:00) Yakutsk",
        "utc": [
            "Asia/Chita",
            "Asia/Khandyga",
            "Asia/Yakutsk"
        ]
    },
    {
        "value": "Central Pacific Standard Time",
        "abbr": "CPST",
        "offset": 11,
        "isdst": false,
        "text": "(UTC+11:00) Solomon Is., New Caledonia",
        "utc": [
            "Antarctica/Macquarie",
            "Etc/GMT-11",
            "Pacific/Efate",
            "Pacific/Guadalcanal",
            "Pacific/Kosrae",
            "Pacific/Noumea",
            "Pacific/Ponape"
        ]
    },
    {
        "value": "Vladivostok Standard Time",
        "abbr": "VST",
        "offset": 11,
        "isdst": false,
        "text": "(UTC+11:00) Vladivostok",
        "utc": [
            "Asia/Sakhalin",
            "Asia/Ust-Nera",
            "Asia/Vladivostok"
        ]
    },
    {
        "value": "New Zealand Standard Time",
        "abbr": "NZST",
        "offset": 12,
        "isdst": false,
        "text": "(UTC+12:00) Auckland, Wellington",
        "utc": [
            "Antarctica/McMurdo",
            "Pacific/Auckland"
        ]
    },
    {
        "value": "UTC+12",
        "abbr": "U",
        "offset": 12,
        "isdst": false,
        "text": "(UTC+12:00) Coordinated Universal Time+12",
        "utc": [
            "Etc/GMT-12",
            "Pacific/Funafuti",
            "Pacific/Kwajalein",
            "Pacific/Majuro",
            "Pacific/Nauru",
            "Pacific/Tarawa",
            "Pacific/Wake",
            "Pacific/Wallis"
        ]
    },
    {
        "value": "Fiji Standard Time",
        "abbr": "FST",
        "offset": 12,
        "isdst": false,
        "text": "(UTC+12:00) Fiji",
        "utc": [
            "Pacific/Fiji"
        ]
    },
    {
        "value": "Magadan Standard Time",
        "abbr": "MST",
        "offset": 12,
        "isdst": false,
        "text": "(UTC+12:00) Magadan",
        "utc": [
            "Asia/Anadyr",
            "Asia/Kamchatka",
            "Asia/Magadan",
            "Asia/Srednekolymsk"
        ]
    },
    {
        "value": "Kamchatka Standard Time",
        "abbr": "KDT",
        "offset": 13,
        "isdst": true,
        "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
        "utc": [
            "Asia/Kamchatka"
        ]
    },
    {
        "value": "Tonga Standard Time",
        "abbr": "TST",
        "offset": 13,
        "isdst": false,
        "text": "(UTC+13:00) Nuku'alofa",
        "utc": [
            "Etc/GMT-13",
            "Pacific/Enderbury",
            "Pacific/Fakaofo",
            "Pacific/Tongatapu"
        ]
    },
    {
        "value": "Samoa Standard Time",
        "abbr": "SST",
        "offset": 13,
        "isdst": false,
        "text": "(UTC+13:00) Samoa",
        "utc": [
            "Pacific/Apia"
        ]
    }
]
export const BookingExampleData = {
    "count": 4,
    "next": null,
    "previous": null,
    "results": [
        {
            "expert": 59863,
            "service": {
                "id": 725312,
                "title": "call",
                "description": null,
                "type": 1,
                "original_charge": 0,
                "show_discount_code": false,
                "display_suggested_price": {
                    "updated_price": 200,
                    "currency": "INR",
                    "display_text": "₹"
                },
                "suggested_support_my_work": 200,
                "duration": 122,
                "charge": 0,
                "user": 59863,
                "is_donation": false,
                "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--SifmduoS--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:call,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:122%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                "currency": {
                    "code": "INR",
                    "currency": "Indian Rupee",
                    "display_text": "₹"
                },
                "suggested_price": 200,
                "follower_charge": {
                    "updated_price": 0,
                    "currency": "INR",
                    "currency_symbol": "₹"
                }
            },
            "consumer_name": "Rahul",
            "consumer_email": "codewithrahul@gmail.com",
            "consumer_phone": "+911211021212",
            "extra_info": null,
            "time": "2024-01-14T11:02:00+05:30",
            "id": "272575de-127b-451e-837d-807f9b1e7c4d",
            "status": "upcoming",
            "price": 0,
            "answers_json": [
                {
                    "answer": "information calling",
                    "question": "What is the call about?",
                    "question_id": "452468"
                },
                {
                    "answer": "codewithrahul@gmail.com",
                    "question": "Email"
                },
                {
                    "answer": "+911211021212",
                    "question": "Phone"
                }
            ],
            "videocall_link_expert": "https://mentorpal.ai/dashboard/calls/video/upcoming?id=272575de-127b-451e-837d-807f9b1e7c4d",
            "videocall_link_follower": "https://mentorpal.ai/videocallv2/SbkGmeYtBGSUj8uDoDczVG",
            "duration": 122,
            "price_for_expert": 0,
            "cancellation_reason": null,
            "reschedule_request": null,
            "info": "",
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "join_call_url": null,
            "issue_type": 0,
            "issue_comment": null,
            "group_call_count": 0,
            "testimonial": null,
            "booking_review": null,
            "package": null,
            "call_experience": null,
            "review_count": null,
            "testimonial_count": null,
            "avg_ratings": 0
        },
        {
            "expert": 59863,
            "service": {
                "id": 725312,
                "title": "call",
                "description": null,
                "type": 1,
                "original_charge": 0,
                "show_discount_code": false,
                "display_suggested_price": {
                    "updated_price": 200,
                    "currency": "INR",
                    "display_text": "₹"
                },
                "suggested_support_my_work": 200,
                "duration": 122,
                "charge": 0,
                "user": 59863,
                "is_donation": false,
                "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--SifmduoS--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:call,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:122%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                "currency": {
                    "code": "INR",
                    "currency": "Indian Rupee",
                    "display_text": "₹"
                },
                "suggested_price": 200,
                "follower_charge": {
                    "updated_price": 0,
                    "currency": "INR",
                    "currency_symbol": "₹"
                }
            },
            "consumer_name": "vibha",
            "consumer_email": "daskjfak@gmail.com",
            "consumer_phone": "+913242345253",
            "extra_info": null,
            "time": "2024-01-28T09:00:00+05:30",
            "id": "b8e87552-b0cc-412c-adcf-3d0ec20609b1",
            "status": "upcoming",
            "price": 0,
            "answers_json": [
                {
                    "answer": "kdfjafdj k jfdlkaj akjfdla f",
                    "question": "What is the call about?",
                    "question_id": "452468"
                },
                {
                    "answer": "daskjfak@gmail.com",
                    "question": "Email"
                },
                {
                    "answer": "+913242345253",
                    "question": "Phone"
                }
            ],
            "videocall_link_expert": "https://mentorpal.ai/dashboard/calls/video/upcoming?id=b8e87552-b0cc-412c-adcf-3d0ec20609b1",
            "videocall_link_follower": "https://mentorpal.ai/videocallv2/KhTdNAnHJujPeow7hwRd5C",
            "duration": 122,
            "price_for_expert": 0,
            "cancellation_reason": null,
            "reschedule_request": null,
            "info": "",
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "join_call_url": null,
            "issue_type": 0,
            "issue_comment": null,
            "group_call_count": 0,
            "testimonial": null,
            "booking_review": null,
            "package": null,
            "call_experience": null,
            "review_count": null,
            "testimonial_count": null,
            "avg_ratings": 0
        },
        {
            "expert": 59863,
            "service": {
                "id": 725312,
                "title": "call",
                "description": null,
                "type": 1,
                "original_charge": 0,
                "show_discount_code": false,
                "display_suggested_price": {
                    "updated_price": 200,
                    "currency": "INR",
                    "display_text": "₹"
                },
                "suggested_support_my_work": 200,
                "duration": 122,
                "charge": 0,
                "user": 59863,
                "is_donation": false,
                "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--SifmduoS--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:call,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20topmate.io%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:122%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                "currency": {
                    "code": "INR",
                    "currency": "Indian Rupee",
                    "display_text": "₹"
                },
                "suggested_price": 200,
                "follower_charge": {
                    "updated_price": 0,
                    "currency": "INR",
                    "currency_symbol": "₹"
                },
                "how_to_use": null
            },
            "consumer_name": "Rohan",
            "consumer_email": "rohan.officail@gmail.com",
            "consumer_phone": "+919050306208",
            "extra_info": null,
            "time": "2024-02-17T09:00:00+05:30",
            "id": "b3e11e30-8e26-4ce3-9912-0ca71265d960",
            "status": "upcoming",
            "price": 0,
            "answers_json": [
                {
                    "answer": "mentorship",
                    "question": "What is the call about?",
                    "question_id": "452468"
                },
                {
                    "answer": "rohan.officail@gmail.com",
                    "question": "Email"
                },
                {
                    "answer": "+919050306208",
                    "question": "Phone"
                }
            ],
            "videocall_link_expert": "https://topmate.io/dashboard/calls/video/upcoming?id=b3e11e30-8e26-4ce3-9912-0ca71265d960",
            "videocall_link_follower": "https://topmate.io/videocallv2/PKVrUpw6vHkaf62Auek8k9",
            "duration": 122,
            "price_for_expert": 0,
            "cancellation_reason": null,
            "reschedule_request": null,
            "info": "",
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "join_call_url": null,
            "issue_type": 0,
            "issue_comment": null,
            "group_call_count": 0,
            "testimonial": null,
            "booking_review": null,
            "package": null,
            "call_experience": null,
            "review_count": null,
            "testimonial_count": null,
            "avg_ratings": 0
        },
        {
            "expert": 59863,
            "service": {
                "id": 727072,
                "title": "sdfgyjk",
                "description": "",
                "type": 1,
                "original_charge": 0,
                "show_discount_code": false,
                "display_suggested_price": null,
                "suggested_support_my_work": 200,
                "duration": 22,
                "charge": 0,
                "user": 59863,
                "is_donation": false,
                "shareable_image": null,
                "currency": {
                    "code": "INR",
                    "currency": "Indian Rupee",
                    "display_text": "₹"
                },
                "suggested_price": 0,
                "follower_charge": {
                    "updated_price": 0,
                    "currency": "INR",
                    "currency_symbol": "₹"
                },
                "how_to_use": null
            },
            "consumer_name": "Ankit",
            "consumer_email": "main.ankit047@gmail.com",
            "consumer_phone": "+918930395399",
            "extra_info": null,
            "time": "2024-02-24T09:44:00+05:30",
            "id": "003bcaa8-2e38-4d8e-8719-da96583ee8bb",
            "status": "upcoming",
            "price": 0,
            "answers_json": [
                {
                    "answer": "mentorship",
                    "question": "What is the call about?",
                    "question_id": "453969"
                },
                {
                    "answer": "main.ankit047@gmail.com",
                    "question": "Email"
                },
                {
                    "answer": "+918930395399",
                    "question": "Phone"
                }
            ],
            "videocall_link_expert": "https://topmate.io/dashboard/calls/video/upcoming?id=003bcaa8-2e38-4d8e-8719-da96583ee8bb",
            "videocall_link_follower": "https://topmate.io/videocallv2/6TdWAHiiYpcndKRedTHFib",
            "duration": 22,
            "price_for_expert": 0,
            "cancellation_reason": null,
            "reschedule_request": null,
            "info": "",
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "join_call_url": null,
            "issue_type": 0,
            "issue_comment": null,
            "group_call_count": 0,
            "testimonial": null,
            "booking_review": null,
            "package": null,
            "call_experience": null,
            "review_count": null,
            "testimonial_count": null,
            "avg_ratings": 0
        }

    ]
}
export const CompleteCallData = {
    "count": 2,
    "next": null,
    "previous": null,
    "results": [
        {
            "expert": 59863,
            "service": {
                "id": 725312,
                "title": "call",
                "description": null,
                "type": 1,
                "original_charge": 0,
                "show_discount_code": false,
                "display_suggested_price": {
                    "updated_price": 200,
                    "currency": "INR",
                    "display_text": "₹"
                },
                "suggested_support_my_work": 200,
                "duration": 122,
                "charge": 0,
                "user": 59863,
                "is_donation": false,
                "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--SifmduoS--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:call,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:122%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                "currency": {
                    "code": "INR",
                    "currency": "Indian Rupee",
                    "display_text": "₹"
                },
                "suggested_price": 200,
                "follower_charge": {
                    "updated_price": 0,
                    "currency": "INR",
                    "currency_symbol": "₹"
                }
            },
            "consumer_name": "Rahul",
            "consumer_email": "codewithrahul@gmail.com",
            "consumer_phone": "+911211021212",
            "extra_info": null,
            "time": "2024-01-14T11:02:00+05:30",
            "id": "272575de-127b-451e-837d-807f9b1e7c4d",
            "status": "cancelled",
            "price": 0,
            "answers_json": [
                {
                    "answer": "information calling",
                    "question": "What is the call about?",
                    "question_id": "452468"
                },
                {
                    "answer": "codewithrahul@gmail.com",
                    "question": "Email"
                },
                {
                    "answer": "+911211021212",
                    "question": "Phone"
                }
            ],
            "videocall_link_expert": "https://mentorpal.ai/dashboard/calls/video/upcoming?id=272575de-127b-451e-837d-807f9b1e7c4d",
            "videocall_link_follower": "https://mentorpal.ai/videocallv2/SbkGmeYtBGSUj8uDoDczVG",
            "duration": 122,
            "price_for_expert": 0,
            "cancellation_reason": "Normal",
            "reschedule_request": null,
            "info": "",
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "join_call_url": null,
            "issue_type": 0,
            "issue_comment": null,
            "group_call_count": 0,
            "testimonial": null,
            "booking_review": null,
            "package": null,
            "call_experience": null,
            "review_count": null,
            "testimonial_count": null,
            "avg_ratings": 0
        },
        {
            "expert": 59863,
            "service": {
                "id": 727072,
                "title": "sdfgyjk",
                "description": "",
                "type": 1,
                "original_charge": 0,
                "show_discount_code": false,
                "display_suggested_price": null,
                "suggested_support_my_work": 200,
                "duration": 22,
                "charge": 0,
                "user": 59863,
                "is_donation": false,
                "shareable_image": null,
                "currency": {
                    "code": "INR",
                    "currency": "Indian Rupee",
                    "display_text": "₹"
                },
                "suggested_price": 0,
                "follower_charge": {
                    "updated_price": 0,
                    "currency": "INR",
                    "currency_symbol": "₹"
                }
            },
            "consumer_name": "Ankit",
            "consumer_email": "codewithankit047@gmail.com",
            "consumer_phone": "+918930395398",
            "extra_info": null,
            "time": "2024-01-11T22:00:10+05:30",
            "id": "cf661944-8f54-42e1-b706-431a98df0282",
            "status": "completed",
            "price": 0,
            "answers_json": [
                {
                    "answer": "Mentorship",
                    "question": "What is the call about?",
                    "question_id": "453969"
                },
                {
                    "answer": "codewithankit047@gmail.com",
                    "question": "Email"
                },
                {
                    "answer": "+918930395398",
                    "question": "Phone"
                }
            ],
            "videocall_link_expert": "https://mentorpal.ai/dashboard/calls/video/upcoming?id=cf661944-8f54-42e1-b706-431a98df0282",
            "videocall_link_follower": "https://mentorpal.ai/videocallv2/jzbrowZQr9TSwRwwCvCy2T",
            "duration": 22,
            "price_for_expert": 0,
            "cancellation_reason": null,
            "reschedule_request": null,
            "info": "",
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "join_call_url": null,
            "issue_type": 5,
            "issue_comment": null,
            "group_call_count": 0,
            "testimonial": null,
            "booking_review": null,
            "package": null,
            "call_experience": null,
            "review_count": null,
            "testimonial_count": null,
            "avg_ratings": 0
        }
    ]
}
export const isMobile = () => {
    // Check if the userAgent contains any keywords indicating a mobile device
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const convertUTCtoDate = (utcTime) => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };

    const localDate = new Date(utcTime).toLocaleString('en-US', options);
    return localDate;
};
export const convertTimeRangeToLocal = (time, duration) => {
    const utcOffset = parseInt(time.slice(-6), 10);
    const [startHour, startMinute] = time.slice(11, 16).split(':').map(Number);

    const convertToTimezone = (hour, minute) => new Date(0, 0, 0, hour, minute - utcOffset).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    const localStart = convertToTimezone(startHour, startMinute);
    const localEnd = convertToTimezone(startHour + Math.floor(duration / 60), (startMinute + duration) % 60);

    return `${localStart} - ${localEnd} (GMT${utcOffset >= 0 ? '+' : ''}${utcOffset / 60})`;
};


export const createTimeList = (diff, start_at, prepend) => {
    let hours, minutes, ampm, thf;
    const createTimeList = [];
    let oneTimeObject = {};

    if (diff === undefined || isNaN(diff)) {
        diff = 30;
    }
    let i_start = 0;
    if (start_at && start_at < 24) {
        i_start = start_at * 60;
    }
    const i_end = 1430 + i_start;

    for (let i = i_start; i <= i_end; i += diff) {
        hours = Math.floor(i / 60);
        thf = hours >= 24 ? hours - 24 : hours;
        minutes = i % 60;
        if (minutes < 10) {
            minutes = "0" + minutes; // adding leading zero
        }
        ampm = hours % 24 < 12 ? "AM" : "PM";
        hours = hours % 12;
        if (hours === 0) {
            hours = 12;
        }
        hours = hours < 10 ? hours : hours;
        thf = thf < 10 ? thf : thf;

        oneTimeObject = {
            key: (prepend ? prepend : "") + thf + ":" + minutes,
            value: thf + ":" + minutes,
            compare: thf * minutes,
            label: hours + ":" + minutes + " " + ampm,
        };
        createTimeList.push(oneTimeObject);
    }
    return createTimeList;
};

// Required for downloading image files
export const toDataURL = (url) => {
    return fetch(url)
        .then((response) => {
            return response.blob();
        })
        .then((blob) => {
            return URL.createObjectURL(blob);
        });
};




export const packageBookingUpcoming = {
    "count": 1,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": "4faa68a5-ae78-4dd5-8973-67619fdf00b9",
            "order": null,
            "should_skip_payment": true,
            "redirect_url": null,
            "coupon_code": null,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "subscription_start_time": null,
            "service": {
                "id": 731153,
                "title": "fds",
                "description": "",
                "type": 4,
                "original_charge": 0,
                "suggested_price": 0,
                "display_suggested_price": null,
                "duration": 0,
                "charge": 0,
                "user": 59863,
                "specific_date": null,
                "follower_charge": {
                    "updated_price": 0,
                    "currency": "INR",
                    "currency_symbol": "₹"
                },
                "follower_total_charge": null,
                "is_subscription_enabled": false
            },
            "package_service_coupons": [
                {
                    "id": 58527,
                    "service": {
                        "id": 731127,
                        "title": "sfhgdfhgf",
                        "description": "",
                        "type": 3,
                        "original_charge": 0,
                        "suggested_price": 0,
                        "display_suggested_price": null,
                        "duration": 30,
                        "charge": 0,
                        "user": 59863,
                        "specific_date": "2024-01-26T21:30:02+05:30",
                        "follower_charge": {
                            "updated_price": 0,
                            "currency": "INR",
                            "currency_symbol": "₹"
                        },
                        "follower_total_charge": null,
                        "is_subscription_enabled": false
                    },
                    "total_count": 1,
                    "remaining_count": 0,
                    "details": [
                        {
                            "status": "Upcoming",
                            "coupon_link": null,
                            "id": "d86b55ad-c640-4608-91d7-c6b4a62cfb93",
                            "time": "2024-01-26T16:00:02Z",
                            "expert_timezone": "Asia/Kolkata",
                            "join_link": "https://meet.google.com/zbi-pvbj-grm"
                        }
                    ]
                },
                {
                    "id": 58528,
                    "service": {
                        "id": 731116,
                        "title": "Digital Product",
                        "description": "",
                        "type": 5,
                        "original_charge": 0,
                        "suggested_price": 0,
                        "display_suggested_price": null,
                        "duration": 0,
                        "charge": 0,
                        "user": 59863,
                        "specific_date": null,
                        "follower_charge": {
                            "updated_price": 0,
                            "currency": "INR",
                            "currency_symbol": "₹"
                        },
                        "follower_total_charge": null,
                        "is_subscription_enabled": false
                    },
                    "total_count": 1,
                    "remaining_count": 0,
                    "details": [
                        {
                            "status": "Delivered",
                            "coupon_link": null,
                            "id": "d467d330-0621-418a-ad16-e19a899c5e61",
                            "download_url": "",
                            "links": [
                                {
                                    "name": "android.pdf",
                                    "url": "https://topmate-staging.s3.ap-south-1.amazonaws.com/gDyzn5JMGYgtrDoVqLUuK2.pdf",
                                    "thumbnail_url": null,
                                    "type": "1"
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": 58529,
                    "service": {
                        "id": 727072,
                        "title": "sdfgyjk",
                        "description": "",
                        "type": 1,
                        "original_charge": 0,
                        "suggested_price": 0,
                        "display_suggested_price": null,
                        "duration": 22,
                        "charge": 0,
                        "user": 59863,
                        "specific_date": null,
                        "follower_charge": {
                            "updated_price": 0,
                            "currency": "INR",
                            "currency_symbol": "₹"
                        },
                        "follower_total_charge": null,
                        "is_subscription_enabled": false
                    },
                    "total_count": 1,
                    "remaining_count": 1,
                    "details": [
                        {
                            "status": "Not scheduled",
                            "coupon_link": "https://mentorpal.ai/ankit18/727072?coupon_code=bfe49a73-4c8e-443a-bb73-093353c44be0&package_id=4faa68a5-ae78-4dd5-8973-67619fdf00b9"
                        }
                    ]
                },
                {
                    "id": 58530,
                    "service": {
                        "id": 725312,
                        "title": "call",
                        "description": null,
                        "type": 1,
                        "original_charge": 0,
                        "suggested_price": 200,
                        "display_suggested_price": {
                            "updated_price": 200,
                            "currency": "INR",
                            "display_text": "₹"
                        },
                        "duration": 122,
                        "charge": 0,
                        "user": 59863,
                        "specific_date": null,
                        "follower_charge": {
                            "updated_price": 0,
                            "currency": "INR",
                            "currency_symbol": "₹"
                        },
                        "follower_total_charge": null,
                        "is_subscription_enabled": false
                    },
                    "total_count": 1,
                    "remaining_count": 1,
                    "details": [
                        {
                            "status": "Not scheduled",
                            "coupon_link": "https://mentorpal.ai/ankit18/725312?coupon_code=518083c1-ddea-49ec-96d1-0c34250df7f1&package_id=4faa68a5-ae78-4dd5-8973-67619fdf00b9"
                        }
                    ]
                },
                {
                    "id": 58531,
                    "service": {
                        "id": 725256,
                        "title": "Priority DM",
                        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                        "type": 2,
                        "original_charge": 0,
                        "suggested_price": 200,
                        "display_suggested_price": {
                            "updated_price": 200,
                            "currency": "INR",
                            "display_text": "₹"
                        },
                        "duration": 60,
                        "charge": 0,
                        "user": 59863,
                        "specific_date": null,
                        "follower_charge": {
                            "updated_price": 0,
                            "currency": "INR",
                            "currency_symbol": "₹"
                        },
                        "follower_total_charge": null,
                        "is_subscription_enabled": false
                    },
                    "total_count": 1,
                    "remaining_count": 1,
                    "details": [
                        {
                            "status": "Not sent",
                            "coupon_link": "https://mentorpal.ai/ankit18/725256/pay?coupon_code=ae28d2e3-41ff-4f8a-8bf3-d7511e42d6d4&package_id=4faa68a5-ae78-4dd5-8973-67619fdf00b9"
                        }
                    ]
                },
                {
                    "id": 58532,
                    "service": {
                        "id": 244425,
                        "title": "Priority DM",
                        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                        "type": 2,
                        "original_charge": 0,
                        "suggested_price": 0,
                        "display_suggested_price": null,
                        "duration": 0,
                        "charge": 0,
                        "user": 59863,
                        "specific_date": null,
                        "follower_charge": {
                            "updated_price": 0,
                            "currency": "INR",
                            "currency_symbol": "₹"
                        },
                        "follower_total_charge": null,
                        "is_subscription_enabled": false
                    },
                    "total_count": 1,
                    "remaining_count": 1,
                    "details": [
                        {
                            "status": "Not sent",
                            "coupon_link": "https://mentorpal.ai/ankit18/244425/pay?coupon_code=6aeafe67-39d5-4cc6-ab82-e2686505c4f5&package_id=4faa68a5-ae78-4dd5-8973-67619fdf00b9"
                        }
                    ]
                },
                {
                    "id": 58533,
                    "service": {
                        "id": 244424,
                        "title": "Have a question?",
                        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                        "type": 2,
                        "original_charge": 0,
                        "suggested_price": 0,
                        "display_suggested_price": null,
                        "duration": 0,
                        "charge": 0,
                        "user": 59863,
                        "specific_date": null,
                        "follower_charge": {
                            "updated_price": 0,
                            "currency": "INR",
                            "currency_symbol": "₹"
                        },
                        "follower_total_charge": null,
                        "is_subscription_enabled": false
                    },
                    "total_count": 1,
                    "remaining_count": 1,
                    "details": [
                        {
                            "status": "Not sent",
                            "coupon_link": "https://mentorpal.ai/ankit18/244424/pay?coupon_code=0feef534-2917-4c9d-9386-83b8cac54299&package_id=4faa68a5-ae78-4dd5-8973-67619fdf00b9"
                        }
                    ]
                }
            ],
            "service_count": 7,
            "reply_to_email_link": "https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=4faa68a5-ae78-4dd5-8973-67619fdf00b9_booking_expert@smart-email.mentorpal.ai&su=Regarding fds",
            "expert": {
                "id": 59863,
                "first_name": "Ankit",
                "last_name": "",
                "profile_pic": null,
                "username": "ankit18",
                "full_name": "Ankit",
                "timezone": "Asia/Kolkata",
                "theme_details": {
                    "theme": "#7b5fc6",
                    "backgroundColor": "#7b5fc6"
                },
                "display_name": "Ankit"
            },
            "created": "2024-01-13T19:11:43.387376+05:30",
            "modified_date": "2024-01-13T19:11:54.966692+05:30",
            "consumer_name": "afdsfas",
            "consumer_email": "raohn.ankit@gmail.com",
            "consumer_phone": "+913423452354",
            "price": 0,
            "fair_price": 0,
            "expert_earnings": 0,
            "ip_address": "42.105.78.37",
            "subscribe_to_whatsapp": true,
            "testing": false,
            "cancellation_reason": null,
            "consumer_timezone": "Asia/Kolkata",
            "discount": 0,
            "discount_borne_by": "topmate",
            "answers_json": [],
            "status": "ongoing",
            "coupon": null
        }
    ]
}

export const webinarBookingUpcoming = {
    "count": 1,
    "next": null,
    "previous": null,
    "results": [
        {
            "expert": 59863,
            "service": {
                "id": 731127,
                "title": "sfhgdfhgf",
                "description": "",
                "type": 3,
                "original_charge": 0,
                "show_discount_code": false,
                "display_suggested_price": null,
                "suggested_support_my_work": 200,
                "duration": 30,
                "charge": 0,
                "user": 59863,
                "is_donation": false,
                "shareable_image": null,
                "currency": {
                    "code": "INR",
                    "currency": "Indian Rupee",
                    "display_text": "₹"
                },
                "suggested_price": 0,
                "follower_charge": {
                    "updated_price": 0,
                    "currency": "INR",
                    "currency_symbol": "₹"
                }
            },
            "consumer_name": "Ankit",
            "consumer_email": "codewithankit047@gmail.com",
            "consumer_phone": "+918930395398",
            "extra_info": null,
            "time": "2024-01-26T21:30:02+05:30",
            "id": "d3b99df9-cdfe-4a20-93b0-d5615cc6b468",
            "status": "upcoming",
            "price": 0,
            "answers_json": [
                {
                    "answer": "codewithankit047@gmail.com",
                    "question": "Email"
                },
                {
                    "answer": "+918930395398",
                    "question": "Phone"
                }
            ],
            "videocall_link_expert": "https://mentorpal.ai/dashboard/calls/webinar/upcoming?id=d3b99df9-cdfe-4a20-93b0-d5615cc6b468",
            "videocall_link_follower": "https://mentorpal.ai/videocallv2/ajHAk9wf44c8xJaTzMgeGr",
            "duration": 30,
            "price_for_expert": 0,
            "cancellation_reason": null,
            "reschedule_request": null,
            "info": "",
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "join_call_url": null,
            "issue_type": 0,
            "issue_comment": null,
            "group_call_count": 2,
            "testimonial": null,
            "booking_review": null,
            "package": null,
            "call_experience": null,
            "review_count": 0,
            "testimonial_count": 0,
            "avg_ratings": 0
        }
    ]
}
export const digitalProductData = {
    "count": 2,
    "next": null,
    "previous": null,
    "results": [
        {
            "consumer_name": "Ankit",
            "consumer_email": "codewithankit047@gmail.com",
            "consumer_phone": "+918930395398",
            "currency": "INR",
            "price": 0,
            "review": null
        },
        {
            "consumer_name": "afdsfas",
            "consumer_email": "raohn.ankit@gmail.com",
            "consumer_phone": "+913423452354",
            "currency": "INR",
            "price": 0,
            "review": null
        }
    ]
}
export const digitalPrductMainData = [
    {
        "service": {
            "id": 731116,
            "title": "Digital Product",
            "description": "",
            "type": 5,
            "original_charge": 0,
            "show_discount_code": false,
            "display_suggested_price": null,
            "suggested_support_my_work": 200,
            "duration": 0,
            "charge": 0,
            "user": 59863,
            "is_donation": false,
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--YhNlB79c--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Digital%20Product,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:archive_munutl,o_100,w_50,x_350,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:Document,w_850,x_425,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "follower_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            }
        },
        "avg_ratings": 0,
        "total_ratings": 0,
        "total_testimonials": 0,
        "total_earnings": 0,
        "total_sales": 2
    }
]
export const oneOnOneserviceData = {
    "count": 8,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 244418,
            "title": "Quick chat",
            "description": "Want to get on a 1-on1 call with me and have a brief discussion about what I do, how I can help you or talk about any brief query you have under the umbrella of interview prep? Book a call with me and I\\'ll see you inside. The only reason to keep a nominal charge is to let me have a chance to speak with people who genuinely want to connect.",
            "short_custom_description": null,
            "duration": 30,
            "charge": 500,
            "user": 59863,
            "questions": [
                {
                    "id": 53784,
                    "created": "2023-04-21T16:24:31.367859+05:30",
                    "modified_date": "2023-04-21T16:24:31.367888+05:30",
                    "question": "What is the call about?",
                    "type": "text",
                    "compulsory": true,
                    "service": 244418
                }
            ],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 0,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 1,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Video Meeting",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": false,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": null,
            "document_url": null,
            "package_validity_in_months": 0,
            "suggested_support_my_work": 625.0,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 500,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": null,
            "upsell_services": [
                244423,
                244422
            ],
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--v9CX_B3I--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Quick%20chat,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:30%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "visits": 1.0,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [
                {
                    "id": 244423,
                    "title": "1:1 Mentorship",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                },
                {
                    "id": 244422,
                    "title": "Career guidance",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                }
            ],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        },
        {
            "id": 244419,
            "title": "Interview prep & tips",
            "description": "If you have an Interview due soon and feel unprepared, don\\'t worry because I\\'ve got you. In this call, I give you solid tips and discuss the frequently asked questions which will help you get an idea of the process, build confidence and ace the interview for the job/internship you\\'ve been eyeing for long.",
            "short_custom_description": null,
            "duration": 30,
            "charge": 500,
            "user": 59863,
            "questions": [
                {
                    "id": 53785,
                    "created": "2023-04-21T16:24:31.399616+05:30",
                    "modified_date": "2023-04-21T16:24:31.399643+05:30",
                    "question": "What is the call about?",
                    "type": "text",
                    "compulsory": true,
                    "service": 244419
                }
            ],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 0,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 1,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Video Meeting",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": false,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": null,
            "document_url": null,
            "package_validity_in_months": 0,
            "suggested_support_my_work": 625.0,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 500,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": null,
            "upsell_services": [
                244418,
                244423
            ],
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--A21Ep93S--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Interview%20prep%20%26%20tips,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:30%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "visits": 2.0,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 625,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [
                {
                    "id": 244418,
                    "title": "Quick chat",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                },
                {
                    "id": 244423,
                    "title": "1:1 Mentorship",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                }
            ],
            "display_suggested_price": {
                "updated_price": 625,
                "currency": "INR",
                "display_text": "₹"
            },
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        },
        {
            "id": 244420,
            "title": "Mock interview",
            "description": "The best way to ace an interview is to do Mock Interviews with a friend or me! In this call, I will help you in your interview preparation for your next job/internship through a Mock Session. This session will be structured in line with the industry and role you are looking for and help you get a feel of how it really feels to be in an interview. ",
            "short_custom_description": null,
            "duration": 60,
            "charge": 1000,
            "user": 59863,
            "questions": [
                {
                    "id": 53786,
                    "created": "2023-04-21T16:24:31.428962+05:30",
                    "modified_date": "2023-04-21T16:24:31.428989+05:30",
                    "question": "What is the call about?",
                    "type": "text",
                    "compulsory": true,
                    "service": 244420
                }
            ],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 0,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 1,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Video Meeting",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": false,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": null,
            "document_url": null,
            "package_validity_in_months": 0,
            "suggested_support_my_work": 1250.0,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 1000,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": null,
            "upsell_services": [
                244418,
                244419
            ],
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--dRFQFPW2--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Mock%20interview,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:60%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "visits": null,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [
                {
                    "id": 244418,
                    "title": "Quick chat",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                },
                {
                    "id": 244419,
                    "title": "Interview prep & tips",
                    "type": 1,
                    "suggested_price": 625,
                    "display_suggested_price": {
                        "updated_price": 625,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                }
            ],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        },
        {
            "id": 244421,
            "title": "Resume review",
            "description": "In this call, I will review your Resume through and through, help you on how to customise your resume according to the role you are applying for, suggest changes if needed, give you tips on how you can make your resume ATS friendly and solve any queries you might have related to your resume.",
            "short_custom_description": null,
            "duration": 30,
            "charge": 500,
            "user": 59863,
            "questions": [
                {
                    "id": 53787,
                    "created": "2023-04-21T16:24:31.457773+05:30",
                    "modified_date": "2023-04-21T16:24:31.457797+05:30",
                    "question": "What is the call about?",
                    "type": "text",
                    "compulsory": true,
                    "service": 244421
                }
            ],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 0,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 1,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Video Meeting",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": false,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": null,
            "document_url": null,
            "package_validity_in_months": 0,
            "suggested_support_my_work": 625.0,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 500,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": null,
            "upsell_services": [
                244420,
                244418
            ],
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--uMERj6AI--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Resume%20review,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:30%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "visits": null,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [
                {
                    "id": 244420,
                    "title": "Mock interview",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 1000,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 1000,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 60,
                    "suggested_support_my_work": 1250.0
                },
                {
                    "id": 244418,
                    "title": "Quick chat",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                }
            ],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        },
        {
            "id": 244422,
            "title": "Career guidance",
            "description": "In this call, I will address in detail any query you may have regarding your career, any obstacles you may be facing and help you gain clarity on further steps to take and decisions to make when it comes to your planning your career.",
            "short_custom_description": null,
            "duration": 30,
            "charge": 500,
            "user": 59863,
            "questions": [
                {
                    "id": 53788,
                    "created": "2023-04-21T16:24:31.485075+05:30",
                    "modified_date": "2023-04-21T16:24:31.485100+05:30",
                    "question": "What is the call about?",
                    "type": "text",
                    "compulsory": true,
                    "service": 244422
                }
            ],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 0,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 1,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Video Meeting",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": false,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": null,
            "document_url": null,
            "package_validity_in_months": 0,
            "suggested_support_my_work": 625.0,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 500,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": null,
            "upsell_services": [
                244420,
                244418
            ],
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--YotVjPi5--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Career%20guidance,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:30%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "visits": null,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [
                {
                    "id": 244420,
                    "title": "Mock interview",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 1000,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 1000,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 60,
                    "suggested_support_my_work": 1250.0
                },
                {
                    "id": 244418,
                    "title": "Quick chat",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                }
            ],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        },
        {
            "id": 244423,
            "title": "1:1 Mentorship",
            "description": "Do you have a major interview due soon but feel unprepared and anxious? Book a 1:1 call with me where I provide step-by-step actionable and practical tips to move past these momentary blocks and ace your next interview.",
            "short_custom_description": null,
            "duration": 30,
            "charge": 500,
            "user": 59863,
            "questions": [
                {
                    "id": 53789,
                    "created": "2023-04-21T16:24:31.514698+05:30",
                    "modified_date": "2023-04-21T16:24:31.514722+05:30",
                    "question": "What is the call about?",
                    "type": "text",
                    "compulsory": true,
                    "service": 244423
                }
            ],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 0,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 1,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Video Meeting",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": false,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": null,
            "document_url": null,
            "package_validity_in_months": 0,
            "suggested_support_my_work": 625.0,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 500,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": null,
            "upsell_services": [
                244420,
                244422
            ],
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--YJwZXwXj--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:1:1%20Mentorship,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:30%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "visits": 1.0,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [
                {
                    "id": 244420,
                    "title": "Mock interview",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 1000,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 1000,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 60,
                    "suggested_support_my_work": 1250.0
                },
                {
                    "id": 244422,
                    "title": "Career guidance",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                }
            ],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        },
        {
            "id": 725312,
            "title": "call",
            "description": null,
            "short_custom_description": null,
            "duration": 122,
            "charge": 0,
            "user": 59863,
            "questions": [
                {
                    "id": 452468,
                    "created": "2024-01-08T21:33:06.166618+05:30",
                    "modified_date": "2024-01-08T21:33:06.166679+05:30",
                    "question": "What is the call about?",
                    "type": "text",
                    "compulsory": true,
                    "service": 725312
                }
            ],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 2,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": true,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 1,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Video Meeting",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": false,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": true,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": null,
            "document_url": null,
            "package_validity_in_months": 0,
            "suggested_support_my_work": 200,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": null,
            "upsell_services": [
                244420,
                244422
            ],
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--SifmduoS--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:call,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:122%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "visits": 4.0,
            "earnings": 0,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 200,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [
                {
                    "id": 244420,
                    "title": "Mock interview",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 1000,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 1000,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 60,
                    "suggested_support_my_work": 1250.0
                },
                {
                    "id": 244422,
                    "title": "Career guidance",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                }
            ],
            "display_suggested_price": {
                "updated_price": 200,
                "currency": "INR",
                "display_text": "₹"
            },
            "links": [],
            "avg_ratings": 0,
            "booking_count": 1,
            "group_call_url": "",
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        },
        {
            "id": 727072,
            "title": "sdfgyjk",
            "description": "",
            "short_custom_description": "",
            "duration": 22,
            "charge": 0,
            "user": 59863,
            "questions": [
                {
                    "id": 453969,
                    "created": "2024-01-09T19:41:04.512397+05:30",
                    "modified_date": "2024-01-09T19:41:04.512428+05:30",
                    "question": "What is the call about?",
                    "type": "text",
                    "compulsory": true,
                    "service": 727072
                }
            ],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 1,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 1,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Video Meeting",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": false,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": true,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": "",
            "document_url": "",
            "package_validity_in_months": 0,
            "suggested_support_my_work": 200,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": "Webinar",
            "upsell_services": [
                244420,
                244422
            ],
            "shareable_image": null,
            "visits": 2.0,
            "earnings": 0,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [
                {
                    "id": 244420,
                    "title": "Mock interview",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 1000,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 1000,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 60,
                    "suggested_support_my_work": 1250.0
                },
                {
                    "id": 244422,
                    "title": "Career guidance",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                }
            ],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 5.0,
            "booking_count": 1,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        }
    ]
}

export const webinarServiceData = {
    "count": 1,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 731127,
            "title": "sfhgdfhgf",
            "description": "",
            "short_custom_description": "",
            "duration": 30,
            "charge": 0,
            "user": 59863,
            "questions": [],
            "skip_payment": false,
            "is_visible": false,
            "enabled": true,
            "schedule": null,
            "bookings_count": 2,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 3,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Webinar",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": "2024-01-26T21:30:02+05:30",
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": true,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": "",
            "document_url": "",
            "package_validity_in_months": 0,
            "suggested_support_my_work": 200,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": "Cohort",
            "upsell_services": [
                244420,
                244422
            ],
            "shareable_image": null,
            "visits": 1.0,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 13,
            "upsell_services_details": [
                {
                    "id": 244420,
                    "title": "Mock interview",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 1000,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 1000,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 60,
                    "suggested_support_my_work": 1250.0
                },
                {
                    "id": 244422,
                    "title": "Career guidance",
                    "type": 1,
                    "suggested_price": 0,
                    "display_suggested_price": null,
                    "original_charge": 0,
                    "charge": 500,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "duration": 30,
                    "suggested_support_my_work": 625.0
                }
            ],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": "https://meet.google.com/zbi-pvbj-grm",
            "cohort_weekdays": [
                "Sun",
                "Mon",
                "Tue",
                "Wed",
                "Thu",
                "Fri",
                "Sat"
            ],
            "suggested_service": {
                "id": 244418,
                "title": "Quick chat",
                "type": 1,
                "suggested_price": 0,
                "display_suggested_price": null,
                "original_charge": 0,
                "charge": 500,
                "currency": {
                    "code": "INR",
                    "currency": "Indian Rupee",
                    "display_text": "₹"
                },
                "follower_charge": {
                    "updated_price": 500,
                    "currency": "INR",
                    "currency_symbol": "₹"
                },
                "duration": 30,
                "suggested_support_my_work": 625.0
            },
            "is_subscription_enabled": false,
            "subscription_config": null
        }
    ]
}
export const priorityDmServiceData = {
    "count": 3,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 244424,
            "title": "Have a question?",
            "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
            "short_custom_description": null,
            "duration": 0,
            "charge": 0,
            "user": 59863,
            "questions": [],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 0,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 2,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Priority DM",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": true,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": null,
            "document_url": null,
            "package_validity_in_months": 0,
            "suggested_support_my_work": 200,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": null,
            "upsell_services": [
                244420,
                244422
            ],
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--MiR1IsvL--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Have%20a%20question%3F,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:send_xj3xnm,o_100,w_50,x_350,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:Text%20query,w_850,x_425,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "visits": null,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        },
        {
            "id": 244425,
            "title": "Priority DM",
            "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
            "short_custom_description": null,
            "duration": 0,
            "charge": 0,
            "user": 59863,
            "questions": [],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 0,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 2,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Priority DM",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": true,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": null,
            "document_url": null,
            "package_validity_in_months": 0,
            "suggested_support_my_work": 200,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": null,
            "upsell_services": [
                244420,
                244422
            ],
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--uNinMl11--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Priority%20DM,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:send_xj3xnm,o_100,w_50,x_350,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:Text%20query,w_850,x_425,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "visits": null,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        },
        {
            "id": 725256,
            "title": "Priority DM",
            "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
            "short_custom_description": "",
            "duration": 60,
            "charge": 0,
            "user": 59863,
            "questions": [],
            "skip_payment": false,
            "is_visible": true,
            "enabled": true,
            "schedule": null,
            "bookings_count": 4,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 2,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "Priority DM",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": true,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": "",
            "document_url": "",
            "package_validity_in_months": 0,
            "suggested_support_my_work": 200,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": "Webinar",
            "upsell_services": [
                244420,
                244422
            ],
            "shareable_image": null,
            "visits": null,
            "earnings": 0,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 200,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [],
            "display_suggested_price": {
                "updated_price": 200,
                "currency": "INR",
                "display_text": "₹"
            },
            "links": [],
            "avg_ratings": 0,
            "booking_count": 1,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        }
    ]
}
export const DigitalProductSericeData = {
    "count": 1,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 731116,
            "title": "Digital Product",
            "description": "",
            "short_custom_description": "rgfgsdfgs fgsdfgs sdg sgf s",
            "duration": 0,
            "charge": 0,
            "user": 59863,
            "questions": [],
            "skip_payment": false,
            "is_visible": false,
            "enabled": true,
            "schedule": null,
            "bookings_count": 2,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": true,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 5,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": true,
            "package_services": [],
            "total_charge": 0,
            "session_count": 0,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": "",
            "document_url": "",
            "package_validity_in_months": 0,
            "suggested_support_my_work": 200,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": "Webinar",
            "upsell_services": [],
            "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--YhNlB79c--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Digital%20Product,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:archive_munutl,o_100,w_50,x_350,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:Document,w_850,x_425,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
            "visits": 1,
            "earnings": 0,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [],
            "display_suggested_price": null,
            "links": [
                {
                    "id": 34657,
                    "service": {
                        "id": 731116,
                        "title": "Digital Product",
                        "description": "",
                        "type": 5,
                        "original_charge": 0,
                        "show_discount_code": false,
                        "display_suggested_price": null,
                        "suggested_support_my_work": 200,
                        "duration": 0,
                        "charge": 0,
                        "user": 59863,
                        "is_donation": false,
                        "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--YhNlB79c--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Digital%20Product,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:archive_munutl,o_100,w_50,x_350,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:Document,w_850,x_425,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                        "currency": {
                            "code": "INR",
                            "currency": "Indian Rupee",
                            "display_text": "₹"
                        },
                        "suggested_price": 0,
                        "follower_charge": {
                            "updated_price": 0,
                            "currency": "INR",
                            "currency_symbol": "₹"
                        }
                    },
                    "created": "2024-01-11T19:57:56.270171+05:30",
                    "modified_date": "2024-01-11T19:57:56.270219+05:30",
                    "url": "https://topmate-staging.s3.ap-south-1.amazonaws.com/gDyzn5JMGYgtrDoVqLUuK2.pdf",
                    "thumbnail_url": null,
                    "name": "android.pdf",
                    "type": "1",
                    "number_of_pages": 0,
                    "size": 0,
                    "is_deleted": false
                }
            ],
            "avg_ratings": 0,
            "booking_count": 2,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        }
    ]
}
export const packageServiceData = {
    "count": 1,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 731153,
            "title": "fds",
            "description": "",
            "short_custom_description": "",
            "duration": 0,
            "charge": 0,
            "user": 59863,
            "questions": [],
            "skip_payment": false,
            "is_visible": false,
            "enabled": true,
            "schedule": null,
            "bookings_count": 1,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 4,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": true,
            "package_services": [
                {
                    "id": 24273,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 731127,
                        "title": "sfhgdfhgf",
                        "description": "",
                        "type": 3,
                        "specific_date": "2024-01-26T21:30:02+05:30",
                        "duration": 30,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:33.965192+05:30",
                    "modified_date": "2024-01-11T20:11:33.965224+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24274,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 731116,
                        "title": "Digital Product",
                        "description": "",
                        "type": 5,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:33.974732+05:30",
                    "modified_date": "2024-01-11T20:11:33.974764+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24275,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 727072,
                        "title": "sdfgyjk",
                        "description": "",
                        "type": 1,
                        "specific_date": null,
                        "duration": 22,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:33.982863+05:30",
                    "modified_date": "2024-01-11T20:11:33.982893+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24276,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 725312,
                        "title": "call",
                        "description": null,
                        "type": 1,
                        "specific_date": null,
                        "duration": 122,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:33.991240+05:30",
                    "modified_date": "2024-01-11T20:11:33.991269+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24277,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 725256,
                        "title": "Priority DM",
                        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                        "type": 2,
                        "specific_date": null,
                        "duration": 60,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:33.999478+05:30",
                    "modified_date": "2024-01-11T20:11:33.999509+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24278,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 244425,
                        "title": "Priority DM",
                        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                        "type": 2,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:34.007952+05:30",
                    "modified_date": "2024-01-11T20:11:34.007984+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24279,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 244424,
                        "title": "Have a question?",
                        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                        "type": 2,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:34.017253+05:30",
                    "modified_date": "2024-01-11T20:11:34.017287+05:30",
                    "count": 1,
                    "is_deleted": false
                }
            ],
            "total_charge": 0,
            "session_count": 7,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": "",
            "document_url": "",
            "package_validity_in_months": 0,
            "suggested_support_my_work": 200,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": "Webinar",
            "upsell_services": [
                244418,
                244423
            ],
            "shareable_image": null,
            "visits": 1,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        }
    ]
}
export const subscriptionServiceData = {
    "count": 1,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 731153,
            "title": "fds",
            "description": "",
            "short_custom_description": "",
            "duration": 0,
            "charge": 0,
            "user": 59863,
            "questions": [],
            "skip_payment": false,
            "is_visible": false,
            "enabled": true,
            "schedule": null,
            "bookings_count": 1,
            "username": "ankit18",
            "show_seats_left": false,
            "show_sale_count": false,
            "show_bookings_count": false,
            "is_donation": false,
            "dynamic_questions": [],
            "promised_response_time": 48,
            "type": 4,
            "recording_charge": 0,
            "images": [],
            "show_extra_info": true,
            "tweak_meta_data": [],
            "short_description": "",
            "original_charge": 0,
            "record_session": false,
            "can_edit_date": true,
            "thumbnail_images": [],
            "specific_date": null,
            "bookings_limit": null,
            "group_call": false,
            "has_flexible_charge": true,
            "package_services": [
                {
                    "id": 24273,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 731127,
                        "title": "sfhgdfhgf",
                        "description": "",
                        "type": 3,
                        "specific_date": "2024-01-26T21:30:02+05:30",
                        "duration": 30,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:33.965192+05:30",
                    "modified_date": "2024-01-11T20:11:33.965224+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24274,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 731116,
                        "title": "Digital Product",
                        "description": "",
                        "type": 5,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:33.974732+05:30",
                    "modified_date": "2024-01-11T20:11:33.974764+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24275,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 727072,
                        "title": "sdfgyjk",
                        "description": "",
                        "type": 1,
                        "specific_date": null,
                        "duration": 22,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:33.982863+05:30",
                    "modified_date": "2024-01-11T20:11:33.982893+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24276,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 725312,
                        "title": "call",
                        "description": null,
                        "type": 1,
                        "specific_date": null,
                        "duration": 122,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:33.991240+05:30",
                    "modified_date": "2024-01-11T20:11:33.991269+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24277,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 725256,
                        "title": "Priority DM",
                        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                        "type": 2,
                        "specific_date": null,
                        "duration": 60,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:33.999478+05:30",
                    "modified_date": "2024-01-11T20:11:33.999509+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24278,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 244425,
                        "title": "Priority DM",
                        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                        "type": 2,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:34.007952+05:30",
                    "modified_date": "2024-01-11T20:11:34.007984+05:30",
                    "count": 1,
                    "is_deleted": false
                },
                {
                    "id": 24279,
                    "package": {
                        "id": 731153,
                        "title": "fds",
                        "description": "",
                        "type": 4,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "service": {
                        "id": 244424,
                        "title": "Have a question?",
                        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                        "type": 2,
                        "specific_date": null,
                        "duration": 0,
                        "charge": 0,
                        "user": {
                            "id": 59863,
                            "first_name": "Ankit",
                            "last_name": "",
                            "profile_pic": null,
                            "username": "ankit18",
                            "full_name": "Ankit",
                            "timezone": "Asia/Kolkata",
                            "theme_details": {
                                "theme": "#7b5fc6",
                                "backgroundColor": "#7b5fc6"
                            },
                            "display_name": "Ankit"
                        },
                        "is_donation": false
                    },
                    "created": "2024-01-11T20:11:34.017253+05:30",
                    "modified_date": "2024-01-11T20:11:34.017287+05:30",
                    "count": 1,
                    "is_deleted": false
                }
            ],
            "total_charge": 0,
            "session_count": 7,
            "currency": {
                "code": "INR",
                "currency": "Indian Rupee",
                "display_text": "₹"
            },
            "break_after_call": 0,
            "custom_text": null,
            "booking_upsell": true,
            "number_of_pages": 0,
            "document_thumbnail_url": "",
            "document_url": "",
            "package_validity_in_months": 0,
            "suggested_support_my_work": 200,
            "ppp_enabled": false,
            "show_discount_code": false,
            "coupons": null,
            "follower_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "follower_total_charge": {
                "updated_price": 0,
                "currency": "INR",
                "currency_symbol": "₹"
            },
            "cohort_noun": "Webinar",
            "upsell_services": [
                244418,
                244423
            ],
            "shareable_image": null,
            "visits": 1,
            "earnings": null,
            "display_original_charge": {
                "updated_price": 0,
                "currency": "INR",
                "display_text": "₹"
            },
            "suggested_price": 0,
            "cohort_no_of_sessions": 1,
            "upsell_services_details": [],
            "display_suggested_price": null,
            "links": [],
            "avg_ratings": 0,
            "booking_count": 0,
            "group_call_url": null,
            "cohort_weekdays": [],
            "suggested_service": null,
            "is_subscription_enabled": false,
            "subscription_config": null
        }
    ]
}
export const ServiceTemplateData = [
    {
        "id": 402,
        "title": "Priority DM",
        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
        "duration": 0,
        "charge": 0,
        "default_selected": false,
        "question": null,
        "question_type": null,
        "promised_response_time": 48,
        "type": 2,
        "order": 59,
        "country": "IN",
        "expertise": 11
    },
    {
        "id": 400,
        "title": "Ask me anything",
        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
        "duration": 0,
        "charge": 0,
        "default_selected": false,
        "question": null,
        "question_type": null,
        "promised_response_time": 48,
        "type": 2,
        "order": 57,
        "country": "IN",
        "expertise": 11
    },
    {
        "id": 398,
        "title": "Have a question?",
        "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
        "duration": 0,
        "charge": 0,
        "default_selected": false,
        "question": null,
        "question_type": null,
        "promised_response_time": 48,
        "type": 2,
        "order": 55,
        "country": "IN",
        "expertise": 11
    },
    {
        "id": 178,
        "title": "Resume review",
        "description": "In this call, I will review your Resume through and through, help you on how to customise your resume according to the role you are applying for, suggest changes if needed, give you tips on how you can make your resume ATS friendly and solve any queries you might have related to your resume.",
        "duration": 30,
        "charge": 500,
        "default_selected": false,
        "question": null,
        "question_type": null,
        "promised_response_time": 48,
        "type": 1,
        "order": 0,
        "country": "IN",
        "expertise": 11
    },
    {
        "id": 180,
        "title": "Career guidance",
        "description": "In this call, I will address in detail any query you may have regarding your career, any obstacles you may be facing and help you gain clarity on further steps to take and decisions to make when it comes to your planning your career.",
        "duration": 30,
        "charge": 500,
        "default_selected": false,
        "question": null,
        "question_type": null,
        "promised_response_time": 48,
        "type": 1,
        "order": 0,
        "country": "IN",
        "expertise": 11
    },
    {
        "id": 182,
        "title": "1:1 Mentorship",
        "description": "Do you have a major interview due soon but feel unprepared and anxious? Book a 1:1 call with me where I provide step-by-step actionable and practical tips to move past these momentary blocks and ace your next interview.",
        "duration": 30,
        "charge": 500,
        "default_selected": false,
        "question": null,
        "question_type": null,
        "promised_response_time": 48,
        "type": 1,
        "order": 0,
        "country": "IN",
        "expertise": 11
    },
    {
        "id": 172,
        "title": "Quick chat",
        "description": "Want to get on a 1-on1 call with me and have a brief discussion about what I do, how I can help you or talk about any brief query you have under the umbrella of interview prep? Book a call with me and I\\'ll see you inside. The only reason to keep a nominal charge is to let me have a chance to speak with people who genuinely want to connect.",
        "duration": 30,
        "charge": 500,
        "default_selected": true,
        "question": null,
        "question_type": null,
        "promised_response_time": 48,
        "type": 1,
        "order": 0,
        "country": "IN",
        "expertise": 11
    },
    {
        "id": 448,
        "title": "Discovery Call",
        "description": "Don't know how I can help you? Then book this quick discovery call where I'll try to learn more about you and we'll find ways to help you reach your goals",
        "duration": 15,
        "charge": 0,
        "default_selected": true,
        "question": null,
        "question_type": null,
        "promised_response_time": 48,
        "type": 1,
        "order": 0,
        "country": "IN",
        "expertise": 11
    },
    {
        "id": 174,
        "title": "Interview prep & tips",
        "description": "If you have an Interview due soon and feel unprepared, don\\'t worry because I\\'ve got you. In this call, I give you solid tips and discuss the frequently asked questions which will help you get an idea of the process, build confidence and ace the interview for the job/internship you\\'ve been eyeing for long.",
        "duration": 30,
        "charge": 500,
        "default_selected": true,
        "question": null,
        "question_type": null,
        "promised_response_time": 48,
        "type": 1,
        "order": 0,
        "country": "IN",
        "expertise": 11
    },
    {
        "id": 176,
        "title": "Mock interview",
        "description": "The best way to ace an interview is to do Mock Interviews with a friend or me! In this call, I will help you in your interview preparation for your next job/internship through a Mock Session. This session will be structured in line with the industry and role you are looking for and help you get a feel of how it really feels to be in an interview. ",
        "duration": 60,
        "charge": 1000,
        "default_selected": true,
        "question": null,
        "question_type": null,
        "promised_response_time": 48,
        "type": 1,
        "order": 0,
        "country": "IN",
        "expertise": 11
    },
    {
        "id": 190,
        "title": "Mastering Time: A Webinar on Effective Time Management",
        "description": "Join this webinar to learn proven strategies for effective time management. Discover techniques to prioritize tasks, overcome procrastination, and achieve better work-life balance. I'll address your specific time management challenges during the session.",
        "duration": 45,
        "charge": 600,
        "default_selected": false,
        "question": "What specific time management challenges do you currently face that you would like addressed during the webinar?",
        "question_type": "text",
        "promised_response_time": 72,
        "type": 3,
        "order": 0,
        "country": "US",
        "expertise": 12
    },
    {
        "id": 191,
        "title": "Navigating the Future: Webinar on Digital Marketing Trends",
        "description": "Stay ahead in the ever-evolving digital landscape. Join this webinar to explore the latest trends in digital marketing. I'll discuss emerging technologies, share case studies, and answer your questions to help you shape successful digital strategies.",
        "duration": 60,
        "charge": 800,
        "default_selected": false,
        "question": "What specific digital marketing challenges or trends are you most interested in learning about during the webinar?",
        "question_type": "text",
        "promised_response_time": 72,
        "type": 3,
        "order": 1,
        "country": "CA",
        "expertise": 18
    }
    , {
        "id": 192,
        "title": "Calm the Chaos: Webinar on Stress Management Techniques",
        "description": "In this webinar, discover effective stress management techniques to maintain mental well-being. I'll provide practical tips, mindfulness exercises, and personalized advice to help you navigate stressors in your personal and professional life.",
        "duration": 45,
        "charge": 550,
        "default_selected": false,
        "question": "Are there specific stressors or situations you'd like guidance on during the webinar?",
        "question_type": "text",
        "promised_response_time": 72,
        "type": 3,
        "order": 2,
        "country": "UK",
        "expertise": 14
    },
    {
        "id": 193,
        "title": "Building Strong Teams Remotely: Webinar on Remote Team Collaboration",
        "description": "Join this webinar to explore strategies for effective remote team collaboration. I'll share insights on virtual communication, team building activities, and address your specific challenges in managing remote teams.",
        "duration": 60,
        "charge": 700,
        "default_selected": false,
        "question": "What challenges do you currently face in managing remote teams that you would like addressed during the webinar?",
        "question_type": "text",
        "promised_response_time": 72,
        "type": 3,
        "order": 3,
        "country": "US",
        "expertise": 20
    },
    {
        "id": 194,
        "title": "Financial Freedom: Webinar on Personal Finance Planning",
        "description": "Learn the essentials of personal finance in this webinar. I'll cover budgeting, investing, and provide tips for achieving financial goals. Feel free to share your specific financial concerns for personalized advice during the session.",
        "duration": 45,
        "charge": 600,
        "default_selected": false,
        "question": "Are there specific financial goals or challenges you'd like to discuss during the webinar?",
        "question_type": "text",
        "promised_response_time": 72,
        "type": 3,
        "order": 4,
        "country": "IN",
        "expertise": 16
    }




]
export const TemplateUserData = {
    "pageProps": {
        "userData": {
            "id": 59863,
            "first_name": "Ankit",
            "last_name": "",
            "display_name": "Ankit",
            "username": "ankit18",
            "social_url": null,
            "title": "",
            "description": null,
            "expertise": 11,
            "timezone": "Asia/Kolkata",
            "system_timezone": "Asia/Kolkata",
            "profile_pic": "https://topmate-staging.s3.ap-south-1.amazonaws.com/avatar.jpg",
            "full_name": "Ankit ",
            "services": [
                {
                    "id": 725312,
                    "title": "call",
                    "description": null,
                    "short_custom_description": null,
                    "duration": 122,
                    "charge": 0,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 2,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 1,
                    "questions": [
                        {
                            "id": 452468,
                            "created": "2024-01-08T16:03:06.166618Z",
                            "modified_date": "2024-01-08T16:03:06.166679Z",
                            "question": "What is the call about?",
                            "type": "text",
                            "compulsory": true,
                            "service": 725312
                        }
                    ],
                    "short_description": "Video Meeting",
                    "cohort_noun": null,
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": true,
                    "show_discount_code": false,
                    "show_sale_count": true,
                    "follower_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--SifmduoS--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:call,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:122%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": null,
                    "images": [],
                    "tags": []
                },
                {
                    "id": 727072,
                    "title": "sdfgyjk",
                    "description": "",
                    "short_custom_description": "",
                    "duration": 22,
                    "charge": 0,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 1,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 1,
                    "questions": [
                        {
                            "id": 453969,
                            "created": "2024-01-09T14:11:04.512397Z",
                            "modified_date": "2024-01-09T14:11:04.512428Z",
                            "question": "What is the call about?",
                            "type": "text",
                            "compulsory": true,
                            "service": 727072
                        }
                    ],
                    "short_description": "Video Meeting",
                    "cohort_noun": "Webinar",
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": true,
                    "show_discount_code": false,
                    "show_sale_count": false,
                    "follower_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": null,
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": "",
                    "images": [],
                    "tags": [
                        {
                            "key": "average_rating",
                            "value": 5
                        }
                    ]
                },
                {
                    "id": 725256,
                    "title": "Priority DM",
                    "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                    "short_custom_description": "",
                    "duration": 60,
                    "charge": 0,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 4,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 2,
                    "questions": [],
                    "short_description": "Priority DM",
                    "cohort_noun": "Webinar",
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": true,
                    "show_discount_code": false,
                    "show_sale_count": false,
                    "follower_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": null,
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": "",
                    "images": [],
                    "tags": []
                },
                {
                    "id": 244423,
                    "title": "1:1 Mentorship",
                    "description": "Do you have a major interview due soon but feel unprepared and anxious? Book a 1:1 call with me where I provide step-by-step actionable and practical tips to move past these momentary blocks and ace your next interview.",
                    "short_custom_description": null,
                    "duration": 30,
                    "charge": 500,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 0,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 1,
                    "questions": [
                        {
                            "id": 53789,
                            "created": "2023-04-21T10:54:31.514698Z",
                            "modified_date": "2023-04-21T10:54:31.514722Z",
                            "question": "What is the call about?",
                            "type": "text",
                            "compulsory": true,
                            "service": 244423
                        }
                    ],
                    "short_description": "Video Meeting",
                    "cohort_noun": null,
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": false,
                    "show_discount_code": false,
                    "show_sale_count": false,
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--YJwZXwXj--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:1:1%20Mentorship,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:30%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": null,
                    "images": [],
                    "tags": []
                },
                {
                    "id": 244420,
                    "title": "Mock interview",
                    "description": "The best way to ace an interview is to do Mock Interviews with a friend or me! In this call, I will help you in your interview preparation for your next job/internship through a Mock Session. This session will be structured in line with the industry and role you are looking for and help you get a feel of how it really feels to be in an interview. ",
                    "short_custom_description": null,
                    "duration": 60,
                    "charge": 1000,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 0,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 1,
                    "questions": [
                        {
                            "id": 53786,
                            "created": "2023-04-21T10:54:31.428962Z",
                            "modified_date": "2023-04-21T10:54:31.428989Z",
                            "question": "What is the call about?",
                            "type": "text",
                            "compulsory": true,
                            "service": 244420
                        }
                    ],
                    "short_description": "Video Meeting",
                    "cohort_noun": null,
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": false,
                    "show_discount_code": false,
                    "show_sale_count": false,
                    "follower_charge": {
                        "updated_price": 1000,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--dRFQFPW2--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Mock%20interview,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:60%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": null,
                    "images": [],
                    "tags": []
                },
                {
                    "id": 244422,
                    "title": "Career guidance",
                    "description": "In this call, I will address in detail any query you may have regarding your career, any obstacles you may be facing and help you gain clarity on further steps to take and decisions to make when it comes to your planning your career.",
                    "short_custom_description": null,
                    "duration": 30,
                    "charge": 500,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 0,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 1,
                    "questions": [
                        {
                            "id": 53788,
                            "created": "2023-04-21T10:54:31.485075Z",
                            "modified_date": "2023-04-21T10:54:31.485100Z",
                            "question": "What is the call about?",
                            "type": "text",
                            "compulsory": true,
                            "service": 244422
                        }
                    ],
                    "short_description": "Video Meeting",
                    "cohort_noun": null,
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": false,
                    "show_discount_code": false,
                    "show_sale_count": false,
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--YotVjPi5--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Career%20guidance,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:30%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": null,
                    "images": [],
                    "tags": []
                },
                {
                    "id": 244418,
                    "title": "Quick chat",
                    "description": "Want to get on a 1-on1 call with me and have a brief discussion about what I do, how I can help you or talk about any brief query you have under the umbrella of interview prep? Book a call with me and I\\'ll see you inside. The only reason to keep a nominal charge is to let me have a chance to speak with people who genuinely want to connect.",
                    "short_custom_description": null,
                    "duration": 30,
                    "charge": 500,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 0,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 1,
                    "questions": [
                        {
                            "id": 53784,
                            "created": "2023-04-21T10:54:31.367859Z",
                            "modified_date": "2023-04-21T10:54:31.367888Z",
                            "question": "What is the call about?",
                            "type": "text",
                            "compulsory": true,
                            "service": 244418
                        }
                    ],
                    "short_description": "Video Meeting",
                    "cohort_noun": null,
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": false,
                    "show_discount_code": false,
                    "show_sale_count": false,
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--v9CX_B3I--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Quick%20chat,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:30%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": null,
                    "images": [],
                    "tags": [
                        {
                            "key": "popular",
                            "value": true
                        }
                    ]
                },
                {
                    "id": 244419,
                    "title": "Interview prep & tips",
                    "description": "If you have an Interview due soon and feel unprepared, don\\'t worry because I\\'ve got you. In this call, I give you solid tips and discuss the frequently asked questions which will help you get an idea of the process, build confidence and ace the interview for the job/internship you\\'ve been eyeing for long.",
                    "short_custom_description": null,
                    "duration": 30,
                    "charge": 500,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 0,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 1,
                    "questions": [
                        {
                            "id": 53785,
                            "created": "2023-04-21T10:54:31.399616Z",
                            "modified_date": "2023-04-21T10:54:31.399643Z",
                            "question": "What is the call about?",
                            "type": "text",
                            "compulsory": true,
                            "service": 244419
                        }
                    ],
                    "short_description": "Video Meeting",
                    "cohort_noun": null,
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": false,
                    "show_discount_code": false,
                    "show_sale_count": false,
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--A21Ep93S--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Interview%20prep%20%26%20tips,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:30%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": null,
                    "images": [],
                    "tags": []
                },
                {
                    "id": 244424,
                    "title": "Have a question?",
                    "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                    "short_custom_description": null,
                    "duration": 0,
                    "charge": 0,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 0,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 2,
                    "questions": [],
                    "short_description": "Priority DM",
                    "cohort_noun": null,
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": true,
                    "show_discount_code": false,
                    "show_sale_count": false,
                    "follower_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--MiR1IsvL--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Have%20a%20question%3F,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:send_xj3xnm,o_100,w_50,x_350,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:Text%20query,w_850,x_425,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": null,
                    "images": [],
                    "tags": []
                },
                {
                    "id": 244425,
                    "title": "Priority DM",
                    "description": "Ask away anything related to my expertise. I will try my best to help you out 🙂",
                    "short_custom_description": null,
                    "duration": 0,
                    "charge": 0,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 0,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 2,
                    "questions": [],
                    "short_description": "Priority DM",
                    "cohort_noun": null,
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": true,
                    "show_discount_code": false,
                    "show_sale_count": false,
                    "follower_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--uNinMl11--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Priority%20DM,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:send_xj3xnm,o_100,w_50,x_350,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:Text%20query,w_850,x_425,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": null,
                    "images": [],
                    "tags": []
                },
                {
                    "id": 244421,
                    "title": "Resume review",
                    "description": "In this call, I will review your Resume through and through, help you on how to customise your resume according to the role you are applying for, suggest changes if needed, give you tips on how you can make your resume ATS friendly and solve any queries you might have related to your resume.",
                    "short_custom_description": null,
                    "duration": 30,
                    "charge": 500,
                    "user": 59863,
                    "is_donation": false,
                    "bookings_count": 0,
                    "show_bookings_count": false,
                    "promised_response_time": 48,
                    "type": 1,
                    "questions": [
                        {
                            "id": 53787,
                            "created": "2023-04-21T10:54:31.457773Z",
                            "modified_date": "2023-04-21T10:54:31.457797Z",
                            "question": "What is the call about?",
                            "type": "text",
                            "compulsory": true,
                            "service": 244421
                        }
                    ],
                    "short_description": "Video Meeting",
                    "cohort_noun": null,
                    "cohort_no_of_sessions": 1,
                    "specific_date": null,
                    "bookings_limit": null,
                    "package_services": [],
                    "total_charge": 0,
                    "session_count": 0,
                    "currency": {
                        "code": "INR",
                        "currency": "Indian Rupee",
                        "display_text": "₹"
                    },
                    "cohort_weekdays": [],
                    "show_seats_left": false,
                    "break_after_call": 0,
                    "original_charge": 0,
                    "package_validity_in_months": 0,
                    "has_flexible_charge": false,
                    "show_discount_code": false,
                    "show_sale_count": false,
                    "follower_charge": {
                        "updated_price": 500,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "follower_total_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "currency_symbol": "₹"
                    },
                    "ppp_enabled": false,
                    "shareable_image": "https://res.cloudinary.com/topmate/image/upload/s--uMERj6AI--/c_fit,co_rgb:000000,g_north_west,h_500,l_text:Arial_130_bold:Resume%20review,w_1300,x_350,y_380/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55:Checkout%20my%20offerings%20and%20book%20a%20slot%20at,w_1500,x_350,y_920/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55:-%3E%20mentorpal.ai%252F,w_1000,x_350,y_1000/c_fit,co_rgb:000000,g_north_west,h_1500,l_text:Arial_55_bold:ankit18,w_1000,x_682,y_1000/c_fit,g_north_west,h_50,l_services_sharable:video_nhkyzj,o_100,w_50,x_350,y_215/c_fit,g_north_west,h_50,l_services_sharable:clock_ramnsz,o_100,w_50,x_800,y_215/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:1:1%20call,w_850,x_425,y_220/c_fit,co_rgb:000000,g_north_west,h_100,l_text:Arial_55_bold:30%20mins,w_850,x_880,y_220/v1/services_sharable/share_base_vv5vv8.jpg",
                    "display_original_charge": {
                        "updated_price": 0,
                        "currency": "INR",
                        "display_text": "₹"
                    },
                    "is_subscription_enabled": false,
                    "document_thumbnail_url": null,
                    "images": [],
                    "tags": []
                }
            ],
            "buffer": 240,
            "testimonials_count": 1,
            "testimonials": [
                {
                    "id": 58737,
                    "liked_properties": null,
                    "follower_name": "Ankit",
                    "text": "Good",
                    "user": "ankit18",
                    "profile_pic": null,
                    "service": "sdfgyjk",
                    "document": null,
                    "booking": "Consumer: Ankit, time: 2024-01-11 16:30:10+00:00, expert: ankit18, status: completed, price: 0, cf661944-8f54-42e1-b706-431a98df0282",
                    "query": null,
                    "image_url": "https://cdn.creatomate.com/renders/7cec3e6d-fc55-4146-9782-d994313d7088.jpg",
                    "order": 999,
                    "pinned": false,
                    "is_visible": true,
                    "is_anonymous": false,
                    "share_link": "7_testimonial_58737"
                }
            ],
            "query_price": 0,
            "paid_query_title": "Ask a question",
            "query_description": "Ask me any anything and I'll revert with a detailed answer",
            "query_tags": "",
            "bookings_count": 12,
            "show_bookings_count": false,
            "is_featured": false,
            "theme_details": {
                "theme": "#7b5fc6",
                "backgroundColor": "#7b5fc6"
            },
            "is_subscribe_enabled": true,
            "subscription_message": null,
            "meta_image": "https://res.cloudinary.com/topmate/image/upload/s--BYEpIvks--/c_fit,g_south_west,h_320,l_meta-image:avatar_bvvufr,w_333,x_91,y_219/c_fit,co_rgb:000000,g_south_west,h_56,l_text:Poppins_36:mentorpal.ai%252Fankit18,w_850,x_124,y_36/v1/meta-image/meta-image_aigq2f.png",
            "booking_period": 60,
            "services_with_app": [],
            "social_urls": null,
            "notice_period": {
                "type": "Minutes",
                "value": 240
            },
            "is_webinar_enabled": true,
            "microsoft_donation": false,
            "has_custom_package": false,
            "ratings_count": 1,
            "highlights": null,
            "pixel_track_id": null,
            "liked_properties": [
                {
                    "property": "Helpful",
                    "total": 1
                }
            ],
            "year_end_achievements": [],
            "achievements": [
                {
                    "title": "12+ bookings",
                    "sub_titile": " Top 5% on mentorpal.ai",
                    "icon": "https://topmate-staging.s3.ap-south-1.amazonaws.com/booking.svg",
                    "percentage": 5
                }
            ],
            "whitelabel_details": null,
            "avg_ratings": 5,
            "total_ratings": 1,
            "is_verified": false,
            "beta_tester": false,
            "donation_campaign": null,
            "enabled_support_my_work": true,
            "reschedule_policy": 1,
            "discount_percentage_buy_in": 0,
            "badge": null,
            "campaign": {
                "snow": false
            }
        },
        "errorStatus": 200
    },
    "__N_SSG": true
}

export const AddServiceConfig = {
    title: {
        label: "Title",
        name: "title",
        placeholder: "Name of Service",
        rules: [
            {
                required: true,
                message: "Title Cannot be empty!",
            },
            {
                min: 3,
                message: "Title cannot be less than 3 characters",
            },
            {
                max: 50,
                message: "Title cannot be more than 50 characters",
            },
            {
                whitespace: true,
                message: "Title cannot be empty!",
            },
        ],
    },
    description: {
        label: "Description",
        name: "description",
        placeholder: "Describe your service",
        rules: [
            {
                min: 5,
                message: "Service Description cannot be less than 5 characters",
            },
            {
                max: 20000,
                message: "Service Description cannot be more than 20000 characters",
            },
        ],
    },
    short_description: {
        label: "Short Description",
        name: "short_custom_description",
        placeholder: "This will be visible below your service title",
        rules: [
            {
                min: 5,
                message: "Short Description cannot be less than 5 characters",
            },
            {
                max: 60,
                message: "Short Description cannot be more than 60 characters",
            },
        ],
    },
    pages: {
        label: "No. of pages",
        name: "number_of_pages",
    },
    group_call_url: {
        label: "",
        name: "group_call_url",
    },
    custom_call_duration: {
        label: "Duration (mins)",
        name: "duration",
        rules: [
            {
                required: true,
                message: " Duration cannot be empty!",
            },
        ],
    },
    date: {
        label: "Session",
        name: "date",
        placeholder: "Select Date",
        rules: [
            {
                required: true,
                message: "Date is required!",
            },
        ],
    },
    time: {
        label: " ",
        name: "time",
        placeholder: "Select Time",
        rules: [
            {
                required: true,
                message: "Time is required!",
            },
        ],
    },
    price: {
        label: "Price",
        name: "charge",
        rules: [
            {
                required: true,
                message: "Price cannot be empty",
            },
            {
                pattern: NumericPattern,
                message: "Price can be integer only",
            },
        ],
    },
    customPrice: {
        label: "Price",
        name: "charge",
        rules: [
            {
                required: true,
                message: "Price cannot be empty",
            },
        ],
    },
    upload: {
        name: "document_url",
        rules: [
            {
                required: true,
                message: "Upload a file",
            },
        ],
    },
    thumbnail: {
        label: "",
        name: "document_thumbnail_url",
    },
    images: {
        label: "",
        name: "images",
    },
    availability: {
        label: "",
        name: "schedule",
        placeholder: "Select a schedule",
    },
    hide_service: {
        label: "Hide this service on your profile",
        name: "is_hidden",
    },
    has_flexible_charge: {
        label: "Allow customers to pay what they want",
        name: "has_flexible_charge",
    },
    parity_charges: {
        label: "Enable location wise dynamic pricing",
        name: "ppp_enabled",
    },
    upsell_services_added: {
        label: "Enable upsell services",
        name: "booking_upsell",
    },
    coupon_enable: {
        label: "Add discount code field to booking form",
        name: "show_discount_code",
    },
    custom_text: {
        label: "Include a custom booking confirmation message",
        name: "custom_text",
        placeholder:
            "E.g Please read this document before our call. You can join my WhatsApp group here",
    },
    bookings_limit: {
        label: "Audience Size",
        name: "bookings_limit",
        placeholder: "Enter a booking limit",
        rules: [
            {
                required: true,
                message: "Audience size is required",
            },
        ],
    },
    break_after_call: {
        label: "",
        name: "break_after_call",
        placeholder: "Select buffer time",
    },
    limit_count: {
        label: "",
        name: "limit_count",
        placeholder: "∞",
    },
};
export const GET_SERVICE_TYPE_STRING = {
    1: "video",
    2: "text",
    3: "webinar",
    4: "package",
    5: "document",
    6: "subscriptions",
};

export const getServiceType = (number) => {
    return GET_SERVICE_TYPE_STRING[number];
}

export const getLinkWithPretag = (link) => {
    return link.startsWith("http://") || link.startsWith("https://")
        ? link
        : `https://${link}`;
};

export const getPricingOptionsForINR = (duration) => {
    return duration && duration < 30
        ? ["100", "200", "500"]
        : ["200", "500", "1000"];
};
export const getPricingOptionsForOtherCurrencies = (
    duration
) => {
    return duration && duration < 30 ? ["10", "200", "50"] : ["20", "50", "100"];
};

export const getPricingOptions = (
    currency_code,
    serviceType = 1
) => {
    if (serviceType == 2)
        return currency_code === "INR"
            ? ["50", "100", "200", "custom"]
            : ["5", "10", "20", "custom"];
    return currency_code === "INR"
        ? getPricingOptionsForINR().concat("custom")
        : getPricingOptionsForOtherCurrencies().concat("custom");
};

export const AllCountriesList = [
    {
        country: "Andorra",
        code: "AD",
    },
    {
        country: "United Arab Emirates",
        code: "AE",
    },
    {
        country: "Afghanistan",
        code: "AF",
    },
    {
        country: "Antigua and Barbuda",
        code: "AG",
    },
    {
        country: "Anguilla",
        code: "AI",
    },
    {
        country: "Albania",
        code: "AL",
    },
    {
        country: "Armenia",
        code: "AM",
    },
    {
        country: "Angola",
        code: "AO",
    },
    {
        country: "Antarctica",
        code: "AQ",
    },
    {
        country: "Argentina",
        code: "AR",
    },
    {
        country: "American Samoa",
        code: "AS",
    },
    {
        country: "Austria",
        code: "AT",
    },
    {
        country: "Australia",
        code: "AU",
    },
    {
        country: "Aruba",
        code: "AW",
    },
    {
        country: "Åland",
        code: "AX",
    },
    {
        country: "Azerbaijan",
        code: "AZ",
    },
    {
        country: "Bosnia and Herzegovina",
        code: "BA",
    },
    {
        country: "Barbados",
        code: "BB",
    },
    {
        country: "Bangladesh",
        code: "BD",
    },
    {
        country: "Belgium",
        code: "BE",
    },
    {
        country: "Burkina Faso",
        code: "BF",
    },
    {
        country: "Bulgaria",
        code: "BG",
    },
    {
        country: "Bahrain",
        code: "BH",
    },
    {
        country: "Burundi",
        code: "BI",
    },
    {
        country: "Benin",
        code: "BJ",
    },
    {
        country: "Saint Barthélemy",
        code: "BL",
    },
    {
        country: "Bermuda",
        code: "BM",
    },
    {
        country: "Brunei",
        code: "BN",
    },
    {
        country: "Bolivia",
        code: "BO",
    },
    {
        country: "Bonaire",
        code: "BQ",
    },
    {
        country: "Brazil",
        code: "BR",
    },
    {
        country: "Bahamas",
        code: "BS",
    },
    {
        country: "Bhutan",
        code: "BT",
    },
    {
        country: "Bouvet Island",
        code: "BV",
    },
    {
        country: "Botswana",
        code: "BW",
    },
    {
        country: "Belarus",
        code: "BY",
    },
    {
        country: "Belize",
        code: "BZ",
    },
    {
        country: "Canada",
        code: "CA",
    },
    {
        country: "Cocos [Keeling] Islands",
        code: "CC",
    },
    {
        country: "Democratic Republic of the Congo",
        code: "CD",
    },
    {
        country: "Central African Republic",
        code: "CF",
    },
    {
        country: "Republic of the Congo",
        code: "CG",
    },
    {
        country: "Switzerland",
        code: "CH",
    },
    {
        country: "Ivory Coast",
        code: "CI",
    },
    {
        country: "Cook Islands",
        code: "CK",
    },
    {
        country: "Chile",
        code: "CL",
    },
    {
        country: "Cameroon",
        code: "CM",
    },
    {
        country: "China",
        code: "CN",
    },
    {
        country: "Colombia",
        code: "CO",
    },
    {
        country: "Costa Rica",
        code: "CR",
    },
    {
        country: "Cuba",
        code: "CU",
    },
    {
        country: "Cape Verde",
        code: "CV",
    },
    {
        country: "Curacao",
        code: "CW",
    },
    {
        country: "Christmas Island",
        code: "CX",
    },
    {
        country: "Cyprus",
        code: "CY",
    },
    {
        country: "Czech Republic",
        code: "CZ",
    },
    {
        country: "Germany",
        code: "DE",
    },
    {
        country: "Djibouti",
        code: "DJ",
    },
    {
        country: "Denmark",
        code: "DK",
    },
    {
        country: "Dominica",
        code: "DM",
    },
    {
        country: "Dominican Republic",
        code: "DO",
    },
    {
        country: "Algeria",
        code: "DZ",
    },
    {
        country: "Ecuador",
        code: "EC",
    },
    {
        country: "Estonia",
        code: "EE",
    },
    {
        country: "Egypt",
        code: "EG",
    },
    {
        country: "Western Sahara",
        code: "EH",
    },
    {
        country: "Eritrea",
        code: "ER",
    },
    {
        country: "Spain",
        code: "ES",
    },
    {
        country: "Ethiopia",
        code: "ET",
    },
    {
        country: "Finland",
        code: "FI",
    },
    {
        country: "Fiji",
        code: "FJ",
    },
    {
        country: "Falkland Islands",
        code: "FK",
    },
    {
        country: "Micronesia",
        code: "FM",
    },
    {
        country: "Faroe Islands",
        code: "FO",
    },
    {
        country: "France",
        code: "FR",
    },
    {
        country: "Gabon",
        code: "GA",
    },
    {
        country: "United Kingdom",
        code: "GB",
    },
    {
        country: "Grenada",
        code: "GD",
    },
    {
        country: "Georgia",
        code: "GE",
    },
    {
        country: "French Guiana",
        code: "GF",
    },
    {
        country: "Guernsey",
        code: "GG",
    },
    {
        country: "Ghana",
        code: "GH",
    },
    {
        country: "Gibraltar",
        code: "GI",
    },
    {
        country: "Greenland",
        code: "GL",
    },
    {
        country: "Gambia",
        code: "GM",
    },
    {
        country: "Guinea",
        code: "GN",
    },
    {
        country: "Guadeloupe",
        code: "GP",
    },
    {
        country: "Equatorial Guinea",
        code: "GQ",
    },
    {
        country: "Greece",
        code: "GR",
    },
    {
        country: "South Georgia and the South Sandwich Islands",
        code: "GS",
    },
    {
        country: "Guatemala",
        code: "GT",
    },
    {
        country: "Guam",
        code: "GU",
    },
    {
        country: "Guinea-Bissau",
        code: "GW",
    },
    {
        country: "Guyana",
        code: "GY",
    },
    {
        country: "Hong Kong",
        code: "HK",
    },
    {
        country: "Heard Island and McDonald Islands",
        code: "HM",
    },
    {
        country: "Honduras",
        code: "HN",
    },
    {
        country: "Croatia",
        code: "HR",
    },
    {
        country: "Haiti",
        code: "HT",
    },
    {
        country: "Hungary",
        code: "HU",
    },
    {
        country: "Indonesia",
        code: "ID",
    },
    {
        country: "Ireland",
        code: "IE",
    },
    {
        country: "Israel",
        code: "IL",
    },
    {
        country: "Isle of Man",
        code: "IM",
    },
    {
        country: "India",
        code: "IN",
    },
    {
        country: "British Indian Ocean Territory",
        code: "IO",
    },
    {
        country: "Iraq",
        code: "IQ",
    },
    {
        country: "Iran",
        code: "IR",
    },
    {
        country: "Iceland",
        code: "IS",
    },
    {
        country: "Italy",
        code: "IT",
    },
    {
        country: "Jersey",
        code: "JE",
    },
    {
        country: "Jamaica",
        code: "JM",
    },
    {
        country: "Jordan",
        code: "JO",
    },
    {
        country: "Japan",
        code: "JP",
    },
    {
        country: "Kenya",
        code: "KE",
    },
    {
        country: "Kyrgyzstan",
        code: "KG",
    },
    {
        country: "Cambodia",
        code: "KH",
    },
    {
        country: "Kiribati",
        code: "KI",
    },
    {
        country: "Comoros",
        code: "KM",
    },
    {
        country: "Saint Kitts and Nevis",
        code: "KN",
    },
    {
        country: "North Korea",
        code: "KP",
    },
    {
        country: "South Korea",
        code: "KR",
    },
    {
        country: "Kuwait",
        code: "KW",
    },
    {
        country: "Cayman Islands",
        code: "KY",
    },
    {
        country: "Kazakhstan",
        code: "KZ",
    },
    {
        country: "Laos",
        code: "LA",
    },
    {
        country: "Lebanon",
        code: "LB",
    },
    {
        country: "Saint Lucia",
        code: "LC",
    },
    {
        country: "Liechtenstein",
        code: "LI",
    },
    {
        country: "Sri Lanka",
        code: "LK",
    },
    {
        country: "Liberia",
        code: "LR",
    },
    {
        country: "Lesotho",
        code: "LS",
    },
    {
        country: "Lithuania",
        code: "LT",
    },
    {
        country: "Luxembourg",
        code: "LU",
    },
    {
        country: "Latvia",
        code: "LV",
    },
    {
        country: "Libya",
        code: "LY",
    },
    {
        country: "Morocco",
        code: "MA",
    },
    {
        country: "Monaco",
        code: "MC",
    },
    {
        country: "Moldova",
        code: "MD",
    },
    {
        country: "Montenegro",
        code: "ME",
    },
    {
        country: "Saint Martin",
        code: "MF",
    },
    {
        country: "Madagascar",
        code: "MG",
    },
    {
        country: "Marshall Islands",
        code: "MH",
    },
    {
        country: "North Macedonia",
        code: "MK",
    },
    {
        country: "Mali",
        code: "ML",
    },
    {
        country: "Myanmar [Burma]",
        code: "MM",
    },
    {
        country: "Mongolia",
        code: "MN",
    },
    {
        country: "Macao",
        code: "MO",
    },
    {
        country: "Northern Mariana Islands",
        code: "MP",
    },
    {
        country: "Martinique",
        code: "MQ",
    },
    {
        country: "Mauritania",
        code: "MR",
    },
    {
        country: "Montserrat",
        code: "MS",
    },
    {
        country: "Malta",
        code: "MT",
    },
    {
        country: "Mauritius",
        code: "MU",
    },
    {
        country: "Maldives",
        code: "MV",
    },
    {
        country: "Malawi",
        code: "MW",
    },
    {
        country: "Mexico",
        code: "MX",
    },
    {
        country: "Malaysia",
        code: "MY",
    },
    {
        country: "Mozambique",
        code: "MZ",
    },
    {
        country: "Namibia",
        code: "NA",
    },
    {
        country: "New Caledonia",
        code: "NC",
    },
    {
        country: "Niger",
        code: "NE",
    },
    {
        country: "Norfolk Island",
        code: "NF",
    },
    {
        country: "Nigeria",
        code: "NG",
    },
    {
        country: "Nicaragua",
        code: "NI",
    },
    {
        country: "Netherlands",
        code: "NL",
    },
    {
        country: "Norway",
        code: "NO",
    },
    {
        country: "Nepal",
        code: "NP",
    },
    {
        country: "Nauru",
        code: "NR",
    },
    {
        country: "Niue",
        code: "NU",
    },
    {
        country: "New Zealand",
        code: "NZ",
    },
    {
        country: "Oman",
        code: "OM",
    },
    {
        country: "Panama",
        code: "PA",
    },
    {
        country: "Peru",
        code: "PE",
    },
    {
        country: "French Polynesia",
        code: "PF",
    },
    {
        country: "Papua New Guinea",
        code: "PG",
    },
    {
        country: "Philippines",
        code: "PH",
    },
    {
        country: "Pakistan",
        code: "PK",
    },
    {
        country: "Poland",
        code: "PL",
    },
    {
        country: "Saint Pierre and Miquelon",
        code: "PM",
    },
    {
        country: "Pitcairn Islands",
        code: "PN",
    },
    {
        country: "Puerto Rico",
        code: "PR",
    },
    {
        country: "Palestine",
        code: "PS",
    },
    {
        country: "Portugal",
        code: "PT",
    },
    {
        country: "Palau",
        code: "PW",
    },
    {
        country: "Paraguay",
        code: "PY",
    },
    {
        country: "Qatar",
        code: "QA",
    },
    {
        country: "Réunion",
        code: "RE",
    },
    {
        country: "Romania",
        code: "RO",
    },
    {
        country: "Serbia",
        code: "RS",
    },
    {
        country: "Russia",
        code: "RU",
    },
    {
        country: "Rwanda",
        code: "RW",
    },
    {
        country: "Saudi Arabia",
        code: "SA",
    },
    {
        country: "Solomon Islands",
        code: "SB",
    },
    {
        country: "Seychelles",
        code: "SC",
    },
    {
        country: "Sudan",
        code: "SD",
    },
    {
        country: "Sweden",
        code: "SE",
    },
    {
        country: "Singapore",
        code: "SG",
    },
    {
        country: "Saint Helena",
        code: "SH",
    },
    {
        country: "Slovenia",
        code: "SI",
    },
    {
        country: "Svalbard and Jan Mayen",
        code: "SJ",
    },
    {
        country: "Slovakia",
        code: "SK",
    },
    {
        country: "Sierra Leone",
        code: "SL",
    },
    {
        country: "San Marino",
        code: "SM",
    },
    {
        country: "Senegal",
        code: "SN",
    },
    {
        country: "Somalia",
        code: "SO",
    },
    {
        country: "Suriname",
        code: "SR",
    },
    {
        country: "South Sudan",
        code: "SS",
    },
    {
        country: "São Tomé and Príncipe",
        code: "ST",
    },
    {
        country: "El Salvador",
        code: "SV",
    },
    {
        country: "Sint Maarten",
        code: "SX",
    },
    {
        country: "Syria",
        code: "SY",
    },
    {
        country: "Swaziland",
        code: "SZ",
    },
    {
        country: "Turks and Caicos Islands",
        code: "TC",
    },
    {
        country: "Chad",
        code: "TD",
    },
    {
        country: "French Southern Territories",
        code: "TF",
    },
    {
        country: "Togo",
        code: "TG",
    },
    {
        country: "Thailand",
        code: "TH",
    },
    {
        country: "Tajikistan",
        code: "TJ",
    },
    {
        country: "Tokelau",
        code: "TK",
    },
    {
        country: "East Timor",
        code: "TL",
    },
    {
        country: "Turkmenistan",
        code: "TM",
    },
    {
        country: "Tunisia",
        code: "TN",
    },
    {
        country: "Tonga",
        code: "TO",
    },
    {
        country: "Turkey",
        code: "TR",
    },
    {
        country: "Trinidad and Tobago",
        code: "TT",
    },
    {
        country: "Tuvalu",
        code: "TV",
    },
    {
        country: "Taiwan",
        code: "TW",
    },
    {
        country: "Tanzania",
        code: "TZ",
    },
    {
        country: "Ukraine",
        code: "UA",
    },
    {
        country: "Uganda",
        code: "UG",
    },
    {
        country: "U.S. Minor Outlying Islands",
        code: "UM",
    },
    {
        country: "United States",
        code: "US",
    },
    {
        country: "Uruguay",
        code: "UY",
    },
    {
        country: "Uzbekistan",
        code: "UZ",
    },
    {
        country: "Vatican City",
        code: "VA",
    },
    {
        country: "Saint Vincent and the Grenadines",
        code: "VC",
    },
    {
        country: "Venezuela",
        code: "VE",
    },
    {
        country: "British Virgin Islands",
        code: "VG",
    },
    {
        country: "U.S. Virgin Islands",
        code: "VI",
    },
    {
        country: "Vietnam",
        code: "VN",
    },
    {
        country: "Vanuatu",
        code: "VU",
    },
    {
        country: "Wallis and Futuna",
        code: "WF",
    },
    {
        country: "Samoa",
        code: "WS",
    },
    {
        country: "Kosovo",
        code: "XK",
    },
    {
        country: "Yemen",
        code: "YE",
    },
    {
        country: "Mayotte",
        code: "YT",
    },
    {
        country: "South Africa",
        code: "ZA",
    },
    {
        country: "Zambia",
        code: "ZM",
    },
    {
        country: "Zimbabwe",
        code: "ZW",
    },
];

// 
export const formatNumber = (number, flexible = false) => {
    return (
        Number(parseFloat(number).toFixed(1)).toLocaleString() +
        (flexible ? "+" : "")
    );
};
export const FloatStep = {
    "beginner": {
        "completed_steps": 3,
        "total_steps": 5,
        "steps": [
            {
                "id": 1,
                "title": "Add availability",
                "desc": "Add your availability so your followers can select a slot",
                "done": 1,
                "cta": "Add availability",
                "link": "/dashboard/availability/schedule"
            },
            {
                "id": 2,
                "title": "Complete your profile",
                "desc": "Add your profile pic and description",
                "done": 0,
                "cta": "Complete your profile",
                "link": "/dashboard/profile/details"
            },
            {
                "id": 3,
                "title": "Create a service",
                "desc": "Add a service so that your followers can book it",
                "done": 1,
                "cta": "Create a service",
                "link": "/dashboard/services/video"
            },
            {
                "id": 4,
                "title": "Share a post",
                "desc": "Share your topmate page with your followers",
                "done": 1,
                "cta": "Share a post",
                "link": "/dashboard/create-post/6_suggested_7"
            },
            {
                "id": 5,
                "title": "Connect payout",
                "desc": "Connect Bank, Stripe or PayPal for seamless withdrawals",
                "done": 0,
                "cta": "Connect payouts",
                "link": "/dashboard/payments"
            }
        ]
    },
    "advanced": {
        "completed_steps": 1,
        "total_steps": 5,
        "steps": [
            {
                "id": 1,
                "title": "Add calendar & meeting link",
                "desc": "Sync your calendar to stay updated. Add your meeting link where all your meetings will be hosted",
                "done": 1,
                "cta": "Update",
                "link": "/dashboard/availability/configurations"
            },
            {
                "id": 3,
                "title": "Let followers support your work",
                "desc": "Let your followers pay as much as they can, after booking. People who find value from you are 40% more likely to pay",
                "done": false,
                "notify_action": true,
                "cta": "Try it out",
                "link": "/dashboard/profile/details?supportwork=true&utm_source=product&utm_medium=checklist"
            },
            {
                "id": 2,
                "title": "Add buy suggestions",
                "desc": "Add relevant services to be suggested to your customers on the booking page. Someone who is already paying is very likely to buy more!",
                "done": false,
                "notify_action": true,
                "cta": "Add Buy Suggestions",
                "link": "dashboard/services/edit/scheduling?id=244418&type=1"
            },
            {
                "id": 4,
                "title": "Add position on Linkedin",
                "desc": "Announce your new position as a mentor, expert or creator at Topmate. It’s a great way to announce to the world",
                "done": false,
                "notify_action": true,
                "cta": "Add position on LinkedIn",
                "link": "https://topmate.click/announce-on-linkedin"
            },
            {
                "id": 5,
                "title": "Enable location wise dynamic pricing",
                "desc": "Charge customers different amounts depending on the cost of living in their country. Dont worry, we take care of it all",
                "done": false,
                "cta": "Enable dynamic pricing",
                "link": "/dashboard/services/edit/scheduling?id=244418&type=1"
            }
        ]
    },
    "intermediate": []
}

export const CurrencyOptions = [
    {
        label: "$ (U.S dollars)",
        value: "USD",
    },
    {
        label: "₹ (Indian Rupee)",
        value: "INR",
    },
    {
        label: "CA$ (Canadian dollars)",
        value: "CAD",
    },
    { label: "€ (Euro)", value: "EUR" },
    { label: "£ (Pound)", value: "GBP" },
    { label: "kr (Swedish Krona)", value: "SEK" },
];
export const StripeSupportedCountries = [
    { country: "Argentina", code: "AR" },
    { country: "Australia", code: "AU" },
    { country: "Austria", code: "AT" },
    { country: "Belgium", code: "BE" },
    { country: "Bolivia", code: "BO" },
    { country: "Brazil", code: "BR" },
    { country: "Bulgaria", code: "BG" },
    { country: "Canada", code: "CA" },
    { country: "Chile", code: "CL" },
    { country: "Colombia", code: "CO" },
    { country: "Costa Rica", code: "CR" },
    { country: "Croatia", code: "HR" },
    { country: "Cyprus", code: "CY" },
    { country: "Czech Republic", code: "CZ" },
    { country: "Denmark", code: "DK" },
    { country: "Dominican Republic", code: "DO" },
    { country: "Estonia", code: "EE" },
    { country: "Finland", code: "FI" },
    { country: "France", code: "FR" },
    { country: "Germany", code: "DE" },
    { country: "Greece", code: "GR" },
    { country: "Hong Kong SAR China", code: "HK" },
    { country: "Hungary", code: "HU" },
    { country: "Iceland", code: "IS" },
    // { country: "India", code: "IN" },
    { country: "Indonesia", code: "ID" },
    { country: "Ireland", code: "IE" },
    { country: "Israel", code: "IL" },
    { country: "Italy", code: "IT" },
    { country: "Japan", code: "JP" },
    { country: "Latvia", code: "LV" },
    { country: "Liechtenstein", code: "LI" },
    { country: "Lithuania", code: "LT" },
    { country: "Luxembourg", code: "LU" },
    { country: "Malta", code: "MT" },
    { country: "Mexico ", code: "MX" },
    { country: "Netherlands", code: "NL" },
    { country: "New Zealand", code: "NZ" },
    { country: "Norway", code: "NO" },
    { country: "Paraguay", code: "PY" },
    { country: "Peru", code: "PE" },
    { country: "Poland", code: "PL" },
    { country: "Portugal", code: "PT" },
    { country: "Romania", code: "RO" },
    { country: "Singapore", code: "SG" },
    { country: "Slovakia", code: "SK" },
    { country: "Slovenia", code: "SI" },
    { country: "Spain", code: "ES" },
    { country: "Sweden", code: "SE" },
    { country: "Switzerland", code: "CH" },
    { country: "Thailand", code: "TH" },
    { country: "Trinidad & Tobago", code: "TT" },
    { country: "United Arab Emirates", code: "AE" },
    { country: "United Kingdom", code: "GB" },
    { country: "United States", code: "US" },
    { country: "Uruguay", code: "UY" },
];

export const downloadFile = async (fileName) => {
    try {
        const fileUrl = `${process.env.REACT_APP_IMAGES_BASE_URL}${fileName}`
        const response = await fetch(fileUrl);

        if (!response.ok) {
            throw new Error(`Failed to download file: ${response.statusText}`);
        }

        const blob = await response.blob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);

        message.success('Downloaded successfully!');
    } catch (error) {
        console.error('Error downloading file:', error);
        message.error('Error downloading file. Please try again.');
    }
};