import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, Row, Col, message } from 'antd';
import { feedbackformSubmitDataAction } from '../../../redux/actions/common';
import { useDispatch, useSelector } from 'react-redux';
export const Feedback = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm(); // Use the useForm hook to get the form object

    const onFinish = (values) => {
        console.log('Received values:', values);
        dispatch(feedbackformSubmitDataAction(values))
    };
    const feedbackReducer = useSelector(
        (state) => state?.feedbackReducer
    );
    useEffect(() => {
        const { loading, status, data, error } = feedbackReducer
        setLoading(loading)
        if (!loading && data !== undefined) {
            message.success("Feedback form submited successfully!")
            form.resetFields();
    
        }
        if (data === undefined && !loading) {
            message.error("Something went wrong ! Please try again later.")
        }


    }, [feedbackReducer]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0">
                <div className="ant-row container-title-section sticky">
                    <div className="ant-col header-section hidden ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-16 ant-col-md-offset-1 ant-col-xl-22 ant-col-xl-offset-1 ant-col-xxl-12 ant-col-xxl-offset-1">
                        <h4 className="ant-typography layout-header">
                            <svg
                                onClick={() => navigate('/dashboard/home')}
                                stroke="currentColor"
                                fill="none"
                                strokeWidth={2}
                                viewBox="0 0 24 24"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="back-btn"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <line x1={19} y1={12} x2={5} y2={12} />
                                <polyline points="12 19 5 12 12 5" />
                            </svg>
                            <div className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line">
                                Feedback
                            </div>
                        </h4>
                    </div>
                </div>
                <div className="ant-row container-children">
                    <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1">
                        <Form
                            className="add-service-form"
                            name="feedbackForm"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            layout="vertical"
                        >

                            <Col span={12}>
                                <Form.Item
                                    className="fields"
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Please enter your name' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className="fields"
                                    label="Email"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Please enter your email' },
                                        { type: 'email', message: 'Please enter a valid email' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className="fields"
                                    label="Message"
                                    name="description"
                                    rules={[{ required: true, message: 'Please enter your message' }]}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                            </Col>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading} className="ant-btn  ant-btn-default tab-cta-link tab-link-common btn-dark">
                                    Submit
                                </Button>
                                <Button
                                    className="ant-btn  ant-btn-default tab-cta-link tab-link-common btn-dark"
                                    style={{ marginLeft: 8 }}
                                    onClick={() => form.resetFields()}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>

        </>
    )
}
