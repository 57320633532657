import React from 'react'

import styles from './TrainingDemo.module.css'

const TrainingDemo = () => {
    return (
        <div className={`TrainingDemo ${styles.demo_wrapper}`} >
            <div className='container'>
                <h1 className={`${styles.demo_heading}`}>
                    Enhance Skills Together – Tailored for Every Organization
                </h1>
                <p className={`${styles.demo_subheading}`}>
                    Discover the Impact of Our Enterprise Plan on Your Company's Growth.
                </p>
                <div className={`${styles.requestDemoBtn}`}>
                    <button>Request a Demo</button>
                </div>
                <p className={`${styles.demo_despcription}`}>Unveil a Glimpse of the Future You with Our Interactive Demo.</p>
                <div className={`${styles.try14Days}`}>
                    <a href='#'><b><u>Try it Free for 14 Days</u></b></a>
                </div>
            </div>
        </div>
    )
}

export default TrainingDemo