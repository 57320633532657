import React, { useState } from "react";
import Footer from "../../footer";
import Header from "../../header";
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";
import Lock from '../../../assets/images/lock-r.png'
import SeoHelmet from "../../Seo/Helmet/SeoHelmet";
import { PrivacyPolicySeo } from "../../Seo/data";

const Terms = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_USER_ID") ? true : false
  );

  return (
    <>
      <SeoHelmet metaData={PrivacyPolicySeo} />

      {loggedIn ? (
        <div style={{ marginTop: "0" }}>
          <ProtectedHeader extraClasses="inner-header" />
        </div>
      ) : (
        <Header extraClasses="inner-header" />
      )}
      <section className="Privacy_policy">
        <div className="container">
          <div className="privacy_heading">
            <h1> <span>  <img src={Lock} alt="" style={{width:'90px', height:'70px',paddingTop:'5px'}}/>  </span>Privacy And Policy</h1>
          </div>
          <div className="privacy_content">
            <h3>1.Our commitment to privacy</h3>
            <p>
              Mentorpal takes the privacy of its users seriously. This
              information relates to our Privacy Policy to help you understand
              and trust our online safety practices. This Privacy Policy covers
              all personally identifiable information collected by Mentorpal
              when you use the Mentorpal.ai website and its services.
            </p>
          </div>
          <div className="privacy_content">
            <h3> 1. Collection of the information of the individual user</h3>
            <p>
              In this privacy policy we use the term “Individual user” to
              represent information that can be connected with a specific person
              who uses Mentorpal and its services.
            </p>
          </div>
          <div className="privacy_content">
            <h3> 2. How do we collect information on individual users</h3>

            <p>
              we collect information about the individual user in a number of
              ways:-
            </p>

            <p>
              <span className="privacy_welcome">Operative collection: </span>
              This is information you provide to Mentorpal when you create an
              account or use any of the Mentorpal services. We may collect the
              information you provide us by using the Mentorpal website or any
              of the servicesvoluntarily which may contain personal information
              for example when you use these Mentorpal services:
            </p>
            <div className="privacy_list">
              <ul style={{ listStyleType: "circle" }}>
                <li> Create an account</li>
                <li> Fill up a questionnaire</li>
                <li>
                  Attend a session, seminar, or event conducted by Mentorpal
                </li>
                <li> Purchase of any service or product</li>
                <li> Downloading content from the Mentorpal website</li>
                <li> Communication by emails</li>
              </ul>
            </div>
          </div>
          <div className="privacy_content">
            <h3> 3.What types of information do we collect?</h3>
            <p>
              we collect information about the individual user in a number of
              ways:-
            </p>
            <p>
              1. We collect the following types of information about individual
              users
            </p>
            <div className="privacy_list">
              <ul style={{ listStyleType: "circle" }}>
                <li> First and last name of the user</li>
                <li> Mobile phone number</li>
                <li> Email address</li>
                <li> User ID and password</li>
                <li> Address with Zip code</li>
                <li> Age</li>
                <li> Gender</li>
              </ul>
            </div>

            <p>
              2. When you use specific services or sessions Mentorpal may
              collect the following information
            </p>
            <div className="privacy_list">
              <ul style={{ listStyleType: "circle" }}>
                <li> Work-related information</li>
                <li> Employment history</li>
                <li> Technical Qualification or experience</li>
                <li> Your opinion</li>
              </ul>
            </div>
          </div>
          <div className="privacy_content">
            <h3>4. Access to your individual information </h3>
            <p>
              {" "}
              We use your personal information for the use of Mentorpal services
              or the Mentorpal database, we don’t share your personal data with
              any third party without your permission.
            </p>
          </div>
          <div className="privacy_content">
            <h3>5. How do we use your individual information</h3>
            <p>
              Our main purpose is to collect, store, and use your personal data
              for the use of Mentorpal services. Some of the information you
              provide us may share with meteors, organizers, and sponsors for a
              mentoring program. Its purpose is to connect and match you with
              mentors and others.
            </p>
          </div>
          <div className="privacy_content">
            <h3>6.Withdraw</h3>
            <p>
              You may withdraw your permission to us collect, use, and store
              your personal data in accordance with this privacy policy. when
              you withdraw your permission to collect, use and store your
              personal data to Mentorpal you can’t use the Mentorpal services
              further.
            </p>
          </div>
          <div className="privacy_content">
            <h3>7. Use of Cookies</h3>
            <p>
              Mentorpal or a third party that provides services to Mentorpal may
              use cookies, flash cookies, pixel tags, and storage and browser
              provided by you.
            </p>
          </div>
          <div className="privacy_content">
            <h3>8. Information shared with social platforms</h3>
            <p>
              We may also offer services that allow you to share public aspects
              of the Mentorpal services with third-party websites such as
              YouTube, Google Plus, Twitter, LinkedIn, Facebook, and other
              social media sites.
            </p>
          </div>
          <div className="privacy_content">
            <h3>9. Complains</h3>
            <p>
              If you have a complaint regarding the privacy policy and the terms
              contained therein should be directed to help@mentorpal.ai if the
              issue can not be resolved, you may withdraw, close your account
              and stop using Mentorpal services
            </p>
          </div>
          <div className="privacy_content">
            <h3>10. Collection and Use of User Information</h3>
            <p>
              By using Mental Pal's website and services, you indicate that you
              have read, understood, and agree to be bound by the Terms of
              Service. In the event of any discrepancy between the Terms of use
              and this Privacy Policy, the terms of use will prevail. We reserve
              the right to modify the Privacy Policy and will notify the same on
              the website
            </p>
          </div>
          <div className="privacy_heading">
            <h2>Cancellation & Refund policy</h2>
            <p>
              Mentorpal.ai believes in helping its customers as far as possible,
              and has therefore a liberal cancellation policy. Under this
              policy:
            </p>
            <div className="privacy_content">
              <ul style={{ listStyleType: "circle" }}>
                <li>
                  Cancellations will be considered only if the request is made
                  immediately after placing the order. However, the cancellation
                  request may not be entertained if the orders have been
                  communicated to the vendors/merchants and they have initiated
                  the process of shipping them.
                </li>
                <li>
                  Mentorpal.ai does not accept cancellation requests for
                  perishable items like flowers, eatables etc. However,
                  refund/replacement can be made if the customer establishes
                  that the quality of product delivered is not good.
                </li>
                <li>
                  In case of receipt of damaged or defective items please report
                  the same to our Customer Service team. The request will,
                  however, be entertained once the merchant has checked and
                  determined the same at his own end. This should be reported
                  within 7 days of receipt of the products.
                </li>
                <li>
                  In case you feel that the product received is not as shown on
                  the site or as per your expectations, you must bring it to the
                  notice of our customer service within 7 days of receiving the
                  product. The Customer Service Team after looking into your
                  complaint will take an appropriate decision.
                </li>
                <li>
                  In case of complaints regarding products that come with a
                  warranty from manufacturers, please refer the issue to them.
                </li>
                <li>
                  In case of any Refunds approved by the Mentorpal.ai, it’ll
                  take 3-5 days for the refund to be processed to
                  the end customer.
                </li>
              </ul>
            </div>
            <div className="privacy_heading">
              <h2>Shipping & Delivery Policy</h2>
              <div className="privacy_content">
                <p>Shipping is not applicable for business</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default Terms;
