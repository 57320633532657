import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Modal, Typography, message } from 'antd';
import '../../../css/calls.css'
import { IoClose } from 'react-icons/io5';

const RescheduleBooking = ({ ReschedulModal, setReschedulModal, ModalData }) => {
    const { Paragraph, Text } = Typography;
    const [RescheduleForm] = Form.useForm();
    const [existingSlotsVisible, setExistingSlotsVisible] = useState(true);
    const [customSlotsVisible, setCustomSlotsVisible] = useState(false);
    const [rescheduleInProgress, setRescheduleInProgress] = useState(false);
    const [slotsDuplicateError, setSlotsDuplicateError] = useState("");

    const onSubmit = async (values) => {
        setRescheduleInProgress(true);
        const slotsAvailable = customSlotsVisible
            ? values?.slots.map(
                (slot) =>
                    slot?.date &&
                    slot?.time &&
                    `${slot?.date.format("YYYY-MM-DD")}T${slot?.time.format(
                        "HH:mm:ss"
                    )}`
            )
            : [];
        const postObj = {
            booking: ModalData?.service?.id,
            custom_availability: values?.custom,
            use_existing_availability: values?.existing,
            slots: slotsAvailable?.filter((slot, idx) => {
                if (slot) return slot;
            }),
        };
        if (values?.reason) {
            postObj["reason"] = values?.reason;
        }
        const trackObj = {
            existing_availability: existingSlotsVisible,
            custom_slots: customSlotsVisible,
            reason: values?.reason || "Not Filled",
            number_of_slots: values?.slots?.length || "NA",
            "Original Time": ModalData?.time || "NA",
        };
        try {
            message.success("Reschedule request sent successfully!");
            setTimeout(() => {
                setRescheduleInProgress(false);
                setReschedulModal(false);
            }, 1000);
        } catch (error) {
            setRescheduleInProgress(false);
            message.error("Some Error Occured. Please try again!");
        }
    };
    const initialValues = {
        existing: true,
        custom: false,
        reason: "",
        slots: [
            {
                date: null,
                time: null,
            },
            {
                date: null,
                time: null,
            },
            {
                date: null,
                time: null,
            },
        ],
    };
    const onValuesChange = async (values) => {
        switch (Object.keys(values)[0]) {
            case "custom": {
                setCustomSlotsVisible(values?.custom);
                break;
            }
            default: {
                break;
            }
        }
    };
    return (
        <>
            <Modal
                open={ReschedulModal}
                footer={null}
                centered
                width={500}
                title={
                    <>
                        <Text strong style={{ fontSize: 24 }}>
                            Reschedule Booking
                        </Text>
                        <Paragraph
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                lineHeight: "24px",
                                marginTop: 4,
                                color: "#434343",
                            }}
                        >
                            vibha . Sun, 28 Jan . 09:00 - 11:02 AM (GMT+5:30)
                        </Paragraph>
                    </>
                }
                destroyOnClose
                onCancel={() => {
                    setReschedulModal(false);

                }}
                className="reschedule-modal"
                closeIcon={<IoClose fontSize={24} color="#141414" />}
            >
                <Form
                    form={RescheduleForm}
                    preserve={false}
                    onFinish={onSubmit}
                    initialValues={initialValues}
                    layout="vertical"
                    onValuesChange={onValuesChange}
                >
                    {/* Existing Slot Availability */}
                    <Form.Item name="existing" valuePropName="checked" noStyle>
                        <Checkbox>
                            <Text style={{ fontSize: 16, lineHeight: "28px" }}>
                                Using existing availability
                            </Text>
                        </Checkbox>
                    </Form.Item>

                    {/* Custom Slots Checkbox */}
                    <Form.Item name="custom" valuePropName="checked">
                        <Checkbox>
                            <Text style={{ fontSize: 16, lineHeight: "28px" }}>
                                Provide custom time slots
                            </Text>
                        </Checkbox>
                    </Form.Item>

                    {/* Custom Slots */}
                    {customSlotsVisible && (
                        <>
                            <>
                                <>
                                    <div
                                        className="ant-row ant-row-middle css-1ay1rjo"
                                        style={{ marginLeft: "-6px", marginRight: "-6px" }}
                                    >
                                        <div
                                            className="ant-col ant-col-xs-11 ant-col-md-11 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <div className="ant-picker css-1ay1rjo full-width">
                                                                    <div className="ant-picker-input">
                                                                        <input
                                                                            id="slots_0_date"
                                                                            readOnly=""
                                                                            placeholder="Select Date"
                                                                            title=""
                                                                            size={13}
                                                                            aria-required="true"
                                                                            autoComplete="off"
                                                                            defaultValue=""
                                                                        />
                                                                        <span className="ant-picker-suffix">
                                                                            <span
                                                                                role="img"
                                                                                aria-label="calendar"
                                                                                className="anticon anticon-calendar"
                                                                            >
                                                                                <svg
                                                                                    viewBox="64 64 896 896"
                                                                                    focusable="false"
                                                                                    data-icon="calendar"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    fill="currentColor"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z" />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-col ant-col-xs-11 ant-col-md-11 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <div className="ant-picker css-1ay1rjo full-width">
                                                                    <div className="ant-picker-input">
                                                                        <input
                                                                            id="slots_0_time"
                                                                            readOnly=""
                                                                            placeholder="Select Time"
                                                                            title=""
                                                                            size={10}
                                                                            aria-required="true"
                                                                            autoComplete="off"
                                                                            defaultValue=""
                                                                        />
                                                                        <span className="ant-picker-suffix">
                                                                            <span
                                                                                role="img"
                                                                                aria-label="clock-circle"
                                                                                className="anticon anticon-clock-circle"
                                                                            >
                                                                                <svg
                                                                                    viewBox="64 64 896 896"
                                                                                    focusable="false"
                                                                                    data-icon="clock-circle"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    fill="currentColor"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                                                                    <path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z" />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-col ant-col-xs-2 ant-col-md-2 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 512 512"
                                                                    fontSize={18}
                                                                    color="#141414"
                                                                    className="dynamic-delete-button"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    style={{ color: "rgb(20, 20, 20)", cursor: "pointer" }}
                                                                >
                                                                    <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="ant-row ant-row-middle css-1ay1rjo"
                                        style={{ marginLeft: "-6px", marginRight: "-6px" }}
                                    >
                                        <div
                                            className="ant-col ant-col-xs-11 ant-col-md-11 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <div className="ant-picker css-1ay1rjo full-width">
                                                                    <div className="ant-picker-input">
                                                                        <input
                                                                            id="slots_0_date"
                                                                            readOnly=""
                                                                            placeholder="Select Date"
                                                                            title=""
                                                                            size={13}
                                                                            aria-required="true"
                                                                            autoComplete="off"
                                                                            defaultValue=""
                                                                        />
                                                                        <span className="ant-picker-suffix">
                                                                            <span
                                                                                role="img"
                                                                                aria-label="calendar"
                                                                                className="anticon anticon-calendar"
                                                                            >
                                                                                <svg
                                                                                    viewBox="64 64 896 896"
                                                                                    focusable="false"
                                                                                    data-icon="calendar"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    fill="currentColor"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z" />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-col ant-col-xs-11 ant-col-md-11 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <div className="ant-picker css-1ay1rjo full-width">
                                                                    <div className="ant-picker-input">
                                                                        <input
                                                                            id="slots_0_time"
                                                                            readOnly=""
                                                                            placeholder="Select Time"
                                                                            title=""
                                                                            size={10}
                                                                            aria-required="true"
                                                                            autoComplete="off"
                                                                            defaultValue=""
                                                                        />
                                                                        <span className="ant-picker-suffix">
                                                                            <span
                                                                                role="img"
                                                                                aria-label="clock-circle"
                                                                                className="anticon anticon-clock-circle"
                                                                            >
                                                                                <svg
                                                                                    viewBox="64 64 896 896"
                                                                                    focusable="false"
                                                                                    data-icon="clock-circle"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    fill="currentColor"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                                                                    <path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z" />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-col ant-col-xs-2 ant-col-md-2 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 512 512"
                                                                    fontSize={18}
                                                                    color="#141414"
                                                                    className="dynamic-delete-button"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    style={{ color: "rgb(20, 20, 20)", cursor: "pointer" }}
                                                                >
                                                                    <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="ant-row ant-row-middle css-1ay1rjo"
                                        style={{ marginLeft: "-6px", marginRight: "-6px" }}
                                    >
                                        <div
                                            className="ant-col ant-col-xs-11 ant-col-md-11 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <div className="ant-picker css-1ay1rjo full-width">
                                                                    <div className="ant-picker-input">
                                                                        <input
                                                                            id="slots_0_date"
                                                                            readOnly=""
                                                                            placeholder="Select Date"
                                                                            title=""
                                                                            size={13}
                                                                            aria-required="true"
                                                                            autoComplete="off"
                                                                            defaultValue=""
                                                                        />
                                                                        <span className="ant-picker-suffix">
                                                                            <span
                                                                                role="img"
                                                                                aria-label="calendar"
                                                                                className="anticon anticon-calendar"
                                                                            >
                                                                                <svg
                                                                                    viewBox="64 64 896 896"
                                                                                    focusable="false"
                                                                                    data-icon="calendar"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    fill="currentColor"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z" />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-col ant-col-xs-11 ant-col-md-11 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <div className="ant-picker css-1ay1rjo full-width">
                                                                    <div className="ant-picker-input">
                                                                        <input
                                                                            id="slots_0_time"
                                                                            readOnly=""
                                                                            placeholder="Select Time"
                                                                            title=""
                                                                            size={10}
                                                                            aria-required="true"
                                                                            autoComplete="off"
                                                                            defaultValue=""
                                                                        />
                                                                        <span className="ant-picker-suffix">
                                                                            <span
                                                                                role="img"
                                                                                aria-label="clock-circle"
                                                                                className="anticon anticon-clock-circle"
                                                                            >
                                                                                <svg
                                                                                    viewBox="64 64 896 896"
                                                                                    focusable="false"
                                                                                    data-icon="clock-circle"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    fill="currentColor"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                                                                    <path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z" />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-col ant-col-xs-2 ant-col-md-2 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 512 512"
                                                                    fontSize={18}
                                                                    color="#141414"
                                                                    className="dynamic-delete-button"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    style={{ color: "rgb(20, 20, 20)", cursor: "pointer" }}
                                                                >
                                                                    <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="ant-row ant-row-middle css-1ay1rjo"
                                        style={{ marginLeft: "-6px", marginRight: "-6px" }}
                                    >
                                        <div
                                            className="ant-col ant-col-xs-11 ant-col-md-11 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <div className="ant-picker css-1ay1rjo full-width">
                                                                    <div className="ant-picker-input">
                                                                        <input
                                                                            id="slots_0_date"
                                                                            readOnly=""
                                                                            placeholder="Select Date"
                                                                            title=""
                                                                            size={13}
                                                                            aria-required="true"
                                                                            autoComplete="off"
                                                                            defaultValue=""
                                                                        />
                                                                        <span className="ant-picker-suffix">
                                                                            <span
                                                                                role="img"
                                                                                aria-label="calendar"
                                                                                className="anticon anticon-calendar"
                                                                            >
                                                                                <svg
                                                                                    viewBox="64 64 896 896"
                                                                                    focusable="false"
                                                                                    data-icon="calendar"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    fill="currentColor"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z" />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-col ant-col-xs-11 ant-col-md-11 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <div className="ant-picker css-1ay1rjo full-width">
                                                                    <div className="ant-picker-input">
                                                                        <input
                                                                            id="slots_0_time"
                                                                            readOnly=""
                                                                            placeholder="Select Time"
                                                                            title=""
                                                                            size={10}
                                                                            aria-required="true"
                                                                            autoComplete="off"
                                                                            defaultValue=""
                                                                        />
                                                                        <span className="ant-picker-suffix">
                                                                            <span
                                                                                role="img"
                                                                                aria-label="clock-circle"
                                                                                className="anticon anticon-clock-circle"
                                                                            >
                                                                                <svg
                                                                                    viewBox="64 64 896 896"
                                                                                    focusable="false"
                                                                                    data-icon="clock-circle"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    fill="currentColor"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                                                                    <path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z" />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-col ant-col-xs-2 ant-col-md-2 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 512 512"
                                                                    fontSize={18}
                                                                    color="#141414"
                                                                    className="dynamic-delete-button"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    style={{ color: "rgb(20, 20, 20)", cursor: "pointer" }}
                                                                >
                                                                    <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="ant-row ant-row-middle css-1ay1rjo"
                                        style={{ marginLeft: "-6px", marginRight: "-6px" }}
                                    >
                                        <div
                                            className="ant-col ant-col-xs-11 ant-col-md-11 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <div className="ant-picker css-1ay1rjo full-width">
                                                                    <div className="ant-picker-input">
                                                                        <input
                                                                            id="slots_0_date"
                                                                            readOnly=""
                                                                            placeholder="Select Date"
                                                                            title=""
                                                                            size={13}
                                                                            aria-required="true"
                                                                            autoComplete="off"
                                                                            defaultValue=""
                                                                        />
                                                                        <span className="ant-picker-suffix">
                                                                            <span
                                                                                role="img"
                                                                                aria-label="calendar"
                                                                                className="anticon anticon-calendar"
                                                                            >
                                                                                <svg
                                                                                    viewBox="64 64 896 896"
                                                                                    focusable="false"
                                                                                    data-icon="calendar"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    fill="currentColor"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z" />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-col ant-col-xs-11 ant-col-md-11 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <div className="ant-picker css-1ay1rjo full-width">
                                                                    <div className="ant-picker-input">
                                                                        <input
                                                                            id="slots_0_time"
                                                                            readOnly=""
                                                                            placeholder="Select Time"
                                                                            title=""
                                                                            size={10}
                                                                            aria-required="true"
                                                                            autoComplete="off"
                                                                            defaultValue=""
                                                                        />
                                                                        <span className="ant-picker-suffix">
                                                                            <span
                                                                                role="img"
                                                                                aria-label="clock-circle"
                                                                                className="anticon anticon-clock-circle"
                                                                            >
                                                                                <svg
                                                                                    viewBox="64 64 896 896"
                                                                                    focusable="false"
                                                                                    data-icon="clock-circle"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    fill="currentColor"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                                                                    <path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z" />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="ant-col ant-col-xs-2 ant-col-md-2 css-1ay1rjo"
                                            style={{ paddingLeft: 6, paddingRight: 6 }}
                                        >
                                            <div className="ant-form-item css-1ay1rjo">
                                                <div className="ant-row ant-form-item-row css-1ay1rjo">
                                                    <div className="ant-col ant-form-item-control css-1ay1rjo">
                                                        <div className="ant-form-item-control-input">
                                                            <div className="ant-form-item-control-input-content">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 512 512"
                                                                    fontSize={18}
                                                                    color="#141414"
                                                                    className="dynamic-delete-button"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    style={{ color: "rgb(20, 20, 20)", cursor: "pointer" }}
                                                                >
                                                                    <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                                <div className="ant-form-item css-1ay1rjo">
                                    <div className="ant-row ant-form-item-row css-1ay1rjo">
                                        <div className="ant-col ant-form-item-control css-1ay1rjo">
                                            <div className="ant-form-item-control-input">
                                                <div className="ant-form-item-control-input-content">
                                                    <button
                                                        type="button"
                                                        className="ant-btn css-1ay1rjo ant-btn-dashed ant-btn-block"
                                                    >
                                                        <span className="ant-btn-icon">
                                                            <span
                                                                role="img"
                                                                aria-label="plus"
                                                                className="anticon anticon-plus"
                                                            >
                                                                <svg
                                                                    viewBox="64 64 896 896"
                                                                    focusable="false"
                                                                    data-icon="plus"
                                                                    width="1em"
                                                                    height="1em"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z" />
                                                                    <path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8z" />
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span>Add Slots</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                        </>
                    )}

                    {/* Reason: (Optional) */}
                    <Form.Item
                        name="reason"
                        label="Reason (optional)"
                        rules={[
                            {
                                max: 500,
                                message: "Please limit your reason to 500 characters!",
                            },
                        ]}
                    >
                        <Input.TextArea
                            placeholder="Update attendee about the reason for rescheduling"
                            rows={3}
                        />
                    </Form.Item>



                    {/* Submit */}
                    <Form.Item noStyle>
                        <Button
                            block
                            className="btn-dark"
                            htmlType="submit"
                            loading={rescheduleInProgress}
                        >
                            Reschedule call
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    );
}

export default RescheduleBooking;
