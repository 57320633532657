import React, { useState } from "react";
import Footer from "..";
import Header from "../../header";
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";

const LimitedUseDisclosure = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_USER_ID") ? true : false
  );

  return (
    <>
      {loggedIn ? (
        <div style={{ marginTop: "0" }}>
          <ProtectedHeader extraClasses="inner-header" />
        </div>
      ) : (
        <Header extraClasses="inner-header" />
      )}
      <section className="Privacy_policy">
        <div className="container">
          <div className="privacy_heading">
            <h1>Google API Services Usage Disclosure</h1>
            <p>Mentorpal's use of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy </a>, including the Limited Use requirements.</p>
          </div>
          <br></br>
         
          <div className="privacy_content">
            <h3>Limited Use</h3>
            <p>
            Our app strictly complies with all conditions specified in the limited use policy of Google.
            </p>
            
            <div className="privacy_list">
              <ul style={{ listStyleType: "circle" }}>
                <li> Do not allow humans to read the user's data unless you have obtained the user's affirmative agreement to view specific messages, files, or other data.</li>
                <li> Do not use or transfer the data for serving ads, including retargeting, personalized, or interest-based advertising; and</li>
                <li> Limit your use of data to providing or improving user-facing features that are prominent in the requesting application's user interface. All other uses of the data are prohibited;</li>
                <li> Only transfer the data to others if necessary to provide or improve user-facing features that are prominent in the requesting application's user interface.</li>
            
              </ul>
              <p>Our <a href="/privacy-policy">privacy policy</a> page documents in detail what data our app is requesting and why the requests access to Google user data.</p>
            </div>
            </div>

         
        
       
    
        </div>
      </section>

      <Footer />
    </>
  );
};
export default LimitedUseDisclosure;
