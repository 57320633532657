import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import './forget.css';
import Header from '../applyAsMentor/BecomeMentorHeader';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_TOKEN_AUTH } from '../auth/tokenProvider';
import { updateUserPassword } from '../../redux/actions/login';

const ChangePasswordModule = ({ visible, onCancel }) => {

    const dispatch = useDispatch();

    const [messageApi, contextHolder] = message.useMessage();
    const [userkey, setuserkey] = useState()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const key = urlParams.get('key');
        setuserkey(key)

        console.log('Key from URL:', key);
    }, []);



    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });

    const [form] = Form.useForm();
    const [confirmDirty, setConfirmDirty] = useState(false);

    const handleConfirmBlur = (e) => {
        const value = e.target.value;
        setConfirmDirty(confirmDirty || !!value);
    };

    const handlePasswordChange = (e) => {
        setFormData({
            ...formData,
            password: e.target.value,
        });
    };

    const handleConfirmPasswordChange = (e) => {
        setFormData({
            ...formData,
            confirmPassword: e.target.value,
        });
    };

    const onFinish = (values) => {
        console.log('Form values:', values);

        if (values.password !== values.confirmPassword) {
            messageApi.error('Password and Confirm Password do not match');
            return;
        }

        if (values.password.length < 6) {
            messageApi.error('Password must be at least 6 characters long.');
            return;
        }

        console.log('Form Data:', values);

        setFormData({
            password: values.password,
            confirmPassword: values.confirmPassword,
        });
        const passwordDataModal = {
            deviceToken: REACT_TOKEN_AUTH,
            key: userkey,
            password: values.password,
            type: "forget",
            passwordData: {
                password: values.password,
                key: userkey,
                type: "forget",
            },
        };
        dispatch(updateUserPassword(passwordDataModal));
    };

    const handleSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                onFinish(values);
            })
            .catch((errorInfo) => {
                messageApi.error('Validation Failed! Try more secure password');

                console.log('Validation Failed:', errorInfo);
            });
    };

    const updateUserPasswordReducerData = useSelector(
        (state) => state.updateUserPasswordReducer
    );

    const isLoading = updateUserPasswordReducerData?.loading;

    const isSuccess = updateUserPasswordReducerData?.success;
    useEffect(() => {
        if (!isLoading && !isSuccess && updateUserPasswordReducerData?.error) {
            messageApi.error('something went wrong ,Try again!');

        }
        if (isLoading && isSuccess) {
            messageApi.error('Password successfully validated!');

        }
    }, [isSuccess, isLoading])

    return (
        <>
            {contextHolder}
            <Header />
            <div className="container d-flex justify-content-center align-items-center">
                <div className="forget-card mt-5 p-4 forget-body">
                    <Form form={form} onFinish={onFinish} layout="vertical" name="passwordForm">
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                {
                                    min: 6,
                                    message: 'Password must be at least 6 characters long.',
                                },
                            ]}
                        >
                            <Input.Password onChange={handlePasswordChange} />
                        </Form.Item>
                        <Form.Item
                            label="Confirm Password"
                            name="confirmPassword"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                            ]}
                        >
                            <Input.Password onBlur={handleConfirmBlur} onChange={handleConfirmPasswordChange} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>


                </div>
            </div>
        </>
    );
};

export default ChangePasswordModule;
