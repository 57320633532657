import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import crossImg from '../img/cross.png';

 
import { useDispatch, useSelector } from "react-redux";
import { mentorSessionServiceHelpMentee } from "../../../redux/actions/mentor";
 
import("../css/custom.css");

const Help = () => {
  const navigate = useNavigate();
  const selectedSessionTypeSavedData = useSelector((state) => {
    const data = state.mentorServiceSessionTypeReducer?.data?.type || "";
    return data;
  });
  const selectedSessioServicenHelpMenteeSavedData = useSelector((state) => {
    const data =
      state.mentorSessionServiceHelpMenteeReducer?.data?.menteePrerequisets ||
      [];
    return data;
  });
  const [txtAreaHelpMentee, setTxtAreaHelpMentee] = useState(
    selectedSessioServicenHelpMenteeSavedData[0]
  );
  const [txtAreaMenteeShouldKnow, setTxtAreaMenteeeShouldKnow] = useState(
    selectedSessioServicenHelpMenteeSavedData[1]
  );
  const [txtAreaOtherQuestion, setTxtAreaOtherQuestion] = useState(
    selectedSessioServicenHelpMenteeSavedData[2]
  );
  const dispatch = useDispatch();
  const helpDataModel = {
    menteePrerequisets: ["", "", ""],
  };

  const setHelpDetails = () => {
    helpDataModel.menteePrerequisets[0] = txtAreaHelpMentee;
    helpDataModel.menteePrerequisets[1] = txtAreaMenteeShouldKnow;
    helpDataModel.menteePrerequisets[2] = txtAreaOtherQuestion;
  };
  useEffect(() => {
     
    import("../css/custom.css");
  }, []);
  useEffect(() => {
    setHelpDetails();
    dispatch(mentorSessionServiceHelpMentee(helpDataModel));
  }, [txtAreaHelpMentee, txtAreaMenteeShouldKnow, txtAreaOtherQuestion]);

  return (
    <>
      <div id="wrapper">
        <div className="step-cont">
          <div className="step-inner-cls">
            <form action="#" method="post" id="raq_questions">
              <div className="question-container active_panel " id="question-5">
                <div className="sign-form-cls">
                  <h3>Define this session how can help the mentee?</h3>
                  <div className="custom-radio-cls">
                    <div className="custom-radio-bg">
                      <label className="custom-radio">
                        <input type="radio" checked="checked" name="radio" />
                        <span className="checkmark"></span>
                        <span className="cont">
                          {selectedSessionTypeSavedData}
                        </span>
                      </label>
                    </div>
                    <div className="form-group mess-box">
                      <textarea
                        maxLength="200"
                        value={txtAreaHelpMentee}
                        placeholder="How this session can help mentee?"
                        className="form-control"
                        onChange={(e) => setTxtAreaHelpMentee(e.target.value)}
                      ></textarea>
                      <span>Max 200 Words</span>
                    </div>
                    <div className="form-group mess-box">
                      <textarea
                        maxLength="200"
                        value={txtAreaMenteeShouldKnow}
                        placeholder="What mentee should know ?"
                        className="form-control"
                        onChange={(e) =>
                          setTxtAreaMenteeeShouldKnow(e.target.value)
                        }
                      ></textarea>
                      <span>Max 200 Words</span>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name=""
                        placeholder="More Questions if any"
                        className="form-control"
                        value={txtAreaOtherQuestion}
                        onChange={(e) =>
                          setTxtAreaOtherQuestion(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
