import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import crossImg from '../img/cross.png';

 
import { useDispatch, useSelector } from "react-redux";
import { mentorSessionServiceDetails } from "../../../redux/actions/mentor";
 
import("../css/custom.css");

const Details = () => {
  const navigate = useNavigate();
  const selectedSessionTypeSavedData = useSelector((state) => {
    const data = state.mentorServiceSessionTypeReducer?.data?.type || "";
    return data;
  });
  const selectedSessionDetailsSavedData = useSelector((state) => {
    const data = state.mentorSessionServiceDetailsReducer?.data || "";
    return data;
  });
  const [sessionTitle, setSessionTitle] = useState(
    selectedSessionDetailsSavedData?.title
  );
  const [sessionDuration, setSessionDuration] = useState(
    selectedSessionDetailsSavedData?.planDuration
  );
  const [servicePrice, setServicePrice] = useState(
    selectedSessionDetailsSavedData?.price
  );
  const dispatch = useDispatch();

  const sessionDetailsDataModel = {
    price: "",
    title: "",
    planDuration: "",
  };
  const setSessionDetails = () => {
    sessionDetailsDataModel.price = servicePrice;
    sessionDetailsDataModel.title = sessionTitle;
    sessionDetailsDataModel.planDuration = sessionDuration;
  };

  const sessionDurationChoice = [
    {
      text: "Duration",
      value: "",
    },
    {
      text: "Monthly",
      value: "Monthly",
    },
    {
      text: "Weekly",
      value: "Weekly",
    },
    {
      text: "Weekend Only (Saturday & Sunday)",
      value: "Weekend Only (Saturday & Sunday)",
    },
  ];
  useEffect(() => {
    setSessionDetails();
    dispatch(mentorSessionServiceDetails(sessionDetailsDataModel));
  }, [sessionTitle, servicePrice, sessionDuration]);
  useEffect(() => {
     
    import("../css/custom.css");
  }, []);

  return (
    <>
      <div id="wrapper">
        <div className="step-cont">
          <div className="step-inner-cls">
            <form action="#" method="post" id="raq_questions">
              <div className="question-container active_panel" id="question-2">
                <div className="sign-form-cls">
                  <h3>Basic details about the session</h3>
                  <div className="custom-radio-cls">
                    <div className="custom-radio-bg">
                      <label className="custom-radio">
                        <input
                          type="radio"
                          checked="checked"
                          name="radio"
                          disabled
                        />
                        <span className="checkmark"></span>
                        <span className="cont">
                          {selectedSessionTypeSavedData}
                        </span>
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name=""
                        placeholder="Session Title"
                        className="form-control"
                        value={sessionTitle}
                        onChange={(e) => setSessionTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <select
                        name=""
                        id=""
                        className="form-control"
                        value={sessionDuration}
                        onChange={(e) => setSessionDuration(e.target.value)}
                      >
                        {sessionDurationChoice.map((choice) => (
                          <option value={choice.value}>{choice.text}</option>
                        ))}

                        {/* <option value="Monthly">Monthly</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Weekend Only (Saturday & Sunday)">
                            Weekend Only (Saturday & Sunday)
                          </option> */}
                      </select>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name=""
                        placeholder="Budget/pricing"
                        className="form-control"
                        value={servicePrice}
                        onChange={(e) => setServicePrice(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
