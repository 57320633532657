import React, { useState } from "react";
import "./UpdateLeadership.css";
import { userMentorshipData } from "../../../../assets/data/data";
import ReactSelect from "react-select";

const UpdateLeadership = (props) => {
  const customKeyLeadership = props?.leadership?.map((item) => {
    return { label: item, value: item };
  });
  const [selectedLeadership, setSelectedLeadership] =
    useState(customKeyLeadership);
  const setLeadershipValue = (e) => {
    let tempList = selectedLeadership;
    e?.map((item) => tempList?.push(item?.label));
    tempList.push(e);

    setSelectedLeadership(e);
  };
  const handleOnClick = () => {
    let leaderships = selectedLeadership?.map((item) => item?.value);
    let tempData = { leadership: leaderships };
    props?.getUpdatedValues(tempData);
  };
  return (
    <div className="update-leadership-main-container">
      {/* <h7>What leadership(s) do you speak?</h7> */}
      <div className="leadership-input">
        <ReactSelect
          required={true}
          value={selectedLeadership ? selectedLeadership : ""}
          isMulti
          name="leadership"
          onChange={setLeadershipValue}
          options={userMentorshipData}
        />
      </div>
      <button className="update-btn-leadership" onClick={handleOnClick}>
        Update
      </button>
    </div>
  );
};

export default UpdateLeadership;
