import React from "react";

const Step2 = ({
  createMeetingData,
  handleChange,
  handleNextStep,
  handlePreviousStep,
}) => {
  const { topic, meeting_link } = createMeetingData;

  return (
    <div>
      <section className="step__goal__item">
        <div>
          <div className="container">
            <div className="step__count help-cont">
              <p>STEP 2 of 3</p>
              <h4>What's your topic right now?</h4>
            </div>
          </div>
          <form action="">
            <div className="form-group">
              <input
                className=" text-left form-control"
                type="text"
                placeholder="Topic"
                value={topic}
                onChange={(e) => handleChange("topic", e.target.value)}
              />
            </div>

            <div className="step__count help-cont">
              <h4>Enter Your Meeting Link??</h4>
            </div>

            <div className="form-group">
              <input
                className=" text-left form-control"
                type="text"
                placeholder="MeetingLink"
                value={meeting_link}
                onChange={(e) => handleChange("meeting_link", e.target.value)}
              />
            </div>
            <div className="d-flex">
              <div className="get-started-cls" id="get-started-cls">
                <button
                  onClick={handlePreviousStep}
                  type="button"
                  className="btn btn-primary"
                >
                  Previous
                </button>
              </div>
              <div className="get-started-cls" id="get-started-cls">
                <button
                  onClick={handleNextStep}
                  type="button"
                  className="btn btn-primary"
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Step2;
