import { Button, Col, Modal } from "antd";
import React from "react";


const WithdrawSuccess = (props) => {
    const { onClose } = props;
    return (
        <Modal
            open={true}
            closable={false}
            centered
            footer={null}
            width={360}
            className="withdraw-success-content"
        >
            <Col>
                <img
                    src={''}
                    play
                    className="withdraw-success-lottie"
                    style={{ margin: "0 auto" }}
                />
                <div className="title">Payment initiated</div>
                <div>
                    <div className="text">
                        Funds are on the way to your account, In case of any issues reach
                        out at help@mentorpal.ai
                    </div>
                </div>
                <Button className="btn-dark" block onClick={onClose}>
                    Okay
                </Button>
            </Col>
        </Modal>
    );
};

export default WithdrawSuccess;
