import {
  loginConstants,
  emailConstants,
  otpConstants,
  passwordLoginConstants,
  signUpConstants,
  socialLoginConstants,
  updateProfileConstants,
  signUpVerifyConstants,
  forgetPasswordConstants,
  userDetailsConstants,
  updateUserPasswordConstants,
} from "../../constants";

const userRegistrationStatus = "signup"  //registered onhold activated

export const otpReducer = (state = {}, action) => {
  switch (action.type) {
    case otpConstants.GENERATE_OTP_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case otpConstants.GENERATE_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case otpConstants.GENERATE_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const emailVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case emailConstants.VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case emailConstants.VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
        headers: action.headers,
      };
    case emailConstants.VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        error: action.error,
      };
    default:
      return state;
  }
};
export const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case loginConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
        headers: action.headers,
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const passwordLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case passwordLoginConstants.PASSWORD_LOGIN_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case passwordLoginConstants.PASSWORD_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
        status: action.status,
        message: action.message,
        headers: action.headers,
      };
    case passwordLoginConstants.PASSWORD_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        error: action.error,
      };
    default:
      return state;
  }
};
export const signUpReducer = (state = {}, action) => {
  switch (action.type) {
    case signUpConstants.SIGN_UP_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case signUpConstants.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        message: action.message,
        error: action.error,
      };
    case signUpConstants.SIGN_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const socialLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case socialLoginConstants.SOCIAL_LOGIN_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case socialLoginConstants.SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case socialLoginConstants.SOCIAL_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const updateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case updateProfileConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case updateProfileConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        data: action.data,
        error: action.error,
      };
    case updateProfileConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const signUpVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case signUpVerifyConstants.SIGNUP_VERIFY_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case signUpVerifyConstants.SIGNUP_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        headers: action.headers,
        status: action.status,
        message: action.message,
        error: action.error,
      };
    case signUpVerifyConstants.SIGNUP_VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const forgetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case forgetPasswordConstants.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case forgetPasswordConstants.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
        message: action.message,
        headers: action.headers,
      };
    case forgetPasswordConstants.FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case userDetailsConstants.USER_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case userDetailsConstants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        userStatus: userRegistrationStatus,
        error: action.error,
      };
    case userDetailsConstants.USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const updateUserPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case updateUserPasswordConstants.UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case updateUserPasswordConstants.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
        success: true,
      };
    case updateUserPasswordConstants.UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    default:
      return state;
  }
};
