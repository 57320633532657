import React from "react";
import { useState } from "react";

const UpdatePhone = ({ PhoneData, getUpdatedValues }) => {
    const [PhoneTxt, setPhoneTxt] = useState(PhoneData);
    const handleOnChange = (e) => {
        setPhoneTxt(e.target.value);
    };
    const handleOnClick = () => {
        let tempData = { phone: PhoneTxt };
        getUpdatedValues(tempData);
    };
    return (
        <div className="update-linkedin-main-container">
            <input
                className="linkedin-input"
                value={PhoneTxt}
                onChange={handleOnChange}
            ></input>
            <button className="update-btn-linkedin" onClick={handleOnClick}>
                Update
            </button>
        </div>
    );
};

export default UpdatePhone;
