import { useRoutes } from "react-router-dom";

import AdminDashboard from "./components/adminDashboard";
import MyProfile from "./components/dashboard/Myprofile/index";
import Profilecheck from "./components/dashboard/Myprofile/profile";
import ApplyAsMentor from "./components/applyAsMentor";
import Calendly from "./components/calandely/app";
import DashBoard from "./components/dashboard";
import DashboardMentorSubcription from "./components/dashboard/dashboard-mentor-subcription";
import Ebooks from "./components/dashboard/eBooks";
import MentorDetails from "./components/mentorDetails";
import LoginModal from "./components/modals/loginModal";
import LandingPage from "./components/verifyEmailLandPage";
import Home from "./components/home";
import MentorProfile from "./components/adminDashboard/profile";
import PublishEbooks from "./components/adminDashboard/publishEbook";
import MentorSession from "./components/adminDashboard/mentorshipSessions";
import CreateSession from "./components/adminDashboard/createSession";
import Available from "./components/adminDashboard/createSessions/availability";
import AdminDashboardSection from "./components/adminDashboard/adminDashboardSection";
import Personal from "./components/adminDashboard/profile/personal";
import Professional from "./components/adminDashboard/profile/professional";
import Security from "./components/adminDashboard/profile/security";
import MyEbooks from "./components/adminDashboard/MyEbooks";
import AllActiveSession from "./components/adminDashboard/MentorshipSession/AllActiveSession";
import OpenSession from "./components/adminDashboard/MentorshipSession/AllActiveSession/OpenSession";
import PrivateSession from "./components/adminDashboard/MentorshipSession/AllActiveSession/PrivateSession";
import MentorshipRequests from "./components/adminDashboard/MentorshipRequest";
import MentorSubscription from "./components/adminDashboard/mentorSubscriptions";
import OpenSessions from "./components/openSessions/OpenSessions";
import Availability from "./components/adminDashboard/Availability";
import AvailableHours from "./components/adminDashboard/Availability/AvailableHours";
import LeaveDates from "./components/adminDashboard/Availability/leaveDates";
import GroupSession from "./components/adminDashboard/GroupSession";
import AboutSession from "./components/adminDashboard/GroupSession/aboutsession";
import SessionSchedule from "./components/adminDashboard/GroupSession/sessionsedule";
import { FeedbackForm } from "./components/Feedback";
import { Mentorfilter } from "./components/mentorFilter";
import AboutUs from "./components/footer/AboutUs";
import Terms from "./components/footer/TermsAndCondition";
import LimitedUseDisclosure from "./components/footer/LimitedUseDisclosure";
import Privacy from "./components/footer/PrivacyAndPolicy";
import EventCalendar from "./components/dashboard/events";
import UpdateAbout from "./components/adminDashboard/profileUpdate/UpdateAbout";
import Seven from "./components/applyAsMentor/RegistrationProcess/Seven";
import MBTI from "./components/mbti";
import { CalendarConnectRedirection } from "./components/googleRedictionPage";
import CV from "./components/CV";
import PersonalityTestResult from "./components/mbti/PersonalityTest";
import PersonalityType from "./components/mbti/PersonalityType";
import FullStack from "./components/AMT/FullStack";
import SessionDetails from "./components/openSessions/SessionDetails";
import EnrollSession from "./components/openSessions/EnrollSession";

import NewSession from "./components/openSessions/createSession/newSession";
import MentorPricing from "./components/Pricing";
import BecomeMentor from "./components/BecomeAMentor/BecomeMentor";
import LiveSessionList from "./components/openSessions/UpdateFindMentor";
import BankDetails from "./components/adminDashboard/BankDetails/BankDetails";
import GoogleCalender from "./components/applyAsMentor/RegistrationProcess/CalenderGoogle";
import MenteeGoal from "./components/MenteeGoal";
import Amtlab from "./components/AMTLAB/Amtlab";
import Contactus from "./components/Contact/Contact-us";
import OurTeam from "./components/TeamPage/ourTeam";
import Listing from "./components/MentorDashboard/MenteesListing/Listing";
import MentorMenteeMessaging from "./components/adminDashboard/Message";
import AdminSideNavBar from "./components/MentorDashboard/AdminSideNavBar";
import MentorAdminDashboard from "./components/MentorDashboard/MentorAdminDashboard";
import ChatModule from "./components/ChatModule";
import MenteeSideNavBar from "./components/MenteeAdminDashboard/MenteeSideNavBar";
import MenteeAdminDashboard from "./components/MenteeAdminDashboard";
import YourGoal from "./components/MenteeAdminDashboard/YourGoal";
import PendingRequest from "./components/ConnectionRequest/PendingRequest";
import AccpetedRequest from "./components/ConnectionRequest/AccpetedRequest";
import AllConnectionRequest from "./components/ConnectionRequest";
import AllMenteeConnection from "./components/ConnectionRequest/MenteeConnection";
import RequestSendingList from "./components/ConnectionRequest/MenteeConnection/RequestSendingList";
import Notifications from "./components/adminDashboard/Notifications";
import LiveSessions from "./components/MenteeAdminDashboard/liveSessions";
import PaymentSuccess from "./components/BookSession/PaymentSuccess";
import PageNotFound from "./components/4o4Page";
import MentorAvailability from "./components/MentorAvailability/main";
import ProBonoBooking from "./components/BookSession/proBonoBooking";
import HireTalent from "./components/hireTalents";
import Filter from "./components/hireTalents/filter";
import AllList from "./components/hireTalents/candidateList/AllList";
import Savedlist from "./components/hireTalents/candidateList/Savedlist";
import MasterCourses from "./components/MasterCourses";
import MasterCourseListing from "./components/MasterCourses/CourseListing";
import Training from "./components/Training/Training";
import HostCourseLandingPage from "./components/HostCourse";
import Enterprise from "./components/Training/Enterprise";
import RequestDemo from "./components/Training/RequestDemo/RequestDemo";
import AllCourse from "./components/HostCourse/CreateCourse/DashBoardAllCourse";
import CreateCourse from "./components/HostCourse/CreateCourse/Create";
import AddContent from "./components/HostCourse/CreateCourse/Create/addContent";
import CourseCheckout from "./components/MasterCourses/CourseChekoutModal/CourseCheckout";
import MyCourseDashBoard from "./components/MasterCourses/MenteeDashBoard";
import AllCourseDashboard from "./components/MasterCourses/MenteeDashBoard/AllCourse";
import ContinueCourse from "./components/MasterCourses/MenteeDashBoard/ContinueCourse";
import PurchaseHistory from "./components/MenteeAdminDashboard/PurchaseHistory";
import CoursePreview from "./components/HostCourse/CreateCourse/Preview";
import CourseAllInfo from "./components/HostCourse/CourseInfo";
import Information from "./components/HostCourse/CourseInfo/information/info";
import Details from "./components/HostCourse/CourseInfo/information/details";
import Pricing from "./components/HostCourse/CourseInfo/information/pricing";
import Advanced from "./components/HostCourse/CourseInfo/information/advanced";
import Pages from "./components/HostCourse/CourseInfo/information/pages";
import Certificate from "./components/HostCourse/CourseInfo/certificates";
import Learner from "./components/HostCourse/CourseInfo/Learners";
import CertificatedDetails from "./components/HostCourse/CourseInfo/certificates/CertificatedDetails";
import CertificateIssued from "./components/HostCourse/CourseInfo/certificates/CertificateIssued";
import CertificateBlackList from "./components/HostCourse/CourseInfo/certificates/CertificateBlackList";
import CourseDetails from "./components/MasterCourses/CourseDetails/courseDetails";
import CourseCommunity from "./components/HostCourse/CourseInfo/Community";
import EnquiryPage from "./components/EnquiryPage";
import CreateWebinar from "./components/Webinar/CreateWebinar";
import Webinar from "./components/Webinar";
import ExploreWebinar from "./components/Webinar/exploreallWebinar";
import Questionnaire from "./components/Questionnaire";
import MqtResultPage from "./components/Questionnaire/Modal/ResultPage";
import SetUpYourPage from "./components/OnboardingMentor/welcomeMentor";
import { MentorDashboardComponent } from "./components/MentorDashboardNew";
import HomeDashboard from "./components/MentorDashboardNew/HomeComponent/Home";
import BookingComponenets from "./components/MentorDashboardNew/Bookings";
import Calls from "./components/MentorDashboardNew/Bookings/Calls/index";
import Packages from "./components/MentorDashboardNew/Bookings/Packages/index";
import PackagesUpcoming from "./components/MentorDashboardNew/Bookings/Packages/Upcoming";
import PackagesCompleted from "./components/MentorDashboardNew/Bookings/Packages/Completed";
import WebinarComponent from "./components/MentorDashboardNew/Bookings/Webinar/index";
import WebinarUpcoming from "./components/MentorDashboardNew/Bookings/Webinar/Upcoming";
import WebinarCompleted from "./components/MentorDashboardNew/Bookings/Webinar/Completed";
import DigitalProduct from "./components/MentorDashboardNew/Bookings/DigitalProduct/index";
import QueriesTab from "./components/MentorDashboardNew/Queries/QueriesTab";
import PendingQueries from "./components/MentorDashboardNew/Queries/Pending";
import AnswerdQueries from "./components/MentorDashboardNew/Queries/Answerd";
import CalenderTime from "./components/MentorDashboardNew/Calender";
import Schedule from "./components/MentorDashboardNew/Calender/schedule";
import AvailabilityCalender from "./components/MentorDashboardNew/Calender/Availability";
import MentorServices from "./components/MentorDashboardNew/Services";
import PaymentsMentor from "./components/MentorDashboardNew/PayMents/Index";
import ProfileComponent from "./components/MentorDashboardNew/Profile";
import ReferralComponet from "./components/MentorDashboardNew/Referral";
import Rewards from "./components/MentorDashboardNew/Rewards";
import ApplyAsMentee from "./components/MenteeAdminDashboard/ApplyAsMentee";
import SignUpModalDynamic from "./components/applyAsMentor/SignUpMentorModel/index";
import BookingMeetDetails from "./components/mentorDetails/BookingDetails";
import OneYearMentorship from "./components/MenteeAdminDashboard/OneYearMentoship";
import ChangePasswordModule from "./components/forgetPassword";
import MainServiceListing from "./components/MentorDashboardNew/Services/MainServiceListing";
import AddNewService from "./components/MentorDashboardNew/Services/AddNewService";
import ServicesEdit from "./components/MentorDashboardNew/Services/EditService/ServiceEdit";
import SocialTemplets from "./components/MentorDashboardNew/SocialTemplets";
import OnboardingMentor from "./components/OnboardingMentor";
import Communications from "./components/MentorDashboardNew/Connections";
import ChatSystemModule from "./components/MentorDashboardNew/Connections/ChatSystemModule";
import YourConnection from "./components/MentorDashboardNew/Connections/YourConnection";
import { Feedback } from "./components/MentorDashboardNew/Feedback";
import HireTalentLanding from "./components/hireTalents/LandingPage";
import MentorEbooks from "./components/MentorDashboardNew/E-Books";
import HireTalentHomePage from "./components/hireTalents/LandingPage/hireTalentHome";
export const MentorRoutes = () => {
  return useRoutes([
    {
      path: "",
      sensitive: true,
      element: <AdminSideNavBar />,
      children: [
        {
          path: "",
          sensitive: true,
          element: <MentorAdminDashboard />,
        },
        {
          path: "sessions",
          sensitive: true,
          element: <AllActiveSession />,
          children: [
            {
              path: "upcoming",
              sensitive: true,
              element: <PrivateSession />,
            },
            {
              path: "history",
              sensitive: true,
              element: <OpenSession />,
            },
          ],
        },

        {
          path: "profile",
          sensitive: true,
          element: <MentorProfile />,
          children: [
            {
              path: "",
              sensitive: true,
              element: <Personal />,
            },
            {
              path: "profession",
              sensitive: true,
              element: <Professional />,
            },
            {
              path: "security-setting",
              sensitive: true,
              element: <Security />,
            },
          ],
        },

        {
          path: "all-mentorship-requests",
          sensitive: true,
          element: <MentorshipRequests />,
        },
        {
          path: "mentor-subscriptions",
          sensitive: true,
          element: <MentorSubscription />,
        },
        { path: "my-ebooks", sensitive: true, element: <MyEbooks /> },
        { path: "publish-ebooks", sensitive: true, element: <PublishEbooks /> },
        { path: "group-session", sensitive: true, element: <GroupSession /> },
        {
          path: "connect-calendar",
          sensitive: true,
          element: <GoogleCalender />,
        },
        { path: "upload-intro", sensitive: true, element: <Seven /> },
        {
          path: "connection-request",
          sensitive: true,
          element: <AllConnectionRequest />,
          children: [
            {
              path: "",
              sensitive: true,
              element: <PendingRequest />,
            },
            {
              path: "accpeted-Request",
              sensitive: true,
              element: <AccpetedRequest />,
            },
          ],
        },

        {
          path: "availability",
          sensitive: true,
          element: <Availability />,
          // children: [
          //   {
          //     path: "",sensitive: true,
          //     element: <AvailableHours />,
          //   },
          //   {
          //     path: "leave-dates",sensitive: true,
          //     element: <LeaveDates />,
          //   },
          // ],
        },
        {
          path: "post-article",
          sensitive: true,
          element: (
            <h2 style={{ whiteSpace: "normal" }}>
              Post Article component hasn't been added yet
            </h2>
          ),
        },
        { path: "create-sessions", sensitive: true, element: <NewSession /> },
        { path: "bank-details", sensitive: true, element: <BankDetails /> },
        { path: "notifications", sensitive: true, element: <Notifications /> },
        {
          path: "course/create-course",
          sensitive: true,
          element: <CreateCourse />,
        },
        { path: "course", sensitive: true, element: <AllCourse /> },
        { path: "webinar", sensitive: true, element: <Webinar /> },
        {
          path: "webinar/create-webinar",
          sensitive: true,
          element: <CreateWebinar />,
        },
      ],
    },
    {
      path: "mentor-details/:user/",
      sensitive: true,
      element: <MentorDetails />,
    },
    { path: "find-mentor", sensitive: true, element: <LiveSessionList /> },
    { path: "training", sensitive: true, element: <Training /> },
    { path: "onboard-mentor", sensitive: true, element: <OnboardingMentor /> },
    { path: "mentor-page", sensitive: true, element: <SetUpYourPage /> },
    {
      path: "request-demo",
      sensitive: true,
      element: <RequestDemo />,
    },
    { path: "my-ebooks", sensitive: true, element: <Ebooks /> },
    // { path: "login" , sensitive: true ,  element: <LoginModal /> },
    // {
    //   path: "dashboard/dashboard-mentor-subcription",
    //   element: <DashboardMentorSubcription />,
    // },
    // { path: "myprofile" , sensitive: true ,  element: <MyProfile /> },
    { path: "apply-as-mentor", sensitive: true, element: <ApplyAsMentor /> },
    { path: "pricing", sensitive: true, element: <MentorPricing /> },

    { path: "book-session", sensitive: true, element: <Calendly /> },
    // { path: "email-verification/:code" , sensitive: true ,  element: <LandingPage /> },
    { path: "/", sensitive: true, element: <Home /> },
    // { path: "profile" , sensitive: true ,  element: <MentorProfile /> },
    // { path: "available" , sensitive: true ,  element: <Available /> },
    { path: "open-sessions", sensitive: true, element: <OpenSessions /> },
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    { path: "feedback", sensitive: true, element: <FeedbackForm /> },
    { path: "filterMentor", sensitive: true, element: <Mentorfilter /> },
    { path: "about-us", sensitive: true, element: <AboutUs /> },
    { path: "terms", sensitive: true, element: <Terms /> },
    {
      path: "enterprise",
      sensitive: true,
      element: <Enterprise />,
    },
    {
      path: "limited-use-disclosure",
      sensitive: true,
      element: <LimitedUseDisclosure />,
    },
    {
      path: "calendar-redirection",
      sensitive: true,
      element: <CalendarConnectRedirection />,
    },
    { path: "privacy-policy", sensitive: true, element: <Privacy /> },
    {
      path: "personality-result",
      sensitive: true,
      element: <PersonalityTestResult />,
    },
    {
      path: "personality-types",
      sensitive: true,
      element: <PersonalityType />,
    },
    // { path: "personality-details" , sensitive: true ,  element: <PersonalityDetails /> },
    { path: "personality-test", sensitive: true, element: <MBTI /> },
    { path: "become-mentor", sensitive: true, element: <BecomeMentor /> },
    { path: "Amtlab", sensitive: true, element: <Amtlab /> },
    { path: "contact-us", sensitive: true, element: <Contactus /> },
    { path: "OurTeam", sensitive: true, element: <OurTeam /> },

    { path: "Chat", sensitive: true, element: <ChatModule /> },
    { path: "message", sensitive: true, element: <MentorMenteeMessaging /> },
    {
      path: "course/create-course/add-syllabus",
      sensitive: true,
      element: <AddContent />,
    },
    {
      path: "hire-talent",
      sensitive: true,
      element: <HireTalent />,
      children: [
        {
          path: "",
          sensitive: true,
          element: <AllList />,
        },
        {
          path: "saved",
          sensitive: true,
          element: <Savedlist />,
        },
      ],
    },

    {
      path: "dashboard",
      sensitive: true,
      element: <MentorDashboardComponent />,
      children: [
        {
          path: "home",
          sensitive: true,
          element: <HomeDashboard />,
        },
        {
          path: "feedback",
          sensitive: true,
          element: <Feedback />,
        },
        {
          path: "social-templates",
          sensitive: true,
          element: <SocialTemplets />,
        },
        {
          path: "e-books", sensitive: true,
          element: <MentorEbooks />
        },

        {
          path: "calls",
          sensitive: true,
          element: <BookingComponenets />,
          children: [
            {
              path: "video",
              sensitive: true,
              element: <Calls />,
            },
            {
              path: "package",
              sensitive: true,
              element: <Packages />,
              children: [
                {
                  path: "upcoming",
                  sensitive: true,
                  element: <PackagesUpcoming />,
                },
                {
                  path: "completed",
                  sensitive: true,
                  element: <PackagesCompleted />,
                },
              ],
            },
            {
              path: "webinar",
              sensitive: true,
              element: <WebinarComponent />,
              children: [
                {
                  path: "upcoming",
                  sensitive: true,
                  element: <WebinarUpcoming />,
                },
                {
                  path: "completed",
                  sensitive: true,
                  element: <WebinarCompleted />,
                },
              ],
            },
            {
              path: "product",
              sensitive: true,
              element: <DigitalProduct />,
            },
          ],
        },
        {
          path: "communications",
          sensitive: true,
          element: <Communications />,
          children: [
            {
              path: "messages",
              sensitive: true,
              element: <ChatSystemModule />,
            },
            {
              path: "connections",
              sensitive: true,
              element: <YourConnection />,
            },
          ],
        },
        {
          path: "queries",
          sensitive: true,
          element: <QueriesTab />,
          children: [
            {
              path: "pending",
              sensitive: true,
              element: <PendingQueries />,
            },
            {
              path: "answered",
              sensitive: true,
              element: <AnswerdQueries />,
            },
          ],
        },
        {
          path: "availability",
          sensitive: true,
          element: <CalenderTime />,
          children: [
            {
              path: "configurations",
              sensitive: true,
              element: <AvailabilityCalender />,
            },
            {
              path: "schedule",
              sensitive: true,
              element: <Schedule />,
            },
          ],
        },
        {
          path: "services",
          sensitive: true,
          element: <MentorServices />,
          children: [
            {
              path: ":service_type",
              sensitive: true,
              element: <MainServiceListing />,
            },
          ],
        },

        {
          path: "service/add",
          sensitive: true,
          element: <AddNewService />,
        },
        {
          path: "service/edit/id=:id",
          sensitive: true,
          element: <ServicesEdit />,
        },

        {
          path: "payments",
          sensitive: true,
          element: <PaymentsMentor />,
        },
        {
          path: "profile",
          sensitive: true,
          element: <ProfileComponent />,
        },
        { path: "referral", sensitive: true, element: <ReferralComponet /> },
        { path: "rewards", sensitive: true, element: <Rewards /> },
      ],
    },
    { path: "master-courses", sensitive: true, element: <MasterCourses /> },
    {
      path: "explore-courses",
      sensitive: true,
      element: <MasterCourseListing />,
    },
    {
      path: "host-course",
      sensitive: true,
      element: <HostCourseLandingPage />,
    },
    { path: "PaymentSuccess", sensitive: true, element: <PaymentSuccess /> },
    { path: "course-preview", sensitive: true, element: <CoursePreview /> },
    { path: "enquiry", sensitive: true, element: <EnquiryPage /> },

    {
      path: "course/:slug/:product_id/",
      sensitive: true,
      element: <CourseAllInfo />,
      children: [
        {
          path: "info",
          sensitive: true,
          element: <Information />,
          children: [
            {
              path: "",
              sensitive: true,
              element: <Details />,
            },
            {
              path: "pricing",
              sensitive: true,
              element: <Pricing />,
            },
            {
              path: "advanced",
              sensitive: true,
              element: <Advanced />,
            },
            {
              path: "pages",
              sensitive: true,
              element: <Pages />,
            },
          ],
        },
        {
          path: "sessions",
          sensitive: true,
          element: <AllActiveSession />,
          children: [
            {
              path: "upcoming",
              sensitive: true,
              element: <PrivateSession />,
            },
            {
              path: "history",
              sensitive: true,
              element: <OpenSession />,
            },
          ],
        },

        { path: "learner", sensitive: true, element: <Learner /> },
        {
          path: "certificates",
          sensitive: true,
          element: <Certificate />,
          children: [
            {
              path: "",
              sensitive: true,
              element: <CertificatedDetails />,
            },
            {
              path: "issued",
              sensitive: true,
              element: <CertificateIssued />,
            },
            {
              path: "blacklist",
              sensitive: true,
              element: <CertificateBlackList />,
            },
          ],
        },
        {
          path: "builder",
          sensitive: true,
          element: <AddContent />,
        },
        {
          path: "community-chat",
          sensitive: true,
          element: <CourseCommunity />,
        },
      ],
    },
    {
      path: "webinar/:slug/:product_id/",
      sensitive: true,
      element: <CourseAllInfo />,
      children: [
        {
          path: "info",
          sensitive: true,
          element: <Information />,
          children: [
            {
              path: "",
              sensitive: true,
              element: <Details />,
            },
            {
              path: "pricing",
              sensitive: true,
              element: <Pricing />,
            },
            {
              path: "advanced",
              sensitive: true,
              element: <Advanced />,
            },
            {
              path: "pages",
              sensitive: true,
              element: <Pages />,
            },
          ],
        },
        {
          path: "sessions",
          sensitive: true,
          element: <AllActiveSession />,
          children: [
            {
              path: "upcoming",
              sensitive: true,
              element: <PrivateSession />,
            },
            {
              path: "history",
              sensitive: true,
              element: <OpenSession />,
            },
          ],
        },

        { path: "learner", sensitive: true, element: <Learner /> },
        {
          path: "certificates",
          sensitive: true,
          element: <Certificate />,
          children: [
            {
              path: "",
              sensitive: true,
              element: <CertificatedDetails />,
            },
            {
              path: "issued",
              sensitive: true,
              element: <CertificateIssued />,
            },
            {
              path: "blacklist",
              sensitive: true,
              element: <CertificateBlackList />,
            },
          ],
        },
        {
          path: "community-chat",
          sensitive: true,
          element: <CourseCommunity />,
        },
      ],
    },
  ]);
};

export const PublicRoutes = () => {
  return useRoutes([
    { path: "mentorship", sensitive: true, element: <Home /> },
    { path: "/", sensitive: true, element: <HireTalentHomePage /> },
    {
      path: "/login-redirect",
      sensitive: true,
      element: <Home loader={true} />,
    },
    {
      path: "apply-as-mentor",
      sensitive: true,
      element: <SignUpModalDynamic />,
    },
    {
      path: "apply-as-mentee",
      sensitive: true,
      element: <SignUpModalDynamic />,
    },
    // { path: "find-mentor", sensitive: true, element: <LiveSessionList /> },
    {
      path: "find-mentor/:urlExprtise",
      sensitive: true,
      element: <LiveSessionList />,
    },
    { path: "find-mentor", sensitive: true, element: <LiveSessionList /> },
    // { path: "apply-as-mentor" , sensitive: true ,  element: <ApplyAsMentor /> },
    {
      path: "mentor-details/:user/",
      sensitive: true,
      element: <MentorDetails />,
    },
    { path: "open-sessions", sensitive: true, element: <OpenSessions /> },
    { path: "login", sensitive: true, element: <LoginModal /> },
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    { path: "group-session", sensitive: true, element: <GroupSession /> },
    { path: "session-schedule", sensitive: true, element: <SessionSchedule /> },
    { path: "about-session", sensitive: true, element: <AboutSession /> },
    { path: "feedback", sensitive: true, element: <FeedbackForm /> },
    {
      path: "email-verification/:code",
      sensitive: true,
      element: <LandingPage />,
    },
    { path: "about-us", sensitive: true, element: <AboutUs /> },
    { path: "terms", sensitive: true, element: <Terms /> },
    {
      path: "limited-use-disclosure",
      sensitive: true,
      element: <LimitedUseDisclosure />,
    },
    {
      path: "enterprise",
      sensitive: true,
      element: <Enterprise />,
    },
    { path: "privacy-policy", sensitive: true, element: <Privacy /> },
    { path: "personality-test", sensitive: true, element: <MBTI /> },
    {
      path: "open-sessions",
      sensitive: true,
      element: <OpenSession />,
    },

    {
      path: "calendar-redirection",
      sensitive: true,
      element: <CalendarConnectRedirection />,
    },
    { path: "yourcv", sensitive: true, element: <CV /> },
    {
      path: "personality-result",
      sensitive: true,
      element: <PersonalityTestResult />,
    },
    {
      path: "personality-types",
      sensitive: true,
      element: <PersonalityType />,
    },
    // { path: "personality-details" , sensitive: true ,  element: <PersonalityDetails /> },
    { path: "full-stack", sensitive: true, element: <FullStack /> },
    {
      path: "session-details/:sessionId",
      sensitive: true,
      element: <SessionDetails />,
    },
    { path: "enroll-session", sensitive: true, element: <EnrollSession /> },
    { path: "pricing", sensitive: true, element: <MentorPricing /> },
    { path: "become-mentor", sensitive: true, element: <BecomeMentor /> },
    { path: "set-goal", sensitive: true, element: <MenteeGoal /> },
    { path: "Amtlab", sensitive: true, element: <Amtlab /> },
    { path: "contact-us", sensitive: true, element: <Contactus /> },
    { path: "OurTeam", sensitive: true, element: <OurTeam /> },
    { path: "Chat", sensitive: true, element: <ChatModule /> },
    { path: "Listing", sensitive: true, element: <Listing /> },

    {
      path: "booksession/:user/",
      sensitive: true,
      element: <BookingMeetDetails />,
    },
    {
      path: "request-demo",
      sensitive: true,
      element: <RequestDemo />,
    },
    { path: "training", sensitive: true, element: <Training /> },

    //{ path: "amt" , element:<Amt/>}
    { path: "message", sensitive: true, element: <MentorMenteeMessaging /> },
    {
      path: "hire-talent",
      sensitive: true,
      element: <HireTalentLanding />,
      
    },
    
    {
      path: "list-talent",
      sensitive: true,
      element: <HireTalent />,
      children: [
        {
          path: "",
          sensitive: true,
          element: <AllList />,
        },
        {
          path: "saved",
          sensitive: true,
          element: <Savedlist />,
        },
      ],
    },
    { path: "enquiry", sensitive: true, element: <EnquiryPage /> },

    { path: "master-courses", sensitive: true, element: <MasterCourses /> },
    {
      path: "explore-courses",
      sensitive: true,
      element: <MasterCourseListing />,
    },
    { path: "course-preview", sensitive: true, element: <CoursePreview /> },

    {
      path: "host-course",
      sensitive: true,
      element: <HostCourseLandingPage />,
    },
    {
      path: "explore-courses/:slug/:product_id",
      sensitive: true,
      element: <CourseDetails />,
    },
    {
      path: "product-checkout/:slug/:product_id",
      sensitive: true,
      element: <CourseCheckout />,
    },
    { path: "webinar", sensitive: true, element: <ExploreWebinar /> },
    {
      path: "webinar/:slug/:product_id",
      sensitive: true,
      element: <CourseDetails />,
    },
    {
      path: "motivational-profile-questionnaire",
      sensitive: true,
      element: <Questionnaire />,
    },
    {
      path: "motivational-profile-questionnaire/result",
      sensitive: true,
      element: <MqtResultPage />,
    },
    {
      path: "forget-password",
      sensitive: true,
      element: <ChangePasswordModule />,
    },
    { path: "proBono-success", sensitive: true, element: <ProBonoBooking /> },
    { path: "PaymentSuccess", sensitive: true, element: <PaymentSuccess /> },
  ]);
};

export const MenteeRoutes = () => {
  return useRoutes([
    {
      path: "calendar-redirection",
      sensitive: true,
      element: <CalendarConnectRedirection />,
    },

    {
      path: "",
      element: <MenteeSideNavBar />,
      children: [
        {
          path: "",
          sensitive: true,
          element: <MenteeAdminDashboard />,
        },
        {
          path: "find-mentor/:urlExprtise",
          sensitive: true,
          element: <LiveSessionList />,
        },

        { path: "my-ebooks", sensitive: true, element: <MyEbooks /> },
        { path: "my-goals", sensitive: true, element: <MenteeGoal /> },
        { path: "notifications", sensitive: true, element: <Notifications /> },
        // { path: "myprofile", sensitive: true, element: <Profilecheck /> },

        {
          path: "myprofile",
          sensitive: true,
          element: <MentorProfile />,
          children: [
            {
              path: "",
              sensitive: true,
              element: <Profilecheck />,
            },
            {
              path: "security-setting",
              sensitive: true,
              element: <Security />,
            },
          ],
        },

        { path: "livesession", sensitive: true, element: <LiveSessions /> },
        { path: "event-calendar", sensitive: true, element: <EventCalendar /> },
        {
          path: "purchase-history",
          sensitive: true,
          element: <PurchaseHistory />,
        },

        { path: "", sensitive: true, element: <MyCourseDashBoard /> },
        {
          path: "sessions",
          sensitive: true,
          element: <AllActiveSession />,
          children: [
            {
              path: "upcoming",
              sensitive: true,
              element: <PrivateSession />,
            },
            {
              path: "history",
              sensitive: true,
              element: <OpenSession />,
            },
          ],
        },

        {
          path: "my-course",
          sensitive: true,
          element: <MyCourseDashBoard />,
          children: [
            {
              path: "",
              sensitive: true,
              element: <AllCourseDashboard />,
            },
            {
              path: "continue-course",
              sensitive: true,
              element: <ContinueCourse />,
            },
          ],
        },
        {
          path: "connection-request",
          sensitive: true,
          element: <AllMenteeConnection />,
          children: [
            {
              path: "",
              sensitive: true,
              element: <RequestSendingList />,
            },
            {
              path: "your-mentor",
              sensitive: true,
              element: <AccpetedRequest />,
            },
          ],
        },
      ],
    },

    // { path: "calendar-redirection" , sensitive: true ,  element: <CalendarConnectRedirection /> },
    { path: "master-courses", sensitive: true, element: <MasterCourses /> },
    {
      path: "explore-courses",
      sensitive: true,
      element: <MasterCourseListing />,
    },
    {
      path: "product-checkout/:slug/:product_id",
      sensitive: true,
      element: <CourseCheckout />,
    },
    {
      path: "host-course",
      sensitive: true,
      element: <HostCourseLandingPage />,
    },
    { path: "find-mentor", sensitive: true, element: <LiveSessionList /> },
    { path: "available", sensitive: true, element: <Available /> },
    { path: "open-sessions", sensitive: true, element: <OpenSessions /> },
    {
      path: "session-details/:sessionId",
      sensitive: true,
      element: <SessionDetails />,
    },
    {
      path: "mentor-details/:user/",
      sensitive: true,
      element: <MentorDetails />,
    },
    { path: "myprofile", sensitive: true, element: <MyProfile /> },
    { path: "feedback", sensitive: true, element: <FeedbackForm /> },
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    { path: "about-us", sensitive: true, element: <AboutUs /> },
    { path: "terms", sensitive: true, element: <Terms /> },
    { path: "training", sensitive: true, element: <Training /> },
    {
      path: "request-demo",
      sensitive: true,
      element: <RequestDemo />,
    },
    {
      path: "enterprise",
      sensitive: true,
      element: <Enterprise />,
    },
    {
      path: "limited-use-disclosure",
      sensitive: true,
      element: <LimitedUseDisclosure />,
    },
    { path: "privacy-policy", sensitive: true, element: <Privacy /> },
    {
      path: "personality-result",
      sensitive: true,
      element: <PersonalityTestResult />,
    },
    {
      path: "personality-types",
      sensitive: true,
      element: <PersonalityType />,
    },
    // { path: "personality-details" , sensitive: true ,  element: <PersonalityDetails /> },
    { path: "personality-test", sensitive: true, element: <MBTI /> },
    { path: "become-mentor", sensitive: true, element: <BecomeMentor /> },
    { path: "pricing", sensitive: true, element: <MentorPricing /> },

    { path: "apply-as-mentee", sensitive: true, element: <ApplyAsMentee /> },

    { path: "OurTeam", sensitive: true, element: <OurTeam /> },

    { path: "contact-us", sensitive: true, element: <Contactus /> },
    { path: "enquiry", sensitive: true, element: <EnquiryPage /> },
    {
      path: "booksession/:user/",
      sensitive: true,
      element: <BookingMeetDetails />,
    },
    { path: "PaymentSuccess", sensitive: true, element: <PaymentSuccess /> },
    { path: "proBono-success", sensitive: true, element: <ProBonoBooking /> },
    { path: "course-preview", sensitive: true, element: <CoursePreview /> },
    { path: "webinar", sensitive: true, element: <ExploreWebinar /> },
    {
      path: "free-membership",
      sensitive: true,
      element: <OneYearMentorship />,
    },
    {
      path: "webinar/:slug/:product_id",
      sensitive: true,
      element: <CourseDetails />,
    },
    {
      path: "explore-courses/:slug/:product_id",
      sensitive: true,
      element: <CourseDetails />,
    },

    // { path: "Amtlab" , sensitive: true ,  element: <Amtlab /> },
    {
      path: "hire-talent",
      sensitive: true,
      element: <HireTalent />,
      children: [
        {
          path: "",
          sensitive: true,
          element: <AllList />,
        },
        {
          path: "saved",
          sensitive: true,
          element: <Savedlist />,
        },
      ],
    },
  ]);
};
