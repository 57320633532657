import React, { useRef } from "react";
import maleIcon from "../../assets/images/male.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getChatMessageAction,
  sendChatMessageAction,
} from "../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import { REACT_USER_ID } from "../auth/tokenProvider";
import { undrawProfile } from "../../assets/images/export-images";

const ChatMentorBox = ({ selectedUser, toggleChatBox }) => {
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);

  const Sender_userID = REACT_USER_ID;

  const dispatch = useDispatch();
  const backIcon = () => {
    toggleChatBox()
    console.log('back icon click works')
  }
  useEffect(() => {
    if (selectedUser) {
      const data = {
        sender_id: selectedUser?.connection_details?.user_id,
        receiver_id: Sender_userID,
      };

      dispatch(getChatMessageAction(data));
      console.log("GetChatUseEffect Called");
    }
  }, [selectedUser, dispatch]);

  const Getmessages = useSelector((state) => state.getChatMessageReducer?.data);

  const isLoading = useSelector(
    (state) => state.getChatMessageReducer?.loading
  );

  useEffect(() => {
    if (!isLoading) {
      setChatMessages(Getmessages || []);
      console.log("chatMessages", chatMessages);
    }
  }, [isLoading, Getmessages]);

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      const data = {
        message: message,
        sender_id: Sender_userID,
        receiver_id: selectedUser?.connection_details?.user_id,
        time: new Date().toUTCString(),
      };
      setChatMessages([...chatMessages, data]);
      console.log("newMessage", data);
      dispatch(sendChatMessageAction(data));
      setMessage("");
    }
  };

  const createdAt = (created_at) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Date(created_at).toLocaleTimeString([], options);
  };

  const messagesContainerRef = useRef(null); // Reference to the messages container

  useEffect(() => {
    // Scroll to the bottom of the messages container whenever the chat messages update
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    const pollingInterval = 570; // Polling interval in milliseconds (e.g., every 0.57 seconds)
    let intervalId;

    if (selectedUser) {
      const data = {
        sender_id: selectedUser?.connection_details?.user_id,
        receiver_id: Sender_userID,
      };

      dispatch(getChatMessageAction(data));
      console.log("GetChatUseEffect Called");

      intervalId = setInterval(() => {
        dispatch(getChatMessageAction(data));
      }, pollingInterval);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedUser, dispatch, Sender_userID]);

  return (
    <>
      {!selectedUser ? (
        <div className="container user_profile my-3 py-1 d-flex py-2 h-100">
          <p className="mt-auto mb-auto">Select a user from the chat list.</p>
        </div>
      ) : (
        <>
          <div className=" user_profile ">
            <div className="my-1 mx-2 pt-1 me-auto profile_name_status">
              <span className="back_icon" onClick={backIcon}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAZ0lEQVR4nO2WMQqAQAwE5xMR/f9LrETRxit8jsdJbKwsNIK306UaWJYlIMR9GmABeoKlCdiBMUpqwOrSDWglfRLF+ypWTXsLs0uTjwW/F9sl6k7yCEyxo8J99PpMkeJTXhZuOC5RPRnBEDtxjsnsowAAAABJRU5ErkJggg==" />
              </span>
              {selectedUser?.connection_details?.profile_photo ? (
                <img
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}${selectedUser.connection_details?.profile_photo}`}
                  alt="error"
                  className="rounded-circle chatInbox___image"
                />
              ) : (
                <img
                  className="rounded-circle chatInbox___image"
                  alt="icon"
                  src={undrawProfile}
                />
              )}
              <span style={{ marginLeft: "10px" }}>{selectedUser.connection_details?.name}</span>
            </div>
          </div>
          <div className="messages_area" ref={messagesContainerRef}>
            {chatMessages && chatMessages.length > 0 ? (
              chatMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`d-flex ${msg.sender_id === Sender_userID ? "justify-content-end" : ""
                    }`}
                >
                  <span
                    className={`${msg.sender_id === Sender_userID
                      ? "right_chat_message"
                      : "left_chat_message"
                      } ${msg.sender_id === Sender_userID ? "ms-auto" : "me-4"}`}
                  >
                    <p>{msg.message}</p>
                    <div className="message_time">
                      {createdAt(msg.created_at)}
                    </div>
                  </span>
                </div>
              ))
            ) : null}
          </div>

          <div className="chat-container">
            <div className="chat-input">
              <input
                type="text"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button className="send_btn_icon" onClick={handleSendMessage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="white"
                  class="bi bi-send-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                </svg>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChatMentorBox;
