import React from "react";
import { useState } from "react";
import { Form, Select, Button } from "antd";

const GaolQuestion = (props) => {
  const {
    activeTab,
    handleContinue,
    menteeGoalData,
    setmenteeGoalData,
    handleBack,
  } = props;

  const expertiseOption = [
    { label: "Software Development", value: "Software Development" },
    { label: "Design", value: "Design" },
    { label: "Product Managment", value: "Product Managment" },
    { label: "Content Writing", value: "Content Writing" },
    { label: "Data Science", value: "Data Science" },
    { label: "Marketing", value: "Marketing" },
    { label: "Product Research", value: "Product Research" },
    {
      label: "Achievement Motivation Trainer",
      value: "Achievement Motivation Trainer",
    },
    { label: "Life Coach", value: "Life Coach" },
    {
      label: "Entrepreneurial Motivation Trainer",
      value: "Entrepreneurial Motivation Trainer",
    },
  ];

  const [form] = Form.useForm();

  const onFinish = (values) => {
    // Handle form submission logic here
    console.log('Received values:', values);
    handleContinue();
  };

  return (
    <>
      <section className="step__goal__item">
        <div className={activeTab === 0 ? "active" : "inactive"}>
          <div className="container">
            <div className="step__count help-cont">
              <p>STEP 1 of 3</p>
              <h4>What's your top goal right now?</h4>
            </div>
          </div>
          <Form form={form} onFinish={onFinish}>
            <div className="form-group">
              <Form.Item
                name="goals"
                initialValue={menteeGoalData?.goals || undefined}
                rules={[
                  {
                    required: true,
                    message: 'Please select at least one goal!',
                  },
                ]}
              >
                <Select
                  size="large"

                  className="text-left "
                  placeholder="Eg: Design, Marketing"
                  style={{
                    height: "50px ",
                    width: "100%",
                    borderRadius: "13px ",
                  }}
                  onChange={(value) => {
                    setmenteeGoalData((prevData) => ({
                      ...prevData,
                      goals: value,
                    }));
                  }}
                >
                  {expertiseOption.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="get-started-cls">
              <a onClick={handleBack}>
                <Button type="button" className="find-btn btn btn-primary">
                  Back
                </Button>
              </a>
              <Button
                htmlType="submit"
                className="btn btn-primary"
                disabled={form.getFieldsError().some((field) => field.errors.length > 0)}
              >
                Continue
              </Button>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default GaolQuestion;
