import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCourseDetailsAction, updateMentorCourseAction } from '../../../../redux/actions/course';
import PricingModal from '../Modal/PricingModal';
import { useParams } from 'react-router-dom';

const Pricing = () => {
    const [data, setData] = useState({
        proBono: '',
        price: '',
        discount_price: '',
        product_id: '',
    });
    const dispatch = useDispatch();

    const getCourseDetailsReducer = useSelector((state) => state.getCourseDetailsReducer);
    const updateMentorCourseReducer = useSelector((state) => state.updateMentorCourseReducer);

    useEffect(() => {
        // Check if data is available in the reducer before updating the state
        if (getCourseDetailsReducer?.data) {
            setData((prevData) => ({
                ...prevData,
                proBono: getCourseDetailsReducer.data.proBono || '',
                price: getCourseDetailsReducer.data.price || '',
                discount_price: getCourseDetailsReducer.data.discount_price || '',
                product_id: getCourseDetailsReducer.data.product_id || '',
            }));
        }
    }, [getCourseDetailsReducer]); // Only trigger when getCourseDetailsReducer changes

    useEffect(() => {
        if (updateMentorCourseReducer?.data && updateMentorCourseReducer?.error === undefined && updateMentorCourseReducer?.loader === false) {
            setData((prevData) => ({
                ...prevData,
                proBono: updateMentorCourseReducer.data.proBono || '',
                price: updateMentorCourseReducer.data.proBono === 'true' ? '0' : updateMentorCourseReducer.data.price || '',
                discount_price: updateMentorCourseReducer.data.proBono === 'true' ? '0' : updateMentorCourseReducer.data.discount_price || '',
                product_id: updateMentorCourseReducer.data.product_id || '',
            }));
        }
    }, [updateMentorCourseReducer]);


    const handlePaidToggle = () => {
        const newProBonoValue = data.proBono === 'true' ? 'false' : 'true';
        setData((prevState) => ({
            ...prevState,
            proBono: newProBonoValue,
            price: newProBonoValue === 'true' ? '0' : prevState.price,
            discount_price: newProBonoValue === 'true' ? '0' : prevState.discount_price,
        }));
    };

    const handlePriceChange = (e) => {
        const newPrice = e.target.value;
        if (data.proBono !== 'true' && (isNaN(newPrice) || newPrice <= 0)) {
            return; // Do not allow 0 or negative values when ProBono is not enabled
        }
        setData({
            ...data,
            price: newPrice,
        });
    };

    const handleDiscountedPriceChange = (e) => {
        const newDiscountPrice = e.target.value;
        if (data.proBono !== 'true' && (isNaN(newDiscountPrice) || newDiscountPrice <= 0)) {
            return; // Do not allow 0 or negative values when ProBono is not enabled
        }
        setData({
            ...data,
            discount_price: newDiscountPrice,
        });
    };
    const UpdateCourseDetails = () => {
        // Check if ProBono is false and price or discount_price is less than or equal to zero
        if (data.proBono === 'false' && (parseInt(data.price) <= 0 || parseInt(data.discount_price) <= 0)) {
            // Show an error message or perform some other action to handle the validation error
            alert("Price and Discounted Price must be greater than zero when ProBono is disabled.");
        } else {
            // Dispatch the action to update course details
            dispatch(updateMentorCourseAction(data));
        }
    };

    return (
        <>
            <div className="form-group">
                <div className="form-check form-switch d-flex">
                    <input
                        className="form-check-input"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        type="checkbox"
                        checked={data.proBono === 'true'}
                        onChange={handlePaidToggle}
                    />
                    <label className="course__label" htmlFor="flexSwitchCheckDefault">
                        ProBono {data?.document_type}
                    </label>
                </div>
            </div>
            {data.proBono === 'false' && (
                <>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label className="course__label">Price *</label>
                                <div className="input-group">
                                    <span className="input-group-text price___default">$</span>
                                    <input
                                        className="form-control"
                                        type="number"
                                        id="customInputPrice"
                                        placeholder="Price"
                                        value={data.price}
                                        onChange={handlePriceChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="course__label">Discounted price *</label>
                                <div className="input-group">
                                    <span className="input-group-text price___default">$</span>
                                    <input
                                        className="form-control"
                                        type="number"
                                        id="customInputDiscountedPrice"
                                        placeholder="Discounted Price"
                                        value={data.discount_price}
                                        onChange={handleDiscountedPriceChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="get-started-cls why_choose_ment">
                <button
                    onClick={UpdateCourseDetails}
                    type="button"
                    className="btn btn-primary"
                >
                    Update {data?.document_type} Details
                </button>
            </div>
        </>
    );
};

export default Pricing;
