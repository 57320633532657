import React from 'react';
import { Helmet } from 'react-helmet';

const SeoHelmet = ({ metaData }) => {
    if (!metaData || !metaData.metaTags || metaData.metaTags.length === 0) {
        return null; // Return null or any fallback content if metaTags is empty or undefined
    }

    return (
        <>
            <Helmet>

                <title>{metaData.title}</title>
                <link rel="canonical" href={metaData?.canonical} />

                {/* Map through the meta data array */}
                {metaData.metaTags.map((meta, index) => (
                    <meta key={index} name={meta.name} content={meta.content} />
                ))}
            </Helmet>
        </>
    );
};

export default SeoHelmet;
