import React from "react";
import {
  getMentorAvailableHoursRequest,
  mentorAvailabilityDaysRequest,
  mentorAvailabilityTimeSlotsRequest,
  viewMentorProfileAction,
} from "../../redux/actions/mentor";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  createPaymentLinkAction,
  getOrderId,
} from "../../redux/actions/session";
import LoginModal from "../modals/loginModal";
import { TailSpin } from "react-loader-spinner";
import maleAvatar from "../../assets/images/male.png";
import femaleAvatar from "../../assets/images/female.png";
import "../BookSession/payment.css";
import { Form, Input, Button, message, Select, Row, Col } from 'antd';
import { stdCodeData } from "../../assets/data/data";
import { SettingsSystemDaydreamTwoTone } from "@mui/icons-material";


const { Option } = Select;

const MentorAvailability = ({ mentor_id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  import("./session.css");
  const loggedInUserStatus = localStorage.getItem("REACT_USER_ID") ? true : false;



  const [messageApi, contextHolder] = message.useMessage();
  const [availableDays, setAvailableDays] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState({});

  const [isDaysLoading, setIsDaysLoading] = useState(true);
  const [isTimeSlotsLoading, setIsTimeSlotsLoading] = useState(true);

  const [startIndex, setStartIndex] = useState(0);

  const [headerModal, setHeaderModal] = useState({});
  const [mentor, setMentor] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [selectedAvailableDays, setSelectedDays] = useState("");
  const [selectedAvailableTimeSlots, setSelectedTimeSlots] = useState("");


  const [std, setStd] = useState(stdCodeData[0].code);
  const [FormData, setFormData] = useState({
    name:'',
    email:'',
    mobile:'',
  }); const [successMsg, setSuccessMsg] = useState(false);


  useEffect(() => {
    dispatch(viewMentorProfileAction(mentor_id));

    dispatch(mentorAvailabilityDaysRequest({ id: mentor_id }));
    dispatch(getMentorAvailableHoursRequest({ id: mentor_id }));
  }, [dispatch, mentor_id]);

  const mentorData = useSelector((state) => {
    const data = state.viewMentorProfileReducer?.data || [];
    return data[0];
  });

  const mentorDataLoading = useSelector(
    (state) => state?.viewMentorProfileReducer?.loading
  );

  const availableDaysData = useSelector(
    (state) => state.mentorAvailabilityDaysReducer?.data
  );
  const availableTimeSlotsData = useSelector(
    (state) => state?.mentorAvailabilityTimeSlotsReducer?.data
  );

  const availableDaysDataLoading = useSelector(
    (state) => state?.mentorAvailabilityDaysReducer?.loading
  );
  const availableTimeSlotsDataLoading = useSelector(
    (state) => state?.mentorAvailabilityTimeSlotsReducer?.loading
  );

  useEffect(() => {
    setMentor(mentorData);
    setIsLoading(mentorDataLoading);
    setAvailableDays(availableDaysData);
    setIsDaysLoading(availableDaysDataLoading);
    setIsTimeSlotsLoading(availableTimeSlotsDataLoading);
    if (
      !availableTimeSlotsDataLoading &&
      // Object.keys(availableTimeSlots).length === 0 &&
      availableTimeSlotsData !== undefined &&
      availableTimeSlotsData.length !== 0
    ) {
      setAvailableTimeSlots(availableTimeSlotsData);

      setSelectedTimeSlots(
        availableTimeSlotsData?.slotTime
          ? availableTimeSlotsData.slotTime[0]
          : ""
      );
      setIsTimeSlotsLoading(false);
    }
  }, [
    availableDaysData,
    availableTimeSlotsData,
    availableDaysDataLoading,
    availableTimeSlotsDataLoading,
    mentorData, mentorDataLoading
  ]);

  const userDetails =
    useSelector((state) => {
      return state.userDetailsReducer?.data;
    }) || [];

  useEffect(() => {
    if (availableDays && availableDays.length && mentor?.user_id) {
      setSelectedDays(availableDays[0]?.iso);
      dispatch(
        mentorAvailabilityTimeSlotsRequest({
          id: mentor_id,
          date: availableDays[0]?.iso,
        })
      );
    }
  }, [availableDays, mentor]);

  const loggedInUserId = localStorage.getItem("REACT_USER_ID");
  let date = new Date();
  let datestring = date.toTimeString();
  let country =
    datestring?.includes("india") | datestring?.includes("India")
      ? "India"
      : "";


  const viewMentorProfileReducer = useSelector(
    (state) => state?.viewMentorProfileReducer
  );

  const [productId, setProductId] = useState("");


  useEffect(() => {

    if (viewMentorProfileReducer?.loading === false && viewMentorProfileReducer?.error === undefined) {
      setProductId(viewMentorProfileReducer?.data[0]?.products?.product_id)
    }
  }, [viewMentorProfileReducer, productId]);





  const handleBookSession = (e) => {

    let data;
    if (loggedInUserStatus) {
      data = {
        amount: country === "India" ? mentor?.inr_price : mentor?.price_per_session,
        currency: country === "India" ? "INR" : "USD",
        receipt: "receipt#1",
        notes: {
          mentor_id: mentor?.user_id,
          mentee_id: loggedInUserId,
          time: extractTimeAndConvertToUTC(selectedAvailableTimeSlots),
          booked_date: selectedAvailableDays,
          product_id: productId,
          timezone: myTimeZone,
        },
        mentee_id: loggedInUserId,
        mentor_id: mentor?.user_id,
        product_id: productId,
      }

    }
    if (!loggedInUserStatus) {
      data = {
        amount: country === "India" ? mentor?.inr_price : mentor?.price_per_session,
        currency: country === "India" ? "INR" : "USD",
        receipt: "receipt#1",
        notes: {
          mentor_id: mentor?.user_id,
          mentee_id: loggedInUserId,
          time: extractTimeAndConvertToUTC(selectedAvailableTimeSlots),
          booked_date: selectedAvailableDays,
          product_id: productId,
          timezone: myTimeZone,
          name: FormData?.name,
          email: FormData?.email,
          mobile:`${std}${FormData?.mobile}`
        },
        mentee_id: loggedInUserId,
        mentor_id: mentor?.user_id,
        product_id: productId,
      }
    }


    dispatch(getOrderId(data));
  };

  const StripePayment = () => {


    let data;
    if (loggedInUserStatus) {
      data = {
        amount:
          country === "India"
            ? mentor?.inr_price
            : mentor?.price_per_session * 100,
        currency: country === "India" ? "INR" : "USD",

        booked_time: extractTimeAndConvertToUTC(selectedAvailableTimeSlots),
        booked_date: selectedAvailableDays,
        product_id: productId,
        notes: {
          mentor_id: mentor?.user_id,
          mentee_id: loggedInUserId,
          timezone: myTimeZone,
        },
      }

    }
    if (!loggedInUserStatus) {
      data = {
        amount:
          country === "India"
            ? mentor?.inr_price
            : mentor?.price_per_session * 100,
        currency: country === "India" ? "INR" : "USD",
        mentor_id: mentor?.user_id,
        mentee_id: loggedInUserId,
        booked_time: extractTimeAndConvertToUTC(selectedAvailableTimeSlots),
        booked_date: selectedAvailableDays,
        product_id: productId,

        notes: {
          timezone: myTimeZone,
          name: FormData?.name,
          email: FormData?.email,
          mobile:`${std}${FormData?.mobile}`

        },
      }
    }





    dispatch(createPaymentLinkAction(data));
  };


  const PaymentUrl = useSelector((state) => {
    return state?.createPaymentLinkReducer?.data;
  });
  const PaymentUrlLoading = useSelector(
    (state) => state?.createPaymentLinkReducer?.loading
  );
  const PaymentUrlStatus = useSelector(
    (state) => state?.createPaymentLinkReducer?.status
  );
  const isPaymentLoading = useSelector((state) => state.orderReducer?.loading);
  const BookingData = useSelector((state) => state.orderReducer);

  const bookSession = () => {
    const is_pro_bono = mentor?.is_pro_bono;
    const price_per_session = mentor?.price_per_session;
    const inr_price = mentor?.inr_price;
    if (is_pro_bono === "yes" || price_per_session) {
      if (is_pro_bono === "yes" || price_per_session === "0") {
        handleBookSession();
      } else if (
        parseFloat(price_per_session) > 0 &&
        parseFloat(inr_price) > 0
      ) {
        StripePayment();
      }
    }

  };
  useEffect(() => {
    if (BookingData?.data && BookingData?.data?.__v === 0) {
      navigate("/proBono-success");
      setSuccessMsg(false);
    }
    else {
      // messageApi.open({
      //   type: 'error',
      //   content: 'Your booking is not confirm',
      // });
    }
  }, [BookingData, navigate, messageApi]);

  useEffect(() => {
    if (PaymentUrlStatus === 200 && PaymentUrl) {
      window.location.href = PaymentUrl; // U
    }
  }, [PaymentUrlStatus, PaymentUrl, navigate]);

  var myTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let countryname =
    datestring?.includes("india") | datestring?.includes("India")
      ? "India"
      : "";
  const formatTimeInAMPM = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return dateTime.toLocaleString("en-US", options);
  };

  // Function to format date in "13 July, 2023" format in local timezone
  const formatDateToLocalFormat = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return dateTime.toLocaleString("en-US", options);
  };

  const extractTimeAndConvertToUTC = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    const localTime = new Date(utcDateTime.toLocaleString("en-US", { timeZone: "UTC" }));
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: false, // Using 24-hour format
    };

    return localTime.toLocaleString("en-US", options);
  };

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  const onFinish = (values) => {
    setLoading(true);
    bookSession()
    setTimeout(() => {
      setLoading(false)
    }, 2000);

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const validateMobile = (_, value) => {
    const phoneRegex = /^[0-9]+$/;
    if (!value || (value.length > 6 && phoneRegex.test(value))) {
      return Promise.resolve();
    }
    return Promise.reject('Please enter a valid  mobile number');
  };


  const handlePrefixChange = (value) => {
    setStd(value);
  };

  return (
    <>
      {contextHolder}
      <div className="Model " style={{ background: "#f8f9fa", borderRadius: "25px" }}>
        {isPaymentLoading ||
          isDaysLoading ||
          isLoading ||
          isTimeSlotsLoading ||
          PaymentUrlLoading || viewMentorProfileReducer?.loading ? (
          <div className="loader open_sessions_loader">
            <TailSpin ariaLabel="Loading..." color="#00BFFF" />
          </div>
        ) : (
          ""
        )}
        <div className="container">
          <div className="row ">
            <div className="col-lg-7 col-md-12 mt-3  avilabilty___col ">
              <div className="Model_Padding mb-4 avilabilty__Modal">
                <div className="pb-3 grey-3-border border-bottom mb-3">
                  <p className="Model_Heading font">Select Date</p>
                  <p>
                    In your local timezone {" "}
                    <span className="Update_Color text-primary"  >
                      {myTimeZone ? myTimeZone : "Not Detected"} &nbsp;
                    </span>
                    {/* <a href="/" className="Update_Color">
                      Update
                    </a> */}
                  </p>
                </div>
                <div className="d-flex" style={{ overflow: "auto" }}>
                  {!isDaysLoading
                    ? availableDays?.slice(startIndex).map((item, id) => (
                      <div
                        key={id}
                        slot={8}
                        className={`${item?.iso == selectedAvailableDays
                          ? "selected-date"
                          : ""
                          } mx-2 Date_Picker Date_Content`}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedDays(item?.iso);
                          dispatch(
                            mentorAvailabilityTimeSlotsRequest({
                              id: mentor_id,
                              date: item?.iso,
                            })
                          );
                        }}
                      >
                        <div className="m-auto date-tab">
                          <small className="date__day">{item?.dayName}</small>
                          <span className="d-block Date_size date__date">
                            {item?.dateName}
                          </span>
                        </div>
                      </div>
                    ))
                    : [1, 2, 3, 4].map((val, id) => (
                      <div
                        key={id}
                        height="74px"
                        slot={8}
                        className="mx-2 Date_Picker Date_Content"
                      >
                        <div className="m-auto">
                          <small className="date__day"></small>
                          <span className="d-block Date_size date__date"></span>
                          <small className="date__slot">Loading..</small>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="mb-4">
                  <div
                    className="pb-3 d-flex align-items-center 
                      justify-content-between border-bottom grey-3-border mb-3"
                  >
                    <p className="Model_Heading font">Select Time</p>
                  </div>
                  <div className=" Time_slot">
                    {!isTimeSlotsLoading
                      ? availableTimeSlots?.slotTime &&
                      availableTimeSlots?.slotTime?.map((item, id) => {
                        let date = new Date(item);
                        let hours = date.getHours()?.toString();
                        let minute = date.getMinutes()?.toString();
                        return (
                          <div
                            key={id}
                            onClick={() => {
                              setSelectedTimeSlots(item);
                            }}
                            className={`${item == selectedAvailableTimeSlots
                              ? "selected-time"
                              : ""
                              } Time_slot_Box`}
                          >
                            <span className="m-auto" height="50px">
                              {hours.length === 1 ? "0" + hours : hours}:
                              {minute.length === 1 ? "0" + minute : minute}
                              {hours < 12 ? " AM" : " PM"}
                            </span>
                          </div>
                        );
                      })
                      : [1, 2, 3].map((item, id) => (
                        <div key={id} className="Time_slot_Box">
                          <span className="m-auto" height="50px">
                            loading...
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {/* <div className="container avilabilty__Modal mb-2 ">
                <div className="row">
                  <div className="col-1 " style={{ width: "4%" }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-telephone"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                      </svg>
                    </span>
                  </div>

                  <div className="col mb-3 ">
                    <h5 className="card-title"> Enter Mobile number</h5>

                    <span style={{ whiteSpace: "noraml" }}>
                      Enter the number on which you wish to receive booking
                      session realted information
                    </span>
                    <input
                      type="phone"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Mobile Number"
                      onChange={(event) => {
                        const { value } = event.target;
                        setmobile(value);
                      }}
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-5 col-md-12 mt-3 ">
              <div className="container avilabilty__Modal ">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <h5 className="card-header">Booking Details</h5>
                  <div className="card-body">
                    <div className="Booking__details ">
                      <div className="row">
                        <div className="col-12">
                          <p className="preview__content__name d-flex align-items-center text-truncate">
                            <span className="black-text font-weight-600 text-truncate">
                              <img
                                className="avatar mr-3 rounded-circle"
                                alt="Mentor_image"
                                style={{
                                  width: "35px",
                                  height: "35px",
                                }}
                                src={
                                  mentor?.profile_photo
                                    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${mentor?.profile_photo}`
                                    : mentor?.gender == "female"
                                      ? `${femaleAvatar}`
                                      : `${maleAvatar}`
                                }
                              />
                              {mentor?.name}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <div className="d-flex align-items-center">
                            <span>Session's Price</span>
                          </div>
                        </div>
                        <div className="col-6 text-right">
                          <div className="d-flex align-items-center justify-content-end">
                            <p>
                              {" "}
                              {mentor?.is_pro_bono == "yes" ||
                                (mentor?.inr_price === "0" &&
                                  mentor?.price_per_session === "0")
                                ? "Pro Bono"
                                : countryname === "India"
                                  ? "₹" + mentor?.inr_price
                                  : "$" + mentor?.price_per_session}
                            </p>
                          </div>
                        </div>
                      </div>



                      <div className="row">
                        <div className="col-6">
                          <div className="d-flex align-items-center">
                            <span>Date</span>
                          </div>
                        </div>
                        <div className="col-6 text-right">
                          <div className="d-flex align-items-center justify-content-end">
                            <p>
                              {selectedAvailableDays
                                ? formatDateToLocalFormat(selectedAvailableDays)
                                : "Not Selected yet"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="d-flex align-items-center">
                            <span> Time</span>
                          </div>
                        </div>
                        <div className="col-6 text-right">
                          <div className="d-flex align-items-center justify-content-end">
                            <p>
                              {selectedAvailableTimeSlots
                                ? formatTimeInAMPM(selectedAvailableTimeSlots)
                                : "Not Selected yet"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="d-flex align-items-center">
                            <span> TimeZone</span>
                          </div>
                        </div>
                        <div className="col-6 text-right">
                          <div className="d-flex align-items-center justify-content-end">
                            <p>{myTimeZone ? myTimeZone : "Not Detected"}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4 mt-4 border-bottom pb-2">
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <p className="Model_Heading font">Total Amount</p>
                    </div>
                  </div>
                  <div className="col-6 text-right">
                    <div className="d-flex align-items-center justify-content-end">
                      <p>
                        {
                          mentor?.is_pro_bono == "yes" ||
                            (mentor?.inr_price === "0" &&
                              mentor?.price_per_session === "0")
                            ? "Pro Bono"
                            : countryname === "India"
                              ? "₹" + mentor?.inr_price
                              : "$" + mentor?.price_per_session}
                      </p>
                    </div>
                  </div>
                  {
                    !loggedInUserStatus ? (
                      <>
                        <Form
                          form={form}
                          name="customForm"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          layout="vertical"
                        >
                          <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please enter your name!' }]}
                          >
                            <Input placeholder="Enter your name" onChange={(e) => handleInputChange('name', e.target.value)} />
                          </Form.Item>

                          <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                              { required: true, message: 'Please enter your email!' },
                              { type: 'email', message: 'Please enter a valid email address!' },
                            ]}
                          >
                            <Input placeholder="Enter your email" onChange={(e) => handleInputChange('email', e.target.value)} />
                          </Form.Item>

                          <Form.Item
                            label="Mobile Number"
                            name="mobile"
                            hasFeedback
                            rules={[
                              { required: true, message: 'Please enter your mobile number!' },
                              { validator: validateMobile },
                            ]}
                          >
                            <Row gutter={8}>
                              <Col span={6}>
                                <Form.Item
                                  name={['stdCode']}
                                  noStyle
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (!std) {
                                          return Promise.reject('Please select STD code!');
                                        } else {
                                          return Promise.resolve();
                                        }
                                      },
                                    }),
                                  ]}

                                >
                                  <Select defaultValue={std} value={std} onChange={(value) => setStd(value)}>
                                    {stdCodeData.map((code) => (
                                      <Option key={code.code} value={code.code}>
                                        {`${code.iso} (+${code.code})`}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={18}>
                                <Form.Item name={['mobile']} noStyle>
                                  <Input
                                    onChange={(e) => handleInputChange('mobile', e.target.value)}
                                    placeholder="Enter your mobile number without STD code"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form.Item>






                          <div className="Book_session">
                            <Form.Item>
                              <Button
                                htmlType="submit" loading={loading}
                                bg="var(--teal)"

                                className="Session_Button text-truncate undefined btn btn-default"

                                disabled={
                                  !(selectedAvailableDays && selectedAvailableTimeSlots && FormData?.email && FormData?.name && FormData?.mobile)
                                }
                              >
                                {
                                  mentor?.is_pro_bono === "yes" ||
                                    mentor?.inr_price === "0" ||
                                    mentor?.price_per_session === "0"
                                    ? "Confirm Booking"
                                    : "Proceed to Pay"}
                              </Button>
                            </Form.Item>



                          </div>
                          {/* <Form.Item>
                      <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                      </Button>
                    </Form.Item> */}
                        </Form>
                      </>
                    ) :
                      (
                        <>
                          <div className="Book_session pt-2">
                            <button
                              onClick={bookSession}
                              // onClick={handleBookSession}
                              bg="var(--teal)"
                              type="button"
                              className="Session_Button text-truncate undefined btn btn-default"
                              disabled={
                                !(selectedAvailableDays && selectedAvailableTimeSlots)
                              }
                            >
                              {
                                mentor?.is_pro_bono === "yes" ||
                                  mentor?.inr_price === "0" ||
                                  mentor?.price_per_session === "0"
                                  ? "Confirm Booking"
                                  : "Proceed to Pay"}
                            </button>


                          </div>
                        </>
                      )

                  }

                </div>


              </div>
              <div className="container avilabilty__Modal mt-2">
                <div className="d-flex">
                  <div>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-person"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                      </svg>
                    </span>
                  </div>
                  <div>
                    <h4>We can help You</h4>
                    <p>
                      Call us +91 8619825569 (or) chat with our online customer
                      support team
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {headerModal?.modal && (
          <headerModal.modal
            headerModal={headerModal}
            setHeaderModal={setHeaderModal}
          />
        )}
      </div>
    </>
  );
};

export default MentorAvailability;
