import React, { useState } from "react";
import { joinImg } from "../../assets/images/export-images";
import "./payment.css";
import DashboardHeader from "../dashboard/dashboardHeader";
import Footer from "../footer";
import { useNavigate } from "react-router";
import LoginModal from "../modals/loginModal";

const ProBonoBooking = () => {
  const navigate = useNavigate();
  const loggedInUserStatus = localStorage.getItem("REACT_USER_ID") ? true : false;
  const [headerModal, setHeaderModal] = useState({});

  const handleDashbord = () => {
    if (loggedInUserStatus) {
      navigate("/")
    }
    else {
      setHeaderModal({
        modal: LoginModal,
        show: true,
        as: 'mentee'

      }
      )

    }
  }
  return (
    <>
      <DashboardHeader />
      <section className="already-mentor-sec why_choose_ment">
        <div className="success__modal">
          <div className="container">
            <div className="mentoring-cont">
              <div className="payment_iner_title">
                <h3 className="h2">Congratulations! Your Session is Booked!</h3>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="joinImg-img">
                  <img alt="icon" src={joinImg} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mentoring-cont">
                  <div className="payment_iner_title">
                    <h2>
                      🎉 You've taken the first step towards personal growth and
                      success with <span>MentorPal.ai!</span> 🚀
                    </h2>
                    <h2>
                      <b> 🎉Note:</b> Should you wish to continue your mentoring
                      journey beyond this complimentary session, MentorPal.ai
                      offers a range of personalized plans to suit your needs.
                    </h2>
                    <h2>
                      🎉If you have any questions or encounter any issues, feel
                      free to reach out to our support team at
                      help@mentorpal.ai. We are here to ensure you have a
                      fantastic mentoring experience. 🚀
                    </h2>
                  </div>
                </div>
                <div className="stepper-wrapper mt-4">
                  <div className="stepper-item completed">
                    <div className="step-counter">
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                        </svg>
                      </span>
                    </div>
                    <div className="step-name">Booking Start</div>
                  </div>
                  <div className="stepper-item completed">
                    <div className="step-counter">
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                        </svg>
                      </span>
                    </div>
                    <div className="step-name">Booking Successful</div>
                  </div>
                </div>
                <div className="get-started-cls why_choose_ment">
                  <a href="/find-mentor">
                    <button type="button" className="find-btn btn btn-primary">
                      Find Mentor
                    </button>
                  </a>
                  <button
                    onClick={() => handleDashbord()}
                    type="button"
                    className="btn btn-primary"
                  >
                    Dashboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {headerModal?.modal && (
        <headerModal.modal
          headerModal={headerModal}
          setHeaderModal={setHeaderModal}
        />
      )}
    </>
  );
};

export default ProBonoBooking;
