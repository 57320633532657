import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { socialLogin } from "../../redux/actions/login";
import google from "../../assets/images/google.png";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css";
import { LinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialLinkedin,
} from "reactjs-social-login";

import { TailSpin } from "react-loader-spinner";
import { GoogleLoginButton, LinkedInLoginButton } from "react-social-login-buttons";
import axios from "axios";
import { Button } from "antd";
let REDIRECT_URI = `${window.location.origin}/login-redirect`;

const SocialLogin = ({ modalprops, modalType }) => {
  
  const [provider, setProvider] = useState("");
  const [isSocialLoding, setIsSocialLoding] = useState(false);
  const [profile, setProfile] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socialToken = useSelector(
    (state) => state?.socialLoginReducer?.headers?.["x-error-msg"]
  );
  const socialLoginReducerData = useSelector((state) => {
    const loading = state?.socialLoginReducer?.loading;
    const data = state?.socialLoginReducer?.data;
    if (isSocialLoding && !loading && data != null && Object.values(data)) {
      setIsSocialLoding(false);
      window.location.reload();
    }
    return state?.socialLoginReducer;
  });

  const isLoadingSocial = socialLoginReducerData?.loading;

  const onLoginStart = useCallback((e) => {
    setIsSocialLoding(true);
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
  }, []);

  useEffect(() => {
    if (profile?.email) {
      const data = {
        name: profile?.name,
        user_type: modalType,
        email: profile?.email,
        social_type: profile?.provider ? profile?.provider: "google",
        access_token:profile?.accessToken,
        profile_photo:profile?.picture
      };
      if(profile?.provider==="google"){
        data['social_id']=profile?.sub ? profile?.sub : ""
      }
      if(profile.provider==="linkedin"){
        data['author_linkedin_urn']="urn:li:person:"+profile?.sub;
      }
      localStorage.setItem("PROVIDER",profile?.provider)
      setIsSocialLoding(true);
      dispatch(socialLogin(data));
    }
  }, [profile]);

  useEffect(() => {
    if (socialToken) {
      if (modalType === "mentor") {
        
        navigate("/mentor-dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  }, [socialToken]);


  // const onLogout = useCallback(() => {}, []);
console.log(profile,socialToken)
  return (
    <>
      {isSocialLoding || isLoadingSocial ? (
        <div className="loader" style={{ zIndex: 9999 }}>
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : null}
      <div
        style={{ display: "flex" }}
        className={`App signup-icon ${provider && profile ? "hide" : ""}`}
      >
        <div className="social-buttons">
          <GoogleOAuthProvider
            clientId={
              "1054161826876-v2jlajddg0m9m91bu89a9unuvan5qmmi.apps.googleusercontent.com" ||
              process.env.REACT_APP_GG_APP_ID ||
              "1054161826876-v2jlajddg0m9m91bu89a9unuvan5qmmi.apps.googleusercontent.com"
            }
          >
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                const user = jwtDecode(credentialResponse?.credential);
                user['provider']="google"
                setProfile(user);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </GoogleOAuthProvider>

            <LoginSocialLinkedin
          
              client_id="86g4pti7y4i1ev"
              client_secret="2WUU783LdXMhKsI0"
              redirect_uri={REDIRECT_URI}
              scope="openid profile w_member_social email "
              onResolve={({provider,data})=>{
              const user = jwtDecode(data?.id_token);
              const accessToken  = data.access_token;
              console.log(user)
                setProfile({...user,provider,accessToken});
              }}
              onReject={(err) => {
                console.log(err);
                
              }}
            >
              <LinkedInLoginButton />
            </LoginSocialLinkedin>
        </div>
      </div>
   

  
    </>
  );
};

export default SocialLogin;
