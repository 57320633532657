import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";
import { arR } from "../../../assets/images/export-images";
import Architect from "./files/Architect.png";
import Logician from "./files/personII.png";
import Commander from "./files/personIII.png";
import Debater from "./files/personIV.png";
import Advocate from "./files/personV.png";
import Mediator from "./files/personVI.png";
import Protagonist from "./files/personVII.png";
import Campaigner from "./files/personVIII.png";
import Logistician from "./files/Logistician.png";
import Defender from "./files/Defender.png";
import Executive from "./files/Executive.png";
import Consul from "./files/Consul.png";
import Virtuoso from "./files/Virtuoso.png";
import Adventurer from "./files/Adventurer.png";
import Entrepreneur from "./files/Entrepreneur.png";
import Entertainer from "./files/Entertainer.png";

import data from "./Types.json";

import { bannerImg, ic1, ic3 } from "../../../assets/images/export-images";
import Footer from "../../footer";
const PersonalityType = () => {
  let images = {
    'Architect':Architect,
    'Logician':Logician,
    'Commander':Commander,
    'Debater':Debater,
    'Advocate':Advocate,
    'Mediator':Mediator,
    'Protagonist':Protagonist,
    'Campaigner':Campaigner,
    'Defender':Defender,
    'Consul':Consul,
    'Entertainer':Entertainer,
    'Virtuoso':Virtuoso,
    'Adventurer':Adventurer,
    'Entrepreneur':Entrepreneur,
    'Logistician':Logistician,
    'Executive':Executive,
  }
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
  );
  const FindTypes = () => {
    navigate("/personality-test");
    console.log("here the findyourtyeps is called");
  };
  return (
    <>
      {loggedIn ? <ProtectedHeader /> : <Header />}
      <section
        className="banner-sec"
        style={{
          backgroundImage: `url(${bannerImg})`,
          padding: "85px 0px 60px 0px",
        }}
      >
        <div className="container">
          <div className="mentroship-sec">
            <h3>Personality Type</h3>
          </div>
        </div>
      </section>
      <div>
        {
          data['sections'].map(title => (
            <section id={title}>
              <div className="details_content">
                <div className="centre">
                  <div className="singupp_upp">
                    <button className="personality__btn" onClick={FindTypes}>
                      Find Your Types
                    </button>
                  </div>
                </div>
                <div className="mentroship-sec person__mainHeading">
                  <h1 id="personality__mainHeading">{ title }</h1>
                </div>
              </div>

              <div className="mentors-tabs types__tab">
                <div className="container">
                  <div className="row">
                    {
                      data[title].map(person => (
                        <div className="col-sm-6 col-md-3 col-lg-3" id={ person.id }>
                          <div className="box-wrap">
                            <div className="type__image">
                              <img alt="icon" src={images[person.name]} />
                            </div>
                            <div className="mentor-cont p__type">
                              <h4>{ person.name }</h4>
                              <h5>{ person.type }</h5>
                              <p className="cv-p">
                                { person.description }
                              </p>
                              <a className="myBtn">
                                Read More{" "}
                                <span className="ar-1">
                                  <img alt="icon" src={arR} />
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  {/* <!-- World className Mentorship --> */}
                </div>
              </div>
            </section>
          ))
        }
      </div>
     
      <Footer />
    </>
  );
}

export default PersonalityType;
