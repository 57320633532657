import React, { useState } from "react";

import "../../openSessions/openSession.css";
import { crossIcon } from "../../../assets/images/export-images";
import { useDispatch, useSelector } from "react-redux";
import { enrollSessionAction } from "../../../redux/actions/common";
import { enrollSessionReducer } from "../../../redux/reducers/common";
import { useEffect } from "react";
import { useRef } from "react";

const EnrollSession = (props) => {
  const { handleClose, sessionId } = props;
  const dispatch = useDispatch();
  const [showSuccessMsg, setshowSuccessMsg] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [enrollSession, setenrollSession] = useState({
    name: "",
    email: "",
    mobile: "",
    session_id: sessionId,
  });
  const enrollSessionReducer = useSelector(
    (state) => state.enrollSessionReducer
  );

  const BooKEnrollSession = () => {
    console.log("enrollSession", enrollSession);
    dispatch(enrollSessionAction(enrollSession));
  };

  return (
    <>
      <section>
        <div
          role="dialog"
          aria-modal="true"
          className=" enroll__modal show"
          tabIndex={-1}
          style={{ display: "block", paddingLeft: 17 }}
        >
          <div className="enroll__modal-dialog  enroll__modal-sm  Enroll_dialog-centered">
            <div className="Enroll_modal-content">
              <div
                style={{
                  height: 140,
                  background: "linear-gradient(180deg, #d96980, #3d29a3)",
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                }}
              >
                <h1 className=" Enroll_heading mb-12 ">Enroll for Session</h1>
              </div>
              <i className="uil uil-times services__modal-close">
                <img src={crossIcon} alt="Close" onClick={handleClose} />
              </i>

              <div className=" Enroll_content Enroll_modal-body">
                <div className="mb-4">
                  <form action="" className="Enroll_session-form">
                    <label htmlFor="name">Your Name</label>
                    <input
                      type="text"
                      name=""
                      placeholder="Your Name"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setenrollSession((pre) => ({
                          ...pre,
                          name: e.target.value,
                        }))
                      }
                    />
                    <label htmlFor="Email">Your Email</label>
                    <input
                      type="email"
                      name=""
                      placeholder="Email"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setenrollSession((pre) => ({
                          ...pre,
                          email: e.target.value,
                        }))
                      }
                    />
                    <label htmlFor="Phone Number">Your Phone Number</label>
                    <input
                      type="text"
                      name=""
                      placeholder="Phone Number"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setenrollSession((pre) => ({
                          ...pre,
                          mobile: e.target.value,
                        }))
                      }
                    />
                  </form>
                </div>
                <div className=" EnrollButton">
                  <button
                    type="submit"
                    className=" Enroll_social_btn text-truncate  white-text btn btn-default"
                    onClick={BooKEnrollSession}
                  >
                    <svg
                      fill="none"
                      width={32}
                      height={32}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.2677 9.06113L13.0023 12.4954C12.1951 13.1283 11.0635 13.1283 10.2563 12.4954L5.95424 9.06113"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.88787 3.5H16.3158C17.6752 3.51525 18.969 4.08993 19.896 5.0902C20.823 6.09048 21.3022 7.42903 21.222 8.79412V15.322C21.3022 16.6871 20.823 18.0256 19.896 19.0259C18.969 20.0262 17.6752 20.6009 16.3158 20.6161H6.88787C3.96796 20.6161 2 18.2407 2 15.322V8.79412C2 5.87545 3.96796 3.5 6.88787 3.5Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Submit to Book Session</span>
                  </button>
                </div>
                {enrollSessionReducer?.isSuccess && (
                  <p style={{ color: "red" }}>Add SuccessFully</p>
                )}
                {enrollSessionReducer?.error && (
                  <p style={{ color: "red" }}>{enrollSessionReducer.error}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EnrollSession;
