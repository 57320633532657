import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dotImg from "../../img/dot.png";
import deleteImg from "../../img/delete.png";
import cameraImg from "../../img/camera.png";
import personalInfoImg from "../../img/personal-info.png";
import professionImg from "../../img/profession.png";
import lock_openImg from "../../img/lock_open.png";
import arrowRightImg from "../../img/arrow-right.png";
import './security.css'
import {
  getUserDetails,
  logoutUser,
  updateProfile,
  updateUserPassword,
} from "../../../../redux/actions/login";
import articleImg from "../../img/article.png";
import { REACT_TOKEN_AUTH, REACT_USER_ID } from "../../../auth/tokenProvider";
import { TailSpin } from "react-loader-spinner";

const Security = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");
  const [abc, setAbc] = useState(false);
  const handleLogOut = () => {
    window.location.assign("/");
    localStorage.removeItem("REACT_TOKEN_AUTH");
    localStorage.removeItem("REACT_USER_ID");
    dispatch(logoutUser(""));
  };
  const passwordDataModal = {
    deviceToken: REACT_TOKEN_AUTH.slice(1, -1),
    userId: REACT_USER_ID,
    password: newPassword,
    passwordData: {
      password: newPassword,
      user_id: REACT_USER_ID,
    },
  };
  const dispatch = useDispatch();
  const updateUserPasswordReducerData = useSelector(
    (state) => state.updateUserPasswordReducer
  );
  const updateUserPasswordMethod = () => {
    if (newPassword === confirmNewPassword && newPassword !== "") {
      dispatch(updateUserPassword(passwordDataModal));
      setPasswordMatch("");
    } else if (
      newPassword === "" ||
      currentPassword === "" ||
      confirmNewPassword === ""
    ) {
      setPasswordMatch("nullPassword");
    } else {
      setPasswordMatch("notMatched");
    }
  };

  const isLoading = updateUserPasswordReducerData?.loading;

  const isSuccess = updateUserPasswordReducerData?.success;

  useEffect(() => {
    ;
    import("../../css/custom.css");
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : null}
      <div className="profile-right-side Profile--Set-outline">
        <div className="tab-content">
          <div className="tab-pane active" id="tabs-3" role="tabpanel">
            <div className="profile-title Profile---title">
              <h3>Update Password</h3>
              <p>View/update your basic information</p>
            </div>
            <div className="profile-information">
              <div className="info-head Info-head-res">
                <p>Change Password</p>
                <a href="#">Edit</a>
              </div>
              <div className="pw-sec">
                <div className="form-group input-group Input--Form-Group">
                  <label className="required">Current Password</label>
                  <input
                    type="text"
                    name=""
                    placeholder="************"
                    className={"form-control FORM--CONTROL profile_security_settings_form_input"}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group input-group Input--Form-Group">
                  <label className="required">New Password</label>
                  <input
                    type="text"
                    name=""
                    className="form-control FORM--CONTROL profile_security_settings_form_input"
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group input-group Input--Form-Group">
                  <label className="required">Confirm Password</label>
                  <input
                    type="text"
                    name=""
                    className="form-control FORM--CONTROL profile_security_settings_form_input"
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    required
                  />
                </div>
                {passwordMatch === "notMatched" ? (
                  <div className="form-field-warning-txt">
                    Password doesn't matched
                  </div>
                ) : passwordMatch === "nullPassword" ? (
                  <div className="form-field-warning-txt">
                    Password can't be null
                  </div>
                ) : null}
                {isSuccess && isLoading === false ? (
                  <div className="form-field-success-txt">
                    Password Changed Successfully
                  </div>
                ) : isSuccess === false && isLoading === false ? (
                  <div className="form-field-warning-txt">
                    Password Change Request Failed
                  </div>
                ) : null}

                {/* <div className="singup-upp-change">
                  <button className="btn" onClick={() => updateUserPasswordMethod()} >
                    Confirm
                  </button>
                </div> */}
              </div>
              <div className="change-now-sec Btn-in-Center">
                <button
                  type="button"
                  className="btn border-bttn Border--Bttn bttn-margin-bottom"
                  onClick={() => updateUserPasswordMethod()}
                >
                  Change Now
                </button>
              </div>
              <div className="info-head Info-head-res Head-Information">
                <p>SIGN OUT FROM THE MENTORPAL</p>
              </div>
              <div className="change-now-sec logout-sec Btn-in-Cente">
                <button
                  type="button"
                  className="btn border-bttn Border--Bttn change-marginofbtn"
                  onClick={handleLogOut}
                >
                  LOGOUT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
