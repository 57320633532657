import React, { useState } from "react";
import ReactSelect from "react-select";
import { GraduationYear } from "../../../../assets/data/data";
// import "./UpdateCountry.css";
// import { countries } from "../../../../assets/data/data";
// import ReactSelect from "react-select";

const UpdateYear = ({ YearData, getUpdatedValues }) => {
    const customYearData = { label: YearData, value: YearData };
    const [selectedYear, setSelectedYear] = useState([
        customYearData,
    ]);
    const setYearValue = (e) => {
        let yearList = [];
        yearList?.push(e?.label);
        setSelectedYear(e);
    };
    const handleOnClick = () => {
        // let country = selectedYear[0]?.label;
        let tempData = { year: selectedYear?.label };
        getUpdatedValues(tempData);
    };
    return (
        <div className="update-country-main-container">
            <h7>Select a year</h7>
            <div className="country-input">
                <ReactSelect
                    value={selectedYear ? selectedYear : ""}
                    onChange={setYearValue}
                    placeholder="Eg. 2001,2002,2003"
                    name="year"
                    options={GraduationYear}
                    className="basic-multi-select text-left"
                    classNamePrefix="select"
                />
            </div>
            <button onClick={handleOnClick} className="update-btn-country">
                Update
            </button>
        </div>
    );
};

export default UpdateYear;
