import React, { useState } from "react";
import maleIcon from "../../assets/images/male.png";
import "./chat.css";
import ChatInboxList from "./Chatlisting";
import ChatMentorBox from "./ChatMentorBox";
import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_ID } from "../auth/tokenProvider";
import { useEffect } from "react";
import { getUserDetails } from "../../redux/actions/login";
import { undrawProfile } from "../../assets/images/export-images";

const ChatModule = (props) => {
  const { userDetails, ConnectionListData } = props;
  const [selectedUser, setSelectedUser] = useState(null);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };
  const toggleChatBox = () => {
    console.log('toggle chat box');
    setSelectedUser(null)
  }

  return (
    <>
      <section>
        <div className="">
          <div className="row">
            <div className="col-md-4 chat_listing">

              {/* <form className="d-flex">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
              </form> */}
              <div>
                {/* Pass the handleUserSelect function to the ChatInboxList component */}
                <ChatInboxList
                  onUserSelect={handleUserSelect}
                  ConnectionListData={ConnectionListData}
                />
              </div>
            </div>
            <div className="col-md-8 second_main">
              {/* Pass the selectedUser prop to the ChatMentorBox component */}
              <ChatMentorBox selectedUser={selectedUser} toggleChatBox={toggleChatBox} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChatModule;
