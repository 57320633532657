import React from 'react';

const CoursePreview = () => {
    return (
        <>
            <section>
                <div className="container avilabilty___col">
                    <div className="d-flex align-items-center justify-content-center vh-100">
                        <div className="text-center">
                            <h1 className="display-1 fw-bold">Coming Soon!</h1>
                            <p className="fs-3">
                                <span className="text-danger">Feature!</span> is not available .
                            </p>
                            <p className="lead">
                                Please use the "Save" button to ensure your course data is backed up.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CoursePreview;
