import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import secure from './assets/secure.png';

import closeUp from './assets/close-up-young.jpg';
import virtualClassroom from './assets/virtual-classroom-study.jpg';
import CallBackModal from './ApplyCourseModal/CallBack';

const GetMore = ({ data }) => {
    const navigate = useNavigate();
    const [callbackModal, setcallbackModal] = useState(false);

    return (
        <>
            <section id="extras" className="section">
                <div className="padding-global">
                    <div className="container-large">
                        <div className="padding-section-medium">
                            <div className="margin-bottom margin-medium">
                                <div className="text-align-center">
                                    <div className="max-width-large align-center">
                                        <h2 className="heading-style-h3" style={{ color: '#b7308e' }}>
                                            Get more from MentorPal {data?.document_type}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className=" layout366_component">
                                <div
                                    id="w-node-_9b67800b-f42b-ce2a-cc6e-c2c58135cf6e-8135cf61"
                                    className="w-layout-grid layout366_row"
                                >
                                    <div
                                        id="w-node-_9b67800b-f42b-ce2a-cc6e-c2c58135cf6f-8135cf61"
                                        className="layout366_card-large"
                                    >
                                        <div
                                            id="w-node-_9b67800b-f42b-ce2a-cc6e-c2c58135cf70-8135cf61"
                                            className="layout366_card-large-image-wrapper"
                                        >
                                            <img
                                                src={virtualClassroom}
                                                loading="lazy"
                                                sizes="(max-width: 479px) 91vw, (max-width: 767px) 93vw, 92vw"
                                                alt="virtualClassroom_image"
                                                className="layout366_card-large-image"
                                            />
                                        </div>
                                        <div className="layout366_card-large-content">
                                            <div className="layout366_card-large-content-top">
                                                <div className="margin-bottom margin-small">
                                                    <h3>Embrace Collaborative Learning with Global Experts</h3>
                                                </div>
                                                <p>
                                                    Immerse yourself in a dynamic educational experience with MentorPal, where you gain access to Live, Interactive, and Self-Paced sessions led by esteemed mentors from across the globe
                                                </p>
                                            </div>
                                            <div className="get-started-cls why_choose_ment">
                                                <a >
                                                    <button type="button" className="find-btn btn btn-primary" onClick={() => { navigate('/find-mentor') }}>
                                                        Find Mentor
                                                    </button>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='reimburse'>
                                        <div
                                            id="reimburse"
                                            className="layout366_card-small-content dark"
                                        >
                                            <div className="layout366_card-small-content-top">
                                                <div className="margin-bottom margin-small">
                                                    <div className="ft-icon">
                                                        <img
                                                            src={secure}
                                                            loading="lazy"
                                                            alt="reimburse_image"
                                                            className="ft___icon"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="margin-bottom margin-xxsmall">
                                                    <h2 className="heading-style-h4 bold">
                                                        Secure Reimbursement from Your Employer with mentorpal.ai
                                                    </h2>
                                                </div>
                                                <p className="text-color-white">
                                                    Once you've successfully enrolled in a {data?.document_type} through mentorpal.ai, you're eligible to receive reimbursement from your employer. Our streamlined process ensures that you can effortlessly claim back your {data?.document_type} expenses
                                                </p>
                                            </div>

                                            <div className="get-started-cls why_choose_ment">
                                                <a
                                                    onClick={() => {

                                                        setcallbackModal({
                                                            modal: CallBackModal, show: true,
                                                            headingTitle: "  Reimbursement from Your Employer", show: true,
                                                            inquiry_type: 'Reimbursement_from_Your_Employer',
                                                            message: `${data?.slug + "" + data?.product_id + " "}Enroll data for Reimbursement of Employer`



                                                        });
                                                    }}
                                                >
                                                    <button type="button" className="find-btn btn btn-primary">
                                                        Get email template
                                                    </button>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="layout366_card-small">
                                        <div
                                            id="w-node-_9b67800b-f42b-ce2a-cc6e-c2c58135cf8c-8135cf61"
                                            className="layout366_card-small-image-wrapper"
                                        >
                                            <img
                                                src={closeUp}
                                                loading="lazy"
                                                alt={data?.document_type}
                                                className="layout366_card-small-image"
                                            />
                                        </div>
                                        <div className="layout366_card-small-content nob">
                                            <div className="layout366_card-small-content-top">
                                                <div className="margin-bottom margin-xxsmall">
                                                    <h2 className="heading-style-h4 bold">
                                                        Buy MentorPal {data?.document_type}s for your team
                                                    </h2>
                                                </div>
                                                <p>
                                                    We Simplify Large-Scale Enrollments, Provide Detailed Billing, and Offer Expert Guidance for MentorPal Team {data?.document_type}s.
                                                </p>
                                            </div>
                                            <div className="margin-top margin-medium">
                                                <div className="button-group">
                                                    <a
                                                        onClick={() => {
                                                            setcallbackModal({
                                                                modal: CallBackModal,
                                                                show: true,
                                                                headingTitle: `Buy MentorPal ${data?.document_type}s for your team`,
                                                                inquiry_type: `Buy_MentorPal_${data?.document_type}s_for_your_team`,
                                                                message: `Enroll data for Buy MentorPal ${data?.document_type}s for your team`
                                                            });
                                                        }}

                                                        className="button is-link is-icon w-inline-block"
                                                    >
                                                        <div>Get in touch</div>
                                                        <div className="icon-embed-xxsmall w-embed">
                                                            <svg
                                                                width={16}
                                                                height={16}
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M6 3L11 8L6 13"
                                                                    stroke="CurrentColor"
                                                                    strokeWidth="1.5"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {callbackModal?.modal && (
                <callbackModal.modal
                    callbackModal={callbackModal}
                    setcallbackModal={setcallbackModal}
                />
            )}
        </>
    );
}

export default GetMore;
