import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ServiceTemplates from './PropsComponent/serviceTemplate';


const MentorServices = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const services = [
        { label: '1:1 Call', href: '/dashboard/services/video' },
        // { label: 'Subscription', href: '/dashboard/services/subscription' },
        // { label: 'Priority DM', href: '/dashboard/services/text' },
        // { label: 'Package', href: '/dashboard/services/package' },
        // { label: 'Digital Product', href: '/dashboard/services/document' },
        { label: 'Webinar', href: '/dashboard/services/webinar' },
    ];

    const [seeTemplates, setSeeTemplates] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    useEffect(() => {
        // Find the index of the service based on the current URL
        const currentServiceIndex = services.findIndex(service => location.pathname.includes(service.href));

        // Set the selectedTabIndex state
        setSelectedTabIndex(currentServiceIndex === -1 ? 0 : currentServiceIndex);
    }, [location.pathname, services]);

    const handleTabClick = (index, service) => {
        setSelectedTabIndex(index);
        navigate(service.href);
    };

    return (
        <>
            <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
                <div className="ant-row container-title-section ">
                    <div className="ant-col header-section ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-14 ant-col-md-offset-1 ant-col-xl-12 ant-col-xl-offset-1 ant-col-xxl-12 ant-col-xxl-offset-1 ">
                        <h4 className="ant-typography layout-header ">
                            <div className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line ">
                                Services
                            </div>
                        </h4>
                    </div>
                    <div className="ant-col tab-container ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-23 ant-col-md-offset-1 ant-col-xl-22 ant-col-xl-offset-1 ant-col-xxl-22 ant-col-xxl-offset-1 ">
                     
                        <span className="ant-typography flex-center " style={{ overflowY: "auto" }}>
                            {services.map((service, index) => (
                                <a
                                    key={index}
                                    className={`tab-link tab-link-common ${index === selectedTabIndex ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index, service)}
                                >
                                    <span className="ant-typography tab-title ">{service.label}</span>
                                </a>
                            ))}
                        </span>
                        
                      
                        <button
                            type="button"
                            className="ant-btn  ant-btn-default tab-cta-link tab-link-common btn-dark"
                            style={{ borderRadius: 4 }}
                            onClick={() => navigate('/dashboard/service/add')}
                        >
                            <span>+ Add Services</span>
                        </button>
                        <div>
                        <button
                            type="button"
                            className="ant-btn  ant-btn-default tab-cta-link tab-link-common btn-dark"
                            style={{
                                background: "rgb(255, 255, 255)",
                                color: "rgb(20, 20, 20)",
                                borderRadius: 4,
                                // marginLeft: "-36px",
                            }}
                            onClick={() => setSeeTemplates(true)}
                        >
                            <span>See Templates</span>
                        </button>
                        </div>
                        
                    </div>
                </div>
                <div className="ant-row container-children ">
                    <Outlet />
                </div>
            </div>


            {
                seeTemplates && (
                    <ServiceTemplates
                        seeTemplates={seeTemplates}
                        setSeeTemplates={setSeeTemplates}

                    />

                )
            }

        </>
    );
};

export default MentorServices;
