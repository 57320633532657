import React from 'react';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../CreateCourse/custom.createcourse.css'



const TextEditor = ({ label, editorState, onEditorStateChange, mentionSuggestions }) => {
    return (
        <div className="form-group ">
            <label className="session__label">{label}</label>
            <div className="Text__editor">

                <Editor
                    className="form-control"
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                    mention={{
                        separator: " ",
                        trigger: "@",
                        suggestions: mentionSuggestions
                    }}
                />
            </div>
        </div>
    );
};

export default TextEditor;
