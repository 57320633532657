import React from "react";
import "./cutsom.css";
import Filter from "./filter";
import AllList from "./candidateList/AllList";
import CandidateList from "./candidateList";
import { useState } from "react";
import Preferences from "./modal/preferences";
import DashboardHeader from "../adminDashboard/DashboardHeader";
import Header from "../header";
const HireTalent = () => {
  const [preferencesModal, setpreferencesModal] = useState({});
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_USER_ID") ? true : false
  );
  const [UserIn, userIn] = useState(localStorage.getItem("REACT_USER_TYPE"));

  return (
    <>
      {loggedIn ? (
        UserIn === "mentee" ? (
          <div style={{ marginTop: "0" }}>
            <DashboardHeader />
          </div>
        ) : (
          <DashboardHeader />
        )
      ) : (
        <Header extraClasses="inner-header" />
      )}
      <div className="Layout__Wrapper">
        <div className="container">
          <div className="SearchForTalents__Wrapper mt-4 mt-md-5 row">
            <div className="col-sm-4">
              <div className="SearchForTalents__SearchWrapper ">
                <h2 className="  hire-heading">
                  Spotlighting the Top Talents of Our Time
                </h2>

                <button
                  height="unset"
                  id="edit-preferences-button"
                  type="button"
                  className="  text-truncate p-0 shadow-none white-text hire-banner-button btn btn-default"
                >
                  <span
                    className="p-0 mr-1"
                    onClick={() => {
                      setpreferencesModal({
                        modal: Preferences,
                        show: true,
                      });
                    }}
                  >
                    Customize
                  </span>
                  <svg
                    fill="none"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.5 5L15.5 12L8.5 19"
                      stroke="#fff"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      s
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <Filter />
            </div>
            <div className="HireTalents__Wrapper col">
              <CandidateList />
            </div>
          </div>{" "}
        </div>
      </div>
      {preferencesModal?.modal && (
        <preferencesModal.modal
          preferencesModal={preferencesModal}
          setpreferencesModal={setpreferencesModal}
        />
      )}
    </>
  );
};

export default HireTalent;
