import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space, Typography } from 'antd';
import BookingDetail from './Modal/BookingDetail';
import CanccelBooking from './Modal/CancelBooking';
import RescheduleBooking from './Modal/RescheduleBooking';
import { BookingExampleData, convertTimeRangeToLocal, convertUTCtoDate } from '../../Data/Data';
import { useSelector } from 'react-redux';

const CallCards = (props) => {
  const { tabKey, data } = props

  const [ModalData, setModalData] = useState()
  const [DetailModal, setDetailModal] = useState(false)
  const [ReschedulModal, setReschedulModal] = useState(false)
  const [CancelModal, setCancelModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState('3');

  const handleOpenChange = (open) => {
    console.log('Dropdown visibility changed:', open);
  };

  const handleItemClick = ({ key, data }) => {

    setSelectedItem(key);
    setModalData(data)
    if (key === '1') {
      setDetailModal(true)
    }
    else if (key === '2') {
      setReschedulModal(true)
    }
    else {
      setCancelModal(true)
    }
  };

  const duration = 122

  return (
    <>


      {
        data.reverse().map((item, index) => (
          <div key={index} className="ant-typography ">
            <div className="ant-typography dates ">
              {item.mentee_details.meeting_time.includes('T') ? (
                convertUTCtoDate(item.mentee_details.meeting_time)
              ) : (
                convertUTCtoDate(`${item.mentee_details.meeting_date}T${item.mentee_details.meeting_time}:00.000Z`)
              )}
            </div>
            <div
              className="ant-row "
              style={{ marginLeft: "-10px", marginRight: "-10px" }}
            >
              <div
                className="ant-col ant-col-24 "
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <div
                  className="ant-col calls-card-container ant-col-xs-24 "
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <div className="ant-typography calls-card ">
                    <div className="ant-row ant-row-space-between calls-header cursor-hover ">
                      <div className="ant-col ">
                        <div className="ant-typography calls-time ">
                          {" "}
                          {item.mentee_details.meeting_time.includes('T') ? (
                            convertTimeRangeToLocal(item.mentee_details.meeting_time, duration)
                          ) : (
                            convertTimeRangeToLocal(`${item.mentee_details.meeting_date}T${item.mentee_details.meeting_time}:00.000Z`, duration)
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="ant-typography calls-details cursor-hover ">
                      <div className="ant-typography calls-name ">
                        <span className="ant-typography ">
                          <strong>{item?.mentee_details?.name} ({duration} mins)</strong>
                        </span>
                      </div>
                      <div className="ant-typography ">
                        <span className="ant-typography calls-service-desc ">
                          {/* {item?.service?.title} */}Call
                        </span>
                        <span className="ant-typography calls-price ">
                          (
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth={2}
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M18 5h-11h3a4 4 0 0 1 0 8h-3l6 6" />
                            <path d="M7 9l11 0" />
                          </svg>
                          {item?.mentee_details?.price_per_session})
                        </span>
                      </div>
                    </div>
                    <div className="ant-typography calls-footer ">
                      <div
                        className="ant-row ant-row-space-between ant-row-middle "
                        style={{ marginLeft: "-2.5px", marginRight: "-2.5px" }}
                      >

                        <div
                          className="ant-col ant-col-xs-10 ant-col-md-9 "
                          style={{ paddingLeft: "2.5px", paddingRight: "2.5px" }}
                        >
                          <div className="ant-typography ">
                            <div
                              className="ant-typography "
                              style={{
                                backgroundColor: "rgb(236, 247, 241)",
                                borderRadius: 28,
                                textAlign: "center",
                                display: "inline-flex"
                              }}
                            >
                              <div
                                className="ant-typography status-tag-text "
                                style={{ color: "rgb(10, 68, 44)" }}
                              >
                                {item?.status}
                              </div>
                            </div>
                          </div>

                        </div>
                        <div
                          className="ant-col ant-col-xs-14 ant-col-md-15 "
                          style={{ paddingLeft: "2.5px", paddingRight: "2.5px" }}
                        >

                          <div
                            className="ant-row ant-row-end ant-row-middle "
                            style={{ marginLeft: "-4px", marginRight: "-4px" }}
                          >
                            <div
                              className="ant-col "
                              style={{ paddingLeft: 4, paddingRight: 4 }}
                            >
                              {/* <Dropdown
                                className="ant-btn  ant-btn-default ant-btn-block ant-dropdown-trigger btn-edit"

                                onOpenChange={handleOpenChange}
                                overlay={
                                  <Menu
                                    items={[
                                      {
                                        key: '1',
                                        label: 'Call Details',
                                        data: item
                                      },
                                      tabKey === 'upcoming' ? {
                                        key: '2',
                                        label: 'Reschedule',
                                        data: item
                                      } : null,
                                      tabKey === 'upcoming' ?
                                        {
                                          key: '3',
                                          label: 'Cancel',
                                          data: item
                                        } : null
                                    ]}

                                    selectable
                                    defaultSelectedKeys={[selectedItem]}
                                    onClick={({ key }) => handleItemClick({ key, data: item })} />}
                              >
                                <Typography.Link>
                                  <Space>
                                    More Action
                                    <DownOutlined />
                                  </Space>
                                </Typography.Link>
                              </Dropdown> */}
                            </div>


                            <div
                              className="ant-col ant-col-xs-15 ant-col-md-10 "
                              style={{ paddingLeft: 4, paddingRight: 4 }}
                            >
                              <a
                                href={item?.mentee_details.meeting_link}
                                target="_blank"
                                title="Call link will be activated 30 mins before booking time"
                                type="button"
                                className="ant-btn  ant-btn-primary ant-btn-block btn-dark"
                                disabled={tabKey === 'upcoming' ? false : true} rel="noreferrer"
                              >
                                <span>Join Call</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))

      }

      {
        DetailModal && (
          <BookingDetail DetailModal={DetailModal} setDetailModal={setDetailModal} ModalData={ModalData} />
        )
      }
      {
        ReschedulModal && (
          <RescheduleBooking ReschedulModal={ReschedulModal} setReschedulModal={setReschedulModal} ModalData={ModalData} />
        )
      }
      {
        CancelModal && (
          <CanccelBooking CancelModal={CancelModal} setCancelModal={setCancelModal} ModalData={ModalData} />
        )
      }
    </>
  );
}

export default CallCards;
