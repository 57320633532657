import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { undrawProfile } from "../../../../assets/images/export-images";
const OpenSession = () => {
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dateFormater = (date) => {
    const monthArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let newDate = new Date(date);
    let formatedDate =
      ("0" + newDate.getDate()).slice(-2) +
      " " +
      monthArray[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      ("0" + newDate.getHours()).slice(-2) +
      ":" +
      ("0" + newDate.getMinutes()).slice(-2);
    return formatedDate;
  };

  const mentorSessionReducerData = useSelector(
    (state) => state?.getAllMentorSessionReducer
  );
  const mentorSessionData = mentorSessionReducerData?.data;

  const isMeetingTimeInFuture = (meetingTime) => {
    const currentDateTime = new Date();
    const meetingDateTime = new Date(meetingTime);
    return meetingDateTime < currentDateTime;
  };
  useEffect(() => {
    setIsLoading(mentorSessionReducerData?.loading)

  }, [mentorSessionReducerData])

  useEffect(() => {
    if (mentorSessionData?.length > 0) {
      const filteredSessions = mentorSessionData.filter(
        (menteeRequest) =>
          menteeRequest.mentee_details.meeting_time.includes("T")
            ? isMeetingTimeInFuture(menteeRequest.mentee_details.meeting_time)
            : isMeetingTimeInFuture(
              `${menteeRequest.mentee_details.meeting_date}T${menteeRequest.mentee_details.meeting_time}:00.000Z`
            )
      );

      setUpcomingSessions(filteredSessions);
    }

  }, [mentorSessionData]);

  return (
    <div className="tab-pane active" id="tabs-1" role="tabpanel">
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div className="table-responsive">
          {upcomingSessions?.length > 0 ? (
            <table style={{ width: "100%" }}>
              <tbody>
                  {upcomingSessions.reverse().map((menteeRequest, index) => (
                  <tr key={index}>
                    <td className="d-flex">
                      <div className="container">
                        <div className="row mobile-view-privatesession">
                          <div className="col-lg-3 col-md-3">
                            <div className="profile-box">
                              {menteeRequest?.mentee_details?.profile_photo ? (
                                <img
                                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}${menteeRequest?.mentee_details?.profile_photo}`}
                                  alt=""
                                />
                              ) : (
                                <img alt="icon" src={undrawProfile} />
                              )}
                            </div>
                            <h3 className="pro-name">
                              {menteeRequest?.mentee_details?.name}
                              <span className="small-cls">
                                {menteeRequest?.mentee_details?.email}
                              </span>
                            </h3>
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <td>
                              <h3 className="pro-name">
                                Meeting Time
                                <span className="small-cls">
                                  {menteeRequest.mentee_details.meeting_time.includes('T') ? (
                                    dateFormater(menteeRequest.mentee_details.meeting_time)
                                  ) : (
                                    dateFormater(`${menteeRequest.mentee_details.meeting_date}T${menteeRequest.mentee_details.meeting_time}:00.000Z`)
                                  )}
                                </span>
                              </h3>
                            </td>
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <td>
                              {/* <h3 className="pro-name ml-auto">
                                Request Status
                                <span className="small-cls">
                                  {menteeRequest?.status}
                                </span>
                              </h3> */}
                              <button
                                // onClick={() =>
                                //   window.open(
                                //     menteeRequest?.mentee_details?.meeting_link
                                //   )
                                // }
                                disabled
                                className="btn session-start"
                              >
                                Session Completed
                              </button>
                            </td>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="no-session-found">
              <h2>No  Sessions History    </h2>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OpenSession;
