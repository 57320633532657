import React from "react";
import ProtectedHeader from "../ProtectedHeader/ProtectedHeader";
// import OurTeam from "../TeamPage/ourTeam";
import Header from "../header";
import { useState } from "react";
import SignUpModal from "../modals/signUpModal";
import Testimonial from "../Testimonial/testimonial.js";
import crossIcon from "../../assets/images/cross-icon.png";
import { Modal, ModalBody } from "react-bootstrap";
import {
  bannerImg,
  star1,
  testimonialImg,
} from "../../assets/images/export-images";
import OwlCarousel from "react-owl-carousel";
import Footer from "../footer";
import style from "./p_ricing.module.css";
import { Helmet } from "react-helmet";
import SeoHelmet from "../Seo/Helmet/SeoHelmet";
import { PricingPageSeo } from "../Seo/data";

const MentorPricing = () => {
  const [headerModal, setHeaderModal] = useState({});
  const [knowMoreModalCommission, setKnowMoreModalCommission] = useState(false);
  const [knowMoreModalSubscription, setKnowMoreModalSubscription] =
    useState(false);
  const [knowMoreModalProBono, setKnowMoreModalProBono] = useState(false);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
  );
  const userData = [
    {
      name: "Reena bantu",
      comment:
        "I can't say enough good things about my experience with Mentorpal. As a mentee, I was matched with an amazing mentor who has been a constant source of support and guidance. He has helped me to identify and pursue my goals, and he has provided me with valuable insights and advice that have helped me to grow both personally and professionally",
      title: "Node js",
    },
    {
      name: "munesh",
      comment:
        "I feel so fortunate to have had the opportunity to work with my mentor and the Mentorpal platform. The platform is user-friendly and the support team is always there to help with any questions or concerns. I highly recommend Mentorpal to anyone seeking a mentor to help them achieve their goals and realize their full potential.",
      title: "java",
    },
    {
      name: "jitendra",
      comment:
        "I have been a mentee on the Mentorpal platform for the past six months and it has truly been a life-changing experience. My mentor has been an incredible source of knowledge, support, and motivation for me. She has helped me to clarify my goals, develop a plan to achieve them, and provided me with valuable resources and guidance along the way",
      title: "next.js",
    },
    {
      name: "jackson",
      comment:
        "I am so grateful to have had the opportunity to work with such an amazing mentor and I highly recommend the Mentorpal platform to anyone seeking guidance and support in their personal or professional development. Thank you, Mentorpal, for providing such a valuable service and for connecting me with my amazing mentor.",
      title: "react.js",
    },
    {
      name: "harpal singh",
      comment:
        "I recently had the opportunity to participate in the Mentorpal mentoring program and it has been an absolute game-changer for me. My mentor, has been a constant source of guidance, support, and encouragement throughout the program. He has a wealth of knowledge and experience in his field and he has been incredibly generous in sharing that with me.",
      title: "Design",
    },
    {
      name: "Neha",
      comment:
        "Not only has my mentor helped me to identify and pursue my goals, but he has also challenged me to think critically and push myself out of my comfort zone. I feel so much more confident and motivated as a result of this mentorship and I am truly grateful to my mentor for all of his help.",
      title: "Content store",
    },
    {
      name: "Sachin",
      comment:
        "I highly recommend the Mentorpal platform to anyone looking for a mentor. The personalized attention and support that I received from Mentorpal has been invaluable and I am confident that it will have a lasting impact on my future. Thank you, Mentorpal!",
      title: "Node.Js",
    },
  ];
  return (

    <>
      <SeoHelmet metaData={PricingPageSeo} />

      {loggedIn ? <ProtectedHeader /> : <Header />}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${bannerImg})`,
          padding: "175px 0px 105px 0px",
        }}
      >
        <div className="container">
          <div className="mentroship-sec">
            <h1 className={style.main_sec_content}>
              MentorPal: Where your passion for mentorship meets your desire to
              make a difference
            </h1>
          </div>
        </div>
        <div className="container">
          <div className="down__arrow get-started-cls">
            <div className="see-pricing">
              <h3 className={style.plans_text}>See pricing and plans</h3>
            </div>
            <a href="#pricing_section">
              <svg
                style={{
                  height: "70px",
                  width: "70px",
                  cursor: "pointer",
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ffff"
                class="bi bi-arrow-down-circle down_arrow_icon"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
                />
              </svg>
            </a>
          </div>
        </div>
      </section>

      {/* pricing section */}
      <section className="why-choose">
        <div className="container" id="pricing_section">
          <div className="row">
            <h2 className={style.two_plans}>
              The mentor pricing plan consists of two different options-
            </h2>

            <div className={`${style.plan_card} col`}>
              <div
                className={`mentroship-sec ${style.mentroship_sec} pricing_h1 ${style.mobile_view_sec_border}`}
              >
                <div className="inner-title">
                  <h3>COMMISSION</h3>
                </div>
                <div>
                  <h1 className={style.utlity__percent}>7%</h1>
                </div>
                <div className="serve-cont">
                  <p>
                    Partner with us and enjoy a transparent and fair commission
                    model. We charge a flat 7% commission on your transactions,
                    without any upfront fees. This means you keep 93% of your
                    earnings while leveraging our platform's features and
                    support.
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "blue",
                        cursor: "pointer",
                        fontSize: "1.2rem",
                      }}
                      onClick={() => {
                        setKnowMoreModalCommission(true);
                      }}
                    >
                      {" "}
                      more...
                    </span>
                  </p>
                </div>
                <div
                  className={` get-started-cls ${style.pricing_commisson_btn}`}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#form"
                    onClick={() =>
                      setHeaderModal({
                        modal: SignUpModal,
                        show: true,
                        as: "mentee",
                      })
                    }
                  >
                    <a href="/apply-as-mentor" style={{ listStyle: "none", textDecoration: "none", color: "black" }}>Commission</a>
                  </button>
                </div>
              </div>
            </div>
            <Modal
              size="x"
              show={knowMoreModalCommission}
              style={{ background: "transparent" }}
            >
              <ModalBody style={{ background: "transparent" }}>
                <button
                  type="button"
                  className="cloose"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setKnowMoreModalCommission(false)}
                >
                  <div className="closebuttons">
                    <span aria-hidden="true">
                      <img src={crossIcon} />
                    </span>
                  </div>
                </button>
                <div className={`${style.modal_pricing}`}>
                  <section className={`why-choose ${style.modal_pricing}`}>
                    <div className="container" id="pricing_section">
                      <div className="row">
                        <div className="inner-title">
                          <h3>COMMISSION</h3>
                        </div>
                        <div className="utlity__percent">
                          {/* <h1
                    className={`${style.utlity__percent} ${style.pricing_label} `}
                  >
                    $15/m
                  </h1> */}
                        </div>
                        <div className="serve-cont">
                          <p>
                            Partner with us under our Commission Model and enjoy
                            a transparent and equitable approach. We charge a
                            flat 7% commission on your transactions, without any
                            upfront fees. This means you keep a remarkable 93%
                            of your hard-earned earnings. Leverage our
                            platform's exceptional features and dedicated
                            support while maintaining control over your
                            mentorship journey. With our clear commission
                            structure, you can focus on what matters
                            most—mentoring
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </ModalBody>
            </Modal>

          
           
            <div className={`${style.pro_bono} col`}>
              <div
                className={`mentroship-sec ${style.mentroship_sec}  pricing_h1`}
              >
                <div className="inner-title">
                  <h3>Pro Bono</h3>
                </div>
                <div>
                  <h1
                    className={`${style.utlity__percent} ${style.pricing_label} `}
                  >
                    Serve
                  </h1>
                </div>
                <div className="serve-cont">
                  <p>
                    Join MentorPal's social initiative and use your skills to
                    make a positive impact. Offer free mentorship on our
                    platform and help empower individuals for a better future,
                    while promoting positive change.
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "blue",
                        cursor: "pointer",
                        fontSize: "1.2rem",
                      }}
                      onClick={() => {
                        setKnowMoreModalProBono(true);
                      }}
                    >
                      {" "}
                      more...
                    </span>
                  </p>
                </div>
                <div className=" get-started-cls">
                  <button type="button" className="btn btn-primary">
                    <a href="/apply-as-mentor" style={{ listStyle: "none", textDecoration: "none", color: "black" }}>Serve</a>
                  </button>
                </div>
              </div>
            </div>
            <Modal
              size="x"
              show={knowMoreModalProBono}
              style={{ background: "transparent" }}
            >
              <ModalBody style={{ background: "transparent" }}>
                <button
                  type="button"
                  className="cloose"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setKnowMoreModalProBono(false)}
                >
                  <div className="closebuttons">
                    <span aria-hidden="true">
                      <img src={crossIcon} />
                    </span>
                  </div>
                </button>
                <div className={`${style.modal_pricing}`}>
                  <section className={`why-choose ${style.modal_pricing}`}>
                    <div className="container" id="pricing_section">
                      <div className="row">
                        <div className="inner-title">
                          <h3>Pro Bono</h3>
                        </div>
                        <div className="utlity__percent">
                          {/* <h1
                    className={`${style.utlity__percent} ${style.pricing_label} `}
                  >
                    $15/m
                  </h1> */}
                        </div>
                        <div className="serve-cont">
                          <p>
                            Join MentorPal's Pro Bono - Serve Initiative and use
                            your skills to empower individuals and drive
                            positive change. By offering free mentorship on our
                            platform, you can contribute to building a better
                            future for others. Become a catalyst for growth and
                            transformation while promoting a culture of social
                            responsibility. Through this initiative, you'll not
                            only create a positive impact but also gain
                            fulfillment from making a meaningful difference in
                            people's lives.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </section>

      {/* modal section */}

      {/* modal section end */}

      {/* comparision section */}

      {/* <section className={style.pricing__section}>
        <div className="container">
          <div className="row">
            <div className={style.comparisonSub}>
              <h3>COMPARISION</h3>
            </div>
            <div className="col">
              <div className={style.here__why_we}>
                <h1>Here's why we think we edge past others</h1>
              </div>
            </div>
            <div className="col">
              <div className={`${style.innerColP} inner-title`}>
                <h3>
                  At MentorPal the aim is to always challenge the status quo and
                  create scalable solutions that don't burn holes in the pocket.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <table class="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Mentorpal</th>
                <th scope="col">Others</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">01</th>
                <td>Pricing</td>
                <td>$0/m</td>
                <td>@$8-$20/m</td>
              </tr>
              <tr>
                <th scope="row">02</th>
                <td>Transaction fees</td>
                <td>$0/m</td>
                <td>$30/m</td>
              </tr>
              <tr>
                <th scope="row">03</th>
                <td>White-label / Custom domain</td>
                <td>$0/m</td>
                <td>$12-$15/m</td>
              </tr>
              <tr>
                <th scope="row">04</th>
                <td>International payments</td>
                <td>$0/m</td>
                <td>$50/m</td>
              </tr>
              <tr>
                <th scope="row">05</th>
                <td>Multiple ways to sell</td>
                <td>$0/m</td>
                <td>$10-$20/m</td>
              </tr>
              <tr>
                <th scope="row">06</th>
                <td>Content tools</td>
                <td>$0/m</td>
                <td>$10-$50/m</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section> */}

      {/* Testionial Section */}
      <div className="container">
        <center>
          <h1 className="my-5">Our Inspiring Mentors & Empowered Mentees</h1>
        </center>
        <Testimonial />
      </div>

      <section className={style.pricing__section}>
        <div className="container">
          <div className="frequently_asked">
            <div className="row">
              <div className="col-md-6">
                <div className="mentoring-cont">
                  <div className={style.faq__pricing}>
                    <h3>Frequently asked Questions</h3>
                    <div className="inner-title">
                      <h2>
                        Can't find the answer you <span> are looking for?</span>
                      </h2>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                        </svg>
                      </span>
                      <a href="mailto:help@mentorpal.ai">
                        {" "}
                        <span className="cont-cls">Reach out to us</span>
                      </a>
                    </li>
                    <li>
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                        </svg>
                      </span>
                      <a href="https://www.mentorpal.ai/feedback">
                        <span className="cont-cls">
                          Help & grow with MentroPal
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        What is MentorPal?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        MentorPal is a tool that enables you to connect with
                        your audience through 1:1 sessions - to share your
                        knowledge and monetise your time better. Just create
                        your personal link, add your services, availability and
                        charges; and share your link with your followers. Your
                        followers can now book a call to connect with you
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Can I conduct paid sessions?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Absolutely, we enable you to conduct both free and paid
                        sessions with your followers
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        How much can I charge for my sessions?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        It really depends on how much value you think you're
                        providing and how much are your followers willing to
                        pay. For idea, our creators charge anywhere from $50 to
                        $500 for a session
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        How do the payments work?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        You can collect payments from anywhere in the world in
                        your preferred currency. Payouts are Instant with the
                        help of our integration with Stripe and Paypal
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        Wait, is MentorPal really free?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Yes! We're free to use and charge a very tiny commission
                        on your earnings (7%). So that we grow only when you
                        grow
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingSix">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix"
                        aria-expanded="false"
                        aria-controls="flush-collapseSix"
                      >
                        What are the transaction charges?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSix"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingSix"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Payment gateways like Stripe and PayPal typically charge
                        around 3% of the transaction amount. This is beyond our
                        control.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />

      {/* <OurTeam/> */}
      <br />
      <Footer />
    </>
  );
};

export default MentorPricing;
