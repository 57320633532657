import { call, put } from "redux-saga/effects";
import {
  uploadImageReq,
  uploadImageFileReq,
  submitFeedbackDataCall,
  updateCalendarSettingsCall,
  personalityTestCall,
  testResponseCall,
  fullStackCall,
  getLiveSessionCall,
  getSessionDetailsCall,
  enrollSessionCall,
  createSessionCall,
  sendChatMessageCall,
  getChatMessageCall,
  getMenteeInboxListCall,
  getConnectionRequestCall,
  updateConnectionRequestCall,
  getAcceptConnectionRequestCall,
  getConnectionListCall,
  getGoalCall,
  getNotificationCall,
  updateNotificationStatusCall,
  subscribeCall,
  contactCall,
  addBankAccountCall,
  getBankeDetailsCall,

} from "../../network";
import {
  commonConstants,
  feedbackConstants,
  updateCalendarSettings,
  personalityTest,
  testResponseConstants,
  fullStackConstants,
  liveSessionConstants,
  sessionDetailsConstants,
  enrollSessionConstants,
  createSessionConstants,
  getChatMessageConstants,
  sendChatMessageConstants,
  getMenteeInboxListConstants,
  getConnectionRequestConstants,
  updateConnectionRequestConstants,
  getAcceptConnectionRequestConstants,
  getConnectionListConstants,
  getGoalConstants,
  getNotificationConstants,
  updateNotificationStatusConstants,
  SubscribeConstants,
  contactConstants,
  getBankDetailsConstants,
  addBankAccountConstants
  // contactCall
} from "../../constants";
import {
  sessionDetailsAction,
  updateCalendarSettingsAction,
} from "../actions/common";

export function* uploadImgaeSaga(action) {
  try {
    const response = yield call(uploadImageReq, action.data);
    yield put({
      type: commonConstants.UPLOAD_IMAGE_REQUEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: commonConstants.UPLOAD_IMAGE_REQUEST_FAILURE,
      error: e.message,
    });
  }
}

export function* uploadImgaeFileSaga(action) {
  try {
    const response = yield call(uploadImageFileReq, action.data);
    yield put({
      type: commonConstants.UPLOAD_IMAGE_FILE_REQUEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: commonConstants.UPLOAD_IMAGE_FILE_REQUEST_FAILURE,
      error: e.message,
    });
  }
}

export function* submitFeedbackDataSaga(action) {
  try {
    const response = yield call(submitFeedbackDataCall, action.data);
    yield put({
      type: feedbackConstants.FEEDBACK_SUBMIT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: feedbackConstants.FEEDBACK_SUBMIT_FAILURE,
      error: e.message,
    });
  }
}

export function* updateCalendarSettingsSaga(action) {
  try {
    const response = yield call(updateCalendarSettingsCall, action.data);
    yield put({
      type: updateCalendarSettings.UPDATE_CALENDAR_SETTINGS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: updateCalendarSettings.UPDATE_CALENDAR_SETTINGS_FAILURE,
      error: e.message,
    });
  }
}

export function* personalityTestSaga(action) {
  try {
    const response = yield call(personalityTestCall, action.data);
    yield put({
      type: personalityTest.TEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({ type: personalityTest.TEST_FAILURE, error: e.message });
  }
}

export function* testResponseSaga(action) {
  try {
    const response = yield call(testResponseCall, action.data);
    yield put({
      type: testResponseConstants.TEST_SUBMIT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: testResponseConstants.TEST_SUBMIT_FAILURE,
      error: e.message,
    });
  }
}
export function* fullStackSaga(action) {
  try {
    const response = yield call(fullStackCall, action.data);
    yield put({
      type: fullStackConstants.FULL_SUBMIT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: fullStackConstants.FULL_SUBMIT_FAILURE,
      error: e.message,
    });
  }
}
export function* liveSessionSaga(action) {
  try {
    const response = yield call(getLiveSessionCall, action.data);
    yield put({
      type: liveSessionConstants.LIVESESSION_SUBMIT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: liveSessionConstants.LIVESESSION_SUBMIT_FAILURE,
      error: e.message,
    });
  }
}
export function* sessionDetailsSaga(action) {
  try {
    const response = yield call(getSessionDetailsCall, action.data);
    yield put({
      type: sessionDetailsConstants.SESSION_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: sessionDetailsConstants.SESSION_DETAILS_FAILURE,
      error: e.message,
    });
  }
}
export function* enrollSessionSaga(action) {
  try {
    const response = yield call(enrollSessionCall, action.data);
    yield put({
      type: enrollSessionConstants.ENROLL_SESSION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: enrollSessionConstants.ENROLL_SESSION_FAILURE,
      error: e.message,
    });
  }
}
export function* createSessionSaga(action) {
  try {
    const response = yield call(createSessionCall, action.data);
    yield put({
      type: createSessionConstants.CREATE_SESSION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: createSessionConstants.CREATE_SESSION_FAILURE,
      error: e.message,
    });
  }
}
export function* sendChatMessageSaga(action) {
  try {
    const response = yield call(sendChatMessageCall, action.data);
    yield put({
      type: sendChatMessageConstants.SEND_CHAT_MESSAGE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: sendChatMessageConstants.SEND_CHAT_MESSAGE_FAILURE,
      error: e.message,
    });
  }
}
export function* getChatMessageSaga(action) {
  try {
    const response = yield call(getChatMessageCall, action.data);
    yield put({
      type: getChatMessageConstants.GET_CHAT_MESSAGE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getChatMessageConstants.GET_CHAT_MESSAGE_FAILURE,
      error: e.message,
    });
  }
}

export function* getConnectionRequestSaga(action) {
  try {
    const response = yield call(getConnectionRequestCall, action.data);
    yield put({
      type: getConnectionRequestConstants.GET_CONNECTION_REQUEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getConnectionRequestConstants.GET_CONNECTION_REQUEST_FAILURE,
      error: e.message,
    });
  }
}
export function* updateConnectionRequestSaga(action) {
  try {
    const response = yield call(updateConnectionRequestCall, action.data);
    yield put({
      type: updateConnectionRequestConstants.UPDATE_CONNECTION_REQUEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: updateConnectionRequestConstants.UPDATE_CONNECTION_REQUEST_FAILURE,
      error: e.message,
    });
  }
}
export function* getConnectionListSaga(action) {
  try {
    const response = yield call(getConnectionListCall, action.data);
    yield put({
      type: getConnectionListConstants.GET_CONNECTION_LIST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getConnectionListConstants.GET_CONNECTION_LIST_FAILURE,
      error: e.message,
    });
  }
}
export function* getGoalSaga(action) {
  try {
    const response = yield call(getGoalCall, action.data);
    yield put({
      type: getGoalConstants.GET_GOAL_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getGoalConstants.GET_GOAL_FAILURE,
      error: e.message,
    });
  }
}
export function* getNotificationSaga(action) {
  try {
    const response = yield call(getNotificationCall, action.data);
    yield put({
      type: getNotificationConstants.GET_NOTIFICATION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getNotificationConstants.GET_NOTIFICATION_FAILURE,
      error: e.message,
    });
  }
}
export function* updateNotificationStatusSaga(action) {
  try {
    const response = yield call(updateNotificationStatusCall, action.data);
    yield put({
      type: updateNotificationStatusConstants.UPDATE_NOTIFICATION_STATUS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: updateNotificationStatusConstants.UPDATE_NOTIFICATION_STATUS_FAILURE,
      error: e.message,
    });
  }
}
export function* subscribeSaga(action) {
  try {
    const response = yield call(subscribeCall, action.data);

    yield put({
      type: SubscribeConstants.SUBSCRIBE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: SubscribeConstants.SUBSCRIBE_FAILURE,
      error: e.message,
    });
  }
}


export function* contactSaga(action) {
  try {
    const response = yield call(contactCall, action.data);
    yield put({
      type: contactConstants.CONTACT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: contactConstants.CONYACT_FAILURE,
      error: e.message,
    });
  }
}
export function* addBankAccountSaga(action) {
  try {
    const response = yield call(addBankAccountCall, action.data);
    yield put({
      type: addBankAccountConstants.ADD_ACCOUNT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: addBankAccountConstants.ADD_ACCOUNT_FAILURE,
      error: e.message,
    });
  }
}
export function* getBankDetailsSaga(action) {
  try {
    const response = yield call(getBankeDetailsCall, action.data);
    yield put({
      type: getBankDetailsConstants.GET_ACCOUNT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: contactConstants.GET_ACCOUNT_FAILURE,
      error: e.message,
    });
  }
}