import React, { useEffect } from "react";
import { countries, userSkills } from "../../../assets/data/data";
import { Select, Form, Button, message } from "antd";

const ReleventMentor = (props) => {
  const {
    activeTab,
    handleContinue,
    handleBack,
    menteeGoalData,
    setmenteeGoalData,
  } = props;

  const disciplines = [
    { label: "Content Writing", value: "Content Writing" },
    { label: "Data Science", value: "Data Science" },
    { label: "Design", value: "Design" },
    { label: "Marketing", value: "Marketing" },
    { label: "Product Management", value: "Product Management" },
    { label: "Product Research", value: "Product Research" },
    { label: "Software Development", value: "Software Development" },
  ];

  const tools = [
    { value: "none", label: "No specific tool needed" },
    { value: "Microsoft Office", label: "Microsoft Office Suite" },
    { value: "Adobe Creative Cloud", label: "Adobe Creative Cloud" },
    { value: "GitHub", label: "GitHub" },
    { value: "Slack", label: "Slack" },
    { value: "Zoom", label: "Zoom" },
    { value: "Google Workspace", label: "Google Workspace" },
    { value: "Asana", label: "Asana" },
    { value: "Trello", label: "Trello" },
    { value: "Jira", label: "Jira" },
    { value: "Figma", label: "Figma" },
    { value: "Sketch", label: "Sketch" },
    { value: "InVision", label: "InVision" },
    { value: "Other", label: "Other" },
  ];

  const [form] = Form.useForm();
  useEffect(() => {
    // Set the form values when the component mounts
    form.setFieldsValue({
      experties: menteeGoalData?.experties,
      specific_tools: menteeGoalData?.specific_tools,
      specific_skills: menteeGoalData?.specific_skills,
      county_like_mentor: menteeGoalData?.county_like_mentor,
    });
  }, [form, menteeGoalData]);


  const onFinish = (values) => {
    // Check if any field is empty only if the field is not present in menteeGoalData
    const isEmptyField = Object.keys(values).some((field) => {
      const isFieldEmpty = Array.isArray(values[field])
        ? values[field].length === 0
        : !values[field];

      return isFieldEmpty && !menteeGoalData?.hasOwnProperty(field);
    });

    if (isEmptyField) {
      message.error("Please fill in all the required fields!");
    } else {
      // Handle form submission logic here
      console.log("Received values:", values);
      handleContinue(); // Trigger the next card
    }
  };
  // const onFinish = (values) => {
  //   // Check if any field is empty
  //   const isEmptyField = Object.values(values).some((value) =>
  //     Array.isArray(value) ? value.length === 0 : !value
  //   );

  //   if (isEmptyField) {
  //     message.error("Please fill in all the required fields!");
  //   } else {
  //     // Handle form submission logic here
  //     console.log("Received values:", values);
  //     handleContinue(); // Trigger the next card
  //   }
  // };

  return (
    <>
      <section className="step__goal__item">
        <div className={activeTab === 1 ? "active" : "inactive"}>
          <div className="container">
            <div
              className="step__count help-cont"
              style={{ textAlign: "initial" }}
            >
              <p>STEP 2 of 3</p>
              <h4>Who is a relevant mentor for you?</h4>
            </div>
          </div>
          <Form form={form} onFinish={onFinish}>
            <div className="form-group">
              <label className="session__label">
                Which disciplines would you like to receive mentoring in?
              </label>
              <Form.Item
                name="experties"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one expertise!",
                  },
                ]}
              >
                <Select
                  mode="tags"
                  size="large"
                  placeholder="Please select"
                  defaultValue={menteeGoalData?.experties}
                  onChange={(selectedTags) => {
                    setmenteeGoalData((prevData) => ({
                      ...prevData,
                      experties: selectedTags,
                    }));
                  }}
                  style={{
                    width: "100%",
                  }}
                  options={disciplines}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <label className="session__label">
                Any specific tools you’d like help with?
              </label>
              <Form.Item
                name="specific_tools"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one tool!",
                  },
                ]}
              >
                <Select
                  mode="tags"
                  size="large"
                  placeholder="Please select"
                  defaultValue={menteeGoalData?.specific_tools}
                  onChange={(selectedTags) => {
                    setmenteeGoalData((prevData) => ({
                      ...prevData,
                      specific_tools: selectedTags,
                    }));
                  }}
                  style={{
                    width: "100%",
                  }}
                  options={tools}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <label className="session__label">
                Any specific skills you’d like help with?
              </label>
              <Form.Item
                name="specific_skills"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one skill!",
                  },
                ]}
              >
                <Select
                  mode="tags"
                  size="large"
                  placeholder="Please select"
                  defaultValue={menteeGoalData?.specific_skills}
                  onChange={(selectedTags) => {
                    setmenteeGoalData((prevData) => ({
                      ...prevData,
                      specific_skills: selectedTags,
                    }));
                  }}
                  style={{
                    width: "100%",
                  }}
                  options={userSkills}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <label className="session__label">
                Which country would you like your mentor from?
              </label>
              <Form.Item
                name="county_like_mentor"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one country!",
                  },
                ]}
              >
                <Select
                  mode="tags"
                  size="large"
                  placeholder="Please select"
                  defaultValue={menteeGoalData?.county_like_mentor}
                  onChange={(selectedTags) => {
                    setmenteeGoalData((prevData) => ({
                      ...prevData,
                      county_like_mentor: selectedTags,
                    }));
                  }}
                  style={{
                    width: "100%",
                  }}
                  options={countries}
                />
              </Form.Item>
            </div>
            <div className="get-started-cls">
              <a onClick={handleBack}>
                <Button
                  type="button"
                  className="find-btn btn btn-primary"
                >
                  Back
                </Button>
              </a>
              <Button
                htmlType="submit"
                type="button"
                className="btn btn-primary"
                disabled={form
                  .getFieldsError()
                  .some((field) => field.errors.length > 0)}
              >
                Continue
              </Button>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default ReleventMentor;
