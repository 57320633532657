import React, { useEffect, useState } from "react";
import { feedbackformSubmitDataAction } from "../../redux/actions/common";
import { useDispatch } from "react-redux";
import { ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "react-bootstrap";
import { crossIcon } from "../../assets/images/export-images";
import("./feedback-form.css");



export const FeedbackForm = ({ feedbackModal, setFeedbackModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [feedbackData, setFeedbackData] = useState({
    name: "",
    email: "",
    description: "",
  });

  

  const submitFeedback = () => {
    dispatch(feedbackformSubmitDataAction(feedbackData)).then(setTimeout(()=>{
        toast.success('Thanks for feedback!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
      });
    },500));
    
  };

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_USER_ID") ? true : false
  );

  return (
    <>      
      <Modal
        size="xl"
        centered
        show={feedbackModal.show}
        onHide={() =>
          setFeedbackModal((prevState) => ({ ...prevState, show: false }))
        }
      >
        <ModalBody>
          <button
            type="button"
            className="cloose"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              setFeedbackModal((prevState) => ({ ...prevState, show: false }))
            }
          >
            <div className="closebuttons">
              <span aria-hidden="true">
                <img src={crossIcon} />               
              </span>
            </div>
          </button>
          <div className="signup-row">
            <div className="content">
              <div className="container-feedback ">
                <div className="texts">Drop Us A Feedback</div> 
                <form action="#" className="forms">
                  <div className="form-rows" id="feedback-rows">
                    <div className="input-datas">
                      <input
                        type="texts"
                        onChange={(e) =>
                          setFeedbackData((pre) => ({
                            ...pre,
                            name: e.target.value,
                          }))
                        }
                        name="name"
                        required=""
                      />
                      <div className="underlines" />
                      <label className="label_feedback" htmlFor="">
                        Name
                      </label>
                    </div>

                    <div className="input-datas">
                      <input
                        type="texts"
                        onChange={(e) =>
                          setFeedbackData((pre) => ({
                            ...pre,
                            email: e.target.value,
                          }))
                        }
                        name="email"
                        required=""
                      />
                      <div className="underlines" />
                      <label className="label_feedback" htmlFor="">
                        Email Address
                      </label>
                    </div>
                  </div>
                  <div className="form-rows"></div>
                  <div className="form-rows">
                    <div className="input-datas textarea">
                      <textarea
                        name="description"
                        onChange={(e) =>
                          setFeedbackData((pre) => ({
                            ...pre,
                            description: e.target.value,
                          }))
                        }
                        className="text-msg"
                        rows={8}
                        cols={80}
                        required=""
                        defaultValue={""}
                      />
                      <br />
                      <div className="underlines" />
                      <label
                        className="label_feedback"
                        htmlFor=""
                        style={{ position: "relative", "margin-top": "31px" }}
                      >
                        Write your message
                      </label>
                      <br />
                      
                      <div className="get-started-cls m-0 text-left">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={submitFeedback}
                          defaultValue="submit"
                          style={{
                            padding: "0rem",
                          }}
                        >
                          <ToastContainer />
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
