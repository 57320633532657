import { Drawer, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import EmailDrawer from './EmailDrawer';

const PackageDetailDrawer = ({ packageDetailModal, setPackageDetailModal, ModalData }) => {
    const isMobile = () => {
        // Check if the userAgent contains any keywords indicating a mobile device
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    const { Paragraph, Text } = Typography;
    const columns = [
        {
            title: "Service",
            dataIndex: "title",
            width: "40%",
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "35%",
            render: (text) => {
                return text === "Not sent" ? "DM not received" : text;
            },
        },
        {
            title: "Action",
            dataIndex: ["status", "coupon_link"],
            width: "25%",
            render: (text, status) =>
                (status?.type === 1 || status?.type === 3) && status?.id ? (
                    <Paragraph
                        underline
                        className="cursor-hover"
                    >
                        <b>View Details</b>
                    </Paragraph>
                ) : (
                    <Paragraph>NA</Paragraph>
                ),
        },
    ];
    const [emailModal, setEmailModal] = useState(false)

    const [consumerList, setConsumerList] = useState([]);
    const [loadingList, setLoadingList] = useState(false);
    useEffect(() => {
        return () => {
            const getPackServices = () => {
                consumerList?.length === 0 &&
                    ModalData?.package_service_coupons?.map((book) => (


                        book?.details?.map((item) =>
                            setConsumerList((consumerList) => [
                                ...consumerList,
                                {
                                    title: book?.service?.title,
                                    serviceId: book?.service?.id,
                                    type: book?.service?.type,
                                    duration: book?.service?.duration,
                                    ...item,
                                },
                            ])
                        )
                    ))
            };

            getPackServices()
        };

    }, [ModalData, consumerList]);
    return (
        <>
            <Drawer
                title={null}
                placement={isMobile ? "right" : "bottom"}
                open={packageDetailModal}
                onClose={setPackageDetailModal}
                width={600}
                closable={false}
                height={isMobile ? "" : "75%"}
                destroyOnClose
                className="call-details-drawer"
                styles={{ body: { padding: 0 } }}
            >
                <div className="ant-drawer-body" style={{ padding: 0 }}>
                    <div className="ant-row details-header ">
                        <div className="ant-col ant-col-xs-21 ant-col-md-21 ">
                            <span className="ant-typography details-title ">
                                With {ModalData?.consumer_name}
                            </span>
                        </div>
                        <div
                            className="ant-col ant-col-xs-3 ant-col-md-2 "
                            style={{ textAlign: "end" }}
                        >

                            <svg
                                onClick={() => {
                                    setPackageDetailModal(false)
                                }}
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 512 512"
                                className="drawer-close-icon"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                            </svg>
                        </div>
                    </div>
                    <div className="ant-typography details-body ">
                        <>
                            <div className="ant-typography details-body ">
                                <div className="ant-col ant-col-24 details-subhead ">
                                    <div className="ant-typography service-name ">
                                        <span className="ant-typography service-name-main ">{ModalData.title}</span>
                                    </div>
                                </div>
                                <div className="ant-row ">
                                    <div className="ant-col call-date ant-col-xs-12 ant-col-md-8 ">
                                        <div className="ant-space  ant-space-horizontal ant-space-align-start ant-space-gap-row-small ant-space-gap-col-small">
                                            <div className="ant-space-item">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeWidth={2}
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    height={20}
                                                    width={20}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <line x1="16.5" y1="9.4" x2="7.5" y2="4.21" />
                                                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
                                                    <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
                                                    <line x1={12} y1="22.08" x2={12} y2={12} />
                                                </svg>
                                            </div>
                                            <div className="ant-space-item">{ModalData?.service_count}</div>
                                            <div className="ant-space-item"> services</div>
                                        </div>
                                    </div>
                                    <div className="ant-col email-btn ant-col-xs-12 ant-col-md-16 ">
                                        <button
                                            onClick={() => {
                                                setEmailModal(true)
                                            }}
                                            type="button"
                                            className="ant-btn  ant-btn-default btn-call-action"
                                        >
                                            <span>Send Email</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="ant-typography details-attendee-title ">
                                    Attendee details
                                </div>
                                <div className="ant-typography ">
                                    <div className="ant-row ">
                                        <div className="ant-col call-date ant-col-xs-24 ant-col-md-12 ">
                                            <div className="ant-space  ant-space-horizontal ant-space-align-start ant-space-gap-row-small ant-space-gap-col-small">
                                                <div className="ant-space-item">
                                                    <svg
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeWidth={2}
                                                        viewBox="0 0 24 24"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        height={20}
                                                        width={20}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                                        <polyline points="22,6 12,13 2,6" />
                                                    </svg>
                                                </div>
                                                <div className="ant-space-item">{ModalData?.consumer_email}</div>
                                            </div>
                                        </div>
                                        <div className="ant-col call-date call-phone-details ant-col-xs-24 ant-col-md-12 ">
                                            <div className="ant-space  ant-space-horizontal ant-space-align-start ant-space-gap-row-small ant-space-gap-col-small">
                                                <div className="ant-space-item">
                                                    <svg
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeWidth={2}
                                                        viewBox="0 0 24 24"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        rotate={180}
                                                        height={20}
                                                        width={20}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                                                    </svg>
                                                </div>
                                                <div className="ant-space-item">{ModalData?.consumer_phone}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ant-typography details-attendee-title ">
                                Details
                            </div>
                            <Table
                                columns={columns}
                                dataSource={consumerList}
                                loading={loadingList}
                                pagination={false}
                                scroll={isMobile ? { y: 240, x: 600 } : { y: 240 }}
                                bordered
                                className="drawer-table"
                            />
                        </>


                    </div>
                </div>

            </Drawer>
            {
                emailModal && (
                    <EmailDrawer emailModal={emailModal} setEmailModal={setEmailModal} ModalData={ModalData} />
                )
            }
        </>
    );
}

export default PackageDetailDrawer;
