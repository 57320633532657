import React from "react";
import { useState } from "react";
import "./UpdatePrice.css";

const UpdatePrice = ({ priceData, getUpdatedValues }) => {
  const [priceTxt, setPriceTxt] = useState(parseFloat(priceData));
  const handleOnChange = (e) => {
    // if (
    //   e.target.value.match("([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|1000)") != null
    // ) {
    setPriceTxt(parseFloat(e.target.value));
    // }
  };
  const handleOnClick = () => {
    let tempData = { price_per_session: priceTxt };
    getUpdatedValues(tempData);
  };
  return (
    <div className="update-price-main-container">
      <h7>Price</h7>
      <input
        className="price-input"
        placeholder="Price in $"
        value={priceTxt}
        onChange={handleOnChange}
      ></input>
      <button onClick={handleOnClick} className="update-btn-price">
        Update
      </button>
    </div>
  );
};

export default UpdatePrice;
