import React, { useEffect } from "react";
import Activity from "./Activity";
import { useState } from "react";
import Notes from "./notes";
import Task from "./task";
import Meeting from "./meeting";
import ChatModule from "../ChatModule";
import { useDispatch, useSelector } from "react-redux";
import { getConnectionListAction } from "../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import AllActiveSession from "../adminDashboard/MentorshipSession/AllActiveSession";
import PrivateSession from "../adminDashboard/MentorshipSession/AllActiveSession/PrivateSession";
import MentorHome from "./mentorHome";
import("../MentorDashboard/menorDashboard.css");

const SearchInput = (props) => {
  const { userDetails } = props;
  console.log("searachInput Box userDeatils", userDetails);

  const [activeComponent, setActiveComponent] = useState("home");

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConnectionListAction());
  }, []);

  const ConnectionListData = useSelector(
    (state) => state.getConnectionListReducer?.data
  );
  const isLoading = useSelector(
    (state) => state.getConnectionListReducer?.loading
  );

  const renderComponent = () => {
    switch (activeComponent) {
      case "home":
        return <MentorHome ConnectionListData={ConnectionListData} />;
      case "activity":
        return <Activity ConnectionListData={ConnectionListData} />;
      case "notes":
        return <Notes ConnectionListData={ConnectionListData} />;
      case "task":
        return <Task ConnectionListData={ConnectionListData} />;
      case "meeting":
        return <Meeting />;
      // return <PrivateSession />;
      case "chat":
        return (
          <ChatModule
            userDetails={userDetails}
            ConnectionListData={ConnectionListData}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      {/* <div className="d-flex top_searchbox " style={{ margin: "auto .7rem" }}>
        <svg
					xmlns="http://www.w3.org/2000/svg"
					width="17"
					height="17"
					fill="currentColor"
					class="bi bi-search mt-1"
					viewBox="0 0 16 16"
				>
					<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
				</svg>
				<input
					type="search"
					className=""
					id="datatable-search-input"
					placeholder="  Search activity, notes, email."
					style={{
						border: "none",
						backgroundColor: "inherit",
						width: "inherit",
						fontSize: "13px",
						color: "rgba(158, 155, 155, 0.562)",
					}}
				/>
      </div> */}

      <div className=" Search__btn mt-2">
        {/* <button
					type="button"
					className={`btn search____btn ${
						activeComponent === "activity" ? "active_btn" : ""
					}`}
					onClick={() => handleButtonClick("activity")}
				>
					Activity
				</button> */}
        <button
          type="button"
          className={`btn search____btn ${
            activeComponent === "home" ? "active_btn" : ""
          }`}
          onClick={() => handleButtonClick("home")}
        >
          Home
        </button>
        {/* <button
          type="button"
          className={`btn search____btn ${
            activeComponent === "notes" ? "active_btn" : ""
          }`}
          onClick={() => handleButtonClick("notes")}
        >
          Notes
        </button> */}
        <button
          type="button"
          className={`btn search____btn ${
            activeComponent === "task" ? "active_btn" : ""
          }`}
          onClick={() => handleButtonClick("task")}
        >
          Task
        </button>
        {/* <button
          type="button"
          className={`btn py-2 search____btn ${
            activeComponent === "meeting" ? "active_btn" : ""
          }`}
          onClick={() => handleButtonClick("meeting")}
        >
          Meeting
        </button> */}
        <button
          type="button"
          className={`btn py-2 search____btn ${
            activeComponent === "chat" ? "active_btn" : ""
          }`}
          onClick={() => handleButtonClick("chat")}
        >
          Messenger
        </button>
      </div>
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div className="render___container">{renderComponent()}</div>
      )}
    </>
  );
};

export default SearchInput;
