import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import successimg from "../../assets/images/success.png";
import { signUpVerify } from "../../redux/actions/login";
import wrong from "../../assets/images/wentwrong.png";
import { useParams } from "react-router-dom";

import("./verify.css");

const EmailVerify = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [success, setSuccess] = useState(false);

  const signUpVerifyReducerState = useSelector(
    (state) => state.signUpVerifyReducer
  );
  const status = signUpVerifyReducerState?.status;
  const loading = signUpVerifyReducerState?.loading;
  const errorSignUp = signUpVerifyReducerState?.headers?.["x-error-msg"];



  const { code } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (status === 200) {
      setSuccess(true);
    }
  }, [status]);

  useEffect(() => {
    if (!loading) {
      setLoader(false);
    }
  }, [loading]);

  const set = useRef(true);

  useEffect(() => {
    if (set.current) {
      set.current = false;
      apicall();
    }
  }, []);

  const apicall = () => {
    const data = {
      code: code,
    };

    dispatch(signUpVerify(data));
  };

  return (
    <Fragment>
      {loader ? (
        <div className="loader" style={{ zIndex: 9999 }}>
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <>
          {success ? (
            <div className="Suceess">
              <img
                src={successimg}
                alt="success.img"
                className="success_tick"
              />
              <div className="header-design">
                <h1>Your email is verified!!!</h1>
                <div className="sub-header">
                  <h2> You can login here.</h2>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primaryyy"
                data-bs-toggle="button"
                onClick={() => navigate('/')}
              >
                Login
              </button>
            </div>
          ) : (
            <div className="Failure">
              <img src={wrong} alt="success.img" className="error" />
              <div className="header2-design">
                <div>{errorSignUp}</div>
                <div className="sub-header2">
                  <p> Sorry,Something went wrong.Try again.</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Fragment>
  );
};
export default EmailVerify;
