import React from "react";
// import achievement from "../../assets/images/achievement.jpg"
import Affiliation from "../../assets/images/affiliction.jpg";
import ringtoss from "../../assets/images/ringtoss.jpg";
import Extenstion from "../../assets/images/Extenstion.jpg";
import Achievement2 from "../../assets/images/Achivement2.jpg";
import Amttraning from "../../assets/images/amt-traning.jpg";
import Power from "../../assets/images/Power.jpg";
import mentorpal from "../../assets/images/banner.png";
//  import DashboardHeader from "../adminDashboard/DashboardHeader";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";

import Footer from "../../components/footer";

import "../AMTLAB/amtlab.css";

function Amtlab() {
  return (
    <>
       <ProtectedHeader/> 
      <div className="container-fluid " id="mentorpal-background">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <section className="Frist-section-achievement">
                <h2 className="heading-achievement">
                  Train Your Employees or Individuals with EMT/AMT
                  Entrepreneurship Achievement Motivation Training Lab
                </h2>
                <p className="paragraf-amt-emt">
                  Are you looking for a way to train your employees or
                  individual to be more motivated and successful in their
                  careers? If so, our training lab can help! We offer a
                  comprehensive curriculum that will teach your employees or
                  individuals everything they need to know about achievement
                  motivation
                </p>
              </section>
            </div>
            <div className="col-lg-6 col-md-6" id="formcolumn-equel">
              <div className="forform">
                <h5 className="formheading">
                  Request a callback to start your journey with Mentorpal!
                </h5>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Name
                  </label>
                  <input
                    type="name"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="name"
                  />
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                  />
                </div>

                {/* Graduation Year Selector      */}
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Graduation Year
                </label>
                <select className="form-select" aria-label="">
                  Graduation Year
                  <option selected="">Select Your Graduation Year</option>
                  <option value={1}>2011</option>
                  <option value={2}>2012</option>
                  <option value={3}>2013</option>
                  <option value={4}>2014</option>
                  <option value={5}>2015</option>
                  <option value={6}>2016</option>
                  <option value={7}>2017</option>
                  <option value={8}>2018</option>
                  <option value={9}>2019</option>
                  <option value={10}>2020</option>
                  <option value={11}>2021</option>
                  <option value={12}>2022</option>
                  <option value={13}>2023</option>
                  <option value={14}>2024</option>
                  <option value={15}>2025</option>
                </select>
                {/* Job Title input      */}
                <div className="mb-3 mt-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Enter Your Present Job Title
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Job Title"
                  />
                </div>
                <div className="mb-1 mt-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Enter Your Mobile Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Mobile Number"
                  />
                  <span
                    id="Mobile Number:HelpBlock"
                    className="mt-0 mb-0 otp form-text text-muted"
                  >
                    You'll receive an otp on this number for verification.
                  </span>
                </div>
                <div className="mt-1 me-auto">
                  <center class="pt-2">
                    <button
                      name="submit"
                      type="submit"
                      className="continueprocess "
                    >
                      Continue
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="what-is-amt">
            
              How does Entrepreneurship Achievement Motivation Training
              (EMT/AMT) work?
            </h2>
            <p className="paragraf-achievement">
              EMT/AMT is a training program that helps individuals develop the
              skills and motivation they need to be successful in business.
              EMT/AMT helps individuals to identify their strengths and
              weaknesses, set goals, and develop a plan to achieve their goals.
              The program also teaches individuals how to overcome obstacles,
              build self-confidence, and manage their time effectively.
              </p>
              <p className="paragraf-achievement">
              Achievement motivation training is a type of training that helps
              people develop the drive and ambition to succeed. It typically
              covers topics such as goal setting, self-confidence, and
              overcoming obstacles. It also covers topics of Organizational
              development training(OD) and Corporate training also.
              </p>
              <p className="paragraf-achievement">
              Organizational development training is a type of training that
              helps organizations improve their performance. It typically covers
              topics such as communication, teamwork, and problem-solving.
              </p>
              <p className="paragraf-achievement">
              Corporate training is a type of training that is provided by
              businesses to their employees. It typically covers topics that are
              relevant to the business's industry or operations. All of these
              types of training can be valuable for individuals and
              organizations. They can help people develop the skills and
              knowledge they need to succeed in their personal and professional
              lives.
            </p>
            </div>
            </div>
            <div className="row">
          <div className="col-md-12  image-orientation">
          <img src={Amttraning} alt="" />
          </div>
          </div>

            <h2 className="what-is-amt">Who is EMT/AMT for?</h2>
            <p className="paragraf-achievement">
              EMT/AMT is for anyone who wants to be more successful in business.
              The program is ideal for individuals who are starting their own
              businesses, looking to advance in their current careers, or simply
              want to improve their personal and professional development.
            </p>
            <h2 className="what-is-amt">What are the benefits of EMT/AMT?</h2>
            <p className="paragraf-achievement">
            <ul>The benefits of EMT/AMT include: 
            <li>  Increased motivation</li>
            <li>   Improved self-confidence </li>
            <li>  Increased ability to set and achieve goals</li>
            <li> Enhanced problem-solving skills </li>
            <li> Improved time management skills</li>
            <li> Increased ability to build relationships</li>
            <li>  Enhanced communication  skills </li>
            <li>  Increased creativity and innovation</li>
               </ul> 
               <p className="paragraf-achievement">
              Our training lab is comprehensive and covers all the essential
              skills needed to succeed in the workplace. Our training lab is
              taught by experienced professionals who are passionate about
              helping their employees succeed. Our training lab offers
              one-on-one coaching and support to help students/employees learn
              at their own pace. Our training lab is affordable and accessible
              to everyone.
            </p>
            </p>
            <h2 className="what-is-amt">
              Our training lab includes modules on:
            </h2>
            <p className="paragraf-achievement">
             <ul> The Three needs of achievement motivation:
             <li>How to set and achieve  goals </li> 
             <li> How to overcome obstacles </li> 
             <li> How to build self-confidence</li> 
               And more!
               We also offer one-on-one coaching and support from our experienced staff.
              </ul>
              </p>

            <h2 className="what-is-amt">
              Achievement Motivation Training Program Overview
            </h2>
            <h3>
              What are the four main motives to understand achievement
              motivation?
            </h3>
         
      

        <div className="row pb-5 ">
          <div className=" col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div
              className="card"
              style={{
                width: "15.5rem",
                height: "17rem",
                marginBottom: "10px",
                backgroundColor: "#fbf9f9",
                padding: "10px",
                boxSizing: "border-box",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <div className="card-body">
                <h5 className="card-title"> Power motivation </h5>
                <p className="card-text">
                  Power motivation is the inner drive or desire to gain
                  influence, control, or authority over others, and to have an
                  impact on the world around us.
                </p>
              </div>
            </div>
          </div>
          <div className=" col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div
              className="card"
              style={{
                width: "15.5rem",
                height: "17rem",
                marginBottom: "10px",
                backgroundColor: "#fbf9f9",
                padding: "10px",
                boxSizing: "border-box",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <div className="card-body">
                <h5 className="card-title"> Achievement motivation </h5>
                <p className="card-text">
                  Achievement motivation is the inner drive or desire to set and
                  achieve difficult goals, to continuously enhance performance,
                  and to be successful in one's chosen pursuits.
                </p>
              </div>
            </div>
          </div>
          <div className=" col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div
              className="card"
              style={{
                width: "15.5rem",
                height: "17rem",
                marginBottom: "10px",
                backgroundColor: "#fbf9f9",
                padding: "10px",
                boxSizing: "border-box",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <div className="card-body">
                <h5 className="card-title"> Affiliation motivation </h5>
                <p className="card-text">
                  Affiliation motivation is the inner drive or desire to
                  establish and maintain positive relationships with others, to
                  feel a sense of belonging, and to seek out social interactions
                  and support.
                </p>
              </div>
            </div>
          </div>
          <div className=" col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div
              className="card"
              style={{
                width: "15.5rem",
                height: "17rem",
                marginBottom: "10px",
                backgroundColor: "#fbf9f9",
                padding: "10px",
                boxSizing: "border-box",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <div className="card-body">
                <h5 className="card-title"> Extension motivation </h5>
                <p className="card-text">
                  An extension can be a great motivation for many people as it
                  gives them more time to complete tasks or projects that they
                  may have been struggling with.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h3> What is your main orientation? and their signs </h3>
        <p className="paragraf-achievement"> 1. Achievement Orientation </p>
        <p className="paragraf-achievement"> 2. Power Orientation </p>
        <p className="paragraf-achievement">
          {" "}
          3. Affiliation Motivation Orientation
        </p>
        <p className="paragraf-achievement"> 4. Extension Orientation </p>

        <div className="row frist-orientation">
          <div className="col-md-6 text-orientation">
            <h3> 1. High Achievement Orientation:</h3>
            <p className="paragraf-achievement">
              Individuals with a high achievement orientation are typically
              driven by a desire to succeed and excel in their endeavors. Some
              signs of high achievement orientation include setting challenging
              goals for oneself, striving to constantly improve and learn,
              seeking feedback and recognition for one's accomplishments, and
              being highly motivated to achieve success.
            </p>
          </div>
          <div className="col-md-6  image-orientation">
            <img src={Achievement2} alt="" />
          </div>
        </div>
        <div className="row frist-orientation">
          <div className="col-md-6   text-orientation">
            <h3> 2. Power Orientation:</h3>
            <p className="paragraf-achievement">
              Individuals with a power orientation are typically focused on
              acquiring and exercising influence, control, and authority over
              others. Some signs of power orientation include a desire to lead
              and take charge, seeking out positions of authority and
              responsibility, being assertive and confident in one's
              decision-making, and enjoying the competition and winning.
            </p>
          </div>
          <div className="col-md-6  image-orientation">
            <img src={Power} alt="" />
          </div>
        </div>

        <div className="row frist-orientation">
          <div className="col-md-6 text-orientation">
            <h3>3. Affiliation Orientation:</h3>
            <p className="paragraf-achievement">
              {" "}
              Individuals with an affiliation orientation are typically focused
              on building and maintaining relationships with others. Some signs
              of affiliation orientation include a desire for social connections
              and closeness, being highly attuned to the emotions and needs of
              others, valuing teamwork and cooperation, and seeking out
              opportunities for collaboration and social interaction.
            </p>
          </div>
          <div className="col-md-6  image-orientation">
            <img src={Affiliation} alt="" />
          </div>
        </div>
        <div className="row frist-orientation">
          <div className="col-md-6   text-orientation">
            <h3> 4.Extension Orientation:</h3>
            <p className="paragraf-achievement">
              Individuals with an extension orientation are typically focused on
              making a positive impact on the world around them. Some signs of
              extension orientation include a desire to contribute to society or
              a cause, being highly motivated to help others, seeking out
              opportunities to make a difference or create change, and valuing
              social responsibility and ethical behavior.
            </p>
          </div>
          <div className="col-md-6 image-orientation">
            <img src={Extenstion} alt="" />
          </div>
        </div>

        <h2>What are the signs of a high achiever person?</h2>
        <h3>Achievement Imagery (AI)</h3>
        <p className="paragraf-achievement">
          Achievement Imagery (AI) is a concept developed by psychologist David
          McClelland, which refers to the mental images or fantasies of
          successful achievement that individuals use to motivate themselves to
          strive for success. McClelland believed that these mental images
          played a significant role in driving achievement-oriented behavior, as
          they help individuals set high goals for themselves and maintain a
          strong sense of commitment and determination in pursuit of those
          goals. He also suggested that AI could be developed and strengthened
          through training and practice, and that it could be used to help
          individuals overcome obstacles and setbacks in pursuit of their goals.
        </p>

        <h3>Thematic Apperception Test (TAT)</h3>
        <p className="paragraf-achievement">
          The Thematic Apperception Test (TAT) is a projective psychological
          test that involves interpreting a series of ambiguous pictures to
          reveal a person's unconscious thoughts, desires, and motivations.
        </p>
        <div className="row">
          <div className="col-md-6  text-orientation">
            <h2> Organize games in the micro lab </h2>
            <p className="paragraf-achievement">
              1.Ring Toss -for test goal setting and risk-taking behavior
            </p>
            <p className="paragraf-achievement">
              2. Moon tent or boat-making exercise for Achievement planning
              orientation (APO)
            </p>
            <p className="paragraf-achievement">
              3. Tower building exercise for test dexterity and teamwork
            </p>
          </div>
          <div className="col-md-6 image-orientation">
            <img src={ringtoss} alt="" />
          </div>
        </div>

        <p className="paragraf-achievement">
          We Compare the Entry behavior and exit behavior of the participants in
          AMT Lab.
        </p>
        <p className="paragraf-achievement">
          We provide Feedback for surviving newly learned Achievement Motivation
          Behave for the participants.
        </p>
        <p className="program-director"> Program Director </p>
        <p className="director"> Brijlal Yogi </p>
        <p className="paragraf-achievement">
          {" "}
          Accredited Master Trainer (AMT/EMT)by NIESBUD Ministry of skill
          development govt of India
        </p>
        <a href=""> Please contact us if you have any queries or questions  </a>
        <a href=" https://help@mentorpal.ai" target="_blank"> Please contact us if you have any queries or questions</a>
      </div>
      <Footer />
    </>
  );
}
export default Amtlab;
