import React from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { triggerOpenNav } from '../../../../redux/actions/course';

const Certificate = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const handleTriggerOpenNavFromB = () => {
        dispatch(triggerOpenNav()); // Dispatch the action to trigger openNav
    };
    return (
        <>
            <section id="Header">
                <div className="header ">
                    <span
                        className='mobile_viewCourse'
                        style={{ fontSize: '30px', cursor: 'pointer' }}
                        onClick={handleTriggerOpenNavFromB}
                    >
                        &#9776;
                    </span>
                    <div
                        className="ctitle"
                        style={{ maxWidth: 1872 }}
                    >
                        Certificated
                    </div>

                 

                </div>

            </section>
            <section>
                {/* <div className="container">
                    <div className="text-center">
                        <h1 className="text-center">
                            Information Page
                        </h1>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <div className="card border-0 ">
                            <div className="card-header header-table tab-header">
                                <ul
                                    className="nav nav-tabs"
                                    role="tablist"
                                    style={{ display: "flex", flexDirection: "row" }}
                                >
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            data-toggle="tab"
                                            href="#tabs-1"
                                            role="tab"
                                            onClick={() => navigate("")}
                                        >
                                            Details
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a
                                            className="nav-link "
                                            data-toggle="tab"
                                            href="#tabs-1"
                                            role="tab"
                                            onClick={() => navigate("issued")}
                                        >
                                            Issued(0)
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a
                                            className="nav-link "
                                            data-toggle="tab"
                                            href="#tabs-1"
                                            role="tab"
                                            onClick={() => navigate("blacklist")}
                                        >
                                            Blacklist(0)
                                        </a>
                                    </li>
                                   
                                </ul>
                            </div>
                            <div className="card-body table-inner">
                                <div className="tab-content">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Certificate;
