import { call, put } from "redux-saga/effects";
import { createPaymentLinkCall, orderIdCall } from "../../network";
import { createPaymentLinkConstants, orderConstants } from "../../constants";

export function* orderIdSaga(action) {
  try {
    const response = yield call(orderIdCall, action.data);
    yield put({
      type: orderConstants.GENERATE_ORDER_ID_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: orderConstants.GENERATE_ORDER_ID_FAILURE,
      error: e.message,
    });
  }
}
export function* createPaymentLinkSaga(action) {
  try {
    const response = yield call(createPaymentLinkCall, action.data);
    yield put({
      type: createPaymentLinkConstants.CREATE_PAYMENT_LINK_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: createPaymentLinkConstants.CREATE_PAYMENT_LINK_FAILURE,
      error: e.message,
    });
  }
}
// export function* executePaymentSaga(action) {
//     try {
//         const response = yield call(userDetailsCall, action.data);

//         yield put({
//             type: userDetailsConstants.USER_DETAILS_SUCCESS,
//             data: response.data,
//             error: response.error,
//             message: response.message,
//             headers: response.headers,
//         });
//     } catch (e) {
//         yield put({
//             type: userDetailsConstants.USER_DETAILS_FAILURE,
//             error: e.message,
//             headers: e.headers,
//         });
//     }
// }
