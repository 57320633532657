export const otpConstants = {
  GENERATE_OTP_REQUEST: "GENERATE_OTP_REQUEST",
  GENERATE_OTP_SUCCESS: "GENERATE_OTP_SUCCESS",
  GENERATE_OTP_FAILURE: "GENERATE_OTP_FAILURE",
};

export const emailConstants = {
  VERIFY_REQUEST: "VERIFY_REQUEST",
  VERIFY_SUCCESS: "VERIFY_SUCCESS",
  VERIFY_FAILURE: "VERIFY_FAILURE",
};

export const loginConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
};
export const logoutConstants = {
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
};
export const findMentorConstants = {
  FIND_MENTOR_REQUEST: "FIND_MENTOR_REQUEST",
  FIND_MENTOR_SUCCESS: "FIND_MENTOR_SUCCESS",
  FIND_MENTOR_FAILURE: "FIND_MENTOR_FAILURE",
};

export const viewMentorProfileConstants = {
  VIEW_MENTOR_PROFILE_REQUEST: "VIEW_MENTOR_PROFILE_REQUEST",
  VIEW_MENTOR_PROFILE_SUCCESS: "VIEW_MENTOR_PROFILE_SUCCESS",
  VIEW_MENTOR_PROFILE_FAILURE: "VIEW_MENTOR_PROFILE_FAILURE",
};

export const passwordLoginConstants = {
  PASSWORD_LOGIN_REQUEST: "PASSWORD_LOGIN_REQUEST",
  PASSWORD_LOGIN_SUCCESS: "PASSWORD_LOGIN_SUCCESS",
  PASSWORD_LOGIN_FAILURE: "PASSWORD_LOGIN_FAILURE",
};
export const signUpConstants = {
  SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
};

export const socialLoginConstants = {
  SOCIAL_LOGIN_REQUEST: "SOCIAL_LOGIN_REQUEST",
  SOCIAL_LOGIN_SUCCESS: "SOCIAL_LOGIN_SUCCESS",
  SOCIAL_LOGIN_FAILURE: "SOCIAL_LOGIN_FAILURE",
};

export const updateProfileConstants = {
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
};

export const signUpVerifyConstants = {
  SIGNUP_VERIFY_REQUEST: "SIGNUP_VERIFY_REQUEST",
  SIGNUP_VERIFY_SUCCESS: "SIGNUP_VERIFY_SUCCESS",
  SIGNUP_VERIFY_FAILURE: "SIGNUP_VERIFY_FAILURE",
};
export const createMentorConstants = {
  CREATE_MENTOR_REQUEST: "CREATE_MENTOR_REQUEST",
  CREATE_MENTOR_SUCCESS: "CREATE_MENTOR_SUCCESS",
  CREATE_MENTOR_FAILURE: "CREATE_MENTOR_FAILURE",
};

export const forgetPasswordConstants = {
  FORGET_PASSWORD_REQUEST: "FORGET_PASSWORD_REQUEST",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_FAILURE: "FORGET_PASSWORD_FAILURE",
};

export const userDetailsConstants = {
  USER_DETAILS_REQUEST: "USER_DETAILS_REQUEST",
  USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
  USER_DETAILS_FAILURE: "USER_DETAILS_FAILURE",
};

export const createFavoriteMentorsConstants = {
  CREATE_FAVORITE_MENTOR_REQUEST: "CREATE_FAVORITE_MENTOR_REQUEST",
  CREATE_FAVORITE_MENTOR_SUCCESS: "CREATE_FAVORITE_MENTOR_SUCCESS",
  CREATE_FAVORITE_MENTOR_FAILURE: "CREATE_FAVORITE_MENTOR_FAILURE",
};
export const removeFavoriteMentorsConstants = {
  REMOVE_FAVORITE_MENTOR_REQUEST: "REMOVE_FAVORITE_MENTOR_REQUEST",
  REMOVE_FAVORITE_MENTOR_SUCCESS: "REMOVE_FAVORITE_MENTOR_SUCCESS",
  REMOVE_FAVORITE_MENTOR_FAILURE: "REMOVE_FAVORITE_MENTOR_FAILURE",
};

export const favoriteMentorsConstants = {
  FAVORITE_MENTOR_REQUEST: "FAVORITE_MENTOR_REQUEST",
  FAVORITE_MENTOR_SUCCESS: "FAVORITE_MENTOR_SUCCESS",
  FAVORITE_MENTOR_FAILURE: "FAVORITE_MENTOR_FAILURE",
  REMOVE_FAVORITE_MENTOR: "REMOVE_FAVORITE_MENTOR",
};

export const subscribedMentorsConstants = {
  SUBSCRIBED_MENTOR_REQUEST: "SUBSCRIBED_MENTOR_REQUEST",
  SUBSCRIBED_MENTOR_SUCCESS: "SUBSCRIBED_MENTOR_SUCCESS",
  SUBSCRIBED_MENTOR_FAILURE: "SUBSCRIBED_MENTOR_FAILURE",
};

export const similarMentorsConstants = {
  SIMILAR_MENTOR_REQUEST: "SIMILAR_MENTOR_REQUEST",
  SIMILAR_MENTOR_SUCCESS: "SIMILAR_MENTOR_SUCCESS",
  SIMILAR_MENTOR_FAILURE: "SIMILAR_MENTOR_FAILURE",
};

export const orderConstants = {
  GENERATE_ORDER_ID_REQUEST: "GENERATE_ORDER_ID_REQUEST",
  GENERATE_ORDER_ID_SUCCESS: "GENERATE_ORDER_ID_SUCCESS",
  GENERATE_ORDER_ID_FAILURE: "GENERATE_ORDER_ID_FAILURE",
};

export const paymentConstants = {
  PAYMENT_REQUEST: "PAYMENT_REQUEST",
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  PAYMENT_FAILURE: "PAYMENT_FAILURE",
};

export const mentorSessionServiceConstants = {
  SESSION_TYPE_REQUEST: "SESSION_TYPE_REQUEST",
  SESSION_DETAILS_REQUEST: "SESSION_DETAILS_REQUEST",
  SESSION_AVAILABILITY_REQUEST: "SESSION_AVAILABILITY_REQUEST",
  SESSION_SERVICES_REQUEST: "SESSION_SERVICES_REQUEST",
  SESSION_SERVICE_FORM_DATA_REQUEST: "SESSION_SERVICE_FORM_DATA_REQUEST",
  SESSION_HELP_MENTEE_REQUEST: "SESSION_HELP_MENTEE_REQUEST",
  SESSION_MILESTONE_REQUEST: "SESSION_MILESTONE_REQUEST",
};

export const createMentorSessionServiceConstants = {
  CREATE_MENTOR_SESSION_SERVICE_REQUEST:
    "CREATE_MENTOR_SESSION_SERVICE_REQUEST",
  CREATE_MENTOR_SESSION_SERVICE_SUCCESS:
    "CREATE_MENTOR_SESSION_SERVICE_SUCCESS",
  CREATE_MENTOR_SESSION_SERVICE_FAILURE:
    "CREATE_MENTOR_SESSION_SERVICE_FAILURE",
};

export const publishEbookConstants = {
  PUBLISH_EBOOK_REQUEST: "PUBLISH_EBOOK_REQUEST",
  PUBLISH_EBOOK_SUCCESS: "PUBLISH_EBOOK_SUCCESS",
  PUBLISH_EBOOK_FAILURE: "PUBLISH_EBOOK_FAILURE",
};

export const mentorProductsConstants = {
  MENTOR_PRODUCTS_REQUEST: "MENTOR_PRODUCTS_REQUEST",
  MENTOR_PRODUCTS_SUCCESS: "MENTOR_PRODUCTS_SUCCESS",
  MENTOR_PRODUCTS_FAILURE: "MENTOR_PRODUCTS_FAILURE",
};
export const createProductConstants = {
  CREATE_PRODUCTS_REQUEST: "CREATE_PRODUCTS_REQUEST",
  CREATE_PRODUCTS_SUCCESS: "CREATE_PRODUCTS_SUCCESS",
  CREATE_PRODUCTS_FAILURE: "CREATE_PRODUCTS_FAILURE",
};

export const mentorEbooksConstants = {
  MENTOR_EBOOKS_REQUEST: "MENTOR_EBOOKS_REQUEST",
  MENTOR_EBOOKS_SUCCESS: "MENTOR_EBOOKS_SUCCESS",
  MENTOR_EBOOKS_FAILURE: "MENTOR_EBOOKS_FAILURE",
};

export const mentorTotalSubscriptionConstants = {
  MENTOR_TOTAL_SUBSCRIPTION_REQUEST: "MENTOR_TOTAL_SUBSCRIPTION_REQUEST",
  MENTOR_TOTAL_SUBSCRIPTION_SUCCESS: "MENTOR_TOTAL_SUBSCRIPTION_SUCCESS",
  MENTOR_TOTAL_SUBSCRIPTION_FAILURE: "MENTOR_TOTAL_SUBSCRIPTION_FAILURE",
};

export const updateUserPasswordConstants = {
  UPDATE_USER_PASSWORD_REQUEST: "UPDATE_USER_PASSWORD_REQUEST",
  UPDATE_USER_PASSWORD_SUCCESS: "UPDATE_USER_PASSWORD_SUCCESS",
  UPDATE_USER_PASSWORD_FAILURE: "UPDATE_USER_PASSWORD_FAILURE",
};

export const openSessionsConstants = {
  OPEN_SESSIONS_REQUEST: "OPEN_SESSIONS_REQUEST",
  OPEN_SESSIONS_SUCCESS: "OPEN_SESSIONS_SUCCESS",
  OPEN_SESSIONS_FAILURE: "OPEN_SESSIONS_FAILURE",
};
export const topMentorsConstants = {
  TOP_MENTOR_REQUEST: "TOP_MENTOR_REQUEST",
  TOP_MENTOR_SUCCESS: "TOP_MENTOR_SUCCESS",
  TOP_MENTOR_FAILURE: "TOP_MENTOR_FAILURE",
};
export const mentorActiveSubscriptionConstants = {
  MENTOR_ACTIVE_SUBSCRIPTION_REQUEST: "MENTOR_ACTIVE_SUBSCRIPTION_REQUEST",
  MENTOR_ACTIVE_SUBSCRIPTION_SUCCESS: "MENTOR_ACTIVE_SUBSCRIPTION_SUCCESS",
  MENTOR_ACTIVE_SUBSCRIPTION_FAILURE: "MENTOR_ACTIVE_SUBSCRIPTION_FAILURE",
};
export const mentorSubscriptionRequestsConstants = {
  MENTOR_SUBSCRIPTION_REQUESTS_REQUEST: "MENTOR_SUBSCRIPTION_REQUESTS_REQUEST",
  MENTOR_SUBSCRIPTION_REQUESTS_SUCCESS: "MENTOR_SUBSCRIPTION_REQUESTS_SUCCESS",
  MENTOR_SUBSCRIPTION_REQUESTS_FAILURE: "MENTOR_SUBSCRIPTION_REQUESTS_FAILURE",
};

export const googleCalanderConstants = {
  GENERATE_AUTH_LINK_REQUEST: "GENERATE_AUTH_LINK_REQUEST",
  GENERATE_AUTH_LINK_REQUEST_SUCCESS: "GENERATE_AUTH_LINK_REQUEST_SUCCESS",
  GENERATE_AUTH_LINK_REQUEST_FAILURE: "GENERATE_AUTH_LINK_REQUEST_FAILURE",
  HANDLE_GOOGLE_REDIRECT_REQUEST: "HANDLE_GOOGLE_REDIRECT_REQUEST",
  HANDLE_GOOGLE_REDIRECT_REQUEST_SUCCESS:
    "HANDLE_GOOGLE_REDIRECT_REQUEST_SUCCESS",
  HANDLE_GOOGLE_REDIRECT_REQUEST_FAILURE:
    "HANDLE_GOOGLE_REDIRECT_REQUEST_FAILURE",
};

export const commonConstants = {
  UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
  UPLOAD_IMAGE_REQUEST_SUCCESS: "UPLOAD_IMAGE_REQUEST_SUCCESS",
  UPLOAD_IMAGE_REQUEST_FAILURE: "UPLOAD_IMAGE_REQUEST_FAILURE",
  UPLOAD_IMAGE_FILE_REQUEST: "UPLOAD_IMAGE_FILE_REQUEST",
  UPLOAD_IMAGE_FILE_REQUEST_SUCCESS: "UPLOAD_IMAGE_FILE_REQUEST_SUCCESS",
  UPLOAD_IMAGE_FILE_REQUEST_FAILURE: "UPLOAD_IMAGE_FILE_REQUEST_FAILURE",
};

export const availableDayConstants = {
  AVAILABLE_DAY_REQUEST: "AVAILABLE_DAY_REQUEST",
  AVAILABLE_DAY_SUCCESS: "AVAILABLE_DAY_SUCCESS",
  AVAILABLE_DAY_FAILURE: "AVAILABLE_DAY_FAILURE",
};

export const availableTimeConstants = {
  AVAILABLE_TIME_REQUEST: "AVAILABLE_TIME_REQUEST",
  AVAILABLE_TIME_SUCCESS: "AVAILABLE_TIME_SUCCESS",
  AVAILABLE_TIME_FAILURE: "AVAILABLE_TIME_FAILURE",
};

export const feedbackConstants = {
  FEEDBACK_SUBMIT_REQUEST: "FEEDBACK_SUBMIT_REQUEST",
  FEEDBACK_SUBMIT_SUCCESS: "FEEDBACK_SUBMIT_SUCCESS",
  FEEDBACK_SUBMIT_FAILURE: "FEEDBACK_SUBMIT_FAILURE",
};

export const mentorAvailableHoursConstants = {
  MENTOR_AVAILABLE_HOURS_REQUEST: "MENTOR_AVAILABLE_HOURS_REQUEST",
  MENTOR_AVAILABLE_HOURS_SUCCESS: "MENTOR_AVAILABLE_HOURS_SUCCESS",
  MENTOR_AVAILABLE_HOURS_FAILURE: "MENTOR_AVAILABLE_HOURS_FAILURE",
};

export const getMentorAvailableHoursConstants = {
  GET_MENTOR_AVAILABLE_HOURS_REQUEST: "GET_MENTOR_AVAILABLE_HOURS_REQUEST",
  GET_MENTOR_AVAILABLE_HOURS_SUCCESS: "GET_MENTOR_AVAILABLE_HOURS_SUCCESS",
  GET_MENTOR_AVAILABLE_HOURS_FAILURE: "GET_MENTOR_AVAILABLE_HOURS_FAILURE",
};

export const getAllMentorSessionConstants = {
  GET_ALL_MENTOR_SESSION_REQUEST: "GET_ALL_MENTOR_SESSION_REQUEST",
  GET_ALL_MENTOR_SESSION_SUCCESS: "GET_ALL_MENTOR_SESSION_SUCCESS",
  GET_ALL_MENTOR_SESSION_FAILURE: "GET_ALL_MENTOR_SESSION_FAILURE",
};

export const updateCalendarSettings = {
  UPDATE_CALENDAR_SETTINGS_REQUEST: "UPDATE_CALENDAR_SETTINGS_REQUEST",
  UPDATE_CALENDAR_SETTINGS_SUCCESS: "UPDATE_CALENDAR_SETTINGS_SUCCESS",
  UPDATE_CALENDAR_SETTINGS_FAILURE: "UPDATE_CALENDAR_SETTINGS_FAILURE",
};

export const personalityTest = {
  TEST_REQUEST: "TEST_REQUEST",
  TEST_SUCCESS: "TEST_SUCCESS",
  TEST_FAILURE: "TEST_FAILURE",
};

export const testResponseConstants = {
  TEST_SUBMIT_REQUEST: "TEST_SUBMIT_REQUEST",
  TEST_SUBMIT_SUCCESS: "TEST_SUBMIT_SUCCESS",
  TEST_SUBMIT_FAILURE: "TEST_SUBMIT_FAILURE",
};
export const fullStackConstants = {
  FULL_SUBMIT_REQUEST: "FULL_SUBMIT_REQUEST",
  FULL_SUBMIT_SUCCESS: "FULL_SUBMIT_SUCCESS",
  FULL_SUBMIT_FAILURE: "FULL_SUBMIT_FAILURE",
};
export const liveSessionConstants = {
  LIVESESSION_SUBMIT_REQUEST: "LIVESESSION_SUBMIT_REQUEST",
  LIVESESSION_SUBMIT_SUCCESS: "LIVESESSION_SUBMIT_SUCCESS",
  LIVESESSION_SUBMIT_FAILURE: "LIVESESSION_SUBMIT_FAILURE",
};
export const sessionDetailsConstants = {
  SESSION_DETAILS_REQUEST: "SESSION_DETAILS_REQUEST",
  SESSION_DETAILS_SUCCESS: "SESSION_DETAILS_SUCCESS",
  SESSION_DETAILS_FAILURE: "SESSION_DETAILS_FAILURE",
};
export const enrollSessionConstants = {
  ENROLL_SESSION_REQUEST: "ENROLL_SESSION_REQUEST",
  ENROLL_SESSION_SUCCESS: "ENROLL_SESSION_SUCCESS",
  ENROLL_SESSION_FAILURE: "ENROLL_SESSION_FAILURE",
};
export const createSessionConstants = {
  CREATE_SESSION_REQUEST: "CREATE_SESSION_REQUEST",
  CREATE_SESSION_SUCCESS: "CREATE_SESSION_SUCCESS",
  CREATE_SESSION_FAILURE: "CREATE_SESSION_FAILURE",
};
export const menteeGoalActionConstants = {
  MENTEE_GOAL_REQUEST: "MENTEE_GOAL__REQUEST",
  MENTEE_GOAL_SUCCESS: "MENTEE_GOAL_SUCCESS",
  MENTEE_GOAL_FAILURE: "MENTEE_GOAL_FAILURE",
};
export const goalSimilarMentorsConstants = {
  GOAL_SIMILAR_MENTOR_REQUEST: "GOAL_SIMILAR_MENTOR_REQUEST",
  GOAL_SIMILAR_MENTOR_SUCCESS: "GOAL_SIMILAR_MENTOR_SUCCESS",
  GOAL_SIMILAR_MENTOR_FAILURE: "GOAL_SIMILAR_MENTOR_FAILURE",
};
export const addNotesConstants = {
  ADD_NOTES_REQUEST: "ADD_NOTES_REQUEST",
  ADD_NOTES_SUCCESS: "ADD_NOTES_SUCCESS",
  ADD_NOTES_FAILURE: "ADD_NOTES_FAILURE",
};
export const getNotesConstants = {
  GET_NOTES_REQUEST: "GET_NOTES_REQUEST",
  GET_NOTES_SUCCESS: "GET_NOTES_SUCCESS",
  GET_NOTES_FAILURE: "GET_NOTES_FAILURE",
};
export const updateNotesConstants = {
  UPDATE_NOTES_REQUEST: "UPDATE_NOTES_REQUEST",
  UPDATE_NOTES_SUCCESS: "UPDATE_NOTES_SUCCESS",
  UPDATE_NOTES_FAILURE: "UPDATE_NOTES_FAILURE",
};
export const getUpcomingActivityConstants = {
  UPCOMING_ACTIVITY_REQUEST: "UPCOMING_ACTIVITY_REQUEST",
  UPCOMING_ACTIVITY_SUCCESS: "UPCOMING_ACTIVITY_SUCCESS",
  UPCOMING_ACTIVITY_FAILURE: "UPCOMING_ACTIVITY_FAILURE",
};
export const getHistoryActivityConstants = {
  HISTORY_ACTIVITY_REQUEST: "HISTORY_ACTIVITY_REQUEST",
  HISTORY_ACTIVITY_SUCCESS: "HISTORY_ACTIVITY_SUCCESS",
  HISTORY_ACTIVITY_FAILURE: "HISTORY_ACTIVITY_FAILURE",
};
export const getUpcomingTaskConstants = {
  UPCOMING_TASK_REQUEST: "UPCOMING_TASK_REQUEST",
  UPCOMING_TASK_SUCCESS: "UPCOMING_TASK_SUCCESS",
  UPCOMING_TASK_FAILURE: "UPCOMING_TASK_FAILURE",
};
export const getHistoryTaskConstants = {
  HISTORY_TASK_REQUEST: "HISTORY_TASK_REQUEST",
  HISTORY_TASK_SUCCESS: "HISTORY_TASK_SUCCESS",
  HISTORY_TASK_FAILURE: "HISTORY_TASK_FAILURE",
};

export const getUpcomingMeetingConstants = {
  UPCOMING_MEETING_REQUEST: "UPCOMING_MEETING_REQUEST",
  UPCOMING_MEETING_SUCCESS: "UPCOMING_MEETING_SUCCESS",
  UPCOMING_MEETING_FAILURE: "UPCOMING_MEETING_FAILURE",
};
export const getHistoryMeetingConstants = {
  HISTORY_MEETING_REQUEST: "HISTORY_MEETING_REQUEST",
  HISTORY_MEETING_SUCCESS: "HISTORY_MEETING_SUCCESS",
  HISTORY_MEETING_FAILURE: "HISTORY_MEETING_FAILURE",
};
export const createMeetingConstants = {
  CREATE_MEETING_REQUEST: "CREATE_MEETING_REQUEST",
  CREATE_MEETING_SUCCESS: "CREATE_MEETING_SUCCESS",
  CREATE_MEETING_FAILURE: "CREATE_MEETING_FAILURE",
};
export const createTaskConstants = {
  CREATE_TASK_REQUEST: "CREATE_TASK_REQUEST",
  CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
  CREATE_TASK_FAILURE: "CREATE_TASK_FAILURE",
};
export const updateTaskConstants = {
  UPDATE_TASK_REQUEST: "CREATE_UPDATE_REQUEST",
  UPDATE_TASK_SUCCESS: "CREATE_UPDATE_SUCCESS",
  UPDATE_TASK_FAILURE: "CREATE_UPDATE_FAILURE",
};

export const cancelMeetingConstants = {
  CANCEL_MEETING_REQUEST: "CANCEL_MEETING_REQUEST",
  CANCEL_MEETING_SUCCESS: "CANCEL_MEETING_SUCCESS",
  CANCEL_MEETING_FAILURE: "CANCEL_MEETING_FAILURE",
};
export const sendChatMessageConstants = {
  SEND_CHAT_MESSAGE_REQUEST: "SEND_CHAT_MESSAGE_REQUEST",
  SEND_CHAT_MESSAGE_SUCCESS: "SEND_CHAT_MESSAGE_SUCCESS",
  SEND_CHAT_MESSAGE_FAILURE: "SEND_CHAT_MESSAGE_FAILURE",
};
export const getChatMessageConstants = {
  GET_CHAT_MESSAGE_REQUEST: "GET_CHAT_MESSAGE_REQUEST",
  GET_CHAT_MESSAGE_SUCCESS: "GET_CHAT_MESSAGE_SUCCESS",
  GET_CHAT_MESSAGE_FAILURE: "GET_CHAT_MESSAGE_FAILURE",
};
export const getMenteeInboxListConstants = {
  GET_MENTEE_INBOX_LIST_REQUEST: "GET_MENTEE_INBOX_LIST_REQUEST",
  GET_MENTEE_INBOX_LIST_SUCCESS: "GET_MENTEE_INBOX_LIST_SUCCESS",
  GET_MENTEE_INBOX_LIST_FAILURE: "GET_MENTEE_INBOX_LIST_FAILURE",
};
export const getConnectionRequestConstants = {
  GET_CONNECTION_REQUEST_REQUEST: "GET_CONNECTION_REQUEST_REQUEST",
  GET_CONNECTION_REQUEST_SUCCESS: "GET_CONNECTION_REQUEST_SUCCESS",
  GET_CONNECTION_REQUEST_FAILURE: "GET_CONNECTION_REQUEST_FAILURE",
};
export const getConnectionListConstants = {
  GET_CONNECTION_LIST_REQUEST: "GET_CONNECTION_LIST_REQUEST",
  GET_CONNECTION_LIST_SUCCESS: "GET_CONNECTION_LIST_SUCCESS",
  GET_CONNECTION_LIST_FAILURE: "GET_CONNECTION_LIST_FAILURE",
};
export const updateConnectionRequestConstants = {
  UPDATE_CONNECTION_REQUEST_REQUEST: "UPDATE_CONNECTION_REQUEST_REQUEST",
  UPDATE_CONNECTION_REQUEST_SUCCESS: "UPDATE_CONNECTION_REQUEST_SUCCESS",
  UPDATE_CONNECTION_REQUEST_FAILURE: "UPDATE_CONNECTION_REQUEST_FAILURE",
};
export const getAcceptConnectionRequestConstants = {
  ACCEPTED_CONNECTION_REQUEST_REQUEST: "ACCEPTED_CONNECTION_REQUEST_REQUEST",
  ACCEPTED_CONNECTION_REQUEST_SUCCESS: "ACCEPTED_CONNECTION_REQUEST_SUCCESS",
  ACCEPTED_CONNECTION_REQUEST_FAILURE: "ACCEPTED_CONNECTION_REQUEST_FAILURE",
};
export const getGoalConstants = {
  GET_GOAL_REQUEST: "GET_GOAL_REQUEST",
  GET_GOAL_SUCCESS: "GET_GOAL_SUCCESS",
  GET_GOAL_FAILURE: "GET_GOAL_FAILURE",
};

export const emaillForBlogUpdateConstants = {
  EMAIL_FEILD_REQUEST: "EMAIL_FEILD_REQUEST",
  EMAIL_FEILD_SUCCESS: "EMAIL_FEILD_SUCCESS",
  EMAIL_FEILD_FAILURE: "EMAIL_FEILD_FAILURE",
};
export const getNotificationConstants = {
  GET_NOTIFICATION_REQUEST: "GET_NOTIFICATION_REQUEST",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILURE: "GET_NOTIFICATION_FAILURE",
};
export const updateNotificationStatusConstants = {
  UPDATE_NOTIFICATION_STATUS_REQUEST: "UPDATE_NOTIFICATION_STATUS_REQUEST",
  UPDATE_NOTIFICATION_STATUS_SUCCESS: "UPDATE_NOTIFICATION_STATUS_SUCCESS",
  UPDATE_NOTIFICATION_STATUS_FAILURE: "UPDATE_NOTIFICATION_STATUS_FAILURE",
};
export const createPaymentLinkConstants = {
  CREATE_PAYMENT_LINK_REQUEST: "CREATE_PAYMENT_LINK_REQUEST",
  CREATE_PAYMENT_LINK_SUCCESS: "CREATE_PAYMENT_LINK_SUCCESS",
  CREATE_PAYMENT_LINK_FAILURE: "CREATE_PAYMENT_LINK_FAILURE",
};
export const SubscribeConstants = {
  SUBSCRIBE_REQUEST: "SUBSCRIBE_REQUEST",
  SUBSCRIBE_SUCCESS: "SUBSCRIBE_SUCCESS",
  SUBSCRIBE_FAILURE: "SUBSCRIBE_FAILURE",
};

export const contactConstants = {
  CONTACT_REQUEST: "CONTACT_REQUEST",
  CONTACT_SUCCESS: "CONTACT_SUCCESS",
  CONYACT_FAILURE: "CONTACT_FAILURE",
};


export const createCourseConstants = {
  CREATE_COURSE_REQUEST: "CREATE_COURSE_REQUEST",
  CREATE_COURSE_SUCCESS: "CREATE_COURSE_SUCCESS",
  CREATE_COURSE_FAILURE: "CREATE_COURSE_FAILURE",
};
export const getMentorCourseConstants = {
  GET_MENTOR_COURSE_REQUEST: "GET_MENTOR_COURSE_REQUEST",
  GET_MENTOR_COURSE_SUCCESS: "GET_MENTOR_COURSE_SUCCESS",
  GET_MENTOR_COURSE_FAILURE: "GET_MENTOR_COURSE_FAILURE",
};
export const updateMentorCourseConstants = {
  UPDATE_MENTOR_COURSE_REQUEST: "UPDATE_MENTOR_COURSE_REQUEST",
  UPDATE_MENTOR_COURSE_SUCCESS: "UPDATE_MENTOR_COURSE_SUCCESS",
  UPDATE_MENTOR_COURSE_FAILURE: "UPDATE_MENTOR_COURSE_FAILURE",
};
export const deleteMentorCourseConstants = {
  DELETE_MENTOR_COURSE_REQUEST: "DELETE_MENTOR_COURSE_REQUEST",
  DELETE_MENTOR_COURSE_SUCCESS: "DELETE_MENTOR_COURSE_SUCCESS",
  DELETE_MENTOR_COURSE_FAILURE: "DELETE_MENTOR_COURSE_FAILURE",
};



  export const getAllCourseConstants = {
    GET_ALL_COURSE_REQUEST: "GET_ALL_COURSE_REQUEST",
    GET_ALL_COURSE_SUCCESS: "GET_ALL_COURSE_SUCCESS",
    GET_ALL_COURSE_FAILURE: "GET_ALL_COURSE_FAILURE",
  };
  export const getCourseDetailsConstants = {
    GET_COURSE_DETAIL_REQUEST: "GET_COURSE_DETAIL_REQUEST",
    GET_COURSE_DETAIL_SUCCESS: "GET_COURSE_DETAIL_SUCCESS",
    GET_COURSE_DETAIL_FAILURE: "GET_COURSE_DETAIL_FAILURE",
  };

  export const shareOnLinkedInConstants = {
    SHARE_ON_LINKEDIN_REQUEST: "SHARE_ON_LINKEDIN_REQUEST",
    SHARE_ON_LINKEDIN_SUCCESS: "SHARE_ON_LINKEDIN_SUCCESS",
    SHARE_ON_LINKEDIN_FAILURE: "SHARE_ON_LINKEDIN_FAILURE",
  };
  
  export const addBankAccountConstants = {
    ADD_ACCOUNT_REQUEST: "ADD_ACCOUNT_REQUEST",
    ADD_ACCOUNT_SUCCESS: "ADD_ACCOUNT_SUCCESS",
    ADD_ACCOUNT_FAILURE: "ADD_ACCOUNT_FAILURE",
  };
  
  
  export const getBankDetailsConstants = {
    GET_ACCOUNT_REQUEST: "GET_ACCOUNT_REQUEST",
    GET_ACCOUNT_SUCCESS: "GET_ACCOUNT_SUCCESS",
    GET_ACCOUNT_FAILURE: "GET_ACCOUNT_FAILURE",
  };
  