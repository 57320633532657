import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import menuIcon from "../../assets/images/menu.png";
import crossIcon from "../../assets/images/cross-icon.png";
import SignUpModal from "../modals/signUpModal";
import LoginModal from "../modals/loginModal";
import logo from "../../assets/images/logo-w.png";
import "../dashboard/css/media.css";
import { Link, useNavigate } from "react-router-dom";
import textSmsImg from "../adminDashboard/img/textsms.png";
import notifImg from "../adminDashboard/img/notification.png";
import undrawProfileImg from "../adminDashboard/img/undraw_profile.svg";
import undrawProfileImg1 from "../adminDashboard/img/undraw_profile_1.svg";
import undrawProfileImg2 from "../adminDashboard/img/undraw_profile_2.svg";
import undrawProfileImg3 from "../adminDashboard/img/undraw_profile_3.svg";
import { logoutUser } from "../../redux/actions/login";
import { findMentorAction } from "../../redux/actions/mentor";
import NotificationIcon from "../Notification/NotificationIcon";
const ProtectedHeader = ({ extraClasses = "" }) => {
  const [headerModal, setHeaderModal] = useState({});


  const userType = localStorage.getItem("REACT_USER_TYPE")

  console.log("userType", userType)

  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });
  // const AccountNav = () => {
  //   if (userDetails?.user_type === "mentee") {
  //     navigate("/myprofile");
  //   } else {
  //     navigate("/mentor-dashboard");
  //   }
  // };
  // const SettingNav = () => {
  //   if (userDetails?.user_type === "mentee") {
  //     navigate("/myprofile");
  //   } else {
  //     navigate("/mentor-dashboard/profile/security-setting");
  //   }
  // };

  let MENTOR_PAGE_SIZE = 12,
    MENTOR_PAGE_NUMBER = 1;
  const clickPopular = (value) => {
    const data = {
      pageSize: MENTOR_PAGE_SIZE,
      pageNo: MENTOR_PAGE_NUMBER,
      experties: value,
    };
    dispatch(findMentorAction(data));
    dispatch(findMentorAction(data));
    if (window.location.pathname !== "/find-mentor") {
      navigate("../find-mentor", {
        state : {
          expertise : value
        }
      });
    }
    else
      {
        navigate("/find-mentor", {
          state : {
            expertise : value
          }
        });
      }
  };

  const handleLogOut = () => {
    window.location.assign("/");
    localStorage.removeItem("REACT_TOKEN_AUTH");
    localStorage.removeItem("REACT_USER_ID");
    dispatch(logoutUser(""));
  };
  const expertise = [
    { label: "Content Writing", value: "Content Writing" },
    { label: "Data Science", value: "Data Science" },
    { label: "Design", value: "Design" },
    { label: "Marketing", value: "Marketing" },
    { label: "Product Managment", value: "Product Managment" },
    { label: "Product Research", value: "Product Research" },
    { label: "Software Development", value: "Software Development" },
  ];
  return (
    <header className={`${extraClasses} header-cls`}>
      <meta
        name="viewport"
        content="width=device-width, initial scale=1.0,user-scalable=no"
      />
      <div className="container main___navbar">
        <nav className="menu navbar navbar-expand-lg">
          <a className="navbar-brand ms-5" href="/">
            <img src={logo} alt="logo" className="header-logo" />
          </a>
          <div className="mobile___notification">
            {/* <NotificationIcon /> */}
          </div>
          <button
            className={"navbar-toggler"}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#headerHandler"
            aria-controls="headerHandler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              alt=""
              src={isOpen ? menuIcon : crossIcon}
            />
          </button>
          <div
            className="menu collapse navbar-collapse rounded ms-3"
            id="headerHandler"
          >
            <ul className="navbar-nav d-flex align-items-center">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Find Mentor
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {expertise?.map((item, idx) => (
                    <Link
                      className="pl-2 dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        clickPopular(item.label);
                      }}
                    >
                      {item.label}
                    </Link>
                  ))}
                </div>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Personality
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a
                    className="pl-2 dropdown-item nav-link"
                    href="/personality-test"
                  >
                    Personlaity Test
                  </a>
                  <a
                    className="pl-2 dropdown-item nav-link "
                    href="/personality-types"
                  >
                    Personlaity Types
                  </a>
                </div>
              </li> */}
              <li
                className="nav-item"
                onClick={() => navigate("/become-mentor")}
              >
                <a className="nav-link">Become a Mentor</a>
              </li>
              <li className="nav-item">
              <a className="nav-link " href="/mastering-full-stack-web-development">
                  Full Stack Course
                </a>
              </li>
              <li className="nav-item dropdown d-none  d-sm-block">
                {/* <NotificationIcon /> */}
              </li>

              {/* <!-- Nav Item - User Information --> */}
              <li className="nav-item dropdown no-arrow">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    className="img-profile rounded-circle"
                    src={undrawProfileImg}
                  />
                  <h5 className="mr-2 d-none d-lg-inline">
                    {/* <span>Indira Priyadarshini</span>  */}
                  </h5>
                </a>
                {/* <!-- Dropdown - User Information --> */}
                <div
                  className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  aria-labelledby="userDropdown"
                >
                  <a
                    className="dropdown-item"
                    href={userType === "mentor" ? ('/profile') : ('/myProfile')}
                  >
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                  </a>
                  <a className="dropdown-item"
                    href={userType === "mentor" ? ('/profile/security-setting') : ('/myProfile')}

                  >
                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    Settings
                  </a>
                  <a className="dropdown-item"
                    href={userType === "mentor" ? ('/') : ('/')}

                  >
                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    Dashboard
                  </a>

                  <div className="dropdown-divider"></div>
                  <Link
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#logoutModal"
                    onClick={handleLogOut}
                  >
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                  </Link>
                </div>
              </li>
            </ul>
            {/* </nav> */}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default ProtectedHeader;
