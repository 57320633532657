import React, { useEffect, useState } from 'react';
import Header from '../../applyAsMentor/BecomeMentorHeader';
import MentorAvailability from '../../MentorAvailability/main';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { viewMentorProfileAction } from '../../../redux/actions/mentor';
import style from "../../BecomeAMentor/BecomeMentor.module.css";
import teachingConcept from "../../../assets/images/teachingConcept.jpg";

const OneYearMentorship = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    // const mentor_id = 'ea05cd33-1f7d-4167-b002-59e31676f768';
    const mentor_id = `${process.env.REACT_APP_FREE_MENTORSHIP_MENTOR_ID}`;
    console.log("REACT_APP_FREE_MENTORSHIP_MENTOR_ID", mentor_id)

    const [mentor, setMentor] = useState({});
    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        dispatch(viewMentorProfileAction(mentor_id));
    }, [dispatch, mentor_id]);


    const mentorData = useSelector((state) => {
        const data = state.viewMentorProfileReducer?.data || [];
        return data[0];
    });

    const mentorDataLoading = useSelector(
        (state) => state?.viewMentorProfileReducer?.loading
    );
    useEffect(() => {
        setMentor(mentorData);
        setIsLoading(mentorDataLoading);

    }, [
        mentorData, mentorDataLoading]);
    return (
        <>
            <Header />
            <div className="container-fluid p-0">
                <header className=" text-white py-5">
                    <div className="container">
                        <div className="activation__header">
                            <div className="menteegoal_title text-center">
                                <h3>Get a One-on-One Mentorship Session for One Year, Free!</h3>
                            </div>
                            <div className="menteeGoal__Subtitle" style={{ textAlign: "start" }}>
                                <h2 className="text-center" style={{ whiteSpace: "normal" }}>
                                    Join our exclusive mentorship program and take your skills to the next
                                    level.
                                </h2>
                            </div>
                        </div>
                    </div>
                </header>

                <section className="py-5">
                    <div className="container">




                        <h2 className='text-center'>Join Now</h2>
                        <MentorAvailability mentor_id={mentor_id} />


                    </div>

                </section >
                <section className={style.intro_section}>
                    <div className={`${style.intro_container} container `}>
                        <div className={`${style.intro_row} row`}>
                            <div
                                className={`${style.qualiImg_col} col-lg-6 mb-4`}
                                style={{ backgroundColor: "#ffff" }}
                            >
                                <img
                                    className={`${style.qualification_image} img-fluid`}
                                    src={teachingConcept}
                                    alt="qualifications Image"
                                />
                            </div>
                            <div className={`${style.intro} col-lg-6`}>
                                <h2 className={style.intro_heading}>
                                    What You'll Get
                                </h2>
                                <ul>
                                    <li>
                                        Personalized guidance from industry experts
                                    </li>
                                    <li> Regular check-ins and progress tracking</li>
                                    <li>                                         Access to exclusive resources and events
                                    </li>
                                    <li>  supportive community of like-minded learners</li>
                                    <li>Being professional and respectful at all times</li>
                                </ul>{" "}
                            </div>
                        </div>
                    </div>
                </section>
            </div >

        </>
    );
}

export default OneYearMentorship;
