import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";
import Header from "../../header";
import Footer from "../../footer";
import { bannerImg } from "../../../assets/images/export-images";
import './custom.exam.css'

Chart.register(...registerables);

const MqtResultPage = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loggedIn, setLoggedIn] = useState(
        localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
    );

    const mpqTestDataReducer = useSelector((state) => state.mpqTestDataReducer);

    useEffect(() => {
        const fetchData = () => {
            const testData = mpqTestDataReducer?.data;
            if (testData !== undefined) {
                setData(testData);
            } else {
                setError("data_not_found");
            }
        };

        fetchData();
    }, [mpqTestDataReducer]);

    const [sumArray, setSumArray] = useState([0, 0, 0, 0]);

    useEffect(() => {
        const newSumArray = [0, 0, 0, 0];

        data?.answeredQuestions?.forEach((question) => {
            switch (question.section) {
                case 'PM':
                    newSumArray[0] += question.answer;
                    break;
                case 'AFM':
                    newSumArray[1] += question.answer;
                    break;
                case 'ACM':
                    newSumArray[2] += question.answer;
                    break;
                case 'EM':
                    newSumArray[3] += question.answer;
                    break;
                default:
                    break;
            }
        });

        setSumArray(newSumArray);
    }, [data]);


    const state = {
        labels: [
            'POWER MOTIVATION',
            'AFFILIATION MOTIVATION',
            'ACHIEVEMENT MOTIVATION',
            'EXTENSION MOTIVATION',
        ],
        datasets: [
            {
                label: 'Motivational Profile Result',
                backgroundColor: ['Indigo', 'Purple', 'Yellow', 'Teal'],
                fill: false,
                lineTension: 0.5,
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: sumArray,
            },
        ],
    };
    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const currentDate = new Date();

        let age = currentDate.getFullYear() - birthDate.getFullYear();

        // Adjust age if birthday hasn't occurred yet this year
        if (
            currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() &&
                currentDate.getDate() < birthDate.getDate())
        ) {
            age--;
        }

        return age;
    };




    return (
        <>
            {loggedIn ? <ProtectedHeader /> : <Header />}
            <section
                className="banner-sec"
                style={{ backgroundImage: `url(${bannerImg})` }}
            >
                <div className="container">
                    <div className="mentroship-sec">
                        <h3>Motivational Profile Questionnaire Report </h3>
                    </div>
                </div>
            </section>
            <section className="section about-section custom-report-about-section" id="about">
                <div className="container">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="custom-report-about-text go-to">
                                <h6 className="theme-color lead">
                                    Introduction
                                </h6>
                                <p>
                                    I am  <b>{data?.values?.name}</b>, a {calculateAge(data?.values?.dob)} year-old student who recently took the Achievement Motivation Training (AMT)  exam.
                                    I am overjoyed to report that I scored {
                                        sumArray[0] + sumArray[1] + sumArray[2] + sumArray[3]


                                    } marks, a remarkable achievement that
                                    reflects my unwavering dedication and commitment to academic excellence.
                                    This exceptional result has not only opened up a plethora of opportunities but
                                    has also provided me with valuable insights into my strengths and areas for improvement.
                                </p>
                                <div className="row custom-report-about-list">
                                    <div className="col-md-6">
                                        <div className="media">
                                            <label>Birthday</label>
                                            <p>
                                                {new Date(data?.values?.dob).toLocaleString()}
                                            </p>
                                        </div>
                                        <div className="media">
                                            <label>Age</label>
                                            <p>{calculateAge(data?.values?.dob)} Yr</p>
                                        </div>
                                        <div className="media">
                                            <label>Gender</label>
                                            <p>{data?.values?.gender} </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="media">
                                            <label>E-mail</label>
                                            <p>{data?.values?.email}</p>
                                        </div>
                                        <div className="media">
                                            <label>Phone</label>
                                            <p>{data?.values?.phone}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="custom-report-about-avatar">
                                {
                                    data?.values?.gender === 'female' ? (
                                        <>
                                            <img
                                                src="https://bootdey.com/img/Content/avatar/avatar8.png"
                                                title=""
                                                alt=""
                                            /></>
                                    ) : (
                                        <img
                                            src="https://bootdey.com/img/Content/avatar/avatar7.png"
                                            title=""
                                            alt=""
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="custom-report-counter mt-4">
                        <div className="row">
                            <div className="col-6 col-lg-3">
                                <div className="count-data text-center">
                                    <h6 className="count h2" data-to={500} data-speed={500}>
                                        {sumArray[0]}
                                    </h6>
                                    <p className="m-0px font-w-600">POWER MOTIVATION</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="count-data text-center">
                                    <h6 className="count h2" data-to={150} data-speed={150}>
                                        {sumArray[1]}
                                    </h6>
                                    <p className="m-0px font-w-600">AFFILIATION MOTIVATION</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="count-data text-center">
                                    <h6 className="count h2" data-to={850} data-speed={850}>
                                        {sumArray[2]}
                                    </h6>
                                    <p className="m-0px font-w-600">ACHIEVEMENT MOTIVATION</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="count-data text-center">
                                    <h6 className="count h2" data-to={190} data-speed={190}>
                                        {sumArray[3]}
                                    </h6>
                                    <p className="m-0px font-w-600">EXTENSION MOTIVATION</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="step__goal__item container mt-4">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Motivation Type</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.labels.map((label, index) => (
                            <tr key={index}>
                                <td>{label}</td>
                                <td>{sumArray[index]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Line
                    data={state}
                    options={{
                        title: {
                            display: true,
                            text: 'Motivational Profile Result',
                            fontSize: 20,
                        },
                        legend: {
                            display: true,
                            position: 'right',
                        },
                    }}
                />
            </section>
            <Footer />
        </>
    );
};

export default MqtResultPage;
