import { BiDollar, BiRupee } from "react-icons/bi";
import { BsCurrencyDollar } from "react-icons/bs";
import {
    TbCurrencyDollarCanadian,
    TbCurrencyEuro,
    TbCurrencyKroneSwedish,
    TbCurrencyPound,
    TbCurrencyRupee,
} from "react-icons/tb";

const getGrayScaledCurrencyIcon = (currency) => {
    switch (currency?.code) {
        case "USD":
            return <BiDollar size={20} />;
        case "CAD":
            return <TbCurrencyDollarCanadian color="black" />;
        case "EUR":
            return <TbCurrencyEuro color="black" />;
        case "GBP":
            return <TbCurrencyPound color="black" />;
        case "SEK":
            return <TbCurrencyKroneSwedish color="black" />;
        default:
            return <BiRupee size={20} />;
    }
};

const CurrencyDisplay = ({ currency = {}, grayscaled = false }) => {
    if (!currency) return <></>;
    if (grayscaled) return getGrayScaledCurrencyIcon(currency);
    const getCurrencyComponent = (currency) => {
        switch (currency?.code) {
            case "USD":
                return <BsCurrencyDollar />;
            case "CAD":
                return <TbCurrencyDollarCanadian />;
            case "EUR":
                return <TbCurrencyEuro />;
            case "GBP":
                return <TbCurrencyPound />;
            case "SEK":
                return <TbCurrencyKroneSwedish />;
            default:
                return <TbCurrencyRupee />;
        }
    };

    return getCurrencyComponent(currency);
};

export default CurrencyDisplay;
