import { takeLatest, all } from "redux-saga/effects";
import {
  otpConstants,
  emailConstants,
  loginConstants,
  findMentorConstants,
  passwordLoginConstants,
  signUpConstants,
  socialLoginConstants,
  updateProfileConstants,
  signUpVerifyConstants,
  createMentorConstants,
  forgetPasswordConstants,
  viewMentorProfileConstants,
  userDetailsConstants,
  favoriteMentorsConstants,
  subscribedMentorsConstants,
  similarMentorsConstants,
  createMentorSessionServiceConstants,
  orderConstants,
  publishEbookConstants,
  mentorProductsConstants,
  mentorEbooksConstants,
  mentorTotalSubscriptionConstants,
  updateUserPasswordConstants,
  openSessionsConstants,
  topMentorsConstants,
  mentorActiveSubscriptionConstants,
  mentorSubscriptionRequestsConstants,
  googleCalanderConstants,
  commonConstants,
  availableDayConstants,
  availableTimeConstants,
  feedbackConstants,
  createFavoriteMentorsConstants,
  mentorAvailableHoursConstants,
  getMentorAvailableHoursConstants,
  getAllMentorSessionConstants,
  updateCalendarSettings,
  personalityTest,
  testResponseConstants,
  fullStackConstants,
  liveSessionConstants,
  sessionDetailsConstants,
  enrollSessionConstants,
  createSessionConstants,
  menteeGoalActionConstants,
  goalSimilarMentorsConstants,
  addNotesConstants,
  getNotesConstants,
  updateNotesConstants,
  getUpcomingTaskConstants,
  getUpcomingMeetingConstants,
  getHistoryMeetingConstants,
  createMeetingConstants,
  createTaskConstants,
  updateTaskConstants,
  cancelMeetingConstants,
  sendChatMessageConstants,
  getChatMessageConstants,
  getMenteeInboxListConstants,
  getConnectionRequestConstants,
  updateConnectionRequestConstants,
  getAcceptConnectionRequestConstants,
  getConnectionListConstants,
  getGoalConstants,
  getNotificationConstants,
  updateNotificationStatusConstants,
  createPaymentLinkConstants,
  SubscribeConstants,
  contactConstants,
  createCourseConstants,
  getMentorCourseConstants,
  updateMentorCourseConstants,
  deleteMentorCourseConstants,
  getAllCourseConstants,
  getCourseDetailsConstants,
  createProductConstants,
  shareOnLinkedInConstants,
  addBankAccountConstants,
  getBankDetailsConstants
} from "../../constants";

import {
  otpSaga,
  verifySaga,
  loginSaga,
  passwordLoginSaga,
  socialLoginSaga,
  updateProfileSaga,
  signUpVerifySaga,
  forgetPasswordSaga,
  userDetailsSaga,
  updateUserPasswordSaga,
} from "./login";
import {
  findMentorSaga,
  createMentorSaga,
  viewMentorProfileSaga,
  createMentorSessionServiceSaga,
  publishEbookSaga,
  mentorProductsSaga,
  mentorEbooksSaga,
  mentorTotalSubscriptionSaga,
  openSessionsSaga,
  getTopMentorSaga,
  mentorActiveSubscriptionSaga,
  mentorSubscriptionRequestsSaga,
  mentorAvailabilityDaysSaga,
  mentorAvailabilityTimeSlotsSaga,
  mentorAvailableHoursSaga,
  getMentorAvailableHoursSaga,
  getAllMentorSessionSaga,
  addNotesSaga,
  getNotesSaga,
  updateNotesSaga,
  getUpcomingTaskSaga,
  getHistoryMeetingSaga,
  getUpcomingMeetingSaga,
  createMeetingSaga,
  createTaskSaga,
  updateTaskSaga,
  cancelMeetingSaga,
  createProdcutSaga,
  shareOnLinkedInSaga,
} from "./mentor";
import { signUpSaga } from "./login";
import {
  favoriteMentorSaga,
  subscribedMentorSaga,
  similarMentorSaga,
  createFavoriteMentorSaga,
  menteeGoalSaga,
  goalSimilarMentorSaga,
} from "./mentee";

import { createPaymentLinkSaga, orderIdSaga } from "./session";

import {
  generateAuthLinkSaga,
  handleGoogleRedirectSaga,
} from "./google-calander";
import {
  uploadImgaeSaga,
  uploadImgaeFileSaga,
  submitFeedbackDataSaga,
  updateCalendarSettingsSaga,
  personalityTestSaga,
  testResponseSaga,
  fullStackSaga,
  liveSessionSaga,
  sessionDetailsSaga,
  enrollSessionSaga,
  createSessionSaga,
  sendChatMessageSaga,
  getChatMessageSaga,
  getConnectionRequestSaga,
  updateConnectionRequestSaga,
  getConnectionListSaga,
  getGoalSaga,
  getNotificationSaga,
  updateNotificationStatusSaga,
  subscribeSaga,
  contactSaga,
  addBankAccountSaga,
  getBankDetailsSaga
} from "./common";
import { createCourseSaga, deleteMentorCourseSaga, getAllCourseSaga, getCourseDetailsSaga, getMentorCourseSaga, updateMentorCourseSaga } from "./course";

function* actionWatcher() {
  yield takeLatest(otpConstants.GENERATE_OTP_REQUEST, otpSaga);
  yield takeLatest(emailConstants.VERIFY_REQUEST, verifySaga);
  yield takeLatest(loginConstants.LOGIN_REQUEST, loginSaga);
  yield takeLatest(findMentorConstants.FIND_MENTOR_REQUEST, findMentorSaga);
  yield takeLatest(
    createMentorConstants.CREATE_MENTOR_REQUEST,
    createMentorSaga
  );
  yield takeLatest(
    passwordLoginConstants.PASSWORD_LOGIN_REQUEST,
    passwordLoginSaga
  );
  yield takeLatest(signUpConstants.SIGN_UP_REQUEST, signUpSaga);
  yield takeLatest(socialLoginConstants.SOCIAL_LOGIN_REQUEST, socialLoginSaga);
  yield takeLatest(
    updateProfileConstants.UPDATE_PROFILE_REQUEST,
    updateProfileSaga
  );
  yield takeLatest(
    signUpVerifyConstants.SIGNUP_VERIFY_REQUEST,
    signUpVerifySaga
  );
  yield takeLatest(
    forgetPasswordConstants.FORGET_PASSWORD_REQUEST,
    forgetPasswordSaga
  );
  yield takeLatest(
    viewMentorProfileConstants.VIEW_MENTOR_PROFILE_REQUEST,
    viewMentorProfileSaga
  );
  yield takeLatest(userDetailsConstants.USER_DETAILS_REQUEST, userDetailsSaga);
  yield takeLatest(
    favoriteMentorsConstants.FAVORITE_MENTOR_REQUEST,
    favoriteMentorSaga
  );
  yield takeLatest(
    subscribedMentorsConstants.SUBSCRIBED_MENTOR_REQUEST,
    subscribedMentorSaga
  );
  yield takeLatest(
    similarMentorsConstants.SIMILAR_MENTOR_REQUEST,
    similarMentorSaga
  );
  yield takeLatest(
    createMentorSessionServiceConstants.CREATE_MENTOR_SESSION_SERVICE_REQUEST,
    createMentorSessionServiceSaga
  );
  yield takeLatest(
    publishEbookConstants.PUBLISH_EBOOK_REQUEST,
    publishEbookSaga
  );
  yield takeLatest(
    mentorProductsConstants.MENTOR_PRODUCTS_REQUEST,
    mentorProductsSaga
  );
  yield takeLatest(orderConstants.GENERATE_ORDER_ID_REQUEST, orderIdSaga);
  yield takeLatest(
    mentorEbooksConstants.MENTOR_EBOOKS_REQUEST,
    mentorEbooksSaga
  );
  yield takeLatest(
    mentorTotalSubscriptionConstants.MENTOR_TOTAL_SUBSCRIPTION_REQUEST,
    mentorTotalSubscriptionSaga
  );
  yield takeLatest(
    mentorActiveSubscriptionConstants.MENTOR_ACTIVE_SUBSCRIPTION_REQUEST,
    mentorActiveSubscriptionSaga
  );
  yield takeLatest(
    mentorSubscriptionRequestsConstants.MENTOR_SUBSCRIPTION_REQUESTS_REQUEST,
    mentorSubscriptionRequestsSaga
  );
  yield takeLatest(
    updateUserPasswordConstants.UPDATE_USER_PASSWORD_REQUEST,
    updateUserPasswordSaga
  );
  yield takeLatest(
    openSessionsConstants.OPEN_SESSIONS_REQUEST,
    openSessionsSaga
  );
  yield takeLatest(topMentorsConstants.TOP_MENTOR_REQUEST, getTopMentorSaga);
  yield takeLatest(
    googleCalanderConstants.GENERATE_AUTH_LINK_REQUEST,
    generateAuthLinkSaga
  );
  yield takeLatest(
    googleCalanderConstants.HANDLE_GOOGLE_REDIRECT_REQUEST,
    handleGoogleRedirectSaga
  );
  yield takeLatest(commonConstants.UPLOAD_IMAGE_REQUEST, uploadImgaeSaga);
  yield takeLatest(
    commonConstants.UPLOAD_IMAGE_FILE_REQUEST,
    uploadImgaeFileSaga
  );
  yield takeLatest(
    availableDayConstants.AVAILABLE_DAY_REQUEST,
    mentorAvailabilityDaysSaga
  );
  yield takeLatest(
    availableTimeConstants.AVAILABLE_TIME_REQUEST,
    mentorAvailabilityTimeSlotsSaga
  );
  yield takeLatest(
    feedbackConstants.FEEDBACK_SUBMIT_REQUEST,
    submitFeedbackDataSaga
  );
  yield takeLatest(
    createFavoriteMentorsConstants.CREATE_FAVORITE_MENTOR_REQUEST,
    createFavoriteMentorSaga
  );
  yield takeLatest(
    mentorAvailableHoursConstants.MENTOR_AVAILABLE_HOURS_REQUEST,
    mentorAvailableHoursSaga
  );
  yield takeLatest(
    getMentorAvailableHoursConstants.GET_MENTOR_AVAILABLE_HOURS_REQUEST,
    getMentorAvailableHoursSaga
  );
  yield takeLatest(
    getAllMentorSessionConstants.GET_ALL_MENTOR_SESSION_REQUEST,
    getAllMentorSessionSaga
  );
  yield takeLatest(
    updateCalendarSettings.UPDATE_CALENDAR_SETTINGS_REQUEST,
    updateCalendarSettingsSaga
  );
  yield takeLatest(personalityTest.TEST_REQUEST, personalityTestSaga);
  yield takeLatest(testResponseConstants.TEST_SUBMIT_REQUEST, testResponseSaga);
  yield takeLatest(fullStackConstants.FULL_SUBMIT_REQUEST, fullStackSaga);
  yield takeLatest(
    liveSessionConstants.LIVESESSION_SUBMIT_REQUEST,
    liveSessionSaga
  );
  yield takeLatest(
    sessionDetailsConstants.SESSION_DETAILS_REQUEST,
    sessionDetailsSaga
  );
  yield takeLatest(
    enrollSessionConstants.ENROLL_SESSION_REQUEST,
    enrollSessionSaga
  );
  yield takeLatest(
    createSessionConstants.CREATE_SESSION_REQUEST,
    createSessionSaga
  );
  yield takeLatest(
    menteeGoalActionConstants.MENTEE_GOAL_REQUEST,
    menteeGoalSaga
  );
  yield takeLatest(
    goalSimilarMentorsConstants.GOAL_SIMILAR_MENTOR_REQUEST,
    goalSimilarMentorSaga
  );
  yield takeLatest(addNotesConstants.ADD_NOTES_REQUEST, addNotesSaga);
  yield takeLatest(getNotesConstants.GET_NOTES_REQUEST, getNotesSaga);
  yield takeLatest(updateNotesConstants.UPDATE_NOTES_REQUEST, updateNotesSaga);

  yield takeLatest(
    getUpcomingTaskConstants.UPCOMING_TASK_REQUEST,
    getUpcomingTaskSaga
  );

  yield takeLatest(
    getUpcomingMeetingConstants.UPCOMING_MEETING_REQUEST,
    getUpcomingMeetingSaga
  );
  yield takeLatest(
    getHistoryMeetingConstants.HISTORY_MEETING_REQUEST,
    getHistoryMeetingSaga
  );
  yield takeLatest(
    createMeetingConstants.CREATE_MEETING_REQUEST,
    createMeetingSaga
  );
  yield takeLatest(createTaskConstants.CREATE_TASK_REQUEST, createTaskSaga);
  yield takeLatest(updateTaskConstants.UPDATE_TASK_REQUEST, updateTaskSaga);
  yield takeLatest(
    cancelMeetingConstants.CANCEL_MEETING_REQUEST,
    cancelMeetingSaga
  );
  yield takeLatest(
    sendChatMessageConstants.SEND_CHAT_MESSAGE_REQUEST,
    sendChatMessageSaga
  );
  yield takeLatest(
    getChatMessageConstants.GET_CHAT_MESSAGE_REQUEST,
    getChatMessageSaga
  );

  yield takeLatest(
    getConnectionRequestConstants.GET_CONNECTION_REQUEST_REQUEST,
    getConnectionRequestSaga
  );
  yield takeLatest(
    updateConnectionRequestConstants.UPDATE_CONNECTION_REQUEST_REQUEST,
    updateConnectionRequestSaga
  );
  yield takeLatest(
    getConnectionListConstants.GET_CONNECTION_LIST_REQUEST,
    getConnectionListSaga
  );
  yield takeLatest(getGoalConstants.GET_GOAL_REQUEST, getGoalSaga);
  yield takeLatest(
    getNotificationConstants.GET_NOTIFICATION_REQUEST,
    getNotificationSaga
  );
  yield takeLatest(
    updateNotificationStatusConstants.UPDATE_NOTIFICATION_STATUS_REQUEST,
    updateNotificationStatusSaga
  );
  yield takeLatest(
    createPaymentLinkConstants.CREATE_PAYMENT_LINK_REQUEST,
    createPaymentLinkSaga
  );
  yield takeLatest(SubscribeConstants.SUBSCRIBE_REQUEST, subscribeSaga);

yield takeLatest(
  contactConstants.CONTACT_REQUEST,
  contactSaga
);
yield takeLatest(
  createCourseConstants.CREATE_COURSE_REQUEST,
  createCourseSaga
);
yield takeLatest(
  getMentorCourseConstants.GET_MENTOR_COURSE_REQUEST,
  getMentorCourseSaga
);
yield takeLatest(
  updateMentorCourseConstants.UPDATE_MENTOR_COURSE_REQUEST,
  updateMentorCourseSaga
);
yield takeLatest(
  deleteMentorCourseConstants.DELETE_MENTOR_COURSE_REQUEST,
  deleteMentorCourseSaga
);

yield takeLatest(
  getAllCourseConstants.GET_ALL_COURSE_REQUEST,
  getAllCourseSaga
);
yield takeLatest(
  getCourseDetailsConstants.GET_COURSE_DETAIL_REQUEST,
  getCourseDetailsSaga
);
yield takeLatest(
  createProductConstants.CREATE_PRODUCTS_REQUEST,
  createProdcutSaga
);
yield takeLatest(
  shareOnLinkedInConstants.SHARE_ON_LINKEDIN_REQUEST,
  shareOnLinkedInSaga
);
yield takeLatest(
  addBankAccountConstants.ADD_ACCOUNT_REQUEST,
  addBankAccountSaga
);
yield takeLatest(
  getBankDetailsConstants.GET_ACCOUNT_REQUEST,
  getBankDetailsSaga
);

}
export default function* rootSaga() {
  yield all([actionWatcher()]);
}
