import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { liveSessionAction } from "../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import maleAvatar from "../../assets/images/male.png";
import femaleAvatar from "../../assets/images/female.png";
import defaultSessions from "./DefaultSession.png";
import { leftArrowImg, rightArrowImg } from "../../assets/images/export-images";
const LiveSessions = () => {
  const dispatch = useDispatch();
  const [expertise, setExpertise] = useState(
    "Find the session you're interested in"
  );
  const navigate = useNavigate();

  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(liveSessionAction());
  }, []);

  const liveSessionData = useSelector((state) => {
    if (isLoading && !state.liveSessionReducer.loading) {
      setIsLoading(false);
    } else if (!isLoading && state.liveSessionReducer.loading) {
      setIsLoading(true);
    }
    return state.liveSessionReducer.data;
  });

  useEffect(() => {
    if (liveSessionData !== undefined) {
      console.log("liveSessionData", liveSessionData);
    }
  }, [isLoading]);

  const sessionDetailsCall = (sessionId) => {
    setSelectedSessionId(sessionId);
    navigate(`/session-details/${sessionId}`);
  };

  const formatDate = (date) => {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    return formattedDate;
  };
  const itemsPerPage = 8;

  const totalPages = Math.ceil((liveSessionData?.length || 0) / itemsPerPage); // Check if data is defined
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the range of data to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = (liveSessionData || []).slice(startIndex, endIndex);
  // Handle pagination button clicks
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <section className="top_mentor_list" style={{ background: "white" }}>
        <div className="container Session__list_main">
          {isLoading ? (
            <div className="loader">
              <TailSpin ariaLabel="Loading..." color="#00BFFF" />
            </div>
          ) : currentData && currentData.length !== 0 ? (
            currentData.map((mentor, id) => (
              <div
                key={id}
                onClick={() => sessionDetailsCall(mentor.session_id)}
                className=" mentor___list pt-3"
              >
                <div className=" mentr_list__box cursor-pointer">
                  <div className="mentor-avatar overflow-hidden">
                    <div className="image-dimmer" />
                    {mentor?.banner_image && mentor?.banner_image !== "" ? (
                      <img
                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}${mentor?.banner_image}`}
                        alt=""
                      />
                    ) : (
                      <img src={defaultSessions} />
                    )}
                  </div>
                  <div className=" name___box " width={1920}>
                    <p className="preview__content__name d-flex align-items-center text-truncate">
                      <span className="black-text font-weight-600 text-truncate">
                        <img
                          className="avatar mr-3 rounded-circle"
                          alt="Mentor_image"
                          // src={item.mentor_photo}
                          style={{
                            width: "35px",
                            height: "35px",
                          }}
                          src={
                            mentor?.mentor_photo
                              ? `${process.env.REACT_APP_IMAGES_BASE_URL}${mentor?.mentor_photo}`
                              : mentor?.gender == "female"
                              ? `${femaleAvatar}`
                              : `${maleAvatar}`
                          }
                        />{" "}
                        {mentor.mentor_name}
                      </span>
                    </p>
                    <div className="preview__content__job grey-1-text">
                      <div className="item-list">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-briefcase"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                        <p
                          className=" mentor___feature ml-2"
                          style={{ whiteSpace: "normal" }}
                        >
                          <span>{mentor.title}</span>{" "}
                          {/* <span className="grey-2-text">at</span>{" "}
                            // <span>{mentor.company_or_school}</span> */}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <p
                        className=" mentor___feature grey-1-text"
                        style={{ marginLeft: "11px" }}
                      >
                        <span className="grey-2-text">{mentor.job_role}</span>
                      </p>
                    </div>
                  </div>
                  <div className="preview__content__metadata grey-4-bg expertise-p3 mt-auto d-flex justify-content-between">
                    <div>
                      <p className="sc-jsMahE dZPGPc grey-2-text">Date</p>
                      <p className=" mentor___feature grey-1-text font-weight-600">
                        {formatDate(mentor?.sesstiondate)}
                      </p>
                    </div>
                    <div>
                      <p className="sc-jsMahE dZPGPc grey-2-text">
                        Max participants
                      </p>
                      <p className=" mentor___feature grey-1-text font-weight-600">
                        {mentor?.max_participants}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h1>No live session</h1>
          )}
        </div>
      <div className="pagination ">
        <a
          onClick={goToPreviousPage}
          className={currentPage === 1 ? "disable" : ""}
        >
          <img alt="" src={leftArrowImg} />
        </a>
        {Array.from({ length: totalPages }, (_, index) => (
          <a
            key={index + 1}
            onClick={() => goToPage(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </a>
        ))}
        <a onClick={goToNextPage} disabled={currentPage === totalPages}>
          <img alt="" src={rightArrowImg} />
        </a>
      </div>
      </section>

    </>
  );
};

export default LiveSessions;
