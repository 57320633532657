import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import ProtectedHeader from "../ProtectedHeader/ProtectedHeader";
import Footer from "../footer";

import {
  bannerImg,
  arR,
  ic1,
  ic2,
  ic3,
  ic4,
  ic6,
  ic7,
  ic5,
  star,
} from "../../assets/images/export-images";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/custom.css";
import "../../assets/css/header.css";
import "../../assets/css/main-banner.css";
import "../../assets/css/world-class-mentorship.css";
import "../../assets/css/why-choose-mentorpal.css";
import "../../assets/css/already-mentoring-people.css";
import "../../assets/css/event-sec.css";
import "../../assets/css/top-mentor.css";
import "../../assets/css/save-time.css";
import "../../assets/css/help-us.css";
import "../../assets/css/footer.css";
import "../../assets/css/popular-sessions.css";
import "../../assets/css/signup-popup.css";
import "../../assets/css/media.css";
import { logged, REACT_USER_ID } from "../auth/tokenProvider";
import { getUserDetails } from "../../redux/actions/login";
import Header from "../header";
import "./cv.css";
import "../../assets/css/main-banner.css";
import {
  getTopMentorsAction,
  getOpenSessionsAction,
  viewMentorProfileAction,
} from "../../redux/actions/mentor";

const CV = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMentorsLoading, setIsMentorsLoading] = useState(false);
  const [isOpenSessionsLoading, setIsOpenSessionsLoading] = useState(false);
  const [topMentors, setTopMentors] = useState([]);
  const [openSessions, setOpenSessions] = useState([]);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
  );
  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });

  const isAuth = useSelector((state) => {
    const data =
      (state.userDetailsReducer?.data &&
        state.userDetailsReducer?.data[0]?.user_id) ||
      state.passwordLoginReducer?.data?.user_id;
    return data ? true : false;
  });

  useSelector((state) => {
    if (
      isMentorsLoading &&
      state.topMentorsReducer?.loading &&
      Object.keys(topMentors).length !== 0
    )
      setTopMentors([]);

    const loading = state.topMentorsReducer?.loading;
    const data = state.topMentorsReducer?.data;

    if (loading && !isMentorsLoading) setIsMentorsLoading(true);

    if (
      !loading &&
      Object.keys(topMentors).length === 0 &&
      data !== undefined &&
      data.length !== 0
    ) {
      setTopMentors(data);
      setIsMentorsLoading(false);
    }
  });

  useSelector((state) => {
    if (
      isOpenSessionsLoading &&
      state.openSessionsReducer?.loading &&
      Object.keys(openSessions).length !== 0
    )
      setOpenSessions([]);

    const request = state.openSessionsReducer?.request;
    const loading = state.openSessionsReducer?.loading;
    const data = state.openSessionsReducer?.data;

    if (loading && !isOpenSessionsLoading) setIsOpenSessionsLoading(true);

    if (request === undefined) {
      if (isOpenSessionsLoading) setIsOpenSessionsLoading(false);
      return;
    }
    if (
      !loading &&
      Object.keys(openSessions).length === 0 &&
      data !== undefined &&
      data.length !== 0
    ) {
      setOpenSessions(data);
      setIsOpenSessionsLoading(false);
    }
  });

  useEffect(() => {
    if (topMentors.length === 0) {
      dispatch(getTopMentorsAction());
    }
  }, []);

  useEffect(() => {
    if (openSessions.length === 0) {
      dispatch(
        getOpenSessionsAction({
          pageSize: 1,
          pageNo: 20,
        })
      );
    }
  }, []);

  const handleUserStatus = (status, type) => {
    switch (status) {
      case "Signup":
        navigate("apply-as-mentor");
        return;
      case "registered":
        navigate("/");
        // alert("Your profile is under verification")
        return;
      // navigate("apply-as-mentor")
      case "onhold":
        navigate("/mentor-dashboard");
        // alert("Your profile is needed action from mentors")
        return;
      default:
        navigate("/mentor-dashboard");
    }
  };

  // route redirect
  const handleRouting = (data) => {
    if (data?.user_type === "mentor") {
      if (data?.status == "activated") {
        navigate("/mentor-dashboard");
      } else {
        handleUserStatus(data?.status, data?.userType);
      }
    }
    if (data?.user_type === "mentee") {
      // navigate("/event-calendar")
      // if (data?.status == "activated") {
      navigate("/");
      // }
      // else {
      //   handleUserStatus(data?.status, data?.userType)
      // }
    }
  };

  useEffect(() => {
    if (logged || isAuth) {
      if (!userDetails) {
        dispatch(getUserDetails(REACT_USER_ID));
      } else {
        handleRouting(userDetails);
      }
    }
  }, [userDetails]);
  const viewProfile = (mentor_id) => {
    dispatch(viewMentorProfileAction(mentor_id));
    navigate(`/mentor-details?id=${mentor_id}`);
  };

  return (
    <>
      {loggedIn ? <ProtectedHeader /> : <Header />}
      <section
        className="banner-sec"
        style={{ backgroundImage: `url(${bannerImg})` }}
      >
        <div className="container">
          <div className="mentroship-sec">
            <h3>Professional Resume / CV Writing Company</h3>
          </div>
        </div>
      </section>
      <section className="mentors-tabs">
        <div className="container">
          <div className="row cv-row">
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="box-wrap">
                <div className="icon-cls">
                  <img alt="icon" src={ic1} />
                </div>
                <div className="mentor-cont">
                  <h4>Complimentary Support Term</h4>
                  <p className="cv-p">
                  Discover your true self and respond authentically to uncover your character traits.
                    
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="box-wrap">
                <div className="icon-cls">
                  <img alt="icon" src={ic3} />
                </div>
                <div className="mentor-cont">
                  <h4>Budget Friendly Bundle</h4>
                  <p className="cv-p">
                  Experience a seamless and budget friendly process with our team of expert writers and get a winning resume.

                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="box-wrap">
                <div className="icon-cls">
                  <img alt="icon" src={ic3} />
                </div>
                <div className="mentor-cont">
                  <h4>Seasoned Resume Specialist</h4>
                  <p className="cv-p">
                  Our team of Seasoned Resume Specialists creates a customized resume that showcases your unique skills, experience, and accomplishments.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="box-wrap">
                <div className="icon-cls">
                  <img alt="icon" src={ic3} />
                </div>
                <div className="mentor-cont">
                  <h4>Adaptations are unlimited</h4>
                  <p className="cv-p">
                  We offer unlimited adaptations to ensure your resume accurately reflects your accomplishments.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- World className Mentorship --> */}
        </div>
      </section>
      <section>
        <div className="professional ">
          <div className="wrapper">
            <p className="cv-p">
            Mentorpal is a one-stop solution for all your resume needs. Our expert team offers a range of resume services, including visual resumes, infographic resumes, text resumes,cover letters and online resume portfolios. Whether you're a creative professional looking to stand out from the crowd or a more traditional job seeker looking for a polished text resume, we've got you covered. With a focus on quality and affordability, Mentorpal is the perfect choice for anyone looking to improve their job search and take their career to the next level.

            </p>
            <div className="cvexpert">
              <h2 className="sectitle bold-heading">
              What are you looking for? Consult our experts on-
              </h2>
              <div className="cvd-flex">
                <div className="cvexpert-box">
                  <div className="icon-box">
                    <i className="fa fa-file-text"></i>
                  </div>
                  <div className="text">Text Resume</div>
                </div>
                <div className="cvexpert-box">
                  <div className="icon-box">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="text">Visual Resume</div>
                </div>
                <div className="cvexpert-box">
                  <div className="icon-box">
                    <i class="fa fa-info-circle"></i>
                  </div>
                  <div className="text">Infographic CV</div>
                </div>
                <div className="cvexpert-box">
                  <div className="icon-box">
                    <i class="fa fa-linkedin"></i>
                  </div>
                  <div className="text">Linkedin Profile</div>
                </div>
                <div className="cvexpert-box">
                  <div className="icon-box">
                    <i className="fa fa-files-o"></i>
                  </div>
                  <div className="text">Resume Portfolio</div>
                </div>
                <div className="cvexpert-box">
                  <div className="icon-box">
                    <i className="fa fa-file-word-o"></i>
                  </div>
                  <div className="text">Cover Letter</div>
                </div>
                <div className="cvexpert-box">
                  <div className="icon-box">
                    <i className="fa fa-search"></i>
                  </div>
                  <div className="text">Job Search Assistance</div>
                </div>
                <div className="cvexpert-box">
                  <div className="icon-box">
                    <i className="fa fa-globe"></i>
                  </div>
                  <div className="text">Resume Display on Job Portal</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="banner">
        <div className="professional p2 grad-blue">
          <div className="wrapper">
            <h2 className="bold-heading">India's Premier Resume Creation Services</h2>
            <div className="maker-flex">
              <div className="content-maker size">
                <p className="cv-p">
                There is a perception that many job seekers don't know how to write a resume.There may be times when you are unsure of the difference between a resume and a CV.You are a recent college graduate struggling for job opportunities, but your resume isn't good enough if it's not written by a professional.A CV requires in-depth analysis and hands-on experience, along with the right CV format and template.To solve all your writing issues we provide the lowest price resume writing services in India. To maintain a long-term relationship with you, we provide free extended support for the next two years. Yes, it is true. We don't let our customers down.Craft your CV online with confidence knowing we appreciate the worth of your investment.In addition, we extend a helping hand to freshers in India and those with up to one-year experience by providing complimentary <a href="resume-writing.php">resume writing services.</a>
                As an added bonus, our clients can also take advantage of our free video resume feature.
                  
                </p>
              </div>
              <div className="four">
                <div className="icon">4</div>
                <p className="cv-p">
                Receive your resume promptly within four business hours
                </p>
                <small className="ft-14 text-red">
                  (Order booked before 3 p.m. are eligible)
                </small>
                <small className="ft-12">(Additional Charges applicable)</small>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" ats_optimised">
        <div className="wrapper">
          <div className="row cv-row">
            <h2 className="sectitle bold-heading">
            Discover the art of crafting ATS-optimized resumes from our team of seasoned writers
            </h2>
            <div className="col-md-12">
              <div className=" ats_optimised_section">
                <p className="cv-p">
                To secure your desired job, it's essential to have a standout resume that distinguishes you from the multitude of other applicants. Our team of experienced writers, who have worked with major corporations, are well-versed in corporate culture and job functions, ensuring that your application receives the attention it deserves.
                </p>
                <p className="cv-p">
                Our diverse team of writers brings expertise from a range of fields, such as technical, finance, marketing, IT, engineering, creative, and more. With exceptional skills, they can effectively help professionals highlight their abilities to HR recruiters.

                </p>
                <p className="cv-p">
                To create a compelling resume that showcases your experience and propels your career to new heights, our writers or managers will reach out to you to gather personal information. Through a blend of original and imaginative writing, we'll craft a resume that captures your essence.
                </p>
                <p className="cv-p">
                To ensure your resume avoids getting lost in an abyss, we format our resumes to be compatible with the ATS (Application Tracking System). This means your application will make it through the initial screening process and reach the hands of human reviewers.

                </p>
                <p className="cv-p">
                Our mission is to deliver thorough resume services that cater to junior, mid-level, and expert-level professionals, helping them elevate their careers and attain greater success in the future.
                </p>

                <h2 className="fea-title">
                 Crafting an ATS-Compatible Resume: Go around with these steps-
                </h2>
                <div className="ats-featured-list">
                  <div className="ats-featured">
                    <div className="list">
                      <ul className="list-group">
                        <li>
                          <span className="tick-cv">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                            </svg>
                          </span>
                          Craft for Targeted Job Profiles
                        </li>
                        <li>
                          <span className="tick-cv">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                            </svg>
                          </span>
                          Align Your Skills to Match the Job Requirements
                        </li>
                        <li>
                          <span className="tick-cv">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                            </svg>
                          </span>
                          Flawless Write: Perfecting Grammar and Syntax
                        </li>
                        <li>
                          <span className="tick-cv">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                            </svg>
                          </span>
                          Craft Customized Resumes for Multiple Job Targets
                        </li>
                      </ul>
                    </div>
                    <div className="list">
                      <ul className="list-group">
                        <li>
                          <span className="tick-cv">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                            </svg>
                          </span>
                          Focus on Your Industry of Interest.
                        </li>
                        <li>
                          <span className="tick-cv">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                            </svg>
                          </span>
                          Your compititors are also smart: So sharpening Your edge in job market
                        </li>
                        <li>
                          <span className="tick-cv">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                            </svg>
                          </span>
                          Optimize Keywords for Maximum Impact (Without Overcrowding)
                        
                        </li>
                        <li>
                          <span className="tick-cv">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                            </svg>
                          </span>
                          Ensure your contact information is up to date and easily accessible
                        
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="row cv-row">
            <div className="col-lg-12">
              <div className="mb-5">
                <div className="text-center">
                  <h2 className="sectitle bold-heading">Commonly Asked Questions</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq">
          <div className="wrapper">
            <div className="row cv-row">
              <div className="col-lg-6">
                <div className="faq-card-cv">
                  <h2>What is two years support?</h2>
                  <p className="cv-p">
                  We value your investment in your career and offer free support for two years to ensure your continued success. Our commitment to your satisfaction is unmatched, as we are the only company in the world to provide such extensive support. Whether you receive a promotion, new project, award, or certification, simply share the new information with us and we'll make the necessary updates to your CV/resume, delivering it directly to your inbox.

                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="faq-card-cv">
                  <h2>Has your CV/resume been tailored for ATS?</h2>
                  <p className="cv-p">
                  We start by understanding your requirements, followed by analyzing the competition and industry trends. Then, we tailor the content of your resume to include the specific keywords and phrases required for the job. This comprehensive process ensures your resume passes the <strong>Applicant Tracking system (ATS)</strong> test and lands in the hands of human reviewers. 
                   
                   
                    All our Cv includes all required keywords with exact phrases
                    which are required for that profile to pass ATS test.
                  </p>
                  <p className="cv-p"></p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="faq-card-cv">
                  <h2>Effortless Resume Creation: Our Simple Process</h2>
                  <p className="cv-p">
                  Creating your new resume is a breeze with our simple process. Begin by selecting the package that best fits your needs, then fill out the form and attach your CV. Make secure online payment using CC Avenue payment gateway for Indian banks or cards issued by MNC banks in India, or PayPal gateway for international clients. Within 24 working hours, our team will reach out to you to discuss your expectations. Wait for the agreed-upon time frame as per your package, and your new resume will be delivered directly to your inbox. For any modifications, please refer to our FAQ section.

                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="faq-card-cv">
                  <h2>Will anybody contact me before writing Cv?</h2>
                  <p className="cv-p">
                  Yes! Once your order is placed, a dedicated coordinator will be assigned to you who will contact you within 24 hours. You can expect a call directly if you are based in India, and if you are an international client, you can expect to be contacted via WhatsApp, Skype, or email. Alternatively, you can also reach out to us during working hours through our online chat or missed call facility available on our portal.

                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="faq-card-cv">
                  <h2>What about Unlimited modifications?</h2>
                  <p className="cv-p">
                  We strive to meet your expectations by offering unlimited modification support during the development stage of your CV/Resume. While our record shows that 97% of our clients approve their CVs on the first attempt, we welcome any suggestions or modifications you may have. Additionally, we offer two years of CV update support after the final product is delivered.

                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="faq-card-cv">
                  <h2>What is Infographic Cv/Visual Cv?</h2>
                  <p className="cv-p">
                  An infographic CV or visual CV is a creative and visually appealing way to present your professional skills, work experience, and achievements. It uses design elements such as graphics, charts, and other visual aids to highlight your strengths and qualifications, making it a unique and eye-catching alternative to traditional CVs.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section services */}
      <section className="why-choose">
        <div className="container">
          <div className="mentorpal-sec">
            <div className="row cv-row">
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic2} />
                  </div>
                  <div className="serve-cont">
                    <h4>Infographic CV</h4>
                    <p className="cv-p">
                    An infographic CV is a type of CV that presents information using visuals, graphics, and other design elements. It is a creative and visually appealing way to showcase your skills, work experience, and achievements, making it stand out from traditional text-based CVs.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic6} />
                  </div>
                  <div className="serve-cont">
                    <h4>Digital CV/ Web CV</h4>
                    <p className="cv-p">
                    A digital CV or web CV is an online version of a traditional CV that is hosted on a website or a personal domain. It can include various multimedia elements such as images, videos, and hyperlinks, making it more interactive and engaging than a paper-based CV.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic7} />
                  </div>
                  <div className="serve-cont">
                    <h4>Online CV Storage</h4>
                    <p className="cv-p">
                    We offer a secure and private server to store your CV in either PDF or Word format. You will have 24/7 live access to your CV, and you can easily share the URL with anyone you choose. With just one click, the viewer can download your CV, ensuring that only authorized people can view it.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic5} />
                  </div>
                  <div className="serve-cont">
                    <h4>Standard operating procedure</h4>
                    <p className="cv-p">
                    Our resume crafting process follows a rigorous standard operating procedure, ensuring that every document we produce meets high standards of quality and professionalism. From initial consultation to final draft, we prioritize clear communication, attention to detail, and a focus on our clients' unique strengths and experiences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic6} />
                  </div>
                  <div className="serve-cont">
                    <h4>Reach to our Seasoned</h4>
                    <p className="cv-p">
                    We encourage clients to reach to our seasoned team of professionals who have years of experience crafting resumes that get results. With our team's expertise, we can help job seekers stand out from the competition and showcase their skills and experience in the best possible light.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic7} />
                  </div>
                  <div className="serve-cont">
                    <h4>International Resume</h4>
                    <p className="cv-p">
                    An international resume is designed to showcase a job seeker's skills and experience in a way that is relevant to a global audience. At our resume making company, we understand the importance of tailoring resumes to specific cultural and professional norms, which is why we offer services to help job seekers create effective international resumes that highlight their unique strengths and qualifications according to international requirement.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic7} />
                  </div>
                  <div className="serve-cont">
                    <h4>Cover Letter</h4>
                    <p className="cv-p">
                    A cover letter is a crucial part of any job application, as it provides job seekers with the opportunity to introduce themselves and explain why they are the ideal candidate for a particular position. At Mentorpal, we specialize in crafting compelling cover letters that grab the attention of hiring managers and help job seekers stand out in a crowded job market.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic7} />
                  </div>
                  <div className="serve-cont">
                    <h4>Motivational Letter</h4>
                    <p className="cv-p">
                    A motivational letter, also known as a personal statement or letter of intent, is a document that job seekers can use to explain their motivations and goals for a particular career path. At Mentorpal, we offer services to help job seekers craft effective motivational letters that showcase their unique skills, experiences, and aspirations, helping them make a strong case for why they are the best fit for a particular job or educational program.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic7} />
                  </div>
                  <div className="serve-cont">
                    <h4>Mock Interview</h4>
                    <p className="cv-p">
                    Mock interviews are a valuable tool for job seekers looking to improve their interviewing skills and gain confidence. At our resume making company, we offer mock interview services to help job seekers practice answering common interview questions, receive feedback on their performance, and identify areas for improvement. With our help, job seekers can feel more prepared and confident when it comes time for the real interview.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="mentorpal-box">
                  <div className="icon">
                    <img alt="icon" src={ic7} />
                  </div>
                  <div className="serve-cont">
                    <h4>Job Searching</h4>
                    <p className="cv-p">
                    Job searching can be a challenging and overwhelming process, but with the guidance and support of a mentor, it can become a more manageable and rewarding experience. At Mentorpal, our resume making and mentoring company, we offer personalized services to help job seekers identify their strengths and career goals, optimize their resumes and cover letters, and develop effective job search strategies. Our mentors provide expert advice and support to help job seekers navigate the job market and land their dream job.


                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonial ->what our national and international client says about us */}
      <section className="top-mentor-sec">
        <div className="container">
          <div className="inner-title">
            <h3>Testimonials</h3>
            <h2>What Our Domestic And Global Client Says About Us</h2>
          </div>
          <div className="row cv-row">
            {topMentors?.map((item, idx) => (
              <div
                onClick={() => viewProfile(item.user_id)}
                key={idx}
                className="col-6 col-sm-6 col-md-3"
              >
                <div className="top-mentor-box">
                  <div className="mentor-img">
                    {item?.profile_photo && item?.profile_photo != "" ? (
                      // <img alt="icon" src={item.profile_photo} />
                      <img
                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}${item?.profile_photo}`}
                        alt=""
                      />
                    ) : (
                      <img
                        alt="icon"
                        src={
                          "https://www.kindpng.com/picc/m/41-414749_beauty-face-girl-head-portrait-profile-people-tete.png"
                        }
                      />
                    )}
                  </div>
                  <div className="top-mentor-cont">
                    <h4>{item.name}</h4>
                    <p className="cv-p">{item.title ? item.title : ""}</p>
                    <div className="review-cls">
                      <span className="star">
                        <img alt="icon" src={star} />
                      </span>
                      <span className="star">
                        <img alt="icon" src={star} />
                      </span>
                      <span className="star">
                        <img alt="icon" src={star} />
                      </span>
                      <span className="star">
                        <img alt="icon" src={star} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="enquiryform">
        <div className="contact-form">
          <h1>Enquiry Form</h1>
          <form action="" className="enquiry cf">
            <div className="form-details left cf">
              <input type="text" id="input-name" placeholder="Name" />
              <input
                type="email"
                id="input-email"
                placeholder="Email address"
              />
              <input type="text" id="input-subject" placeholder="Subject" />
            </div>
            <div className="form-deatils right cf">
              <textarea
                name="message"
                type="text"
                id="input-message"
                placeholder="Message"
              ></textarea>
            </div>
            <input type="submit" value="Submit" id="input-submit"/>
          </form>
        </div>
      </section>

      {/* Not added yet */}
      <Footer />
    </>
  );
};

export default CV;
