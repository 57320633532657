import React, { useEffect } from "react";
import { useState } from "react";
import { REACT_USER_ID } from "../auth/tokenProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotesAction,
  getNotesAction,
  updateNotesAction,
} from "../../redux/actions/mentor";
import { TailSpin } from "react-loader-spinner";

const Notes = (props) => {
  const { ConnectionListData } = props;

  const dispatch = useDispatch();

  const [topic, settopic] = useState("");
  const [notes_title, setnotes_title] = useState("");
  const [date, setDate] = useState("");
  const [target_user_id, settarget_user_id] = useState("");
  const [notes_description, setnotes_description] = useState("");

  // Updated State
  const [updatedTopic, setUpdatedTopic] = useState({});
  const [updatedRelated_to, setUpdatedRelated_to] = useState({});
  const [updatedDescription, setUpdatedDescription] = useState({});

  const [editVisibility, setEditVisibility] = useState(false);

  const isLoading = useSelector((state) => state.getNotesReducer?.loading);
  const addNoteSuccess = useSelector((state) => state.addNotesReducer);
  const addNotesLoading = useSelector((state) => state.addNotesReducer.loading);
  const updateNotesLoading = useSelector(
    (state) => state.updateNotesReducer.loading
  );
  const mentor_id = REACT_USER_ID;

  // Get the current date
  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const getNotesFunction = () => {
    dispatch(getNotesAction(mentor_id));
  };

  // Set the current date when the component is mounted
  useState(() => {
    getNotesFunction();
  }, []);
  useState(() => {
    setDate(getCurrentDate());
  }, []);

  const handleAddNote = () => {
    // Create the data object with the input values
    const data = {
      topic,
      notes_title,
      target_user_id,
      date,
      notes_description,
    };
    console.log("data", data);
    dispatch(addNotesAction(data));

    if (addNotesLoading === false) {
      setTimeout(() => {
        getNotesFunction(); // Call the function when addNotesLoading is true
      }, 3000); // 3 seconds delay
    }
  };

  const getNotesData = useSelector((state) => state.getNotesReducer?.data);
  console.log("addNotes", getNotesData);

  const updateNotes = (notesId, id) => {
    // Update the notes using the provided notesId and updated data
    const Updateddata = {
      date,
      topic: updatedTopic[id],
      related_to: updatedRelated_to[id],
      notes_description: updatedDescription[id],
      notesId,
    };
    console.log("updateNote notes_ids", notesId, Updateddata);
    setEditVisibility({ ...editVisibility, [id]: false });
    dispatch(updateNotesAction(Updateddata));

    if (updateNotesLoading === false) {
      setTimeout(() => {
        getNotesFunction(); // Call the function when isCancelMeetingLoading is true
      }, 3000); // 3 seconds delay
    }
  };

  const handleEditClick = (id) => {
    setEditVisibility({ ...editVisibility, [id]: true });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <>
      {/* <div className="filter__bar d-flex">
        <div className="filter__sub_item">
          <div id="Filter______Col">
            <select name="" id="" className="filter_____Admin">
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
              <option value="">Filter activity 21/25</option>
            </select>
          </div>
          <div className="all_user_select">
            <select name="" id="" className="filter_____Admin mx-3 px-4">
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
              <option value="">All user</option>
            </select>
          </div>
        </div>
      </div> */}
      <section style={{ background: "transparent" }}>
        <div className="title__activity">
          <h4 className="activity_heading">
            <b>Add a new note </b>
          </h4>
        </div>
        <div className=" Add_____notes mb-3 pb-2">
          <select
            className="text-left form-control border-0 "
            style={{}}
            required
            onChange={(e) => settarget_user_id(e.target.value)}
          >
            <option value=""> Select User</option>
            {ConnectionListData?.map((option) => (
              <option
                key={option?.connection_details?.user_id}
                value={option?.connection_details?.user_id}
              >
                {option?.connection_details?.name}
              </option>
            ))}
          </select>
          <input
            className="form-control  activity_heading border-0 "
            type="text"
            placeholder="Note Title"
            onChange={(e) => setnotes_title(e.target.value)}
          />
          <input
            className="form-control  activity_heading border-0 "
            type="text"
            placeholder="Topic"
            onChange={(e) => settopic(e.target.value)}
          />
          <textarea
            style={{ height: "6rem", fontSize: "1rem" }}
            className="form-control border-0 "
            id="exampleFormControlTextarea1"
            placeholder="Note description"
            rows={10}
            cols={10}
            onChange={(e) => setnotes_description(e.target.value)}
          ></textarea>

          <div className="get-started-cls text-end mb-3 mt-0 pb-2">
            <a href="#">
              <button
                type="button"
                onClick={handleAddNote}
                className="find-btn btn btn-primary"
              >
                Add Note
              </button>
            </a>
          </div>
          {addNotesLoading ? (
            <div className="loader">
              <TailSpin ariaLabel="Loading..." color="#00BFFF" />
            </div>
          ) : null}
          {addNoteSuccess?.isSuccess && (
            <center className="mb-2">
              {" "}
              <p className="mb-2 success_message">
                The note has been added successfully.
              </p>
            </center>
          )}
          {addNoteSuccess?.error && <p>{addNoteSuccess.error}</p>}
        </div>
        {isLoading ? (
          <div className="loader">
            <TailSpin ariaLabel="Loading..." color="#00BFFF" />
          </div>
        ) : getNotesData && getNotesData.length > 0 ? (
          getNotesData.map((notes, id) => (
            <div key={notes.notes_id} className="note-card">
              <div className="accordion pb-3" id={`accordionExample${id}`}>
                <div className="accordion-item Item____Radius">
                  <h2 className="accordion-header" id={`headingOne${id}`}>
                    <button
                      className="accordion-button collapsed Item____Radius"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapseOne${id}`}
                      aria-expanded="false"
                      aria-controls={`collapseOne${id}`}
                    >
                      <span className="w-auto d-flex">
                        <span className="me-3 icon_circle">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="blue"
                            className="bi bi-journal-text"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                            <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                            <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                          </svg>
                        </span>
                        <span className="task_created">
                          Note by <br></br> <b> {notes.sender?.name}</b>
                        </span>
                      </span>
                      <span
                        className="user__Task__list "
                        style={{ fontSize: "12px" }}
                      >
                        <span className="mx-1 due"> Due:{"  "}</span>

                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="#6F7E8E"
                            className="bi bi-calendar mx-1 mb-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                          </svg>
                        </span>
                        <span className="day_time">
                          {formatDate(notes.date)}
                        </span>
                      </span>
                    </button>
                  </h2>
                  <div
                    id={`collapseOne${id}`}
                    className="accordion-collapse collapse "
                    aria-labelledby={`headingOne${id}`}
                    data-bs-parent={`#accordionExample${id}`}
                  >
                    <div className="accordion-body">
                      <div className="activity_item">
                        {editVisibility[notes.notes_id] ? (
                          <div className="Update___content">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Notes Topic
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  updatedTopic[notes.notes_id] || notes.topic
                                }
                                onChange={(e) =>
                                  setUpdatedTopic({
                                    ...updatedTopic,
                                    [notes.notes_id]: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Update Realted To
                              </label>
                              <select
                                className="text-left form-control"
                                style={{
                                  height: "50px",
                                  width: "100%",
                                  borderRadius: "13px",
                                }}
                                required
                                onChange={(e) =>
                                  settarget_user_id(e.target.value)
                                }
                              >
                                <option value="">Select User</option>
                                {ConnectionListData?.map((option) => (
                                  <option
                                    key={option?.connection_details?.user_id}
                                    value={option?.connection_details?.user_id}
                                  >
                                    {option?.connection_details?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlTextarea1"
                                className="form-label"
                              >
                                Note Description
                              </label>
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows={3}
                                value={
                                  updatedDescription[notes.notes_id] ||
                                  notes.notes_descrpition
                                }
                                onChange={(e) =>
                                  setUpdatedDescription({
                                    ...updatedDescription,
                                    [notes.notes_id]: e.target.value,
                                  })
                                }
                              ></textarea>
                              <div className="get-started-cls text-end mb-3 mt-0">
                                <a href="#">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      updateNotes(
                                        notes.notes_id,
                                        notes.notes_id
                                      )
                                    }
                                    className="find-btn btn btn-primary"
                                  >
                                    Update Note
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-xs-2  d-flex justify-content-end">
                              <span
                                className="edit___btn_icon"
                                onClick={() => handleEditClick(notes.notes_id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                  class="bi bi-pencil-square mt-3"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="col-xs-10">
                              <p className="internal_heading">Topic:</p>
                              <p className="internal_heading">{notes.topic}</p>
                              <p className="internal_heading">Title:</p>

                              <p className="internal_heading">
                                {notes.notes_title}
                              </p>
                               <p className="internal_heading">Description:</p>

                            <p className="internal_para">
                              {notes.notes_description}
                            </p>
                            </div>
                           
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className=" Add_____notes mb-3 p-4 text-center">
            <h2>No Notes To display</h2>
          </div>
        )}
      </section>
    </>
  );
};

export default Notes;
