import { Col, Modal, Row, Typography } from "antd";
import { useEffect, useState } from "react";

const { Title, Paragraph } = Typography;

const ConfirmationDialogue = ({ show, onConfirm, onClose, title, content }) => {
  const [open, setOpen] = useState();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Modal
      onCancel={onClose}
      open={open}
      onOk={onConfirm}
      centered
      width={350}
      className="internal-page-modal"
      title={
        <Title
          style={{
            fontSize: "1.8rem",
            fontWeight: "700",
          }}
        >
          {title}
        </Title>
      }
    >
      <Row>
        <Col span={24}>
          <Paragraph>{content || "Are you sure you?"}</Paragraph>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmationDialogue;
