import React, { useState } from 'react'
import { WhatsAppOutlined, MailOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Divider,
    Modal,
    Row,
    Typography,
    message,
} from "antd";
import { AiFillSlackSquare } from "react-icons/ai";
import { FaInstagramSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import { LuCopy } from "react-icons/lu";
const { Paragraph } = Typography;
const Help = ({ showSuppportModal, setSupportModalVisibility }) => {

    const shareoptions = [
        {
            id: 1,
            title: "Facebook",
            analysisEvent: "Facebook",
            icon: <AiFillSlackSquare size={32} color="rgba(0, 0, 0, 0.49)" />,
            link: "https://www.facebook.com/profile.php?id=100090574942977",

        },
        {
            id: 2,
            title: "Linkedin",
            analysisEvent: "Linkedin",
            icon: <FaLinkedin size={32} color="rgba(0, 0, 0, 0.49)" />,
            link: "https://www.linkedin.com/company/mentorpal",

        },
        {
            id: 3,
            title: "Twitter",
            analysisEvent: "Twitter",
            icon: <FaTwitterSquare size={32} color="rgba(0, 0, 0, 0.49)" />,
            link: "https://twitter.com/mentorpal",

        },
        {
            id: 4,
            title: "Instagram",
            analysisEvent: "Instagram",
            icon: <FaInstagramSquare size={32} color="rgba(0, 0, 0, 0.49)" />,
            link: "https://www.instagram.com/mentorpalai",

        },
    ];
    const supportOptions = [
        // {
        //     id: 1,
        //     title: "Help articles",
        //     icon: <RightOutlined size={18} />,
        //     link: "#",

        // },
        {
            id: 2,
            title: "help@mentorpal.ai",
            showImage: <MailOutlined className="side-image" size={18} />,
            icon: (
                <Button icon={<LuCopy size={30} />} className="email-copy-btn">
                    Copy
                </Button>
            ),
            link: "mailto:help@mentorpal.ai?subject=Your%20Subject&body=Your%20Body"
        },
        {
            id: 3,
            title: "Reach out on Whatsup",
            showImage: <WhatsAppOutlined className="side-image" size={30} />,
            link: "https://api.whatsapp.com/send?phone=918619825569&text=Hi",
        },
    ];
    const optionClicked = (source) => {

        window.open(source?.link, "_blank");
    };

    return (
        <Modal
            closable={false}
            open={showSuppportModal}
            footer={[
                <Paragraph key="socials">
                    <Divider />
                    <Row
                        gutter={16}
                        align="middle"
                        style={{ padding: "0px 24px 24px 24px" }}
                    >
                        {shareoptions &&
                            shareoptions.map((option) => (
                                <Col key={option?.id}>
                                    <Paragraph
                                        className="cursor-hover"
                                        onClick={() => {
                                            optionClicked(option);
                                        }}
                                    >
                                        {option?.icon}
                                    </Paragraph>
                                </Col>
                            ))}
                        <Col>
                            <Paragraph className="desc">Join the community</Paragraph>
                        </Col>
                    </Row>
                </Paragraph>,
            ]}
            className="share-options"
            centered
            width={400}
            bodyStyle={{ borderRadius: "24px", padding: "24px 18px" }}
        >
            <Row justify="space-between" className="header">
                <Paragraph className="title">Help centre</Paragraph>
                <Paragraph
                    className="close-share mx-auto"
                    onClick={() => {
                        setSupportModalVisibility(false);
                    }}
                >
                    &#x2715;
                </Paragraph>
            </Row>
            <Paragraph className="desc">
                Facing any issues or want to connect with the community? Look no
                further
            </Paragraph>
            <Col className="sharing-sites" style={{ padding: "0px" }}>
                <Col className="option-list" style={{ padding: "0px" }}>
                    {supportOptions &&
                        supportOptions.map((option) => (
                            <Row
                                justify="space-between"
                                key={option.id}
                                className="social-option"
                                onClick={() => {
                                    if (option?.id == 2) {
                                        navigator.clipboard.writeText("help@mentorpal.ai");

                                        message.success("Email copied to clipboard");
                                    }
                                    option?.link && window.open(option?.link, "_blank");
                                }}
                            >
                                <Paragraph className="sites">

                                    {option?.showImage}

                                    {option?.title}
                                </Paragraph>
                                <Paragraph>{option.icon}</Paragraph>
                            </Row>
                        ))}
                </Col>
            </Col>
        </Modal>
    )
}

export default Help