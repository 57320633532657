import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import { useEffect, useState } from "react";
import { mentorEbooks } from "../../../redux/actions/mentor";
import { TailSpin } from "react-loader-spinner";
import { bookImg } from "../../../assets/images/export-images";
import { downloadFile } from "../Data/Data";
import { Image } from "antd";

const MyMentorEbooks = () => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(null);
  useEffect(() => {
    dispatch(mentorEbooks(REACT_USER_ID));
  }, [dispatch]);
  const mentorEbooksReducer = useSelector((state) => {
    return state.mentorEbooksReducer;
  });

  useEffect(() => {
    const { data, loading, error, status } = mentorEbooksReducer
    setloading(loading)
    if(data && !loading){
      setdata(data)
    }
  }, [mentorEbooksReducer])
  
  const dateFormater = (date) => {
    const monthArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let newDate = new Date(date);
    let formatedDate =
      ("0" + newDate.getDate()).slice(-2) +
      " " +
      monthArray[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      ("0" + newDate.getHours()).slice(-2) +
      ":" +
      ("0" + newDate.getMinutes()).slice(-2);
    return formatedDate;
  };
  return (
    <>
      <div className=" w-100" >
        {loading ? (
          <div className="loader">
            <TailSpin ariaLabel="Loading..." color="#00BFFF" />
          </div>
        ) : (
          <div className="ebook-manage-cls">
            <div className="ebook-download-sec">
              <div className="ebook-bg">
                <div className="ebbok-title-cls">
                  <h3>Your E-Books</h3>
                </div>
                  {data?.length > 0 ? (
                  <>
                      {data?.map((book) => (
                      <div className="single-book-cls">
                        <div className="signle-book-left">
                          <div className="prd-book-dtl">
                            <div className="book-img">
                                <Image
                                  width={200}
                                  src={book?.picture !== "" ? `${process.env.REACT_APP_IMAGES_BASE_URL}${book?.picture}` : bookImg} 
                                />
                            </div>
                            <div className="book-cont">
                              <h5>{book?.title}</h5>
                              <p>Author: {book?.author}</p>
                              <p>Description: {book?.description}</p>
                              <p>Book objective: {book?.book_objective}</p>
                              <p className="price-p">Price: ${book?.price}</p>
                            </div>
                          </div>
                          <div className="book-download-sec">
                            <p>
                              Publish Date :{" "}
                              {dateFormater(book?.last_modified_at)}
                            </p>
                              <button type="button" className="btn btn-green" onClick={()=>downloadFile(book?.picture)}>
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div>
                    <hr />
                    <div style={{ "text-align": "center", "padding-bottom": "1rem" }}>
                      <h2>No Book Found</h2>
                      {/* hello */}
                      <p>You haven't added any book</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

    </>
  )
}
export default MyMentorEbooks;