import { InfoCircleOutlined } from "@ant-design/icons";
import { Table, Tooltip, Typography } from "antd";
import { useState } from "react";
import { formatNumber } from "../Data/Data";
import CurrencyDisplay from "./Modal/CurrencyDisplay";

const { Paragraph } = Typography;

const { Text } = Typography;

const BalanceTable = (props) => {
    const [bottom] = useState("bottomLeft");
    const { tableData, currency } = props;
    const dataSource = tableData;

    const getHeaderTitle = (title) => (
        <Paragraph
            style={{
                fontSize: 14,
                fontWeight: 600,
            }}
        >
            {title}
        </Paragraph>
    );

    const columns = [
        {
            title: getHeaderTitle("Item"),
            dataIndex: "title",
            key: "title",
            width: 300,
            minWidth: 200,
        },
        {
            title: getHeaderTitle("Total Count"),
            dataIndex: "total_bookings",
            key: "total_bookings",
            width: 200,
            minWidth: 150,
            responsive: ["sm"],
        },
        {
            title: getHeaderTitle("Completed"),
            dataIndex: "completed_bookings",
            key: "completed_bookings",
            width: 200,
            minWidth: 150,
            responsive: ["sm"],
        },
        {
            title: (
                <Paragraph
                    style={{
                        display: "inline-flex",
                    }}
                >
                    {getHeaderTitle("Total Earnings")}
                    <Tooltip title="Total amount of all the completed calls and priority DM">
                        <InfoCircleOutlined
                            style={{
                                marginLeft: "4px",
                            }}
                        />
                    </Tooltip>
                </Paragraph>
            ),
            dataIndex: "earnings",
            key: "earnings",
            width: 300,
            minWidth: 250,
            render: (text) => {
                return {
                    props: {},
                    children: text ? (
                        <Text style={{ display: "flex", alignItems: "center" }}>
                            <CurrencyDisplay currency={currency} grayscaled={true} />
                            {formatNumber(text)}
                        </Text>
                    ) : (
                        "0"
                    ),
                };
            },
        },
    ];

    return (
        <Table
            className="payments-table"
            style={{
                marginTop: 20,
            }}
            dataSource={dataSource}
            columns={columns}
            bordered
            pagination={{
                position: [bottom],
                pageSize: 10,
                responsive: true,
                showSizeChanger: false,
                hideOnSinglePage: true,
                defaultCurrent: 1,
            }}
        />
        
    );
};

export default BalanceTable;
