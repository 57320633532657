import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Cardsdata from './CardsData'
import '../TeamPage/ourTeam.css';

const Cards = () => {

  const [data, setData] = useState(Cardsdata);


  return (
    <div className='container  cards-padding-top'>
     
      <div className="row d-flex justify-content-center align-items-center gx-2">
     
        {
          data.map((element, id) => {
            return (
              <>
              <div className='col-lg-3 col-md-4 col-sm-6' >
                <Card className="mx-2 mt-4 card-style">
                    <div className='img-container'>
                  <Card.Img variant="top" src={element.imgdata} style={{height:"18rem"}} />
                  <div className="button_div d-flex justify-content-center card-img-overlay" style={{paddingTop:"13rem"}}>
                  <div className='overlay-button'>
                    <button className=" show-your-details">
                      {element.fname}
                    <br/>
                    {element.profession}
                    </button>
                    </div>
                    </div>
                    </div>
                </Card>
                </div>
              </>
            )
          })
        }
  
      </div>
    </div>
  )
}

export default Cards