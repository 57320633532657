import {
  emailConstants,
  otpConstants,
  loginConstants,
  passwordLoginConstants,
  signUpConstants,
  socialLoginConstants,
  updateProfileConstants,
  signUpVerifyConstants,
  forgetPasswordConstants,
  userDetailsConstants,
  logoutConstants,
  updateUserPasswordConstants,
} from "../../constants";

export function generateOtp(data) {
  return { type: otpConstants.GENERATE_OTP_REQUEST, data };
}

export function verifyEmail(data) {
  return { type: emailConstants.VERIFY_REQUEST, data };
}
export function login(data) {
  return { type: loginConstants.LOGIN_REQUEST, data };
}
export function passwordLogin(data) {
  return { type: passwordLoginConstants.PASSWORD_LOGIN_REQUEST, data };
}

export function signUpAction(data) {
  return { type: signUpConstants.SIGN_UP_REQUEST, data };
}
export function socialLogin(data) {
  return { type: socialLoginConstants.SOCIAL_LOGIN_REQUEST, data };
}

export function updateProfile(data) {
  return { type: updateProfileConstants.UPDATE_PROFILE_REQUEST, data };
}

export function signUpVerify(data) {
  return { type: signUpVerifyConstants.SIGNUP_VERIFY_REQUEST, data };
}

export function forgetPassword(data) {
  return { type: forgetPasswordConstants.FORGET_PASSWORD_REQUEST, data };
}

export function getUserDetails(data) {
  return { type: userDetailsConstants.USER_DETAILS_REQUEST, data };
}

export function logoutUser(data) {
  return { type: logoutConstants.LOGOUT_REQUEST, data };
}
export function updateUserPassword(data) {
  return {
    type: updateUserPasswordConstants.UPDATE_USER_PASSWORD_REQUEST,
    data,
  };
}
