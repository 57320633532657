import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./ResultTest.css";
import eight from "./assets/eight.png";
import first from "./assets/first.png";
import five from "./assets/five.png";
import one from "./assets/one.png";
import six from "./assets/six.png";
import three from "./assets/three.png";
import Footer from "../../footer";
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";
import Header from "../../header";
import { useState } from "react";
import { TailSpin } from "react-loader-spinner";

const PersonalityTestResult = () => {

  const [isLoading, setIsLoading] = useState(true);

  const testResponseData = useSelector((state) => {
    if(isLoading && !state.testResponseReducer.loading)
      setIsLoading(state.testResponseReducer.loading);
    console.log(isLoading); 
    return state.testResponseReducer.data;
  });
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
  );

  let extraverted,introvert,judging,assertive,intuit,think,feel,observent,prospect,turubelent;

  useEffect(() => {
    if(testResponseData !== undefined){
      console.log("TestResponse", testResponseData);
      // Data const value form the Answer api
      extraverted = testResponseData.extraversion;
      introvert = testResponseData.introversion;
      judging = testResponseData.judging; //prospecting
      assertive = testResponseData.perceiving;
      intuit = testResponseData.intuition; //observent
      think = testResponseData.thinking;
      feel = testResponseData.feeling;
      observent = 100 - intuit;
      prospect = 100 - judging;
      turubelent = 100 - assertive;
      console.log("dfghj",extraverted,introvert,judging,assertive,intuit,think,feel,observent,prospect,turubelent);
    }
  }, [isLoading]);
  
  return (
    <>
      {/* { loggedIn ? <ProtectedHeader /> : <Header />} */}
      {loggedIn ? (
        <div style={{ marginTop: "0" }}>
          <ProtectedHeader extraClasses="inner-header" />
        </div>
      ) : (
        <Header extraClasses="inner-header" />
      )}

      {
        isLoading ? (
          <div className="loader">
            <TailSpin ariaLabel="Loading..." color="#00BFFF" />
          </div>
        ) : (
          <>
          <div id="carouselExampleIndicators" className="carousel slide container">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="cards your">
                  <div className="row">
                    <div className="col center">
                      <h4>Your personality type is:</h4>
                      <h3 style={{ fontSize: "2rem" }}>Virtuoso</h3>
                      <h4 className="gold">ISTP-A</h4>
                    </div>
                    <div className="row">
                      <div className="col center">
                        <img className="virtuoso" src={first} alt="" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p className="cards-text my-3 center">
                          Virtuosos are innovative and practical experimenters,
                          masters of all kinds of tools.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col bg">
                    <h3 className="head">Mind</h3>
                  </div>
                </div>
                <div className="cards">
                  <div className="row g-0">
                    <div className="col-md-5 images mt-5">
                      <img className="pic" src={one} alt="..." />
                    </div>
                    <div className="col-md-7 images">
                      <div className="cards-body">
                        {introvert > extraverted ? (
                          <h2 className="cards-title mt-4 mb-4">Introverted</h2>
                        ) : (
                          <h2 className="cards-title mt-4 mb-4">Extraverted</h2>
                        )}
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-label="Segment three"
                            style={{ width: `${extraverted}%` }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                          <div
                            className="progress-bar-two"
                            role="progressbar"
                            aria-label="Segment one"
                            style={{ width: `${introvert}%` }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span style={{ display: "flex", textAlign: "left" }}>
                          <div>
                            <div className="per-two">
                              {testResponseData.extraversion}%
                            </div>
                            <div className="per-two">EXTRAVERTED</div>
                          </div>
                          <div style={{ marginLeft: "auto", textAlign: "end" }}>
                            <div className="per">
                              {testResponseData.introversion}%
                            </div>
                            <div className="per">INTROVERTED</div>
                          </div>
                        </span>
                        <p className="cards-text my-4">
                          Introverted individuals tend to prefer fewer, yet deep and
                          meaningful, social interactions and often feel drawn to
                          calmer environments.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* */}
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col bg">
                    <h3 className="head">Energy</h3>
                  </div>
                </div>
                <div className="cards">
                  <div className="row g-0">
                    <div className="col-md-5 images mt-5">
                      <img className="pic" src={eight} alt="..." />
                    </div>
                    <div className="col-md-7 images">
                      <div className="cards-body">
                        {intuit > observent ? (
                          <h2 className="cards-title mt-4 mb-4">INTUITIVE</h2>
                        ) : (
                          <h2 className="cards-title mt-4 mb-4">OBSERVANT</h2>
                        )}
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-label="Segment three"
                            style={{ width: `${intuit}%` }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                          <div
                            className="progress-bar-two"
                            role="progressbar"
                            aria-label="Segment one"
                            style={{ width: `${observent}%` }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span style={{ display: "flex", textAlign: "left" }}>
                          <div>
                            <div className="per-two">
                              {testResponseData.intuition}%{" "}
                            </div>
                            <div className="per-two">INTUITIVE</div>
                          </div>
                          <div style={{ marginLeft: "auto", textAlign: "end" }}>
                            <div className="per">{observent}%</div>
                            <div className="per">OBSERVANT</div>
                          </div>
                        </span>
                        <p className="cards-text my-4">
                          Observant individuals are pragmatic and down-to-earth.
                          They tend to have a strong focus on what is happening or
                          very likely to happen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col bg">
                    <h3 className="head">Nature</h3>
                  </div>
                </div>
                <div className="cards">
                  <div className="row g-0">
                    <div className="col-md-5 images mt-5">
                      <img className="pic" src={three} alt="..." />
                    </div>
                    <div className="col-md-7 images">
                      <div className="cards-body">
                        {think > feel ? (
                          <h2 className="cards-title mt-4 mb-4">THINKING</h2>
                        ) : (
                          <h2 className="cards-title mt-4 mb-4">FEELING</h2>
                        )}
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-label="Segment three"
                            style={{ width: `${think}%` }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                          <div
                            className="progress-bar-two"
                            role="progressbar"
                            aria-label="Segment one"
                            style={{ width:`${feel}%` }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span style={{ display: "flex", textAlign: "left" }}>
                          <div>
                            <div className="per-two">
                              {testResponseData.thinking}%
                            </div>
                            <div className="per-two">THINKING</div>
                          </div>
                          <div style={{ marginLeft: "auto", textAlign: "end" }}>
                            <div className="per">{testResponseData.feeling}% </div>
                            <div className="per">FEELING</div>
                          </div>
                        </span>
                        <p className="cards-text my-4">
                          Thinking individuals focus on objectivity and rationality,
                          often dismissing emotions in favor of logic. They tend to
                          see effectiveness as more important than social harmony.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col bg">
                    <h3 className="head">Tactics</h3>
                  </div>
                </div>
                <div className="cards">
                  <div className="row g-0">
                    <div className="col-md-5 images mt-5">
                      <img className="pic" src={five} alt="..." />
                    </div>
                    <div className="col-md-7 images">
                      <div className="cards-body">
                        {judging > prospect ? (
                          <h2 className="cards-title mt-4 mb-4">JUDGING</h2>
                        ) : (
                          <h2 className="cards-title mt-4 mb-4">PROSPECTING</h2>
                        )}
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-label="Segment three"
                            style={{ width: `${judging}%`}}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                          <div
                            className="progress-bar-two"
                            role="progressbar"
                            aria-label="Segment one"
                            style={{ width:`${prospect}%` }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span style={{ display: "flex", textAlign: "left" }}>
                          <div>
                            <div className="per-two">
                              {testResponseData.judging}%
                            </div>
                            <div className="per-two">JUDGING</div>
                          </div>
                          <div style={{ marginLeft: "auto", textAlign: "end" }}>
                            <div className="per">{prospect}%</div>
                            <div className="per">PROSPECTING</div>
                          </div>
                        </span>
                        <p className="cards-text my-4">
                          Prospecting individuals are very good at improvising and
                          adapting to opportunities. They tend to be flexible
                          nonconformists who value novelty above stability.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col bg">
                    <h3 className="head">Identity</h3>
                  </div>
                </div>
                <div className="cards">
                  <div className="row g-0">
                    <div className="col-md-5 images mt-5">
                      <img className="pic" src={six} alt="..." />
                    </div>
                    <div className="col-md-7 images">
                      <div className="cards-body">
                        {assertive > turubelent ? (
                          <h2 className="cards-title mt-4 mb-4">ASSERTIVE</h2>
                        ) : (
                          <h2 className="cards-title mt-4 mb-4">TURBULENT</h2>
                        )}
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-label="Segment three"
                            style={{ width: `${prospect}%` }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                          <div
                            className="progress-bar-two"
                            role="progressbar"
                            aria-label="Segment one"
                            style={{ width: `${turubelent}%` }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span style={{ display: "flex", textAlign: "left" }}>
                          <div>
                            <div className="per-two">
                              {testResponseData.perceiving}%{" "}
                            </div>
                            <div className="per-two">ASSERTIVE</div>
                          </div>
                          <div style={{ marginLeft: "auto", textAlign: "end" }}>
                            <div className="per">{turubelent}%</div>
                            <div className="per">TURBULENT</div>
                          </div>
                        </span>
                        <p className="cards-text my-4">
                          Assertive individuals are self-assured, even-tempered, and
                          resistant to stress. They refuse to worry too much and
                          tend to be self-confident when striving to achieve goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item mb-4 review">
                <div className="row">
                  <div className="col bg">
                    <h3 className="head">One more thing...</h3>
                  </div>
                </div>
                <div className="cards">
                  <div className="roow g-0">
                    <div className="roww">
                      <p className="before">
                        Before we move on to your profile, would you like an e-mail
                        copy of your results?
                      </p>
                    </div>
                    <div className="input-container">
                      <i className="fa fa-envelope icon" />
                      <input
                        className="input-field"
                        type="email"
                        placeholder="your@email.com"
                      />
                    </div>
                    <div>
                      <p className="right_info">
                        We'll save your results so you can easily access them again.
                      </p>
                    </div>
                    <div>
                      <label>
                        <input type="checkbox" name="agree" defaultValue="yes" />
                        <span className="check">
                          Send me Virtuoso tips and research insights
                        </span>
                      </label>
                    </div>
                    <button className="btnn">Send Result</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon " aria-hidden="true" />
            <span className="visually-hidden ">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
          </>
        )
      }

      {/* footer aded */}

      <Footer />
    </>
  );
};

export default PersonalityTestResult;
