import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMentorCourseAction } from '../../../../redux/actions/course';
import { useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const Advanced = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [data, setData] = useState({ product_id: "" });
    const [loader, setloader] = useState(false);


    const getCourseDetailsReducer = useSelector((state) => state.getCourseDetailsReducer);


    const deleteMentorCourseReducer = useSelector((state) => state.deleteMentorCourseReducer);

    useEffect(() => {
        setloader(getCourseDetailsReducer?.loading)
    }, [getCourseDetailsReducer]);


    useEffect(() => {
        if (deleteMentorCourseReducer?.data && deleteMentorCourseReducer?.loader === false) {
            if (data?.document_type === 'course') {
                navigate("/course")
            }
            if (data?.document_type === 'webinar') {
                navigate("/webinar")
            }
        }
    }, [deleteMentorCourseReducer, navigate]);


    useEffect(() => {

        if (getCourseDetailsReducer?.data) {
            setData({
                ...data,
                product_id: getCourseDetailsReducer.data.product_id || '',
                document_type: getCourseDetailsReducer.data.document_type || '',
            });
        }
        if (deleteMentorCourseReducer?.data) {
            setData(deleteMentorCourseReducer?.data)
        }
    }, [deleteMentorCourseReducer, getCourseDetailsReducer, data]);



    const handleDeleteCourse = () => {

        dispatch(deleteMentorCourseAction(data?.product_id))
    };
    return (
        <>
            {
                loader ? (<div className="loader open_sessions_loader">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>) : (

                    <div className="row mt-4">
                        <div className="col-12 col-md-4">
                            <h4 className="text-capitalize">Delete {data?.document_type}</h4>
                        </div>
                        <div className="col-12 col-md-8  p-4">
                            <div className="form-group">
                                This will permanently delete your {data?.document_type}. Though learners who have purchased it will continue to have access until their subscription ends.
                            </div>
                            <br />
                            <button type="button" className="btn btn-success certificate_savebtn" onClick={handleDeleteCourse}>
                                <span className="text-capitalize">Delete {data?.document_type}</span>
                            </button>
                        </div>
                    </div>

                )
            }
            {
                data?.acknowledged === true ? ("Course delete Succesfully") : ("Error Course not delete yet ")
            }

            {/* Delete Course Section */}

        </>
    );
}

export default Advanced;
