import React, { useEffect, useState } from "react";
import BasicDetailsForm from "./Step/BasicDetailsForm";
import WorkDetails from "./Step/WorkDetails";

import RequirementsForm from './Step/RequirementForm';
import { ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import Header from './../../applyAsMentor/BecomeMentorHeader/index';
import { Button, message } from "antd";
import '../../OnboardingMentor/onBoarding.css'
import '../../OnboardingMentor/onboarding.Main.css'
import Overlay from "../../applyAsMentor/Overlay";
import { updateProfile } from "../../../redux/actions/login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ProfessionalDetails from "./Step/ProfessionalDetails";

const ApplyAsMentee = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [messageApi, contextHolder] = message.useMessage();
    const [data, setData] = useState({});
    const [state, setState] = useState(1);
    const [currState, setCurrState] = useState(1);

    const userDetails = useSelector((state) => {
        const data = state.userDetailsReducer?.data || [];
        return data[0];
    });
    const USER_ID = localStorage.getItem("REACT_USER_ID");
    console.log("------------------------------------------------");
    console.log(userDetails);
    console.log("------------------------------------------------");
    const [registrationProcessData, setRegistrationProcessData] = useState({
        country: userDetails?.country ? userDetails.country : "",
        language: userDetails?.language ? userDetails.language : [],
        experties: userDetails?.experties ? userDetails.experties : [],
        experience: userDetails?.experience ? userDetails.experience : "",
        company_or_school: userDetails?.company_or_school
            ? userDetails.company_or_school
            : "",
        title: userDetails?.title ? userDetails.title : "",
        linkedin_url: userDetails?.linkedin_url ? userDetails.linkedin_url : "",
        mobile: userDetails?.mobile ? userDetails.mobile : "",
        profile_photo: userDetails?.profile_photo ? userDetails?.profile_photo : "",
        goals: userDetails?.goals ? userDetails.goals : "",
        year: userDetails?.year ? userDetails.year : "",
        course: userDetails?.course ? userDetails.course : "course ",
        user_id: USER_ID,
        timezone: userDetails?.timezone ? userDetails.timezone : "",

    });

    const onValidate = (value, name) => {
        console.log(value, name)
        setCardOneErrors((prev) => ({
            ...prev,
            [name]: { ...prev[name], errorMsg: value },
        }));
    };
    const onValidateTwo = (value, name) => {
        setCardTwoErrors((prev) => ({
            ...prev,
            [name]: { ...prev[name], errorMsg: value },
        }));
    };
    const onValidateThree = (value, name) => {
        setCardThreeErrors((prev) => ({
            ...prev,
            [name]: { ...prev[name], errorMsg: value },
        }));
    };
    const onValidateFour = (value, name) => {
        setCardFourErrors((prev) => ({
            ...prev,
            [name]: { ...prev[name], errorMsg: value },
        }));
    };


    const [cardOneErrors, setCardOneErrors] = useState({
        country: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidate,
        },
        title: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidate,
        },
        language: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidate,
        },
        mobile: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidate,
        }
    });

    const [cardTwoErrors, setCardTwoErrors] = useState({
        experties: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidateTwo,
        },

        // linkedin_url: {
        //     isReq: true,
        //     errorMsg: "",
        //     onValidateFunc: onValidateTwo,
        // },
        course: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidateTwo,
        },
        year: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidateTwo,
        }
    });

    const [cardThreeErrors, setCardThreeErrors] = useState({
        goals: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidateThree,
        },

    });
    const [cardFourErrors, setCardFourErrors] = useState({
        experties: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidateFour,
        },
        // linkedin_url: {
        //     isReq: true,
        //     errorMsg: "",
        //     onValidateFunc: onValidateFour,
        // },
        experience: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidateFour,
        },
        company_or_school: {
            isReq: true,
            errorMsg: "",
            onValidateFunc: onValidateFour,
        },

    });

    const validateFormOne = () => {
        let isInvalid = false;
        Object.keys(cardOneErrors).forEach((x) => {
            const errObj = cardOneErrors[x];
            console.log(`Field: ${x}, Value: ${registrationProcessData[x]}, Error: ${errObj.errorMsg}`);
            if (errObj.errorMsg) {
                isInvalid = true;
            } else {
                let type = typeof registrationProcessData[x];
                if (type === "string") {
                    if (errObj.isReq && !registrationProcessData[x]) {
                        isInvalid = true;
                        onValidate(true, x);
                    }
                } else {
                    if (errObj.isReq && !registrationProcessData[x]?.length) {
                        isInvalid = true;
                        onValidate(true, x);
                    }
                }
            }
        });
        return !isInvalid;
    };



    const validateFormTwo = () => {
        let isInvalid = false;
        Object.keys(cardTwoErrors).forEach((x) => {
            const errObj = cardTwoErrors[x];
            console.log(`Field: ${x}, Value: ${registrationProcessData[x]}, Error: ${errObj.errorMsg}`);

            if (errObj.errorMsg) {
                isInvalid = true;
            } else {
                let type = typeof registrationProcessData[x];
                if (type === "string") {
                    if (errObj.isReq && !registrationProcessData[x]) {
                        isInvalid = true;
                        onValidateTwo(true, x);
                    }
                } else {
                    if (errObj.isReq && !registrationProcessData[x]?.length) {
                        isInvalid = true;
                        onValidateTwo(true, x);
                    }
                }
            }
        });
        return !isInvalid;
    };

    const validateFormThree = () => {
        let isInvalid = false;
        Object.keys(cardThreeErrors).forEach((x) => {
            const errObj = cardThreeErrors[x];
            console.log(`Field: ${x}, Value: ${registrationProcessData[x]}, Error: ${errObj.errorMsg}`);

            if (errObj.errorMsg) {
                isInvalid = true;
            } else {
                if (errObj.isReq && !registrationProcessData[x]?.length) {
                    isInvalid = true;
                    onValidateThree(true, x);
                }
            }
        });
        return !isInvalid;
    };
    const validateFormFour = () => {
        let isInvalid = false;
        Object.keys(cardFourErrors).forEach((x) => {
            const errObj = cardFourErrors[x];
            console.log(`Field: ${x}, Value: ${registrationProcessData[x]}, Error: ${errObj.errorMsg}`);

            if (errObj.errorMsg) {
                isInvalid = true;
            } else {
                let type = typeof registrationProcessData[x];
                if (type === "string") {
                    if (errObj.isReq && !registrationProcessData[x]) {
                        isInvalid = true;
                        onValidateFour(true, x);
                    }
                } else {
                    if (errObj.isReq && !registrationProcessData[x]?.length) {
                        isInvalid = true;
                        onValidateFour(true, x);
                    }
                }
            }
        });
        return !isInvalid;
    };
    const changestate = (index) => {
        console.log("state", state, index);

        if (index > 0 && index <= 3) {
            if (state < index) {
                handleSubmit();
            }

            setCurrState(state);
            setState(index);

            switch (index) {
                case 1:
                    setCard(
                        <BasicDetailsForm
                            registrationProcessData={registrationProcessData}
                            errors={cardOneErrors}
                            setRegistrationProcessData={setRegistrationProcessData}
                            state={state}
                            setState={setState}
                        />
                    );
                    break;

                case 2:
                    setCard(
                        registrationProcessData?.title === 'Student' ? (
                            <WorkDetails
                                registrationProcessData={registrationProcessData}
                                errors={cardTwoErrors}
                                setRegistrationProcessData={setRegistrationProcessData}
                            />
                        ) : (
                            <ProfessionalDetails
                                registrationProcessData={registrationProcessData}
                                errors={cardFourErrors}
                                setRegistrationProcessData={setRegistrationProcessData}
                            />
                        )
                    );
                    break;

                case 3:
                    setCard(
                        <RequirementsForm
                            registrationProcessData={registrationProcessData}
                            errors={cardThreeErrors}
                            setRegistrationProcessData={setRegistrationProcessData}
                        />
                    );
                    break;

                default:
                    setCard(
                        <Overlay overlayData={data} setOverlayData={setData} />
                    );
                    break;
            }
        }
    };

    const handlerRequiredSubmit = async () => {
        if (state > 0 && state <= 3) {
            switch (state) {
                case 1:
                    const isValidOne = validateFormOne();
                    if (!isValidOne) {
                        changestate(state);
                        return false;
                    } else {
                        changestate(state + 1);
                        return true;
                    }

                case 2:
                    const isValidTwo = registrationProcessData?.title === "Student"
                        ? validateFormTwo()
                        : validateFormFour();

                    if (!isValidTwo) {
                        changestate(state);
                        return false;
                    } else {
                        changestate(state + 1);
                        return true;
                    }

                case 3:
                    const isValidThree = await validateFormThree();
                    if (!isValidThree) {
                        changestate(state);
                        return false;
                    } else {
                        changestate(state + 1);
                        return true;
                    }

                default:
                    return;
            }
        }
    };


    const [card, setCard] = useState(
        <BasicDetailsForm
            registrationProcessData={registrationProcessData}
            errors={cardOneErrors}
            setRegistrationProcessData={setRegistrationProcessData}
        />
    );



    const handleSubmit = () => {

        dispatch(updateProfile(registrationProcessData));
    };

    useEffect(() => {
        if (state === 3 && registrationProcessData.status === "registered")
            handleSubmit();
    }, [registrationProcessData, state]);

    useEffect(() => {
        if (state === 3)
            setRegistrationProcessData((data) => ({ ...data, status: "registered" }));
    }, [state]);

    useEffect(() => {
        changestate(state);
        return () => { };
    }, [
        cardOneErrors.country?.errorMsg,
        cardOneErrors.language?.errorMsg,
        cardOneErrors.mobile?.errorMsg,
        cardOneErrors.title?.errorMsg,
        cardTwoErrors.experties?.errorMsg,
        cardFourErrors.experties?.errorMsg,

        cardFourErrors.experience?.errorMsg,
        cardFourErrors.company_or_school?.errorMsg,

        cardTwoErrors.course?.errorMsg,
        cardTwoErrors.passing_year?.errorMsg,


        // cardTwoErrors.linkedin_url?.errorMsg,
        // cardFourErrors.linkedin_url?.errorMsg,



        cardThreeErrors.goals?.errorMsg,
    ]);


    return (
        <>
            {contextHolder}
            <Header />

            <div>

                <section
                    className="aapply-mentor-sec"
                    style={{ background: "transparent" }}
                >

                    <div className="container">
                        {state <= 3 ? (
                            <div>
                                <div className="steps-count">
                                    <p className=" mb-1 w-0 font-size-14 steps-position" style={{ width: '0% !important' }}>
                                        <span style={{ color: "black" }}>STEP {state} of 3</span>
                                    </p>
                                </div>
                            </div>
                        ) : null}
                        {/* ---------------------------registration data ------------------------------------------ */}
                        {state === 4 ? (
                            <div className="mt-5 info-container">{card}</div>
                        ) : (
                            <form className=" mx-auto registraion-Process-main-page">
                                <div className="mt-2 info-container">
                                    <div style={{ textAlign: "center", fontSize: "1.1rem" }}>
                                        Mentee Basic information
                                    </div>
                                    <FontAwesomeIcon
                                        className="icons-back"
                                        icon={faArrowLeft}
                                        size="2x"
                                        onClick={() => {
                                            setCurrState(state);
                                            changestate(state - 1);
                                        }}
                                    />
                                    <div className="mt-5 regstration-card-container">
                                        {card}
                                    </div>
                                    <div className="row ">
                                        <div className="col back-btn  ">
                                            <a
                                                className="Back_button "
                                                onClick={() => changestate(state - 1)}
                                            >
                                                Back
                                            </a>
                                        </div>
                                        <div className="col col-md-auto">
                                            {state === 3 ? (

                                                <button
                                                    type="submit"
                                                    className="btn-lg"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleSubmit();
                                                        navigate('/free-membership')

                                                    }}
                                                    disabled={!registrationProcessData.goals}
                                                >
                                                    Submit
                                                </button>

                                            ) : (
                                                <button
                                                    type="button"
                                                    className="btn-lg "
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handlerRequiredSubmit();
                                                    }}
                                                >
                                                    {state === 3 ? "Submit" : "Continue"}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </section>
            </div>


        </>
    );
};

export default ApplyAsMentee;