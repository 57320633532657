export const languages = [
  {
    label: "English",
    value: "English",
  },
  {
    label: "Hindi - हिन्दी",
    value: "Hindi - हिन्दी",
  },
  {
    label: "English (Australia)",
    value: "English (Australia)",
  },
  {
    label: "English (Canada)",
    value: "English (Canada)",
  },
  {
    label: "English (India)",
    value: "English (India)",
  },
  {
    label: "English (United States)",
    value: "English (United States)",
  },
  {
    label: "Afrikaans",
    value: "Afrikaans",
  },
  {
    label: "Albanian - shqip",
    value: "Albanian - shqip",
  },
  {
    label: "Amharic - አማርኛ",
    value: "Amharic - አማርኛ",
  },
  {
    label: "Arabic - العربية",
    value: "Arabic - العربية",
  },
  {
    label: "Aragonese - aragonés",
    value: "Aragonese - aragonés",
  },
  {
    label: "Armenian - հայերեն",
    value: "Armenian - հայերեն",
  },
  {
    label: "Asturian - asturianu",
    value: "Asturian - asturianu",
  },
  {
    label: "Azerbaijani - azərbaycan dili",
    value: "Azerbaijani - azərbaycan dili",
  },
  {
    label: "Basque - euskara",
    value: "Basque - euskara",
  },
  {
    label: "Belarusian - беларуская",
    value: "Belarusian - беларуская",
  },
  {
    label: "Bengali - বাংলা",
    value: "Bengali - বাংলা",
  },
  {
    label: "Bosnian - bosanski",
    value: "Bosnian - bosanski",
  },
  {
    label: "Breton - brezhoneg",
    value: "Breton - brezhoneg",
  },
  {
    label: "Bulgarian - български",
    value: "Bulgarian - български",
  },
  {
    label: "Catalan - català",
    value: "Catalan - català",
  },
  {
    label: "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
    value: "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
  },
  {
    label: "Chinese - 中文",
    value: "Chinese - 中文",
  },
  {
    label: "Chinese (Hong Kong) - 中文（香港）",
    value: "Chinese (Hong Kong) - 中文（香港）",
  },
  {
    label: "Chinese (Simplified) - 中文（简体）",
    value: "Chinese (Simplified) - 中文（简体）",
  },
  {
    label: "Chinese (Traditional) - 中文（繁體）",
    value: "Chinese (Traditional) - 中文（繁體）",
  },
  {
    label: "Corsican",
    value: "Corsican",
  },
  {
    label: "Croatian - hrvatski",
    value: "Croatian - hrvatski",
  },
  {
    label: "Czech - čeština",
    value: "Czech - čeština",
  },
  {
    label: "Danish - dansk",
    value: "Danish - dansk",
  },
  {
    label: "Dutch - Nederlands",
    value: "Dutch - Nederlands",
  },
  {
    label: "English (New Zealand)",
    value: "English (New Zealand)",
  },
  {
    label: "English (South Africa)",
    value: "English (South Africa)",
  },
  {
    label: "English (United Kingdom)",
    value: "English (United Kingdom)",
  },
  {
    label: "Esperanto - esperanto",
    value: "Esperanto - esperanto",
  },
  {
    label: "Estonian - eesti",
    value: "Estonian - eesti",
  },
  {
    label: "Faroese - føroyskt",
    value: "Faroese - føroyskt",
  },
  {
    label: "Filipino",
    value: "Filipino",
  },
  {
    label: "Finnish - suomi",
    value: "Finnish - suomi",
  },
  {
    label: "French - français",
    value: "French - français",
  },
  {
    label: "French (Canada) - français (Canada)",
    value: "French (Canada) - français (Canada)",
  },
  {
    label: "French (France) - français (France)",
    value: "French (France) - français (France)",
  },
  {
    label: "French (Switzerland) - français (Suisse)",
    value: "French (Switzerland) - français (Suisse)",
  },
  {
    label: "Galician - galego",
    value: "Galician - galego",
  },
  {
    label: "Georgian - ქართული",
    value: "Georgian - ქართული",
  },
  {
    label: "German - Deutsch",
    value: "German - Deutsch",
  },
  {
    label: "German (Austria) - Deutsch (Österreich)",
    value: "German (Austria) - Deutsch (Österreich)",
  },
  {
    label: "German (Germany) - Deutsch (Deutschland)",
    value: "German (Germany) - Deutsch (Deutschland)",
  },
  {
    label: "German (Liechtenstein) - Deutsch (Liechtenstein)",
    value: "German (Liechtenstein) - Deutsch (Liechtenstein)",
  },
  {
    label: "German (Switzerland) - Deutsch (Schweiz)",
    value: "German (Switzerland) - Deutsch (Schweiz)",
  },
  {
    label: "Greek - Ελληνικά",
    value: "Greek - Ελληνικά",
  },
  {
    label: "Guarani",
    value: "Guarani",
  },
  {
    label: "Gujarati - ગુજરાતી",
    value: "Gujarati - ગુજરાતી",
  },
  {
    label: "Hausa",
    value: "Hausa",
  },
  {
    label: "Hawaiian - ʻŌlelo Hawaiʻi",
    value: "Hawaiian - ʻŌlelo Hawaiʻi",
  },
  {
    label: "Hebrew - עברית",
    value: "Hebrew - עברית",
  },
  {
    label: "Hungarian - magyar",
    value: "Hungarian - magyar",
  },
  {
    label: "Icelandic - íslenska",
    value: "Icelandic - íslenska",
  },
  {
    label: "Indonesian - Indonesia",
    value: "Indonesian - Indonesia",
  },
  {
    label: "Interlingua",
    value: "Interlingua",
  },
  {
    label: "Irish - Gaeilge",
    value: "Irish - Gaeilge",
  },
  {
    label: "Italian - italiano",
    value: "Italian - italiano",
  },
  {
    label: "Italian (Italy) - italiano (Italia)",
    value: "Italian (Italy) - italiano (Italia)",
  },
  {
    label: "Italian (Switzerland) - italiano (Svizzera)",
    value: "Italian (Switzerland) - italiano (Svizzera)",
  },
  {
    label: "Japanese - 日本語",
    value: "Japanese - 日本語",
  },
  {
    label: "Kannada - ಕನ್ನಡ",
    value: "Kannada - ಕನ್ನಡ",
  },
  {
    label: "Kazakh - қазақ тілі",
    value: "Kazakh - қазақ тілі",
  },
  {
    label: "Khmer - ខ្មែរ",
    value: "Khmer - ខ្មែរ",
  },
  {
    label: "Korean - 한국어",
    value: "Korean - 한국어",
  },
  {
    label: "Kurdish - Kurdî",
    value: "Kurdish - Kurdî",
  },
  {
    label: "Kyrgyz - кыргызча",
    value: "Kyrgyz - кыргызча",
  },
  {
    label: "Lao - ລາວ",
    value: "Lao - ລາວ",
  },
  {
    label: "Latin",
    value: "Latin",
  },
  {
    label: "Latvian - latviešu",
    value: "Latvian - latviešu",
  },
  {
    label: "Lingala - lingála",
    value: "Lingala - lingála",
  },
  {
    label: "Lithuanian - lietuvių",
    value: "Lithuanian - lietuvių",
  },
  {
    label: "Macedonian - македонски",
    value: "Macedonian - македонски",
  },
  {
    label: "Malay - Bahasa Melayu",
    value: "Malay - Bahasa Melayu",
  },
  {
    label: "Malayalam - മലയാളം",
    value: "Malayalam - മലയാളം",
  },
  {
    label: "Maltese - Malti",
    value: "Maltese - Malti",
  },
  {
    label: "Marathi - मराठी",
    value: "Marathi - मराठी",
  },
  {
    label: "Mongolian - монгол",
    value: "Mongolian - монгол",
  },
  {
    label: "Nepali - नेपाली",
    value: "Nepali - नेपाली",
  },
  {
    label: "Norwegian - norsk",
    value: "Norwegian - norsk",
  },
  {
    label: "Norwegian Bokmål - norsk bokmål",
    value: "Norwegian Bokmål - norsk bokmål",
  },
  {
    label: "Norwegian Nynorsk - nynorsk",
    value: "Norwegian Nynorsk - nynorsk",
  },
  {
    label: "Occitan",
    value: "Occitan",
  },
  {
    label: "Oriya - ଓଡ଼ିଆ",
    value: "Oriya - ଓଡ଼ିଆ",
  },
  {
    label: "Oromo - Oromoo",
    value: "Oromo - Oromoo",
  },
  {
    label: "Pashto - پښتو",
    value: "Pashto - پښتو",
  },
  {
    label: "Persian - فارسی",
    value: "Persian - فارسی",
  },
  {
    label: "Polish - polski",
    value: "Polish - polski",
  },
  {
    label: "Portuguese - português",
    value: "Portuguese - português",
  },
  {
    label: "Portuguese (Brazil) - português (Brasil)",
    value: "Portuguese (Brazil) - português (Brasil)",
  },
  {
    label: "Portuguese (Portugal) - português (Portugal)",
    value: "Portuguese (Portugal) - português (Portugal)",
  },
  {
    label: "Punjabi - ਪੰਜਾਬੀ",
    value: "Punjabi - ਪੰਜਾਬੀ",
  },
  {
    label: "Quechua",
    value: "Quechua",
  },
  {
    label: "Romanian - română",
    value: "Romanian - română",
  },
  {
    label: "Romanian (Moldova) - română (Moldova)",
    value: "Romanian (Moldova) - română (Moldova)",
  },
  {
    label: "Romansh - rumantsch",
    value: "Romansh - rumantsch",
  },
  {
    label: "Russian - русский",
    value: "Russian - русский",
  },
  {
    label: "Scottish Gaelic",
    value: "Scottish Gaelic",
  },
  {
    label: "Serbian - српски",
    value: "Serbian - српски",
  },
  {
    label: "Serbo - Croatian",
    value: "Serbo - Croatian",
  },
  {
    label: "Shona - chiShona",
    value: "Shona - chiShona",
  },
  {
    label: "Sindhi",
    value: "Sindhi",
  },
  {
    label: "Sinhala - සිංහල",
    value: "Sinhala - සිංහල",
  },
  {
    label: "Slovak - slovenčina",
    value: "Slovak - slovenčina",
  },
  {
    label: "Slovenian - slovenščina",
    value: "Slovenian - slovenščina",
  },
  {
    label: "Somali - Soomaali",
    value: "Somali - Soomaali",
  },
  {
    label: "Southern Sotho",
    value: "Southern Sotho",
  },
  {
    label: "Spanish - español",
    value: "Spanish - español",
  },
  {
    label: "Spanish (Argentina) - español (Argentina)",
    value: "Spanish (Argentina) - español (Argentina)",
  },
  {
    label: "Spanish (Latin America) - español (Latinoamérica)",
    value: "Spanish (Latin America) - español (Latinoamérica)",
  },
  {
    label: "Spanish (Mexico) - español (México)",
    value: "Spanish (Mexico) - español (México)",
  },
  {
    label: "Spanish (Spain) - español (España)",
    value: "Spanish (Spain) - español (España)",
  },
  {
    label: "Spanish (United States) - español (Estados Unidos)",
    value: "Spanish (United States) - español (Estados Unidos)",
  },
  {
    label: "Sundanese",
    value: "Sundanese",
  },
  {
    label: "Swahili - Kiswahili",
    value: "Swahili - Kiswahili",
  },
  {
    label: "Swedish - svenska",
    value: "Swedish - svenska",
  },
  {
    label: "Tajik - тоҷикӣ",
    value: "Tajik - тоҷикӣ",
  },
  {
    label: "Tamil - தமிழ்",
    value: "Tamil - தமிழ்",
  },
  {
    label: "Tatar",
    value: "Tatar",
  },
  {
    label: "Telugu - తెలుగు",
    value: "Telugu - తెలుగు",
  },
  {
    label: "Thai - ไทย",
    value: "Thai - ไทย",
  },
  {
    label: "Tigrinya - ትግርኛ",
    value: "Tigrinya - ትግርኛ",
  },
  {
    label: "Tongan - lea fakatonga",
    value: "Tongan - lea fakatonga",
  },
  {
    label: "Turkish - Türkçe",
    value: "Turkish - Türkçe",
  },
  {
    label: "Turkmen",
    value: "Turkmen",
  },
  {
    label: "Twi",
    value: "Twi",
  },
  {
    label: "Ukrainian - українська",
    value: "Ukrainian - українська",
  },
  {
    label: "Urdu - اردو",
    value: "Urdu - اردو",
  },
  {
    label: "Uyghur",
    value: "Uyghur",
  },
  {
    label: "Uzbek - o‘zbek",
    value: "Uzbek - o‘zbek",
  },
  {
    label: "Vietnamese - Tiếng Việt",
    value: "Vietnamese - Tiếng Việt",
  },
  {
    label: "Walloon - wa",
    value: "Walloon - wa",
  },
  {
    label: "Welsh - Cymraeg",
    value: "Welsh - Cymraeg",
  },
  {
    label: "Western Frisian",
    value: "Western Frisian",
  },
  {
    label: "Xhosa",
    value: "Xhosa",
  },
  {
    label: "Yiddish",
    value: "Yiddish",
  },
  {
    label: "Yoruba - Èdè Yorùbá",
    value: "Yoruba - Èdè Yorùbá",
  },
  {
    label: "Zulu - isiZulu",
    value: "Zulu - isiZulu",
  },
];

export const countries = [
  {
    label: "India",
    value: "India",
  },
  {
    label: "Afghanistan",
    value: "Afghanistan",
  },
  {
    label: "Aland Islands",
    value: "Aland Islands",
  },
  {
    label: "Albania",
    value: "Albania",
  },
  {
    label: "Algeria",
    value: "Algeria",
  },
  {
    label: "American Samoa",
    value: "American Samoa",
  },
  {
    label: "Andorra",
    value: "Andorra",
  },
  {
    label: "Angola",
    value: "Angola",
  },
  {
    label: "Anguilla",
    value: "Anguilla",
  },
  {
    label: "Antarctica",
    value: "Antarctica",
  },
  {
    label: "Antigua and Barbuda",
    value: "Antigua and Barbuda",
  },
  {
    label: "Argentina",
    value: "Argentina",
  },
  {
    label: "Armenia",
    value: "Armenia",
  },
  {
    label: "Aruba",
    value: "Aruba",
  },
  {
    label: "Australia",
    value: "Australia",
  },
  {
    label: "Austria",
    value: "Austria",
  },
  {
    label: "Azerbaijan",
    value: "Azerbaijan",
  },
  {
    label: "Bahamas",
    value: "Bahamas",
  },
  {
    label: "Bahrain",
    value: "Bahrain",
  },
  {
    label: "Bangladesh",
    value: "Bangladesh",
  },
  {
    label: "Barbados",
    value: "Barbados",
  },
  {
    label: "Belarus",
    value: "Belarus",
  },
  {
    label: "Belgium",
    value: "Belgium",
  },
  {
    label: "Belize",
    value: "Belize",
  },
  {
    label: "Benin",
    value: "Benin",
  },
  {
    label: "Bermuda",
    value: "Bermuda",
  },
  {
    label: "Bhutan",
    value: "Bhutan",
  },
  {
    label: "Bolivia",
    value: "Bolivia",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
  },
  {
    label: "Botswana",
    value: "Botswana",
  },
  {
    label: "Bouvet Island",
    value: "Bouvet Island",
  },
  {
    label: "Brazil",
    value: "Brazil",
  },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
  },
  {
    label: "Brunei Darussalam",
    value: "Brunei Darussalam",
  },
  {
    label: "Bulgaria",
    value: "Bulgaria",
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    label: "Burundi",
    value: "Burundi",
  },
  {
    label: "Cambodia",
    value: "Cambodia",
  },
  {
    label: "Cameroon",
    value: "Cameroon",
  },
  {
    label: "Canada",
    value: "Canada",
  },
  {
    label: "Cape Verde",
    value: "Cape Verde",
  },
  {
    label: "Cayman Islands",
    value: "Cayman Islands",
  },
  {
    label: "Central African Republic",
    value: "Central African Republic",
  },
  {
    label: "Chad",
    value: "Chad",
  },
  {
    label: "Chile",
    value: "Chile",
  },
  {
    label: "China",
    value: "China",
  },
  {
    label: "Christmas Island",
    value: "Christmas Island",
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
  },
  {
    label: "Colombia",
    value: "Colombia",
  },
  {
    label: "Comoros",
    value: "Comoros",
  },
  {
    label: "Congo",
    value: "Congo",
  },
  {
    label: "Congo, Democratic Republic of the Congo",
    value: "Congo, Democratic Republic of the Congo",
  },
  {
    label: "Cook Islands",
    value: "Cook Islands",
  },
  {
    label: "Costa Rica",
    value: "Costa Rica",
  },
  {
    label: "Cote D'Ivoire",
    value: "Cote D'Ivoire",
  },
  {
    label: "Croatia",
    value: "Croatia",
  },
  {
    label: "Cuba",
    value: "Cuba",
  },
  {
    label: "Curacao",
    value: "Curacao",
  },
  {
    label: "Cyprus",
    value: "Cyprus",
  },
  {
    label: "Czech Republic",
    value: "Czech Republic",
  },
  {
    label: "Denmark",
    value: "Denmark",
  },
  {
    label: "Djibouti",
    value: "Djibouti",
  },
  {
    label: "Dominica",
    value: "Dominica",
  },
  {
    label: "Dominican Republic",
    value: "Dominican Republic",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
  },
  {
    label: "Egypt",
    value: "Egypt",
  },
  {
    label: "El Salvador",
    value: "El Salvador",
  },
  {
    label: "Equatorial Guinea",
    value: "Equatorial Guinea",
  },
  {
    label: "Eritrea",
    value: "Eritrea",
  },
  {
    label: "Estonia",
    value: "Estonia",
  },
  {
    label: "Ethiopia",
    value: "Ethiopia",
  },
  {
    label: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)",
  },
  {
    label: "Faroe Islands",
    value: "Faroe Islands",
  },
  {
    label: "Fiji",
    value: "Fiji",
  },
  {
    label: "Finland",
    value: "Finland",
  },
  {
    label: "France",
    value: "France",
  },
  {
    label: "French Guiana",
    value: "French Guiana",
  },
  {
    label: "French Polynesia",
    value: "French Polynesia",
  },
  {
    label: "French Southern Territories",
    value: "French Southern Territories",
  },
  {
    label: "Gabon",
    value: "Gabon",
  },
  {
    label: "Gambia",
    value: "Gambia",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Germany",
    value: "Germany",
  },
  {
    label: "Ghana",
    value: "Ghana",
  },
  {
    label: "Gibraltar",
    value: "Gibraltar",
  },
  {
    label: "Greece",
    value: "Greece",
  },
  {
    label: "Greenland",
    value: "Greenland",
  },
  {
    label: "Grenada",
    value: "Grenada",
  },
  {
    label: "Guadeloupe",
    value: "Guadeloupe",
  },
  {
    label: "Guam",
    value: "Guam",
  },
  {
    label: "Guatemala",
    value: "Guatemala",
  },
  {
    label: "Guernsey",
    value: "Guernsey",
  },
  {
    label: "Guinea",
    value: "Guinea",
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
  },
  {
    label: "Guyana",
    value: "Guyana",
  },
  {
    label: "Haiti",
    value: "Haiti",
  },
  {
    label: "Heard Island and Mcdonald Islands",
    value: "Heard Island and Mcdonald Islands",
  },
  {
    label: "Holy See (Vatican City State)",
    value: "Holy See (Vatican City State)",
  },
  {
    label: "Honduras",
    value: "Honduras",
  },
  {
    label: "Hong Kong",
    value: "Hong Kong",
  },
  {
    label: "Hungary",
    value: "Hungary",
  },
  {
    label: "Iceland",
    value: "Iceland",
  },
  {
    label: "Indonesia",
    value: "Indonesia",
  },
  {
    label: "Iran, Islamic Republic of",
    value: "Iran, Islamic Republic of",
  },
  {
    label: "Iraq",
    value: "Iraq",
  },
  {
    label: "Ireland",
    value: "Ireland",
  },
  {
    label: "Isle of Man",
    value: "Isle of Man",
  },
  {
    label: "Israel",
    value: "Israel",
  },
  {
    label: "Italy",
    value: "Italy",
  },
  {
    label: "Jamaica",
    value: "Jamaica",
  },
  {
    label: "Japan",
    value: "Japan",
  },
  {
    label: "Jersey",
    value: "Jersey",
  },
  {
    label: "Jordan",
    value: "Jordan",
  },
  {
    label: "Kazakhstan",
    value: "Kazakhstan",
  },
  {
    label: "Kenya",
    value: "Kenya",
  },
  {
    label: "Kiribati",
    value: "Kiribati",
  },
  {
    label: "Korea, Democratic People's Republic of",
    value: "Korea, Democratic People's Republic of",
  },
  {
    label: "Korea, Republic of",
    value: "Korea, Republic of",
  },
  {
    label: "Kosovo",
    value: "Kosovo",
  },
  {
    label: "Kuwait",
    value: "Kuwait",
  },
  {
    label: "Kyrgyzstan",
    value: "Kyrgyzstan",
  },
  {
    label: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
  },
  {
    label: "Latvia",
    value: "Latvia",
  },
  {
    label: "Lebanon",
    value: "Lebanon",
  },
  {
    label: "Lesotho",
    value: "Lesotho",
  },
  {
    label: "Liberia",
    value: "Liberia",
  },
  {
    label: "Libyan Arab Jamahiriya",
    value: "Libyan Arab Jamahiriya",
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    label: "Lithuania",
    value: "Lithuania",
  },
  {
    label: "Luxembourg",
    value: "Luxembourg",
  },
  {
    label: "Macao",
    value: "Macao",
  },
  {
    label: "Macedonia, the Former Yugoslav Republic of",
    value: "Macedonia, the Former Yugoslav Republic of",
  },
  {
    label: "Madagascar",
    value: "Madagascar",
  },
  {
    label: "Malawi",
    value: "Malawi",
  },
  {
    label: "Malaysia",
    value: "Malaysia",
  },
  {
    label: "Maldives",
    value: "Maldives",
  },
  {
    label: "Mali",
    value: "Mali",
  },
  {
    label: "Malta",
    value: "Malta",
  },
  {
    label: "Marshall Islands",
    value: "Marshall Islands",
  },
  {
    label: "Martinique",
    value: "Martinique",
  },
  {
    label: "Mauritania",
    value: "Mauritania",
  },
  {
    label: "Mauritius",
    value: "Mauritius",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
  },
  {
    label: "Mexico",
    value: "Mexico",
  },
  {
    label: "Micronesia, Federated States of",
    value: "Micronesia, Federated States of",
  },
  {
    label: "Moldova, Republic of",
    value: "Moldova, Republic of",
  },
  {
    label: "Monaco",
    value: "Monaco",
  },
  {
    label: "Mongolia",
    value: "Mongolia",
  },
  {
    label: "Montenegro",
    value: "Montenegro",
  },
  {
    label: "Montserrat",
    value: "Montserrat",
  },
  {
    label: "Morocco",
    value: "Morocco",
  },
  {
    label: "Mozambique",
    value: "Mozambique",
  },
  {
    label: "Myanmar",
    value: "Myanmar",
  },
  {
    label: "Namibia",
    value: "Namibia",
  },
  {
    label: "Nauru",
    value: "Nauru",
  },
  {
    label: "Nepal",
    value: "Nepal",
  },
  {
    label: "Netherlands",
    value: "Netherlands",
  },
  {
    label: "Netherlands Antilles",
    value: "Netherlands Antilles",
  },
  {
    label: "New Caledonia",
    value: "New Caledonia",
  },
  {
    label: "New Zealand",
    value: "New Zealand",
  },
  {
    label: "Nicaragua",
    value: "Nicaragua",
  },
  {
    label: "Niger",
    value: "Niger",
  },
  {
    label: "Nigeria",
    value: "Nigeria",
  },
  {
    label: "Niue",
    value: "Niue",
  },
  {
    label: "Norfolk Island",
    value: "Norfolk Island",
  },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
  },
  {
    label: "Norway",
    value: "Norway",
  },
  {
    label: "Oman",
    value: "Oman",
  },
  {
    label: "Pakistan",
    value: "Pakistan",
  },
  {
    label: "Palau",
    value: "Palau",
  },
  {
    label: "Palestinian Territory, Occupied",
    value: "Palestinian Territory, Occupied",
  },
  {
    label: "Panama",
    value: "Panama",
  },
  {
    label: "Papua New Guinea",
    value: "Papua New Guinea",
  },
  {
    label: "Paraguay",
    value: "Paraguay",
  },
  {
    label: "Peru",
    value: "Peru",
  },
  {
    label: "Philippines",
    value: "Philippines",
  },
  {
    label: "Pitcairn",
    value: "Pitcairn",
  },
  {
    label: "Poland",
    value: "Poland",
  },
  {
    label: "Portugal",
    value: "Portugal",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
  },
  {
    label: "Qatar",
    value: "Qatar",
  },
  {
    label: "Reunion",
    value: "Reunion",
  },
  {
    label: "Romania",
    value: "Romania",
  },
  {
    label: "Russian Federation",
    value: "Russian Federation",
  },
  {
    label: "Rwanda",
    value: "Rwanda",
  },
  {
    label: "Saint Barthelemy",
    value: "Saint Barthelemy",
  },
  {
    label: "Saint Helena",
    value: "Saint Helena",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
  },
  {
    label: "Saint Lucia",
    value: "Saint Lucia",
  },
  {
    label: "Saint Martin",
    value: "Saint Martin",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  {
    label: "Samoa",
    value: "Samoa",
  },
  {
    label: "San Marino",
    value: "San Marino",
  },
  {
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
  },
  {
    label: "Saudi Arabia",
    value: "Saudi Arabia",
  },
  {
    label: "Senegal",
    value: "Senegal",
  },
  {
    label: "Serbia",
    value: "Serbia",
  },
  {
    label: "Serbia and Montenegro",
    value: "Serbia and Montenegro",
  },
  {
    label: "Seychelles",
    value: "Seychelles",
  },
  {
    label: "Sierra Leone",
    value: "Sierra Leone",
  },
  {
    label: "Singapore",
    value: "Singapore",
  },
  {
    label: "Sint Maarten",
    value: "Sint Maarten",
  },
  {
    label: "Slovakia",
    value: "Slovakia",
  },
  {
    label: "Slovenia",
    value: "Slovenia",
  },
  {
    label: "Solomon Islands",
    value: "Solomon Islands",
  },
  {
    label: "Somalia",
    value: "Somalia",
  },
  {
    label: "South Africa",
    value: "South Africa",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
  },
  {
    label: "South Sudan",
    value: "South Sudan",
  },
  {
    label: "Spain",
    value: "Spain",
  },
  {
    label: "Sri Lanka",
    value: "Sri Lanka",
  },
  {
    label: "Sudan",
    value: "Sudan",
  },
  {
    label: "Suriname",
    value: "Suriname",
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen",
  },
  {
    label: "Swaziland",
    value: "Swaziland",
  },
  {
    label: "Sweden",
    value: "Sweden",
  },
  {
    label: "Switzerland",
    value: "Switzerland",
  },
  {
    label: "Syrian Arab Republic",
    value: "Syrian Arab Republic",
  },
  {
    label: "Taiwan, Province of China",
    value: "Taiwan, Province of China",
  },
  {
    label: "Tajikistan",
    value: "Tajikistan",
  },
  {
    label: "Tanzania, United Republic of",
    value: "Tanzania, United Republic of",
  },
  {
    label: "Thailand",
    value: "Thailand",
  },
  {
    label: "Timor-Leste",
    value: "Timor-Leste",
  },
  {
    label: "Togo",
    value: "Togo",
  },
  {
    label: "Tokelau",
    value: "Tokelau",
  },
  {
    label: "Tonga",
    value: "Tonga",
  },
  {
    label: "Trinidad and Tobago",
    value: "Trinidad and Tobago",
  },
  {
    label: "Tunisia",
    value: "Tunisia",
  },
  {
    label: "Turkey",
    value: "Turkey",
  },
  {
    label: "Turkmenistan",
    value: "Turkmenistan",
  },
  {
    label: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands",
  },
  {
    label: "Tuvalu",
    value: "Tuvalu",
  },
  {
    label: "Uganda",
    value: "Uganda",
  },
  {
    label: "Ukraine",
    value: "Ukraine",
  },
  {
    label: "United Arab Emirates",
    value: "United Arab Emirates",
  },
  {
    label: "United Kingdom",
    value: "United Kingdom",
  },
  {
    label: "United States",
    value: "United States",
  },
  {
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
  },
  {
    label: "Uruguay",
    value: "Uruguay",
  },
  {
    label: "Uzbekistan",
    value: "Uzbekistan",
  },
  {
    label: "Vanuatu",
    value: "Vanuatu",
  },
  {
    label: "Venezuela",
    value: "Venezuela",
  },
  {
    label: "Viet Nam",
    value: "Viet Nam",
  },
  {
    label: "Virgin Islands, British",
    value: "Virgin Islands, British",
  },
  {
    label: "Virgin Islands, U.s.",
    value: "Virgin Islands, U.s.",
  },
  {
    label: "Wallis and Futuna",
    value: "Wallis and Futuna",
  },
  {
    label: "Western Sahara",
    value: "Western Sahara",
  },
  {
    label: "Yemen",
    value: "Yemen",
  },
  {
    label: "Zambia",
    value: "Zambia",
  },
  {
    label: "Zimbabwe",
    value: "Zimbabwe",
  },
];
export const expertise = [
  { label: "Software Development", value: "Software Development" },
  { label: "Design", value: "Design" },
  { label: "Product Managment", value: "Product Managment" },
  { label: "Content Writing", value: "Content Writing" },
  { label: "Data Science", value: "Data Science" },
  { label: "Marketing", value: "Marketing" },
  { label: "Product Research", value: "Product Research" },
  {
    label: "Achievement Motivation Trainer",
    value: "Achievement Motivation Trainer",
  },
  { label: "Talent Acquisition", value: "Talent Acquisition" },
  { label: "Recruiters", value: "Recruiters" },
  { label: "Life Coach", value: "Life Coach" },
  {
    label: "Entrepreneurial Motivation Trainer",
    value: "Entrepreneurial Motivation Trainer",
  },
  { label: "HR professionals", value: "HR professionals" }
];

export const compaines = [
  { label: "Google", value: "Google" },
  { label: "Microsoft", value: "Microsoft" },
  { label: "Apple", value: "Apple" },
  { label: "Amazon", value: "Amazon" },
  { label: "Facebook", value: "Facebook" },
  { label: "IBM", value: "IBM" },
  { label: "Samsung", value: "Samsung" },
  { label: "Intel", value: "Intel" },
  { label: "Oracle", value: "Oracle" },
  { label: "Cisco", value: "Cisco" },
  { label: "Sony", value: "Sony" },
  { label: "HP", value: "HP" },
  { label: "Dell", value: "Dell" },
  { label: "Adobe", value: "Adobe" },
  { label: "NVIDIA", value: "NVIDIA" },
  { label: "Netflix", value: "Netflix" },
  { label: "Salesforce", value: "Salesforce" },
  { label: "Tesla", value: "Tesla" },
  { label: "Uber", value: "Uber" },
  { label: "Airbnb", value: "Airbnb" },
  { label: "PayPal", value: "PayPal" },
  { label: "SpaceX", value: "SpaceX" },
  { label: "Twitter", value: "Twitter" },
  { label: "LinkedIn", value: "LinkedIn" },
  { label: "Snap Inc.", value: "Snap Inc." },
  { label: "Zoom", value: "Zoom" },
  { label: "Slack", value: "Slack" },
  { label: "Atlassian", value: "Atlassian" },
  { label: "Pinterest", value: "Pinterest" },
  { label: "Alibaba", value: "Alibaba" },
  { label: "Tencent", value: "Tencent" },
  { label: "Baidu", value: "Baidu" },
  { label: "JD.com", value: "JD.com" },
  { label: "ByteDance", value: "ByteDance" },
  { label: "Xiaomi", value: "Xiaomi" },
  { label: "Meituan", value: "Meituan" },
  { label: "DiDi", value: "DiDi" },
  { label: "Grab", value: "Grab" },
  { label: "Gojek", value: "Gojek" },
  { label: "Reliance Industries", value: "Reliance Industries" },
  { label: "Infosys", value: "Infosys" },
  { label: "Wipro", value: "Wipro" },
  { label: "Tata Consultancy Services", value: "Tata Consultancy Services" },
  { label: "General Electric", value: "General Electric" },
  { label: "Siemens", value: "Siemens" },
  { label: "BMW", value: "BMW" },
  { label: "Toyota", value: "Toyota" },
  { label: "Honda", value: "Honda" },
  { label: "Volkswagen", value: "Volkswagen" },
  { label: "Ford", value: "Ford" },
  { label: "General Motors", value: "General Motors" },
  { label: "Mercedes-Benz", value: "Mercedes-Benz" },
  { label: "Boeing", value: "Boeing" },
  { label: "Airbus", value: "Airbus" },
  { label: "Nestle", value: "Nestle" },
  { label: "Procter & Gamble", value: "Procter & Gamble" },
  { label: "Unilever", value: "Unilever" },
  { label: "Coca-Cola", value: "Coca-Cola" },
  { label: "PepsiCo", value: "PepsiCo" },
  { label: "Johnson & Johnson", value: "Johnson & Johnson" },
  { label: "Wal-Mart Stores", value: "Wal-Mart Stores" },
  { label: "Target", value: "Target" },
  { label: "Home Depot", value: "Home Depot" },
  { label: "Netflix", value: "Netflix" },
  { label: "Disney", value: "Disney" },
  { label: "AT&T", value: "AT&T" },
  { label: "Verizon Communications", value: "Verizon Communications" },
  { label: "Comcast", value: "Comcast" },
  { label: "Booz Allen Hamilton", value: "Booz Allen Hamilton" },
  { label: "Accenture", value: "Accenture" },
  { label: "Deloitte", value: "Deloitte" },
  { label: "KPMG", value: "KPMG" },
  { label: "PwC", value: "PwC" },
  { label: "Berkshire Hathaway", value: "Berkshire Hathaway" },
  { label: "ExxonMobil", value: "ExxonMobil" },
  { label: "Chevron", value: "Chevron" },
  { label: "Shell", value: "Shell" },
  { label: "Total", value: "Total" },
  { label: "BP", value: "BP" },
  { label: "Schlumberger", value: "Schlumberger" },
  { label: "Halliburton", value: "Halliburton" },
  { label: "Lockheed Martin", value: "Lockheed Martin" },
  { label: "Raytheon Technologies", value: "Raytheon Technologies" },
];

export const experience = [
  { label: "Entry Level", value: "Entry Level" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Senior", value: "Senior" },
  { label: "Manager", value: "Manager" },
  { label: "Director", value: "Director" },
  { label: "Lead", value: "Lead" },
  { label: "Executive", value: "Executive" },
  { label: "Founder", value: "Founder" },
];
export const GraduationYear = [
  { "label": "1985", "value": "1985" },
  { "label": "1986", "value": "1986" },
  { "label": "1987", "value": "1987" },
  { "label": "1988", "value": "1988" },
  { "label": "1989", "value": '1989' },
  { "label": "1990", "value": '1990' },
  { "label": "1991", "value": '1991' },
  { "label": "1992", "value": '1992' },
  { "label": "1993", "value": '1993' },
  { "label": "1994", "value": '1994' },
  { "label": "1995", "value": '1995' },
  { "label": "1996", "value": '1996' },
  { "label": "1997", "value": '1997' },
  { "label": "1998", "value": '1998' },
  { "label": "1999", "value": '1999' },
  { "label": "2000", "value": '2000' },
  { "label": "2001", "value": '2001' },
  { "label": "2002", "value": '2002' },
  { "label": "2003", "value": '2003' },
  { "label": "2004", "value": '2004' },
  { "label": "2005", "value": '2005' },
  { "label": "2006", "value": '2006' },
  { "label": "2007", "value": '2007' },
  { "label": "2008", "value": '2008' },
  { "label": "2009", "value": '2009' },
  { "label": "2010", "value": '2010' },
  { "label": "2011", "value": "2011" },
  { "label": "2012", "value": "2012" },
  { "label": "2013", "value": "2013" },
  { "label": "2014", "value": "2014" },
  { "label": "2015", "value": "2015" },
  { "label": "2016", "value": "2016" },
  { "label": "2017", "value": "2017" },
  { "label": "2018", "value": "2018" },
  { "label": "2019", "value": "2019" },
  { "label": "2020", "value": "2020" },
  { "label": "2021", "value": "2021" },
  { "label": "2022", "value": "2022" },
  { "label": "2023", "value": "2023" },
  { "label": "2024", "value": "2024" },
  { "label": "2025", "value": "2025" },
  { "label": "2026", "value": "2026" },
  { "label": "2027", "value": "2027" },
  { "label": "2028", "value": "2028" },
  { "label": "2029", "value": "2029" },
  { "label": "2030", "value": "2030" },
  { "label": "2031", "value": "2031" },
  { "label": "2032", "value": "2032" },
  { "label": "2033", "value": "2033" },
  { "label": "2034", "value": "2034" },
  { "label": "2035", "value": "2035" }
]

export const userMentorshipData = [
  {
    label: "Keeping team members motivated",
    value: "Keeping team members motivated",
  },
  { label: "Managing People", value: "Managing People" },
  {
    label: "Fostering a good team culture",
    value: "Fostering a good team culture",
  },
  { label: "Bulding an effective team", value: "Bulding an effective team" },
  { label: "Managing a remote team", value: "Managing a remote team" },
  {
    label: "Avoiding common dysfunction",
    value: "Avoiding common dysfunction",
  },
  { label: "Building balanced teams", value: "Building balanced teams" },
  { label: "Hiring software engineers", value: "Hiring software engineers" },
  { label: "Making btter tech choices", value: "Making btter tech choices" },
];

export const useMantorshipDataSecond = [
  { label: "General Mentorship", value: "General Mentorship" },
  { label: "Working remotely", value: "Working remotely" },
  { label: "Managing burnout", value: "Managing burnout" },
  {
    label: "Dealing with imposter syndrome",
    value: "Dealing with imposter syndrome",
  },
  { label: "Being a woman in tech", value: "Being a woman in tech" },
];

export const userSkills = [
  {
    label: "Choosing metrics and team goals",
    value: "Choosing metrics and team goals",
  },
  {
    label: "Organizational development training",
    value: "Organizational development training",
  },
  {
    label: "Creative thinking training",
    value: "Creative thinking training",
  },
  {
    label: "Yoga training",
    value: "Yoga training",
  },
  {
    label: "Spritual guidance",
    value: "Spritual guidance",
  },
  {
    label: "Running effective design critiques",
    value: "Running effective design critiques",
  },
  {
    label: "Launching new product or features",
    value: "Launching new product or features",
  },
  {
    label: "Prototyping methods and tips",
    value: "Prototyping methods and tips",
  },
  {
    label: " Running effective meetings",
    value: " Running effective meetings",
  },
  {
    label: "Identifying problems vs solutions",
    value: "Identifying problems vs solutions",
  },
  {
    label: "Presenting your work with impact",
    value: "Presenting your work with impact",
  },
  { label: " Working with designers", value: " Working with designers" },
  { label: "Working cross-funcitonally", value: "Working cross-funcitonally" },
  { label: "Building a design process", value: "Building a design process" },
  { label: "Being the only designer", value: "Being the only designer" },
  {
    label: "Conducting good user research",
    value: "Conducting good user research",
  },
  { label: "Improving Design skills", value: "Improving Design skills" },
  {
    label: " Working with product and engineering",
    value: " Working with product and engineering",
  },
  {
    label: " Leveraging Product Analytics ",
    value: " Leveraging Product Analytics ",
  },
  {
    label: " Creating and leveraging user personas ",
    value: " Creating and leveraging user personas ",
  },
  {
    label: " Giving effective feedback ",
    value: " Giving effective feedback ",
  },
  {
    label: " Creating a product vision ",
    value: " Creating a product vision ",
  },
  { label: " Working with engineers ", value: " Working with engineers " },
  { label: " Onboarding new user", value: " Onboarding new user" },
  {
    label: " Writing product requirement documents",
    value: " Writing product requirement documents",
  },
  {
    label: " Conducting effective customer interviews",
    value: " Conducting effective customer interviews",
  },
];

export const carrerAdvice = [
  {
    label: "Getting the most out of my internship",
    value: "Getting the most out of my internship",
  },
  { label: "Changing roles", value: "Changing roles" },
  { label: "How to get promoted", value: "How to get promoted" },
  { label: "Negotiating a job offers", value: "Negotiating a job offers" },
  { label: "Design career path", value: "Design career path" },
  {
    label: "Product management career path",
    value: "Product management career path",
  },
];

export const jobSearch = [
  {
    label: "Resume and Portfolio review",
    value: "Resume and Portfolio review",
  },
  { label: "Practice mock interviews", value: "Practice mock interviews" },
  { label: "Breaking into tech", value: "Breaking into tech" },
  {
    label: "Acing technical interview questions",
    value: "Acing technical interview questions",
  },
];

export const title = [
  { label: "Student", value: "Student" },
  { label: "Product Manager", value: "Product Manager" },
  { label: "Software Engineer", value: "Software Engineer" },
  { label: "Teacher", value: "Teacher" },
  { label: "Doctor", value: "Doctor" },
  { label: "Accountant", value: "Accountant" },
  { label: "Graphic Designer", value: "Graphic Designer" },
  { label: "Marketing Manager", value: "Marketing Manager" },
  { label: "Data Analyst", value: "Data Analyst" },
  { label: "Sales Representative", value: "Sales Representative" },
  { label: "Project Manager", value: "Project Manager" },
  { label: "Architect", value: "Architect" },
  { label: "Nurse", value: "Nurse" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Journalist", value: "Journalist" },
  { label: "Chef", value: "Chef" },
  { label: "Financial Analyst", value: "Financial Analyst" },
  { label: "Human Resources Manager", value: "Human Resources Manager" },
  { label: "Research Scientist", value: "Research Scientist" },
  { label: "Operations Manager", value: "Operations Manager" },
  { label: "Entrepreneur", value: "Entrepreneur" },
  { label: "Artist", value: "Artist" },
  { label: "Consultant", value: "Consultant" },
  { label: "Mechanical Engineer", value: "Mechanical Engineer" },
  { label: "Data Scientist", value: "Data Scientist" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "Product Designer", value: "Product Designer" },
  { label: "Writer", value: "Writer" },
  { label: "Electrician", value: "Electrician" },
  { label: "Financial Advisor", value: "Financial Advisor" },
  { label: "Social Media Manager", value: "Social Media Manager" },
  { label: "Dentist", value: "Dentist" },
  { label: "Operations Analyst", value: "Operations Analyst" },
  { label: "HR Assistant", value: "HR Assistant" },
  { label: "Civil Engineer", value: "Civil Engineer" },
  { label: "Market Research Analyst", value: "Market Research Analyst" },
  { label: "Executive Assistant", value: "Executive Assistant" },
  { label: "Mechanic", value: "Mechanic" },
  { label: "Investment Banker", value: "Investment Banker" },
  { label: "UX Designer", value: "UX Designer" },
  { label: "Physician", value: "Physician" },
  { label: "Phlebotomist", value: "Phlebotomist" },
];


export const MentorshipType = [
  {
    "label": "Career Guidance",
    "value": "Career Guidance"
  },
  {
    "label": "Skill Development",
    "value": "Skill Development"
  },
  {
    "label": "Industry Insights",
    "value": "Industry Insights"
  },
  {
    "label": "Leadership Development",
    "value": "Leadership Development"
  },
  {
    "label": "Entrepreneurship",
    "value": "Entrepreneurship"
  },
  {
    "label": "Networking",
    "value": "Networking"
  },
  {
    "label": "Project Management",
    "value": "Project Management"
  },
  {
    "label": "Innovation",
    "value": "Innovation"
  },
  {
    "label": "Time Management",
    "value": "Time Management"
  },
  {
    "label": "Communication Skills",
    "value": "Communication Skills"
  },
  {
    "label": "Conflict Resolution",
    "value": "Conflict Resolution"
  },
  {
    "label": "Team Collaboration",
    "value": "Team Collaboration"
  },
  {
    "label": "Technical Expertise",
    "value": "Technical Expertise"
  },
  {
    "label": "Work-Life Balance",
    "value": "Work-Life Balance"
  },
  {
    "label": "Creativity",
    "value": "Creativity"
  },
  {
    "label": "Diversity and Inclusion",
    "value": "Diversity and Inclusion"
  },
  {
    "label": "Professional Networking",
    "value": "Professional Networking"
  },
  {
    "label": "Decision Making",
    "value": "Decision Making"
  },
  {
    "label": "Personal Branding",
    "value": "Personal Branding"
  },
  {
    "label": "Remote Work",
    "value": "Remote Work"
  },
  {
    "label": "Financial Literacy",
    "value": "Financial Literacy"
  },
  {
    "label": "Goal Setting",
    "value": "Goal Setting"
  },
  {
    "label": "Stress Management",
    "value": "Stress Management"
  },
  {
    "label": "Emotional Intelligence",
    "value": "Emotional Intelligence"
  },
  {
    "label": "Industry Trends",
    "value": "Industry Trends"
  },
  {
    "label": "Public Speaking",
    "value": "Public Speaking"
  },
  {
    "label": "Career Transition",
    "value": "Career Transition"
  },
  {
    "label": "Mentorship Skills",
    "value": "Mentorship Skills"
  },
  {
    "label": "Professional Ethics",
    "value": "Professional Ethics"
  },
  {
    "label": "Time Management",
    "value": "Time Management"
  },
  {
    "label": "Negotiation Skills",
    "value": "Negotiation Skills"
  },
  {
    "label": "Industry-Specific Insights",
    "value": "Industry-Specific Insights"
  },
  {
    "label": "Self-Confidence Building",
    "value": "Self-Confidence Building"
  },
  {
    "label": "Continuous Learning",
    "value": "Continuous Learning"
  },
  {
    "label": "Job Search Strategies",
    "value": "Job Search Strategies"
  },
  {
    "label": "Workplace Etiquette",
    "value": "Workplace Etiquette"
  },
  {
    "label": "Critical Thinking",
    "value": "Critical Thinking"
  },
  {
    "label": "Crisis Management",
    "value": "Crisis Management"
  },
  {
    "label": "Motivation",
    "value": "Motivation"
  },
  { label: "Software Development", value: "Software Development" },
  { label: "Design", value: "Design" },
  { label: "Product Managment", value: "Product Managment" },
  { label: "Content Writing", value: "Content Writing" },
  { label: "Data Science", value: "Data Science" },
  { label: "Marketing", value: "Marketing" },
  { label: "Product Research", value: "Product Research" },
  {
    label: "Achievement Motivation Trainer",
    value: "Achievement Motivation Trainer",
  },
  { label: "Life Coach", value: "Life Coach" },
  {
    label: "Entrepreneurial Motivation Trainer",
    value: "Entrepreneurial Motivation Trainer",
  },

  { label: "Content Writing", value: "Content Writing" },
  { label: "Data Science", value: "Data Science" },
  { label: "Design", value: "Design" },
  { label: "Marketing", value: "Marketing" },
  { label: "Product Managment", value: "Product Managment" },
  { label: "Product Research", value: "Product Research" },
  { label: "Software Development", value: "Software Development" },
]
export const Qualification = [
  { "value": "high_school_diploma", "label": "High School Diploma" },
  { "value": "associates_degree", "label": "Associate's Degree" },
  { "value": "bachelors_degree", "label": "Bachelor's Degree" },
  { "value": "masters_degree", "label": "Master's Degree" },
  { "value": "phd", "label": "Doctorate (Ph.D.)" },
  { "value": "other", "label": "Other" }
]

export const Timezones_List = [{
  "name": "(GMT-11:00) Midway Island",
  "timezone": "Pacific/Midway"
}, {
  "name": "(GMT-11:00) Samoa",
  "timezone": "US/Samoa"
}, {
  "name": "(GMT-10:00) Hawaii",
  "timezone": "US/Hawaii"
}, {
  "name": "(GMT-09:00) Alaska",
  "timezone": "US/Alaska"
}, {
  "name": "(GMT-08:00) Pacific Time (US & Canada)",
  "timezone": "US/Pacific"
}, {
  "name": "(GMT-08:00) Tijuana",
  "timezone": "America/Tijuana"
}, {
  "name": "(GMT-07:00) Arizona",
  "timezone": "US/Arizona"
}, {
  "name": "(GMT-07:00) Mountain Time (US & Canada)",
  "timezone": "US/Mountain"
}, {
  "name": "(GMT-07:00) Chihuahua",
  "timezone": "America/Chihuahua"
}, {
  "name": "(GMT-07:00) Mazatlan",
  "timezone": "America/Mazatlan"
}, {
  "name": "(GMT-06:00) Mexico City",
  "timezone": "America/Mexico_City"
}, {
  "name": "(GMT-06:00) Monterrey",
  "timezone": "America/Monterrey"
}, {
  "name": "(GMT-06:00) Saskatchewan",
  "timezone": "Canada/Saskatchewan"
}, {
  "name": "(GMT-06:00) Central Time (US & Canada)",
  "timezone": "US/Central"
}, {
  "name": "(GMT-05:00) Eastern Time (US & Canada)",
  "timezone": "US/Eastern"
}, {
  "name": "(GMT-05:00) Indiana (East)",
  "timezone": "US/East-Indiana"
}, {
  "name": "(GMT-05:00) Bogota",
  "timezone": "America/Bogota"
}, {
  "name": "(GMT-05:00) Lima",
  "timezone": "America/Lima"
}, {
  "name": "(GMT-04:30) Caracas",
  "timezone": "America/Caracas"
}, {
  "name": "(GMT-04:00) Atlantic Time (Canada)",
  "timezone": "Canada/Atlantic"
}, {
  "name": "(GMT-04:00) La Paz",
  "timezone": "America/La_Paz"
}, {
  "name": "(GMT-04:00) Santiago",
  "timezone": "America/Santiago"
}, {
  "name": "(GMT-03:30) Newfoundland",
  "timezone": "Canada/Newfoundland"
}, {
  "name": "(GMT-03:00) Buenos Aires",
  "timezone": "America/Buenos_Aires"
}, {
  "name": "(GMT-03:00) Greenland",
  "timezone": "Greenland"
}, {
  "name": "(GMT-02:00) Stanley",
  "timezone": "Atlantic/Stanley"
}, {
  "name": "(GMT-01:00) Azores",
  "timezone": "Atlantic/Azores"
}, {
  "name": "(GMT-01:00) Cape Verde Is.",
  "timezone": "Atlantic/Cape_Verde"
}, {
  "name": "(GMT) Casablanca",
  "timezone": "Africa/Casablanca"
}, {
  "name": "(GMT) Dublin",
  "timezone": "Europe/Dublin"
}, {
  "name": "(GMT) Lisbon",
  "timezone": "Europe/Lisbon"
}, {
  "name": "(GMT) London",
  "timezone": "Europe/London"
}, {
  "name": "(GMT) Monrovia",
  "timezone": "Africa/Monrovia"
}, {
  "name": "(GMT+01:00) Amsterdam",
  "timezone": "Europe/Amsterdam"
}, {
  "name": "(GMT+01:00) Belgrade",
  "timezone": "Europe/Belgrade"
}, {
  "name": "(GMT+01:00) Berlin",
  "timezone": "Europe/Berlin"
}, {
  "name": "(GMT+01:00) Bratislava",
  "timezone": "Europe/Bratislava"
}, {
  "name": "(GMT+01:00) Brussels",
  "timezone": "Europe/Brussels"
}, {
  "name": "(GMT+01:00) Budapest",
  "timezone": "Europe/Budapest"
}, {
  "name": "(GMT+01:00) Copenhagen",
  "timezone": "Europe/Copenhagen"
}, {
  "name": "(GMT+01:00) Ljubljana",
  "timezone": "Europe/Ljubljana"
}, {
  "name": "(GMT+01:00) Madrid",
  "timezone": "Europe/Madrid"
}, {
  "name": "(GMT+01:00) Paris",
  "timezone": "Europe/Paris"
}, {
  "name": "(GMT+01:00) Prague",
  "timezone": "Europe/Prague"
}, {
  "name": "(GMT+01:00) Rome",
  "timezone": "Europe/Rome"
}, {
  "name": "(GMT+01:00) Sarajevo",
  "timezone": "Europe/Sarajevo"
}, {
  "name": "(GMT+01:00) Skopje",
  "timezone": "Europe/Skopje"
}, {
  "name": "(GMT+01:00) Stockholm",
  "timezone": "Europe/Stockholm"
}, {
  "name": "(GMT+01:00) Vienna",
  "timezone": "Europe/Vienna"
}, {
  "name": "(GMT+01:00) Warsaw",
  "timezone": "Europe/Warsaw"
}, {
  "name": "(GMT+01:00) Zagreb",
  "timezone": "Europe/Zagreb"
}, {
  "name": "(GMT+02:00) Athens",
  "timezone": "Europe/Athens"
}, {
  "name": "(GMT+02:00) Bucharest",
  "timezone": "Europe/Bucharest"
}, {
  "name": "(GMT+02:00) Cairo",
  "timezone": "Africa/Cairo"
}, {
  "name": "(GMT+02:00) Harare",
  "timezone": "Africa/Harare"
}, {
  "name": "(GMT+02:00) Helsinki",
  "timezone": "Europe/Helsinki"
}, {
  "name": "(GMT+02:00) Istanbul",
  "timezone": "Europe/Istanbul"
}, {
  "name": "(GMT+02:00) Jerusalem",
  "timezone": "Asia/Jerusalem"
}, {
  "name": "(GMT+02:00) Kyiv",
  "timezone": "Europe/Kiev"
}, {
  "name": "(GMT+02:00) Minsk",
  "timezone": "Europe/Minsk"
}, {
  "name": "(GMT+02:00) Riga",
  "timezone": "Europe/Riga"
}, {
  "name": "(GMT+02:00) Sofia",
  "timezone": "Europe/Sofia"
}, {
  "name": "(GMT+02:00) Tallinn",
  "timezone": "Europe/Tallinn"
}, {
  "name": "(GMT+02:00) Vilnius",
  "timezone": "Europe/Vilnius"
}, {
  "name": "(GMT+03:00) Baghdad",
  "timezone": "Asia/Baghdad"
}, {
  "name": "(GMT+03:00) Kuwait",
  "timezone": "Asia/Kuwait"
}, {
  "name": "(GMT+03:00) Nairobi",
  "timezone": "Africa/Nairobi"
}, {
  "name": "(GMT+03:00) Riyadh",
  "timezone": "Asia/Riyadh"
}, {
  "name": "(GMT+03:00) Moscow",
  "timezone": "Europe/Moscow"
}, {
  "name": "(GMT+03:30) Tehran",
  "timezone": "Asia/Tehran"
}, {
  "name": "(GMT+04:00) Baku",
  "timezone": "Asia/Baku"
}, {
  "name": "(GMT+04:00) Volgograd",
  "timezone": "Europe/Volgograd"
}, {
  "name": "(GMT+04:00) Muscat",
  "timezone": "Asia/Muscat"
}, {
  "name": "(GMT+04:00) Tbilisi",
  "timezone": "Asia/Tbilisi"
}, {
  "name": "(GMT+04:00) Yerevan",
  "timezone": "Asia/Yerevan"
}, {
  "name": "(GMT+04:30) Kabul",
  "timezone": "Asia/Kabul"
}, {
  "name": "(GMT+05:00) Karachi",
  "timezone": "Asia/Karachi"
}, {
  "name": "(GMT+05:00) Tashkent",
  "timezone": "Asia/Tashkent"
}, {
  "name": "(GMT+05:30) Calcutta",
  "timezone": "Asia/Calcutta"
}, {
  "name": "(GMT+05:45) Kathmandu",
  "timezone": "Asia/Kathmandu"
}, {
  "name": "(GMT+06:00) Ekaterinburg",
  "timezone": "Asia/Yekaterinburg"
}, {
  "name": "(GMT+06:00) Almaty",
  "timezone": "Asia/Almaty"
}, {
  "name": "(GMT+06:00) Dhaka",
  "timezone": "Asia/Dhaka"
}, {
  "name": "(GMT+07:00) Novosibirsk",
  "timezone": "Asia/Novosibirsk"
}, {
  "name": "(GMT+07:00) Bangkok",
  "timezone": "Asia/Bangkok"
}, {
  "name": "(GMT+07:00) Jakarta",
  "timezone": "Asia/Jakarta"
}, {
  "name": "(GMT+08:00) Krasnoyarsk",
  "timezone": "Asia/Krasnoyarsk"
}, {
  "name": "(GMT+08:00) Chongqing",
  "timezone": "Asia/Chongqing"
}, {
  "name": "(GMT+08:00) Hong Kong",
  "timezone": "Asia/Hong_Kong"
}, {
  "name": "(GMT+08:00) Kuala Lumpur",
  "timezone": "Asia/Kuala_Lumpur"
}, {
  "name": "(GMT+08:00) Perth",
  "timezone": "Australia/Perth"
}, {
  "name": "(GMT+08:00) Singapore",
  "timezone": "Asia/Singapore"
}, {
  "name": "(GMT+08:00) Taipei",
  "timezone": "Asia/Taipei"
}, {
  "name": "(GMT+08:00) Ulaan Bataar",
  "timezone": "Asia/Ulaanbaatar"
}, {
  "name": "(GMT+08:00) Urumqi",
  "timezone": "Asia/Urumqi"
}, {
  "name": "(GMT+09:00) Irkutsk",
  "timezone": "Asia/Irkutsk"
}, {
  "name": "(GMT+09:00) Seoul",
  "timezone": "Asia/Seoul"
}, {
  "name": "(GMT+09:00) Tokyo",
  "timezone": "Asia/Tokyo"
}, {
  "name": "(GMT+09:30) Adelaide",
  "timezone": "Australia/Adelaide"
}, {
  "name": "(GMT+09:30) Darwin",
  "timezone": "Australia/Darwin"
}, {
  "name": "(GMT+10:00) Yakutsk",
  "timezone": "Asia/Yakutsk"
}, {
  "name": "(GMT+10:00) Brisbane",
  "timezone": "Australia/Brisbane"
}, {
  "name": "(GMT+10:00) Canberra",
  "timezone": "Australia/Canberra"
}, {
  "name": "(GMT+10:00) Guam",
  "timezone": "Pacific/Guam"
}, {
  "name": "(GMT+10:00) Hobart",
  "timezone": "Australia/Hobart"
}, {
  "name": "(GMT+10:00) Melbourne",
  "timezone": "Australia/Melbourne"
}, {
  "name": "(GMT+10:00) Port Moresby",
  "timezone": "Pacific/Port_Moresby"
}, {
  "name": "(GMT+10:00) Sydney",
  "timezone": "Australia/Sydney"
}, {
  "name": "(GMT+11:00) Vladivostok",
  "timezone": "Asia/Vladivostok"
}, {
  "name": "(GMT+12:00) Magadan",
  "timezone": "Asia/Magadan"
}, {
  "name": "(GMT+12:00) Auckland",
  "timezone": "Pacific/Auckland"
}, {
  "name": "(GMT+12:00) Fiji",
  "timezone": "Pacific/Fiji"
}];

export const stdCodeData = [
  { "country": "India", "code": "91", "iso": "IN" },
  { "country": "Afghanistan", "code": "93", "iso": "AF" },
  { "country": "Albania", "code": "355", "iso": "AL" },
  { "country": "Algeria", "code": "213", "iso": "DZ" },
  { "country": "American Samoa", "code": "1-684", "iso": "AS" },
  { "country": "Andorra", "code": "376", "iso": "AD" },
  { "country": "Angola", "code": "244", "iso": "AO" },
  { "country": "Anguilla", "code": "1-264", "iso": "AI" },
  { "country": "Antarctica", "code": "672", "iso": "AQ" },
  { "country": "Antigua and Barbuda", "code": "1-268", "iso": "AG" },
  { "country": "Argentina", "code": "54", "iso": "AR" },
  { "country": "Armenia", "code": "374", "iso": "AM" },
  { "country": "Aruba", "code": "297", "iso": "AW" },
  { "country": "Australia", "code": "61", "iso": "AU" },
  { "country": "Austria", "code": "43", "iso": "AT" },
  { "country": "Azerbaijan", "code": "994", "iso": "AZ" },
  { "country": "Bahamas", "code": "1-242", "iso": "BS" },
  { "country": "Bahrain", "code": "973", "iso": "BH" },
  { "country": "Bangladesh", "code": "880", "iso": "BD" },
  { "country": "Barbados", "code": "1-246", "iso": "BB" },
  { "country": "Belarus", "code": "375", "iso": "BY" },
  { "country": "Belgium", "code": "32", "iso": "BE" },
  { "country": "Belize", "code": "501", "iso": "BZ" },
  { "country": "Benin", "code": "229", "iso": "BJ" },
  { "country": "Bermuda", "code": "1-441", "iso": "BM" },
  { "country": "Bhutan", "code": "975", "iso": "BT" },
  { "country": "Bolivia", "code": "591", "iso": "BO" },
  { "country": "Bosnia and Herzegovina", "code": "387", "iso": "BA" },
  { "country": "Botswana", "code": "267", "iso": "BW" },
  { "country": "Brazil", "code": "55", "iso": "BR" },
  { "country": "British Indian Ocean Territory", "code": "246", "iso": "IO" },
  { "country": "British Virgin Islands", "code": "1-284", "iso": "VG" },
  { "country": "Brunei", "code": "673", "iso": "BN" },
  { "country": "Bulgaria", "code": "359", "iso": "BG" },
  { "country": "Burkina Faso", "code": "226", "iso": "BF" },
  { "country": "Burundi", "code": "257", "iso": "BI" },
  { "country": "Cambodia", "code": "855", "iso": "KH" },
  { "country": "Cameroon", "code": "237", "iso": "CM" },
  { "country": "Canada", "code": "1", "iso": "CA" },
  { "country": "Cape Verde", "code": "238", "iso": "CV" },
  { "country": "Cayman Islands", "code": "1-345", "iso": "KY" },
  { "country": "Central African Republic", "code": "236", "iso": "CF" },
  { "country": "Chad", "code": "235", "iso": "TD" },
  { "country": "Chile", "code": "56", "iso": "CL" },
  { "country": "China", "code": "86", "iso": "CN" },
  { "country": "Christmas Island", "code": "61", "iso": "CX" },
  { "country": "Cocos Islands", "code": "61", "iso": "CC" },
  { "country": "Colombia", "code": "57", "iso": "CO" },
  { "country": "Comoros", "code": "269", "iso": "KM" },
  { "country": "Cook Islands", "code": "682", "iso": "CK" },
  { "country": "Costa Rica", "code": "506", "iso": "CR" },
  { "country": "Croatia", "code": "385", "iso": "HR" },
  { "country": "Cuba", "code": "53", "iso": "CU" },
  { "country": "Curacao", "code": "599", "iso": "CW" },
  { "country": "Cyprus", "code": "357", "iso": "CY" },
  { "country": "Czech Republic", "code": "420", "iso": "CZ" },
  { "country": "Democratic Republic of the Congo", "code": "243", "iso": "CD" },
  { "country": "Denmark", "code": "45", "iso": "DK" },
  { "country": "Djibouti", "code": "253", "iso": "DJ" },
  { "country": "Dominica", "code": "1-767", "iso": "DM" },
  { "country": "Dominican Republic", "code": "1-809, 1-829, 1-849", "iso": "DO" },
  { "country": "East Timor", "code": "670", "iso": "TL" },
  { "country": "Ecuador", "code": "593", "iso": "EC" },
  { "country": "Egypt", "code": "20", "iso": "EG" },
  { "country": "El Salvador", "code": "503", "iso": "SV" },
  { "country": "Equatorial Guinea", "code": "240", "iso": "GQ" },
  { "country": "Eritrea", "code": "291", "iso": "ER" },
  { "country": "Estonia", "code": "372", "iso": "EE" },
  { "country": "Ethiopia", "code": "251", "iso": "ET" },
  { "country": "Falkland Islands", "code": "500", "iso": "FK" },
  { "country": "Faroe Islands", "code": "298", "iso": "FO" },
  { "country": "Fiji", "code": "679", "iso": "FJ" },
  { "country": "Finland", "code": "358", "iso": "FI" },
  { "country": "France", "code": "33", "iso": "FR" },
  { "country": "French Polynesia", "code": "689", "iso": "PF" },
  { "country": "Gabon", "code": "241", "iso": "GA" },
  { "country": "Gambia", "code": "220", "iso": "GM" },
  { "country": "Georgia", "code": "995", "iso": "GE" },
  { "country": "Germany", "code": "49", "iso": "DE" },
  { "country": "Ghana", "code": "233", "iso": "GH" },
  { "country": "Gibraltar", "code": "350", "iso": "GI" },
  { "country": "Greece", "code": "30", "iso": "GR" },
  { "country": "Greenland", "code": "299", "iso": "GL" },
  { "country": "Grenada", "code": "1-473", "iso": "GD" },
  { "country": "Guam", "code": "1-671", "iso": "GU" },
  { "country": "Guatemala", "code": "502", "iso": "GT" },
  { "country": "Guernsey", "code": "44-1481", "iso": "GG" },
  { "country": "Guinea", "code": "224", "iso": "GN" },
  { "country": "Guinea-Bissau", "code": "245", "iso": "GW" },
  { "country": "Guyana", "code": "592", "iso": "GY" },
  { "country": "Haiti", "code": "509", "iso": "HT" },
  { "country": "Honduras", "code": "504", "iso": "HN" },
  { "country": "Hong Kong", "code": "852", "iso": "HK" },
  { "country": "Hungary", "code": "36", "iso": "HU" },
  { "country": "Iceland", "code": "354", "iso": "IS" },
  { "country": "Indonesia", "code": "62", "iso": "ID" },
  { "country": "Iran", "code": "98", "iso": "IR" },
  { "country": "Iraq", "code": "964", "iso": "IQ" },
  { "country": "Ireland", "code": "353", "iso": "IE" },
  { "country": "Isle of Man", "code": "44-1624", "iso": "IM" },
  { "country": "Israel", "code": "972", "iso": "IL" },
  { "country": "Italy", "code": "39", "iso": "IT" },
  { "country": "Ivory Coast", "code": "225", "iso": "CI" },
  { "country": "Jamaica", "code": "1-876", "iso": "JM" },
  { "country": "Japan", "code": "81", "iso": "JP" },
  { "country": "Jersey", "code": "44-1534", "iso": "JE" },
  { "country": "Jordan", "code": "962", "iso": "JO" },
  { "country": "Kazakhstan", "code": "7", "iso": "KZ" },
  { "country": "Kenya", "code": "254", "iso": "KE" },
  { "country": "Kiribati", "code": "686", "iso": "KI" },
  { "country": "Kosovo", "code": "383", "iso": "XK" },
  { "country": "Kuwait", "code": "965", "iso": "KW" },
  { "country": "Kyrgyzstan", "code": "996", "iso": "KG" },
  { "country": "Laos", "code": "856", "iso": "LA" },
  { "country": "Latvia", "code": "371", "iso": "LV" },
  { "country": "Lebanon", "code": "961", "iso": "LB" },
  { "country": "Lesotho", "code": "266", "iso": "LS" },
  { "country": "Liberia", "code": "231", "iso": "LR" },
  { "country": "Libya", "code": "218", "iso": "LY" },
  { "country": "Liechtenstein", "code": "423", "iso": "LI" },
  { "country": "Lithuania", "code": "370", "iso": "LT" },
  { "country": "Luxembourg", "code": "352", "iso": "LU" },
  { "country": "Macao", "code": "853", "iso": "MO" },
  { "country": "Macedonia", "code": "389", "iso": "MK" },
  { "country": "Madagascar", "code": "261", "iso": "MG" },
  { "country": "Malawi", "code": "265", "iso": "MW" },
  { "country": "Malaysia", "code": "60", "iso": "MY" },
  { "country": "Maldives", "code": "960", "iso": "MV" },
  { "country": "Mali", "code": "223", "iso": "ML" },
  { "country": "Malta", "code": "356", "iso": "MT" },
  { "country": "Marshall Islands", "code": "692", "iso": "MH" },
  { "country": "Mauritania", "code": "222", "iso": "MR" },
  { "country": "Mauritius", "code": "230", "iso": "MU" },
  { "country": "Mayotte", "code": "262", "iso": "YT" },
  { "country": "Mexico", "code": "52", "iso": "MX" },
  { "country": "Micronesia", "code": "691", "iso": "FM" },
  { "country": "Moldova", "code": "373", "iso": "MD" },
  { "country": "Monaco", "code": "377", "iso": "MC" },
  { "country": "Mongolia", "code": "976", "iso": "MN" },
  { "country": "Montenegro", "code": "382", "iso": "ME" },
  { "country": "Montserrat", "code": "1-664", "iso": "MS" },
  { "country": "Morocco", "code": "212", "iso": "MA" },
  { "country": "Mozambique", "code": "258", "iso": "MZ" },
  { "country": "Myanmar", "code": "95", "iso": "MM" },
  { "country": "Namibia", "code": "264", "iso": "NA" },
  { "country": "Nauru", "code": "674", "iso": "NR" },
  { "country": "Nepal", "code": "977", "iso": "NP" },
  { "country": "Netherlands", "code": "31", "iso": "NL" },
  { "country": "Netherlands Antilles", "code": "599", "iso": "AN" },
  { "country": "New Caledonia", "code": "687", "iso": "NC" },
  { "country": "New Zealand", "code": "64", "iso": "NZ" },
  { "country": "Nicaragua", "code": "505", "iso": "NI" },
  { "country": "Niger", "code": "227", "iso": "NE" },
  { "country": "Nigeria", "code": "234", "iso": "NG" },
  { "country": "Niue", "code": "683", "iso": "NU" },
  { "country": "North Korea", "code": "850", "iso": "KP" },
  { "country": "Northern Mariana Islands", "code": "1-670", "iso": "MP" },
  { "country": "Norway", "code": "47", "iso": "NO" },
  { "country": "Oman", "code": "968", "iso": "OM" },
  { "country": "Pakistan", "code": "92", "iso": "PK" },
  { "country": "Palau", "code": "680", "iso": "PW" },
  { "country": "Palestine", "code": "970", "iso": "PS" },
  { "country": "Panama", "code": "507", "iso": "PA" },
  { "country": "Papua New Guinea", "code": "675", "iso": "PG" },
  { "country": "Paraguay", "code": "595", "iso": "PY" },
  { "country": "Peru", "code": "51", "iso": "PE" },
  { "country": "Philippines", "code": "63", "iso": "PH" },
  { "country": "Pitcairn", "code": "64", "iso": "PN" },
  { "country": "Poland", "code": "48", "iso": "PL" },
  { "country": "Portugal", "code": "351", "iso": "PT" },
  { "country": "Puerto Rico", "code": "1-787, 1-939", "iso": "PR" },
  { "country": "Qatar", "code": "974", "iso": "QA" },
  { "country": "Republic of the Congo", "code": "242", "iso": "CG" },
  { "country": "Reunion", "code": "262", "iso": "RE" },
  { "country": "Romania", "code": "40", "iso": "RO" },
  { "country": "Russia", "code": "7", "iso": "RU" },
  { "country": "Rwanda", "code": "250", "iso": "RW" },
  { "country": "Saint Barthelemy", "code": "590", "iso": "BL" },
  { "country": "Saint Helena", "code": "290", "iso": "SH" },
  { "country": "Saint Kitts and Nevis", "code": "1-869", "iso": "KN" },
  { "country": "Saint Lucia", "code": "1-758", "iso": "LC" },
  { "country": "Saint Martin", "code": "590", "iso": "MF" },
  { "country": "Saint Pierre and Miquelon", "code": "508", "iso": "PM" },
  { "country": "Saint Vincent and the Grenadines", "code": "1-784", "iso": "VC" },
  { "country": "Samoa", "code": "685", "iso": "WS" },
  { "country": "San Marino", "code": "378", "iso": "SM" },
  { "country": "Sao Tome and Principe", "code": "239", "iso": "ST" },
  { "country": "Saudi Arabia", "code": "966", "iso": "SA" },
  { "country": "Senegal", "code": "221", "iso": "SN" },
  { "country": "Serbia", "code": "381", "iso": "RS" },
  { "country": "Seychelles", "code": "248", "iso": "SC" },
  { "country": "Sierra Leone", "code": "232", "iso": "SL" },
  { "country": "Singapore", "code": "65", "iso": "SG" },
  { "country": "Sint Maarten", "code": "1-721", "iso": "SX" },
  { "country": "Slovakia", "code": "421", "iso": "SK" },
  { "country": "Slovenia", "code": "386", "iso": "SI" },
  { "country": "Solomon Islands", "code": "677", "iso": "SB" },
  { "country": "Somalia", "code": "252", "iso": "SO" },
  { "country": "South Africa", "code": "27", "iso": "ZA" },
  { "country": "South Korea", "code": "82", "iso": "KR" },
  { "country": "South Sudan", "code": "211", "iso": "SS" },
  { "country": "Spain", "code": "34", "iso": "ES" },
  { "country": "Sri Lanka", "code": "94", "iso": "LK" },
  { "country": "Sudan", "code": "249", "iso": "SD" },
  { "country": "Suriname", "code": "597", "iso": "SR" },
  { "country": "Svalbard and Jan Mayen", "code": "47", "iso": "SJ" },
  { "country": "Swaziland", "code": "268", "iso": "SZ" },
  { "country": "Sweden", "code": "46", "iso": "SE" },
  { "country": "Switzerland", "code": "41", "iso": "CH" },
  { "country": "Syria", "code": "963", "iso": "SY" },
  { "country": "Taiwan", "code": "886", "iso": "TW" },
  { "country": "Tajikistan", "code": "992", "iso": "TJ" },
  { "country": "Tanzania", "code": "255", "iso": "TZ" },
  { "country": "Thailand", "code": "66", "iso": "TH" },
  { "country": "Togo", "code": "228", "iso": "TG" },
  { "country": "Tokelau", "code": "690", "iso": "TK" },
  { "country": "Tonga", "code": "676", "iso": "TO" },
  { "country": "Trinidad and Tobago", "code": "1-868", "iso": "TT" },
  { "country": "Tunisia", "code": "216", "iso": "TN" },
  { "country": "Turkey", "code": "90", "iso": "TR" },
  { "country": "Turkmenistan", "code": "993", "iso": "TM" },
  { "country": "Turks and Caicos Islands", "code": "1-649", "iso": "TC" },
  { "country": "Tuvalu", "code": "688", "iso": "TV" },
  { "country": "U.S. Virgin Islands", "code": "1-340", "iso": "VI" },
  { "country": "Uganda", "code": "256", "iso": "UG" },
  { "country": "Ukraine", "code": "380", "iso": "UA" },
  { "country": "United Arab Emirates", "code": "971", "iso": "AE" },
  { "country": "United Kingdom", "code": "44", "iso": "GB" },
  { "country": "United States", "code": "1", "iso": "US" },
  { "country": "Uruguay", "code": "598", "iso": "UY" },
  { "country": "Uzbekistan", "code": "998", "iso": "UZ" },
  { "country": "Vanuatu", "code": "678", "iso": "VU" },
  { "country": "Vatican", "code": "379", "iso": "VA" },
  { "country": "Venezuela", "code": "58", "iso": "VE" },
  { "country": "Vietnam", "code": "84", "iso": "VN" },
  { "country": "Wallis and Futuna", "code": "681", "iso": "WF" },
  { "country": "Western Sahara", "code": "212", "iso": "EH" },
  { "country": "Yemen", "code": "967", "iso": "YE" },
  { "country": "Zambia", "code": "260", "iso": "ZM" },
  { "country": "Zimbabwe", "code": "263", "iso": "ZW" }]