import React, { useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { mentorActiveSubscription } from "../../../redux/actions/mentor";
import { REACT_USER_ID } from "../../auth/tokenProvider";

const MentorSubscription = () => {
  const dispatch = useDispatch();
  const user_id = REACT_USER_ID;
  useEffect(() => {
    dispatch(mentorActiveSubscription("a75dc1e4-85d3-4de2-92b0-4e9bf90b67b0"));
  }, []);
  const mentorSubscriptionsReducerData = useSelector(
    (state) => state.mentorActiveSubscriptionReducer
  );
  const mentorSubscriptionsList = mentorSubscriptionsReducerData?.data || [];
  const isLoading = mentorSubscriptionsReducerData?.loading;
  return (
    <>
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div className="container-fluid">
          {/* <!-- Page Heading --> */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800 main-title">
              Mentee's Information
            </h1>
          </div>

          {/* <!-- Content Row --> */}
          <div className="row">
            {/* <!-- Earnings (Monthly) Card Example --> */}
            {mentorSubscriptionsList.length > 0 ? (
              <div className="col-xl-12 col-md-12 mb-4">
                <div className="card">
                  <div className="card-body table-inner">
                    <div className="table-responsive">
                      <table style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            <th className="">
                              <span className="">User Name</span>
                            </th>
                            <th>
                              <span className="">Subcriptions</span>
                            </th>
                            <th>
                              <span className="">Total Sessions</span>
                            </th>
                            <th className="">
                              <span className="">Contact</span>
                            </th>

                            <th className=""></th>
                          </tr>
                        </thead>
                        <tbody>
                          {mentorSubscriptionsList.map((subscription) => (
                            <tr>
                              <td className="d-flex">
                                <span className="pro-bg">RB</span>
                                <h3 className="pro-name">
                                  {subscription?.mentee_id}
                                  <span className="small-cls">
                                    reena_bantu@gmail.com
                                  </span>
                                </h3>
                              </td>
                              <td>
                                <h3 className="pro-name">
                                  <span className="small-cls">
                                    {subscription?.product_details?.title}
                                  </span>
                                </h3>
                              </td>
                              <td>
                                <h3 className="pro-name">
                                  <span className="small-cls">15</span>
                                </h3>
                              </td>
                              <td>9876543210</td>
                              <td>
                                <button className="btn green-btn">
                                  View Profile
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <hr />
                <div
                  style={{ "text-align": "center", "padding-bottom": "1rem" }}
                >
                  <h2>No Subscription Found</h2>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MentorSubscription;
