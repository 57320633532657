import React, { useState, useEffect } from 'react';
import './coutnDown.css'

function CountdownTimer({ courseStartDateUtc }) {
    // Parse UTC timestamp string to Date object
    const courseStartDateUtcParsed = new Date(Date.parse(courseStartDateUtc));
const courseStartDateLocal = new Date(courseStartDateUtcParsed.toLocaleString());

    // Initialize state for time remaining
    const [timeRemaining, setTimeRemaining] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        // Calculate time difference between courseStartDateUtcParsed and current local time
        const intervalId = setInterval(() => {
            const now = new Date(); // Current local time
            const timeDifference = courseStartDateUtcParsed - now;

            if (timeDifference > 0) {
                const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                setTimeRemaining({ days, hours, minutes, seconds });
            } else {
                clearInterval(intervalId); // Stop the countdown when courseStartDate is reached
            }
        }, 1000); // Update the countdown every 1 second

        return () => {
            clearInterval(intervalId); // Clean up the interval when the component unmounts
        };
    }, [courseStartDateUtcParsed]);

    return (
        <div className={`countdown-timer ${timeRemaining.days === 0 && timeRemaining.hours === 0 && timeRemaining.minutes === 0 && timeRemaining.seconds === 0 ? 'expired' : ''}`}>
            <h2>Registration closes:</h2>
            <div className="countdown">
                <div className="countdown-item">
                    <span>{timeRemaining.days}</span>
                    <span>DD</span>
                </div>
                <div className="countdown-item">
                    <span>{timeRemaining.hours}</span>
                    <span>HH</span>
                </div>
                <div className="countdown-item">
                    <span>{timeRemaining.minutes}</span>
                    <span>MM</span>
                </div>
                <div className="countdown-item">
                    <span>{timeRemaining.seconds}</span>
                    <span>SS</span>
                </div>
            </div>
        </div>
    );
}

export default CountdownTimer;
