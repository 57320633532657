import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import crossImg from '../img/cross.png';

 
import { map } from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { mentorChooseSessionServiceType } from "../../../redux/actions/mentor";
 
import("../css/custom.css");

const ChooseSession = () => {
  const navigate = useNavigate();
  const selectedSessionTypeSavedData = useSelector((state) => {
    const data =
      state.mentorServiceSessionTypeReducer?.data?.type || "One-Time-Session";
    return data;
  });
  const [selectedSession, setSelectedSession] = useState(
    selectedSessionTypeSavedData
  );
  const dispatch = useDispatch();
  const sessionChoices = [
    {
      text: "Regular Session",
      value: "Regular Session",
    },
    {
      text: "One-Time-Session",
      value: "One-Time-Session",
    },
    {
      text: "Public/Open Session",
      value: "Public/Open Session",
    },
  ];
  const chooseSessionDataModel = {
    type: "",
    serviceType: "",
  };
  const setSessionTypeDetails = () => {
    chooseSessionDataModel.type = selectedSession;
    chooseSessionDataModel.serviceType = selectedSession;
  };
  useEffect(() => {
    setSessionTypeDetails();
    dispatch(mentorChooseSessionServiceType(chooseSessionDataModel));
  }, [selectedSession]);
  useEffect(() => {
     
    import("../css/custom.css");
  }, []);

  return (
    <>
      <div id="wrapper">
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div className="step-cont">
          <div className="step-inner-cls">
            <form action="#" method="post" id="raq_questions">
              <div className="question-container active_panel" id="question-1">
                <h5>Let's get started with creating session</h5>
                <h3>Choose a session that suits you best</h3>
                <div className="custom-radio-cls">
                  {sessionChoices.map((choice) => (
                    <div className="custom-radio-bg">
                      <label className="custom-radio">
                        <input
                          type="radio"
                          checked={selectedSession === choice.value}
                          name="radio"
                          value={choice.value}
                          onChange={(e) => setSelectedSession(e.target.value)}
                        />
                        <span className="checkmark"></span>
                        <span className="cont">{choice.text}</span>
                      </label>
                    </div>
                  ))}
                  {/* <div className="custom-radio-bg">
                    <label className="custom-radio">
                      <input type="radio" name="radio"/>
                        <span className="checkmark"></span>
                        <span className="cont">One-Time-Session</span>
                    </label>
                  </div>
                  <div className="custom-radio-bg">
                    <label className="custom-radio">
                      <input type="radio" name="radio"/>
                        <span className="checkmark"></span>
                        <span className="cont">Public/Open Session</span>
                    </label>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseSession;
