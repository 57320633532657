import React, { useState } from "react";
import menuIcon from "../../../assets/images/menu.png";
import crossIcon from "../../../assets/images/cross-icon.png";
import "../../dashboard/css/media.css";
import { mentorLogo } from "../../../assets/images/export-images";

const Header = ({ extraClasses = "" }) => {
  const [isOpen, setIsOpen] = useState(true);


 
  return (
    <>

      <header className={`${extraClasses} header-cls reg-header-bottom position-relative`} style={{padding:"0.4%"}}>
        <meta
          name="viewport"
          content="width=device-width, initial scale=1.0,user-scalable=no"
        />
        <div className="container">
          <nav className="menu navbar navbar-expand-lg">
            <a className="navbar-brand" href="/">
              <img src={mentorLogo} alt="logo" className="header-logo" />
            </a>
            <button
              className={"navbar-toggler"}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#headerHandler"
              aria-controls="headerHandler"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                alt=""
                style={{ color: "white" }}
                src={isOpen ? menuIcon : crossIcon}
              />
            </button>

            
            <div
              className="menu collapse navbar-collapse rounded"
              id="headerHandler"
            >
              <ul className="navbar-nav ml-auto ">
                <li className="nav-item">
                   
                </li>
              </ul>
            </div>
          </nav>
        </div>

      </header>   

    </>
  );
};

export default Header;
