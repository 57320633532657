import React from 'react';
import { undrawProfile } from '../../../assets/images/export-images';
import fullStackBanner from '../assets/fullStackBanner.jpg'

const AllCourseDashboard = () => {
    const cours__data = [
        {
            "course_name": "Web Development Fundamentals",
            "course_start_date": "2023-09-15",
            "course_image": fullStackBanner,
            "course_detail_link": "example.com/courses/webdevfundamentals",
            "course_hosted_by": "Tech Academy",
            "course_syllabus": "example.com/syllabus/webdevfundamentals",
            "course_type": "Online"
        },
        {
            "course_name": "Introduction to Python Programming",
            "course_start_date": "2023-10-05",
            "course_image": fullStackBanner,
            "course_detail_link": "example.com/courses/pythonprogramming",
            "course_hosted_by": "Code Masters University",
            "course_syllabus": "example.com/syllabus/pythonprogramming",
            "course_type": "In-Person"
        },
        {
            "course_name": "Digital Marketing Masterclass",
            "course_start_date": "2023-11-20",
            "course_image": fullStackBanner,
            "course_detail_link": "example.com/courses/digitalmarketing",
            "course_hosted_by": "Marketing Innovators Institute",
            "course_syllabus": "example.com/syllabus/digitalmarketing",
            "course_type": "Hybrid"
        },
        {
            "course_name": "Art History Through the Ages",
            "course_start_date": "2024-01-10",
            "course_image": fullStackBanner,
            "course_detail_link": "example.com/courses/arthistory",
            "course_hosted_by": "Metropolitan Art Institute",
            "course_syllabus": "example.com/syllabus/arthistory",
            "course_type": "In-Person"
        },
        {
            "course_name": "Data Science Bootcamp",
            "course_start_date": "2024-03-03",
            "course_image": fullStackBanner,
            "course_detail_link": "example.com/courses/datascience",
            "course_hosted_by": "Analytics Pro Academy",
            "course_syllabus": "example.com/syllabus/datascience",
            "course_type": "Online with Live Sessions"
        }
    ]
    return (
        <>
            <div className="table-responsive">
                {cours__data?.length > 0 ? (
                    <table style={{ width: "100%" }}>
                        <tbody>
                            {cours__data?.map((course, index) => (
                                <tr key={index}>
                                    <td className="d-flex">
                                        <div className="container">
                                            <div className="row mobile-view-privatesession">

                                                <h3 className="pro-name">
                                                    Title: {" "}{course?.course_name}



                                                </h3>
                                                <div className="col-lg-3 col-md-3">

                                                    <div className="course__banner_image">
                                                        {course?.course_image
                                                            ? (
                                                                <img
                                                                    src={course?.course_image}
                                                                    // src={`${process.env.REACT_APP_IMAGES_BASE_URL}${menteeRequest?.connection_details?.profile_photo}`}
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <img alt="icon" src={undrawProfile} />
                                                            )}
                                                    </div>

                                                </div>

                                                <div className="col-lg-3 col-md-3 d-flex " style={{ flex: "auot", flexDirection: "row" }} >
                                                    <div className="row">
                                                        <td>
                                                            <h3 className="pro-name ml-auto">
                                                                Mode
                                                                <span className="small-cls">
                                                                    {course?.course_type}

                                                                </span>
                                                            </h3>
                                                        </td>
                                                        <td>
                                                            <div className="get-started-cls why_choose_ment">
                                                                <a href="/find-mentor">
                                                                    <button type="button" className="find-btn btn btn-primary">
                                                                        Download Syllabus
                                                                    </button>
                                                                </a>

                                                            </div>
                                                        </td>
                                                    </div>
                                                    <div className="row">
                                                        <td>
                                                            <h3 className="pro-name ml-auto">
                                                                Start Date
                                                                <span className="small-cls">

                                                                    {course?.course_start_date}
                                                                </span>
                                                            </h3>
                                                        </td>
                                                        <td>
                                                            <div className="get-started-cls why_choose_ment">

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                >
                                                                    View Detail Page
                                                                </button>
                                                            </div>
                                                        </td>

                                                    </div>
                                                    <div className="row">
                                                        <td>
                                                            <h3 className="pro-name ml-auto">
                                                                hosted by
                                                                <span className="small-cls">

                                                                    {course?.course_hosted_by}
                                                                </span>
                                                            </h3>
                                                        </td>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    {/* <hr /> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>
                        <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
                            <h2>No Course Found</h2>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default AllCourseDashboard;

