import React, { useState } from "react";
import {
  countries,
  experience,
  expertise,
  languages,
} from "../../../assets/data/data";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import "./filterModal.css";
const FilterModal = ({
  modalIsOpen,
  setIsOpen,
  closeModal,
  filterModal,
  setFilterModal,
  setfilterCount,
  handleFilterModalSubmit,
  resetFilterModal,
  FilterOptions,
}) => {
  
  return (
    <div className="modal_parent" >
      <Modal show={modalIsOpen} onHide={closeModal} className="modal">
        <Modal.Body className="modal_body_filter">
          <Modal.Header closeButton>
            <Modal.Title>Find Mentor</Modal.Title>
          </Modal.Header>
          <Form>
            <Row style={{marginTop:"20px"}} >
              
              <Col sm={6} >
              <Form.Group>
              <Form.Label>Expertise</Form.Label>
              <Form.Select
                value={filterModal.expertise}
                onChange={(e) =>
                  setFilterModal((prev) => ({
                    ...prev,
                    expertise: e.target.value,
                  }))
                }
              >
                <option value="No Filter">No Filter</option>
                {expertise.map((filter) => (
                  <option key={filter.value} value={filter.value}>
                    {filter.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
              </Col>
              <Col sm={6} >
              <Form.Group>
              <Form.Label>Country</Form.Label>
              <Form.Select
                value={filterModal.countries}
                onChange={(e) =>
                  setFilterModal((prev) => ({
                    ...prev,
                    countries: e.target.value,
                  }))
                }
              >
                <option value="No Filter">No Filter</option>
                {countries.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
              </Col >
              <Col sm={6} >
              <Form.Group>
              <Form.Label>Experience</Form.Label>
              <Form.Select
                value={filterModal.experience}
                onChange={(e) =>
                  setFilterModal((prev) => ({
                    ...prev,
                    experience: e.target.value,
                  }))
                }
              >
                <option value="No Filter">No Filter</option>
                {experience.map((exp) => (
                  <option key={exp.value} value={exp.value}>
                    {exp.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
              </Col>
              <Col sm={6} >
              <Form.Group>
              <Form.Label>Pro Bono</Form.Label>
              <Form.Select
                onChange={(e) =>
                  setFilterModal((prev) => ({
                    ...prev,
                    pro_bono: e.target.value,
                  }))
                }
              >
                <option  value="No Filter">No Filter</option>
                <option value = "Yes">Yes</option>
                <option value = "No">No</option>
              </Form.Select>
            </Form.Group>
              </Col>
             

              <Col sm={6}>
              <Form.Group>
              <Form.Label>Languages</Form.Label>
              <Form.Select
                value={filterModal.languages}
                onChange={(e) =>
                  setFilterModal((prev) => ({
                    ...prev,
                    languages: e.target.value,
                  }))
                }
              >
                <option value="No Filter">No Filter</option>
                {languages.map((language) => (
                  <option key={language.value} value={language.value}>
                    {language.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
              </Col>

             
              
            </Row>
          
          </Form>
          
          
          <Modal.Footer
            style={{
              background: "white",
              display: "flex",
              justifyContent: "space-evenly",
              marginTop:"30px"
            }}  
          >
            <Button variant="secondary" style={{background:"linear-gradient(65deg, #d96980, #3d29a3)", border:"none",borderRadius: "20px"} } onClick={resetFilterModal}>
              CLEAR FILTERS
            </Button>
            <Button variant="secondary" style={{background:"linear-gradient(65deg, #d96980, #3d29a3)", border:"none",borderRadius: "20px"} }  onClick={handleFilterModalSubmit}>
              EXPLORE
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FilterModal;
