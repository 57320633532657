import React from 'react';

const AnswerdQueries = () => {
    const data = true
    return (
        <>
        {
            data ? (
                <>
                            <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
                                <div className="ant-row queries ">
                                    <div className="ant-col ant-col-xs-0 ant-col-md-24 ">
                                        <div className="ant-row ">
                                            <div id="el" className="ant-col ant-col-6 query-listing ">
                                                <div className="ant-row ">
                                                    <div className="ant-col queries-select ant-col-xs-24 ant-col-md-24 ">
                                                        <div className="ant-select ant-select-lg  ant-select-single ant-select-show-arrow">
                                                            <div className="ant-select-selector">
                                                                <span className="ant-select-selection-search">
                                                                    <input
                                                                        type="search"
                                                                        autoComplete="off"
                                                                        className="ant-select-selection-search-input"
                                                                        role="combobox"
                                                                        aria-expanded="false"
                                                                        aria-haspopup="listbox"
                                                                        aria-owns="rc_select_53_list"
                                                                        aria-autocomplete="list"
                                                                        aria-controls="rc_select_53_list"
                                                                        readOnly=""
                                                                        unselectable="on"
                                                                        defaultValue=""
                                                                        style={{ opacity: 0 }}
                                                                        id="rc_select_53"
                                                                    />
                                                                </span>
                                                                <span className="ant-select-selection-item" title="All DMs">
                                                                    All DMs
                                                                </span>
                                                            </div>
                                                            <span
                                                                className="ant-select-arrow"
                                                                unselectable="on"
                                                                aria-hidden="true"
                                                                style={{ userSelect: "none" }}
                                                            >
                                                                <span
                                                                    role="img"
                                                                    aria-label="down"
                                                                    className="anticon anticon-down ant-select-suffix"
                                                                >
                                                                    <svg
                                                                        viewBox="64 64 896 896"
                                                                        focusable="false"
                                                                        data-icon="down"
                                                                        width="1em"
                                                                        height="1em"
                                                                        fill="currentColor"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ant-row ">
                                                    <div className="ant-col query-list-item active ant-col-xs-24 ant-col-md-0 ">
                                                        <div className="ant-typography query-list-title-section ">
                                                            <span className="ant-typography query-user-name ">
                                                                dsgs
                                                            </span>
                                                            <span className="ant-typography query-user-subtitle ">
                                                                8 Jan
                                                            </span>
                                                        </div>
                                                        <div className="ant-typography ">
                                                            <span className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line query-user-subtitle ">
                                                                sadfadfa
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="ant-col query-list-item active ant-col-xs-0 ant-col-md-24 ">
                                                        <div className="ant-typography query-list-title-section ">
                                                            <span className="ant-typography query-user-name ">
                                                                dsgs
                                                            </span>
                                                            <span className="ant-typography query-user-subtitle ">
                                                                8 Jan
                                                            </span>
                                                        </div>
                                                        <div className="ant-typography ">
                                                            <span className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line query-user-subtitle ">
                                                                sadfadfa
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ant-col ant-col-17 ant-col-offset-1 ">
                                                <div className="ant-row ">
                                                    <div className="ant-col query-header-section ant-col-xs-24 ant-col-md-18 ">
                                                        <img
                                                            alt="Query Img"
                                                            loading="lazy"
                                                            width={40}
                                                            height={40}
                                                            decoding="async"
                                                            data-nimg={1}
                                                            className="query-user-img"
                                                            srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Fqueries%2Ffollower_query.png&w=48&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Fqueries%2Ffollower_query.png&w=96&q=75 2x"
                                                            src="/_next/image?url=%2Fimages%2Fdashboard%2Fqueries%2Ffollower_query.png&w=96&q=75"
                                                            style={{ color: "transparent", height: "auto" }}
                                                        />
                                                        <div className="ant-typography ">
                                                            <div className="ant-typography query-user-name ">
                                                                dsgs
                                                            </div>
                                                            <div className="ant-typography query-user-subtitle ">
                                                                asfdasdf@gmail.com | +912345677777
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ant-col header-tags ant-col-xs-0 ant-col-md-6 ">
                                                        <span className="ant-typography public-profile-pricing ">
                                                            Free
                                                        </span>
                                                    </div>
                                                    <div className="ant-col ant-col-24 query-details-section ">
                                                        <div className="ant-typography query-value ">
                                                            <span className="ant-typography query-details-title ">
                                                                Priority DM
                                                            </span>
                                                            <span className="ant-typography query-details-text ">
                                                                sadfadfa
                                                            </span>
                                                        </div>
                                                        <div className="ant-typography query-questions " />
                                                        <div className="ant-typography query-value ">
                                                            <span className="ant-typography query-details-title ">
                                                                Your Response
                                                            </span>
                                                            <span className="ant-typography query-details-text response ">
                                                                Okay, thanks
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ant-col ant-col-xs-24 ant-col-md-0 ">
                                        <div id="elmobile" className="ant-col query-listing ">
                                            <div className="ant-row ">
                                                <div className="ant-col queries-select ant-col-xs-24 ant-col-md-24 ">
                                                    <div className="ant-select ant-select-lg  ant-select-single ant-select-show-arrow">
                                                        <div className="ant-select-selector">
                                                            <span className="ant-select-selection-search">
                                                                <input
                                                                    type="search"
                                                                    autoComplete="off"
                                                                    className="ant-select-selection-search-input"
                                                                    role="combobox"
                                                                    aria-expanded="false"
                                                                    aria-haspopup="listbox"
                                                                    aria-owns="rc_select_54_list"
                                                                    aria-autocomplete="list"
                                                                    aria-controls="rc_select_54_list"
                                                                    readOnly=""
                                                                    unselectable="on"
                                                                    defaultValue=""
                                                                    style={{ opacity: 0 }}
                                                                    id="rc_select_54"
                                                                />
                                                            </span>
                                                            <span className="ant-select-selection-item" title="All DMs">
                                                                All DMs
                                                            </span>
                                                        </div>
                                                        <span
                                                            className="ant-select-arrow"
                                                            unselectable="on"
                                                            aria-hidden="true"
                                                            style={{ userSelect: "none" }}
                                                        >
                                                            <span
                                                                role="img"
                                                                aria-label="down"
                                                                className="anticon anticon-down ant-select-suffix"
                                                            >
                                                                <svg
                                                                    viewBox="64 64 896 896"
                                                                    focusable="false"
                                                                    data-icon="down"
                                                                    width="1em"
                                                                    height="1em"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ant-row ">
                                                <div className="ant-col query-list-item active ant-col-xs-24 ant-col-md-0 ">
                                                    <div className="ant-typography query-list-title-section ">
                                                        <span className="ant-typography query-user-name ">
                                                            dsgs
                                                        </span>
                                                        <span className="ant-typography query-user-subtitle ">
                                                            8 Jan
                                                        </span>
                                                    </div>
                                                    <div className="ant-typography ">
                                                        <span className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line query-user-subtitle ">
                                                            sadfadfa
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="ant-col query-list-item active ant-col-xs-0 ant-col-md-24 ">
                                                    <div className="ant-typography query-list-title-section ">
                                                        <span className="ant-typography query-user-name ">
                                                            dsgs
                                                        </span>
                                                        <span className="ant-typography query-user-subtitle ">
                                                            8 Jan
                                                        </span>
                                                    </div>
                                                    <div className="ant-typography ">
                                                        <span className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line query-user-subtitle ">
                                                            sadfadfa
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                </>
            ):(
                <>
                            <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
                                <div
                                    className="ant-row ant-row-center ant-row-middle "
                                    style={{ marginTop: 30 }}
                                >
                                    <div className="ant-col text-center ant-col-xs-24 ant-col-md-10 ">
                                        <img
                                            alt="No Pending/Answered Queries"
                                            loading="lazy"
                                            width={298}
                                            height={194}
                                            decoding="async"
                                            data-nimg={1}
                                            className=""
                                            srcSet="/_next/image?url=%2Fimages%2Fdashboard%2Fservices%2Fimg-query-empty-state.svg&w=384&q=75 1x, /_next/image?url=%2Fimages%2Fdashboard%2Fservices%2Fimg-query-empty-state.svg&w=640&q=75 2x"
                                            src="/_next/image?url=%2Fimages%2Fdashboard%2Fservices%2Fimg-query-empty-state.svg&w=640&q=75"
                                            style={{ color: "transparent", height: "auto", marginBottom: 10 }}
                                        />
                                        <div className="ant-space  ant-space-vertical ant-space-align-center ant-space-gap-row-middle ant-space-gap-col-middle">
                                            <div className="ant-space-item">
                                                <h5
                                                    className="ant-typography "
                                                    style={{ marginBottom: 0, marginTop: 16 }}
                                                >
                                                    Try Priority DM
                                                </h5>
                                            </div>
                                            <div className="ant-space-item">
                                                <div className="ant-typography " style={{ margin: 0 }}>
                                                    Priority DM allows you to accept DM requests without revealing
                                                    your information and reply seamlessly through <b>WhatsApp</b>.
                                                </div>
                                            </div>
                                            <div className="ant-space-item">
                                                <button
                                                    type="button"
                                                    className="ant-btn  ant-btn-primary btn-dark"
                                                    style={{ marginTop: 8 }}
                                                >
                                                    <span>Add Priority DM</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </>
            )
        }

           
        </>
    );
}

export default AnswerdQueries;
