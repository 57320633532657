import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { REACT_USER_ID } from "../../../auth/tokenProvider";
import "../../../dashboard/css/dashboard.css";
import { Link, NavLink } from "react-router-dom";
import UpdateName from "../../profileUpdate/UpdateName";
import UpdateAbout from "../../profileUpdate/UpdateAbout";
import UpdateLanguage from "../../profileUpdate/UpdateLanguage";
import UpdateCountry from "../../profileUpdate/UpdateCountry";
import { getUserDetails, updateProfile } from "../../../../redux/actions/login";
import UpdateLeadership from "../../profileUpdate/UpdateLeadership";
import UpdateInterest from "../UpdateInterest";
import UpdatePrice from "../../profileUpdate/UpdatePrice";
import Professional from "../professional";
import Security from "../security";

const Personal = () => {
  useEffect(() => {
    import("../../css/custom.css");
    import("../CustomProfile.css");
  }, []);

  const dispatch = useDispatch();

  const userDetailsData = useSelector(
    (state) => state?.userDetailsReducer?.data[0]
  );
  const editStateObject = {
    name: false,
    country: false,
    // timezone: false,
    about: false,
    // phone: false,
    price: false,
    language: false,
    industry: false,
    interests: false,
  };
  const NO_VALUE = "N/A";
  const user_id = REACT_USER_ID;
  const getStringValueFromList = (lst) => {
    if (lst) return lst.join(", ");
    else return "N/A";
  };

  

  const getUpdatedValues = (data) => {
    let tempData = { ...data, user_id: user_id };
    // setLoader(true);
    dispatch(updateProfile(tempData)).then(setTimeout(()=>{
      window.location.reload();
    },500));
    dispatch(getUserDetails(user_id));
    
  };

  
  const fieldComponentMapping = {
    name: {
      lable: "Name",
      value: userDetailsData?.name || NO_VALUE,
      component: (
        <UpdateName
          nameData={userDetailsData?.name}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    country: {
      lable: "Country",
      value: userDetailsData?.country || NO_VALUE,
      component: (
        <UpdateCountry
          countryData={userDetailsData?.country}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    // timezone: {
    //   lable: "Timezone",
    //   value: "Timezone value",
    //   component: <div>Timezone Component</div>,
    // },
    about: {
      lable: "About",
      value: userDetailsData?.story || NO_VALUE,
      component: (
        <UpdateAbout
          aboutData={userDetailsData?.story}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    // phone: {
    //   lable: "Phone",
    //   value: "Phone value",
    //   component: <div>Phone Component</div>,
    // },
    price: {
      lable: "Price per session",
      value: userDetailsData?.price_per_session || NO_VALUE,
      component: (
        <UpdatePrice
          priceData={userDetailsData?.price_per_session}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    language: {
      lable: "Language",
      value: getStringValueFromList(userDetailsData?.language) || NO_VALUE,
      component: (
        <UpdateLanguage
          languages={userDetailsData?.language}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    industry: {
      lable: "Leadership",
      value: getStringValueFromList(userDetailsData?.leadership) || NO_VALUE,
      component: (
        <UpdateLeadership
          leadership={userDetailsData?.leadership}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
    interests: {
      lable: "Interests",
      value: getStringValueFromList(userDetailsData?.mentorship) || NO_VALUE,
      component: (
        <UpdateInterest
          interest={userDetailsData?.mentorship}
          getUpdatedValues={getUpdatedValues}
        />
      ),
    },
  };

  const fieldList = Object.keys(editStateObject);

  const [editState, setEditState] = useState(editStateObject);
  const changeEditStateMethod = (stateName) => {
    let newValue = !editState[stateName];
    setEditState({ ...editState, [stateName]: newValue });
  };
  const userDetails = useSelector((state) => {
    const data = state.userDetailsReducer?.data || [];
    return data[0];
  });
  return (
    <>
      <div className="update-field-container">
        {fieldList?.map((field, index) => (
          <div key={index} className="update-field-row">
            <div className="details-edit-btn-header">
              <span>{fieldComponentMapping[field]?.lable}</span>
              <Link to="" onClick={() => changeEditStateMethod(field)}>
                {editState[field] === false ? "Edit" : "Cancel"}
              </Link>
            </div>
            {editState[field] ? (
              fieldComponentMapping[field]?.component
            ) : (
              <div className="spanPadd">
                {fieldComponentMapping[field]?.value}
              </div>
            )}
            <hr />
          </div>
        ))}
      </div>
      <Professional />
    </>
  );
};

export default Personal;
