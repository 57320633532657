import React, { useState } from "react";

import "../MentorDashboard/menorDashboard.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/actions/login";
import { crossIcon } from "../../assets/images/export-images";
import DashboardHeader from "../adminDashboard/DashboardHeader";
import Footer from "../footer";

const MenteeSideNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogOut = () => {
    window.location.assign("/");
    localStorage.removeItem("REACT_TOKEN_AUTH");
    localStorage.removeItem("REACT_USER_ID");
    dispatch(logoutUser(""));
  };
  const [showSidebar, setShowSidebar] = useState(true);
  const showSideNavbar = () => {
    // Function logic goes here
    console.log("Showing side navbar...");
    setShowSidebar(!showSidebar);
  };

  const [activeButton, setActiveButton] = useState("");
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    navigate(buttonName);
  };

  return (
    <>
      <DashboardHeader showSideNavbar={showSideNavbar} />
      <div className="sidebar__container d-flex ">
        <div
          className="sidebar-overlay"
          style={{
            display: showSidebar ? "block" : "none",
            "@media screen and (max-width: 768px)": {
              display: "block",
            },
          }}
          onClick={showSideNavbar}
        />

        {/* Sidebar Content */}
        <div
          className="sidebar-content"
          style={{
            display: showSidebar ? "block" : "none",
            transform: showSidebar ? "translateX(0)" : "none",
            transition: "transform 0.3s ease",
            "@media screen and (max-width: 768px)": {
              display: "block",
              transform: "none",
            },
          }}
        >
          <ul className="nav flex-column">
            <li className="nav-item itemss " id="cross___Icon">
              <a className="nav-link linkss " onClick={showSideNavbar}>
                <span className="d-flex align-items-center">
                  {" "}
                  <img src={crossIcon} alt="" />
                </span>{" "}
              </a>
            </li>
            <li
              className={`nav-item itemss ${
                activeButton === "" ? "active___side_tabs" : ""
              }`}
            >
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="black"
                      class="bi bi-speedometer2 ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
                      />
                    </svg>
                    <span className="ms-2" style={{ color: "grey" }}>
                      Dashboard
                    </span>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li
              className={`nav-item itemss ${
                activeButton === "connection-request"
                  ? "active___side_tabs"
                  : ""
              }`}
            >
              {" "}
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("connection-request")}
              >
                {" "}
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-plus-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      <path
                        fill-rule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                    <article className="ms-2" style={{ color: "grey" }}>
                      All Connection
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            {/* <li
              className={`nav-item itemss ${
                activeButton === "sessions" ? "active___side_tabs" : ""
              }`}
            >
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("sessions")}
              >
                {" "}
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-plus-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      <path
                        fill-rule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                    <article className="ms-2" style={{ color: "grey" }}>
                       Session
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li> */}
            {/* <li
              className={`nav-item itemss ${
                activeButton === "livesession" ? "active___side_tabs" : ""
              }`}
            >
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("livesession")}
              >
                {" "}
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-plus-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      <path
                        fill-rule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                    <article className="ms-2" style={{ color: "grey" }}>
                      Live Session
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li> */}

            {/* <li
              className={`nav-item itemss ${
                activeButton === "find-mentor" ? "active___side_tabs" : ""
              }`}
            >
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("find-mentor")}
              >
                {" "}
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-bookmark-heart-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 15.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v13.5zM8 4.41c1.387-1.425 4.854 1.07 0 4.277C3.146 5.48 6.613 2.986 8 4.412z" />
                    </svg>
                    <article className="ms-2" style={{ color: "grey" }}>
                      Find Mentor
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li> */}
            {/* <li
              className={`nav-item itemss ${
                activeButton === "my-ebooks" ? "active___side_tabs" : ""
              }`}
            >
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("my-ebooks")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-plus-square-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      My E-Books
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li> */}
            {/* <li
              className={`nav-item itemss ${
                activeButton === "my-course" ? "active___side_tabs" : ""
              }`}
            >
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("my-course")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-plus-square-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      My Course
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li> */}
            {/* <li
              className={`nav-item itemss ${
                activeButton === "purchase-history" ? "active___side_tabs" : ""
              }`}
            >
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("purchase-history")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-plus-square-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      Purchase History
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li> */}
            <li
              className={`nav-item itemss ${
                activeButton === "my-goals" ? "active___side_tabs" : ""
              }`}
            >
              {" "}
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("my-goals")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-people-fill ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                    </svg>
                    <article className="ms-2" style={{ color: "grey" }}>
                      My Goals
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>

            <li
              className={`nav-item itemss ${
                activeButton === "myprofile" ? "active___side_tabs" : ""
              }`}
            >
              {" "}
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("myprofile")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-person-circle ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                    <article className="ms-2" style={{ color: "grey" }}>
                      My Profile
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
            <li
              className={`nav-item itemss ${
                activeButton === "event-calendar" ? "active___side_tabs" : ""
              }`}
            >
              {" "}
              <a
                className="nav-link linkss"
                onClick={() => handleButtonClick("event-calendar")}
              >
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-person-circle ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                    <article className="ms-2" style={{ color: "grey" }}>
                      Connect Calendar
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>

            <li
              className={`nav-item itemss ${
                activeButton === "handleLogOut" ? "active___side_tabs" : ""
              }`}
            >
              {" "}
              <a className="nav-link linkss" onClick={handleLogOut}>
                <span className="d-flex align-items-center">
                  {" "}
                  <span className="sidebar-icon-img d-flex align-items-center navi-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="black"
                      class="bi bi-box-arrow-left ms-2 me-3"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                      />
                    </svg>{" "}
                    <article className="ms-2" style={{ color: "grey" }}>
                      LogOut
                    </article>
                  </span>{" "}
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div id="content-wrapper" className=" w-100">
          <div id="content">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MenteeSideNavBar;
