import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { GraduationYear, Qualification, compaines, countries, experience, expertise, title, userSkills } from "../../../../assets/data/data";
import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_ID } from "../../../auth/tokenProvider";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../../redux/actions/login";
import SelectListComponent from "../../../SelectListComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import SelectMultiInputs from "../../../applyAsMentor/selectMultiInputs";


const StudentDetails = ({
    registrationProcessData,
    setRegistrationProcessData,
    errors,
}) => {
    const [selectedExperties, setSelectedExperties] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);

    useEffect(() => {

        // ---------------- experties data
        if (registrationProcessData.experties?.length > 0) {
            let exp = [];
            registrationProcessData?.experties?.map((item, id) => {
                if (item !== selectedExperties[id]?.value) {
                    // let label = expertise?.filter((val) => {
                    //   if (val?.value == item) return val;
                    // });
                    exp.push({ label: item, value: item });
                }
            });
            setSelectedExperties(exp);
        }
        // 
        if (registrationProcessData.course !== "") {
            Qualification?.map((item, id) => {
                if (item.label == registrationProcessData.course) {
                    setSelectedCourse([item]);
                }
            });
        }
        // 
        if (registrationProcessData.year !== "") {
            GraduationYear?.map((item, id) => {
                if (item.label == registrationProcessData.year) {
                    setSelectedYear([item]);
                }
            });
        }



    }, []);
    const handleRemove = (title, toRemove, selectedData, setSelectedData) => {
        const updatedData = selectedData.filter((item) => {
            return item.value !== toRemove.value;
        });
        setSelectedData(updatedData);

        setRegistrationProcessData((prev) => ({
            ...prev,
            [title]: updatedData.map((title) => title.label),
        }));
    };
    return (
        <>
            <div className="registrationCard">
                <div className=" leftSide">
                    <h3 className="mb-32 fw-bold">
                        Love it! Tell us more about what you do.
                    </h3>
                    <p className="related-context">
                        Are you working in corporate at the present? Yes!! Let us know what
                        company and your job title.
                    </p>
                </div>

                <div className="row rightSide">
                    <div className="container ">
                        <div className="form-group">
                            {selectedExperties.length > 0 ? (
                                <div className="multi-inputs-container">
                                    <div className="text">
                                        Which experties  would you like to grow?*
                                    </div>
                                    <div className="multi-inputs">
                                        {selectedExperties.map((experties) => (
                                            <div className="multi-input-design" key={experties.value}>
                                                <div key={experties.value}>{experties.label}</div>
                                                <div
                                                    key={experties.value}
                                                    onClick={() =>
                                                        handleRemove(
                                                            "experties",
                                                            experties,
                                                            selectedExperties,
                                                            setSelectedExperties
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        className="multi-select-icon"
                                                        icon={faXmark}
                                                        style={{ color: "gray" }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className=" multi-inputs-container">
                                    <labe> Which experties  would you like to grow?*</labe>
                                </div>
                            )}
                            <SelectMultiInputs
                                title="experties"
                                // label={"Which experties would you like to mentor?"}
                                value=""
                                onChangeFunc={(e) => {
                                    setSelectedExperties((prev) => [...prev, e]);
                                    // console.log('label', e.label);
                                    setRegistrationProcessData((prev) => ({
                                        ...prev,
                                        experties: [...prev.experties, e.label],
                                    }));
                                }}
                                is2d={true}
                                placeholder="Not found! Type your own"
                                IsMulti
                                isCreatable={true}
                                name="experties"
                                options={expertise}
                                closeMenuOnSelect={true}
                                className="basic-multi-select text-left"
                                classNamePrefix="select"
                                inputData={selectedExperties}
                                {...errors.experties}
                            />
                        </div>
                    </div>

                    <div className="container">
                        <div className="form-group ">
                            <SelectListComponent
                                title={"course"}
                                label={"Highest Qualification?*"}
                                value={selectedCourse ? selectedCourse : ""}
                                onChangeFunc={(e) => {
                                    let l = [];
                                    l?.push(e?.value);
                                    setSelectedCourse(e);
                                    setRegistrationProcessData((prev) => ({
                                        ...prev,
                                        course: l[0],
                                    }));
                                }}
                                menuPlacement="top"
                                closeMenuOnSelect={true}
                                placeholder="Select Qualification"
                                name="course"
                                options={Qualification}
                                className="basic-multi-select text-left"
                                classNamePrefix="select"
                                {...errors.course}
                            />
                        </div>
                    </div>



                    <div className="container">
                        <div className="form-group ">
                            <SelectListComponent
                                title={"year"}
                                label={"When did/will you complete your highest education?*"}
                                value={selectedYear ? selectedYear : ""}
                                onChangeFunc={(e) => {
                                    let l = [];
                                    l?.push(e?.value);
                                    setSelectedYear(e);
                                    setRegistrationProcessData((prev) => ({
                                        ...prev,
                                        year: l[0],
                                    }));
                                }}
                                menuPlacement="top"
                                closeMenuOnSelect={true}
                                placeholder="Select year"
                                name="year"
                                options={GraduationYear}
                                className="basic-multi-select text-left"
                                classNamePrefix="select"
                                {...errors.year}
                            />
                        </div>
                    </div>

                    <div className=" multi-inputs-container mb-0">
                        <label>LinkedIn URL</label>
                    </div>
                    <div className="container">

                        <div className="form-group">
                            <input

                                onChange={(e) => {
                                    e.preventDefault();
                                    // if (!errors.linkedin_url?.onValidateFunc) return;
                                    // let msg = null;
                                    // if (!e.target.value && errors.linkedin_url?.isReq) {
                                    //     msg = `Please type your linkedin url.`;
                                    // }
                                    // errors.linkedin_url?.onValidateFunc(msg, "linkedin_url");
                                    setRegistrationProcessData((prev) => ({
                                        ...prev,
                                        linkedin_url: e.target.value,
                                    }));
                                }}
                                type="url"
                                className="form-control"
                                placeholder="linkedin.com/in/. "
                                required
                                defaultValue={registrationProcessData?.linkedin_url}
                            />
                            {/* {errors?.linkedin_url?.errorMsg && (
                                <span className="text-danger">
                                    {errors?.linkedin_url?.errorMsg === true
                                        ? `Please type your linkedin_url.`
                                        : errors?.linkedin_url?.errorMsg}
                                </span>
                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default StudentDetails;
