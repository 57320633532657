import React from "react";

const Savedlist = () => {
  return (
    <div className="no___data">
      <h1>No data to show</h1>
    </div>
  );
};

export default Savedlist;
