import React, { useState } from "react";
import "./chat.css";
import maleIcon from "../../assets/images/male.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getConnectionListAction,
  getMenteeInboxListAction,
} from "../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import { undrawProfile } from "../../assets/images/export-images";

const ChatInboxList = ({ onUserSelect, ConnectionListData }) => {
  const dispatch = useDispatch();
  const [selectvalue, setselectvalue] = useState("");
  const selectChatBox = (e) => {
    onUserSelect(JSON.parse(e.target.value));
  };
  useEffect(() => {
    console.log("getChatInbox Calling dispatch");
    //  dispatch(getConnectionListAction());
  }, []);

  // const ConnectionListData = useSelector(
  //   (state) => state.getConnectionListReducer?.data
  // );
  const isLoading = useSelector(
    (state) => state.getConnectionListReducer?.loading
  );
  return (
    <>
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div>
          <div style={{ maxHeight: "74vh", overflow: "auto" }}>
            {Array.isArray(ConnectionListData) &&
            ConnectionListData.length > 0 ? (
              ConnectionListData.map((item, index) => (
                <div>
                  <ul className="chatInbox___list d-none d-md-block">
                    <div key={index} className="User____inbox">
                      <li
                        className="individual_chatbox"
                        style={{
                          borderBottom: "1px solid grey",
                          padding: "0.25rem 0.75rem",
                        }}
                        onClick={() => onUserSelect(item)}
                      >
                        {item.connection_details?.profile_photo ? (
                          <img
                            className="rounded-circle"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginLeft: "2%",
                            }}
                            src={`${process.env.REACT_APP_IMAGES_BASE_URL}${item.connection_details?.profile_photo}`}
                            alt="error"
                          />
                        ) : (
                          <img
                            style={{
                              width: "50px",
                              height: "50px",
                              marginLeft: "2%",
                            }}
                            className="rounded-circle"
                            alt="icon"
                            src={undrawProfile}
                          />
                        )}
                        <span className="my-1 mx-2 pt-1 me-auto user_name">
                          {item.connection_details?.name}
                        </span>
                      </li>
                    </div>
                  </ul>
                </div>
              ))
            ) : (
              <ul className="chatInbox___list">
                <li
                  className="individual_chatbox"
                  style={{ whiteSpace: "normal" }}
                >
                  No Mentee here Check Your Connection Request.
                </li>
              </ul>
            )}
          </div>
          <div className="d-md-none">
            {" "}
            <div className="form-group">
              <select
                name="chat_inbox_mobile"
                onChange={selectChatBox}
                style={{ marginBottom: "10px" }}
                className="form-select form-select-lg mb-2"
              >
                <option value="">Select a user</option>
                {Array.isArray(ConnectionListData) &&
                ConnectionListData.length > 0 ? (
                  ConnectionListData.map((item, index) => (
                    <option value={JSON.stringify(item)}>
                      <div key={index} className="User____inbox">
                        <span className="my-1 mx-2 pt-1 me-auto user_name">
                          {item.connection_details?.name}
                        </span>
                      </div>
                    </option>
                  ))
                ) : (
                  <option value="">No Mentee here</option>
                )}
              </select>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatInboxList;
