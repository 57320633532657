import { Button, Form, Input, message, Radio } from "antd";
import { useEffect, useState } from "react";
import { REACT_USER_ID } from './../../auth/tokenProvider';
import { addBankAccountAction } from "../../../redux/actions/common";
import { useDispatch, useSelector } from "react-redux";


const BankAccountNumberRegexPattern = /^[0-9]{7,18}$/;
const IFSCRegexPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;

const BankDetailsForm = (props) => {
    const dispatch = useDispatch()
    const { bank_details, onModalClose } = props;
   
    const [BankDetailsForm] = Form.useForm();

    const [bankDetailsExists, setBankDetailsExists] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const addBankAccountReducer = useSelector((state) => state?.addBankAccountReducer)
    useEffect(()=>{
        const { data, loading, error, status } = addBankAccountReducer
       setLoading(loading)
    if(data && !loading){
        message.success("success!")
    }

    }, [addBankAccountReducer])

    const submitBankDetails = (fields) => {
        setLoading(true);
        const { account_name, account_number, account_type, ifsc, routing_number } =
            fields;
        let postObj = {
            account_name: account_name,
        };
        if (bank_details?.currency === "INR") {
            postObj = Object.assign(
                {
                    account_number: account_number,
                    account_type: account_type || "savings",
                    ifsc: ifsc,
                },
                postObj
            );
        }
        if (bank_details?.country === "US" && bank_details?.currency === "USD") {
            postObj = Object.assign(
                {
                    account_number: account_number,
                    payment_gateway: "stripe_acc",
                    routing_number: routing_number,
                },
                postObj
            );
        }

        dispatch(addBankAccountAction({
            ...postObj, mentor_id:
                REACT_USER_ID
        }))
        setTimeout(()=>{
            onModalClose()
        },3000)

    };



    const [formEdit, setFormEdit] = useState([]);

    useEffect(() => {
        if (bank_details?.status === false) {
            setBankDetailsExists(false);

        } else {
            setBankDetailsExists(true);

        }
    }, []);

    return (
        <>
            <Form
                form={BankDetailsForm}
                requiredMark={false}
                initialValues={bank_details}
                layout="vertical"
                onValuesChange={(val) => {
                    const key = Object.keys(val)[0];
                    !bank_details
                        ? setFormEdit([...formEdit, key])
                        : bank_details && bank_details[key] !== val[key]
                            ? formEdit?.indexOf(key) < 0 && setFormEdit([...formEdit, key])
                            : formEdit?.indexOf(key) > -1 &&
                            setFormEdit(formEdit.filter((item) => item !== key));
                }}
                onFinish={submitBankDetails}
            >

                {/* Account Type */}
                {bank_details?.currency === "INR" && (
                    <Form.Item label="Account Type" name="account_type">
                        <Radio.Group
                            className="radio-checkbox onboarding-checkbox"
                            defaultValue={"savings"}
                            style={{ justifyContent: "flex-start" }}
                        >
                            <Radio.Button
                                className="radio-checkbox-item"
                                value={"savings"}
                                style={{ width: "auto" }}
                            >
                                Savings
                            </Radio.Button>
                            <Radio.Button
                                className="radio-checkbox-item"
                                value={"current"}
                                style={{ width: "auto" }}
                            >
                                Current
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                )}

                {/* Account holder name */}
                <Form.Item
                    label="Account holder name"
                    name="account_name"
                    className="title-fields name-field"
                    rules={[
                        {
                            required: true,
                            message: "Bank account name is required.",
                        },
                        {
                            whitespace: true,
                            message: "Name cannot be empty!",
                        },
                        {
                            min: 3,
                            message: "This is too short!",
                        },
                        {
                            max: 200,
                            message: "Max 200 characters allowed!",
                        },
                    ]}
                >
                    <Input
                        placeholder={
                            bankDetailsExists && !editMode ? "******** ******" : ""
                        }
                        disabled={bankDetailsExists  && !editMode}
                    />
                </Form.Item>

                {/* Account holder ifsc */}
                {bank_details?.currency === "INR" && (
                    <Form.Item
                        label="IFSC code"
                        name="ifsc"
                        className="title-fields name-field"
                        rules={[
                            {
                                required: true,
                                message: "IFSC is required.",
                            },
                            {
                                whitespace: true,
                                message: "IFSC cannot be empty!",
                            },
                            {
                                pattern: IFSCRegexPattern,
                                message: "Please enter a valid IFSC code!",
                            },
                        ]}
                    >
                        <Input
                            placeholder={
                                bankDetailsExists && !editMode ? "******** ******" : ""
                            }
                            disabled={bankDetailsExists && !editMode}
                        />
                    </Form.Item>
                )}

                {/* Account holder number */}
                <Form.Item
                    label="Account number"
                    name="account_number"
                    className="title-fields name-field"
                    rules={[
                        {
                            required: true,
                            message: "Bank account number is required.",
                        },
                        {
                            whitespace: true,
                            message: "Bank account number cannot be empty!",
                        },
                        {
                            min: 9,
                            message: "This is too short!",
                        },
                        {
                            max: 18,
                            message: "Max 18 characters allowed!",
                        },
                        {
                            pattern: BankAccountNumberRegexPattern,
                            message: "Please enter a valid Account Number!",
                        },
                    ]}
                >
                    <Input
                        placeholder={
                            bankDetailsExists && !editMode ? "******** ******" : ""
                        }
                        disabled={bankDetailsExists && !editMode}
                    />
                </Form.Item>

                {/* Routing Number */}
                {bank_details?.country === "US" && bank_details?.currency === "USD" && (
                    <Form.Item
                        label="Routing Number"
                        name="routing_number"
                        className="title-fields name-field"
                        rules={[
                            {
                                required: true,
                                message: "Routing number is required.",
                            },
                            {
                                whitespace: true,
                                message: "Routing number cannot be empty!",
                            },
                            {
                                min: 7,
                                message: "This is too short!",
                            },
                            {
                                max: 18,
                                message: "Max 18 characters allowed!",
                            },
                            {
                                pattern: BankAccountNumberRegexPattern,
                                message: "Please enter a valid Routing Number!",
                            },
                        ]}
                    >
                        <Input
                            placeholder={
                                bankDetailsExists && !editMode ? "******** ******" : ""
                            }
                            disabled={bankDetailsExists && !editMode}
                        />
                    </Form.Item>
                )}

                {/* Submit */}
                <Form.Item>
                    <Button
                        block
                        htmlType={bankDetailsExists || !editMode ? "submit" : "button"}
                        className="btn-dark"
                        loading={loading}
                        disabled={loading}
                        onClick={() => {
                            if (bankDetailsExists) {
                                setEditMode(true);
                            }
                        }}
                    >
                        {bankDetailsExists || !editMode ? "Save Changes" : "Edit Details"}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default BankDetailsForm;
