import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { noResultImg } from "../../assets/images/export-images";
import { FeedbackForm } from "../Feedback";

const FindMentorNoResult = () => {
  const navigate = useNavigate();
  const [feedbackModal, setFeedbackModal] = useState({});

  return (
    <>
      <section className="noresult-cls">
        <div className="container">
          <div className="noresult-inner-cls">
            <h3>Didn’t Find mentor? No Worries</h3>
            <h5>
              Tell us your cretieria. we will arrange for you as soon as possible
            </h5>
            <div className="get-started">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#tellus"
                onClick={() =>
                  setFeedbackModal({
                    modal: FeedbackForm,
                    show: true,
                  })
                }
              >
                Submit Request
              </button>
            </div>

            <div className="noresult-img">
              <img alt="" src={noResultImg} />
            </div>
          </div>
        </div>
      </section>

      
      {
        feedbackModal?.modal && (
          <feedbackModal.modal
            feedbackModal={feedbackModal}
            setFeedbackModal={setFeedbackModal}
          />
        )
      }
    </>
  
  );
};

export default FindMentorNoResult;
