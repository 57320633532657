import {
  favoriteMentorsConstants,
  similarMentorsConstants,
  subscribedMentorsConstants,
  createFavoriteMentorsConstants,
  goalSimilarMentorsConstants,
  
  // removeFavoriteMentorsConstants
} from "../../constants";

export const favoriteMentorReducer = (state = {}, action) => {
  switch (action.type) {
    case favoriteMentorsConstants.FAVORITE_MENTOR_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case favoriteMentorsConstants.FAVORITE_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case favoriteMentorsConstants.FAVORITE_MENTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case favoriteMentorsConstants.REMOVE_FAVORITE_MENTOR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    default:
      return state;
  }
};

export const createFavoriteMentorReducer = (state = {}, action) => {
  switch (action.type) {
    case createFavoriteMentorsConstants.CREATE_FAVORITE_MENTOR_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case createFavoriteMentorsConstants.CREATE_FAVORITE_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case createFavoriteMentorsConstants.CREATE_FAVORITE_MENTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const subscribedMentorReducer = (state = {}, action) => {
  switch (action.type) {
    case subscribedMentorsConstants.SUBSCRIBED_MENTOR_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case subscribedMentorsConstants.SUBSCRIBED_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case subscribedMentorsConstants.SUBSCRIBED_MENTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const similarMentorReducer = (state = {}, action) => {
  switch (action.type) {
    case similarMentorsConstants.SIMILAR_MENTOR_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case similarMentorsConstants.SIMILAR_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case similarMentorsConstants.SIMILAR_MENTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const goalSimilarMentorReducer = (state = {}, action) => {
  switch (action.type) {
    case goalSimilarMentorsConstants.GOAL_SIMILAR_MENTOR_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case goalSimilarMentorsConstants.GOAL_SIMILAR_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case goalSimilarMentorsConstants.GOAL_SIMILAR_MENTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};


