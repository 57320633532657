import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAcceptConnectionRequestAction,
  getConnectionListAction,
  getMenteeInboxListAction,
} from "../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import { undrawProfile } from "../../assets/images/export-images";

const AccpetedRequest = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConnectionListAction());
  }, []);

  const ConnectionListData = useSelector(
    (state) => state.getConnectionListReducer?.data
  );
  const isLoading = useSelector(
    (state) => state.getConnectionListReducer?.loading
  );

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div className="table-responsive">
          {ConnectionListData?.length > 0 ? (
            <table style={{ width: "100%" }}>
              <tbody>
                {ConnectionListData?.map((menteeRequest, index) => (
                  <tr key={index}>
                    <td className="d-flex">
                      <div className="container">
                        <div className="row mobile-view-privatesession">
                          <div className="col-lg-3 col-md-3">
                            <div className="profile-box">
                              {menteeRequest?.connection_details
                                ?.profile_photo ? (
                                <img
                                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}${menteeRequest?.connection_details?.profile_photo}`}
                                  alt=""
                                />
                              ) : (
                                <img alt="icon" src={undrawProfile} />
                              )}
                            </div>
                            <h3 className="pro-name">
                              {menteeRequest?.connection_details?.name}
                            </h3>
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <td>
                              <h3 className="pro-name ml-auto">
                                Request Status
                                <span className="small-cls">
                                  {menteeRequest?.status}
                                </span>
                              </h3>
                            </td>
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* <hr /> */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>
              <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
                <h2>No Request Found</h2>
                {/* hello */}
                {/* <p>You haven't added any book</p> */}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AccpetedRequest;
