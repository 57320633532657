import React from 'react';
import "./certificate.css";


const CertificatedDetails = () => {
    return (
        <>
            <h3 className='certificate_head'>Design</h3>
            <body>
                <div class="container pm-certificate-container">
                    <div class="outer-border"></div>
                    <div class="inner-border"></div>

                    <div class="pm-certificate-border col-xs-12">
                        <div class="row pm-certificate-header">
                            <div class="pm-certificate-title  col-xs-12 text-center">
                                <h2>Mentorpal.ai Certificate of Completion</h2>
                            </div>
                        </div>

                        <div class="row pm-certificate-body">

                            <div class="pm-certificate-block">
                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-2">{"  "}</div>
                                        <div class="pm-certificate-name underline margin-0 col-xs-8 text-center">
                                            <span class="pm-name-text bold">TrueNorth Administrator</span>
                                        </div>
                                        <div class="col-xs-2">{"  "}</div>
                                    </div>
                                </div>

                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-2">{"  "}</div>
                                        <div class="pm-earned col-xs-8 text-center">
                                            <span class="pm-earned-text padding-0 block cursive">has earned</span>
                                            <span class="pm-credits-text block bold sans">PD175: 1.0 Credit Hours</span>
                                        </div>
                                        <div class="col-xs-2">{"  "}</div>
                                        <div class="col-xs-12"></div>
                                    </div>
                                </div>

                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-2">{"  "}</div>
                                        <div class="pm-course-title col-xs-8 text-center">
                                            <span class="pm-earned-text block cursive">while completing the training course entitled</span>
                                        </div>
                                        <div class="col-xs-2">{"  "}</div>
                                    </div>
                                </div>

                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-2">{"  "}</div>
                                        <div class="pm-course-title underline col-xs-8 text-center">
                                            <span class="pm-credits-text block bold sans">Full Stack WebDevlopment Course</span>
                                        </div>
                                        <div class="col-xs-2">{"  "}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12">
                                <div class="row">
                                    <div class="pm-certificate-footer d-flex ">
                                        <div class="col-xs-4 pm-certified col-xs-4 text-center">
                                            <span class="pm-credits-text block sans">Mentorpal.ai</span>
                                            <span class="pm-empty-space block underline"></span>
                                            <span class="bold block">
                                                Mentorpal
                                                #D1004,Goyal Orchid Whitefield
                                                Bengaluru,Karnataka (560066)
                                                India</span>
                                        </div>
                                        <div class="col-xs-4">
                                            {"  "}
                                        </div>
                                        <div class="col-xs-4 pm-certified col-xs-4 text-center">
                                            <span class="pm-credits-text block sans">Date Completed</span>
                                            <span class="pm-empty-space block underline"></span>
                                            <span class="bold block">DOB: </span>
                                            <span class="bold block">Social Security # (last 4 digits)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </body>
            <div className='certificate_head certificate_section_head'>
                <h3 className='certificate_head'>Serial Number</h3>
                <div class="form-check" className='certificate_group'>
                    <input type="radio" class="form-check-input" id="radio1" name="optradio" value="option1" checked />Random
                </div>
                <div class="form-check" className='certificate_group'>
                    <input type="radio" class="form-check-input" id="radio2" name="optradio" value="option2" />Increamental
                </div>
                <button type="button" className="btn btn-success certificate_savebtn">Save</button>
            </div>

            <div className='certificate_head certificate_section_head'>
                <h3 className='certificate_head certificate_section_head'>Certification Issue Type</h3>
                <div class="form-check" className='certificate_group'>
                    <input type="radio" class="form-check-input" id="radio1" name="optradio" value="option1" checked />Automatic
                </div>
                <div class="form-check" className='certificate_group'>
                    <input type="radio" class="form-check-input" id="radio2" name="optradio" value="option2" />Manual
                </div>
                <button type="button" className='btn btn-success certificate_savebtn'>Save</button>
            </div>

            <div className='certificate_head certificate_section_head'>
                <h3 className='certificate_head'>Type</h3>
                <div class="form-check" className='certificate_group'>
                    <input type="radio" class="form-check-input" id="radio1" name="optradio" value="option1" checked />Completion Certificate
                </div>
                <label for="basic-url" className="form-label certificate_group">Completion percentage</label>
                <div className="input-group mb-3 certificate_group ">
                    <input type="text" style={{ width: '90%' }} />
                </div>
                <label for="basic-url" className="form-label certificate_group ">Minimum passing percentage</label>
                <div className="input-group mb-3 certificate_group">
                    <input type="text" style={{ width: '90%' }} />
                </div>
                <div class="form-check" className='certificate_group'>
                    <input type="radio" class="form-check-input" id="radio2" name="optradio" value="option2" />Knowledge Based Certificate
                </div>
                <button type="button" className="btn btn-success certificate_savebtn">Save</button>
            </div>
        </>
    );
}

export default CertificatedDetails;
