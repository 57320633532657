
import { Input, Select, Space } from 'antd';
import React, { useState } from 'react';
import { PhoneInput } from "react-contact-number-input";
import onboardingWhatsappform from './img-onboarding-whatsapp-form.svg'

const StepM4 = () => {


  const disabled = false;
  const containerClass = ' ant-form-item-control w-100';
  const currentCountryCode = '+91'; // Replace with your logic to get the current country code
  const placeholder = 'Enter phone number';

  return (


    <div className="ant-col onboarding-content ant-col-xs-22 ant-col-md-24 ">
      <h5
        className="ant-typography onboarding-header "
        style={{ marginBottom: 32 }}
      >
        Alright! One last thing
      </h5>
      <div className="ant-row ant-row-center ">
        <div className="ant-col general-info ant-col-xs-24 ant-col-md-24 ">
          <form className="ant-form ant-form-vertical ant-form-hide-required-mark ">
            <div className="ant-form-item ">
              <div className="ant-row ant-form-item-row ">
                <div className="ant-col ant-form-item-label ">
                  <label
                    htmlFor="phone"
                    className="ant-form-item-required"
                    title="Whatsapp number"
                  >
                    Whatsapp number
                  </label>
                  <PhoneInput
                    disabled={disabled}
                    containerClass={containerClass}
                    countryCode={currentCountryCode}
                    onChange={function noRefCheck() { }}
                    placeholder={placeholder}
                  />;
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <img
        alt="Whatsapp Icon"
        loading="lazy"
        width={499}
        height={82}
        decoding="async"
        data-nimg={1}
        className="full-width"
        src={onboardingWhatsappform}
        style={{ color: "transparent", marginBottom: 30 }}
      />
    </div>



  );
};

export default StepM4;
