import React, { useEffect, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import "reactjs-popup/dist/index.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import StopIcon from "@mui/icons-material/Stop";
import { v4 } from "uuid";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage, uploadImageFile } from "../../redux/actions/common";
import { updateProfile } from "../../redux/actions/login";
import { REACT_USER_ID } from "../auth/tokenProvider";
import { CloudUpload } from "@mui/icons-material";

function RecordView() {
    const dispatch = useDispatch();

    const [userOption, setUserOption] = useState("");
    const [recordedMediaBlobUrl, setRecordedMediaBlobUrl] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [uploadedVideo, setUploadedVideo] = useState(null);

    const [loader, setLoader] = useState(false);
    const [data, setdata] = useState();
    const [error, setError] = useState();
    const [MainFileData, setMainFile] = useState();
    const [FileNameCustom, setFileNameCustom] = useState();

    const videoRecorder = useReactMediaRecorder({ video: true });
    const screenRecorder = useReactMediaRecorder({ screen: true });

    const startRecordingVideo = () => {
        setUserOption("video");
        videoRecorder.startRecording();
    };

    const startRecordingScreen = () => {
        setUserOption("screen");
        screenRecorder.startRecording();
    };

    const stopRecording = () => {
        if (userOption === "video") {
            videoRecorder.stopRecording();
        } else if (userOption === "screen") {
            screenRecorder.stopRecording();
        }
        setIsPopupOpen(true);
    };

    useEffect(() => {
        if (videoRecorder.status === "stopped") {
            setRecordedMediaBlobUrl(videoRecorder.mediaBlobUrl);
        } else if (screenRecorder.status === "stopped") {
            setRecordedMediaBlobUrl(screenRecorder.mediaBlobUrl);
        }
    }, [videoRecorder.status, screenRecorder.status]);

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const uploadImageReducerData = useSelector((state) => state.uploadImageReducer);
    const uploadImageFileReducer = useSelector((state) => state.uploadImageFileReducer);

    useEffect(() => {
        if (uploadImageReducerData) {
            setdata(uploadImageReducerData?.data);
            setError(uploadImageReducerData?.error);
            setLoader(uploadImageReducerData?.loading);
        }
        if (uploadImageFileReducer) {
            setLoader(uploadImageFileReducer?.loading);
        }
    }, [uploadImageReducerData, uploadImageFileReducer]);

    const uploadVideo = () => {
        if (recordedMediaBlobUrl) {
            const customFileName = "mentorIntroVideo/" + v4() + "_mentor_intro_video";
            setFileNameCustom(customFileName + ".mp4");
            const name = customFileName + ".mp4";
            const videoFile = new File([uploadedVideo], customFileName);
            dispatch(updateProfile({ mentor_intro_video: name, user_id: REACT_USER_ID }));
            setMainFile(videoFile);
            setLoader(true);

            fetch(recordedMediaBlobUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    setUploadedVideo(blob); // Save the Blob in your state
                    // Dispatch the upload action here using the Blob
                    dispatch(uploadImage({ documentName: customFileName, format: "mp4" }));
                })
                .catch((error) => {
                    console.error("Error downloading the video:", error);
                    setLoader(false);
                });
        } else {
            console.error("No recorded video available to upload.");
        }
    };

    useEffect(() => {
        if (uploadImageReducerData && uploadImageReducerData?.data?.url && uploadImageReducerData?.loading === false) {
            const FileData = {
                url: data?.url,
                fileData: uploadedVideo,
            };
            dispatch(uploadImageFile(FileData));
        }
    }, [uploadImageReducerData, data, uploadedVideo, dispatch, MainFileData]);

    const profileData = useSelector((state) => {
        return state.updateProfileReducer;
    });
    useEffect(() => {
        if (profileData) {
            setLoader(profileData?.loading);
        }
    }, [FileNameCustom, dispatch, profileData, uploadImageReducerData]);

    return (
        <>
            {loader ? (
                <div className="loader open_sessions_loader">
                    <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                </div>
            ) : null}
            <div className="btn-start-stop-rec-div">
                {isPopupOpen ? (
                    <div className="modal-content-recorder">
                        <button className="popup-btn">
                            <a className="download-href" href={recordedMediaBlobUrl} download="recorded_video.mp4">
                                Download Recorded Video
                            </a>
                            <DownloadIcon sx={{ ml: 1 }} />
                        </button>
                        <button className="popup-btn" onClick={uploadVideo}>
                            Upload Recorded Video
                            <CloudUpload sx={{ ml: 1 }} />
                        </button>
                        <button className="popup-btn btn-close" onClick={closePopup}>
                            <span>Close</span>
                            <CloseIcon sx={{ ml: 1 }} />
                        </button>
                    </div>
                ) : (
                    <>
                        {videoRecorder.status === "idle" || videoRecorder.status === "stopped" ? (
                            <>
                                <button className="btn-start-stop-rec" onClick={startRecordingVideo}>
                                    <span>Start Video Recording</span>
                                    <PlayArrowIcon fontSize="large" sx={{ ml: -1.2 }} />
                                </button>
                                <span className="or-span">or</span>
                            </>
                        ) : (
                            <button className="btn-start-stop-rec" onClick={stopRecording}>
                                <span>Stop Recording</span>
                                <StopIcon fontSize="large" />
                            </button>
                        )}
                        {screenRecorder.status === "idle" || screenRecorder.status === "stopped" ? (
                            <>
                                <button className="btn-start-stop-rec" onClick={startRecordingScreen}>
                                    <span>Start Screen Recording</span>
                                    <PlayArrowIcon fontSize="large" sx={{ ml: -1.2 }} />
                                </button>
                            </>
                        ) : (
                            <button className="btn-start-stop-rec" onClick={stopRecording}>
                                <span>Stop Recording</span>
                                <StopIcon fontSize="large" />
                            </button>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default RecordView;
