import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

import reducer from "./redux/reducers";
import rootSaga from "./redux/saga";
import { logged } from './components/auth/tokenProvider'
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Auth } from "./components/auth/auth";

const sagaMiddleware = createSagaMiddleware();
const Logger = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
});

const store = createStore(reducer, applyMiddleware(sagaMiddleware, Logger));

sagaMiddleware.run(rootSaga);

function App() {
 
  return (
    <div className="App">
      <Router>
      <BreadcrumbsSchema/>
        <Provider store={store}>
          <Auth LoggedIn={logged} />
        </Provider>
      </Router>
    </div>
  );
}

export default App;

const BreadcrumbsSchema = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const itemListElements = pathnames.map((pathname, index) => {
    const pathSegments = pathnames.slice(0, index + 1);
    const fullPath = pathSegments.length > 0 ? `/${pathSegments.join('/')}` : '/';
  
    return {
      "@type": "ListItem",
      "position": index + 1,
      "item": {
        "@id": `${window.location.origin}${fullPath}`,
        "name": pathname
      }
    };
  });
  
  const schema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": itemListElements
  };
  
  const JSONschema = JSON.stringify(schema);
  
  return (
    <Helmet>
      <script type="application/ld+json">{JSONschema}</script>
      <meta name="description" content="Your page description" />
    </Helmet>
  );
};

