import { createCourseConstants, deleteMentorCourseConstants, getAllCourseConstants, getCourseDetailsConstants, getMentorCourseConstants, updateMentorCourseConstants } from "../../constants";

const initialState = {
  data: [],
};
export const SyllabusIndexDataRedcuer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_BASIC_COURSE_DATA':
      return {
        ...state,
        data: [...state?.data, action.payload],
      };
    case 'APPEND_FORM_DATA': {
      const { unitIndex, chapterIndex, formData } = action.payload;
      const updatedData = state?.data.map(course => ({
        ...course,
        course_syllabus: course?.course_syllabus?.map((unit, uIndex) =>
          uIndex === unitIndex
            ? {
              ...unit,
              chapters: unit?.chapters?.map((chapter, cIndex) =>
                cIndex === chapterIndex
                  ? {
                    ...chapter,
                    type: formData?.type,
                    data: chapter?.data
                      ? [formData] 
                      : [formData], 
                  }
                  : chapter
              ),
            }
            : unit
        ),
      }));

      return {
        ...state,
        data: updatedData,
      };
    }
    case 'ADD_CHAPTER_DATA': {
      const { unitIndex, chapterIndex, formData } = action.payload;
      const updatedData = state?.data.map(course => ({
        ...course,
        course_syllabus: course?.course_syllabus?.map((unit, uIndex) =>
          uIndex === unitIndex
            ? {
              ...unit,
              chapters: unit?.chapters?.map((chapter, cIndex) =>
                cIndex === chapterIndex
                  ? {
                    ...chapter,
                    data: chapter?.data
                      ? [
                          { ...formData },
                          ...chapter.data.slice(1)
                        ]
                      : [formData],
                  }
                  : chapter
              ),
            }
            : unit
        ),
      }));

      return {
        ...state,
        data: updatedData,
      };
    }

    case 'ADD_CHAPTER_TO_UNIT_BY_INDEX': {
      const { unitIndex, formData } = action.payload;

      return {
        ...state,
        data: state.data.map((course, cIndex) => {
          const unit = course.course_syllabus[unitIndex];

          if (!unit) {
            course.course_syllabus[unitIndex] = {
              unitName: '',
              weeks: '',
              chapters: [],
            };
          }

          course.course_syllabus[unitIndex].chapters.push({
            title: formData.title || '',
            type: formData.type || '',
            data: formData.data || [],
          });


          return course;
        }),
      };
    }
case 'ADD_UNIT': {
  const { formData } = action.payload;

  return {
    ...state,
    data: state.data.map((course, cIndex) => {
      const newUnit = {
        unitName: formData.title || '',
        weeks: '',
        chapters: [
          // {
          //   ...formData,
          //   data: [], // Add an empty data array
          // },
        ],
      };

      if (!course.course_syllabus) {
        course.course_syllabus = [];
      }

      course.course_syllabus.push(newUnit);

      return course;
    }),
  };
}
case 'DELETE_CHAPTER_BY_INDEX': {
  const { unitIndex, chapterIndex } = action.payload;

  return {
    ...state,
    data: state.data.map((course, cIndex) => {
      if (course.course_syllabus && course.course_syllabus[unitIndex]) {
        const unit = { ...course.course_syllabus[unitIndex] };

        if (unit.chapters && unit.chapters[chapterIndex]) {
          unit.chapters = [
            ...unit.chapters.slice(0, chapterIndex),
            ...unit.chapters.slice(chapterIndex + 1),
          ];

          course.course_syllabus[unitIndex] = unit;
        }
      }

      return course;
    }),
  };
}

case 'DELETE_UNIT_BY_INDEX': {
  const { unitIndex } = action.payload;

  return {
    ...state,
    data: state.data.map((course, cIndex) => {
      if (course.course_syllabus && course.course_syllabus[unitIndex]) {
        course.course_syllabus = [
          ...course.course_syllabus.slice(0, unitIndex),
          ...course.course_syllabus.slice(unitIndex + 1),
        ];
      }

      return course;
    }),
  };
}


    

    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

export const createCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case createCourseConstants.CREATE_COURSE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case createCourseConstants.CREATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case createCourseConstants.CREATE_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getMentorCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case getMentorCourseConstants.GET_MENTOR_COURSE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getMentorCourseConstants.GET_MENTOR_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case getMentorCourseConstants.GET_MENTOR_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const updateMentorCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case updateMentorCourseConstants.UPDATE_MENTOR_COURSE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case updateMentorCourseConstants.UPDATE_MENTOR_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case updateMentorCourseConstants.UPDATE_MENTOR_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const deleteMentorCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case deleteMentorCourseConstants.DELETE_MENTOR_COURSE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case deleteMentorCourseConstants.DELETE_MENTOR_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case deleteMentorCourseConstants.DELETE_MENTOR_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getAllCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case getAllCourseConstants.GET_ALL_COURSE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getAllCourseConstants.GET_ALL_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case getAllCourseConstants.GET_ALL_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getCourseDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case getCourseDetailsConstants.GET_COURSE_DETAIL_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getCourseDetailsConstants.GET_COURSE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
      };
    case getCourseDetailsConstants.GET_COURSE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const CoursePrewiewReducer = (state = {}, action) => {
  switch (action.type) {
    case 'PREVIEW_COURSE_ACTION':
      return {
        ...state,
        sharedData: action.payload,
      };
    default:
      return state;
  }
};
export const navReducer = (state = {}, action) => {
  switch (action.type) {
    case 'TRIGGER_OPEN_NAV':
      return {
        ...state,
        triggerOpenNav: true,
      };
    default:
      return state;
  }
};
