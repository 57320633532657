import React from 'react'

import styles from './TrainingServices.module.css'
import Servicesimg1 from './../../../assets/images/servicesimg1.png'
import Servicesimg2 from './../../../assets/images/servicesimg2.png'
import Servicesimg3 from './../../../assets/images/servicesimg3.png'
import Servicesimg4 from './../../../assets/images/servicesimg4.png'
import Servicesimg5 from './../../../assets/images/servicesimg5.png'
import Servicesimg6 from './../../../assets/images/servicesimg6.png'
import RightArrow from './../../../assets/images/right-arrow-1.png'

const TrainingServices = () => {
    return (
        <div className='TrainingServices' style={{ backgroundColor: '#f7f9fa', paddingBottom: '60px' }}>
            <div className={`container `} >
                <h1 className={`${styles.services_heading}`} >
                    Empower Your Teams with Transformative Learning Solutionss
                </h1>
                <p className={styles.services_subheading}>
                    Our comprehensive learning services cater to the diverse needs of every team, eliminating the hassle of managing multiple providers.
                </p>

                <div className='row' style={{ marginTop: '50px' }}>
                    <div className={`col-sm-4 ${styles.servicesCardStyles} `} >
                        <span className={`${styles.services_icon}`} style={{ marginTop: '14px' }}>
                            <img src={Servicesimg1} width={'50px'} />
                        </span>
                        <h4 className={`${styles.services_card_heading}`}>Digital Marketing</h4>
                        <p className={`${styles.services_card_subheading}`}>Learn to promote products online through channels like social media, search engines, and email. Master strategies for brand visibility, customer engagement, and digital sales.</p>
                        <h5 className={`${styles.callToAction}`}>Learn more
                            <span className={`${styles.rightArrowMargin}`}>
                                <img src={RightArrow} />
                            </span>
                        </h5>
                        <hr className={`d-block d-sm-none ${styles.hrstyles}`} />
                    </div>
                    <div className={`col-sm-4 ${styles.servicesCardStyles} `}>
                        <span className={`${styles.services_icon}`}>
                            <img src={Servicesimg2} />
                        </span>
                        <h4 className={`${styles.services_card_heading}`}>Graphic Design</h4>
                        <p className={`${styles.services_card_subheading}`}>Develop artistic and technical skills to create appealing designs for print and digital media. Use industry-standard software and understand design principles</p>
                        <h5 className={`${styles.callToAction}`}>Learn more
                            <span className={`${styles.rightArrowMargin}`}>
                                <img src={RightArrow} />
                            </span>
                        </h5>
                        <hr className={`d-block d-sm-none ${styles.hrstyles}`} />
                    </div>
                    <div className={`col-sm-4 ${styles.servicesCardStyles} `}>
                        <span className={`${styles.services_icon}`}>
                            <img src={Servicesimg3} />
                        </span>
                        <h4 className={`${styles.services_card_heading}`}>Web Development</h4>
                        <p className={`${styles.services_card_subheading}`}>Dive into coding to build engaging interactive websites using HTML, CSS, and JavaScript. Design user-friendly web interfaces, captivating websites, and applications.</p>
                        <h5 className={`${styles.callToAction}`}>Learn more
                            <span className={`${styles.rightArrowMargin}`}>
                                <img src={RightArrow} />
                            </span>
                        </h5>
                        <hr className={`d-block d-sm-none ${styles.hrstyles}`} />
                    </div>
                </div>
                <div className='row' style={{ marginTop: '40px' }}>
                    <div className={`col-sm-4 ${styles.servicesCardStyles} `}>
                        <span className={`${styles.services_icon}`}>
                            <img src={Servicesimg4} width={'50px'} />
                        </span>
                        <h4 className={`${styles.services_card_heading}`}>Data Analysis</h4>
                        <p className={`${styles.services_card_subheading}`}>Harness data for informed decisions. Analyze and interpret data using tools like Excel, Python, or R to uncover insights and trends.</p>
                        <h5 className={`${styles.callToAction}`}>Learn more
                            <span className={`${styles.rightArrowMargin}`}>
                                <img src={RightArrow} />
                            </span>
                        </h5>
                        <hr className={`d-block d-sm-none ${styles.hrstyles}`} />
                    </div>
                    <div className={`col-sm-4 ${styles.servicesCardStyles} `}>
                        <span className={`${styles.services_icon}`}>
                            <img src={Servicesimg5} />
                        </span>
                        <h4 className={`${styles.services_card_heading}`}>Communication Skills</h4>
                        <p className={`${styles.services_card_subheading}`}>Enhance verbal and written communication. Master public speaking, active listening, negotiation, and writing compelling content.</p>
                        <h5 className={`${styles.callToAction}`}>Learn more
                            <span className={`${styles.rightArrowMargin}`}>
                                <img src={RightArrow} />
                            </span>
                        </h5>
                        <hr className={`d-block d-sm-none ${styles.hrstyles}`} />
                    </div>
                    <div className={`col-sm-4 ${styles.servicesCardStyles} `}>
                        <span className={`${styles.services_icon}`}>
                            <img src={Servicesimg6} width={'50px'} />
                        </span>
                        <h4 className={`${styles.services_card_heading}`}>Photography and Videography</h4>
                        <p className={`${styles.services_card_subheading}`}>Capture moments and stories through images and videos. Learn composition, lighting, editing, and post-production techniques.
                        </p>
                        <h5 className={`${styles.callToAction}`}>Learn more
                            <span className={`${styles.rightArrowMargin}`}>
                                <img src={RightArrow} />
                            </span>
                        </h5>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TrainingServices