import { Button, Col, Row, Tooltip, Typography } from "antd";
import { useState } from "react";
import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";
import { InfoCircleOutlined, BranchesOutlined, MoneyCollectOutlined, DotChartOutlined } from "@ant-design/icons";
import {
    TbCurrencyRupee,
} from "react-icons/tb";
import PaymentsOnboarding from "./Modal/PaymentsOnboarding";
const { Paragraph, Text } = Typography;

const BalanceTabs = () => {
    const { user: profile } =''
    const [onboardingModalOpen, setOnboardingModalOpen] =
        useState (false);
    const [showWithdrawalStatus] = useState(true);
    const [onboardingStep, setOnboardingStep] = useState(0);

    const { bank_details } =''
    const { balanceData } = ''
    const { data: canWithdrawMoneyData } = ''
    const { data: bankAccountStatus } =''

    const connectPayment = (page = 0)=> {
        if (page) {
            setOnboardingStep(page);
        }
        setOnboardingModalOpen(true);
    };


    const isWithdrawButtonVisible =
        balanceData?.balance !== 0 &&
        showWithdrawalStatus &&
        (canWithdrawMoneyData?.can_withdraw ||
            canWithdrawMoneyData?.reason === "no_bank_account");

    const onWithdrawClick = () => {
      
        if (bankAccountStatus === "not_added") {
            connectPayment(0);
        } else {
            
        }
    };

    const getCommissionTooltip = () => {
        if (
            profile?.has_transaction_charge_enabled &&
            profile?.commission_percentage > 0
        ) {
            return `Earnings after deducting ${profile?.commission_percentage}% Topmate fees and applicable transaction fees`;
        } else if (profile?.commission_percentage > 0) {
            return `Earnings after deducting ${profile?.commission_percentage}% Topmate fees`;
        } else {
            return `Earnings after deducting applicable transaction fees`;
        }
    };

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString("en-US", { month: "short" });
    };

    return (
        <Col span={24}>
            {/* Payments Nav */}
            <Row className="payments-nav" gutter={{ md: 24, xl: 24 }}>
             

                {/* Balance Card */}
                {bank_details?.payment_gateway !== "stripe" && (
                    <Col xs={24} md={7} xl={7}>
                        <Paragraph className="balance-cards first d-flex">
                          
                            <BranchesOutlined className="icon" width={30} />
                            <Paragraph style={{ flexGrow: 1 }}>
                                <Text className="balance-text">
                                    Balance
                                    {(profile?.has_transaction_charge_enabled ||
                                        profile?.commission_percentage > 0) && (
                                            <Tooltip title={getCommissionTooltip()}>
                                                <InfoCircleOutlined className="info-icon" />
                                            </Tooltip>
                                        )}
                                </Text>
                                <Row
                                    justify="space-between"
                                    align="middle"
                                    style={{ flexShrink: 0 }}
                                >
                                    <Text className="balance-value">
                                        {balanceData?.balance ? (
                                            <Paragraph className="balance-amount">
                                                <TbCurrencyRupee currency={profile?.currency} />{" "}
                                                {balanceData?.balance}
                                            </Paragraph>
                                        ) : (
                                            0
                                        )}
                                    </Text>
                                    {bank_details?.payment_gateway !== "stripe" &&
                                        isWithdrawButtonVisible ? (
                                        <Button
                                            type="text"
                                            className="withdraw-btn"
                                            onClick={onWithdrawClick}
                                        >
                                            Withdraw
                                        </Button>
                                    ) : null}
                                    {bank_details?.payment_gateway !== "stripe" &&
                                        canWithdrawMoneyData?.reason === "request_pending" && (
                                            <Text className="in-withdrawal">In withdrawal</Text>
                                        )}
                                </Row>
                            </Paragraph>
                        </Paragraph>
                    </Col>
                )}

                {/* Pending Card */}
                {/* {bank_details?.payment_gateway !== "stripe" && ( */}
                <Col xs={24} md={7} xl={7}>
                    <Paragraph className="balance-cards second">
                       
                        <DotChartOutlined className="icon"
                            width={30}
                            height={40} />
                        <Paragraph>
                            <Text className="balance-text">
                                Pending Completion
                                <Tooltip
                                    title={
                                        bank_details?.payment_gateway === "stripe"
                                            ? "Amount from any upcoming bookings will be credited once the booking gets completed"
                                            : "Amount from your upcoming calls will move to balance once the call gets completed"
                                    }
                                >
                                    <InfoCircleOutlined className="info-icon" />
                                </Tooltip>
                            </Text>
                            <Paragraph className="balance-value">
                                {balanceData?.pending_earnings ? (
                                    <Paragraph className="balance-amount">
                                        <TbCurrencyRupee currency={profile?.currency} />{" "}
                                        {balanceData?.pending_earnings}
                                    </Paragraph>
                                ) : (
                                    0
                                )}{" "}
                            </Paragraph>
                        </Paragraph>
                    </Paragraph>
                </Col>

                {/* Lifetime Earnings */}
                <Col xs={24} md={10} xl={10}>
                    <Row className="last-balance-card">
                        <Col className="earning-details" md={10} xs={10}>
                         
                            <MoneyCollectOutlined className="icon"
                                width={30}
                                height={40} />
                            <Paragraph>
                                <Text className="balance-text">
                                    Lifetime earnings
                                    <Tooltip
                                        title={
                                            bank_details?.payment_gateway === "stripe"
                                                ? getCommissionTooltip()
                                                : "All-time earnings of all your completed calls and queries"
                                        }
                                    >
                                        <InfoCircleOutlined className="info-icon" />
                                    </Tooltip>
                                </Text>
                                <Paragraph className="balance-value">
                                    {balanceData?.total_earnings ? (
                                        <Paragraph className="balance-amount">
                                            <TbCurrencyRupee currency={profile?.currency} />{" "}
                                            {balanceData?.total_earnings}
                                        </Paragraph>
                                    ) : (
                                        0
                                    )}
                                </Paragraph>
                            </Paragraph>
                        </Col>
                        {balanceData?.total_earnings_last_3_months[1]?.earnings > 0 &&
                            balanceData?.total_earnings_last_3_months[0]?.earnings > 0 && (
                                <Col className="monthly-details" md={14} xs={14}>
                                    <Paragraph className="show-diff">
                                        <Paragraph className="balance-amount">
                                        <TbCurrencyRupee currency={profile?.currency} />{" "}
                                            {Math.abs(
                                                balanceData?.total_earnings_last_3_months[0]?.earnings -
                                                balanceData?.total_earnings_last_3_months[1]?.earnings
                                            )}
                                        </Paragraph>

                                        {balanceData?.total_earnings_last_3_months[0]?.earnings <
                                            balanceData?.total_earnings_last_3_months[1]?.earnings ? (
                                            <BiTrendingDown color="#E52646" size={16} />
                                        ) : (
                                            <BiTrendingUp color="#008060" size={16} />
                                        )}
                                    </Paragraph>
                                    <Row
                                        justify="space-around"
                                        style={{ height: "100%" }}
                                        align="bottom"
                                    >
                                        <Col className="month-column">
                                            <Paragraph>
                                                {getMonthName(
                                                    balanceData?.total_earnings_last_3_months[1]?.month
                                                )}
                                            </Paragraph>
                                            <Paragraph
                                                className="month-bar"
                                                style={{
                                                    height: `${Math.round(
                                                        (balanceData?.total_earnings_last_3_months[1]
                                                            ?.earnings *
                                                            100) /
                                                        (balanceData?.total_earnings_last_3_months[1]
                                                            ?.earnings +
                                                            balanceData?.total_earnings_last_3_months[0]
                                                                ?.earnings)
                                                    ).toFixed(0)}%`,
                                                }}
                                            ></Paragraph>
                                        </Col>
                                        <Col className="month-column">
                                            <Paragraph>
                                                {getMonthName(
                                                    balanceData?.total_earnings_last_3_months[0]?.month
                                                )}
                                            </Paragraph>
                                            <Paragraph
                                                className="month-bar"
                                                style={{
                                                    height: `${Math.round(
                                                        (balanceData?.total_earnings_last_3_months[0]
                                                            ?.earnings *
                                                            100) /
                                                        (balanceData?.total_earnings_last_3_months[1]
                                                            ?.earnings +
                                                            balanceData?.total_earnings_last_3_months[0]
                                                                ?.earnings)
                                                    ).toFixed(0)}%`,
                                                }}
                                            ></Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                    </Row>
                </Col>

               
                {/* Withdraw Success Modal */}
                {onboardingModalOpen && (
                    <PaymentsOnboarding
                        open={onboardingModalOpen}
                        page={onboardingStep}
                        onClose={() => setOnboardingModalOpen(false)}
                    />
                )}

            </Row>
        </Col>
    );
};

export default BalanceTabs;
