import React from 'react'
import { Link } from 'react-router-dom'

import styles from './TrainingHeroSection.module.css'

const TrainingHeroSection = ({ heading, SubHeading, btn, BgImg, text }) => {
    return (
        <>
            <div
                className={`TrainingHeroSection ${styles.hero_section_wrapper}`}
                style={{ backgroundImage: `url(${BgImg})` }}
            >
                <div>
                    <div><p style={{ color: 'white' }}>{text}</p></div>
                    <h1 className={`${styles.hero_heading}`}>
                        {heading}
                    </h1>
                    <br />
                    <h3 style={{ color: "white" }}>
                        {SubHeading}
                    </h3>
                    <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <button className={`${styles.training_get_started_btn}`}>
                            <Link to='/request-demo' style={{ color: 'white' }}>
                                {btn}
                            </Link>
                        </button>
                    </div>
                </div>
            </div >
        </>
    )
}

export default TrainingHeroSection