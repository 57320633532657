import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const BookingComponenets = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const tabs = [
        { label: '1:1 calls', href: '/dashboard/calls/video' },
        // { label: 'Package', href: '/dashboard/calls/package/upcoming' },
        { label: 'Webinar', href: '/dashboard/calls/webinar/upcoming' },
        // { label: 'Digital Product', href: '/dashboard/calls/product' },
    ];

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    useEffect(() => {
        // Find the index of the tab based on the current URL
        const currentTabIndex = tabs.findIndex(tab => location.pathname.includes(tab.href));

        // Set the selectedTabIndex state
        setSelectedTabIndex(currentTabIndex === -1 ? 0 : currentTabIndex);
    }, [location.pathname, tabs]);

    const handleTabClick = (index, tab) => {
        setSelectedTabIndex(index);
        navigate(tab?.href);
    };
    return (
        <>
            <div className="ant-col internal-page-layout ant-col-xs-24 ant-col-xs-offset-0 ant-col-md-24 ant-col-md-offset-0 ">
                <div className="ant-row container-title-section ">
                    <div className="ant-col header-section ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-14 ant-col-md-offset-1 ant-col-xl-12 ant-col-xl-offset-1 ant-col-xxl-12 ant-col-xxl-offset-1 ">
                        <h4 className="ant-typography layout-header ">
                            <div className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line ">
                                Bookings
                            </div>
                        </h4>
                    </div>
                    <div className="ant-col tab-container ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xl-22 ant-col-xl-offset-1 ant-col-xxl-22 ant-col-xxl-offset-1 ">
                        <span className="ant-typography flex-center " style={{ overflowY: "auto" }}>
                            {tabs.map((tab, index) => (
                                <a
                                    key={index}
                                    className={`tab-link tab-link-common ${index === selectedTabIndex ? 'active' : ''}`}
                                 
                                    onClick={() => handleTabClick(index,tab)}
                                >
                                    <span className="ant-typography tab-title ">
                                        <div className="ant-typography " style={{ display: "flex", alignItems: "center" }}>
                                            <span className="ant-typography ">{tab.label}</span>
                                        </div>
                                    </span>
                                </a>
                            ))}
                        </span>
                    </div>
                </div>
                <div className="ant-row container-children ">
                    <Outlet />
                </div>
            </div>

        </>
    );
}

export default BookingComponenets;
