import React, { useEffect, useState } from 'react';
import { Button, Col, Drawer, Flex, Row, Typography } from "antd";

import packageempty from '../../Image/packageempty.svg';
import PackageDetailDrawer from './Modal/PackageDetailDrawer';
import { isMobile, packageBookingUpcoming } from '../../Data/Data';
import EmailDrawer from './Modal/EmailDrawer';
import { BsArchive, BsSend } from "react-icons/bs";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { LuArchive } from "react-icons/lu"
import { useParams } from 'react-router-dom';


const PackagesCompleted = () => {
    const { Paragraph, Text } = Typography;

    const [status, setStatus] = useState('completed');
    const { statusType } = useParams();



    const [emailModal, setEmailModal] = useState(false)
    const [packageDetailModal, setPackageDetailModal] = useState(false)
    const [ModalData, setModalData] = useState()

    const openDetails = (source) => {
        setPackageDetailModal(true);
    };
    const getStatusText = (status) => {
        if (
            status === "completed" ||
            status === "ongoing" ||
            status === "upcoming"
        ) {
            return (
                <Paragraph
                    style={{
                        backgroundColor: "#ecf7f1",
                        borderRadius: "28px",
                        textAlign: "center",
                        display: "inline-flex",
                    }}
                >
                    <Paragraph
                        style={{
                            color: "#0A442C",
                        }}
                        className="status-tag-text"
                    >
                        {status}
                    </Paragraph>
                </Paragraph>
            );
        }
        if (status === "cancelled") {
            return (
                <Paragraph
                    style={{
                        backgroundColor: "#fdf3f2",
                        borderRadius: "28px",
                        textAlign: "center",
                        display: "inline-flex",
                    }}
                >
                    <Paragraph
                        style={{
                            color: "#61140C",
                        }}
                        className="status-tag-text"
                    >
                        Cancelled
                    </Paragraph>
                </Paragraph>
            );
        }
        return "";
    };
    return (
        <>
            <Col xs={24} className="calls-card-container">
             

                <Paragraph className="calls-card">
                    <Row
                        className="calls-header cursor-hover"
                        justify="space-between"
                        onClick={() => openDetails("card")}
                    >
                        <Col span={24}>
                            <Flex align="center" justify="space-between">
                                <Paragraph className="calls-time">
                                    <BsArchive style={{ marginRight: "8px" }} />
                                    {packageBookingUpcoming?.service_count || 0} services
                                </Paragraph>
                                <Flex
                                    style={{
                                        borderRadius: 28,
                                        background: "#F2F7FC",
                                        padding: "5px 10px",
                                        fontSize: 12,
                                        lineHeight: "175%",
                                    }}
                                    gap={6}
                                    align="center"
                                >
                                    {packageBookingUpcoming?.service?.is_subscription_enabled === true ? (
                                        <HiOutlineBellAlert fontSize={16} />
                                    ) : (
                                        <LuArchive fontSize={16} />
                                    )}
                                    <strong>
                                        {packageBookingUpcoming?.service?.is_subscription_enabled === true
                                            ? "Subcription"
                                            : "Package"}
                                    </strong>
                                </Flex>
                            </Flex>
                        </Col>
                    </Row>
                    <Paragraph
                        className="calls-details cursor-hover"
                        onClick={() => openDetails("card")}
                    >
                        <Text className="calls-name" strong>
                            {packageBookingUpcoming?.consumer_name}
                        </Text>
                        <Paragraph className="calls-service-desc">
                            {packageBookingUpcoming?.service?.title}
                        </Paragraph>
                    </Paragraph>
                    <Row
                        justify="space-between"
                        className="package-calls-footer"
                        align="middle"
                        gutter={12}
                    >
                        <Col>
                            {status === "completed" && (
                                <Paragraph>
                                    {getStatusText(packageBookingUpcoming?.status, packageBookingUpcoming?.consumer_name)}
                                </Paragraph>
                            )}
                        </Col>
                        <Col>
                            <Row gutter={10} align="middle">
                                <Col>
                                    <Button
                                        className="btn-packageBookingUpcoming-action"
                                        onClick={() => {
                                            setEmailModal(true);
                                        }}
                                    >
                                        {isMobile ? <BsSend /> : "Send Email"}
                                    </Button>
                                </Col>

                                <Col xs={15} md={12}>
                                    <Button
                                        className="btn-dark"
                                        onClick={() => openDetails("View details")}
                                    >
                                        View details
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Paragraph>
            </Col>
            {
                emailModal && (
                    <EmailDrawer emailModal={emailModal} setEmailModal={setEmailModal} ModalData={ModalData} />
                )
            }
            {
                packageDetailModal && (
                    <PackageDetailDrawer packageDetailModal={packageDetailModal} setPackageDetailModal={setPackageDetailModal} ModalData={ModalData} />
                )
            }
        </>
    );
}

export default PackagesCompleted;
