import { EditorState, convertToRaw } from 'draft-js';
import React, { useState } from 'react';
import TextEditor from '../Modal/TextEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import SelectMultiInputs from '../../applyAsMentor/selectMultiInputs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { appendFormData } from '../../../redux/actions/course';
import { useDebugValue } from 'react';

const AddYouTubeVideoChapter = ({ unitIndex, chapterIndex }) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        title: '',
        enableSharing: false,
        description: "",
        Tags: [],
        availabilityType: 'AlwaysAvailable',
        availableFrom: '',
        availableTill: '',
        ConfirmationMessage: " ",
        duration: " ",
        link: '',
    });
    const syllabusIndexData = useSelector(
        (state) => state.SyllabusIndexDataRedcuer?.data[0]
    )
    useEffect(() => {
        if (syllabusIndexData) {
            const selectedUnit = syllabusIndexData.course_syllabus?.[unitIndex];
            const selectedChapter = selectedUnit?.chapters?.[chapterIndex];
            const data = selectedChapter?.data[0]

            if (selectedChapter) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    title: data?.title || '',
                    link: data?.link || '',
                    enableSharing: data?.enableSharing || '',
                    duration: data?.duration || '',
                    availabilityType: data?.availabilityType || '',
                    availableFrom: data?.availableFrom || '',
                    availableTill: data?.availableTill || '',
                    description: data?.description || '',
                    ConfirmationMessage: data?.ConfirmationMessage || '',
                }));
            }
        }
    }, [syllabusIndexData, chapterIndex, unitIndex]);

    const [validationMessages, setValidationMessages] = useState({
        title: '',
        Tags: '',
        link: '',
        duration: '',
        availableFrom: '',
        availableTill: '',
        description: '',
        ConfirmationMessage: '',
        autoplay: '', // Added 'autoplay' field
    });

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState, e) => {
        setEditorState(editorState);
        const { blocks } = convertToRaw(editorState.getCurrentContent());
        setFormData(prevFormData => ({
            ...prevFormData,
            description: blocks[0]?.text,
        }));
    };
    const handleTagsChange = (item, addFunc) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            Tags: [...prevFormData.Tags, item]
        }));
    };

    const handleRemoveTag = (item) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            Tags: prevFormData.Tags.filter(tag => tag.value !== item.value)
        }));
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        const newValidationMessages = {}; // Store validation messages for all fields

        // Validation logic for each field
        if (!formData.title) {
            newValidationMessages.title = 'Title is required';
        }
        if (formData.Tags.length < 5) {
            newValidationMessages.Tags = 'At least 5 Tags are required';
        }
        if (!formData.link) {
            newValidationMessages.link = 'YouTube Link is required';
        }
        if (!formData.duration) {
            newValidationMessages.duration = 'Duration is required';
        }
        if (formData.availabilityType === 'TimeBased') {
            if (!formData.availableFrom) {
                newValidationMessages.availableFrom = 'Available From is required';
            }
            if (!formData.availableTill) {
                newValidationMessages.availableTill = 'Available Till is required';
            }
        }
        if (!formData.description) {
            newValidationMessages.description = 'Description is required';
        }
        if (!formData.ConfirmationMessage) {
            newValidationMessages.ConfirmationMessage = 'Confirmation Message is required';
        }
        if (!formData.autoplay) {
            newValidationMessages.autoplay = 'AutoPlay selection is required';
        }

        // Set validation messages
        setValidationMessages(newValidationMessages);

        // Check if any validation message exists
        const isValid = Object.values(newValidationMessages).every((message) => !message);

        if (isValid) {
            // If all fields are valid, proceed with form submission
            console.log('Form Data:', formData);
            dispatch(appendFormData("ADD_CHAPTER_DATA", chapterIndex, unitIndex, formData))

        }
    };
    return (
        <>
            <div className='bg-white p-5'>
                <form onSubmit={handleFormSubmit}>
                    {/* Title */}
                    <div className="form-group">
                        <label className="course__label">Title *</label>
                        <input
                            type="text"
                            name="title"
                            placeholder="Enter course Title"
                            className="form-control"
                            value={formData.title}
                            onChange={handleInputChange}
                            required
                        />
                        {validationMessages.title && (
                            <p className="text-danger">{validationMessages.title}</p>
                        )}
                    </div>

                    {/* Tags */}
                    <div className="form-group">
                        <label className="course__label">Tags *</label>
                        <div className="multi-inputs-container">
                            <div className="multi-inputs">
                                {formData.Tags.map((Tag) => (
                                    <div className="multi-input-design" key={Tag.value}>
                                        <div>{Tag.label}</div>
                                        <div onClick={() => handleRemoveTag(Tag)}>
                                            <FontAwesomeIcon
                                                className="multi-select-icon"
                                                icon={faXmark}
                                                style={{ color: 'grey' }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <SelectMultiInputs
                            isMulti={true}
                            title="language"
                            onChangeFunc={(e) => handleTagsChange(e, setFormData)}
                            value=""
                            name="Tags"
                            isCreatable={true}
                            placeholder="Add at least 5-6 Tags"
                            className="basic-multi-select text-left"
                            classNamePrefix="select"
                            inputData={formData.Tags}
                            closeMenuOnSelect={true}
                            style={{ borderRadius: '12px', height: '3rem' }}
                        />
                        {validationMessages.Tags && (
                            <p className="text-danger">{validationMessages.Tags}</p>
                        )}
                    </div>

                    {/* YouTube Link */}
                    <div className="form-group">
                        <label className="course__label">YouTube Link *</label>
                        <input
                            type="text"
                            name="link"
                            placeholder="Enter YouTube Link"
                            className="form-control"
                            value={formData.link}
                            onChange={handleInputChange}
                            required
                        />
                        {validationMessages.link && (
                            <p className="text-danger">{validationMessages.link}</p>
                        )}
                    </div>

                    {/* Duration */}
                    <div className="form-group">
                        <label className="course__label">Duration *</label>
                        <input
                            type="number"
                            name="duration"
                            placeholder="Enter Duration"
                            className="form-control"
                            value={formData.duration}
                            onChange={handleInputChange}
                            required
                        />
                        {validationMessages.duration && (
                            <p className="text-danger">{validationMessages.duration}</p>
                        )}
                    </div>

                    {/* Availability Type */}
                    <div className='d-flex gap-3'>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="AlwaysAvailable"
                                id="fullPaymentRadio"
                                name="availabilityType"
                                checked={formData.availabilityType === 'AlwaysAvailable'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="fullPaymentRadio">
                                Always Available
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="TimeBased"
                                id="partialPaymentRadio"
                                name="availabilityType"
                                checked={formData.availabilityType === 'TimeBased'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="partialPaymentRadio">
                                Time Based
                            </label>
                        </div>
                    </div>

                    {/* Available From (if TimeBased) */}
                    {formData.availabilityType === 'TimeBased' && (
                        <div className="form-group">
                            <label htmlFor="" className='course__label'>Available From: *</label>
                            <input
                                type="datetime-local"
                                name="availableFrom"
                                value={formData.availableFrom}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                            {validationMessages.availableFrom && (
                                <p className="text-danger">{validationMessages.availableFrom}</p>
                            )}
                        </div>
                    )}

                    {/* Available Till (if TimeBased) */}
                    {formData.availabilityType === 'TimeBased' && (
                        <div className="form-group">
                            <label htmlFor="" className='course__label'>Available Till: *</label>
                            <input
                                type="datetime-local"
                                name="availableTill"
                                value={formData.availableTill}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                            {validationMessages.availableTill && (
                                <p className="text-danger">{validationMessages.availableTill}</p>
                            )}
                        </div>
                    )}

                    {/* Description */}
                    <div className="form-group">
                        <TextEditor
                            label="Description: *"
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                        />
                        {validationMessages.description && (
                            <p className="text-danger">{validationMessages.description}</p>
                        )}
                    </div>

                    {/* Confirmation Message */}
                    <div className="form-group">
                        <label className="course__label">Confirmation Message *</label>
                        <input
                            type="text"
                            name="ConfirmationMessage"
                            placeholder="Enter Confirmation Message"
                            className="form-control"
                            value={formData.ConfirmationMessage}
                            onChange={handleInputChange}
                            required
                        />
                        {validationMessages.ConfirmationMessage && (
                            <p className="text-danger">{validationMessages.ConfirmationMessage}</p>
                        )}
                    </div>

                    {/* AutoPlay */}
                    <div className="d-flex gap-3">
                        <label className="course__label">AutoPlay *</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="yes"
                                id="autoplayYes"
                                name="autoplay"
                                checked={formData.autoplay === 'yes'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="autoplayYes">
                                Yes
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="no"
                                id="autoplayNo"
                                name="autoplay"
                                checked={formData.autoplay === 'no'}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="autoplayNo">
                                No
                            </label>
                        </div>
                        {validationMessages.autoplay && (
                            <p className="text-danger">{validationMessages.autoplay}</p>
                        )}
                    </div>

                    {/* Thumbnail */}
                    <div className="d-flex">
                        <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={500}
                                height={300}
                                fill="currentColor"
                                className="bi bi-film"
                                viewBox="0 0 16 16"
                            >
                                <path d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm4 0v6h8V1H4zm8 8H4v6h8V9zM1 1v2h2V1H1zm2 3H1v2h2V4zM1 7v2h2V7H1zm2 3H1v2h2v-2zm-2 3v2h2v-2H1zM15 1h-2v2h2V1zm-2 3v2h2V4h-2zm2 3h-2v2h2V7zm-2 3v2h2v-2h-2zm2 3h-2v2h2v-2z" />
                            </svg>
                        </span>
                        <div className="form-group">
                            <label className="course__label">
                                Thumbnail Recommended Size (600px * 400px)
                            </label>
                            <input
                                type="file"
                                name="Thumbnail"
                                placeholder="Thumbnail"
                                className="form-control"
                                required
                            />
                        </div>
                    </div>
                    {
                        formData?.link ? (
                            <>
                                <video
                                    style={{ width: "100%", height: "100%" }}

                                    src={formData?.link}
                                    controls
                                />

                            </>
                        ) : (
                            <>
                                <p className="text-center">No video is Available to preview</p>
                            </>
                        )
                    }

                    {/* Validation message for the entire form */}
                    {Object.values(validationMessages).some((message) => message) && (
                        <p className="text-danger">Please fix the errors before submitting.</p>
                    )}

                    <div className="get-started-cls why_choose_ment">
                        <button type="submit" className="find-btn btn btn-primary">
                            Save
                        </button>
                    </div>
                </form>
            </div>

        </>
    );
}

export default AddYouTubeVideoChapter;
