import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { crossIcon } from "../../../assets/images/export-images";
import "../cutsom.css";
import { OpenToData, hireTalentFilter } from "../filter/constant";
import { countries, expertise } from "../../../assets/data/data";

const Preferences = ({ preferencesModal, setpreferencesModal }) => {
  return (
    <>
      <Modal
        size="xl"
        centered
        show={preferencesModal.show}
        onHide={() =>
          setpreferencesModal((prevState) => ({ ...prevState, show: false }))
        }
      >
        <ModalBody>
          <div
            type="button"
            className="cloose"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              setpreferencesModal((prevState) => ({
                ...prevState,
                show: false,
              }))
            }
          >
            <div className="closebuttons">
              <span aria-hidden="true">
                <img src={crossIcon} />
              </span>
            </div>
          </div>
          {/* <form> */}

          <div className="filter______row">
            <header className=" customize__prefrence text-center">
              <p className="  hire-subpara edit__pre  pb-1">
                Customize Your Preferences
              </p>
              <span className="hir__span">
                Shape Your Destiny: Exciting Career Opportunities in the
                Community.
              </span>
            </header>
            <div className="signup-row ">
              <div className="filter__col col">
                <div className="Filters__Wrapper ">
                  <div className="filters__Item   Filters__DropDown">
                    <div className="mb-3 form-check">
                      <input type="checkbox" className="custom-control-input" />
                      <label
                        className="form-check-label hire-subtext font-weight-700"
                        htmlFor="work_remotely"
                      >
                        I am open to requests
                      </label>
                    </div>
                    <p className="  hire-subtext font-weight-400 mb-8 grey-2-text">
                      Location
                    </p>

                    <div className="form-group">
                      <select
                        className="text-left form-control"
                        style={{
                          height: "50px",
                          width: "100%",
                          borderRadius: "13px",
                        }}
                        required
                      >
                        <option value=""> Eg: Product Design </option>
                        {countries?.map((option) => (
                          <option value={option.value} key={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="  hire-subtext font-weight-400 mb-8 grey-2-text">
                      Years of Experience
                    </p>
                    <div className="d-sm-flex">
                      <div className="form-group">
                        <select
                          className="text-left form-control"
                          style={{
                            height: "50px",
                            width: "100%",
                            borderRadius: "13px",
                          }}
                          required
                        >
                          <option value=""> Year </option>
                          {[...Array(30).keys()].map((number) => (
                            <option value={number + 1} key={number + 1}>
                              {number + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <select
                          className="text-left form-control"
                          style={{
                            height: "50px",
                            width: "100%",
                            borderRadius: "13px",
                          }}
                          required
                        >
                          <option value=""> Month</option>
                          {[...Array(12).keys()].map((number) => (
                            <option value={number + 1} key={number + 1}>
                              {number + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <p className="  hire-subtext font-weight-400 mb-8 grey-2-text">
                      My Top Skills
                    </p>

                    <div className="form-group">
                      <select
                        className="text-left form-control"
                        style={{
                          height: "50px",
                          width: "100%",
                          borderRadius: "13px",
                        }}
                        required
                      >
                        <option value=""> Eg: Product Design </option>
                        {expertise?.map((option) => (
                          <option value={option.value} key={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="filter__col col">
                <div className="Filters__OpenToFilters border-bottom pb-24">
                  <p
                    id="open-to-filters"
                    className="  hire-subtext font-weight-400 mb-16 grey-2-text"
                  >
                    I am Open to
                  </p>
                  {OpenToData.map((item, index) => (
                    <div
                      className="d-flex justify-content-between align-items-center "
                      style={{ lineHeight: "3rem" }}
                      key={index}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          color="#25D209"
                          radius={4}
                          width="32px"
                          height="32px"
                          className=" open__icon"
                        >
                          {item.icon}
                        </div>
                        <div className="ml-3">
                          <p className="hire-subtext font-weight-700">
                            {item.title}
                          </p>
                        </div>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id={`open-to-toggle-${index}`}
                          className="custom-control-input"
                        />
                        <label
                          title=""
                          htmlFor={`open-to-toggle-${index}`}
                          className="custom-control-label"
                        />
                      </div>
                    </div>
                  ))}
                  <button
                    height={50}
                    type="button"
                    className=" request_to_chat text-truncate w-100 btn--default btn btn-default"
                  >
                    Create Preferences
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* </form> */}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Preferences;
