import {
    Alert,
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Skeleton,
    Space,
    Typography,
} from "antd";
import { useState } from "react";
import { BsBank2 } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import { AllCountriesList, currencyName, CurrencyOptions, StripeSupportedCountries } from "../../Data/Data";
import BankDetailsForm from "../BankDetailsForm";
import { useDispatch } from "react-redux";
import { addBankAccountAction } from './../../../../redux/actions/common';
import { REACT_USER_ID } from './../../../auth/tokenProvider';
import paypal from '../../Image/paypal_196566.png'
import stripe from '../../Image/Stripe-Credit-Card-Payment-WordPress-Download-Manager.png'




const { Paragraph, Text } = Typography;

const PaymentsOnboarding = (props) => {
    const dispatch = useDispatch()
    const { open, onClose, } = props;
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);

    const [bank_details, setBank_details] = useState({
        country:"",
        allow_stripe_connect:"",
        currency:"",
        external_account_id:"",
        status:false
    })

    const onModalClose = () => {
        setPage(0);
        onClose();
    };

    const EmailRegexPattern =
        /^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9][-a-zA-Z0-9]*(\.[-a-zA-Z0-9]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|in|global|fit|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?/;

    const [paypalForm] = Form.useForm();


    const isSupportedByStripe = (country_code) => {
        return !!StripeSupportedCountries.find((x) => x.code === country_code);
    };

    const submitPaypal = async (fields) => {
        setLoader(true);
        const { email } = fields;
        const payload = {
            external_account_id: email,
            payment_gateway: "paypal",
        };
        console.log("payload", payload)
        dispatch(addBankAccountAction({ ...payload, mentor_id: REACT_USER_ID }))
        try {
            if (bank_details?.status === "not_added") {
                setLoader(false);
                setPage(0);
                onClose();
            } else {
                setLoader(false);
                message.success("Your Paypal account has been updated", 4);
                setPage(0);
                onClose();
            }
        } catch (error) {
            setLoader(false);
            message.error(
                error?.response?.data?.external_account_id || "Something went wrong",
                5
            );
        }
    };

    const submitPayoutPreferenceForm = async (fields) => {
        setLoader(true);
        const { country: country_code, currency: currency_code } = fields;
        const payload = {
            country: country_code,
            currency: currency_code,
        };
        console.log("PayoutPreference", payload);

        dispatch(addBankAccountAction({ ...payload, mentor_id: REACT_USER_ID }))
        try {
            message.success(`${"Updated Successfully!"}`, 4);

            setTimeout(() => {
                setPage(fields?.currency === "INR" ? 2 : 1);
                setLoader(false);
            }, 800);
        } catch (error) {
            message.error("Some Error Occured in updating!", 4);
            setLoader(false);
        }
    };
    const PayoutPreference = () => (
        <Row>
            <Col span={24}>
                <Paragraph className="po-title">Payout preference</Paragraph>
                <Paragraph className="po-subtitle">
                    Please confirm the currency and country you want to receive your
                    payout in.
                </Paragraph>
                <Form
                    requiredMark={false}
                    layout="vertical"
                    className="payout-preference-form"
                    onFinish={submitPayoutPreferenceForm}
                    initialValues={{
                        currency: bank_details?.currency,
                        country: bank_details?.country,
                    }}
                >
                    {/* Currency */}
                    <Form.Item
                        name="currency"
                        label="Currency"
                        rules={[
                            {
                                required: true,
                                message: "Please select a currency",
                            },
                        ]}
                    >
                        {loader ? (
                            <Skeleton.Button block active />
                        ) : (
                            <Select
                                options={CurrencyOptions}
                                placeholder="Select a Currency"
                                style={{ width: "100%" }}
                                fieldNames={{ label: "label", value: "value" }}
                                showSearch
                                loading={loader}
                                    onChange={(value) => {
                                        setBank_details((prevBankDetails) => ({
                                            ...prevBankDetails,
                                            currency: value,
                                        }));
                                    }}
                            />
                        )}
                    </Form.Item>

                    {/* Country */}
                    <Form.Item name="country" label="Country" rules={[
                        {
                            required: true,
                            message: "Please select a country ",
                        },
                    ]}  >
                        {loader ? (
                            <Skeleton.Button block active />
                        ) : (
                            <Select
                                options={AllCountriesList}
                                placeholder="Select a Country"
                                style={{ width: "100%" }}
                                defaultValue={bank_details?.country || null}
                                fieldNames={{ label: "country", value: "code" }}
                                showSearch
                                loading={loader}
                                filterOption={(input, option) =>
                                    (option?.country ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                    onChange={(value) => {
                                        setBank_details((prevBankDetails) => ({
                                            ...prevBankDetails,
                                            country: value,
                                        }));
                                    }}
                            />
                        )}
                    </Form.Item>

                    {/* Submit */}
                    <Button
                        className="btn-dark save-btn"
                        htmlType="submit"
                        loading={loader}
                        disabled={loader}
                        block
                    >
                        Confirm details
                    </Button>
                </Form>
            </Col>
        </Row>
    );

    const PayoutOptions = () => (
        <Row>
            <Col span={24}>
                <Paragraph className="po-title">Choose payout method</Paragraph>
                <Paragraph className="po-subtitle">
                    It&apos;s how you will receive your earnings from Mentorpal. &nbsp;
                    <a
                       
                    >
                        <Text strong>
                            <u>Learn more</u>
                        </Text>
                    </a>
                </Paragraph>

                {/* Bank Transfer */}
                {bank_details?.currency?.code === "INR" ? (
                    <Button
                        block
                        className="po-options-cta"
                        type="text"
                        onClick={() => {

                            setPage(2);
                        }}
                    >
                        <Space size={12} align="center">
                            <BsBank2 size={28} />
                            <Text strong style={{ fontSize: 16 }}>
                                Indian bank account
                            </Text>
                        </Space>

                        <Space align="start">
                            <Text style={{ fontSize: 12 }}>0-2 days</Text>
                            <FaChevronRight fontSize={16} />
                        </Space>
                    </Button>
                ) : (
                    <>

                        {/* Stripe */}
                        <Button
                            block
                            className={`po-options-cta ${isSupportedByStripe(bank_details?.country) ? "" : "disabled"
                                }`}
                            type="text"
                            onClick={() => {

                                setPage(4);
                            }}
                            disabled={!bank_details?.allow_stripe_connect}
                        >
                            <img
                                 src={stripe}
                                alt="Stripe Logo"
                                width={82}
                                height={33}
                                className="po-options-icon stripe"
                            />

                            <Space align="start">
                                <Text style={{ fontSize: 12 }}>0-2 days</Text>
                                <FaChevronRight fontSize={16} />
                            </Space>
                        </Button>

                        {/* Paypal */}
                        <Button
                            block
                            className="po-options-cta"
                            type="text"
                            onClick={() => {

                                setPage(3);
                            }}
                        >
                            <img
                                src={paypal}
                                alt="Paypal Logo"
                                width={115}
                                height={28}
                                className="po-options-icon paypal"
                            />

                            <Space align="start">
                                <Text style={{ fontSize: 12 }}>7 days</Text>
                                <FaChevronRight fontSize={16} />
                            </Space>
                        </Button>

                        {/* US Bank Account */}
                        {bank_details?.country === "US" && (
                            <Button
                                block
                                type="text"
                                className="po-options-cta"
                                onClick={() => {

                                    setPage(2);
                                }}
                            >
                                <Space size={12} align="center">
                                    <BsBank2 size={28} />
                                    <Text strong style={{ fontSize: 16 }}>
                                        US bank account
                                    </Text>
                                </Space>

                                <Space align="start">
                                    <Text style={{ fontSize: 12 }}>0-2 days</Text>
                                    <FaChevronRight fontSize={16} />
                                </Space>
                            </Button>
                        )}
                    </>
                )}
            </Col>
        </Row>
    );

    const StripeView = () => (
        <Row>
            <Col span={24}>
                <Paragraph className="po-title" style={{ marginBottom: 40 }}>
                    <img
                        src={stripe}
                        alt="Stripe Logo"
                        width={82}
                        height={33}
                        className="po-options-icon stripe"
                    />
                </Paragraph>
                <Paragraph className="po-content">
                    Get paid to your local bank account automatically. No minimum
                    threshold. If you don&apos;t have a Stripe account, you&apos;ll be
                    asked to create one for free.
                </Paragraph>

            </Col>
        </Row>
    );

    const PaypalView = () => (
        <Row>
            <Col span={24}>
                <Paragraph className="po-title" style={{ marginBottom: 40 }}>
                    <img
                        src={paypal}
                        alt="Paypal Logo"
                        width={115}
                        height={28}
                        className="po-options-icon paypal"
                    />
                </Paragraph>
                <Form
                    layout="vertical"
                    requiredMark={false}
                    form={paypalForm}
                    onFinish={submitPaypal}
                    initialValues={{
                        email: bank_details?.external_account_id,
                    }}
                >
                    <Form.Item
                        name="email"
                        label="Paypal Email"
                        rules={[
                            {
                                required: true,
                                message: "Email is required!",
                            },
                            {
                                pattern: EmailRegexPattern,
                                message: "Please enter a valid email!",
                            },
                            {
                                whitespace: true,
                                message: "Email cannot be empty!",
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter your paypal email"
                            disabled={bank_details?.external_account_id}
                        />
                    </Form.Item>
                    <Popconfirm
                        title="Update paypal id"
                        description="Are you sure to update the email id?"
                        open={bank_details?.external_account_id}
                        onConfirm={() => paypalForm.submit()}
                        onCancel={() => { }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            className="btn-dark"
                            htmlType={bank_details?.external_account_id ? "button" : "submit"}
                            loading={loader}
                            disabled={loader || bank_details?.external_account_id}
                            style={{ marginTop: 26 }}

                            block
                        >
                            {bank_details?.external_account_id
                                ? "Update Email"
                                : "Connect Paypal"}
                        </Button>
                    </Popconfirm>
                </Form>
            </Col>
        </Row>
    );

    const BankAccountView = () => (
        <Row>
            <Col span={24}>
                <Paragraph className="po-title" style={{ marginBottom: 40 }}>
                    Bank Account Details
                </Paragraph>
                <BankDetailsForm
                    bank_details={bank_details}
                    onModalClose={onModalClose}
                />
            </Col>
        </Row>
    );

    const getModalContent = () => {
        switch (page) {
            case 0:
                return <PayoutPreference />;
            case 1:
                return <PayoutOptions />;
            case 2:
                return <BankAccountView />;
            case 3:
                return <PaypalView />;
            case 4:
                return <StripeView />;
            default:
                break;
        }
    };

    return (
        <Modal
            open={open}
            onCancel={onModalClose}
            footer={null}
            width={400}
            centered
            destroyOnClose
            className="payment-onboarding-modal"
        >
            {getModalContent()}
        </Modal>
    );
};

export default PaymentsOnboarding;


