import React, { useEffect,useRef, useState } from "react";
import { UploadImage } from "../../../UploadImage";
import { useSelector } from "react-redux";
import { userDetailsConstants } from "../../../../constants";

const Four = ({
  registrationProcessData,
  setRegistrationProcessData,
  errors,
}) => {
 
    const [imageInsert,setImageInsert]=useState(false);
    const userDetailsReducer=useSelector((state)=>state?.userDetailsReducer?.data);
    
    useEffect(()=>{
      setImageInsert(false);
    },[]);
  
  useEffect(()=>{
     if(userDetailsReducer?.profile_photo){
      setRegistrationProcessData((prev) => ({ ...prev, profile_photo:userDetailsReducer?.profile_photo }))
     }
  },[userDetailsReducer?.profile_photo]);

  const handleImage = (val) => {
    if (val) {
      if (!errors.profile_photo?.onValidateFunc) return;
      let msg = null;
      if (!val && errors.profile_photo?.isReq) {
        msg = `Please select profile photo.`;
      }
      errors.profile_photo?.onValidateFunc(msg, "profile_photo");
      setRegistrationProcessData((prev) => ({ ...prev, profile_photo: val }));
      setImageInsert(true);
    }
  };
  return (
    <>
      <div className="registrationCard">
        <div className=" leftSide">
          <h3 className="mb-32 fw-bold">
            Add a profile photo so people can find you.
          </h3>
        
              <p className="related-context"> Upload a picture of yours in Business formals or casuals make sure that the picture is good, your profile photo is your first impression</p>
        </div>
     
        <div className="row rightSide">
          <div className="container">
            <div className="form-group">  
              <UploadImage
                // ref={profileImage}
                callback={handleImage}
                fileName="_profile-photo"
                imagePath={"profilePhoto/"}
                filetype={["image"]}
              />
              {registrationProcessData?.profile_photo && !imageInsert?
              (<div className="h-25 w-25" id="preview">
              <img
                 src={
                `${process.env.REACT_APP_IMAGES_BASE_URL}${registrationProcessData?.profile_photo}`
              }
              alt=""/>
              </div>)
            :null}
            </div>
          </div>
          {errors?.profile_photo?.errorMsg && (
            <span className="text-danger">
              {errors?.profile_photo?.errorMsg === true
                ? `Please select profile photo.`
                : errors?.profile_photo?.errorMsg}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default Four;
