import { Button, Flex, Modal, Space, Tag, Typography, message } from "antd";
import { useState } from "react";
import styles from "./reschedulePolicyModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../redux/actions/login";
import { REACT_USER_ID } from "../../../auth/tokenProvider";

const ReschedulePolicyModal = (props) => {
    const dispatch = useDispatch()
    const { showModal, setShowModal, profile } = props;
    const { Paragraph, Text } = Typography;

    const { CheckableTag } = Tag;
    const [loading, setLoading] = useState(false);
    const time = [
        { value: 30, text: "30 mins" },
        { value: 480, text: "8 hrs" },
        { value: 1440, text: "24 hrs" },
        { value: 0, text: "Anytime" },
    ];

    const rescheduleTypes = [
        { value: 1, text: "Request reschedule" },
        { value: 2, text: "Directly reschedule" },
    ];
    const [selectedTime, setSelectedTime] = useState(
        profile?.reschedule_buffer_minutes || 0
    );
    const [selectedReschedule, setSelectedReschedule] = useState(
        profile?.reschedule_policy || 1
    );
    const handleCancel = () => {

        setShowModal(false);
    };

    const handleReschedulesChange = (item) => {
        setSelectedReschedule(item);
    };
    const updateProfileReducer = useSelector(
        (state) => state?.updateProfileReducer
    );
    const updatePolicy = async () => {
        setLoading(true);
        const postObj = {
            reschedule_buffer_minutes: selectedTime,
            reschedule_policy: selectedReschedule,
        };

        try {
            await dispatch(updateProfile({ user_id: REACT_USER_ID, ...postObj }));

            const { data, error, loading, status } = updateProfileReducer;
            console.log("updateProfileResponse", updateProfileReducer);

            if (status === true && !loading && error === undefined) {
                message.success("Settings Updated Successfully!", 4);
            } else {
                message.error("Failed to update Settings. Please try again!");
            }
            setLoading(false);
            setShowModal(false);
        } catch (error) {
            setLoading(false);
            message.error("Failed to update", 4);
        }

    };

    return (
        <Modal
            open={showModal}
            onCancel={handleCancel}
            width={420}
            closable={false}
            centered
            footer={null}
        >
            <Paragraph className={styles.ModalHeader}>
                <Flex justify="space-between" align="center">
                    <Space>
                        <Text strong className={styles.Heading}>
                            Reschedule Policy
                        </Text>
                    </Space>
                    <Paragraph
                        onClick={() => {
                            handleCancel();
                        }}
                        className={styles.CloseIcon}
                    >
                        &#x2715;
                    </Paragraph>
                </Flex>
            </Paragraph>
            <Paragraph className={styles.Fields} style={{ marginTop: "54px" }}>
                How can your customers initiate a reschedule
            </Paragraph>
            <Space size={[0, 8]} className={styles.RescheduleTags}>
                {rescheduleTypes.map((tag) => (
                    <CheckableTag
                        key={tag?.text}
                        className={styles.checkableTag}
                        checked={selectedReschedule == tag?.value ? true : false}
                        onChange={(checked) => {
                            handleReschedulesChange(tag?.value);
                        }}
                    >
                        {tag?.text}
                    </CheckableTag>
                ))}
            </Space>
            <Paragraph className={styles.Fields}>
                Minimum notice before rescheduling a call
            </Paragraph>
            <Space size={[0, 8]} className={styles.TimeTags}>
                {time.map((tag) => (
                    <CheckableTag
                        key={tag?.value}
                        className={styles.checkableTag}
                        checked={selectedTime == tag?.value ? true : false}
                        onChange={(checked) => setSelectedTime(tag?.value)}
                    >
                        {tag?.text}
                    </CheckableTag>
                ))}
            </Space>
            <Button
                className="btn-dark"
                block
                onClick={updatePolicy}
                loading={loading}
            >
                Update Policy
            </Button>
        </Modal>
    );
};

export default ReschedulePolicyModal;
