import React, { useState } from "react";
import "./UpdateLanguage.css";
import { languages } from "../../../../assets/data/data";
import ReactSelect from "react-select";

const UpdateLanguage = (props) => {
  const customKeyLang = props?.languages?.map((item) => {
    return { label: item, value: item };
  });
  const [selectedLanguage, setSelectedLanguage] = useState(customKeyLang);
  const setLanguageValue = (e) => {
    let langList = selectedLanguage;
    e?.map((item) => langList?.push(item?.label));
    langList.push(e);

    setSelectedLanguage(e);
  };

  const handleOnClick = () => {
    let languages = selectedLanguage?.map((item) => item?.value);
    let tempData = { language: languages };
    props?.getUpdatedValues(tempData);
  };
  return (
    <div className="update-language-main-container">
      <h7>What language(s) do you speak?</h7>
      <div className="language-input">
        <ReactSelect
          required={true}
          value={selectedLanguage ? selectedLanguage : ""}
          placeholder="e.g English,Hindi,French"
          isMulti
          name="language"
          onChange={setLanguageValue}
          options={languages}
        />
      </div>
      <button className="update-btn-language" onClick={handleOnClick}>
        Update
      </button>
    </div>
  );
};

export default UpdateLanguage;
