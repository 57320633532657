import React from 'react';
import logo from '../../../assets/images/logo-w.png'
import fbLogo from "../../../assets/images/fb.png";
import twitterLogo from "../../../assets/images/twitter.png";
import linkedInLogo from "../../../assets/images/linkedin.png";
import instaLogo from "../../../assets/images/insta.png";
import youtubeLogo from "../../../assets/images/youtube.png";
import mentorLogo from "../../../assets/images/mentor-logo.png";



const MasterFooter = () => {
	return (
		<>

			<footer className='course__footer' >
				<div
					className="container "
					style={{ paddingLeft: "0", paddingRight: "0" }}
				>
					<div
						className="menu navbar navbar-expand-lg navbar__fix"
						style={{ display: "", flexBasis: "" }}
					>
						<div className="met_fot1" id="met_fot1">
							<img src={mentorLogo} alt='mentorpalLogo' />
						</div>

						<div
							className="menu  rounded Mobile_menu m-2 text-align-center"
							style={{ display: "", flexBasis: "100%" }}
							id="headerHandler"
						>
							<ul className="navbar-nav align-items-center  ">
								<li className="nav-item">
									<a href='/explore-courses ' className="course_footer-title" >Explore course</a>
								</li>
								<li className="nav-item">
									<a href='/host-course' className="course_footer-title" >Host a course</a>
								</li>
								<li className="nav-item">
									<a href='/' className="course_footer-title" >Get mentorship</a>
								</li>
								<li className="nav-item">
									<a href='/become-mentor' className="course_footer-title">Become a Mentor</a>
								</li>
								<li className="nav-item ">
									<div className="foot_ican course__foot_ican">
										<ul>
											<li>
												<a href="https://www.facebook.com/profile.php?id=100090574942977" target="_blank" rel="noreferrer">
													<img alt="" src={fbLogo} />
												</a>
											</li>
											<li>
												<a href="https://twitter.com/mentorpal">
													<img alt="" src={twitterLogo} />
												</a>
											</li>
											<li>
												<a href="https://www.linkedin.com/company/mentorpal/mycompany/" target="_blank" rel="noreferrer">
													<img alt="" src={linkedInLogo} />
												</a>
											</li>
											<li>
												<a href="https://www.instagram.com/mentorpalai/" target="_blank" rel="noreferrer">
													<img alt="" src={instaLogo} />
												</a>
											</li>
											<li>
												<a href="https://www.youtube.com/@mentorpal" target="_blank" rel="noreferrer">
													<img alt="" src={youtubeLogo} />
												</a>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</div>
					</div>

				</div>

			</footer>
			<section className="copyright why_choose_ment">
				<div className="container">
					<div className="copyright-cont text-center">
						<p>2022 All rights are reserved @Mentorpal Company Ltd.</p>
					</div>
				</div>
			</section>

		</>
	);
}

export default MasterFooter;
