import React from "react";
import { useState } from "react";
import Footer from "../../footer";
import Header from "../../header";
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";
import Testimonial from '../../../components/Testimonial/testimonial.js'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import first from "./files/Frist2 svg.svg"
import first from "../../../assets/images/mission1.png";
import second from "../../../assets/images/values.png";
import third from "../../../assets/images/services.png";
import { Helmet } from "react-helmet";
import SeoHelmet from "../../Seo/Helmet/SeoHelmet";
import { AboutMentorpalSeo } from "../../Seo/data";
import("./about.css");

const AboutUs = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_USER_ID") ? true : false
  );


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 992 },
      items: 3
    },

    ipad: {
      breakpoint: { max: 992, min: 768 },
      items: 2
    },

    tablet1: {
      breakpoint: { max: 768, min: 465 },
      items: 1
    },

    mobile: {
      breakpoint: { max: 465, min: 0 },
      items: 1
    }
  }
  return (
    <>

      <SeoHelmet metaData={AboutMentorpalSeo} />


      {loggedIn ? (
        <div style={{ marginTop: "0" }}>
          <ProtectedHeader extraClasses="inner-header" />
        </div>
      ) : (
        <Header extraClasses="inner-header" />
      )}
      {/* First section */}
      <section>
        <div className="container">
          <div className="row">
            <h1 className="heading-aboutus pt-5" id="about">
              {" "}
              Mentorpal.ai- A ground-up initiative from the heart
            </h1>
          </div>
        </div>
      </section>

      <div className="container container_about">
        <div className="row aboutus-row">
          <div className="row">
            <h2 className="heading-aboutus py-1">Our mission and vision</h2>
          </div>
          <div className="col-lg-4 vision_img_wrapper d-flex">
            <img className="img-fluid vision_image" src={first} alt="Relode" />
          </div>
          <div className="col-lg-8">
            <p className="p-aboutus">
              {" "}
              <b>Mentorpal.ai: A New Way to Achieve Your Goals</b> <br></br>
            </p>
            <ul className="p-aboutus">
              <li>
                Today's world is full of information, but there is a lack of
                direction and guidance.
              </li>
              <li>
                Mentorpal.ai is a platform that connects students with
                experienced mentors who can help them achieve their academic and
                professional goals.
              </li>
              <li>
                Our mentors are experts in their fields and have a wealth of
                knowledge and experience to share.
              </li>
              <li>
                They can help students with everything from choosing a major to
                preparing for a job interview.
              </li>
              <li>
                Mentorpal.ai is the perfect way for students to get the guidance
                and support they need to succeed.
              </li>
              <li>
                We believe that everyone deserves the opportunity to reach their
                full potential, and we are committed to making mentorship
                accessible to everyone.
              </li>
              <li>
                If you are a student who is looking for guidance and support,
                please join Mentorpal.ai today.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Second section    */}
      <div className="container-fluid value_section">
        <div className="container py-4">
          <div className="row aboutus-row">
            <h2 className="heading-aboutus py-1">Our Values </h2>
            <div className="col-md-8">
              <ul className="p-aboutus">
                <li>
                  {" "}
                  <b> Access:</b> We believe that everyone deserves access to
                  quality mentorship, regardless of their background or
                  financial resources.
                </li>
                <li>
                  {" "}
                  <b> Authenticity:</b> We believe in being honest and
                  transparent with our users.
                </li>
                <li>
                  {" "}
                  <b> Collaboration: </b> We believe in working together to
                  achieve our goals.
                </li>
                <li>
                  {" "}
                  <b> Innovation:</b> We are always looking for new ways to
                  improve our platform and services.
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <img className="img-fluid" src={second} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* Third section */}

      <div className="container">
        <div className="row aboutus-row py-4">
          <h2 className="heading-aboutus py-1">Our Products and Services</h2>
          <div className="col-md-5">
            <img className="img-fluid" src={third} alt="" />
          </div>
          <div className="col-md-7">
            <ul className="p-aboutus">
              <li>
                {" "}
                <b> Mentorship matching:</b> We match students with mentors who
                are a good fit for their needs.
              </li>
              <li>
                {" "}
                <b> Mentorship coaching:</b> We provide mentors with the tools
                and resources they need to be successful.
              </li>
              <li>
                {" "}
                <b> Mentorship events: </b> We host events where students and
                mentors can connect in person.
              </li>
              <li>
                {" "}
                <b> Mentorship resources:</b> We provide students and mentors
                with access to a variety of resources, such as articles, videos,
                and webinars.
              </li>
              <li>
                {" "}
                <b> Achievement motivation trainings:</b> Our achievement
                motivation training helps individuals unlock their full
                potential, develop a growth mindset, and achieve their goals
                with confidence.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid value_section">
        <div className="container">
          <div className="row aboutus-row py-4">
            <h2 className="heading-aboutus py-1">Social Responsibility</h2>
            <div className=" p-aboutus">
              Mentorpal.ai is committed to social responsibility. We believe
              that everyone deserves the opportunity to succeed, regardless of
              their background or circumstances. That's why we offer pro bono
              mentorship to those in need. We also believe that education is the
              key to social reform, and we are working to make our platform
              accessible to everyone, regardless of their income or location.
            </div>
          </div>
        </div>
      </div>

      {/* Contact section */}

      <div className="container">
        <div className="row aboutus-row py-4">
          <div className="col-lg-6">
            <h2 className="heading-aboutus py-1">Message Us</h2>
            <p className="p-aboutus">
              At Mentorpal.ai, we value open communication and are always here
              to help with any questions or concerns you may have. Whether you
              are a mentor or a mentee, we want to make sure that your
              experience with us is the best it can be. If you have any
              feedback, suggestions, or simply want to reach out to our team,
              please feel free to send us a message using the contact form
              below. We will do our best to get back to you as soon as possible.
            </p>
          </div>
          <div className="col-lg-6">
            <div className="container py-4">
              <form className="message_form">
                <div className="form-container text-start">
                  <div className="form-group">
                    <label htmlFor="nameInput" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="about_input form-control"
                      id="nameInput"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="emailInput" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="about_input form-control"
                      id="emailInput"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="commentInput" className="form-label">
                      Comment
                    </label>
                    <textarea
                      className="about_input form-control"
                      id="commentInput"
                      rows="3"
                    ></textarea>
                  </div>
                  <center> <button type="submit" className="btn msg_btn">
                    Submit
                  </button></center>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <center><h2 className="heading-aboutus my-5">Our Inspiring Mentors & Empowered Mentees</h2></center>
        <Testimonial />

      </div>

      <div className="text text-about pt-5">

        <center> <p>
          We apologize that some of our services are currently unavailable. Our team is working diligently to bring them on track as soon as possible. Thank you for your understanding.
        </p></center>

      </div>
      <Footer />
    </>

  );
};
export default AboutUs;
