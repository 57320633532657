import React, { useEffect, useState } from "react";
import { v4 } from "uuid";

import {
  Upload,
  Input,
  Button,
  Form,
  Row,
  Col,
  InputNumber,
  message,
} from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons"; // Import Ant Design styles

import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import { uploadImage, uploadImageFile } from "../../../redux/actions/common";
import { publishMentorEbook } from "../../../redux/actions/mentor";

const PublisheMentorEbooks = () => {
  const dispatch = useDispatch()
  const [loading, setloading] = useState(false);
  const [coverFile, setCoverFile] = useState(null)
  const [coverName, setCoverName] = useState(null)
  const [ebookFile, setEbookFile] = useState(null)
  const [ebookName, setEbookName] = useState(null)
  const [FileLoader, setFileLoader] = useState([]);
  const onFinish = (values) => {
    // Handle form submission logic here
    console.log('Received values:', values);
    const data ={
      ...values,
      picture:coverName,
      mentor_id: REACT_USER_ID,
      document_url:ebookName

    }
    dispatch(publishMentorEbook(data));

  };
  const publishEbookReducer = useSelector(
    (state) => state.publishEbookReducer
  );
useEffect(() => {
  const { data, loading, status, error } = publishEbookReducer
  setloading(loading)
  console.log(publishEbookReducer)
  if(data && !loading && !error){
    message.success("upload successfully!") 
   }
   else{
    console.log("not upload ")
   }
}, [publishEbookReducer])
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };


// cover upload
  const CoverUpload = (file) => {
    const fileName = "_book-cover";
    const imagePath = "eBook/cover/";
    let imageName = imagePath + v4() + fileName;
    let extension = file?.name?.split('.').pop().toLowerCase();

    setCoverFile(file);
    dispatch(uploadImage({ documentName: imageName, format: extension }));

    // Remove curly braces around extension
    const coverName = imageName + '.' + extension;
    setCoverName(coverName)
   
    return false; // Prevent automatic upload
  };

  const imageURLReqData = useSelector(
    (state) => state.uploadImageReducer
  );
  const uploadImageFileReducer = useSelector(
      (state) => state.
        uploadImageFileReducer
  );
  const urlVerifyStatus = (url, fileName) => {
    if (!url || !fileName) {
      return false;
    }
    return url.includes(fileName);
  };

  useEffect(() => {
    const { data, loading, status, error } = imageURLReqData
    if(data && !loading){
    if (urlVerifyStatus(data?.url, coverName)  && data ) {
      const reqData = { url: data?.url, fileData: coverFile };
      dispatch(uploadImageFile(reqData));

    } else {
      console.log("error in cover File Upload func")
    }
    if (urlVerifyStatus(data?.url, ebookName)  && data ) {
      const reqData = { url: data?.url, fileData: ebookFile };
      dispatch(uploadImageFile(reqData));

    }else{
      console.log("error in E-Book File Upload func")
    }}
  }, [dispatch, imageURLReqData]);
  useEffect(()=>{
    const { data, loading, status, error, request } = uploadImageFileReducer
    if(data && !loading && urlVerifyStatus(request?.url,coverName)){
      setFileLoader((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[0] = loading;
        return newLoadings;
      });
    }
    else{
      console.log("file cover uplaod loader error")
    }
    if (data && !loading && urlVerifyStatus(request?.url,ebookName)){
      setFileLoader((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[1] = loading;
        return newLoadings;
      });
    }
  }, [uploadImageFileReducer])

// file upload
  const FileUploadFunc =(file)=>{
    console.log("FileUploadFunc",file)
    const fileName = "_book-file";
    const filePath = "eBook/file/";
    let ebookFileName = filePath + v4() + fileName;
    let extension = file?.name?.split('.').pop().toLowerCase();

    setEbookFile(file);
    dispatch(uploadImage({ documentName: ebookFileName, format: extension }));

    // Remove curly braces around extension
    const coverName = ebookFileName + '.' + extension;
    setEbookName(coverName)

    return false; // Prevent automatic upload
  }
  return (
    <>
      {/* {loading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : null} */}
      <Row className="mt-2">

        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 16, offset: 0 }}
          xl={{ span: 12, offset: 0 }}
        >
          <Form
            name="bookForm"
            // labelCol={{ span: 6 }}
            // wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            scrollToFirstError={true}
            requiredMark={false}
            className="profile-details-form"
          >
            {/* Book Title */}
            <Form.Item

              label="Book Title"
              className="title-fields name-field"

              name="title"
              rules={[
                { required: true, message: 'Title is required.' },
                { whitespace: true, message: 'Title cannot be empty!' },
                { min: 3, message: 'Title is too short!' },
                { max: 50, message: 'Max 50 characters allowed!' },
              ]}
            >
              <Input placeholder="Enter the book title" />
            </Form.Item>

            {/* Price */}
            <Form.Item className="title-fields name-field"

              label="Price"
              name="price"
              rules={[
                { required: true, message: 'Price is required.' },
                { type: 'number', min: 0, message: 'Price must be a non-negative number.' },
              ]}
            >
              <InputNumber placeholder="Enter the book price" style={{ width: '100%' }} />
            </Form.Item>

            {/* Author */}
            <Form.Item className="title-fields name-field"

              label="Author"
              name="author"
              rules={[
                { required: true, message: 'Author is required.' },
                { whitespace: true, message: 'Author cannot be empty!' },
                { min: 3, message: 'Author name is too short!' },
                { max: 50, message: 'Max 50 characters allowed!' },
              ]}
            >
              <Input placeholder="Enter the author's name" />
            </Form.Item>

            {/* Book Objective */}
            <Form.Item className="title-fields name-field"

              label="Book Objective"
              name="book_objective"
              rules={[
                { required: true, message: 'Objective is required.' },
                { whitespace: true, message: 'Objective cannot be empty!' },
              ]}
            >
              <Input.TextArea rows={4} placeholder="Enter the book objective" />
            </Form.Item>

            {/* Cover Picture */}
            <Form.Item className="title-fields name-field"

              label="Cover Picture"
              name="picture"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: 'Cover picture is required.' }]}
              >
              <Upload
                beforeUpload={CoverUpload}
                listType="picture"
                maxCount={1}
                disbaled={FileLoader[0]}
                status={FileLoader[0] === true ? 'uploading' : 'done'}
              >
                <Button icon={<UploadOutlined />}>Upload Cover Picture</Button>
              </Upload>
            </Form.Item>

            {/* Book File Upload */}
            <Form.Item className="title-fields name-field"

              label="Upload Book File"
              name="ebook_file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: 'Book file is required.' }]}
              
              >
              <Upload
                beforeUpload={FileUploadFunc}
                listType="text"
                maxCount={1}
                disbaled={FileLoader[1]}
                status={FileLoader[1] === true ? 'uploading' : 'done'}

              >
                <Button icon={<UploadOutlined />}>Upload Book File</Button>
              </Upload>
            </Form.Item>

            {/* Description */}
            <Form.Item className="title-fields name-field"

              label="Description"
              name="description"
              rules={[
                { required: true, message: 'Description is required.' },
                { min: 10, message: 'Description is too short!' },
                { max: 500, message: 'Max 500 characters allowed!' },
              ]}
            >
              <Input.TextArea rows={6} placeholder="Enter the book description" />
            </Form.Item>

            {/* Submit Button */}
            <Form.Item className="title-fields name-field"
              wrapperCol={{ offset: 6, span: 12 }}>
              <Button type="primary" htmlType="submit" className="ant-btn  ant-btn-default tab-cta-link tab-link-common btn-dark" loading={loading}
>
                Submit
              </Button>
        
            </Form.Item>
          </Form>
</Col>
</Row>
     
    </>
  );
};
export default PublisheMentorEbooks;
