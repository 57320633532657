import React, { useState } from 'react';
import docempty from '../../Image/docempty.svg';
import SalesModalDigital from './SalesModal';
import { digitalPrductMainData } from '../../Data/Data';

const DigitalProduct = () => {
  const data = true;
  const [salesModal, setSalesModal] = useState({
    show: false,
    data: ""
  })

  return (
    <>
      {
        digitalPrductMainData && digitalPrductMainData?.length > 0 ? (
          <>
            {
              digitalPrductMainData?.map((item, id) => (
                <div key={id} className="ant-row calls-main ">
                  <div className="ant-col ant-col-24 ">
                    <div className="ant-col calls-card-container ant-col-xs-24 ">
                      <div className="ant-typography calls-card ">
                        <div className="ant-row ant-row-space-between calls-header cursor-hover ">
                          <div className="ant-col ">
                            <div className="ant-typography calls-time ">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 576 512"
                                height={16}
                                width={16}
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginRight: 8 }}
                              >
                                <path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z" />
                              </svg>
                              {item?.total_testimonials} | {item?.total_testimonials} testimonials
                            </div>
                          </div>
                        </div>
                        <div className="ant-typography calls-details cursor-hover ">
                          <span className="ant-typography calls-name ">
                            <strong />
                          </span>
                          <div className="ant-typography calls-name ">
                            <span className="ant-typography ">
                              <strong>{item?.service?.title} </strong>
                            </span>
                          </div>
                        </div>
                        <div
                          className="ant-row ant-row-space-between ant-row-middle package-calls-footer "
                          style={{ marginLeft: "-6px", marginRight: "-6px" }}
                        >
                          <div
                            className="ant-col "
                            style={{ paddingLeft: 6, paddingRight: 6 }}
                          >
                            <div className="ant-typography doc-sales-tag ">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 16 16"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z" />
                              </svg>
                              {item?.total_sales} sales | <b>₹{item?.total_earnings}</b>
                            </div>
                          </div>
                          <div
                            className="ant-col "
                            style={{ paddingLeft: 6, paddingRight: 6 }}
                          >
                            <button
                              onClick={() => {
                                setSalesModal({
                                  show: true,
                                  data: item
                                })

                              }}
                              type="button"
                              className="ant-btn  ant-btn-default btn-dark"
                            >
                              <span>View sales</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              ))
            }


          </>
        ) : (

          <>
            <div className="ant-row container-children ">
              <div className="ant-col ant-col-xs-22 ant-col-xs-offset-1 ant-col-md-22 ant-col-md-offset-1 ant-col-xxl-16 ant-col-xxl-offset-1 ">
                <div className="ant-row ant-row-center ant-row-middle ">
                  <div className="ant-col text-center ant-col-xs-24 ant-col-md-7 ">
                    <img
                      alt="No Pending/Answered Queries"
                      loading="lazy"
                      width={170}
                      height={170}
                      decoding="async"
                      data-nimg={1}
                      className=""
                      src={docempty}
                      style={{ color: "transparent", height: "auto" }}
                    />
                    <div className="ant-space  ant-space-vertical ant-space-align-center ant-space-gap-row-middle ant-space-gap-col-middle">
                      <div className="ant-space-item">
                        <h5
                          className="ant-typography "
                          style={{ marginBottom: 0, marginTop: 16 }}
                        >
                          Sell a digital product
                        </h5>
                      </div>
                      <div className="ant-space-item">
                        <div className="ant-typography " style={{ margin: 0 }}>
                          Create a passive income or lead generation stream by hosting a
                          product
                        </div>
                      </div>
                      <div className="ant-space-item">
                        <button
                          type="button"
                          className="ant-btn  ant-btn-primary btn-dark"
                          style={{ marginTop: 8 }}
                        >
                          <span>+ Add a product</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        salesModal && (
          <SalesModalDigital salesModal={salesModal} setSalesModal={setSalesModal} />
        )
      }
    </>
  );
}

export default DigitalProduct;
