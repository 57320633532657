import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

const changeHandler = (e, props) => {
  let value = null;
  if (e?.value !== undefined) value = e.value;
  else if (e?.length > 0) value = e;

  props.onChangeFunc(e);
  
  if (!props.onValidateFunc) return;
 

  let msg = null;
  if (!value && props.isReq) {
    msg = `Please select ${props.title}.`;
  }

  props.onValidateFunc(msg, props.name);
};

const Select = (props) => {
  const inputProps = {
    name: props.name,
    placeholder: props.placeholder || `Select ${props.title}`,
    className: props.className,
    isClearable: props.isClearable,
    value: props.value,
    options: props.options,
    label: props.label,
    IsMulti: props.IsMulti,
    components: props.components,
    closeMenuOnSelect: props.closeMenuOnSelect,
    hideSelectedOptions: props.hideSelectedOptions,
    allowSelectAll: props.allowSelectAll,
    // styles: props.styles,
    menuPlacement:props.menuPlacement,
    styles: {
      ...props.styles,
      control: (provided, state) => ({
        ...provided,
        textAlign: 'left', // Align placeholder text to the left
      }),
    },
  };

  return (
    <div className={props.outerClassName}>
      <label className="form-label d-flex text-left" htmlFor="label">
        {props.label}
      </label>
      {
        props.isCreatable
        ? <CreatableSelect
            isMulti={props.IsMulti}
            {...inputProps}
            onChange={(e) => changeHandler(e, props)} 
            menuPlacement={props.menuPlacement}/>
        : <ReactSelect
            isMulti={props.IsMulti}
            {...inputProps}
            onChange={(e) => changeHandler(e, props)} 
            menuPlacement={props.menuPlacement}/>
      }
      {props.errorMsg && (
        <span className="text-danger">
          {(props.errorMsg === true)
            ? `Please select ${props.title}.`
            : props.errorMsg}
        </span>
      )}
    </div>
  );
};

Select.defaultProps = {
  name: '',
  title: '',
  placeholder: '',
  className: '',
  outerClassName: 'mb-2',
  isClearable: true,
  value: '',
  label: "",
  IsMulti: false,
  options: [],
  onChangeFunc: () => { },
  isReq: null,
  onValidateFunc: () => { },
  components: "",
  closeMenuOnSelect: false,
  hideSelectedOptions: false,
  allowSelectAll: false,
  styles: "",
  menuPlacement:"bottom"
};

Select.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  outerClassName: PropTypes.string,
  IsMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array,
  onChangeFunc: PropTypes.func,
  isReq: PropTypes.bool,
  errorMsg: PropTypes.any,
  label: PropTypes.string,
  onValidateFunc: PropTypes.func,
  components: PropTypes.any,
  closeMenuOnSelect: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  styles: PropTypes.any,
  menuPlacement:PropTypes.string
};

export default memo(Select);
