import React, { useState } from 'react';
import './courseCheckout.css'
import { TailSpin } from 'react-loader-spinner';
import { mentorLogo, mentorWlogo } from '../../../assets/images/export-images';
import { REACT_USER_ID } from '../../auth/tokenProvider';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentLinkAction } from '../../../redux/actions/session';
import { useNavigate, useParams } from 'react-router-dom';
import { getCourseDetailsAction } from '../../../redux/actions/course';


const CourseCheckout = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [utcTime, setUTCTime] = useState('');
    const [message, setMessage] = useState('');
    const [paymentError, setPaymentError] = useState('');
    const [utcDate, setUTCDate] = useState('');

    const [ProductId, setProductId] = useState('');
    const [loader, setloader] = useState(true);
    const [courseData, setCoursedata] = useState({});
    const [status, setStatus] = useState('');
    const [error, setError] = useState('')

    const { slug, product_id } = useParams();
    useEffect(() => {
        setProductId(product_id);
    }, []);

    useEffect(() => {
        console.log("ProductId", ProductId);
        dispatch(getCourseDetailsAction(ProductId))
    }, [ProductId, dispatch]);

    const getCourseDetailsReducer = useSelector(
        (state) => state.getCourseDetailsReducer
    )
    useEffect(() => {
        setloader(getCourseDetailsReducer?.loading)
        setCoursedata(getCourseDetailsReducer?.data)
        setStatus(getCourseDetailsReducer?.status)
        setError(getCourseDetailsReducer?.error)
    }, [getCourseDetailsReducer]);
    useEffect(() => {
        console.log("data top", courseData)
    }, [courseData]);


    const [InitialAmountInr, setInitialAmountInr] = useState();
    const [InitialAmountUSD, setInitialAmountUSD] = useState();
    useEffect(() => {
        if (courseData?.discount_price) {
            setInitialAmountInr(
                Math.round(courseData?.discount_price * 83.12 * 0.5)
            )
            setInitialAmountUSD(
                Math.round(courseData?.discount_price * 0.5)
            )
        }
    }, [courseData]);

    const [PayableAmount, setPayableAmount] = useState('');
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    const [loggedIn, setLoggedIn] = useState(
        localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
    );
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const utcTimeString = now.toISOString();
            const utcDateString = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())).toISOString().split('T')[0];
            setUTCTime(utcTimeString);
            setUTCDate(utcDateString);
        }, 1000); // Update every second

        return () => {
            clearInterval(interval);
        };
    }, []);


    let date = new Date();
    let datestring = date.toTimeString();
    let countryname =
        datestring?.includes("india") | datestring?.includes("India")
            ? "India"
            : "";



    const handleCheckout = () => {

        console.log("checkout fuction called")
        // Validation: Check if required fields are filled

        if (!loggedIn) {
            if (!formData.fullName || !formData.email || !formData.phone) {
                setMessage("Please fill all the required fields !!");
                setTimeout(() => {
                    setMessage("");
                }, 2000);
                return;
            }
        }
        let data;
        if (loggedIn) {
            data = {
                product_id: courseData?.product_id,
                mentor_id: courseData?.mentor_id,
                title: courseData?.title,
                mentee_id: REACT_USER_ID,
                amount: PayableAmount,
                currency: countryname === "India" ? "INR" : "USD",
                booked_date: utcDate,
                booked_time: utcTime,
                document_type :"course"
            }

        } else {
            if (courseData?.proBono === 'false') {
                data = {
                    product_id: courseData?.product_id,
                    mentor_id: courseData?.mentor_id,
                    amount: PayableAmount,
                    currency: countryname === "India" ? "INR" : "USD",
                    booked_date: utcDate,
                    booked_time: utcTime,
                    name: formData?.fullName,
                    email: formData?.email,
                    phone: formData?.phone,
                    title: courseData?.course_topic,
                    document_type: "course"

                }

            }
            else {
                data = {
                    proBono: courseData?.proBono,
                    product_id: courseData?.product_id,
                    mentor_id: courseData?.mentor_id,
                    booked_date: utcDate,
                    booked_time: utcTime,
                    name: formData?.fullName,
                    email: formData?.email,
                    phone: formData?.phone,
                    title: courseData?.course_topic,
                    document_type: "course"

                }
            }
        }
        console.log("data", data)
        dispatch(createPaymentLinkAction(data));
    }
    const PaymentUrl = useSelector((state) => {
        return state?.createPaymentLinkReducer?.data;
    });
    const createPaymentLinkReducer = useSelector((state) => {
        return state?.createPaymentLinkReducer;
    });

    useEffect(() => {
        setloader(createPaymentLinkReducer?.loading)
        if (createPaymentLinkReducer?.status === 200 && PaymentUrl) {
            window.location.href = PaymentUrl; // U
        }
        if (createPaymentLinkReducer?.error !== undefined && createPaymentLinkReducer?.loading === false && PaymentUrl === undefined) {
            setPaymentError("Please try again later !!")
            setTimeout(() => {
                setPaymentError("");
            }, 2000);
        }
    }, [PaymentUrl, navigate, createPaymentLinkReducer]);
    const isCheckoutDisabled = !loggedIn
        ? !(formData.fullName && formData.email && formData.phone)
        : false;


    const [paymentOption, setPaymentOption] = useState('full');
    useEffect(() => {
        if (paymentOption === 'full') {
            setPayableAmount(countryname === "India"
                ? Math.round(courseData?.discount_price * 83.12)
                : Math.round(courseData?.discount_price))
        }
        if (paymentOption === 'partial') {
            setPayableAmount(countryname === "India"
                ? Math.round(InitialAmountInr)
                : Math.round(InitialAmountUSD))
        }


    }, [paymentOption, countryname, InitialAmountInr, InitialAmountUSD, courseData]);

    const handlePaymentChange = (event) => {
        setPaymentOption(event.target.value);
    };

    const getDateOfNextMonth = () => {
        const currentDate = new Date();
        const nextMonth = new Date(currentDate);
        nextMonth.setMonth(nextMonth.getMonth() + 1);

        const month = nextMonth.toLocaleString('default', { month: 'short' });
        const day = nextMonth.getDate();
        const year = nextMonth.getFullYear();

        return `${month} ${day}, ${year}`;
    }
    const getLocalTime = (utcTime) => {
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };

        const localTime = new Date(utcTime).toLocaleString("en-US", options);
        return localTime;
    };
    return (
        <>
            <div className={`${loggedIn ? 'bg-white w-100' : 'sign-up-page'}`}>
                <div className={` container w-100 ${loggedIn ? "loggedIn-container" : "singupMentor-container max----checkout  "}`} >
                    {loader && (
                        <div className="loader" style={{ zIndex: 9999 }}>
                            <TailSpin ariaLabel="Loading..." color="#00BFFF" />
                        </div>
                    )}



                    <div className={`mt-4 ${loggedIn ? 'd-block bg-white' : 'signup-row'}`}>
                        {
                            loggedIn ? (null) : (
                                <>
                                    <div className="signup-leftcol">
                                        <div className="sign-form-cls">
                                            <div className="logo-sec">
                                                <h4>Welcome to</h4>
                                                <img src={mentorLogo} alt="Mentorpal" />
                                                <h4>Cart</h4>
                                            </div>
                                        </div>

                                        <div className="mt-3 manual-signup">
                                            <form >
                                                {!loggedIn ? (
                                                    <>

                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                name="fullName"
                                                                placeholder="Full Name"
                                                                className="form-control"
                                                                value={formData.fullName}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                placeholder="Email"
                                                                className="form-control"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                name="phone"
                                                                placeholder="Phone"
                                                                className="form-control"
                                                                value={formData.phone}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <p className='text-center m-auto text-danger'>{message}</p>
                                                    </>
                                                ) : (null)}
                                            </form>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        <div className={`${loggedIn ? 'd-block' : 'checkout-rightcol'}`}>
                            <>
                                <div className="">
                                    <div className={`${loggedIn ? 'loggdin_checkout-content' : 'checkout-content'}`}>

                                        <div className={`${loggedIn ? 'd-block' : 'bag-product'}`}>
                                            <div className={`${loggedIn ? 'w-100 ' : 'checkout-image'}`}>
                                                <img
                                                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}${courseData?.course_banner}`} alt={courseData?.course_topic}
                                                    loading="lazy"
                                                    className="bnr-img"
                                                />
                                            </div>
                                            <div className="description">
                                                <p className="product-code  muted">Product Id : {courseData?.product_id}</p>
                                                <h1 className='checkout-h1'>{courseData?.course_topic}</h1>
                                                <p> <span><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={20}
                                                    fill="currentColor"
                                                    className="bi bi-calendar2"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z" />
                                                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z" />
                                                </svg>
                                                    {' '}
                                                </span>{getLocalTime(courseData?.course_start_date)}</p>
                                                <p className="description-text">
                                                    <span><svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={20}
                                                        height={20}
                                                        fill="currentColor"
                                                        className="bi bi-file-earmark-richtext-fill"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM7 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V9.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V9s1.54-1.274 1.639-1.208zM5 11h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1zm0 2h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1z" />
                                                    </svg>
                                                        {' '}
                                                    </span>{courseData?.course_type}
                                                </p>
                                            </div>

                                        </div>
                                        <div className="bag-total">
                                            <div className="subtotal">
                                                <p className="checkout-h1">Price:</p>
                                                <p className="checkout-h1" style={courseData?.proBono === "true" ? {} : { textDecoration: "line-through" }}>
                                                    {courseData?.proBono === "true"
                                                        ? "Pro Bono"
                                                        : countryname === "India"
                                                            ? "₹" + Math.round(courseData?.price * 83.12)
                                                            : "$" + Math.round(courseData?.price)}
                                                </p>

                                            </div>
                                            <div className="subtotal">
                                                <p className="">Hosted by:</p>
                                                <p className="">Mentorpal.ai</p>
                                            </div>
                                        </div>

                                        {courseData?.proBono === "true" ? (null) : (
                                            <>
                                                <h4 className="checkout-hr-lines"> Details </h4>
                                                <div className="bag-total">
                                                    <div className="subtotal">
                                                        <p className="">Discount:</p>
                                                        <p className="badge rounded-pill bg-success">{((courseData?.price - courseData?.discount_price) / courseData?.price * 100).toFixed(0)}% off</p>
                                                    </div>
                                                    <div className="subtotal">
                                                        <p className="">Total:</p>
                                                        <p className="" style={{ textDecoration: "line-through" }}>  {courseData?.proBono === "yes"
                                                            ? "Pro Bono"
                                                            : countryname === "India"
                                                                ? "₹" + Math.round(courseData?.price * 83.12)
                                                                : "$" + Math.round(courseData?.price)

                                                        }
                                                            <span class="badge rounded-pill bg-success">{courseData?.is_pro_bono === "yes"
                                                                ? null
                                                                : countryname === "India"
                                                                    ? "₹" + Math.round(courseData?.discount_price * 83.12)
                                                                    : "$" + Math.round(courseData?.discount_price)

                                                            }</span>
                                                        </p>

                                                    </div>
                                                    <div className="subtotal pt-2">
                                                        <p className="">Choose:</p>
                                                        <p className=""><form className='d-flex gap-3'>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value="full"
                                                                    checked={paymentOption === 'full'}
                                                                    onChange={handlePaymentChange}
                                                                    id="fullPaymentRadio"
                                                                />
                                                                <label className="form-check-label" htmlFor="fullPaymentRadio">
                                                                    Full Payment
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value="partial"
                                                                    checked={paymentOption === 'partial'}
                                                                    onChange={handlePaymentChange}
                                                                    id="partialPaymentRadio"
                                                                />
                                                                <label className="form-check-label" htmlFor="partialPaymentRadio">
                                                                    Partial Payment
                                                                </label>
                                                            </div>
                                                        </form></p>
                                                    </div>





                                                    <h4 className="checkout-hr-lines"> Payment </h4>
                                                    {paymentOption === 'partial' ? (
                                                        <>

                                                            <div className="subtotal">
                                                                <p className="">Initial Payments:</p>
                                                                <p className="badge rounded-pill bg-success">   {courseData?.proBono === "yes"
                                                                    ? null
                                                                    : countryname === "India"
                                                                        ? "₹" + Math.round(InitialAmountInr)
                                                                        : "₹" + Math.round(InitialAmountUSD)}</p>
                                                            </div>
                                                            <div className="subtotal">
                                                                <p className="">Remaining  Payments:</p>
                                                                <p className="badge rounded-pill bg-success"> {courseData?.proBono === "yes"
                                                                    ? null
                                                                    : countryname === "India"
                                                                        ? "₹" + Math.round(courseData?.discount_price * 83.12 - InitialAmountInr)
                                                                        : "$" + Math.round(courseData?.discount_price - InitialAmountUSD)

                                                                } {" "}on{" "}{getDateOfNextMonth()}</p>
                                                            </div>

                                                        </>
                                                    ) : (null)}
                                                    <div className="subtotal pt-2">
                                                        <p className="">Total:</p>
                                                        <p className="badge rounded-pill bg-success">

                                                            {courseData?.proBono === "yes"
                                                                ? null
                                                                : countryname === "India"
                                                                    ? "₹" + Math.round(PayableAmount)
                                                                    : "$" + Math.round(PayableAmount)

                                                            }
                                                        </p>

                                                    </div>





                                                </div>
                                            </>
                                        )

                                        }
                                        <div className={`checkout-btn ${isCheckoutDisabled ? 'disabled-btn' : ''}`}>
                                            <button
                                                className={`btn-go-checkout ${isCheckoutDisabled ? 'payBtn-disabled' : ''}`}
                                                onClick={handleCheckout}
                                                disabled={isCheckoutDisabled} // Disable the button based on the condition
                                            >
                                                <span>Pay</span>
                                            </button>
                                        </div>
                                        <p className="text-center m-auto text-danger">
                                            {paymentError}
                                        </p>

                                        <div className="help">
                                            <p>Need help? Call free +91 99403 39107
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </>


                        </div>
                    </div>



                </div>
            </div>

        </>
    );
}

export default CourseCheckout;
