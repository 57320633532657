import React from "react";
import "./Listing.css";
import MenteeCard from "./MenteeCard";

function Listing() {
  return (
    <>
      <div className="container listing_container">
        <div className="row">
          <div className="col-md-3 all_mentees">
            <div className="colored_col">
              <span className="card mentee_details mx-1 all_col">
                <span className="img_name d-flex pt-3 ps-3">
                  {" "}
                  <svg
                    className="mx-3 mt-1 dot"
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    fill="red"
                    class="bi bi-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>{" "}
                  <h6 className="card-title">All Mentees</h6>
                  <br></br>
                </span>
              </span>
              <MenteeCard />
              <MenteeCard />
              <MenteeCard />
              <MenteeCard />
              <MenteeCard />
              <MenteeCard />
              <MenteeCard />
            </div>
          </div>

          {/* second column */}

          <div className="col-md-3 new_joined">
            <div className="colored_col">
              <span className="card mentee_details mx-1 all_col">
                <span className="img_name d-flex pt-3 ps-3">
                  <svg
                    className="mx-3 mt-1 dot"
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    fill="green"
                    class="bi bi-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>{" "}
                  <h6 className="card-title">New</h6>
                  <br></br>
                </span>
              </span>
              <MenteeCard />
              <MenteeCard />
              <MenteeCard />
              <MenteeCard />
            </div>
          </div>

          {/* third column      */}
          <div className="col-md-3 for_upcoming_sessions">
            <div className="colored_col">
              <span className="card mentee_details mx-1 all_col">
                <span className="img_name d-flex pt-3 ps-3">
                  <svg
                    className="mx-3 mt-1 dot"
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    fill="blue"
                    class="bi bi-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>{" "}
                  <h6 className="card-title">Active</h6>
                  <br></br>
                </span>
              </span>
              <MenteeCard />
              <MenteeCard />
              <MenteeCard />
              <MenteeCard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Listing;
