import React, { useState } from "react";

const PurchaseHistory = () => {
  //Dummy data for purchase history (you can replace this with your actual data)
  const purchaseHistoryData = [
    {
      orderID: "123456",
      courseName: "Course Name 1",
      purchaseDate: "Jan 15, 2023",
      courseAmount: "$49.99",
      paymentStatus: "Completed",
    },
    {
      orderID: "123457",
      courseName: "Course Name 2",
      purchaseDate: "Feb 20, 2023",
      courseAmount: "$29.99",
      paymentStatus: "Completed",
    },
    {
      orderID: "123458",
      courseName: "Course Name 3",
      purchaseDate: "Mar 10, 2023",
      courseAmount: "$39.99",
      paymentStatus: "Pending",
    },
    // Add more data as needed
  ];

  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(purchaseHistoryData.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = purchaseHistoryData.slice(startIndex, endIndex);

  return (
    <>
      <br></br>
      <section id="Header">
        <div className="header ">
          <div
            className="ctitle"
            style={{ maxWidth: 1872, fontSize: "34px", marginBottom: "20px" }}
          >
            Purchase History of Courses
          </div>
        </div>
        <br></br>

        <div className="items-per-page">
          <label>
            Show
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
            entries
          </label>
        </div>
      </section>
      <div className="table-inner db-table">
        <div className="table-responsive">
          <table classNmae="table" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Course Name</th>
                <th>Order ID</th>
                <th>Purchase Date</th>
                <th>Course Amount</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <tbody>
              {purchaseHistoryData.map((item, index) => (
                <tr key={index}>
                  <td className="py-3">{item.courseName}</td>
                  <td>{item.orderID}</td>
                  <td>{item.purchaseDate}</td>
                  <td>{item.courseAmount}</td>
                  <td>
                    <span
                      className={`badge ${
                        item.paymentStatus === "Completed"
                          ? "bg-success"
                          : "bg-warning"
                      }`}
                    >
                      {item.paymentStatus}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <br></br>
      <div
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
          marginTop: 0,
        }}
      >
        <button
          className="btn btn-primary"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous Page
        </button>
        <button
          className="btn btn-primary"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next Page
        </button>
      </div>
    </>
  );
};

export default PurchaseHistory;
