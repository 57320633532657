import {
    Button,
    Col,
    Drawer,
    Row,
    Skeleton,
    Typography,
} from "antd";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { isMobile, ServiceTemplateData } from "../../Data/Data";
import { useNavigate } from "react-router-dom";


const { Paragraph, Text, Title } = Typography;
const ServiceTemplates = (
    { setSeeTemplates, seeTemplates }
) => {
    const navigate = useNavigate()
    const [tab, setTab] = useState(1);
    const [isLoading, setisLoading] = useState(false);
    const [templates, settemplates] = useState(ServiceTemplateData);
    const serviceTab = {
        1: "video",
        2: "text",
        3: "webinar",
        4: "document",
        5: "package",
    };

    const getTemplates = () => {
        return templates.filter((template) => template?.type == tab);
    };


    const handleClick = (val) => {
        console.log("Navigating with", val);
        navigate(`/dashboard/service/add?currentTab=${serviceTab[val?.type]}&templateId=${val?.id}`);
        setSeeTemplates(false);
    };





    return (
        <Drawer
            title={null}
            placement={isMobile ? "right" : "bottom"}
            open={seeTemplates}
            onClose={setSeeTemplates}
            width={530}
            closable={false}
            height={isMobile ? "" : "70%"}
            destroyOnClose
            className="service-templates-drawer"
            styles={{ padding: 0 }}
        >
            {/* Header */}
            <Col span={24} className="templates-header">
                <Title level={4} className="service-templates-title">
                    Templates
                </Title>
                <IoClose className="drawer-close-icon" onClick={() => setSeeTemplates(false)} />
            </Col>

            {isLoading ? (
                <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
                <Row className="template-body">

                    <Paragraph className="templates-container">
                        <Text className="flex-center">
                            <Button
                                onClick={() => {

                                    setTab(1);
                                }}
                                className={tab == 1 ? "tab-btn-selected " : "tab-btn "}
                            >
                                1:1 calls
                            </Button>
                            <Button
                                className={
                                    tab == 2
                                        ? "tab-btn-selected milestone-btn"
                                        : "tab-btn milestone-btn"
                                }
                                onClick={() => {

                                    setTab(3);
                                }}
                            >
                                Webinar
                            </Button>
                        </Text>
                        <Row>
                            {templates && getTemplates()?.length > 0 ? (
                                getTemplates().map((template) => (
                                    <Row
                                        key={template.id}
                                        justify="space-between"
                                        align="middle"
                                        className="sample-service cursor-hover"
                                        onClick={() => {

                                            handleClick(template);
                                        }}
                                    >
                                        <Col
                                            xs={{ span: 18, offset: 0 }}
                                            md={{ span: 20, offset: 0 }}
                                        >
                                            <Paragraph
                                                id={template.id}
                                                disabled={template.disabled}
                                            >
                                                <Paragraph className="title">
                                                    {template.title}
                                                </Paragraph>
                                            </Paragraph>
                                        </Col>
                                        <Col>
                                            <Button className="use-btn">
                                                Use
                                                <AiOutlineArrowRight
                                                    size="16px"
                                                    style={{ paddingLeft: "4px" }}
                                                />
                                            </Button>
                                            <Button className="arrow-btn">
                                                <AiOutlineArrowRight />
                                            </Button>
                                        </Col>
                                    </Row>
                                ))
                            ) : (
                                <Text className="no-templates">
                                    No Templates Available Currently!
                                </Text>
                            )}
                        </Row>
                    </Paragraph>
                    {/* )} */}
                </Row>
            )}
        </Drawer>
    );
};

export default ServiceTemplates;
