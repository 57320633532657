import React, { useEffect, useState } from 'react';
import { Col, Button, Typography, Form, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../redux/actions/login';
import { REACT_USER_ID } from '../auth/tokenProvider';
import Header from '../applyAsMentor/BecomeMentorHeader';
import OnboardingAboutYou from './Step/StepM1';
import { StepM2 } from './Step/StepM2';
import { StepM3 } from './Step/StepM3';
import StepM4 from './Step/StepM4';

const { Paragraph } = Typography;

const OnboardingMentor = () => {
	const dispatch = useDispatch();
	const user_id = REACT_USER_ID;
	const [currentStep, setCurrentStep] = useState(1);
	const [data, setData] = useState();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		dispatch(getUserDetails(user_id));
	}, [dispatch, user_id]);

	const userDetails = useSelector((state) => state?.userDetailsReducer);

	useEffect(() => {
		setData(userDetails?.data[0]);
		setIsLoading(userDetails?.loading);
	}, [userDetails]);

	const [ProfileDetailsForm] = Form.useForm();

	useEffect(() => {
		ProfileDetailsForm?.setFieldsValue(data);
	}, [ProfileDetailsForm, data]);

	const nextStep = () => {
		ProfileDetailsForm
			.validateFields()
			.then(() => {
				ProfileDetailsForm.submit();

				// setCurrentStep(currentStep + 1);
			})
			.catch((error) => {
				message.error('Please fill in all required fields.');
				console.log(error)
			});
	};

	const prevStep = () => {
		setCurrentStep(currentStep - 1);
	};

	const renderStep = () => {
		switch (currentStep) {
			case 1:
				return <OnboardingAboutYou form={ProfileDetailsForm} profile={data} loading={isLoading} setLoading={setIsLoading} />;
			case 2:
				return <StepM2 form={ProfileDetailsForm} profile={data} loading={isLoading} setLoading={setIsLoading} />;
			case 3:
				return <StepM3 form={ProfileDetailsForm} profile={data} loading={isLoading} setLoading={setIsLoading} />;
			case 4:
				return <StepM4 form={ProfileDetailsForm} profile={data} loading={isLoading} setLoading={setIsLoading} />;
			default:
				return null;
		}
	};

	const enterLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
			nextStep();
		}, 2000);
	};

	const variants = {
		hidden: { opacity: 0 },
		enter: { opacity: 1 },
		exit: { opacity: 0 },
	};

	return (
		<>
			<Paragraph className="onboarding">
				<Header currentStep={currentStep} />
				<Paragraph className="content-box">
					<Col xs={24} md={8}>
						<motion.main
							variants={variants}
							initial="hidden"
							animate="enter"
							exit="exit"
							transition={{
								type: 'spring',
								stiffness: 260,
								damping: 20,
							}}
						>
							{renderStep()}
						</motion.main>
					</Col>
				</Paragraph>
				<section className="onboarding-bottom">
					{currentStep > 1 ? (
						<div className="ant-row ant-row-center">
							<div className="ant-col ant-col-xs-22 ant-col-md-8">
								<div className="ant-row ant-row-space-between ant-row-middle nav-content">
									<div className="ant-col ant-col-xs-0 ant-col-md-2">
										<button type="button" className="ant-btn ant-btn-link back-icon" onClick={prevStep}>
											<ArrowLeftOutlined />
										</button>
									</div>
									<div className="ant-col ant-col-xs-24 ant-col-md-15">
										<Button type="text" className="ant-btn ant-btn-default ant-btn-block btn-dark" loading={isLoading} onClick={enterLoading}>
											<span>{currentStep === 4 ? 'Launch' : 'Next'}</span>
										</Button>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="ant-row ant-row-center onboarding-bottom">
							<div className="ant-col ant-col-xs-22 ant-col-md-8">
								<div className="ant-row ant-row-space-between ant-row-middle nav-content">
									<div className="ant-col ant-col-xs-24 ant-col-md-24">
										<Button type="text" className="ant-btn ant-btn-default ant-btn-block btn-dark" loading={isLoading} onClick={enterLoading}>
											<span>Next</span>
										</Button>
									</div>
								</div>
							</div>
						</div>
					)}
				</section>
			</Paragraph>
		</>
	);
};

export default OnboardingMentor;
