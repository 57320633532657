import React from "react";
import crossIcon from "../../../assets/images/cross-icon.png";
import menuIcon from "../../../assets/images/menu.png";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo-w.png";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../redux/actions/login";
import { findMentorAction } from "../../../redux/actions/mentor";
import { useState } from "react";
import LoginModal from "../../modals/loginModal";

const DashboardHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let MENTOR_PAGE_SIZE = 12,
    MENTOR_PAGE_NUMBER = 1;

  const findMentors = () => {
    const data = {
      pageSize: MENTOR_PAGE_SIZE,
      pageNo: MENTOR_PAGE_NUMBER,
      skills: "top",
    };
    dispatch(findMentorAction(data));
    navigate("/find-mentor");
  };

  const handleLogOut = () => {
    window.location.assign("/");
    localStorage.removeItem("REACT_TOKEN_AUTH");
    localStorage.removeItem("REACT_USER_ID");
    dispatch(logoutUser(""));
  };
  const [isOpen, setIsOpen] = useState(true);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
  );
  const [headerModal, setHeaderModal] = useState({});


  return (
    <header className="inner-header header-cls" style={{ height: '73px' }}>
      <meta
        name="viewport"
        content="width=device-width, initial scale=1.0,user-scalable=no"
      />
      <div className="container">
        <nav className="menu navbar navbar-expand-lg">
          <a className="navbar-brand" href="/">
            <img src={logo} style={{ width: 200 }} />
          </a>
          <button
            className={"navbar-toggler"}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#headerHandler"
            aria-controls="headerHandler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              alt=""
              src={isOpen ? menuIcon : crossIcon}
            />
          </button>
          <div className="menu collapse navbar-collapse rounded" id="headerHandler">

            <ul className="navbar-nav align-items-center mentee_nav_item">
              <li className="nav-item" onClick={() => navigate("/dashboard")}>
                <a className="nav-link">Dashboard</a>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  findMentors();
                }}
              >
                <a className="nav-link">Find Mentor</a>
              </li>
              <li
                className="nav-item"
                onClick={() => navigate("/become-mentor")}
              >
                <a className="nav-link">Become a Mentor</a>
              </li>

              <li className="nav-item" onClick={() => {
                loggedIn ? (navigate("/my-ebooks")) : (setHeaderModal({
                  modal: LoginModal, show: true,
                  as: "mentee"
                }))
              }}>
                <a className="nav-link">E-Books</a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Manage Profile
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a
                    className="dropdown-item"


                    onClick={() => {
                      loggedIn ? (navigate("/myprofile")) : (setHeaderModal({
                        modal: LoginModal, show: true,
                        as: "mentee"
                      }))
                    }}
                  >
                    My Accout
                  </a>
                  <a className="dropdown-item" onClick={handleLogOut}>
                    Logout
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="button btn get-started"

                  data-toggle="modal"
                  data-target="#form"
                  onClick={() => {
                    setHeaderModal({
                      modal: LoginModal,
                      show: true,
                      as: "mentee",
                    });
                  }}
                >
                  Need Help?
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {headerModal?.modal && (
        <headerModal.modal
          headerModal={headerModal}
          setHeaderModal={setHeaderModal}
        />
      )}
    </header>
  );
};

export default DashboardHeader;
