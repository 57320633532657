import React from "react";
import "../cutsom.css";
import { Outlet, useNavigate } from "react-router-dom";
const CandidateList = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="HireTalents__Content pl-xl-5">
        <div className="row justify-content-between align-items-center">
          <div className="col-xl-12 col-md-12">
            <div className="card border-0">
              <div className="card-header header-table tab-header p-0">
                <ul
                  className="nav nav-tabs"
                  role="tablist"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabs-1"
                      role="tab"
                      onClick={() => navigate("")}
                    >
                      All
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#tabs-2"
                      role="tab"
                      onClick={() => navigate("saved")}
                    >
                      Saved
                    </a>
                  </li>
                </ul>
                <div className="ms-auto hire-subpara listing__cards ">
                  <div className="talent-stats__skills">
                    <div className=" d-flex ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="currentColor"
                        className="bi bi-person-vcard-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                      </svg>
                      <div className="ms-4 ">
                        <p className="  font-weight-700 user__item">987</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Render Component */}
        <div className="pt-3">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default CandidateList;
