import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import {
  REACT_TOKEN_AUTH,
  REACT_USER_ID,
} from "./components/auth/tokenProvider";

let API_BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const headers = {
  "x-app-name": "mentorpal",
  "x-device-id": "deviceid1",
  "x-platform-id": "web",
  "x-correlation-id": uuidv4(),
  "x-app-version": "1.0",
  "x-test-otp": 12345,
};

if (REACT_TOKEN_AUTH && REACT_USER_ID) {
  headers["Authorization"] = "Bearer " + REACT_TOKEN_AUTH;
  headers["x-user-id"] = REACT_USER_ID;
}

const headers2 = (deviceToken, userId) => {
  if (deviceToken) {
    headers["x-device-token"] = deviceToken;
    headers["x-user-id"] = userId;
  }
  return headers;
};

const getHeaders = (data) => {
  const header = { ...headers, ...data };
  return header;
};

export async function generateOtp(data) {
  return await axios

    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/email/generate-otp`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
      };
    });
}
export async function verifyEmail(data) {
  return axios
    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/otp/verify`, data, {
      headers: headers,
    })
    .then((res) => {
      localStorage.setItem("REACT_TOKEN_AUTH", res.headers?.["x-device-token"]);
      localStorage.setItem("REACT_USER_ID", res.headers?.["x-user-id"]);
      localStorage.setItem("REACT_USER_TYPE", res.data?.["user_type"]);
      return {
        status: res.status,
        data: res.data,
        headers: res.headers,
      };
    })
    .catch((res) => {
      return {
        headers: res?.response?.headers,
        status: res?.response?.status,
      };
    });
}
export async function login(data) {
  return axios
    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/login`, data.data, {
      headers: headers2(data.deviceToken, data.userId),
    })
    .then((res) => {
      localStorage.setItem("REACT_TOKEN_AUTH", res.headers?.["x-device-token"]);
      localStorage.setItem("REACT_USER_ID", res.headers?.["user-id"]);
      localStorage.setItem("REACT_USER_TYPE", res.data?.["user_type"]);
      return {
        data: res.data,
        headers: res.headers,
      };
    })
    .catch((res) => {
      return {
        headers: res?.response?.headers,
      };
    });
}

export async function updateUserPasswordCall(data) {
  return await axios
    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/password/`, data, {
      headers: headers2(data.deviceToken, data.userId),
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function findMentor(data) {
  return await axios
    .get(`${API_BASE_URL}listing-apis/mentor/list`, {
      headers,
      params: {
        pageSize: data.pageSize,
        pageNo: data.pageNo,
        skills: data.skills,
        experties: data.experties,
        is_pro_bono: data.is_pro_bono,
        language: data.language,
        country: data.country,
        experience: data.experience
      },
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function createMentor(data) {
  return await axios
    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/user/sign-up`, data, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function viewMentorProfile(data) {
  // let groupSession = await getMentorGroupSession(
  //   "5a098b4a-b981-40ae-8787-5dd99e300c78"
  // );
  // let skills = await getMentorSkills(data);
  // let books = await getMentorBooks("0b57bc39-ccbe-4f3d-b3a7-0b654ac4c981");
  // let products = await getMentorProducts(data);
  let products = [];
  let details = await getMentorDetails(data);

  if (details.data !== undefined && details.data.length !== 0) {
    // details.data[0].skills = [];
    // details.data[0].groupSession = groupSession?.data;
    // details.data[0].books = books.data;
    // details.data[0].products = products?.data[0];
    details.data[0].products = [];
  }

  return details;
}

export async function getMentorDetails(data) {
  return await axios
    .get(`${API_BASE_URL}mentor-details/v1/mentors/${data}/details`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function getMentorSkills(data) {
  return await axios
    .get(`${API_BASE_URL}mentor-skills/v1/mentors/${data}/skill`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function getMentorGroupSession(data) {
  return await axios
    .get(
      `${API_BASE_URL}mentor-group-session/v1/mentors/${data}/group/session`,
      {
        headers,
      }
    )
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function getMentorBooks(data) {
  return await axios
    .get(`${API_BASE_URL}mentor-books/books`, {
      headers,
      params: {
        mentorId: data,
      },
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function getMentorProducts(data) {
  return await axios
    .get(`${API_BASE_URL}product-service/product?mentorId=${data}`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function password(data) {
  return await axios
    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/user/password/login`, data, {
      headers: headers,
    })
    .then((res) => {
      localStorage.setItem("REACT_TOKEN_AUTH", res.headers?.["x-device-token"]);
      localStorage.setItem("REACT_USER_ID", res.headers?.["user-id"]);
      localStorage.setItem("REACT_USER_TYPE", res.data?.["user_type"]);
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
        status: res.status,
      };
    })
    .catch((res) => {
      return {
        message: res?.response?.data,
        headers: res?.response?.headers,
      };
    });
}

export async function signUpCall(data) {
  return await axios
    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/user/sign-up`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
      };
    })
    .catch((res) => {
      return {
        message: res?.response?.data,
      };
    });
}

export async function social(data) {
  return axios
    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/user/social-login`, data, {
      headers: headers,
    })
    .then((res) => {
      localStorage.setItem("REACT_TOKEN_AUTH", res.headers?.["x-device-token"]);
      localStorage.setItem("REACT_USER_ID", res.headers?.["user-id"]);
      localStorage.setItem("REACT_USER_TYPE", res.data?.["user_type"]);
      return {
        data: res.data,
        headers: res.headers,
        status: res.status,
        message: res.message,
      };
    });
}

export async function linkedinShare(data){
  return axios
  .post(`${API_BASE_URL}third-party/v1/send-linkedin/${REACT_USER_ID}/posts`, data, {
    headers: headers,
  })
  .then((res) => {
    return {
      data: res
    };
  })
  .catch((res) => {
    return {
      headers: res?.response?.headers,
    };
  });
}
export async function profileUpdate(data) {
  return axios
    .post(
      `${API_BASE_URL}mentorpal-auth/auth/v1/user/update-user-profile`,
      data,
      {
        headers: headers2(data.deviceToken),
        params: {
          userId: data.user_id,
        },
      }
    )
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    })
    .catch((res) => {
      return {
        headers: res?.response?.headers,
      };
    });
}

export async function signUpVerifyy(data) {
  return axios
    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/email/verify`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        status: res.status,
      };
    })
    .catch((res) => {
      return {
        headers: res?.response?.headers,
        message: res?.response?.data,
      };
    });
}

export async function forgetPasswordCall(data) {
  return await axios
    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/user/forget-password`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    })
    .catch((res) => {
      return {
        message: res?.response?.data,
        headers: res?.response?.headers,
      };
    });
}

export async function userDetailsCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentor-details/v1/mentors/${data}/details`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function favoriteMentorCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentee-service/v1/mentee/${data}/favorite`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}
export async function createFavoriteMentorCall(data) {
  return await axios
    .post(`${API_BASE_URL}mentee-service/v1/create-favorite-mentor/`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function subscribedMentorCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentee-service/v1/mentee/${data}/subscribed`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function similarMentorCall(data) {
  return await axios
    .get(`${API_BASE_URL}similar-mentor/v1/similar/mentor?mentorId=${data}`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}
export async function similarMentorGoalCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentee-service//v1/get-similar/${data}/goals`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function orderIdCall(data) {
  return await axios
    .post(`${API_BASE_URL}payment-service/pg/create/order`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    })
    .catch((res) => {
      return {
        message: res?.response?.data,
        headers: res?.response?.headers,
      };
    });
}

export async function createMentorSessionServiceCall(data) {
  return await axios
    .post(`${API_BASE_URL}product-service/product`, data, { headers: headers })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function publishEbookCall(data) {
  return await axios
    .post(`${API_BASE_URL}mentor-books/books`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function mentorProductsCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentor-product/v1/mentors/${data}/product`, {
      headers: headers,
      params: {
        serviceType: data.serviceType,
        mentorId: data.mentorId,
      },
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function mentorEbooksCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentor-books/books`, {
      headers: headers,
      params: { mentorId: data },
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}
export async function mentorTotalSubscriptionCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentorship-request/v1/mentor/${data}/total`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}
export async function openSessionsCall(data) {
  return await axios
    .get(`${API_BASE_URL}home/get-open-session`, {
      headers: headers,
      params: {
        pageSize: data.pageSize,
        pageNo: data.pageNo,
      },
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}
export async function topMentorsCall() {
  return await axios
    .get(`${API_BASE_URL}home/get-top-mentor`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}
export async function mentorActiveSubscriptionCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentorship-request/v1/mentor/${data}/active`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}
export async function mentorSubscriptionRequestsCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentorship-request/v1/mentor/${data}/mentorship`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function generateAuthLink(data) {
  return axios
    .post(
      `${API_BASE_URL}mentorpal-google-auth/google/connect/calander`,
      data,
      {
        headers: getHeaders({ "redirect-url": data?.redirectUrl }),
      }
    )
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
      };
    })
    .catch((res) => {
      return {
        headers: res?.response?.headers,
      };
    });
}

export async function handleGoogleRedirect(code, redirectUrl) {
  return await axios
    .get(
      `${API_BASE_URL}mentorpal-google-auth/google/connect/calander/handleRedirect?code=${code}`,
      {
        headers: getHeaders({ "redirect-url": redirectUrl }),
      }
    )
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function uploadImageReq(data) {
  return await axios
    .post(`${API_BASE_URL}platform-service/create/presigned/url/`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function uploadImageFileReq(data) {
  delete headers["Authorization"];
  return await axios
    .put(data.url, data.fileData, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}
export async function getMentorAvailabilityDays(data) {
  return await axios
    .get(`${API_BASE_URL}availability-services/available-day`, {
      headers: headers,
      params: {
        mentorId: data.id,
      },
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}
export async function getMentorAvailabilityTimeSlots(data) {
  return await axios
    .get(`${API_BASE_URL}availability-services/available-time-slot`, {
      headers: headers,
      params: {
        mentorId: data.id,
        date: data.date,
      },
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function submitFeedbackDataCall(data) {
  return await axios
    .post(`${API_BASE_URL}home/feedback`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function mentorAvailableHoursCall(data) {
  return await axios
    .post(`${API_BASE_URL}availability-services/update-availability`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function getMentorAvailableHoursCall(data) {
  return await axios
    .get(`${API_BASE_URL}availability-services/get-availability`, {
      headers: headers,
      params: {
        mentorId: data?.id,
      },
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function getAllMentorSessionCall(data) {
  return await axios
    .get(`${API_BASE_URL}order-service/v1/mentor/${data}/mentorship`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function updateCalendarSettingsCall(data) {
  return await axios
    .post(
      `${API_BASE_URL}mentorpal-auth/auth/v1/user/update-calendar-settings`,
      data,
      {
        headers,
        params: {
          userId: data.id,
        },
      }
    )
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}

export async function personalityTestCall(data) {
  return await axios
    .get(`${API_BASE_URL}personality/v1/personality/questions`, {
      headers: headers,
    })
    .then((res) => {
      return {
        data: res.data,
        headers: res.headers,
        message: res.message,
      };
    });
}

export async function testResponseCall(data) {
  return await axios
    .post(`${API_BASE_URL}personality//v1/personality/answers`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function fullStackCall(data) {
  return await axios
    .post(`https://ats.api.mentorpal.ai/public/enquiry`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function getLiveSessionCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentor-live-session/v1/get-live-session`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}
export async function getSessionDetailsCall(data) {
  return await axios

    .get(
      `${API_BASE_URL}mentor-live-session/v1/get-session-details?sessionId=${data.sessionId}`,
      {
        headers,
      }
    )
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function enrollSessionCall(data) {
  return await axios
    .post(`${API_BASE_URL}mentor-live-session/v1/session-enrollment`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function createSessionCall(data) {
  return await axios
    .post(`${API_BASE_URL}session/service/session`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function menteeGoalCall(data) {
  return await axios
    .post(`${API_BASE_URL}mentee-service/v1/create-goals`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}

export async function addNotesCall(data) {
  return await axios
    .post(`${API_BASE_URL}notes/notes`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function getNotesCall(data) {
  return await axios

    .get(`${API_BASE_URL}notes/notes?targetUserId=${data}`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}
export async function updateNotesCall(data) {
  return await axios
    .put(`${API_BASE_URL}notes/notes?notesId=${data.notesId}`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}

export async function getUpcomingTaskCall(data) {
  return await axios

    .get(`${API_BASE_URL}tasks/tasks?targetUserId=${data}`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function getUpcomingMeetingCall(data) {
  return await axios

    .get(`${API_BASE_URL}self-meetings/v1/meeting-list`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}
export async function getHistoryMeetingCall(data) {
  return await axios

    .get(`${API_BASE_URL}notes/notes?mentorId=${data}`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}
export async function createTaskCall(data) {
  return await axios
    .post(`${API_BASE_URL}tasks/tasks`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function createMeetingCall(data) {
  return await axios
    .post(`${API_BASE_URL}self-meetings/v1/create-meeting`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function updateTaskCall(data) {
  return await axios
    .put(`${API_BASE_URL}tasks/tasks?taskId=${data.tasks_id}`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function cancelMeetingCall(data) {
  return await axios
    .post(`${API_BASE_URL}self-meetings/v1/meeting/${data.id}/cancel`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function sendChatMessageCall(data) {
  return await axios
    .post(`${API_BASE_URL}message/v1/message`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function getChatMessageCall(data) {
  return await axios

    .get(
      `${API_BASE_URL}message/v1/chat/${data.receiver_id}/${data.sender_id}/message`,
      {
        headers,
      }
    )
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function getConnectionRequestCall(data) {
  return await axios

    .get(`${API_BASE_URL}connection-request/v1/get-connection-request`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function updateConnectionRequestCall(data) {
  return await axios
    .put(
      `${API_BASE_URL}connection-request/v1/update-connection-status?connectionId=${data.connection_id}`,
      data,
      {
        headers: headers,
      }
    )
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function getConnectionListCall(data) {
  return await axios
    .get(`${API_BASE_URL}connection-request/v1/get-connection-list`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}
export async function getGoalCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentee-service/v1/get-mentee/${data}/goals`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}
export async function getNotificationCall(data) {
  return await axios
    .get(`${API_BASE_URL}notification/v1/get-notification-list`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}
export async function updateNotificationStatusCall(data) {
  return await axios
    .put(`${API_BASE_URL}notification/v1/update-notification-status`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function createPaymentLinkCall(data) {
  return await axios
    .post(`${API_BASE_URL}strip-payment-service/v1/create-payment-link`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}

export async function subscribeCall(data) {
  return await axios
    .post(`${API_BASE_URL}home/newsletter`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}

export async function contactCall(data) {
  return await axios
    .post(`${API_BASE_URL}home/feedback`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}

export async function createReview(data) {
  return await axios
    .post(`${API_BASE_URL}mentor-reviews/v1/create-review`, data, {
      headers: headers
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        // status: res.status,
      };
    })
    .catch((err) => console.log(err))
}

export async function getReviews(mentor_id) {
  return await axios
    .get(`${API_BASE_URL}mentor-reviews/v1/get-review?mentorId=${mentor_id}`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}


export async function createCourseCall(data) {
  return await axios
    .post(`${API_BASE_URL}product-service/product`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function getMentorCourseCall(data) {
  return await axios
    .get(`${API_BASE_URL}product-service/product?mentorId=${data?.mentor_id}&document_type=${data?.document_type}`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}
export async function getAllCourseCall(data) {
  return await axios
    .get(`${API_BASE_URL}product-service/get-all-product-list?document_type=${data?.document_type}`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}
export async function getCourseDetailsCall(data) {
  return await axios
    .get(`${API_BASE_URL}product-service/get-product-details/${data}`, {
      headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}

export async function updateMentorCourseCall(data) {
  return await axios
    .put(`${API_BASE_URL}product-service/product?productId=${data?.product_id}`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}
export async function deleteMentorCourseCall(data) {
    delete headers["Authorization"];
  return await axios
    .delete(`${API_BASE_URL}product-service/delete-all-course/${data}`, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
      };
    });
}


export async function createProductCall(data) {
  return await axios
    .post(`${API_BASE_URL}product-service/product?mentorId=${data?.mentor_id}`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}

export async function addBankAccountCall(data) {
  return await axios
    .post(`${API_BASE_URL}mentorpal-auth/auth/v1/user-account`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}
export async function getBankeDetailsCall(data) {
  return await axios
    .get(`${API_BASE_URL}mentorpal-auth/auth/v1/user-account`, data, {
      headers: headers,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data,
        status: res.status,
      };
    });
}