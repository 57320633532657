import { call, put } from "redux-saga/effects";
import {
  generateAuthLink,handleGoogleRedirect
} from "../../network";
import {
    googleCalanderConstants
  } from "../../constants";

export function* generateAuthLinkSaga(action) {
  try {
    const response = yield call(generateAuthLink,action?.data?.redirectUrl);
    yield put({
      type: googleCalanderConstants.GENERATE_AUTH_LINK_REQUEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
    if(response?.data?.url){
      window.location.href = response.data.url 
    }
  } catch (e) {
    yield put({ type: googleCalanderConstants.GENERATE_AUTH_LINK_REQUEST_FAILURE, error: e.message });
  }
}

export function* handleGoogleRedirectSaga(action) {
  try {
    const response = yield call(handleGoogleRedirect, action?.data?.code,action?.data?.redirectUrl);
    yield put({
      type: googleCalanderConstants.HANDLE_GOOGLE_REDIRECT_REQUEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({ type: googleCalanderConstants.HANDLE_GOOGLE_REDIRECT_REQUEST_FAILURE, error: e.message });
  }
}